<template>
    <v-alert text prominent type="error" icon="mdi-cloud-alert">
        <h3>Não foram encontrad{{ gender }}s {{ asset }}</h3>
        <span>Não foram encontrados dados dentro dos critérios específicados. Por favor pesquise por critérios distintos.</span>
        
        <!--
        <v-divider class="my-4 error" style="opacity: 0.22"></v-divider>
        
        <v-row align="center" no-gutters>
            <v-col class="grow">Se acredita que isto é um erro, por favor entra em contacto.</v-col>
            <v-spacer></v-spacer>
            <v-col class="shrink">
                <v-btn color="error" outlined>Contactar</v-btn>
            </v-col>
        </v-row>
        -->
    </v-alert>
</template>

<script>
export default {
    name: 'AlertNoData',

    props: {
        gender: { type: String, required: false, default: 'a' },
        asset: { type: String, required: false, default: 'dados' }
    }
}
</script>

<style scoped></style>