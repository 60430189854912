import { mapGetters } from 'vuex';
import DialogAddEditExpenseType from '@/components/Rail/Dialog/DialogAddEditExpenseType/DialogAddEditExpenseType.vue';
import DialogConfirmation from '@/components/Rail/Dialog/DialogConfirmation/DialogConfirmation.vue';
import EmptyStateNoData from '@/components/EmptyStateNoData';

export default {
  name: 'ExpensesTypes',
  
  components: {
    DialogAddEditExpenseType,
    DialogConfirmation,
    EmptyStateNoData,
  },

  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
    subtitle: {
      type: String,
      required: false,
      default: '',
    },
    
  },

  computed: {
    ...mapGetters({
      expenseTypes: 'railExpenses/expenseTypes',
      selectedType: 'railExpenses/selectedType',
      loadingExpenseTypes: 'railExpenses/loadingExpenseTypes',
      error: 'railExpenses/error',
      humanError: 'railExpenses/humanError',
      heroDatePickerValue: 'UI/heroDatePickerValue',
    }),

    /**
     * Transforms API output into node-tree format.
     * Depends on expenseTypes from railExpenses store.
     * @returns {Array[Object]}
     */
    treeviewData: function () {

      // Return empty arr if no data in store
      if (! this.expenseTypes) {
        return [];
      }

      let topLevelItems = [];
      let childLevelItems = [];

      // Generate items, separate nodes between top-level and child
      for (let index in this.expenseTypes) {
        let item = {
          id: this.expenseTypes[index].id,
          name: this.expenseTypes[index].name,
          children: [],
          color: this.expenseTypes[index].color,
        };

        // Child node
        if (this.expenseTypes[index].parentExpenseType) {
          item.parent = this.expenseTypes[index].parentExpenseType;
          childLevelItems.push(item);
        // Top level node
        } else {
          topLevelItems.push(item);
        }
      }

      for (let index in childLevelItems) {
        // Find parent child id
        let parentExpenseTypeId = childLevelItems[index].parent ? childLevelItems[index].parent.id : null;
        let childParentExpenseTypeIndex = childLevelItems.findIndex(e => e.id == parentExpenseTypeId);

        if (childParentExpenseTypeIndex != -1) {
          childLevelItems[childParentExpenseTypeIndex].children.push(childLevelItems[index]);
        }

      }

      // Add child nodes to parent.children[]
      for (let index in childLevelItems) {
        // Find parent id
        let parentExpenseTypeId = childLevelItems[index].parent.id;
        let parentExpenseTypeIndex = topLevelItems.findIndex(e => e.id == parentExpenseTypeId);

        // Standardize item by clearing 'parent' key
        delete childLevelItems[index].parent;

        // Push to parent
        if (parentExpenseTypeIndex != -1) {
          topLevelItems[parentExpenseTypeIndex].children.push(childLevelItems[index]);
        }
      }

      return topLevelItems;
    },

  },

  watch: {
    selection() {
      let _selectedNodes = [];
      let _treenodes = this.$refs['tree'].nodes;

      for (const key in _treenodes) {
        if (Object.hasOwnProperty.call(_treenodes, key)) {
          const node = _treenodes[key];
          if (node.isSelected) _selectedNodes.push(node.item.id);
        }
      }
  
      this.generateOutput(_selectedNodes);
    }
  },

  data() {
    return this.initialState();
  },

  created() {
    this.initialize();
  },

  methods: {
    initialState() {
      return {
        selection: [],
        dialogues: {
					create: false,
					delete: false,
        },
        options: {
          page: 1,
          itemsPerPage: 10,
        },
        submittingDelete: false,
        confirmationMessage: false,
      }
    },

    initialize() {
        this.fetchExpenseTypes();
    },

    async fetchExpenseTypes() {
      // Get expense types
      await this.$store.dispatch('railExpenses/getTypes');
    },

    /**
     * emits an array filled with expense types ID's
     * @param {Array} selected 
     */
    generateOutput(items) {
      this.$emit('emit-expenses', items);
    },
    
    openDialogDeleteExpense(item) {
      if(item.children.length > 0){
        this.confirmationMessage = 'Esta categoria tem despesas associadas que serão movidas para a categoria  "não catalogada" , isto é irreversível. Tem a certeza que quer apagar este tipo de despesa ?';
      }else{
        this.confirmationMessage = false
      }
      this.$store.commit('railExpenses/setSelectedType', item);
      this.$store.dispatch('railExpenses/clearError');
      this.dialogues.delete = true;
    },

    closeDialogDeleteExpense() {
      this.dialogues.delete = false;
    },
    
    openDialogAddEditExpenseType(expenseType = null) {
      this.$store.dispatch('railExpenses/clearError');

      // If editting, populate selected type
      if (expenseType) {
        this.$store.commit(
          'railExpenses/setSelectedType', 
          this.expenseTypes.find(e => e.id == expenseType.id)
        );
      }

      // Open dialog
      this.dialogues.create = true;
      this.$refs.DialogAddEditExpenseType.populate();
    },

    closeDialogAddEditExpenseType() {
      this.dialogues.create = false;
    },

    async deleteExpenseType() {
      // Set DialogConfirmation to submitting state
      this.submittingDelete = true;

      // Dispatch store action
      let result = await this.$store.dispatch('railExpenses/deleteType', this.selectedType.id);

      if (result === true) {
        // Refresh data
        this.fetchExpenseTypes();
        this.fetchExpenses();

        // Close dialog
        this.closeDialogDeleteExpense();

        // Show success snackbar
        this.$store.dispatch('UI/showSnackbar', {
          message: 'Tipo de despesa apagado com sucesso.',
          color: 'success'
        });
      }

      // re-enables submit button;
      this.submittingDelete = false;
    },

    async fetchExpenses() {
      let payload = {
        params: {
          dateBegin: this.heroDatePickerValue[0],
          dateEnd: this.heroDatePickerValue[1],
          page: this.options.page,
          itemsPerPage: this.options.itemsPerPage,
        }
      }
      await this.$store.dispatch('railExpenses/getExpenses', payload);
    }
  },
}