import Vue from 'vue';
import Vuex from 'vuex';
import StoreMixin from '@/mixins/Store.vue';

Vue.use(Vuex);
Vue.use(StoreMixin);

const state = {
    data: [],
    total: null,
    loading: true,
    error:  null,
};

const getters = {
    data: state => state.data,
    total: state => state.total,
    loading: state => state.loading,
    error: state => state.error,
};

const mutations = {
    setData: (state, payload) => { state.data = payload; },
    setTotal: (state, payload) => { state.total = payload; },
    setLoading: (state, payload) => { state.loading = payload; },
    setError: (state, payload) => { state.error = payload; },
};

const actions = {
    clearData({ commit }) {
        commit('setData', []);
    },

    async get({ commit, dispatch }, payload) {
        dispatch('reset');
        let output = false;
        let request_url = Vue.prototype.$scheduling_api + 'gamegroups/';

        // Formulate URL with query string with generateQueryParamsUrl mixin function
        if (payload) {
            request_url = StoreMixin.methods.generateQueryParamsUrl(request_url, payload.params);
        }

        return axios.get(request_url)
        .then(function (response) {
            output = true;
            commit('setData', response.data.data);
            commit('setTotal', response.data.total);
        })
        .catch(function (error) {
            commit('setError', error.response ? error.response.data.error.message : error);
            output = false;
        })
        .then(function () {
            commit('setLoading', false);
            return output;
        });
    },

    async getById({ commit }, id) {
        commit('setLoading', true);
        let output = false;
        let request_url = Vue.prototype.$scheduling_api + 'gamegroups/' + id;

        return axios.get(request_url)
        .then(function (response) {
            output = true;
            commit('setData', response.data.data);
        })
        .catch(function (error) {
            commit('setError', error.response ? error.response.data.error.message : error);
            output = false;
        })
        .then(function () {
            commit('setLoading', false);
            return output;
        });
    },

    async getForModel({ commit }, payload) {
        commit('setLoading', true);
        let output = false;
        let request_url = Vue.prototype.$scheduling_api + 'models/' + payload.id + '/gamegroups';

        // Formulate URL with query string with generateQueryParamsUrl mixin function
        if (payload) {
            request_url = StoreMixin.methods.generateQueryParamsUrl(request_url, payload.params);
        }

        return axios.get(request_url)
        .then(function (response) {
            output = true;
            commit('setData', response.data.data);
            commit('setTotal', response.data.total);
        })
        .catch(function (error) {
            commit('setError', error.response ? error.response.data.error.message : error);
            output = false;
        })
        .then(function () {
            commit('setLoading', false);
            return output;
        });
    },

    
    async getOneForModel({ commit }, data) {
        commit('setLoading', true);
        let output = false;
        let request_url = Vue.prototype.$scheduling_api + 'models/' + data.modelId + '/gamegroups/' + data.gameGroupId;
       
        return axios.get(request_url)
        .then(function (response) {
            output = true;
            commit('setData', response.data.data);
        })
        .catch(function (error) {
            commit('setError', error.response ? error.response : error);
            output = false;
        })
        .then(function () {
            commit('setLoading', false);
            return output;
        });
    },

    async create({commit}, payload) {
        commit('setLoading', true);
        let output = false;
        let request_url = Vue.prototype.$scheduling_api + 'gamegroups/';

        var requestConfig = {
            method: 'POST',
            url: request_url,
            headers: {
                'Content-Type': 'application/json'
            },
            data: payload
        }

        return axios(requestConfig)
        .then(function (response) {
            output = true;
            commit('setData', response.data.data);
        })
        .catch(function (error) {
            commit('setError', error.response ? error.response.data.error.message : error);
            output = false;
        })
        .then(function () {
            commit('setLoading', false);
            return output;
        });
    },

    async update({commit}, payload) {
        commit('setLoading', true);
        let output = false;
        let request_url = Vue.prototype.$scheduling_api + 'gamegroups/' + id;

        var requestConfig = {
            method: 'PUT',
            url: request_url,
            headers: {
                'Content-Type': 'application/json'
            },
            data: payload
        }
        
        return axios(requestConfig)
        .then(function (response) {
            output = true;
            commit('setData', response.data.data);
        })
        .catch(function (error) {
            commit('setError', error.response ? error.response.data.error.message : error);
            output = false;
        })
        .then(function () {
            commit('setLoading', false);
            return output;
        });
    },

    async delete({commit}) {
        commit('setLoading', true);
        let output = false;
        let request_url = Vue.prototype.$scheduling_api + 'gamegroups/' + id;

        var requestConfig = {
            method: 'DELETE',
            url: request_url,
            headers: {
                'Content-Type': 'application/json'
            }
        }
        
        return axios(requestConfig)
        .then(function (response) {
            // 204 response status represents success
            output = response.status == 204;
            if (! output) {
                commit('setError', response.data.message);
            }
        })
        .catch(function (error) {
            commit('setError', error.response ? error.response.data.error.message : error);
            output = false;
        })
        .then(function () {
            commit('setLoading', false);
            return output;
        });
    },

    clearError({ commit }) {
        commit('setError', null);
    },

    // Reset store to its initial state
    reset({ commit }) {
        commit('setData', []);
        commit('setLoading', true);
        commit('setError', null);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};