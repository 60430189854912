export default {
    name:'HistoricVideoRequest',
  
    props: {
      title: {
        type: String,
        required: false,
        default: 'Historic',
      }
    },
  
    data: () => ({
      items: [
        { id: '1', title: '351 - RuiNF - Analise de maos marcadas', date: '28-12-2021 12:34', },
        { id: '2', title: '562 - Iniciação ao ICM - Parte 2', date: '28-12-2021 12:34', },
        { id: '3', title: '561 - Iniciação ao ICM - Parte 1', date: '28-12-2021 12:34', },
        { id: '4', title: '538 - Aula- ICM Multimesas - Parte 1', date: '28-12-2021 12:34', },
        { id: '5', title: '351 - RuiNF - Analise de maos marcadas', date: '28-12-2021 12:34', },
        { id: '6', title: '538 - Aula- ICM Multimesas - Parte 1', date: '28-12-2021 12:34', },
        { id: '7', title: '561 - Iniciação ao ICM - Parte 1', date: '28-12-2021 12:34', },
      ],
    }),
    
  }
  