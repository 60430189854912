import Vue from 'vue';
import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth';
import DataMixin from '@/mixins/Data';

export default {
	name: 'Login',

	mixins: [
        AuthMixin,
        DataMixin,
    ],

	data() {
        return this.initialState();
	},

	computed: {
        ...mapGetters({
            userAuthenticated: 'auth/userAuthenticated',
			userType: 'auth/userType',
			loginError: 'auth/loginError',
        }),

        linkRecoverPassword() {
            return Vue.prototype.$links.login_recover_password;
        },
	},

	/*beforeRouteEnter (to, from, next) {
		if ( userAuthenticated ) next('/')
		else next()
	},*/

	created() {
		this.initialize()
	},

	methods: {
        initialState() {
            return {
                credentials: {
                    username: null,
                    password: null,
                },
                formValidationErrors: [],
                loggingIn: false,
                rules: {
                    fieldUsername: [v => !!v || 'Introduza um email ou nome de utilizador válido'],
                    fieldPassword: [v => !!v || 'Introduza uma password válida'],
                }
            }
        },

		initialize() {
			// Redirect user to homepage if already authenticated
			if (this.userAuthenticated) {
                this.logUserIn();
            }
		},

		async login() {
            this.clearUrl();

			// Validate form
			if (! this.$refs.formLogin.validate()) {
				return false;
			}

            this.toggleLoggingIn();

			let loginResult = await this.$store.dispatch('auth/login', {
				username: this.credentials.username,
				password: this.credentials.password
			});

			if (loginResult == true) {
                this.logUserIn();
			} else {
				// Show errors if they exist
				this.formValidationErrors = loginResult;
			}

            this.toggleLoggingIn()
		},

        /**
         * When auth is successful
         */
        logUserIn() {
            // Redirect user to homepage on successful login
            this.$router.push({ path: '/' });
        },

        /**
         * Used to disable login button
         */
        toggleLoggingIn() {
            this.loggingIn = ! this.loggingIn;
        },

        /**
         * Used to clear the "successfully logged out" alert
         */
        clearUrl() {
            if (this.$route.query.logout) {
                this.$router.push({
                    path: '/login'
                });
            }
        }
	}
}