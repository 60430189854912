export default {
    name: 'AlertError',

    props: {
        error: {
            type: String,
            required: false,
            default: 'Something went wrong',
        },
        humanError: {
            type: String,
            required: false,
            default: ''
        },
    },

    computed: {
        humanErrorComputed: function () {
            let output = this.humanError;

            if (! this.humanError) {
                output = 'Algo correu mal. Tente mais tarde ou contacte o suporte.';
            }

            return output;
        }
    },
}   
