<template>
  <v-card class="text-center fill-height">
    <v-card-title>Balanço global</v-card-title>

    <v-card-text
      v-if="noData"
      class="text-center display-3 font-weight-thin"
    >
      No data available.
    </v-card-text>

    <!-- Chart -->
    <v-sparkline
      :value="value"
      color="accent"
      height="250"
      padding="24"
      stroke-linecap="round"
      smooth
      label-size="12"
    >
      <template #label="item">
        ${{ item.value }}
      </template>
    </v-sparkline>
  </v-card>
</template>

<script>
export default {
	name: "Chart",

	data() {
		return {
			value: [423, 446, 675, 510, 590, 610, 760],
		};
	},
};
</script>