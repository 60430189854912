import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth';

export default {
    name: 'RailDivisionDropdown',

    props: {
        value: { type: [Object,Array], required: false, default: () => { } },
        excludedTeamsByType: { type: Array, required: false, default: () => [] },
        excludedTeamsById: { type: Array, required: false, default: () => [] },
        includedSpecificTeams: { type: Array, required: false, default: () => [] },
        searchByTypeTeam:{ type: Number, required: false, default: null }
    },

    mixins: [
        AuthMixin,
    ],

    computed: {
        ...mapGetters({
            teams: 'teams/teamsDivision',
            loading: 'teams/teamsDivisionLoading',
            userRoles: 'auth/userRoles',
            userTeams: 'auth/userTeams',
            teamFilter: 'teams/teamFilter',
        }),

        computedValue: {
            get: function () {
               return this.team;
            },
            set: function (newValue) {
                this.team = newValue;
                this.emitChange();
            }
        },

        teamsComputed: function () {
            let output = [];
        
            if (this.userHasRole(['rail_manager', 'tracker_manager'])) {
                output = [this.allTeamsDefault]
                if(this.userTeams && this.userTeams.length > 0){
                    output = [...output, ...this.userTeams];
                }
            } else {
                output = [...this.teams];
        
                if (!this._.isEmpty(output)) {
                    output = this.excludeTeamsByType(output);
                    output = this.excludeTeamsById(output);
                    output = this.includeSpecificTeams(output);
                }

                output.unshift(this.allTeamsDefault);
            }
            return output;
        },
    },

    watch: {
        computedValue: function () {
            // set promiseAllUsers to NULL in order to be possible to make new API Request
            this.$store.commit('users/setPromiseUsersAllActive', null);

            // set promiseAllMine to null in order to allow refreshing nicknames all mine again
            // TableBalances is affected in order to update management/all mine nicknames
			this.$store.commit('nicknames/setPromiseAllMine', null);

            // clear userNotes after value has changed
            this.$store.commit('users/setUserNotes', []);

            // set team filter 
            this.$store.commit('teams/setTeamFilter', this.computedValue);
        },

        teamFilter: {
            handler() {
                this.team = this.teamFilter;
            },
            deep: true,
            immediate: true,
        },
    },

    data() {
        return this.initialState();
    },

    created() {
        this.fetchData();
    },

    methods: {
        initialState() {
            return {
                team: null,
                data: [],
                allTeamsDefault: {
                    id: null,
                    name: 'Todas as Equipas',
                    contractDuration: null,
                    profitShare: null,
                    type: null,
                    ranking: 0
                },
          }
        },

        async fetchData() {
            if (this._.isEmpty(this.teams)) {
                let payload = {
                    params: {
                        page: 1,
                        itemsPerPage: -1,
                        ranking: 0
                    }
                }

                if(this.searchByTypeTeam){
                    payload.params.type = this.searchByTypeTeam // HELPER TEAM
                }
                
                this.$store.dispatch('teams/getTeamsDivision', payload);
            }
        },

        /**
        * Exclude certain teams from the results.
        * @param {*} data 
        * @returns {Array}
        */
        excludeTeamsByType(data = []) {
            let output = data;

            if (this.excludedTeamsByType && this.excludedTeamsByType.length && !this._.isEmpty(data)) {
                return data.filter(e => ! this.excludedTeamsByType.includes(e.type));
            }

            return output;
        },

        excludeTeamsById(data = []) {
            let output = data;

            if (this.excludedTeamsByType && this.excludedTeamsByType.length && !this._.isEmpty(data)) {
                return data.filter(e => ! this.excludedTeamsByType.includes(e.id));
            }

            return output;
        },

        includeSpecificTeams(data = []) {
            let output = data;

            if (this.includedSpecificTeams && this.includedSpecificTeams.length && !this._.isEmpty(data)) {
                return data.filter(e => this.includedSpecificTeams.includes(e.id));
            }

            return output;
        },
        
        emitChange() {
            this.$emit('input', this.computedValue);
            this.$emit('change', this.computedValue);
        },

        reset() {
            this.team = null;
            if (this.$refs.formRailDivisionDropdown) {
                this.$refs.formRailDivisionDropdown.reset();
            }
        },
        
    }
}