import Vue from 'vue';
import { mapGetters } from 'vuex';

export default {
    name: 'LivePlayerInformation',

    computed: {
        ...mapGetters({
            selectedPlayer: 'livePlayers/selectedPlayer',
            error: 'livePlayers/error',
            humanError: 'livePlayers/humanError',
            selectedPlayerTags: 'livePlayers/selectedPlayerTags',
            selectedPlayerTagsLoading: 'livePlayers/selectedPlayerTagsLoading',
        }),

        tagsComputed: function () {
            let tags = [
                'reg over agro',
                'pato over agro',
                'nit',
                'whale',
                'reg live / reg fraco',
                'pato high-stakes',
                'reg high-stakes'
            ];
        
            return tags;
        },

        // Synthetic v-model
        tagValueComputed: {
            get: function () {
                return this.tags;
            },
            set: function (newValue) {
                    this.tags = newValue;
            }
        },
    },

    data() {
        return this.initialState();
    },

    created(){
        this.fetchData();
        this.populateFields();
    },

    watch: {
        selectedPlayer: function () {
            this.populateFields();
        },
    },

    methods: {
        initialState() {
            return {
                addTagMenu: false,
                submitting: false,
                tags: [],
                tagList: [
                    { name: 'reg over agro', color: '', description: '' },
                    { name: 'pato over agro', color: '', description: '' },
                    { name: 'nit', color: '', description: '' },
                    { name: 'whale', color: '', description: '' },
                    { name: 'reg live / reg fraco', color: '', description: '' },
                    { name: 'pato high-stakes', color: '', description: '' },
                    { name: 'reg high-stakes', color: '', description: '' }
                ],
            };
        },
        handleUsername(user) {
            let output = '';

            if (user && user.name) {
                output = user.name;
            }

            return output;
        },

        openExternalLink() {
            if(this.selectedPlayer && this.selectedPlayer.external_link){
                window.open(this.selectedPlayer.external_link, '_blank');
            }
        },

        async fetchData(){
            if(this.selectedPlayer && this.selectedPlayer.id){
                let payload = {
                    id: this.selectedPlayer.id,
                }

                await this.$store.dispatch('livePlayers/fetchSelectedPlayer', payload.id);
            }
        },

        populateFields() {
            this.tagValueComputed = this.tagsWithOnlyNames();
        },

        async addTagsToPlayer(player) {
            let payload = player;
            payload.tags = this.tagsWithDetails(this.tagValueComputed)

            this.submitting = true;
            let result = await this.$store.dispatch('livePlayers/addPlayerTag', payload);
            this.submitting = false;
            
            if (result === true) {
                this.addTagMenu = false;
                this.tagValueComputed = this.tagsWithOnlyNames();
            }
        },

        // used to prepare payload
        tagsWithDetails(tagNames) {
            return tagNames.map(tagName => {
                return this.tagList.find(tag => tag.name === tagName);
                }).filter(tag => tag !== undefined);
        },

        tagsWithOnlyNames() {
            return this.selectedPlayerTags.map(element => element.name);
        },
    },
}