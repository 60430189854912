import AuthMixin from '@/mixins/Auth';
import { mapGetters } from 'vuex';
import DashboardMenu from '@/components/Menus/DashboardMenu/DashboardMenu.vue';
import DashboardBtn from '@/components/Menus/DashboardMenu/DashboardBtn.vue';
import Hero from "@/components/Hero/Hero.vue";

export default {
    name: 'Tools',

    components: {
        DashboardMenu,
        DashboardBtn,
        Hero,
    },

    mixins: [
        AuthMixin,
    ],

    computed: {
        ...mapGetters({
            userDisplayName: 'auth/userDisplayName',
        }),
    },

    async created() {
        await this.allowOnlyRoles(['rail_admin', 'rail_manager', 'tracker_admin', 'tracker_manager']);
    },
}