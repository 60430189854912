<template>
  <v-card>
    <v-card-title class="headline">
      {{ title }}
    </v-card-title>
    <v-card-text>
      {{ subtitle }}
    </v-card-text>
    <v-card-text
      v-if="hasLoaded && chartData.length == 0"
      class="text-center display-3 font-weight-thin"
    >
      Sem dados disponíveis.
    </v-card-text>

    <!-- Loader -->
    <v-row align="center" justify="center">
      <v-col class="text-center">
        <v-progress-circular
          v-if="hasLoaded == false"
          size="50"
          indeterminate
          color="accent"
        />
      </v-col>
    </v-row>

    <!-- Chart -->
    <GChart
      v-if="hasLoaded && chartData.length > 1"
      :settings="{ packages: ['line'] }"
      :createChart="(el, google) => new google.charts.Line(el)"
      :data="chartData"
      :options="chartOptions"
      style="min-height: 500px; max-width: 90%"
      class="mx-auto py-5"
    />
  </v-card>
</template>

<script>
/**
 * The data populating this component should be held and managed by it's parent.
 * On 'created()', this component emits a 'getData' event, which should trigger the function
 * fetching the data.
 * 
 * In case there are multiple charts on the same page, the following procedure should be followed:
 * - The parent executed a function 'fetchData()' which loads the general chart data into a variable 'generalChartData'.
 * - All charts' 'getData' event link to a function 'loadData()' which in turn executes each of the page's
 *   charts data loading method (loadChart1(), loadChart2(), ...).
 * - Each of these 'loadChartX()' functions:
 *    - Verifies if the 'generalChartData' variable is populated. Calls function 'fetchData()' otherwise;
 *    - Validates the no data scenario;
 *    - Adds headers to chart;
 *    - Adds data to chart;
 *    - Sets chart status to loaded;
 * 
 * For an implementation example, see /src/views/VideoStatistics/VideoStatistics.js.
 */

import { GChart } from 'vue-google-charts';
import DataMixin from '@/mixins/Data';

export default {
  name: 'LineChart_Dual_Y',

  props: {
    dateFrom: { type: String, required: false, default: '' },
    dateTo: { type: String, required: false, default: '' },
    filterType: { type: String, required: false, default: '' },
    filterValue: { type: [String, Number], required: false, default: '' },
    chartData: { type: Array, required: true, default: null },
    title: { type: String, required: true, default: '' },
    subtitle: { type: String, required: true, default: '' },
    hasLoaded: { type: Boolean, required: true, default: false },
  },

  components: {
    GChart,
  },

  mixins: [DataMixin],

  computed: {},

  watch: {
    dateFrom: function () {
      this.initialize();
    },
    dateTo: function () {
      this.initialize();
    },
    filterValue: function () {
      this.initialize();
    },
  },

  data() {
    return {
      chartOptions: {
        series: {
          0: { axis: 'number' },
        },
        axes: {
          y: {
            number: { label: 'Num. video requests / Num. reports received' },
          },
        },
        legend: { position: 'none' },
      },
    };
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.$emit('getData');
    }
  },
};
</script>

<style scoped>
</style>