import { mapGetters } from "vuex";


export default {
    name: 'AlertApplicationEvaluation',

    props: {
        stage: { type: Number, required: true, default: 1 }
    },

    computed: {
        ...mapGetters({
            selectedApplication: 'applications/selectedApplication'
        })
    },

    methods: {
        getAlertType() {
            if ( this.selectedApplication.stage_applications > this.stage || this.selectedApplication.status_applications == 2 ) return 'success'
            else return 'error'
        }
    }
}