import DataMixin from '@/mixins/Data.vue';

export default {
  name: 'DatePickerNavigator',

  mixins: [
    DataMixin,
  ],

  props: {
    value: {
      type: Array,
      required: false,
      default: () => []
    },
    label: {
      type: String,
      required: false,
      default: ''
    },
    rules: {
      type: Array,
      required: false,
      default: () => [(v) => !!v || 'Por favor selecione uma data']
    },
    hideDetails: {
      type: Boolean,
      required: false,
      default: false
    },
    dense: {
      type: Boolean,
      required: false,
      default: false
    },
    outlined: {
      type: Boolean,
      required: false,
      default: true
    },
    type: {
      type: String, // month - year
      required: false,
      default: 'year'
    },
  },

  data() {
    return this.initialState();
  },

  computed: {
    // Synthetic v-model
    computedValue: {
      get: function () {
        return this.finalValue;
      },
      set: function (newValue) {
        this.finalValue = newValue;
        this.emitChange();
      }
    },

    dateNavigatorText() {
      if (!this._.isEmpty(this.computedValue)) {
        if (this.type == 'year') {
          return this.$moment(this.computedValue[0]).format('YYYY');
        } else {
          return this.$moment(this.computedValue[0]).format('YYYY-MM');
        }
      }
    },
  },

  watch: {
    // Synthetic v-model
    value: function (newValue) {
      this.computedValue = newValue;
    },
  },

  created() {
    // Populate with default values
    this.populateDefault();
  },

  methods: {
    initialState() {
      return {
        menu: false,
        date: [this.$moment().format('YYYY-MM-DD'), this.$moment().format('YYYY-MM-DD')],
        finalValue: null,
      }
    },

    populateDefault() {
      // Save data passed to this component via v-model
      if (!this._.isEmpty(this.value)) {
        this.computedValue = this.date;
      }

    },

    emitChange() {
      this.$emit('input', this.computedValue);
      this.$emit('change', this.computedValue);
    },

    /**
     * @param {String} type 
     */
    previousEvent(type) {
      let currentDate = this.$moment(this.computedValue[0], 'YYYY-MM-DD');
    
      // Subtract 1 year from the current date
      let previousDate = this.$moment(currentDate).subtract(1, type);
    
      // First day and first month of the previous year
      let firstDayFirstMonth = previousDate.clone().startOf(type);
    
      // Last day and last month of the previous year
      let lastDayLastMonth = previousDate.clone().endOf(type);
    
      // Format the dates
      let firstDayFirstMonthFormatted = firstDayFirstMonth.format('YYYY-MM-DD');
      let lastDayLastMonthFormatted = lastDayLastMonth.format('YYYY-MM-DD');
    
      this.computedValue = [
        firstDayFirstMonthFormatted,
        lastDayLastMonthFormatted
      ];
    },
    
    /**
     * @param {String} type 
     */
    nextEvent(type) {
      let currentDate = this.$moment(this.computedValue[0], 'YYYY-MM-DD');
    
      // Add 1 year to the current date
      let nextDate = this.$moment(currentDate).add(1, type);
    
      // First day and first month of the next year
      let firstDayFirstMonth = nextDate.clone().startOf(type);
    
      // Last day and last month of the next year
      let lastDayLastMonth = nextDate.clone().endOf(type);
    
      // Format the dates
      let firstDayFirstMonthFormatted = firstDayFirstMonth.format('YYYY-MM-DD');
      let lastDayLastMonthFormatted = lastDayLastMonth.format('YYYY-MM-DD');

      this.computedValue = [
        firstDayFirstMonthFormatted,
        lastDayLastMonthFormatted
      ];
    }
  }
}
