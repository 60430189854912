import { mapGetters } from 'vuex';
import EmptyStateNoData from '@/components/EmptyStateNoData';

export default {
    name: 'PieChartProfitPerNetwork',

    components: {
        EmptyStateNoData,
    },

	props: {
		title: {
			type: String,
			required: false,
			default: 'Lucro por sala',
		},
		subtitle: {
			type: String,
			required: false,
			default: '',
		},
		data: {
			type: Array,
			required: false,
			default: () => [],
		},
		labels: {
			type: Array,
			required: false,
			default: () => [],
		},
		height: {
			type: String,
			required: false,
			default: '400px',
        },
        loading: {
            type: Boolean,
            required: false,
            default: false
        },
	},

    // Setup vars used in the graph so it auto-updates if the data changes
    computed: {
        ...mapGetters({
            themeDarkMode: 'UI/themeDarkMode',
            loadingDataProfitPerNetwork: 'railStatistics/loadingDataProfitPerNetwork',
            dataProfitPerNetwork: 'railStatistics/dataProfitPerNetwork',
            networks: 'networks/networks',
            heroDatePickerValue: 'UI/heroDatePickerValue',
        }),

        series: function () {
            return [44, 55, 41, 17, 15]
            // return this.dataProfitPerNetworkFormatted;
        },
        options: function () {
            return {
                chart: {
                    type: 'donut',
                    height: 200,
                  },
                responsive: [{
                    breakpoint: 600,
                    options: {
                        legend: {
                            position: 'bottom'
                        }
                    }
                }],
                plotOptions: {
                    pie: {
                      donut: {
                        size: '70%',
                        labels: {
                          show: true,
                          name: {
                            show: true,
                            offsetY: -8,
                          },
                          value: {
                            show: true,
                            offsetY: 16,
                          },
                          total: {
                            show: true,
                            showAlways: false,
                            label: 'Total de jogos',
                          }
                        }
                      },      
                    }
                  }
                
            }
            // return {
            //     chart: {
            //         type: 'bar',
            //         height: 440,
            //         stacked: false,
            //         toolbar: {
            //             show: true,
            //             tools: {
            //               download: false
            //             }
            //         }
            //     },
            //     colors: ['#4CAF50', '#FF5252'],
            //     plotOptions: {
            //     bar: {
            //         horizontal: true,
            //         borderRadius: 8,
            //         barHeight: '100%',
            //         dataLabels: {
            //             position: 'bottom'
            //             },
            //         colors: {
            //             ranges: [{
            //                 from: 0,
            //                 to: 9999999,
            //                 color: '#4CAF50'
            //             }, {
            //                 from: -9999999,
            //                 to: 0,
            //                 color: '#FF5252'
            //             }]
            //         },
            //         columnWidth: '100%',
            //     },
            //     },
            //     dataLabels: {
            //         enabled: false
            //     },
            //     stroke: {
            //     width: 1,
            //     colors: ["#fff"]
            //     },
                
            //     grid: {
            //         xaxis: {
            //             lines: {
            //             show: true
            //             }
            //         }
            //     },
            //     yaxis: {           
            //         labels: {
            //             formatter: function (val) {
            //                 return val;
            //             },
            //         },
            //     },
            //     xaxis: {
            //         type: 'category',
            //         categories: this.getCategoriesNetworkName(),
            //         title: {
            //             text: ''
            //         },
            //         labels: {
            //             formatter: (val) => this.handleNumberName(val)
            //         }
            //     },
            //     tooltip: {
            //         shared: false,
            //         intersect: true,
            //         x: {
            //             formatter: undefined,
            //             formatter: (series, item) => {
            //                 let dataValue = item.series[item.seriesIndex][item.dataPointIndex]
            //                 let findNetworkName = this.dataProfitPerNetworkFormatted.find(e => e.data == dataValue)
            //                 return findNetworkName.name;
            //             }
            //         },
            //         y: {
            //             formatter: undefined,
            //             title: {
            //                 formatter: () => 'Lucro: ',
            //             },
            //         }
            //     },
            //     legend: {
            //         show: false,
            //         showForSingleSeries: false,
            //         showForNullSeries: false,
            //         showForZeroSeries: false,
            //         position: 'right',
            //         horizontalAlign: 'center', 
            //         floating: false,
            //         fontSize: '14px',
            //         fontFamily: 'Helvetica, Arial',
            //         fontWeight: 400,
            //         formatter: undefined,
            //         inverseOrder: false,
            //         width: undefined,
            //         height: undefined,
            //         tooltipHoverFormatter: undefined,
            //         customLegendItems: [],
            //         offsetX: 0,
            //         offsetY: 0,
            //         labels: {
            //             colors: undefined,
            //             useSeriesColors: false
            //         },
            //         markers: {
            //             width: 12,
            //             height: 12,
            //             strokeWidth: 0,
            //             strokeColor: '#fff',
            //             fillColors: undefined,
            //             radius: 12,
            //             customHTML: undefined,
            //             onClick: undefined,
            //             offsetX: 0,
            //             offsetY: 0
            //         },
            //         itemMargin: {
            //             horizontal: 5,
            //             vertical: 0
            //         },
            //         onItemClick: {
            //             toggleDataSeries: true
            //         },
            //         onItemHover: {
            //             highlightDataSeries: true
            //         },
            //     }
            // }
        },

        dataProfitPerNetworkFormatted: function () {
            let output = [];

            if (!this._.isEmpty(this.dataProfitPerNetwork)) {
                output = this.dataProfitPerNetwork.map(e => {
                    return {
                        name: this.getNetworkNameByid(e.network),
                        data: [e.pnl.EUR.value],
                        id: e.network,
                    }
                })
                return output;
            }
        },
    },

    watch: {
        heroDatePickerValue: function () {
            this.fetchData();
        },
    },

    data() {
        return this.initialState();
    },

    created() {
        this.fetchData();
    },

    methods: {
        initialState() {
            return {};
        },

        async handleBarClick(event, chartContext, config) {
            const seriesIndex = config.seriesIndex;

            if (seriesIndex >= 0 && seriesIndex < this.dataProfitPerNetworkFormatted.length) {
                const clickedDataValue = this.dataProfitPerNetworkFormatted[seriesIndex].id;
                await this.$router.push({ path: '/networks/' + clickedDataValue,
                query: {
                    dateBegin: this.$route.query.dateBegin ? this.$route.query.dateBegin : '',
                    dateEnd: this.$route.query.dateEnd ? this.$route.query.dateEnd : ''
                } });
            }
        },

        async fetchData() {
            // get networks list
            if (this._.isEmpty(this.networks)) {
                await this.$store.dispatch('networks/get');
            }

            let payload = {
                params: {
                    page: 1,
                    itemsPerPage: -1,
                    type: 1,
                }
            }
            // Add date params
            if (!this._.isEmpty(this.heroDatePickerValue)) {
                payload.params.dateBegin = this.heroDatePickerValue[0];
                payload.params.dateEnd = this.heroDatePickerValue[1];
            }
            
            this.$store.dispatch('railStatistics/getStatisticsProfitPerNetwork', payload);
        },

        getNetworkNameByid(networkID) {
            let output = 'Network desconhecida';

            if (!this._.isEmpty(this.networks)) {
                output = this.networks.find(e => e.id == networkID);
            }

            if (!this._.isUndefined(output)) {
                output = output.name;
            }

            return output;
        },

        getCategoriesNetworkName() {
            let output = [];
            if (!this._.isUndefined(this.dataProfitPerNetworkFormatted) ) {
                output = this.dataProfitPerNetworkFormatted.map(e => e.name);
            }
            return output;
        },
        
        /**
         * Transforms number (Ex: 1000 turns into 1k)
         * @param {Number} number 
         * @returns {String}
         */
        handleNumberName(number) {
            var letters = ["", "k", "M", "G", "T", "P", "E"];

            // what tier? (determines SI symbol)
            var tier = Math.log10(Math.abs(number)) / 3 | 0;

            // if zero, we don't need a suffix
            if(tier == 0) return number;

            // get suffix and determine scale
            var suffix = letters[tier];
            var scale = Math.pow(10, tier * 3);

            // scale the number
            var scaled = number / scale;

            // format number and add suffix
            return scaled.toFixed(1) + suffix;
        }
    },
}