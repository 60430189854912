import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth.vue';
import RailMixin from '@/mixins/Rail.vue';
import Loading from '@/views/Loading.vue';
import Hero from "@/components/Hero/Hero.vue";
import Snackbar from "@/components/Snackbar";
import SearchLive from '@/components/SearchLive/SearchLive.vue';
import Breadcrumb from '@/components/Hero/Breadcrumb/Breadcrumb.vue';
import DialogNoteDetails from '@/components/Dialog/DialogNoteDetails/DialogNoteDetails.vue';


export default {
    name: 'LiveSearch',

    mixins: [
      AuthMixin,
      RailMixin,
    ],

    components: {
        Loading,
        Hero,
        Snackbar,
        SearchLive,
		Breadcrumb,
		DialogNoteDetails,
    },

    computed: {
      ...mapGetters({
          userType: 'auth/userType',
          userRoles: 'auth/userRoles',
      }),
      
      breadcrumbItems() {
        return this.generateBreadcrumbPaths();
      },
    },

    created() {
      this.initialize();
    },

    data() {
      return this.initialState();
    },

    methods: {
      initialize() {
        return;
      },

      initialState() {
        return {
          search: null,
          paths: [{ text: 'Home', to: '/' }],
          link: {
            eventsLive: '/live/events/',
            tournamentsLive: '/live/tournaments/',
            playersLive: '/live/players/',
			teamsLive: '/live/teams/',
			notesLive: '/live/notes/',
			liveNow: '/live/now/',
			},
			dialogues: {
				viewNote: false,
			},
        };
      },
      
    removeBranches(paths, currentPath) {
      let newPaths = paths.filter(path => currentPath.includes(path.to) || path.to.includes('?'))
      return newPaths
    },

	generateBreadcrumbPaths() {
		const allRoutes = this.$router.options.routes
		

		const routeSegments = this.$route.path.split('/').filter(segment => segment !== '');
		const routeMatchedPath = this.$route.matched[0].path;

		let currentPath = '';

		const thisRouteHasParams = routeMatchedPath.includes(':')
		
		for (let i = 0; i < routeSegments.length; i++) {
			currentPath += `/${routeSegments[i]}`;

			let text = allRoutes.find((route) => {
				let routePath = route.path;
				const routeEndsWithSlash = route.path.length > 2 && route.path.endsWith('/');

				if(routeEndsWithSlash) {
					routePath = routePath.slice(0, -1);
				}

				const routeHasParam = routePath.includes(':');				

				if (thisRouteHasParams && routeHasParam && this.showParamsUrl) {
					const [params] = Object.keys(this.$route.params)
					
					let newPath = `${routePath}` 
					
					if (routePath.includes(`:${params}`)) {
						newPath = newPath.replace(`:${params}`, this.$route.params[params])
					}

					return newPath === currentPath	
				}
				
				return routePath === currentPath
			});

			if (!text) {
				text = allRoutes.find((route) => {
					return route.path === this.$route.matched[0].path;
				})
			}
			const dateRegex = /\d{4}-\d{2}-\d{2}/; 
			if(!currentPath.endsWith('/date') && !(currentPath === '/videos') && !dateRegex.test(currentPath) && !(currentPath.endsWith('me'))){
				const pathItem = {
					text: text.name,
					to: currentPath
				};
				this.paths.push(pathItem);
			}
		}

		const finalPaths = [];
		const seenPaths = {};

		for (let obj of this.paths) {
			const path = obj.to;

			if (!seenPaths[path] && !path.includes('?')) {
				seenPaths[path] = true;
				finalPaths.push(obj);
			}
		}

		this.paths = finalPaths;

		if(Object.keys(this.$route.query).length > 0){
			const params = new URLSearchParams(this.$route.query).toString();
			this.paths[this.paths.length-1].to += `?${params}`
		}

		this.paths = this.removeBranches(this.paths, currentPath);

		this.paths = this.paths.map(element => {
			if (element.text == 'Ficheiro #' || element.text == 'Operação #' || element.text == 'Evento #' || element.text == 'Torneio #' || element.text == 'Equipa #' || element.text == 'Jogador #') {
				return {
					text: element.text + '' + this.$route.params.id,
					to: element.to
				}
			} else {
				return element;
			}
		}) 

		return this.paths;
		},
	
	
  },
}