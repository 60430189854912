import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import StoreMixin from '@/mixins/Store.vue';

Vue.use(Vuex);
Vue.use(StoreMixin);

const state = {
    prizeStructure: null,
    errorPrizeStructure: null,
    humanErrorPrizeStructure: null,
    loadingPrizeStructure: false,
};

const getters = {
    prizeStructure: state => state.prizeStructure,
    errorPrizeStructure: state => state.errorPrizeStructure,
    humanErrorPrizeStructure: state => state.humanErrorPrizeStructure,
    loadingPrizeStructure: state => state.loadingPrizeStructure,
};

const mutations = {
    setPrizeStructure: (state, payload) => { state.prizeStructure = payload; },
    setErrorPrizeStructure: (state, payload) => { state.errorPrizeStructure = payload; },
    setHumanErrorPrizeStructure: (state, payload) => { state.humanErrorPrizeStructure = payload; },
    setLoading: (state, payload) => { state.loadingPrizeStructure = payload; },
    setError: (state, payload) => { state.error = payload; },
    setHumanError: (state, payload) => { state.humanError = payload; },
};

const actions = {
    async getPrizeStructure({ commit, dispatch }, payload) {
        dispatch('reset');
        commit('setLoading', true);

        // Configure request
        let request_url = Vue.prototype.$ctms_api;

        // Build URL with querystring
        request_url = StoreMixin.methods.generateQueryParamsUrl(request_url, payload);

        // Execute request & return
        let output = false;

        return axios.get(request_url)
        .then(function (response) {
            console.log(response);

            output = response.data.success;

            if (output) {
                commit('setPrizeStructure', response.data.data);
            } else {
                commit('setError', response.data.message);
                commit('setHumanError', response.data.human_message);
            }
        })
        .catch(function (error) {
            commit('setErrorPrizeStructure', error.response ? error.response.data.message : 'Network error');
            commit('setHumanErrorPrizeStructure', 'Ocorreu um erro a obter os dados do torneio.');
            output = false;
        })
        .then(function () {
            commit('setLoading', false);
            return output;
        });
    },

    /**
     * Reset to initial state
     */
    reset({commit, dispatch}) {
        commit('setPrizeStructure', null);
        commit('setLoading', false);
        dispatch('clearError');
    },

    clearError({ commit }) {
        commit('setErrorPrizeStructure', null);
        commit('setHumanErrorPrizeStructure', null);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};