import Vue from 'vue';
import { mapGetters } from 'vuex';
import RailMixin from '@/mixins/Rail.vue';
import DataMixin from '@/mixins/Data.vue';
import AuthMixin from '@/mixins/Auth.vue';
import UserMixin from '@/mixins/User.vue';
import AlertError from '@/components/Alerts/AlertError/AlertError.vue';
import DatePicker from '@/components/DatePicker/DatePicker.vue';
import CardStepper from '@/components/CardStepper/CardStepper.vue';
import CardStepperNotes from '@/components/CardStepper/CardStepperNotes/CardStepperNotes.vue';
import UserDropdown from '@/components/Rail/UserDropdown/UserDropdown.vue';
import NicknameDropdown from '@/components/Rail/NicknameDropdown/NicknameDropdown.vue';
import OperationDropdown from '@/components/Rail/OperationDropdown/OperationDropdown.vue';
import NetworkTransferPicker from '@/components/Rail/NetworkTransferPicker/NetworkTransferPicker.vue';
import DialogUserNotes from '@/components/Rail/Dialog/DialogUserNotes/DialogUserNotes.vue';
import DialogSplitHelperInfo from '@/components/Rail/Dialog/DialogSplitHelperInfo/DialogSplitHelperInfo.vue';
import AlertUserNote from '@/components/Rail/AlertUserNote/AlertUserNote.vue';
import CardStepperNetwork from '@/components/CardStepper/CardStepperNetwork/CardStepperNetwork.vue';

export default {
    name: 'CardRegisterAnyOperation',

    components: {
        AlertError,
        DatePicker,
        CardStepper,
        CardStepperNotes,
        UserDropdown,
        NicknameDropdown,
        OperationDropdown,
        NetworkTransferPicker,
        AlertUserNote,
        DialogUserNotes,
        DialogSplitHelperInfo,
        CardStepperNetwork,
    },

    mixins: [
        RailMixin,
        DataMixin,
        AuthMixin,
        UserMixin,
    ],

    computed: {
        ...mapGetters({
            error: 'railRequests/error',
            humanError: 'railRequests/humanError',
            errorStatistics: 'railStatistics/error',
            humanErrorStatistics: 'railStatistics/humanError',
            themeDarkMode: 'UI/themeDarkMode',
            selectedUser: 'users/selectedUser',
            selectedRequest: 'railRequests/selectedRequest',
            userTrackerId: 'auth/userTrackerId',
            userNotes: 'users/userNotes',
            userTargetNotes: 'users/userTargetNotes',
            selectedUserProfitShare: 'users/selectedUserProfitShare',
            currencies: 'currencies/currencies',
            teamFilter: 'teams/teamFilter',
            userRoles: 'auth/userRoles',
            sourceUserRole: 'users/sourceUserRole',
            targetUserRole: 'users/targetUserRole',
        }),

        computedUser: {
            get: function () {
                return this.form.user;
            },
            set: async function (newValue) {
                if (this.form.user != newValue || this._.isNull(this.form.user)) {
                    this.form.user = newValue;
                    if (this.form.user && this.form.user.id) {
                        
                        await this.fetchUserInfo(this.form.user.id, 'user');

                        if (!this.isUserManagementComputed) {
                            this.fetchUserNotes(this.form.user.id, 'userNotes');
                        }
                    };
                }
            },
        },

        computedTargetUser: {
            get: function () {
                return this.form.targetUser;
            },
            set: async function (newValue) {
                if (this.form.targetUser != newValue || this._.isNull(this.form.targetUser)) {
                    this.form.targetUser = newValue;
                    if (this.form.targetUser && this.form.targetUser.id) {
                        
                        await this.fetchUserInfo(this.form.targetUser.id, 'targetUser');

                        if (!this.isTargetUserManagementComputed) {
                            this.fetchUserNotes(this.form.targetUser.id, 'userTargetNotes');
                        }
                    };
                }
            },
        },

        // Validator : Used in NicknameDropdown in computedUser field
        isUserManagementComputed: {
            get: function () {
                return this.isUserManagement;
            },
            set: function (newValue) {
                this.isUserManagement = newValue;
            },
        },

        // Validator : Used in NicknameDropdown in computedTargetUser field
        isTargetUserManagementComputed: {
            get: function () {
                return this.isTargetUserManagement;
            },
            set: function (newValue) {
                this.isTargetUserManagement = newValue;
            }
        },

        userId: function () {
            return this.computedUser
                ? this.computedUser.id
                : null;
        },

        targetUserId: function () {
            return this.computedTargetUser
                ? this.computedTargetUser.id
                : null;
        },

        excludedOperations: function () {
            return [
                Vue.prototype.$rail_ops.operations.withdraw,
                Vue.prototype.$rail_ops.operations.deposit,
                Vue.prototype.$rail_ops.operations.makeup,
            ];
        },

        userHint: function () {
            if (this.form.operation) {
                switch (this.form.operation.value) {
                    case Vue.prototype.$rail_ops.operations.split:
                        return 'Selecionar jogador a splitar';
                    case Vue.prototype.$rail_ops.operations.bankroll_reduction:
                        return 'Selecionar jogador para reduzir a banca';
                    case Vue.prototype.$rail_ops.operations.bankroll_increment:
                        return 'Selecionar um membro da Gestão ';
                    case Vue.prototype.$rail_ops.operations.transfer_to_self:
                        return 'Quem envia o montante ';
                    case Vue.prototype.$rail_ops.operations.swap:
                        return 'Selecionar jogador que envia';
                }
            }
        },

        targetUserHint: function () {
            if (this.form.operation) {
                switch (this.form.operation.value) {
                    case Vue.prototype.$rail_ops.operations.split:
                        return 'Selecionar um membro da Gestão';
                    case Vue.prototype.$rail_ops.operations.bankroll_reduction:
                        return 'Selecionar um membro da Gestão';
                    case Vue.prototype.$rail_ops.operations.bankroll_increment:
                        return 'Selecionar Jogador a receber';
                    case Vue.prototype.$rail_ops.operations.transfer_to_self:
                        return 'Quem recebe o montante';
                    case Vue.prototype.$rail_ops.operations.swap:
                        return 'Selecionar o Jogador que recebe';
                }
            }
        },

        /**
         * validates if transfersList is populated properly
         * Used in NetworkTransferPicker
         * @returns {Boolean} value
         */
        networkTransferPickerValidator: function () {
            let output = [];
            // remove entries without source, target and amount
            if (!this._.isEmpty(this.form.transfersList)) {
                output = this.form.transfersList.filter(element => {
                    if (element && !this._.isNull(element.source) && !this._.isNull(element.target) && element.amount) {
                        return element;
                    }
                });
            }

            return output.length == this.form.transfersList.length;
        },
    },

    watch: {
        /**
         * Triggers everytime user has changed
         * sets isUserManagementComputed = true
         * clears transfers list
         */
        'form.user': function () {
            this.clearTransfersList();
            this.isUserManagementComputed = true;
        },

        /**
         * Triggers everytime targetUser has changed
         * sets isTargetUserManagementComputed = true
         * clears transfers list
         */
        'form.targetUser': function () {
            this.isTargetUserManagementComputed = true;
            this.clearTransfersList();
        },

        /**
         * Triggers everytime operation type changes
         * Set isAdminHandDisabled, and form.adminHand based on operation type
         * Pre fill user/target user based on operation type
         * Clear CardBalanceProfitUSD and CardBalanceProfitEUR states
         */
        'form.operation': function(){
            if (this.form.operation) {

                // only runs when creating new operation
                if (this._.isNull(this.selectedRequest)) {
                    // clear states before populating them again
                    this.form.user = null;
                    this.form.targetUser = null;
                    this.$store.commit('users/setSelectedUser', null);
                    this.$store.commit('users/setUserNotes', []);
                    if (this.$refs && this.$refs.UserDropdownRef && this.$refs.TargetUserDropdownRef) {
                        this.$refs.UserDropdownRef.$refs.formUserDropdown.resetValidation();
                        this.$refs.TargetUserDropdownRef.$refs.formUserDropdown.resetValidation();
                    }
                }

                switch (this.form.operation.value) {
                    case Vue.prototype.$rail_ops.operations.bankroll_increment:
                        this.form.user = { id: this.userTrackerId }; // Pre fill user (will always be management)
                        this.form.adminHand = true;
                        this.isAdminHandDisabled = true;
                        break;
                    case Vue.prototype.$rail_ops.operations.split:
                    case Vue.prototype.$rail_ops.operations.bankroll_reduction:
                        this.form.targetUser = { id: this.userTrackerId }; // Pre fill target user (will always be management)
                        this.isAdminHandDisabled = false;
                        break;
                    case Vue.prototype.$rail_ops.operations.swap:
                    case Vue.prototype.$rail_ops.operations.transfer_to_self:
                        this.form.adminHand = true;
                        this.isAdminHandDisabled = true;
                        break;
                    default:
                        this.isAdminHandDisabled = false;
                        break;
                }

                // clear states
                this.$store.commit('railOverview/setCardBalanceProfitUSD', null);
                this.$store.commit('railOverview/setCardBalanceProfitEUR', null);
                this.$store.dispatch('railStatistics/clearError');
            }
        },

        selectedRequest: {
            async handler() {

                if (this.selectedRequest) {
                    this.form.date =this.selectedRequest ? this.$moment(this.selectedRequest.updatedAt.date).format('YYYY-MM-DD') : null;
                    this.form.operation = { value: this.selectedRequest.type, name: this.getRailOperationName(this.selectedRequest.type) };
                    this.editOperationDisabledField = true;

                    // ongoing = 0 finalizado | ongoing = 1 a decorrer
                    this.form.adminHand = !this.selectedRequest.ongoing;
    
                    // populate user and targetUser fields
                    await this.populateUserFields();
    
                    // populate transfer fields
                    this.populateTransferFields();
    
                    // populate observation fields
                    this.populateObservationField();
    
                    this.validateStep(1);
                } else {
                    this.editOperationDisabledField = false;
                    this.form.operation = null;
                    this.form.user = null;
                    this.form.targetUser = null;

                    if (this.$refs.OperationDropdownRef) {
                        this.$refs.OperationDropdownRef.reset();
                    }

                    if (this.$refs.UserDropdownRef) {
                        this.$refs.UserDropdownRef.reset();
                    }

                    if (this.$refs.TargetUserDropdownRef) {
                        this.$refs.TargetUserDropdownRef.reset();
                    }
                }
            },
            deep: true,
            immediate: true,
        },

        'form.transfersList': {
            handler() {
                this.handleRealTimeSplit();
            },
            deep: true,
            immediate: true
        },
        'form.requestsList': {
            handler(newValue) {
                this.setSplitTotalCurrency();
                this.showAlertReviewTransfers(newValue);
            },
            deep: true,
            immediate: true
        },
    },

    data() {
        return this.initializeState();
    },

    methods: {
        initializeState() {
            return {
                notes: [],
                stateToPopulate: 'userNotes',
                messageDateAlert: 'Data definida para o dia anterior. Altera-a se pretendes registar esta operação já no dia de hoje.',
                isAdminHandDisabled: false,
                isUserManagement: false,
                isTargetUserManagement: false,
                editOperationDisabledField: false,
                cardTitles: [
                    'Tipo de operação',
                    'Transferências',
                    'Resumo'
                ],
                formValidations: [
                    false,
                    false,
                    true,
                ],
                form: {
                    transfersList: [],
                    requestsList: [],
                    validator: {
                        requestsList: [],
                        showAlertReviewTransfers: false,
                    },
                    user: null,
                    targetUser: null,
                    adminHand: false,
                    date: this.$moment().format('YYYY-MM-DD'),
                    operation: null,
                    observation: null,
                    gyazoLink: null,
                    fileName: [],
                    totalAmountEur: {
                        value: null,
                        currency: null,
                        width: null,
                    },
                    totalAmountUsd: {
                        value: null,
                        currency: null,
                        width: null,
                    },
                    totalAmount: {
                        value: null,
                        currency: null,
                        width: null
                    }
                },
                rules: {
                    user: [
                        v => !!v || 'Por favor selecione um jogador',
                    ],
                    operation: [
                        v => !!v || 'Por favor selecione uma operação',
                    ],
                    NetworkTransferPicker: [
                        v => v && v.length >= 1 || 'Por favor adicione uma transferência.'
                    ],
                    amount: [
                        (v) => !!v || 'Por favor introduza o montante',
                        (v) => !Number.isNaN(v) || 'Insira um valor numérico',
                    ],
                    cardStepperNetwork: [
                        v => (v && v.length > 0) || 'Required!',
                    ],
                },
                dialogues: {
                    userNotes: false,
                    splitHelperInfo: false,
                  },
            }
        },

        /**
         * Validates a specific form step.
         * Assumes form steps are named 'formStep_x' where x is a Int 0 - maxStep-1.
         * @param {Int} step Identifier of the form step to validate.
         * @returns {Void}
         */
        validateStep(step) {
            const formStepRef = 'formStep_' + (step);
            if (this.$refs[formStepRef]) {
                // Update formValidation[step] in a way the DOM will be reactive
                Vue.set(
                    this.formValidations,
                    step - 1,
                    this.$refs[formStepRef].validate()
                );

                // clean errors if exists
                if (this.error) {
                    this.$store.dispatch('railRequests/clearError');
                }
            }
        },

        // get transfersList from NetworkTransferPicker component
        getTransfersList(item) {
            this.form.transfersList = item;
        },

        // clears transfersList
        clearTransfersList() {
            this.form.transfersList = [];
        },

        /**
         * retrieve fields from CardStepperNotes component
         * @param {Object} payload 
         */
        getInfoFromCardStepperNotes(payload) {
            if (payload) {
                this.form.gyazoLink = payload.gyazoLink;
                this.form.fileName = payload.filename;
            }
        },

        // @returns {Array} that will be used in submit()
        generateTransfersList() {
            let output = [];

            if (!this._.isEmpty(this.form.transfersList)) {
                output = this.form.transfersList.map(element => {
                    if (element && !this._.isNull(element.source) && !this._.isNull(element.target)) {
                        return {
                            source: element.source.id,
                            target: element.target.id,
                            amount: element.amount,
                            rate: element.rate,
                            finalized: true,
                        }
                    }
                })
            }
            return output;
        },

        /**
         * creates an array with source and amount values based on form.requestsList
         * @returns {Array}
         */
        handleRequestSplit() {
            let requests = [];
            if (!this._.isEmpty(this.form.requestsList)) {

                this.form.requestsList.forEach(element => {
                        requests.push({
                            source: element.wallet,
                            amount: element.balance
                        })
                })
            }

            return requests;
        },

        /**
         * used to show split alert, if has value it means that will have a split
         * @returns total split value
         */
        splitTotalAmountAlert() {       
            let splitMap = !this._.isEmpty(this.handleRequestSplit()) ? this.handleRequestSplit().map(e => e.amount) : [];
            let totalSplit = this._.sum(splitMap);

            return totalSplit;
        },

        /**
         * handles UC - Split by showing the split amount in eur and usd currency
         */
        setSplitTotalCurrency() {

            // array with amount values in Eur
            let splitMapEur = !this._.isEmpty(this.handleRequestSplit())
                ? this.handleRequestSplit().map(e => e.source && (e.source.network.currency.id == Vue.prototype.$currency_ids.EUR) ? e.amount.value : 0)
                : [];
            
            // array with amount values in Usd
            let splitMapUsd = !this._.isEmpty(this.handleRequestSplit())
                ? this.handleRequestSplit().map(e => e.source && (e.source.network.currency.id == Vue.prototype.$currency_ids.USD) ? e.amount.value : 0)
                : [];
            
            // sets total amount EUR and USD
            this.form.totalAmountEur = { value: this._.sum(splitMapEur), currency: '€' };
            this.form.totalAmountUsd = { value: this._.sum(splitMapUsd), currency: '$' };

            if (this.form.totalAmountEur.value && this.form.totalAmountUsd.value) {
                this.form.totalAmount = { value: parseFloat(this.form.totalAmountEur.value + (this.form.totalAmountUsd.value * this.currencies.USDEUR.rate)), currency: '€' };
            } else if (this.form.totalAmountEur.value) {
                this.form.totalAmount = { value: this.form.totalAmountEur.value, currency: '€' };
            } else {
                this.form.totalAmount = { value: this.form.totalAmountUsd.value, currency: '$' };
            }

            // set width currency input field
            this.form.totalAmount.width = this.getInputWidth(this.form.totalAmount.value);
            this.form.totalAmountEur.width = this.getInputWidth(this.form.totalAmountEur.value);
            this.form.totalAmountUsd.width = this.getInputWidth(this.form.totalAmountUsd.value);
        },

        submit() {
            if (this.selectedRequest) {
                this.edit();
            } else {
                this.create();
            }
        },

        async create() {
            // Validate
            if (!this.$refs.formRegisterAnyOperation.validate()) {
                return false;
            }

            // Toggle submit button
            this.$refs.CardStepper.toggleSubmitting();

            /**
             * Formulate payload
             */
            let payload = new FormData();
            payload.append('date', this.form.date);
            payload.append('type', this.form.operation.value);
            payload.append('sourceUser', this.computedUser.id);
            payload.append('targetUser', this.computedTargetUser.id);
            payload.append('adminHand', this.form.adminHand);

            // Add 'observation' field if necessary
            if (this.form.observation) {
                payload.append('observation[description]', this.form.observation);
            }

            // Add 'screenshot' field if necessary
            if (!this._.isEmpty(this.form.fileName)) {
                payload.append('screenshot', this.form.fileName[0]);
            }

            // Add 'observation[url]' field if necessary
            if (this.form.gyazoLink) {
                payload.append('observation[url]', this.form.gyazoLink);
            }

            if (!this._.isEmpty(this.form.transfersList)) {
                let transfers = this.generateTransfersList();

                transfers.forEach((element, index) => {
                    let handleRate = element.rate
                        ? element.rate.toString()
                        : null;
                    
                    var transferObject = {
                        source: element.source,
                        target: element.target,
                        amount: element.amount,
                        rate: handleRate,
                    }

                    payload.append('transfers[' + index + '][source]', transferObject.source);
                    payload.append('transfers[' + index + '][target]', transferObject.target);
                    payload.append('transfers[' + index + '][amount]', transferObject.amount);
                    if (transferObject.rate) {
                        payload.append('transfers[' + index + '][exchangeRate]', transferObject.rate);
                    }
                });
            }

            // if Operation type = SPLIT, add requests to payload
            if (this.form.operation && this.form.operation.value == Vue.prototype.$rail_ops.operations.split) {
                let requests = this.handleRequestSplit();

                requests.forEach((element, index) => {
                    payload.append('requests[' + index + '][source]', element.source.id);
                    payload.append('requests[' + index + '][amount]', element.amount.value);
                })
            }

            // Dispatch to store
            let result = await this.$store.dispatch('railRequests/operationCreate', payload);

            // Re-enable btn
            if (this.$refs.CardStepper) {
                this.$refs.CardStepper.toggleSubmitting();
            }

            // On success
            if (result === true) {

                let payloadRefresh = {
                    params: {}
                  }
          
                // if team exists and isManagement, Add team parameter to payload.params
                if (this.teamFilter && this.userHasRailAccessDivisionDropdown) {
                payloadRefresh.params.team = this.teamFilter;
                }
                
                // Refresh Requests Transfers and Balances
                this.updateRequestsTransfersAndBalances(payloadRefresh);

                // Show success snackbar
                this.$store.dispatch('UI/showSnackbar', {
                    message: 'Operação criada com sucesso',
                    color: 'success'
                });

                // clear states
                this.$store.commit('railOverview/setCardBalanceProfitUSD', null);
                this.$store.commit('railOverview/setCardBalanceProfitEUR', null);

                // change hasFinished state in order to change component
                this.$store.dispatch('TabSlider/setHasFinished', true);
                await this.$router.push({ path: '/rail/operations/' + this.selectedRequest.id }).catch(() => { });
            }
        },

        async edit() {
            // Validate
            if (!this.$refs.formRegisterAnyOperation.validate()) {
                return false;
            }

            // Toggle submit button
            this.$refs.CardStepper.toggleSubmitting();

            /**
             * Formulate payload
             */
            let payload = {
                id: this.selectedRequest ? this.selectedRequest.id : null,
            }

            payload.body = new FormData();
            payload.body.append('date', this.form.date);
            payload.body.append('type', this.form.operation.value);
            payload.body.append('sourceUser', this.computedUser.id);
            payload.body.append('targetUser', this.computedTargetUser.id);
            payload.body.append('adminHand', this.form.adminHand);

            // Add 'observation' field if necessary
            if (this.form.observation) {
                payload.body.append('observation[description]', this.form.observation);
            }

            // Add 'screenshot' field if necessary
            if (!this._.isEmpty(this.form.fileName)) {
                payload.body.append('screenshot', this.form.fileName[0]);
            }

            // Add 'observation[url]' field if necessary
            if (this.form.gyazoLink) {
                payload.body.append('observation[url]', this.form.gyazoLink);
            }

            if (!this._.isEmpty(this.form.transfersList)) {
                let transfers = this.generateTransfersList();

                transfers.forEach((element, index) => {
                    let handleRate = element.rate
                        ? element.rate.toString()
                        : null;
                    
                    var transferObject = {
                        source: element.source,
                        target: element.target,
                        amount: element.amount,
                        rate: handleRate,
                    }

                    payload.body.append('transfers[' + index + '][source]', transferObject.source);
                    payload.body.append('transfers[' + index + '][target]', transferObject.target);
                    payload.body.append('transfers[' + index + '][amount]', transferObject.amount);
                    if (transferObject.rate) {
                        payload.body.append('transfers[' + index + '][exchangeRate]', transferObject.rate);
                    }
                });
            }

            // if Operation type = SPLIT, add requests to payload
            if (this.form.operation && this.form.operation.value == Vue.prototype.$rail_ops.operations.split) {
                let requests = this.handleRequestSplit();

                requests.forEach((element, index) => {
                    payload.body.append('requests[' + index + '][source]', element.source.id);
                    payload.body.append('requests[' + index + '][amount]', element.amount.value);
                })
            }


            // Dispatch to store
            let result = await this.$store.dispatch('railRequests/editRailOperation', payload);

            // Re-enable btn
            if (this.$refs.CardStepper) {
                this.$refs.CardStepper.toggleSubmitting();
            }

            // On success
            if (result === true) {

                let payloadRefresh = {
                    params: {}
                  }
          
                // if team exists and isManagement, Add team parameter to payload.params
                if (this.teamFilter && this.userHasRailAccessDivisionDropdown) {
                payloadRefresh.params.team = this.teamFilter;
                }
                
                // Refresh Requests Transfers and Balances
                this.updateRequestsTransfersAndBalances(payloadRefresh);
                
                // Show success snackbar
                this.$store.dispatch('UI/showSnackbar', {
                    message: 'Operação editada com sucesso',
                    color: 'success'
                });

                // clear states
                this.$store.commit('railOverview/setCardBalanceProfitUSD', null);
                this.$store.commit('railOverview/setCardBalanceProfitEUR', null);

                // change hasFinished state in order to change component
                this.$store.dispatch('TabSlider/setHasFinished', true);
                await this.$router.push({path:'/rail/operations/' + this.selectedRequest.id}).catch(() => { }); 
            }
        },

        /**
        * Retrieves user info
        * @param {Number} userId 
        * @param {String} userField Expects: ('user', 'targetUser')
        */
        async fetchUser(userId, userField) {
            // Validate if is necessary to make a request by userField
            let isRequestNecessary = this.checkUserField(userField);

            // if userId exists, call API to retrieve full userDTO
            if (userId && isRequestNecessary) {
                await this.$store.dispatch('users/getUser', userId);
            };
        },

        /**
         * retrieves user roles and populates some computed properties based on userId and userField parameters
         * @param {Number|String} userId 
         * @param {String} userField Expects: ('user', 'targetUser')
         */
        async fetchUserRoles(userId, userField) {
            // Validate if is necessary to make a request by userField
            let isRequestNecessary = this.checkUserField(userField);
            let stateUserField = userField == 'user' ? 'source' : 'target';
            let payload = {
                id: userId,
                state: stateUserField,
            }

            let result;

            // if userId exists, call API to retrieve full userDTO
            if (userId && isRequestNecessary) {
               result = await this.$store.dispatch('users/getUserRoles', payload);
            };

            if (result === true) {
                if (this.selectedUser) {
                    if (userField == 'user') {
                        this.isUserManagementComputed = ! this._.isEmpty(this._.intersection(this.sourceUserRole, ['rail_admin', 'rail_manager']));
                    };
                    if (userField == 'targetUser') {
                        this.isTargetUserManagementComputed = ! this._.isEmpty(this._.intersection(this.targetUserRole, ['rail_admin', 'rail_manager']));
                    };
                };
            }
        },

        /**
         * retrieves user info and user roles info by userID
         * @param {Number|String} userId 
         * @param {String} userField 
         */
        async fetchUserInfo(userId, userField) {
            await this.fetchUser(userId, userField);
            await this.fetchUserRoles(userId, userField);
        },

        /**
         * used in other functions in order to know if is necessary to make new API Calls() 
         * @param {String} field 
         * @returns {Boolean} 
         */
        checkUserField(field) {
            let output = true;
            if (this.form.operation) {
                switch (this.form.operation.value) {
                    case Vue.prototype.$rail_ops.operations.split:
                    case Vue.prototype.$rail_ops.operations.bankroll_reduction:
                        output = field == 'user';
                        break;
                    case Vue.prototype.$rail_ops.operations.bankroll_increment:
                        output = field == 'targetUser';
                        break;
                    default:
                        output = true;
                        break;
                }
            }
            return output;
        },

        /**
         * Evaluates if is necessary to show a warning if admin doesn't select an External Wallet
         * @returns {Boolean}
         */
        showWarningSplit() {
            let showWarning = false;
            let transfers = this.generateTransfersList();
            if (!this._.isEmpty(transfers) && this.form.operation && (this.form.operation.value == Vue.prototype.$rail_ops.operations.split)) {
                let findExternalWallet = transfers.find(e => e.target == Vue.prototype.$rail_external_wallet_id);
                if (findExternalWallet == undefined) {
                    showWarning = true;
                }
            }
            return showWarning;
        },

        /**
         * Populates computedUser
         * Populates computedTargetUser
         */
        populateUserFields() {
            if (this.selectedRequest) {
                switch (this.selectedRequest.type) {
                    case Vue.prototype.$rail_ops.operations.split:
                    case Vue.prototype.$rail_ops.operations.bankroll_reduction:
                        this.computedUser = this.selectedRequest.user;
                        this.computedTargetUser =  {id: parseInt(this.userTrackerId)};
                        break;
                    case Vue.prototype.$rail_ops.operations.bankroll_increment:
                        this.computedUser = {id: parseInt(this.userTrackerId)};
                        this.computedTargetUser =  this.selectedRequest.targetUser;
                        break;
                    case Vue.prototype.$rail_ops.operations.swap:
                    case Vue.prototype.$rail_ops.operations.transfer_to_self:
                    case Vue.prototype.$rail_ops.operations.transfer_to_player:
                        this.computedUser = this.selectedRequest.user ? this.selectedRequest.user : { id: parseInt(this.userTrackerId) };
                        this.computedTargetUser = this.selectedRequest.targetUser ? this.selectedRequest.targetUser : { id: parseInt(this.userTrackerId) };
                        break;
                    default:
                        break;
                }
            }
        },

        /**
         * Populates form.transfersList
         */
        populateTransferFields() {
            let output = [];

            // Add to output[] transferObjects from selectedRequest.requests
            if (!this._.isEmpty(this.selectedRequest.requests)) {
                this.selectedRequest.requests.map(element => {
                    if (element && !this._.isNull(element.source) && !this._.isNull(element.target)) {
                        let transferObject =  {
                            id: element.nickname.id,
                            network: element.nickname.network.name,
                            currency: element.nickname.network.currency,
                            wallet: element.nickname ? element.nickname : element.wallet,
                            balance: element.amount,
                        }
                        this.form.requestsList.push(transferObject);

                        // only used in edit operation type SPLIT
                        this.form.validator.requestsList.push(transferObject);
                    }
                    
                })
            }

            // Add to output[] transferObjects from selectedRequest.transfers
            if (!this._.isEmpty(this.selectedRequest.transfers)) {
                this.selectedRequest.transfers.map(element => {
                    if (element && !this._.isNull(element.source) && !this._.isNull(element.target)) {
                        let transferObject = {
                            source: element.source,
                            target: element.target,
                            amount: element.amount.value,
                            rate: parseFloat(element.rate),
                            finalized: true,
                        }

                        output.push(transferObject);
                    }
                })
            }

            this.form.transfersList = output;
        },

        /**
         * Shows alert whenever both arrays aren't equal
         * @param {Boolean} newValue 
         * @returns {Boolean} output
         */
        showAlertReviewTransfers(newValue) {
            let output = false;

            if (this.form.operation && (this.form.operation.value == Vue.prototype.$rail_ops.operations.split) && this.selectedRequest) {

                let newValueMap = [];
                let validatorRequestsListMap = [];
                
                // mapping newValues (form.requestsList) in order to be easier to compare
                if (!this._.isEmpty(newValue)) {
                    newValueMap = newValue.map(element => {
                        return {
                            id: element.id,
                            balance: element.balance.value,
                        }
                    })
                }
                // mapping newValues (form.requestsList) in order to be easier to compare
                if (!this._.isEmpty(this.form.validator.requestsList)) {
                    validatorRequestsListMap = this.form.validator.requestsList.map(element => {
                        return {
                            id: element.id,
                            balance: element.balance.value,
                        }
                    })
                }

                // if its equal it means no changes
                output = ! this._.isEqual(newValueMap, validatorRequestsListMap);
            }
            
            // updates validator 
            this.form.validator.showAlertReviewTransfers = output;

            return output;
        },

        /**
         * Populates form.observation
         */
        populateObservationField() {
            let output;

            if (!this._.isEmpty(this.selectedRequest.observations)) {
               output = this.selectedRequest.observations.find(e => e.phase == this.selectedRequest.phase)
            }
            if (typeof output == 'object') {
                this.form.observation = output.description;
            }
        },
      
        /**
         * runs everytime form.requestsList has changed
         * retrieves amounts in USD and EUR that will be discounted in PnL (Table Balances) 
         */
        handleRealTimeSplit() {
            // if requestsList is not empty and operation is SPLIT
            if (!this._.isEmpty(this.form.requestsList) && this.form.operation && this.form.operation.value == Vue.prototype.$rail_ops.operations.split) {

                // filter list to show only "transfers" with currency USD
                let listUsd = this.form.requestsList.filter(e => e.wallet && e.wallet.network && (e.wallet.network.currency.code == 'USD'));

                // if listUsd is not empty
                if (!this._.isEmpty(listUsd)) {
                    // map list Usd in order to be easier to sum values
                    let listUsdMapAmount = listUsd.map(e => e.amount);
                    this.$store.commit('railOverview/setCardBalanceProfitUSD', this._.sum(listUsdMapAmount));
                } else {
                    this.$store.commit('railOverview/setCardBalanceProfitUSD', null);
                }

                // filter list to show only "transfers" with currency EUR
                let listEur = this.form.requestsList.filter(e => e.wallet && e.wallet.network && (e.wallet.network.currency.code == 'EUR'));

                // if listEur is not empty
                if (!this._.isEmpty(listEur)) {
                    // map list Eur in order to be easier to sum values
                    let listEurMapAmount = listEur.map(e => e.amount);
                    this.$store.commit('railOverview/setCardBalanceProfitEUR', this._.sum(listEurMapAmount));
                } else {
                    this.$store.commit('railOverview/setCardBalanceProfitEUR', null);
                }
            }
        },

        /**
         * used in setSplitTotalCurrency() to retrieve the width value
         * @param {Number|String} amount 
         * @returns {Number}
         */
        getInputWidth(amount) {
            let output = '60px';

            if (amount) {
                output = amount.toFixed(2);

                // turn output into String
                output = String(output);

                output = (output.length + 1) * 8.5 + 'px';
            }
            return output;
        },

        /**
         * Receives two parameters and fetch notes from a player
         * @param {Number|String} id 
         * @param {String} state 
         */
        fetchUserNotes(id, state) {
            let payload = {
                id: id,
                populateUserNotes: state == 'userNotes',
            }
    
            if (id) {
                // get user notes
                this.$store.dispatch('users/getUserNotes',payload);
            }
        },
      
        /**
         * Sets stateToPopulate based on state parameter
         * Sets notes based on state parameter
         * @param {String} state 'userNotes' || 'userTargetNotes'
         */
        openDialogUserNotes(state = 'userNotes') {
            this.stateToPopulate = state;
            this.notes = state == 'userNotes'
            ? this.userNotes
            : this.userTargetNotes;
        
            this.dialogues.userNotes = true;
        },
    }
}