import { mapGetters } from 'vuex';

export default {
    name: 'CalenderHome',

    computed: {
        ...mapGetters({
            eventsCalendar: 'eventsCalendar/data',
            eventsCategoriesCalendar: 'eventsCategoriesCalendar/data',
            eventsFormsCalendar: 'eventsFormsCalendar/data',
        }),
    },

    data() {
        return this.initialState();
    },

    methods: {
        initialState() {
            return {
                results: 'Empty'
            };
        },

        init() {
        },

        async getCalendarEvents() {
            await this.$store.dispatch('eventsCalendar/get');
            this.results = this.eventsCalendar;
        },

        async getCalendarEvent(id) {
            await this.$store.dispatch('eventsCalendar/getById', id);
            this.results = this.eventsCalendar;
        },

        async getCalendarEventsCategories() {
            await this.$store.dispatch('eventsCategoriesCalendar/get');
            this.results = this.eventsCategoriesCalendar;
        },

        async getCalendarEventsCategory(id) {
            await this.$store.dispatch('eventsCategoriesCalendar/getById', id);
            this.results = this.eventsCategoriesCalendar;
        },

        async getCalendarEventsForms() {
            await this.$store.dispatch('eventsFormsCalendar/get');
            this.results = this.eventsFormsCalendar;
        },

        // Get single form
        async getCalendarEventsForm(id) {
            await this.$store.dispatch('eventsFormsCalendar/getById', id);
            this.results = this.eventsFormsCalendar;
        },

        // Get forms of a single event 
        async getCalendarEventForms(id) {
            await this.$store.dispatch('eventsFormsCalendar/getFormsByEventId', id);
            this.results = this.eventsFormsCalendar;
        }
    }
}