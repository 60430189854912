var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Hero',{attrs:{"title":"Contratos","message":"Gestão dos prazos de contratos de jogadores.","icon":"mdi-card-account-details-outline"}}),_c('v-container',[_c('Snackbar'),(_vm.dataLoadingFinished)?[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-card',{staticClass:"fill-height"},[_c('v-card-title',{staticClass:"headline"},[_vm._v(" Expiram brevemente ")]),_c('v-card-text',[_vm._v("Contratos a expirar nos próximos 30 dias.")]),(_vm.contracts)?_c('v-data-table',{attrs:{"headers":_vm.tableHeaders,"items":_vm.contracts.expiring_soon,"search":_vm.tables.valid.search,"items-per-page":_vm.tables.valid.itemsPerPage,"footer-props":_vm.tables.valid.footerProps,"loading":_vm.contracts == null,"loading-text":"A carregar..."},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.contractEndDate_users)?[_c('v-chip',{attrs:{"color":_vm.getExpiryColor(item),"dark":""}},[_vm._v(" Expira em "+_vm._s(item.days_to_expiry)+" dias ")])]:[_c('v-chip',{attrs:{"color":"red","dark":""}},[_vm._v(" Informação incompleta ")])]]}},{key:"item.name_users",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":'/players/' + item.id_users + '/'}},[_vm._v(" "+_vm._s(_vm.joinNameWithNickname(item.name_users, item.nickname_users))+" ")])]}},{key:"item.contractEndDate_users",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.contractEndDate_users).toLocaleDateString())+" ")]}}],null,false,2566633611)},[_c('template',{slot:"progress"},[_c('v-progress-linear',{attrs:{"color":"accent","indeterminate":""}})],1)],2):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-card',{attrs:{"height":"100%"}},[_c('v-card-title',{staticClass:"headline"},[_vm._v(" Expirados ")]),_c('v-card-text',[_vm._v("Contratos que ultrapassaram a data de expiração.")]),(_vm.contracts)?_c('v-data-table',{attrs:{"headers":_vm.tableHeaders,"items":_vm.contracts.expired,"search":_vm.tables.expired.search,"items-per-page":_vm.tables.expired.itemsPerPage,"footer-props":_vm.tables.expired.footerProps,"loading":_vm.contracts == null,"loading-text":"A carregar..."},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.contractEndDate_users)?[_c('v-chip',{attrs:{"color":"red","dark":""}},[_vm._v(" Expirado há "+_vm._s(item.days_to_expiry)+" dias ")])]:[_c('v-chip',{attrs:{"color":"red","dark":""}},[_vm._v(" Informação incompleta ")])]]}},{key:"item.name_users",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":'/players/' + item.id_users + '/'}},[_vm._v(" "+_vm._s(_vm.joinNameWithNickname(item.name_users, item.nickname_users))+" ")])]}},{key:"item.contractEndDate_users",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.contractEndDate_users).toLocaleDateString())+" ")]}}],null,false,276264514)},[_c('template',{slot:"progress"},[_c('v-progress-linear',{attrs:{"color":"accent","indeterminate":""}})],1)],2):_vm._e()],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-toolbar',{staticClass:"elevation-0"},[_c('v-tabs',{attrs:{"slot":"extension","background-color":"transparent","color":"accent"},slot:"extension",model:{value:(_vm.tabs.current),callback:function ($$v) {_vm.$set(_vm.tabs, "current", $$v)},expression:"tabs.current"}},_vm._l((_vm.tabs.headers),function(tab){return _c('v-tab',{key:tab,staticClass:"text--primary"},[_vm._v(" "+_vm._s(tab)+" ")])}),1)],1),_c('v-tabs-items',{model:{value:(_vm.tabs.current),callback:function ($$v) {_vm.$set(_vm.tabs, "current", $$v)},expression:"tabs.current"}},[(_vm.contracts)?_c('v-data-table',{attrs:{"headers":_vm.tableHeaders,"items":_vm.filterContracts(),"search":_vm.tables.valid.search,"items-per-page":_vm.tables.valid.itemsPerPage,"footer-props":_vm.tables.valid.footerProps,"loading":_vm.contracts == null,"loading-text":"A carregar..."},scopedSlots:_vm._u([{key:"item.name_users",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":'/players/' + item.id_users + '/'}},[_vm._v(" "+_vm._s(_vm.joinNameWithNickname(item.name_users, item.nickname_users))+" ")])]}},{key:"item.contractEndDate_users",fn:function(ref){
var item = ref.item;
return [(item.contractEndDate_users)?[_vm._v(" "+_vm._s(new Date(item.contractEndDate_users).toLocaleDateString())+" ")]:[_c('v-chip',{attrs:{"color":"red","dark":""}},[_vm._v(" Não definida ")])]]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.contractEndDate_users)?[_c('v-chip',{attrs:{"color":_vm.getExpiryColor(item),"dark":""}},[_vm._v(" Expira em "+_vm._s(item.days_to_expiry)+" dias ")])]:[_c('v-chip',{attrs:{"color":"red","dark":""}},[_vm._v(" Informação incompleta ")])]]}}],null,false,1719714352)},[_c('template',{slot:"progress"},[_c('v-progress-linear',{attrs:{"color":"accent","indeterminate":""}})],1)],2):_vm._e()],1)],1)],1)],1)]:[(_vm.dataLoadingFinished)?_c('AlertNoData',{attrs:{"gender":"o","asset":"contratos"}}):_vm._e()]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }