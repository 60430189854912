import Vue from 'vue';
import { mapGetters } from 'vuex';
import CardStepper from '@/components/CardStepper/CardStepper.vue';
import CardStepperNotes from '@/components/CardStepper/CardStepperNotes/CardStepperNotes.vue';
import CardStepperResume from '@/components/CardStepper/CardStepperResume/CardStepperResume.vue';
import NicknameDropdown from '@/components/Rail/NicknameDropdown/NicknameDropdown.vue';
import UserDropdown from '@/components/Rail/UserDropdown/UserDropdown.vue';
import NetworkDropdown from '@/components/Rail/NetworkDropdown/NetworkDropdown.vue';
import RowTransfer from '@/components/Rail/RowTransfer/RowTransfer.vue';
import InputFinancial from '@/components/InputFinancial/InputFinancial.vue';
import AlertError from '@/components/Alerts/AlertError/AlertError.vue';
import DialogUserNotes from '@/components/Rail/Dialog/DialogUserNotes/DialogUserNotes.vue';
import AlertUserNote from '@/components/Rail/AlertUserNote/AlertUserNote.vue';
import AuthMixin from '@/mixins/Auth.vue';
import RailMixin from '@/mixins/Rail.vue';
import DataMixin from '@/mixins/Data.vue';
import UserMixin from '@/mixins/User.vue';

export default {
  name: 'CardTransferSwap',

  components: {
    CardStepper,
    CardStepperNotes,
    CardStepperResume,
    NicknameDropdown,
    RowTransfer,
    InputFinancial,
    UserDropdown,
    NetworkDropdown,
    AlertError,
    AlertUserNote,
    DialogUserNotes,
  },

  mixins: [
    AuthMixin,
    RailMixin,
    DataMixin,
    UserMixin,
  ],

  computed: {
    ...mapGetters({
      error: 'railRequests/error',
      nicknamesAllMine: 'nicknames/nicknamesAllMine',
      nicknamesAllPlayer: 'nicknames/nicknamesAllPlayer',
      nicknamesError: 'nicknames/error',
      nicknamesHumanError: 'nicknames/humanError',
      humanError: 'railRequests/humanError',
      users: 'users/usersAllActive',
      selectedUser: 'users/selectedUser',
      sourceUserRole: 'users/sourceUserRole',
      targetUserRole: 'users/targetUserRole',
      sourceUserRoleLoading: 'users/sourceUserRoleLoading',
      targetUserRoleLoading: 'users/targetUserRoleLoading',
      userNotes: 'users/userNotes',
      userTargetNotes: 'users/userTargetNotes',
      teamFilter: 'teams/teamFilter',
      userTrackerId: 'auth/userTrackerId',
      selectedRequest: 'railRequests/selectedRequest',
    }),

    /**
     * @returns {String}
     */
    targetNicknameIcon() {
      if (! this.targetNicknameValidation.hasExecuted) {
        return '';
      }

      if (this.targetNicknameValidation.loading) {
        return 'mdi-loading mdi-spin';
      } else {
        if (this.targetNicknameValidation.status == true) {
          return 'mdi-check';
        } else {
          return 'mdi-alert';
        }
      }
    },

    targetNicknameValidationClass() {
      if (! this.targetNicknameValidation.hasExecuted || this.targetNicknameValidation.loading) {
        return '';
      }

      if (this.targetNicknameValidation.status == true) {
        return 'validate';
      } else {
        return 'novalidate';
      }
    },

    nicknameSourcePropsComputed: {
      get: function () {
          return this.nicknameSourceProps;
      },
      set: function (newValue) {
          this.nicknameSourceProps = newValue;
      }
    },

    nicknameTargetPropsComputed: {
      get: function () {
          return this.nicknameTargetProps;
      },
      set: function (newValue) {
          this.nicknameTargetProps = newValue;
      }
    },

  },

	watch: {
    // Update targetNicknameValidation 'error' property from store to local component
		nicknamesHumanError: function (newValue) {
			this.targetNicknameValidation.error = newValue;
    },
    
    // makes API call everytime form.user has changed - used to populate TableBalances
    'form.user': function () {
      if (this.userIsRailManager && this.form.user) {
        this.fetchUserRole(this.form.user.id, 'source');
      }
    },

    // makes API call everytime form.user has changed - used to populate TableBalances
    'form.targetUser': function () {
      if (this.userIsRailManager && this.form.targetUser) {
        this.fetchUserRole(this.form.targetUser.id, 'target');
      }
    },

    'form.walletOrNickname': function () {
      // when this field changes, get networks from user
      this.getFilterTargetUserNetworks();

      if (! this.userIsRailManager) {
        // When this field changes, re-validate the 'target nickname' field
        this.resetTargetNicknameField();
      }
    },
	},

  data() {
    return this.initialState();
  },

  async created() {
    // set some props from CardStepper as soon as CardTransferSwap is created
    this.setCardStepperProps();

    // fetching users
    if (this._.isEmpty(this.users)) {
      await this.fetchUsers();
    }

    // get all networks from user logged in
    if (this._.isEmpty(this.nicknamesAllMine)) {
      await this.$store.dispatch('nicknames/getAllMine');
    }
  },

  methods: {
    initialState() {
      return {
        stateToPopulate: 'userNotes',
        notes: [],
        nicknameSourceProps: {
          type:'ANY',
          scope:'MANAGEMENT',
          user: null,
          alwaysReload: true,
        },
        nicknameTargetProps: {
          type:'ANY',
          scope:'MANAGEMENT',
          user: null,
          alwaysReload: true,
        },
        isUserManagement: false,
        isTargetUserManagement: false,
        form: {
          walletOrNickname: null,
          amount: {
            value: null,
          },
          targetNetwork: null,
          targetUser: null,
          targetNickname: null,
          source: null,
          sameId: false,
          checkbox: false,
          nickname: null,
          user: null,
          observation: null,
          gyazoLink: null,
          fileName: [],
          date: this.$moment()
        },
        rules: {
          userDropdown: [
            (v) => !!v || 'Por favor introduza um jogador'
          ],
          amount: [
            (v) => !!v || 'Por favor introduza o montante',
            (v) => !Number.isNaN(v) || 'Insira um valor numérico',
          ],
          nicknameDropdown: [
            (v) => !!v || 'Por favor introduza uma sala'
          ],
          networkDropdown: [
            (v) => !!v || 'Por favor introduza uma sala'
          ],
          targetNickname: [
            (v) => !!v || 'Por favor introduza um nickname'
          ],
          checkbox: [
            (v) => !!v || '',
          ],
        },
        transfersConfirmationStep: 2,
        resumeStep: 3,
        cardSteps: 3,
        cardTitles: [
          'Detalhes Transferência ',
          'Realizar transferências',
          'Resumo',
        ],
        formValidations: [
          false,
          false,
          true,
        ],
        targetNicknameValidation: {
          hasExecuted: false,
          loading: false,
          status: false,
          error: null,
        },
        dialogues: {
          userNotes: false,
        },
        showSkrillAlertPlayer1: false,
        showSkrillAlertPlayer2: false,
      }
    },

  /**
   * Validates a specific form step.
   * Assumes form steps are named 'formStep_x' where x is a Int 0 - maxStep-1.
   * 
   * @param {Int} step Identifier of the form step to validate.
   * @returns {Void}
   */
    validateStep(step) {
      const formStepRef = 'formStep_' + (step);

      // Reset validation for InputFinancialRef and targetNicknameRef to enforce lazy-validation
      this.resetValidationFormStep1();
      // Reset Checkboxes
      this.resetValidationFormStep2();

      if (this.$refs[formStepRef]) {
        let formStepValidationResult = this.$refs[formStepRef].validate();

        // If step is 1, the 'nickname matches user' validation needs to occurr
        if (! this.userIsRailManager && step == 1) {
          formStepValidationResult = formStepValidationResult && this.targetNicknameValidation.status;
        }

        // clean errors if exists
        if (this.error) {
          this.$store.dispatch('railRequests/clearError');
        }

        // Update formValidation[step] in a way the DOM will be reactive
        Vue.set(
          this.formValidations,
          step - 1,
          formStepValidationResult
        );
      }
    },

    resetValidationFormStep1() {
      if (this.$refs.formStep_1) {
        this.$refs.InputFinancialRef.resetValidation();

        if (this.userIsRailManager && this.$refs) {
          this.$refs.SyntheticNicknameDropdownSource.resetValidation();
          this.$refs.SyntheticNicknameDropdownTarget.resetValidation();
        }

        if (!this.userIsRailManager) {
          this.$refs.targetNicknameRef.resetValidation();
        }
      }
    },

    resetValidationFormStep2() {
      // if is user view resets form.checkbox from transfers
      if (this.$refs.formStep_2 && !this.userIsRailManager) {
        this.$refs.checkbox.resetValidation();
      }
    },

    /**
     * Set bunch of props used by CardStepper Component
     */
    setCardStepperProps() {
      if (this.userIsRailManager) {
        this.cardSteps = 2;
        this.transfersConfirmationStep = null;
        this.resumeStep = this.cardSteps;
        this.cardTitles = [
          'Detalhes Transferência ',
          'Resumo',
        ];
        this.formValidations = [
          false,
          true,
        ];

      } else {
        this.cardSteps = 3;
        this.transfersConfirmationStep = 2;
        this.resumeStep = this.cardSteps;
        this.cardTitles = [
          'Detalhes Transferência ',
          'Realizar transferências',
          'Resumo',
        ];
        this.formValidations = [
          false,
          false,
          true,
        ];
      }
    },

    /**
     * Triggers when enter 2.º step as user and 4.º step (admin and user)
     * @returns boolean var named form.sameId
     */
    checkSourceTargetSameId() {
      // validate if source and target exists
      if (this.form.walletOrNickname && this.form.targetNetwork) {

        let targetNetworkId = this.userIsRailManager ? this.form.targetNetwork.network.id : this.form.targetNetwork.id;

        this.form.sameId = this.form.walletOrNickname.network.id == targetNetworkId;
        
        return this.form.sameId;
      }
    },

    /**
     * Populate form.nickname/form.source based on form.walleltOrNickname field
     * @param {*Object} item 
     */
    populateNetworksAndNickname(item) {
      let isNetwork = item && !item.network.isWallet;
      return isNetwork
        ? this.form.nickname = item
        : this.form.source = item;
    },

    /**
     * Triggers when choosing targetNetwork
     * Only to be displayed in Step 2, transfer's flow in action
     * @param {*Object} form.targetNetwork
     * @returns a equal network with same ID as targetNetwork
     */
    setSourceDisplayWallet(item) {
      if (item && !this._.isEmpty(this.nicknamesAllMine)) {
        let id = this.userIsRailManager ? item.network.id : item.id
          this.form.source = this.nicknamesAllMine.find(element => element.network.id == id)
      }

      // When this field changes, re-validate the 'target nickname' field
      this.validateTargetNickname();
    },


    /**
     * Used in NetworkDropdown (when logged in as User)
     * @returns {Array} with network currency ids
     */
    filterNetworksByCurrency() {
      if (this.form.walletOrNickname) {
        return [this.form.walletOrNickname.network.currency.id];
      }
    },

    /**
     * Used in NetworkDropdown (when logged in as User)
     * @returns an array filled with network ID's;
     */
    getFilterTargetUserNetworks() {
      if (this.form.walletOrNickname) {
        /**
         * output - an array that will be returned filled with network ID's;
         * nicknames - nicknames[] based on user Role;
         * excludedNetwoks - Filter nicknames by wallet with different currency code || filter by networks with different ID as form.walletOrNickname;
         * filteredNetworks - Filter nicknames by wallet with same currency code || filter by networks with same ID as form.walletOrNickname; 
         */

        let output = [];
        let nicknames = this.userIsRailManager ? this.nicknamesAllPlayer : this.nicknamesAllMine;
        let excludedNetwoks = nicknames.filter(e => (e.network.isWallet && e.network.currency.code != this.form.walletOrNickname.network.currency.code) || (!e.network.isWallet && e.network.id != this.form.walletOrNickname.network.id));
        let filteredNetworks = nicknames.filter(e => (e.network.isWallet && e.network.currency.code == this.form.walletOrNickname.network.currency.code) || (!e.network.isWallet && e.network.id == this.form.walletOrNickname.network.id));
  
        if (this.userIsRailManager) {
          nicknames = excludedNetwoks;
        } else {
          nicknames = filteredNetworks;
          }
        // pushes every nicknameID in nicknames[] to output[]
        nicknames.forEach(element => output.push(element.network.id));
        return output;
      }
    },

    /**
    * @returns array passed as prop to RowTransfers Component
    */
    getTransfers() {
      // transfers array
      let transfers = [];

      // prepare target object
      let target = {};

      // if is rail manager
      if (this.userIsRailManager) {
        transfers.push({
          source: this.form.walletOrNickname,
          target: this.form.targetNetwork,
          amount: this.form.amount,
          isPending: !this.checkbox
        })
        return transfers;
      }

      // poppulate target.network with form.targetNetwork
      target.network = this.form.targetNetwork;
      // poppulate target.name with form.targetNickname
      target.name = this.form.targetNickname;

      // if same id, only 1 transfer is necessary
      if (this.checkSourceTargetSameId()) {
        transfers.push({
          source: this.form.walletOrNickname,
          target: target,
          amount: this.form.amount,
          isPending: !this.checkbox
        });
      } else {
         // 1st transfer object - (nickname/network to wallet)
        transfers.push({
          source: this.form.nickname,
          target: this.form.source,
          amount: this.form.amount,
          isPending: !this.checkbox
        });

        // 2nd transfer object - (source user wallet to target user wallet)
        transfers.push({
          source: this.form.source,
          target: target,
          amount: this.form.amount,
          isPending: !this.checkbox
        });
      }
      return transfers;
    },

    // retrieve fields from CardStepperNotes component
    getInfoFromCardStepperNotes(payload) {
      if (payload) {
        this.form.gyazoLink = payload.gyazoLink;
        this.form.fileName = payload.filename;
      }
    },

    async submit() {
      // Validate form
      if (!this.$refs.formTransferSwap.validate()) {
        return false;
      }

      // Toggle submit button
      this.$refs.CardStepper.toggleSubmitting();

      /**
       * Formulate payload formData
       */
      let payload = new FormData();
      payload.append('sourceNickname', this.form.walletOrNickname.id);
      payload.append('targetUser', this.form.targetUser.id);
      payload.append('amount', this.form.amount.value);

      if (this.userIsRailManager) {
        payload.append('sourceUser', this.form.user.id);
        payload.append('targetNetwork', this.form.targetNetwork.network.id);
        payload.append('targetNickname', this.form.targetNetwork.id);
      } else {
        payload.append('targetNetwork', this.form.targetNetwork.id);
        payload.append('targetName', this.form.targetNickname);
      }

      // Add 'observation' field if necessary
      if (this.form.observation) {
        payload.append('observation[description]', this.form.observation);
      }

      if (!this._.isEmpty(this.form.fileName)) {
        payload.append('screenshot', this.form.fileName[0]);
      }

      if (this.form.gyazoLink) {
        payload.append('observation[url]', this.form.gyazoLink);
      }

      /**
       * Dispatch to store
       */

      // Dispatch store action
      let result = await this.$store.dispatch('railRequests/swapCreate', payload);

      // Re-enable btn
      if (this.$refs.CardStepper) {
        this.$refs.CardStepper.toggleSubmitting();
      }

      // On success
      if (result === true) {

        let payloadRefresh = {
          params: {}
        }

        // if team exists and isManagement, Add team parameter to payload.params
        if (this.teamFilter && this.userHasRailAccessDivisionDropdown) {
          payloadRefresh.params.team = this.teamFilter;
        }
        
        // Refresh Requests Transfers and Balances
        this.updateRequestsTransfersAndBalances(payloadRefresh);


        if (this.userIsRailManager) {
          this.updateStatisticsByUserId(this.form.user.id);
          this.updateStatisticsByUserId(this.form.targetUser.id);
        }

        // Refresh swaps
        this.$store.dispatch('railSwaps/get', payloadRefresh);

        // change hasFinished state in order to change component
        this.$store.dispatch('TabSlider/setHasFinished', true);

        let id = this.selectedRequest.id;
        let url = Vue.prototype.$tab_slider.tabs.requests + id;

        // Show success snackbar
        this.$store.dispatch('UI/showSnackbar', {
          message: 'Pedido de swap criado com sucesso.',
          color: 'success'
        });

        // change to operation single view
        this.$router.push({ path: url });
      }
    },

    resetForm() {
      Object.assign(this.$data, this.initialState());
      this.$refs.formTransferSwap.reset();
    },

    validateTargetNicknameHandler() {
      // Save current value
      let lastValue = this.form.targetNickname;

      // If in interval value is the same as the one saved, search
      setTimeout(() => {
        if (lastValue == this.form.targetNickname) {
          this.validateTargetNickname();
        }
      }, 1500);
    },

    async validateTargetNickname() {
      if (this.form.targetUser && this.form.targetNetwork && this.form.targetNickname) {
        // Mark that this has executed atleast once
        this.targetNicknameValidation.hasExecuted = true;

        // Set to loading
        this.targetNicknameValidation.loading = true;

        // Dispatch store action
        this.targetNicknameValidation.status = await this.$store.dispatch('nicknames/validate', {
          targetUser: this.form.targetUser.id,
          targetNetwork: this.form.targetNetwork.id,
          targetName: this.form.targetNickname,
        });

        // Remove loading
        this.targetNicknameValidation.loading = false;

        // Re-run form validation to enable 'Next' button
        this.validateStep(1);
      }
    },

    resetTargetNicknameField() {
      // Reset value
      this.form.targetNickname = null;

      // Reset validation helping function
      this.targetNicknameValidation = {
        hasExecuted: false,
        loading: false,
        status: false,
        error: null,
      };

      // Clear any existing store error from previous validations
      this.$store.dispatch('nicknames/clearError');
    },

    // pre load users 
    fetchUsers() {
      let payload = this.formulateUsersAllUrlParameters();
      this.$store.dispatch('users/getUsersAllActive', payload);
    },
        
    /**
     * Fetches userRole and then populates some fields to be used in NicknameDropdowns component
     * @param {Number|String} userId 
     * @param {String} state 
     */
    async fetchUserRole(userId, state) {
      let payload = {
        state: state,
        id : userId
      }
      let user = {
        roles: null
      }

      // API Call to retrieve user role
      await this.$store.dispatch('users/getUserRoles', payload);

      // if state is  equal to source, populates isUserManagement and nicknameSourcePropsComputed
      if (state == 'source') {
        user.roles = this.sourceUserRole;
        
        // checking user roles
        this.isUserManagement = this.userHasRole(['rail_admin', 'rail_manager'], user.roles);
        
        // setting props for nicknameDropdown
        this.nicknameSourcePropsComputed = {
          type: !this.isUserManagement ? 'PLAYER' : 'ANY',
          scope: !this.isUserManagement ? 'ALL' : 'MANAGEMENT',
          user: !this.isUserManagement && this.form.user ? this.form.user.id : null
        }

        if (!this.isUserManagement) {
          // fetching user data if user is not management
          this.fetchUser(userId);
          this.fetchUserNotes(userId, 'userNotes');

          this.showSkrillAlertPlayer1 = true;
        } else {
          this.showSkrillAlertPlayer1 = false;
        }
      }
      // if state is  equal to target, populates isTargetUserManagement and nicknameTargetPropsComputed
      if (state == 'target') {
        user.roles = this.targetUserRole;
        
        // checking user roles
        this.isTargetUserManagement = this.userHasRole(['rail_admin', 'rail_manager'], user.roles);
        
        // setting props for nicknameDropdown
        this.nicknameTargetPropsComputed = {
          type: !this.isTargetUserManagement ? 'PLAYER' : 'ANY',
          scope: !this.isTargetUserManagement ? 'ALL' : 'MANAGEMENT',
          user: !this.isTargetUserManagement && this.form.targetUser ? this.form.targetUser.id : null
        }

        if (!this.isTargetUserManagement) {
          // fetching user data if user is not management
          this.fetchUser(userId);
          this.fetchUserNotes(userId, 'targetUserNotes');
          
          this.showSkrillAlertPlayer2 = true;
        } else {
          this.showSkrillAlertPlayer2 = false;
        }
      }
    },

    async fetchUser(userId) {
      await this.$store.dispatch('users/getUser', userId);
    },

    /**
     * Receives two parameters and fetch notes from a player
     * @param {Number|String} id 
     * @param {String} state 
     */
    fetchUserNotes(id, state) {

      let payload = {
        id: id,
        populateUserNotes: state == 'userNotes',
      }

      if (id) {
        // get user notes
        this.$store.dispatch('users/getUserNotes',payload);
      }
    },
    
    /**
     * Sets stateToPopulate based on state parameter
     * Sets notes based on state parameter
     * @param {String} state 'userNotes' || 'userTargetNotes'
     */
    openDialogUserNotes(state = 'userNotes') {
      this.stateToPopulate = state;
      this.notes = state == 'userNotes'
        ? this.userNotes
        : this.userTargetNotes;
    
      this.dialogues.userNotes = true;
    },
  }
}
