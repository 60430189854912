import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth';
import AlertError from '@/components/Alerts/AlertError/AlertError.vue';
import MetadataDropdown from '@/components/Rail/MetadataDropdown/MetadataDropdown.vue';

export default {
    name: 'DialogEditNetwork',

    components: {
        AlertError,
        MetadataDropdown,
    },

    props: {
        isOpen: {
            type: Boolean,
            required: true,
            default: false
        },
    },

    mixins: [
        AuthMixin,
    ],

    data() {
        return this.initialState();
    },

    computed: {
        ...mapGetters({
            error: 'networks/error',
            humanError: 'networks/humanError',
            selectedNetwork: 'networks/selectedNetwork',
            networks: 'networks/networks',
            images: 'networks/images',
        }),
    },

    watch: {
        // when re opened,  populate field
        isOpen: function (newVal, oldVal) {
            if (newVal === true) {
                // some networks don't have identifier
                // reset form validation in order to avoid error messages
                this.$refs.formEditNetwork.resetValidation();
                this.populateFields();
            }
        },
        selectedNetwork: function (newVal, oldVal){
            if (newVal != oldVal) {
                this.populateFields();
            }
        }
    },
    
    methods: {
        initialState() {
            return {
                submitting: false,
                nicknameType: [
                    { value: 0, name: 'Sala' },
                    { value: 1, name: 'Carteira' }
                ],
                form: {
                    name: null,
                    identifier: null,
                    currency: null,
                    image: null,
                    isWallet: null,
                    metadata_required: null,
                    metadataOptions: [
                        { id: 1, name: 'name' },
                        { id: 2, name: 'number' },
                        { id: 3, name: 'email' },
                        { id: 4, name: 'addr_btc_btc' },
                        { id: 5, name: 'addr_usdt_erc20' },
                    ]
                },
                rules: {
                    name: [ 
                        v => !!v || 'Por favor introduza um nome para a network',
                    ],
                    identifier: [ 
                        v => !!v || 'Por favor introduza um identificador para a network',
                    ],
                    currency: [ 
                        v => !!v || 'Por favor introduza um tipo de moeda para a network',
                    ],
                    image: [ 
                        v => !!v || 'Por favor introduza uma imagem para a network',
                    ],
                },
            };
        },

        populateFields() {
            if (this.selectedNetwork) {
                this.form.id = this.selectedNetwork.id;
                this.form.name = this.selectedNetwork.name;
                this.form.identifier = this.selectedNetwork.identifier;
                this.form.currency = this.selectedNetwork.currency.id;
                this.form.isWallet = this.selectedNetwork.isWallet ? this.nicknameType[1] : this.nicknameType[0];
                this.form.image = this.selectedNetwork.imageURL;

                if (this.selectedNetwork.metadata_required) {
                    let output = [];
                    let metadata = this.selectedNetwork.metadata_required
                    
                    metadata.forEach(e => {
                        let findElement = this.form.metadataOptions.find(element => e == element.name);
                        output.push(findElement);
                    })

                    this.form.metadata_required = output;
                }
            }
        },

        async submit() {
            if (!this.$refs.formEditNetwork.validate()) {
                return false;
            }

            // Toggle submit button
            this.toggleSubmitting();

            let payload = {
                id: this.form.id,
                body: {
                    name: this.form.name,
                    identifier: this.form.identifier,
                    currencyID: this.form.currency,
                    image: this.form.image,
                    isWallet: this.form.isWallet.value,
                }                    
            }

            if (this.form.isWallet.value) {
                payload.body.identifier = '';
            }

            if (!this._.isEmpty(this.form.metadata_required)) {
                let metadataMapped = this.form.metadata_required.map(e => e.name);

                payload.body.metadataRequired = metadataMapped.join(',');
            } else {
                payload.body.metadataRequired = null;
            }

            let result = await this.$store.dispatch('networks/update', payload);

            // // Re-enable button
            this.toggleSubmitting();

            // On success
            if (result === true) {
                // Show success snackbar
                this.$store.dispatch('UI/showSnackbar', {
                    message: 'Network atualizada com sucesso.',
                    color: 'success'
                });

                // set promiseNetworks to NULL in order to be possible to make new API Request
                this.$store.commit('networks/setPromiseNetworks', null);

                this.$emit('refresh');

                // closes dialog
                this.closeDialog();
            }
        },

        /**
         * Used to disable Submit button
         */
        toggleSubmitting() {
            this.submitting = !this.submitting;
        },

        closeDialog() {
            this.$emit("close-dialog");
            this.resetForm();
        },

        resetForm() {
            Object.assign(this.$data, this.initialState());
            this.$refs.formEditNetwork.reset();
            this.$store.dispatch('networks/clearError');
        }
    },
};
