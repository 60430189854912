import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth';
import AlertError from '@/components/Alerts/AlertError/AlertError.vue';
import DatePicker from '@/components/DatePicker/DatePicker.vue';
import UserDropdown from '@/components/Rail/UserDropdown/UserDropdown.vue';
import EventLiveDropdown from '@/components/EventLiveDropdown/EventLiveDropdown.vue';

export default {
    name: 'DialogAddEditTournament',

    mixins: [
        AuthMixin,
    ],

    components: {
        AlertError,
        DatePicker,
        UserDropdown,
        EventLiveDropdown,
    },

    props: {
      isOpen: {
          type: Boolean,
          required: true,
          default: false
      },
      action: {
			type: String,
			required: true,
			default: 'create',
			validator: function (value) {
				const allowedActions = ['create', 'update']
				return allowedActions.includes(value);
			},
		},
    },

    data() {
        return this.initialState();
    },

    mounted() {
    },

    computed: {
        ...mapGetters({
            selectedEvent: 'liveEvents/selectedEvent',
            selectedTournament: 'liveTournaments/selectedTournament',
            error: 'railSessions/error',
            humanError: 'railSessions/humanError',
        }),
    },

    watch: {
        // when re opened, clean form and populate fields
        isOpen: function (newVal, oldVal) {
            if (newVal === true) {
                this.resetForm();
                this.populateForm();
            }
        },
    },

    methods: {
        initialState() {
            return {
                submitting: false,
                selectedLiveEvent: this.selectedEvent ? this.selectedEvent : null,
                form: {
                    name: null,
                    day: null,
                    starts_at: null,
                    timeStart: null,
                    lastTime: null,
                    late_registration_at: null,
                    currency: null,
                    buyin: null,
                    entries: null,
                    starting_stack: null,
                    participants: null,
                    prize_pool: null,
                    event: null,
                },
                currencyTypes: [
                    {type: 'EUR', label: 'Euro', id: 2, icon: 'mdi-currency-eur'},
                    {type: 'USD', label: 'Dólar', id: 3, icon: 'mdi-currency-usd'},
                ],
                rules: {
                  name: [
                    v => !!v || 'Nome do torneio é obrigatório',
                  ],
                  day: [
                    v => !!v || 'Dia é obrigatório',
                  ],
                  starts_at: [
                    v => !!v || 'Data de início é obrigatória',
                  ],
                  timeStart: [
                    v => !!v || 'Hora de início é obrigatória',
                  ],
                  late_registration_at: [
                    v => !!v || 'Data final é obrigatória',
                  ],
                  lastTime: [
                    v => !!v || 'Hora final é obrigatória',
                  ],
                  currency: [
                    v => !!v || 'Moeda é obrigatória',
                  ],
                  buyin: [
                    v => !!v || 'Buy-in é obrigatório',
                  ],
                  starting_stack: [
                    v => !!v || 'starting_stack é obrigatório',
                  ],
                  participants: [
                    v => !!v || 'Participantes é obrigatório',
                  ],
                  prize_pool: [
                    v => !!v || 'Prémio é obrigatório',
                  ],
                  event: [
                    v => !!v || 'Evento é obrigatório',
                  ],
                  entries: [
                    v => !!v || 'Entradas é obrigatório',
                  ]
                }
            };
        },
        
        dialogTitle() {
            return this.action == 'create' ? 'Adicionar torneio live' : 'Editar torneio live';
        },

        populateForm() {
            if (this.action == 'update' && this.selectedTournament) {
              this.selectedLiveEvent = this.selectedTournament.event;
              this.form.name = this.selectedTournament.name;
              this.form.day = this.selectedTournament.number;
              this.form.starts_at = this.$moment(this.selectedTournament.starts_at).format('YYYY-MM-DD');
              this.form.late_registration_at = this.$moment(this.selectedTournament.late_registration_at).format('YYYY-MM-DD');
              this.form.timeStart = this.splitDateTime(this.selectedTournament.starts_at, 'time');
              this.form.lastTime = this.splitDateTime(this.selectedTournament.late_registration_at, 'time');
              this.form.currency = this.selectedTournament.currency;
              this.form.buyin = this.selectedTournament.buyin;
              this.form.entries = this.selectedTournament.entries;
              this.form.starting_stack = this.selectedTournament.starting_stack;
              this.form.participants = this.selectedTournament.participants;
              this.form.prize_pool = this.selectedTournament.prize_pool;
            }
        },

        getCurrencyIcon() {
          return this.form.currency === 'USD' ? 'mdi-currency-usd' : 'mdi-currency-eur';
        },

        openDeleteDialog() {
            let id = 2
            this.$emit('open-delete-dialog', id);
        },

        async fetchData() {
        },

        combineDateTime(date, time) {
          let momentObj = this.$moment(date);
          let timeParts = time.split(':');

          momentObj.set({
            hour: timeParts[0],
            minute: timeParts[1],
            second: 0,
            millisecond: 0
          });

          return momentObj.toISOString();
        },

        splitDateTime(dateTime, action = 'date') {
          if (!dateTime) {
            return null;
          }
        
          const momentObj = this.$moment(dateTime);
        
          const formats = {
            date: 'YYYY-MM-DD',
            time: 'HH:mm',
          };
        
          const format = formats[action];
        
          return format ? momentObj.format(format) : null;
        },

        async submit() {
            let isValid = true;

            if (!this.$refs.formAddEditTournament.validate()) {
                isValid = false;
            }

            if (!this.$refs.EventLiveDropdown.$refs.formEventLiveDropdown.validate()) {
                isValid = false;
            }

            if (!isValid) {
              return false;
            }

            let { starts_at, timeStart, late_registration_at, lastTime } = this.form;

            // Toggle submit button
            this.toggleSubmitting();

            let payload = {
                event: this.selectedLiveEvent,
                name: this.form.name,
                number: Number(this.form.day),
                starts_at: this.combineDateTime(starts_at, timeStart),
                late_registration_at: this.combineDateTime(late_registration_at, lastTime),
                buyin: Number(this.form.buyin),
                prize_pool: Number(this.form.prize_pool),
                starting_stack: Number(this.form.starting_stack),
                participants: Number(this.form.participants),
                structure: '',
                currency: this.form.currency,
                entries: Number(this.form.entries),
            }
            
            if (this.action == 'update') {
                payload.id = this.selectedTournament.id;
            }

            let actionToDispatch = this.action == 'create'
            ? 'liveTournaments/createTournament'
            : 'liveTournaments/updateTournament';
          
            let messageSnackbar = this.action == 'create'
            ? 'torneio adicionado com sucesso.'
            : 'torneio editado com sucesso.'

            let result = await this.$store.dispatch(actionToDispatch, payload);

            // // Re-enable button
            this.toggleSubmitting();

            // On success
            if (result === true) {
                this.$store.dispatch('UI/showSnackbar', {
                    message: messageSnackbar,
                    color: 'success'
                });

                // refresh
                this.$emit('reload');
                this.closeDialog();
            }
        },

        /**
         * Used to disable Submit button
         */
        toggleSubmitting() {
            this.submitting = !this.submitting;
        },

        closeDialog() {
            this.$emit("close-dialog");
            this.resetForm();
        },

        resetForm() {
            Object.assign(this.$data, this.initialState());
            if (this.$refs.formAddEditTournament) {
                this.$refs.formAddEditTournament.reset();
            }

            if (this.$refs.EventLiveDropdown) {
                this.$refs.EventLiveDropdown.reset();
            }

            // clear errors
            this.$store.dispatch('railSessions/clearError');
        },
    }
}