import AuthMixin from '@/mixins/Auth';
import RailMixin from '@/mixins/Rail';
import DragAndDropInputFile from '@/components/DragAndDropInputFile/DragAndDropInputFile.vue';

export default {
    name: 'CardStepperNotes',

    components: {
        DragAndDropInputFile,
    },

    props: {
        value: { type: String, required: false, default: null },
        message: { type: String, required: false, default: 'Aponte todos os detalhes relevantes para a realização desta operação. Se precisa de receber o montante numa moeda ou carteira específica ou se realizou qualquer transferência não aponte nas fases anteriores, deve referir aqui esse facto.' },
        allowAttachment: { type: Boolean, required: false, default: true },
        isRequired: { type: Boolean, required: false, default: false },
        attachment: { type: Array, required: false, default: () => [] },
        link: { type: String, required: false, default: null },
        panel: { type: Number, required: false, default: 0 },
        hasPanel: { type: Boolean, required: false, default: true }
    },

    mixins: [
        AuthMixin,
        RailMixin,
    ],

    created() {
        // populate computed props
        this.initialize();
        // handle inputs based on props
        this.handleButtons();
        this.emitChange();
    },

    computed: {
        // prepare payload with form fields
        formData: function () {
            let payload = {
                gyazoLink: this.form.link,
                filename: this.form.filename
            }   
            return payload;
        },

        /**
         * used as v-model (form.observation) on v-textarea
         */
        computedValue: {
            get: function () {
                return this.form.observation;
            },
            set: function (newValue) {
                this.form.observation = newValue;
                this.$emit('input', newValue);
                this.emitChange();
            }
        },

        panelComputedValue: {
            get: function () {
                return this.form.panel;
            },
            set: function (newValue) {
                this.form.panel = newValue;
            }
        },

        /**
         * form.filename passed through prop to DragAndDropInputFile
         */
        computedFilename: {
            get: function () {
                return this.form.filename;
            },
            set: function (newValue) {
                this.form.filename = newValue;
            }
        },

        disableFormLinkField: function () {
            if (!this._.isEmpty(this.computedFilename)) {
                this.form.link = null;
                return true;
            }
            return false;
        },
        inputValueBtnText: function () {
            let text = this.inputFile 
                     ? 'Adicionar URL'
                     : 'Carregar Imagem'
            
            return text;
        },
        inputValueBtnIcon: function () {
            let icon = this.inputFile
                     ? 'mdi-link-variant'
                     : 'mdi-upload'

            return icon;
        },
    },

    watch: {
        value: function(newValue) {
            this.computedValue = newValue;
        },
        panel: function (newValue) {
            this.panelComputedValue = newValue;
        },
        attachment: function(newValue) {
            this.computedFilename = newValue;
        },
        // retrieve payload from formData and emit to parent component
        formData: function (payload) { 
            if (payload) {
                this.$emit('retrieve-info', payload);
            }
        },
    },

    data() {
        return this.initializeState();
    },

    methods: {
        initializeState() {
            return {
                inputFile: false,
                form: {
                    observation: null,
                    link: null,
                    filename: [],
                    panel: 0,
                },
                rules: {
                    observation: [(v) => !!v || 'Por favor preencha o campo']
                }
            }
        },

        initialize() {
            // Initial value passed through prop
            this.computedValue = this.value;
            // Initial attachment passed through prop
            this.computedFilename = this.attachment;
            // Initial link passed through prop
            this.form.link = this.link;
            // Initial panel value passed through prop
            this.panelComputedValue = this.panel;
        },

        handleButtons() {
            if (!this._.isEmpty(this.attachment) || this.link ) {
                this.toggleInputFile();
            }

        },

        getDataFromDragAndDropInputFile(payload) {
            this.computedFilename = payload;
        },

        toggleInputFile() {
            return this.inputFile = !this.inputFile;
        },


        emitChange() {
            this.$emit('input', this.computedValue);
            this.$emit('change', this.computedValue);
        },
    }
}
