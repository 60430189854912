import { mapGetters } from 'vuex';
import EmptyStateNoData from '@/components/EmptyStateNoData';

export default {
    name: 'LineChartHistoricBalance',

    components: {
        EmptyStateNoData,
    },

	props: {
		title: {
			type: String,
			required: false,
			default: '',
		},
		subtitle: {
			type: String,
			required: false,
			default: '',
		},
    },
    
    computed: {
        ...mapGetters({
            selectedNickname: 'nicknames/selectedNickname',
            nicknameHistoricBalanceGraph: 'nicknames/nicknameHistoricBalanceGraph',
            hasLoadedNicknameHistoricBalanceGraph: 'nicknames/hasLoadedNicknameHistoricBalanceGraph',
            heroDatePickerValue: 'UI/heroDatePickerValue',
            themeDarkMode: 'UI/themeDarkMode',
        }),

        seriesComputed: {
            get: function () {
                return this.series;
            },
            set: function (newValue) {
                this.series[0]['data'] = newValue;
            }
        },

        optionsComputed: function () {
            return {
                chart: {
                    type: 'area',
                    height: 350,
                    zoom: {
                        enabled: false
                    },
                    toolbar: {
                        show: false
                    },
                    foreColor: '#f6f7f8',
                    background: this.themeDarkMode ? 'dark' : 'light',
                },
                labels: this.nicknameHistoricBalanceGraph.labels,
                colors: ['#FFC900'],
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'straight'
                },
                xaxis: {
                    type: 'datetime',
                    labels: {
                        show: true,
                        style: {
                            colors: this.themeDarkMode ? '#FFFFFF' : '#000000',
                        },
                    }
                },
                yaxis: {
                    opposite: true,
                    labels: {
                        show: true,
                        style: {
                            colors: this.themeDarkMode ? '#FFFFFF' : '#000000',
                        },
                    }
                },
                legend: {
                    horizontalAlign: 'left'
                },
                theme: {
                    mode: this.themeDarkMode ? 'dark' : 'light',
                }
            }
        },
    },

    watch: {
        selectedNickname: function () {
          if (this.selectedNickname) {
            this.fetchData();
          }  
        },
        nicknameHistoricBalanceGraph: function () {
            // set new value for series
            if (this.nicknameHistoricBalanceGraph && this.nicknameHistoricBalanceGraph.hasOwnProperty('series')) {
                this.seriesComputed = this.nicknameHistoricBalanceGraph.series;
            }
        },
        heroDatePickerValue: function () {
            this.fetchData();
        },
        
        themeDarkMode: function () {
            if (this.$refs.LineChartHistoricBalance) {
                this.$refs.LineChartHistoricBalance.destroy();
                this.$refs.LineChartHistoricBalance.refresh();
            }
        }
      },

    data() {
        return this.initialState();
    },

    methods: {
        initialState() {
            return {
                series: [{
                    name: this.title,
                    data: [],
                }],
            }
        },

        async fetchData() {
            let payload = {
                id: this.selectedNickname.id,
                params: {}
            }

            if (!this._.isEmpty(this.heroDatePickerValue)) {
                payload.params.dateBegin = this.heroDatePickerValue[0];
                payload.params.dateEnd = this.heroDatePickerValue[1];
            }

            if (!this._.isEmpty(payload.params)) {
                await this.$store.dispatch('nicknames/getNicknameHistoricBalanceGraph', payload);
            }
        }
    },
}