import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth.vue';
import RailMixin from '@/mixins/Rail.vue';
import DataMixin from '@/mixins/Data.vue';
import EmptyStateNoData from '@/components/EmptyStateNoData';

export default {
  name: 'TablePlayerTotalBalance',

  mixins: [
    AuthMixin,
    RailMixin,
    DataMixin,
  ],

  components: {
    EmptyStateNoData,
  },

  data() {
    return this.initializeState();
  },

  created() {
    this.fetchData();
  },

  computed: {
    ...mapGetters({
      statistics: 'railStatistics/dataProfitPerPlayer',
      statisticsLoading: 'railStatistics/loadingDataProfitPerPlayer',
      themeDarkMode: 'UI/themeDarkMode',
      heroDatePickerValue: 'UI/heroDatePickerValue',
      usersAll: 'users/usersAllActive',
      teamFilter: 'teams/teamFilter',
    }),

    tableData: {
      get: function () {
          return this.items;
      },
      set: function (newValue) {
        if (newValue) {
          this.items = Object.values(newValue);
        }
      }
    },

    optionsComputed: {
      get: function () {
        return this.options;
      },
      set: function (newValue) {
        if (!this._.isEqual(this.options, newValue)) {
          this.options = newValue;
        }
      }
    },
  },

  watch: {
    heroDatePickerValue: function () {
        this.fetchData();
    },

    teamFilter: function () {
      this.fetchData();
  },

    statistics: {
      handler(newValue) {
      if (newValue) {
        this.tableData = newValue;
      } else {
        this.tableData = [];
      }
      },
      deep: true
    },
  },

  methods: {
    initializeState() {
      return {
        items: [],
        showEuro: true,
        options: {
          page: 1,
          itemsPerPage: 25,
          sortBy: [],
          sortDesc: [],
          groupBy: [],
          groupDesc: [],
          mustSort: true,
          multiSort: true
        },
        headers: [
          { text: 'Jogador', value: 'user' },
          { text: 'Grupo', value: 'group', sortable: false },
          { text: 'Total', value: 'profit.EUR.value' },
        ],
        dataTable: {
          footerProps: {
            'items-per-page-options': this.$itemsPerPageOptions,
          }
        }
      };
    },

    async fetchData() {
      // Formulate payload
      let payload = {
        id: 7,
        params: {
          details: 1,
        }
      }

      if (!this._.isEmpty(this.heroDatePickerValue)) {
        payload.params.dateBegin = this.heroDatePickerValue[0];
        payload.params.dateEnd = this.heroDatePickerValue[1];
      }

      // if team exists and isManagement, Add team parameter to payload.params
      if (this.teamFilter && this.userHasRailAccessDivisionDropdown) {
        payload.params.team = this.teamFilter;
      }

      // Get data
      await this.$store.dispatch('railStatistics/getStatisticsProfitPerPlayer', payload);
    },
    
    handleUsername(id) {
      let output = '';
      if (!this._.isEmpty(this.usersAll)) { 
        output = this.usersAll.find(element => element.id == id);
        if (!this._.isUndefined(output)) {
          output = output.name;
        }
      }
      return output;
    },

    handleUserGroup(id) {
      let output = { "id": 1, "name": "Nível indefinido", "image": "nogroup.png" };

      if (!this._.isEmpty(this.usersAll)) { 

        output = this.usersAll.find(element => element.id == id);

        if (!this._.isUndefined(output)) {
          output = output.group;
        }
      }
      return output;
    },

    switchEuroOrDollar() {
      return this.showEuro
        ? 'Moeda: Euro €'
        : 'Moeda: Dólar $';
    },

    showValueBasedOnSwitch(item) {
      let output = 0;

      if (item && item.profit) {
        output = this.showEuro
          ? item.profit.USD.value
          : item.profit.EUR.value;
      }

      return output;
    },

    showCurrencyBasedOnSwitch() {
      return this.showEuro
        ? '$'
        : '€';
    },

    handleUserImgCutout(id) {
      let output = null;

      if (!this._.isEmpty(this.usersAll)) { 

        output = this.usersAll.find(element => element.id == id);

        if (!this._.isUndefined(output)) {
          output = output.imgCutout;
        }

      }
      return output;
    },
  },
};

