// https://vuetifyjs.com/en/customization/theme

import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: false,
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#0F1014',
        secondary: '#f5f5f5',
        anchor: '#0F1014',
        accent: '#ffc900',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FB8C00',
      },
      dark: {
        primary: '#ffffff',
        secondary: '#4D4E52',
        anchor: '#ffffff',
        accent: '#ffc900',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FB8C00',
      }
    },
  },

  icons: {
    iconfont: 'mdi',
  },
});
