/**
 * @prop    {String}        title                   
 * @prop    {String}        nicknameId
 * @prop    {String}        type                    Valid values TRANSFERS, SWAPS, MOVEMENTS.
 * @prop    {Boolean}       showTabs                Valid values TRUE or FALSE.
 */

import Vue from 'vue';
import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth.vue';
import RailMixin from '@/mixins/Rail.vue';
import DataMixin from '@/mixins/Data.vue';
import EmptyStateNoData from '@/components/EmptyStateNoData';
import DialogViewTransfer from '@/components/Rail/DialogViewTransfer/DialogViewTransfer.vue';
import DialogDownloadCsv from '@/components/Rail/Dialog/DialogDownloadCsv/DialogDownloadCsv.vue';
import UserDropdown from '@/components/Rail/UserDropdown/UserDropdown.vue';
import NicknameCard from '@/components/NicknameCard/NicknameCard.vue';

export default {
  name: 'DataTableRailsTransfers',

  components: {
    EmptyStateNoData,
    DialogViewTransfer,
    DialogDownloadCsv,
    UserDropdown,
    NicknameCard,
  },

  mixins: [
    AuthMixin,
    RailMixin,
    DataMixin,
  ],

  props: {
    title: {
      type: String,
      required: false,
      default: 'Transferências',
    },
    type: {
      type: String,
      required: false,
      default: 'TRANSFERS',
    },
    showTabs: {
      type: Boolean,
      required: false,
      default: false,
    },
    nicknameId: {
      type: String,
      required: false,
      default: '',
    },
    filters: {
      type: Object,
      required: false,
      default: () => { }
    },
  },

  data() {
    return this.initialState();
  },

  computed: {
    ...mapGetters({
      // Items state
      swaps: 'railSwaps/swaps',
      swapsPending: 'railSwaps/swapsPending',
      swapsFinalized: 'railSwaps/swapsFinalized',
      swapsRejected: 'railSwaps/swapsRejected',
      transfers: 'railTransfers/transfers',
      transfersManagement: 'railTransfers/transfersManagement',
      transfersPlayer: 'railTransfers/transfersPlayer',
      transfersPending: 'railTransfers/transfersPending',
      transfersRejected: 'railTransfers/transfersRejected',

      // Total Items state
      swapsTotal: 'railSwaps/swapsTotal',
      swapsPendingTotal: 'railSwaps/swapsPendingTotal',
      swapsFinalizedTotal: 'railSwaps/swapsFinalizedTotal',
      swapsRejectedTotal: 'railSwaps/swapsRejectedTotal',
      transfersTotal: 'railTransfers/transfersTotal',
      transfersManagementTotal: 'railTransfers/transfersManagementTotal',
      transfersPlayerTotal: 'railTransfers/transfersPlayerTotal',
      transfersPendingTotal: 'railTransfers/transfersPendingTotal',
      transfersRejectedTotal: 'railTransfers/transfersRejectedTotal',

      // Loading Items state
      swapsLoading: 'railSwaps/loading',
      swapsPendingLoading: 'railSwaps/swapsPendingLoading',
      swapsFinalizedLoading: 'railSwaps/swapsFinalizedLoading',
      swapsRejectedLoading: 'railSwaps/swapsRejectedLoading',
      loadingTransfers: 'railTransfers/loading',
      transfersManagementLoading: 'railTransfers/transfersManagementLoading',
      transfersPlayerLoading: 'railTransfers/transfersPlayerLoading',
      transfersPendingLoading: 'railTransfers/transfersPendingLoading',
      transfersRejectedLoading: 'railTransfers/transfersRejectedLoading',

      nicknameTransfers: 'nicknames/nicknameTransfers',
      nicknameTransfersTotal: 'nicknames/nicknameTransfersTotal',
      hasLoadedNicknameTransfers: 'nicknames/hasLoadedNicknameTransfers',
      userTrackerId: 'auth/userTrackerId',
      heroDatePickerValue: 'UI/heroDatePickerValue',
      themeDarkMode: 'UI/themeDarkMode',
      selectedTransfer: 'railTransfers/selectedTransfer',
      selectedSwap: 'railSwaps/selectedSwap',
      teamFilter: 'teams/teamFilter'
    }),

    /**
     * Populate tabs based on prop "type"
     * @returns tabs 
     */
    tableTabs: function () {
      let tabs;
      switch (this.type) {
        case 'TRANSFERS':
          tabs = this.userIsRailManager ? this.tabs.transfers.admin : null;
          break;
        case 'SWAPS':
          tabs = this.tabs.swaps;
          break;
        default:
          return null;
      }
      return tabs;
    },

    /**
     * Populate v-data-table headers based on prop "type"
     * @returns headers 
     */
    tableHeaders: function () {
      let headers;
      switch (this.type) {
        case 'TRANSFERS':
          headers = this.userIsRailManager ? this.headers.transfers.admin : this.headers.transfers.user;
          break;
        case 'SWAPS':
          headers = this.headers.swaps;
          break;
        case 'MOVEMENTS':
          headers = this.headers.movements;
          break;
        default:
          return null;
      }
      return headers;
    },

    /**
     * Populate v-data-table items based on prop "type" and tabs
     * @returns data already handled to table
     */
    tableData: function () {
      let data;
      switch (this.type) {
        case 'TRANSFERS':
          data = this.tableTransfersFilter;
          break;
        case 'SWAPS':
          data = this.tableSwapsFilter;
          break;
        case 'MOVEMENTS':
          data = this.nicknameTransfers;
          break;
        default:
          return null;
      }
      return data;
    },

    /**
     * populate v-data-table prop 'server-items-length' based on TableTransfers prop 'type'
     */
    tableDataTotal() {
      let total;
      switch (this.type) {
        case 'TRANSFERS':
          total = this.tableTransfersTotalFilter;
          break;
        case 'SWAPS':
          total = this.tableSwapsTotalFilter;
          break;
        case 'MOVEMENTS':
          total = this.nicknameTransfersTotal;
          break;
        default:
          break;
      }
      return total;
    },

    /**
     * populate v-data-table prop 'loading' based on TableTransfers prop 'type'
     */
    tableDataLoading() {
      let loading;
      switch (this.type) {
        case 'TRANSFERS':
          loading = this.tableTransfersLoadingFilter;
          break;
        case 'SWAPS':
          loading = this.tableSwapsLoadingFilter;
          break;
        case 'MOVEMENTS':
          loading = !this.hasLoadedNicknameTransfers;
          break;
        default:
          break;
      }
      return loading;
    },

    /**
     * Filters transfers by current tab (if is admin)
     * @returns filtered transfers
     */
    tableTransfersFilter: function () {
      if (this.userIsRailManager) {
        switch (this.tabs.current) {
          case 0:
            // All transfers
            return this.transfers;
          case 1:
            // Transfers Pending
            return this.transfersPending;
          case 2:
            // Transfers that involve management
            return this.transfersManagement;
          case 3:
            // Transfers that dont involve management
            return this.transfersPlayer;
          case 4:
            // Rejected Transfers 
            return this.transfersRejected;
          default:
            // All transfers
            return this.transfers;
        }
      } else {
        return this.transfers;
      }
    },

    tableTransfersTotalFilter: function () {
      switch (this.tabs.current) {
        case 0:
          return this.transfersTotal;
        case 1:
          return this.transfersPendingTotal;
        case 2:
          return this.transfersManagementTotal;
        case 3:
          return this.transfersPlayerTotal;
        case 4:
          return this.transfersRejectedTotal;
        default:
          return this.transfersTotal;
      }
    },

    tableTransfersLoadingFilter: function () {
      switch (this.tabs.current) {
        case 0:
          return this.loadingTransfers;
        case 1:
          return this.transfersPendingLoading;
        case 2:
          return this.transfersManagementLoading;
        case 3:
          return this.transfersPlayerLoading;
        case 4:
          return this.transfersRejectedLoading;
        default:
          return this.loadingTransfers;
      }
    },

   /**
   * Filters swap operations by All / Pending / Finalized
   * @returns filtered transfers 
   */
    tableSwapsFilter: function () {
      switch (this.tabs.current) {
        case 0:
          // All swaps
          return this.swaps;
        case 1:
          // Swaps Pendentes
          return this.swapsPending;
        case 2:
          // Swaps Finalizados
          return this.swapsFinalized;
        case 3:
          // Swaps Rejeitados
          return this.swapsRejected;
        default:
          return this.swaps;
      }
    },

    tableSwapsTotalFilter: function () {
      switch (this.tabs.current) {
        case 0:
          // All swaps
          return this.swapsTotal;
        case 1:
          // Swaps Pendentes
          return this.swapsPendingTotal;
        case 2:
          // Swaps Finalizados
          return this.swapsFinalizedTotal;
        case 3:
          // Swaps Rejeitados
          return this.swapsRejectedTotal;
        default:
          return this.swapsTotal;
      }
    },

    tableSwapsLoadingFilter: function () {
      switch (this.tabs.current) {
        case 0:
          // All swaps
          return this.swapsLoading;
        case 1:
          // Swaps Pendentes
          return this.swapsPendingLoading;
        case 2:
          // Swaps Finalizados
          return this.swapsFinalizedLoading;
        case 3:
          // Swaps Rejeitados
          return this.swapsRejectedLoading;
        default:
          return this.swapsLoading;
      }
    },

    /**
     * populate prop "type" in DialogDownloadCsv component based on prop "type" in TableTransfers
     */
    getDownloadType() {
      switch (this.type) {
        case 'TRANSFERS':
          return 'TRANSFER';
        case 'SWAPS':
          return 'SWAP';
        case 'MOVEMENTS':
          return 'MOVEMENT';
        default:
          break;
      }
    },

    swapStatusBasedOnCurrentTab: function () {
      let output;
      switch (this.tabs.current) {
        case 0:
          output = '';
          break;
        case 1:
          output = Vue.prototype.$rail_status.pending;
          break;
        case 2:
          output = Vue.prototype.$rail_status.finalized;
          break;
        case 3:
          output = Vue.prototype.$rail_status.rejected;
          break;
        default:
          output = '';
          break;
      }
      return output;
    },

    optionsComputed: {
      get: function () {
        return this.options;
      },
      set: function (newValue) {
        if (!this._.isEqual(this.options, newValue)) {
          this.options = newValue;
        }
      }
    },

    emptyStateTitle: function () {
      switch (this.type) {
        case 'TRANSFERS':
          return 'Não foram encontradas transferências';
        case 'SWAPS':
          return 'Não foram encontrados Swaps';
        case 'MOVEMENTS':
          return 'Não foram encontrados movimentos';
        default:
          return 'Não foram encontradas transferências';
      }
    },

    emptyStateMessage: function () {
      switch (this.type) {
        case 'TRANSFERS':
          return this.emptyStateMessageTransfers;
        case 'SWAPS':
          return this.emptyStateMessageSwaps;
        case 'MOVEMENTS':
          return 'Todos os movimentos aparecerão aqui.';
        default:
          break;
      }
    },

    emptyStateMessageTransfers: function () {
      let word = ['realizadas', 'pendentes', 'da gestão', 'dos jogadores', 'rejeitadas'];
      let output = "Todas as transferências " + word[this.tabs.current] + " aparecerão aqui."
      return output;
    },

    emptyStateMessageSwaps: function () {
      let word = ['realizados', 'pendentes', 'finalizados', 'rejeitados'];
      let output = "Todos os Swaps " + word[this.tabs.current] + " aparecerão aqui."
      return output;
    },

    informationString: function() {
      let output = '';

      switch (this.tabs.current) {
        case 0:
          output = 'Está a ver transferências com qualquer estado.';
          break;
        case 1:
          output = 'Está a ver transferências a decorrer.';
          break;
        case 2:
          output = 'Está a ver transferências de operações em que a gestão está envolvida.';
          break;
        case 3:
          output = 'Está a ver transferências de operações em que a gestão não está envolvida.';
          break;
        case 4:
          output = 'Está a ver transferências de operações rejeitadas';
          break;
        default:
          output = '';
          break;
      };

      return output;
    },
  },

  watch: {
    // watches this.options object
    // triggers everytime this.options has changed
    options: {
      handler() {
        this.fetchData();
      },
      deep: true,
    },

    '$route.query': {
      immediate: true,
      handler: function(newQuery, oldQuery) {
        if(newQuery.dateBegin){
          this.dateParams = newQuery
        }
      },
    },

    heroDatePickerValue: function () {
      if (!this.loadingFetchData) {
        this.fetchData();
      }
    },

    // watches this.tabs object
    // resets this.options object everytime tab has changed
    tabs: {
      handler() {
        this.options = {
          page: 1,
          itemsPerPage: 10,
          sortBy: ['createdAt'],
          sortDesc: [true],
          groupBy: [],
          groupDesc: [],
          mustSort: false,
          multiSort: true
        }
      },
      deep: true,
    },

    // watches this.filters prop
    // triggers fetchData() by "resetting" options object
    filters: {
      handler() {
        // resets options object
        this.options = {
          page: 1,
          itemsPerPage: 10,
          sortBy: ['createdAt'],
          sortDesc: [true],
          groupBy: [],
          groupDesc: [],
          mustSort: false,
          multiSort: true
        }
      },
      deep: true,
    },

    teamFilter: function () {
      if (!this.loadingFetchData) {
        this.fetchData();
      }
    },

    user: function() {
      this.fetchData();
    }
  },

  created() {
    // fetches single transfer based  on route params id and opens Dialog
    if (this.$route.params.id) {
      this.fetchSingleTransfer();
    }

    // fetches transfers based on prop type and nicknameId
    if (!this.loadingFetchData) {
      this.fetchData();
    }
  },

  methods: {
    initialState() {
      return {
        user: null,
        loadingFetchData: false,
        tabs: {
          current: 0,
          swaps: ['Todos', 'Pendentes', 'Finalizados', 'Rejeitados'],
          transfers: {
            admin: ['Todas', 'Pendentes', 'Gestão', 'Jogadores', 'Rejeitados'],
          },
        },
        headers: {
          movements: [
            { text: 'Data', value: 'createdAt' },
            { text: 'Tipo', value: 'type' },
            { text: 'Emissor', value: 'source' },
            { text: 'Destinatário', value: 'target' },
            { text: 'Montante', value: 'amountEUR' },
            { text: 'Ação', value: 'action', sortable: false }
          ],
          swaps: [
            { text: 'Data', value: 'createdAt' },
            { text: 'Emissor', value: 'source' },
            { text: 'Destinatário', value: 'target' },
            { text: 'Montante', value: 'amountEUR' },
            { text: 'Estado', value: 'status', sortable: false },
            { text: 'Ação', value: 'action', sortable: false },
          ],
          transfers: {
            admin: [
              { text: '', value: 'type_parties', sortable: false },
              { text: 'Data', value: 'createdAt' },
              { text: 'Operação', value: 'type' },
              { text: 'Emissor', value: 'source' },
              { text: 'Destinatário', value: 'target' },
              { text: 'Montante', value: 'amountEUR' },
              { text: 'Ação', value: 'action', sortable: false }
            ],
            user: [
              { text: '', value: 'type_parties', sortable: false },
              { text: 'Data', value: 'createdAt' },
              { text: 'Tipo', value: 'type' },
              { text: 'Emissor', value: 'source' },
              { text: 'Destinatário', value: 'target' },
              { text: 'Montante', value: 'amountEUR' },
              { text: 'Ação', value: 'action', sortable: false },
            ]
          }
        },
        dataTable: {
          search: null,
          footerProps: {
            'items-per-page-options': this.$itemsPerPageOptions,
          },
        },
        options: {
          page: 1,
          itemsPerPage: 10,
          sortBy: ['createdAt'],
          sortDesc: [true],
          groupBy: [],
          groupDesc: [],
          mustSort: true,
          multiSort: true
        },
        dialogues: {
          viewTransfer: false,
          download: {
            state: false,
            message: 'Exporta os dados da tabela para formato Excel.',
          },
        },
        titleViewTransfer: null,
        typeViewTransfer: null,
        dateParams: {},
      };
    },

    /**
     * Set action to be dispatched based on prop "type"
     * @returns string that contains the store + action to call API
     */
    actionToDispatch() {
      let action;
      switch (this.type) {
        case 'TRANSFERS':
          action = 'railTransfers/get';
          break;
        case 'SWAPS':
          action = 'railSwaps/get';
          break;
        case 'MOVEMENTS':
          action = 'nicknames/getNicknameTransfers';
          break;
        default:
          break;
      }
      return action;
    },

    // only used when prop type is equal to 'TRANSFERS'
    isManagementInvolvedBasedOnCurrentTab() {
      let output;
      switch (this.tabs.current) {
        case 0:
          output = '';
          break;
        case 1:
          output = '';
          break;
        case 2:
          output = 1;
          break;
        case 3:
          output = 0;
          break;
        default:
          output = '';
          break;
      }
      return output;
    },

    async verifyQuery(){
         return new Promise((resolve) => {
          const interval = setInterval(() => {
            if (this.$route.query.dateBegin && this.$route.query.dateEnd) {
              this.dateParams.dateBegin = this.$route.query.dateBegin
              this.dateParams.dateEnd = this.$route.query.dateEnd
              clearInterval(interval);
              resolve();
            }
          }, 100);
        });
    },

    async fetchData() {

      this.loadingFetchData = true;
      
      // Base payload
      let payload = {
        export: false,
        params: {
          page: this.options.page,
          itemsPerPage: this.options.itemsPerPage,
        }
      };

      // add filter by user through prop
      if (this.filters && !this._.isNull(this.filters.user) && !this._.isUndefined(this.filters.user) && !this._.isEmpty(this.filters.user)) {
        payload.params.sourceUser = this.filters.user.id;
        payload.params.targetUser = this.filters.user.id;
      }

      // add user by userDropdown embedded
      if (this.user && !this._.isNull(this.user) && !this._.isUndefined(this.user) && !this._.isEmpty(this.user)) {
        payload.params.sourceUser = this.user.id;
        payload.params.targetUser = this.user.id;
      }

      // if team exists and isManagement, Add team parameter to payload.params
      if (this.teamFilter && this.userHasRailAccessDivisionDropdown) {
        payload.params.team = this.teamFilter;
      }

      if (this.type == 'TRANSFERS') {
        if (this.userIsRailManager) {
          payload.params.managementInvolved = this.isManagementInvolvedBasedOnCurrentTab();
          // add to payload pending parameter
          if (this.tabs.current == 1) {
            payload.params.pending = true;
          }

          // add to payload status parameter
          if (this.tabs.current == 4) {
            payload.params.status = Vue.prototype.$rail_status.rejected;
          }
        };

        // add to payload transfer Type
        if (this.filters && this.filters.transferType) {

          this.filters.transferType.forEach((element, index) => {
            return payload.params['transferType[' + index + ']'] = element.id;
          })

          // removes transferType from payload params if doesn't exists
          if (this._.isEmpty(payload.params.transferType)) {
            delete payload.params.transferType;
          }
        }

        // add filter by operation type
        if (this.filters && !this._.isEmpty(this.filters.operation)) {
          this.filters.operation.forEach((element, index) => {
            return payload.params['type[' + index + ']'] = element.value;
          })
        } else {
          // requests default operations (EXCEPT SWAP)
          payload.params['type[0]'] = Vue.prototype.$rail_ops.operations.split;
          payload.params['type[1]'] = Vue.prototype.$rail_ops.operations.bankroll_reduction;
          payload.params['type[2]'] = Vue.prototype.$rail_ops.operations.bankroll_increment;
          payload.params['type[3]'] = Vue.prototype.$rail_ops.operations.transfer_to_self;
          payload.params['type[4]'] = Vue.prototype.$rail_ops.operations.deposit;
          payload.params['type[5]'] = Vue.prototype.$rail_ops.operations.withdraw;
          payload.params['type[6]'] = Vue.prototype.$rail_ops.operations.makeup;
        }
      }

      if (this.type == 'SWAPS') {
        payload.params.status = this.swapStatusBasedOnCurrentTab;
      }

      if (this.type == 'MOVEMENTS' && this.nicknameId) {
        payload.id = this.nicknameId;
      }

      if (!this._.isEmpty(this.heroDatePickerValue)) {
        payload.params.dateBegin = this.heroDatePickerValue[0];
        payload.params.dateEnd = this.heroDatePickerValue[1];
      } else {
        payload.params.dateBegin = this.$moment().subtract(1, 'month').format('YYYY-MM-DD');
        payload.params.dateEnd = this.$moment().format('YYYY-MM-DD');
      }
      
      // Add sortBy parameter to payload
      if (!this._.isEmpty(this.options.sortBy)) {
        this.options.sortBy.forEach((element, index) => {
          payload.params['sortBy[' + index + ']'] = element;
        })
      }

      // Add sortDesc parameter to payload.params
      if (!this._.isEmpty(this.options.sortDesc)) {
        this.options.sortDesc.forEach((element, index) => {
          payload.params['sort[' + index + ']'] = element == false ? 'ASC' : 'DESC';;
        })
      }

      // API Call
      if(this.$route.name == 'Nickname'){
        await this.verifyQuery()
        if(this.dateParams.dateBegin && this.dateParams.dateEnd){
          payload.params.dateBegin = this.dateParams.dateBegin
          payload.params.dateEnd = this.dateParams.dateEnd
        }
      }

      const action = this.actionToDispatch();
      await this.$store.dispatch(action, payload);

      this.loadingFetchData = false;
    },

    async fetchSingleTransfer() {
      let route = this.$router.currentRoute.name;

      switch (route) {
        case 'Transferência':
          await this.$store.dispatch('railTransfers/getTransferById', this.$route.params.id);
          if (this.selectedTransfer) {
            this.viewItem(this.selectedTransfer);
          } else {
            this.routing();
          }
          break;
        case 'Swap':
          await this.$store.dispatch('railSwaps/getSwapById', this.$route.params.id);
          if (this.selectedSwap) {
            this.viewItem(this.selectedSwap);
          } else {
            this.routing();
          }
          break;
        default:
          break;
      }
    },

    /**
    * @param {Object} item From v-datatable template
    */
    async goToRailOperation(item) {
      // routing done by router-link element

      //reset card
      this.$store.commit('TabSlider/setCard', null);

      // await to prevent visual bug
      await this.$store.dispatch('railRequests/getRequestById', item.railOperation.id);

      // set hasFinished state to false
      this.$store.commit('TabSlider/setHasFinished', false);

      // set card based on selectedRequest type
      this.$store.commit('TabSlider/setCard', item.railOperation.type);
    },

    openDialogDownloadCsv() {
      this.dialogues.download.state = true;
    },

    /**
     * Select transfer
     * @param {Object} item Item from the <v-datatable>
     */
    setActiveItem(item) {
      if (item) {
        this.$store.commit(
          'railTransfers/setSelected',
          item
        );
      }
    },

    /**
     * @param {Object} item Item from the <v-datatable> 
     * @returns String populated with source name
     */
    getSourceName(item) {
      if (item.type == Vue.prototype.$transfer_types.O_M) {
        return 'Conta Externa';
      } else if (item.type == Vue.prototype.$transfer_types.P_O && item.railOperation.type == Vue.prototype.$rail_ops.operations.makeup) {
        return item.railOperation.targetUser.displayName;
      } else if (item.source && item.source.isManagement) {
        return 'Gestão';
      } else if (item && item.railOperation.user) {
        return item.railOperation.user.displayName;
      } else {
        return 'Desconhecido';
      }
    },

   /**
   * @param {Object} item Item from the <v-datatable> 
   * @returns String populated with source id
   */
    getSourceId(item) {
      if (item.type == Vue.prototype.$transfer_types.O_M) {
        return null;
      } else if (item.type == Vue.prototype.$transfer_types.P_O && item.railOperation.type == Vue.prototype.$rail_ops.operations.makeup) {
        return item.railOperation.targetUser.id;
      } else if (item.source && item.source.isManagement) {
        return null;
      } else if (item && item.railOperation.user) {
        return item.railOperation.user.id;
      } else {
        return null;
      }
    },

    /**
     * @param {Object} item Item from the <v-datatable>
     * @returns String populated with target name
     */
    getTargetName(item) {
      let isOutsideTransfer = item.type == Vue.prototype.$transfer_types.P_O || item.type == Vue.prototype.$transfer_types.M_O;
      // if is PNPW transfer type, it returns user display name 
      if (item.type == Vue.prototype.$transfer_types.PNPW) {
        return item.railOperation.user
          ? item.railOperation.user.displayName
          : 'Desconhecido';
      } else if (isOutsideTransfer) {
        return 'Conta Externa';
      } else if (item.target && item.target.isManagement) {
        return 'Gestão';
        // UC - Swap 
      } else if (item && item.railOperation.targetUser) {
        return item.railOperation.targetUser.displayName;
      } else if (item && item.railOperation.user) {
        return item.railOperation.user.displayName;
      } else {
        return 'Desconhecido';
      }
    },

    /**
     * @param {Object} item Item from the <v-datatable> 
     * @returns String populated with target id
     */
    getTargetId(item) {
      let isOutsideTransfer = item.type == Vue.prototype.$transfer_types.P_O || item.type == Vue.prototype.$transfer_types.M_O;
      // if is PNPW transfer type, it returns user display name 
      if (item.type == Vue.prototype.$transfer_types.PNPW) {
        return item.railOperation.user
          ? item.railOperation.user.id
          : null;
      } else if (isOutsideTransfer) {
        return null;
      } else if (item.target && item.target.isManagement) {
        return null;
        // UC - Swap 
      } else if (item && item.railOperation.targetUser) {
        return item.railOperation.targetUser.id;
      } else if (item && item.railOperation.user) {
        return item.railOperation.user.id;
      } else {
        return null;
      }
    },

    /**
     * Whether the bankroll of the viewing user remained flat, increased or decreased.
     * @param {Object} item Item from the <v-datatable>
     * @return {String} 'up', 'down' or 'flat'.
     */
    getAmountVariationDirection(item) {
      let output = null;

      if (item.source && item.source.isManagement && item.target && item.target.isManagement) {
        output = 'flat';
      } else if (item.railOperation && item.railOperation.status == Vue.prototype.$rail_status.rejected) {
        output = 'reject';
      } else if (item.source && !item.source.isManagement && item.target && item.target.isManagement) {
        output = this.userIsRailManager
          ? 'up'
          : 'down';
      } else if (item.source && item.source.isManagement && item.target && !item.target.isManagement) {
        output = this.userIsRailManager
          ? 'down'
          : 'up';
      } else if (item.type == Vue.prototype.$transfer_types.PNPW || item.type == Vue.prototype.$transfer_types.PWPN) {
        output = 'flat';
      } else if (item.type == Vue.prototype.$transfer_types.P_O) {
        output = this.userIsRailManager
          ? 'flat'
          : 'down';
      } else if (item.type == Vue.prototype.$transfer_types.P_P || item.type == Vue.prototype.$transfer_types.UNKNOWN) {
        output = item.railOperation.user && this.userTrackerId == item.railOperation.user.id
          ? 'down'
          : 'up';
        output = this.userIsRailManager ? '' : output;
      } else {
        output = 'flat';
      }

      return output;
    },

    /**
     * Generates the message in the tooltip.
     * Relies on function getAmountVariationDirection().
     * 
     * @param {Object} item Item from the <v-datatable>
     * @returns {String} Message to show in <v-tooltip>
     */
    getAmountVariationMessage(item) {
      const direction = this.getAmountVariationDirection(item);

      const messages = {
        up: 'Aumentou banca',
        down: 'Diminuiu banca',
        flat: 'Não alterou banca',
        reject: 'Devolvido'
      };

      return messages[direction];
    },

    /**
     * Generates the icon to show in the table.
     * Relies on function getAmountVariationDirection().
     * 
     * @param {Object} item Item from the <v-datatable>
     * @returns {String} Icon to show on the table
     */
    getAmountVariationIcon(item) {
      const direction = this.getAmountVariationDirection(item);

      const icons = {
        up: 'mdi-arrow-top-right',
        down: 'mdi-arrow-bottom-left',
        flat: 'mdi-equal',
        reject: 'mdi-restore-alert'
      }

      return icons[direction];
    },

    /**
     * Generates the icon color to show in the table.
     * Relies on function getAmountVariationDirection().
     * 
     * @param {Object} item Item from the <v-datatable>
     * @returns {String} Color to show on the icon
     */
    getAmountVariationColor(item) {
      const direction = this.getAmountVariationDirection(item);

      const colors = {
        up: 'success',
        down: 'error',
        flat: '',
        reject: 'error'
      }

      return colors[direction];
    },

    getStatusIcon(item) {
      let output = 'help-circle-outline';

      if (item.railOperation && item.railOperation.status == Vue.prototype.$rail_status.rejected) {
        output = 'mdi-close-circle-outline';
      } else if (item.isPending) {
        output = 'mdi-timelapse';
      } else {
        output = 'mdi-check';
      }

      return output;
    },

    getStatusMessage(item) {
      let output = 'Estado desconhecido';

      if (item && item.railOperation && item.railOperation.status == Vue.prototype.$rail_status.rejected) {
        output = 'Rejeitada';
      } else if (item.isPending) {
        output = 'Pendente';
      } else {
        output = 'Finalizada';
      }

      return output;
    },

    viewItem(item) {
      this.dialogues.viewTransfer = true;
      this.titleViewTransfer = this.getRailOperationName(item.railOperation.type);
      this.typeViewTransfer = this.type;

      if (this.type == 'SWAPS') {
        this.$store.commit('railSwaps/setSelectedSwap', item);
      }

      // routing
      this.routing(item.id);
    },

    closeDialogViewTransfer(redirect) {
      // clear selects
      this.$store.commit('railTransfers/setSelected', null);
      this.$store.commit('railSwaps/setSelectedSwap', null);

      // close dialog
      this.dialogues.viewTransfer = false;

      // used when navigating from DialogViewTransfer to rail operation
      if (redirect) return false;

      let path;

      switch (this.type) {
        case 'TRANSFERS':
          path = '/tracker' + Vue.prototype.$tab_slider.tabs.transfers
          break;
        case 'SWAPS':
          path = '/tracker' + Vue.prototype.$tab_slider.tabs.swaps
          break;
        default:
          path = null;
          break;
      }

      if (path) {
        // updates url without triggering vue-router
        history.pushState({}, null, path);
      }

    },

    /**
     * Handle route based on prop type and if id exists.
     * @param {String} id 
     */
    routing(id = null) {
      let pathType = this.type == 'TRANSFERS'
        ? '/rail/transfers/'
        : '/rail/swaps/';

      let routeObject = { path: pathType };

      // add id if exists
      if (id) {
        routeObject.path = routeObject.path + id;
      }
      if (this.$route.name == 'Swaps' || this.$route.name == 'Swap' || this.$route.name == 'Transferências' || this.$route.name == 'Transferência') {
        // routing
        this.$router.push(routeObject).catch(() => { });
      }
    },

    /**
     * @param {Object} item 
     * @returns object value symbol
     */
    getCurrencySymbol(item) {
      if (item.source && item.source.network.currency.symbol != '?') {
        return item.source.network.currency.symbol;
      } else {
        if(item.target){
          return item.target.network.currency.symbol;
        }
      }
    },

    /**
     * @param {Object} item 
     * @returns {String} icon name
     */
    getTypePartyIcon(item) {
      if (item && !item.isInternalTransfer) {
        return 'mdi-account-cash';
      } else if (this.getSourceName(item) == this.getTargetName(item)) {
        return 'mdi-account';
      } else {
        return 'mdi-account-switch';
      }
    },

    /**
     * @param {Object} item 
     * @returns {String} name for tooltip
     */
    getTypePartyName(item) {
      if (item && !item.isInternalTransfer) {
        return 'Transferência externa';
      } else if (this.getSourceName(item) == this.getTargetName(item)) {
        return 'Transferência interna';
      } else {
        return 'Transferência entre 2 partes';
      }
    },
  },
};
