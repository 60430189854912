import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth.vue';
import RailMixin from '@/mixins/Rail.vue';
import Loading from '@/views/Loading.vue';
import Hero from "@/components/Hero/Hero.vue";
import TableLiveEvents from '@/components/Tables/TableLiveEvents/TableLiveEvents.vue';
import DialogAddEditEventLive from '@/components/Dialog/DialogAddEditEventLive/DialogAddEditEventLive.vue';

export default {
    name: 'LiveEvents',

    mixins: [
      AuthMixin,
      RailMixin,
    ],

    components: {
        Loading,
        Hero,
        TableLiveEvents,
        DialogAddEditEventLive,
    },

    computed: {
      ...mapGetters({
        }),
    },

    data() {
      return this.initialState();
    },

    methods: {
      initialState() {
        return {
          dialogues: {
            addEditEventLive: false,
          },
        };
      },

      toggleDialog(dialog) {
        this.dialogues[dialog] = !this.dialogues[dialog];
      },
    }
}