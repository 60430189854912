import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth';
import Operations from '@/components/TabSlider/Tabs/Operations/Operations/Operations.vue';
import Operation from '@/components/TabSlider/Tabs/Operations/Operation/Operation.vue';

export default {
  name: 'Rail',

  mixins: [
    AuthMixin
  ],

  components: {
    Operations,
    Operation,
  },

  computed: {
    ...mapGetters({
    }),

    componentToShow() {
      return this.$route.params.id
        ? 'Operation'
        : 'Operations';
    },
  },

  data() {
    return this.initialState();
  },

  methods: {
    initialState() {
      return {
        id: null,
      };
    },
  }
}