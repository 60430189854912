import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth';
import DataMixin from '@/mixins/Data';
import NicknamesAdmin from '@/views/Nicknames/Admin/Nicknames-Admin.vue';
import NicknamesUser from '@/views/Nicknames/User/Nicknames-User.vue';

export default {
  name: 'Nicknames',

  mixins: [
    AuthMixin,
    DataMixin,
  ],

  components: {
    NicknamesAdmin,
    NicknamesUser
  },

  computed: {
    ...mapGetters({
      userType: 'auth/userType',
      userRoles: 'auth/userRoles',
    }),

    componentToShow() {
      let component = null;

      if (this.userIsManager) {
        component = 'NicknamesAdmin';
      } else {
        component = 'NicknamesUser';
      }

      return component;
    }
  }
}