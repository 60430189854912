import { mapGetters } from 'vuex';
import TableNotes from "@/components/Tables/TableNotes/TableNotes.vue";
import DialogAddEditPlayerLive from "@/components/Dialog/DialogAddEditPlayerLive/DialogAddEditPlayerLive.vue";
import LivePlayerInformation from "@/components/LivePlayerInformation/LivePlayerInformation.vue";
export default {
    props: {
		show: {
			type: Boolean,
			required: false,
			default: false,
		},
	},

    components: {
        TableNotes,
        DialogAddEditPlayerLive,
        LivePlayerInformation,
    },

    watch: {
        'tabs.current': function(newValue, oldValue) {
           if(newValue !== oldValue){
                switch (this.tabs.headers[newValue]) {
                    case 'Notas':
                        this.$root.$emit('change-fab-button', 'notes');
                        break;
                    case 'Info':
                        this.$root.$emit('change-fab-button', 'info');
                        break;
                
                    default:
                        break;
                }
            }
        },

        selectedPlayer: function(newValue, oldValue){
            this.$root.$emit('reload-table-notes');
        },
    },

    computed: {
        ...mapGetters({
            selectedPlayer: 'livePlayers/selectedPlayer',
            selectedPlayerNicknames: 'livePlayers/selectedPlayerNicknames',
            selectedPlayerTags: 'livePlayers/selectedPlayerTags',
            error: 'livePlayers/error',
            humanError: 'livePlayers/humanError',
        }),
    },

    data() {
        return {
            tabs: {
                current: 0,
                headers: ['Notas', 'Info'],
              },
            dialogues: {
                editPlayer: false,
            }
        }
    },

    methods: {
        hideComponent() {
            this.$emit("hide-component");
        },

        checkIsOpen(){
            return this.dialogues.editPlayer;
        },

        toggleDialog(dialog) {
            this.dialogues[dialog] = !this.dialogues[dialog];
        },

        customStyle(){
            if(this.vue.breakdown.mdAndUp){
                return 'width: 55%';
            }
            return 'width: 55%';
        },

        removePlayer(){
            this.$root.$emit('remove-player', this.selectedPlayer);
            this.$root.$emit('clear-players', this.selectedPlayer);
            this.hideComponent();
        },
    }
}