export default {
  data: () => ({
    items: [
      { id: '1', action: 'Depósito', player_name: 'Xunekas23', amout: '$ 100.00', icon: 'mdi-bank-transfer-in' },
      { id: '2', action: 'Levantamento', player_name: 'Xunekas23', amout: '$ 100,000.00', icon: 'mdi-bank-transfer-out' },
      { id: '3', action: 'Split', player_name: 'RuiNF', amout: '$ 200,000.00', icon: 'mdi-call-split' },
      { id: '4', action: 'Swap', player_name: 'DiogoNF', amout: '$ 10,000.00', icon: 'mdi-autorenew' },
      { id: '5', action: 'Pedido de depósito', player_name: 'Xunekas23', amout: '$ 8,000.00', icon: 'mdi-cash-plus' },
      { id: '6', action: 'Redução de Banca', player_name: 'Norte', amout: '$ 500.00', icon: 'mdi-cash-minus' },
      { id: '7', action: 'Transferência entre contas', player_name: 'Sam Farha', amout: '$ 10.00', icon: 'mdi-bank-transfer-in' },
      { id: '8', action: 'Audit', player_name: 'Phil Ivey', amout: '$ 10,000.00', icon: 'mdi-bank-transfer-out' },
      { id: '9', action: 'Reporte', player_name: 'Chris Ferguson', amout: '$ 2,000.00', icon: 'mdi-flag' },
      { id: '10', action: 'Registo de sessão', player_name: 'Doyle Brunson', amout: '$ 100.00', icon: 'mdi-cards-playing-outline' },
      { id: '11', action: 'Pedido de redução de banca', player_name: 'Daniel Negreanu', amout: '$ 1,040.00', icon: 'mdi-cash-minus' },
      { id: '12', action: 'Pedido de Split', player_name: 'Erik Seidel', amout: '$ 130.00', icon: 'mdi-call-split' },
    ],
  }),

  props: {
    title: {
      type: String,
      required: false,
      default: 'Atividade',
    }
},
}