import CardPlayerGoals from '@/components/Rail/CardPlayerGoals/CardPlayerGoals.vue';
import UserMixin from '@/mixins/User.vue';
import AuthMixin from '@/mixins/Auth.vue';
import DataMixin from '@/mixins/Data.vue';
import LottieVuePlayer from "@lottiefiles/vue-lottie-player";
import congratulationsAnimation from '@/assets/animations/congratulations.json';
import congratulationsAnimationMD from '@/assets/animations/congratulationsMD.json';
import congratulationsAnimationMD2 from '@/assets/animations/congratulationsMD2.json';
import checkmark from '@/assets/animations/checkmark.json';
import { mapGetters } from 'vuex';
import Vue from 'vue';

Vue.use(LottieVuePlayer);

export default {
  data() {
    return {
      dialog: this.isOpen,
      timeoutId: null,
      congratulationsAnimation: congratulationsAnimation,
      congratulationsAnimationMD: congratulationsAnimationMD,
      congratulationsAnimationMD2: congratulationsAnimationMD2,
      checkmark: checkmark,
      completedSessions: false,
      completedSessionsMD: false,
      completedSessionsMD2: false,
      screenWidth: window.innerWidth,
      dialogWidth: this.readScreen(window.innerWidth)
    };
  },

  components: {
    CardPlayerGoals
  },

  mixins: [
    UserMixin,
    AuthMixin,
    DataMixin,
  ],
  computed: {
    ...mapGetters({
      userStatisticsLoading: 'users/userStatisticsLoading',
      userTeams: 'users/userTeams',
      selectedUser: 'users/selectedUser'
    }),

    hasGamification() {
      let output = false;
      if (this.gamification) {
        output = Object.keys(this.gamification).length > 0;
      }
      return output
    },

    watchScreenWidth() {
      return this.screenWidth;
    },

    cardPlayerEventText: function () {
      let output = {
        title: 'N.º de eventos',
        tooltip: 'N.º de eventos registados',
        subtitleMonth: 'N.º de eventos registados',
        cardStatisticTitle: 'Média de eventos por sessão'
      }

      if (!this._.isEmpty(this.userTeams)) {
        let userTeamsMapID = this.userTeams.map(e => e.id)
        if (userTeamsMapID.includes(Vue.prototype.$team_division_cash_id)) {
          output = {
            title: 'N.º de mãos',
            tooltip: 'N.º de mãos contratualizadas',
            subtitleMonth: 'N.º de mãos registadas este mês',
            cardStatisticTitle: 'Média de mãos por sessão'
          }
        } else {
          output = {
            title: 'N.º de torneios',
            tooltip: 'N.º de torneios contratualizados',
            subtitleMonth: 'N.º de torneios registados este mês',
            cardStatisticTitle: 'Média de torneios por sessão'
          }
        }
      }
      return output;
    },
  },

  props: {
    isOpen: { type: Boolean, required: true, default: false },
    gamification: { type: Object, required: true, default: {} },
    action: { type: String, required: true, default: 'create' },
  },

  created() {
    window.addEventListener('resize', this.updateScreenWidth);
  },

  destroyed() {
    window.removeEventListener('resize', this.updateScreenWidth);
  },

  watch: {
    isOpen(newValue) {
      let dialogTime = Vue.prototype.$gamification_session_dialog_feedback_time;
      if (newValue && this.action == 'create') {
        this.dialog = true;

        if (this.gamification.show_animation) {
          this.showAnimation()
        }

        if(dialogTime !== 0){
          this.timeoutId = setTimeout(() => {
            this.closeDialog();
          }, dialogTime);
        }
      }
    },

    watchScreenWidth(newWidth) {
      this.readScreen(newWidth)
    },
  },

  methods: {
    openDialog() {
      this.dialog = true;
      this.toggleDialog()
    },

    readScreen(newWidth) {
      if (newWidth < 400) {
        this.dialogWidth = '100%'
        return '100%'
      } else if (newWidth < 600) {
        return '500px'
      }
      else {
        this.dialogWidth = '600px'
        return '600px'
      }
    },

    updateScreenWidth() {
      this.screenWidth = window.innerWidth;
    },

    redirectToPlayerSingleView() {
      const playerId =  this.selectedUser.id;
      const tabId = 2;

      // redirect to  player single  view (Performance tab)
      this.$router.push({ path: `/players/${playerId}/performance/` });

      // sets tab to "Desempenho"
      this.$root.$emit('changeTab', { playerId, tabId });

      this.closeDialog();
    },

    showAnimation() {
      const feedbackTime = Vue.prototype.$gamification_session_animation_time;
      const windowWidth = window.innerWidth;

      if (windowWidth > 1500) {
        this.completedSessions = true;
      } else if (windowWidth > 960 && windowWidth < 1050) {
        this.completedSessionsMD = true;
      } else if (windowWidth > 1050 && windowWidth < 1264) {
        this.completedSessionsMD2 = true;
      }
    
      setTimeout(() => {
        this.completedSessions = false;
        this.completedSessionsMD = false;
        this.completedSessionsMD2 = false;
      }, feedbackTime);
    },

    async getUserTeams() {
      await this.$store.dispatch('users/getUserTeams', this.$route.params.id);
    },

    closeDialog() {
      this.$emit('feedback-closed', 'feedback-success');
      clearTimeout(this.timeoutId);
      this.dialog = false;
    },

    toggleDialog() {
      this.$emit('update:is-open', !this.isOpen);
    },

    handleDialogInput(value) {
      if (!value) {
        clearTimeout(this.timeoutId);
      }
    }
  },

  beforeDestroy() {
    clearTimeout(this.timeoutId);
  }
};
