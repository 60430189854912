var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('DialogEditGroup',{attrs:{"is-open":_vm.dialogues.editGroup},on:{"close-dialog":function($event){_vm.dialogues.editGroup = false},"reload-data":function($event){return _vm.fetchData()}}}),_c('DialogRemoveGroup',{attrs:{"is-open":_vm.dialogues.removeGroup},on:{"close-dialog":function($event){_vm.dialogues.removeGroup = false},"reload-data":function($event){return _vm.fetchData()}}}),_c('DialogAddPlayerToGroup',{attrs:{"is-open":_vm.dialogues.addPlayer},on:{"close-dialog":function($event){_vm.dialogues.addPlayer = false}}}),_c('v-card-title',[_vm._v(" Níveis "),_c('v-spacer'),_c('v-text-field',{staticClass:"table-search-field d-none d-md-none",attrs:{"append-icon":"mdi-magnify","label":"Pesquisar","single-line":"","hide-details":"","outlined":"","dense":""},on:{"input":function($event){return _vm.search()}},model:{value:(_vm.dataTable.search),callback:function ($$v) {_vm.$set(_vm.dataTable, "search", $$v)},expression:"dataTable.search"}})],1),_c('div',[_c('v-text-field',{staticClass:"mx-4 d-flex d-md-none",attrs:{"append-icon":"mdi-magnify","label":"Pesquisar","single-line":"","hide-details":""},on:{"input":function($event){return _vm.search()}},model:{value:(_vm.dataTable.search),callback:function ($$v) {_vm.$set(_vm.dataTable, "search", $$v)},expression:"dataTable.search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.dataTable.headers,"items":_vm.groups,"search":_vm.dataTable.search,"server-items-length":_vm.groupsTotal,"options":_vm.options,"loading":_vm.loading,"footer-props":_vm.dataTable.footerProps,"loading-text":"A carregar...","must-sort":""},on:{"click:row":_vm.setActiveItem,"update:options":function (e) { return _vm.optionsComputed = e; }},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":'/groups/' + item.id + '/'}},[_c('div',{staticClass:"d-flex align-center"},[_c('div',[(_vm.isValidImagePath(item.image))?_c('v-img',{staticClass:"mr-2",attrs:{"src":require('@/assets/groups/' + item.image),"height":"24","width":"24"}}):_vm._e()],1),_c('span',[_vm._v(_vm._s(item.name))])])])]}},{key:"item.countPlayers",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.countPlayers))])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"dense":""},on:{"click":function($event){return _vm.dialogViewGroups(item)}}},on),[_vm._v(" mdi-launch ")])]}}],null,true)},[_c('span',[_vm._v("Abrir")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"dense":""},on:{"click":function($event){return _vm.dialogAddPlayerToGroup()}}},on),[_vm._v(" mdi-plus-circle-outline ")])]}}],null,true)},[_c('span',[_vm._v("Adicionar jogador")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"dense":""},on:{"click":function($event){return _vm.dialogEditGroup()}}},on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v("Editar Nível")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"dense":""},on:{"click":function($event){return _vm.dialogRemoveGroup()}}},on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v("Remover Nível")])])]}},{key:"no-data",fn:function(){return [_c('EmptyStateNoData',{attrs:{"icon":"mdi-check-circle-outline","title":"Não existem níveis","class-color":"success white--text","class-icon":"white"}})]},proxy:true},{key:"no-results",fn:function(){return [_c('EmptyStateNoData',{attrs:{"icon":"mdi-magnify","title":"Não foram encontrados resultados","message":"Ajuste os termos da sua pesquisa para encontrar mais resultados"}})]},proxy:true},{key:"loading",fn:function(){return [_c('v-skeleton-loader',{attrs:{"type":"table-tbody"}})]},proxy:true}])},[_c('template',{slot:"progress"},[_c('v-progress-linear',{attrs:{"color":"accent","indeterminate":""}})],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }