import axios from 'axios';
import FormDialogEdit from '@/components/Schedules/FormDialogEdit/FormDialogEdit.vue';

export default {
    name: 'DialogEdit',

    components: {
        FormDialogEdit,
    },

    data () {
        return {
            editedItem2: null,
            tab: null,
        }
    },

    props: {
        isOpen: { type: Boolean, required: true, default: false },
        editedItem: { type: Object, required: false, default: null },
        defaultItem: { type: Object, required: false, default: null },
        editedIndex: { type: Number, required: false, default: null },
    },

    created() {
        //this.initialize();
    },

    methods: {
        initialize() {
            return false;
        },

        addSeries() {
            // Configure new series
            let newSeries =  this.parseDefaultItem();
            // Add series entry
            if (! (this._.has(this.editedItem.rules, 'series') && this._.isArray(this.editedItem.rules.series))) {
              this.editedItem.rules.series = [];
            }
      
            this.editedItem.rules.series.push(newSeries);
            
            // Switch user to new entry
            this.tab = 'tab-series-' + (this.editedItem.rules.series.length - 1);
        },

        parseDefaultItem() {
            let newSeries = this.defaultItem.rules; 
            let editedRules = this.editedItem && this.editedItem.rules;
            newSeries.name = 'Nova série';
        
            if(editedRules){
                let properties = ['regular', 'regular_pko', 'turbo', 'turbo_pko', 'hyper', 'bubble_rush', 'splash', 'sng', 'fast', 'earlybird', 'blue', 'red'];
                for(let prop of properties) {
                    if(editedRules.hasOwnProperty(prop)) {
                        newSeries[prop] = editedRules[prop];
                    }
                }
            }
            return newSeries;
        },
        
        removeSeries() {
            let selectedSeries = this.getSelectedSeries();
            this.editedItem.rules.series.splice(selectedSeries, 1);
        },

        updateSchedule(data) {
            // Get selected series
            let selectedSeries = this.getSelectedSeries();
        
            if (selectedSeries === false) {
                // Update main schedule (editedItem)
                //let backupSeries = this.editedItem.rules.series;
                this.editedItem.rules = data;
                //this.editedItem.rules.series = backupSeries;
            } else {
                // Update selected series (editedItem)
                this.editedItem.rules.series[selectedSeries] = data;
            }
        },

        updateEditedItemKey(key, newValue) {
            this.editedItem[key] = newValue;
        },
    
        getSelectedSeries() {
            let selectedSeries = this.tab.split('tab-series-');
        
            if (selectedSeries.length == 2 && ! isNaN(selectedSeries[1])) {
                return selectedSeries[1];
            } else {
                return false;
            }
        },

        /* Turn '\n' into ';' */
        transformItemForSave(item) {
            this.editedItem.rules.exception_specials = this.convertExceptionsForSaving( this.editedItem.rules.exception_specials );
            this.editedItem.rules.exception_sunday = this.convertExceptionsForSaving( this.editedItem.rules.exception_sunday );
            this.editedItem.rules.exception_weekend = this.convertExceptionsForSaving( this.editedItem.rules.exception_weekend );
    
            if ( Array.isArray(this.editedItem.rules.series) && this.editedItem.rules.series.length ) {
            this.editedItem.rules.series.forEach((value, index) => {
                this.editedItem.rules.series[index].exception_specials = this.convertExceptionsForSaving(value.exception_specials);
                this.editedItem.rules.series[index].exception_sunday = this.convertExceptionsForSaving(value.exception_sunday);
                this.editedItem.rules.series[index].exception_weekend = this.convertExceptionsForSaving(value.exception_weekend);
            });
            }
    
            return item;
        },

        /* Turn '\n' into ';' */
        convertExceptionsForSaving(exceptions) {
            if ( ! exceptions )
            return null;
    
            exceptions = exceptions.split('\n');
    
            // Remove empty lines
            for (let key in exceptions) {
            if (exceptions[key] == '')
                exceptions.splice(key, 1);
            }
    
            exceptions = exceptions.join(';');
    
            return exceptions;
        },

        saveItem() {
            // Convert exceptions for saving
            this.editedItem = this.transformItemForSave(this.editedItem);
      
            // Set according to operation (update / create)
            let requestMethod = this.editedIndex > -1
                                ? 'PUT'
                                : 'POST';
      
            // Prepare request
            let requestParameters = new URLSearchParams();
            let id = this.editedItem.id_rulesets;
            
            // Ruleset configuration parameters
            requestParameters.append('ruleset', id);
            requestParameters.append('group', this.editedItem.id_groups);
            requestParameters.append('network', this.editedItem.id_networks);
      
            // Rule parameters
            for (const [key, value] of Object.entries(this.editedItem.rules))
            {
              // Skip if key is series (added later)
              if (key == 'series') continue;
      
              // Add key to params
              requestParameters.append(key, value);
            }
      
            // Series parameters
            for (let i in this.editedItem.rules.series)
            {
              let thisSeries = this.editedItem.rules.series[i];
              
              for (const [key, value] of Object.entries(thisSeries))
                requestParameters.append('series['+i+']['+key+']', value);
            }
      
            // Perform request
            axios({
              method: requestMethod,
              url: this.$url_api + 'v1/rulesets.php',
              data: requestParameters,
            })
            .then((response) => {
              //console.log(response);
            })
            .catch(error => console.log(error))
            .then(() => {
                this.$emit('force-update');
            });
            
            // UI Finalization
            //this.initialize();
            this.close();
        },

        close() {      
            this.$emit('close-dialog');
        },
    },
}