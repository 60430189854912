import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth';

import SchedulingAdminHome from './Admin/Home/Home.vue';
import SchedulingUser from './User/Scheduling-User.vue';

export default {
    name: 'Scheduling',

    mixins: [
      AuthMixin,
    ],

    components: {
      SchedulingAdminHome,
      SchedulingUser
    },

    computed: {
      ...mapGetters({
        userType: 'auth/userType',
        userRoles: 'auth/userRoles'
      }),
  
      componentToShow() {  
        if (this.userHasRole(['tracker_admin', 'tracker_manager'])) {
          return 'SchedulingAdminHome';
        } else {
          return 'SchedulingUser';
        }
      }
    },

    created() {
      this.init();
    },

    methods: {
      init() {
        // Deny access if user doesn't have the necessary role
        if (! this.userHasRole(['beta_tester_scheduling'])) {
          this.$router.push({ path: '/' });
        }
      }
    }
}