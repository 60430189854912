import Vue from 'vue';
import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth';
import RailMixin from '@/mixins/Rail';
import NetworkDropdown from '@/components/Rail/NetworkDropdown/NetworkDropdown.vue';
import AlertError from '@/components/Alerts/AlertError/AlertError.vue';

export default {
    name: 'DialogAddNicknamesToPlayerLive',

    props: {
        isOpen: {
            type: Boolean,
            required: true,
            default: false
        },
        userId: {
            type: [String, Number],
            required: false,
            default: null
        },
        allowFetch: {
            type: Boolean,
            required: false,
            default: true
        },
        action: {
			type: String,
			required: true,
			default: 'create',
			validator: function (value) {
				const allowedActions = ['create', 'update']
				return allowedActions.includes(value);
			},
        },
        nickname: {
            type: Object,
            required: false,
            default: () => {}
        },
    },

    components: {
        NetworkDropdown,
        AlertError,
    },

    mixins: [
        AuthMixin,
        RailMixin,
    ],

    data() {
        return this.initialState();
    },

    computed: {
        ...mapGetters({
            selectedPlayerNicknames: 'livePlayers/selectedPlayerNicknames',
            selectedPlayer: 'livePlayers/selectedPlayer'
        }),
    },

    watch: {
        isOpen: function () {
            if (this.isOpen) {
                if (this.$refs && this.$refs.NetworkDropdownRef) {
                    this.$refs.NetworkDropdownRef.resetNetwork();
                }
                this.resetForm();
                if (this.action == 'create') {
                    this.populateForm();
                }

            }
        },
    },
    
    methods: {
        initialState() {
            return {
                submitting: false,
                form: {
                    user: null,
                    network: null,
                    name: null,
                },
                rules: {
                    name: [
                        v => !!v || 'Por favor preencha o campo',
                    ],
                    nicknameDropdown: [
                        (v) => !!v || 'Por favor introduza uma sala'
                    ],
                },
            };
        },

        async handleSubmit() {
            if (!this.$refs.formCreateNickname.validate()) {
                return false;
            }

            // Toggle submit button
            this.toggleSubmitting();

            let payload = this.selectedPlayer;

            payload.nicknames = payload.nicknames.concat({
                room: this.form.network ? this.form.network.name : null,
                name: this.form.name,
                network_sharkscope: '',
                ability: '',
            })

            let actionToDispatch = this.action == 'create'
            ? 'livePlayers/addPlayerNickname'
            : 'livePlayers/updatePlayerNickname';
          
            let messageSnackbar = this.action == 'create'
            ? 'Nickname adicionado com sucesso.'
            : 'Nickname editado com sucesso.'

            let result = await this.$store.dispatch(actionToDispatch, payload);

            // Toggle submit button
            this.toggleSubmitting();

            if (result === true) {
                // Show success snackbar
                this.$store.dispatch('UI/showSnackbar', {
                    message: messageSnackbar,
                    color: 'success'
                });
                this.$store.commit('livePlayers/setSelectedPlayerNicknames', payload.nicknames);
                this.refreshNicknames(this.userId);

                this.$emit('reload');
                this.closeDialog();
            }
        },

        populateForm() {
            this.form.name = this.nickname.name;
            this.form.room = this.nickname.room
        },

        toggleSubmitting() {
            this.submitting = !this.submitting;
        },

        refreshNicknames(userId) {
            this.$store.dispatch('livePlayers/fetchSelectedPlayerNicknames', userId);
        },

        closeDialog() {
            this.$emit("close-dialog");
            this.resetForm();
        },

        resetForm() {
            Object.assign(this.$data, this.initialState());
            if (this.$refs.formCreateNickname) {
                this.$refs.formCreateNickname.reset();
            }
        },
    },
};
