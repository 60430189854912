import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth.vue';
import RailAdmin from '@/views/Rail/Admin/Rail-Admin.vue';
import RailUser from '@/views/Rail/User/Rail-User.vue';
import Loading from '@/views/Loading.vue';

export default {
    name: 'Rail',

    mixins: [
      AuthMixin,
    ],

    components: {
      RailAdmin,
      RailUser,
      Loading,
    },

    computed: {
      ...mapGetters({
          userType: 'auth/userType',
          userRoles: 'auth/userRoles',
        }),
  
      componentToShow() {

        if (this._.isEmpty(this.userRoles)) {
          return 'Loading';
        }

        return  this.userIsRailManager
                ? 'RailAdmin'
                : 'RailUser';
      }
    },

    created() {
      this.initialize();
    },

    data() {
      return this.initialState();
    },

    methods: {
      initialize() {
        return;
      },

      initialState() {
        return {};
      },
    }
}