import { mapGetters } from 'vuex';
import DialogCreateEditFactors from '@/components/Scheduling/Dialogs/DialogCreateEditFactors/DialogCreateEditFactors.vue';
import DialogConfirmation from '@/components/Rail/Dialog/DialogConfirmation/DialogConfirmation.vue';
import EmptyStateNoData from '@/components/EmptyStateNoData';
import AuthMixin from '@/mixins/Auth.vue';

export default {
    name: 'TableFactors',

    components: {
        DialogCreateEditFactors,
        DialogConfirmation,
        EmptyStateNoData,
    },

    mixins: [
        AuthMixin
    ],

    data() {
        return this.initialState();
    },

    computed: {
        ...mapGetters({
            factors: 'schedulingFactors/factors',
            loading: 'schedulingFactors/loading',
            error: 'schedulingFactors/error'
        }),
        headers() {
            let headers = [
                { text: 'Nome', value: 'name', sortable: true },
                { text: 'Descrição', value: 'description', sortable: false },
                { text: 'Exclusão', value: 'exclusion', sortable: false },
                { text: 'Cálculo', value: 'calculation', width:"9em", sortable: true },
            ]
            if (this.userHasRole('it_developer')) {
                headers.push({ text: 'Código', value: 'code', sortable: true });
                headers.push({ text: 'Identificador DTO', value: 'DTOproperty', sortable: true });
                headers.push({ text: 'Coluna BD', value: 'column', sortable: true });
                headers.push({ text: 'Valores aceites', value: 'values', width: "9em", sortable: true });
            }
            headers.push({ text: 'Ação', value: 'action', width: "12em", sortable: false });
            return headers;
        }
    },

    created() {
        this.getFactors();
    },

    methods: {
        initialState() {
            return {
                search: null,
                dialogs: {
                    createEditFactor: false,
                    delete: false,
                },
                dialogAction: 'create',
                factorToEdit: undefined,
                idFactorToDelete: null,
                submittingDelete: false,
            };
        },

        getBooleanIcon(atr) {
            return (atr) ? "mdi-check" : "mdi-close";
        },

        toggleDialog(name) {
            this.dialogs[name] = !this.dialogs[name];
        },

        openCreateDialog() {
            this.dialogAction = 'create';
            this.factorToEdit = {};
            this.toggleDialog('createEditFactor');
        },

        openEditDialog(factor) {
            this.dialogAction = 'edit';
            this.factorToEdit = this._.clone(factor);
            this.toggleDialog('createEditFactor');
        },

        // opens DialogConfirmation
        openDialogDeleteFactor(id) {
            this.idFactorToDelete = id;
            this.dialogs.delete = true;
        },

        /**
         * close DialogConfirmation
         * send dispatch to users store to clear errors
         */
        closeDialogDeleteFactor() {
            this.dialogs.delete = false;
            this.$store.dispatch('schedulingFactors/clearError');
        },

        addFactor(factor) {
            this.factors.push(factor);
            this.toggleDialog('createEditFactor');
        },

        updateFactor(factor) {
            let index = this.factors.indexOf(this.factors.find(f => f.id == factor.id));
            this.factors.splice(index, 1, factor);
            this.toggleDialog('createEditFactor');
        },

        removeFactor() {
            let index = this.factors.indexOf(this.factors.find(f => f.id == this.idFactorToDelete));
            this.factors.splice(index, 1);
        },

        async getFactors() {
            await this.$store.dispatch('schedulingFactors/get');
        },

        async deleteFactor() {
            await this.$store.dispatch('schedulingFactors/clearError');

            // Set DialogConfirmation to submitting state
            this.submittingDelete = true;

            await this.$store.dispatch('schedulingFactors/delete', this.idFactorToDelete);

            // re-enables submit button;
            this.submittingDelete = false;

            if (!this.error) {
                // remove factor from list
                this.removeFactor();

                this.idFactorToDelete = null;

                // closes dialog
                this.closeDialogDeleteFactor();
            }
        },
    }
}