import Vue from 'vue';
import { mapGetters } from 'vuex';
import RailMixin from '@/mixins/Rail.vue';
import DataMixin from '@/mixins/Data.vue';
import AuthMixin from '@/mixins/Auth.vue';
import CardStepper from '@/components/CardStepper/CardStepper.vue';
import CardStepperNotes from '@/components/CardStepper/CardStepperNotes/CardStepperNotes.vue';
import CardStepperResume from '@/components/CardStepper/CardStepperResume/CardStepperResume.vue';
import SelectWalletExpansionPanel from '@/components/SelectWalletExpansionPanel/SelectWalletExpansionPanel.vue';
import RowTransfer from '@/components/Rail/RowTransfer/RowTransfer.vue';
import NicknameDropdown from '@/components/Rail/NicknameDropdown/NicknameDropdown.vue';
import AlertError from '@/components/Alerts/AlertError/AlertError.vue';
import DialogViewAttachment from '@/components/Rail/Dialog/DialogViewAttachment/DialogViewAttachment.vue';
import ButtonCopyToClipboard from '@/components/Rail/ButtonCopyToClipboard/ButtonCopyToClipboard.vue';
import DialogConfirmation from '@/components/Rail/Dialog/DialogConfirmation/DialogConfirmation.vue';
import DialogUserNotes from '@/components/Rail/Dialog/DialogUserNotes/DialogUserNotes.vue';
import AlertUserNote from '@/components/Rail/AlertUserNote/AlertUserNote.vue';

export default {
  name: 'CardRequestDepositAdmin',

  components: {
    CardStepper,
    CardStepperNotes,
    CardStepperResume,
    SelectWalletExpansionPanel,
    RowTransfer,
    NicknameDropdown,
    AlertError,
    DialogViewAttachment,
    ButtonCopyToClipboard,
    DialogConfirmation,
    AlertUserNote,
    DialogUserNotes,
  },

  mixins: [
    RailMixin,
    DataMixin,
    AuthMixin,
  ],

  computed: {
    ...mapGetters({
      walletsManagement: 'railWallets/walletsManagement',
      selectedRequest: 'railRequests/selectedRequest',
      walletsByUserID: 'railWallets/walletsAll',
      error: 'railRequests/error',
      humanError: 'railRequests/humanError',
      selectedUser: 'users/selectedUser',
      userNotes: 'users/userNotes',
      teamFilter: 'teams/teamFilter',
    }),
    selectedRequestObservation: function () {
      return this.getObservationByPhase(this.selectedRequest.observations, Vue.prototype.table_requests_phases[Vue.prototype.$rail_ops.operations.bankroll_increment][0]['value']);
    },
  },

  watch: {
    /**
     * triggers populateComponentState (populating some fields)
     * immediate behaves like created()
     */
    selectedRequest: {
      handler() {
        // reset data component
        this.resetComponentState();
        // retrieve management wallets if necessary
        this.fetchData();
        // populate fields
        this.populateComponentState();
      },
      immediate: true,
    }
  },

  data() {
    return this.initializeState();
  },

  methods: {
    initializeState() {
      return {
        panel: 0,
        indexPanel: null,
        selectedPanel: null,
        submittingDelete: false,
        disabledDelete: false,
        cardTitles: [
          'Pedido de Depósito',
          'Carteiras',
          'Resumo'
        ],
        formValidations: [
          true,
          false,
          true,
        ],
        form: {
          transfers: [],
          wallets: [],
          observation: null,
          gyazoLink: null,
          fileName: []
        },
        rules: {
          wallet: [
            v => (v && !!v || 'Required!'),
          ],
          amount: [
            (v) => !!v || 'Por favor introduza o montante',
            (v) => !Number.isNaN(v) || 'Insira um valor numérico',
          ],
        },
        dialogues: {
          viewAttachment: false,
          delete: false,
          userNotes: false,
        },
        observation: {
          url: null,
          type: null,
        }
      }
    },

    /**
     * Validates a specific form step.
     * Assumes form steps are named 'formStep_x' where x is a Int 0 - maxStep-1.
     * 
     * @param {Int} step Identifier of the form step to validate.
     * @returns {Void}
     */
    validateStep(step) {
      const formStepRef = 'formStep_' + (step);
      if (this.$refs[formStepRef]) {
        // Update formValidation[step] in a way the DOM will be reactive
        Vue.set(
          this.formValidations,
          step - 1,
          this.$refs[formStepRef].validate()
        );

        // clean errors if exists
        if (this.error) {
          this.$store.dispatch('railRequests/clearError');
        }
      }
    },

    fetchData() {
      // Preload management wallets
      if (this._.isEmpty(this.walletsManagement)) {
        this.$store.dispatch('railWallets/getWalletsFromManagement');
      }

      if (this.selectedRequest) {
        // get user notes
        if (this.selectedRequest.targetUser) {
          let payload = {
            id: this.selectedRequest.targetUser.id,
            populateUserNotes: true,
          }
          this.$store.dispatch('users/getUserNotes', payload);
        }
      }
    },

    populateComponentState() {
      this.getWalletsFromTargetUser();
      this.retrieveWalletsFromRequest();
      this.populateTransfers();
    },

    /**
     * populate form.wallets with wallets from selectedRequest
     * used in 1st step
     */
    retrieveWalletsFromRequest() {
      // reset state 
      this.form.wallets = [];

      if (this.selectedRequest) {
        this.selectedRequest.requests.forEach(element => {
          const index = this.form.wallets.findIndex(x => x.wallet.id == element.wallet.id);
          if (index === -1) {
            this.form.wallets.push(element);
            return this.form.wallets;
          }
        })
      }
    },

    getWalletsFromTargetUser() {
      //  API Call to retrieve target User wallets
      if (this.selectedRequest.targetUser.id) {
        this.$store.dispatch('railWallets/getWalletsAll', this.selectedRequest.targetUser.id);
      }
    },

    /**
     *  populates form.transfers that will be used in 2nd step
     */
    populateTransfers() {

      if (this._.isEmpty(this.form.wallets)) {
        this.form.wallets = this.retrieveWalletsFromRequest();
      }

      this.form.transfers = this.form.wallets.map(element => {
        return {
          source: null,
          target: element.wallet,
          amount: this.getTotalAmountByWalletCurrency(element.wallet.network.currency.code),
          code: element.wallet.network.currency.code,
          deletePanel: false,
        }
      })
    },

    resetComponentState() {
      // force CardStepper to display 1 step
      this.$store.commit('UI/setCardStepperStep', 1);
      // reset data component state
      Object.assign(this.$data, this.initializeState());
    },

    /**
     * @returns array passed as prop to RowTransfers Component
     */
    getRowTransfers() {
      let transfers = []

      this.form.transfers.forEach(element => {
        transfers.push({
          source: element.source,
          target: element.target,
          amount: {
            value: element.amount
          },
        })
      })

      return transfers;
    },

    /**
     * Retrieve objects with same currency in order to get total amount
     * @param {String} currency 
     */
    getTotalAmountByWalletCurrency(currency) {
      // Retrieve array with objects with the same currency
      let arrayByCurrency = this.selectedRequest.requests.filter(element => element.wallet.network.currency.code == currency);

      // return total amount
      return this._.sumBy(this._.map(arrayByCurrency, 'amount'), 'value');
    },

    /**
     * retrieve fields from CardStepperNotes component
     * @param {Object} payload 
     */
    getInfoFromCardStepperNotes(payload) {
      if (payload) {
        this.form.gyazoLink = payload.gyazoLink;
        this.form.fileName = payload.filename;
      }
    },

    async submit() {
      // Validate
      if (!this.$refs.formBankrollIncrementChild.validate()) {
        return false;
      }

      // Toggle submit button
      this.$refs.CardStepper.toggleSubmitting();

      /**
       * Formulate payload
       */

      // Base
      let payload = {
        id: this.selectedRequest.id,
      }

      // add body formData
      payload.body = new FormData();

      // Add 'observation' field if necessary
      if (this.form.observation) {
        payload.body.append('observation[description]', this.form.observation);
      }

      // Add 'screenshot' field if necessary
      if (!this._.isEmpty(this.form.fileName)) {
        payload.body.append('screenshot', this.form.fileName[0]);
      }

      // Add 'observation[url]' field if necessary
      if (this.form.gyazoLink) {
        payload.body.append('observation[url]', this.form.gyazoLink);
      }

      let transfers = this.getRowTransfers();

      transfers.forEach((element, index) => {
        var transferObject = {
          source: element.source.id,
          target: element.target.id,
          amount: element.amount.value,
        }

        payload.body.append('transfers[' + index + '][source]', transferObject.source);
        payload.body.append('transfers[' + index + '][target]', transferObject.target);
        payload.body.append('transfers[' + index + '][amount]', transferObject.amount);
      });

      // Dispatch to store
      let result = await this.$store.dispatch('railRequests/bankrollIncrementUpdate', payload);

      // Re-enable btn
      if (this.$refs.CardStepper) {
        this.$refs.CardStepper.toggleSubmitting();
      }

      // On success
      if (result === true) {

        let payloadRefresh = {
          params: {}
        }

        // if team exists and isManagement, Add team parameter to payload.params
        if (this.teamFilter && this.userHasRailAccessDivisionDropdown) {
          payloadRefresh.params.team = this.teamFilter;
        }

        // Refresh Requests Transfers and Balances
        this.updateRequestsTransfersAndBalances(payloadRefresh);

        this.updateStatisticsByUserId(this.selectedRequest.targetUser.id);

        // Show success snackbar
        this.$store.dispatch('UI/showSnackbar', {
          message: 'Pedido de Incremento de Banca confirmado com sucesso.',
          color: 'success'
        });

        // change hasFinished state in order to change component
        this.$store.dispatch('TabSlider/setHasFinished', true);
      }
    },

    /**
    * Populates observation.url and observation.name
    * @param {Object} observation 
    * @returns {Boolean} open dialog or opens new page;
    */
    openDialogViewAttachment(observation) {
      if (observation && observation.url) {

        // validate observation type
        let isTypeLink = observation.type == 1;

        // populate fields
        this.observation.url = observation.url;
        this.observation.type = observation.type;

        return isTypeLink
          ? window.open(observation.url, '_blank')
          : this.dialogues.viewAttachment = true;
      }
    },

    /**
     * Opens DialogConfirmation and sets selectedPanel
     * @param {Object} element from panel 
     */
    handlePanel(e, index) {
      this.selectedPanel = e;
      this.indexPanel = index;
      this.dialogues.delete = true;
    },

    /**
    * close DialogConfirmation
    * send dispatch to railRequests store to clear errors
    */
    closeDialogDeleteTransfer() {
      this.dialogues.delete = false;
      this.$store.dispatch('railRequests/clearError');
    },

    /**
     * Remove transfer requested by User
     */
    deleteUserTransferRequest() {
      // remove object in indexPanel position
      this.form.transfers.splice(this.indexPanel, 1);

      // Set DialogConfirmation to submitting state
      this.submittingDelete = true;

      // disables delete
      this.disabledDelete = true;

      // end of submitting State
      this.submittingDelete = false;

      // closes dialog
      this.closeDialogDeleteTransfer();
    },

    openDialogUserNotes() {
      this.dialogues.userNotes = true;
    },
  }
}