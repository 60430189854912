var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.metaLive,"hide-default-footer":"","show-expand":""},scopedSlots:_vm._u([{key:"item.live.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"white-space":"nowrap"}},[_c('span',{staticClass:"font-weight-semibold"},[_vm._v(_vm._s(_vm.getLiveName(item)))])])]}},{key:"item.event.name",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',{style:({ maxWidth: _vm.getMaxWidthColumnName(item)})},[_c('div',_vm._g(_vm._b({staticClass:"table-cell"},'div',attrs,false),on),[_c('span',{staticClass:"font-weight-semibold"},[_vm._v(" "+_vm._s(_vm.getEventName(item))+" ")])])])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getEventName(item)))])])]}},{key:"item.day",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"white-space":"nowrap"}},[_c('span',{staticClass:"font-weight-semibold"},[_vm._v(" "+_vm._s(item.day)+" ")])])]}},{key:"item.buyin",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex",staticStyle:{"white-space":"nowrap"}},[_c('div',{staticClass:"font-weight-semibold"},[_vm._v(" "+_vm._s(_vm.getCurrency(item))+" "),_c('currency-input',{style:({ width: _vm.getCurrencyInputWidth(item.buyin) }),attrs:{"value":item.buyin,"currency":null,"disabled":"","allow-negative":true}})],1)])]}},{key:"item.prize",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex",staticStyle:{"white-space":"nowrap"}},[_c('div',{staticClass:"font-weight-semibold"},[_vm._v(" "+_vm._s(_vm.getCurrency(item))+" "),_c('currency-input',{style:({ width: _vm.getCurrencyInputWidth(item.prize) }),attrs:{"value":item.prize,"currency":null,"disabled":"","allow-negative":true}})],1)])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6","md":"3"}},[_c('span',[_vm._v("N° jogadores entrada")]),_c('div',[_c('span',{staticClass:"font-weight-semibold"},[_vm._v(_vm._s(_vm.getNumPlayersBegin(item)))])])]),_c('v-col',{attrs:{"cols":"6","md":"3"}},[_c('span',[_vm._v("N° jogadores final")]),_c('div',[_c('span',{staticClass:"font-weight-semibold"},[_vm._v(_vm._s(_vm.getNumPlayersEnd(item)))])])]),_c('v-col',{attrs:{"cols":"6","md":"2"}},[_c('span',[_vm._v("N° jogadores total")]),_c('div',[_c('span',{staticClass:"font-weight-semibold"},[_vm._v(_vm._s(item.num_players_total))])])]),_c('v-col',{attrs:{"cols":"6","md":"2"}},[_c('span',[_vm._v("Dificuldade torneio")]),_c('div',[_c('span',{staticClass:"font-weight-semibold"},[_vm._v(_vm._s(_vm.getDifficultyName(item)))])])]),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('span',[_vm._v("N° horas jogadas")]),_c('div',[_c('span',{staticClass:"font-weight-semibold"},[_vm._v(_vm._s(item.hours_played))])])])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }