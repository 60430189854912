import Vue from 'vue';
import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth.vue';
import RailMixin from '@/mixins/Rail.vue';
import TableTransfers from '@/components/Rail/TableTransfers/TableTransfers.vue';
import CardStatistics from '@/components/Rail/Statistics/CardStatistics/CardStatistics.vue';
import UserDropdown from '@/components/Rail/UserDropdown/UserDropdown.vue';
import DatePickerRange from '@/components/DatePickerRange/DatePickerRange.vue';
import TableSwapGroup from '@/components/Rail/TableSwapGroup/TableSwapGroup.vue';

export default {
    name: 'Swaps',

    components: {
        CardStatistics,
        TableTransfers,
        UserDropdown,
        DatePickerRange,
        TableSwapGroup,
    },

    mixins: [
        AuthMixin,
        RailMixin,
    ],

    data() {
        return this.initialState();
    },
    
    created() {
        this.initialize();
    },

    computed: {
        ...mapGetters({
            statistics: 'railStatistics/data',
            statisticsAdmin: 'railStatistics/dataAdmin',
            statisticsLoading: 'railStatistics/loadingDataAdmin',
            transfers: 'railTransfers/transfers',
            heroDatePickerValue: 'UI/heroDatePickerValue',
            swaps: 'railSwaps/swaps',
            swapsTotal: 'railSwaps/swapsTotal',
            swapsLoading: 'railSwaps/loading',
            teamFilter: 'teams/teamFilter',
        }),
        
        cardInfo() {
            if (this.userIsRailManager) {
                return [
                    {
                        name: 'Montante Total',
                        value: this.statisticsAdmin['SWAP'] ? this.statisticsAdmin['SWAP'].value.EUR.value : 0,
                        precision: 2,
                        icon: 'mdi-bank',
                        currencyIcon: Vue.prototype.$default_currency_icon,
                        showCurrency: true,
                        showAltCurrency: false,
                        loading: this.statisticsLoading
                    },
                    {
                        name: 'Nº Swaps',
                        value: this.swapsTotal ? this.swapsTotal : 0,
                        precision: 0,
                        icon: 'mdi-bank',
                        showCurrency: false,
                        showAltCurrency: false,
                        loading: this.swapsLoading,
                    },
                    {
                        name: 'Nº Swaps Pendentes',
                        value: this.pendingSwaps,
                        precision: 0,
                        icon: 'mdi-bank',
                        showCurrency: false,
                        showAltCurrency: false,
                        loading: this.swapsLoading,
                    },
                ];
            } else {
                return [];
            }
        },

        pendingSwaps() {
            return this.swaps ? this.swaps.filter(e => e.isPending && e.railOperation.status == 1).length : '';
        },

        // computed property passed to TableTransfers
        formFilters: {
            get() {
                return this.form;
            },
            set(newVal) {
                this.form = newVal;
            }
        },
        
        computedCalendarDates: {
			get() {
				return this.date;
			},
			set(newValue) {
                this.date = newValue;
				this.$store.commit('UI/setHeroDatePickerValue', newValue);
			}
        },
    },

    watch: {
        teamFilter: function () {
            this.fetchStatistics();
        },
        heroDatePickerValue: function () {
            this.fetchStatistics();
        }
        
    },

    methods: {
        initialState() {
            return {
                date: [],
                form: {
                    user: null,
                }
            }
        },
        async initialize() {
            await this.fetchStatistics();
        },

        async fetchStatistics() {
            // Formulate payload
            let payload = null;
            if (! this._.isEmpty(this.heroDatePickerValue)) {
                payload = {
                    dateBegin: this.heroDatePickerValue[0],
                    dateEnd: this.heroDatePickerValue[1],
                    admin: true,
                };
            }

            // if team exists and isManagement, Add team parameter to payload
            if (this.teamFilter && this.userHasRailAccessDivisionDropdown) {
                payload.team = this.teamFilter;
            }
            
            // Get data
            if (this.userIsRailManager) {
                await this.$store.dispatch('railStatistics/getAdmin', payload);
            } else {
                await this.$store.dispatch('railStatistics/get', payload);
            }
        },
    }
}
