import { mapGetters } from 'vuex';
import AlertError from '@/components/Alerts/AlertError/AlertError.vue';
import ExpenseTypeDropdown from '@/components/Rail/ExpenseTypeDropdown/ExpenseTypeDropdown.vue';

export default {
	name: 'DialogAddEditExpenseType',

	components: {
		AlertError,
		ExpenseTypeDropdown,
	},

	props: {
		isOpen: { type: Boolean, required: true, default: false },
	},

	computed: {
		...mapGetters({
			expenseTypes: 'railExpenses/expenseTypes',
			selectedType: 'railExpenses/selectedType',
			error: 'railExpenses/error',
			humanError: 'railExpenses/humanError',
			heroDatePickerValue: 'UI/heroDatePickerValue',
		}),

		action: function() {
			let output = this.selectedType == null
						 ? 'create'
						 : 'update';
			
			return output;
		},

		title: function() {
			let word = this.action == 'create'
						 ? 'Criar'
						 : 'Editar';
			
			let output = word + ' tipo de despesa';
			return output;
		},

	},

	data() {
		return this.initialState();
	},
	
	methods: {
		initialState() {
			return {
				form: {
					name: null,
					parent: null,
					color: null,
					submitting: false,
					rules: {
						name: [ (v) => !!v || 'Introduza um nome' ],
					}
				},
				options: {
					page: 1,
					itemsPerPage: 10
				}
			};
		},

		closeDialog() {
			this.$emit('close-dialog');
			this.resetForm();

			// Reset the selected type
			this.$store.commit('railExpenses/setSelectedType', null);
		},

		resetForm() {
			Object.assign(this.$data, this.initialState());
		},

		populate() {
			if (this.selectedType) {
				this.form.name = this.selectedType.name;
				this.form.color = this.selectedType.color;

				if (this.selectedType.parentExpenseType) {
					this.form.parent = this.selectedType.parentExpenseType.id;
				}
			}
		},

		generatePayload() {
			let output = {
				name: this.form.name,
				parent: this.form.parent ? this.form.parent : null,
				color: this.form.color ? this.form.color : null,
			};

			output.color = typeof this.form.color == 'object'
				? this.form.color.hex
				: this.form.color;

			if (this.action == 'update') {
				output = {
					id: this.selectedType.id,
					body: output
				};
			}

			return output;
		},

		async submit() {
			// Validate form
			if (! this.$refs.formAddEditExpenseType.validate()) {
				return false;
			}

			// Disable submit btn
			this.toggleFormSubmitting();

			// Define store action
			let storeAction = this.action == 'create'
							  ? 'railExpenses/createType'
							  : 'railExpenses/updateType';

			// Dispatch to store
			let result = await this.$store.dispatch(storeAction, this.generatePayload());

			// Re-enable btn
			this.toggleFormSubmitting();

			// On success
			if (result === true) {
				// Refresh data
				this.$store.dispatch('railExpenses/getTypes');

				if (this.action == 'update') {
					this.refreshExpenses()
				}

				// Close dialog
				this.closeDialog();

				// Define message to show
				let snackbarMsg = this.action == 'create'
									? 'Tipo de despesa criado com sucesso.'
									: 'Tipo de despesa atualizado com sucesso.';

				// Show success snackbar
				this.$store.dispatch('UI/showSnackbar', {
					message: snackbarMsg,
					color: 'success'
				});
			}
		},

		async refreshExpenses() {
			let payload = {
				params: {
					page: this.options.page,
					itemsPerPage: this.options.itemsPerPage,
				}
			}

			// Add date params
			if (!this._.isEmpty(this.heroDatePickerValue)) {
				payload.params.dateBegin = this.heroDatePickerValue[0];
				payload.params.dateEnd = this.heroDatePickerValue[1];
			}
			// API Call
			await this.$store.dispatch('railExpenses/getExpenses', payload);
		},

		toggleFormSubmitting() {
			this.form.submitting = ! this.form.submitting;
		},
	},
};
