/**
 * @prop    {String}        type                    Valid values REQUEST, TRANSFER, SWAP, SESSION, EXPENSE, REPORT.
 * @prop    {String}        message
 */
import Vue from 'vue';
import { mapGetters } from 'vuex';
import RailMixin from '@/mixins/Rail.vue';
import DataMixin from '@/mixins/Data.vue';

export default {
	name: 'DialogDownloadCsv',
    
    props: {
        isOpen: { type: Boolean, required: true, default: false },
        message: { type: String, required: true, default: 'Dialog message Confirmation' },
        type: { type: String, required: true, default: 'REQUEST' },
    },

    mixins: [
        RailMixin,
        DataMixin,
    ],
    
    computed: {
        ...mapGetters({
            exportedRequests: 'railRequests/exportedRequests',
            exportedTransfers: 'railTransfers/exportedTransfers',
            exportedSessions: 'railSessions/exportedSessions',
            exportedExpenses: 'railExpenses/exportedExpenses',
            exportedReports: 'railReports/exportedReports',
            heroDatePickerValue: 'UI/heroDatePickerValue',
            nicknameTransfers: 'nicknames/nicknameTransfers',
        }),

        // filename is based on prop type
        computedFilename: function () {
          let output;
          switch (this.type) {
              case 'REQUEST':
                  this.filename = 'rail-operacoes';
                  break;
              case 'TRANSFER':
                  this.filename = 'rail-transferencias';
                  break;
              case 'SWAP':
                  this.filename = 'rail-swaps';
                  break;
              case 'SESSION':
                  this.filename = 'rail-sessoes';
                  break;
              case 'EXPENSE':
                  this.filename = 'rail-despesas';
                  break;
              case 'REPORT':
                  this.filename = 'rail-reports';
                  break;
              case 'MOVEMENT':
                  this.filename = 'rail-movimentos';
                  break;
              default:
                  this.filename = 'rail-'
                  break;
            }
            output = this.filename + '-' + this.$moment().format('DD-MM-YYYY') + '.csv';
            return output;
        },
    },

    watch: {
        heroDatePickerValue: function () {
            this.fetchDates();
        },
    },
    
    created() {
      return this.fetchDates();
    },

  	data() {
    	return this.initialState();
    },
	
	methods: {
		initialState() {
			return {
                submitting: false,
                menuStart: false,
                menuEnd: false,
                data: [],
                filename: null,
                error: null,
                form: {
                    startDate: null,
                    endDate: null,
                },
                rules: {
                    date: [
                        (v) => !!v || 'Por favor introduza uma data',
                    ]
                }
			};
        },

        // populate start and end dates
        fetchDates() {
            this.form.startDate = this.heroDatePickerValue[0];
            this.form.endDate = this.heroDatePickerValue[1];
        },

        /**
         * Fetches data and filters it.
         * @returns {Array}
         */
        async fetchData() {
            let hasFilters = this.filtersHandler();

            switch (this.type) {
                case 'REQUEST':
                    await this.$store.dispatch('railRequests/getRequests', hasFilters);
                    this.data = this.requestsHandler(this.exportedRequests);
                    break;
                case 'TRANSFER':
                case 'MOVEMENT':
                case 'SWAP':
                    await this.$store.dispatch('railTransfers/get', hasFilters);
                    this.data = this.transfersHandler(this.exportedTransfers);
                    break;
                case 'SESSION':
                    await this.$store.dispatch('railSessions/export', hasFilters);
                    this.data = this.sessionsHandler(this.exportedSessions);
                    break;
                case 'EXPENSE':
                    await this.$store.dispatch('railExpenses/getExpenses', hasFilters);
                    this.data = this.expensesHandler(this.exportedExpenses);
                    break;
                case 'REPORT':
                    await this.$store.dispatch('railReports/get', hasFilters);
                    this.data = this.reportsHandler(this.exportedReports);
                    break;
                default:
                    break;
            }

            return this.data;
        },

        /**
         * Handle filter inputs
         * @returns {String} url path with parameters, if filters doesn't exist it returns false.
         */
        filtersHandler() {
            let payload = {
                export: true,
                params: {},
            };

            if (! this._.isNull(this.form.startDate)) {
                payload.params.dateBegin = this.form.startDate
            }
            
            if (! this._.isNull(this.form.endDate)) {
                payload.params.dateEnd = this.form.endDate;
            }

            payload.params.page = 1;
            payload.params.itemsPerPage = -1;  // -1 = all items

            return payload;
        },
        
        /**
         * @param {*Array} requests 
         * @returns an formatted array to export
         */
        requestsHandler(requests) {
            let output = [];
            if (!this._.isEmpty(requests)) {

                output = requests.map(element => {
                    return {
                        'Data': this.$moment(element.createdAt.date).format('YYYY-MM-DD'),
                        'Operacao': this.getRailOperationName(element.type),
                        'Jogador': this.getUsernameFromRailOperation(element),
                        'Montante': this.getAmount(element),
                        'Sala de jogo': this.getNetworkNames(element),
                        'Carteira destino': this.getTargetNetworkNames(element),
                        'Estado': this.getRailOperationStatus(element.status),
                    }
                })
            };
            return output;
        },

        /**
         * @param {*Array} transfers 
         * @returns an formatted array to export
         */
        transfersHandler(transfers) {
            let output = [];
            let filteredData;

            if (transfers) {
                let withoutSwaps = transfers.filter(e =>  e.railOperation && e.railOperation.type != Vue.prototype.$rail_ops.operations.swap);
                let withSwaps = transfers.filter(e => e.railOperation && e.railOperation.type == Vue.prototype.$rail_ops.operations.swap);
                let movements = this.nicknameTransfers;

                switch (this.type) {
                    case 'REQUEST':
                        filteredData = withoutSwaps;
                        break;
                    case 'SWAP':
                        filteredData = withSwaps;
                        break;
                    case 'MOVEMENT':
                        filteredData = movements;
                        break;
                    default:
                        filteredData = transfers;
                        break;
                }

                output = filteredData.map(element => {
                    return {
                        'Data': this.$moment(element.createdAt.date).format('YYYY-MM-DD HH:mm'),
                        'Operacao': this.getRailOperationName(element.railOperation.type),
                        'Emissor': element.railOperation.user ? element.railOperation.user.displayName : 'Gestao' ,
                        'Nickname Emissor': element.source.name,
                        'Conta Emissor': element.source.network.name,
                        'Destinatário': element.railOperation.targetUser ? element.railOperation.targetUser.displayName : 'Gestao' ,
                        'Nickname Destinatario': element.target.name,
                        'Conta Destinatario': element.target.network.name,
                        'Montante': element.amount.value,
                    };
                });
            };
            return output;
        },

        /**
         * @param {*Array} sessions 
         * @returns an formatted array to export
         */
        sessionsHandler(sessions) {
            let output = [];

            if (sessions) {
                output = sessions.map(element => {
                    return {
                        'Data': this.$moment(element.createdAt.date).format('YYYY-MM-DD'),
                        'Jogador': element.user.displayName,
                        'Salas': this.getNetworksFromSessionNicknames(element),
                        'Num torneios jogados': element.eventsPlayed,
                        'Meditação': this.computeBooleanToVerbal(element.meditation),
                        'Warmup': this.computeBooleanToVerbal(element.warmup),
                        'Balanço (€)': element.pnl.EUR.value
                    };
                });
            };
            return output;
        },

        /**
         * @param {*Array} expenses 
         * @returns an formatted array to export
         */
        expensesHandler(expenses) {
            let output = [];

            if (expenses) {
                expenses.forEach(element => {
                    output.push({
                        'Data': this.$moment(element.createdAt.date).format('YYYY-MM-DD'),
                        'Tipo de despesa': element.expenseType.name,
                        'Categoria': element.expenseType.parentExpenseType ? element.expenseType.parentExpenseType.name : element.expenseType.name,
                        'Carteira': element.transfers[0]['source']['network']['name'],
                        'Montante (€)': element.transfers[0]['amount']['EUR']['value'],
                    });
                });
            };

            return output;
        },

        /**
         * @param {*Array} reports 
         * @returns an formatted array to export
         */
        reportsHandler(reports) {
            let output = [];

            if (reports) {
                reports.forEach(element => {
                    output.push({
                        'Data': this.$moment(element.openDate.date).format('YYYY-MM-DD'),
                        'Operacao': this.getRailOperationName(element.railOperation.type),
                        'Motivo': element.type.name,
                        'Nome': element.userCreator.name,
                        'Observacao Jogador': element.userObservation,
                        'Observacao Administrador': element.adminObservation,
                        'Estado': this.getReportStatus(element),
                    });
                });
            };

            return output;
        },

        /**
         * @param {*Object} item - used in  sessionsHandler()
         * @returns networks name
         */
        getNetworksFromSessionNicknames(item) {
            let output = [];

            if (item) {
                item.sessionNicknames.forEach(element => {
                    output.push(element.nickname.network.name);
                });
            };

            return output;
        },

        /**
         * Uses getSourceNetworkTransfers mixin to get source networks
         * @param {*Object} item  
         * @returns Array with Strings (network names)
         */
        getNetworkNames(item) {
            let output = [];

            let networks = this.getSourceNetworkTransfers(item);
            networks.forEach(element => {
                output.push(element.source.network.name);
            });

            return output;
        },

        /**
         * Uses getTargetWalletTransfers mixin to get target networks
         * @param {*Object} item  
         * @returns Array with Strings (network names)
         */
        getTargetNetworkNames(item) {
            let output = [];

            let networks = this.getTargetWalletTransfers(item);
            networks.forEach(element => {
                output.push(element.target.network.name);
            });

            return output;
        },

		submit() {
            // Toggle submit button
            this.toggleSubmitting();
            
            /**
             * Fetches data based on prop type
             * Handles data in order to be exportable to csv
             * 
             */
            this.fetchData()
                .then((response) => {
                if (! this._.isEmpty(response)) {

                    // triggers <download-csv /> button component
                    this.$refs.downloadButton.$el.click();
                    
                    // Show snackbar
                    this.$store.dispatch('UI/showSnackbar', {
                        message: 'Dados exportados com sucesso.',
                        color: 'success'
                    });
                } else {
                    // Show error
                    this.error = true;
                }
            })
            .catch((error) => {
                // Show error
                this.error = true;
            })
            .then(() => {
                // Re-enable btn
                this.toggleSubmitting();
                
                if (!this.error) {
                    // closes dialog
                    this.closeDialog();
                }
            });
		},

        closeDialog() {
            // Clean error
            this.error = false;
			this.$emit('close-dialog');
		},

		toggleSubmitting() {
            this.submitting = ! this.submitting;
        },

        /**
         * @param {Object} item 
         * @returns {String} value
         */
        getAmount(item) {
            let output;
    
            let fieldToBeSummed = this.opHasSingleCurrency(item) ? 'value' : 'EUR.value';
    
            if (Vue.prototype.$rail_status.finalized == item.status) {
            output = this.getAmountSent(item, fieldToBeSummed);
            } else {
            output = this.getAmountRequest(item, fieldToBeSummed);
            }
            return output;
        },
    },
};