import DatePickerRange from '@/components/DatePickerRange/DatePickerRange.vue';
import moment from 'moment';

export default {
    name: 'RowEditExclusionFactors',

    components: {
        DatePickerRange
    },

    data() {
        return this.initialState();
    },

    created() {
        this.init()
    },

    props: {
        index: {
            type: Number,
            required: true,
            default: 0
        },
        availableFactors: {
            type: Array,
            required: false,
            default() {
                return []
            }
        },
        operators: {
            type: Array,
            required: true,
            default() {
                return []
            }
        },
        networks: {
            type: Array,
            required: true,
            default() {
                return []
            }
        },
        selectedExclusionFactor: {
            type: Object,
            required: true,
            default() {
                return {
                    factor1: -1,
                    comparisonOperator: 1,
                    value1: undefined,
                    value2: undefined,
                    timeRange: undefined
                }
            }
        }
    },

    methods: {
        initialState() {
            return {
                values: "number",
                enums: [],
                selectedFactor: {
                    factor1: -1,
                    comparisonOperator: 1,
                    value1: undefined,
                    value2: undefined,
                    timeRange: undefined
                }
            }
        },

        init() {
            let factor = this.availableFactors.filter(f => f.id == this.selectedExclusionFactor.factor1)[0];
            this.selectedFactor = this._.clone(this.selectedExclusionFactor);
            if (this.selectedExclusionFactor.value1) {
                this.selectedFactor.value1 = (String(this.selectedExclusionFactor.value1).charAt(0) == "[") ? JSON.parse(this.selectedExclusionFactor.value1) : this.selectedExclusionFactor.value1;
            }
            this.updateUIInputs(factor);
        },

        onChange(event) {
            let factor = this.availableFactors.filter(f => f.id == event)[0];
            this.updateUIInputs(factor);
            this.$emit('updateExclusionFactor', this.index);
        },

        // Based on the selected factor, updates the UI inputs shown on the row
        updateUIInputs(factor) {
            if (factor) {
                let factorAcceptedValues = JSON.parse(factor.values);
                if (factorAcceptedValues.type != "decimal" && factorAcceptedValues.type != "integer") {
                    this.selectedFactor.comparisonOperator = 0;

                    if (factorAcceptedValues.type == "enum") {
                        this.values = "enum";
                        this.selectedFactor.value1 = (this.selectedFactor.value1) ? this.selectedFactor.value1 : [];
                        this.enums = factorAcceptedValues.options;
                    }

                    if (factorAcceptedValues.type == "from_database") {
                        this.values = factor.name;
                        this.selectedFactor.value1 = (this.selectedFactor.value1) ? this.selectedFactor.value1 : [];
                    }

                    if (factorAcceptedValues.type == "boolean") {
                        this.values = "boolean"
                        this.selectedFactor.value1 = (this.selectedFactor.value1) ? this.selectedFactor.value1 : true;
                    }
                    if (factorAcceptedValues.type == "dateTime") {
                        this.values = "timeRange"
                    }
                } else {
                    this.selectedFactor.comparisonOperator = (this.selectedFactor) ? this.selectedFactor.comparisonOperator : 1;
                    this.values = "number";
                }
            }
        },

        updateFactorValuesOnParent(index, isTimeRange, updated) {
            if (updated) {
                if (isTimeRange) {
                    let v = this.selectedFactor.timeRange
                    this.selectedFactor.value1 = moment(v[0]).unix().valueOf();
                    this.selectedFactor.value2 = moment(v[1]).unix().valueOf();
                }
                this.$emit('updateExclusionFactor', index)
            }
        },

        removeExclusionFactor(index, factor) {
            this.$emit('removeExclusionFactor', { index, factor })
        }
    },
}