import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import StoreMixin from '@/mixins/Store.vue';

Vue.use(Vuex);
Vue.use(StoreMixin);

const state = {
    transfers: [],
    transfersManagement: [],
    transfersPlayer: [],
    transfersPending: [],
    transfersRejected: [],
    exportedTransfers: [],
    wallets: [],
    selectedTransfer: null,
    loading: false,
    transfersManagementLoading: false,
    transfersPlayerLoading: false,
    transfersPendingLoading: false,
    transfersRejectedLoading: false,
    error: null,
    humanError: null,
    transfersTotal: null,
    transfersManagementTotal: null,
    transfersPlayerTotal: null,
    transfersPendingTotal: null,
    transfersRejectedTotal: null,
};

const getters = {
    transfers: (state) => state.transfers,
    transfersManagement: (state) => state.transfersManagement,
    transfersPlayer: (state) => state.transfersPlayer,
    transfersPending: (state) => state.transfersPending,
    transfersRejected: (state) => state.transfersRejected,
    exportedTransfers: state => state.exportedTransfers,
    wallets: (state) => state.wallets,
    selectedTransfer: (state) => state.selectedTransfer,
    loading: (state) => state.loading,
    transfersManagementLoading: (state) => state.transfersManagementLoading,
    transfersPlayerLoading: (state) => state.transfersPlayerLoading,
    transfersPendingLoading: (state) => state.transfersPendingLoading,
    transfersRejectedLoading: (state) => state.transfersRejectedLoading,
    error: (state) => state.error,
    humanError: (state) => state.humanError,
    transfersTotal: (state) => state.transfersTotal,
    transfersManagementTotal: (state) => state.transfersManagementTotal,
    transfersPlayerTotal: (state) => state.transfersPlayerTotal,
    transfersPendingTotal: (state) => state.transfersPendingTotal,
    transfersRejectedTotal: (state) => state.transfersRejectedTotal,
};

const mutations = {
    set(state, payload) {
        state.transfers = payload
    },
    setManagementTransfers(state, payload) {
        state.transfersManagement = payload;
    },
    setPlayerTransfers(state, payload) {
        state.transfersPlayer = payload;
    },
    setPendingTransfers(state, payload) {
        state.transfersPending = payload;
    },
    setRejectedTransfers(state, payload) {
        state.transfersRejected = payload;
    },
    setExportedTransfers(state, payload) {
        state.exportedTransfers = payload;
    },
    setWallets(state, payload) {
        state.wallets = payload
    },
    setSelected(state, payload) {
        state.selectedTransfer = payload
    },
    setLoading(state, payload) {
        state.loading = payload;
    },
    setManagementTransfersLoading(state, payload) {
        state.transfersManagementLoading = payload;
    },
    setPlayerTransfersLoading(state, payload) {
        state.transfersPlayerLoading = payload;
    },
    setPendingTransfersLoading(state, payload) {
        state.transfersPendingLoading = payload;
    },
    setRejectedTransfersLoading(state, payload) {
        state.transfersRejectedLoading = payload;
    },
    setError(state, payload) {
        state.error = payload;
    },
    setHumanError(state, payload) {
        state.humanError = payload;
    },
    add(state, payload) {
        if (!state.transfers || !Array.isArray(state.transfers))
            state.transfers = [];
        state.transfers.unshift(payload);
    },
    update: (state, payload) => {
        const session = state.transfers.filter(
            i => i.id == payload.id
        )[0];

        const index = state.transfers.findIndex(
            i => i.id == payload.id
        );

        const updatedItems = [
            ...state.transfers.slice(0, index),
            session,
            ...state.transfers.slice(index + 1)
        ];

        state.transfers = updatedItems;
    },
    delete: (state, payload) => {
        const index = state.transfers.findIndex(
            i => i.id == payload.id
        );
        state.transfers.splice(index, 1);
    },
    setTotalTransfers(state, payload) {
        state.transfersTotal = payload;
    },
    setManagementTransfersTotal(state, payload) {
        state.transfersManagementTotal = payload;
    },
    setPlayerTransfersTotal(state, payload) {
        state.transfersPlayerTotal = payload;
    },
    setPendingTransfersTotal(state, payload) {
        state.transfersPendingTotal = payload;
    },
    setRejectedTransfersTotal(state, payload) {
        state.transfersRejectedTotal = payload;
    },
};

const actions = {
    async get({ commit, state }, payload) {

        // declare variables
        let setItems;
        let setTotalItems;
        let setLoadingItems;
        let handleMutation = StoreMixin.methods.handleRailTransfersMutations();

        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/rails/transfers/';

        // Formulate URL with query string with generateQueryParamsUrl mixin function
        if (payload && payload.params) {
            
            // if status is empty , delete it. (TableTransfers, 1st tab)
            if (payload.params.managementInvolved === '') delete payload.params.managementInvolved;
            let pending = payload.params.pending;
            let status = payload.params.status;
            
            handleMutation = StoreMixin.methods.handleRailTransfersMutations(payload.params.managementInvolved, pending, status);
            request_url = StoreMixin.methods.generateQueryParamsUrl(request_url, payload.params);
        }

        // mutations that will be used to populate states based on status parameter
        setItems = handleMutation.transfers;
        setTotalItems = handleMutation.total;
        setLoadingItems = handleMutation.loading;

        // clear states
        commit('setError', null);
        commit(setItems, []);
        commit(setTotalItems, 0);
        commit(setLoadingItems, true);

        // Execute request & return
        let output = false;

        return axios.get(request_url)
        .then((response) => {
            output = response.data.success;
            if (output) {
                if (payload && payload.export) {
                    commit('setExportedTransfers', response.data.data);
                }
                commit(setTotalItems, response.data.total);
                commit(setItems, response.data.data);
            }
        })
        .catch(function (error) {
            commit('setError', error.response ? error.response.data.message : error);
            commit('setHumanError', error.response ? error.response.data.human_message : error);
            output = false;
        })
        .then(function () {
            commit(setLoadingItems, false);
            return output;
        });
    },

    async getTransferById({ commit, dispatch }, payload) {
        // clear state
        commit('setSelected', null);
        
        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/rails/transfers/' + payload;

        // Execute request & return
        let output = false;

        return axios.get(request_url)
        .then((response) => {
            output = response.data.success;
            if (output) {
                commit('setSelected', response.data.data);
            } else {
                commit('setError', response.data.message);
                commit('setHumanError', response.data.human_message);
            }
        })
        .catch(function (error) {
            commit('setError', error.response ? error.response.data.message : error);
            commit('setHumanError', error.response ? error.response.data.human_message : error);
            output = false;
        })
        .then(function () {
            return output;
        });
    },

    async create({ commit, dispatch }, payload) {
        dispatch('clearError');

        // Configure request
        const request_url = Vue.prototype.$url_api + 'v2/rails/transfers/';

        var requestConfig = {
            method: 'POST',
            url: request_url,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            data: payload
        }

        // Execute request & return
        let output = false;

        return axios(requestConfig)
        .then(function (response) {
            output = response.data.success;

            if (output) {
                commit('add', response.data.data);
                commit('setSelected', response.data.data);
            } else {
                commit('setError', response.data.message);
                commit('setHumanError', response.data.human_message);
            }
        })
        .catch(error => {
            commit('setError', error.response ? error.response.data.message : error);
            commit('setHumanError', error.response ? error.response.data.human_message : error);
            output = false;
        })
        .then(function () {
            return output;
        });
    },

    async update({ commit, dispatch }, payload) {
        dispatch('clearError');

        // Handle payload data
        let data = JSON.stringify(payload.body);

        // Configure request
        const request_url = Vue.prototype.$url_api + 'v2/rails/transfers/' + payload.id;
        let config = {
            method: 'POST',
            url: request_url,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data,
        }

        // Execute request & return
        let output = false;

        return axios(config)
        .then(function (response) {
            output = response.data.success;

            if (! output){
                commit('setError', response.data.message);
                commit('setHumanError', response.data.human_message);
            }
        })
        .catch(function (error) {
            commit('setError', error.response ? error.response.data.message : error);
            commit('setHumanError', error.response ? error.response.data.human_message : error);
            output = false;
        })
        .then(function () {
            return output;
        });
    },

    reset({ commit }) {
        commit('setWallets', []);
        commit('set', []);
        commit('setManagementTransfers', []);
        commit('setPlayerTransfers', []);
        commit('setPendingTransfers', []);
        commit('setRejectedTransfers', []);
        commit('setExportedTransfers', []);
        commit('setSelected', null);
        commit('setTotalTransfers', null);
        commit('setManagementTransfersTotal', null);
        commit('setPlayerTransfersTotal', null);
        commit('setPendingTransfersTotal', null);
        commit('setRejectedTransfersTotal', null);
        commit('setLoading', true);
        commit('setManagementTransfersLoading', true);
        commit('setPlayerTransfersLoading', true);
        commit('setPendingTransfersLoading', true);
        commit('setRejectedTransfersLoading', true);
        commit('setError', null);
        commit('setHumanError', null);
    },

    clearError({ commit }) {
        commit('setError', null);
        commit('setHumanError', null);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};