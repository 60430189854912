<script>
import Vue from 'vue';
import moment from 'moment';

export default {
    methods: {
        getDaysLeft(expiryDate) {
            let diffDays = 0;
            if (expiryDate)
                diffDays = moment(expiryDate).diff(moment(), 'Days');

            return diffDays;
        },
        dayOrDays(num) {
            return num == 1 ? 'dia' : 'dias';
        },
        getAccessStatusString(item) {
            //const accessDate = item.updatedAt_video_requests;
            const expiryDate = item.expiry_video_requests;
            const status = item.status_video_requests;

            // Get int number of days to expiry
            const days = this.getDaysLeft(expiryDate);

            // Formulate output
            let output = '';
            switch (status) {
                case Vue.prototype.$video_requests.status.pending:
                    // Pending
                    output = 'Pendente';
                    break;
                case Vue.prototype.$video_requests.status.approved:
                    // Approved
                    if (days > 0)
                        output = 'Expira em ' + days + ' ' + this.dayOrDays(days);
                    else if (expiryDate == null)
                        output = 'Não expira';
                    else
                        output = 'Expira hoje';
                    break;
                case Vue.prototype.$video_requests.status.rejected:
                    // Rejected
                    output = 'Rejeitado';
                    break;
                case Vue.prototype.$video_requests.status.expired:
                    // Expired
                    output = 'Expirado';
                    break;
                case Vue.prototype.$video_requests.status.complete:
                    // Complete
                    output = 'Concluído';
                    break;
                default:
                    break;
            }

            return output;
        },
        getDaysLeftColor(item) {
            let output = 'black';
            switch (item.status_video_requests) {
                case Vue.prototype.$video_requests.status.pending:
                    // Pending
                    output = 'black';
                    break;
                case Vue.prototype.$video_requests.status.approved:
                    // Approved
                    output = 'green';
                    break;
                case Vue.prototype.$video_requests.status.rejected:
                    // Rejected
                    output = 'red';
                    break;
                case Vue.prototype.$video_requests.status.expired:
                    // Expired
                    output = 'orange';
                    break;
                case Vue.prototype.$video_requests.status.complete:
                    // Complete
                    output = 'green';
                    break;
                default:
                    break;
            }

            return output;
        },
    }
}
</script>