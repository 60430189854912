/**
* @prop    {Array[Int]}    excludedNetworks        Hide given networks.
* @prop    {Array[Int]}    filterNetworks          Filter given networks.
* @prop    {Array[Int]}    currencies              Show ONLY networks with these currencies.
*/

import { mapGetters } from 'vuex';

export default {
    name: 'MetadataDropdown',

    props: {
        value: {
            type: Array,
            required: false,
            default: () => []
        },
        label: {
            type: String,
            required: false,
            default: 'Metadata'
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        solo: {
            type: Boolean,
            required: false,
            default: false
        },
        outlined: {
            type: Boolean,
            required: false,
            default: true
        },
        rules: {
            type: Array,
            required: false,
            default: () => [
                (v) => !!v || 'Por favor preencha o campo metadata',
            ]
        }
    },

    data() {
        return this.initialState();
    },

    created() {
        this.initialize();
        this.emitChange();
    },

    mounted() {
        // Clear error triggered naturally when element is loaded
        this.$refs.formMetadataDropdown.resetValidation();
    },
    
    computed: {
        ...mapGetters({

        }),
    
        // Synthetic v-model
        computedValue: {
            get: function () {
                return this.metadata;
            },
            set: function (newValue) {
                this.metadata = newValue;
            }
        },

        // return prop rules
        computedRules: function () {
            return this.rules;
        },
    },

    watch: {
        value: function(newValue) {
            this.computedValue = newValue;
        },
    },

    methods: {
        initialState() {
            return {
                metadata: null,
                data: [
                    { id: 1, name: 'name' },
                    { id: 2, name: 'number' },
                    { id: 3, name: 'email' },
                    { id: 4, name: 'addr_btc_btc' },
                    { id: 5, name: 'addr_usdt_erc20' },
                ],
            }
        },

        initialize() {
            // Initial value passed through prop
            this.computedValue = this.value;
            this.fetchData();
        },

        async fetchData() {

        },

        reset() {
            Object.assign(this.$data, this.initialState());
            this.$refs.formMetadataDropdown.reset();
        },

        resetMetadata() {
            this.metadata = null;
            this.$refs.formMetadataDropdown.reset();
        },

        emitChange() {
            this.$emit('input', this.computedValue);
            this.$emit('change', this.computedValue);
        },
    }
}