import Vue from 'vue';
import { mapGetters } from 'vuex';
import DialogAddEditSession from '@/components/Rail/Dialog/DialogAddEditSession/DialogAddEditSession.vue';
import DialogAddEditSessionSpins from '@/components/Rail/Dialog/DialogAddEditSessionSpins/DialogAddEditSessionSpins.vue';
import ButtonAction from '@/components/Rail/ButtonAction/ButtonAction.vue';
import AuthMixin from '@/mixins/Auth.vue';
import RailMixin from '@/mixins/Rail.vue';

export default {
    name: 'CardMainActions',

    components: {
        DialogAddEditSession,
        DialogAddEditSessionSpins,
        ButtonAction,
    },

    computed: {
        ...mapGetters({
            userTeams: 'auth/userTeams',
        }),
    },

    mixins: [
        AuthMixin,
        RailMixin,
    ],

    data() {
        return this.initialState();
    },

    methods: {
        initialState() {
            return {
                dialogues: {
                    addEditSession: false,
                    addEditSessionSpins: false,
                }
            }
        },

        toggleDialog(dialog) {
            this.dialogues[dialog] = ! this.dialogues[dialog];
        },

        async handleDialogSession() {

             if (this._.isEmpty(this.userTeams)) {
                 await this.$store.dispatch('auth/getUser', this.userTrackerId);
            }
            
            // map userTeams by name and verifies if user belongs to Spins Division
            let userTeamsMap = this.userTeams.map(e => e.name);

            let isSpinDivision = userTeamsMap.includes("Spins Division");

            // if user belongs to Spins Division, open Dialog Spins
            return isSpinDivision
                ? this.toggleDialog('addEditSessionSpins')
                : this.toggleDialog('addEditSession');
           
        },
        
        async setCurrentCard(cardName) {
            // set current card
            this.$store.commit('TabSlider/setCard', cardName);
            // force CardStepper to display 1 step
            this.$store.commit('UI/setCardStepperStep', 1);

            // clear states
            this.$store.commit('TabSlider/setHasFinished', false);
            this.$store.dispatch('railRequests/clearError');
            this.$store.commit('railRequests/setSelectedRequest', null);
            this.$store.commit('railTransfers/setSelected', null);
            this.$store.commit('users/setSelectedUser', null);
            this.$store.commit('railExpenses/setIsExpense', false);
            // clear balance profit from UC - SPLIT
            this.$store.commit('railOverview/setCardBalanceProfitUSD', null);
            this.$store.commit('railOverview/setCardBalanceProfitEUR', null);

            // routing
            await this.$router.push({ path: Vue.prototype.$tab_slider.tabs.action }).catch(() => { });
            this.$store.commit('TabSlider/setSelectedTab', Vue.prototype.$tab_slider.tabs.action);
        },
    }
}