import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import StoreMixin from '@/mixins/Store.vue';

Vue.use(Vuex);
Vue.use(StoreMixin);

const state = {
    pokerTablePlayers: [],
    pokerTablePlayersLoading: true,
    selectedPokerTablePlayer: null,
    error: null,
    humanError: null,
};

const getters = {
    pokerTablePlayers: (state) => state.pokerTablePlayers,
    pokerTablePlayersLoading: (state) => state.pokerTablePlayersLoading,
    selectedPokerTablePlayer: (state) => state.selectedPokerTablePlayer,
    error: (state) => state.error,
    humanError: (state) => state.humanError,
};

const mutations = {
    addPlayer: (state, payload) => {
        if (!state.pokerTablePlayers || !state.pokerTablePlayers.length)
            state.pokerTablePlayers = [];

        state.pokerTablePlayers.unshift(payload);
    },
    setPokerTablePlayers: (state, payload) => { state.pokerTablePlayers = payload },
    setPokerTablePlayersLoading: (state, payload) => { state.pokerTablePlayersLoading = payload },
    setError: (state, payload) => { state.error = payload },
    setHumanError: (state, payload) => { state.humanError = payload },
    setSelectedPokerTablePlayer: (state, payload) => { state.selectedPokerTablePlayer = payload },
};

const actions = {
    // Fetch the list of live players
    async addPlayerToPokerTable({ commit }, payload) {
        commit('setPokerTablePlayersLoading', true);
        // Handle payload data
        var data = JSON.stringify(payload);

        // Configure request
        const request_url = Vue.prototype.$url_api_live + 'game-modes';

        var config = {
            method: 'POST',
            url: request_url,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        }

        // Execute request & return
        let output = false;

        return axios(config)
            .then(function (response) {
                if (response.status === 201) {
                    commit('setPokerTablePlayers', data);
                    output = true;
                } else {
                    commit('setError', response.data.message);
                    commit('setHumanError', response.data.human_message);
                }
            })
            .catch(function (error) {
                commit('setError', error.response ? error.response.data.message : error);
                commit('setHumanError', error.response ? error.response.data.human_message : error);
                output = false;
            })
            .then(function () {
                commit('setPokerTablePlayersLoading', true);
                return output;
            });
    },

    // Fetch details of a selected player
    async getPokerTable({ commit }) {
        commit('setPokerTablePlayers', null);
        commit('setPokerTablePlayersLoading', true);
        commit('setError', null);

        try {
            const response = await axios.get(Vue.prototype.$url_api_live + `me/game-modes`);
            if (response.status === 200) {
                commit('setPokerTablePlayers', response.data[0]);
            } else {
                commit('setError', response.data.message);
                commit('setErrorStatus', response.status);
            }
        } catch (error) {
            commit('setPokerTablePlayersLoading', false);
            commit('setError', error.response ? error.response.data.message : error.message);
            commit('setErrorStatus', error.response ? error.response.status : null);
        }
        commit('setPokerTablePlayersLoading', false);
    },

    async updatePokerTable({ commit, dispatch }, payload) {
        commit('setPokerTablePlayersLoading', true);
        // Handle payload data
        let data = JSON.stringify(payload);

        // Configure request_url
        const request_url = Vue.prototype.$url_api_live + 'game-modes/' + payload.id;

        // Configure request
        let config = {
            method: 'PUT',
            url: request_url,
            headers: { 
              'Content-Type': 'application/json'
            },
            data : data
        };

        // Execute request & return
        let output = false;

        return axios(config)
        .then(function (response) {
            output = response.status == 200;
            if (output) {
                commit('setPokerTablePlayers', response.data);
            } else {
                commit('setError', response.data.message);
                commit('setHumanError', response.data.human_message);
            }
        })
        .catch(function (error) {
            commit('setError', error.response ? error.response.data.message : error);
            commit('setHumanError', error.response ? error.response.data.human_message : error);
            output = false;
        })
        .then(function () {
            commit('setPokerTablePlayersLoading', false);
            return output;
        });
    },

    clearErrors({ commit }) {
        commit('setError', null);
        commit('setHumanError', null);
        commit('setErrorStatus', null);
    },

    resetStore({ commit }) {
        commit('setPokerTablePlayers', []);
        commit('setError', null);
        commit('setHumanError', null);
        commit('setSelectedPokerTablePlayer', null);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
