import { mapGetters } from 'vuex';
import EmptyStateNoData from '@/components/EmptyStateNoData';

export default {
    name: 'AreaChartCumulativePNL',

    components: {
        EmptyStateNoData,
    },

	props: {
		title: {
			type: String,
			required: false,
			default: '',
		},
		subtitle: {
			type: String,
			required: false,
			default: '',
		},
		data: {
			type: Array,
			required: false,
			default: () => [],
		},
		labels: {
			type: Array,
			required: false,
			default: () => [],
		},
		height: {
			type: String,
			required: false,
			default: '400px',
        },
        loading: {
            type: Boolean,
            required: false,
            default: false
        },
	},

    // Setup vars used in the graph so it auto-updates if the data changes
    computed: {
        ...mapGetters({
            themeDarkMode: 'UI/themeDarkMode',
        }),

        series: function () {
            return [{
                name: this.title,
                data: this.data,
            }];
        },
        options: function () {
            return {
                chart: {
                    type: 'area',
                    zoom: {
                        enabled: false
                    },
                    toolbar: {
                        show: false
                    },
                    foreColor: '#f6f7f8',
                    background: this.themeDarkMode ? 'dark' : 'light',
                },
                labels: this.labels,
                colors: ['#FFC900'],
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'straight'
                },
                xaxis: {
                    type: 'datetime',
                    labels: {
                        format: 'dd/MM',
                        show: true,
                        style: {
                            colors: this.themeDarkMode ? '#FFFFFF' : '#000000',
                        },
                    }
                },
                yaxis: {
                    opposite: true,
                    labels: {
                        show: true,
                        style: {
                            colors:  this.themeDarkMode ? '#FFFFFF' : '#000000',
                        },
                    }
                },
                legend: {
                    horizontalAlign: 'left'
                },
                theme: {
                    mode: this.themeDarkMode ? 'dark' : 'light',
                },
            };
        },
    },

    watch: {
        themeDarkMode: function () {
            if (this.$refs.AreaChart) {
                this.$refs.AreaChart.refresh();
            }
        }
    },

    data() {
        return this.initialState();
    },

    methods: {
        initialState() {
            return {};
        },
    },
}