import { mapGetters } from 'vuex';
import VideoReportsPreview from '@/components/VideoReports/dialogues/VideoReports-Preview/VideoReports-Preview.vue';
import AlertNoData from '@/components/AlertNoData';
import EmptyStateNoData from '@/components/EmptyStateNoData';
import VideoRequestsMixin from '@/mixins/VideoRequests.vue';

export default {
    name: 'table-video-requests-user',
    components: {
        'video-reports-preview': VideoReportsPreview,
        AlertNoData,
        EmptyStateNoData,
    },
    mixins: [
        VideoRequestsMixin
    ],
    computed: {
        ...mapGetters({
            requestedVideos: 'videoRequests/requestedVideos',
            selectedVideo: 'videoRequests/selectedVideo',
            hasLoaded: 'videoRequests/hasLoaded',
        }),
    },
    data() {
        return this.initialState();
    },
    async created() {
        await this.initialize();
    },
    methods: {
        async initialize() {
            await this.$store.dispatch('videoRequests/getRequestVideos');
        },
        initialState() {
            return {
                dataLoadingFinished: false,
                dataTable: {
                    headers: [
                        { text: 'Info', value: 'info' },
                        { text: 'Estado', value: 'state' },
                        { text: 'Data de acesso', value: 'accessDate' },
                        { text: 'Data de expiração', value: 'expiryDate' },
                        { text: 'Video', value: 'video' },
                        { text: 'Relatório', value: 'report' },
                    ],
                    footerProps: {
                        'items-per-page-options': this.$itemsPerPageOptions,
                    },
                    search: null,
                    itemsPerPage: 10,
                },
                tabs: {
                    current: 0,
                    headers: ['Em curso', 'Passados'],
                },
                dialogues: {
                    reportPreview: false,
                },
            };
        },
        filter() {
            if (!this.requestedVideos.length) return [];

            switch (this.tabs.current) {
                case 0:
                    return this.requestedVideos.filter(
                        (e) => e.status_video_requests < 2
                    );
                case 1:
                    return this.requestedVideos.filter(
                        (e) => e.status_video_requests >= 2
                    );
                default:
                    return this.requestedVideos;
            }
        },
        reportStatus(report) {
            if (report) {
                return 'Entregue';
            } else {
                return 'Não entregue';
            }
        },
        reportStatusColor(report) {
            if (report) {
                return 'green white--text';
            } else {
                return 'red white--text';
            }
        },
        dialogReportPreview(item) {
            if (item) {
                this.$store.commit(
                    'videoRequests/setSelectedVideo',
                    item
                );

                this.$refs.dialogReportPreview.populate(item);

                this.openDialog('reportPreview');
            }
        },
        openDialog(d) {
            this.dialogues[d] = true;
        },
        closeDialog() {
            this.dialogues.reportPreview = false;
        },
        select(item) {
            if (item) {
                this.$store.commit('videoRequests/setSelectedVideo', item);
            }
        },
    },
};
