import Vue from 'vue';
import { mapGetters } from 'vuex';
import TableSessions from '@/components/Rail/TableSessions/TableSessions.vue'
import ChartSessions from '@/components/Rail/Statistics/ChartSessions.vue'
import DialogViewSession from '@/components/Rail/DialogViewSession/DialogViewSession.vue';
import DialogViewSessionSpins from '@/components/Rail/Dialog/DialogViewSessionSpins/DialogViewSessionSpins.vue';
import UserDropdown from '@/components/Rail/UserDropdown/UserDropdown.vue';
import NetworkDropdown from '@/components/Rail/NetworkDropdown/NetworkDropdown.vue';
import GroupDropdown from '@/components/Rail/GroupDropdown/GroupDropdown.vue';
import DatePickerRange from '@/components/DatePickerRange/DatePickerRange.vue';
import CardStatistics from '@/components/Rail/Statistics/CardStatistics/CardStatistics.vue';
import AuthMixin from '@/mixins/Auth.vue';
import BarChartProfitPerNetwork from '@/components/Charts/BarChartProfitPerNetwork/BarChartProfitPerNetwork.vue';

export default {
    name: 'Sessions',

    components: {
        TableSessions,
        ChartSessions,
        DialogViewSession,
        UserDropdown,
        NetworkDropdown,
        GroupDropdown,
        DatePickerRange,
        DialogViewSessionSpins,
        CardStatistics,
        BarChartProfitPerNetwork
    },

    mixins: [
        AuthMixin,
    ],

    computed: {
        ...mapGetters({
            sessions: 'railSessions/sessions',
            loading: 'railSessions/loading',
            totalSessions: 'railSessions/totalSessions',
            selectedTab: 'TabSlider/selectedTab',
            selectedSession: 'railSessions/selectedSession',
            heroDatePickerValue: 'UI/heroDatePickerValue',
            statisticsAdmin: 'railStatistics/dataAdmin',
            statisticsLoading: 'railStatistics/loadingDataAdmin',
            teamFilter: 'teams/teamFilter',
        }),

        cardInfo() { 
            return [
                {
                    name: 'Jogadores ativos rail',
                    value: this.statisticsAdmin['RAIL_USERS_ACTIVE'] ? this.statisticsAdmin['RAIL_USERS_ACTIVE'].percentageValue.pct * 100 : 0,
                    ativePlayers: this.statisticsAdmin['RAIL_USERS_ACTIVE'] ? this.statisticsAdmin['RAIL_USERS_ACTIVE'].percentageValue.selected : 0,
                    totalPlayers: this.statisticsAdmin['RAIL_USERS_ACTIVE'] ? this.statisticsAdmin['RAIL_USERS_ACTIVE'].percentageValue.total : 0,
                    icon: 'mdi-registered-trademark',
                    showCurrency: false,
                    showAltCurrency: false,
                    valuePrecision: 0,
                    tooltip: 'Este valor representa o número de jogadores ativos que registaram pelo  menos uma sessão durante o intervalo de tempo especificado'
                },
                {
                    name: 'Jogadores ativos sharkscope',
                    value: this.statisticsAdmin['SHARKSCOPE_USERS_ACTIVE'] ? this.statisticsAdmin['SHARKSCOPE_USERS_ACTIVE'].percentageValue.pct * 100 : 0,
                    ativePlayers: this.statisticsAdmin['SHARKSCOPE_USERS_ACTIVE'] ? this.statisticsAdmin['SHARKSCOPE_USERS_ACTIVE'].percentageValue.selected : 0,
                    totalPlayers: this.statisticsAdmin['SHARKSCOPE_USERS_ACTIVE'] ? this.statisticsAdmin['SHARKSCOPE_USERS_ACTIVE'].percentageValue.total : 0,
                    icon: 'mdi-shark',
                    showCurrency: false,
                    showAltCurrency: false,
                    valuePrecision: 0,
                    tooltip: 'Este valor representa o número de jogadores ativos que jogaram pelo menos um torneio durante o intervalo de tempo especificado'
                },
            ];
        },

        // computed property passed to TableRequests
        formFilters: {
            get() {
                return this.form;
            },
            set(newVal) {
                this.form = newVal;
            }
        },

        computedCalendarDates: {
			get() {
				return this.dates;
			},
			set(newValue) {
                this.dates = newValue;
				this.$store.commit('UI/setHeroDatePickerValue', newValue);
			}
        },
    },

    watch: {
        /**
         * runs everytime some nested object has changed
         * sets formFilters computed property that will be passed as prop to TableRequests
         */
        form: {
            handler(newVal) {
                this.formFilters = newVal;
            },
            deep: true,
        },

        /**
         * Whenever heroDatePickerValue has changed, we set new values to computedCalendarDates computed property
         */
        heroDatePickerValue: function () {
            this.computedCalendarDates = this.heroDatePickerValue;
            this.fetchStatistics();
        },

        teamFilter: function () {
            this.fetchStatistics();
        }        
    },

    created() {
        // if current route name is 'Sessão', triggers fetchSingleSession()
        if (this.selectedTab == Vue.prototype.$tab_slider.tabs.sessions && this.$router.currentRoute.name == 'Sessão') {
            // fetches single session if URL has an id
            this.fetchSingleSession();
        }

        // Save data passed to this component via mapGetters
        this.computedCalendarDates = this.heroDatePickerValue;
    },

    data() {
        return this.initialState()
    },

    methods: {
        initialState() {
            return {
                dates: [],
                form: {
                    user: null,
                    group: null,
                },
                dialogs: {
                    viewSession: false,
                    viewSessionSpins: false,
                }
            }
        },

        // fetches single session based on route params id and opens Dialog
        async fetchSingleSession() {
            if (this.$route.params.id) {
                await this.$store.dispatch('railSessions/getSessionById', this.$route.params.id);
                if (this.selectedSession) {
                    this.handleDialogViewSession();
                }
            }
        },

        handleDialogViewSession() {
            if (this.selectedSession) {
                // map userTeams by name and verifies if user belongs to Spins Division
                let userTeams = this.selectedSession.user
                    ? this.selectedSession.user.teams.map(e => e.name)
                    : [];
                
                let isSpinDivision = userTeams.includes("Spins Division");

                // if user belongs to Spins Division, open Dialog Spins
                return isSpinDivision
                    ? this.openDialogViewSession(this.selectedSession, 'viewSessionSpins')
                    : this.openDialogViewSession(this.selectedSession, 'viewSession');
            }
        },

        openDialogViewSession(item, dialog) {
            this.setActiveItem(item);
            this.dialogs[dialog] = true;

            if (this.$route.name == 'Sessões' || this.$route.name == 'Sessão') {
                // routing
                this.routing(item.id);
            }
        },

        closeDialogViewSession() {
            this.dialogs.viewSession = false;

            if (this.$route.name == 'Sessão') {
              // routing
              this.routing();
            }
        },

        toggleDialog(name) {
            this.dialogs[name] = !this.dialogs[name];
        },

        setActiveItem(item) {
            if (item)
                this.$store.commit('railSessions/setSelected', item);
        },

        /**
         * Handle route based on prop type and if id exists.
         * @param {String} id 
         */
        routing(id = null) {
            let routeObject = { path: '/rail/sessions/' };

            // add id if exists
            if (id) {
                routeObject.path = routeObject.path + id;
            }

            // routing
            this.$router.push(routeObject).catch(() => { });
        },

        async fetchStatistics() {

            if (this.userIsPlayer) {
                return false;
            }

            let payload = null;
            if (! this._.isEmpty(this.heroDatePickerValue)) {
                payload = {
                    dateBegin: this.heroDatePickerValue[0],
                    dateEnd: this.heroDatePickerValue[1],
                };
            }

            // if team exists and isManagement, Add team parameter to payload.params
            if (this.teamFilter) {
                payload.team = this.teamFilter;
            }

            if (!this._.isEmpty(payload)) {
                await this.$store.dispatch('railStatistics/getAdmin', payload);
            }
        }
    }
}