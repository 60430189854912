import Vue from 'vue'
import { mapGetters } from "vuex";

export default {
    name: "dialog-request-user",
    props: {
        dialogOpen: { type: Boolean, required: true, default: false },
    },
    computed: {
        ...mapGetters({
            videos: "videoRequests/videos",
        }),
    },
    created() {
        this.fetchData();
    },
    data() {
        return this.initialState();
    },
    methods: {
        initialState() {
            return {
                dialog: false,
                post: null,
                status: Vue.prototype.$video_requests.status["pending"],
                rules: {
                    fieldVideo: [(v) => !!v || "Por favor introduza um video"],
                },
                dialogues: {
                    feedback: false
                }
            };
        },

        /**
         * Fetch users from the store
         */
        async fetchData() {
            await this.$store.dispatch("videoRequests/getVideos");
        },

        submit() {
            if (!this.$refs.formRequestVideo.validate())
                return false;

            let payload = {
                post: this.post,
                status: this.status,
            };

            this.$store.dispatch("videoRequests/createRequestVideo", payload);

            this.closeDialog(); 
            this.$emit("feedback");
        },

        closeDialog() {
            this.$emit("close-dialog");
            this.resetDialog();
        },

        /**
         * Reset form validation errros (post and user are required)
         * refresh component state data to initial state
         */
        resetDialog() {
            this.$refs.formRequestVideo.resetValidation();
            Object.assign(this.$data, this.initialState());
        },
    }
};
