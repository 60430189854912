import Vue from 'vue';
import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth';
import DataMixin from '@/mixins/Data.vue';
import DatePicker from '@/components/DatePicker/DatePicker.vue';
import DatePickerRange from '@/components/DatePickerRange/DatePickerRange.vue';
import TableSingleSwapTournament from '@/components/Rail/TableSingleSwapTournament/TableSingleSwapTournament.vue';
import GamesDropdown from '@/components/Rail/GamesDropdown/GamesDropdown.vue';
import AlertError from '@/components/Alerts/AlertError/AlertError.vue';
import PercentageDropdown from '@/components/PercentageDropdown/PercentageDropdown.vue';

export default {
	name: 'DialogAddEditSwapTournament',

	props: {
		isOpen: { type: Boolean, required: true, default: false },
		action: { type: String, required: false, default: 'create'},
	},

	components: {
		TableSingleSwapTournament,
		DatePicker,
		GamesDropdown,
		DatePickerRange,
		AlertError,
		PercentageDropdown,
    },

    mixins: [
		AuthMixin,
		DataMixin,
    ],
	
	data() {
		return this.initialState();
	},

	computed: {
		...mapGetters({
			themeDarkMode: 'UI/themeDarkMode',
			selectedSwapGroup: 'railSwaps/selectedSwapGroup',
			selectedSwapPool: 'railSwaps/selectedSwapPool',
			humanError: 'railSwaps/humanError',
			error: 'railSwaps/error',
			
		}),

		entriesComputed: {
            get() {
                return this.form.pool;
            },
            set(newVal) {
                this.form.pool = newVal;
            },
		},
		
		isBuyInPopulated: function () {
			let output = false;

			if (!this._.isEmpty(this.entriesComputed)) {
				this.entriesComputed.forEach(e => {
					// if checkbox is true and amount input field isn't populated correctly, it will disables submit  btn
					if (e.played && (e.amount == 0 || this._.isNull(e.amount))) {
						output = true;
					}
				})
			}

			return output;
		},
		
		title: function () {
			return this.action == 'create'
				? 'Adicionar torneio'
				: 'Editar torneio';
		},

		description: function () {
			return this.action == 'create'
				? 'Adicionar torneio e participantes'
				: 'Editar torneio e participantes';
		},
	},

	watch: {
		isOpen: {
			handler() {
				// clear errors
				this.$store.dispatch('railSwaps/clearError');

				this.$store.commit('railSwaps/setSelectedSwapPool', this.selectedSwapPool);
				if (this.isOpen && this.action == 'edit') {
					this.populateFields();
				} else {
					this.form.tournament = null;
					this.form.description = null;
					this.form.date = this.$moment().format('YYYY-MM-DD');
					this.form.dateRange = [
						this.$moment().subtract(7, 'd').format('YYYY-MM-DD'),
						this.$moment().format('YYYY-MM-DD')
					]
					this.form.swapDeal = this.handleShareBasedOnSwapGroup();
				}
			},
			immediate: true,
		}
	},

	methods: {
		initialState() {
			return {
				submitting: false,
				form: {
					date: this.$moment().format('YYYY-MM-DD'),
					dateRange: [
						this.$moment().subtract(7, 'd').format('YYYY-MM-DD'),
						this.$moment().format('YYYY-MM-DD')
					],
					tournament: null,
					description: null,
					pool: null,
					swapDeal: null,
				},
			};
		},

		populateFields() {
			if (this.selectedSwapPool) {
				
				if (this.selectedSwapPool.game) {
					this.form.tournament = { name: this.selectedSwapPool.game.name, id: this.selectedSwapPool.game.id };
					this.form.dateRange = [
						this.$moment(this.selectedSwapPool.game.dateStart.date).format('YYYY-MM-DD'),
						this.$moment(this.selectedSwapPool.game.dateFinish.date).format('YYYY-MM-DD')
					]
				}

				this.form.description = this.selectedSwapPool.description;
				this.form.date = this.$moment.unix(this.selectedSwapPool.date).format('YYYY-MM-DD');
				this.form.swapDeal = this.handleShareBasedOnSwapGroup();
			}
		},

		generateEntriesPayload(list) {
			if (!this._.isEmpty(list)) {
				let deal = this.form.swapDeal && this.form.swapDeal.value ? this.form.swapDeal.value : this.form.swapDeal
				let mapList = list.map(element => {
					return {
						amount: element.amount,
						deal: deal,
						played: element.played,
						prize: element.prize,
						prizeScreenshot:  element.prizeScreenshot,
						user: element.user,
						id: element.id
					}
				})
				this.entriesComputed = mapList;
			}
		},

		async submit() {
			if (!this.$refs.formAddEditSwapTournament.validate()) {
				return false;
			}

			// Toggle submit button
			this.toggleSubmitting();
			
			// construct payload
			let payload = {
				params: {
					date: this.form.date,
					game: null,
					swapGroup: this.selectedSwapGroup.id,
					description: this.form.description,
					paid: false,
					entries: this.entriesComputed
				}
			}

			if (this.form.tournament && !this._.isNull(this.form.tournament)) {
				payload.params.game = this.form.tournament.id;
			}

			if (this.action == 'edit') {
				payload.id = this.selectedSwapPool.id;
				payload.paid = this.selectedSwapPool.paid;
			}

			let actionToDisptach = this.action == 'create'
				? 'railSwaps/createSwapPool'
				: 'railSwaps/updateSwapPool';
			
            // API call
			let result = await this.$store.dispatch(actionToDisptach, payload);

			// // Re-enable button
			this.toggleSubmitting();

			// On success
			if (result === true) {

				let word = this.action == 'create'
				? 'adicionado'
				: 'editado';
				
				// Show success snackbar
				this.$store.dispatch('UI/showSnackbar', {
					message: 'Torneio '+ word + ' com sucesso',
					color: 'success'
				});

				// refresh TableSwapTournaments, statistics
				let payloadRefresh = {
					id: this.selectedSwapGroup.id,
					params: {
						swapGroup: this.selectedSwapGroup.id,
					}
				}

				this.$store.dispatch('railSwaps/getSwapPools', payloadRefresh);
				this.$store.dispatch('railSwaps/getSwapGroupStatistics', payloadRefresh);
				
				// closes dialog
				this.closeDialog();
			}
		},

		handleShareBasedOnSwapGroup() {
            let output = ''
            if (this.selectedSwapGroup) {
                output = Vue.prototype.$deal_share_percentages.find(e => this.selectedSwapGroup.size == e.usersNumber);
                
                if (!this._.isUndefined(output)) {
                    output =  { value: output.share };
                }
			}
			
            return output;
        },


		/**
		 * Used to disable Submit button
		 */
		toggleSubmitting() {
			this.submitting = !this.submitting;
		},

		closeDialog() {
			this.$emit("close-dialog");
			// this.resetForm();
		},

		resetForm() {
			Object.assign(this.$data, this.initialState());
			this.$refs.formAddEditSwapTournament.reset();
		}
	},
};