import Vue from 'vue';
import { mapGetters } from 'vuex';
import RowTransfer from '@/components/Rail/RowTransfer/RowTransfer.vue';
import RailMixin from '@/mixins/Rail.vue';

export default {
    name: 'DialogViewTransfer',

    components: {
        RowTransfer,
    },

    mixins: [
		RailMixin,
    ],
    
    data() {
        return this.initialState();
    },
    
    props: {
        dialogOpen: { type: Boolean, required: true, default: false },
        title: { type: String, required: false, default: 'Transferência' },
        type: { type: String, required: false, default: 'TRANSFERS' }, // TRANSFERS || SWAPS
    },

    computed: {
        ...mapGetters({
            selectedTransfer: 'railTransfers/selectedTransfer',
            selectedSwap: 'railSwaps/selectedSwap',
        }),
        data: function () {
            return this.selectDataToBeDisplayed();
        },
    },

    methods: {
        initialState() {
            return {
                submitting: false,
            }
        },

        closeDialog(redirect = false) {
            this.$emit('close-dialog', redirect);
        },

        /**
         * Data to be displayed based on prop "type"
         * @returns {Object|Null}
         */
        selectDataToBeDisplayed() {
            switch (this.type) {
                case 'TRANSFERS':
                   return this.selectedTransfer;
                case 'SWAPS':
                    return this.selectedSwap;
                default:
                    return this.selectedTransfer;
            }
        },

        triggerCopyToClipboard() {
            let url = window.location.href;
            let word = this.type == 'TRANSFERS' ? 'da transferência' : 'do swap';
            this.copyToClipboard(url);


            // Show snackbar
            this.$store.dispatch('UI/showSnackbar', {
              message: "🔗 Link "+ word + " copiado com sucesso.",
              color: 'success'
			});
        },

        async goToRailOperation(item) {
            // toggle submitting
            this.submitting = true;

            //reset card
            this.$store.commit('TabSlider/setCard', null);
            
            // clear balance profit from UC - SPLIT
            this.$store.commit('railOverview/setCardBalanceProfitUSD', null);
            this.$store.commit('railOverview/setCardBalanceProfitEUR', null);

            // retrieve selectedRequest full info (ex: reportsDTO ) in order to populate checkout page
            // await to prevent visual bug
            await this.$store.dispatch('railRequests/getRequestById', item.railOperation.id);

            // toggle submitting
            this.submitting = false;

            // close dialog before redirect to operation view
            this.closeDialog(true);

            // set card based on selectedRequest type
            await this.$store.commit('TabSlider/setCard', item.railOperation.type);

            this.$store.commit('TabSlider/setSelectedTab', null); 
            
            // force CardStepper to display 1 step
            await this.$store.commit('UI/setCardStepperStep', 1);

            // routing
            this.$store.commit('TabSlider/setSelectedTab', Vue.prototype.$tab_slider.tabs.requests);
            this.$router.push({ path: Vue.prototype.$tab_slider.tabs.requests + item.railOperation.id });
        }
    }
}