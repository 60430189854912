import Vue from 'vue';
import { mapGetters } from 'vuex';
import Hero from '@/components/Hero/Hero.vue';
import LoadingSpinner from '@/components/LoadingSpinner/LoadingSpinner.vue';
import TableScoredGameGroups from '@/components/Scheduling/Tables/TableScoredGameGroups/TableScoredGameGroups.vue';
import AlertError from '@/components/Alerts/AlertError/AlertError.vue';

export default {
    name: 'ModelsSingle',

    components: {
        Hero,
        LoadingSpinner,
        TableScoredGameGroups,
        AlertError
    },

    computed: {
        ...mapGetters({
            model: 'schedulingModels/data',
            loading: 'schedulingModels/loading',
            error: 'schedulingModels/error',
        }),
    },

    data() {
        return this.initialState();
    },

    created() {
        this.getSchedulingModel(this.$route.params.id, true);
    },

    methods: {
        initialState() {
            return {
                interval: null,
                modelReady: true,
            };
        },

        goToModel() {
            this.$router.push({
                path: '/scheduling/models/' + this.model.id
              });
        },

        async getSchedulingModel(id, updateLoading) {
            let data = {
                id: id, 
                updateLoading : updateLoading
            }
            await this.$store.dispatch('schedulingModels/getById', data);
            if (!this.model) {
                this.$router.replace({ path: '/scheduling/models/' });
            }
            return true;
        },

        async reevaluateGameGroups() {
            this.$refs.gameGroupsTable.loading = true;
            this.modelReady = false;
            await this.$store.dispatch('schedulingGameGroups/clearData');
            await this.$store.dispatch('schedulingModels/evaluateGameGroups', this.model.id);
            
            this.interval = setInterval(this.checkModelReady, Vue.prototype.scheduling_configs.timeBetweenReq);
        },

        async checkModelReady() {
            await this.getSchedulingModel(this.model.id, false);
            this.modelReady = this.model.ready;
            if(this.modelReady){
                clearInterval(this.interval);
                this.$refs.gameGroupsTable.getSchedulingGameGroups(this.model.id);
            }
        },
    }
}