import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth.vue';
import RailMixin from '@/mixins/Rail.vue';
import DataMixin from '@/mixins/Data.vue';
import EmptyStateNoData from '@/components/EmptyStateNoData';
import DialogRemoveNetwork from '@/components/Dialog/DialogRemoveNetwork/DialogRemoveNetwork.vue';
import DialogEditNetwork from '@/components/Dialog/DialogEditNetwork/DialogEditNetwork.vue';
import NicknameCard from '@/components/NicknameCard/NicknameCard.vue';

export default {
    name: 'TableNetworks',

    mixins: [
        AuthMixin,
        RailMixin,
        DataMixin,
    ],

    components: {
        EmptyStateNoData,
        DialogRemoveNetwork,
        DialogEditNetwork,
        NicknameCard,
    },

    data() {
        return this.initializeState();
    },

    created() {
        this.fetchData();
    },

    computed: {
        ...mapGetters({
            networks: 'networks/networks',
            networksTotal: 'networks/networksTotal',
            loading: 'networks/loading',
            selectedNetwork: 'networks/selectedNetwork',
        }),

        getTableHeaders: function () {
            return this.userIsManager
                ? this.dataTable.headers.admin
                : this.dataTable.headers.user;
        },

        optionsComputed: {
            get: function () {
              return this.options;
            },
            set: function (newValue) {
                if (!this._.isEqual(this.options, newValue)) {
                    this.options = newValue;
                }
            }
        }
    },

    watch: {
        // watches this.options object
        // triggers everytime this.options has changed
        options: {
            handler() {
                this.fetchData();
            },
            deep: true,
        },
    },

    methods: {
        initializeState() {
            return {
                dataTable: {
                    headers: {
                        admin: [
                            { text: 'Sala', align: 'left', sortable: true, value: 'name' },
                            { text: 'Identificador', value: 'identifier' },
                            { text: 'Ação', value: 'action', sortable: false },
                        ],
                        user: [
                            { text: 'Sala', align: 'left', sortable: true, value: 'name' },
                            { text: 'Identificador', value: 'identifier' },
                        ],
                    },
                    footerProps: {
                        'items-per-page-options': this.$itemsPerPageOptions,
                    },
                    search: null,
                },
                options: {
                    page: 1,
                    itemsPerPage: 10,
                    sortBy: [],
                    sortDesc: [],
                    groupBy: [],
                    groupDesc: [],
                    mustSort: true,
                    multiSort: false,
                },
                dialogues: {
                    editNetwork: false,
                    removeNetwork: false,
                }
            };
        },

        // trigered by v-text-field @input="search()"
        search() {
            let lastValue = this.dataTable.search;
            
            setTimeout(() => {
                if (lastValue == this.dataTable.search) {
                    // Manually trigger API call if page = 1
                    if (this.options.page == 1) {
                        this.fetchData();
                    }
                    // This triggers API call if page != 1 due to watcher
                    else {
                        this.options.page = 1;
                    }
                }
            }, 1500);
        },

        refreshNetworks() {
            // set default options to refresh Table
            this.optionsComputed = {
                page: 1,
                itemsPerPage: 10,
                sortBy: [],
                sortDesc: [],
                groupBy: [],
                groupDesc: [],
                mustSort: true,
                multiSort: false,
            }
        },

        async fetchData() {
            // Base payload
            let payload = {
                params: {
                page: this.options.page,
                itemsPerPage: this.options.itemsPerPage,
                }
            };

            // Add name parameter to payload.params
            if (!this._.isEmpty(this.dataTable.search)) {
                payload.params.search = this.dataTable.search;
            }

            // Add sortDesc parameter to payload.params
            Object.assign(payload.params, this.sortDescHandler(this.options.sortDesc));

            // Add sortBy parameter to payload
            this.addParameterToPayload(payload, 'sortBy[]', this.options.sortBy);

            // set promiseNetworks to NULL in order to be possible to make new API Request
            this.$store.commit('networks/setPromiseNetworks', null);

            // API Call
            await this.$store.dispatch('networks/get', payload);
        },

        setActiveItem(item) {
            this.$store.commit('networks/setSelectedNetwork', item);
        },

        openDialogRemoveNetwork() {
            this.dialogues.removeNetwork = true;
        },

        openDialogEditNetwork() {
            this.dialogues.editNetwork = true;
        }
    },
};
