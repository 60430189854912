<script>
import { mapGetters } from 'vuex';
import Vue from 'vue';
import AuthMixin from '@/mixins/Auth.vue';
import DataMixin from '@/mixins/Data.vue';

export default {
	mixins: [
		AuthMixin,
		DataMixin,
	],

	computed: {
		...mapGetters({
			heroDatePickerValue: 'UI/heroDatePickerValue',
			userRoles: 'auth/userRoles',
			userRolesLoading: 'auth/userRolesLoading',
			userTrackerId: 'auth/userTrackerId',
            nicknamesByUserTrackerID: 'nicknames/nicknamesByUserTrackerID',
			teamFilter: 'teams/teamFilter',
			themeDarkMode: 'UI/themeDarkMode',
		}),
	},

    methods: {
        copyToClipboard(item) {
            return this.clipboard(item);
        },

		/**
		 * @returns {String}
		 */
        getPnlColor(pnl) {
			if (pnl > 0) {
				return 'green';
			} else if (pnl < 0) {
				return 'red';
			} else {
				return 'gray';
			}
		},

		/**
		 * @returns {String}
		 */
		getPnlArrowIcon(pnl) {
			if (pnl > 0) {
				return 'mdi-arrow-up';
			} else if (pnl < 0) {
				return 'mdi-arrow-down';
			} else {
				return 'mdi-circle-small';
			}
		},
		
		/**
		 * @returns {String}
		 */
		getRailOperationName(item) {
			if (item) {
				switch (item) {
					case Vue.prototype.$rail_ops.operations.split:
						return 'Split';
					case Vue.prototype.$rail_ops.operations.bankroll_reduction:
						return 'Redução de Banca';
					case Vue.prototype.$rail_ops.operations.bankroll_increment:
						return 'Incremento de Banca';
					case Vue.prototype.$rail_ops.operations.transfer_to_self:
					case Vue.prototype.$rail_ops.operations.transfer_to_player:
						return 'Transferência';
					case Vue.prototype.$rail_ops.operations.swap:
						return 'Swap';
					case Vue.prototype.$rail_ops.operations.deposit:
						return 'Depósito';
					case Vue.prototype.$rail_ops.operations.withdraw:
						return 'Levantamento';
					case Vue.prototype.$rail_ops.operations.sessions:
						return 'Sessão';
					case Vue.prototype.$rail_ops.operations.makeup:
						return 'Makeup';
					default:
						break;
				}
			}
		},

		/**
		* Retrieve rail operation type and based on it return the respective color
		* @param {*} item 
		*/
		getRailOperationColor(item) {
			let color = Vue.prototype.$operationColor[item];
			return color;
		},

		/**
		 * @param {Object} item 
		 * @returns {String}
		 */
		getMakeUpReason(item) {
			if (item && item.type == Vue.prototype.$rail_ops.operations.makeup) {
				let reason = item.reason ? "( " + item.reason.name + " )" : '';
				return reason;
			}
		},

		/**
		 * @returns {String}
		 */
		getCurrencyIcon(item) {
			let output = '';
			
			if (item &&	item.network.currency.code) {
				switch (item.network.currency.code) {
					case 'USD':
						output = 'mdi-currency-usd';
						break;
					case 'EUR':
						output = 'mdi-currency-eur';
						break;
					case 'BTC':
						output = 'mdi-currency-btc';
						break;
					default:
						break;
				}
			}

			return output;
		},

		/**
		 * Get observation description by phase
		 * @param {*Array} observations 
		 * @param {*Integer} phase 
		 * @returns {String} with description
		 */
		getObservationDescriptionByPhase(observations, phase) {
			let output = 'Sem observação.';

			if (!_.isEmpty(observations)) {
				output = observations.find(e => e.phase == phase);

				if (output) {
					output = output.description;
				}
			}

			return output;
		},

		/**
		 * Get observation object by phase
		 * @param {*Array} observations 
		 * @param {*Integer} phase 
		 * @returns {Object}
		 */
		getObservationByPhase(observations, phase) {
			let output = {};

			if (!_.isEmpty(observations)) {
				output = observations.find(e => e.phase == phase);

				if (! output) {
					output = {}; 
				}
			}

			return output;
		},

		/**
		 * Retrieves  observation url, split url to get file name
		 * @param {String} url 
		 * @returns {String}
		 */
		getObservationFileName(url) {
			let output = null;
			
			let name = url ? url.split("/") : [];

			if (name.length) {
				output = name[name.length - 1];
			}

			return output;
		},

		/**
		 * Get username by rail operation
		 * @param {Object} item
		 * @returns {String} with username
		 */
		getUsernameFromRailOperation(item){
			switch (item.type) {
				case Vue.prototype.$rail_ops.operations.deposit:
				case Vue.prototype.$rail_ops.operations.withdraw:
					return 'Gestão';
				default:
					if (item.user) {
						return item.user.displayName;
					} else if (item.targetUser) {
						return item.targetUser.displayName;
					} else {
						return 'Gestão';
					}
			}
      	},

		/**
		 * Get username by rail operation
		 * @param {Object} item
		 * @returns {String} with username
		 */
		getUserDTOFromRailOperation(item){
			switch (item.type) {
				case Vue.prototype.$rail_ops.operations.deposit:
				case Vue.prototype.$rail_ops.operations.withdraw:
					return null;
				default:
					if (item.user) {
						return item.user;
					} else if (item.targetUser) {
						return item.targetUser;
					} else {
						return null;
					}
			}
      	},

		/**
		 * Get user id by rail operation
		 * @param {Object} item
		 * @returns {String} with an id
		 */
		getUserIdFromRailOperation(item){
			if (item.user) {
				return item.user.id;
			} else if (item.targetUser) {
				return item.targetUser.id;
			} else {
				return '';
			}
      	},

		/**
		 * Get total amount in each rail operation
		 * @ref https://stackoverflow.com/questions/23247859/better-way-to-sum-a-property-value-in-an-array
		 * @param {*Object} item
		 * @param {String} field - used in TableRequests, if has requests/transfers with multiple currencies
		 * @returns {Integer} if item.requests/item.transfers exists, else return a String.
		 */
      	getAmountRequest(item, field='value') {
			let output = 'Por definir';
			let hasTransfers = item.transfers && item.transfers.length;
			let hasRequests = item.requests && item.requests.length;
			let isTypeSplitOrBankrollIncrement = item.type == Vue.prototype.$rail_ops.operations.split || item.type == Vue.prototype.$rail_ops.operations.bankroll_increment;

			// if requests exist, then we sum every amount in requests[], else we do it in transfers[],
			if (hasRequests) {
				output = isTypeSplitOrBankrollIncrement ? this._.sumBy(this._.map(item.requests, 'amount'), field) : item.requests[0]['amount']['value'];
			} else if (hasTransfers) {
				// filter by transfers from Player to Management or from Player to Player
				output = item.transfers.filter(e => e.type == Vue.prototype.$transfer_types.P_M || e.type == Vue.prototype.$transfer_types.P_P)
				output = this._.sumBy(this._.map(output, 'amount'), field);
			}
        	return output;
      	},

		/** 
		 * @param {*Object} item 
		 * @param {String} field - used in TableRequests, if has requests/transfers with multiple currencies
		 * @returns an {Integer|String} amount 
		 */
		getAmountSent(item, field='value') {
			let output;
			let transfers;

			// if rail operation type is equal to Vue.prototype.$rail_ops.operations.transfer_to_self, use item.transfers, else use transfers with filter
			if (item.type == Vue.prototype.$rail_ops.operations.transfer_to_self) {
				transfers = item.transfers;
			// if operation type equals to SPLIT then we use requests in order to know which nicknames/wallets are being splitted
			} else if (item.type == Vue.prototype.$rail_ops.operations.split) {
				transfers = ! this._.isEmpty(item.requests) ? item.requests : item.transfers;
			// if operation type equals BANKROLL_REDUCTION
			} else if (item.type == Vue.prototype.$rail_ops.operations.bankroll_reduction) {
				// filters by transfers PLAYER -> MANAGEMENT
				transfers = item.transfers ? item.transfers.filter(e => e.type == Vue.prototype.$transfer_types.P_M || e.type == Vue.prototype.$transfer_types.P_O) : [];
			// if operation type equals RAKE then we select first transfer from item.transfers
			} else if (item.type == Vue.prototype.$rail_ops.operations.makeup) {
				
				transfers = item.transfers.slice();
					if (transfers.length > 1) {
						transfers.pop();
					}

			} else {
				transfers = item.transfers.filter(element => !element.isPending && (element.type != Vue.prototype.$transfer_types.PNPW));
			}

			// if transfers exist, then we sum every amount in transfers[], else it is unknown till transfer is no longer pending
			if (!this._.isEmpty(transfers)) {
				output = this._.sumBy(this._.map(transfers, 'amount'), field);
			} else {
				output = 'Desconhecido';
			}
			return output;
		},

		/**
		 * @param {Object} item Table row item
		 * @returns {String} Currency symbol
		 */
		getRailOperationCurrency(item) {
			let output = '';
			
			/**
			 * runs only if has requests (item.requests has nicknames and wallets)
			 * triggers opHasSingleCurrency() in order to know which currency will be displayed
			 * if has only 1 currency, we display the first wallet symbol, else (multiple currencies) we display €
			 */
			if (item.requests && item.requests.length) {
				output = this.opHasSingleCurrency(item)
					? item.requests[0].nickname.network.currency.symbol
					: '€';
			/**
			 * runs only if has transfers (item.transfers has source and target)
			 * triggers opHasSingleCurrency() in order to know which currency will be displayed
			 * if has only 1 currency, we display the first source symbol, else (multiple currencies) we display €
			 */
			} else if (item.transfers.length) {
				output = this.opHasSingleCurrency(item)
					? item.transfers[0].source.network.currency.symbol
					: '€';
				// UC - withdraw, show source network symbol
				if (item.type == Vue.prototype.$rail_ops.operations.withdraw) {
					output = item.transfers[0].source.network.currency.symbol;
				}
				// UC - deposit, show target network symbol
				if (item.type == Vue.prototype.$rail_ops.operations.deposit) {
					output = item.transfers[0].target.network.currency.symbol;
				}
				// UC - Session, show target network symbol
				if (item.type == Vue.prototype.$rail_ops.operations.sessions) {
					output = item.transfers[0].target.network.currency.symbol;
				}
			}
			return output;
		},

		isCryptoCurrency(currencyID) {
			let ArrCryptoCurrenciesIds = [
				Vue.prototype.$currency_ids.BTC,
				];
			return ArrCryptoCurrenciesIds.includes(currencyID);
		},

		/**
		 * Evaluates how many currencies the operation has in order to know what currency to show
		 * 
		 * @param {Object} operation current operation 
		 * @returns {Boolean} true if has one currency / false if has more than one currency
		 */
		opHasSingleCurrency(operation) {
			let output = true;

			// get transfers or requests based on operation status
			let transfersOrRequests = this.getOpTransfersRequests(operation)

			if (!this._.isEmpty(transfersOrRequests)) {
				// map by wallet or source if key => wallet not exists
				let mapByKey = this._.has(transfersOrRequests[0], 'wallet')
								? 'wallet.network.currency.id'
								: 'source.network.currency.id';

				let currencyIdMap = this._.map(transfersOrRequests, mapByKey);
				// checks if every item is equal to the first item
				output = currencyIdMap.every(v => v === currencyIdMap[0]);
			};

			return output;
    	},

		getOpTransfersRequestsKey(op) {
			// uses transfers if operation has status finalized
			let key = op.status == Vue.prototype.$rail_status.finalized
              ? 'transfers'
              : 'requests';

			// if selected key in op object is empty, use transfers.
			let output = this._.isEmpty(op[key]) ? 'transfers' : 'requests';
      
      		return output;
		},

		getOpTransfersRequests(op) {
			let keyToUse = this.getOpTransfersRequestsKey(op);
			return op[keyToUse];
		},

		/**
		 * Get source networks
		 * @param {*Object} item
		 * @returns {Array} a filtered array fullfilled with source networks.
		 */
		getSourceNetworkTransfers(item, filter=null) {
			if (item) {
				let output = item.transfers;

				// Filter by transfers type PNPW
				output = output.filter(e => e.type == Vue.prototype.$transfer_types.PNPW);
				
				// If doesn't exist transfers type PNPW
				if (this._.isEmpty(output)) {
					switch (item.type) {
						case Vue.prototype.$rail_ops.operations.makeup:
							// Filter by transfers type UNKNOWN or P_M
							output = item.transfers.filter(e => e.type == Vue.prototype.$transfer_types.UNKNOWN || e.type == Vue.prototype.$transfer_types.P_M);
							break;
						default:
							output = item.transfers;
							break;
					}
				}
				if(filter == 'isNotInternalTransfer' && output.length > 1){
					output = output.filter(e => e.isInternalTransfer == false);
				}
				return output;
			}
     	},

		/**
		 * Get target networks/wallets
		 * @param {*Object} item
		 * @returns {Array} a filtered array fullfilled with target networks/wallets.
		 */
		getTargetWalletTransfers(item, filter=null) {
			if (item) {
				let output = item.transfers;

				// if rail operation type is different to Transfer to Self 
				if (item.type != Vue.prototype.$rail_ops.operations.transfer_to_self) {
					// Filter by transfers that aren't type PNPW
					output = output.filter(e => e.type != Vue.prototype.$transfer_types.PNPW);
				}
				// if rail operation type is equal to Split
				if (item.type == Vue.prototype.$rail_ops.operations.split) {
					// Filter by transfers that are equal to type P_M
					output = output.filter(e => e.type == Vue.prototype.$transfer_types.P_M);
				}

				// if rail operation type is equal to Makeup
				if (item.type == Vue.prototype.$rail_ops.operations.makeup) {
					// Filter by transfers that are equal to type UNKNOWN or P_M
					output = output.filter(e => e.type == Vue.prototype.$transfer_types.UNKNOWN || e.type == Vue.prototype.$transfer_types.P_M);
				}


				if(filter == 'isNotInternalTransfer' && output.length > 1){
					output = output.filter(e => e.isInternalTransfer == false);
				}
				return output;
			}
    	},

		getRailOperationStatus(status) {
			if (status) {
				switch (status) {
					case Vue.prototype.$rail_status.pending:
						return 'Pendente';
					case Vue.prototype.$rail_status.accepted:
						return 'Aceite';
					case Vue.prototype.$rail_status.approved:
						return 'Aprovado';
					case Vue.prototype.$rail_status.rejected:
						return 'Rejeitado';
					case Vue.prototype.$rail_status.finalized:
						return 'Finalizado';
					default:
						break;
				}
			}
		},

		getReportStatus(item) {
			if (item) {
				switch (item.status) {
				case Vue.prototype.$rail_reports.status.ongoing:
					return 'Pendente';
				case Vue.prototype.$rail_reports.status.seen:
					return 'Em Revisão';
				case Vue.prototype.$rail_reports.status.resolved:
					return 'Resolvido';
				default:
					break;
				}
			}
		},

		getRailOperationStatusName(status) {
			return this._.has(Vue.prototype.$rail_status_name, status-1)
					? Vue.prototype.$rail_status_name[status-1]
					: 'DESCONHECIDO OU ERRO';
		},

		getRailOperationStatusColor(status) {
			if (status) {
				switch (status) {
					case Vue.prototype.$rail_status.pending:
						return "warning";
					case Vue.prototype.$rail_status.accepted:
						return "info";
					case Vue.prototype.$rail_status.approved:
						return "success";
					case Vue.prototype.$rail_status.rejected:
						return "error";
					case Vue.prototype.$rail_status.finalized:
						return "success";
					default:
						break;
				}
			}
		},
		
		/**
		 * Calculates amount * profit share
		 * @param {Number} profitShare
		 * @param {Number|string} amount
		 * @returns {string|undefined}
		 */
		profitAmount(profitShare, amount) {
			let output;

			// if profitShare or amount are not numbers, we return undefined
			if (Number(profitShare) && Number(amount)) {
				output = amount * profitShare;
				output = this._.round(output, 2).toFixed(2);
			}

      		return output;
    	},

		/**
		 * @param {Number|String}
		 * @returns {Number|String} formatted
		 */
		getProfitShare(profitShare){
			let output = 0;
			if (profitShare) {
				output = this.convertDecimalToPercentage(profitShare);
			}
			
			if (typeof (output) == 'number') {
				output = output.toFixed(2) + ' %';
			}

			return output;
		},

		/**
		 * Updates all of the rail data
		 * 
		 * TODO: This generates 2 parameters and gets rail operations twice because of how
		 * store/rail-requests is setup. It separates operations into different states depending
		 * on their state (ongoing, finalized, etc). Therefore, to refresh operations correctly,
		 * we must call multiple endpoints. When the store is refactored, we can just call
		 * 'getRequests' here, as we are looking for ALL requests.
		 * 
		 * TODO: Rename this to e.g. 'updateAllRailData'
		 * 
		 * @param {object} payload Payload to send to the store. Has a 'params' key.
		 * @returns {void}
		 */
		updateRequestsTransfersAndBalances(payload=null) {
			/**
			 * Build payloadRequests
			 */

			// Base payloadRequests
			let payloadRequestsFinalized = {params: {}};
			let payloadRequestsOngoing = {params: {}};
			let payloadRequestsAll = {params: {}};

			// Build payloadRequests
			payloadRequestsFinalized.params = this.genRailRequestsPayload(0);
			payloadRequestsOngoing.params = this.genRailRequestsPayload(1);
			payloadRequestsAll.params = this.genRailRequestsPayload(null);

			// if payload exists and has team parameter
			if (payload && payload.params && payload.params.team) {
				payloadRequestsFinalized.params.team = payload.params.team;
				payloadRequestsOngoing.params.team = payload.params.team;
			}

			/**
			 * Dispatch requests
			 */

			// retrieve userRoles for API calls purposes if doesnt exist
			if (this._.isUndefined(this.userRoles) || this._.isEmpty(this.userRoles)) {
				this.$store.dispatch('auth/getUserRoles', this.userTrackerId);
			}

			// Refresh finalized requests (TableRequests Tab "FINALIZADOS")
			this.$store.dispatch('railRequests/getRequests', payloadRequestsFinalized);
			// Refresh ongoing requests (TableRequests Tabs "PENDENTES/A DECORRER")
			this.$store.dispatch('railRequests/getRequests', payloadRequestsOngoing);
			// Refresh all requests (TableRequests Tab "TODOS")
			this.$store.dispatch('railRequests/getRequests', payloadRequestsAll);

			// set promise to null in order to be able to reload nicknames all mine again
        	this.$store.commit('nicknames/setPromiseAllMine', null);

			// Refresh balances
			this.$store.dispatch('nicknames/getAllMine');

			// Refresh transfers
			this.$store.dispatch('railTransfers/get', payload);

			// Refresh Swaps
			//this.$store.dispatch('railSwaps/get', payload);

			// Refresh statistics
			if (! this.userRolesLoading) {

				let payloadStatistics = {}

				if (!this._.isEmpty(this.heroDatePickerValue)) {
					payloadStatistics.dateBegin = this.heroDatePickerValue[0];
					payloadStatistics.dateEnd = this.heroDatePickerValue[1];
				} else {
					payloadStatistics.dateBegin = this.$moment().subtract(1, 'month').format('YYYY-MM-DD');
					payloadStatistics.dateEnd = this.$moment().format('YYYY-MM-DD');
				}

				if (! this._.isEmpty(this._.intersection(this.userRoles, ['rail_admin', 'rail_manager']))) {
					if (this.teamFilter) {
						payloadStatistics.team = this.teamFilter;
					}
					this.$store.dispatch('railStatistics/getAdmin', payloadStatistics);
				} else {
					this.$store.dispatch('railStatistics/get', payloadStatistics);
				}
			}
		},

		updateStatisticsByUserId(userID) {
			let payload = {
				params: {
					user: userID,
					dateBegin: this.heroDatePickerValue[0],
					dateEnd: this.heroDatePickerValue[1],
				}
			}
			this.$store.commit('railStatistics/setLastPayloadDataByUserId', {});
			this.$store.dispatch('railStatistics/getByUserId', payload);
		},

		/** 
		 * @param {int} ongoingStatus Status of the rail operations to get
		 * @returns {object}
		 */
		genRailRequestsPayload(ongoingStatus) {
			let output = {
				page: 1,
				itemsPerPage: 10,
				dateBegin: this.heroDatePickerValue[0],
				dateEnd: this.heroDatePickerValue[1],
				ongoing: ongoingStatus,
				'type[0]': 6,
				'type[1]': 3,
				'type[2]': 7,
				'type[3]': 9,
				'type[4]': 2,
				'type[5]': 1,
				'type[6]': 5,
			};

			if (ongoingStatus === 1) {
				output.itemsPerPage = -1;
				output['sortBy[0]'] = 'date';
				output['sortBy[1]'] = 'user';
				output['sortBy[2]'] = 'targetUser';
				output['sort[0]'] = 'ASC';
				output['sort[1]'] = 'ASC';
				output['sort[2]'] = 'ASC';
			}

			return output;
		},
		
		formulateUsersAllUrlParameters() {
			return {
				params: {
					page: 1,
                    itemsPerPage: -1,
				}
			}
		},

		/** 
		 * Checks if rail manager has own nicknames
		 */
		hasRailManagerOwnNicknames() {
            let hasOwnNicknames = false;

            // checks if user is rail manager
            if (this.userIsRailManager) {

                // checks if nicknamesByUserTrackerID is not empty
                if (!this._.isEmpty(this.nicknamesByUserTrackerID)) {
					hasOwnNicknames = this.validateOwnNicknames();
					return hasOwnNicknames;
                } else {
                    // if nicknamesByUserTrackerID are empty, it triggers getNicknamesByUserTrackerID()
                    this.getNicknamesByUserTrackerID();
					hasOwnNicknames = this.validateOwnNicknames();
                    return hasOwnNicknames;
                }
            }

            return hasOwnNicknames;
        },

        /**
         * Triggered by hasRailManagerOwnNicknames()
         */
        async getNicknamesByUserTrackerID() {
            let payload = {
                user: this.userTrackerId,
                aliasParameter: '&aliasChildOf=null'
            }

            // API Call to retrieve nicknamesByUserTrackerID
            await this.$store.dispatch('nicknames/getOwnNicknamesByTrackerID', payload);
        },

        /**
         * retrieves nicknames for user logged in and then validates if user has own nicknames
         * @returns {Boolean} value
         */
        validateOwnNicknames() {
            let output = false;
			
            // filter for management nicknames
            output = !this._.isEmpty(this.nicknamesByUserTrackerID.filter(e => e.isManagement == true));

			// if isn't empty it means that has nicknames and is returning true
			return output;
        },

		/**
		 * Handles number of decimals that must show based on network currency code
		 * @param {Object} item
		 * @returns {Number}
		 */
		handleDecimalNumbers(item) {
			let networkCurrencyCode = null;
            let output = 2;

            if (item) {
                networkCurrencyCode = item.network.currency.code;

                if (this._.includes(Vue.prototype.$crypto_currencies, networkCurrencyCode)) {
                    output = 8;
                    return output
                };
            }
			
            return output;
		},

		valueColorFinal() {
            let output = 'black';

            if (this.themeDarkMode) {
              output = 'white';
            }
      
            return output;
        },
    }
}
</script>