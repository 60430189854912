import Vue from 'vue';

export default {
    name: 'ReportStatusDropdown',

    props: {
        value: { type: [Object,Array], required: false, default: () => { } },
        hideDetails: { type: Boolean, required: false, default: false },
        outlined: { type: Boolean, required: false, default: false },
        solo: { type: Boolean, required: false, default: false },
        excludedOperations: { type: Array, required: false, default: () => [] },
        selectedOperation: { type: [Object,Array], required: false, default: () => { } },
        hint: { type: Boolean, required: false, default: false },
        multiple : { type: Boolean, required: false, default: false },
    },

    computed: {
        computedValue: {
            get: function () {
               return this.status;
            },
            set: function (newValue) {
                this.status = newValue;
                this.emitChange();
            }
        },
    },

    data() {
        return this.initialState();
    },

    created() {
        this.computedValue = this.value;
    },

    methods: {
        initialState() {
            return {
                status: null,
                reportStatus: [
                    { value: Vue.prototype.$rail_reports.status.ongoing, name: 'A decorrer' },
                    { value: Vue.prototype.$rail_reports.status.seen, name: 'Em revisão' },
                    { value: Vue.prototype.$rail_reports.status.resolved , name: 'Resolvido' },
                ],
            }
        },
    

        emitChange() {
            this.$emit('input', this.computedValue);
            this.$emit('change', this.computedValue);
        },
    }
}