import Vue from 'vue';
import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth.vue';
import DataMixin from '@/mixins/Data.vue';
import RailMixin from '@/mixins/Rail.vue';
import NicknameDropdown from '@/components/Rail/NicknameDropdown/NicknameDropdown.vue';
import InputFinancial from '@/components/InputFinancial/InputFinancial.vue';

export default {
    name: 'CardStepperNetwork',

    props: {
        user: { type: Number, required: false, default: null },
        text: { type: String, required: false, default: 'Seleciona as salas relacionadas com este processo.' },
        value: { type: Array, required: false, default: () => [] },
        isSplit: { type: Boolean, required: false, default: false },
        splitType: { type: Number, required: false, default: 1 }
    },

    components: {
        NicknameDropdown,
        InputFinancial,
    },

    mixins: [
        AuthMixin,
        DataMixin,
        RailMixin,
    ],

    computed: {
        ...mapGetters({
            nicknames: 'nicknames/nicknames',
            nicknamesAllMine: 'nicknames/nicknamesAllMine',
            nicknamesByUserID: 'nicknames/nicknamesByUserID',
        }),

        totalDollar: function () {
            return this.getTotalBalanceByCurrency('$');
        },

        totalEuro: function () {
            return this.getTotalBalanceByCurrency('€');
        },

        // Synthetic v-model
        computedValue: {
            get: function () {
               return this.form.nicknames;
            },
            set: function (newValue) {
                this.form.nicknames = newValue;
                this.emitChange();
            }
        },
    },

    watch: {
        // Synthetic v-model
        value: function (newValue) {
            this.computedValue = newValue;
        },
        totalDollar: function (newValue) {
            if (this.isSplit) {
                this.$store.commit(
                    'railOverview/setCardBalanceProfitUSD',
                    newValue
                  );
            }
        },
        totalEuro: function (newValue) {
            if (this.isSplit) {
                this.$store.commit(
                    'railOverview/setCardBalanceProfitEUR',
                    newValue
                );
            }
        },
    },

    created() {
        this.initialize();
    },

    mounted() {
        // Clear error in <input-financial>, triggered naturally when element is loaded
        this.$refs.CardStepperNetwork.reset();
    },

    data() {
        return this.initialState();
    },

    methods: {
        initialize() {
            // Get data
            this.fetchData();

            // Synthetic v-model
            this.computedValue = this.value;
            this.emitChange();
        },

        async fetchData() {
            let payload = {
                params: {
                    'status[0]': Vue.prototype.$nickname_status.active.value,
                    'status[1]': Vue.prototype.$nickname_status.flagged.value,
                    itemsPerPage: -1,
                    page: 1
                }
            }
            await this.$store.dispatch('nicknames/get', payload);
            await this.$store.dispatch('railWallets/getWallets');
        },

        initialState() {
            return {
                headers: [
                    {
                        text: 'Salas',
                        value: 'network',
                        sortable: false,
                    },
                    {
                        text: 'Montante',
                        value: 'balance',
                        sortable: false,
                    },
                ],
                form: {
                    wallet: null,
                    nicknames: [],
                    balance: {
                        value: null
                    },
                },
                rules: {
                    balance: [
                        (v) => !!v || 'Por favor introduza o montante',
                        (v) => ! Number.isNaN(v) || 'Insira um valor numérico',
                    ],
                },
                splitTypes: {
                    normal: 1,
                    back_office: 2,
                  },
            }
        },

        /**
         * Validate user's input in this.form.balance
         * If returns true, it means the user typed letters
         */
        validateBalance() {
            const regExp = /^[A-Za-z]+$/;
            return regExp.test(this.form.balance.value);
        },

        addWalletToNicknamesList() {
            // Formulate payload object "wallet" + balance that user requested
            let payload = {
                id: this.form.wallet.id,
                wallet: this.form.wallet,
                balance: {
                    value: this.form.balance.value,
                },
                currency: this.form.wallet.network.currency,
                network: this.form.wallet.network.name
            }

            const index = this.form.nicknames.findIndex(e => e.id == payload.id);
            const alreadyExists = index != -1;
            if (alreadyExists) {
                this.$set(this.form.nicknames, index, payload);
            } else {
                this.form.nicknames.push(payload);
            }
            this.emitChange();
            this.resetInputs();
        },

        // remove session from form.nicknames[]
        removeFromNicknamesList(item) {
            const index = this.form.nicknames.findIndex(e => e.id == item.id);
            this.form.nicknames.splice(index, 1);
            this.emitChange();
        },

        /**
         * get total balance by currency
         * returns int (total balance)
         */
        getTotalBalanceByCurrency(currency) {
            if (this.form.nicknames) {
                // Retrieve array with objects with the same currency
                let arrayByCurrency = this.form.nicknames.filter(element => element.currency.symbol == currency);

                // Retun total amount
                return this.sumValuesInArray(arrayByCurrency, 'value');
            }
        },

        setActiveItem(item) {
            this.resetInputFinancialValidation();
            this.$store.commit(
              'railOverview/setSelectedBalance',
              item
            );
        },

        // clean input fields without showing rules error
        resetInputs() {
            this.$refs.CardStepperNetwork.reset();
            this.$refs.inputFinancial.reset();
            this.$refs.NicknameDropdown.resetNickname();
        },

        resetInputFinancialValidation() {
            if (this.$refs.inputFinancial) {
                this.$refs.inputFinancial.resetValidation();
            }
        },

        emitChange() {
            this.$emit('input', this.computedValue);
            this.$emit('change', this.computedValue);
        },

        /**
         * Useful for form.balance field
         * 
         * @returns {String} mdi icon based on this.form.wallet.network.currency.code
         */
        getCurrencyIcon() {
            if (this.form.wallet) {
                switch (this.form.wallet.network.currency.code) {
                    case 'USD':
                        return 'mdi-currency-usd';
                    case 'EUR':
                        return 'mdi-currency-eur';
                    case 'BTC':
                        return 'mdi-currency-btc';
                    default:
                        break;
                }
            }
        },

        /**
         * Filters only networks where management and the player have common nicknames
         * @returns {Array} filled with network IDS that cannot be used
         */
        handleNetworksFilter() {
            let output = [];
            if (this.splitType == this.splitTypes.normal) {
                return output;
            } else {
                let nicknamesAllMineMapNetworkID = this.nicknamesAllMine.map(e => e.network.id);
                let nicknamesByUserIdMapNetworkID = this.nicknamesByUserID.map(e => e.network.id);
                output = this._.difference(nicknamesByUserIdMapNetworkID, nicknamesAllMineMapNetworkID)
                return output;
            }
        }
    }
}
