import Vue from 'vue';
import axios from 'axios';
import StoreMixin from '@/mixins/Store.vue';

const state = {
    gameEntries: [],
    loadingGameEntries: true,
    error: null,
    humanError: null,
    totalGameEntries: null,
};

const getters = {
    gameEntries: (state) => state.gameEntries,
    loadingGameEntries: (state) => state.loadingGameEntries,
    error: (state) => state.error,
    humanError: (state) => state.humanError,
    totalGameEntries: (state) => state.totalGameEntries,
};

const mutations = {
    setGameEntries(state, payload) {
        state.gameEntries = payload;
    },
    setLoadingGameEntries(state, payload) {
        state.loadingGameEntries = payload;
    },
    setError(state, payload) {
        state.error = payload;
    },
    setHumanError(state, payload) {
        state.humanError = payload;
    },
    setTotalGameEntries(state, payload) {
        state.totalGameEntries = payload;
    },
};

const actions = {
    async getGameEntries({ dispatch, commit }, payload) {
        // clear state
        dispatch('clearError');
        commit('setGameEntries', []);
        commit('setLoadingGameEntries', true);

        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/games/entries';

        // Configure URL parameters
        let parameters = payload && payload.params
            ? payload.params
            : StoreMixin.methods.getParametersDefault();
        
        // Build URL with parameters
        request_url = StoreMixin.methods.generateQueryParamsUrl(request_url, parameters);

        // Execute request & return
        let output = false;

        return axios.get(request_url)
        .then((response) => {
            output = response.data.success;

            if (output) {
                commit('setTotalGameEntries', response.data.total);
                commit('setGameEntries', response.data.data);
            } else {
                commit('setError', response.data.message);
                commit('setHumanError', response.data.human_message);
            }
        })
        .catch(function (error) {
            commit('setError', error.response ? error.response.data.message : error);
            commit('setHumanError', error.response ? error.response.data.human_message : error);
            output = false;
        })
        .then(function () {
            commit('setLoadingGameEntries', false);
            return output;
        });
    },

    clearError({ commit }) {
        commit('setError', null);
        commit('setHumanError', null);
    },

    // Reset store to its initial state
    reset({ commit }) {
        commit('setGameEntries', []);
        commit('setTotalGameEntries', null);
        commit('setLoadingGameEntries', true);
        commit('setError', null);
        commit('setHumanError', null);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
