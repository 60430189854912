import { mapGetters } from 'vuex';
import TableReports from '@/components/Rail/TableReports/TableReports.vue';
import UserDropdown from '@/components/Rail/UserDropdown/UserDropdown.vue';
import OperationDropdown from '@/components/Rail/OperationDropdown/OperationDropdown.vue';
import ReportStatusDropdown from '@/components/Rail/ReportStatusDropdown/ReportStatusDropdown.vue';
import DatePickerRange from '@/components/DatePickerRange/DatePickerRange.vue';
import AuthMixin from '@/mixins/Auth.vue';

export default {
    name: 'ReportsView',

    components: {
        TableReports,
        UserDropdown,
        OperationDropdown,
        ReportStatusDropdown,
        DatePickerRange,
    },

    mixins: [
        AuthMixin,
    ],

    computed: {
        ...mapGetters({
            heroDatePickerValue: 'UI/heroDatePickerValue',
        }),

        computedCalendarDates: {
			get() {
				return this.date;
			},
			set(newValue) {
                this.date = newValue;
				this.$store.commit('UI/setHeroDatePickerValue', newValue);
			}
        },

        // computed property passed to TableTransfers
        formFilters: {
            get() {
                return this.form;
            },
            set(newVal) {
                this.form = newVal;
            }
        },
    },

    data() {
        return this.initialState();
    },
    
    methods: {
        initialState() {
            return {
                date: [],
                form: {
                    user: null,
                    status: [],
                    operation: [],
                }
            }
        },
    }
}
