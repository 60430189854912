import { mapGetters } from 'vuex';
import Hero from '@/components/Hero/Hero.vue';
import TableGames from '@/components/Scheduling/Tables/TableGames/TableGames.vue';
import NetworkSharkscopeDropdown from '@/components/Scheduling/Menus/NetworkSharkscopeDropdown/NetworkSharkscopeDropdown.vue';
import GameFlagsCombobox from '@/components/Scheduling/Menus/GameFlagsCombobox/GameFlagsCombobox.vue';
import DatePickerRange from '@/components/DatePickerRange/DatePickerRange.vue';
import BuyinDropdown from '@/components/Scheduling/Menus/BuyinDropdown/BuyinDropdown.vue';
import UserDropdown from '@/components/Rail/UserDropdown/UserDropdown.vue';
import LoadingSpinner from '@/components/LoadingSpinner/LoadingSpinner.vue';

export default {
    name: 'Games-All',

    components: {
        Hero,
        NetworkSharkscopeDropdown,
        GameFlagsCombobox,
        DatePickerRange,
        BuyinDropdown,
        UserDropdown,
        TableGames,
        LoadingSpinner
    },

    computed: {
        ...mapGetters({
            gameGroup: 'schedulingGameGroups/data',
            loading: 'schedulingGameGroups/loading',
            error: 'schedulingGameGroups/error',
        }),

        // computed property passed to TableGames
        formFilters: {
            get() {
                return this.form;
            },
            set(newVal) {
                this.form = newVal;
            }
        },
    },

    data() {
        return this.initialState();
    },

    created() {
        if (this.$route.params.id) {
            this.getSchedulingGameGroup(this.$route.params.id);
        }
    },

    methods: {
        initialState() {
            return {
                groupId: null,
                groupName: null,
                form: { dateRange: [this.$moment("2021-01-01"), this.$moment()], },
            };
        },

        async getSchedulingGameGroup(id) {
            await this.$store.dispatch('schedulingGameGroups/getById', id);
            this.groupName = this.gameGroup.name;
        },
    },

    watch: {
        /**
         * runs every time some nested object has changed
         * sets formFilters computed property that will be passed as prop to TableGames
         */
        form: {
            handler(newVal) {
                this.formFilters = newVal;
            },
            deep: true,
        },
    },
}