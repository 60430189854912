import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth.vue';

export default {
    name: 'GroupDropdown',

    mixins: [
        AuthMixin,
    ],

    props: {
        value: {
            type: [Array,Object],
            required: false,
            default: () => []
        },
        rules: {
            type: Array,
            required: false,
            default: () => []
        },
        multiple: {
            type: Boolean,
            required: false,
            default: true,
        },
        returnObject: {
            type: Boolean,
            required: false,
            default: false,
        },
        solo: {
            type: Boolean,
            required: false,
            default: true,
        },
        outlined: {
            type: Boolean,
            required: false,
            default: false,
        },
        hideDetails: {
            type: Boolean,
            required: false,
            default: true,
        },
        excludeGroups: {
            type: Array,
            required: false,
            default: () => []
        },
        dense: {
            type: Boolean,
            required: false,
            default: false,
        },
        clearable: {
            type: Boolean,
            required: false,
            default: false,
        },
        showLabel: {
            type: Boolean,
            required: false,
            default: true,
        },
        filterGroups: {
            type: String,
            required: false,
            default: '',
        }
    },

    data() {
        return this.initialState();
    },

    created() {
        this.initialize();
        this.emitChange();
    },

    mounted() {
        // Clear error triggered naturally when element is loaded
        this.$refs.formGroupDropdown.resetValidation();
    },
    
    computed: {
        ...mapGetters({
            groups: 'groups/groups',
            loading: 'groups/loading',
            teamFilter: 'teams/teamFilter',
        }),

        // Synthetic v-model
        computedValue: {
            get: function () {
                return this.group;
            },
            set: function (newValue) {
                this.group = newValue;
                this.emitChange();
            }
        },

        filteredGroups () {
            let idActual
            if(this.value && this.value.id){
                idActual = this.value.id
            }
            if (this.filterGroups === 'HIGHER') {
                if(this.filterGroupsWithImage().filter(item => item.next !== null && item.next > idActual).length == 0){
                    return this.filterGroupsWithImage()
                }else{
                    return this.filterGroupsWithImage().filter(item => item.next !== null && item.next > idActual)
                }
            }
            else if (this.filterGroups === 'LOWER') {
                if(this.filterGroupsWithImage().filter(item => item.previous !== null && item.previous < idActual).length == 0){
                    return this.filterGroupsWithImage()
                }else{
                    return this.filterGroupsWithImage().filter(item => item.previous !== null && item.previous < idActual)
                }
            }
            return this.filterGroupsWithImage()
        },

        // return prop rules
        computedRules: function () {
            return this.rules;
        },
    },

    watch: {
        value: function(newValue) {
            this.computedValue = newValue;
        },
        teamFilter: function (newValue) {
            this.computedValue = this.multiple ? [] : {};
            this.fetchData();
        },
    },

    methods: {
        initialState() {
            return {
                group: null,
                options: {
                    page: 1,
                    itemsPerPage: -1,
                }
            }
        },

        initialize() {
            // Initial value passed through prop
            this.computedValue = this.value;
            this.fetchData();
        },

        filterGroupsWithImage() {
            return this.groups.filter (item => item.image !== undefined)
        },

        async fetchData() {

            // Base payload
            let payload = {
                params: {
                page: this.options.page,
                itemsPerPage: this.options.itemsPerPage,
                }
            };

            // if team exists and isManagement, Add team parameter to payload.params
            if (this.teamFilter && this.userHasRailAccessDivisionDropdown) {
                payload.params.team = this.teamFilter;
            }
            
            // get groups list
            await this.$store.dispatch('groups/get', payload);
        },

        reset() {
            Object.assign(this.$data, this.initialState());
            this.$refs.formGroupDropdown.reset();
        },

        emitChange() {
            this.$emit('input', this.computedValue);
            this.$emit('change', this.computedValue);
        },
    }
}
