var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v(" Lista de Grupos de Jogos "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-icon',{staticClass:"pl-2 pb-1"},[_vm._v(" mdi-information-outline ")])],1)]}}])},[_c('span',[_vm._v(" Grupos de jogos a avaliar pelos modelos ")])]),_c('v-spacer'),_c('v-text-field',{staticClass:"table-search-field d-none d-md-flex pr-5",attrs:{"append-icon":"mdi-magnify","label":"Pesquisar","single-line":"","hide-details":"","outlined":"","dense":""}})],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.gameGroups,"server-items-length":_vm.totalData,"options":_vm.options,"footer-props":_vm.footerProps,"loading":_vm.loading,"loading-text":"A carregar..."},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.full_buyin",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.currency.symbol)+" "+_vm._s(item.buyin)+" ")]}},{key:"item.isKO",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.isKO ? 'success' : 'error'}},[_vm._v(" "+_vm._s(_vm.getBooleanIcon(item.isKO))+" ")])]}},{key:"item.isPKO",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.isPKO ? 'success' : 'error'}},[_vm._v(" "+_vm._s(_vm.getBooleanIcon(item.isPKO))+" ")])]}},{key:"item.allowsRebuys",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.allowsRebuys ? 'success' : 'error'}},[_vm._v(" "+_vm._s(_vm.getBooleanIcon(item.allowsRebuys))+" ")])]}},{key:"item.allowsReentries",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.allowsReentries ? 'success' : 'error'}},[_vm._v(" "+_vm._s(_vm.getBooleanIcon(item.allowsReentries))+" ")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":'/scheduling/gameGroups/' + item.id + '/'}},[_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v(" mdi-eye ")])],1)],1)]}}],null,true)},[_c('span',[_vm._v("Ver detalhes")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":'/scheduling/gameGroups/' + item.id + '/games'}},[_c('v-btn',_vm._g({staticClass:"ml-1",attrs:{"icon":""}},on),[_c('v-icon',[_vm._v(" mdi-format-list-group ")])],1)],1)]}}],null,true)},[_c('span',[_vm._v("Listar jogos")])])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }