import { mapGetters } from 'vuex';
import EmptyStateNoData from '@/components/EmptyStateNoData';
import DataMixin from '@/mixins/Data.vue';
import AuthMixin from '@/mixins/Auth.vue';
import UserCard from '@/components/UserCard/UserCard.vue';

export default {
  name: 'TableProfitPlayer',

  components: {
    EmptyStateNoData,
    UserCard,
  },

  props: {
    maxHeight: {
      type: String,
      required: false,
      default: null
    },
    minHeight: {
      type: String,
      required: false,
      default: '588px'
    },
  },

  mixins: [
    DataMixin,
    AuthMixin,
  ],

  data() {
    return this.initializeState();
  },

  computed: {
    ...mapGetters({
      networkPnlUsers: 'networks/networkPnlUsers',
      networkPnlUsersLoading: 'networks/networkPnlUsersLoading',
      networkPnlUsersTotal: 'networks/networkPnlUsersTotal',
      heroDatePickerValue: 'UI/heroDatePickerValue',
      teamFilter: 'teams/teamFilter',
      selectedNetwork: 'networks/selectedNetwork',
    }),

    heightComputed: function () {
      if (this.maxHeight) {
        return this.maxHeight;
      }

      return '588px';
    },

    currencyToBeDisplayed: function () {
      if (this.selectedNetwork) {
        return this.selectedNetwork.currency.symbol;
      }
    },

    optionsComputed: {
      get: function () {
        return this.options;
      },
      set: function (newValue) {
        if (!this._.isEqual(this.options, newValue)) {
          this.options = newValue;
        }
      }
    }

  },

  watch: {
    // watches this.options object
    // triggers everytime this.options has changed
    options: {
      handler() {
        if (!this.fetchLoading) {
          this.fetchData();
        }
      },
      deep: true,
    },
    heroDatePickerValue: function () {
      if (!this.fetchLoading) {
        this.fetchData();
      }
    },
    teamFilter: function () {
      if (!this.fetchLoading) {
        this.fetchData();
      }
    },
  },

  created() {
    this.fetchData();
  },

  methods: {
    initializeState() {
      return {
        fetchLoading: false,
        headers: [
          { text: 'Jogador', value: 'name', sortable: false },
          { text: 'Resultado', value: 'pnl.value', sortable: false },
          { text: 'Equipa', value: 'teams', sortable: false },
        ],
        dataTable: {
          search: null,
        },
        options: {
          page: 1,
          itemsPerPage: 10,
          sortBy: [],
          sortDesc: [],
          groupBy: [],
          groupDesc: [],
          mustSort: true,
          multiSort: false
        },
      };
    },
    /**
     * Get chip color based item value
     * @param {Number} item 
     * @returns {String} color
     */
    getChipColor(item) {
      let output = 'grey';
      if (item > 0) {
          output = 'success';
      } else if (item < 0) {
          output = 'error';
      } else {
          output = 'grey';
      }

      return output;
    },

    async fetchData() {

      this.fetchLoading = true;

      // Base payload
      let payload = {
        id: this.$route.params.id,
        params: {
          page: this.options.page,
          itemsPerPage: this.options.itemsPerPage,
        }
      };

      // Add name parameter to payload.params
      if (!this._.isEmpty(this.dataTable.search)) {
        payload.params.name = this.dataTable.search;
      }

      // if team exists and isManagement, Add team parameter to payload.params
      if (this.teamFilter && this.userHasRailAccessDivisionDropdown) {
        payload.params.team = this.teamFilter;
      }

      if (!this._.isEmpty(this.heroDatePickerValue)) {
        payload.params.dateBegin = this.heroDatePickerValue[0];
        payload.params.dateEnd = this.heroDatePickerValue[1];
      }

      // Add sortDesc parameter to payload.params
      Object.assign(payload.params, this.sortDescHandler(this.options.sortDesc));

      // Add sortBy parameter to payload
      this.addParameterToPayload(payload, 'sortBy[]', this.options.sortBy);

      await this.$store.dispatch('networks/getNetworkPnlUsers', payload);

      this.fetchLoading = false;
    },
  },
};
