export default {
    name: 'LoadingSpinner',

    props: {
        isLoading: {
            type: Boolean,
            required: false,
            default: false
        },
        title: {
            type: String,
            required: false,
            default: null
        },
        size: {
            type: Number,
            required: false,
            default: 110,
        },
        message: {
            type: String,
            required: false,
            default: null
        },
        size: {
            type: Number,
            required: false,
            default: 110
        }
    },
};
