<template>
  <v-dialog
    max-width="400"
    :value="dialogOpen"
    @input="closeDialog()"
    @click:outside="closeDialog()"
  >
    <v-card>
      <v-card-title class="headline">
        Finalizar formulário de exclusão
      </v-card-title>

      <v-card-text>
        Tem a certeza que pretende assinalar este formulário como finalizado? Esta ação é irreversível
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn
          text
          @click="closeDialog()"
        >
          Cancelar
        </v-btn>
        <v-btn
          color="success"
          text
          @click="confirmFinalize()"
        >
          Confirmar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: 'DeparturesDialogFinalize',

    components: {
    },

    mixins: [],

    props: {
        dialogOpen: { type: Boolean, required: true, default: false },
        action: { type: String, required: false, default: null }
    },

    data() {
        return {
        }
    },

    computed: {
        ...mapGetters({
            selectedDeparture: 'departures/selectedDeparture',
            reasonsDeparture: 'departures/reasonsDeparture',
            users: 'users_v1/users'
        }),
    },

    created() {
        //this.initialize();
    },

    methods: {
        closeDialog() {
            this.$emit('close-dialog');
        },

        confirmFinalize() {
            this.$emit('confirm-finalize');
        },
    }
}
</script>

<style scoped>
</style>