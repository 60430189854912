<template>
  <v-form
    ref="formRailSplit"
    lazy-loading
    class="fill-height"
  >
    <component
      :is="componentToShow"
      ref="formSplitRouting"
    />
  </v-form>
</template>

<script>
import CardSplitStage1 from "@/components/Rail/CardSplit/Stage1/CardSplit.vue";
import CardSplitStage2 from "@/components/Rail/CardSplit/Stage2/CardSplit.vue";
import { mapGetters } from "vuex";
import AuthMixin from "@/mixins/Auth.vue";

export default {
	name: "CardSplit",

	components: { 
		CardSplitStage1,
		CardSplitStage2,
		},

	mixins: [
		AuthMixin,
		],

	computed: {
		...mapGetters({
			selectedRequest: "railRequests/selectedRequest",
		}),
		componentToShow() {
			// if create by user and phase equals to 2
			let createdByUser = this.selectedRequest &&	!this.selectedRequest.createdByManagement && this.selectedRequest.phase == 2;
      		// if create by admin
			let createdByAdmin = this.selectedRequest && this.selectedRequest.createdByManagement;
			// if phase equals to 3
			let adminStage2 = this.selectedRequest && this.selectedRequest.phase == 3


			let pageToShow =
				createdByUser || createdByAdmin || adminStage2
					? "CardSplitStage2"
					: "CardSplitStage1";

			return pageToShow;
		},
	},
	methods: {
		/** This CardBankrollReduction.vue is focus on routing/component to show based on user role
		 *  When Bankroll Reduction view (current action)  by clicking submit button it triggers this submit() present in this file
		 *  This submit() triggers the submit() in child component with ref formBankrollReductionChild
		 */
		submit() {
			this.$refs.formSplitRouting.submit();
		},
	},
};
</script>
