var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.gameGroups,"server-items-length":_vm.totalData,"options":_vm.options,"loading":_vm.loading,"loading-text":"a carregar..."},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.gameGroup.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":'/scheduling/games/' + item.gameGroup.id + '/'}},[_vm._v(" "+_vm._s(item.gameGroup.name)+" ")])]}},{key:"item.full_buyin",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.gameGroup.currency.symbol)+" "+_vm._s(item.gameGroup.buyin)+" ")]}},{key:"item.finalScore",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"align-self-center mr-2",staticStyle:{"font-size":"0.8rem"}},[_vm._v("("+_vm._s(item.finalScore)+")")]),_c('v-rating',{attrs:{"background-color":"grey","color":"yellow","half-increments":"","readonly":"","dense":""},model:{value:(item.finalScore),callback:function ($$v) {_vm.$set(item, "finalScore", $$v)},expression:"item.finalScore"}})],1)]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.toggleScoredGameGroupDetails(item.gameGroup.id)}}},on),[_c('v-icon',[_vm._v(" mdi-eye ")])],1)]}}],null,true)},[_c('span',[_vm._v("Ver")])])]}},{key:"no-data",fn:function(){return [(!_vm.loading)?_c('EmptyStateNoData',{attrs:{"icon":"mdi-check-circle-outline","title":"Não existem grupos de jogos","class-color":"success white--text","class-icon":"white"}}):_vm._e()]},proxy:true}])}),_c('DialogScoredGameGroupDetails',{attrs:{"open":_vm.dialogScoredGameGroupDetails,"game-group":_vm.selectedGameGroup},on:{"close":function($event){return _vm.toggleScoredGameGroupDetails(null)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }