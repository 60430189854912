<template>
  <v-form
    ref="formBankrollReductionRouting"
    lazy-loading
    class="fill-height"
  >
    <component
      :is="componentToShow"
      ref="formBankrollReductionChild"
    />
  </v-form>
</template>

<script>
import CardBankrollReductionUser from "@/components/Rail/CardBankrollReduction/Stage1/user/CardBankrollReduction.vue";
import CardBankrollReductionAdmin from "@/components/Rail/CardBankrollReduction/Stage1/admin/CardBankrollReduction.vue";
import AuthMixin from "@/mixins/Auth.vue";

export default {
	name: "CardBankrollReduction",

	components: {
		CardBankrollReductionUser,
		CardBankrollReductionAdmin
		},

	mixins: [
		AuthMixin
		],
	
	computed: {
		componentToShow() {
			let pageToShow = this.userIsRailManager
				? "CardBankrollReductionAdmin"
				: "CardBankrollReductionUser";

			return pageToShow;
		},
		
	},
	methods: {

		/** This CardBankrollReduction.vue is focus on routing/component to show based on user role
		 *  When Bankroll Reduction view (current action)  by clicking submit button it triggers this submit() present in this file
		 *  This submit() triggers the submit() in child component with ref formBankrollReductionChild
		 */
		submit() {
			this.$refs.formBankrollReductionChild.submit();
		}
	}
};
</script>
