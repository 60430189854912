import Vue from 'vue';
import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth.vue';
import RailMixin from '@/mixins/Rail.vue';
import NicknameDropdown from '@/components/Rail/NicknameDropdown/NicknameDropdown.vue';
import UserDropdown from '@/components/Rail/UserDropdown/UserDropdown.vue';
import InputFinancial from '@/components/InputFinancial/InputFinancial.vue';

export default {
    name: 'NetworkPicker',

    components: {
        NicknameDropdown,
        UserDropdown,
        InputFinancial,
    },

	props: {
        user: { type: Object, required: false, default: () => {} },
        excludedNetworks: { type: Array, required: false, default: () => []  },
        selectUserDisabled: { type: Boolean, required: false, default: false },
        currencies: { type: Array, required: false, default: () => [] },
        action: {
			type: String,
			required: false,
			default: 'create',
			validator: function(value) {
				const allowedActions = ['create', 'update']
				return allowedActions.includes(value);
			},
		},
    },

    data() {
        return this.initialState();
    },

    mixins: [
        AuthMixin,
        RailMixin,
    ],

    computed: {
        ...mapGetters({
            users: 'users/usersAllActive',
            usersLoading: 'users/loading',
            selectedSession: 'railSessions/selectedSession',
            nicknames: 'nicknames/nicknames',
            nicknameshasLoaded: 'nicknames/hasLoaded',
            nicknamesByUserID: 'nicknames/nicknamesByUserID',
            hasLoadedNicknamesByUserID: 'nicknames/hasLoadedNicknamesByUserID',
            existsItemsAvailableAfterFilter: 'nicknames/existsItemsAvailableAfterFilter',
            numberOfItemsAvailableAfterFilter: 'nicknames/numberOfItemsAvailableAfterFilter',
        }),

        computedUser: {
            get: function () {
                return this.form.user;
            },
            set: function (newValue) {
                this.form.user = newValue;
                return newValue;
            },
        },

        userId: function () {
            return this.computedUser
                ? this.computedUser.id
                : null;
        },

        // @returns {Object} props passed to NicknameDropdown
        nicknameDropdownProps() {
            return {
                ref: "NicknameDropdown",
                type:"PLAYER",
                scope: this.userIsRailManager ? 'ALL' : 'MINE',
                user: this.userIsRailManager ? this.userId : null,
                currencies: this.currencies,
                readonly: this.userIsRailManager && ! this.userId,
                'always-reload': !this.userIsRailManager,
                excludeZeroBalance: this.userIsPlayer && this.action == 'create',
                label: "Sala",
            }
        },

        selectedNetworkIds: function () {
            return this._.map(this.sessionNicknamesList, 'nickname.network.id');
        },

        // @returns Boolean value , if true shows error message in Alert Component
        showAlertUserHasNicknames: function () {
            return this.userIsRailManager && this._.isEmpty(this.nicknamesByUserID) && this.hasLoadedNicknamesByUserID && !this._.isNull(this.computedUser);
        },

        classCustomContainer: function () {
            let isScrollOn = this.sessionNicknamesList.length > 1;

            return isScrollOn ? 'container-scroll' : '';
        }
    },

    watch: {
        // Everytime sessionNicknamesList change, we emit an event
        sessionNicknamesList: {
            handler() {
                this.emitChange();
            },
            deep: true
        },

        // everytimes user prop changes, it updates computedUser
        user: {
            handler() {
              // Initial value passed through prop
                this.computedUser = this.user;
            },
            deep: true,
            immediate: true,
          }
    },

    created() {
        // Fetch data
        this.fetchData();

        this.emitChange();
    },

    methods: {
        initialState() {
            return {
                sessionNicknamesList: [],
                form: {
                    user: null,
                    nickname: null,
                    balance: {
                        value: 0,
                    },
                    currency: null
                },
                rules: {
                    nickname: [(v) => !!v || 'Selecione uma sala'],
                    balance: [
                        (v) => !!v || 'Introduza um montante',
                        (v) => ! Number.isNaN(v)|| 'Introduza um valor numérico',
                    ]
                },
                showError: false,
            }
        },

        /**
         * Updates sessionNicknamesList each object (element.excludedNetworks) field with excludedNetworks
         * @returns {Array} with updated objects
         */
        filterNetworks() {
            let output = [];
            if (!this._.isEmpty(this.sessionNicknamesList)) {
                output = this.sessionNicknamesList.forEach(element => {
                    return element.excludedNetworks = element.nickname
                        ? this.selectedNetworkIds.filter(id => id != element.nickname.network.id)
                        : this.selectedNetworkIds;
                });
            }
            return output;
        },

        // add an empty entry to sessionNicknamesList
        add() {
            this.sessionNicknamesList.push({
                nickname: null,
                balance: 0,
                rakebackBalance: 0, 
                rakebackTickets: 0,
                rakebackCoins: 0,
                excludedNetworks: this.selectedNetworkIds ? this.selectedNetworkIds : []
            });

            // Scroll user down
            setTimeout(() => {
                document.getElementById('divNicknameList').scrollTop = document.getElementById('divNicknameList').scrollHeight;
            }, 1);
        },

        /**
         * Removes entry from SessionNicknamesList based on item id and index position
         * Runs filterNetworks() to update the remaining input entries.
         * @param {Object} item 
         * @param {Number} index 
         */
        remove(item, index) {
            let indexPosition;

            // when NicknameDropdown is fullfilled it findIndex by id, else we it findIndex by index
            indexPosition = item
                ? this.sessionNicknamesList.findIndex(e => e.nickname.id == item.id)
                : this.sessionNicknamesList.findIndex(e => e == index);
            
            // remove entry from SessionNicknamesList
            this.sessionNicknamesList.splice(indexPosition, 1);

            // run in order to update the remaining NicknamesDropdown inputs
            this.filterNetworks();
        },

        /**
         * Fetch data from the store
         */
        async fetchData() {
            // get nicknames with status 1 and 2
            let payload = {
                params: {
                    'status[0]': Vue.prototype.$nickname_status.active.value,
                    'status[1]': Vue.prototype.$nickname_status.flagged.value,
                    itemsPerPage: -1,
                    page: 1
                }
            }

            // Get users (if admin)
            if (this._.isEmpty(this.users)) {
                if (this.userIsRailManager) {
                    let payloadUsers = this.formulateUsersAllUrlParameters();
                    this.$store.dispatch('users/getUsersAllActive', payloadUsers);
                }
            }

            // Get nicknames
            if (this._.isEmpty(this.nicknames)) {
                if (!this.userIsRailManager) {
                    this.$store.dispatch('nicknames/get', payload);
                }
            }

            /** For every object inside this.selectedSession.sessionNicknames[]
             *  We create sessionNicknameObject and push it to sessionNicknamesList[]
             */
            if (this.action == 'update' && this.selectedSession) {
                this.selectedSession.sessionNicknames.forEach(element => {
                    this.sessionNicknamesList.push({
                        nickname: element.nickname,
                        balance: element.balance.value,
                        rakebackBalance: element.rakebackBalance,
                        rakebackTickets: element.rakebackCoins,
                        rakebackCoins: element.rakebackTickets,
                        excludedNetworks: []
                    })
                });
            }

            // if creating New Session, adds input to be fullfilled
            if (this.action == 'create') {
                this.sessionNicknamesList.push({
                    nickname: null,
                    balance: 0,
                    excludedNetworks: []
                })
            };
        },

        // clean fields and sessionNicknamesList from NetworkPicker
        reset() {
            Object.assign(this.$data, this.initialState());
            this.$refs.form.reset();
        },

        emitChange() {
            this.$emit('change', this.generateOutput());
            this.$emit('input', this.generateOutput());
        },

        /**
         * Generates an array with objects containing nicknameID and balance
         * @returns {Array} 
         */
        generateSessionNicknamesList() {
            let output = [];

            // maps new array to retrieve only nicknameID and balance.
            if ( !this._.isEmpty(this.sessionNicknamesList)) {
                output = this.sessionNicknamesList.map(element => {
                    if (element && element.nickname && element.nickname.id) {
                        return {
                            nicknameID: element.nickname.id,
                            balance: element.balance,
                            rakebackBalance: element.rakebackBalance,
                            rakebackTickets: element.rakebackTickets,
                            rakebackCoins: element.rakebackCoins,
                            network: element.nickname.network
                        };
                    }
                })
            }
            // removes all undefined values
            output = output.filter(element => element != undefined);
            return output;
        },

        /**
         * Generates the output of the NetworkPicker component.
         * @returns {Object}
         */
        generateOutput() {
            return {
                user: this.form.user,
                nicknames: this.generateSessionNicknamesList(),
            };
        },

        toggleShowError() {
            this.showError = ! this.showError;
        },

        setShowError(v) {
            this.showError = v;
        },

        setChanges() {
            if (this.action == 'create') {
                // clean sessionNicknamesList when user has changed
                this.sessionNicknamesList = [];
                // add an empty entry
                this.add();
            }
        },
    }
}
