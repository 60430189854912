import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth';
import DashboardAdmin from '@/views/Dashboard/Dashboard-Admin/Dashboard-Admin.vue';
import DashboardUser from '@/views/Dashboard/Dashboard-User/Dashboard-User.vue';
import Loading from '@/views/Loading.vue';

export default {
    name: 'Dashboard',

    mixins: [
      AuthMixin,
    ],

    components: {
      DashboardAdmin,
      DashboardUser,
      Loading,
    },

    computed: {
      ...mapGetters({
          userType: 'auth/userType',
          userRoles: 'auth/userRoles'
        }),
  
      componentToShow() {

        if (this._.isEmpty(this.userRoles)) {
          return 'Loading';
        }

        return  this.userHasRole(['rail_admin', 'rail_manager', 'tracker_admin', 'tracker_manager'])
                ? 'DashboardAdmin'
                : 'DashboardUser';
      }
    },

    created() {
      this.initialize();
    },

    data() {
      return this.initialState();
    },

    methods: {
      initialize() {
      },

      initialState() {
        return {}
      },
    }
}