<template>
  <h1>Scheduling-User</h1>
</template>

<script>
export default {
  name: 'SchedulingUser'
}
</script>

<style scoped>
</style>