<script>
import Vue from 'vue';
import moment from 'moment';

export default {
	methods: {
		arrayColumn(array, column) {
			if (Array.isArray(array)) return array.map((e) => e[column]);
			else return null;
		},

		/* https://stackoverflow.com/a/57693672/1298923 */
		validateDate(date) {
			let dateVariable = new Date(date);
			return dateVariable !== "Invalid Date";
		},

		getDateFirstOfTheMonth(currentDate = new Date()) {
			let outputDate = new Date(
				currentDate.getFullYear() +
					"-" +
					String(currentDate.getMonth() + 1).padStart(2, "0") +
					"-" +
					"01"
			);
			return outputDate;
		},

		/* https://stackoverflow.com/a/4929629/1298923 */
		generateHtmlFormatDateString(date = new Date()) {
			let dd = String(date.getDate()).padStart(2, "0");
			let mm = String(date.getMonth() + 1).padStart(2, "0"); //January is 0!
			let yyyy = date.getFullYear();

			let formattedDate = yyyy + "-" + mm + "-" + dd;
			return formattedDate;
		},

		generateMySQLDateTime(date = new Date()) {
			return moment(date).format("YYYY-MM-DD HH:mm:ss");
		},

		/* https://stackoverflow.com/a/58906636/1298923 */
		formatNumber(num) {
			let formatted = (num + "").replace(
				/(\..*)$|(\d)(?=(\d{3})+(?!\d))/g,
				(digit, fract) => fract || digit + ","
			);
			return formatted;
		},

		/* https://stackoverflow.com/a/33457557/1298923
		 * Alternatively use lodash library */
		range(start, end, step = 1) {
			const len = Math.floor((end - start) / step) + 1;
			return Array(len)
				.fill()
				.map((_, idx) => start + idx * step);
		},

		/* https://stackoverflow.com/a/4444497/1298923 */
		StringContains(needle, haystack) {
			return haystack.indexOf(needle) > 1;
		},

		computeBooleanToVerbal(value) {
			if (value) return "Sim";
			else return "Não";
		},

		computeBooleanToColor(value) {
			if (value) return "green";
			else return "red";
		},

		/* https://stackoverflow.com/a/52201499/1298923 */
		convertDateForAPIRequest(date) {
			if (date) return moment(date).format("YYYY-MM-DD HH:mm:ss");
			else return null;
		},

		convertDateForDisplay(date) {
			if (date) {
				return new Date(date);
			} else {
				return null;
			}
		},

		joinNameWithNickname(name, nickname) {
			if (nickname) return name.replace(" ", ' "' + nickname + '" ');
			else return name;
		},

		handleRequestParameters(parameters) {
			let outputParameters = new URLSearchParams();

			for (const [key, value] of Object.entries(parameters))
				outputParameters.append(key, value);

			return outputParameters;
		},

		/* Based on https://stackoverflow.com/a/51278280/1298923 */
		isJsonString(str) {
			if (!str) return false;

			try {
				let json = JSON.parse(str);
				return typeof json === "object";
			} catch (e) {
				return false;
			}
		},

		formulateRequestParameters(data) {
			let parameters = new URLSearchParams();

			/*
            for (const[key,value] of Object.entries(data))
                if (value)
                    parameters.append(key, value);
            */

			for (const [key, value] of Object.entries(data))
				parameters.append(key, value);

			return parameters;
		},

		getDateAgo(date) {
			return date ? moment(date).fromNow() : null;
		},

		dateDiff(d1, d2) {
			let d1f = this.$moment(d1).startOf("day");
			let d2f = this.$moment(d2).startOf("day");
			let diff = d1f.diff(d2f, "days");
			return diff;
		},

        /**
         * Converts string values 'true' / 'false' to boolean True / False
         * @param   {string} val
         * @returns {boolean}
         */
        strToBool(val) {
            return val === 'true';
        },

        /**
         * Sum function
         * WARNING: Consider using _.sumBy instead
         * @ref https://stackoverflow.com/questions/23247859/better-way-to-sum-a-property-value-in-an-array
         */
        sumValuesInArray(arrayValue, value) {
            Array.prototype.sum = function (prop) {
                var total = 0;
                for (var i = 0, _len = this.length; i < _len; i++) {
                    total += this[i].balance[prop];
                }
                return total;
            }
            return arrayValue.sum(value)
        },

        /**
         * if sortDesc exists, we map sortDesc array when false means 'ASC' and true means 'DESC'.
         * API is expecting data like this
         * @param {Array}
         * @returns {Object}
         */
        sortDescHandler(parameter) {
            if (!this._.isEmpty(parameter)) {
                let sortDesc = { 'sort[]': parameter.map(element => {
                    return element == false ? 'ASC' : 'DESC';
                })}
            return sortDesc;
            }
        },

        addParameterToPayload(payload, namefield, parameter) {
            // Add sortBy parameter to payload
            if (! this._.isEmpty(parameter)) {
                let payloadObject = Object.defineProperty(
                    payload.params,
                    namefield,
                    {
                        value: parameter,
                        writable: true,
                        enumerable: true,
                        configurable: true
                    }
                );
            
                return payloadObject;
            }
        },

        getExpiryColor(item) {
			if (item <= 0) return 'red';
            else if (item <= 30) return 'orange';
            else if (item <= 90) return 'blue';
            else return 'green';
        },

        /**
		 * @param {Integer} Status prop
		 * @returns {String} populated with name
		 */
		getNicknameStatus(item) {
            let output = null;

            let statusList = [
                { id: Vue.prototype.$nickname_status.active.value, name: Vue.prototype.$nickname_status.active.name },
                { id: Vue.prototype.$nickname_status.flagged.value, name: Vue.prototype.$nickname_status.flagged.name },
                { id: Vue.prototype.$nickname_status.deassociated.value, name: Vue.prototype.$nickname_status.deassociated.name },
                { id: Vue.prototype.$nickname_status.inactive.value, name:  Vue.prototype.$nickname_status.inactive.name },
			];

			if (item) {
				let statusName = statusList.find(element => element.id == item);
				output = statusName.name;
			}

            return output;
		},

		/**
		 * @param {Integer} Status prop
		 * @returns {String}
		 */
		getNicknameStatusColor(item) {
			let output;
			if (item) {
				switch (item) {
					case Vue.prototype.$nickname_status.active.value:
						output = 'success';
						break;
					case Vue.prototype.$nickname_status.flagged.value:
						output = 'warning';
						break;
					case Vue.prototype.$nickname_status.deassociated.value:
						output = 'warning';
						break;
					case Vue.prototype.$nickname_status.inactive.value:
						output = 'error';
						break;
					default:
						output = 'success';
						break;
				}
			}
			return output;
		},

		getUserStatus(status) {
 			return this._.has(Vue.prototype.$user_status, status-1)
			 	? Vue.prototype.$user_status[status-1].name
			 	: 'DESCONHECIDO OU ERRO';
		},

		getUserStatusIcon(status) {
    		const userStatus = this.$user_status.find(user => user.value === status);
    		return userStatus ? userStatus.icon : 'mdi-help-circle-outline';
		},
		
		getUserStatusColor(status) {
            if (status == 1)
                return 'error'
            else if (status == 2)
                return 'success'
            else
                return 'gray';
        },

		/**
		 * Convert a decimal number to percentual format (e.g. 0.255 => 25.5%)
		 * @param	{Float}	number Value to convert
		 * @returns	{Float}	Converted value
		 */
		convertDecimalToPercentage(number) {
			let output = 0;

			if (typeof number == 'number') {
				output = number * 100;
			}

			return output;
		},

		generateSharkScopeGameUrl(networkIdentifier, gameId) {
			return 'https://www.sharkscope.com/#Find-Tournament//networks/' + networkIdentifier + '/tournaments/' + gameId;
		},

		/**
		 * Convert a label String (number) into weekday
		 * @param {Array} item
		 * @returns {Array} item with converted values
		 */
		handleStatisticLabelsByWeekDay(item) {
			let output;
			if ( ! this._.isEmpty(item)) {
				output = item.map(element => Vue.prototype.$weekday[parseInt(element)]);
			};
			return output;
		},
    
		/**
         * 
         * @param {Number|String} value 
         * @param {Boolean} table used in Table Balances 
         * @returns {String} with width value
         */
        getCurrencyInputWidth(value, table=false) {
            let output = '50px';
            let adjustWidth = table
                              ? 2
                              : 1.5;

            if (value) {
                output = value;
                // if true, removes decimal 
                if (table) {
                   output = parseInt(output);
                }

                // turn output into String
                output = String(output);

                output = (output.length + adjustWidth) * 9 + 'px';
            }
            return output;
        },

		/**
		 * @param {String} startDate - Time format hh:mm:ss
		 * @param {String} endDate - Time format hh:mm:ss
		 * @returns {String} - format YYYY-MM-DD
		 */
		handleDateDefaultWhenEarly(startDate, endDate) {
			let startDateInterval = this.$moment(startDate, 'LT');
			let endDateInterval = this.$moment(endDate, 'LT');
			let isDateBetween = this.$moment().isBetween(startDateInterval, endDateInterval);
			let dayBefore = this.$moment().subtract(1, 'days').format('YYYY-MM-DD');

			return this.computedValue = isDateBetween
				? dayBefore
				: this.$moment().format('YYYY-MM-DD');
		},

		/**
		 * @param {String|Integer} epoch - Timestamp in epoch format (e.g. 1662796800000)
		 * @returns {String} - Date format (e.g. )
		 */
		epochToDateTime(epoch) {
			return moment(Number(epoch)).format('YYYY-MM-DDTHH:mm:ss');
		},

		/**
		 * @param {String} date - Date format (e.g. 2022-10-31)
		 * @returns {String} - Timestamp in epoch format (e.g. 1662796800000)
		 */
		dateToEpoch(date) {
			return moment(date).format('x')
		},

		/**
         * @param {Number} value (0 - 1)
         * @returns a color to fullfill progressbar
         */
		getProgressColor(value) {
			const colors = [
				{ value: 0, color: '#FF9800' },
				{ value: 0.3, color: '#FFB300' },
				{ value: 0.5, color: '#FDD835' },
				{ value: 0.7, color: '#CDDC39' },
				{ value: 0.9, color: '#8BC34A' },
				{ value: 1.0, color: '#43A047' },
			];

			const formattedValue = value ? value.toFixed(1) : value;
			const matchedColors = colors.find(t => formattedValue <= t.value);

			return matchedColors ? matchedColors.color : '#43A047';
		},

		areObjectsEqual(objA, objB) {
			// Verifica se os tipos dos objetos são iguais
			if (typeof objA !== typeof objB) {
				return false;
			}

			// Se os objetos são primitivos, basta compará-los diretamente
			if (typeof objA !== 'object') {
				return objA === objB;
			}

			// Obtém as chaves de ambos os objetos
			const keysA = Object.keys(objA);
			const keysB = Object.keys(objB);

			// Verifica se o número de chaves é o mesmo
			if (keysA.length !== keysB.length) {
				return false;
			}

			// Itera sobre as chaves e compara os valores recursivamente
			for (const key of keysA) {
				if (!this.areObjectsEqual(objA[key], objB[key])) {
				return false;
				}
			}

			// Se todas as comparações anteriores passaram, os objetos são iguais
			return true;
		}
	}
}
</script>
