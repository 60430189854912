var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"fill-height"},[_c('DialogAddEditSwapGroup',{attrs:{"is-open":_vm.dialogs.swapGroup,"action":_vm.dialogAction},on:{"close-dialog":function($event){_vm.dialogs.swapGroup = false}}}),_c('DialogConfirmation',{attrs:{"title":"Não podes ver este grupo de swap","body":"Não podes entrar neste grupo de swap porque existe 1 ou mais jogadores aos quais não tens acesso.","button-name":"Compreendo","button-color":"success","is-open":_vm.dialogs.showAlertUserAccess,"submitting":false,"show-cancel-button":false},on:{"submit":function($event){return _vm.toggleDialog('showAlertUserAccess')},"close-dialog":function($event){return _vm.toggleDialog('showAlertUserAccess')}}}),_c('v-card-title',[_vm._v(" "+_vm._s(_vm.tableTitle)+" "),_c('v-spacer'),(!_vm._.isEmpty(_vm.swapGroups))?_c('span',{staticClass:"swapdropdown-width"},[_c('SwapGroupsDropdown',{attrs:{"label":"Pesquisar por Swap Group","outlined":"","solo":false,"dense":""},model:{value:(_vm.swapGroup),callback:function ($$v) {_vm.swapGroup=$$v},expression:"swapGroup"}})],1):_vm._e(),(_vm.userIsRailManager)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-3",attrs:{"color":"secondary primary--text","elevation":"1","fab":"","small":""},on:{"click":function($event){return _vm.toggleDialog('swapGroup', 'create')}}},on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}],null,false,73503102)},[_c('span',[_vm._v("Registar Swap Group")])]):_vm._e()],1),_c('v-text-field',{staticClass:"table-search-field mx-4 d-none d-md-none",attrs:{"append-icon":"mdi-magnify","label":"Pesquisar","single-line":"","hide-details":""},on:{"input":function($event){return _vm.search()}},model:{value:(_vm.dataTable.search),callback:function ($$v) {_vm.$set(_vm.dataTable, "search", $$v)},expression:"dataTable.search"}}),_c('v-data-table',{staticClass:"table-footer-bottom",attrs:{"headers":_vm.dataTableHeadersComputed,"items":_vm.swapGroups,"search":_vm.dataTable.search,"server-items-length":_vm.swapGroupsTotal,"options":_vm.options,"footer-props":_vm.dataTable.footerProps,"loading":_vm.swapGroupsLoading,"must-sort":"","loading-text":"A carregar...","no-data-text":"Não foram encontradas pools registadas."},on:{"click:row":_vm.setActiveItem,"update:options":function (e) { return _vm.optionsComputed = e; }},scopedSlots:_vm._u([{key:"item.selected",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[(
                _vm.selectedSwapGroup && item.id == _vm.selectedSwapGroup.id
              )?_c('v-icon',{staticClass:"circle"}):_vm._e()],1)]}}],null,true)},[_c('span',[_vm._v("Grupo Swap pool Selecionado")])])]}},{key:"item.date",fn:function(ref){
              var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$moment.unix(item.createdAt).format('DD-MM-YYYY')))])]}},{key:"item.name",fn:function(ref){
              var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name))])]}},{key:"item.players",fn:function(ref){
              var item = ref.item;
return [(!_vm._.isEmpty(item.users))?_c('div',_vm._l((item.users),function(id){return _c('span',{key:id},[(_vm.handleUsername(id))?_c('router-link',{attrs:{"to":'/players/' + id + '/'}},[_c('v-chip',{staticClass:"mx-1 cursor-pointer",attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.handleUsername(id))+" ")])],1):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-chip',{staticClass:"mx-1 cursor-pointer white--text",attrs:{"small":"","color":"red"}},[_vm._v(" Jogador desconhecido ")])],1)]}}],null,true)},[_c('span',[_vm._v("Não tens acesso a este jogador porque não está na equipa "+_vm._s(_vm.getTeamDivision()))])])],1)}),0):_c('div',[_c('v-chip',{attrs:{"color":"error"}},[_vm._v(" Sem jogadores ")])],1)]}},{key:"item.active",fn:function(ref){
              var item = ref.item;
return [(item.active)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" mdi-check ")]):_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-close ")])]}},{key:"item.pnl",fn:function(ref){
              var item = ref.item;
return [_c('v-chip',{attrs:{"color":"green","text-color":"white"}},[_vm._v(" "+_vm._s(item.pnl)+" ")])]}},{key:"item.action",fn:function(ref){
              var item = ref.item;
return [(item.size <= 1)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v(" mdi-information-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v("Mínimo de 2 jogadores para utilizar o registo de swap pools")])]):_vm._e(),(!_vm.handleAccess(item.users))?_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":_vm.viewSwapGroup(item)}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":item.size <= 1,"icon":""}},on),[_c('v-icon',[_vm._v(" mdi-eye-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v("Ver Swap group")])])],1):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":item.size <= 1,"icon":""},on:{"click":function($event){return _vm.toggleDialog('showAlertUserAccess')}}},on),[_c('v-icon',[_vm._v(" mdi-eye-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v("Ver Swap group")])]),(_vm.userIsRailManager)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":_vm.handleAccess(item.users),"icon":""},on:{"click":function($event){return _vm.toggleDialog('swapGroup', 'edit')}}},on),[_c('v-icon',[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar Swap group")])]):_vm._e()]}},{key:"no-data",fn:function(){return [_c('v-spacer'),_c('EmptyStateNoData',{attrs:{"icon":"mdi-file-check-outline","title":"Não existem Swap pools"}}),_c('v-spacer')]},proxy:true},{key:"no-results",fn:function(){return [_c('EmptyStateNoData',{attrs:{"icon":"mdi-magnify","title":"Não foram encontrados resultados","message":"Ajuste os termos da sua pesquisa para encontrar mais resultados"}})]},proxy:true}])},[_c('v-spacer')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }