import Vue from 'vue';
import { mapGetters } from 'vuex';
import DataMixin from '@/mixins/Data';
import AuthMixin from '@/mixins/Auth.vue';
import RailMixin from '@/mixins/Rail.vue';

export default {
    name: 'UserDropdown',

    mixins: [
        DataMixin,
        AuthMixin,
        RailMixin,
    ],

    props: {
        value: {
            type: [Object,Array],
            required: false,
            default: () => {}
        },
        multiple: {
            type: Boolean,
            required: false,
            default: false,
        },
        label: {
            type: String,
            required: false,
            default: 'Jogador'
        },
        status: {
            type: String,
            required: false,
            default: ''
        },
        backgroundColor: {
            type: String,
            required: false,
            default: ''
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        flat: {
            type: Boolean,
            required: false,
            default: false
        },
        solo: {
            type: Boolean,
            required: false,
            default: false
        },
        clearable: {
            type: Boolean,
            required: false,
            default: false
        },
        outlined: {
            type: Boolean,
            required: false,
            default: true
        },
        dense: {
            type: Boolean,
            required: false,
            default: false
        },
        hideDetails: {
            type: Boolean,
            required: false,
            default: false
        },
        rules: {
            type: Array,
            required: false,
            default: () => [
                (v) => !!v || 'Por favor preencha o nome do jogador'
            ]
        },
        hint: {
            type: String,
            required: false,
            default: ''
        },
        autoReload: {
            type: Boolean,
            required: false,
            default: true
        },
        dense: {
            type: Boolean,
            required: false,
            default: false 
        },
        excludedUsers: {
            type: Array,
            required: false,
            default: () => []
        },
    },

    data() {
        return this.initialState();
    },

    created() {
        this.initialize();
        this.emitChange();
    },

    mounted() {
        // Clear error triggered naturally when element is loaded
        this.$refs.formUserDropdown.resetValidation();
    },

    computed: {
        ...mapGetters({
            users: 'users/usersAllActive',
            isLoading: 'users/loading',
            teamFilter: 'teams/teamFilter',
        }),
    
        // Synthetic v-model
        computedValue: {
            get: function () {
                return this.user;
            },
            set: function (newValue) {
                this.user = newValue;
                this.emitChange();
            }
        },

        computedRules: function () {
            return this.rules;
        },

        /**
         * Filters data according to the props passed
         * @returns {Array}
         */
        dataFiltered: function () {
            // Using slice() to avoid the following error
            // https://stackoverflow.com/questions/43151265/you-may-have-an-infinite-update-loop-in-a-component-render-function
            let output = this.users.slice();

            // (if set) Don't show specific users
            output = this.excludeUsers(output);
           
            return output;
        },
    },

    watch: {
        value: function(newValue) {
            this.computedValue = newValue;
        },
        teamFilter: function (newValue) {
            this.computedValue = this.multiple ? [] : {};
            this.fetchData();
        },
    },

    methods: {
        initialState() {
            return {
                user: null,
                search: null,
                options: {
                    page: 1,
                    itemsPerPage: -1,
                    sortBy: ['name'],
                    sortDesc: [false],
                },
                data: [],
            }
        },

        async initialize() {
            // Initial value passed through prop
            this.computedValue = this.value;

            if (this._.isEmpty(this.users) && this.autoReload) {
                await this.fetchData();
            }
        },

        async fetchData() {

            // Base payload
            let payload = this.formulateUsersAllUrlParameters();

            // add status parameter in order to filter by status, if empty it retrieves all users
            if (!this._.isEmpty(this.status)) {
                payload.params.status = this.status;
            }

            // if team exists and isManagement, Add team parameter to payload.params
            if (this.teamFilter && this.userHasRailAccessDivisionDropdown) {
                payload.params.team = this.teamFilter;
            }

            // Add sortDesc parameter to payload.params
            Object.assign(payload.params, this.sortDescHandler(this.options.sortDesc));

            // Add sortBy parameter to payload
            this.addParameterToPayload(payload, 'sortBy[]', this.options.sortBy);
            
            // get users list
            await this.$store.dispatch('users/getUsersAllActive', payload);
        },

        reset() {
            Object.assign(this.$data, this.initialState());
            this.$refs.formUserDropdown.reset();
        },

        emitChange() {
            this.$emit('input', this.computedValue);
            this.$emit('change', this.computedValue);
        },

        /**
         * Exclude certain users from the results.
         * @param {Array} filled with userID's
         * @returns {Array} filtered
         */
        excludeUsers(data) {
            let output = data;
            if (this.excludedUsers && this.excludedUsers.length) {
                return data.filter(e => !this.excludedUsers.includes(e.id));
            }

            return output;
        }
    }
}