import Vue from 'vue';
import Vuex from 'vuex';
import Overview from '@/components/TabSlider/Tabs/Overview/Overview.vue';
import Operations from '@/components/TabSlider/Tabs/Operations/Operations.vue';
import Sessions from '@/components/TabSlider/Tabs/Sessions/Sessions.vue';
import Transfers from '@/components/TabSlider/Tabs/Transfers/Transfers.vue';
import Swaps from '@/components/TabSlider/Tabs/Swaps/Swaps.vue';
import Action from '@/components/TabSlider/Tabs/Action/Action.vue';
import Reports from '@/components/TabSlider/Tabs/Reports/Reports.vue';
import Expenses from '@/components/TabSlider/Tabs/Expenses/Expenses.vue';
import Statistics from '@/components/TabSlider/Tabs/Statistics/User/Statistics.vue';

Vue.use(Vuex);

const state = {
    tabs: [
        { tab: 'Visão Geral', key: 1, path: '/rail/', content: Overview, disabled: false, show: true },
        { tab: 'Operações', key: 2, path: '/rail/operations/', content: Operations, disabled: false, show: true },
        { tab: 'Sessões', key: 3, path:'/rail/sessions/', content: Sessions, disabled: false, show: true },
        { tab: 'Transferências', key: 4, path:'/rail/transfers/', content: Transfers, disabled: false, show: true },
        { tab: 'Swaps', key: 5, path: '/rail/swaps/', content: Swaps, disabled: false, show: true },
        { tab: 'Reports', key: 6, path: '/rail/reports/', content: Reports, disabled: false, show: true },
        { tab: 'Despesas', key: 7, path: '/rail/expenses/', content: Expenses, disabled: false, show: false },
        { tab: 'Estatisticas', key: 8, path: '/rail/statistics/', content: Statistics, disabled: false, show: false },
        { tab: 'Ação', key: 9, path: '/rail/action/', content: Action, disabled: true, show: true }
      ],
    selectedTab: '/rail/',
    previousTab: null,
    disabledTab: true,
    hasFinished: false,
    card: null,
    tabHistory: [],
};

const getters = {
    tabs: (state) => state.tabs,
    selectedTab: (state) => state.selectedTab,
    previousTab: (state) => state.previousTab,
    disabledTab: (state) => state.disabledTab,
    card: (state) => state.card,
    hasFinished: (state) => state.hasFinished,
};

const mutations = {
    setSelectedTab(state, payload) {
        state.selectedTab = payload;

        // if current tab is "Current Action" then its active and disableTab is false
        if (payload == Vue.prototype.$tab_slider.tabs.action) {
            this.commit('TabSlider/setDisableTab', false)
        }

        // when current tab changes, it goes to the top page
        window.scrollTo({ top: 0, behavior: 'smooth' });
        
        // populate tabHistory[] in order to get previous tab
        state.tabHistory.push(payload)
        state.previousTab = state.tabHistory[state.tabHistory.length - 2]
       // console.log('state.previousTab =>', state.previousTab)
        if (state.previousTab == 5) {
            state.previousTab = 0
        }
    },

    setTabItems(state, payload) {
        state.tabs = payload
    },

    setDisableTab(state, payload) {
        state.disabledTab = payload
    },

    setCard(state, payload) {
        state.card = payload
    },

    setHasFinished(state, payload) {
        state.hasFinished = payload
    },

};

const actions = {
    setCurrentCard({ commit }, payload) {
        commit('setCard', payload);
        commit('setHasFinished', false);
        commit('setSelectedTab', Vue.prototype.$tab_slider.tabs.action);
    },

    setHasFinished({ commit }, payload) {
        commit('setHasFinished', payload);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};