import { mapGetters } from 'vuex';
import EmptyStateNoData from '@/components/EmptyStateNoData';
import LoadingSpinner from '@/components/LoadingSpinner/LoadingSpinner.vue';

export default {
    name: 'BarChartAvgBuyIn',

    components: {
        EmptyStateNoData,
        LoadingSpinner,
    },

	props: {
        barHorizontal: {
            type: Boolean,
            required: false,
            default: true
        },
	},

    // Setup vars used in the graph so it auto-updates if the data changes
    computed: {
        ...mapGetters({
            themeDarkMode: 'UI/themeDarkMode',
            heroDatePickerValue: 'UI/heroDatePickerValue',
            dataAverageBuyIn: 'railStatistics/dataAverageBuyIn',
            loadingDataAverageBuyIn: 'railStatistics/loadingDataAverageBuyIn',
        }),

        series: function () {
            return this.avgBuyInValuesFormatted;
        },

        abiPlayerColor: function () {
            let abiDiffPct = this.dataAverageBuyIn && this.dataAverageBuyIn.ABI_PLAYER_VS_GROUP ? this.dataAverageBuyIn.ABI_PLAYER_VS_GROUP.data.diffPct : 0;

            return abiDiffPct > 0
                ? '#008000'
                : '#FF0000';
        },

        abiPlayer: function () {
            return this.dataAverageBuyIn.ABI_PLAYER ? this.dataAverageBuyIn.ABI_PLAYER.value.USD.value : 0;
        },

        options: function () {
            return {
                chart: {
                    type: 'bar',
                    height: 440,
                    stacked: false,
                    fontFamily: 'Inter, sans-serif',
                    toolbar: {
                        show: false,
                        tools: {
                          download: false
                        }
                    }
                },

                colors: [this.abiPlayerColor, '#2196F3'],
                plotOptions: {
                bar: {
                    horizontal: this.barHorizontal,
                    distributed: false,
                    borderRadius: 15,
                    barHeight: this.barHorizontal ? '80%' : '20%', // altura das colunas
                    dataLabels: {
                        position: this.barHorizontal ? 'bottom' : 'top', // top, center, bottom
                        textAnchor: this.barHorizontal ? 'start' : 'middle', // start, middle, end
                        },
                    colors: {
                        ranges: []
                    },
                    columnWidth: '30%', // tamanho das colunas
                },
                },
                dataLabels: {
                    enabled: true,
                    position: this.barHorizontal ? 'bottom' : 'top', // top, center, bottom
                    textAnchor: this.barHorizontal ? 'start' : 'middle', // start, middle, end
                    floating: true,
                    formatter: function (val) {
                        return '$' + val.toFixed(2);
                    },
                    offsetY: this.barHorizontal ? -2 : -20,
                    offsetX: this.barHorizontal ? 0 : 0,
                    dropShadow: {
                        enabled: true
                    },
                    style: {
                        fontSize: '14px',
                        fontFamily: 'Inter, sans-serif',
                        fontWeight: 700, //'bold',
                        colors: undefined
                    }
                },
                stroke: {
                width: 10,
                colors: ["#304758"]
                },
                grid: {
                    show: true, // Hide the grid
                    borderColor: '#90A4AE',
                    xaxis: {
                        lines: {
                        show: false
                        }
                    },
                    yaxis: {
                        lines: {
                        show: false
                        }
                    },
                    row: {
                        colors: undefined,
                        opacity: 0.5
                    },  
                    column: {
                        colors: undefined,
                        opacity: 0.5
                    },
                    padding: {
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0
                    },
                },
                yaxis: {  
                    show: false, //this.barHorizontal,
                    range: 500,
                    labels: {
                        show: this.barHorizontal,
                    },
                    axisBorder: {
                        show: this.barHorizontal,
                    },
                    axisTicks: {
                        show: this.barHorizontal,
                    },
                },
                xaxis: {
                    type: 'category',
                    categories: this.getCategories(),
                    tickPlacement: 'between',
                    range: 500,
                    title: {
                        text: ''
                    },
                    labels: {
                        show: false, //!this.barHorizontal,
                        formatter: function (value) {
                            return value;
                        },
                    },
                    axisBorder: {
                        show: false,
                    },
                    axisTicks: {
                        show: !this.barHorizontal,
                    },
                },
                tooltip: {
                    shared: false,
                    intersect: true,
                    x: {
                        formatter: undefined,
                        formatter: (series, item) => {
                            let dataValue = item.series
                            return dataValue;
                        }
                    },
                    y: {
                        formatter: undefined,
                        title: {
                            formatter: () => 'Lucro: ',
                        },
                    }
                },
                legend: {
                    show: true,
                    showForSingleSeries: false,
                    showForNullSeries: false,
                    showForZeroSeries: false,
                    position: 'bottom',
                    horizontalAlign: 'center', 
                    floating: false,
                    fontSize: '14px',
                    fontFamily: 'Inter, sans-serif',
                    fontWeight: 400,
                    formatter: undefined,
                    inverseOrder: false,
                    width: undefined,
                    height: undefined,
                    tooltipHoverFormatter: undefined,
                    customLegendItems: [
                        'Buy-in médio do jogador',
                        'Buy-in médio do nível'
                    ],
                    offsetX: 0,
                    offsetY: 0,
                    labels: {
                        colors: undefined,
                        useSeriesColors: false
                    },
                    markers: {
                        width: 12,
                        height: 12,
                        strokeWidth: 0,
                        strokeColor: '#fff',
                        fillColors: undefined,
                        radius: 12,
                        customHTML: undefined,
                        onClick: undefined,
                        offsetX: 0,
                        offsetY: 0
                    },
                    itemMargin: {
                        horizontal: 5,
                        vertical: 0
                    },
                    onItemClick: {
                        toggleDataSeries: false
                    },
                    onItemHover: {
                        highlightDataSeries: false
                    },
                },
            }
        },

        avgBuyInValuesFormatted: function () {
            let output = this.dataAverageBuyIn && this.dataAverageBuyIn.ABI_PLAYER_VS_GROUP ? this.dataAverageBuyIn.ABI_PLAYER_VS_GROUP.value : [];
            return output;
        },
    },

    watch: {
        heroDatePickerValue: function () {
            if (!this.loadingDataAverageBuyIn) {
                this.fetchData();
            }
        },
    },

    data() {
        return this.initialState();
    },

    created() {
        this.fetchData();
    },

    methods: {
        initialState() {
            return {};
        },

        async fetchData() {
            let payload = {
                params: {
                    user: this.$route.params.id,
                    page: 1,
                    itemsPerPage: -1,
                    'statisticType[0]': 31,
                    'statisticType[1]': 32,
                }
            }

            // Add date params
            if (!this._.isEmpty(this.heroDatePickerValue)) {
                payload.params.dateBegin = this.heroDatePickerValue[0];
                payload.params.dateEnd = this.heroDatePickerValue[1];
            }

            this.$store.dispatch('railStatistics/getStatisticsAverageBuyIn', payload);
            
        },

        getCategories() {
            let output = [];
            if (!this._.isUndefined(this.avgBuyInValuesFormatted) ) {
                output = this.avgBuyInValuesFormatted.map(e => e.name);
            }
            return output;
        },
    },
}