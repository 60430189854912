import Vue from 'vue';
import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth.vue';
import RailMixin from '@/mixins/Rail.vue';
import UserDropdown from '@/components/Rail/UserDropdown/UserDropdown.vue';
import CardLiveTournament from "@/components/CardLiveTournament/CardLiveTournament.vue";
import DialogConfirmation from '@/components/Rail/Dialog/DialogConfirmation/DialogConfirmation.vue';
import DialogAddEditTournamentLive from '@/components/Dialog/DialogAddEditTournamentLive/DialogAddEditTournamentLive.vue';
import EmptyStateNoData from '@/components/EmptyStateNoData';

export default {
    name: 'TableLiveTournaments',
    props: {
        hideCreateButton: {
            type: Boolean,
            required: false,
            default: true,
        },
        searchByEventId: {
            type: Boolean,
            required: false,
            default: false,
        }
    },

    components: {
        UserDropdown,
        DialogConfirmation,
        EmptyStateNoData,
        CardLiveTournament,
        DialogAddEditTournamentLive,
    },

    mixins: [
        AuthMixin,
        RailMixin,
    ],

    computed: {
        ...mapGetters({
            countries: 'countries/countries',
            tournaments: 'liveTournaments/tournaments',
            tournamentsLoading: 'liveTournaments/tournamentsLoading',
            selectedTournament: 'liveTournaments/selectedTournament',
            error: 'liveTournaments/error',
            humanError: 'liveTournaments/humanError',
        }),

        numberOfPages() {
            if (!this._.isEmpty(this.tournaments)) {
                return Math.ceil(this.tournaments.length / this.itemsPerPage)
            }
        },

        skeletonLoadingComputed() {
            return this.$vuetify.breakpoint.mdAndUp
                ? 12
                : 6;
        },

        dateRangeFilter() {
            return this.datesFilter.join(' - ')
        },
    },

    data() {
        return this.initialState();
    },

    async created() {
        await this.fetchData();
    },

    watch: {
        datesFilter(val) {
            if (val.length === 2) {
                let start = new Date(val[0]);
                let end = new Date(val[1]);
                let startDate = new Date(start.getUTCFullYear(), start.getUTCMonth(), start.getUTCDate());
                let endDate = new Date(end.getUTCFullYear(), end.getUTCMonth(), end.getUTCDate());
    
                if (startDate > endDate) {
                    this.datesFilter = [endDate.toISOString(), startDate.toISOString()];
                }
    
                this.menu = false;
                this.fetchData();
                this.textDate = this.formatFilteredDate(this.datesFilter);
            }
        },
        textDate(val) {
            if (val === null) {
                this.datesFilter = [];
                this.fetchData();
            }
        },

        page: function () {
            this.fetchData();
        },

        itemsPerPage: function () {
            this.fetchData();
        },

        tournaments: function (tournament) {
            this.showNextButton = tournament.length === this.itemsPerPage;
            this.showPreviousButton = this.page > 1;
        }
    },  

    mounted() {
    },

    methods: {
        formatFilteredDate(date) {
            const firstDate = this.$moment(date[0]).format('DD/MM/YYYY');
            const secondDate = this.$moment(date[1]).format('DD/MM/YYYY');

            return `${firstDate} - ${secondDate}`
        },

        initialState() {
            return {
                menu: false,
                search: '',
                selectedCountry: null,
                datesFilter: [],
                textDate: "",
                dialogues: {
                    addEditTournamentLive: false,
                    deleteTournamentLive: false,
                },
                action: 'create',
                itemsPerPageArray: [4, 8, 12],
                page: 1,
                itemsPerPage: 12,
                typingTimeout: null,
                computedDatesFinal: [],
                showNextButton: true,
                showPreviousButton: false,
            };
        },

        async fetchData() {
            let payload = {};
            // Adiciona 'search' ao payload se não estiver vazio
            if (!this._.isEmpty(this.search)) {
                payload.search = this.search;
            }

            // Adiciona 'country' ao payload se não estiver vazio
            if (!this._.isEmpty(this.selectedCountry)) {
                payload.country = this.selectedCountry;
            }

            // Adiciona 'starts_at' e 'ends_at' ao payload se não estiver vazio
            if (this.datesFilter.length === 2) {
                payload.starts_from = new Date(this.datesFilter[0]).toISOString();
                payload.starts_until = new Date(this.datesFilter[1]).toISOString();
            }

            if (this.searchByEventId) {
                payload.id = this.$route.params.id;

                this.$store.commit('liveTournaments/setTournamentsLoading', true);

                payload.per_page = this.itemsPerPage;
                payload.offset = (this.page - 1) * this.itemsPerPage;
                
                setTimeout(() => {
                    return this.$store.dispatch('liveTournaments/fetchTournamentsByEventId', payload);
                }, 800);
                return;
            }
            await this.$store.dispatch('liveTournaments/fetchTournaments', payload);
        },

        toggleDialog(dialog) {
            this.dialogues[dialog] = !this.dialogues[dialog];
        },

        addNewTournament() {
            this.$store.commit('liveTournaments/setSelectedTournament', null);
            this.action = 'create';
            this.toggleDialog('addEditTournamentLive')
        },

        editTournament(tournament) {
            this.$store.commit('liveTournaments/setSelectedTournament', tournament);
            this.action = 'update';
            this.toggleDialog('addEditTournamentLive');
        },

        openDialogConfirmation(tournament) {
            this.$store.commit('liveTournaments/setSelectedTournament', tournament);
            this.toggleDialog('deleteTournamentLive')
        },

        searchTournament() {
            // Limpa o temporizador anterior para evitar múltiplas execuções
            clearTimeout(this.typingTimeout);
            // Define um novo temporizador que chama 'searchTournament' após 1 segundo de inatividade
            this.typingTimeout = setTimeout(() => {
                this.fetchData()
            }, 1000);
        },

        async deleteTournament() {
            await this.$store.dispatch('liveTournaments/deleteTournament', this.selectedTournament.id);
            this.toggleDialog('deleteTournamentLive');
        },


        nextPage () {
            if (this.tournaments.length === this.itemsPerPage) this.page += 1
        },
        
        formerPage () {
            if (this.page - 1 >= 1) this.page -= 1
        },

        updateItemsPerPage (number) {
            this.itemsPerPage = number
        },

    }
}