import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth';
import AlertError from '@/components/Alerts/AlertError/AlertError.vue';

export default {
    name: 'DialogRemoveGroup',

    components: {
        AlertError,
    },

    props: {
        isOpen: {
            type: Boolean,
            required: true,
            default: false
        },
    },

    mixins: [
        AuthMixin,
    ],

    data() {
        return this.initialState();
    },

    computed: {
        ...mapGetters({
            error: 'groups/error',
            humanError: 'groups/humanError',
            selectedGroup: 'groups/selectedGroup',
        }),
    },

    watch: {
        // when re opened,  populate field
        isOpen: function (newVal, oldVal) {
            if (newVal === true) {
                this.populateFields();
            }
        },
        selectedGroup: function (newVal, oldVal){
            if (newVal != oldVal) {
                this.populateFields();
            }
        }
    },
    
    methods: {
        initialState() {
            return {
                submitting: false,
                form: {
                    id: null,
                },
            };
        },

        populateFields() {
            if (this.selectedGroup) {
                this.form.id = this.selectedGroup.id;
            }
        },

        async submit() {
            if (!this.$refs.formRemoveGroup.validate()) {
                return false;
            }

            let payload = {
                id: this.form.id,
            }

            // Toggle submit button
            this.toggleSubmitting();

            let result = await this.$store.dispatch('groups/remove', payload);

            // // Re-enable button
            this.toggleSubmitting();

            // On success
            if (result === true) {
                // Show success snackbar
                this.$store.dispatch('UI/showSnackbar', {
                    message: 'Grupo removido com sucesso.',
                    color: 'success'
                });

                this.$emit('reload-data');

                // closes dialog
                this.closeDialog();
            }
        },

        /**
         * Used to disable Submit button
         */
        toggleSubmitting() {
            this.submitting = !this.submitting;
        },

        closeDialog() {
            this.$emit('close-dialog');
            this.resetForm();
        },

        resetForm() {
            Object.assign(this.$data, this.initialState());
            this.$refs.formRemoveGroup.reset();
            
            // clear errors
            this.$store.dispatch('groups/clearError');
        }
    },
};
