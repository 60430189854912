import Vue from 'vue';
import { mapGetters } from 'vuex';
import DataMixin from '@/mixins/Data';
import AuthMixin from '@/mixins/Auth.vue';
import RailMixin from '@/mixins/Rail.vue';

export default {
    name: 'EventLiveDropdown',

    mixins: [
        DataMixin,
        AuthMixin,
        RailMixin,
    ],

    props: {
        value: {
            type: [Object,Array],
            required: false,
            default: () => {}
        },
        multiple: {
            type: Boolean,
            required: false,
            default: false,
        },
        label: {
            type: String,
            required: false,
            default: 'Evento'
        },
        status: {
            type: String,
            required: false,
            default: ''
        },
        backgroundColor: {
            type: String,
            required: false,
            default: ''
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        flat: {
            type: Boolean,
            required: false,
            default: false
        },
        solo: {
            type: Boolean,
            required: false,
            default: false
        },
        clearable: {
            type: Boolean,
            required: false,
            default: false
        },
        outlined: {
            type: Boolean,
            required: false,
            default: true
        },
        dense: {
            type: Boolean,
            required: false,
            default: false
        },
        hideDetails: {
            type: Boolean,
            required: false,
            default: false
        },
        rules: {
            type: Array,
            required: false,
            default: () => [
                (v) => !!v || 'Por favor selecione um evento'
            ]
        },
        hint: {
            type: String,
            required: false,
            default: ''
        },
        autoReload: {
            type: Boolean,
            required: false,
            default: true
        },
        dense: {
            type: Boolean,
            required: false,
            default: false 
        }
    },

    data() {
        return this.initialState();
    },

    created() {
        this.initialize();
        this.emitChange();
    },

    mounted() {
        // Clear error triggered naturally when element is loaded
        this.$refs.formEventLiveDropdown.resetValidation();
    },

    computed: {
        ...mapGetters({
            events: 'liveEvents/events',
            eventsLoading: 'liveEvents/eventsLoading',
        }),
    
        // Synthetic v-model
        computedValue: {
            get: function () {
                return this.liveEvent;
            },
            set: function (newValue) {
                this.liveEvent = newValue;
                this.emitChange();
            }
        },

        computedRules: function () {
            return this.rules;
        },

        /**
         * Filters data according to the props passed
         * @returns {Array}
         */
        dataFiltered: function () {
            // Using slice() to avoid the following error
            // https://stackoverflow.com/questions/43151265/you-may-have-an-infinite-update-loop-in-a-component-render-function
            let output = this.events.slice();
           
            return output;
        },
    },

    watch: {
        value: function(newValue) {
            this.computedValue = newValue;
        },
    },

    methods: {
        initialState() {
            return {
                liveEvent: null,
                search: null,
                options: {
                    page: 1,
                    itemsPerPage: -1,
                    sortBy: ['name'],
                    sortDesc: [false],
                },
                data: [],
            }
        },

        async initialize() {
            // Initial value passed through prop
            this.computedValue = this.value;

            if (this._.isEmpty(this.events) && this.autoReload) {
                await this.fetchData();
            }
        },

        async fetchData() {
            // get players list
            await this.$store.dispatch('liveEvents/fetchEvents');
        },

        reset() {
            Object.assign(this.$data, this.initialState());
            this.$refs.formEventLiveDropdown.reset();
        },

        emitChange() {
            this.$emit('input', this.computedValue);
            this.$emit('change', this.computedValue);
        }
    }
}