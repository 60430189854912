import Vue from 'vue';
import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth.vue';
import RailMixin from '@/mixins/Rail.vue';
import DataMixin from '@/mixins/Data.vue';
import EmptyStateNoData from '@/components/EmptyStateNoData';
import DatePicker from '@/components/DatePicker/DatePicker.vue';
import DialogDownloadCsv from '@/components/Rail/Dialog/DialogDownloadCsv/DialogDownloadCsv.vue';
import ButtonCreateOperation from '@/components/Rail/ButtonCreateOperation/ButtonCreateOperation.vue';
import DialogViewSession from '@/components/Rail/DialogViewSession/DialogViewSession.vue';
import DialogViewSessionSpins from '@/components/Rail/Dialog/DialogViewSessionSpins/DialogViewSessionSpins.vue';
import UserDropdown from '@/components/Rail/UserDropdown/UserDropdown.vue';
import GroupDropdown from '@/components/Rail/GroupDropdown/GroupDropdown.vue';

export default {
  name: 'TableRequestsLegacyView',
  
  components: {
    EmptyStateNoData,
    DialogDownloadCsv,
    DatePicker,
    ButtonCreateOperation,
    DialogViewSession,
    DialogViewSessionSpins,
    UserDropdown,
    GroupDropdown,
  },
  
  mixins: [
    AuthMixin,
    RailMixin,
    DataMixin,
  ],
  
  props: {
    title: { type: String, required: false, default: 'Operações Legacy', },
  },
  
  data() {
    return this.initialState();
  },

  computed: {
    ...mapGetters({
      operationsLegacyList: 'railRequests/operationsLegacyList',
      hasLoadedOperationsLegacyList: 'railRequests/hasLoadedOperationsLegacyList',
      totalOperationsLegacyList: 'railRequests/totalOperationsLegacyList',
      reports: 'railReports/reports',
      userTrackerId: 'auth/userTrackerId',
      heroDatePickerValue: 'UI/heroDatePickerValue',
      selectedRequestLegacy: 'railRequests/selectedRequestLegacy',
      themeDarkMode: 'UI/themeDarkMode',
      teamFilter: 'teams/teamFilter',
    }),
    
    optionsComputed: {
      get: function () {
        return this.options;
      },
      set: function (newValue) {
        if (!this._.isEqual(this.options, newValue)) {
          this.options = newValue;
        }
      }
    },

    sortByComputed: function () {
      return ['date'];
    },

    sortDescComputed: function () {
      return [true];
    },

  },

  watch: {
    // when component is created and retrieves heroDatePickerValues, it resets options object which triggers fetchData()
    heroDatePickerValue: function () {
      // resets options object
      this.options = {
        page: 1,
        itemsPerPage: 10,
        sortBy: this.sortByComputed,
        sortDesc: this.sortDescComputed,
        groupBy: [],
        groupDesc: [],
        mustSort: false,
        multiSort: false
      }
    },

    teamFilter: function () {
      // resets options object
      this.options = {
        page: 1,
        itemsPerPage: 10,
        sortBy: this.sortByComputed,
        sortDesc: this.sortDescComputed,
        groupBy: [],
        groupDesc: [],
        mustSort: false,
        multiSort: false
      }
    },

    // watches this.options object
    // triggers everytime this.options has changed
    options: {
      handler() {
        if (!this.fetchLoading) {
          this.fetchData();
        }
      },
      deep: true,
    },

    'form.user': {
      handler() {
        if (!this.fetchLoading) {
          this.fetchData();
        }
      },
      immediate: true,
    },

    'form.group': {
      handler() {
        if (!this.fetchLoading) {
          this.fetchData();
        }
      },
      immediate: true,
    },
  },
  
    methods: {
      initialState() {
        return {
          submitting: false,
          fetchLoading: false,
          form: {
            group: null,
            user: null,
          },
          date: null,
          headers: [
            { text: 'Data', value: 'date' },
            { text: 'Operação', value: 'type', width: '100px' },
            { text: 'Emissor', value: 'sourceUser', sortable: false },
            { text: 'Destinatário', value: 'targetUser', sortable: false },
            { text: 'Sala (Nickname)', value: 'source', sortable: false, width: '130px' },
            { text: 'Carteira destino', value: 'destination', sortable: false, width: '130px' },
            { text: 'Montante', value: 'amount' },
            { text: 'Ação', value: 'action', sortable: false }
          ],
          options: {
            page: 1,
            itemsPerPage: 10,
            sortBy: ['date'],
            sortDesc: [true],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false
          },
          dataTable: {
            footerProps: {
              'items-per-page-options': this.$itemsPerPageOptions
            }
          },
          showNoRequestsNotice: false,
          // Positions according to Vue.prototype.$rail_status.<STATUS>
          operationStatusColor: [
            '',
            'orange',
            'orange',
            'orange',
            'red',
            'green',
          ],
          dialogues: {
            viewSession: false,
            viewSessionSpins: false,
          },
        }
      },

      // trigered by v-text-field @input="search()"
      search() {
        setTimeout(() => {
          this.fetchData()
        }, 1000);
      },
    
      async fetchData() {

        this.fetchLoading = true;

        // Base payload
        let payload = {
          export: false,
          params: {
            page: this.options.page,
            itemsPerPage: this.options.itemsPerPage,
          }
        };

        // Add user id param
        if (this.form.user) {
          payload.params.sourceUser = this.form.user.id;
          payload.params.targetUser = this.form.user.id;
        }

        // Add user id param
        if (this.form.group) {
          this.form.group.forEach((element, index) => {
            payload.params['group[' + index + ']'] = element;
          })
        }
 
        // if dateIndependent is true, it means payload doesn't use date params
        if (!this.dateIndependent) {
          // Add date params
          if (!this._.isEmpty(this.heroDatePickerValue)) {
            payload.params.dateBegin = this.heroDatePickerValue[0];
            payload.params.dateEnd = this.heroDatePickerValue[1];
          }
        }

        // if team exists and isManagement, Add team parameter to payload.params
        if (this.teamFilter && this.userHasRailAccessDivisionDropdown) {
          payload.params.team = this.teamFilter;
        }
      
        // Add sortBy parameter to payload
        if (!this._.isEmpty(this.options.sortBy)) {
          this.options.sortBy.forEach((element, index) => {
            payload.params['sortBy[' + index + ']'] = element;
          })
        }

        // Add sortDesc parameter to payload.params
        if (!this._.isEmpty(this.options.sortDesc)) {
          this.options.sortDesc.forEach((element, index) => {
            payload.params['sort[' + index + ']'] = element == false ? 'ASC' : 'DESC';;
          })
        }
      
        await this.$store.dispatch('railRequests/getOperationsLegacyList', payload);

        this.fetchLoading = false;
      },

      triggerCopyToClipboard(content) {

        let contentToBeCopied = '';

        if (content)
          contentToBeCopied = content.target.network.id == Vue.prototype.$rail_GGPoker_network_id
            ? content.target.description
            : content.target.name;
      
        this.copyToClipboard(contentToBeCopied);
      
        // Show snackbar
        this.$store.dispatch('UI/showSnackbar', {
          message: "Nome da conta '" + contentToBeCopied + "' copiado com sucesso.",
          color: 'success'
        });
      },

      /**
       * Checks if operation is type Session, else opens operation in Checkout view
       * @param {Object} item 
       */
      handleGoToOperation(item) {
        if (item && (item.type == Vue.prototype.$rail_ops.operations.sessions)) {
          this.openDialogViewSession(item);
        } else {
          this.goToRailOperation(item);
        }
      },

      async openDialogViewSession(item) {

        await this.$store.dispatch('railSessions/getSessionById', item.id);

        // map userTeams by name and verifies if user belongs to Spins Division
        let userTeamsMap = item.user.teams.map(e => e.name);
        let isSpinDivision = userTeamsMap.includes("Spins Division");

        if (isSpinDivision) {
          this.toggleDialog('viewSessionSpins');
        } else {
          this.toggleDialog('viewSession');
        }
      },

      toggleDialog(dialog) {
        this.dialogues[dialog] = !this.dialogues[dialog];
      },
      
      /**
      * @param {Object} item From v-datatable template. item.type is a number between 1-7 - see Vue.prototype.$rail_ops.operations.
      */
      async goToRailOperation(item) {
        // set ative item (green dot that appears 1st column)
        this.setActiveItem(item);
      
        // toggle submitting
        this.submitting = true;
      
        // populate if report exists
        let findReport;

        // if item flagged, go to report page view
        if (item.flag) {

          // Base payload
          let payload = {
            export: false,
            params: {
              page: 1,
              itemsPerPage: -1,
            }
          };
          // retrieve reports list
          await this.$store.dispatch('railReports/get', payload);

          // find report where railOperation id is equal to item.id and report item is still unresolved
          findReport = this.reports.find(element => {
            let result = (element.railOperation.id == item.id) && (element.status != 3)
            return result;
          });
                    
          if (findReport) {
            // set setSelectedReport equal to findReport 
            await this.$store.commit('railReports/setSelectedReport', findReport);

            // routing
            this.$router.push({ path: Vue.prototype.$tab_slider.tabs.reports + findReport.id });

          }
          // toggle submitting
          this.submitting = false;

          return true;
        }
      
        //reset card
        this.$store.commit('TabSlider/setCard', null);
      
        // clear balance profit from UC - SPLIT
        this.$store.commit('railOverview/setCardBalanceProfitUSD', null);
        this.$store.commit('railOverview/setCardBalanceProfitEUR', null);
      
        // retrieve selectedRequest full info (ex: reportsDTO ) in order to populate checkout page
        // await to prevent visual bug
        await this.$store.dispatch('railRequests/getRequestById', item.id);

        // toggle submitting
        this.submitting = false;

        // set hasFinished state to false
        this.$store.commit('TabSlider/setHasFinished', false);

        // set card based on selectedRequest type
        await this.$store.commit('TabSlider/setCard', item.type);

        this.$store.commit('TabSlider/setSelectedTab', null);
      
        // force CardStepper to display 1 step
        await this.$store.commit('UI/setCardStepperStep', 1);

        // routing
        await this.$router.push({ path: Vue.prototype.$tab_slider.tabs.requests + item.id });
        this.$store.commit('TabSlider/setSelectedTab', Vue.prototype.$tab_slider.tabs.requests);

      },

      /**
       * @param {Object} item 
       * @returns {String}
       */
      goToRailOperationIcon(item) {
        let output = 'mdi-launch';
        if (this.selectedRequestLegacy) {
          if (item.id == this.selectedRequestLegacy.id) {
            output = this.submitting ? 'mdi-loading mdi-spin' : output;
          }
        }
        return output;
      },

      setActiveItem(item) {
        if (item) {
          this.$store.commit(
            'railRequests/setSelectedRequestLegacy',
            item
          );
        }
      },


       /**
       * get player imageCutout based on name parameter
       * @param {Object} item 
       * @param {String} name 
       * @returns {String}
       */
      imageCutout(item, name) {
        let user = item[name] ? item[name] : null;
        let placeholder = require('@/assets/images/players/player-example.png');
        return user && user.imgCutout ? user.imgCutout : placeholder;
      },

      /**
       * get player url based on name parameter
       * @param {Object} item 
       * @param {String} name 
       * @returns {String}
       */
      getPlayerURL(item, name) {
        let playerID = item[name] ? item[name].id : null
        return playerID
          ? '/players/' + playerID + '/'
          : '';
      },

        /**
       * Get user target name based on operation type
       * @param {Object} item 
       * @returns {String} name
       */
      getTargetUserFromRailOperation(item) {
        let output = '';

        switch (item.type) {
          case Vue.prototype.$rail_ops.operations.split:
          case Vue.prototype.$rail_ops.operations.bankroll_reduction:
          case Vue.prototype.$rail_ops.operations.bankroll_increment:
          case Vue.prototype.$rail_ops.operations.transfer_to_self:
            output = item.targetUser ? item.targetUser.name : 'Gestão';
            break;
          case Vue.prototype.$rail_ops.operations.swap:
            output = item.targetUser ? item.targetUser.name : 'Gestão';
            break;
          case Vue.prototype.$rail_ops.operations.transfer_to_player:
            output = item.targetUser ? item.targetUser.name : 'Gestão';
            break;
          case Vue.prototype.$rail_ops.operations.deposit:
          case Vue.prototype.$rail_ops.operations.withdraw:
            output = 'Gestão';
            break;
          case Vue.prototype.$rail_ops.operations.sessions:
            output = '';
            break;
        
          case Vue.prototype.$rail_ops.operations.makeup:
            output = item.targetUser ? item.targetUser.name : null;
        }

        return output;
      },

      /**
       * Get user name based on operation type
       * @param {Object} item 
       * @returns {String} name
       */
      getUserFromRailOperation(item) {
        let output = '';

        switch (item.type) {
          case Vue.prototype.$rail_ops.operations.split:
          case Vue.prototype.$rail_ops.operations.bankroll_reduction:
          case Vue.prototype.$rail_ops.operations.bankroll_increment:
          case Vue.prototype.$rail_ops.operations.transfer_to_self:
            output = item.user ? item.user.name : 'Gestão';
            break;
          case Vue.prototype.$rail_ops.operations.swap:
            output = item.user ? item.user.name : 'Gestão';
            break;
          case Vue.prototype.$rail_ops.operations.transfer_to_player:
            output = item.user ? item.user.name : 'Gestão';
            break;
          case Vue.prototype.$rail_ops.operations.deposit:
          case Vue.prototype.$rail_ops.operations.withdraw:
            output = 'Gestão';
            break;
          case Vue.prototype.$rail_ops.operations.sessions:
            output = item.user ? item.user.name : 'Gestão';
            break;
        
          case Vue.prototype.$rail_ops.operations.makeup:
            output = 'Gestão';
        }

        return output;
      },

      getOperationRequests(item) {
        let output = [];
        if (item && item.requests) {
  
          // retrieving only ID's from target wallets avoiding duplicates
          let walletsID = [...new Set(item.requests.map(element => element.wallet.id))];
  
          // for each id stored in walletsID, it finds the first wallet request with same ID and then populates output
          walletsID.forEach(id => {
            item.requests.find(request => {
              if (request.wallet.id == id) {
                return output.push(request);
              }
            })
          })
        }
        return output;
      },
    }
}