/**
 * Valid lookups:
 * 
 * PLAYER-MINE          User's own nicknames of type 'PLAYER'.
 * PLAYER-ALL           User's own or 3rd party nicknames of types 'PLAYER' or 'WALLET'.
 * WALLET-MINE          User's own nicknames of type 'WALLET'.
 * WALLET-ALL           Invalid unless this.user prop is specified or Use PLAYER-ALL to get all types of nicknames ('PLAYER' or 'WALLET').
 * WALLET-MANAGEMENT    Management's nicknames of type 'WALLET'.
 * ANY-MINE             User's own nicknames of all types.
 * ANY-ALL              All user's nicknames of all types.
 * ANY-MANAGEMENT       All management's nicknames of all types (MANAGEMENT ONLY).
 * 
 * Examples:
 * 
 * Another user's nicknames: specify type 'PLAYER', scope 'ALL' and 'user' prop.
 * Management nicknames: type 'PLAYER', scope 'MANAGEMENT'.
 * Management wallets: type 'WALLET', scope 'MANAGEMENT'.
 * 
 * @prop    {String}        type                    Valid values PLAYER, WALLET, ANY.
 * @prop    {String}        scope                   Valid values MINE, ALL, MANAGEMENT.
 * @prop    {Number}        user                    User ID.
 * @prop    {Bool}          readonly                Disables the input.
 * @prop    {Number}        selectedNicknameId      Pre-select a nickname.
 * @prop    {Array[Int]}    excludedNetworks        Hide given networks.
 * @prop    {Array[Int]}    currencies              Show ONLY networks with these currencies.
 * @prop    {Bool}          alwaysReload            Always fetch new nickname list even if store is already filled.
 * @prop    {Bool}          excludeZeroBalance      Exclude nicknames with a balance of zero.
 * @prop    {Bool}          optional                Input field rules can be optional
 */

import Vue from 'vue';
import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth.vue';
import ButtonCopyToClipboard from '@/components/Rail/ButtonCopyToClipboard/ButtonCopyToClipboard.vue';

export default {
    name: 'NicknameDropdown',

    props: {
        value: { type: Object, required: false, default: () => { } },
        type: { type: String, required: true, default: 'PLAYER' },
        scope: { type: String, required: true, default: 'MINE' },
        label: { type: String, required: false, default: 'Conta' },
        user: { type: Number, required: false, default: null },
        readonly: { type: Boolean, required: false, default: false },
        outlined: { type: Boolean, required: false, default: true },
        dense: { type: Boolean, required: false, default: false },
        flat: { type: Boolean, required: false, default: false },
        solo: { type: Boolean, required: false, default: false },
        backgroundColor: { type: String, required: false, default: '' },
        placeholder: { type: String, required: false, default: '' },
        fullWidth: { type: Boolean, required: false, default: false },
        disabled: { type: Boolean, required: false, default: false },
        selectedNicknameId: { type: Number, required: false, default: null },
        excludedNetworks: { type: Array, required: false, default: () => [] },
        excludedNicknames: { type: Array, required: false, default: () => [] },
        currencies: { type: Array, required: false, default: () => [] },
        alwaysReload: { type: Boolean, required: false, default: false },
        excludeZeroBalance: { type: Boolean, required: false, default: false },
        showExternalWallet: { type: Boolean, required: false, default: false },
        concatAllNicknames: { type: Boolean, required: false, default: false },
        optional: { type: Boolean, required: false, default: false },
        hideDetails: { type: Boolean, required: false, default: false },
        hasNetwork: { type: Number, required: false, default: null },
        onlyNicknames: { type: Boolean, required: false, default: false },
        onlyManagementNicknames: { type: Boolean, required: false, default: false },
        showAlias: { type: Boolean, required: false, default: false },
        clearable: { type: Boolean, required: false, default: false },
    },

    components: {
        ButtonCopyToClipboard,
    },

    data() {
        return this.initialState();
    },

    mixins: [
        AuthMixin,
    ],

    computed: {
        ...mapGetters({
            nicknames: 'nicknames/nicknames',
            nicknamesAllMine: 'nicknames/nicknamesAllMine',
            nicknamesAllPlayer: 'nicknames/nicknamesAllPlayer',
            nicknamesByUserID: 'nicknames/nicknamesByUserID',
            selectedNickname: 'nicknames/selectedNickname',

            wallets: 'railWallets/wallets',
            walletsAll: 'railWallets/walletsAll',
            walletsManagement: 'railWallets/walletsManagement',
            walletsByUserID: 'railWallets/walletsByUserID',
            selectedWallet: 'railWallets/selectedWallet',

            hasLoadedNicknames: 'nicknames/hasLoaded',
            hasLoadedNicknamesAllPlayer: 'nicknames/hasLoadedNicknamesAllPlayer',
            hasLoadedNicknamesAllMine: 'nicknames/hasLoadedNicknamesAllMine',
            hasLoadedNicknamesByUserID: 'nicknames/hasLoadedNicknamesByUserID',
            hasLoadedWallets: 'railWallets/hasLoaded',
            hasLoadedWalletsAll: 'railWallets/hasLoadedWalletsAll',
            hasLoadedWalletsManagement: 'railWallets/hasLoadedWalletsManagement',
            hasLoadedWalletsByUserID: 'railWallets/hasLoadedWalletsByUserID',
        }),

        /**
         * Filters data according to the props passed
         * @returns {Array}
         */
        dataFiltered: function () {
            // Using slice() to avoid the following error
            // https://stackoverflow.com/questions/43151265/you-may-have-an-infinite-update-loop-in-a-component-render-function
            let output = this.data.slice();

            // (If set) Dont show the given networks (usually to avoid duplication)
            output = this.excludeNetworks(output);
            // (If set) Show only nicknames with balance > 0
            output = this.excludeZeroBalanceNicknames(output);
            // (If set) Show only nicknames of the given type
            output = this.filterByType(output);
            // (If set) Show only nicknames of the given currencies
            output = this.filterByCurrency(output);
            // (if set) Show all nicknames from management and user in the same input
            output = this.includeAllNicknames(output);
            // (if set) Don't show specific nicknames
            output = this.excludeNicknames(output);
            // (if set) Show only nicknames
            output = this.showOnlyNicknames(output);
            // (if set) Show only management nicknames
            output = this.showOnlyManagementNicknames(output);
            // (if set) Shows nicknames and nicknames alias
            output = this.handleNicknameAlias(output);
            // (If set) Show external wallet | Last validation in order to successfully concat nickname
            output = this.includeExternalWallet(output);

            this.dataAfterFilters = output;

            this.$store.commit('nicknames/setExistsItemsAvailableAfterFilter', output.length > 0);
            this.$store.commit('nicknames/setNumberOfItemsAvailableAfterFilter', output.length);

            return output;
        },

        // Synthetic v-model
        computedValue: {
            get: function () {
                return this.nickname;
            },
            set: function (newValue) {
                    this.nickname = newValue;
                    this.emitChange();
            }
        },

        /**
         * @returns {Boolean} True if is loading, false if has loaded.
         */
        isLoading: function () {
            let output = false;

            if (!this._.isUndefined(this.isLoadingPointer[this.type])) {
                // Negation because store uses 'hasLoaded' instead of 'isLoading'
                output = !this.isLoadingPointer[this.type][this.scope];
            }

            return output;
        },

        isLoadingPointer: function () {
            return {
                PLAYER: {
                    MINE: this.hasLoadedNicknamesAllMine,
                    ALL: (this.user ? this.hasLoadedNicknamesByUserID : this.hasLoadedNicknamesAllPlayer),
                    MANAGEMENT: true,
                },
                WALLET: {
                    MINE: this.hasLoadedWallets,
                    ALL: this.hasLoadedWalletsAll,
                    MANAGEMENT: this.hasLoadedWalletsManagement,
                },
                ANY: {
                    MINE: this.hasLoadedNicknamesAllMine,
                    ALL: (this.user ? this.hasLoadedNicknamesAllPlayer : this.hasLoadedNicknamesAllMine),
                    MANAGEMENT: this.hasLoadedNicknamesAllMine,
                },
            };
        },

        hint: function () {
            const balance = this.nickname && this.nickname.balance
                ? this.nickname.network.currency.symbol + ' ' + this.nickname.balance.value
                : null;

            if (balance) {
                return 'Saldo: ' + balance;
            }

            // Return empty str if impossible to obtain info
            return '';
        },

        rulesComputed: function () {
            let output = [];
            if (!this.optional) {
                output = this.rules.dropdown
            }
            return output;
        }
    },

    watch: {
        value: function (newValue) {
            this.computedValue = newValue;
        },

        /**
         * This computed property needs to be watched deeply for reactivity in order for fields to be
         * enabled or disabled.
         */
        isLoadingPointer: {
            handler: function () { },
            deep: true
        },

        /**
        *  fetchData() runs when component is created. fetchData() runs everytime user ID has changed
        *  in UC-BankrollReduction Admin View Stage 1, Admin must choose a player before choosing the network/wallet.
        *  But if admin changes the player, NicknameDropdown must run fetchData() again because the prop user has changed
        */
        user: function () {
            this.reset();
            this.fetchData()
        },
    },

    created() {
        this.initialize();
        this.emitChange();
    },

    mounted() {
        // Clear error triggered naturally when element is loaded
        this.$refs.formNicknameDropdown.resetValidation();
    },

    methods: {
        initialState() {
            return {
                nickname: null,
                toggleSelect: false,
                data: [],
                dataAfterFilters: [],
                rules: {
                    dropdown: [
                        (v) => !!v || 'Por favor preencha a ' + this.label,
                    ],
                },
            }
        },

        async initialize() {
            this.reset();

            // Initial value passed through prop
            this.computedValue = this.value;

            // Validate props make sense
            if (this.validateProps()) {
                // Get data
                await this.fetchData();
                this.prePopulate();
                this.hasNetworkHandler(this.hasNetwork);
            }
        },

        prePopulate() {
            if (this.selectedNicknameId) {
                this.computedValue = this.data.find(e => e.id == this.selectedNicknameId);
            }
        },

        validateProps() {
            // Does not make sense to specify MINE and look for other user
            if (this.scope == 'MINE' && this.user) {
                console.error('NicknameDropdown: Scope `MINE` and prop `user` detected.');
                return false;
            }

            // Regular users cannot see ANY-ALL nicknames without specifying a user
            if (!this.userIsRailManager && this.type == 'ANY' && this.scope == 'ALL' && !this.user) {
                console.error('NicknameDropdown: Type-Scope combination `ANY-ALL` requires a `user` prop when requested by non-admins.');
                return false;
            }

            if (!this.userIsRailManager && this.type == 'WALLET' && this.scope == 'ALL' && !this.user) {
                console.error('NicknameDropdown: Type-Scope combination `WALLET-ALL` requires a `user` prop when requested by non-admins.');
                return false;
            }

            if (!this.userIsRailManager && this.type == 'ANY' && this.scope == 'MANAGEMENT' && !this.user) {
                console.error('NicknameDropdown: Type-Scope combination `ANY-MANAGEMENT` can only be requested by admins.');
                return false;
            }

            // If code gets here, all validations passed
            return true;
        },

        /**
         * Fetches data and filters it partially.
         * @returns {Array}
         */
        async fetchData() {
            // Type 'PLAYER'
            if (this.type == 'PLAYER') {
                // Type 'PLAYER' Scope 'MINE'
                if (this.scope == 'MINE') {
                    if (this._.isEmpty(this.nicknamesAllMine) || this.alwaysReload) {

                        /*  TO REMOVE LATER                      

                            let payload = {
                             params: {
                                'status[0]': Vue.prototype.$nickname_status.active.value,
                                'status[1]': Vue.prototype.$nickname_status.flagged.value,
                                itemsPerPage: -1,
                                page: 1
                             }
                         } */
                         
                         await this.$store.dispatch('nicknames/getAllMine');
                     }
 
                     this.data = this.nicknamesAllMine;
                 }
 
                 // Type 'PLAYER' Scope 'ALL'
                 else if (this.scope == 'ALL') {
                     
                     let result;
                     // Check if the loaded nicknames are relative to the currently selected user, otherwise, we need to load again
                     let sameUser = this.nicknamesByUserID[0]
                                    && Object.prototype.hasOwnProperty.call(this.nicknamesByUserID[0], 'user')
                         && this.nicknamesByUserID[0].user.id == this.user;
 
                     if (this._.isEmpty(this.nicknamesByUserID) || !sameUser || this.alwaysReload) {
                         if (this.user) {
                            let payload = {
                                user: this.user,
                                aliasParameter: '&aliasChildOf=null'
                              }
                             
                            result = await this.$store.dispatch('nicknames/getAllPlayer', payload);
                         }
                     }

                    if (result === true || result === undefined) {
                        if ( this._.isNull(this.user) ) {
                            this.data = this.nicknamesAllPlayer;
                       } else {
                           this.data = this.nicknamesByUserID;
                        }
                    }
                 }
 
                 // Type 'PLAYER' Scope 'MANAGEMENT' (unavailable)
                 else if (this.scope == 'MANAGEMENT') {
                     console.error('NicknameDropdown: Scope `MANAGEMENT` is unavailable for type `PLAYER`.');
                     this.data = [];
                 }
             }
 
             // Type 'WALLET'
             else if (this.type == 'WALLET') {
                 // Type 'WALLET' Scope 'MINE'
                 if (this.scope == 'MINE') {
                     if (this._.isEmpty(this.wallets) || this.alwaysReload) {
                         await this.$store.dispatch('railWallets/getWallets');
                     }
 
                     this.data = this.wallets;
                 }
 
                 // Type 'WALLET' Scope 'ALL' (unavailable) unless user exists
                 else if (this.scope == 'ALL') {
                     if (this._.isEmpty(this.walletsAll) || this.alwaysReload) {
                         await this.$store.dispatch('railWallets/getWalletsAll', this.user);
                     }
 
                     this.data = this.walletsAll;
                 }
 
                 // Type 'WALLET' Scope 'MANAGEMENT'
                 else if (this.scope == 'MANAGEMENT') {
                     if (this._.isEmpty(this.walletsManagement) || this.alwaysReload) {
                         if (this.userIsPlayer) {
                            await this.$store.dispatch('railWallets/getWalletsFromManagement');
                         } else {
                            await this.$store.dispatch('railWallets/getWalletsFromManagementWithAmounts');
                         }
                     }
 
                     this.data = this.walletsManagement;
                 }
             }
 
             // Type 'ANY'
             else if (this.type == 'ANY') {
                 // Type 'ANY' Scope 'MINE'
                 if (this.scope == 'MINE') {
                     if (this._.isEmpty(this.nicknamesAllMine) || this.alwaysReload) {
                         await this.$store.dispatch('nicknames/getAllMine');
                     }
 
                     this.data = this.nicknamesAllMine;
                 }
 
                 // Type 'ANY' Scope 'ALL'
                 else if (this.scope == 'ALL') {
                     // Check if the loaded nicknames are relative to the currently selected user, otherwise, we need to load again
                     let sameUser = this.nicknamesAllPlayer[0]
                                    && Object.prototype.hasOwnProperty.call(this.nicknamesAllPlayer[0], 'user')
                                    && this.nicknamesAllPlayer[0].user.id == this.user;
 
                     if (this._.isEmpty(this.nicknamesAllPlayer) || !sameUser || this.alwaysReload) {
                         if (this.user) {
                            let payload = {
                                user: this.user,
                                aliasParameter: '&aliasChildOf=null'
                            }
                            await this.$store.dispatch('nicknames/getAllPlayer', payload);
                        }
                    }

                    this.data = this.selectedNicknameId || this.user
                        ? this.nicknamesByUserID
                        : this.nicknamesAllPlayer;
                }

                // Type 'ANY' Scope 'MANAGEMENT'
                // Same as 'ANY-MINE'
                else if (this.scope == 'MANAGEMENT') {
                    if (this._.isEmpty(this.nicknamesAllMine) || this.alwaysReload) {
                        await this.$store.dispatch('nicknames/getAllMine');
                    }

                    this.data = this.nicknamesAllMine;
                }
            }

            return this.data;
        },

        clearValue() {
            this.computedValue = null;
            this.$emit('input', this.computedValue);
            this.$emit('change', this.computedValue);
        },

        emitChange() {
            this.$emit('input', this.computedValue);
            this.$emit('change', this.computedValue);
        },

        /**
         * Show only nicknames associated with a certain type of network (PLAYER or WALLET).
         * 
         * @param {*} data 
         * @returns {Array}
         */
        filterByType(data) {
            let output = null;

            switch (this.type) {
                case 'PLAYER':
                    output = data.filter(e => e.network.isWallet == false);
                case 'WALLET':
                    output = data.filter(e => e.network.isWallet == true);
                case 'ANY':
                    output = data;
                default:
                    break;
            }

            return output;
        },

        /**
         * Select only nicknames of the given currency IDs.
         * Prop 'currencies' defines this filtering.
         * 
         * @param {*} data 
         * @returns {Array}
         */
        filterByCurrency(data) {
            if (!this._.isEmpty(this.currencies)) {
                data = data.filter(e => this.currencies.includes(e.network.currency.id));
            }
            return data;
        },

        /**
         * Exclude certain networks from the results.
         * 
         * @param {*} data 
         * @returns {Array}
         */
        excludeNetworks(data) {
            let output = data;
            if (this.excludedNetworks && this.excludedNetworks.length) {
                return data.filter(e => !this.excludedNetworks.includes(e.network.id));
            }
            return output;
        },

        excludeZeroBalanceNicknames(data) {
            let output = data;
            if (this.excludeZeroBalance) {
                output = data.filter(e => e.balance.value > 0);
            }
            return output;
        },

        /**
          * Exclude certain nicknames from the results.
          * 
          * @param {*} data 
          * @returns {Array}
          */
        excludeNicknames(data) {
            let output = data;
            if (this.excludedNicknames && this.excludedNicknames.length) {
                return data.filter(e => !this.excludedNicknames.includes(e.id));
            }
            return output;
        },

        includeExternalWallet(data) {
            let output = data;
            if (this.showExternalWallet) {
                output.push(Vue.prototype.$external_wallet)
            }

            return output;
        },

        includeAllNicknames(data) {
            let output = data;
            if (this.concatAllNicknames) {
                output = this.nicknamesAllMine.concat(this.nicknamesByUserID);
            }
            return output;
        },

        showOnlyNicknames(data) {
            let output = data;
            if (this.onlyNicknames) {
                output = data.filter(e => e.network.isWallet == false);
            }
            return output;
        },

        showOnlyManagementNicknames(data) {
            let output = data;
            if (this.onlyManagementNicknames) {
                output = data.filter(e => e.isManagement == true);
            }
            return output;
        },

        reset() {
            Object.assign(this.$data, this.initialState());
            if (this.$refs.formNicknameDropdown) {
                this.$refs.formNicknameDropdown.reset();
            }
        },

        resetNickname() {
            this.nickname = null;
            if (this.$refs.formNicknameDropdown) {
                this.$refs.formNicknameDropdown.reset();
            }
        },

        // prevents menu to open while copying Nickname
        disableToggleSelect() {
            this.toggleSelect = true;
            setTimeout(() => {
                this.toggleSelect = false;
            }, 1000);
        },

        /**
         * Receives an ID from a network, checks if exists in this.data
         * @param {Number} network ID
         */
        hasNetworkHandler(network) {
            let dataMap = !this._.isEmpty(this.data) ? this.data.map(e => e.network.id) : [];
            let hasNetwork = dataMap.includes(network);
            this.$emit('hasNetworkHandler', hasNetwork);
        },

        /**
         * If showAlias prop is false, then it filters for nicknames without aliasChildOf
         * @param {Array} data 
         * @returns {Array}
         */
        handleNicknameAlias(data) {
            let output = data;

            // If type == WALLET returns initial  array data parameter
            if (this.type == 'WALLET') {
                return output;
            }

            if (this.showAlias === false) {
                output = data.filter(e => this._.isNull(e.aliasChildOf));
            }

            return output;
        },
    }
}
