import Vue from 'vue';
import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth';
import AlertError from '@/components/Alerts/AlertError/AlertError.vue';
import GroupImageDropdown from '@/components/Rail/GroupImageDropdown/GroupImageDropdown.vue';
import GroupImageBadgeDropdown from '@/components/Rail/GroupImageBadgeDropdown/GroupImageBadgeDropdown.vue';
import LevelChangeGroup from '@/components/LevelChangeGroup/LevelChangeGroup.vue';

export default {
    name: 'DialogEditGroup',

    components: {
        AlertError,
        GroupImageDropdown,
        GroupImageBadgeDropdown,
        LevelChangeGroup,
    },

    props: {
        isOpen: {
            type: Boolean,
            required: true,
            default: false
        },
    },

    mixins: [
        AuthMixin,
    ],

    created() {
        this.initialize()
    },

    data() {
        return this.initialState();
    },

    computed: {
        ...mapGetters({
            error: 'groups/error',
            humanError: 'groups/humanError',
            selectedGroup: 'groups/selectedGroup',
            teams: 'teams/teams',
            teamsLoading: 'teams/loading'
        }),
    },

    watch: {
        isOpen: function (newVal, oldVal) {
            if (newVal === true) {
                this.populateFields();
            }
        },
        selectedGroup: function (newVal, oldVal){
            if (newVal != oldVal) {
                this.populateFields();
            }
        },

        showContractObligations: function (newVal, oldVal) {
            if (newVal === false) {
                this.form.contractEvents = null;
                this.form.contractSessions = null;
                this.form.monthlyEvents = null;
                this.form.monthlySessions = null;
            }
        }
    },
    
    methods: {
        initialState() {
            return {
                submitting: false,
                showContractObligations: false,
                form: {
                    name: null,
                    id: null,
                    image: null,
                    type: null,
                    level: null,
                    contractSessions: null,
                    contractEvents: null,
                    monthlySessions: null,
                    monthlyEvents: null,
                    imageBadge: null,
                },
                levelChangeGroupForm: {
                    minAverageBuyIns: null,
                    numAverageBuyIns: null,
                    daysToCalcBuyIn: null,
                    totalSplitsAmount: null,
                    totalSplits: null,
                    calcDaysforAmountAndSplit: null,
                    minAverageSessions: null,
                    calcDaysAverageSessions: null,
                    totalSplitsAmountFast: null,
                    numSessionsToUpAfterDownOff: null,
                    numOfDescentsConsecutiveAllowed: null,
                    numDaysOff: null,
                    stats_ok: null,
                },
                tabs: {
                    current: 0,
                    headers: ['Info', 'Subida/Descida', 'Objectivos'],
                  },
                types: [
                    { label: 'Grupo', name: 'Grupo', value: Vue.prototype.$group_types.group },
                    { label: 'Nível', name: 'Nível', value: Vue.prototype.$group_types.level },
                ],
                rules: {
                    name: [ 
                        v => !!v || 'Por favor introduza um nome para o grupo',
                    ],
                    image: [ 
                        v => !!v || 'Por favor introduza um imagem para o grupo',
                    ],
                    imageBadge: [ 
                        v => !!v || 'Por favor introduza um imagem para o grupo',
                    ],
                    type: [ 
                        v => !!v || 'Por favor introduza um tipo de grupo',
                    ],
                    level: [
                        (v) => (!!v && v > 0) || 'Introduza um valor positivo',
                        (v) => (!!v && /^\d{0,5}.\d{0,1}$/.test(v)) || 'Introduza um número válido até 5 digitos com 1 casa decimal (Ex: 12345 | 12345.6)',
                    ],
                    contractSessions: [
                        v => {
                          return !!v || 'Por favor introduza um valor'
                        }
                    ],
                    contractEvents: [
                        v => {
                          return !!v || 'Por favor introduza um número de sessões do contrato'
                        }  
                    ],
                    monthlyEvents: [
                        v => {
                          return !!v || 'Por favor introduza um número de eventos do contrato'
                        }  
                    ],
                    monthlySessions: [
                        v => {
                          return !!v || 'Por favor introduza um número de sessões mensalmente'
                        }  
                    ],
                    team: [ 
                        v => !!v || 'Por favor introduza uma equipa para o grupo',
                    ],
                }
            };
        },

        initialize() {
            if (this._.isEmpty(this.teams)) {
                let payload = {
                    params: {
                        type: 2, // HELPER TEAM
                        page: 1,
                        itemsPerPage: -1
                    }
                }

                this.$store.dispatch('teams/get', payload);
            }
        },

        populateFields() {
            if (this.selectedGroup) {
                this.form.name = this.selectedGroup.name;
                this.form.id = this.selectedGroup.id;
                this.form.image = this.selectedGroup.image;
                this.form.type = this.selectedGroup.type;
                this.form.level = this.selectedGroup.level;
                this.form.contractEvents = this.selectedGroup.contractNumEvents;
                this.form.contractSessions = this.selectedGroup.contractNumSessions;
                this.form.monthlyEvents = this.selectedGroup.oblNumEvents;
                this.form.monthlySessions = this.selectedGroup.oblNumSessions;
                this.form.imageBadge = this.selectedGroup.imageBadge;
                this.form.team = this.selectedGroup.team;
                

              if(this.selectedGroup.rules_change){
                this.levelChangeGroupForm.stats_ok = this.selectedGroup.statsOk;
                this.levelChangeGroupForm.minAverageBuyIns = this.selectedGroup.rules_change.UP_SPLITS_MIN_AMOUNT_ABI;
                this.levelChangeGroupForm.numAverageBuyIns = this.selectedGroup.rules_change.DOWN_ABI_AMOUNT;
                this.levelChangeGroupForm.daysToCalcBuyIn = this.selectedGroup.rules_change.DOWN_ABI_DAYS;
                this.levelChangeGroupForm.totalSplitsAmount = this.selectedGroup.rules_change.UP_NORMAL_SPLITS_AMOUNT;
                this.levelChangeGroupForm.totalSplits = this.selectedGroup.rules_change.UP_NORMAL_SPLITS_COUNT;
                this.levelChangeGroupForm.calcDaysforAmountAndSplit = this.selectedGroup.rules_change.UP_NORMAL_SPLITS_DAYS;
                this.levelChangeGroupForm.minAverageSessions = this.selectedGroup.rules_change.UP_FAST_AVG_SESSIONS;
                this.levelChangeGroupForm.calcDaysAverageSessions = this.selectedGroup.rules_change.UP_FAST_AVG_SESSIONS_DAYS;
                this.levelChangeGroupForm.totalSplitsAmountFast = this.selectedGroup.rules_change.UP_FAST_SPLITS_AMOUNT;
                this.levelChangeGroupForm.numSessionsToUpAfterDownOff = this.selectedGroup.rules_change.UP_DAYS_OFF_SESSIONS;
                this.levelChangeGroupForm.numOfDescentsConsecutiveAllowed = this.selectedGroup.rules_change.DOWN_DAYS_OFF_CONSECUTIVE;
                this.levelChangeGroupForm.numDaysOff = this.selectedGroup.rules_change.DOWN_DAYS_OFF_AMOUNT;
              }
              
                if (this.form.contractSessions || this.form.contractEvents || this.form.monthlyEvents || this.form.monthlySessions) {
                    this.showContractObligations = true;
                } else {
                    this.showContractObligations = false;
                }
            }
        },

        getImageBadge(item){
            const badges = Vue.prototype.$group_badges;

            if (typeof item === 'string' && item.endsWith('.png')) {
              const badge = badges.find(b => b.image === item);
              if (badge) {
                return badge.name;
              }
            }

            if (typeof item === 'string') {
              const badge = badges.find(b => b.name === item);
              if (badge) {
                return badge.name;
              }
            }

            return 'Default';
        },

        async submit() {
            if (!this.$refs.formEditGroup.validate()) {
                return false;
            }

            // Toggle submit button
            this.toggleSubmitting();

            let payload = {
                id: this.form.id,
                body: {
                    name: this.form.name,
                    image: this.form.image,
                    level: this.form.level,
                    image_badge: this.form.imageBadge,
                    contractNumEvents: this.form.contractEvents ? this.form.contractEvents : null,
                    contractNumSessions:  this.form.contractSessions ? this.form.contractSessions : null,
                    oblNumEvents:  this.form.monthlyEvents ? this.form.monthlyEvents : null,
                    oblNumSessions:  this.form.monthlySessions ? this.form.monthlySessions : null,
                    rules_change: {
                        UP_FAST_AVG_SESSIONS: Number(this.levelChangeGroupForm.minAverageSessions),
                        UP_FAST_AVG_SESSIONS_DAYS: Number(this.levelChangeGroupForm.calcDaysAverageSessions),
                        UP_FAST_SPLITS_AMOUNT: Number(this.levelChangeGroupForm.totalSplitsAmountFast),
                        UP_NORMAL_SPLITS_AMOUNT: Number(this.levelChangeGroupForm.totalSplitsAmount),
                        UP_NORMAL_SPLITS_COUNT: Number(this.levelChangeGroupForm.totalSplits),
                        UP_NORMAL_SPLITS_DAYS: Number(this.levelChangeGroupForm.calcDaysforAmountAndSplit),
                        DOWN_ABI_AMOUNT: Number(this.levelChangeGroupForm.numAverageBuyIns),
                        DOWN_ABI_DAYS: Number(this.levelChangeGroupForm.daysToCalcBuyIn),
                        DOWN_DAYS_OFF_AMOUNT: Number(this.levelChangeGroupForm.numDaysOff),
                        DOWN_DAYS_OFF_CONSECUTIVE: Number(this.levelChangeGroupForm.numOfDescentsConsecutiveAllowed),
                        UP_DAYS_OFF_SESSIONS: Number(this.levelChangeGroupForm.numSessionsToUpAfterDownOff),
                        UP_SPLITS_MIN_AMOUNT_ABI: Number(this.levelChangeGroupForm.minAverageBuyIns),
                    },
                    team: this.form.team,
                    stats_ok: Number(this.levelChangeGroupForm.stats_ok),
                }       
            }

            if (!this._.isNull(this.form.type)) {
                payload.body.type = this.form.type;
            }
            if (!this._.isNull(this.form.level) && this.form.type == Vue.prototype.$group_types.level) {
                payload.body.level = this.form.level.toString();
            }

            if(this.form.type == 2){
                delete payload.body.level
            }
            let result = await this.$store.dispatch('groups/update', payload);

            // // Re-enable button
            this.toggleSubmitting();

            // On success
            if (result === true) {
                // Show success snackbar
                this.$store.dispatch('UI/showSnackbar', {
                    message: 'Nível atualizado com sucesso.',
                    color: 'success'
                });

                
            this.$emit('reload-data');

            // closes dialog
            this.closeDialog();
            }
        },

        checkSubmitDisabled() {
            if (Object.keys(this.levelChangeGroupForm).length < 13) {
                return true;
            }
            let hasAnyChildNull = false

            for(let key in this.levelChangeGroupForm){
                if(this.levelChangeGroupForm[key] === null || this.levelChangeGroupForm[key] === undefined || this.levelChangeGroupForm[key] === ''){
                    hasAnyChildNull = true
                }
            }

            return hasAnyChildNull
        },

        /**
         * Used to disable Submit button
         */
        toggleSubmitting() {
            this.submitting = !this.submitting;
        },

        closeDialog() {
            this.$emit("close-dialog");
            this.resetForm();
        },

        resetForm() {
            Object.assign(this.$data, this.initialState());
            this.$refs.formEditGroup.reset();
            
            // clear errors
            this.$store.dispatch('groups/clearError');
        }
    },
};
