import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth.vue';
import RailMixin from '@/mixins/Rail.vue';

export default {
    name: 'CurrencyRates',

    mixins: [
      AuthMixin,
      RailMixin,
    ],

    computed: {
        ...mapGetters({
          userAuthenticated: 'auth/userAuthenticated',
          currencies: 'currencies/currencies',
        }),
    },

    watch: {
        userAuthenticated: {
            handler() {
            if (this.userAuthenticated) {
                this.fetchCurrencies();
            };
            },
            immediate: true,
        },
    },

    methods: {
        fetchCurrencies() {
          if (this._.isEmpty(this.currencies)) {
            this.$store.dispatch('currencies/get');
          }
        },
    
        getCurrencyRate(item) {
          return this.currencies[item] ? this.currencies[item].rate : 0;
        },
          
        triggerCopyToClipboard(content) {
          this.copyToClipboard(this.getCurrencyRate(content));
          
          // Show snackbar
          this.$store.dispatch('UI/showSnackbar', {
              message: "Rate " + content + " '" + this.getCurrencyRate(content) + "' copiado com sucesso.",
              color: 'success'
          });
        },
      }
}