var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('video-reports-preview',{ref:"dialogReportPreview",attrs:{"dialog-open":_vm.dialogues.reportPreview},on:{"closeDialog":function($event){return _vm.closeDialog()}}}),_c('v-container',{staticClass:"first-element-table"},[[_c('v-card',[_c('v-card-title',[_vm._v(" Os meus videos "),_c('v-spacer'),_c('v-text-field',{staticClass:"table-search-field",attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.dataTable.search),callback:function ($$v) {_vm.$set(_vm.dataTable, "search", $$v)},expression:"dataTable.search"}})],1),_c('div',[_c('v-tabs',{attrs:{"slot":"extension","background-color":"transparent","color":"accent"},slot:"extension",model:{value:(_vm.tabs.current),callback:function ($$v) {_vm.$set(_vm.tabs, "current", $$v)},expression:"tabs.current"}},_vm._l((_vm.tabs.headers),function(tab){return _c('v-tab',{key:tab,staticClass:"text--primary"},[_vm._v(" "+_vm._s(tab)+" ")])}),1)],1),_c('v-tabs-items',{model:{value:(_vm.tabs.current),callback:function ($$v) {_vm.$set(_vm.tabs, "current", $$v)},expression:"tabs.current"}},[_c('v-data-table',{attrs:{"headers":_vm.dataTable.headers,"search":_vm.dataTable.search,"items-per-page":_vm.dataTable.itemsPerPage,"items":_vm.filter(),"footer-props":_vm.dataTable.footerProps,"loading":!_vm.hasLoaded,"loading-text":"A carregar...","no-data-text":"Não foram encontrados pedidos de vídeo pendentes."},on:{"click:row":_vm.select},scopedSlots:_vm._u([{key:"item.info",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('p',_vm._g(_vm._b({},'p',attrs,false),on),[_c('v-icon',[_vm._v("mdi-information-outline")])],1)]}}],null,true)},[_c('span',[_c('b',[_vm._v("Última modificaçāo:")])]),_c('p',[_vm._v(_vm._s(item.updatedAt_video_requests))]),(item.reason_reject_video_requests)?_c('span',[_c('b',[_vm._v("Motivo de rejeiçāo:")])]):_vm._e(),_c('p',[_vm._v(_vm._s(item.reason_reject_video_requests))])])]}},{key:"item.state",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"dark":"","color":_vm.getDaysLeftColor(item)}},[_vm._v(" "+_vm._s(_vm.getAccessStatusString(item))+" ")])]}},{key:"item.accessDate",fn:function(ref){
var item = ref.item;
return [(item.updatedAt_video_requests)?_c('div',[_vm._v(" "+_vm._s(item.updatedAt_video_requests)+" ")]):_c('div',[_c('v-chip',{attrs:{"color":"black","dark":""}},[_vm._v(" Não definida ")])],1)]}},{key:"item.expiryDate",fn:function(ref){
var item = ref.item;
return [(item.expiry_video_requests)?_c('div',[_vm._v(" "+_vm._s(item.expiry_video_requests)+" ")]):_c('div',[_c('v-chip',{attrs:{"color":"black","dark":""}},[_vm._v(" Não definida ")])],1)]}},{key:"item.video",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":item.wp_post_url,"target":"_blank"}},[_vm._v(_vm._s(item.wp_post_title_video_requests))])]}},{key:"item.report",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"text":"","color":_vm.reportStatusColor(item.video_reports),"ripple":item.video_reports == null ? false : true},on:{"click":function($event){item.video_reports != null ? _vm.dialogReportPreview(item) : ''}}},[(_vm.reportStatus(item.video_reports) == 'Entregue')?_c('v-icon',[_vm._v(" mdi-cursor-default-click ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.reportStatus(item.video_reports))+" ")],1)]}},{key:"no-data",fn:function(){return [_c('v-container',[(_vm.hasLoaded)?_c('EmptyStateNoData',{attrs:{"icon":"mdi-video-outline","title":"Não tens pedidos de vídeo em curso.","message":"Seleciona a aba Passados para ver o estado dos teus pedidos de vídeos anteriores."}}):_vm._e()],1)]},proxy:true},{key:"no-results",fn:function(){return [_c('EmptyStateNoData',{attrs:{"icon":"mdi-magnify","title":"Sem resultados encontrados","message":"Ajusta os termos da pesquisa para encontrar mais resultados"}})]},proxy:true}])},[_c('template',{slot:"progress"},[_c('v-progress-linear',{attrs:{"color":"accent","indeterminate":""}})],1)],2)],1)],1)]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }