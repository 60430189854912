var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',[_c('v-btn',{class:_vm.navBtn.rail ? 'v-btn--active mr-2' : 'v-btn--contained mr-2',attrs:{"depressed":"","rounded":"","to":"/rail/","color":_vm.navBtn.rail ? 'accent' : 'transparent'},on:{"click":function($event){return _vm.selectBtn('rail')}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-bank-outline ")]),_vm._v(" Rail ")],1),_c('v-menu',{attrs:{"open-on-hover":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({class:_vm.navBtn.games ? 'v-btn--active mr-2' : 'v-btn--contained mr-2',attrs:{"depressed":"","rounded":"","color":_vm.navBtn.games ? 'accent' : 'transparent'}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-cards-playing-outline ")]),_vm._v(" Jogo "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-chevron-down ")])],1)]}}])},[_c('v-list',_vm._l((_vm.gamesBtnMenu),function(item,index){return _c('v-list-item-group',{key:index},[_c('v-list-item',{staticClass:"text-decoration-none",attrs:{"to":item.path},on:{"click":function($event){return _vm.selectBtn('games')}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.name))])],1)],1)],1)}),1)],1),_c('v-menu',{attrs:{"open-on-hover":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({class:_vm.navBtn.videos ? 'v-btn--active mr-2' : 'v-btn--contained mr-2',attrs:{"depressed":"","rounded":"","color":_vm.navBtn.videos ? 'accent' : 'transparent'}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-play-circle-outline ")]),_vm._v(" Vídeos "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-chevron-down ")])],1)]}}])},[_c('v-list',_vm._l((_vm.videosBtnMenu),function(item,index){return _c('v-list-item-group',{key:index},[(item.condition)?_c('v-list-item',{staticClass:"text-decoration-none",attrs:{"to":item.path},on:{"click":function($event){return _vm.selectBtn('videos')}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.name))])],1)],1):_vm._e()],1)}),1)],1),(_vm.userHasRole(['coach', 'super_coach', 'tracker_admin', 'tracker_manager']))?_c('v-menu',{attrs:{"open-on-hover":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({class:_vm.navBtn.players ? 'v-btn--active mr-2' : 'v-btn--contained mr-2',attrs:{"depressed":"","rounded":"","color":_vm.navBtn.players ? 'accent' : 'transparent'}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-account-outline ")]),_vm._v(" Jogadores "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-chevron-down ")])],1)]}}],null,false,4051239215)},[_c('v-list',_vm._l((_vm.playersBtnMenu),function(item,index){return _c('v-list-item-group',{key:index},[(item.condition)?_c('v-list-item',{staticClass:"text-decoration-none",attrs:{"to":item.path},on:{"click":function($event){return _vm.selectBtn('players')}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.name))])],1)],1):_vm._e()],1)}),1)],1):_vm._e(),(!_vm.userHasRole(['coach', 'super_coach', 'tracker_admin', 'tracker_manager']))?_c('v-btn',{class:_vm.navBtn.tracker ? 'v-btn--active mr-2' : 'v-btn--contained mr-2',attrs:{"color":_vm.navBtn.tracker ? 'accent' : 'transparent',"depressed":"","rounded":"","to":"/nicknames/"},on:{"click":function($event){return _vm.selectBtn('tracker')}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-form-textbox ")]),_vm._v(" Nicknames ")],1):_vm._e(),(_vm.userHasRole(['coach', 'super_coach', 'tracker_admin', 'tracker_manager']))?_c('v-menu',{attrs:{"open-on-hover":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({class:_vm.navBtn.tracker ? 'v-btn--active mr-2' : 'v-btn--contained mr-2',attrs:{"depressed":"","rounded":"","color":_vm.navBtn.tracker ? 'accent' : 'transparent'}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-application-cog-outline ")]),_vm._v(" Gestão "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-chevron-down ")])],1)]}}],null,false,2767803095)},[_c('v-list',_vm._l((_vm.trackerBtnMenu),function(item,index){return _c('v-list-item-group',{key:index},[(item.condition)?_c('v-list-item',{staticClass:"text-decoration-none",attrs:{"to":item.path},on:{"click":function($event){return _vm.selectBtn('tracker')}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.name))])],1)],1):_vm._e()],1)}),1)],1):_vm._e(),(_vm.userHasRole(['beta_tester_scheduling', 'beta_tester_calendar']))?_c('v-menu',{attrs:{"open-on-hover":"","offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({class:_vm.navBtn.schedulle ? 'v-btn--active mr-2' : 'v-btn--contained mr-2',attrs:{"depressed":"","rounded":"","color":_vm.navBtn.schedulle ? 'accent' : 'transparent'}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-test-tube ")]),_vm._v(" Beta Testing "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-chevron-down ")])],1)]}}],null,false,2612858294)},[_c('v-list',_vm._l((_vm.scheduleBtnMenu),function(item,index){return _c('v-list-item-group',{key:index},[_c('v-list-item',{staticClass:"text-decoration-none",attrs:{"to":item.path},on:{"click":function($event){return _vm.selectBtn('schedulle')}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.name))])],1)],1)],1)}),1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }