import Vue from 'vue';
import AuthMixin from '@/mixins/Auth.vue';

export default {
    name: 'DialogWelcome',

    mixins: [
        AuthMixin,
    ],
    
    data() {
        return this.initialState();
    },

    computed: {
        demo() {
          return true;
        },
    },

    created() {
      this.initialize();
    },

    methods: {
        initialState() {
            return {
                open: false,
                cookieName: Vue.prototype.$cookie_names.prefix + 'dialog_welcome',
            }
        },

        initialize() {
            // Open the modal if conditions are met
            setTimeout(() => {
                this.open = this.shouldShowDialog();
            }, 5000);
        },

        shouldShowDialog() {
            return false;

            // Dont show to unauthenticated users
            if ( ! this.isAuthenticated() ) {
                return false;
            }

            // Dont show to users who have the cookie (already viewed)
            if ($cookies.get(this.cookieName) === 'true') {
                return false;
            }

            // Show if we got here
            return true;
        },

        writeCookie() {
            $cookies.set(this.cookieName, true);
        },

        close(writeCookie = true) {
            console.log('close');

            this.open = false;

            if (writeCookie) {
                this.writeCookie();
            }
        },
    }
}