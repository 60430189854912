import Vue from 'vue';
import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth.vue';
import RailMixin from '@/mixins/Rail.vue';
import DataMixin from '@/mixins/Data.vue';
import EmptyStateNoData from '@/components/EmptyStateNoData';
import ValueChip from '@/components/ValueChip';
import DialogConfirmation from '@/components/Rail/Dialog/DialogConfirmation/DialogConfirmation.vue';

export default {
  name: 'TableAudits',

  props: {
    showTabs: {
      type: Boolean,
      required: false,
      default: true
    },
    user: {
      type: Object,
      required: false,
      default: null
    },
    network: {
      type: Object,
      required: false,
      default: null
    },
    status: {
      type: Number,
      required: false,
      default: null
    },
  },

  mixins: [
    AuthMixin,
    RailMixin,
    DataMixin,
  ],

  components: {
    EmptyStateNoData,
    ValueChip,
    DialogConfirmation,
  },

  data() {
    return this.initializeState();
  },

  async created() {
    if (!this.fetchLoading) {
      return await this.fetchData();
    }
  },

  computed: {
    ...mapGetters({
      userTrackerId: 'auth/userTrackerId',
      heroDatePickerValue: 'UI/heroDatePickerValue',
      teamFilter: 'teams/teamFilter',
      selectedAudit: 'audits/selectedAudit',
      error: 'audits/error',
      humanError: 'audits/humanError',
      audits: 'audits/audits',
      auditsTotal: 'audits/auditsTotal',
      auditsLoading: 'audits/auditsLoading',

      auditsPending: 'audits/auditsPending',
      auditsPendingTotal: 'audits/auditsPendingTotal',
      auditsPendingLoading: 'audits/auditsPendingLoading',

      auditsFinalized: 'audits/auditsFinalized',
      auditsFinalizedTotal: 'audits/auditsFinalizedTotal',
      auditsFinalizedLoading: 'audits/auditsFinalizedLoading',
      themeDarkMode: 'UI/themeDarkMode',
    }),

    tableHeaders: function () {
      if (this.userIsRailManager && this.$route.name != 'Ficheiros do Jogador') {
        return this.dataTable.headers
      } else {
        return [
          { text: 'Data de importação', value: 'date' },
          { text: 'Sala', value: 'network' },
          { text: 'Ficheiro', value: 'file' },
          { text: 'Estado', value: 'status', sortable: false, width: '10px' },
          { text: 'Ação', value: 'action', sortable: false }
        ];
      }

    },

    tableData: function () {
      if (this.showTabs) {
        switch (this.tabs.current) {
          case 0:
            return this.auditsPending;
          case 1:
            return this.auditsFinalized;
          case 2:
            return this.audits;
          default:
            return this.audits;
        }
      }
      return this.audits;
    },

    tableLoading: function () {
      if (this.showTabs) {
        switch (this.tabs.current) {
          case 0:
            return this.auditsPendingLoading;
          case 1:
            return this.auditsFinalizedLoading;
          case 2:
            return this.auditsLoading;
          default:
            return this.auditsLoading;
        }
      }
      return this.auditsLoading;
    },

    tableTotal: function () {
      if (this.showTabs) {
        switch (this.tabs.current) {
          case 0:
            return this.auditsPendingTotal;
          case 1:
            return this.auditsFinalizedTotal;
          case 2:
            return this.auditsTotal;
          default:
            return this.auditsTotal;
        }
      }
      return this.auditsTotal;
    },

    optionsComputed: {
      get: function () {
        return this.options;
      },
      set: function (newValue) {
        if (!this._.isEqual(this.options, newValue)) {
          this.options = newValue;
        }
      }
    }
  },

  watch: {
    // watches this.options object
    // triggers everytime this.options has changed
    options: {
      handler() {
        if (!this.fetchLoading) {
          this.fetchData();
        }
      },
      deep: true,
    },

    heroDatePickerValue: function () {
      if (!this.fetchLoading) {
        this.fetchData();
      }
    },
    'user': function () {
      if (!this.fetchLoading) {
        this.fetchData();
      }
    },
    'network': function () {
      this.fetchData();
    },
    'status': function () {
      this.fetchData();
    },
    'tabs.current': function () {
      this.fetchData();
    },
  },

  methods: {
    initializeState() {
      return {
        form: {
          user: null,
        },
        dialogues: {
          confirm: false,
          addEditStatsRecord: false,
          submittingDelete: false,
          confirmation: {
            title: '',
            message: '',
            btn: {
              name: '',
              color: '',
            },
            action: null,
          }
        },
        tabs: {
          current: 0,
          items: [
            { name: 'Pendentes', disabled: false },
            { name: 'Revistos', disabled: false },
            { name: 'Todos', disabled: false }
          ],
        },
        fetchLoading: false,
        singleViewAuditLoading: false,
        deleteAuditLoading: false,
        dataTable: {
          headers: [
            { text: 'Data de importação', value: 'date' },
            { text: 'Jogador', value: 'userTarget', sortable: false },
            { text: 'Sala', value: 'network' },
            { text: 'Ficheiro', value: 'file' },
            { text: 'Estado', value: 'status', sortable: false, width: '10px' },
            { text: 'Ação', value: 'action', sortable: false }
          ],
          search: null,
        },
        options: {
          page: 1,
          itemsPerPage: 10,
          sortBy: [],
          sortDesc: [],
          groupBy: [],
          groupDesc: [],
          mustSort: true,
          multiSort: false
        },
      };
    },


    /**
     * @param {Object} item 
     * @returns String Date
     */
    getDate(item, mode = null) {
      if (item && !mode) {
        return this.$moment(item.date.date).format('DD/MM/YYYY');
      }else if(mode == 'all'){
        return this.$moment(item.createdAt.date).format('DD/MM/YYYY hh:mm');
      }
    },

    /**
     * @param {Object} item 
     * @returns {String} symbol value
     */
    getSymbol(item) {
      if (item) {
        let output = this.getItemObject(item);
        return output.currency.symbol;
      };
    },

    // trigered by v-text-field @input="search()"
    search() {
      setTimeout(() => {
        this.fetchData()
      }, 1000);
    },

    async fetchData() {

      this.fetchLoading = true;

      let payload = {
        params: {
          page: this.options.page,
          itemsPerPage: this.options.itemsPerPage,
        }
      }

      // Add date params
      if (!this._.isEmpty(this.heroDatePickerValue)) {
       payload.params.dateBegin = this.heroDatePickerValue[0];
       payload.params.dateEnd = this.heroDatePickerValue[1];
      }

      // handle tab status parameter
      if ((this.tabs.current == 0) && this.showTabs) {
        payload.params['status[' + 0 + ']'] = Vue.prototype.$audits.status.REQUESTED;
        payload.params['status[' + 1 + ']'] = Vue.prototype.$audits.status.PENDING_REVIEW;
      } else if (this.tabs.current == 1 && this.showTabs) {
        payload.params['status[' + 0 + ']'] = Vue.prototype.$audits.status.APPROVED;
        payload.params['status[' + 1 + ']'] = Vue.prototype.$audits.status.REJECTED;
      } else {
        delete payload.params.status;
      }

      // if user prop is populated
      if (this.user) {
        payload.params.user_target = this.user.id;
      } else {
        delete payload.params.user_target;
      }

      // if network prop is populated
      if (this.network) {
        payload.params.network = this.network.id;
      } else {
        delete payload.params.network;
      }
      
      // if user prop is populated
      if (this.status) {
        payload.params.status = this.status;
      } else {
        delete payload.params.status;
      }

      // table options
      // Add sortBy parameter to payload
      if (!this._.isEmpty(this.options.sortBy)) {
        this.options.sortBy.forEach((element, index) => {
          payload.params['sortBy[' + index + ']'] = element;
        })
      }

      // Add sortDesc parameter to payload.params
      if (!this._.isEmpty(this.options.sortDesc)) {
        this.options.sortDesc.forEach((element, index) => {
          payload.params['sort[' + index + ']'] = element == false ? 'ASC' : 'DESC';;
        })
      }


      await this.$store.dispatch('audits/getAudits', payload);

      this.fetchLoading = false;
    },

    toggleDialog(dialog) {
      this.dialogues[dialog] = !this.dialogues[dialog];
    },

    imageCutout(item) {
      let placeholder = require('@/assets/images/players/player-example.png');
      return item && item.imgCutout ? item.imgCutout : placeholder;
    },

    getStatusColor(status) {
      let output = '';
      if (status) {
        switch (status) {
          case Vue.prototype.$audits.status.REQUESTED:
            output = 'accent';
            break;
          case Vue.prototype.$audits.status.PENDING_REVIEW:
            output = 'info';
            break;
          case Vue.prototype.$audits.status.APPROVED:
            output = 'success';
            break;
          case Vue.prototype.$audits.status.REJECTED:
            output = 'error';
            break;
          default:
            output = 'accent';
            break;
        }
      }
      return output;
    },

    getStatusName(status) {
      let output = '';
      if (status) {
        switch (status) {
          case Vue.prototype.$audits.status.REQUESTED:
            output = 'Pedido submetido';
            break;
          case Vue.prototype.$audits.status.PENDING_REVIEW:
            output = 'Revisão pendente';
            break;
          case Vue.prototype.$audits.status.APPROVED:
            output = 'Aprovado';
            break;
          case Vue.prototype.$audits.status.REJECTED:
            output = 'Rejeitado';
            break;
          default:
            output = 'Pendente';
            break;
        }
      }
      return output;
    },

    openDialogConfirmation(item) {
      this.clearErrors();
      this.setActiveItem(item);
      this.deleteAuditLoading = true;

      this.dialogues.confirm = true;
      this.dialogues.confirmation = {
        title: 'Apagar audit',
        message: 'Tem a certeza que quer apagar o audit importado?',
        btn: {
          name: 'Apagar',
          color: 'error'
        },
      } 
    },

    closeDialogConfirmation() {
      // close dialog
      this.dialogues.confirm = false;
      // stop spin loader state
      this.deleteAuditLoading = false;

    },

    setActiveItem(item) {
      this.$store.commit(
        'audits/setSelectedAudit',
        item
      );
    },

    async deleteAudit() {

      if (this.selectedAudit) {
        let payload = {
          id: this.selectedAudit.id,
          params: {}
        }

        this.dialogues.submittingDelete = true;
        let result = await this.$store.dispatch('audits/deleteSingleAudit', payload);
        this.dialogues.submittingDelete = false;
        if (result === true) {
          this.fetchData();
          this.closeDialogConfirmation();
        }
      }
    },

    clearErrors() {
      this.$store.dispatch('audits/clearErrors');
    }
  },
};
