import { mapGetters } from 'vuex';
import Hero from '@/components/Hero/Hero.vue';
import AlertNoData from '@/components/AlertNoData';
import Snackbar from '@/components/Snackbar';
import DialogView from '@/components/Departures/DialogView';
import DialogEdit from '@/components/Departures/DialogEdit.vue';
import DialogDelete from '@/components/Departures/DialogDelete';
import DataMixin from '@/mixins/Data';
import AuthMixin from '@/mixins/Auth';

export default {
    name: 'Departures',

    components: {
        Hero,
        AlertNoData,
        Snackbar,
        DialogView,
        DialogEdit,
        DialogDelete,
    },

    mixins: [
        DataMixin,
        AuthMixin,
    ],

    computed: {
        ...mapGetters({
            departures: 'departures/departures',
            reasonsDeparture: 'departures/reasonsDeparture',
            userRolesLoading: 'auth/userRolesLoading',
        }),
    },

    data() {
        return {
            dataLoadingFinished: false,

            tabs: {
                current: 0,
                headers: ['Todas', 'A decorrer', 'Finalizadas']
            },

            dataTable: {
                headers: [
                    { text: 'Estado', value: 'status_departures' },
                    { text: 'Início', value: 'start_departures' },
                    { text: 'Fim', value: 'end_departures' },
                    { text: 'Jogador', value: 'user.display_name_users' },
                    { text: 'Motivo', value: 'name_dr' },
                    { text: 'Devido', value: 'total_departures' },
                    { text: 'Ação', value: 'action', sortable: false },
                ],
                footerProps: {
                    'items-per-page-options': this.$itemsPerPageOptions,
                },
                search: null,
                itemsPerPage: 10
            },

            dialogues: {
                view: false,
                edit: false,
                delete: false
            }
        };
    },

    async created() {
        await this.allowOnlyRoles(['super_coach', 'rail_admin', 'tracker_admin']);

        if (this._.isEmpty(this.departures)) {
            await this.$store.dispatch('departures/getDepartures');
        }

        if (this._.isEmpty(this.reasonsDeparture)) {
            await this.$store.dispatch('departures/getReasonsDeparture');
        }
        
        this.dataLoadingFinished = true;
    },

    methods: {
        initialize() {
            return false;
        },

        filterDepartures() {
            switch (this.tabs.current) {
                case 0:
                    return this.departures;
                case 1:
                    return this.departures.filter(e => e.status_departures == 1);
                case 2:
                    return this.departures.filter(e => e.status_departures == 2);
                default:
                    return this.departures;
            }
        },

        getStatusColor(item) {
            if (item.status_departures == 1)
                return 'orange'
            else if (item.status_departures == 2)
                return 'green'
            else
                return 'gray'
        },

        getStatusName(item) {
            if (item.status_departures == 1)
                return 'A decorrer'
            else if (item.status_departures == 2)
                return 'Finalizado'
            else
                return 'Desconhecido'
        },

        getDueColor(item) {
            if (item.bankroll_returned_departures && item.makeup_returned_departures)
                return 'green'
            else if (item.bankroll_returned_departures || item.makeup_returned_departures)
                return 'orange'
            else
                return 'red'
        },

        viewDeparture(item) {
            if (item) {
                this.$store.commit('departures/setSelectedDeparture', item);
                this.dialogues.view = true;
            }
        },

        editDeparture(item) {
            // Populate if editing already existing departure
            if (item) {
                this.$store.commit('departures/setSelectedDeparture', item);
                this.$refs.dialogEditDepartures.populateFields();
            }

            this.dialogues.edit = true;     // This is outside because we need to create new departures
        },

        async deleteDeparture(item) {
            this.$store.commit('departures/setSelectedDeparture', item);
            this.dialogues.delete = true;   // this.$store.dispatch('departures/deleteDeparture');
        },

        openDialog(dialog) {
            this.dialogues[dialog] = true;
        },

        closeDialog(dialog) {
            this.$store.commit('departures/setSelectedDeparture', null);
            this.dialogues[dialog] = false;
        },

        deleteCallback() {
            this.closeDialog('delete');
            
            // Show success snackbar
            this.$store.dispatch('UI/showSnackbar', {
                message: 'Registo de saída apagado com sucesso.',
                color: 'success'
            });
        }
    }
};