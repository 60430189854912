import Vue from "vue";
import { mapGetters } from "vuex";

export default {
    name: "video-reports-evaluate",
    data() {
        return this.initialState();
    },
    props: {
        dialogOpenEvaluate: { type: Boolean, required: true, default: false },
    },

    /**
     * On create hook get selected report from the store
     * Update evaluation and observations input forms with the current selected video report
     * Everytime the selected video report changes this Component will re-render and this hook will be called again updating data state with correct data
     **/
    created() {
        if (this.selectedReport) {
            this.evaluation = this.selectedReport.evaluation_video_reports;
            this.observations = this.selectedReport.observations_video_reports;
        }
    },

    computed: {
        ...mapGetters({
            selectedReport: 'videoReports/selectedVideoReport',
        }),
    },

    methods: {
        initialState() {
            return {
                evaluation: this.selectedReport ? this.selectedReport.evaluation_video_reports : null,
                observations: this.selectedReport ? this.selectedReport.observations_video_reports : null,
                rules: {
                    fieldEvaluation: [
                        (v) => !!v || 'Insira um valor numérico entre 0 e 20.',
                        (v) => Number.isInteger(v) || 'Insira um valor numérico inteiro',
                    ],
                },
                form_valid: true,
            };
        },

        submit() {
            if (!this.$refs.formVideoReportEvaluate.validate()) {
                return false;
            }

            setTimeout(() => {
                if (!this.form_valid) return false;

                if (this.selectedReport) {
                    let payload = {
                        id: this.selectedReport.id_video_reports,
                        evaluation: this.evaluation,
                        observations: this.observations,
                        status: Vue.prototype.$video_reports.status.approved,
                    };

                    this.$store.dispatch(
                        'videoReports/updateVideoReport',
                        payload
                    );
                    this.$store.dispatch('UI/showSnackbar', {
                        message: 'Avaliação guardada com sucesso.',
                        color: 'success',
                    });
                }

                this.$emit('closeBothDialogs');
                return true;
            }, 500);
        },

        /**
         * Close video-reports-evaluate dialog and reset forms validation 
         */
        closeDialog() {
            this.$emit('closeDialog');
            this.resetValidationDialog();
        },

        resetValidationDialog() {
            this.$refs.formVideoReportEvaluate.resetValidation();
            Object.assign(this.$data, this.initialState());
        }
    },
};
