import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth.vue';
import _ from 'lodash';
import moment from 'moment';

export default {
    name: 'CalendarList',

    props: {
        events: {
            type: Array,
            default: () => []
        },
        month: {
            type: String,
            default: () => { moment().format('MM') }
        }
    },

    components: {},

    mixins: [
        AuthMixin
    ],

    computed: {
        ...mapGetters({
            selectedEvent: 'eventsCalendar/selectedEvent',
            storeEvents: 'eventsCalendar/data',
        }),

        /**
         * Group events by date
         * @returns {array}
         */
        eventsByDay() {
            // output {"2022-10-01": [..Array of events], "2022-10-02": [...Aray of events] }
            let events = _.groupBy(this.events, event => event.start.slice(0, 10));

            /**
             * to-do improve this filter to more readable code and create a filter function to return just the filtered events
             * Filter events by selected month
             * 
             * on line 50 I convert key ('2022-10-01' to -> '10') and validate if selected month is equals key
             * @see https://stackoverflow.com/a/56081419 
             */
            const filteredEvents = Object.fromEntries(
                Object.entries(events).filter(
                    ([key, val]) => this.month.includes(moment(key).format('MM'))
                )
            );

            return filteredEvents;
        },

        /**
         * Title of the day 
         * 
         * @param {Object} firstEvent of the day  
         * @returns {string} ex: 18 de outubro 2022
         */
        dayTitleString: () => (date) => {
            return moment(date).format('LL');
        },

        /**
         * Name of the week day 
         * 
         * @returns {string} ex: Terça-feira
         */
        weekdayString: () => (date) => {
            return moment(date).format('dddd');   
        },

        /**
         * String of start hour - end hour  
         * 
         * @returns {string} 
         */
         eventHoursString: () => (event) => {
            const startTime = moment(event.start).format('HH:mm');
            const endTime   = moment(event.end).format('HH:mm');

            return `${startTime} - ${endTime}`;
        },

        getEventUrl: () => (event) => {
            return `/calendar/event/${event.id}`
        }, 

        /**
         * @returns {boolean} true if exists events
         */
        hasEvents() {
            return ! _.isEmpty(this.eventsByDay);
        }
    },

    watch: {},

    created() {},
    
    mounted() {},

    data() {
        return this.initialState(); 
    },

    methods: {
        initialState() {
            return {
            };
        },

        async editEvent(event) {
            // set selected event before emit edit-event
            await this.setSelectedEvent(event.id);
            this.$emit('edit-event');
        },
        
        async deleteEvent(event) {
            // set selected event before emit delete-event
            await this.setSelectedEvent(event.id);
            this.$emit('delete-event');
        },

        /**
         * 
         * @param {integer} id of the event 
         */
        async setSelectedEvent(id) {
            await this.$store.commit('eventsCalendar/setSelectedEvent', this.storeEvents.find(e => e.id == id));
        }
    }

}