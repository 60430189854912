import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth';
import Hero from '@/components/Hero/Hero.vue';
import AlertNoData from '@/components/AlertNoData';
import Snackbar from '@/components/Snackbar';
import DialogApplications from '@/components/Applications/DialogApplications.vue';

export default {
    name: 'Applications',

    components: {
        Hero,
        AlertNoData,
        Snackbar,
        DialogApplications
    },

    mixins: [
        AuthMixin,
    ],

    computed: {
        ...mapGetters({
            applications: 'applications/applications'
        })
    },

    async created() {
        await this.allowOnlyRoles([
            'rail_admin',
            'rail_manager',
            'tracker_admin',
            'tracker_manager',
            'applications_manager',
            'team_cash_manager'
        ]);

        this.initialize();
    },

    data() {
        return {
            dataLoadingFinished: false,

            tabs: {
                current: 0,
                headers: [
                    'Por responder',
                    'Respondidas',
                    'Todas',
                    'A decorrer',
                    'Aprovadas',
                    'Rejeitadas',
                    'Fase 1',
                    'Fase 2',
                    'Fase 3'
                ]
            },

            dataTable: {
                headers: [
                    { text: 'Data', value: 'timestamp_stage_1_submit_applications' },
                    { text: 'Fase', value: 'stage_applications' },
                    { text: 'Tipo', value: 'name_applications_categories' },
                    { text: 'Nome', value: 'data_stage_1_applications.player-name' },
                    { text: 'Idade', value: 'data_stage_1_applications.player-age' },
                    { text: 'País', value: 'country' },
                    { text: 'Email', value: 'data_stage_1_applications.player-email', align: ' d-none' },
                    { text: 'Status',value: 'status_applications', filterable: false },
                    { text: 'Ação', value: 'open', filterable: false, sortable: false }
                ],
                footerProps: {
                    'items-per-page-options': this.$itemsPerPageOptions,
                },
                search: null,
                itemsPerPage: 10
            },

            dialogOpen: false
        };
    },

    methods: {
        async initialize() {
            await this.$store.dispatch('applications/getApplications');
            this.dataLoadingFinished = true;
        },

        filterApplications() {
            switch (this.tabs.current) {
                case 0:
                    return this.applications.filter(e => e['data_stage_' + e.stage_applications + '_applications'] !== null && e.status_applications != 0 && e.status_applications != 2);
                case 1:
                    return this.applications.filter(e => e['data_stage_' + e.stage_applications + '_applications'] === null);
                case 2:
                    return this.applications;
                case 3:
                    return this.applications.filter(e => e.status_applications == 1);
                case 4:
                    return this.applications.filter(e => e.status_applications == 2);
                case 5:
                    return this.applications.filter(e => e.status_applications == 0);
                case 6:
                    return this.applications.filter(e => e.stage_applications == 1 && e.status_applications == 1);
                case 7:
                    return this.applications.filter(e => e.stage_applications == 2 && e.status_applications == 1);
                case 8:
                    return this.applications.filter(e => e.stage_applications == 3 && e.status_applications == 1);
                default:
                    return this.applications;
            }
        },

        getStatusColor(item) {
            if (item.status_applications == 0) return 'red'
            else if (item['data_stage_' + item.stage_applications + '_applications'] === null) return 'green'
            else if (item.status_applications == 1) return 'orange'
            else if (item.status_applications == 2) return 'green'
            else return 'gray'
        },

        getStatusName(item) {
            if (item.status_applications == 0) return 'Rejeitada'
            else if (item['data_stage_' + item.stage_applications + '_applications'] === null) return 'A aguardar'
            else if (item.status_applications == 1) return 'Por responder'
            else if (item.status_applications == 2) return 'Aprovada'
            else return 'Unknown'
        },

        viewApplication(application) {
            this.$store.commit('applications/setSelectedApplication', application); // Store
            this.$store.commit('applications/setIdApplicationInView', application.id_applications); // Store
            this.$store.dispatch('applications/getPastApplications');   // Get past applications by same candidate
            //this.selectedApplication = application; // Prop passed to dialog

            // If this is a stage 3 application, get CalendarEvents associated with it
            if (application.stage_applications == 3)
                this.$store.dispatch('calendar/getEventsByApplication', application.id_applications);

            // UI
            this.dialogOpen = true;
        },

        closeDialog() {
            // UI
            this.dialogOpen = false;

            // Clear data & store
            this.selectedApplication = {};
            this.$store.commit('applications/setSelectedApplication', this.selectedApplication);
            this.$store.commit('applications/setIdApplicationInView', null);
            this.$store.commit('applications/setPastApplications', []);
        }
    }
};