import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import StoreMixin from '@/mixins/Store.vue';
import DataMixin from '@/mixins/Data.vue';

Vue.use(Vuex);
Vue.use(StoreMixin);
Vue.use(DataMixin);

const state = {
    teams: [],
    teamsDivision: [],
    teamsTotal: null,
    selectedTeam: null,
    error: null,
    humanError: null,
    loading: true,
    teamsDivisionLoading: true,
    teamFilter: null,
    selectedStatistic: null,
    selectedStatisticLoading: null,
    selectedPnlStatistic: null,
    selectedPnlStatisticLoading: null,
    lastPayload: {},
    lastPayloadTeamsDivision: {},
};

const getters = {
    teams: state => state.teams,
    teamsDivision: state => state.teamsDivision,
    teamsTotal: state => state.teamsTotal,
    selectedTeam: state => state.selectedTeam,
    error: state => state.error,
    humanError: state => state.humanError,
    loading: state => state.loading,
    teamsDivisionLoading: state => state.teamsDivisionLoading,
    teamFilter: (state) => state.teamFilter,
    selectedStatistic: state => state.selectedStatistic,
    selectedStatisticLoading: state => state.selectedStatisticLoading,
    selectedPnlStatistic: state => state.selectedPnlStatistic,
    selectedPnlStatisticLoading: state => state.selectedPnlStatisticLoading,
};

const mutations = {
    setTeams: (state, payload) => { state.teams = payload; },
    setTeamsTotal: (state, payload) => { state.teamsTotal = payload; },
    setError: (state, payload) => { state.error = payload; },
    setSelectedTeam: (state, payload) => { state.selectedTeam = payload; },
    setErrorStatus: (state, payload) => { state.errorStatus = payload },
    setHumanError: (state, payload) => { state.humanError = payload; },
    setSelectedTeamLoading: (state, payload) => { state.selectedTeamLoading = payload },
    setLoading: (state, payload) => { state.loading = payload },
    
    setSelectedStatisticLoading: (state, payload) => { state.selectedStatisticLoading = payload },
    setSelectedStatistic: (state, payload) => { state.selectedStatistic = payload; },

    setSelectedPnlStatisticLoading: (state, payload) => { state.selectedPnlStatisticLoading = payload },
    setSelectedPnlStatistic: (state, payload) => { state.selectedPnlStatistic = payload; },

    addTeam: (state, payload) => {
        if (! state.teams || ! state.teams.length)
        state.teams = [];
    
        state.teams.unshift(payload);
    },
    setTeamFilter(state, payload) {
        state.teamFilter = payload;
    },
    updateTeam: (state, payload) => {
        const index = state.teams.findIndex(d => d.id == payload.id);

        const updatedItems = [
            ...state.teams.slice(0, index),
            payload,
            ...state.teams.slice(index + 1)
        ];

        state.teams = updatedItems;
    },
    setTeamsDivision: (state, payload) => { state.teamsDivision = payload; },
    setTeamsDivisionLoading: (state, payload) => { state.teamsDivisionLoading = payload; },
    setLastPayload: (state, payload) => { state.lastPayload = payload; },
    setLastPayloadTeamsDivision: (state, payload) => { state.lastPayloadTeamsDivision = payload; },
};

const actions = {

    clearError({ commit }) {
        commit('setError', null);
        commit('setHumanError', null);
        commit('setErrorStatus', null);
    },

    async get({ commit, dispatch, state }, payload) {

        // Configure URL Parameters
        let parameters = payload && payload.params
            ? payload.params
            : StoreMixin.methods.getParametersDefault();
        
        // validate payload objects before API Call
        let areObjectsEqual = DataMixin.methods.areObjectsEqual(state.lastPayload, parameters);

        if (areObjectsEqual) return false;
        // save last payload
        commit('setLastPayload', parameters);

        //clear state
        commit('setLoading', true);
        
        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/teams/';
        
        // Build URL with parameters
        request_url = StoreMixin.methods.generateQueryParamsUrl(request_url, parameters);

        // Execute request & return
        let output = false;

        return axios.get(request_url)
        .then(function (response) {
            output = response.data.success;

            if (output) {
                commit('setTeams', response.data.data);
                commit('setTeamsTotal', response.data.total);
            } else {
                commit('setError', response.data.message);
                commit('setHumanError', response.data.human_message);
            }
        })
        .catch(function (error) {
            commit('setError', error.response ? error.response.data.message : error);
            commit('setHumanError', error.response ? error.response.data.human_message : error);
            output = false;
        })
        .then(function () {
            commit('setLoading', false);
            return output;
        });
    },

    async getTeamsDivision({ commit, dispatch, state }, payload) {

        // Configure URL Parameters
        let parameters = payload && payload.params
            ? payload.params
            : StoreMixin.methods.getParametersDefault();
        
        // validate payload objects before API Call
        let areObjectsEqual = DataMixin.methods.areObjectsEqual(state.lastPayloadTeamsDivision, parameters);

        if (areObjectsEqual) return false;
        // save last payload
        commit('setLastPayloadTeamsDivision', payload.params);

        //clear state
        commit('setTeamsDivisionLoading', true);
        
        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/teams/';
        
        // Build URL with parameters
        request_url = StoreMixin.methods.generateQueryParamsUrl(request_url, parameters);

        // Execute request & return
        let output = false;

        return axios.get(request_url)
        .then(function (response) {
            output = response.data.success;

            if (output) {
                commit('setTeamsDivision', response.data.data);
            } else {
                commit('setError', response.data.message);
                commit('setHumanError', response.data.human_message);
            }
        })
        .catch(function (error) {
            commit('setError', error.response ? error.response.data.message : error);
            commit('setHumanError', error.response ? error.response.data.human_message : error);
            output = false;
        })
        .then(function () {
            commit('setTeamsDivisionLoading', false);
            return output;
        });
    },

    async getStatistics({ commit, dispatch }, payload) {
        // clear state
        dispatch('clearError');
        commit('setSelectedStatistic', null);
        commit('setSelectedStatisticLoading', true);

        if(payload.id){
            let request_url = Vue.prototype.$url_api + 'v2/teams/' + payload.id + '/statistics';
        
            if (payload && payload.dateBegin && payload.dateEnd) {
                request_url += `?dateBegin=${payload.dateBegin}&dateEnd=${payload.dateEnd}`;
              }         
            // Execute request & return
            let output = false;
    
            return axios.get(request_url)
            .then(function (response) {
                output = response.data.success;
                if (output) {
                    commit('setSelectedStatistic', response.data.data);
                } else {
                    commit('setError', response.data.message);
                    commit('setHumanError', response.data.human_message);
                    commit('setErrorStatus', response.status);
                }
            })
            .catch(function (error) {
                commit('setError', error.response ? error.response.data.message : error);
                commit('setHumanError', error.response ? error.response.data.human_message : error);
                commit('setErrorStatus', error.response.status);
                output = false;
            })
            .then(function () {
                commit('setSelectedStatisticLoading', false);
                return output;
            });
        }
    },

    async getPnlStatistics({ commit, dispatch }, payload) {
        // clear state
        dispatch('clearError');
        commit('setSelectedPnlStatistic', null);
        commit('setSelectedPnlStatisticLoading', true);

        if(payload.id){
            let request_url = Vue.prototype.$url_api + 'v2/teams/' 
            + payload.id + '/gameEntries/pnl/' + `?dateBegin=${payload.dateBegin}&dateEnd=${payload.dateEnd}`;
            // Execute request & return
            let output = false;
            return axios.get(request_url)
            .then(function (response) {
                output = response.data.success;
                if (output) {
                    commit('setSelectedPnlStatistic', response.data.data);
                } else {
                    commit('setError', response.data.message);
                    commit('setHumanError', response.data.human_message);
                    commit('setErrorStatus', response.status);
                }
            })
            .catch(function (error) {
                commit('setError', error.response ? error.response.data.message : error);
                commit('setHumanError', error.response ? error.response.data.human_message : error);
                commit('setErrorStatus', error.response.status);
                output = false;
            })
            .then(function () {
                commit('setSelectedPnlStatisticLoading', false);
                return output;
            });
        }
    },

    async update({ commit, dispatch }, payload) {
        // clear state
        dispatch('clearError');

        // Handle payload data
        let data = JSON.stringify(payload.body);

        // Configure request
        const request_url = Vue.prototype.$url_api + 'v2/teams/' + payload.id;
        let config = {
            method: 'PUT',
            url: request_url,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        }
        // Execute request & return
        let output = false;

        return axios(config)
        .then(function (response) {
            output = response.data.success;

            if (output) {
                commit('updateTeam', response.data.data);
                commit('setSelectedTeam', response.data.data);
            } else {
                commit('setError', response.data.message);
                commit('setHumanError', response.data.human_message);
            }
        })
        .catch(function (error) {
            commit('setError', error.response ? error.response.data.message : error);
            commit('setHumanError', error.response ? error.response.data.human_message : error);
            output = false;
        })
        .then(function () {
            return output;
        });
    },

    async getTeam({ commit, dispatch }, payload) {
        // clear state
        dispatch('clearError');
        commit('setSelectedTeam', null);
        commit('setSelectedTeamLoading', true);

        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/teams/' + payload.id;
        // Execute request & return
        let output = false;

        return axios.get(request_url)
        .then(function (response) {
            output = response.data.success;
            if (output) {
                commit('setSelectedTeam', response.data.data);
            } else {
                commit('setError', response.data.message);
                commit('setHumanError', response.data.human_message);
                commit('setErrorStatus', response.status);
            }
        })
        .catch(function (error) {
            commit('setError', error.response ? error.response.data.message : error);
            commit('setHumanError', error.response ? error.response.data.human_message : error);
            commit('setErrorStatus', error.response.status);
            output = false;
        })
        .then(function () {
            commit('setSelectedTeamLoading', false);
            return output;
        });
    },

    async remove({ commit, dispatch }, payload) {
        dispatch('clearError');

        const request_url = Vue.prototype.$url_api + 'v2/teams/' + payload.id;
        let config = {
            method: 'DELETE',
            url: request_url,
            headers: {
                'Content-Type': 'application/json'
            },
        }
        let output = false;
        return axios(config)
        .then(function (response) {
            // 204 response status represents success
            output = response.status == 204;
            if (output) {
                // set last payload to default state in order to force refresh when triggering get()
                commit('setLastPayload', {});
            } else {
                commit('setError', response.data.message);
                commit('setHumanError', response.data.human_message);
            }
        })
        .catch(function (error) {
            commit('setError', error.response ? error.response.data.message : error);
            commit('setHumanError', error.response ? error.response.data.human_message : error);
            output = false;
        })
        .then(function () {
            return output;
        });
    },

    
    async create({ commit, dispatch }, payload) {
        dispatch('clearError');

        let data = JSON.stringify(payload.body);

        const request_url = Vue.prototype.$url_api + 'v2/teams/';
        let config = {
            method: 'POST',
            url: request_url,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        }
        let output = false;

        return axios(config)
        .then(function (response) {
            output = response.data.success;
            if (output) {
                commit('addTeam', response.data.data);
                commit('setSelectedTeam', response.data.data);
            } else {
                commit('setError', response.data.message);
                commit('setHumanError', response.data.human_message);
            }
        })
        .catch(function (error) {
            commit('setError', error.response ? error.response.data.message : error);
            commit('setHumanError', error.response ? error.response.data.human_message : error);
            output = false;
        })
        .then(function () {
            return output;
        });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};