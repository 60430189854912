import Vue from 'vue';

export default {
    name: 'OperationStatusDropdown',

    props: {
        value: { type: Array, required: false, default: () => [] },
        tab: { type: [String, Number], required: false, default: '' },
        hideDetails: { type: Boolean, required: false, default: false },
    },

    data() {
        return this.initialState();
    },

    computed: {
        computedValue: {
            get: function () {
               return this.status;
            },
            set: function (newValue) {
                this.status = newValue;
                this.emitChange();
            }
        },
        // returns an array populated with some status based on tab passed through props
        computedStatusItems: function () {
            let output = [];
            switch (this.tab) {
                case 0:
                case 1:
                    output.push(
                        this.operationStatus.pending,
                        this.operationStatus.accepted,
                        this.operationStatus.approved,
                    );
                    break;
                case 2:
                    output.push(
                        this.operationStatus.rejected,
                        this.operationStatus.finalized,
                    );
                    break;
                case 3:
                    output.push(
                        this.operationStatus.pending,
                        this.operationStatus.accepted,
                        this.operationStatus.approved,
                        this.operationStatus.rejected,
                        this.operationStatus.finalized,

                    );
                    break;
                default:
                    output.push(
                        this.operationStatus.pending,
                        this.operationStatus.accepted,
                        this.operationStatus.approved,
                    );
                    break;
            }
            return output;
        },
    },

    watch: {
        // clears status everytime tab prop as changed
        tab: function () {
            this.status = [];
            this.emitChange();
        },
    },

    methods: {
        initialState() {
            return {
                status: [],
                operationStatus: {
                    pending: { value: Vue.prototype.$rail_status.pending, name: Vue.prototype.$rail_status_name[0] },
                    accepted: { value: Vue.prototype.$rail_status.accepted, name: Vue.prototype.$rail_status_name[1] },
                    approved: { value: Vue.prototype.$rail_status.approved, name: Vue.prototype.$rail_status_name[2] },
                    rejected: { value: Vue.prototype.$rail_status.rejected, name: Vue.prototype.$rail_status_name[3] },
                    finalized: { value: Vue.prototype.$rail_status.finalized, name: Vue.prototype.$rail_status_name[4] },
                },                
            }
        },

        emitChange() {
            this.$emit('input', this.computedValue);
            this.$emit('change', this.computedValue);
        },
    }
}