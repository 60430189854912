var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Hero',{attrs:{"title":"Candidaturas","message":"Gestão de candidaturas para entrada na equipa.","icon":"mdi-clipboard-list-outline"}}),_c('v-container',[[_c('Snackbar')],_c('DialogApplications',{attrs:{"dialog-open":_vm.dialogOpen},on:{"closeDialog":function($event){return _vm.closeDialog()}}}),(_vm.applications)?[_c('v-card',[_c('v-toolbar',{staticClass:"elevation-0"},[_c('v-tabs',{attrs:{"slot":"extension","background-color":"transparent","show-arrows":"","color":"accent"},slot:"extension",model:{value:(_vm.tabs.current),callback:function ($$v) {_vm.$set(_vm.tabs, "current", $$v)},expression:"tabs.current"}},_vm._l((_vm.tabs.headers),function(tab){return _c('v-tab',{key:tab,staticClass:"text--primary"},[_vm._v(" "+_vm._s(tab)+" ")])}),1),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Pesquisar por nome ou email","single-line":"","hide-details":""},model:{value:(_vm.dataTable.search),callback:function ($$v) {_vm.$set(_vm.dataTable, "search", $$v)},expression:"dataTable.search"}})],1),_c('v-tabs-items',{model:{value:(_vm.tabs.current),callback:function ($$v) {_vm.$set(_vm.tabs, "current", $$v)},expression:"tabs.current"}},[(_vm.applications)?_c('v-data-table',{attrs:{"headers":_vm.dataTable.headers,"items":_vm.filterApplications(),"search":_vm.dataTable.search,"items-per-page":_vm.dataTable.itemsPerPage,"footer-props":_vm.dataTable.footerProps,"loading":!_vm.applications.length,"loading-text":"A carregar..."},scopedSlots:_vm._u([{key:"item.name_applications_categories",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":"grey","dark":""}},[(item.name_applications_categories)?_c('span',[_vm._v(_vm._s(item.name_applications_categories))]):_c('span',[_vm._v("Desconhecido")])])]}},{key:"item.stage_applications",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":"primary secondary--text"}},[_vm._v(" "+_vm._s(item.stage_applications)+" ")])]}},{key:"item.country",fn:function(ref){
var item = ref.item;
return [(Array.isArray(item.data_stage_1_applications['player-country']) && item.data_stage_1_applications['player-country'][0] !== undefined)?_c('span',[_vm._v(" "+_vm._s(item.data_stage_1_applications['player-country'][0])+" ")]):_c('span',[_vm._v(" "+_vm._s(item.data_stage_1_applications['player-country'])+" ")])]}},{key:"item.status_applications",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getStatusColor(item),"dark":""}},[_vm._v(" "+_vm._s(_vm.getStatusName(item))+" ")])]}},{key:"item.open",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},on:{"click":function($event){return _vm.viewApplication(item)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.viewApplication(item)}}},on),[_vm._v(" mdi-page-next-outline ")])]}}],null,true)},[_c('span',[_vm._v("Visualizar candidatura")])])]}}],null,false,2865246731)},[_c('template',{slot:"progress"},[_c('v-progress-linear',{attrs:{"color":"accent","indeterminate":""}})],1)],2):_vm._e()],1)],1)]:[(_vm.dataLoadingFinished)?_c('AlertNoData',{attrs:{"gender":"a","asset":"candidaturas"}}):_vm._e()]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }