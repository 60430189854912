import Vue from 'vue';
import { mapGetters } from 'vuex';
import CardStepper from '@/components/CardStepper/CardStepper.vue';
import CardStepperNotes from '@/components/CardStepper/CardStepperNotes/CardStepperNotes.vue';
import CardStepperNetwork from '@/components/CardStepper/CardStepperNetwork/CardStepperNetwork.vue';
import NicknameDropdown from '@/components/Rail/NicknameDropdown/NicknameDropdown.vue';
import UserDropdown from '@/components/Rail/UserDropdown/UserDropdown.vue';
import RowTransfer from '@/components/Rail/RowTransfer/RowTransfer.vue';
import DialogViewAttachment from '@/components/Rail/Dialog/DialogViewAttachment/DialogViewAttachment.vue';
import DialogUserNotes from '@/components/Rail/Dialog/DialogUserNotes/DialogUserNotes.vue';
import AlertUserNote from '@/components/Rail/AlertUserNote/AlertUserNote.vue';
import AlertError from '@/components/Alerts/AlertError/AlertError.vue';
import Alert from '@/components/Alerts/Alert/Alert.vue';
import RailMixin from '@/mixins/Rail.vue';
import DataMixin from '@/mixins/Data.vue';
import AuthMixin from '@/mixins/Auth.vue';
import NicknameCard from '@/components/NicknameCard/NicknameCard.vue';

export default {
  name: 'CardSplit',

  components: {
    CardStepper,
    CardStepperNotes,
    CardStepperNetwork,
    NicknameDropdown,
    RowTransfer,
    UserDropdown,
    AlertError,
    DialogViewAttachment,
    Alert,
    DialogUserNotes,
    AlertUserNote,
    NicknameCard,
  },

  mixins: [
    RailMixin,
    DataMixin,
    AuthMixin,
  ],

  created() {
    this.initialize();
  },

  computed: {
    ...mapGetters({
      users: 'users/usersAllActive',
      nicknamesAllPlayer: 'nicknames/nicknamesAllPlayer',
      walletsManagement: 'railWallets/walletsManagement',
      loading: 'users/loading',
      selectedRequest: 'railRequests/selectedRequest',
      error: 'railRequests/error',
      humanError: 'railRequests/humanError',
      selectedUser: 'users/selectedUser',
      userNotes: 'users/userNotes',
      teamFilter: 'teams/teamFilter',
      nicknamesAllMine: 'nicknames/nicknamesAllMine',
      nicknamesByUserID: 'nicknames/nicknamesByUserID',
      themeDarkMode: 'UI/themeDarkMode',
    }),
    
    /**
     * @returns {Number|String} profit share value
     */
    profitShareStr: function () {
      let output = 0;
      if (this.selectedUser) {
        output = this.getProfitShare(this.selectedUser.profitShare);
      }
      return output;
    },

    selectedRequestObservation: function () {
      return this.getObservationByPhase(this.selectedRequest.observations, Vue.prototype.table_requests_phases[Vue.prototype.$rail_ops.operations.split][0]['value']);
    },

    isGGPokerSelected: function () {
      if (!this._.isEmpty(this.form.nicknames)) {
        let hasGGPoker = this._.find(this.form.nicknames, (el) => el.wallet.network.id == Vue.prototype.$rail_GGPoker_network_id);
				return !this._.isUndefined(hasGGPoker);
      }
    },
  },

  watch: {
    // if user changes then form.nicknames needs to be cleaned
    // if user changes then call API in order to get new user's profitShare
    'form.user': function (oldVal, newVal) {
      if (newVal && oldVal && (newVal.id != oldVal.id )) {
        this.form.nicknames = [];
      }
      if (this.form.user) {
        this.$store.dispatch('users/getUser', this.form.user.id);

        let payload = {
          id: this.form.user.id,
          populateUserNotes: true,
        }
        // get user notes
        this.$store.dispatch('users/getUserNotes', payload);
      }
    },

    selectedRequest: function () {
      this.resetComponentState();
      this.fetchData();
    },

    split_type: function () {
      if (this.split_type == this.split_types.normal) {
        this.adminHand = false;
      } else {
        this.adminHand = true;
      }
    },
  },

  data() {
    return this.initialState();
  },

  methods: {
    initialState() {
      return {
        split_type: 1,
        split_types: {
          normal: 1,
          back_office: 2,
        },
        adminHand: false,
        tableNicknamesToSplit: {
          headers: [
            { text: 'Sala a splitar', value: 'network', sortable: false },
            { text: 'Montante Total', value: 'amount', sortable: false },
          ]
        },
        form: {
          userID: null,
          user: null,
          gyazoLink: null,
          observation: null,
          fileName: [],
          nicknames: [],
          walletTarget: [],
          isPending: true,
        },
        rules: {
          user: [
            (v) => !!v || 'Por favor introduza um jogador'
          ],
          cardStepperNetwork: [
            v => (v && v.length > 0) || 'Required!',
          ],
          cardStepperNotes: [
            (v) => !!v || 'Por favor preencha o campo'
          ],
          walletTarget: [
            v => (v && !!v) || 'Required!',
          ],
        },
        cardTitles: [
          'Selecionar jogador e tipo',
          'Selecionar salas',
          'Selecionar carteiras',
          'Confirmação'
        ],
        formValidations: [
          false,
          false,
          false,
          true,
        ],
        dialogues: {
          viewAttachment: false,
          userNotes: false,
        },
        observation: {
          url: null,
          type: null
        },
      }
    },

    initialize() {
      this.fetchData();
    },

    /**
     * Validates a specific form step.
     * Assumes form steps are named 'formStep_x' where x is a Int 0 - maxStep-1.
     * 
     * @param {Int} step Identifier of the form step to validate.
     * @returns {Void}
     */
    validateStep(step) {
      const formStepRef = 'formStep_' + (step);
      if (this.$refs[formStepRef]) {
        // Update formValidation[step] in a way the DOM will be reactive
        Vue.set(
          this.formValidations,
          step - 1,
          this.$refs[formStepRef].validate()
        );
      }

      // clean errors if exists
      if (this.error) {
        this.$store.dispatch('railRequests/clearError');
      }
    },

    fetchData() {

      // Preload users
      if (this._.isEmpty(this.users)) {
        let payload = this.formulateUsersAllUrlParameters();
        this.$store.dispatch('users/getUsersAllActive', payload);
      }
      
      // Preload management wallets
      if (this._.isEmpty(this.walletsManagement)) {
        this.$store.dispatch('railWallets/getWalletsFromManagement');
      }

      if (this.selectedRequest) {
        this.form.user = this.selectedRequest.user;
        this.form.userID = this.selectedRequest.user.id;

        // Preload all of the player's nicknames
        if (this._.isEmpty(this.nicknamesAllPlayer)) {
          let payload = {
            user: this.selectedRequest.user.id,
            aliasParameter: '&aliasChildOf=null'
          }
          this.$store.dispatch('nicknames/getAllPlayer', payload);
        }

        // if request exist, no need to validate step 1
        this.formValidations[0] = true;
      }
    },

    resetComponentState() {
      // force CardStepper to display 1 step
      this.$store.commit('UI/setCardStepperStep', 1);
      // reset data component state
      Object.assign(this.$data, this.initialState());
    },

    /**
     * retrieve observation fields from CardStepperNotes component
     * @param {Object} payload 
     */
    getInfoFromCardStepperNotes(payload) {
      if (payload) {
        this.form.gyazoLink = payload.gyazoLink;
        this.form.fileName = payload.filename;
      }
    },

    /**
     * Uses form.nicknames to populate transfer objects 
     * @returns an array with objects to be used in TransferRow component
     */
    getTransfers() {
      let transfers = []

      // Add transfers
      this.form.nicknames.forEach(e => {

        let walletAdmin = this.form.walletTarget[e.id];
        let walletPlayer = this.nicknamesByUserID.find(e => e.network.id == walletAdmin.network.id)
        let isSameNetwork = e.wallet.network.id == walletAdmin.network.id;

        if (this.split_type == this.split_types.back_office) {
          // if split type is backoffice and isSameNetwork
          if (isSameNetwork) {
            transfers.push(
              {
                source: e.wallet,
                target: this.nicknamesAllMine.find(item => item.network.id == e.wallet.network.id),
                amount: { value: e.balance.value - (e.balance.value * this.selectedUser.profitShare) },
                isPending: this.form.isPending
              },
              {
                source: walletPlayer,
                target: Vue.prototype.$external_wallet,
                amount: { value: e.balance.value - (e.balance.value * ( 1 - this.selectedUser.profitShare)) },
                isPending: this.form.isPending
              },
            )
          } else {
            // if split type is backoffice the transfers list has 3 transfers (PN -> MN / MW -> PW / PW -> OUT)
            transfers.push(
              {
                source: e.wallet,
                target: this.nicknamesAllMine.find(item => item.network.id == e.wallet.network.id),
                amount: { value: e.balance.value },
                isPending: this.form.isPending
              },
              {
                source: this.form.walletTarget[e.id],
                target: walletPlayer,
                amount: { value: e.balance.value - (e.balance.value * ( 1 - this.selectedUser.profitShare)) },
                isPending: this.form.isPending
              },
              {
                source: walletPlayer,
                target: Vue.prototype.$external_wallet,
                amount: { value: e.balance.value - (e.balance.value * ( 1 - this.selectedUser.profitShare)) },
                isPending: this.form.isPending
              },
            )
          }

        } else {
          // add transfer if network IDs are diferent
          if (e.wallet.network.id != walletAdmin.network.id) {
            transfers.push(              {
              source: e.wallet,
              target: walletPlayer,
              amount: e.balance,
              isPending: this.form.isPending
              })
          }
          transfers.push(
            {
              source: walletPlayer,
              target: this.form.walletTarget[e.id],
              amount: { value: e.balance.value - (e.balance.value * this.selectedUser.profitShare) },
              isPending: this.form.isPending
            },
            {
              source: walletPlayer,
              target: Vue.prototype.$external_wallet,
              amount: { value: e.balance.value * this.selectedUser.profitShare },
              isPending: this.form.isPending
            },
          )
        }
      });

      return transfers
    },

    async submit() {
      // Validate form
      if (!this.$refs.formSplitChild.validate()) {
        return false;
      }

      // Toggle submit button
      this.$refs.CardStepper.toggleSubmitting();

      /**
      * Preparing payload
      */

      // Base payload
      let payload = {
        body: {}
      }

      // add body FormData
      payload.body = new FormData();

      // add user
      payload.body.append('user', this.form.userID);
      
      payload.body.append('adminHand', this.adminHand ? 1 : 0 );

      // Add transfers
      this.form.nicknames.forEach((element, index) => {
        payload.body.append('transfers[' + index + '][source]', element.wallet.id);
        payload.body.append('transfers[' + index + '][target]', this.form.walletTarget[element.id].id)
        payload.body.append('transfers[' + index + '][amount]', element.balance.value);
      });

      // Add 'id' field if necessary
      if (this.selectedRequest && this.selectedRequest.id) {
        payload.id = this.selectedRequest.id;
      }

      // Add 'observation' field if necessary
      if (this.form.observation) {
        payload.body.append('observation[description]', this.form.observation);
      }

      // Add 'screenshot' field if necessary
      if (!this._.isEmpty(this.form.fileName)) {
        payload.body.append('screenshot', this.form.fileName[0]);
      }

      // Add 'observation[url]' field if necessary
      if (this.form.gyazoLink) {
        payload.body.append('observation[url]', this.form.gyazoLink);
      }

      /**
       * Dispatch to store
       */

      // If payload.id exists, we are updating, else, we are creating
      const actionToDispatch = payload.id
        ? 'railRequests/splitUpdate'
        : 'railRequests/splitCreate';

      // Dispatch store action
      let result = await this.$store.dispatch(actionToDispatch, payload);

      // Re-enable btn
      if (this.$refs.CardStepper) {
        this.$refs.CardStepper.toggleSubmitting();
      }

      // On success
      if (result === true) {
        
        let payloadRefresh = {
          params: {}
        }

        // if team exists and isManagement, Add team parameter to payload.params
        if (this.teamFilter && this.userHasRailAccessDivisionDropdown) {
        payloadRefresh.params.team = this.teamFilter;
        }
        
        // Refresh Requests Transfers and Balances
        this.updateRequestsTransfersAndBalances(payloadRefresh);
        
        this.updateStatisticsByUserId(this.form.userID);
        
        // Show success snackbar
        const snackbarActionWord = payload.id
          ? 'atualizado'
          : 'criado';
        
        let id = this.selectedRequest.id;
        let url = Vue.prototype.$tab_slider.tabs.requests + id;

        this.$store.dispatch('UI/showSnackbar', {
          message: 'Pedido de split ' + snackbarActionWord + ' com sucesso.',
          color: 'success'
        });

        // Change hasFinished state in order to change component
        this.$store.commit('TabSlider/setHasFinished', true);

        // change to operation single view
        this.$router.push({ path: url });
      }
    },

    /**
    * Populates observation.url and observation.name
    * @param {Object} observation 
    * @returns {Boolean} open dialog or opens new page;
    */
    openDialogViewAttachment(observation) {
      if (observation && observation.url) {

        // validate observation type
        let isTypeLink = observation.type == 1;

        // populate fields
        this.observation.url = observation.url;
        this.observation.type = observation.type;

        return isTypeLink
          ? window.open(observation.url, '_blank')
          : this.dialogues.viewAttachment = true;
      }
    },

    openDialogUserNotes() {
      this.dialogues.userNotes = true;
    },
    
    closeAlertUserNotes() {
      this.$store.commit('users/setUserNotes', []);
    },

    /**
     * Only used when this.split_type == this.split_types.backoffice
     * @param {Object} nickname 
     * @returns {Array} filled with network IDs
     */
    handleNetworksToBeExcluded(nickname) {
      let output = [];

      if (this.split_type == this.split_types.normal) {
        return output;
      }

      let nicknamesAllMineMapNetworkID = this.nicknamesAllMine.map(e => e.network.id);
      let nicknamesByUserIdMapNetworkID = this.nicknamesByUserID.map(e => e.network.id);
      output = this._.difference(nicknamesAllMineMapNetworkID, nicknamesByUserIdMapNetworkID);
      return output;
    }
  }
}