import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth';
import UserDropdown from '@/components/Rail/UserDropdown/UserDropdown.vue';
import AlertError from '@/components/Alerts/AlertError/AlertError.vue';

export default {
    name: 'DialogAddPlayerToGroup',

    props: {
        isOpen: {
            type: Boolean,
            required: true,
            default: false
        },
    },
    components: {
        UserDropdown,
        AlertError,
    },

    mixins: [
        AuthMixin,
    ],

    data() {
        return this.initialState();
    },

    created() {
        this.fetchData();
    },

    computed: {
        ...mapGetters({
            error: 'users/error',
            humanError: 'users/humanError',
            groups: 'groups/groups',
            selectedGroup: 'groups/selectedGroup'
        }),
    },

    watch: {
        // when re opened, clean form and populate fields
        isOpen: function (newVal, oldVal) {
            if (newVal === true) {
                this.resetForm();
                this.populateFields();
            }
        },
        selectedGroup: function () {
            this.populateFields();
        }
    },

    methods: {
        initialState() {
            return {
                submitting: false,
                form: {
                    user: null,
                    group: null,
                },
                rules: {
                    user: [
                        (v) => !!v || 'Por favor introduza um jogador'
                    ],
                    group: [
                        v => !!v || 'Por favor introduza um grupo'
                    ],
                }
            };
        },

        async fetchData() {
            this.populateFields();
        },

        populateFields() {
            // when selected by table row ( TableGroupMembers )
            this.form.group = this.selectedGroup;

            // when entered single group page
            if (this.$route.params.id) {
                this.form.group = this.groups.find(element => element.id == this.$route.params.id);
            }
        },

        async submit() {
            if (!this.$refs.formAddPlayerToGroup.validate()) {
                return false;
            }

            // Toggle submit button
            this.toggleSubmitting();

            let payload = {
                id: this.form.user.id,
                group: this.form.group.id
            }

            let result = await this.$store.dispatch('users/updateUser', payload);

            // // Re-enable button
            this.toggleSubmitting();

            // On success
            if (result === true) {

                let payloadGroups = {
                    params: {
                        group: this.form.group.id,
                    },
                }

                // refresh TableGroupMembers
                this.$store.dispatch('users/getUsersFromGroup', payloadGroups);

                // Show success snackbar
                this.$store.dispatch('UI/showSnackbar', {
                    message: 'Jogador adicionado com sucesso.',
                    color: 'success'
                });

                // closes dialog
                this.closeDialog();
            }
        },

        /**
         * Used to disable Submit button
         */
        toggleSubmitting() {
            this.submitting = !this.submitting;
        },

        closeDialog() {
            this.$emit("close-dialog");
            this.resetForm();
        },

        resetForm() {
            Object.assign(this.$data, this.initialState());
            if (this.$refs.formAddPlayerToGroup) {
                this.$refs.formAddPlayerToGroup.reset();
            }
            if (this.$refs.UserDropdown) {
                this.$refs.UserDropdown.reset();
            }

            // clear errors
            this.$store.dispatch('groups/clearError');
        }
    },
};
