import Vue from 'vue';
import { mapGetters } from 'vuex';
import CardStepper from '@/components/CardStepper/CardStepper.vue';
import CardStepperNotes from '@/components/CardStepper/CardStepperNotes/CardStepperNotes.vue';
import AlertError from '@/components/Alerts/AlertError/AlertError.vue';
import RailMixin from '@/mixins/Rail.vue';

export default {
  name: "Split",
  
  components: {
    CardStepper,
    CardStepperNotes,
    AlertError,
  },

  mixins: [
    RailMixin,
  ],

  computed: {
    ...mapGetters({
      error: 'railRequests/error',
      humanError: 'railRequests/humanError',
      selectedRequest: 'railRequests/selectedRequest',
    }),
  },

  data() {
    return this.initialState();
  },

  methods: {
    initialState() {
      return {
        cardTitles: [
          'Fazer pedido de Split',
        ],
        formValidations: [
          false
        ],
        form: {
          observation: null,
          gyazoLink: null,
          fileName: []
        },
        rules: {
          cardStepperNotes: [(v) => !!v || 'Por favor preencha o campo'],
        }
      }
    },

  /**
   * Validates a specific form step.
   * Assumes form steps are named 'formStep_x' where x is a Int 0 - maxStep-1.
   * 
   * @param {Int} step Identifier of the form step to validate.
   * @returns {Void}
   */
    validateStep(step) {
      const formStepRef = 'formStep_' + (step);
      if (this.$refs[formStepRef]) {
        // Update formValidation[step] in a way the DOM will be reactive
        Vue.set(
          this.formValidations,
          step - 1,
          this.$refs[formStepRef].validate()
        );
      }
    },

    // retrieve fields from CardStepperNotes component
    getInfoFromCardStepperNotes(payload) {
      if (payload) {
        this.form.gyazoLink = payload.gyazoLink;
        this.form.fileName = payload.filename;
      }
    },

    // create a Bankroll Reduction request with an observation
    async submit() {
      // Validate form
      if (!this.$refs.formSplitChild.validate()) {
        return false;
      }

      // Toggle submit button
      this.$refs.CardStepper.toggleSubmitting();

      /**
       * Preparing payload
      */

      // Base payload
      let payload = {
        body: {},
      }

      payload.body = new FormData();
      // Add 'observation' field if necessary
      if (this.form.observation) {
        payload.body.append('observation[description]', this.form.observation);
      }

      // Add 'screenshot' field if necessary
      if (!this._.isEmpty(this.form.fileName)) {
        payload.body.append('screenshot', this.form.fileName[0]);
      }

      // Add 'observation[url]' field if necessary
      if (this.form.gyazoLink) {
        payload.body.append('observation[url]', this.form.gyazoLink);
      }

      // Dispatch to store
      let result = await this.$store.dispatch("railRequests/splitCreate", payload);


      // Re-enable btn
      if (this.$refs.CardStepper) {
        this.$refs.CardStepper.toggleSubmitting();
      }

      // On success
      if (result === true) {
        // Refresh Requests Transfers and Balances
        this.updateRequestsTransfersAndBalances();

        let id = this.selectedRequest.id;
        let url = Vue.prototype.$tab_slider.tabs.requests + id;

        // Show success snackbar
        this.$store.dispatch('UI/showSnackbar', {
          message: 'Pedido de split criado com sucesso.',
          color: 'success'
        });

        // change hasFinished state in order to change component
        this.$store.dispatch('TabSlider/setHasFinished', true);

        // change to operation single view
        this.$router.push({ path: url });
      }
    }
  }
}