import Vue from 'vue';
import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth.vue';
import RailMixin from '@/mixins/Rail.vue';
import Alert from '@/components/Rail/Alert/Alert.vue';
import ButtonContainer from '@/components/Rail/ButtonContainer/ButtonContainer.vue';

export default {
    name: 'CardReport',

    components: {
        Alert,
        ButtonContainer,
    },

    mixins: [
        AuthMixin,
        RailMixin
    ],

    computed: {
        ...mapGetters({
            selectedReport: 'railReports/selectedReport',
            userDisplayName: 'auth/userDisplayName',
        }),
    },

    watch: {
        selectedReport: function () {
            // reset comment fields
            this.reset();
            // validates if user/admin can write a comment
            this.writeValidate();
            // populate comment
            this.getComment();
            // update status
            this.updateReportStatus();
        }
    },

    created() {
        // fetch data from selectedReport
        return this.fetchData();
    },

    data() {
        return this.initialState();
    },
    methods: {
        initialState() {
            return {
                title: 'Problema reportado',
                description: 'Dar seguimento a um relato de problema no Rail.',
                date: null,
                operation: null,
                reportType: null,
                comment: null,
                commentDate: null,
                commentAuthor: null,
                writeComment: null,
                reportStatus: null,
                canWrite: true,
                rules: {
                    comment: [
                        v => !!v || 'Por favor preencha o campo de resposta',
                    ],
                },
                phases: {
                    transfer: [
                        { value: 1, name: 'Transferido' },
                        { value: 2, name: 'Recebido' },
                    ],
                    bankroll_increment: [
                        { value: 1, name: 'Pedido' },
                        { value: 2, name: 'Transferido' },
                    ],
                    session: [
                        { value: 1, name: 'Registado' }
                    ]
                }
            }
        },

        // updates status to 2 ("Em revisão") as soon as we enter in card report
        async updateReportStatus() {
            if (this.userIsRailManager) {
                if (this.selectedReport && this.selectedReport.status == Vue.prototype.$rail_reports.status.ongoing) {

                    // formulate payload
                    let payload = {
                        id: this.selectedReport.id,
                        report: {
                            status: Vue.prototype.$rail_reports.status.seen,
                            operation: this.selectedReport.railOperation.id
                        },
                        feedback: {
                            message: 'Update: Report em revisão',
                            color: 'success'
                        }
                    }

                    // send payload to store 
                    let result = await this.$store.dispatch('railReports/update', payload);
                    if (result === true) {

                        // refresh reports
                        this.$store.dispatch('railReports/get');
                        
                        // feedback on successs
                        this.$store.dispatch('UI/showSnackbar', {
                            message: payload.feedback.message,
                            color: payload.feedback.color
                        });
                    }
                    this.$store.commit('TabSlider/setHasFinished', false);
                }
            }
        },

        // Get report Status
        getReportStatus() {
            if (this.selectedReport) {
                switch (this.selectedReport.status) {
                    case Vue.prototype.$rail_reports.status.ongoing:
                        return 'Pendente';
                    case Vue.prototype.$rail_reports.status.seen:
                        return 'Em Revisão';
                    case Vue.prototype.$rail_reports.status.resolved:
                        return 'Resolvido';
                    default:
                        break;
                }
            }
        },

        getOperationStatus(item) {
            var statusName = null;

            switch (item.type) {
                case Vue.prototype.$rail_ops.operations.split:

                    if ((item.status <= 2) && item.status == item.phase) {
                        return 'Pedido';
                    } else if (item.status == 3 && item.phase == 2) {
                        return 'Aprovado';
                    } else if (item.status == 3 && item.phase == 3 && item.ongoing == 1) {
                        return 'Por confirmar';
                    } else {
                        return 'Finalizado';
                    }

                case Vue.prototype.$rail_ops.operations.bankroll_reduction:
                    if (item.createdByManagement && item.status == 3 && item.phase == 1 || item.status == 1 && item.phase == 2) {
                        return 'Pedido';
                    } else if (item.createdByManagement && item.status == 3 && item.phase == 2) {
                        return 'Transferido';
                    } else {
                        return 'Finalizado';
                    }

                case Vue.prototype.$rail_ops.operations.bankroll_increment:
                    statusName = this.phases.bankroll_increment.filter(e => e.value == item.phase);
                    return statusName[0]['name'];

                case Vue.prototype.$rail_ops.operations.transfer_to_self:
                case Vue.prototype.$rail_ops.operations.transfer_to_player:
                    statusName = this.phases.transfer.filter(e => e.value == item.phase);
                    return statusName[0]['name'];

                case Vue.prototype.$rail_ops.operations.swap:
                    statusName = this.phases.transfer.filter(e => e.value == item.phase);
                    return statusName[0]['name'];

                case Vue.prototype.$rail_ops.operations.deposit:
                case Vue.prototype.$rail_ops.operations.withdraw:
                    statusName = this.phases.transfer.filter(e => e.value == item.phase);
                    return statusName[0]['name'];

                case Vue.prototype.$rail_ops.operations.sessions:
                    statusName = this.phases.session.filter(e => e.value == item.phase);
                    return 'Registado';
                default:
                    break;
            }
        },

        // if selectedReport is valid then we populate a bunch of fields 
        fetchData() {
            if (this.selectedReport) {
                this.getComment();
                this.updateReportStatus();
            }
        },

        isUserDateGreaterThanAdminDate() {
            if (this.selectedReport) {
                var userDate = this.selectedReport.userObservationDate;
                var adminDate = this.selectedReport.adminObservationDate;

                // if both dates aren't null
                if (userDate && adminDate) {
                    // get diff between user/admin date and moment date inn minutes
                    var userDateDiff = this.$moment().diff(this.$moment(userDate.date), 'seconds');
                    var adminDateDiff = this.$moment().diff(this.$moment(adminDate.date), 'seconds');
                    var isUserGreaterThanAdmin = userDateDiff > adminDateDiff;
                    return isUserGreaterThanAdmin;
                } else if (userDate && userDate.date) {
                    return true;
                } else {
                    return false;
                }
            }
        },

        // poppulate comment about the report
        getComment() {
            var userDate = this.selectedReport.userObservationDate;
            var adminDate = this.selectedReport.adminObservationDate;
            if (userDate && adminDate) {
                // if adminDate is greater than userDate, then we populate comment with userObservation because isAdminGreaterThanUser 
                this.comment = this.isUserDateGreaterThanAdminDate() ? this.selectedReport.adminObservation : this.selectedReport.userObservation;
                this.commentDate = this.isUserDateGreaterThanAdminDate() ? adminDate.date : userDate.date;
                this.commentAuthor = this.isUserDateGreaterThanAdminDate() ? 'Gestor' : this.userDisplayName;
            }
        },

        // reset comment fields
        reset() {
            this.comment = null;
            this.commentDate = null;
            this.commentAuthor = null;
        },

        writeValidate() {           
            let isReportResolved = this.selectedReport && (this.selectedReport.status == Vue.prototype.$rail_reports.status.resolved);
            let hasAdminObservation = this.userIsRailManager && this.selectedReport.adminObservation;
            let hasUserObservation = !this.userIsRailManager && this.selectedReport.userObservation;

            if (isReportResolved || hasAdminObservation || hasUserObservation) {
                return this.canWrite = false;
            } else {
                return this.canWrite = true;
            }
        },

        async write() {
            // Validate
            if (!this.$refs.writeComment.validate()) {
                return false;
            }

            this.comment = this.writeComment;

            let payload = {
                id: this.selectedReport.id,
                report: {
                    observation: this.comment,
                    type: this.selectedReport.type.id,
                    operation: this.selectedReport.railOperation.id
                },
                feedback: {
                    message: 'Comentaste o report com sucesso!',
                    color: 'success'
                }
            }

            let result = await this.$store.dispatch('railReports/update', payload);
            // On success
            if (result === true) {

                // feedback on successs
                this.$store.dispatch('UI/showSnackbar', {
                    message: payload.feedback.message,
                    color: payload.feedback.color
                });
                // refresh reports
                this.$store.dispatch('railReports/get');
                let username = this.selectedReport.railOperation && this.selectedReport.railOperation.targetUser
                    ? this.selectedReport.railOperation.targetUser.name
                    : this.selectedReport.railOperation.user.name
                this.commentAuthor = this.userIsRailManager ? 'Gestor' : username;
            }

            // clean field
            this.writeComment = null;
        },
    }
}
