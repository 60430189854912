import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';

Vue.use(Vuex);

const state = {
    videoReports: [],
    selectedReport: null,
    hasLoaded: false
};

const getters = {
    videoReports: (state) => state.videoReports,
    selectedVideoReport: (state) => state.selectedReport,
    hasLoaded: (state) => state.hasLoaded,
};

const mutations = {
    setVideoReports(state, payload) {
        state.videoReports = payload;
    },
    setSelectedVideoReports: (state, payload) => {
        state.selectedReport = payload;
    },
    updateVideoReport: (state, payload) => {
        const index = state.videoReports.findIndex(
            (d) => d.id_video_reports == payload.id_video_reports
        );
        const updatedItems = [
            ...state.videoReports.slice(0, index),
            payload,
            ...state.videoReports.slice(index + 1),
        ];

        state.videoReports = updatedItems;
    },
    deleteVideoReport: (state, payload) => {
        const index = state.videoReports.findIndex(
            (d) => d.id_video_reports == payload.id_video_reports
        );
        state.videoReports.splice(index, 1);
    },
    setHasLoaded(state, payload) {
        state.hasLoaded = payload;
    },
};

const actions = {
    async getVideoReports({ commit }, payload) {
        const requestUrl = Vue.prototype.$url_api + 'v1/video_reports.php';

        await axios
            .get(requestUrl)
            .then((result) => {
                commit('setVideoReports',result.data.data);
                commit('setHasLoaded', true);
                return true;
            })
            .catch((err) => {
                return false;
            });
    },

    async updateVideoReport({ commit }, payload) {
        const requestUrl =
            Vue.prototype.$url_api +
            'v1/video_reports.php?id=' +
            payload.id;

        var bodyFormData = new FormData();
        if (payload.hasOwnProperty('evaluation'))
            bodyFormData.append('evaluation', payload.evaluation);
        if (payload.hasOwnProperty('observations') && payload.observations != null)
            bodyFormData.append('observations', payload.observations);
        if (payload.hasOwnProperty('status'))
            bodyFormData.append('status', payload.status);

        await axios({
            method: 'PUT',
            url: requestUrl,
            data: bodyFormData,
            headers: { 'Content-Type': 'multipart/form-data' },
        })
            .then(function (result) {

                commit('updateVideoReport', result.data.data);
                return true;
            })
            .catch(function(err) {
                return false;
            });
    },

    async deleteVideoReport({ commit }, payload) {
        const requestUrl =
            Vue.prototype.$url_api +
            'v1/video_reports.php?id=' +
            payload.request;

        let params = new URLSearchParams();
        params.append('request', payload.request);

        // Request
        await axios
            .delete(requestUrl, params)
            .then((result) => {
                commit('deleteVideoReport', result.data.data);
                return true;
            })
            .catch((err) => {
                return false;
            });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
