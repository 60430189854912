import Hero from '@/components/Hero/Hero.vue';
import TableGameGroups from '@/components/Scheduling/Tables/TableGameGroups/TableGameGroups.vue';
import NetworkSharkscopeDropdown from '@/components/Scheduling/Menus/NetworkSharkscopeDropdown/NetworkSharkscopeDropdown.vue';
import GameFlagsCombobox from '@/components/Scheduling/Menus/GameFlagsCombobox/GameFlagsCombobox.vue';
import BuyinDropdown from '@/components/Scheduling/Menus/BuyinDropdown/BuyinDropdown.vue';

export default {
    name: 'GameGroups-All',

    components: {
        Hero,
        TableGameGroups,
        NetworkSharkscopeDropdown,
        GameFlagsCombobox,
        BuyinDropdown
    },

    computed: {
        // computed property passed to TableGameGroups
        formFilters: {
            get() {
                return this.form;
            },
            set(newVal) {
                this.form = newVal;
            }
        },
    },

    data() {
        return this.initialState();
    },

    created() {
    },

    methods: {
        initialState() {
            return {
                form: {},
            };
        },
    },

    watch: {
        /**
         * runs every time some nested object has changed
         * sets formFilters computed property that will be passed as prop to TableGames
         */
        form: {
            handler(newVal) {
                this.formFilters = newVal;
            },
            deep: true,
        },
    },
}