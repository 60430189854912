import Vue from 'vue';
import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth';
import RailMixin from '@/mixins/Rail';
import NetworkDropdown from '@/components/Rail/NetworkDropdown/NetworkDropdown.vue';
import UserDropdown from '@/components/Rail/UserDropdown/UserDropdown.vue';
import AlertError from '@/components/Alerts/AlertError/AlertError.vue';
import DialogConfirmation from '@/components/Rail/Dialog/DialogConfirmation/DialogConfirmation.vue';

export default {
    name: 'DialogCreateNickname',

    props: {
        isOpen: {
            type: Boolean,
            required: true,
            default: false
        },
        userId: {
            type: [String,Number],
            required: false,
            default: null
        },
        allowFetch: {
            type: Boolean,
            required: false,
            default: true
        },
    },

    components: {
        NetworkDropdown,
        UserDropdown,
        AlertError,
        DialogConfirmation,
    },

    mixins: [
        AuthMixin,
        RailMixin,
    ],

    data() {
        return this.initialState();
    },

    computed: {
        ...mapGetters({
            error: 'nicknames/error',
            humanError: 'nicknames/humanError',
            userTrackerId: 'auth/userTrackerId',
            users: 'users/usersAllActive',
            selectedUser: 'users/selectedUser',
            nicknamesByUserID: 'nicknames/nicknamesByUserID',
            nicknamesAllMine: 'nicknames/nicknamesAllMine',
            userTeams: 'auth/userTeams',
        }),

        handleMetadataFields: function () {
            // clear states
            this.form.metaDataArray = [];
            this.form.email = null;
            this.form.address_btc = null;
            this.form.address_usdt = null;
            this.form.accountName = null;
            this.form.accountNumber = null;
            this.form.name = null;

            // populates form.metaDataArray with string's
            if (this.form.network && this.form.network.metadata_required) {
                this.form.metaDataArray = this.form.network.metadata_required;
            }

            return this.form.metaDataArray;
        },

        showAlertNicknameAlreadyExists: function () {
            let showAlert = false;
            let nicknames = this.userIsManager
                ? this.nicknamesByUserID
                : this.nicknamesAllMine;
            
            // if network exists
            if (this.form.network) {
                // find network in nicknames list where network is equal to form.network.id and aliasChildOf is NULL
                let nicknameHasNetwork = nicknames.find(e => e.network.id == this.form.network.id && this._.isNull(e.aliasChildOf));

                // check if isn't undefined (it means nickname already exists in this network)
                if (!this._.isUndefined(nicknameHasNetwork)) {
                    showAlert = true;
                    this.form.replace = nicknameHasNetwork.id;
                }
            }
            return showAlert;
        },

        authUserTeamsComputed: function () {
            let userTeamsMapped = [];
            if (this.userIsRailAdmin) {
                userTeamsMapped = [
                    Vue.prototype.$team_division_mtt_id,
                    Vue.prototype.$team_division_cash_id,
                    Vue.prototype.$team_division_spins_id,
                ]
            } else {
                userTeamsMapped = this.userTeams.map(e => e.id);
            }
            return userTeamsMapped;
        }
    },

    watch: {
        isOpen: function () {
            if (this.isOpen) {
                // clear input errors
                this.$store.dispatch('nicknames/clearError');
                if (this.$refs && this.$refs.UserDropdownRef) {
                    this.$refs.UserDropdownRef.reset();
                }

                if (this.$refs && this.$refs.NetworkDropdownRef) {
                    this.$refs.NetworkDropdownRef.resetNetwork();
                }

                if (this.userId) {
                    this.form.user = { id: this.userId };
                }

                if (this.selectedUser) {
                    this.form.user = this.selectedUser;
                }

                // needed to validate showAlertNicknameAlreadyExists computed property
                if (this._.isEmpty(this.nicknamesAllMine) && !this._.isEmpty(this.error) && !this.userIsManager) {
                    this.$store.dispatch('nicknames/getAllMine');
                }
            }
        },
    },
    
    methods: {
        initialState() {
            return {
                submitManagementClick: false, 
                submitting: false,
                showAlertNicknameMessage: null,
                showMainDialog: true,
                form: {
                    replace: null,
                    metaDataArray: [],
                    user: null,
                    network: null,
                    name: null,
                    description: null,
                    management: false,
                    managementTeam: [],
                    email: null,
                    accountName: null,
                    accountNumber: null,
                    address_btc: null,
                    address_usdt: null,
                },
                options: {
                    page: 1,
                    itemsPerPage: 10,
                },
                dialogues: {
                    confirm: false,
                },
                rules: {
                    name: [
                        v => !!v || 'Por favor preencha o campo',
                    ],
                    nicknameDropdown: [
                        (v) => !!v || 'Por favor introduza uma sala'
                    ],
                    email: [
                        (v) => !!v && /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(v) || 'Por favor introduza um email válido'
                    ],
                    accountNumber: [
                        (v) => !!v && /(^[0-9]*$)/.test(v) || 'Por favor introduza um número válido'
                    ],
                    accountName: [
                        (v) => !!v && /^[a-zA-Z\s]*$/.test(v) || 'Por favor introduza um nome válido'
                    ],
                    address: [
                        v => !!v || 'Por favor preencha o campo de endereço da carteira',
                    ],
                    management: [
                        (v) => (!this.submitManagementClick || (v && this.form.managementTeam.length > 0)) || 'Selecione pelo menos uma opção para a equipa de gestão.',
                    ],
                },
            };
        },

        /**
         * if showAlertNicknameAlreadyExists then we show a Dialog to confirm the action, else it runs submit()
         */
        async handleSubmit() {
            this.submitManagementClick = this.form.management;

            if (!this.$refs.formCreateNickname.validate() ) {
                return false;
            }

            // Toggle submit button
            this.toggleSubmitting();

            // fetch nicknames from user id to handle showAlertNicknameAlreadyExists
            await this.fetchNicknamesByUserId();

            // Toggle submit button
            this.toggleSubmitting();

            if (this.showAlertNicknameAlreadyExists) {
                let mainInfo = this.userIsManager 
                    ? 'Este jogador já tem um nickname ativo registado nesta sala.'
                    : 'Já tem um nickname ativo registado nesta sala.'
                
                let additionalInfo = 'Caso prentenda continuar, o saldo do nickname antigo será transferido para este novo nickname. O nickname antigo será desativado.'

                // sets alert message
                this.showAlertNicknameMessage = mainInfo + ' ' +  additionalInfo;

                // opens dialog confirmation
                this.dialogues.confirm = true;

                // hide main dialog
                this.showMainDialog = false;
            } else {
                this.submit();
            }
        },
         
        async submit() {
            if (!this.$refs.formCreateNickname.validate()) {
                return false;
            }

            // Toggle submit button
            this.toggleSubmitting();

            let payload = {
                body: {
                    metadata: {}
                }
            }

            if (this.userIsManager) {
                payload.body.user = this.form.user ? this.form.user.id : null;
            } else {
                payload.body.user = this.userTrackerId;
            }

            if (this.form.network) {
                payload.body.network = this.form.network.id;
            }

            if (this.showAlertNicknameAlreadyExists) {
                payload.body.replace = this.form.replace;
            }

            // if has management
            if (!this._.isNull(this.form.management)) {
                payload.body.management = this.form.management;
            }
            // if has management it means it must have a managementTeam
            if (this.form.management && !this._.isEmpty(this.form.managementTeam)) {
                payload.body.managementTeam = this.form.managementTeam.join();
            } else {
                delete payload.body.managementTeam;
                payload.body.management = false;
            }

            // if has description
            if (!this._.isNull(this.form.description)) {
                payload.body.description = this.form.description;
            }

            // if has name
            if (!this._.isNull(this.form.name)) {
                payload.body.name = this.form.name;
            }

            // if has accountName && accountNumber
            if (!this._.isNull(this.form.accountName) && !this._.isNull(this.form.accountNumber)) {
                payload.body.name = this.form.accountNumber + ' - ' + this.form.accountName;
            }

            // if has email
            if (!this._.isNull(this.form.email)) {
                payload.body.metadata.email = this.form.email;
            }

            // if has address_btc
            if (!this._.isNull(this.form.address_btc)) {
                payload.body.metadata.addr_btc_btc = this.form.address_btc;
            }

            // if has address_usdt
            if (!this._.isNull(this.form.address_usdt)) {
                payload.body.metadata.addr_usdt_erc20 = this.form.address_usdt;
            }

            if (this._.isEmpty(payload.body.metadata)) {
                delete payload.body.metadata;
            }

            let actionToDispatch = this.showAlertNicknameAlreadyExists
                ? 'nicknames/replace'
                : 'nicknames/create';

            let result = await this.$store.dispatch(actionToDispatch, payload);

            // // Re-enable button
            this.toggleSubmitting();

            // On success
            if (result === true) {
                // Show success snackbar
                this.$store.dispatch('UI/showSnackbar', {
                    message: 'Nickname criado com sucesso.',
                    color: 'success'
                });

                // if is manager updates a specific user nicknames
                if (this.userIsManager) {
                    // update TableBalances
                    // set promise to null in order to be able to reload nicknames by user id
                    this.$store.commit('nicknames/setCurrentPayloadUserId', null);

                    // reload nicknames from a specific user
                    let payloadRefreshNicknames = {
                        user: payload.body.user,
                        aliasParameter: '&aliasChildOf=null'
                      }
                    this.$store.dispatch('nicknames/getAllPlayer', payloadRefreshNicknames);
                }
                
                // if userId and userTrackerId are the same
                if (payload.body.user == this.userTrackerId) {
                    // set promise to null in order to be able to reload nicknames all mine
                    this.$store.commit('nicknames/setPromiseAllMine', null);

                    // reload nicknames all mine
                    this.$store.dispatch('nicknames/getAllMine');
                }

                // if update is sucessfully completed , it emits reload event to update tableNicknames
                this.$emit('reload');
                
                // closes dialog
                this.closeDialog();
            } else {
                this.dialogues.confirm = false;

                // show main dialog
                this.showMainDialog = true;
            }
        },

        /**
         * Used to disable Submit button
         */
        toggleSubmitting() {
            this.submitting = !this.submitting;
        },

        closeDialog() {
            this.$emit("close-dialog");
            this.resetForm();
        },

        closeDialogConfirmation() {
            this.dialogues.confirm = false;

            this.showMainDialog = true;
        },

        resetForm() {
            Object.assign(this.$data, this.initialState());
            if (this.$refs.formCreateNickname) {
                this.$refs.formCreateNickname.reset();
            }
            this.$store.dispatch('nicknames/clearError');
        },
        
        async fetchNicknamesByUserId() {
            let user = this.userIsManager ? this.form.user.id : this.userTrackerId;
            let payload = {
                user: user,
                aliasParameter: ''
            }

            await this.$store.dispatch('nicknames/getAllPlayer', payload);
        },

        handleLabelName(network) {
            let output = 'Nickname do jogador';
            if (network && (network.id == Vue.prototype.$rail_muchbetter_eur_network || network.id == Vue.prototype.$rail_muchbetter_usd_network) ) {
                output = 'Número de telefone';
            }
            return output;
        }
    },
};
