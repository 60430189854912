// VALID LOOKUPS - "BOTTOM" / "TOP"
import { mapGetters } from 'vuex';
import EmptyStateNoData from '@/components/EmptyStateNoData';

export default {
    name: 'ListPlayerPnl',

    components: {
        EmptyStateNoData
    },

    props: {
        type: {
            type: String,
            required: false,
            default: 'BOTTOM'
        },
        title: {
            type: String,
            required: false,
            default: 'Titulo'
        }
    },
    
    data() {
        return this.initialState();
    },
    
    computed: {
        ...mapGetters({
            statisticsAdmin: 'railStatistics/dataAdmin',
            statisticsLoading: 'railStatistics/loadingDataAdmin',
            heroDatePickerValue: 'UI/heroDatePickerValue',
            users: 'users/usersAllActive',
            teamFilter: 'teams/teamFilter',
        }),
        
        cardInfo() {
            let output = [];
            let index = this.type == 'BOTTOM' ? 'BOT_USERS_PNL' : 'TOP_USERS_PNL';
            if (! this._.isEmpty(this.statisticsAdmin)) {
                output = this.statisticsAdmin[index];
                
                output = typeof (output) != 'undefined'
                    ? output.data
                    : [];

            }
            return output;
        },

        pnlColor() {
            let color = this.type == 'BOTTOM' ? 'red--text' : 'green--text';
            return color;
        }
    },
    
    watch: {
        heroDatePickerValue: function () {
            this.fetchStatistics();
        },
        teamFilter: function () {
            this.fetchStatistics();
        },
    },

    created() {
        this.fetchUsers();
    },
    
    methods: {
        initialState() {
            return {};
        },

        fetchUsers() {
            // Get users if not already fetched
            if (this._.isEmpty(this.users)) {
                this.$store.dispatch('users/getUsersAllActive');
            }
        },

        /**
         * <router-link> :to prop expects String, Object. Doesn't accept Null values.
         * @param {*} item 
         * @returns String link
         */
        genPlayerLink(item) {
            return item.user
                ? '/players/' + item.user
                : '#';
        },

        /**
         * Retrieves username based on id parameter
         * @param {Number} id 
         * @returns {String} empty or with user name
         */
        handleUsername(id) {
            let output = '';
            let user = this.retrieveUserInfo(id);
            if (user && user.name) {
                output = user.name;
            }
            return output;
        },

        /**
         * Retrieves user DTO based on id parameter
         * @param {Number} id 
         * @returns {Object}
         */
        retrieveUserInfo(id) {
            let output = {};
            if (!this._.isEmpty(this.users)) {
                output = this.users.find(element => element.id == id);
    
                if (!this._.isUndefined(output)) {
                    output = output;
                }
            }
            return output;
        },
        
        async fetchStatistics() {
            let payload = null;
            if (! this._.isEmpty(this.heroDatePickerValue)) {
                payload = {
                    dateBegin: this.heroDatePickerValue[0],
                    dateEnd: this.heroDatePickerValue[1],
                };
            }
            if (this.teamFilter) {
                payload.team = this.teamFilter;
            }
            await this.$store.dispatch('railStatistics/getAdmin', payload);
        },

        /**
         * Retrieves user img cutout based on id parameter
         * @param {Number} id 
         * @returns {String} url with imgCutout
         */
        handleUserImgCutout(id) {
            let output = null;
            let user = this.retrieveUserInfo(id);

            if (user && user.imgCutout) {
                output = user.imgCutout;
            }

            return output;
        },
    }
}
