import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth';
import RailMixin from '@/mixins/Rail';
import DataMixin from '@/mixins/Data';
import AlertError from '@/components/Alerts/AlertError/AlertError.vue';

export default {
	name: 'DialogAdditionalUserInformation',

	components: {
		AlertError,
	},

	props: {
        isOpen: {
            type: Boolean,
            required: true,
            default: false
        },
	},

    mixins: [
		AuthMixin,
		RailMixin,
		DataMixin,
    ],
	
	data() {
		return this.initialState();
	},

	created() {
		this.fetchData();
	},

	computed: {
		...mapGetters({
			selectedUser: 'users/selectedUser',
			usersAll: 'users/usersAllActive',
			userMeta: 'users/userMeta',
		}),

		socialNetworks: function () {
			let output = [
				{
				  id: 1,
				  field: this.facebook,
				  icon: 'mdi-facebook',
				  tooltip: 'Facebook'
				},
				{
				  id: 2,
				  field: this.instagram,
				  icon: 'mdi-instagram',
				  tooltip: 'Instagram'
				},
				{
				  id: 3,
				  field: this.twitter,
				  icon: 'mdi-twitter',
				  tooltip: 'Twitter'
				},
				{
				  id: 4,
				  field: this.youtube,
				  icon: 'mdi-youtube',
				  tooltip: 'Youtube'
				},
				{
				  id: 5,
				  field: this.twitch,
				  icon: 'mdi-twitch',
				  tooltip: 'Twitch'
				},
				{
				  id: 6,
				  field: this.skype,
				  icon: 'mdi-skype',
				  tooltip: 'Skype'
				},
				{
				  id: 7,
				  field: this.discord,
				  icon: 'mdi-message-text',
				  tooltip: 'Discord'
				},
			]
			output = output.filter(e => e.field != null);
			
			return output;
		}
	},
	
	watch: {
		isOpen: function () {
			if (this.isOpen) {
				this.fetchData();
				this.populateFields();
			}
		}
	},

	methods: {
		initialState() {
			return {
				submitting: false,
				buttonName: null,
				buttonColor: null,
				messageAction: null,
				title: null,
				discord: null,
				skype: null,
				facebook: null,
				instagram: null,
				twitter: null,
				youtube: null,
				twitch: null,
			};
		},

		async fetchData() {
			await this.$store.dispatch('users/getUserMeta', this.selectedUser.id);
		},

		populateFields() {
			this.discord = this.validateSocialNetworkFields('discord');
			this.skype = this.validateSocialNetworkFields('skype');
			this.facebook = this.validateSocialNetworkFields('facebook');
			this.instagram = this.validateSocialNetworkFields('instagram');
			this.twitter = this.validateSocialNetworkFields('twitter');
			this.youtube = this.validateSocialNetworkFields('youtube');
			this.twitch = this.validateSocialNetworkFields('twitch');
		},

		closeDialog() {
			this.$emit("close-dialog");
			this.resetForm();
		},

		resetForm() {
			Object.assign(this.$data, this.initialState());
		},

		handleUserImgCutout(user) {
            let output = null;

			if (user && user.imgCutout) {
				output = user.imgCutout;
			}
            return output;
		},
		
		handleUsername(user) {
			let output = '';

			if (user && user.name) {
				output = user.name;
			}

			return output;
		},

		validateSocialNetworkFields(field) {
			return this._.has(this.selectedUser.socialNetworks, field)
				   ? this.selectedUser.socialNetworks[field]
				   : null;
		},

		triggerCopyToClipboard(content) {
			this.copyToClipboard(content);
	  
			// Show snackbar
			this.$store.dispatch('UI/showSnackbar', {
			  message: "Nome '" + content + "' copiado com sucesso.",
			  color: 'success'
			});
		},

		getFullAddress(meta) {
			let output;
			if (meta.address) {
				output = meta.address;
			}
		
			if (meta.postalCode) {
				output = output + ', ' + meta.postalCode
			}
		
			if (meta.city) {
				output = output + ', ' + meta.city
			}
			return output;
		}
	},
};