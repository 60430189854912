var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"formNicknameDropdown"},[_c('v-select',{class:_vm.readonly ? 'block-pointer' : '',attrs:{"items":_vm.dataFiltered,"hint":_vm.hint,"label":_vm.label,"rules":_vm.rulesComputed,"readonly":_vm.readonly,"disabled":_vm.readonly || _vm.isLoading || _vm._.isEmpty(_vm.dataFiltered),"loading":_vm.isLoading,"item-value":"id","persistent-hint":"","return-object":"","outlined":_vm.outlined,"clearable":_vm.clearable,"dense":_vm.dense,"flat":_vm.flat,"solo":_vm.solo,"full-width":_vm.fullWidth,"background-color":_vm.backgroundColor,"placeholder":_vm.placeholder,"hide-details":_vm.hideDetails,"menu-props":{ offsetY: true, openOnClick: _vm.toggleSelect }},on:{"input":function($event){return _vm.emitChange()},"click:clear":_vm.clearValue},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('img',{staticClass:"mr-2",attrs:{"src":require('@/assets/networks/' + item.network.imageURL)}}),_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"body-2"},[_vm._v(" "+_vm._s(item.network.name)+" ")]),_c('span',{staticClass:"caption"},[_vm._v(" "+_vm._s(item.name)+" "+_vm._s(item.network.currency.symbol)+" "+_vm._s(item.balance ? item.balance.value : '')+" ")])])]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-space-between w-100 overflow-hidden"},[_c('div',{staticClass:"d-flex align-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('img',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"src":require('@/assets/networks/' + item.network.imageURL)}},'img',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(item.network.name)+" ")])]),_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"body-2"},[_vm._v(" "+_vm._s(item.network.name)+" ")]),_c('span',{staticClass:"caption text-truncate",staticStyle:{"max-width":"130px"}},[_vm._v(" "+_vm._s(item.name)+" ")])])],1),_c('ButtonCopyToClipboard',{staticClass:"right",attrs:{"name":item.name,"network":item.network.id,"description":item.description,"is-management":item.isManagement},on:{"action":function($event){return _vm.disableToggleSelect()}}})],1)]}}]),model:{value:(_vm.computedValue),callback:function ($$v) {_vm.computedValue=$$v},expression:"computedValue"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }