import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import StoreMixin from '@/mixins/Store.vue';
import DataMixin from '@/mixins/Data.vue';

Vue.use(Vuex);
Vue.use(StoreMixin);
Vue.use(DataMixin);

const state = {
    notes: [],
    notesTotal: null,
    selectedNote: null,
    error: null,
    humanError: null,
    loading: true,
    selectedNoteLoading: true,
    lastPayloadNotes: {},
    userNotes: [],
    userNotesLoading: true,
};

const getters = {
    notes: state => state.notes,
    notesTotal: state => state.notesTotal,
    selectedNote: state => state.selectedNote,
    error: state => state.error,
    humanError: state => state.humanError,
    loading: state => state.loading,
    selectedNoteLoading: state => state.selectedNoteLoading,
    userNotes: state => state.userNotes,
    userNotesLoading: state => state.userNotesLoading,
};

const mutations = {
    setNotes: (state, payload) => { state.notes = payload; },
    setNotesTotal: (state, payload) => { state.notesTotal = payload; },
    setSelectedNote: (state, payload) => { state.selectedNote = payload; },
    setError: (state, payload) => { state.error = payload; },
    setHumanError: (state, payload) => { state.humanError = payload; },
    setLoading: (state, payload) => { state.loading = payload; },
    setSelectedNoteLoading: (state, payload) => { state.selectedNoteLoading = payload; },
    addNote: (state, payload) => {
        if (!state.notes || !state.notes.length)
            state.notes = [];
        
        state.notes.unshift(payload);
    },
    updateNote: (state, payload) => {
        const index = state.notes.findIndex(d => d.id == payload.id);
        
        const updatedItems = [
            ...state.notes.slice(0, index),
            payload,
            ...state.notes.slice(index + 1)
        ];

        state.notes = updatedItems;
    },
    deleteNote: (state, id) => {
        const index = state.notes.findIndex(n => n.id == id);
        state.notes.splice(index, 1);
    },
    setLastPayloadNotes: (state, payload) => { state.lastPayloadNotes = payload; },
    setUserNotes: (state, payload) => { state.userNotes = payload; },
    setUserNotesLoading: (state, payload) => { state.userNotesLoading = payload; },
};

const actions = {

    clearError({ commit }) {
        commit('setError', null);
        commit('setHumanError', null);
    },

    async get({ commit, dispatch, state }, payload) {   
        // Build URL with parameters
        let areObjectsEqual = DataMixin.methods.areObjectsEqual(state.lastPayloadNotes, payload.params);
            
        if (areObjectsEqual) return false;
        // save last payload
        commit('setLastPayloadNotes', payload.params);

        //clear state
        commit('setLoading', true);
        commit('setNotes', []);
        
        // Configure request
        let request_url = Vue.prototype.$url_api_live + 'notes';

        // Configure URL Parameters
        let parameters = payload && payload.params
            ? payload.params
            : StoreMixin.methods.getParametersDefault()
        
        // Build URL with parameters
        request_url = StoreMixin.methods.generateQueryParamsUrl(request_url, parameters);

        // Execute request & return
        let output = false;

        return axios.get(request_url)
        .then(function (response) {
            output = response.status == 200;
            if (output) {
                commit('setNotes', response.data);
                commit('setNotesTotal', response.data.length);
            } else {
                commit('setError', response.data.message);
                commit('setHumanError', response.data.human_message);
            }
        })
        .catch(function (error) {
            commit('setError', error.response ? error.response.data.message : error);
            commit('setHumanError', error.response ? error.response.data.human_message : error);
            output = false;
        })
        .then(function () {
            commit('setLoading', false);
            return output;
        });
    },

    async getUserNotes({ commit, dispatch, state }, payload) {
        //clear state
        commit('setUserNotesLoading', true);

        // Configure URL Parameters
        let parameters = payload && payload.params
            ? payload.params
            : StoreMixin.methods.getParametersDefault();
        // Configure request
        let request_url = Vue.prototype.$url_api_live + 'me/notes';
        
        // Build URL with parameters
        request_url = StoreMixin.methods.generateQueryParamsUrl(request_url, parameters);

        // Execute request & return
        let output = false;

        return axios.get(request_url)
        .then(function (response) {
            output = response.status == 200;

            if (output) {
                commit('setUserNotes', response.data);
            } else {
                commit('setError', response.message);
                commit('setHumanError', response.message);
            }
        })
        .catch(function (error) {
            commit('setError', error.message ? error.message : error);
            commit('setHumanError', error.message ? error.message : error);
            output = false;
        })
        .then(function () {
            commit('setUserNotesLoading', false);
            return output;
        });
    },

    async create({ commit, dispatch }, payload) {
        dispatch('clearError');

        let data = JSON.stringify(payload.body);

        const request_url = Vue.prototype.$url_api_live + 'notes';
        let config = {
            method: 'POST',
            url: request_url,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        }
        let output = false;

        return axios(config)
        .then(function (response) {
            output = response.status == 201;
            if (output) {
                commit('addNote', response.data);
                commit('setSelectedNote', response.data);
            } else {
                commit('setError', response.data.message);
                commit('setHumanError', response.data.human_message);
            }
        })
        .catch(function (error) {
            commit('setError', error.message ? error.message : error);
            commit('setHumanError', error.message ? error.message : error);
            output = false;
        })
        .then(function () {
            return output;
        });
    },

    async delete({ commit, dispatch }, payload) {
        dispatch('clearError');

        const request_url = Vue.prototype.$url_api_live + 'notes/' + payload.id;
        let config = {
            method: 'DELETE',
            url: request_url,
            headers: {
                'Content-Type': 'application/json'
            },
        }
        let output = false;
        return axios(config)
        .then(function (response) {
            // 204 response status represents success
            output = response.status == 204;
            if (output) {
                commit('setLastPayloadNotes', {});
            } else {
                commit('setError', response.data.message);
                commit('setHumanError', response.data.human_message);
            }
        })
        .catch(function (error) {
            commit('setError', error.response ? error.response.data.message : error);
            commit('setHumanError', error.response ? error.response.data.human_message : error);
            output = false;
        })
        .then(function () {
            return output;
        });
    },

    async update({ commit, dispatch }, payload) {
        // clear state
        dispatch('clearError');

        // Handle payload data
        let data = JSON.stringify(payload.body);

        // Configure request
        const request_url = Vue.prototype.$url_api_live + 'notes/' + payload.body.id;
        let config = {
            method: 'PUT',
            url: request_url,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        }
        // Execute request & return
        let output = false;

        return axios(config)
        .then(function (response) {
            output = response.status == 200;
            if (output) {
                commit('updateNote', response.data);
                commit('setSelectedNote', response.data);
            } else {
                commit('setError', response.data.message);
                commit('setHumanError', response.data.human_message);
            }
        })
        .catch(function (error) {
            commit('setError', error.message ? error.message : error);
            commit('setHumanError', error.message ? error.message : error);
            output = false;
        })
        .then(function () {
            return output;
        });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};