import CardTransferSwapStage1 from './Stage1/CardTransferSwap.vue'
import CardTransferSwapStage2 from './Stage2/CardTransferSwap.vue'
import { mapGetters } from 'vuex';

export default {
  name: 'CardTransferSwap',
  
  components: {
    CardTransferSwapStage1,
    CardTransferSwapStage2,
  },
  
  computed: {
    ...mapGetters({
      selectedRequest: 'railRequests/selectedRequest',
    }),
    
    componentToShow() {
      let pageToShow = this.selectedRequest
                        ? 'CardTransferSwapStage2'
                        : 'CardTransferSwapStage1';
      return pageToShow;
    }
  },
}