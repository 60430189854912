import { mapGetters } from "vuex";
import AuthMixin from '@/mixins/Auth.vue';
import DataMixin from '@/mixins/Data.vue';
import DialogConfirmation from '@/components/Rail/Dialog/DialogConfirmation/DialogConfirmation.vue';
import DialogAddEditStats from '@/components/Dialog/DialogAddEditStats/DialogAddEditStats.vue';
import EmptyStateNoData from '@/components/EmptyStateNoData';
import DragAndDropInputFile from '@/components/DragAndDropInputFile/DragAndDropInputFile.vue';
import AlertError from '@/components/Alerts/AlertError/AlertError.vue';
import DialogUploadResume from '@/components/Dialog/DialogUploadResume/DialogUploadResume.vue';
import DatePicker from '@/components/DatePicker/DatePicker.vue';
import UserCard from '@/components/UserCard/UserCard.vue';

export default {
    name: 'TableStats',

    props:{
        filters: { type: Object, required: false, default: () => { } },
        userId: {type: Number, required: false, default: null }
    },

    mixins: [
        AuthMixin,
        DataMixin,
    ],

    components: {
        DialogConfirmation,
        DialogAddEditStats,
        EmptyStateNoData,
        DragAndDropInputFile,
        AlertError,
        DialogUploadResume,
        DatePicker,
        UserCard,
    },

    computed: {
        ...mapGetters({
            stats: 'stats/stats',
            loading: 'stats/loading',
            statsTotal: 'stats/statsTotal',
            teamFilter: 'teams/teamFilter',
            error: 'stats/error',
            humanError: 'stats/humanError',
            userTrackerId: 'auth/userTrackerId',
            heroDatePickerValue: 'UI/heroDatePickerValue',
            statsBatchLoading: 'stats/statsBatchLoading',
            statsBatch: 'stats/statsBatch',
        }),
        

        optionsComputed: {
            get: function () {
                
              return this.options;
            },
            set: function (newValue) {
                if (!this._.isEqual(this.options, newValue)) {
                    this.options = newValue;
                }
            }
        },

        filteredUsers() {
        if (this.filters.groups && this.filters.stats) {
            return this.stats.filter(user => user.group.id === this.filters.groups);
        } else {
            return this.stats;
        }
        },

        tableHeaders() {
            if(this.userIsRailManager){
                return [
                    { text: 'Data', value: 'date', sortable: true},
                    { text: 'Jogador', value: 'user' , sortable: true},
                    { text: 'Nível', value: 'user_target.group', sortable: false },
                    { text: 'Valor Stats', value: 'value', sortable: true },
                    { text: 'Estado', value: 'status', sortable: false },
                    { text: 'Ação', value: 'action', sortable: false },
                ]
            }else{
                return [
                    { text: 'Data', value: 'date', sortable: true},
                    { text: 'Nível', value: 'user_target.group', sortable: false },
                    { text: 'Valor Stats', value: 'value', sortable: true },
                    { text: 'Estado', value: 'status', sortable: false },
                ]
            }
        },

        /**
         * form.filename passed through prop to DragAndDropInputFile
         */
		computedFilename: {
			get: function () {
				return this.form.filename;
			},
            set: function (newValue) {
                this.clearError();
                this.form.filename = newValue;
			}
		},
    },

    created() {
        this.init();      
    },

    watch:{
        $route: {
            handler(newRoute, oldRoute) {
                if(newRoute !== oldRoute){
                    this.updateTable(this.$route.query);
                }
            },
            deep: true
        },

        options: {
            handler() {
              this.fetchData();
            },
            deep: true,
            immediate: true,
        },
        heroDatePickerValue: function () {
            this.fetchData();
        },
        userId: function () {
            this.fetchData();
        },
    },

    methods:{
        initialState(){
            return {
                errorUpload: false,
                form: {
                    filename: [],
                    date: this.$moment().format('YYYY-MM-DD'),
                },
                options: {
                    page: 1,
                    itemsPerPage: 10,
                    sortBy: [],
                    sortDesc: [],
                    groupBy: [],
                    groupDesc: [],
                    mustSort: true,
                    multiSort: false,
                },
                dialogues: {
                    addStat: false,
                    removeStat: false,
                    editStat: false,
                    uploadResume: false,
                },
                submitDelete: false,
                idToDelete: null,
                dataTable: {
                    headers:[
                        { text: 'Data', value: 'date', sortable: true},
                        { text: 'Jogador', value: 'user' , sortable: true},
                        { text: 'Nível', value: 'user_target.group', sortable: false },
                        { text: 'Valor Stats', value: 'value', sortable: true },
                        { text: 'Estado', value: 'status', sortable: false },
                    ],
                    footerProps: {
                        'items-per-page-options': this.$itemsPerPageOptions,
                    },
                    search: null,
                    itemsPerPage: 10,
                },
                filteredData: this.stats,
                showUploadFile: false,
                
            }
        },

        toggleDialog(dialog) {
            this.dialogues[dialog] = ! this.dialogues[dialog];
        },

        closeDialogStat() {
            this.$store.commit('stats/setSelectedStat', null);
            if(this.dialogues.addStat){
            this.toggleDialog('addStat');
            }else if(this.dialogues.editStat){
                this.toggleDialog('editStat');
            }
        },

        openDialogRemoveConfirmation(id) {
            this.dialogues.removeStat = true;
            this.idToDelete = id;
        },

        openDialogAddEditStats(dialog, item) {
            this.setActiveItem(item);
            this.toggleDialog(dialog);
        },

        closeDialogRemoveConfirmation() {
            this.dialogues.removeStat = false;
        },

        openDialogUploadResume() {
            this.dialogues.uploadResume = true;
        },

        closeDialogUploadResume() {
            this.dialogues.uploadResume = false;
        },

        setActiveItem(item) {
            if (item) {
              this.$store.commit(
                'stats/setSelectedStat',
                item
              );
            }
        },

        checkIsOpen(){
            return this.dialogues.addStat || this.dialogues.editStat;
        },

        checkAction(){
            return this.dialogues.addStat ? 'create' : 'update';
        },

        async deleteStats(){
            this.submitDelete = true;

            let payload = {
                id:this.idToDelete,
            }

            let result = await this.$store.dispatch('stats/remove', payload);
            this.dialogues.removeStat = false;
      
            if (result === true) {

                //if delete a stat in player single view, it's necessary to refresh groupChanges from user
                if (this.$route.name == 'Stats do Jogador') {
                    this.refreshGroupChanges();
                }

                this.$store.dispatch('UI/showSnackbar', {
                    message: 'Stat removido com sucesso.',
                    color: 'success',
                });

            this.submitDelete = false;
            this.fetchData();
            this.fetchStatsStatistics();
            this.closeDialogStat();
            }
        },

        reloadData() {
            this.fetchData();

            if (this.$route.name == 'Stats do Jogador') {
                //if delete a stat in player single view, it's necessary to refresh groupChanges from user
                this.refreshGroupChanges();
            }
        },
        
        refreshGroupChanges() {

            //  reset payload in order to be able to refresh group changes again
            this.$store.commit('groups/setLastPayloadGroupChangesByUserId', {});

            let payload = {
                id: this.$route.params.id,
                params: {
                    details: true,
                }
            }

            this.$store.dispatch('groups/getGroupChangesByUserId', payload);
        },

        async init() {
            await this.fetchData();
        },

        async updateTable(newFilters) {
            await this.fetchData(newFilters);
        },

        async fetchData(){
            let payload = {
                params: {
                  page: this.options.page,
                  itemsPerPage: this.options.itemsPerPage,
                }
            };

            if(this.userId){
                payload.params.user = this.userId;
            }

            // add filter by team
            if (this.filters && !this._.isNull(this.filters.stats) && !this._.isUndefined(this.filters.stats)) {
                payload.params.status = this.filters.stats;
            }
            // add filter by group
            if (this.filters && !this._.isNull(this.filters.groups) && !this._.isUndefined(this.filters.groups)) {
                payload.params.group = this.filters.groups.id;
            }

            if (this.filters && !this._.isNull(this.filters.users) && !this._.isUndefined(this.filters.users)) {
                payload.params.user = this.filters.users.id;
            }

            if (!this._.isEmpty(this.heroDatePickerValue)) {
                payload.params.dateBegin = this.heroDatePickerValue[0];
                payload.params.dateEnd = this.heroDatePickerValue[1];
            }

            Object.assign(payload.params, this.sortDescHandler(this.options.sortDesc));
            // Add sortBy parameter to payload
            this.addParameterToPayload(payload, 'sortBy[]', this.options.sortBy);

            await this.$store.dispatch('stats/getStats', payload); 
        },

        imageCutout(item) {
            let placeholder = require('@/assets/images/players/player-example.png');
            return item && item.user_target.imgCutout ?  item.user_target.imgCutout : placeholder;
        },

        toggleUploadFile() {
            this.showUploadFile = !this.showUploadFile;
        },

        getDataFromDragAndDropInputFile(payload) {
            this.computedFilename = payload;
        },

        async submitBatchImport() {

            // validar aqui maxSize e o type file
            if (this._.isEmpty(this.form.filename) || this.form.filename[0].size > 9097152 || ! ['text/plain', 'text/csv'].includes(this.form.filename[0].type)) {
                return false;
            }

            let payload = new FormData();
            
            payload.append('file', this.form.filename[0]);
            payload.append('date', this.form.date);
              
            let result = await this.$store.dispatch('stats/createBatchImport', payload);
        
            if (result === true) {
                this.$store.dispatch('UI/showSnackbar', {
                    message: 'Ficheiro importado com sucesso!',
                    color: 'success'
                });
                  
                // reset last stats payload in order to refresh TableStats 
                this.$store.commit('stats/setLastPayload', {});
                this.fetchData();
                this.openDialogUploadResume();
                this.fetchStatsStatistics();

                // reset state
                this.computedFilename = [];
                this.errorUpload = false;
            }
        },

        clearError() {
            this.$store.dispatch('stats/clearError');
        },

        async fetchStatsStatistics() {
            let payload = {}
      
            // Add date params
            if (!this._.isEmpty(this.heroDatePickerValue)) {
              payload.dateBegin = this.heroDatePickerValue[0];
              payload.dateEnd = this.heroDatePickerValue[1];
            }
              
            await this.$store.dispatch('railStatistics/getStatsStatistics', payload);
          },

    },

    data() {
        return this.initialState();
    },
    
}