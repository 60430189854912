import { mapGetters } from 'vuex';
import EmptyStateNoData from '@/components/EmptyStateNoData';

export default {
    name: 'DonutChartProfitTeam',

    components: {
        EmptyStateNoData,
    },

	props: {
		title: {
			type: String,
			required: false,
			default: '',
		},
		subtitle: {
			type: String,
			required: false,
			default: '',
        },
        data: {
			type: Array,
			required: false,
			default: () => [5, 20, 25, 50],
		},
		labels: {
			type: Array,
			required: false,
			default: () => ['Galvanize', 'Team Cash', 'Team MTT', 'Outras'],
        },
        loading: {
            type: Boolean,
            required: false,
            default: false
        },
    },
    
    computed: {
        ...mapGetters({
            themeDarkMode: 'UI/themeDarkMode',
        }),

        series: function () {
            return this.data;
        },

        options: function () {
            return {
                chart: {
                    type: 'donut',
                    zoom: {
                        enabled: false
                    },
                    toolbar: {
                        show: false
                    },
                    foreColor: '#f6f7f8',
                    background: this.themeDarkMode ? 'dark' : 'light',
                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                      chart: {
                        width: 200
                      },
                      legend: {
                        position: 'bottom'
                      }
                    }
                  }],
                labels: this.labels,
                dataLabels: {
                    enabled: true
                },
                legend: {
                    position: 'bottom',
                    labels: {
                        colors: this.themeDarkMode ? '#FFFFFF' : '#000000',
                    },
                },
                tooltip: {
                    theme: true,
                },
                theme: {
                    mode: this.themeDarkMode ? 'dark' : 'light',
                    palette: 'palette1'
                }
            }
        },
    },

    watch: {
        themeDarkMode: function () {
            if (this.$refs.DonutChart) {
                this.$refs.DonutChart.refresh();
            }
        }
    },

    data() {
        return this.initialState();
    },

    methods: {
        initialState() {
            return {}
        },
    },
}
