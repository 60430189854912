import { mapGetters } from 'vuex';
import DialogScoredGameGroupDetails from '@/components/Scheduling/Dialogs/DialogScoredGameGroupDetails/DialogScoredGameGroupDetails.vue';
import EmptyStateNoData from '@/components/EmptyStateNoData';

export default {
    name: 'TableScoredGameGroups',

    components: {
        DialogScoredGameGroupDetails,
        EmptyStateNoData,
    },

    computed: {
        ...mapGetters({
            gameGroups: 'schedulingGameGroups/data',
            totalData: 'schedulingGameGroups/total',
        }),
    },

    props: {
        modelId: { type: Number, required: true },
        // a model is ready when it's game groups are evaluated for the most recent set of factors
        modelReady: { type: Boolean, required: false, default: true }
    },

    data() {
        return this.initialState();
    },

    created() {
        this.init()
    },

    methods: {
        initialState() {
            return {
                loading: true,
                dialogScoredGameGroupDetails: false,
                selectedGameGroup: {},
                headers: [
                    { text: 'Nome', value: 'gameGroup.name', sortable: false },
                    { text: 'Sala', value: 'gameGroup.sharkscopeNetwork.name', sortable: false },
                    { text: 'Buyin', value: 'full_buyin', sortable: false },
                    { text: 'Velocidade', value: 'gameGroup.speed.name', sortable: false },
                    { text: 'Pontuação', value: 'finalScore', sortable: false },
                    { text: 'Ação', value: 'action', sortable: false },
                ],
                options: {
                    page: 1,
                    itemsPerPage: 10,
                    sortBy: [],
                    sortDesc: [],
                    groupBy: [],
                    groupDesc: [],
                    mustSort: true,
                    multiSort: true
                },
            };
        },

        init() {
            this.fetchData();
        },

        fetchData() {
            if (this.modelReady) {
                this.getSchedulingGameGroups(this.modelId);
            }
        },

        toggleScoredGameGroupDetails(gameGroupId) {
            if (gameGroupId) {
                this.selectedGameGroup = this.gameGroups.filter(gg => gg.gameGroup.id == gameGroupId)[0];
            }
            this.dialogScoredGameGroupDetails = !this.dialogScoredGameGroupDetails;
        },

        async getSchedulingGameGroups(id) {
            let payload = {
                params: {
                    page: this.options.page,
                    itemsPerPage: this.options.itemsPerPage,
                },
                id: id
            }
            await this.$store.dispatch('schedulingGameGroups/getForModel', payload);
            this.loading = false;
        },
    },
    watch: {
        options: {
            handler() {
                this.fetchData();
            },
            deep: true,
        },
    }
}