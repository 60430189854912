import AuthMixin from '@/mixins/Auth.vue';

export default {
    name: "NavBarDesktop",

    props: {
        playersBtnMenu: { type: Array, required: false, default: [] },
        trackerBtnMenu: { type: Array, required: false, default: [] },
        gamesBtnMenu: { type: Array, required: false, default: [] },
        videosBtnMenu: { type: Array, required: false, default: [] },
        statisticsBtnMenu: { type: Array, required: false, default: [] },
        scheduleBtnMenu: { type: Array, required: false, default: () => [] },
    },

    components: {},

    mixins: [
        AuthMixin
    ],

    data() {
        return this.initialState();
    },

    methods: {
        initialState() {
            return {
                menu: false,
                sheet: false,
                sheetFab: false,
                navBtn: {
                    rail: false,
                    players: false,
                    tracker: false,
                    games: false,
                    videos: false,
                    statistics: false,
                    schedulle: false,

                }
            }
        },
        
        /**
         * Change selected btn state
         * @param {String} e 
         */
        selectBtn(e) {
            // reset every state
            this.navBtn = {
                rail: false,
                players: false,
                tracker: false,
                games: false,
                videos: false,
                statistics: false,
                schedulle: false,
            }

            switch (e) {
                case 'rail':
                    this.navBtn.rail = true;
                    break;
                case 'players':
                    this.navBtn.players = true;
                    break;
                case 'tracker':
                    this.navBtn.tracker = true;
                    break;
                case 'games':
                    this.navBtn.games = true;
                    break;
                case 'videos':
                    this.navBtn.videos = true;
                    break;
                case 'statistics':
                    this.navBtn.statistics = true;
                    break;
                case 'schedulle':
                    this.navBtn.schedulle = true;
                    break;
                default:
                    break;
            }
        }
    }
};