var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{staticClass:"ma-0 pa-0"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"hide-default-footer":"","disable-sort":""},scopedSlots:_vm._u([{key:"item.from",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-space-between flex-nowrap"},[(item.source)?_c('div',{staticClass:"d-flex align-center flex-nowrap"},[_c('div',{staticClass:"d-flex align-center text-left"},[_c('NicknameCard',{attrs:{"id":item.nickname ? item.nickname.id : item.source.id,"image-u-r-l":item.source.network.imageURL,"network-name":item.nickname ? item.nickname.network.name : item.source.network.name,"name":item.nickname ? item.nickname.name : item.source.name,"network-id":item && item.source && item.source.network ? item.source.id : null}})],1)]):_c('v-chip',{attrs:{"color":"error"}},[_vm._v(" Sem nickname ")]),_c('v-icon',{staticClass:"d-none d-lg-flex"},[_vm._v(" mdi-arrow-right ")])],1)]}},{key:"item.to",fn:function(ref){
var item = ref.item;
return [(item.target)?_c('td',{staticClass:"d-flex align-center"},[_c('NicknameCard',{attrs:{"id":item.nickname ? item.nickname.id : item.target.id,"image-u-r-l":item.target.network.imageURL,"network-name":item.nickname ? item.nickname.network.name : item.target.network.name,"name":item.nickname ? item.nickname.name : item.target.name,"network-id":item && item.target && item.target.network ? item.target.id : null}})],1):_c('v-chip',{attrs:{"color":"error"}},[_vm._v(" Sem nickname ")])]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-space-between"},[_c('v-chip',{staticClass:"font-weight-bold"},[_c('span',{staticClass:"mr-2"},[_vm._v(_vm._s(item.source && item.source.network ? item.source.network.currency.symbol : ''))]),_c('currency-input',{staticClass:"text-end",style:({ color: _vm.valueColorFinal(), width: '90px' }),attrs:{"value":item.amount.value,"currency":null,"precision":_vm.handleDecimalNumbers(item.source),"allow-negative":"","disabled":""}})],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(!item.isPending)?_c('v-btn',_vm._g(_vm._b({staticClass:"d-none d-sm-flex",attrs:{"icon":"","large":"","color":"green"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-check-circle-outline")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Confirmado")])])],1)]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.isPending)?_c('v-chip',{staticClass:"black--text",attrs:{"color":"accent"}},[_c('b',[_vm._v("Pendente")])]):_c('v-chip',{attrs:{"dark":"","color":"green"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-check-circle ")]),_c('b',[_vm._v("Confirmado")])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }