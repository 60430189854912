import Vue from 'vue';
import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth.vue';
import RailMixin from '@/mixins/Rail.vue';
import DataMixin from '@/mixins/Data.vue';
import EmptyStateNoData from '@/components/EmptyStateNoData';

export default {
  name: 'TableSwapGroupMembers',

  components: {
    EmptyStateNoData,
  },

  mixins: [
    AuthMixin,
    RailMixin,
    DataMixin,
  ],

  data() {
    return this.initialState();
  },

  computed: {
    ...mapGetters({
      selectedSwapGroup: 'railSwaps/selectedSwapGroup',
      swapGroupStatistics: 'railSwaps/swapGroupStatistics',
      swapGroupStatisticsLoading: 'railSwaps/swapGroupStatisticsLoading',
      users: 'users/usersAllActive',
      themeDarkMode: 'UI/themeDarkMode',
      teamFilter: 'teams/teamFilter',
    }),

    playersToPay: function () {
      let output = [];
      if (!this.swapGroupStatisticsLoading && this.swapGroupStatistics) {
          output = this.swapGroupStatistics.paid;
      }
      return output;
  },

    optionsComputed: {
      get: function () {
        return this.options;
      },
      set: function (newValue) {
        if (!this._.isEqual(this.options, newValue)) {
          this.options = newValue;
        }
      }
    },

  },

  methods: {
    initialState() {
      return {
        tableTitle: 'Montantes por jogador',
        swapGroup: null,
        dataTable: {
          search: null,
          headers: [
            { text: 'Jogador', value: 'user', sortable: false },
            { text: 'Recebeu da pool', value: 'receivedAmount.value' },
            { text: 'Enviou para a pool', value: 'sentAmount.value', sortable: true },
            { text: 'Diferença', value: 'transferAmount.value' },
          ],
          footerProps: {
            'items-per-page-options': [10, 25, 50, 100, 200, -1],
          },
        },
        dialogAction: null,
      };
    },

    handleUsername(id) {
      let output = null;
      if (!this._.isEmpty(this.users)) {
        output = this.users.find(element => element.id == id);

        if (!this._.isUndefined(output)) {
          output = output.name;
        }
      }
      return output;
    },
  }
};
