import RailMixin from '@/mixins/Rail.vue';
import ButtonCopyToClipboard from '@/components/Rail/ButtonCopyToClipboard/ButtonCopyToClipboard.vue';
import NicknameCard from '@/components/NicknameCard/NicknameCard.vue';

export default {
    name: 'RowTransfer',

    components: {
        ButtonCopyToClipboard,
        NicknameCard,
    },

    mixins: [
        RailMixin,
    ],
    
    props: {
        items: { type: Array, required: false, default: () => [] },
        headerFrom: { type: String, required: false, default: 'De:' },
        headerTo: { type: String, required: false, default: 'Para:' },
    },

    data() {
        return this.initialState();
    },

    methods: {
        initialState() {
            return {
                headers: [
                    { text: this.headerFrom, value: 'from' },
                    { text: this.headerTo, value: 'to' },
                    { text: 'Montante:', value: 'total' },
                    { text: 'Estado:', value: 'status', align: '  d-none' },
                ],          
            }
        },

        getNetworkImage(item) {
            if (item) {
                return require('@/assets/networks/' + item);
            }
        },
    }
};
