import Hero from '@/components/Hero/Hero.vue';
import TableFactors from '@/components/Scheduling/Tables/TableFactors/TableFactors.vue';

export default {
    name: 'Factors-All',

    components: {
        Hero,
        TableFactors
    },
}