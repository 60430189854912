import Vue from 'vue';
import { mapGetters } from 'vuex';
import UserMixin from '@/mixins/User.vue';
import AuthMixin from '@/mixins/Auth.vue';
import DataMixin from '@/mixins/Data.vue';
import Snackbar from '@/components/Snackbar';
import Hero from '@/components/Hero/Hero.vue';
import UserInformation from '@/components/UserInformation/UserInformation.vue';
import UserTeams from '@/components/UserTeams/UserTeams.vue';
import UserContract from '@/components/UserContract/UserContract.vue';
import TableBalance from '@/components/Rail/TableBalance/TableBalance.vue';
import UserEventList from '@/components/UserEventList/UserEventList.vue';
import LineChartCumulativePNL from '@/components/Charts/LineChartCumulativePNL/LineChartCumulativePNL.vue';
import TableSessions from '@/components/Rail/TableSessions/TableSessions.vue';
import CardMainStatistics from '@/components/Rail/Statistics/CardMainStatistics/CardMainStatistics.vue';
import HeatmapYearly from '@/components/Charts/HeatmapYearly/HeatmapYearly.vue';
import TableVideoAccess from '@/components/Tables/TableVideoAccess/TableVideoAccess.vue';
import HistoricVideoRequest from '@/components/HistoricVideoRequest/HistoricVideoRequest.vue';
import CoursesCompleted from '@/components/CoursesCompleted/CoursesCompleted.vue';
import CardStatistics from '@/components/Rail/Statistics/CardStatistics/CardStatistics.vue';
import CardStatisticsVertical from '@/components/Rail/Statistics/CardStatisticsVertical/CardStatisticsVertical.vue';
import UserNotes from '@/components/UserNotes/UserNotes.vue';
import DatePickerRange from '@/components/DatePickerRange/DatePickerRange.vue';
import TableRequests from '@/components/Rail/TableRequests/TableRequests.vue';
import Loading from '@/views/Loading.vue';
import CardPlayerGoals from '@/components/Rail/CardPlayerGoals/CardPlayerGoals.vue';
import Alert from '@/components/Rail/Alert/Alert.vue';
import HeatmapMonthly from '@/components/Charts/HeatmapMonthly/HeatmapMonthly.vue';
import DialogConfirmation from '@/components/Rail/Dialog/DialogConfirmation/DialogConfirmation.vue';
import DialogAccessFeedback from '@/components/Rail/Dialog/DialogAccessFeedback/DialogAccessFeedback.vue';
import TableStats from "../../components/Tables/TableStats/TableStats.vue";
import CardAvgBuyIn from '@/components/CardAvgBuyIn/CardAvgBuyIn.vue';
import TableAudits from '@/components/Tables/TableAudits/TableAudits.vue';
import LevelProgressDetail from '@/components/LevelProgressDetail/LevelProgressDetail.vue';
import AlertLevelChangeMode from '../../components/Alerts/AlertLevelChangeMode/AlertLevelChangeMode.vue';
import TableLevelChange from '@/components/Rail/TableLevelChange/TableLevelChange.vue';
import TableGamesPlayed from '@/components/Tables/TableGamesPlayed/TableGamesPlayed.vue';
import PieChartVolumePerNetwork from '@/components/Charts/PieChartVolumePerNetwork/PieChartVolumePerNetwork.vue';
import BarChartVolumePerNetwork from '@/components/Charts/BarChartVolumePerNetwork/BarChartVolumePerNetwork.vue';
import NetworkSharkscopeDropdown from '@/components/Scheduling/Menus/NetworkSharkscopeDropdown/NetworkSharkscopeDropdown.vue';
import GameFlagsCombobox from '@/components/Scheduling/Menus/GameFlagsCombobox/GameFlagsCombobox.vue';

export default {
    name: 'Players-Single',

    components: {
        Loading,
        UserInformation,
        UserTeams,
        UserContract,
        TableBalance,
        UserEventList,
        LineChartCumulativePNL,
        TableSessions,
        CardMainStatistics,
        HeatmapYearly,
        TableVideoAccess,
        HistoricVideoRequest,
        CoursesCompleted,
        Snackbar,
        Hero,
        CardStatistics,
        CardStatisticsVertical,
        UserNotes,
        DatePickerRange,
        TableRequests,
        CardPlayerGoals,
        Alert,
        HeatmapMonthly,
        DialogConfirmation,
        DialogAccessFeedback,
        TableStats,
        CardAvgBuyIn,
        TableAudits,
        LevelProgressDetail,
        AlertLevelChangeMode,
        TableLevelChange,
        TableGamesPlayed,
        PieChartVolumePerNetwork,
        BarChartVolumePerNetwork,
        NetworkSharkscopeDropdown,
        GameFlagsCombobox,
    },

    mixins: [
        UserMixin,
        AuthMixin,
        DataMixin,
    ],

    data() {
        return this.initialState();
    },

    created() {
        // Load necessary page data
        this.fetchData();
        // Check if any of the requests returned forbidden-type errors
        this.validateAccessPermission();

        // Handle tabs
        this.getCurrentTabFromURL();

        this.$root.$on('changeTab', ({ playerId, tabId }) => {
            window.scrollTo(0, 0);
            this.tab.current = tabId;
        });
    },

    computed: {
        ...mapGetters({
            userDisplayName: 'auth/userDisplayName',
            userGroup: 'auth/userGroup',
            userAvatar: 'auth/userAvatar',
            userTrackerId: 'auth/userTrackerId',
            userRolesLoading: 'auth/userRolesLoading',
            selectedUser: 'users/selectedUser',
            selectedUserStatus: 'users/selectedUserStatus',
            userLoading: 'users/userLoading',
            userHistoryLevelLoading: 'users/userHistoryLevelLoading',
            userHistoryLevel: 'users/userHistoryLevel',
            userNotes: 'users/userNotes',
            userTeams: 'users/userTeams',
            userTeamsLoading: 'users/userTeamsLoading',
            usersErrorStatus: 'users/errorStatus',
            userStatistics: 'users/userStatistics',
            userStatisticsLoading: 'users/userStatisticsLoading',
            sessions: 'railSessions/sessions',
            statisticsUser: 'railStatistics/data',
            dataByUserId: 'railStatistics/dataByUserId',
            dataByUserIdLoading: 'railStatistics/loadingDataByUserId',
            heroDatePickerValue: 'UI/heroDatePickerValue',
            themeDarkMode: 'UI/themeDarkMode',
            teams: 'teams/teams',
            userStatisticError: 'users/userStatisticError',
            totalSessions: 'railSessions/totalSessions',
            userAccessLoading: 'users/userAccessLoading',
            userAccess: 'users/userAccess',
            groupChangeRoutineMode: 'users/groupChangeRoutineMode',
        }),

        isLoading: function () {
            return this.userRolesLoading || this.userLoading;
        },

        userIsAccountOwner: function () {
            return this.userId == this.$route.params.id;
        },

        showTeam: function () {
            if (!this._.isEmpty(this.userTeams)) {
                let team = this.userTeams.find(element => element.type == 1);
                return team ? team.name : 'Sem Equipa';
            }
        },

        computedCalendarDates: {
            get: function () {
                return this.calendarDatesFinal;
            },
            set: function (newValue) {
                this.calendarDatesFinal = newValue;
                this.$store.commit('UI/setHeroDatePickerValue', newValue);
            }
        },

        avatar: function () {
            let output = this.userAvatar;
            if (this.userIsManager && this.selectedUser) {
                output = require('@/assets/images/logo-polarize.png');
            }
            return output;
        },

        user: function () {
            if (this.selectedUser) {
                return this.selectedUser;
            }
        },

        userId: function () {
            return this.$route.params.id;
        },

        cardInfo() {
            return [
                {
                    name: 'A Enviar',
                    value: !this.dataByUserIdLoading && this.dataByUserId && this.dataByUserId['IN_DEBT']
                        ? this.dataByUserId['IN_DEBT'].value.EUR.value
                        : 0,
                    icon: 'mdi-share',
                    borderColor: 'red',
                    valueColor: 'red',
                    showCurrency: true,
                    show: true,
                },
                {
                    name: 'A Receber',
                    value: !this.dataByUserIdLoading && this.dataByUserId && this.dataByUserId['DEBIT']
                        ? this.dataByUserId['DEBIT'].value.EUR.value
                        : 0,
                    icon: 'mdi-reply',
                    borderColor: '#4CAF50',
                    valueColor: '#4CAF50',
                    showCurrency: true,
                    show: true,
                },
                {
                    name: 'Split',
                    value: !this.dataByUserIdLoading && this.dataByUserId && this.dataByUserId['SPLIT']
                        ? this.dataByUserId['SPLIT'].value.EUR.value
                        : 0,
                    icon: 'mdi-call-split',
                    showCurrency: true,
                    show: this.isMttDivision(),
                },
                {
                    name: 'Swap',
                    value: !this.dataByUserIdLoading && this.dataByUserId && this.dataByUserId['SWAP']
                        ? this.dataByUserId['SWAP'].value.EUR.value
                        : 0,
                    icon: 'mdi-autorenew',
                    showCurrency: true,
                    show: this.isMttDivision(),
                },
            ];
        },

        cardInfoVertical() {
            return [
                {
                    name: 'Split',
                    value: !this.dataByUserIdLoading && this.dataByUserId && this.dataByUserId['SPLIT']
                        ? this.dataByUserId['SPLIT'].value.EUR.value
                        : 0,
                    icon: 'mdi-call-split',
                    showCurrency: true,
                },
                {
                    name: 'Swap',
                    value: !this.dataByUserIdLoading && this.dataByUserId && this.dataByUserId['SWAP']
                        ? this.dataByUserId['SWAP'].value.EUR.value
                        : 0,
                    icon: 'mdi-autorenew',
                    showCurrency: true,
                },
            ];
        },

        cardInfoTableBalances() {
            return [
                {
                    name: 'Lucro',
                    valueEUR:  ! this.dataByUserIdLoading && this.dataByUserId && this.dataByUserId['PROFIT']
                            ? this.dataByUserId['PROFIT'].value.EUR.value
                            : 0,
                    valueUSD: ! this.dataByUserIdLoading && this.dataByUserId && this.dataByUserId['PROFIT']
                            ? this.dataByUserId['PROFIT'].value.USD.value
                            : 0,
                    icon: 'mdi-account-cash-outline',
                    showCurrency: true,
                    valueColor: '',
                    showColor: true,
                },
                {
                    name: 'Saldo total',
                    valueEUR: ! this.dataByUserIdLoading && this.dataByUserId && this.dataByUserId['BANKROLL_USER']
                            ? this.dataByUserId['BANKROLL_USER'].value.EUR.value
                            : 0,
                    valueUSD: ! this.dataByUserIdLoading && this.dataByUserId && this.dataByUserId['BANKROLL_USER']
                            ? this.dataByUserId['BANKROLL_USER'].value.USD.value
                            : 0,
                    icon: 'mdi-cash-plus',
                    showCurrency: true,
                    valueColor: this.themeDarkMode ? 'white' : 'black',
                    showColor: false,
                },
                {
                    name: 'Saldo inicial',
                    valueEUR: ! this.dataByUserIdLoading && this.dataByUserId && this.dataByUserId['BANKROLL_INITIAL_USER']
                            ? this.dataByUserId['BANKROLL_INITIAL_USER'].value.EUR.value
                            : 0,
                    valueUSD: ! this.dataByUserIdLoading && this.dataByUserId && this.dataByUserId['BANKROLL_INITIAL_USER']
                            ? this.dataByUserId['BANKROLL_INITIAL_USER'].value.USD.value
                            : 0,
                    icon: 'mdi-wallet-outline',
                    showCurrency: true,
                    valueColor: this.themeDarkMode ? 'white' : 'black',
                    showColor: false,
                },
            ];
        },

        cardInfoTableSessions() {
            return [
                {
                    name: 'Nº total de sessões',
                    value: this.totalSessions ? this.totalSessions : 0,
                    icon: 'mdi-cards-playing-outline',
                    showCurrency: false,
                    precision: 0,
                    showSlotCurrencyInputValue: false,
                    showCurrencyInputValue: true,
                },
                {
                    name: this.cardPlayerEventText.title,
                    value: this.dataByUserId && this.dataByUserId.EVENTS_COUNT ? this.dataByUserId.EVENTS_COUNT.value : 0,
                    icon: this.isCashDivision ? 'mdi-hand-clap' : 'mdi-trophy-outline',
                    showCurrency: false,
                    precision: 0,
                    showSlotCurrencyInputValue: false,
                    showCurrencyInputValue: true,
                },
                {
                    name: 'Duração média por sessão',
                    value: this.userStatistics && this.userStatistics['SESSION_TIME_AVG']
                            ? parseInt(this.userStatistics['SESSION_TIME_AVG'].value)
                            : 0,
                    icon: 'mdi-clock-plus-outline',
                    showCurrency: false,
                    precision: 0,
                    showSlotCurrencyInputValue: true,
                    showCurrencyInputValue: false,
                },
                {
                    name: this.cardPlayerEventText.cardStatisticTitle,
                    value: this.userStatistics && this.userStatistics['SESSION_EVENTS_AVG']
                            ? parseInt(this.userStatistics['SESSION_EVENTS_AVG'].value)
                            : 0,
                    icon: 'mdi-table-clock',
                    showCurrency: false,
                    precision: 0,
                    showSlotCurrencyInputValue: false,
                    showCurrencyInputValue: true,
                },
            ];
        },

        statsCumulativePNL: function () {
            let output = {
                series: [],
                labels: [],
            }

            if (this.dataByUserId && !this._.isEmpty(this.dataByUserId)) {
                output.series = this.dataByUserId['CUMULATIVE_PNL'].data.series;
                output.labels = this.dataByUserId['CUMULATIVE_PNL'].data.labels;
            }

            return output;
        },

        userHistoryLevelComputed: function () {
            let output = {
                series: [],
                labels: [],
            };

            if (this.userHistoryLevel) {
                if (!this._.isEmpty(this.userHistoryLevel.series)) {
                    let values = this.findLowestAndHighestValuesInsideAnArray(this.userHistoryLevel.series);
                    output.series = this.userHistoryLevel.series;
                    output.min = values.min;
                    output.max = values.max;
                }

                if (!this._.isEmpty(this.userHistoryLevel.labels)) {
                    output.labels = this.userHistoryLevel.labels;
                }
            }

            return output;
        },

        computedTabs: function () {
            let isTabDisabled = true;
            let changeRoutineMode = null;

            if (this.userIsRailManager) {
                isTabDisabled = false;
            } else if (this.userIsPlayer) {
                isTabDisabled = false;
            }

            if (this.groupChangeRoutineMode && this.groupChangeRoutineMode.mode) {
                changeRoutineMode = this.groupChangeRoutineMode.mode;
            }

            // Disable tabs Rail, Stats
            this.tab.items[1].disabled = isTabDisabled;
            this.tab.items[4].disabled = isTabDisabled;

            return this.tab.items;
        },

        cardPlayerSessionsMonthly: function () {
            let output = {
                percentageValue: this.userStatistics && this.userStatistics.MONTHLY_SESSIONS_PCT ? this.userStatistics.MONTHLY_SESSIONS_PCT.percentageValue : 0,
                total: this.userStatistics && this.userStatistics.MONTHLY_SESSIONS_PCT ? this.userStatistics.MONTHLY_SESSIONS_PCT.total : 0,
                value: this.userStatistics && this.userStatistics.MONTHLY_SESSIONS_PCT ? this.userStatistics.MONTHLY_SESSIONS_PCT.value : 0,
                monthProgress: this.userStatistics && this.userStatistics.MONTH_PROGRESS ? this.userStatistics.MONTH_PROGRESS.percentageValue : 0,
                customGoal: this.userStatistics && this.userStatistics.MONTHLY_SESSIONS_PCT ? this.userStatistics.MONTHLY_SESSIONS_PCT.customGoal : false,
                pastTeamGoal: this.userStatistics && this.userStatistics.MONTHLY_SESSIONS_PCT ? this.userStatistics.MONTHLY_SESSIONS_PCT.pastTeamGoal : false,
                pastTeamGoalMessage: this.userStatistics && this.userStatistics.MONTHLY_SESSIONS_PCT ? this.getTeamGoalMessageByTeamID(this.userStatistics.MONTHLY_SESSIONS_PCT.teamConsidered) : ''
            }
            return output;
        },
        
        cardPlayerEventsMonthly: function () {
            let output = {
                percentageValue: this.userStatistics && this.userStatistics.MONTHLY_EVENTS_PCT ? this.userStatistics.MONTHLY_EVENTS_PCT.percentageValue : 0,
                total: this.userStatistics && this.userStatistics.MONTHLY_EVENTS_PCT ? this.userStatistics.MONTHLY_EVENTS_PCT.total : 0,
                value: this.userStatistics && this.userStatistics.MONTHLY_EVENTS_PCT ? this.userStatistics.MONTHLY_EVENTS_PCT.value : 0,
                monthProgress: this.userStatistics && this.userStatistics.MONTH_PROGRESS ? this.userStatistics.MONTH_PROGRESS.percentageValue : 0,
                customGoal: this.userStatistics && this.userStatistics.MONTHLY_EVENTS_PCT ? this.userStatistics.MONTHLY_EVENTS_PCT.customGoal : false,
                pastTeamGoal: this.userStatistics && this.userStatistics.MONTHLY_EVENTS_PCT ? this.userStatistics.MONTHLY_EVENTS_PCT.pastTeamGoal : false,
                pastTeamGoalMessage: this.userStatistics && this.userStatistics.MONTHLY_EVENTS_PCT ? this.getTeamGoalMessageByTeamID(this.userStatistics.MONTHLY_EVENTS_PCT.teamConsidered) : ''
            }
            return output;
        },

        cardPlayerSessionsContract: function () {
            let output = {
                percentageValue: this.userStatistics && this.userStatistics.CONTRACT_SESSIONS_PCT ? this.userStatistics.CONTRACT_SESSIONS_PCT.percentageValue : 0,
                total: this.userStatistics && this.userStatistics.CONTRACT_SESSIONS_PCT && this.userStatistics.CONTRACT_SESSIONS_PCT.total ? this.userStatistics.CONTRACT_SESSIONS_PCT.total : 0,
                value: this.userStatistics && this.userStatistics.CONTRACT_SESSIONS_PCT && this.userStatistics.CONTRACT_SESSIONS_PCT.value ? this.userStatistics.CONTRACT_SESSIONS_PCT.value : 0,
                monthProgress: this.userStatistics && this.userStatistics.MONTH_PROGRESS ? this.userStatistics.MONTH_PROGRESS.percentageValue : 0,
                customGoal: this.userStatistics && this.userStatistics.CONTRACT_SESSIONS_PCT ? this.userStatistics.CONTRACT_SESSIONS_PCT.customGoal : false,
                pastTeamGoal: this.userStatistics && this.userStatistics.CONTRACT_SESSIONS_PCT ? this.userStatistics.CONTRACT_SESSIONS_PCT.pastTeamGoal : false,
            }

            return output;
        },

        cardPlayerEventsContract: function () {
            let output = {
                percentageValue: this.userStatistics && this.userStatistics.CONTRACT_EVENTS_PCT ? this.userStatistics.CONTRACT_EVENTS_PCT.percentageValue : 0,
                total: this.userStatistics && this.userStatistics.CONTRACT_EVENTS_PCT && this.userStatistics.CONTRACT_EVENTS_PCT.total ? this.userStatistics.CONTRACT_EVENTS_PCT.total : 0,
                value: this.userStatistics && this.userStatistics.CONTRACT_EVENTS_PCT && this.userStatistics.CONTRACT_EVENTS_PCT.value ? this.userStatistics.CONTRACT_EVENTS_PCT.value : 0,
                monthProgress: this.userStatistics && this.userStatistics.MONTH_PROGRESS ? this.userStatistics.MONTH_PROGRESS.percentageValue : 0,
                customGoal: this.userStatistics && this.userStatistics.CONTRACT_EVENTS_PCT ? this.userStatistics.CONTRACT_EVENTS_PCT.customGoal : false,
                pastTeamGoal: this.userStatistics && this.userStatistics.CONTRACT_EVENTS_PCT ? this.userStatistics.CONTRACT_EVENTS_PCT.pastTeamGoal : false,
            }
            return output;
        },

        cardPlayerSessionEventsAverage: function () {
            let output = {
                percentageValue: this.userStatistics && this.userStatistics.SESSION_EVENTS_AVG ? this.userStatistics.SESSION_EVENTS_AVG.percentageValue : 0,
                total: this.userStatistics && this.userStatistics.SESSION_EVENTS_AVG ? this.userStatistics.SESSION_EVENTS_AVG.total : 0,
                value: this.userStatistics && this.userStatistics.SESSION_EVENTS_AVG ? this.userStatistics.SESSION_EVENTS_AVG.value : 0,
                monthProgress: this.userStatistics && this.userStatistics.MONTH_PROGRESS ? this.userStatistics.MONTH_PROGRESS.percentageValue : 0,
            }
            return output;
        },

        cardPlayerSessionTimeAverage: function () {
            let output = {
                percentageValue: this.userStatistics && this.userStatistics.SESSION_TIME_AVG ? this.userStatistics.SESSION_TIME_AVG.percentageValue : 0,
                total: this.userStatistics && this.userStatistics.SESSION_TIME_AVG ? this.userStatistics.SESSION_TIME_AVG.total : 0,
                value: this.userStatistics && this.userStatistics.SESSION_TIME_AVG ? this.userStatistics.SESSION_TIME_AVG.value : 0,
                monthProgress: this.userStatistics && this.userStatistics.MONTH_PROGRESS ? this.userStatistics.MONTH_PROGRESS.percentageValue : 0,
            }
            return output;
        },

        cardPlayerEventText: function () {
            let output = {
                title: 'N.º de eventos',
                tooltip: 'N.º de eventos registados',
                subtitleMonth: 'N.º de eventos registados',
                cardStatisticTitle: 'Média de eventos por sessão'
            }

            if (!this._.isEmpty(this.userTeams)) {
                let userTeamsMapID = this.userTeams.map(e => e.id)
                if (userTeamsMapID.includes(Vue.prototype.$team_division_cash_id)) {
                    output = {
                        title: 'N.º de mãos',
                        tooltip: 'N.º de mãos contratualizadas',
                        subtitleMonth: 'N.º de mãos registadas este mês',
                        cardStatisticTitle: 'Média de mãos por sessão'
                    }
                } else {
                    output = {
                        title: 'N.º de torneios',
                        tooltip: 'N.º de torneios contratualizados',
                        subtitleMonth: 'N.º de torneios registados este mês',
                        cardStatisticTitle: 'Média de torneios por sessão'
                    }
                }
            }
            return output;
        },

        handleMinutesPlayed() {
            if (this.userStatisticsLoading) {
                return null;
            }
            
            let hours = Math.floor(this.cardPlayerSessionTimeAverage.value / 60);
            let minutes = this.cardPlayerSessionTimeAverage.value % 60;

            if (hours > 0) {
                return hours + 'h ' + minutes + 'm';
            } else {
                return  minutes + 'm';
            }
        },

        showAlertNoData: function () {
            let output = false;
            if (this.userStatisticError) {
                output = true;
            }

            return output;
        },

        showObligationsAlert: function () {
            let hasCompleteGoals = this.cardPlayerSessionsMonthly.percentageValue >= 1 && this.cardPlayerEventsMonthly.percentageValue >= 1;
            return hasCompleteGoals || this.isDatePast;
        },

        isDatePast: function () {
            let dateToday = this.$moment().format('YYYY-MM-DD');
            let output = !this.$moment(this.heroDatePickerValue[0]).isSame(dateToday, 'month');

            return output;
        },

        obligationsAlertData: function () {
            let output = {
                color: null,
                title: '',
                description: null,
                icon: 'mdi-example'
            };
            
            if (!this._.isEmpty(this.heroDatePickerValue)) {
                output.title = 'Objetivos mensais - ' + this.$moment(this.heroDatePickerValue[0]).format('MMMM YYYY').charAt(0).toUpperCase() + this.$moment(this.heroDatePickerValue[0]).format('MMMM YYYY').slice(1).toLowerCase();
            }

            if (this.cardPlayerSessionsMonthly.percentageValue < 1 && this.cardPlayerEventsMonthly.percentageValue < 1) {
                output.color = 'error';
                output.description = 'Jogador não cumpriu nenhum dos objetivos em ' + this.$moment(this.heroDatePickerValue[0]).format('MMMM YYYY').charAt(0).toUpperCase() + this.$moment(this.heroDatePickerValue[0]).format('MMMM').slice(1).toLowerCase();
                output.icon = 'mdi-emoticon-sad';
            } else if ((this.cardPlayerSessionsMonthly.percentageValue >= 1 && this.cardPlayerEventsMonthly.percentageValue < 1) || (this.cardPlayerSessionsMonthly.percentageValue < 1 && this.cardPlayerEventsMonthly.percentageValue >= 1)) {
                output.color = 'warning';
                output.description = 'Jogador cumpriu parte dos objetivos em ' + this.$moment(this.heroDatePickerValue[0]).format('MMMM YYYY').charAt(0).toUpperCase() + this.$moment(this.heroDatePickerValue[0]).format('MMMM').slice(1).toLowerCase();
                output.icon = 'mdi-emoticon-neutral';
            } else {
                output.color = 'success';
                output.description = 'Jogador cumpriu todos os objetivos em ' + this.$moment(this.heroDatePickerValue[0]).format('MMMM YYYY').charAt(0).toUpperCase() + this.$moment(this.heroDatePickerValue[0]).format('MMMM').slice(1).toLowerCase();
                output.icon = 'mdi-emoticon-cool';
           }

            return output;
        },

        dateRangeObligationsData: function () {
            let output = {
                showAlert: false,
                title: 'A ver dados de ' + this.$moment(this.heroDatePickerValue[0]).format('MMMM'),
                description: 'O intervalo de datas engloba vários meses. Os dados demonstrados referem-se apenas ao 1.º mês selecionado '
            };

            let firstMonthDates = {
                start: this.$moment(this.heroDatePickerValue[0]).startOf('month').format('DD-MM-YYYY'),
                end: this.$moment(this.heroDatePickerValue[0]).endOf('month').format('DD-MM-YYYY'),
            }
            // check if both dates are in same month
            let checkDiffDates = this.$moment(this.heroDatePickerValue[1]).isSame(this.heroDatePickerValue[0], 'months');

            if (!checkDiffDates) {
                output.showAlert = true;
                output.description = 'O intervalo de datas engloba vários meses. Os dados demonstrados referem-se apenas ao 1.º mês selecionado ' + firstMonthDates.start + ' - ' + firstMonthDates.end + '.'
            }

            return output;
        },

        cardInfoGames() {
            return [
                {
                    name: 'Nº total de jogos',
                    value: 1234,
                    icon: 'mdi-autorenew',
                    showCurrency: false,
                    show: true,
                },
                {
                    name: 'Buy-in médio',
                    value: 125,
                    icon: 'mdi-autorenew',
                    showCurrency: true,
                    show: true,
                },
                {
                    name: 'ROI',
                    value: 504,
                    icon: 'mdi-autorenew',
                    showCurrency: true,
                    show: true,
                },
                {
                    name: 'Nº de torneios',
                    value: 635,
                    icon: 'mdi-autorenew',
                    showCurrency: false,
                    show: true,
                },
                {
                    name: 'Nº de vitórias',
                    value: 1235,
                    icon: 'mdi-autorenew',
                    showCurrency: false,
                    show: true,
                },
                {
                    name: 'Nº de horas jogadas',
                    value: 1235,
                    icon: 'mdi-autorenew',
                    showCurrency: false,
                    show: true,
                },
            ];
        },
    },

    watch: {
        heroDatePickerValue: function () {
            if (this.apiStatisticsRequestEnabled) {
                this.fetchStatistics();
            }

            if (this.apiUserStatisticsRequestEnabled) {
                this.getUserStatistics();
            }
        },

        isLoading: function () {
            this.validateAccessPermission();
        },

        'tab.current': function(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.$router.push({
                    path: '/players/' + this.$route.params.id + '/' + this.tab.items[newValue].slug,
                    query: this.queryParams
                });
            }
        },

        '$route.query': {
            immediate: true,
            deep: true,
            handler(newValue, oldValue) {
                this.queryParams = newValue;
            }
        },
        
        '$route.params.id': {
            handler(newValue, oldValue) {
                this.fetchData();
            }
        }
    },

    methods: {
        initialState() {
            // TODO - Move this. The initialState is supposed to be a state that is present prior to all the processing..
            let params = this.searchParams();

            return {
                apiUserStatisticsRequestEnabled: true,
                apiStatisticsRequestEnabled: true,
                valueColor: '',
                queryParams: params,
                calendarDatesFinal: [],
                teamCashDivisionID: 11,
                isCash: null,
                form: { dateRange: [this.$moment("2021-01-01"), this.$moment()], network: null, flags: null },
                tab: {
                    current: 0,
                    // URLs need to match router.js
                    items: [
                        { tab: 'Info', slug: '', disabled: false },
                        { tab: 'Rail', slug: 'rail', disabled: false },
                        { tab: 'Desempenho', slug: 'performance', disabled: false },
                        { tab: 'Nível', slug: 'level', disabled: false },
                        { tab: 'Stats', slug: 'stats', disabled: false },
                        { tab: 'Jogos', slug: 'games', disabled: false},
                        { tab: 'Pokercraft', slug: 'files', disabled: false },
                    ],
                },
                dialogues: {
                    access: {
                        confirmation: false,
                        feedback: false
                    }
                },
                submittingRemoveAccess: false,
            };
        },

        searchParams() {
            const hasQueryParams = Object.keys(this.$route.query).length > 0;
            if(hasQueryParams){
                return {dateBegin: this.$route.query.dateBegin, dateEnd: this.$route.query.dateEnd}
            }else{
                const dateBegin = this.$moment().startOf('month').format('YYYY-MM-DD');
                const dateEnd = this.$moment().endOf('month').format('YYYY-MM-DD');
                return {dateBegin, dateEnd}
            }
           
        },

        getCurrentTabFromURL() {
            // Match slug vs tabs
            let slugTabIndex = 0;
            const slugs = this.tab.items.map(e => e.slug);
            slugs.forEach(slug => {
                if (this.$route.path.includes('/' + slug)) {
                    slugTabIndex = slugs.indexOf(slug);
                }
            });

            // Change tab in the UI
            this.tab.current = slugTabIndex;

            // Return the index of the selected tab
            return slugTabIndex;
        },

        validateAccessPermission() {
            // Redirect user to homepage if unable to access player
            if (this._.includes([401, 403, 404], this.usersErrorStatus)) {
                this.$router.push({ path: '/' });
            }
        },

        async fetchData() {
            this.setDatePickerDate();
            this.getUserMeta();
            await this.getUser();
            await this.getUserTeams();
            this.getUserChangeRoutine();
            this.checkAccess();
            this.getTeams();
            this.isCashDivision();
            this.fetchUserHistoryLevel();

            if (this.apiUserStatisticsRequestEnabled) {
                this.getUserStatistics();
            }
        },

        getTeams() {
            if (this._.isEmpty(this.teams)) {
                this.$store.dispatch('teams/get', {
                    page: 1,
                    itemsPerPage: -1
                });
            }
        },

        async getUser() {
            await this.$store.dispatch('users/getUser', this.$route.params.id);
        },

        getUserMeta() {
            this.$store.dispatch('users/getUserMeta', this.$route.params.id);
        },

        async getUserTeams() {
            await this.$store.dispatch('users/getUserTeams', this.$route.params.id);
        },

        async getUserStatistics() {
            this.apiUserStatisticsRequestEnabled = false;
            
            let payload = {
                id: this.$route.params.id,
                body: {}
            }

            // Add date params to payload
            if (!this._.isEmpty(this.heroDatePickerValue)) {
                payload.body.dateBegin = this.heroDatePickerValue[0];
                payload.body.dateEnd = this.heroDatePickerValue[1];
            }

            if (payload.id) {
                await this.$store.dispatch('users/getUserStatistics', payload);
            }

            this.apiUserStatisticsRequestEnabled = true;
        },

        isCashDivision() {
            let hasUserTeams = this.selectedUser && !this._.isEmpty(this.userTeams);
            // if admin
            if (hasUserTeams && this.userIsManager) {
                let userHasTeamCash = this._.find(this.userTeams, { id: this.teamCashDivisionID });
                this.isCash = !this._.isUndefined(userHasTeamCash);
            }

            // if user
            if (!this.userIsManager) {
                this.isCash = this.userIsDivision('cash');
            }
            return this.isCash;
        },

        isMttDivision() {
            let hasUserTeams = this.selectedUser && !this._.isEmpty(this.userTeams);
            // if admin
            if (hasUserTeams && this.userIsManager) {
                let userHasTeamMtt = this._.find(this.userTeams, { id: Vue.prototype.$team_ids.division_mtt });
                return !this._.isUndefined(userHasTeamMtt);
            }

            // if user
            if (!this.userIsManager) {
                return this.userIsDivision('mtt');
            }

            // safety
            return false;
        },

        generatePayload() {
            let payload = { params: {} };

            if (!this._.isEmpty(this.heroDatePickerValue)) {
                payload.params.dateBegin = this.heroDatePickerValue[0];
                payload.params.dateEnd = this.heroDatePickerValue[1];
            }
            
            if (this.$route.params) {
                payload.params.user = this.$route.params.id;
            }

            return payload;
        },

        async fetchStatistics() {
            this.apiStatisticsRequestEnabled = false;

            let payload = this.generatePayload();

            if (!this._.isEmpty(payload.params)) {
                await this.$store.dispatch('railStatistics/getByUserId', payload);
            }

            this.apiStatisticsRequestEnabled = true;
        },

        fetchUserHistoryLevel() {
            this.$store.dispatch('users/getUserHistoryLevel', { id: this.$route.params.id });
        },

        valueColorFinal(item) {
            let output = this.valueColor;

            if (!this.valueColor && this.themeDarkMode) {
                output = 'white';
            }

            if (item < 0) {
                output = 'red';
            }

            return output;
        },

        /**
        * @param {Number|String} value 
        * @returns {String} with width value
        */
        getCurrencyInputWidthStats(value) {
            let output = '50px';
            if (value) {
                output = parseFloat(value).toFixed(3);
    
                // turn output into String
                output = String(output);
                output = (output.length + 1) * 10 + 'px';
            }
            return output;
        },

        getTeamGoalMessageByTeamID(id) {
            if (this._.isNull(id) || this._.isUndefined(id)) {
                return '';
            }

            let findTeam = this.teams.find(e => e.id == id);
            let team = !this._.isUndefined(findTeam) ? findTeam.name : 'atual';

            return 'A utilizar objetivos da equipa ' + team;
        },

        setDatePickerDate() {
            let output = [
              this.$moment().startOf('month').format('YYYY-MM-DD'),
              this.$moment().endOf('month').format('YYYY-MM-DD'),
            ];
            this.$store.commit('UI/setHeroDatePickerValue', output);
        },

        /**
         * Remove every access this user has based on $route.params.id (user id)
         */
        async removeAccess() {
            this.submittingRemoveAccess = true;
            let result = await this.$store.dispatch('users/removeAccessByTrackerId', this.$route.params.id);
            this.submittingRemoveAccess = false;

            if (result == true) {
                this.dialogues.access.confirmation = false; // close Dialog confirmation
                this.checkAccess(); // refresh access
            }
        },

        /**
         * Add access to tracker and polarize website based on $route.params.id (user id)
         */
        async addAccess() {
            let result = await this.$store.dispatch('users/addAccessByTrackerId', this.$route.params.id);

            if (result == true) {
                this.dialogues.access.feedback = true;
                this.checkAccess();
            }
        },

        /**
         * Check user roles based on $route.params.id (user id)
         */
        async checkAccess() {
            if (this.userIsRailManager) {
                await this.$store.dispatch('users/checkAccessByTrackerId', this.$route.params.id);
            }
        },

        openDialogConfirmation() {
            this.dialogues.access.confirmation = true;
        },

        getAccessLabelMessage() {
            let output = {
                message: null,
                tooltip: null,
            }

            // Default message if some properties are missing
            if (! (this.userAccess && this.userAccess.detail)) {
                output = {
                    message: 'Acesso desconhecido',
                    tooltip: 'Acesso não foi avaliado por questões técnicas',  
                }

                return output
            }

            // Situational messages
            if (this.userAccess.is_manager) {
                output = {
                    message: 'Acesso de gestão',
                    tooltip: '✅ Role de gestão ✅ Grupos',  
                }
            } else if (this.userAccess.general) {
                output = {
                    message: 'Tem acesso',
                    tooltip: '✅ Role ✅ Grupos',  
                }
            } else if (!this.userAccess.detail.has_role && !this.userAccess.detail.has_group) {
                output = {
                    message: 'Sem acesso',
                    tooltip: '❌ Role ❌ Grupos',  
                }
            } else if (!this.userAccess.detail.has_role) {
                output = {
                    message: 'Sem acesso',
                    tooltip: '❌ Role ✅ Grupos',  
                }
            } else if (!this.userAccess.detail.has_group) {
                output = {
                    message: 'Sem acesso',
                    tooltip: '✅ Role ❌ Grupos',  
                }
            }

            return output;
        },

        // TODO: move to DataMixin
        findLowestAndHighestValuesInsideAnArray(arr) {
            if (arr.length === 0) {
              return { lowest: undefined, highest: undefined };
            }
          
            let lowest = arr[0];
            let highest = arr[0];
          
            for (let i = 1; i < arr.length; i++) {
              if (arr[i] < lowest) {
                lowest = arr[i];
              }
              if (arr[i] > highest) {
                highest = arr[i];
              }
            }
          
            return { min: lowest, max: highest };
        },

        showComponentHandler() {
            let userTeamIds = this.selectedUser && !this._.isEmpty(this.selectedUser.teams)
                ? this.selectedUser.teams.map(e => e.id)
                : []
            
            return userTeamIds.includes(Vue.prototype.$team_division_mtt_id);
        },

        getUserChangeRoutine() {
            this.$store.dispatch('users/getUserChangeRoutineMode', { id: this.userId });
        },
    },
}
