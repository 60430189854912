<template>
    <v-row>
        <v-col cols="12" sm="auto">
            <v-btn color="secondary" class="mt-2 btn-remove" @click.stop="remove()">
                <v-icon small>mdi-minus</v-icon>
            </v-btn>
        </v-col>
        <v-col cols="12" sm>
            <v-autocomplete
                v-if="networks != null && networks.length"
                v-model="form.network"
                :items="networks.map(e => e.name_networks)"
                label="Sala"
                required
                @change="emitToParent()"
            />
        </v-col>

        <v-col cols="12" sm>
            <v-text-field
                type="number"
                v-model="form.amount"
                label="Montante"
                :prepend-icon="currencySign"
                required
                @input="emitToParent()"
                :rules="rules.amount"
            />
        </v-col>
    </v-row>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'InputNetworkAmount',

    props: {
        dataProp: {
            type: Object,
            required: true,
            default: null
        }
    },

    computed: {
        ...mapGetters({
            networks: 'networks/networks'
        }),

        selectedNetwork() {
            if (this.networks)
                return this.networks.find(e => e.name_networks == this.form.network);
            else
                return null;
        },

        networkId() {
            if (this.selectedNetwork)
                return this.selectedNetwork.id_networks;
            else
                return null;
        },

        currency() {
            if (this.selectedNetwork)
                return this.selectedNetwork.currency.code_currencies;
            else
                return null;
        },

        currencySign() {
            if (this.selectedNetwork && this.selectedNetwork.currency.code_currencies)
                return 'mdi-currency-' + this.selectedNetwork.currency.code_currencies.toLowerCase();
            else
                return 'mdi-help';
        },

        formData() {
            return {
                network: this.networkId,
                amount: this.form.amount,
                currency: this.currency
            };
        }
    },

    data() {
        return {
            form: {
                network: null,
                amount: null
            },
            rules: {
                amount: [v => !!v || 'Por favor introduza o montante']
            }
        }
    },

    created() {
        this.initialize();
    },

    methods: {
        async initialize() {
            // Fetch networks
            if (! this.networks)
                await this.$store.dispatch('networks/get');

            // Fill fields
            let networkIdToText = this.networks.find(e => e.id_networks == this.dataProp.network);
            if (networkIdToText)
                networkIdToText = networkIdToText.name_networks
            else
                networkIdToText = null;

            if (this.dataProp) {
                this.form = {
                    network: networkIdToText,
                    amount: this.dataProp.amount
                };
            }
        },

        emitToParent() {
            this.$emit('change', this.formData);
        },

        remove() {
            this.$emit('remove');
        },
    }
}
</script>

<style scoped>
.btn-remove {
    min-width: 0px !important;
}
</style>