import { mapGetters } from 'vuex';

export default {
  name: 'NetworkSharkscopeDropdown',

  props: {
    rules: { type: Array, required: false, default: () => [] },
  },

  mixins: [],

  components: {},

  computed: {
    ...mapGetters({
      networksSharkscope: 'networksSharkscope/data',
    }),
  },

  /*
  watch: {
    // Synthetic v-model
    value: function (newValue) {
      this.value = newValue;
    },
  },
  */

  created() {
    this.initialize();
  },

  data() {
    return this.initialState();
  },

  methods: {
    initialize() {
      this.fetchData();
    },

    initialState() {
      return {
        value: null,
      };
    },

    fetchData(force=false) {
      if (force || _.isEmpty(this.networksSharkScope)) {
        this.$store.dispatch('networksSharkscope/get');
      }
    },

    emitChange() {
      this.$emit('input', this.value);
    },
  }
}