import { mapGetters } from 'vuex';
import TableBalance from '@/components/Rail/TableBalance/TableBalance.vue';
import UserDropdown from '@/components/Rail/UserDropdown/UserDropdown.vue';

export default {
    name: 'DrawerTableBalance',

    components: {
        TableBalance,
        UserDropdown,
    },

    props: {
        isOpen: {
            type: Boolean,
            required: true,
            default: false
        },
    },

    data() {
        return this.initialState();
    },

    created() {
        this.computedValue = this.isOpen;
    },

    computed: {
        ...mapGetters({
            userTrackerId: 'auth/userTrackerId',
            selectedUser: 'users/selectedUser',
        }),

        // Synthetic v-model
        computedValue: {
            get: function () {
                return this.drawerState;
            },
            set: function (newValue) {
                this.drawerState = newValue;

                if (newValue === false) {
                    this.$emit('close-drawer');
                }
            },
        },
    },

    watch: {
        isOpen: function (newValue) {
            this.computedValue = newValue;
        },

        'form.user': function () {
            this.$store.commit('users/setSelectedUser', this.form.user);
        },
        selectedUser: {
            handler() {
                this.form.user = this.selectedUser;
            },
            deep: true,
            immediate: true
        },
    },

    methods: {
        initialState() {
            return {
                drawerState: false,
                form: {
                    user: null,
                }
            };
        },

        closeDrawer() {
            this.computedValue = false;
        }, 

        reset() {
            Object.assign(this.$data, this.initialState());
        },
    },
}