var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"fill-height"},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.tableTitle)+" "),_c('v-spacer')],1),_c('v-data-table',{staticClass:"table-footer-bottom",attrs:{"headers":_vm.dataTable.headers,"items":_vm.playersToPay,"loading":_vm.swapGroupStatisticsLoading,"loading-text":"A carregar...","no-data-text":"Não foram encontradas pools registadas.","must-sort":""},scopedSlots:_vm._u([{key:"item.user",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('span',[(_vm.handleUsername(item.user))?_c('router-link',{attrs:{"to":'/players/' + item.user + '/'}},[_c('v-chip',{staticClass:"mx-1 cursor-pointer",attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.handleUsername(item.user))+" ")])],1):_c('span',{staticClass:"align-center d-flex"},[_c('v-chip',{staticClass:"mx-1 cursor-pointer white--text",attrs:{"small":"","color":"red"}},[_vm._v(" Jogador desconhecido ")])],1)],1)])]}},{key:"item.receivedAmount.value",fn:function(ref){
var item = ref.item;
return [_c('v-chip',[_c('span',{staticClass:"mr-2"},[_vm._v(" $ ")]),_c('currency-input',{staticClass:"text-end",style:({color: _vm.valueColorFinal(), width: '90px' }),attrs:{"value":item.receivedAmount.value,"currency":null,"precision":2,"disabled":""}})],1)]}},{key:"item.sentAmount.value",fn:function(ref){
var item = ref.item;
return [_c('v-chip',[_c('span',{staticClass:"mr-2"},[_vm._v(" $ ")]),_c('currency-input',{staticClass:"text-end",style:({color: _vm.valueColorFinal(), width: '90px' }),attrs:{"value":item.sentAmount.value,"currency":null,"precision":2,"disabled":""}})],1)]}},{key:"item.transferAmount.value",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getPnlColor(item.transferAmount.value)}},[_c('span',{staticClass:"mr-2 text-white"},[_vm._v(" $ ")]),_c('currency-input',{staticClass:"text-end",style:({color: 'white', width: '90px' }),attrs:{"value":item.transferAmount.value,"currency":null,"precision":2,"allow-negative":"","disabled":""}})],1)]}},{key:"no-data",fn:function(){return [_c('v-spacer'),_c('EmptyStateNoData',{attrs:{"icon":"mdi-file-check-outline","title":"Não existem montantes dos jogadores registados."}}),_c('v-spacer')]},proxy:true},{key:"no-results",fn:function(){return [_c('EmptyStateNoData',{attrs:{"icon":"mdi-magnify","title":"Não foram encontrados resultados","message":"Ajuste os termos da sua pesquisa para encontrar mais resultados"}})]},proxy:true}])},[_c('v-spacer')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }