/**
 * @prop    {String}        action                    Valid values 'deactivate' / 'activate'.
 */

import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth';
import RailMixin from '@/mixins/Rail';
import AlertError from '@/components/Alerts/AlertError/AlertError.vue';

export default {
	name: 'DialogStatusNickname',

	components: {
		AlertError,
	},

	props: {
        isOpen: {
            type: Boolean,
            required: true,
            default: false
        },
        // valid values e.g : 'deactivate' / 'activate'
        action: {
            type: String,
            required: false,
            default: 'deactivate'
        }
	},

    mixins: [
		AuthMixin,
		RailMixin,
    ],
	
	data() {
		return this.initialState();
	},

	created() {
		this.fetchData();
	},

	computed: {
		...mapGetters({
            error: 'nicknames/error',
            humanError: 'nicknames/humanError',
			selectedNickname: 'nicknames/selectedNickname',
			userTrackerId: 'auth/userTrackerId',
		}),
	},
	
	watch: {
		isOpen: function () {
			if (this.isOpen) {
				return this.fetchData();
			}
		}
	},

	methods: {
		initialState() {
			return {
				submitting: false,
				messageToDispatch: this.action == 'deactivate' ? 'desativado' : 'ativado',
				buttonName: null,
				buttonColor: null,
				messageAction: null,
				title: null,
			};
		},

		fetchData() {
			this.title = this.action == 'deactivate' ? 'Desativar' : 'Ativar';
			this.messageAction = this.action == 'deactivate' ? 'desativar' : 'ativar';
			this.messageToDispatch = this.action == 'deactivate' ? 'desativado' : 'ativado';
			this.buttonColor = this.action == 'deactivate' ? 'error' : 'success';
			this.buttonName = this.title;
		},

		async submit() {
			if (!this.$refs.formStatusNickname.validate()) {
				return false;
			}

			// Toggle submit button
            this.toggleSubmitting();
            

			let result = await this.$store.dispatch('nicknames/' + this.action , this.selectedNickname.id);

			// // Re-enable button
			this.toggleSubmitting();

			// On success
			if (result === true) {
				// Show success snackbar
				this.$store.dispatch('UI/showSnackbar', {
					message: 'Nickname ' + this.messageToDispatch + ' com sucesso.',
					color: 'success'
				});

				if (this.userIsRailManager) {

					// clear current payload user in order to be able to refresh nicknames by userId again
					this.$store.commit('nicknames/setCurrentPayloadUserId', null);

					// only run if this.selectedNickname.user.id == userTrackerId
					if (this.selectedNickname.user.id == this.userTrackerId) {
						// clear current payload user tracker id in order to be able to refresh nicknames again
						this.$store.commit('nicknames/setCurrentPayloadUserTrackerId', null);
						this.getNicknamesByUserTrackerID();
					}
				}

				if (this.userIsPlayer) {
					// set promise to null in order to be able to reload nicknames all mine
					this.$store.commit('nicknames/setPromiseAllMine', null);

					// reload nicknames all mine
					this.$store.dispatch('nicknames/getAllMine');
				}

				// if update is sucessfully completed , it emits reload event to update tableNicknames
				this.$emit('reload');

				// closes dialog
				this.closeDialog();
			}
		},

		/**
		 * Used to disable Submit button
		 */
		toggleSubmitting() {
			this.submitting = !this.submitting;
		},

		closeDialog() {
			this.$emit("close-dialog");
			this.resetForm();
		},

		resetForm() {
			Object.assign(this.$data, this.initialState());
			this.$refs.formStatusNickname.reset();
			this.$store.dispatch('nicknames/clearError');
		}
	},
};