var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.userIsRailManager && (_vm.selectedRequest.type != _vm.$rail_ops.operations.deposit && _vm.selectedRequest.type != _vm.$rail_ops.operations.withdraw && (_vm.selectedRequest.type != _vm.$rail_ops.operations.makeup && _vm.selectedRequest.direction != _vm.$direction_rail_operations.decrease)))?_c('v-card',{staticClass:"mt-6"},[_c('v-col',{staticClass:"pa-4"},[_c('p',{staticClass:"subtitle-1 font-weight-bold"},[_vm._v(" Histórico de nicknames: ")]),_c('div',[(_vm.nicknamesHistoricBalanceComputed)?_c('v-data-table',{staticClass:"elevation-0 overflow-x-hidden",attrs:{"headers":_vm.table.headers,"items":_vm.nicknamesHistoricBalanceComputed,"loading-text":"A carregar o histórico dos nicknames..","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.nickname",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"mr-2"},'span',attrs,false),on),[_c('v-icon',{attrs:{"dense":""}},[_vm._v(_vm._s(_vm.isNicknameManagementIcon(item)))])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.isNicknameManagementTooltip(item)))])]),_c('div',{staticClass:"d-flex align-center text-left"},[_c('NicknameCard',{attrs:{"id":item.nickname ? item.nickname : null,"image-u-r-l":_vm.handleNicknameToDisplay(item.nickname).network.imageURL,"network-name":item.nickname ? _vm.handleNicknameToDisplay(item.nickname).network.name : null,"copy-to-clipboard":false,"name":item.nickname ? _vm.handleNicknameToDisplay(item.nickname).name : null}})],1)],1)]}},{key:"item.amountBefore",fn:function(ref){
var item = ref.item;
return [_c('v-chip',[(item && item.nickname)?_c('span',{staticClass:"mr-1"},[_vm._v(_vm._s(_vm.handleNicknameToDisplay(item.nickname).network.currency.symbol))]):_vm._e(),_c('currency-input',{staticClass:"text-end",style:({color: _vm.valueColorFinal(), width:'90px' }),attrs:{"value":item.balance_before.value,"currency":null,"precision":2,"disabled":""}})],1)]}},{key:"item.amountAfter",fn:function(ref){
var item = ref.item;
return [_c('v-chip',[(item && item.nickname)?_c('span',{staticClass:"mr-1"},[_vm._v(_vm._s(_vm.handleNicknameToDisplay(item.nickname).network.currency.symbol))]):_vm._e(),_c('currency-input',{staticClass:"text-end",style:({color: _vm.valueColorFinal(), width:'90px' }),attrs:{"value":item.balance_after.value,"currency":null,"precision":2,"disabled":""}})],1)]}},{key:"item.diff",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getChipColor(item)}},[(item && item.nickname)?_c('span',{staticClass:"mr-1",style:({color: 'white'})},[_vm._v(_vm._s(_vm.handleNicknameToDisplay(item.nickname).network.currency.symbol))]):_vm._e(),_c('currency-input',{staticClass:"text-end",style:({color: 'white', width:'90px' }),attrs:{"value":item.difference.value,"currency":null,"allow-negative":"","precision":2,"disabled":""}})],1)]}},{key:"item.detail",fn:function(ref){
var item = ref.item;
return [(item && item.nickname)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('router-link',{staticClass:"text-decoration-none w-100",attrs:{"to":'/nicknames/' + _vm.handleNicknameToDisplay(item.nickname).id}},[_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v(" mdi-eye-outline ")])],1)],1)]}}],null,true)},[_c('span',[_vm._v("Ver histórico deste nickname em detalhe")])]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('router-link',{staticClass:"text-decoration-none w-100",attrs:{"to":'/nicknames/' + item.source.id}},[_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v(" mdi-eye-outline ")])],1)],1)]}}],null,true)},[_c('span',[_vm._v("Ver histórico deste nickname em detalhe")])])]}},{key:"no-data",fn:function(){return [(_vm._.isEmpty(_vm.nicknamesHistoricBalance))?_c('EmptyStateNoData',{attrs:{"icon":"mdi-check-bold","title":"Sem resultados","message":"Ajuste os filtros para encontrar mais resultados","class-color":"green white--text","class-icon":"white"}}):_vm._e()]},proxy:true}],null,false,94091317)}):_vm._e()],1)])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }