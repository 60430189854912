import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth';
import AlertError from '@/components/Alerts/AlertError/AlertError.vue';
import NicknameDropdown from '@/components/Rail/NicknameDropdown/NicknameDropdown.vue';
import PlayerLiveDropdown from '@/components/PlayerLiveDropdown/PlayerLiveDropdown.vue';

export default {
  name: 'DialogAddEditNotes',
  props: {
    isOpen: {
      type: Boolean,
      required: true,
      default: false,
    },
    action: {
			type: String,
			required: true,
			default: 'create',
			validator: function (value) {
				const allowedActions = ['create', 'update']
				return allowedActions.includes(value);
			},
		},
    associatePlayerLive:{
      type: Object,
      required: false,
      default: () => {},
    }
  },

  mixins: [
    AuthMixin,
  ],

  computed: {
    ...mapGetters({
      error: 'notes/error',
      selectedNote: 'notes/selectedNote',
      selectedPlayer: 'livePlayers/selectedPlayer',
      humanError: 'notes/humanError',
    }),
  },

  async created() {
    await this.initialize();
  },

  components: {
    AlertError,
    NicknameDropdown,
    PlayerLiveDropdown,
  },

  data() {
    return {
        title: '',
        submitting: false,
        tags: [
            { name: 'Tag 1', value: 'tag1' },
            { name: 'Tag 2', value: 'tag2' },
        ],
      form: {
        title: null,
        note: null,
        associateLivePlayer: null,
        associateNickname: null,
        tags: [],
      },
      rules:{
        note: [
          v => !!v || 'Por favor adicione uma nota',
          v => (v && v.length <= 500) || 'A nota deve ter menos de 500 caracteres',
        ],
        associateLivePlayer: [
          (v) => !!v || 'Por favor preencha o nome do jogador'
        ],
      }
    };
  },

  watch: {
    isOpen() {
      if (this.isOpen) {
        this.resetForm();
        this.dialogTitle();
        this.populateForm();
      }
    },
  },

  methods: {
    toggleSubmitting(){
      this.submitting = !this.submitting;
    },

    async initialize(){
    },
    
    dialogTitle() {
      this.title = this.action == 'create' ? 'Adicionar nota' : 'Editar nota';
    },

    closeDialog(){
      this.$emit('close-dialog');
      this.resetForm();
    },

    populateForm() {
      if(this.action == 'create' && this.associatePlayerLive){
        this.form.associateLivePlayer = this.associatePlayerLive;
      }

      if (this.selectedNote && this.action == 'update') {
        this.form.note = this.selectedNote.text;
        this.form.title = this.selectedNote.title;
        this.form.associateLivePlayer = this.selectedNote.player;
      }
    },

    resetForm() {
      if (this.$refs && this.$refs.playerLiveDropdown) {
        this.$refs.playerLiveDropdown.$refs.formPlayerLiveDropdown.resetValidation();
      }
      this.form.title = null;
      this.form.note = null;
      this.form.associateLivePlayer = null;
      this.form.associateNickname = null;
      this.form.tags = [];
      
			// Reset validation
			if (this.$refs.formCreateNote)
				this.$refs.formCreateNote.reset();
      this.$store.dispatch('notes/clearError');
    },

    async handleSubmit() {
      if (!this.$refs.formCreateNote.validate()){
          this.$refs.playerLiveDropdown.$refs.formPlayerLiveDropdown.validate();
          return false;
      }

      if(!this.$refs.playerLiveDropdown.$refs.formPlayerLiveDropdown.validate()){
        return false;
      }

      this.toggleSubmitting();

      let { title, note, associateLivePlayer, associateNickname, tags } = this.form;

      let payload = {
        body: {
            player: {
              id: associateLivePlayer.id,
              name: associateLivePlayer.name,
              country: associateLivePlayer.country,
              picture: associateLivePlayer.picture,
            },
            tags: [],
            text: note,
            title: title ? title : 'Sem título',
        }
      }
      
      if (this.action == 'update') {
        payload.body.id = this.selectedNote.id;
        payload.body.created_at = this.selectedNote.created_at ? this.selectedNote.created_at : null;
        payload.body.created_by = this.selectedNote.created_by ? this.selectedNote.created_by : null;
        payload.body.last_updated_at = this.selectedNote.last_updated_at ? this.selectedNote.last_updated_at : null;
        payload.body.last_updated_by = this.selectedNote.last_updated_by ? this.selectedNote.last_updated_by : null;
      }

      let actionToDispatch = this.action == 'create'
        ? 'notes/create'
        : 'notes/update';
      
      let messageSnackbar = this.action == 'create'
        ? 'Nota adicionada com sucesso.'
        : 'Nota editada com sucesso.'

      let result = await this.$store.dispatch(actionToDispatch, payload);

      this.toggleSubmitting();
      
      if (result === true) {
        this.$store.dispatch('UI/showSnackbar', {
          message: messageSnackbar,
          color: 'success'
        });
        
        this.$store.commit('notes/setLastPayloadNotes', {});

        if (this.selectedPlayer && this.selectedPlayer.id === associateLivePlayer.id) {
          let payload = {
            params: {
              id: associateLivePlayer.id
            }
          }
          this.$store.dispatch('livePlayers/fetchSelectedPlayerNotes', payload);
        }
        
        this.$root.$emit('update-player-notes');

        this.closeDialog();
      }
     },
  },
};
