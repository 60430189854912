import Vue from 'vue';
import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth';
import DataMixin from '@/mixins/Data.vue';
import UserMixin from '@/mixins/User.vue';
import RailMixin from '@/mixins/Rail.vue';
import AlertError from '@/components/Alerts/AlertError/AlertError.vue';
import MetadataDropdown from '@/components/Rail/MetadataDropdown/MetadataDropdown.vue';
import DatePicker from '@/components/DatePicker/DatePicker.vue';
import UserDropdown from '@/components/Rail/UserDropdown/UserDropdown.vue';
import GroupDropdown from '@/components/Rail/GroupDropdown/GroupDropdown.vue';

export default {
    name: 'DialogAddLevelChange',

    components: {
        AlertError,
        MetadataDropdown,
        DatePicker,
        UserDropdown,
        GroupDropdown
    },

    props: {
        isOpen: {
            type: Boolean,
            required: true,
            default: false
        },
        playerSingle: {
            type: Object,
            required: false,
            default: function() { return {} },
        },
        direction: { type: String, required: false, default: null }, // valid values -> ['UP', 'DOWN' ]
        
    },

    mixins: [
        AuthMixin,
        DataMixin,
        UserMixin,
        RailMixin
    ],

    data() {
        return this.initialState();
    },

    watch: {
        showDetails: function (newVal, oldVal) {
            if (newVal === false) {
                this.form.avgBuyInEur = null;
                this.form.avgBuyInDol = null;
                this.form.numSplits = null;
                this.form.amountSplitsEur = null;
                this.form.amountSplitsDol = null;
                this.form.profitEur = null;
                this.form.profitDol = null;
            }
        },

        'form.reason': function (newVal, oldVal) {

            if (newVal == Vue.prototype.$GroupsChangeReason[7].key && this.form.user) {
                this.filterBy = null;

            } else if(newVal == Vue.prototype.$GroupsChangeReason[0].key && this.form.user || newVal == Vue.prototype.$GroupsChangeReason[1].key && this.form.user){
                if (this.form && this.form.user) {
                    this.form.newLevel = {id: this.form.user.group.previous};
                    this.filterBy = 'LOWER';
                }
            } else if(this.isOpen == true && this.form.user) {
                this.filterBy = 'HIGHER';
                this.form.newLevel = {id: this.form.user.group.next};
            }
        },

        'form.user': function (newVal) {
            if(newVal && newVal.group){
                this.form.actualLevel = { id:newVal.group.id, name: newVal.group.name, image: newVal.group.image };
            }
        },

        isOpen: function (newVal) {

            // clear errors
            this.$store.dispatch('groups/clearError');
            
            if (!newVal) return false;
            
            // populate status with "APPROVED" value
            this.form.status = Vue.prototype.$GroupChangeStatus[1].key;
            this.form.date = this.$moment().format('YYYY-MM-DD');
            this.form.reason = Vue.prototype.$GroupsChangeReason[7].key;

            if (this.playerSingle && Object.keys(this.playerSingle).length > 0) {
                this.form.user = this.playerSingle;
                this.form.actualLevel = {id: this.playerSingle.group.id, name: this.playerSingle.group.name, image: this.playerSingle.group.image};

                if(this.playerSingle.direction == 'UP'){
                    this.form.newLevel = {id: this.playerSingle.group.next};
                } else if (this.playerSingle.direction == 'DOWN' && (this.playerSingle.group.previous) >= 0) {
                    this.form.newLevel = {id: this.playerSingle.group.previous};
                } else {
                    if (this.direction == 'UP') {
                        this.form.newLevel = { id: this.form.user.group.next }
                    } else if (this.direction == 'DOWN') {
                        this.form.newLevel = { id: this.form.user.group.previous }
                    }
                }
            }
        }
    },

    computed: {
        ...mapGetters({
            error: 'groups/error',
            humanError: 'groups/humanError',
            userTrackerId: 'auth/userTrackerId',
        }), 
    },

    methods: {
        initialState() {
            return {
                submitting: false,
                statusValues: Vue.prototype.$GroupChangeStatus,
                groupChangeReason: Vue.prototype.$GroupsChangeReason,
                getStatusOk: 0,
                showDetails: false,
                filterBy: null,
                form: {
                    date: this.$moment().format('YYYY-MM-DD'),
                    status: null,
                    reason: null,
                    user: null,
                    actualLevel: {id: null, name: null, image: null},
                    newLevel: null,
                    avgBuyInEur: null,
                    avgBuyInDol: null,
                    numSplits: null,
                    amountSplitsEur: null,
                    amountSplitsDol: null,
                    profitEur: null,
                    profitDol: null,
                    daysOff: null,
                },
                toggleItems: [
                    { label: 'Stats Ok', value: 1, color: 'success' },
                    { label: 'Stats Não Ok', value: 0, color: 'warning' },
                ],
                rules: {
                    status: [ 
                        v => !!v || 'Por favor selecione um valor',
                    ],
                    reason: [ 
                        v => !!v || 'Por favor introduza um motivo',
                    ],
                    avgBuyInEur: [ 
                        v => !!v || 'Por favor introduza um valor',
                    ],
                    avgBuyInDol: [ 
                        v => !!v || 'Por favor introduza um valor',
                    ],
                    amountSplitsEur: [ 
                        v => !!v || 'Por favor introduza um valor',
                    ],
                    amountSplitsDol: [ 
                        v => !!v || 'Por favor introduza um valor',
                    ],
                    numSplits: [ 
                        v => !!v || 'Por favor introduza um valor',
                    ],
                    profitEur: [ 
                        v => !!v || 'Por favor introduza um valor',
                    ],
                    profitDol: [ 
                        v => !!v || 'Por favor introduza um valor',
                    ],
                }
            };
        },

        async submit() {
            if (!this.$refs.formCreateLevelChange.validate()) {
                return false;
            }

            this.toggleSubmitting();

            let payload = {
                date: this.form.date,
                user: this.form.user.id,
                status: this.form.status,
                statsOk: this.getStatusOk,
                reason: this.form.reason,
                group_1: this.form.actualLevel.id,
                group_2: this.form.newLevel.id,
            }

            if(this.showDetails == true){
                payload.details = {
                    abi:{
                        EUR: this.form.avgBuyInEur,
                        USD: this.form.avgBuyInDol,
                    },
                    splits:{
                        count: this.form.numSplits,
                        amount: {
                            USD: this.form.amountSplitsDol,
                            EUR: this.form.amountSplitsEur,
                        },
                    },
                    pnl: {
                        USD: this.form.profitDol,
                        EUR: this.form.profitEur,
                    },
                    daysOff: Number(this.form.daysOff) || null,
                }
            }
            let result = await this.$store.dispatch('groups/createLevelChange', payload);

            this.toggleSubmitting();

            if (result === true) {
                this.$store.dispatch('UI/showSnackbar', {
                    message: 'Alteração de nível adicionada com sucesso.',
                    color: 'success'
                });
            
                this.$emit('reload-data');

                let routeNames = ['Jogador #', 'Rail do Jogador', 'Desempenho do Jogador', 'Evolução do Jogador', 'Ficheiros do Jogador', 'Stats do Jogador'];
                
                if (routeNames.includes(this.$route.name)) {
                    //if in player single view, it's necessary to refresh all user info
                    this.$store.dispatch('users/getUser', this.$route.params.id);
                };

            this.closeDialog();
            }
        },

        toggleSubmitting() {
            this.submitting = !this.submitting;
        },

        closeDialog() {
            this.$emit('close-dialog');
            this.resetForm();
        },

        formIsValid(){
            if(this.form.user == null){
                return false;
            }
            return true;
        },

        resetForm() {
            Object.assign(this.$data, this.initialState());
            this.$refs.formCreateLevelChange.reset();
            this.$store.dispatch('groups/clearError');
        },
    },
};
