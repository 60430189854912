import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth';
import AlertError from '@/components/Alerts/AlertError/AlertError.vue';

export default {
    name: 'DialogRemovePlayerFromGroup',

    components: {
        AlertError,
    },

    props: {
        isOpen: {
            type: Boolean,
            required: true,
            default: false
        },
    },

    mixins: [
        AuthMixin,
    ],

    data() {
        return this.initialState();
    },

    computed: {
        ...mapGetters({
            error: 'users/error',
            humanError: 'users/humanError',
            groups: 'groups/groups',
            selectedGroupMember: 'groups/selectedGroupMember'
        }),
    },

    methods: {
        initialState() {
            return {
                submitting: false,
            };
        },

        async submit() {
            if (!this.$refs.formRemovePlayerFromGroup.validate()) {
                return false;
            }

            // Toggle submit button
            this.toggleSubmitting();

            let payload = {
                id: this.selectedGroupMember.id,
                group: 1 // Grupo Indefinido
            }

            let result = await this.$store.dispatch('users/updateUser', payload);

            // // Re-enable button
            this.toggleSubmitting();

            // On success
            if (result === true) {

                let payloadGroups = {
                    params: {
                        group: this.selectedGroupMember.group.id,
                    },
                }
    
                // refresh TableGroupMembers
                this.$store.dispatch('users/getUsersFromGroup', payloadGroups);

                // Show success snackbar
                this.$store.dispatch('UI/showSnackbar', {
                    message: 'Jogador removido com sucesso.',
                    color: 'success'
                });

                // closes dialog
                this.closeDialog();
            }
        },

        /**
         * Used to disable Submit button
         */
        toggleSubmitting() {
            this.submitting = !this.submitting;
        },

        closeDialog() {
            this.$emit('close-dialog');
            this.resetForm();
        },

        resetForm() {
            Object.assign(this.$data, this.initialState());
            this.$refs.formRemovePlayerFromGroup.reset();
            
            // clear errors
            this.$store.dispatch('users/clearErrors');
        }
    },
};
