import { mapGetters } from 'vuex';
import AlertError from '@/components/Alerts/AlertError/AlertError.vue';
import DatePicker from '@/components/DatePicker/DatePicker.vue';
import UserDropdown from '@/components/Rail/UserDropdown/UserDropdown.vue';

export default {
  name: 'DialogAddEditStats',
  props: {
    isOpen: {
      type: Boolean,
      required: true,
      default: false,
    },
    checkFields: {
      type: Boolean,
      required: false,
      default: null,
    },
    selectedUserId: {
      type: Number,
      required: false,
      default: 0,
    },

    action: {
			type: String,
			required: true,
			default: 'create',
			validator: function (value) {
				const allowedActions = ['create', 'update']
				return allowedActions.includes(value);
			},
		},
  },

  computed: {
    ...mapGetters({
      usersAllActive: 'users/usersAllActive',
      selectedUser: 'users/selectedUser',
      stats: 'stats/stats',
      selectedStat: 'stats/selectedStat',
      error: 'stats/error',
      humanError: 'stats/humanError',
      userTrackerId: 'auth/userTrackerId',
    }),
  },

  async created() {
    await this.initialize();
  },

  components:{
    AlertError,
    DatePicker,
    UserDropdown
  },

  data() {
    return {
        title: '',
        submitting: false,
        validForm: false,
        navBar: null,
        fieldsEmpty: false,
        checked: this.checkFields,
        userGroup: null,
      form: {
        name: {},
        ratingStats: null,
        date: this.$moment().format('YYYY-MM-DD'),
      },
      rules: {
        name: [ 
          v => {
            return !!v || 'Por favor introduza um nome para o grupo'
          }  
        ]
    },
    };
  },

  watch: {
    isOpen() {
      if (this.isOpen) {
        this.$store.dispatch('stats/clearError')
        this.resetForm();
        this.dialogTitle();
        this.isFormValid();
        this.populateForm();
      }
    },

    'form.name'(newVal){
      if(newVal && newVal.group){
        this.userGroup = newVal.group.statsOk;
      }
    },

    selectedStat() {
      if (this.isOpen) {
        this.dialogTitle();
        this.isFormValid();
        if(this.selectedStat && this.selectedStat.user_target_group){
          this.userGroup = this.selectedStat.user_target_group.statsOk;
        }
        this.populateForm();
      }
    }
  },
  methods: {
    toggleSubmitting(){
      this.submitting = !this.submitting;
    },

    async initialize(){
      if (this._.isEmpty(this.selectedUser)) {
        await this.$store.dispatch('users/getUser', this.userTrackerId);
      }
    },

    isFormValid() {
      if(this.form.name === ''){
        return this.validForm = false
      }
      return this.validForm = true
    },
    
    dialogTitle() {
      this.title = this.action == 'create' ? 'Adicionar registo de stats' : 'Editar registo de stats';
    },

    closeDialog(){
      this.$emit('close-dialog');
      this.resetForm();
    },

    populateForm() {
      if(this.action == 'create'){
        if(this.selectedUserId > 0){
          this.form.name = {id: this.selectedUserId};
        }else{
          this.form.name = {id: this.userTrackerId};
          this.userGroup = this.selectedUser.group.statsOk;
        }
      }

      if (this.selectedStat && this.action == 'update') {
        this.form.name = {id: this.selectedStat.user_target.id};
        this.form.ratingStats = this.selectedStat.value;
        this.form.date = this.$moment(this.selectedStat.createdAt.date).format('YYYY-MM-DD');
      }
    },

    resetForm() {
      this.form.name = null;
      this.form.ratingStats = null;

      this.$refs.formCreateStat.reset();
      
      this.$store.dispatch('stats/clearError');
    },

    async submitForm() {
      if (!this.$refs.formCreateStat.validate()) {
        return false;
      }

      this.toggleSubmitting();

      let { name, ratingStats, date } = this.form;

      let payload = {
        body: {
          value: Number(ratingStats),
          user: name.id,
          date: date
          }
      }
      
      if (this.action == 'update') {
        payload.id = this.selectedStat.id;
      }

      let actionToDispatch = this.action == 'create'
        ? 'stats/create'
        : 'stats/update';
      
      let messageSnackbar = this.action == 'create'
        ? 'stat adicionada com sucesso.'
        : 'stat editada com sucesso.'

      let result = await this.$store.dispatch(actionToDispatch, payload);

      this.toggleSubmitting();
      
      if (result === true) {
        this.$store.dispatch('UI/showSnackbar', {
          message: messageSnackbar,
          color: 'success'
        });
        // reset last stats payload in order to refresh TableStats 
        this.$store.commit('stats/setLastPayload', {});

        // refresh stats
        this.$emit('reload');
        this.closeDialog();
      }
    },
  },
};
