export default {
    name: 'ScheduleTable',
    
    props: {
        annotations: { type: String, required: false, default: "" },
        blue: { type: Number, required: false, default: 0 },
        bubble_rush: { type: Number, required: false, default: 0 },
        early_bird: { type: Number, required: false, default: 0 },
        fast: { type: Number, required: false, default: 0 },
        hyper: { type: Number, required: false, default: 0 },
        red: { type: Number, required: false, default: 0 },
        regular: { type: Number, required: false, default: 0 },
        regular_pko: { type: Number, required: false, default: 0 },
        sng: { type: Number, required: false, default: 0 },
        splash: { type: Number, required: false, default: 0 },
        turbo: { type: Number, required: false, default: 0 },
        turbo_pko: { type: Number, required: false, default: 0 },
        exception_specials: { type: String, required: false, default: "" },
        exception_sunday: { type: String, required: false, default: "" },
        exception_weekend: { type: String, required: false, default: "" },
        series: { type: Array, required: false, default: () => [] },
    },
    
    computed: {
        exception_specials_array() {
            if (this.exception_specials) return this.exception_specials.split(";");
            else return [];
        },
        
        exception_sunday_array() {
            if (this.exception_sunday) return this.exception_sunday.split(";");
            else return [];
        },
        
        exception_weekend_array() {
            if (this.exception_weekend) return this.exception_weekend.split(";");
            else return [];
        },
    },
    
    data() {
        return {
            data: null,
        };
    },
}