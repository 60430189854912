import Vue from 'vue';
import { mapGetters } from 'vuex';
import DataMixin from '@/mixins/Data'

export default {
    name: 'dialog-remove-access',
    mixins: [
        DataMixin
    ],
    props: {
        dialogOpen: {
            type: Boolean,
            required: true,
            default: false
        },
        usernameId: {
            type: Number,
            required: false,
            default: null,
        },
        nameVideo: {
            type: String,
            required: false,
            default: null,
        },
    },
    computed: {
        ...mapGetters({
            selectedVideo: 'videoRequests/selectedVideo',
            users: 'users_v1/users',
        }),
    },
    data() {
        return this.initialState();
    },
    methods: {
        initialState() {
            return {
                name_video: this.nameVideo,
                userId: this.usernameId,
                expiry: 0,
                form: {
                    valid: true,
                    status_options: [
                        {
                            id: Vue.prototype.$video_requests.status.expired,
                            text: 'Expirado'
                        },
                        {
                            id: Vue.prototype.$video_requests.status.rejected,
                            text: 'Rejeitado'
                        },
                        {
                            id: Vue.prototype.$video_requests.status.complete,
                            text: 'Concluído'
                        }
                    ],
                    value: null,
                    status_rules: [(v) => !!v || 'Por favor selecione um estado']
                }
            };
        },
        submit() {
            // Validate form
            if (!this.$refs.form.validate()) return false;

            // Wait 0.5s before proceeding because v-combobox are difficult to validate
            setTimeout(() => {
                if (!this.form.valid) return false;

                // Build payload
                let payload = {
                    id: this.selectedVideo.id_video_requests,
                    status: this.form.value.id
                };

                // Make expiry date current timestamp, is status=expired
                if (payload.status == Vue.prototype.$video_requests.status.expired)
                    payload.expiry = this.generateMySQLDateTime();

                // Build result
                const result = this.$store.dispatch(
                    'videoRequests/updateRequestVideo',
                    payload
                );

                /** Close dialog and return */

                // Text to place on snackbar
                let marked_as = null;
                switch (payload.status) {
                    case Vue.prototype.$video_requests.status.expired:
                        marked_as = 'Expirado';
                        break;
                    case Vue.prototype.$video_requests.status.rejected:
                        marked_as = 'Rejeitado';
                        break;
                    case Vue.prototype.$video_requests.status.complete:
                        marked_as = 'Concluído';
                        break;
                    default:
                        break;
                }
                this.closeDialog();
                this.$store.dispatch('UI/showSnackbar', {
                    message: 'O pedido de video foi marcado como ' + marked_as + '.',
                    color: 'success',
                });
                return result;
            }, 500);
        },
        closeDialog() {
            this.$emit('closeDialog');
        },
        getUserDisplayNameById(id) {
            if (this.users) {
                let user = this.users.filter((e) => e.id_users == id);
                return user[0]['display_name_users'];
            }
        },
        populateFields() {
            this.name_video = this.selectedVideo.wp_post_title_video_requests;
            this.userId = this.selectedVideo.ref_users_video_requests;
        },
    },
};
