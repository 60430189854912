import Vue from 'vue';
import { mapGetters } from 'vuex';
import AuthMixin from "@/mixins/Auth.vue";
import EmptyStateNoData from '@/components/EmptyStateNoData';

export default {
    name: 'UserEventList',

    components: {
        EmptyStateNoData,
    },

    props: {
        title: {
            type: String,
            required: false,
            default: 'Events',
        },
        showDateAgo: {
            type: Boolean,
            required: false,
            default: false,
        },
        items: {
            type: Array,
            required: false,
            default: () => [],
        },
        virtualScrollHeight: {
            type: String,
            required: false,
            default: '',
        },
        hasLink: {
            type: Boolean,
            required: false,
            default: true,
        },
        singleOperation: {
            type: Boolean,
            required: false,
            default: false,
        },
        userId: {
            type: String,
            required: false,
            default: null,
        },

    },

    mixins: [
        AuthMixin,
    ],

    data() {
        return this.initialState();
    },

    created() {
        this.refresh();
    },

    computed: {
        ...mapGetters({
            events: 'systemEvents/events',
            loading: 'systemEvents/loading',
            heroDatePickerValue: 'UI/heroDatePickerValue',
            hasLoadedOperationEvents: 'railRequests/hasLoadedOperationEvents',
            operationEvents: 'railRequests/operationEvents',
            selectedRequest: 'railRequests/selectedRequest',
        }),

        loadingComputed: function () {
            if (this.singleOperation) {
                return !this.hasLoadedOperationEvents;
            } else {
                return this.loading;
            }
        },

        tableData: function () {
            if (this.singleOperation) {
                return this.operationEvents;
            } else {
                return this.events;
            }
        },
    },

    watch: {
        heroDatePickerValue: function () {
            this.refresh();
        },
        selectedRequest: {
            handler() {
              if (this.selectedRequest && this.singleOperation) {
                this.fetchSystemEventsFromSingleOperation(this.selectedRequest.id);
              }
            },
            deep: true,
            immediate: true,
        },
        userId: function () {
            this.refresh();
        },
    },

    methods: {
        initialState() {
            return {
                itemsPerPage: 25,
                page: 1,
                form: {
                    startDate: null,
                    endDate: null,
                },
                endpointCalled: false,
            }
        },

        refresh() {            
            this.fetchDates();
            if (! this.singleOperation) {
                this.fetchData();
            }
        },

        fetchDates() {
            this.form.startDate = this.heroDatePickerValue[0];
            this.form.endDate = this.heroDatePickerValue[1];
        },

        fetchData() {
            if (!this.endpointCalled){
                let payload = {
                    params: {
                        page: this.page,
                        itemsPerPage: this.itemsPerPage,
                        dateBegin: this.form.startDate,
                        dateEnd:  this.form.endDate
                    }
                }
    
                if (this.userId) {
                    payload.params.user = this.userId;
                }
                this.$store.dispatch('systemEvents/get', payload);
                this.endpointCalled = true;
            }
        },

        displayDate(item) {
            if (this.showDateAgo) {
                return this.$moment(item.date.date).fromNow();
            } else {
                return this.$moment(item.date.date).format('LLL');
            }
        },

        getEventIcon(item) {
            if (item) {
                let eventTypes = Vue.prototype.$system_event_types;
                let event = eventTypes.find(element => element.id == item.type);
                return event.icon;
            }
        },

        /**
         * <router-link> :to prop expects String, Object. Doesn't accept Null values.
         * @param {*} item 
         * @returns String link
         */
        genPlayerLink(item) {
            return item.user
                   ? 'players/' + item.user.id
                   : '#';
        },

        genPlayerName(item) {
            return this.user
                   ? item.user.displayName
                   : null;
        },

        fetchSystemEventsFromSingleOperation(id) {
            this.$store.dispatch('railRequests/getEventsFromOperation', id);
        },
    }
}
