import AuthMixin from '@/mixins/Auth';
import SwapsView from '@/components/TabSlider/Tabs/Swaps/Swaps/Swaps.vue';
import SwapPoolView from '@/components/TabSlider/Tabs/Swaps/SwapPool/SwapPool.vue';

export default {
  name: 'Swaps',

  mixins: [
    AuthMixin,
  ],

  components: {
    SwapsView,
    SwapPoolView,
  },

  computed: {
      componentToShow() {
      return this.$route.params.id && this.$route.name == 'Grupo de swap'
        ? 'SwapPoolView'
        : 'SwapsView';
    },
  },
}