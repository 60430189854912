<template>
  <v-form
    ref="formSplitRouting"
    lazy-loading
    class="fill-height"
  >
    <component
      :is="componentToShow" 
      ref="formSplitChild"
    />
  </v-form>
</template>

<script>
import CardSplitUser from "./user/CardSplit.vue";
import CardSplitAdmin from "./admin/CardSplit.vue";
import AuthMixin from "@/mixins/Auth.vue";

export default {
	name: "CardSplit",

	components: {
		CardSplitUser,
		CardSplitAdmin
		},

	mixins: [
		AuthMixin
		],

	computed: {
		componentToShow() {
			let pageToShow = this.userIsRailManager
				? "CardSplitAdmin"
				: "CardSplitUser";

			return pageToShow;
		},
	},
	
	methods: {
		/** This CardSplit.vue is focus on routing/component to show based on user role
		 *  When Split view (current action)  by clicking submit button it triggers this submit() present in this file
		 *  This submit() triggers the submit() in child component with ref formSplitChild
		 */
		submit() {
			this.$refs.formSplitChild.submit();
		},
	},
};
</script>