import Vue from 'vue';
import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth';
import DataMixin from '@/mixins/Data';
import RailMixin from '@/mixins/Rail.vue';
import DialogEdit from '@/components/Players/DialogEdit.vue';
import EmptyStateNoData from '@/components/EmptyStateNoData';
import DialogAddLevelChange from '@/components/Dialog/DialogAddLevelChange/DialogAddLevelChange.vue';
import UserCard from '@/components/UserCard/UserCard.vue';

export default {
    name: 'TableUser',

    components: {
        DialogEdit,
        EmptyStateNoData,
        DialogAddLevelChange,
        UserCard,
    },

    mixins: [
        AuthMixin,
        DataMixin,
        RailMixin,
    ],

    props:{
        filterProp: { type: Object, required: false, default: () => { return { action: '', id: '' } } },
    },

    data() {
        return this.initialState();
    },

    computed: {
        ...mapGetters({
            users: 'users/users',
            usersActive: 'users/usersActive',
            usersInactive: 'users/usersInactive',
            usersOut: 'users/usersOut',
            usersTotal: 'users/usersTotal',
            usersActiveTotal: 'users/usersActiveTotal',
            usersInactiveTotal: 'users/usersInactiveTotal',
            usersOutTotal: 'users/usersOutTotal',
            usersLoading: 'users/loading',
            usersActiveLoading: 'users/usersActiveLoading',
            usersInactiveLoading: 'users/usersInactiveLoading',
            usersOutLoading: 'users/usersOutLoading',
            teamFilter: 'teams/teamFilter',
        }),

        emptyStateMessage: function () {
            let output;
            switch (this.tabs.current) {
                case 0:
                    output = "Não existem utilizadores ativos";
                    break;
                case 1:
                    output = "Não existem utilizadores inativos";
                    break;
                case 2:
                    output = "Não existem jogadores sem estado";
                case 3:
                    output = "Não existem jogadores";
                default:
                    output = "Não existem jogadores";
            }
            return output;
        },

        tableData: function () {
            let output;
            switch (this.tabs.current) {
                case 0:
                    output = this.usersActive;
                    break;
                case 1:
                    output = this.usersInactive;
                    break;
                case 2:
                    output = this.usersOut;
                    break;
                case 3:
                    output = this.users;
                    break;
                default:
                    break;
            }
            return output;
        },

        tableTotal: function () {
            let output;
            switch (this.tabs.current) {
                case 0:
                    output = this.usersActiveTotal;
                    break;
                case 1:
                    output = this.usersInactiveTotal;
                    break;
                case 2:
                    output = this.usersOutTotal;
                    break;
                case 3:
                    output = this.usersTotal;
                    break;
                default:
                    break;
            }
            return output;
        },

        tableLoading: function () {
            let output;
            switch (this.tabs.current) {
                case 0:
                    output = this.usersActiveLoading;
                    break;
                case 1:
                    output = this.usersInactiveLoading;
                    break;
                case 2:
                    output = this.usersOutLoading;
                    break;
                case 3:
                    output = this.usersLoading;
                    break;
                default:
                    break;
            }
            return output;
        },

        optionsComputed: {
            get: function () {
              return this.options;
            },
            set: function (newValue) {
                if (!this._.isEqual(this.options, newValue)) {
                    this.options = newValue;
                }
            }
        }
    },

    watch: {
        // watches this.options object
        // triggers everytime this.options has changed
        options: {
            handler() {
                this.filter();
            },
            deep: true,
        },

        // watches this.tabs object
        // resets this.options object everytime tab has changed
        tabs: {
            handler() {
            this.options = {
                page: 1,
                itemsPerPage: 10,
                sortBy: [],
                sortDesc: [],
                groupBy: [],
                groupDesc: [],
                mustSort: false,
                multiSort: false
            }
            },
            deep: true,
        },

        teamFilter: function () {
            this.filter();
        },
    },

    async created() {
        this.initialize();
    },

    methods: {
        initialState() {
            return {
                direction: null,
                activeUser: null,
                dataTable: {
                    headers: [
                        { text: 'Jogador', align: 'left', value: 'name', sortable: true, width: "10%", },
                        { text: 'Nível', value: 'group', sortable: true, width: "320px", },
                        { text: 'Equipas', value: 'teams', sortable: false },
                        { text: 'Contactos', value: 'contact', sortable: false },
                        { text: 'Estado', value: 'status', sortable: true, width: "60px", },
                        { text: 'Ação', value: 'action', sortable: false },
                    ],
                    footerProps: {
                        'items-per-page-options': this.$itemsPerPageOptions,
                    },
                    search: null,
                    itemsPerPage: 10
                },
                tabs: {
                    current: 0,
                    headers: ['Ativos', 'Inativos', 'Outros', 'Todos']
                },
                options: {
                    page: 1,
                    itemsPerPage: 10,
                    sortBy: [],
                    sortDesc: [],
                    groupBy: [],
                    groupDesc: [],
                    mustSort: true,
                    multiSort: false,
                },
                dialogues: {
                    edit: false,
                    editUserLevel: false
                }
            }
        },

        editUserLevel(item, direction) {
            this.activeUser = item;
            this.direction = direction;
            this.dialogues.editUserLevel = true;
        },

        async initialize() {
            this.filter();
        },

        /**
         * Populates payload object and makes API Call 
         * @param {Integer} status = '' by default
         * @returns users
         */
        async fetchData(status = '') {
            // Base payload
            let payload = {
                params: {
                    page: this.options.page,
                    itemsPerPage: this.options.itemsPerPage,
                    status: status
                }
            };

            // if team exists and isManagement, Add team parameter to payload.params
            if (this.teamFilter && this.userHasRailAccessDivisionDropdown) {
                payload.params.team = this.teamFilter;
            }

            // Add search parameter to payload.params
            if (!this._.isEmpty(this.dataTable.search)) {
                payload.params.search = this.dataTable.search;
            }

            // Add sortDesc parameter to payload.params
            Object.assign(payload.params, this.sortDescHandler(this.options.sortDesc));

            // Add sortBy parameter to payload
            this.addParameterToPayload(payload, 'sortBy[]', this.options.sortBy);

            if(this.filterProp.action == 'teamById'){
                this.addParameterToPayload(payload, 'team', this.filterProp.id);
            }

            // API Call
            return await this.$store.dispatch('users/get', payload);
        },

        /**
         * Populates status based on current tab
         * @returns status value and triggers fetchData()
         */
        filter() {
            let status;
            switch (this.tabs.current) {
                case 0:
                    // active
                    status = Vue.prototype.$user_status[1].value;
                    break;
                case 1:
                    // inactive
                    status = Vue.prototype.$user_status[0].value;
                    break;
                case 2:
                    // no stats
                    status = Vue.prototype.$user_status[2].value;
                    break;
                case 3:
                    status = '';
                    break;
                default:
                    // active
                    status = Vue.prototype.$user_status[1].value;
                    break;
            }

            return this.fetchData(status);
        },

        // trigered by v-text-field @input="search()"
        search() {
            let lastValue = this.dataTable.search;
            
            setTimeout(() => {
                if (lastValue == this.dataTable.search) {
                    // Manually trigger API call if page = 1
                    if (this.options.page == 1) {
                        this.filter();
                    }
                    // This triggers API call if page != 1 due to watcher
                    else {
                        this.options.page = 1;
                    }
                }
            }, 1500);
        },

        async editItem(item) {

            // Populate if editing already existing player
            if (item) {
                await this.$store.dispatch('users/getUserMeta', item.id);
                this.$store.commit('users/setSelectedUser', item);
            }

            this.dialogues.edit = true;
        },

        triggerCopyToClipboard(content) {
            this.copyToClipboard(content);
      
            // Show snackbar
            this.$store.dispatch('UI/showSnackbar', {
              message: "Nome '" + content + "' copiado com sucesso.",
              color: 'success'
            });
        },

        filteredTeams(item) {
            return item.teams.slice(0, 2);
        },

        imageCutout(item) {
            let placeholder = require('@/assets/images/players/player-example.png');
            return item && item.imgCutout ?  item.imgCutout : placeholder;
        },
    }
}