/**
 * Import app-wide dependencies
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Config from './config'
import store from "./store/index";

/**
 * Load plugins
 */
import vuetify from '@/plugins/vuetify';
import '@/plugins/vue-cookies';
import '@/plugins/vue-moment';
import '@/plugins/vuetify-datetime-picker';
import '@/plugins/clipboard';
import '@/plugins/lodash';
import '@/plugins/vue-currency-input';
import '@/plugins/vue-apexcharts';
import '@/plugins/vue-json-csv';

/**
 * Load Styles
 */
import './scss/main.scss';

/**
 * App configuration
 */

Vue.config.silent = false;
Vue.config.productionTip = true;
Vue.prototype.$polarize_site = 'https://www.google.com/';

const app = new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app');