import { mapGetters } from "vuex";
import Hero from '@/components/Hero/Hero.vue';
import DataMixin from '@/mixins/Data.vue';
import AuthMixin from '@/mixins/Auth.vue';
import RailMixin from '@/mixins/Rail.vue';
import TableStats from "../../components/Tables/TableStats/TableStats.vue";
import UserDropdown from '@/components/Rail/UserDropdown/UserDropdown.vue';
import GroupDropdown from '@/components/Rail/GroupDropdown/GroupDropdown.vue';
import ChartStatsOkPctPerLevel from '@/components/Charts/ChartStatsOkPctPerLevel/ChartStatsOkPctPerLevel.vue';
import ChartAvgStatsPerLevel from '@/components/Charts/ChartAvgStatsPerLevel/ChartAvgStatsPerLevel.vue';

export default{
    name: 'Stats-Admin',

    components: {
        Hero,
        TableStats,
        UserDropdown,
        GroupDropdown,
        ChartStatsOkPctPerLevel,
        ChartAvgStatsPerLevel,
    },

    mixins: [
        DataMixin,
        AuthMixin,
        RailMixin,
      ],

    computed: {
        ...mapGetters({
            groups: 'groups/groups',
            users: 'users/users',
            heroDatePickerValue: 'UI/heroDatePickerValue',
        }),
    },

    created() {
        this.fetchData();
    },

    data() {
        return this.initialState();
    },

    watch: {
        filters: {
            handler(newFilters, oldFilters) {
                if(typeof newFilters.groups == 'array' || typeof newFilters.groups == 'undefined'){
                    newFilters.groups = null;
                }
                if (this.$refs.tableStats) {
                    this.$refs.tableStats.updateTable(newFilters);
                }
            },
            deep: true,
        },

        heroDatePickerValue: function () {
            this.fetchStatsStatistics();  
        },
      },

    methods:{
        initialState(){
            return {
                filters:{
                    users: null,
                    groups: null,
                    stats: null,
                },
                stats:[
                    {label: 'OK', value: 1},
                    {label: 'Não OK', value: 0},
                ],
            }
        },

        async loadData() {
            this.fetchStatsStatistics();
            await this.$store.dispatch('groups/get');
            await this.$store.dispatch('users/getUsersAllActive');
        },

        async fetchData() {
            await this.loadData();
        },

        async fetchStatsStatistics() {
            let payload = {}
      
            // Add date params
            if (!this._.isEmpty(this.heroDatePickerValue)) {
              payload.dateBegin = this.heroDatePickerValue[0];
              payload.dateEnd = this.heroDatePickerValue[1];
            }
              
            await this.$store.dispatch('railStatistics/getStatsStatistics', payload);
          },
    }
}