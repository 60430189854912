<template>
  <v-dialog
    :value="dialogOpen"
    max-width="600"
  >
    <v-card>
      <v-card-title class="headline">
        Agendar evento
      </v-card-title>

      <v-card-text>
        Esta informação ficará guardada no calendário. É alocada 1 hora para
        cada entrevista.
      </v-card-text>

      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="eventName"
              type="text"
              label="Título do evento*"
              required
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-textarea
              v-model="eventInfo"
              label="Descrição / anotações"
              hint="Aponta as informações mais relevantes para este evento."
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-datetime-picker
              v-model="eventStartDatetime"
              label="Hora de começo"
            >
              <template slot="dateIcon">
                <v-icon>mdi-calendar</v-icon>
              </template>
              <template slot="timeIcon">
                <v-icon>mdi-alarm</v-icon>
              </template>
            </v-datetime-picker>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn
          color="error"
          text
          @click="close()"
        >
          Cancelar
        </v-btn>

        <v-btn
          color="success"
          text
          @click="submit()"
        >
          Agendar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import DataMixin from '@/mixins/Data';

export default {
	name: 'DialogScheduleCalendarEvent',

	mixins: [DataMixin],

	props: {
		dialogOpen: { type: Boolean, required: true, default: false },
	},

	data() {
		return {
			eventName: null,
			eventInfo: null,
			eventStartDatetime: null,
		};
	},

	computed: {
		...mapGetters({
			selectedApplication: 'applications/selectedApplication',
		}),
	},

	created() {
		this.initialize();
	},

	methods: {
		async initialize() {},

		async submit() {
			// Form payload
			// Format startDatetime, Generate endDatetime (60 minutes / interview)
			let payload = {
				name: this.eventName,
				info: this.eventInfo,
				start: moment(this.eventStartDatetime).format(
					'YYYY-MM-DD HH:mm:ss'
				),
				end: moment(this.eventStartDatetime, 'hh:mm:ss A')
					.add(60, 'minutes')
					.format('YYYY-MM-DD HH:mm:ss'),
				assocTable: 'applications',
				assocReference: this.selectedApplication.id_applications,
			};

			// Call store
			await this.$store.dispatch('calendar/createEvent', payload);

			// UI
			this.close();
		},

		close() {
			this.$emit('close-dialog');
			this.resetData();
		},

		resetData() {
			this.eventName = null;
			this.eventInfo = null;
			this.eventStartDatetime = null;
		},
	},
};
</script>

<style scoped></style>