import Vue from 'vue';
import { mapGetters } from 'vuex';
import DataMixin from '@/mixins/Data.vue';
import AuthMixin from '@/mixins/Auth.vue';
import UserMixin from '@/mixins/User.vue';
import RailMixin from '@/mixins/Rail.vue';
import NetworkPicker from '@/components/Rail/NetworkPicker/NetworkPicker.vue';
import DatePicker from '@/components/DatePicker/DatePicker.vue';
import AlertError from '@/components/Alerts/AlertError/AlertError.vue';
import DialogConfirmation from '@/components/Rail/Dialog/DialogConfirmation/DialogConfirmation.vue';
import InputFinancial from '@/components/InputFinancial/InputFinancial.vue';
import DialogSessionGamification from '../DialogSessionGamification/DialogSessionGamification.vue';
import SessionLiveMetadata from '@/components/Rail/SessionLiveMetadata/SessionLiveMetadata.vue';

export default {
	name: 'DialogAddEditSession',

	components: {
		NetworkPicker,
		DatePicker,
		AlertError,
		DialogConfirmation,
		InputFinancial,
		DialogSessionGamification,
		SessionLiveMetadata
	},

	mixins: [
		DataMixin,
		AuthMixin,
		UserMixin,
		RailMixin,
	],

	props: {
		isOpen: { type: Boolean, required: true, default: false },
		activities: {
			type: Array,
			required: false,
			default: () => [
				{ text: 'Warm Up', value: 1 },
				{ text: 'Meditação', value: 2 },
			],
		},
		action: {
			type: String,
			required: true,
			default: 'create',
			validator: function (value) {
				const allowedActions = ['create', 'update']
				return allowedActions.includes(value);
			},
		},
		reloadSingleUser: { type: Boolean, required: false, default: false },
		userId: { type: [String, Number], required: false, default: null },
	},

	computed: {
		...mapGetters({
			selectedSession: 'railSessions/selectedSession',
			gamification: 'railSessions/gamification',
			error: 'railSessions/error',
			humanError: 'railSessions/humanError',
			selectedNickname: 'nicknames/selectedNickname',
			userTrackerId: 'auth/userTrackerId',
			selectedUser: 'users/selectedUser',
			userTeams: 'users/userTeams',
			userTeamsLoading: 'users/userTeamsLoading',
			heroDatePickerValue: 'UI/heroDatePickerValue',
			teamFilter: 'teams/teamFilter',
			themeDarkMode: 'UI/themeDarkMode',
			existsItemsAvailableAfterFilter: 'nicknames/existsItemsAvailableAfterFilter',
			numberOfItemsAvailableAfterFilter: 'nicknames/numberOfItemsAvailableAfterFilter',
			hasLoadedNicknamesAllMine: 'nicknames/hasLoadedNicknamesAllMine',
		}),

		/**
		 * passed through prop to NetworkPicker
		 * @returns {Object|null} user
		 */
		user: function () {
			let data = this.selectedNickname
				? this.selectedNickname
				: this.selectedSession;

			// if is user and is creating new Session
			if (this.userIsPlayer && this.action == 'create' && this.isOpen) {
				if (this._.isNull(this.selectedUser)) {
					this.fetchUser(this.userTrackerId);
				}
				return this.selectedUser;
			}

			// if is admin and is in Single user page
			if (this.userIsRailManager && this.reloadSingleUser) {
				return this.selectedUser;
			}

			// if user exists in selectedSession or selectedNickname
			if (data && data.user) {
				return data.user;
			}

			return null;
		},

		/**
		 * @returns {String} mtt|cash|spins|null Division code or NULL if unknown
		 */
		userDivision: function () {
			let userTeamIds = [];

			if (this.user && this.user.teams) {
				userTeamIds = _.map(this.user.teams, 'id');
			}

			if (userTeamIds.includes(Vue.prototype.$team_ids.division_mtt)) {
				return 'mtt';
			} else if (userTeamIds.includes(Vue.prototype.$team_ids.division_cash)) {
				return 'cash';
			} else if (userTeamIds.includes(Vue.prototype.$team_ids.division_spins)) {
				return 'spins';
			} else {
				return null;
			}
		},

		/**
		 * Duplicate of DialogViewSession::eventsPlayedLabel computed property
		 * @returns {String} label
		 */
		eventsPlayedLabel: function () {
			let output = 'Nº de eventos';

			if (['mtt', 'spins'].includes(this.userDivision)) {
				output = 'Nº de torneios';
			} else if (['cash'].includes(this.userDivision)) {
				output = 'Nº de mãos';
			}

			return output;
		},

		isSessionLive: function () {
			let output = false;

			if (this.form.networkPicker && !this._.isEmpty(this.form.networkPicker.nicknames)) {
				let networkIDs = this.form.networkPicker.nicknames.map(e => e.network ? e.network.id : null);
				output = networkIDs.includes(Vue.prototype.$rail_liveEUR_network_id) || networkIDs.includes(Vue.prototype.$rail_liveUSD_network_id);
			}
			return output;
		}
	},

	watch: {
		// When form is reopened, reset it and fill date
		isOpen: function (newVal, oldVal) {
			if (newVal === true) {
				// Clear errors such as "sessions not found" associated with requests not regarding add/edit session
				this.$store.dispatch('railSessions/clearError');

				this.fetchLiveEvents()

				// Reset the form
				this.resetForm();

				// need timeout to render element and then validate if exists to reset validation
				setTimeout(() => {
					if (this.$refs && this.$refs.numberOfEvents) {
						this.$refs.numberOfEvents.resetValidation();
					}

					if (this.$refs && this.$refs.minutesPlayed) {
						this.$refs.minutesPlayed.resetValidation();
					}
				}, 50);

				// Pre-fill date field
				if (!this.form.date) {
					this.form.date = this.getCurrentDate();
				}
			}
		},

		selectedSession: function () {
			if (this.isOpen) {
				this.populate();
			}
		},

		'form.user': function () {
			// when user changes, fetch his teams
			if (this.form.user) {
				if (!this.loadingFetchUserTeams) {
					this.fetchUserTeams(this.form.user.id);
				}
			};
		},
	},

	data() {
		return this.initialState();
	},

	async created() {
		// if is Player user, make API call to get his own info
		if (this.userIsPlayer && this.isOpen) {
			await this.fetchUser(this.userTrackerId);
		};
	},

	methods: {
		initialState() {
			return {
				messageDateAlert: 'Data definida para o dia anterior. Altera-a se iniciaste a sessão já no dia de hoje.',
				datePickerMenu: false,
				loadingFetchUserTeams: false,
				activeDialogSuccess: false,
				gamificationSession: {},
				feedbackDialog: null,
				showComponent: false,
				form: {
					submitting: false,
					deleting: false,
					date: this.getCurrentDate(),
					user: null,
					minutesPlayed: null,
					eventsPlayed: null,
					networkPicker: null,
					activities: [],
					avgNumTables: null,
					avgBuyin: null,
					chipEv: null,
					chipNetWon: null,
					rakeback: null,
					metadata: []
				},
				dialogues: {
					delete: false,
				},
				rules: {
					date: [
						(v) => !!v || 'Introduza uma data',
						(v) => this.dateDiff(this.$moment(), v) >= 0 || 'A data não pode ser futura',
					],
					NetworkPicker: [
						(v) => !v.length || 'Selecione pelo menos uma sala'
					],
					notNull: [
						(v) => !!v || 'Introduza um valor',
					],
				},
			};
		},

		/**
		 * @param {Integer} id 
		 */
		async fetchUser(id) {
			if (id) {
				await this.$store.dispatch('users/getUser', id);
			}
			this.form.user = this.selectedUser;
		},

		async fetchUserTeams(id) {
			if (this.userTeamsLoading) {
                return false;
			}
			
			this.loadingFetchUserTeams = true;

			if (id) {
				await this.$store.dispatch('users/getUserTeams', id);
			}

			this.loadingFetchUserTeams = false;
		},

		/**
		 * Populates form fields
		 */
		populate() {
			if (this.selectedSession) {
				// we initiialize this.form.activities with an empty array in order to clean it everytime we change selected session
				this.form.date = this.$moment(this.selectedSession.date.date).format('YYYY-MM-DD');
				this.form.minutesPlayed = this.selectedSession.minutesPlayed;
				this.form.eventsPlayed = this.selectedSession.eventsPlayed;
				this.form.meditation = this.selectedSession.meditation;
				this.form.warmup = this.selectedSession.warmup;
				this.form.activities = [];

				// If warmup is true, then we push it to this.form.activities[] in order to appear in v-combobox element view as selected
				if (this.form.warmup)
					this.form.activities.push(this.activities[0]);
				if (this.form.meditation)
					this.form.activities.push(this.activities[1]);
			}
		},

		/**
		 * @returns {String} formatted date
		 */
		getCurrentDate() {
			return this.$moment().format('YYYY-MM-DD');
		},

		handleFeedbackClosed(val) {
			this.feedbackDialog = val
			this.closeDialog();
			let snackbarMsg = this.action == 'create'
			  ? 'Sessão guardada com sucesso.'
			  : 'Sessão atualizada com sucesso.';
			this.$store.dispatch('UI/showSnackbar', {
			  message: snackbarMsg,
			  color: 'success'
			});
		  },

		/**
		 * Submits form
		 */
		async submit() {
			// Validate if user has selected networks
			if (!this.$refs.NetworkPicker.$data.sessionNicknamesList.length) {
				this.$refs.NetworkPicker.$refs.form.validate();

				this.$refs.NetworkPicker.setShowError(true);
				return false;
			}
			
			if (this.$refs && this.$refs.SessionLiveMetadata) {
				if (! this.$refs.SessionLiveMetadata.$refs.formMetadata.validate()) {
					return false;
				}
			}

			// Validate form
			if (! this.$refs.formRailAddSession.validate()) {
				return false;
			}

			// Clear any NetworkPicker errors
			this.$refs.NetworkPicker.setShowError(false);

			// Disable submit btn
			this.toggleFormSubmitting();
			
			// Generate base payload
			let payload = {
				date: this.form.date,
				minutesPlayed: this.form.minutesPlayed,
				eventsPlayed: this.form.eventsPlayed,
				warmup: this.form.activities.includes('Warm Up'),
				meditation: this.form.activities.includes('Meditação'),
				sessionNicknames: [],
			};

			let hasLiveNicknames = this.form.networkPicker.nicknames.map(e => e.network.id);
			if (this.form.metadata && ( hasLiveNicknames.includes(Vue.prototype.$rail_liveUSD_network_id) || hasLiveNicknames.includes(Vue.prototype.$rail_liveEUR_network_id) )) {
				payload.meta_live = this.form.metadata;
			}

			// Add 'user' field if action create + user admin
			if (this.action == 'create' && this.userIsRailManager) {
				payload.user = this.form.networkPicker.user.id;
			}

			// Add fields specific to the 'update' action
			if (this.action == 'update') {
				payload.id = this.selectedSession.id;
				payload.user = this.selectedSession.user.id;
			}

			// Add nicknames
			this.form.networkPicker.nicknames.forEach(element => {
				payload.sessionNicknames.push({
					nicknameID: element.nicknameID,
					balance: element.balance,
					rakebackBalance: element.rakebackBalance,
					rakebackTickets: element.rakebackCoins,
					rakebackCoins: element.rakebackTickets
				});
			});

			// Define store action
			let storeAction = this.action == 'create'
				? 'railSessions/create'
				: 'railSessions/update';

			// Dispatch to store
			let result = await this.$store.dispatch(storeAction, payload);
			
			if (this.action == 'create') {
				this.gamificationSession = this.gamification
			}

			// Re-enable btn
			this.toggleFormSubmitting();

			// On success
			if (result === true) {
				if(this.gamificationSession.show_dialog_feedback){
					this.showComponent = true;
					this.activeDialogSuccess = true;
				}else{
					this.showComponent = false;
					this.activeDialogSuccess = false;
				}
				if (this.action == 'create') {
					let payloadRefresh = {
						params: {
							itemsPerPage: 10,
							page: 1,
							'sortBy[0]': 'id',
							'sort[0]': 'DESC',
							'type[0]': 6,
							'type[1]': 3,
							'type[2]': 7,
							'type[3]': 9,
							'type[4]': 2,
							'type[5]': 1,
							'type[6]': 5,
							'type[7]': 8,
							'type[8]': 4,
						}
					}

					// if team exists and isManagement, Add team parameter to payload.params
					if (this.teamFilter && this.userHasRailAccessDivisionDropdown) {
						payloadRefresh.params.team = this.teamFilter;
					}
					
					this.$store.dispatch('railRequests/getRequests', payloadRefresh);
				}

				// Refresh Table Sessions/Nicknames/Statistics
				this.refresh(payload.user);

				// Close dialog
				if(!this.activeDialogSuccess && !this.gamificationSession.show_dialog_feedback){
					this.closeDialog();
					let snackbarMsg = this.action == 'create'
					? 'Sessão guardada com sucesso.'
					: 'Sessão atualizada com sucesso.';
					this.$store.dispatch('UI/showSnackbar', {
					message: snackbarMsg,
					color: 'success'
					});	
				}
			}
		},

		closeDialog() {
			this.$store.commit('railSessions/setSelected', null);
			this.$emit('close-dialog');
			this.resetForm();
		},

		resetForm() {
			// Reset data
			Object.assign(this.$data, this.initialState());

			// Reset validation
			if (this.$refs.formRailAddSession)
				this.$refs.formRailAddSession.reset();
			if (this.$refs.NetworkPicker)
				this.$refs.NetworkPicker.reset();
		},

		// used in submit()
		toggleFormSubmitting() {
			this.form.submitting = !this.form.submitting;
		},

		/**
		 * only runs if is Admin
		 * @param {Object} payload from NetworkPicker component
		 */
		getInfoFromNetworkPicker(payload) {
			if (this.userIsRailManager && this._.isObject(payload.user)) {
				this.form.user = payload.user;
			}
		},

		async deleteSession() {
			let result;

			// Disable delete btn
			this.toggleFormDeleting();

			// Generate payload
			let payload = this.selectedSession && this.selectedSession.id;

			// Dispatch to store
			if (payload) {
				result = await this.$store.dispatch('railRequests/deleteRequest', payload);
			}

			// Re-enable delete btn
			this.toggleFormDeleting();

			// On success
			if (result === true) {

				// Refresh Table Sessions/Nicknames/Statistics
				this.refresh();

				// Emit 'submitted' event
				this.$emit('submitted');

				// Close dialog
				this.closeDialog();

				// Show success snackbar
				this.$store.dispatch('UI/showSnackbar', {
					message: 'Sessão apagada com sucesso.',
					color: 'success'
				});
			}
		},

		toggleFormDeleting() {
			this.form.deleting = !this.form.deleting;
		},

		/**
		 * Refreshes Nicknames/Statistics/Sessions after an action (Create/Update/Delete) Session
		 * @param {String|Number} User ID
		 */
		refresh(userID) {
			let payloadRefresh = {
				params: {}
			};
			let dateObject = {}

			// Populates dateObject used in railStatistics endpoint && railSessions/get endpoint
			if (!this._.isEmpty(this.heroDatePickerValue)) {
				dateObject.dateBegin = this.heroDatePickerValue[0];
				dateObject.dateEnd = this.heroDatePickerValue[1];

				payloadRefresh.params.dateBegin = dateObject.dateBegin;
				payloadRefresh.params.dateEnd = dateObject.dateEnd;
			}

			// if team exists and isManagement, Add team parameter to payload.params
			if (this.teamFilter && this.userHasRailAccessDivisionDropdown) {
				payloadRefresh.params.team = this.teamFilter;
			}

			// add user ID to payload
			if (this.userIsRailManager) {
				payloadRefresh.params.user = userID ? userID : this.selectedSession.user.id;
			} else {
				payloadRefresh.params.user = this.userTrackerId;
			}

			// if reloadSingleUser (Single Page View) is true
			if (this.reloadSingleUser) {
				
				let payloadRailStatistics = {
					params: {
						user: payloadRefresh.params.user,
						dateBegin: payloadRefresh.params.dateBegin,
						dateEnd: payloadRefresh.params.dateEnd,
					}
				};

				// Refresh TableBalances by user ID
				if (this.userIsRailManager) {
					let payloadTableBalance = {
						user: payloadRefresh.params.user,
						aliasParameter: '&aliasChildOf=null'
					  }
					this.$store.dispatch('nicknames/getAllPlayer', payloadTableBalance);
				}

				// reset last payload in order to refresh statistics
				this.$store.commit('railStatistics/setLastPayloadDataByUserId', {});

				// Refresh rail statistics (TableBalances stats)
				this.$store.dispatch('railStatistics/getByUserId', payloadRailStatistics);

				// Refresh obligation statistics
				let payloadHeatmap = {
					id: payloadRefresh.params.user,
					body: {
						year: this.$moment(this.heroDatePickerValue[0]).format('YYYY'),
						month: this.$moment(this.heroDatePickerValue[0]).format('MM'),
					  }
				}

				this.$store.dispatch('users/getUserStatisticsHeatmap', payloadHeatmap);
				this.$store.dispatch('users/getUserStatisticsHeatmapMonthly', payloadHeatmap);
			} 

			// Refresh obligation statistics
			let payloadObligationStats = {
				id: payloadRefresh.params.user,
				body: {
					year: this.$moment(this.heroDatePickerValue[0]).format('YYYY'),
					month: this.$moment(this.heroDatePickerValue[0]).format('MM'),
				}
			}

			// reset last payload in order to refresh user statistics
			this.$store.commit('users/setLastPayloadUserStatistics', {});

			this.$store.dispatch('users/getUserStatistics', payloadObligationStats);

			// if user
			if (this.userIsPlayer) {
				// Refresh Rail Statistics
				if (!this._.isEmpty(dateObject)) {
					this.$store.dispatch('railStatistics/get', dateObject);
				}

				// set promiseAllMine to null in order to allow refreshing nicknames all mine again
				this.$store.commit('nicknames/setPromiseAllMine', null);

				// Refresh TableBalance (own nicknames)
				this.$store.dispatch('nicknames/getAllMine');
			}

			// if the route is Nickname add nickname parameter to payload
			if (this.$route.name === 'Nickname') {
				payloadRefresh.params.nickname = this.$route.params.id;
			}

			// Refresh Sessions
			this.$store.dispatch('railSessions/get', payloadRefresh);
			
		},

		/**
		 * used in input-financial input element in order to do proper validations
		 * @param {Number} field 
		 * @returns {Array} with boolean values
		 */
		handleMinutesPlayedRules(field) {
			return [
				() => !!field || 'Introduza a duração da sessão',
				() => (!!field && Number(field) <= 2880) || 'O valor tem de ser inferior a 24 horas',
				() => _.isInteger(Number(field)) || 'Por favor introduza um número válido.',
			]
		},

		/**
		 * used in input-financial input element in order to do proper validations
		 * @param {Number} field 
		 * @returns {Array} with boolean values
		 */
		handleEventsPlayed(field) {
			return [
				() => !!field || 'Introduza um número de eventos',
				() => _.isInteger(Number(field)) || 'Por favor introduza um número válido.',
				() => (!!field && parseFloat(String(field).replace(/,./g, '.')) > 0) || 'O valor tem de ser positivo',
				() => String(field).replace(/[^0-9]/g, '') <= 30000 || 'O valor não deve exceder 30.000',
			]
		},

		handleMinDate() {
			let europeLisbonTimeZone = this.$moment().utcOffset('Europe/Lisbon', true)
			let isDayOne = europeLisbonTimeZone.date() === 1 && europeLisbonTimeZone.hour() >= Vue.prototype.defaultWhenEarlyCap.end.split(":")[0];

			if (this.userIsPlayer) {
				if (isDayOne) {
					return this.$moment().format('YYYY-MM-DD');
				} else {
					return this.$moment().subtract(1, 'days').format('YYYY-MM-DD');
				}
			} else {
				return undefined;
			}
		},

		getInfoFromSessionLiveMetadata(item) {
			this.form.metadata = item;
		},

		fetchLiveEvents() {
			this.$store.dispatch('railSessions/getLiveEvents');
		},

		hasNullOrUndefinedOrEmpty(obj) {
			for (const key in obj) {
				if (obj[key] === null || obj[key] === undefined) {
					return true;
				}
			}
			return false;
		}
	},
};
