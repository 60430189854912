import Hero from '@/components/Hero/Hero.vue';
import DialogAddEditTeam from '@/components/Dialog/DialogAddEditTeam/DialogAddEditTeam.vue';
import TableUser from '@/components/Tables/TableUser/TableUser.vue';
import CardStatistics from '@/components/Rail/Statistics/CardStatistics/CardStatistics.vue';
import AuthMixin from '@/mixins/Auth';
import TeamInformation from '../../components/TeamInformation/TeamInformation.vue';
import LineChartCumulativePNL from '@/components/Charts/LineChartCumulativePNL/LineChartCumulativePNL.vue';
import DataMixin from '@/mixins/Data';
import { mapGetters } from 'vuex';

export default {
  name: 'Teams-Single',

  components: {
    Hero,
    DialogAddEditTeam,
    CardStatistics,
    TableUser,
    TeamInformation,
    LineChartCumulativePNL
  },

  mixins: [
    AuthMixin,
    DataMixin,
],

  computed: {
    ...mapGetters({
      teams: 'teams/teams',
      selectedTeam: 'teams/selectedTeam',
      selectedTeamLoading: 'teams/selectedTeamLoading',
      selectedStatistic: 'teams/selectedStatistic',
      selectedStatisticLoading: 'teams/selectedStatisticLoading',
      heroDatePickerValue: 'UI/heroDatePickerValue',
      selectedPnlStatistic: 'teams/selectedPnlStatistic',
      selectedPnlStatisticLoading: 'teams/selectedPnlStatisticLoading',
    }),

    cardInfo() {
      return [
          {
              name: 'Nº de jogadores',
              value: (this.selectedStatistic && this.selectedStatistic.NUM_PLAYERS && this.selectedStatistic.NUM_PLAYERS.value) || 0,
              precision: 0,
              icon: 'mdi-account',
              showCurrency: false,
              showCurrencyIcon: 'mdi-currency-usd',
              showAltCurrency: false,
              show: true,
          },
          {
              name: 'Nº de Jogos',
              value: (this.selectedStatistic && this.selectedStatistic.NUM_GAMES && this.selectedStatistic.NUM_GAMES.value) || 0,
              icon: 'mdi-cards-playing-outline',
              showCurrency: false,
              precision: 0,
              showCurrencyIcon: 'mdi-currency-usd',
              showAltCurrency: false,
              show: true,
          },
          {
              name: 'Lucro',
              value: this.selectedStatistic && this.selectedStatistic.PNL_GAMES && this.selectedStatistic.PNL_GAMES.value && this.selectedStatistic.PNL_GAMES.value.EUR && this.selectedStatistic.PNL_GAMES.value.EUR.value ? this.selectedStatistic.PNL_GAMES.value.EUR.value : 0,
              precision: 2,
              icon: 'mdi-cash',
              showCurrency: true,
              showAltCurrency: false,
              show: true,
          },
      ];
  },

    isLoading: function () {
        return this.selectedTeamLoading;
    },
  },

  data() {
    return this.initialState();
  },

  watch:{
    heroDatePickerValue: function () {
      this.fetchData();
  },
  },

  methods: {
     initialState() {
      return {
        id: this.$route.params.id,
        dialogues: {
          editTeam: false,
        },
        checkFields: false,
        filter: {action: 'teamById', id: this.$route.params.id},
      };
    },

    async fetchData(){
      let payload = {
        id: this.$route.params.id,
      }
      if (!this._.isEmpty(this.heroDatePickerValue)) {
          payload.dateBegin = this.heroDatePickerValue[0];
          payload.dateEnd = this.heroDatePickerValue[1];
      }
      this.getInfoByDateRange();
      await this.$store.dispatch('teams/getPnlStatistics', payload);
      
    },

    async getInfoByDateRange(){
      let payload = {
        id: this.$route.params.id,
      }

      if (!this._.isEmpty(this.heroDatePickerValue)) {
          payload.dateBegin = this.heroDatePickerValue[0];
          payload.dateEnd = this.heroDatePickerValue[1];
      }
      await this.$store.dispatch('teams/getStatistics', payload);
      },

    async initialize(){
      await this.allowOnlyRoles(['rail_admin', 'rail_manager', 'tracker_admin', 'tracker_manager', 'super_coach']);
      await this.$store.dispatch('teams/getTeam', { id: this.$route.params.id });
    },

    toggleDialog(dialog){
      this.dialogues[dialog] = ! this.dialogues[dialog]
    },

  },

  created() {
    this.initialize();
  }
};
