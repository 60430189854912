import { mapGetters } from 'vuex';

export default {
    name: 'TableGameGroups',

    computed: {
        ...mapGetters({
            gameGroups: 'schedulingGameGroups/data',
            totalData: 'schedulingGameGroups/total',
            loading: 'schedulingGameGroups/loading',
        })
    },

    props: {
        filters: { type: Object, required: false, default: () => { } },
    },

    data() {
        return this.initialState();
    },

    created() {
        this.init();
    },

    methods: {
        initialState() {
            return {
                headers: [
                    { text: 'Nome', value: 'name', sortable: true },
                    { text: 'Sala', value: 'sharkscopeNetwork.name', sortable: true },
                    { text: 'Buyin', value: 'full_buyin', sortable: true },
                    { text: 'Velocidade', value: 'speed.name', sortable: true },
                    { text: 'KO', value: 'isKO', sortable: true },
                    { text: 'PKO', value: 'isPKO', sortable: true },
                    { text: 'Rebuys', value: 'allowsRebuys', sortable: true },
                    { text: 'Reentries', value: 'allowsReentries', sortable: true },
                    { text: 'Ação', value: 'action', sortable: false },
                ],
                footerProps: {
                    'items-per-page-options': this.$itemsPerPageOptions,
                },
                options: {
                    page: 1,
                    itemsPerPage: 10,
                    sortBy: [],
                    sortDesc: [],
                    groupBy: [],
                    groupDesc: [],
                    mustSort: true,
                    multiSort: true
                },
            }
        },

        init() {
            this.getSchedulingGameGroups();
        },

        getBooleanIcon(atr) {
            return (atr) ? "mdi-check" : "mdi-close";
        },

        constructPayload() {
            let payload = {
                params: {
                    page: this.options.page,
                    itemsPerPage: this.options.itemsPerPage,
                },
            }

            // add network filter
            if (this.filters && this.filters.network) {
                payload.params.networks = this.filters.network.id;
            }

            // add flags filter
            if (this.filters && this.filters.flags) {
                payload.params.flags = this.filters.flags.join(',');
            }

            // add buyin filter
            if (this.filters && this.filters.buyinRange) {
                payload.params.buyin = this.filters.buyinRange.join(',');
            }

            return payload;
        },

        async getSchedulingGameGroups() {
            let payload = this.constructPayload();
            await this.$store.dispatch('schedulingGameGroups/get', payload);
        },
    },
    watch: {
        options: {
            handler() {
                this.getSchedulingGameGroups();
            },
            deep: true,
        },
        filters: {
            handler() {
                // resets options object
                this.options = {
                    page: 1,
                    itemsPerPage: 10,
                    mustSort: false,
                    multiSort: false
                }
            },
            deep: true,
        },
    }
}