export default {
    name: 'Alert',
    props: {
        title: {type: String, required: false, default: ''},
        description: {type: String, required: false, default: ''},
        color: {type: String, required: false, default: ''},
        button_name_1: { type: String, required: false, default: null},
        button_name_2: { type: String, required: false, default: null},
        icon: { type: String, required: false, default: '' },
        outlined: { type: Boolean, required: false, default: false },
        border: { type: String, required: false, default: 'left' },
        type: { type: String, required: false, default: null },
        coloredBorder: { type: Boolean, required: false, default: true },
        elevation: { type: Number, required: false, default: 2 },
        dismissible: { type: Boolean, required: false, default: true },
    },
    data() {
        return this.initializeState();
    },
    methods: {
        initializeState() {
            return {
            }
        },
        action() {
            return this.$emit("button-action");
        },
    }
}
