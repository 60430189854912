/**
 * @ref https://dm4t2.github.io/vue-currency-input/
 */

import Vue from 'vue';
import VueCurrencyInput from 'vue-currency-input';

const pluginOptions = {
  globalOptions: {
    locale: 'pt-PT',
    distractionFree: false,
    precision: 2,
    autoDecimalMode: false,
    allowNegative: false,
  }
};

Vue.use(VueCurrencyInput, pluginOptions);