<script>
import Vue from 'vue';
import storeAuth from '../store/auth'

export default {
    methods: {
        /**
         * Based on Auth
         * Gets the numeric portion of a player's group name.
         * e.g. '2.3' out of 'Nivel 2.3'.
         * @return {string|null}
         */
        getUserGroupNumeric() {
            let output = null;
            let indice = 1;
            if (storeAuth.state.userGroup && storeAuth.state.userGroup.name) {

                if (this.userIsDivision('cash')) {
                    let removeSlash = storeAuth.state.userGroup.name.replace('/', '');
                    output = removeSlash.split('NL');
                } else if (this.userIsDivision('mtt')) {
                    output = storeAuth.state.userGroup.name.split(' ');
                } else if (this.userIsDivision('spins')) {
                    output = storeAuth.state.userGroup.name.split(' ');
                    indice = 2;
                }

                if ( this._.isArray(output) && output[indice] && ! isNaN(parseFloat(output[indice])) ) {
                    output = output[indice];
                } else {
                    output = null;
                }
            }

            return output;
        },

        /**
         * Based on user parameter
         * Gets the numeric portion of a player's group name.
         * e.g. '2.3' out of 'Nivel 2.3'.
         * @param   {Object} user
         * @return {string|null}
         */
        getUserGroupNumericAsAdmin(user) {
            let output = null;
            let indice = 1;

            if (user && user.group) {

                let userTeamIds = [];

                if (user && user.teams) {
                    userTeamIds = this._.map(user.teams, 'id');
                }

                if (userTeamIds.includes(Vue.prototype.$team_ids.division_mtt)) {
                    output = user.group.name.split(' ');
                } else if (userTeamIds.includes(Vue.prototype.$team_ids.division_cash)) {
                    let removeSlash = user.group.name.replace('/', '')
                    output = removeSlash.split('NL');
                } else if (userTeamIds.includes(Vue.prototype.$team_ids.division_spins)) {
                    output = user.group.name.split(' ');
                    indice = 2;
                } else {
                    return null;
                }

                if ( this._.isArray(output) && output[indice] && ! isNaN(parseFloat(output[indice])) ) {
                    output = output[indice];
                } else {
                    output = null;
                }

            }
            return output;
        },

        /**
         * Based on Auth
         * Gets the user team structure excluding helper teams such as 'MTT Division' and 'Galvanize'.
         * Needs the variables:
         *  - 'storeAuth.state.userTeams' to contain a 'teams' property (customized simple-jwt-auth wp plugin).
         *  - 'Vue.prototype.$teams_types' to be filled according to the database model.
         * 
         * @return {Object} Obj {id, name, type}
         */
        getUserTeam() {
            if (storeAuth.state.userTeams)
                return storeAuth.state.userTeams.find(e => e.type == Vue.prototype.$teams_types.real);
            else
                return null;
        },

        /**
         * Based on Auth
         * Gets only the team name retrieved by the function 'getUserTeam()'
         * @return {string}
         */
        getUserTeamName() {
            let team = this.getUserTeam();

            if (team)
                return team.name;
            else
                return 'Equipa desconhecida';
        },

        /**
         * Based on Auth
         * Gets the user division.
         * @return  {string}    'cash' or 'mtt'
         */
        getUserDivision() {
            let output = null;

            if (storeAuth.state.userTeams) {
                // Find team containing 'Divisão'
                output = storeAuth.state.userTeams.find(e => e.type == Vue.prototype.$teams_types.helper && e.name.includes('Division'));

                // Handle result
                if (output) {
                    output = output.name.split(' ')[0].toLowerCase();
                } else {
                    output = null;
                }
            }
            
            return output;
        },

        /**
         * Based on Auth
         * Pratical comparison function for 'getUserDivision()'
         * @param   {string}    div 'cash' or 'mtt'
         * @return  {bool}
         */
        userIsDivision(div) {
            return this.getUserDivision() == div;
        },
    }
}
</script>