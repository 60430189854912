import { mapGetters } from 'vuex';
import DialogConfirmation from '@/components/Rail/Dialog/DialogConfirmation/DialogConfirmation.vue';
import AuthMixin from '@/mixins/Auth';
import DataMixin from '@/mixins/Data';
import RailMixin from '@/mixins/Rail.vue';

export default {
    name: 'ListCalendarEventParticipants',

    mixins: [
        AuthMixin,
        DataMixin,
        RailMixin,
    ],

    components: {
        DialogConfirmation,
    },

    computed: {
        ...mapGetters({
            selectedEvent: 'eventsCalendar/selectedEvent',
            eventsError: 'eventsCalendar/error',
            eventsHumanError: 'eventsCalendar/humanError',
            users: 'users/usersAllActive',
        }),

        selectedAttendeesText() {
            const text = this.selectedNewAttendees.length === 1 ? 'Selecionado' : 'Selecionados';
            return this.selectedNewAttendees.length + ' ' + text;
        },

        addParticipantsEnabled() {
            return this.selectedEvent.rsvp_count < this.selectedEvent.capacity;
        },
    },

    created() {
        this.init();
    },

    data() {
        return this.initialState();
    },

    methods: {
        initialState() {
            return {
                menu : false,
                selectedNewAttendees: [],
                showAddAttendeesDiv: false,
                dialogConfirmation: {
					open: false,
					submitting: false,
				},
                selectedUserId: null,
            }
        },

        init() {
            this.fetchData();
        },

        fetchData() {
            if (this._.isEmpty(this.users)) {
                let payload = this.formulateUsersAllUrlParameters();
                this.$store.dispatch('users/getUsersAllActive', payload);
            }
        },

        removeAttendee(user_id) {
            //open dialog 
            this.dialogConfirmation.open = true;
            
            // setup id of user to remove
            this.selectedUserId = user_id;
        },

        toggleAddAttendeesDiv() {
            this.showAddAttendeesDiv = ! this.showAddAttendeesDiv;
        },

        async rsvpAttendees() {
            await this.selectedNewAttendees.forEach(attendee_id => {
                this.submitToStore(attendee_id);
            })

            this.clearData();
        },

        undoRsvp() {
            // Send to store (same action for rsvp/undo-rsvp)
			this.submitToStore(this.selectedUserId);

			// Close DialogConfirmation "Are you sure you want to undo-rsvp?"
			this.dialogConfirmation.open = false;
        },
        
        submitToStore(user_id) {
            let payload = {
                eventID: this.selectedEvent.id,
                user: user_id,
            };

            this.$store.dispatch('eventsCalendar/rsvpEnroll', payload);
        },

        clearData() {
            Object.assign(this.$data, this.initialState());
        },

        getUserProfileLink(userId) {
            let output = '#';
            // Only admins can visit player pages
            if (this.userIsManager) {
                output = `/players/${userId}`;
            }
            return output;
        },

        toggleAttended(id) {
            const payload = {
                rsvpId: id,
            }
            this.$store.dispatch('eventsCalendar/toggleAttended', payload);
        },

        triggerCopyToClipboard(content) {
            this.copyToClipboard(content);
            
            // Show snackbar
            this.$store.dispatch('UI/showSnackbar', {
                message: "Email '" + content + "' copiado com sucesso.",
                color: 'success'
            });
        },
    }
}