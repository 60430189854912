<template>
  <div v-if="isAuthenticated()">
    <v-app-bar
      app
      dark
    >
      <v-container class="hidden-sm-and-down">
        <v-slide-group show-arrows>
          <!--
            Menu Desktop Admin/user
          -->
          <NavBarDesktop
            :players-btn-menu="playersBtnMenu"
            :tracker-btn-menu="trackerBtnMenu"
            :games-btn-menu="gamesBtnMenu"
            :videos-btn-menu="videosBtnMenu"
            :statistics-btn-menu="statisticsBtnMenu"
            :schedule-btn-menu="scheduleBtnMenu"
          />
        </v-slide-group>
      </v-container>

      <v-container class="hidden-md-and-up pa-0">
        <!--
          Menu Mobile Admin/user
        -->
        <NavBarMobile
          :players-btn-menu="playersBtnMenu"
          :tracker-btn-menu="trackerBtnMenu"
          :games-btn-menu="gamesBtnMenu"
          :videos-btn-menu="videosBtnMenu"
          :statistics-btn-menu="statisticsBtnMenu"
          :schedule-btn-menu="scheduleBtnMenu"
        />
      </v-container>
    </v-app-bar>
  </div>
</template>

<script>
import AuthMixin from "@/mixins/Auth.vue";
import NavBarDesktop from "@/components/NavBarDesktop/NavBarDesktop.vue";
import NavBarMobile from "@/components/NavBarMobile/NavBarMobile.vue";

export default {
	name: "NavBar",

	components: {
		NavBarDesktop,
		NavBarMobile,
	},

	mixins: [AuthMixin],

	data: () => ({
		menu: false,
		sheet: false,
		sheetFab: false,
		state: {
			navbarMobile: false,
		},
	}),

	computed: {
		playersBtnMenu: function() {
			return [
				{
					name: "Jogadores",
					path: "/players/",
					condition: this.userHasRole([
						"rail_admin",
						"rail_manager",
						"tracker_admin",
						"tracker_manager",
						"super_coach",
					]),
				},
				{
					name: "Nicknames",
					path: "/nicknames/",
					condition: this.userIsManager,
				},
				{
					name: "Contratos",
					path: "/contracts/",
					condition: this.userIsManager,
				},
				{
					name: "Candidaturas",
					path: "/applications/",
					condition: this.userIsApplicationsManager,
				},
				{
					name: "Saídas",
					path: "/departures/",
					condition: this.userHasRole(["super_coach"]),
				},
			];
		},

		trackerBtnMenu: function() {
			return [
				{
					name: "Schedules",
					path: "/schedules/",
					condition: this.userHasRole([
						"rail_admin",
						"rail_manager",
						"tracker_admin",
						"tracker_manager",
						"super_coach",
						"coach",
					]),
				},
				{
					name: "Níveis",
					path: "/groups/",
					condition: this.userHasRole([
						"rail_admin",
						"rail_manager",
						"tracker_admin",
						"tracker_manager",
						"super_coach",
					]),
				},
				{
					name: "Salas",
					path: "/networks/",
					condition: this.userHasRole([
						"rail_admin",
						"rail_manager",
						"tracker_admin",
						"tracker_manager",
						"super_coach",
					]),
				},
			];
		},

		gamesBtnMenu: function() {
			let pathSchedules = this.userHasRole([
				"rail_admin",
				"rail_manager",
				"tracker_admin",
				"tracker_manager",
				"super_coach",
				"coach",
			])
				? "/schedules/"
				: "/schedules/me/";

			return [
				{ name: "Schedules", path: pathSchedules, condition: true },
				{ name: "Anomalias", path: "/anomalies/", condition: true },
			];
		},

		videosBtnMenu: function() {
			let output = [
				{ name: "Pedidos", path: "/videos/requests/", condition: true },
			];

			if (
				this.userHasRole([
					"team_cash_manager",
					"video_reports_reviewer_team_mtt",
					"video_reports_reviewer_team_cash",
				])
			) {
				output.push({
					name: "Relatórios",
					path: "/videos/reports/",
					condition: this.userHasRole([
						"team_cash_manager",
						"video_reports_reviewer_team_mtt",
						"video_reports_reviewer_team_cash",
					]),
				});
			}

			return output;
		},

		statisticsBtnMenu: function() {
			return [
				{
					name: "Geral",
					path: "/statistics/",
					condition: this.userHasRole([
						"coach",
						"super_coach",
						"tracker_admin",
						"tracker_manager",
					]),
				},
				{
					name: "Candidaturas",
					path: "/stats/applications/",
					condition: this.userHasRole([
						"coach",
						"super_coach",
						"tracker_admin",
						"tracker_manager",
					]),
				},
				{
					name: "Vídeos",
					path: "/stats/videos/",
					condition: this.userHasRole([
						"coach",
						"super_coach",
						"tracker_admin",
						"tracker_manager",
					]),
				},
			];
		},

		scheduleBtnMenu: function() {
			return [
				{
					name: "Scheduling",
					path: "/scheduling/",
					condition: this.userHasRole([
						"beta_tester_scheduling",
						"beta_tester_calendar",
					]),
				},
				{
					name: "Modelos",
					path: "/scheduling/models/",
					condition: this.userHasRole([
						"beta_tester_scheduling",
						"beta_tester_calendar",
					]),
				},
				{
					name: "Fatores",
					path: "/scheduling/factors/",
					condition: this.userHasRole([
						"beta_tester_scheduling",
						"beta_tester_calendar",
					]),
				},
				{
					name: "Jogos",
					path: "/scheduling/games/",
					condition: this.userHasRole([
						"beta_tester_scheduling",
						"beta_tester_calendar",
					]),
				},
				{
					name: "Grupos de jogos",
					path: "/scheduling/gameGroups/",
					condition: this.userHasRole([
						"beta_tester_scheduling",
						"beta_tester_calendar",
					]),
				},
				{
					name: "Calendário",
					path: "/calendar/",
					condition: this.userHasRole([
						"beta_tester_scheduling",
						"beta_tester_calendar",
					]),
				},
			];
		},
	},
};
</script>

<style></style>
