import { mapGetters } from 'vuex';

export default {
    name: 'BuyinDropdown',

    props: {
        value: { type: [Object, Array], required: false, default: () => { } },
        label: { type: String, required: false, default: 'Buyin' },
        status: { type: String, required: false, default: '' },
        disabled: { type: Boolean, required: false, default: false },
        dark: { type: Boolean, required: false, default: false },
        solo: { type: Boolean, required: false, default: false },
        clearable: { type: Boolean, required: false, default: false },
        outlined: { type: Boolean, required: false, default: true },
        hideDetails: { type: Boolean, required: false, default: false },
        hint: { type: String, required: false, default: '' },
        autoReload: { type: Boolean, required: false, default: true },
    },

    computed: {
        ...mapGetters({
            themeDarkMode: 'UI/themeDarkMode',
        }),
        themeDarkModeColor() {
            if (!this.dark) {
                return this.themeDarkMode ? 'white-border' : 'dark-border';
            }
        },
        buyinText: {
            set() {
                this.buyinRange[0] + ' - ' + this.buyinRange[1];
            },
            get() {
                return this.buyinRange[0] + ' - ' + this.buyinRange[1];
            },
        }
    },

    created() {
    },

    data() {
        return this.initialState();
    },

    methods: {
        initialState() {
            return {
                menuShowing: false,
                max: 5000000,
                min: 0,
                buyinRange: [0, 5000000],
            }
        },

        emitChange() {
            this.$emit('input', this.buyinRange);
            this.$emit('change', this.buyinRange);
        },
    },
    watch: {
        menuShowing: function () {
            if(!this.menuShowing) {
                this.emitChange();
            }
        },
    },
}