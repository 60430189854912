import Vue from 'vue';

export default {
    name: 'OperationDropdown',

    props: {
        value: { type: [Object,Array], required: false, default: () => { } },
        hideDetails: { type: Boolean, required: false, default: false },
        outlined: { type: Boolean, required: false, default: false },
        solo: { type: Boolean, required: false, default: false },
        excludedOperations: { type: Array, required: false, default: () => [] },
        selectedOperation: { type: [Object,Array], required: false, default: () => { } },
        hint: { type: Boolean, required: false, default: false },
        multiple: { type: Boolean, required: false, default: false },
        disabled: { type: Boolean, required: false, default: false },
    },

    computed: {
        computedValue: {
            get: function () {
               return this.operation;
            },
            set: function (newValue) {
                this.operation = newValue;
                this.emitChange();
            }
        },

         /**
         * Filters data according to the props passed
         * @returns {Array}
         */
        dataFiltered: function () {
            let output = this.operations;

            // (If set) Dont show the given operations (usually to avoid duplication)
            output = this.removeOperations(output);

            return output;
        },

        operationHint: function () {
            if (!this.hint) {
                return '';
            }

            if (this.operation) {
                switch (this.operation.value) {
                    case Vue.prototype.$rail_ops.operations.split:
                        return 'A operação ' + this.operation.name + ' permite dividir os lucros do jogador com a gestão.'
                    case Vue.prototype.$rail_ops.operations.bankroll_reduction:
                        return 'A operação ' + this.operation.name + ' permite reduzir a banca do jogador';
                    case Vue.prototype.$rail_ops.operations.bankroll_increment:
                        return 'A operação ' + this.operation.name + ' permite aumentar a banca do jogador';
                    case Vue.prototype.$rail_ops.operations.transfer_to_self:
                        return 'A operação ' + this.operation.name + ' permite a transferência entre duas partes';
                    case Vue.prototype.$rail_ops.operations.swap:
                        return 'A operação ' + this.operation.name + ' permite trocar o montante entre jogadores.';
                    case 5:
                    case 7:
                    case 8: 
                        return '';
                }
            }
        },
    },

    data() {
        return this.initialState();
    },

    created() {
        this.computedValue = this.value;
    },

    methods: {
        initialState() {
            return {
                operation: null,
                operations: [
                    { value: Vue.prototype.$rail_ops.operations.deposit, name: 'Depósito' },
                    { value: Vue.prototype.$rail_ops.operations.bankroll_increment, name: 'Incremento de Banca' },
                    { value: Vue.prototype.$rail_ops.operations.withdraw, name: 'Levantamento' },
                    { value: Vue.prototype.$rail_ops.operations.makeup, name: 'Makeup' },
                    { value: Vue.prototype.$rail_ops.operations.bankroll_reduction, name: 'Redução de Banca' },
                    { value: Vue.prototype.$rail_ops.operations.split , name: 'Split' },
                    { value: Vue.prototype.$rail_ops.operations.swap, name: 'Swap' },
                    { value: Vue.prototype.$rail_ops.operations.transfer_to_self, name: 'Transferência' },
                ],
            }
        },
        
        /**
         * @param {Array} with objects (operations) 
         * @returns {Array} filtered without excluded Operations
         */
        removeOperations(item) {
            let output = item;
            if (!this._.isEmpty(this.excludedOperations)) {
               output = item.filter(e => ! this.excludedOperations.includes(e.value));
            }
            return output;
        },

        emitChange() {
            setTimeout(() => {
                this.$emit('change', this.computedValue)
                this.$emit('input', this.computedValue)
            }, 2000);
        },

        reset() {
            this.operation = null;
            if (this.$refs.formOperationDropdown) {
                this.$refs.formOperationDropdown.reset();
            }
         },
    }
}