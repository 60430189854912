import AlertError from '@/components/Alerts/AlertError/AlertError.vue';

export default {
  name: 'DragAndDropInputFile',

	components: {
		AlertError,
	},

  props: {
    value: { type: Array, required: false, default: () => [] },
    allowedFileTypes: { type: Array, required: false, default: () => ['image/png', 'image/jpeg'] },
    text: { type: String, required: false, default: 'São permitidas imagens do tipo PNG e JPEG até 2 MB.' },
    maxSize: { type: [Number, String], required: false, default: 2097152 },
    errorMessage: { type: String, required: false, default: 'O ficheiro selecionado não cumpre os requisitos (JPEG ou PNG até 2 MB)'}
  },

  computed: {
    computedFiles: {
      get: function () {
        return this.files;
      },
      set: function (newValue) {
        // if files has already been populated, it discards old file and set new value
        if (this.files.length >= 1) {
          this.files.shift();
        }

        this.files = newValue;
        if (!this._.isEmpty(this.files)) {
          // clear files if bigger than 2MB or type different from allowedFileTypes
          if (this.files[0].size > this.maxSize || ! this.allowedFileTypes.includes(this.files[0].type)) {
            this.files.pop();
            this.showError(this.errorMessage);
          }
        }
        this.$emit('retrieve-info', this.files);
      }
    }
  },
  
  watch: {
    value: {
      handler() {
        this.computedFiles = this.value;
      },
      deep: true
    }
  },

  created() {
    // Initial value passed through prop
    this.computedFiles = this.value;
  },
  
  data() {
    return this.initialState();
  },
  methods: {
    // https://dev.to/hundredrab/yet-another-vue-drag-and-drop-15m2
    initialState() {
      return {
        files: [],
        error: {
          msg: null
        },
      }
    },

    handleFileDrop(e) {
      this.hideError();
      this.computedFiles = [];
      let droppedFiles = e.dataTransfer.files;

      if (!droppedFiles) return;
      ([...droppedFiles]).forEach(f => {
        this.files.push(f);
      });
      this.$emit('retrieve-info', this.files);
      this.$emit('drop-event')
    },

    handleFileInput(e) {
      this.hideError();
      let files = e.target.files;
      if (!files) return;
      ([...files]).forEach(f => {
        this.files.push(f);
      });
      this.$emit('retrieve-info', this.files);
      this.$emit('select-event')
    },

    removeFile(fileKey) {
      this.files.splice(fileKey, 1);
    },

    showError(msg) {
      this.error.msg = msg;
    },

    hideError() {
      this.error.msg = null;
    },

    handleFileSize(fileSize) {
      if (typeof fileSize !== 'number' || isNaN(fileSize) || fileSize < 0) {
        return '';
      }
    
      const kb = 1024;
      const mb = kb * 1024;
      const gb = mb * 1024;
    
      if (fileSize >= gb) {
        return (fileSize / gb).toFixed(2) + ' GB';
      } else if (fileSize >= mb) {
        return (fileSize / mb).toFixed(2) + ' MB';
      } else if (fileSize >= kb) {
        return (fileSize / kb).toFixed(2) + ' KB';
      } else {
        return fileSize + ' bytes';
      }
    }
  }
}
