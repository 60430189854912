import Vue from 'vue';
import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth.vue';
import RailMixin from '@/mixins/Rail.vue';
import PlayerGroupBadge from '@/components/PlayerGroupBadge/PlayerGroupBadge.vue';
import LoadingSpinner from '@/components/LoadingSpinner/LoadingSpinner.vue';
import EmptyStateNoData from '@/components/EmptyStateNoData';

export default {
    name: 'LevelProgressDetail',

    components: {
        PlayerGroupBadge,
        LoadingSpinner,
        EmptyStateNoData,
    },

    mixins: [
        AuthMixin,
        RailMixin,
    ],

    props: {
        title: {
            type: String,
            required: false,
            default: '',
        },
        subtitle: {
            type: String,
            required: false,
            default: '',
        },
    },

    data() {
        return this.initialState();
    },

    async created() {
        await this.fetchGroups();
        this.setActiveGroup(this.selectedUser.group);
        await this.fetchGroupChangesByUserId();
        this.openDefaultPanel();
        this.dataLoaded = true;
    },

    computed: {
        ...mapGetters({
            themeDarkMode: 'UI/themeDarkMode',
            selectedUser: 'users/selectedUser',
            userTeams: 'users/userTeams',
            userTrackerId: 'auth/userTrackerId',
            userProgressLevelLoading: 'users/userProgressLevelLoading',
            userProgressLevel: 'users/userProgressLevel',
            userGroup: 'auth/userGroup',
            groups: 'groups/groups',
            groupsTotal: 'groups/groupsTotal',
            statsByUserId: 'stats/statsByUserId',
            statsByUserIdLoading: 'stats/statsByUserIdLoading',
            selectedGroup: 'groups/selectedGroup',
            selectedGroupLoading: 'groups/selectedGroupLoading',
            groupChangesByUserId: 'groups/groupChangesByUserId',
            groupChangesByUserIdLoading: 'groups/groupChangesByUserIdLoading',
            groupChangesByUserIdHumanError: 'groups/groupChangesByUserIdHumanError',
            groupChangesByUserIdError: 'groups/groupChangesByUserIdError',
        }),
        
        filteredMetrics() {
            return this.metricsPanel1.filter((metric) => metric.show);
        },

        numElements() { return this.$vuetify.breakpoint.mdAndDown ? 3 : 5; },

        // stats of a specific user
        groupChangesByUserIdComputed: function () {
            let ownGroupParams = this.groupChangesByUserId && this.groupChangesByUserId.level_params ? this.groupChangesByUserId.level_params : {};
            let selectedGroupParams = this.selectedGroup && this.selectedGroup.rules_change ? this.selectedGroup.rules_change : {};

            return {
                level_params: this.isActiveGroupSameAsUserGroup ? ownGroupParams : selectedGroupParams,
                mode: {
                    reason: {
                        name: this.groupChangesByUserId && this.groupChangesByUserId.mode ? this.groupChangesByUserId.mode.reason.name : null,
                        id: this.groupChangesByUserId && this.groupChangesByUserId.mode ? this.groupChangesByUserId.mode.reason.id : null,
                    },
                    value: {
                        total: this.groupChangesByUserId && this.groupChangesByUserId.mode ? this.groupChangesByUserId.mode.value.total : 0,
                        selected: this.groupChangesByUserId && this.groupChangesByUserId.mode ? this.groupChangesByUserId.mode.value.selected : 0,
                        pct: this.groupChangesByUserId && this.groupChangesByUserId.mode ? (this.groupChangesByUserId.mode.value.pct * 100).toFixed(2) : 0,
                    }
                },
                splits: {
                    details: {
                        days: this.groupChangesByUserId && this.groupChangesByUserId.splits ? this.groupChangesByUserId.splits.details.days : 0,
                        count: this.groupChangesByUserId && this.groupChangesByUserId.splits ? this.groupChangesByUserId.splits.details.count : 0,
                    },
                    value: {
                        EUR: {
                            value: this.groupChangesByUserId && this.groupChangesByUserId.splits ? (this.groupChangesByUserId.splits.value.EUR.value).toFixed(2) : 0,
                        }
                    }
                },
                abi: {
                    details: {
                        days: this.groupChangesByUserId && this.groupChangesByUserId.abi ? this.groupChangesByUserId.abi.details.days : 0,
                    },
                    value: {
                        EUR: {
                            value: this.groupChangesByUserId && this.groupChangesByUserId.abi ? (this.groupChangesByUserId.abi.value.EUR.value).toFixed(2) : 0,
                        }
                    }
                },
                days_off: {
                    details: {
                        days_since_last_session: this.groupChangesByUserId && this.groupChangesByUserId.days_off ? this.groupChangesByUserId.days_off.details.days_since_last_session : 0,
                        consecutive_change_days_off: this.groupChangesByUserId && this.groupChangesByUserId.days_off ? this.groupChangesByUserId.days_off.details.consecutive_change_days_off : 0,
                        max_consecutive_change_days_off: this.groupChangesByUserId && this.groupChangesByUserId.days_off ? this.groupChangesByUserId.days_off.details.max_consecutive_change_days_off : 0,
                        max_days_since_last_session: this.groupChangesByUserId && this.groupChangesByUserId.days_off ? this.groupChangesByUserId.days_off.details.max_days_since_last_session : 0,
                    },
                    value: {
                        total: this.groupChangesByUserId && this.groupChangesByUserId.days_off ? this.groupChangesByUserId.days_off.value.total : 0,
                        selected: this.groupChangesByUserId && this.groupChangesByUserId.days_off ? this.groupChangesByUserId.days_off.value.selected : 0,
                        pct: this.groupChangesByUserId && this.groupChangesByUserId.days_off ? this.groupChangesByUserId.days_off.value.pct : 0,
                    }
                },
                stats: {
                    status: this.groupChangesByUserId && this.groupChangesByUserId.stats ? this.groupChangesByUserId.stats.status : false,
                    value: {
                        total: this.groupChangesByUserId && this.groupChangesByUserId.stats ? this.groupChangesByUserId.stats.value.total : 0,
                        selected: this.groupChangesByUserId && this.groupChangesByUserId.stats ? this.groupChangesByUserId.stats.value.selected : 0,
                        pct: this.groupChangesByUserId && this.groupChangesByUserId.stats ? this.groupChangesByUserId.stats.value.pct : 0,
                    }
                },
                last_level_change: {
                    details: {
                        pnl: {
                            EUR: {
                                value: this.groupChangesByUserId && this.groupChangesByUserId.last_level_change ? this.groupChangesByUserId.last_level_change.pnl.EUR.value : 0,
                            }
                        }
                    }
                },
                recovery: {
                    value: {
                        EUR: {
                            value: this.groupChangesByUserId && this.groupChangesByUserId.recovery ? this.groupChangesByUserId.recovery.value.EUR.value : 0,
                        }
                    }
                },
                sessions: {
                    value: {
                        count: this.groupChangesByUserId && this.groupChangesByUserId.sessions ? this.groupChangesByUserId.sessions.value.count : 0,
                        avg: this.groupChangesByUserId && this.groupChangesByUserId.sessions ? this.groupChangesByUserId.sessions.value.avg : 0,
                    }
                },
                makeup: {
                    details: {
                        max: {
                            value: {
                                EUR: {
                                    value: this.groupChangesByUserId && this.groupChangesByUserId.makeup ? (this.groupChangesByUserId.makeup.details.max.value.EUR.value).toFixed(2) : 0,
                                }
                            },
                            abi: this.groupChangesByUserId && this.groupChangesByUserId.makeup ? this.groupChangesByUserId.makeup.details.max.abi : 0,
                        }
                    },
                    value: {
                      abi: this.groupChangesByUserId && this.groupChangesByUserId.makeup ? this.groupChangesByUserId.makeup.value.abi : 0,
                  }  
                },
 
            }
        },

        // returns boolean value
        isActiveGroupSameAsUserGroup: function () {
            let userGroup = this.selectedUser && this.selectedUser.group.id;
            let activeGroupId = this.activeGroup && this.activeGroup.id
            return userGroup == activeGroupId;
        },

        userProgressLevelComputed: function () {
            let output = 0;
            if (this.userProgressLevel) {
                output = this.userProgressLevel * 100;
            }
            return output;
        },

        /**
         * List of groups
         * Sets current group centered in array groups
         * @returns {Array} filled with groups
         */
        itemsToShow() {
            if (this._.isEmpty(this.groups)) {
                return []
            }

            const startIndex = Math.max(0, this.currentIndex - Math.floor(10 / 2));
            const endIndex = Math.min(this.groupsTotal - 1, startIndex + 10 - 1);
            return [startIndex, endIndex];
        },

        // Métricas para subida rápida
        metricsPanel1: function () {
            if(this.groupChangesByUserId.details && this.groupChangesByUserId.details.sessions && this.isActiveGroupSameAsUserGroup){
                this.fastEnabled = this.groupChangesByUserId.details.sessions.fastEnabled;
            }
            let mode = false;
            if(this.groupChangesByUserId.details && this.groupChangesByUserId.details.mode){
                mode = this.groupChangesByUserId.details.mode.reason == Vue.prototype.$GroupsChangeReason[4].key ? true : false;
            }

            return [
                {
                    name: 'Stats',
                    general_value: this.groupChangesByUserId.details.stats.value.total,
                    value: this.groupChangesByUserId.details.stats.value.selected,
                    colorIcon: 'success',
                    show: true,
                    showIcon: mode,
                },
                {
                    name: 'Montante em splits',
                    general_value: this.fastEnabled == true ? this.groupChangesByUserId.details.levelParams.UP_FAST_SPLITS_AMOUNT : 
                    this.isActiveGroupSameAsUserGroup ? this.groupChangesByUserId.details.levelParams.UP_FAST_SPLITS_AMOUNT_NEXT_GROUP 
                    : this.groupChangesByUserId.details.levelParams.UP_FAST_SPLITS_AMOUNT,
                    value: this.groupChangesByUserId.details.splits.amount.USD.value,
                    colorIcon: 'success',
                    show: true,
                    showIcon: mode,
                },
                {
                    name: 'Média de sessões mensal',
                    general_value: this.groupChangesByUserId.details.levelParams.UP_FAST_AVG_SESSIONS,
                    value: this.groupChangesByUserId.details.sessions.avg,
                    colorIcon: 'success',
                    show: (this.isActiveGroupSameAsUserGroup && this.fastEnabled) || !this.isActiveGroupSameAsUserGroup,
                    showIcon: mode,
                },
                {
                    name: 'Dias para média de sessões',
                    general_value: this.groupChangesByUserId.details.levelParams.UP_FAST_AVG_SESSIONS_DAYS,
                    colorIcon: 'success',
                    show: (this.isActiveGroupSameAsUserGroup && this.fastEnabled) || !this.isActiveGroupSameAsUserGroup,
                    showIcon: false,
                },
            ]
        },

        // Métricas para subida normal
        metricsPanel2: function () {
            let mode = false;
            if(this.groupChangesByUserId.details && this.groupChangesByUserId.details.mode){
                mode = this.groupChangesByUserId.details.mode.reason == Vue.prototype.$GroupsChangeReason[3].key ? true : false;
            }
            return  [
                {
                    name: 'Nº de splits',
                    general_value: this.groupChangesByUserId.details.levelParams.UP_NORMAL_SPLITS_COUNT,
                    value: this.groupChangesByUserId.details.splits.count,
                    colorIcon: 'success',
                    show: this.isActiveGroupSameAsUserGroup,
                    showIcon: mode,
                },
                {
                    name: 'Montante em splits',
                    general_value: this.groupChangesByUserId.details.levelParams.UP_NORMAL_SPLITS_AMOUNT,
                    value: this.groupChangesByUserId.details.splits.amount.USD.value, 
                    colorIcon: 'success',
                    show: this.isActiveGroupSameAsUserGroup,
                    showIcon: mode,
                },
                {
                    name: 'Dias para cál. de montante e quantidade de splits',
                    general_value: this.groupChangesByUserId.details.splits.days,
                    colorIcon: 'success',
                    show: false,
                    showIcon: false,
                },
            ]
        },
        
        // Métricas para descida por makeup
        metricsPanel3: function () {
            let mode = false;
            if(this.groupChangesByUserId.details && this.groupChangesByUserId.details.mode){
                mode = this.groupChangesByUserId.details.mode.reason == Vue.prototype.$GroupsChangeReason[0].key ? true : false;
            }
            return [
                {
                    name: 'Makeup em Buy-in médio',
                    general_value: this.groupChangesByUserId.details.levelParams.DOWN_ABI_AMOUNT,
                    value: this.groupChangesByUserId.details.makeup.currentMakeup.nAbis,              
                    colorIcon: 'error',
                    show: this.isActiveGroupSameAsUserGroup,
                    showIcon: mode,
                },
                {
                    name: 'Makeup',
                    general_value: this.groupChangesByUserId.details.makeup.maxMakeup.value.USD.value,
                    value: this.groupChangesByUserId.details.makeup.currentMakeup.value.USD.value,
                    colorIcon: 'error',
                    show: this.isActiveGroupSameAsUserGroup,
                    showIcon: mode,
                },
                {
                    name: 'Buy-in médio',
                    general_value: this.groupChangesByUserId.details.makeup.abi.value.USD.value,
                    colorIcon: 'error',
                    show: false,
                    showIcon: false,
                }
            ]
        },

        // Métricas para descida por days off
        metricsPanel4: function () {
            let mode = false;
            if(this.groupChangesByUserId.details && this.groupChangesByUserId.details.mode){
                mode = this.groupChangesByUserId.details.mode.reason == Vue.prototype.$GroupsChangeReason[1].key ? true : false;
            }
            return [
                {
                    name: 'Nº dias ausente',
                    general_value: this.groupChangesByUserId.details.history.maxDaysOff,
                    value: this.groupChangesByUserId.details.history.daysOff,
                    colorIcon: 'error',
                    show: this.isActiveGroupSameAsUserGroup,
                    showIcon: mode,
                },
                {
                    name: 'Nº de descidas por dias ausente consecutivas',
                    general_value: this.groupChangesByUserId.details.history.maxConsecutiveLevelDowns,
                    value:  this.groupChangesByUserId.details.history.consecutiveLevelDowns,
                    colorIcon: 'error',
                    show: this.isActiveGroupSameAsUserGroup,
                    showIcon: false,
                }
            ]
        },

        // Métricas para recuperação (rápida)
        metricsPanel5: function () {
            let mode = false;
            if(this.groupChangesByUserId.details && this.groupChangesByUserId.details.mode){
                mode = this.groupChangesByUserId.details.mode.reason == Vue.prototype.$GroupsChangeReason[5].key ? true : false;
            }
            return [
                {
                    name: 'Stats',
                    general_value: this.groupChangesByUserId.details.stats.value.total,
                    value:  this.groupChangesByUserId.details.stats.value.selected,           
                    colorIcon: 'success',
                    show: this.isActiveGroupSameAsUserGroup,
                    showIcon: mode,
                },
                {
                    name: 'Montante a recuperar',
                    general_value: this.groupChangesByUserId.details.history.amountToRecoverFast ? this.groupChangesByUserId.details.history.amountToRecoverFast.USD.value : 0,
                    value: this.groupChangesByUserId.details.history.amountRecoveredFast ? this.groupChangesByUserId.details.history.amountRecoveredFast.USD.value: 0,
                    colorIcon: 'success',
                    show: this.isActiveGroupSameAsUserGroup,
                    showIcon: mode,
                },
            ]
        },

        // Métricas para recuperação (normal)
        metricsPanel6: function () {
            let mode = false;
            if(this.groupChangesByUserId.details && this.groupChangesByUserId.details.mode){
                mode = this.groupChangesByUserId.details.mode.reason == Vue.prototype.$GroupsChangeReason[6].key ? true : false;
            }
            return [
                {
                    name: 'Montante a recuperar',
                    general_value: this.groupChangesByUserId.details.history.amountToRecoverNormal ? this.groupChangesByUserId.details.history.amountToRecoverNormal.USD.value : 0,
                    value: this.groupChangesByUserId.details.history.amountRecoveredNormal ? this.groupChangesByUserId.details.history.amountRecoveredNormal.USD.value : 0,             
                    colorIcon: 'success',
                    show: this.isActiveGroupSameAsUserGroup,
                    showIcon: mode,
                },
            ]
        },

        // Métricas para subida por dias ausente
        metricsPanel7: function () {
            let mode = false;
            if(this.groupChangesByUserId.details && this.groupChangesByUserId.details.mode){
                mode = this.groupChangesByUserId.details.mode.reason == Vue.prototype.$GroupsChangeReason[2].key ? true : false;
            }

            return [
                {
                    name: 'Nº de sessões para subir',
                    general_value: this.groupChangesByUserId.details.levelParams.UP_DAYS_OFF_SESSIONS,
                    value: this.groupChangesByUserId.details.history.days_off, 
                    colorIcon: 'success',
                    show: this.isActiveGroupSameAsUserGroup,
                    showIcon: mode,
                },
            ]
        },
    },

    watch: {
        selectedGroup: function() {
            this.activeGroup = this.selectedGroup;
        }
    },

    methods: {
        initialState() {
            return {
                options: {
                    page: 1,
                    itemsPerPage: -1,
                },
                activeGroup: null,
                currentIndex: null,
                dataLoaded: false,
                fastEnabled: false,
                expansionPanels: {
                    panel_1: {
                        name: 'Métricas para subida rápida',
                        icon: 'mdi-escalator-up',
                        active: false,
                        show: false,
                    },
                    panel_2: {
                        name: 'Métricas para subida normal',
                        icon: 'mdi-stairs-up',
                        active: false,
                        show: false,
                    },
                    panel_3: {
                        name: 'Métricas para descida por makeup',
                        icon: 'mdi-stairs-down',
                        active: false,
                        show: false,
                    },
                    panel_4: {
                        name: 'Métricas para descida por dias ausente',
                        icon: 'mdi-stairs-down',
                        active: false,
                        show: false,
                    },
                    panel_5: {
                        name: 'Métricas para recuperação (rápida)',
                        icon: 'mdi-redo-variant',
                        active: false,
                        show: false,
                    },
                    panel_6: {
                        name: 'Métricas para recuperação (normal)',
                        icon: 'mdi-redo-variant',
                        active: false,
                        show: false,
                    },
                    panel_7: {
                        name: 'Métricas para subida por dias ausente',
                        icon: 'mdi-stairs-up',
                        active: false,
                        show: false,
                    },
                },
                model: null,
                percentage: 45,
            }
        },

        async fetchGroups() {

            // Base payload
            let payload = {
                params: {
                page: this.options.page,
                itemsPerPage: this.options.itemsPerPage,
                }
            };

            // get groups list
            await this.$store.dispatch('groups/get', payload);
        },

        /**
         * 
         * @param {object} item 
         */
        setActiveGroup(item) {
            this.activeGroup = item;

            this.currentIndex = this.groups.findIndex(e => e.id == item.id);
            this.fetchSingleGroup(item.id);
        },

        async fetchGroupChangesByUserId() {
            let payload = {
                id: this.selectedUser.id,
                params: {
                    details: true,
                }
            }

            if (this.userIsRailManager) {
                await this.$store.dispatch('groups/getGroupChangesByUserId', payload);
            } else {
                await this.$store.dispatch('groups/getOwnGroupChanges', payload);
            }
        },

        async fetchSingleGroup(id) {
            let payload = {
                id: id
            }
            await this.$store.dispatch('groups/getGroup', payload);
        },

        openDefaultPanel() {
            
            let mode = this._.get(this.groupChangesByUserId, 'details.mode.reason', null);
            
            switch (mode) {
                // Subida rápida
                case Vue.prototype.$GroupsChangeReason[4].key:
                    this.expansionPanels.panel_1.show = true;
                    this.expansionPanels.panel_1.active = true;
                    break;
                
                // Subida normal
                case Vue.prototype.$GroupsChangeReason[3].key:
                    this.expansionPanels.panel_2.show = true;
                    this.expansionPanels.panel_2.active = true;
                    break;
                
                // Descida por makeup
                case Vue.prototype.$GroupsChangeReason[0].key:
                    this.expansionPanels.panel_3.show = true;
                    this.expansionPanels.panel_3.active = true;
                    break;
                
                // Descida por dias ausente
                case Vue.prototype.$GroupsChangeReason[1].key:
                    this.expansionPanels.panel_4.show = true;
                    this.expansionPanels.panel_4.active = true;
                    break;
                
                // Recuperação (rápida)
                case Vue.prototype.$GroupsChangeReason[5].key:
                    this.expansionPanels.panel_5.show = true;
                    this.expansionPanels.panel_5.active = true;
                    break;
                
                // Recuperação (normal)
                case Vue.prototype.$GroupsChangeReason[6].key:
                    this.expansionPanels.panel_6.show = true;
                    this.expansionPanels.panel_6.active = true;
                    break;

                // Subida por dias ausente
                case Vue.prototype.$GroupsChangeReason[2].key:
                    this.expansionPanels.panel_7.show = true;
                    this.expansionPanels.panel_7.active = true;
                    break;

                default:
                    break;
            }
        },

        getProgressLinearColor(mode) {
            let output = 'grey lighten-3'
            if (mode) {
                switch (mode) {
                    case Vue.prototype.$GroupsChangeReason[0].key:
                    case Vue.prototype.$GroupsChangeReason[1].key:
                        output = 'error';
                        break;
                    case Vue.prototype.$GroupsChangeReason[2].key: 
                    case Vue.prototype.$GroupsChangeReason[3].key: 
                    case Vue.prototype.$GroupsChangeReason[4].key: 
                    case Vue.prototype.$GroupsChangeReason[5].key: 
                    case Vue.prototype.$GroupsChangeReason[6].key: 
                        output = 'success';
                        break;
                    default:
                        break;
                }
                return output;
            }
            return output;
        },

        showComponentHandler() {
            let userTeamIds = this.selectedUser && !this._.isEmpty(this.selectedUser.teams)
                ? this.selectedUser.teams.map(e => e.id)
                : []
            
            return userTeamIds.includes(Vue.prototype.$team_division_mtt_id) && !this._.isNull(this.groupChangesByUserId);
        },

        /**
         * @param {Number|String} value 
         * @returns {String} with width value
         */
        getCurrencyInputWidth(value) {
            let output = '50px';
            if (value) {
                output = parseFloat(value).toFixed(3);

                // turn output into String
                output = String(output);

                output = (output.length + 1.2) * 8 + 'px';
            }
            return output;
        },
    },
}
