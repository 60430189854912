
import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth.vue';
import RailMixin from '@/mixins/Rail.vue';
import DataMixin from '@/mixins/Data.vue';
import EmptyStateNoData from '@/components/EmptyStateNoData';

export default {
  name: 'ScheduleTable',

  mixins: [
    AuthMixin,
    RailMixin,
    DataMixin,
  ],

  components: {
    EmptyStateNoData,
  },

  data() {
    return this.initializeState();
  },
  
  created() {
    this.info.title = 'Visualização do schedule ' + (this.userGroup ? this.userGroup.name : null);
    this.info.btnName = this.userGroup ? this.userGroup.name : '';
  },

  computed: {
    ...mapGetters({
      themeDarkMode: 'UI/themeDarkMode',
      heroDatePickerValue: 'UI/heroDatePickerValue',
      selectedSchedule: 'schedules/selectedSchedule',
      loadingSelectedSchedule: 'schedules/loadingSelectedSchedule',
      schedules: 'schedules/schedules',
      groups: 'groups/groups',
      userGroup: 'auth/userGroup',
    }),

    selectedScheduleComputed: function () {
      let resultArray = [];

      if (this.selectedSchedule && this.selectedSchedule.rules) {
        resultArray = this.selectedSchedule.rules.main;
      }

      if (this.tabs.current) {
        switch (this.tabs.current) {
          case 0:
            resultArray = this.selectedSchedule.rules.main;
            break;
          case 1:
            if (this.selectedSchedule.rules && this.selectedSchedule.rules.series) {
              resultArray = this.selectedSchedule.rules.series.items[0];
            };
            break;
          case 2:
            if (this.selectedSchedule.rules && this.selectedSchedule.rules.series) {
              resultArray = this.selectedSchedule.rules.series.items[1];
            };
            break;
          case 3:
            if (this.selectedSchedule.rules && this.selectedSchedule.rules.series) {
              resultArray = this.selectedSchedule.rules.series.items[2];
            };
            break;

          default:
            break;
        }
      }

      return resultArray;
    },

    selectedScheduleFiltered: function () {
      let output = [];
      if (!this._.isEmpty(this.selectedScheduleComputed)) {
        output = this.filterSchedules(this.selectedScheduleComputed);
      }
      return output;
    },

    selectedScheduleSpecialTournaments: function () {
      let torneiosDomingoObject = { 'type': 'Torneios de domingo', 'value': [] };
      let torneiosEspeciaisObject = { 'type': 'Torneios especiais', 'value': [] };
      let torneiosFimDeSemanaObject = { 'type': 'Torneios de fim-de-semana', 'value': [] };

      torneiosDomingoObject = this.selectedScheduleComputed.find(item => item.type === 'Torneios de domingo');
      torneiosEspeciaisObject = this.selectedScheduleComputed.find(item => item.type === 'Torneios especiais');
      torneiosFimDeSemanaObject = this.selectedScheduleComputed.find(item => item.type === 'Torneios de fim-de-semana');

      return {
        domingo: torneiosDomingoObject,
        especiais: torneiosEspeciaisObject,
        fim_de_semana: torneiosFimDeSemanaObject,
      };
    },

    tableTabComputed: function () {

      let tabs = ['Geral'];

      if (this.selectedSchedule && this.selectedSchedule.rules) {
        if (this.selectedSchedule.rules.series.count) {
          return tabs.concat(this.selectedSchedule.rules.series.names);
        }
      }

      return tabs;
    },

    validationScheduleFimDeSemana: function () {
      const fim_de_semana = this.selectedScheduleSpecialTournaments.fim_de_semana;
      // Check if fim_de_semana is defined and not empty, and it doesn't contain only empty strings
      const isValid = fim_de_semana && fim_de_semana.value.length > 0 && fim_de_semana.value.every(element => element !== "");
      return isValid;
    },

    validationScheduleEspeciais: function () {
      const especiais = this.selectedScheduleSpecialTournaments.especiais;
      // Check if especiais is defined and not empty, and it doesn't contain only empty strings
      const isValid = especiais && especiais.value.length > 0 && especiais.value.every(element => element !== "");
      return isValid;
    },

    validationScheduleDomingo: function () {
      const domingo = this.selectedScheduleSpecialTournaments.domingo;
      // Check if domingo is defined and not empty, and it doesn't contain only empty strings
      const isValid = domingo && domingo.value.length > 0 && domingo.value.every(element => element !== "");
      return isValid;
    },
  },

  watch: {
    userGroup: function () {
      this.info.title = 'Visualização do schedule ' + (this.userGroup ? this.userGroup.name : null);
      this.info.btnName = this.userGroup ? this.userGroup.name : '';
    }
  },

  methods: {
    initializeState() {
      return {
        info: {
          title: 'Visualização do schedule',
          subtitle: 'A ver o teu schedule atual',
          showAlert: false,
          alertMessage: 'A ver o schedule para o nível seguinte, joga apenas os limites para o teu nível atual para não criar anomalias!',
          btnWord: 'Nível atual',
          btnName: 'Nível'
        },
        values: ['previous', 'mine', 'next'],
        selectedIndex: 1, // 'mine' initially selected
        emptyArray: [""],
        items: [],
        navBar: null,
        tabs: {
          current: 0,
          headers: ['Geral', 'Series'],
        },
        headers: [
          { text: 'Tipo de jogo', value: 'type' },
          { text: 'Buy-in máximo', value: 'value' },
        ],
      };
    },

    filterSchedules(item) {
      const excludedTypes = ['Torneios de fim-de-semana', 'Torneios especiais', 'Torneios de domingo', 'Notas', 'name'];
      const filteredSchedules = item.filter(schedule => !excludedTypes.includes(schedule.type));
      return filteredSchedules;
    },

    emphasizeWords(inputString) {
      const wordsToEmphasize = ["GTD", "Turbo", "Entradas"];

      // Emphasize specific words
      wordsToEmphasize.forEach(word => {
        const wordPattern = new RegExp(`\\b${word}\\b`, 'gi');
        inputString = inputString.replace(wordPattern, `<strong>${word}</strong>`);
      });

      // Emphasize dollar values followed by "K"
      const dollarPattern = /\$[\d,.]+\s?K/gi;
      inputString = inputString.replace(dollarPattern, match => `<strong>${match}</strong>`);

      return inputString;
    },

    async selectSchedule(name) {

      const data = [
        { value: 'mine', endpoint: 'schedules/getMine', btnWord: 'Nível atual', showAlert: false, group: this.getGroupInfo(this.userGroup.id), alertMessage: null },
        { value: 'next', endpoint: 'schedules/getNextSchedule', btnWord: 'Nível seguinte', showAlert: true, group: this.getGroupInfo(this.userGroup.next), alertMessage: 'Este schedule é do nível seguinte - não jogues estes limites ou serão detetadas anomalias ⚠', },
        { value: 'previous', endpoint: 'schedules/getPreviousSchedule', btnWord: 'Nível anterior', showAlert: true, group: this.getGroupInfo(this.userGroup.previous), alertMessage: 'Este schedule é do nível inferior ao teu atual' },
      ];

      this.$store.commit('schedules/setSelectedSchedule', null);
      this.$emit('marker-color', name);

      const information = data.find(t => name == t.value);

      this.info.title = 'Schedule do ' + information.group.name;
      this.info.subtitle = name == 'mine' ? 'A ver o teu schedule atual' : 'A ver o schedule de outro nível';
      this.info.btnName = information.group.name;
      this.info.btnWord = information.btnWord

      let apiCall = await this.$store.dispatch(information ? information.endpoint : 'schedules/getMine');

      if (apiCall === true) {
        this.info.showAlert = information.showAlert;
        this.info.alertMessage = information.alertMessage;
      }
    },

    getGroupInfo(id) {
      if (id) {
        return this.groups.find(e => e.id == id);
      }
    },

    previous() {
      this.selectedIndex = (this.selectedIndex - 1 + this.values.length) % this.values.length;
      this.selectSchedule(this.values[this.selectedIndex]);
    },
    next() {
      this.selectedIndex = (this.selectedIndex + 1) % this.values.length;
      this.selectSchedule(this.values[this.selectedIndex]);
    }
  }
}
