import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth.vue';
import AlertError from '@/components/Alerts/AlertError/AlertError.vue';
import GroupDropdown from '@/components/Rail/GroupDropdown/GroupDropdown.vue';

export default {
	name: 'DialogEditUserLevel',

	components: {
		AlertError,
		GroupDropdown,
	},

	props: {
		isOpen: { type: Boolean, required: true, default: false },
		direction: { type: String, required: false, default: null }, // valid values -> ['UP', 'DOWN' ]
		user: { type: Object, required: false, default: () => {} }
	},

	mixins: [
		AuthMixin,
	],
	
	data() {
		return this.initialState();
	},

	computed: {
		...mapGetters({
			selectedUser: 'users/selectedUser',
			humanError: 'users/humanError',
			error: 'users/error',
			groups: 'groups/groups',
		}),

		imageCutout: function () {
            return this.selectedUser && this.selectedUser.imgCutout ?  this.selectedUser.imgCutout : null;
        },

		userNameFirstLetter: function () {
            return ! this._.isNull(this.form.username)
					? this.form.username[0]
					: null;
        },
	},

	watch: {
		isOpen: function () {
			this.onOpen();	
		},
	},

	methods: {
		initialState() {
			return {
				submitting: false,
				form: {
					group: null,
					username: null,
					atualGroup: null,
				},
				rules: {
					group: [
						(value) => !this._.isEmpty(value) || 'Por favor selecione um grupo'
					]
				}
			};
		},

		onOpen() {
			// Clear user errors
			this.clearErrors();
			// populate input fields
			this.populateFields();
		},

		populateFields() {

			this.form.username = this.user ? this.user.name : 'Sem informação';
			this.form.atualGroup = this.user ? this.user.group : null;

			if (this.direction == 'UP') {
				this.form.group = { id: this.user.group.next }
			} else if (this.direction == 'DOWN') {
				this.form.group = { id: this.user.group.previous }
			} else {
				this.form.group = null;
			}
		},

		async submit() {

			if (!this.$refs.formEditUserLevel.validate()) {
				return false;
			}

			if (!this.$refs.groupDropdownRef.$refs.formGroupDropdown.validate()) {
				return false;
			}

            // Toggle submit button
            this.toggleSubmitting();

            let payload = {
                id: this.user.id,
                group: this.form.group.id
            }

            let result = await this.$store.dispatch('users/updateUser', payload);

            // // Re-enable button
            this.toggleSubmitting();

			// On success
			if (result === true) {
				// Show success snackbar
				this.$store.dispatch('UI/showSnackbar', {
					message: 'Nível do jogador atualizado com sucesso.',
					color: 'success'
				});

				this.$emit('reload');
				
				// closes dialog
				this.closeDialog();
			}
		},

		/**
		 * Used to disable Submit button
		 */
		toggleSubmitting() {
			this.submitting = !this.submitting;
		},

		closeDialog() {
			this.$emit("close-dialog");
			this.clearErrors();
		},
		
		clearErrors() {
			if (this.$refs && this.$refs.groupDropdownRef && this.$refs.groupDropdownRef.$refs.formGroupDropdown) {
				this.$refs.groupDropdownRef.$refs.formGroupDropdown.resetValidation();
			}
			this.$store.dispatch('users/clearErrors');
		}
	},
};