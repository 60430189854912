var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"form"},[_c('v-data-table',{style:({'max-height': _vm.maxHeight, 'overflow':'auto'}),attrs:{"id":"divNicknameList","headers":_vm.dataTableHeaders,"items":_vm.transfersListComputed,"items-per-page":_vm.dataTable.itemsPerPage,"height":"100%","fixed-header":"","dense":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.source",fn:function(ref){
var item = ref.item;
return [(_vm.action == 'create')?_c('div',[(_vm.loadingInput)?_c('v-select',{staticClass:"my-3 my-md-0",attrs:{"loading":"","disabled":"","placeholder":"Origem","dense":"","solo":"","flat":"","background-color":"transparent"},model:{value:(item.source),callback:function ($$v) {_vm.$set(item, "source", $$v)},expression:"item.source"}}):_c('NicknameDropdown',_vm._b({staticClass:"my-3 my-md-0",on:{"change":function($event){return _vm.setRate(item)}},model:{value:(item.source),callback:function ($$v) {_vm.$set(item, "source", $$v)},expression:"item.source"}},'NicknameDropdown',_vm.nicknameSourceComputed,false)),_c('v-spacer')],1):_c('div',[(item.source)?_c('div',{staticClass:"d-flex align-center mx-0 px-0"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('img',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"src":require('@/assets/networks/' + item.source.network.imageURL)}},'img',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(item.source ? item.source.network.name : '')+" ")])]),_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"body-2"},[_vm._v(" "+_vm._s(item.source ? item.source.network.name : '')+" ")]),_c('span',{staticClass:"caption text-truncate",staticStyle:{"max-width":"150px"}},[_vm._v(" "+_vm._s(item.source ? item.source.name : '')+" ")])])],1):_vm._e()])]}},{key:"item.target",fn:function(ref){
var item = ref.item;
return [(_vm.action == 'create')?_c('div',[(_vm.loadingInput)?_c('v-select',{staticClass:"my-3 my-md-0",attrs:{"loading":"","disabled":"","placeholder":"Destino","dense":"","solo":"","flat":"","background-color":"transparent"},model:{value:(item.target),callback:function ($$v) {_vm.$set(item, "target", $$v)},expression:"item.target"}}):_c('NicknameDropdown',_vm._b({staticClass:"my-3 my-md-0",on:{"change":function($event){return _vm.setRate(item)}},model:{value:(item.target),callback:function ($$v) {_vm.$set(item, "target", $$v)},expression:"item.target"}},'NicknameDropdown',_vm.nicknameTargetComputed,false))],1):_c('div',[(item.target)?_c('div',{staticClass:"d-flex align-center mx-0 px-0"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('img',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"src":require('@/assets/networks/' + item.target.network.imageURL)}},'img',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(item.target ? item.target.network.name : '')+" ")])]),_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"body-2"},[_vm._v(" "+_vm._s(item.target ? item.target.network.name : '')+" ")]),_c('span',{staticClass:"caption text-truncate",staticStyle:{"max-width":"150px"}},[_vm._v(" "+_vm._s(item.target ? item.target.name : '')+" ")])])],1):_vm._e()])]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('span',{class:_vm.action == 'create' ? 'mb-md-4' : ''},[_vm._v(_vm._s(_vm.getCurrency(item)))]),_c('input-financial',{ref:"inputFinancial",class:_vm.action == 'create' ? 'mb-md-4' : '',style:({ color: _vm.valueColorFinal(), width: _vm.action == 'create' ? '100%' : _vm.getCurrencyInputWidth(item.amount, true) }),attrs:{"allow-negative":"","precision":_vm.handleDecimalNumbers(item.source),"vbind":{
            label: _vm._.isNull(item.amount) || item.amount == 0 ? 'Montante' : '',
            outlined: false,
            solo: true,
            dense: true,
            flat: true,
            backgroundColor: 'transparent',
            hideDetails: true,
            readonly: _vm.action == 'create' ? false : true,
            rules: [],
          }},model:{value:(item.amount),callback:function ($$v) {_vm.$set(item, "amount", _vm._n($$v))},expression:"item.amount"}})],1)]}},{key:"item.rate",fn:function(ref){
          var item = ref.item;
return [(_vm.action == 'create')?_c('v-edit-dialog',{attrs:{"return-value":item.rate,"large":"","persistent":"","cancel-text":"Cancelar","save-text":"Guardar"},on:{"update:returnValue":function($event){return _vm.$set(item, "rate", $event)},"update:return-value":function($event){return _vm.$set(item, "rate", $event)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('div',{staticClass:"mt-4 text-h6"},[_vm._v(" Editar taxa ")]),_c('input-financial',{ref:"inputFinancial",style:({ color: _vm.valueColorFinal(), width: _vm.action == 'create' ? '100%' : _vm.getCurrencyInputWidth(item.rate, true) }),attrs:{"vbind":{
              label: '',
              outlined: false,
              dense: true,
              flat: true,
              backgroundColor: 'transparent',
              hideDetails: _vm.action == 'create' ? false : true,
              readonly: _vm.action == 'create' ? false : true,
              rules: [],
            }},model:{value:(item.rate),callback:function ($$v) {_vm.$set(item, "rate", _vm._n($$v))},expression:"item.rate"}})]},proxy:true}],null,true)},[_c('currency-input',{class:_vm.action == 'create' ? 'mb-md-5 caption' : 'caption',style:({ color: _vm.valueColorFinal(), width: _vm.getCurrencyInputWidth(item.rate, true) }),attrs:{"value":item.rate,"currency":null,"disabled":""}})],1):_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"caption mr-3 mr-md-0 w-100"},[_vm._v(_vm._s(item.rate ? item.rate : 'Sem Taxa'))])])]}},{key:"item.delete",fn:function(ref){
            var item = ref.item;
return [(_vm.action == 'create' && !_vm.singleTransfer)?_c('div',{staticClass:"d-flex align-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2 mr-4 mb-md-7",attrs:{"icon":"","color":_vm.valueColorFinal(),"disabled":_vm.form.transfersList.length == 1},on:{"click":function($event){return _vm.remove(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-delete-forever ")])],1)]}}],null,true)},[_c('span',[_vm._v("Remover transferência")])])],1):_vm._e()]}}])}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [(_vm.action == 'create' && !_vm.singleTransfer)?_c('v-btn',_vm._g(_vm._b({attrs:{"block":"","depressed":""},on:{"click":function($event){return _vm.add()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" Adicionar transferência ")],1):_vm._e()]}}])},[_c('span',[_vm._v("Clica aqui se pretendes adicionar uma nova transferência a esta operação.")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }