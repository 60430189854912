import { mapGetters } from 'vuex';
import EmptyStateNoData from '@/components/EmptyStateNoData';

export default {
    name: 'ColumnChart',
    
    components: {
        EmptyStateNoData,
    },

	props: {
		title: {
			type: String,
			required: false,
			default: '',
		},
		subtitle: {
			type: String,
			required: false,
			default: '',
		},
		data: {
			type: Array,
			required: false,
			default: () => [],
		},
		labels: {
			type: Array,
			required: false,
			default: () => [],
        },
        loading: {
            type: Boolean,
            required: false,
            default: false
        },
        options: {
            type: Object,
            required: false,
            default: () => ({
                chart: {
                    type: 'bar',
                    zoom: {
                        enabled: false
                    },
                    toolbar: {
                        show: false
                    },
                    foreColor: '#f6f7f8',
                    background: 'light',
                },
                plotOptions: {
                    bar: {
                        borderRadius: 10,
                        columnWidth: '40%',
                    }
                },
                colors: ['#FFC900'],
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'straight'
                },
                xaxis: {
                    type: 'week',
                    labels: {
                        show: true,
                        style: {
                            colors: '#000000',
                        },
                    }
                },
                yaxis: {
                    opposite: true,
                    labels: {
                        show: true,
                        style: {
                            colors: '#000000',
                        },
                    }
                },
                legend: {
                    horizontalAlign: 'left'
                },
                theme: {
                    mode: 'light',
                }
            })
        },
        height: {
            type: String,
			required: false,
			default: '350',
        },
        showCard: {
            type: Boolean,
			required: false,
			default: true,
        }
    },
    
    computed: {
        ...mapGetters({
            themeDarkMode: 'UI/themeDarkMode',
        }),

        series: function () {
            return [{
                name: this.title,
                data: this.data,
            }];
        }

        /*
        options: function() {
            return {
                chart: {
                    type: 'bar',
                    zoom: {
                        enabled: false
                    },
                    toolbar: {
                        show: false
                    },
                    foreColor: '#f6f7f8',
                    background: this.themeDarkMode ? 'dark' : 'light',
                },
                plotOptions: {
                    bar: {
                        borderRadius: 10,
                        columnWidth: '40%',
                    }
                  },
      
                labels: this.labels,
                colors: ['#FFC900'],
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'straight'
                },
                xaxis: {
                    type: 'week',
                    labels: {
                        show: true,
                        style: {
                            colors: this.themeDarkMode ? '#FFFFFF' : '#000000',
                        },
                    }
                },
                yaxis: {
                    opposite: true,
                    labels: {
                        show: true,
                        style: {
                            colors: this.themeDarkMode ? '#FFFFFF' : '#000000',
                        },
                    }
                },
                legend: {
                    horizontalAlign: 'left'
                },
                theme: {
                    mode: this.themeDarkMode ? 'dark' : 'light',
                }
            }
        }
        */
    },

    watch: {
        themeDarkMode: function () {
            this.adaptToBrightnessMode();
        }
    },

    data() {
        return this.initialState();
    },

    created() {
    },
    
    mounted() {
        this.initialSetup();
    },


    methods: {
        initialState() {
            return {
                optionsHandled: this.options,
            };
        },

        /**
         * Initial setup of the component
         * Prepare props such as 'labels' and brightness mode related stuff
         */
        initialSetup() {
            this.optionsHandled.labels = this.labels;
            this.adaptToBrightnessMode();
        },

        /**
         * Alters chart light mode according to this.themeDarkMode / $vuetify.theme.dark value
         */
        adaptToBrightnessMode() {
            if (this.$refs.ColumnChart) {
                let newApexChartsThemeMode = this.themeDarkMode ? 'dark' : 'light';

                if (newApexChartsThemeMode != this.optionsHandled.theme.mode) {
                    this.optionsHandled.chart.background = this.themeDarkMode ? 'dark' : 'light';
                    this.optionsHandled.xaxis.labels.style.colors = this.themeDarkMode ? '#FFFFFF' : '#000000';
                    this.optionsHandled.yaxis.labels.style.colors = this.themeDarkMode ? '#FFFFFF' : '#000000';
                    this.optionsHandled.theme.mode = this.themeDarkMode ? 'dark' : 'light';
                }
                this.$refs.ColumnChart.refresh();
            }
        },
    },
}