import AuthMixin from '@/mixins/Auth.vue';
import DataMixin from '@/mixins/Data.vue';
import MenuUserNotes from '@/components/Menus/MenuUserNotes/MenuUserNotes.vue';

export default {
    name: 'AlertNote',

    props: {
        border: { type: String, required: false, default: 'left' },
        coloredBorder: { type: Boolean, required: false, default: true },
        content: { type: String, required: false, default: '' },
        color: { type: String, required: false, default: '#FFC900' },
        author: { type: String, required: false, default: '' },
        date: { type: String, required: false, default: '' },
        stateToPopulate: { type: String, required: true, default: 'userNotes' }, // VALID VALUES ['userNotes', 'userTargetNotes]
    },

    components: {
        MenuUserNotes,
    },

    mixins: [
        DataMixin,
        AuthMixin,
    ],

    data() {
        return this.initialState();
    },

    methods: {
        initialState() {
            return {};
        },

        // emits change to parent component
        setActiveItem() {
            this.$emit('set-active');
        }
    }
}