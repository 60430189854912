import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth';
import DataMixin from '@/mixins/Data';
import Hero from '@/components/Hero/Hero.vue';
import AlertNoData from '@/components/AlertNoData';
import CardStatistics from '@/components/Rail/Statistics/CardStatistics/CardStatistics.vue';
import TableGroupMembers from '@/components/Tables/TableGroupMembers/TableGroupMembers.vue';
import LineChartCumulativePNL from '@/components/Charts/LineChartCumulativePNL/LineChartCumulativePNL.vue';
import Snackbar from "@/components/Snackbar";
import Loading from '@/views/Loading.vue';
import DialogEditGroup from '@/components/Dialog/DialogEditGroup/DialogEditGroup.vue';
import GroupInformation from '@/components/GroupInformation/GroupInformation.vue';

export default {
    name: 'Groups-Single',

    components: {
        Hero,
        AlertNoData,
        CardStatistics,
        TableGroupMembers,
        LineChartCumulativePNL,
        Snackbar,
        Loading,
        DialogEditGroup,
        GroupInformation,
    },

    mixins: [
        AuthMixin,
        DataMixin,
    ],

    computed: {
        ...mapGetters({
            selectedGroup: 'groups/selectedGroup',
            groupMembersTotal: 'users/usersFromGroupTotal',
            groupMembersTotalLoading: 'users/usersFromGroupTotalLoading',
            groupGameEntries: 'groups/groupGameEntries',
            groupGameEntriesLoading: 'groups/groupGameEntriesLoading',
            groupPnl: 'groups/groupPnl',
            groupPnlLoading: 'groups/groupPnlLoading',
            groupPnlGraph: 'groups/groupPnlGraph',
            groupPnlGraphLoading: 'groups/groupPnlGraphLoading',
            heroDatePickerValue: 'UI/heroDatePickerValue',
            selectedGroupLoading: 'groups/selectedGroupLoading',
            groupErrorStatus: 'groups/errorStatus',
        }),

        cardInfo() {
            return [
                {
                    name: 'Nº de jogadores',
                    value: this.groupMembersTotal ? this.groupMembersTotal : 0,
                    precision: 0,
                    icon: 'mdi-account',
                    showCurrency: false,
                    showCurrencyIcon: 'mdi-currency-usd',
                    showAltCurrency: false,
                    loading: this.groupMembersTotalLoading,
                    show: true,
                },
                {
                    name: 'Nº de Jogos',
                    value: this.groupGameEntries ? this.groupGameEntries : 0,
                    precision: 0,
                    icon: 'mdi-cards-playing-outline',
                    showCurrency: false,
                    showCurrencyIcon: 'mdi-currency-usd',
                    showAltCurrency: false,
                    loading: this.groupGameEntriesLoading,
                    show: true,
                },
                {
                    name: 'Lucro',
                    value: this.groupPnl && this.groupPnl.EUR ? this.groupPnl.EUR.value : 0,
                    precision: 2,
                    icon: 'mdi-cash',
                    showCurrency: true,
                    showAltCurrency: false,
                    loading: this.groupPnlLoading,
                    show: true,
                },
            ];
        },

        groupPnlGraphComputed: {
            get: function () {
				return this.graphPnl;
            },
            set: function (newValue) {
                this.graphPnl.series = newValue ? newValue.series : [];
                this.graphPnl.labels = newValue ? newValue.labels : [];
            }
        },

        isLoading: function () {
            return this.selectedGroupLoading;
        },


    },

    watch: {
        heroDatePickerValue: function () {
            this.fetchData();
        },

        groupPnlGraph: {
            handler: function (val, oldVal) {
                this.groupPnlGraphComputed = val;
            },
            deep: true,
        },

        isLoading: function () {
            this.validateAccessPermission();
        },

    },

    data() {
        return this.initialState();
    },

    created() {
        this.initialize();
    },

    methods: {
        initialState() {
            return {
                graphPnl: {
                    series: [],
                    labels: [],
                },
                dialogues:{
                    editGroup: false,
                }
            };
        },

        validateAccessPermission() {
            // Redirect user to homepage if unable to access group single view
            if (this._.includes([401, 403, 404], this.groupErrorStatus)) {
                this.$router.push({ path: '/' });
            }
        },


        async initialize() {
            // Restrict access
            await this.allowOnlyRoles(['rail_admin', 'rail_manager', 'tracker_admin', 'tracker_manager', 'super_coach']);
            
            this.$store.dispatch('groups/getGroup', { id: this.$route.params.id });
        },

        toggleDialog(dialog){
            this.dialogues[dialog] = ! this.dialogues[dialog]
        },

        fetchData() {
            let payload = {
                id: this.$route.params.id,
                params: {},
            }

            if (!this._.isEmpty(this.heroDatePickerValue)) {
                payload.params.dateBegin = this.heroDatePickerValue[0];
                payload.params.dateEnd = this.heroDatePickerValue[1];
            }

            this.getGroupMembers();

            this.$store.dispatch('groups/getGroupGameEntries', payload);
            this.$store.dispatch('groups/getGroupPnl', payload);
            this.$store.dispatch('groups/getGroupPnlGraph', payload);
        },

        getGroupMembers() {
            let payload = {
                params: {
                    group: this.$route.params.id,
                },
            }

            if (!this._.isEmpty(this.heroDatePickerValue)) {
                payload.params.dateBegin = this.heroDatePickerValue[0];
                payload.params.dateEnd = this.heroDatePickerValue[1];
            }

            this.$store.dispatch('users/getUsersFromGroup', payload);
        },

        async fetchGroups() {
            let pageNumber = {
                params: {
                    page: 1,
                    itemsPerPage: 10,
                }
            }
            
            // API Call
            await this.$store.dispatch('groups/get', pageNumber);
        }
    },
}