export default {
    name: 'MenuPredefinedDates',

    data() {
        return this.initialState();
    },
    
    methods: {
        initialState() {
            return {
                datePreFilters: [
                    {
                        name: 'Hoje',
                        dates: [
                            this.$moment().format('YYYY-MM-DD'),
                            this.$moment().format('YYYY-MM-DD')
                        ]
                    },
                    {
                        name: 'Esta semana',
                        dates: [
                            this.$moment().startOf('week').format('YYYY-MM-DD'),
                            this.$moment().format('YYYY-MM-DD')
                        ]
                    },
                    {
                        name: 'Este mês',
                        dates: [
                            this.$moment().startOf('month').format('YYYY-MM-DD'),
                            this.$moment().endOf('month').format('YYYY-MM-DD')
                        ]
                    },
                    {
                        name: 'Desde o inicio do ano',
                        dates: [
                            this.$moment().startOf('year').format('YYYY-MM-DD'),
                            this.$moment().format('YYYY-MM-DD')
                        ]
                    },
                    {
                        name: 'Último mês',
                        dates: [
                            this.$moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'),
                            this.$moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')
                        ]
                    },
                    {
                        name: 'Últimos 3 meses',
                        dates: [
                            this.$moment().subtract(3, 'month').format('YYYY-MM-DD'),
                            this.$moment().format('YYYY-MM-DD')
                        ]
                    },
                    {
                        name: 'Último ano',
                        dates: [
                            this.$moment().subtract(1, 'year').startOf('year').format('YYYY-MM-DD'),
                            this.$moment().subtract(1, 'year').endOf('year').format('YYYY-MM-DD')
                        ]
                    },
                ]
            }
        },

        emitEvent(dates) {
            this.$emit('setPredefinedDate', dates);
        }
    } 
};
