import Vue from 'vue';
import { mapGetters } from 'vuex';
import CardStepper from '@/components/CardStepper/CardStepper.vue';
import CardStepperNotes from '@/components/CardStepper/CardStepperNotes/CardStepperNotes.vue';
import DialogViewAttachment from '@/components/Rail/Dialog/DialogViewAttachment/DialogViewAttachment.vue';
import RowTransfer from '@/components/Rail/RowTransfer/RowTransfer.vue';
import AlertError from '@/components/Alerts/AlertError/AlertError.vue';
import RailMixin from '@/mixins/Rail.vue';
import AuthMixin from '@/mixins/Auth.vue';
import AlertUserNote from '@/components/Rail/AlertUserNote/AlertUserNote.vue';
import DialogUserNotes from '@/components/Rail/Dialog/DialogUserNotes/DialogUserNotes.vue';

export default {
  name: 'CardBankrollReduction',

  components: {
    CardStepper,
    CardStepperNotes,
    RowTransfer,
    AlertError,
    DialogViewAttachment,
    DialogUserNotes,
    AlertUserNote,
  },

  mixins: [
    RailMixin,
    AuthMixin,
  ],

  created() {
    return this.fetchData();
  },

  computed: {
    ...mapGetters({
      users: 'users/usersAllActive',
      selectedRequest: 'railRequests/selectedRequest',
      error: 'railRequests/error',
      humanError: 'railRequests/humanError',
      userNotes: 'users/userNotes',
      teamFilter: 'teams/teamFilter',
    }),

    selectedRequestObservation: function () {
      return this.getObservationByPhase(this.selectedRequest.observations, Vue.prototype.table_requests_phases[Vue.prototype.$rail_ops.operations.bankroll_reduction][1]['value'])
    },

    hasInternalTransfers: function () {
      // find transfers type PNPW in selectedRequest
      let findInternalTransfers = this.selectedRequest.transfers.find(e => e.type == Vue.prototype.$transfer_types.PNPW);
      // returns false if doesn't find transfers type PNPW, else return true
      return findInternalTransfers == undefined ? false : true;
    },
  },

  watch: {
    selectedRequest: function () {
      // reset component state
      this.resetComponentState();
      // populate component fields
      this.populateFields();
    }
  },

  data() {
    return this.initialState();
  },

  methods: {
    initialState() {
      return {
        checkboxTransferDone: false,
        isFirstWalletANetwork: null,
        cardTitles: [
          'Resumo inicial',
          'Confirmar transferência',
          'Resumo',
        ],
        formValidations: [
          true,
          false,
          true,
        ],
        form: {
          user: null,
          nickname: null,
          observation: null,
          walletSource: null,
          walletTarget: null,
          balance: null,
          nicknames: [],
          gyazoLink: null,
          fileName: [],
        },
        rules: {
          checkbox: [
            (v) => !!v || '',
          ],
        },
        dialogues: {
          viewAttachment: false,
          userNotes: false,
        },
        observation: {
          url: null,
          type: null,
        },
      }
    },

    async fetchData() {
      this.populateFields();
      // get users list
      if (this._.isEmpty(this.users)) {
        let payload = this.formulateUsersAllUrlParameters();
        this.$store.dispatch('users/getUsersAllActive', payload);
      }
    },

    /**
    * Validates a specific form step.
    * Assumes form steps are named 'formStep_x' where x is a Int 0 - maxStep-1.
    * 
    * @param {Int} step Identifier of the form step to validate.
    * @returns {Void}
    */
    validateStep(step) {
      const formStepRef = 'formStep_' + (step);
      if (this.$refs[formStepRef]) {
        // Reset validation after enter step 2
        if (this.$refs.formStep_2) {
          this.$refs.checkboxRef.resetValidation();
        }
        
        // Update formValidation[step] in a way the DOM will be reactive
        Vue.set(
          this.formValidations,
          step - 1,
          this.$refs[formStepRef].validate()
        );

        // clean errors if exists
        if (this.error) {
          this.$store.dispatch('railRequests/clearError');
        }
      }
    },

    populateFields() {
      if (this.selectedRequest) {

        // if hasInternalTransfers == undefined it means that selectedRequest doesn't have transfers PNPW
        if (this.hasInternalTransfers) {
          this.form.nickname = this.selectedRequest.transfers[0]['source'];
        } 
        this.form.user = this.selectedRequest.user.displayName;
        this.fetchUserNotes(this.selectedRequest.user);
      }
    },

    /**
    * @param {*Number} type 
    * @returns array passed as prop to RowTransfers Component
    */
    getTransfers(type = null) {
      let transfers = [];

      if (type) {
        this.selectedRequest.transfers.forEach(transfer => {
          if (type == transfer.type) {
           transfers.push({
              source: transfer.source,
              target: transfer.target,
              amount: transfer.amount,
              isPending: transfer.isPending
            })
          }
        })
      } else {
        this.selectedRequest.transfers.forEach(transfer => {
           transfers.push({
              source: transfer.source,
              target: transfer.target,
              amount: transfer.amount,
              isPending: transfer.isPending
            })
        })
      }
      return transfers;
    },

    /**
     * Get nicknames/wallets from management that will receive money from player
     * returns {Array}
     */
    getNicknamesDisplayedLastStep() {
      let list = []
      if (!this._.isEmpty(this.selectedRequest.transfers)) {

        this.selectedRequest.transfers.forEach(e => {
          let objectList = {
            transferID: e.id,
            imageURL: e.target.network.imageURL,
            name: e.target.network.name,
            email: e.target.name,
            symbol: e.target.network.currency.symbol,
            amount: e.amount.value
          }
          if (e.type == Vue.prototype.$transfer_types.P_M) {
            list.push(objectList);
          }
        })
      }
      return list;
    },

    /**
     * Get nicknames/wallets from management 
     * uses getNicknamesDisplayedLastStep() to retrieve nicknames
     * @returns {Array}
     */
    getConfirmationWallets() {
      let output = [];
      let filterNicknames = this.getNicknamesDisplayedLastStep();

      filterNicknames.forEach(el => {
        let nicknameObject = {
          imageURL: el.imageURL,
          name: el.name,
          email: el.email
        }

        let findSameObject = output.find(el2 => el.name == el2.name)
        let alreadyExists = findSameObject == undefined ? false : true; 

        if (!alreadyExists) {
          output.push(nicknameObject);
        }
      })

      return output;
    },


    // retrieve observation fields from CardStepperNotes component
    getInfoFromCardStepperNotes(payload) {
      if (payload) {
        this.form.gyazoLink = payload.gyazoLink;
        this.form.fileName = payload.filename;
      }
    },


    // retrieve fields populated by admin to create a Bankroll Reduction request !
    async submit() {
      // Validate form
      if (!this.$refs.formBankrollReductionChild.validate()) {
        return false;
      }

      // Toggle submit button
      this.$refs.CardStepper.toggleSubmitting();

      /**
       * Preparing payload
      */

      // Base
      let payload = {
        id: this.selectedRequest.id,
      }

      payload.body = new FormData();

      // Add 'observation' field if necessary
      if (this.form.observation) {
        payload.body.append('observation[description]', this.form.observation);
      }

      // Add 'screenshot' field if necessary
      if (!this._.isEmpty(this.form.fileName)) {
        payload.body.append('screenshot', this.form.fileName[0]);
      }

      // Add 'observation[url]' field if necessary
      if (this.form.gyazoLink) {
        payload.body.append('observation[url]', this.form.gyazoLink);
      }

      // Dispatch to store
      let result = await this.$store.dispatch('railRequests/confirmRailOperation', payload);

      // Re-enable btn
      if (this.$refs.CardStepper) {
        this.$refs.CardStepper.toggleSubmitting();
      }

      // On success
      if (result === true) {

        let payloadRefresh = {
          params: {}
        }

        // if team exists and isManagement, Add team parameter to payload.params
        if (this.teamFilter && this.userHasRailAccessDivisionDropdown) {
          payloadRefresh.params.team = this.teamFilter;
        }

        // Refresh Requests Transfers and Balances
        this.updateRequestsTransfersAndBalances(payloadRefresh);

        this.updateStatisticsByUserId(this.selectedRequest.user.id);

        // Show success snackbar
        this.$store.dispatch('UI/showSnackbar', {
          message: 'Pedido de redução de banca confirmado com sucesso.',
          color: 'success'
        });
        // change hasFinished state in order to change component
        this.$store.dispatch('TabSlider/setHasFinished', true);
      }
    },

    /**
    * Populates observation.url and observation.name
    * @param {Object} observation 
    * @returns {Boolean} open dialog or opens new page;
    */
    openDialogViewAttachment(observation) {
      if (observation && observation.url) {

        // validate observation type
        let isTypeLink = observation.type == 1;

        // populate fields
        this.observation.url = observation.url;
        this.observation.type = observation.type;

        return isTypeLink
          ? window.open(observation.url, '_blank')
          : this.dialogues.viewAttachment = true;
      }
    },

    resetComponentState() {
      // force CardStepper to display 1 step
      this.$store.commit('UI/setCardStepperStep', 1);
      // reset data component state
      Object.assign(this.$data, this.initialState());
    },

    openDialogUserNotes() {
      this.dialogues.userNotes = true;
    },

    fetchUserNotes(user) {
      if (user) {
        let payload = {
          id: user.id,
          populateUserNotes: true,
        }
        // get user notes
        this.$store.dispatch('users/getUserNotes', payload);
      }
    },
  }
}