import { mapGetters } from 'vuex';
import EmptyStateNoData from '@/components/EmptyStateNoData';
import RailMixin from '@/mixins/Rail.vue';
import DataMixin from '@/mixins/Data.vue';
import AuthMixin from '@/mixins/Auth.vue';
import StoreMixin from '@/mixins/Store.vue';
import NicknameCard from '@/components/NicknameCard/NicknameCard.vue';

export default {
    name: 'TableNicknamesHistoricBalances',

    components: {
        EmptyStateNoData,
        NicknameCard,
    },

    mixins: [
        RailMixin,
        DataMixin,
        AuthMixin,
        StoreMixin,
    ],

    computed: {
        ...mapGetters({
            selectedRequest: 'railRequests/selectedRequest',
            selectedTransfer: 'railTransfers/selectedTransfer',
            hasFinished: 'TabSlider/hasFinished',
            reports: 'railReports/reports',
            userTrackerId: 'auth/userTrackerId',
            themeDarkMode: 'UI/themeDarkMode',
        }),

        nicknamesHistoricBalanceComputed: function () {
            if (this._.isNull(this.selectedRequest.nicknamesHistoricBalance)) {
                this.$store.dispatch('railRequests/getRequestById', this.selectedRequest.id);
            }

            return this.selectedRequest.nicknamesHistoricBalance;
        },

    },

    data() {
        return this.initialState();
    },

    methods: {
        initialState() {
            return {
                table: {
                    headers: [
                        { text: 'Nickname', value: 'nickname', sortable: false },
                        { text: 'Saldo Anterior', value: 'amountBefore', sortable: false },
                        { text: 'Saldo  Atual', value: 'amountAfter', sortable: false },
                        { text: 'Diferença', value: 'diff', sortable: false },
                        { text: 'Detalhes', value: 'detail', sortable: false },
                    ]
                },
            }
        },

        getNetworkImage(item) {
            if (item) {
                return require('@/assets/networks/' + item);
            }
        },

        /**
         * Returns an icon based on nick isManagement
         * @param {Object} item
         * @returns {String} mdi icon
         */
        isNicknameManagementIcon(item) {
            let output;
            let nickname = this.handleNicknameToDisplay(item.nickname);

            if (nickname && nickname.isManagement) {
                output = 'mdi-account-tie';
            } else {
                output = 'mdi-account-card';
            }
            return output;
        },

        /**
         * Returns nickname object based on nicknameID parameter
         * @param {Number} nicknameID 
         * @returns {Object} nickname
         */
        handleNicknameToDisplay(nicknameID) {
            let output = null;
            let sourceNickname = this.selectedRequest.transfers.find(e => e.source.id == nicknameID);
            let targetNickname = this.selectedRequest.transfers.find(e => e.target.id == nicknameID);

            if (typeof sourceNickname == 'object') {
                output = sourceNickname.source;
            } else if (typeof targetNickname == 'object'){
                output = targetNickname.target;
            }
            return output;
        },

        /**
         * Returns an message based on nick isManagement
         * @param {Object} item
         * @returns {String} mdi icon
         */
        isNicknameManagementTooltip(item) {
            let output;
            let nickname = this.handleNicknameToDisplay(item.nickname);

            if (nickname && nickname.isManagement) {
                output = 'Pertence à Gestão';
            } else {
                output = 'Pertence ao jogador';
            }
            return output;
        },

        /**
         * Get chip color based on amount diff value
         * @param {Object} item 
         * @returns {String} color
         */
        getChipColor(item) {
            let output = 'grey';
            if (item.difference.value > 0) {
                output = 'success';
            } else if (item.difference.value < 0) {
                output = 'error';
            } else {
                output = 'grey';
            }

            return output;
        }
    }
}