import AuthMixin from '@/mixins/Auth';
import Hero from '@/components/Hero/Hero.vue';
import TableUser from '@/components/Tables/TableUser/TableUser.vue';
import Snackbar from '@/components/Snackbar';

export default {
  name: 'Players',

  components: {
    TableUser,
    Snackbar,
    Hero,
  },

  mixins: [
    AuthMixin,
  ],

  data () {
    return {}
  },

  async created () {
    await this.allowOnlyRoles(['rail_admin', 'rail_manager', 'tracker_admin', 'tracker_manager', 'super_coach']);
  },

  methods: {}
}