import { mapGetters } from 'vuex';

export default {
    name: 'EventPermissionPicker',

    props: {
        input: {
            type: Array,
            required: false,
            default: () => [],
        },
    },

    components: {},

    computed: {
        ...mapGetters({
            users: 'users/usersAllActive',
            groups: 'groups/groups',
            teams: 'teams/teams',
        }),

        /**
         * Prepares the output for the parent component to access via $refs.x.output
         * @returns {Array} Output in API format
         */
        output() {
            let output = [];
            
            for (let type of this.types) {
                if ( ! _.isEmpty(this.inputs[type]) ) {
                    for (let e of this.inputs[type]) {
                        output.push({
                            type: type,
                            value: e
                        });
                    }
                }
            }

            return output;
        },
    },

    watch: {
        input () {
            this.setupPermissionsPassedByProp();
        },
    },

    created() {
        this.fetchData();
        this.setupPermissionsPassedByProp();
    },

    data() {
        return this.initialState();
    },

    methods: {
        initialState() {
            return {
                types: ['users', 'groups', 'teams'],
                typesDropdown: [
                    { value: 'users', name: 'Jogadores' }, 
                    { value: 'groups', name: 'Níveis' }, 
                    { value: 'teams', name: 'Equipas' }
                ],
                typesActive: {
                    users: false,
                    groups: false,
                    teams: false,
                },
                inputs: {
                    addRestrictionType: null,
                    users: [],
                    groups: [],
                    teams: [],
                }
            }
        },
        
        addRestrictionType(type) {
            // Clear field
            setTimeout(() => {
                this.inputs.addRestrictionType = null;
            }, 50);

            // Show the restriction type
            this.changeTypeStatus(type, true);
        },

        changeTypeStatus(type, status) {
            this.typesActive[type] = status;
        },

        /**
         * Parses the value passed to the 'input' prop and uses it to
         * populate the 'inputs' and 'typesActive' variables
         */
        setupPermissionsPassedByProp() {
            for (let type of this.types) {
                this.inputs[type] = _.map(this.input.filter(e => e.type == type), 'value');

                if ( ! _.isEmpty(this.inputs[type]) ) {
                    this.typesActive[type] = true;
                }
            }
        },

        /**
         * Calls functions to fetch data used to populate the permission
         * pickers (users, groups, teams)
         */
        fetchData() {
            if ( _.isEmpty(this.users) ) {
                this.fetchUsers();
            }
    
            if ( _.isEmpty(this.groups) ) {
                this.fetchGroups();
            }
    
            if ( _.isEmpty(this.teams) ) {
                this.fetchTeams();
            }
        },

        async fetchUsers() {
            this.loading = true;
            let payload = {
                params: {
                  page: 1,
                  itemsPerPage: -1,
                }}

            await this.$store.dispatch('users/getUsersAllActive', payload);
            this.loading = false;
        },

        async fetchGroups() {
            this.loading = true
            await this.$store.dispatch('groups/get');
            this.loading = false
        },

        async fetchTeams() {
            this.loading = true;
            await this.$store.dispatch('teams/get');
            this.loading = false;
        },

        reset() {
            Object.assign(this.$data, this.initialState());
            //this.$store.dispatch('users/clearErrors');
        },
    },
}