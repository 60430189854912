import Vue from 'vue';
import AuthMixin from '@/mixins/Auth';
import { mapGetters } from 'vuex';
import AlertError from '@/components/Alerts/AlertError/AlertError.vue';
import DialogAddEditNotes from '@/components/Dialog/DialogAddEditNotes/DialogAddEditNotes.vue';

export default {
    name: 'DialogNoteDetails',
    props: {
        isOpen: {
          type: Boolean,
          required: true,
          default: false,
        },
      },

    components: {
        AlertError,
        DialogAddEditNotes,
    },

    mixins: [
        AuthMixin,
    ],

    computed: {
        associatePlayers(){
            this.selectedNote && this.selectedNote.associatePlayers ? this.selectedNote.associatePlayers : [];
        }
    },

    data() {
        return {
            dialogues: {
                editNote: false,
            }
        };
    },

    computed: {
        ...mapGetters({
          selectedNote: 'notes/selectedNote',
          userTrackerId: 'auth/userTrackerId',
          selectedNoteLoading: 'notes/selectedNoteLoading',
        }),
    },  


    methods: {
        checkIsOpen(){
            return this.dialogues.editNote;
        },

        toggleDialog(dialog) {
            this.dialogues[dialog] = ! this.dialogues[dialog];
        },

        reloadData() {
            this.fetchData();
        },

        closeDialog(){
            this.$emit('close-dialog');
        },

        userIsNoteOwner(){
            if(this.selectedNote){
                return this.selectedNote.created_by.id === this.userTrackerId;
            }
        },
    },
}