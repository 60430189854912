var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"fill-height"},[_c('DialogViewSession',{attrs:{"is-open":_vm.dialogues.viewSession},on:{"close-dialog":function($event){return _vm.closeDialogViewSession('viewSession')}}}),_c('DialogViewSessionSpins',{attrs:{"is-open":_vm.dialogues.viewSessionSpins},on:{"close-dialog":function($event){return _vm.closeDialogViewSession('viewSessionSpins')}}}),_c('DialogAddEditSession',{attrs:{"is-open":_vm.dialogues.addEditSession,"action":_vm.dialogAction,"reload-single-user":_vm.reloadSingleUserComputed,"user-id":_vm.userId},on:{"close-dialog":function($event){return _vm.closeDialogAddEditSession('addEditSession')}}}),_c('DialogAddEditSessionSpins',{attrs:{"is-open":_vm.dialogues.addEditSessionSpins,"action":_vm.dialogAction,"reload-single-user":_vm.reloadSingleUserComputed},on:{"close-dialog":function($event){return _vm.closeDialogAddEditSession('addEditSessionSpins')}}}),_c('DialogDownloadCsv',{ref:"DialogDownloadCsv",attrs:{"type":"SESSION","is-open":_vm.dialogues.download.state,"message":_vm.dialogues.download.message},on:{"close-dialog":function($event){_vm.dialogues.download.state = false}}}),_c('v-card-title',[_vm._v(" "+_vm._s(_vm.tableTitle)+" "),_c('v-spacer'),_c('v-text-field',{staticClass:"table-search-field d-none d-md-none",attrs:{"append-icon":"mdi-magnify","label":"Pesquisar","single-line":"","hide-details":"","outlined":"","dense":""},on:{"input":function($event){return _vm.search()}},model:{value:(_vm.dataTable.search),callback:function ($$v) {_vm.$set(_vm.dataTable, "search", $$v)},expression:"dataTable.search"}}),(_vm.userIsRailManager)?_c('v-tooltip',{staticClass:"d-flex ml-2",attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"icon":"","fab":"","small":""},on:{"click":function($event){return _vm.openDialogDownloadCsv()}}},[_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-download ")])],1)]}}],null,false,1424114431)},[_c('span',[_vm._v("Exportar tabela")])]):_vm._e(),(_vm.userIsRailAdmin)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","small":"","outlined":""}},'v-btn',attrs,false),on),[_vm._v(" Adicionar sessão "),_c('v-icon',{attrs:{"size":"24","right":""}},[_vm._v(" mdi-chevron-down ")])],1)]}}],null,false,2972397999)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.openDialogAddEditSession('create', null, 'addEditSession')}}},[_c('v-list-item-title',[_vm._v("Sessão MTT")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.openDialogAddEditSession('create', null, 'addEditSession')}}},[_c('v-list-item-title',[_vm._v("Sessão Cash")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.openDialogAddEditSession('create', null, 'addEditSessionSpins')}}},[_c('v-list-item-title',[_vm._v("Sessão Spins")])],1)],1)],1):_c('v-btn',{attrs:{"text":"","small":"","outlined":"","disabled":_vm._.isEmpty(_vm.authUserTeams)},on:{"click":function($event){return _vm.handleDialogToAddEdit()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-plus ")]),_vm._v(" Adicionar sessão ")],1)],1),_c('v-text-field',{staticClass:"mx-4 mb-3 d-none d-md-none",attrs:{"append-icon":"mdi-magnify","label":"Pesquisar","single-line":"","hide-details":""},on:{"input":function($event){return _vm.search()}},model:{value:(_vm.dataTable.search),callback:function ($$v) {_vm.$set(_vm.dataTable, "search", $$v)},expression:"dataTable.search"}}),_c('v-data-table',{staticClass:"table-footer-bottom",attrs:{"headers":_vm.tableHeaders,"items":_vm.tableData,"search":_vm.dataTable.search,"server-items-length":_vm.tableDataTotal,"options":_vm.options,"footer-props":_vm.dataTable.footerProps,"loading":_vm.tableLoading,"must-sort":"","loading-text":"A carregar...","no-data-text":"Não foram encontradas sessões registadas."},on:{"update:options":function (e) { return _vm.optionsComputed = e; }},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(_vm.$moment(item.date.date).format('L')))])]}}],null,true)},[_c('div',{staticClass:"d-flex flex-column"},[_c('span',[_vm._v("Criado em: "+_vm._s(_vm.$moment(item.createdAt.date).format('LLL')))]),_c('span',[_vm._v("Editado em: "+_vm._s(item.updatedAt ? _vm.$moment(item.updatedAt.date).format('LLL') : '----'))])])])]}},{key:"item.networks",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-block text-truncate",style:(_vm.$vuetify.breakpoint.xlOnly ? '' : 'max-width: 200px;')},_vm._l((item.sessionNicknames),function(value,index){return _c('v-tooltip',{key:index,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('img',_vm._g({staticClass:"mr-2 pt-1",attrs:{"src":require('@/assets/networks/' + value.nickname.network.imageURL)}},on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(value.nickname.network.name))])])}),1),(item && item.isLive)?_c('div',{staticClass:"ml-4"},[_c('v-chip',{staticClass:"font-weight-medium",attrs:{"color":"accent","text-color":"black","label":"","small":""}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v(" mdi-cash-check ")]),_c('span',[_vm._v(" Live ")])],1)],1):_vm._e()])]}},{key:"item.user",fn:function(ref){
var item = ref.item;
return [(_vm.userIsRailManager && item.user )?_c('router-link',{attrs:{"to":'/players/' + item.user.id + '/'}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-avatar',{staticClass:"mr-2",attrs:{"size":"32px","color":"accent"}},[(item.user.imgCutout != null)?_c('v-img',{staticClass:"mt-2",attrs:{"position":"","src":_vm.imageCutout(item.user)}}):_c('span',{staticClass:"font-weight-semibold"},[_vm._v(" "+_vm._s(item.user.displayName[0])+" ")])],1),_c('span',[_vm._v(" "+_vm._s(item.user ? item.user.name : _vm.usernameFromSelectedNickname)+" ")])],1)]):_c('div',{staticClass:"d-flex align-center"},[_c('v-avatar',{staticClass:"mr-2",attrs:{"size":"32px","color":"accent"}},[(item.imgCutout != null)?_c('v-img',{staticClass:"mt-2",attrs:{"position":"","src":_vm.imageCutout(item)}}):_c('span',{staticClass:"font-weight-semibold"},[_vm._v(" "+_vm._s(item.displayName[0])+" ")])],1),_c('span',[_vm._v(" "+_vm._s(item.user ? item.user.name : _vm.usernameFromSelectedNickname)+" ")])],1)]}},{key:"item.pnlEUR",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"d-flex align-center"},[_c('v-chip',{staticClass:"d-flex align-center"},[_c('v-icon',{attrs:{"color":_vm.getPnlColor(_vm.handlePnlToDisplay(item))}},[_vm._v(" "+_vm._s(_vm.getPnlArrowIcon(_vm.handlePnlToDisplay(item)))+" ")]),_c('span',{staticClass:"ml-1 mr-2"},[_vm._v(" "+_vm._s(_vm.nicknameId ? item.sessionNicknames[0].nickname.network.currency.symbol : '€')+" ")]),_c('currency-input',{staticClass:"w-100 text-end",style:({color: _vm.textColor, width: '90px'}),attrs:{"value":_vm.handlePnlToDisplay(item),"currency":null,"allow-negative":"","disabled":""}})],1)],1)]}},{key:"item.eventsPlayed",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"d-flex align-center"},[_c('v-chip',{staticClass:"d-flex align-center w-100",attrs:{"outlined":""}},[_c('currency-input',{staticClass:"w-100 text-center",style:({color: _vm.textColor}),attrs:{"value":item.eventsPlayed,"currency":null,"allow-negative":"","disabled":"","precision":0}})],1)],1)]}},{key:"item.chipNetWon",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"d-flex align-center"},[_c('v-chip',{staticClass:"d-flex align-center w-100",attrs:{"color":item.chipNetWon >= 0 ? 'success' : 'error',"outlined":""}},[_c('currency-input',{class:(item.chipNetWon >= 0 ? 'success--text' : 'error--text') + ' w-100 text-center',attrs:{"value":item.chipNetWon,"currency":null,"allow-negative":"","disabled":"","precision":0}})],1)],1)]}},{key:"item.chipEV",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"d-flex align-center"},[_c('v-chip',{staticClass:"d-flex align-center w-100",attrs:{"color":item.chipEv >= 0 ? 'success' : 'error',"outlined":""}},[_c('currency-input',{class:(item.chipEv >= 0 ? 'success--text' : 'error--text') + ' w-100 text-center',attrs:{"value":item.chipEv,"currency":null,"allow-negative":"","disabled":"","precision":0}})],1)],1)]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.handleDialogToView(item)}}},on),[_c('v-icon',[_vm._v(" mdi-eye-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v("Ver sessão")])]),(_vm.isEnabledEditSession(item))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.handleDialogToAddEdit('update', item)}}},on),[_c('v-icon',[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar sessão")])]):_vm._e()]}},{key:"no-data",fn:function(){return [_c('v-spacer'),_c('EmptyStateNoData',{attrs:{"icon":"mdi-file-check-outline","title":"Não existem sessões","message":_vm.userIsPlayer ? 'Regista a tua primeira sessão clicando no botão abaixo' : 'Sem sessões registadas no intervalo de datas especificado',"show-action":_vm.userIsPlayer,"action-add-session":_vm.userIsPlayer,"action-text":"Adicionar Sessão","reload-single-user":_vm.reloadSingleUserComputed},on:{"openDialogAddEditSession":function($event){return _vm.handleDialogToAddEdit()}}}),_c('v-spacer')]},proxy:true},{key:"no-results",fn:function(){return [_c('EmptyStateNoData',{attrs:{"icon":"mdi-magnify","title":"Não foram encontrados resultados","message":"Ajuste os termos da sua pesquisa para encontrar mais resultados"}})]},proxy:true}])},[_c('template',{slot:"progress"},[_c('v-progress-linear',{attrs:{"color":"accent","indeterminate":""}})],1),_c('v-spacer')],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }