<template>
  <div v-if="selectedDeparture">
    <!-- Child dialogues -->
    <DialogEdit
      ref="dialogEditDepartures"
      :dialog-open="dialogues.edit"
      @close-dialog="closeChildDialog('edit')"
    />

    <DialogDelete
      :dialog-open="dialogues.delete"
      @close-dialog="closeDialog()"
      @confirm-delete="deleteCallback()"
    />

    <DialogFinalize
      :dialog-open="dialogues.finalize"
      @close-dialog="closeChildDialog('finalize')"
      @confirm-finalize="finalizeCallback()"
    />

    <!-- Content -->
    <v-dialog
      :value="dialogOpen"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      @input="closeDialog()"
      @click:outside="closeDialog()"
    >
      <v-card>
        <!-- Header -->
        <v-toolbar
          dark
        >
          <v-btn
            icon
            @click="closeDialog()"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>

          <v-toolbar-title>Visualizar saída</v-toolbar-title>
          <v-spacer />

          <v-toolbar-items>
            <div>
              <v-btn
                text
                height="100%"
                @click="deleteDeparture()"
              >
                <v-icon left>
                  mdi-delete
                </v-icon>
                Apagar
              </v-btn>

              <v-btn
                text
                height="100%"
                :disabled="selectedDeparture.status_departures == 2"
                @click="editDeparture()"
              >
                <v-icon left>
                  mdi-pencil
                </v-icon>
                Editar
              </v-btn>

              <v-btn
                text
                height="100%"
                :disabled="selectedDeparture.status_departures == 2"
                @click="finalizeDeparture()"
              >
                <v-icon left>
                  mdi-check-bold
                </v-icon>
                Finalizar
              </v-btn>
            </div>
          </v-toolbar-items>
        </v-toolbar>

        <!-- Content -->
        <v-container>
          <v-row>
            <v-col cols="12">
              <p class="headline font-weight-black">
                Processo de exclusão
              </p>
              <p class="text--secondary">
                Jogador, motivo e observações que motivam a saída.
              </p>
            </v-col>
          </v-row>

          <v-simple-table>
            <template #default>
              <thead>
                <tr>
                  <th class="text-left">
                    Campo
                  </th>
                  <th class="text-left">
                    Valor
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="text--secondary">
                    Estado do processo
                  </td>
                  <td>
                    <template v-if="selectedDeparture.status_departures == 1">
                      <v-chip
                        color="orange"
                        dark
                      >
                        A decorrer
                      </v-chip>
                    </template>
                    <template v-else-if="selectedDeparture.status_departures == 2">
                      <v-chip
                        color="green"
                        dark
                      >
                        Finalizado
                      </v-chip>
                    </template>
                    <template v-else>
                      <v-chip
                        color="gray"
                        dark
                      >
                        Desconhecido
                      </v-chip>
                    </template>
                  </td>
                </tr>
                <tr>
                  <td class="text--secondary">
                    Data de início
                  </td>
                  <td>{{ selectedDeparture.start_departures }}</td>
                </tr>
                <tr>
                  <td class="text--secondary">
                    Data de fim
                  </td>
                  <td>{{ selectedDeparture.end_departures }}</td>
                </tr>
                <tr>
                  <td class="text--secondary">
                    Jogador
                  </td>
                  <td>{{ selectedDeparture.user.name_users }}</td>
                </tr>
                <tr>
                  <td class="text--secondary">
                    Motivo
                  </td>
                  <td>{{ selectedDeparture.name_dr }}</td>
                </tr>
                <tr>
                  <td class="text--secondary">
                    Observações
                  </td>
                  <td>{{ selectedDeparture.observations_departures }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <v-row>
            <v-col cols="12">
              <p class="headline font-weight-black">
                Valores
              </p>
              <p class="text--secondary">
                Makeup e bancas nas diferentes salas.
              </p>
            </v-col>
          </v-row>

          <v-simple-table>
            <template #default>
              <thead>
                <tr>
                  <th class="text-left">
                    Campo
                  </th>
                  <th class="text-left">
                    Valor
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="text--secondary">
                    Makeup
                  </td>
                  <td>€ {{ selectedDeparture.makeup_departures }}</td>
                </tr>
                <tr>
                  <td class="text--secondary">
                    Banca
                  </td>
                  <td>€ {{ selectedDeparture.bankroll_total_departures }} (€{{ selectedDeparture.bankroll_eur_departures }} + ${{ selectedDeparture.bankroll_usd_departures }})</td>
                </tr>
                <tr>
                  <td class="text--secondary">
                    Dívida total
                  </td>
                  <td>€ {{ selectedDeparture.total_departures }} (€{{ selectedDeparture.total_eur_departures }} + ${{ selectedDeparture.total_usd_departures }})</td>
                </tr>
                                

                <tr>
                  <td class="text--secondary">
                    Pedido enviado
                  </td>
                  <td>
                    <template v-if="selectedDeparture.notification_send_departures">
                      <v-chip
                        color="green"
                        dark
                      >
                        Enviado a {{ new Date(selectedDeparture.notification_send_departures).toLocaleString() }}
                      </v-chip>
                    </template>
                    <template v-else>
                      <v-chip
                        color="red"
                        dark
                      >
                        Não enviado
                      </v-chip>
                    </template>
                  </td>
                </tr>
                <tr>
                  <td class="text--secondary">
                    Makeup devolvido
                  </td>
                  <td>
                    <template v-if="selectedDeparture.makeup_returned_departures">
                      <v-chip
                        color="green"
                        dark
                      >
                        Devolvido a {{ new Date(selectedDeparture.makeup_returned_departures).toLocaleString() }}
                      </v-chip>
                    </template>
                    <template v-else>
                      <v-chip
                        color="red"
                        dark
                      >
                        Não devolvido
                      </v-chip>
                    </template>
                  </td>
                </tr>
                <tr>
                  <td class="text--secondary">
                    Banca devolvida
                  </td>
                  <td>
                    <template v-if="selectedDeparture.bankroll_returned_departures">
                      <v-chip
                        color="green"
                        dark
                      >
                        Devolvido a {{ new Date(selectedDeparture.bankroll_returned_departures).toLocaleString() }}
                      </v-chip>
                    </template>
                    <template v-else>
                      <v-chip
                        color="red"
                        dark
                      >
                        Não devolvida
                      </v-chip>
                    </template>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <v-row>
            <v-col cols="12">
              <p class="headline font-weight-black">
                Acessos
              </p>
              <p class="text--secondary">
                Estado da remoção de acessos ao jogador.
              </p>
            </v-col>
          </v-row>

          <v-simple-table>
            <template #default>
              <thead>
                <tr>
                  <th class="text-left">
                    Campo
                  </th>
                  <th class="text-left">
                    Valor
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="text--secondary">
                    Website (Vídeos, Fórum, Tracker)
                  </td>
                  <td>
                    <template v-if="selectedDeparture.access_website_departures">
                      <v-chip
                        color="green"
                        dark
                      >
                        Removido a {{ new Date(selectedDeparture.access_website_departures).toLocaleString() }}
                      </v-chip>
                    </template>
                    <template v-else>
                      <v-chip
                        color="red"
                        dark
                      >
                        Não removido
                      </v-chip>
                    </template>
                  </td>
                </tr>

                <tr>
                  <td class="text--secondary">
                    Rail
                  </td>
                  <td>
                    <template v-if="selectedDeparture.access_rail_departures">
                      <v-chip
                        color="green"
                        dark
                      >
                        Removido a {{ new Date(selectedDeparture.access_rail_departures).toLocaleString() }}
                      </v-chip>
                    </template>
                    <template v-else>
                      <v-chip
                        color="red"
                        dark
                      >
                        Não removido
                      </v-chip>
                    </template>
                  </td>
                </tr>
                <tr>
                  <td class="text--secondary">
                    Rail Resumo
                  </td>
                  <td>
                    <template v-if="selectedDeparture.access_rail_resumo_departures">
                      <v-chip
                        color="green"
                        dark
                      >
                        Removido a {{ new Date(selectedDeparture.access_rail_resumo_departures).toLocaleString() }}
                      </v-chip>
                    </template>
                    <template v-else>
                      <v-chip
                        color="red"
                        dark
                      >
                        Não removido
                      </v-chip>
                    </template>
                  </td>
                </tr>
                <tr>
                  <td class="text--secondary">
                    SharkScope
                  </td>
                  <td>
                    <template v-if="selectedDeparture.access_sharkscope_departures">
                      <v-chip
                        color="green"
                        dark
                      >
                        Removido a {{ new Date(selectedDeparture.access_sharkscope_departures).toLocaleString() }}
                      </v-chip>
                    </template>
                    <template v-else>
                      <v-chip
                        color="red"
                        dark
                      >
                        Não removido
                      </v-chip>
                    </template>
                  </td>
                </tr>
                <tr>
                  <td class="text--secondary">
                    Discord - Permissões
                  </td>
                  <td>
                    <template v-if="selectedDeparture.access_discord_permissions_departures">
                      <v-chip
                        color="green"
                        dark
                      >
                        Removido a {{ new Date(selectedDeparture.access_discord_permissions_departures).toLocaleString() }}
                      </v-chip>
                    </template>
                    <template v-else>
                      <v-chip
                        color="red"
                        dark
                      >
                        Não removido
                      </v-chip>
                    </template>
                  </td>
                </tr>
                <tr>
                  <td class="text--secondary">
                    Discord
                  </td>
                  <td>
                    <template v-if="selectedDeparture.access_discord_departures">
                      <v-chip
                        color="green"
                        dark
                      >
                        Removido a {{ new Date(selectedDeparture.access_discord_departures).toLocaleString() }}
                      </v-chip>
                    </template>
                    <template v-else>
                      <v-chip
                        color="red"
                        dark
                      >
                        Não removido
                      </v-chip>
                    </template>
                  </td>
                </tr>
                <tr>
                  <td class="text--secondary">
                    Discord - Vídeos
                  </td>
                  <td>
                    <template v-if="selectedDeparture.access_discord_videos_departures">
                      <v-chip
                        color="green"
                        dark
                      >
                        Removido a {{ new Date(selectedDeparture.access_discord_videos_departures).toLocaleString() }}
                      </v-chip>
                    </template>
                    <template v-else>
                      <v-chip
                        color="red"
                        dark
                      >
                        Não removido
                      </v-chip>
                    </template>
                  </td>
                </tr>
                <tr>
                  <td class="text--secondary">
                    Discord - Mão da Sessão
                  </td>
                  <td>
                    <template v-if="selectedDeparture.access_discord_mds_departures">
                      <v-chip
                        color="green"
                        dark
                      >
                        Removido a {{ new Date(selectedDeparture.access_discord_mds_departures).toLocaleString() }}
                      </v-chip>
                    </template>
                    <template v-else>
                      <v-chip
                        color="red"
                        dark
                      >
                        Não removido
                      </v-chip>
                    </template>
                  </td>
                </tr>
                <tr>
                  <td class="text--secondary">
                    Folha de nicks
                  </td>
                  <td>
                    <template v-if="selectedDeparture.access_folha_nicks_departures">
                      <v-chip
                        color="green"
                        dark
                      >
                        Removido a {{ new Date(selectedDeparture.access_folha_nicks_departures).toLocaleString() }}
                      </v-chip>
                    </template>
                    <template v-else>
                      <v-chip
                        color="red"
                        dark
                      >
                        Não removido
                      </v-chip>
                    </template>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
//import moment from 'moment';
import DataMixin from '../../mixins/Data.vue';
import DialogEdit from '@/components/Departures/DialogEdit.vue';
import DialogDelete from '@/components/Departures/DialogDelete.vue';
import DialogFinalize from '@/components/Departures/DialogFinalize.vue';

export default {
    name: 'DeparturesDialogView',

    components: {
        DialogEdit,
        DialogDelete,
        DialogFinalize
    },

    mixins: [DataMixin],

    props: {
        dialogOpen: { type: Boolean, required: true, default: false }
    },

    computed: {
        ...mapGetters({
            selectedDeparture: 'departures/selectedDeparture',
        })
    },


    data() {
        return {
            dialogues: {
                edit: false,
                delete: false,
                finalize: false
            }
        }
    },

    created() {
        //this.initialize();
    },

    methods: {
        initialize() {
            return;
        },

        /* Based on view/departures - need to call populateFields */
        editDeparture() {
            this.$refs.dialogEditDepartures.populateFields();   // Populates fields based on store 'departures/selectedDeparture'
            this.dialogues.edit = true;
        },

        deleteDeparture() {
            this.dialogues.delete = true;
        },

        finalizeDeparture() {
            this.dialogues.finalize = true;
        },

        finalizeCallback() {
            this.closeChildDialog('finalize');
            
            // Store / API
            let params = this.formulateRequestParameters({
                status: 2
            });
            this.$store.dispatch('departures/updateDeparture', params);

            // Show success snackbar
            this.$store.dispatch('UI/showSnackbar', {
                message: 'Registo de saída finalizado com sucesso.',
                color: 'success'
            });
        },

        closeDialog() {
            this.closeChildDialog('edit');
            this.closeChildDialog('delete');
            this.$emit('close-dialog');
        },

        closeChildDialog(dialog) {
            this.dialogues[dialog] = false;
        },

        deleteCallback() {
            // Close all dialogues - view and delete
            this.closeDialog();

            // Show success snackbar
            this.$store.dispatch('UI/showSnackbar', {
                message: 'Registo de saída apagado com sucesso.',
                color: 'success'
            });
        }
    }
}
</script>

<style scoped>
p {
    padding: 12px 12px 0px 12px;
}

p.title,
p.headline {
    margin-bottom: 0 !important;
}

.multiline {
    white-space: normal;
}
</style>