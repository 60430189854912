var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('DialogEdit',{ref:"dialogEditPlayers",attrs:{"dialog-open":_vm.dialogues.edit},on:{"close-dialog":function($event){_vm.dialogues.edit = false},"reload":function($event){return _vm.filter()}}}),_c('DialogAddLevelChange',{attrs:{"is-open":_vm.dialogues.editUserLevel,"player-single":_vm.activeUser,"direction":_vm.direction},on:{"close-dialog":function($event){_vm.dialogues.editUserLevel = false},"reload-data":function($event){return _vm.filter()}}}),_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Pesquisar","single-line":"","hide-details":""},on:{"input":function($event){return _vm.search()}},model:{value:(_vm.dataTable.search),callback:function ($$v) {_vm.$set(_vm.dataTable, "search", $$v)},expression:"dataTable.search"}})],1),_c('v-tabs',{attrs:{"background-color":"transparent","color":"accent"},model:{value:(_vm.tabs.current),callback:function ($$v) {_vm.$set(_vm.tabs, "current", $$v)},expression:"tabs.current"}},_vm._l((_vm.tabs.headers),function(tab){return _c('v-tab',{key:tab,staticClass:"text--primary"},[_vm._v(" "+_vm._s(tab)+" ")])}),1),_c('v-tabs-items',{model:{value:(_vm.tabs.current),callback:function ($$v) {_vm.$set(_vm.tabs, "current", $$v)},expression:"tabs.current"}},[_c('v-data-table',{attrs:{"headers":_vm.dataTable.headers,"items":_vm.tableData,"server-items-length":_vm.tableTotal,"options":_vm.options,"search":_vm.dataTable.search,"loading":_vm.tableLoading,"footer-props":_vm.dataTable.footerProps,"loading-text":"A carregar..."},on:{"update:options":function (e) { return _vm.optionsComputed = e; }},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('UserCard',{attrs:{"id":item.id,"img-cutout":item.imgCutout,"display-name":item.displayName}})]}},{key:"item.group",fn:function(ref){
var item = ref.item;
return [(item.group)?[_c('v-btn-toggle',{staticClass:"d-flex",attrs:{"dense":""}},[_c('v-btn',{staticClass:"pl-1 d-flex align-center justify-start",style:({ width: 'calc(100% - 64px)' }),attrs:{"small":"","to":'/groups/' + item.group.id + '/'}},[_c('div',[_c('v-img',{staticClass:"mr-2",attrs:{"src":require('@/assets/groups/' + item.group.image),"height":"24","width":"24"}})],1),_vm._v(" "+_vm._s(item.group.name)+" ")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":"","disabled":_vm._.isNull(item.group.previous)},on:{"click":function($event){return _vm.editUserLevel(item, 'DOWN')}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-minus ")])],1)]}}],null,true)},[_c('span',[_vm._v("Descer")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":"","disabled":_vm._.isNull(item.group.next)},on:{"click":function($event){return _vm.editUserLevel(item, 'UP')}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-plus ")])],1)]}}],null,true)},[_c('span',[_vm._v("Subir")])])],1)]:[_vm._v(" Sem nível definido ")]]}},{key:"item.teams",fn:function(ref){
var item = ref.item;
return [(item.teams && item.teams.length)?[_c('v-chip-group',[_vm._l((_vm.filteredTeams(item)),function(team,index){return _c('router-link',{key:team.id,attrs:{"to":'/teams/' + team.id}},[_c('v-chip',{attrs:{"small":""}},[_vm._v(" "+_vm._s(team.name)+" ")])],1)}),(item.teams.length > 2)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-chip',_vm._g({staticStyle:{"width":"50px","display":"flex","align-items":"center","justify-content":"center"},attrs:{"small":""}},on),[_vm._v(" ... ")])]}}],null,true)},[_c('div',{staticStyle:{"max-width":"400px"}},[_c('span',[_vm._v(_vm._s(item.teams.slice(2).map(function (t) { return t.name; }).join(', ')))])])]):_vm._e()],2)]:[_c('v-chip',{attrs:{"color":"red","text-color":"white","small":""}},[_vm._v(" Sem equipa ")])]]}},{key:"item.contact",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[(item.socialNetworks && item.email)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.triggerCopyToClipboard(item.email)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-email ")])],1)]}}],null,true)},[_c('span',[_vm._v("Email")])]):_vm._e(),(item.socialNetworks && item.socialNetworks.discord)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.triggerCopyToClipboard(item.socialNetworks.discord)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-message-text ")])],1)]}}],null,true)},[_c('span',[_vm._v("Discord")])]):_vm._e(),(item.socialNetworks && item.socialNetworks.facebook)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.triggerCopyToClipboard(item.socialNetworks.facebook)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-facebook ")])],1)]}}],null,true)},[_c('span',[_vm._v("Facebook")])]):_vm._e(),(item.socialNetworks && item.socialNetworks.instagram)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.triggerCopyToClipboard(item.socialNetworks.instagram)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-instagram ")])],1)]}}],null,true)},[_c('span',[_vm._v("Instagram")])]):_vm._e(),(item.socialNetworks && item.socialNetworks.youtube)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.triggerCopyToClipboard(item.socialNetworks.youtube)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-youtube ")])],1)]}}],null,true)},[_c('span',[_vm._v("Youtube")])]):_vm._e(),(item.socialNetworks && item.socialNetworks.twitter)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.triggerCopyToClipboard(item.socialNetworks.twitter)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-twitter ")])],1)]}}],null,true)},[_c('span',[_vm._v("Twitter")])]):_vm._e(),(item.socialNetworks && item.socialNetworks.twitch)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.triggerCopyToClipboard(item.socialNetworks.twitch)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-twitch ")])],1)]}}],null,true)},[_c('span',[_vm._v("Twitch")])]):_vm._e(),(item.socialNetworks && item.socialNetworks.skype)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.triggerCopyToClipboard(item.socialNetworks.skype)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-skype ")])],1)]}}],null,true)},[_c('span',[_vm._v("Skype")])]):_vm._e()],1)]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"w-100 d-flex justify-center",class:_vm.getUserStatusColor(item.status)},[_vm._v(" "+_vm._s(_vm.getUserStatus(item.status))+" ")])]}},(_vm.userIsManager)?{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"dense":""},on:{"click":function($event){return _vm.editItem(item)}}},on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v("Editar")])])]}}:null,{key:"no-data",fn:function(){return [_c('EmptyStateNoData',{attrs:{"icon":"mdi-file-check-outline","title":"Não existem utilizadores","message":_vm.emptyStateMessage,"class-color":"green white--text","class-icon":"white"}})]},proxy:true},{key:"no-results",fn:function(){return [_c('EmptyStateNoData',{attrs:{"icon":"mdi-magnify","title":"Não foram encontrados resultados","message":"Ajuste os termos da sua pesquisa para encontrar mais resultados"}})]},proxy:true},{key:"loading",fn:function(){return [_c('v-skeleton-loader',{attrs:{"type":"table-tbody"}})]},proxy:true}],null,true)},[_c('template',{slot:"progress"},[_c('v-progress-linear',{attrs:{"color":"accent","indeterminate":""}})],1)],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }