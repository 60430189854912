<template>
  <v-app>
    <div v-if="isAppEmbedded">
      <NavBar />
    </div>
    <div v-else>
      <Sidebar />
      <AppBar />
      <DrawerTableBalance
        v-if="isDrawerTableBalanceVisible"
        :is-open="dialogues.drawer"
        @close-drawer="dialogues.drawer = false"
      />
      <v-btn
        v-if="isDrawerTableBalanceVisible"
        v-show="!dialogues.drawer"
        color="accent"
        dark
        fixed
        top
        right
        class="custom-drawer-btn rounded-br-0 rounded-bl-0"
        @click="toggleDialog('drawer')"
      >
        <v-icon class="mr-2">
          mdi-chevron-up
        </v-icon> Balanços
      </v-btn>
    </div>
    <v-main class="background">
      <router-view />
      <DialogWelcome />
    </v-main>
  </v-app>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import Sidebar from '@/components/Sidebar/Sidebar.vue';
import AppBar from '@/components/AppBar.vue';
import DialogWelcome from '@/components/Dialog/DialogWelcome/DialogWelcome.vue';
import DataMixin from '@/mixins/Data.vue';
import AuthMixin from '@/mixins/Auth.vue';
import NavBar from '@/components/NavBar.vue';
import DrawerTableBalance from '@/components/Rail/DrawerTableBalance/DrawerTableBalance.vue';

export default {
  name: 'PlayerTracker',
  
  components: {
    Sidebar,
    AppBar,
    NavBar,
    DialogWelcome,
    DrawerTableBalance,
  },

  mixins: [
    DataMixin,
    AuthMixin,
  ],

  data() {
    return this.initialState();
  },

  computed: {
    ...mapGetters({
      userAuthenticated: 'auth/userAuthenticated',
      isAppEmbedded: 'UI/isAppEmbedded',
    }),

    isDrawerTableBalanceVisible: function () {
      if (this.userAuthenticated) {
        let routeNames = ['Visão Geral', 'Operações', 'Sessões', 'Transferências','Transferência', 'Swaps','Swap', 'Swap Group', 'Reports', 'Report', 'Despesas', 'Despesa', 'Rail - Administração'];
        let isVisible = routeNames.includes(this.$route.name);
  
        return this.userIsRailManager && isVisible && !this.$vuetify.breakpoint.smAndDown;
        
      }
      return false;
    }
  },

  created() {
    // Hotjar Tracking Code for polarize.poker
    (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:2318428,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
    
    // Contrast mode (light/dark)
    this.initAppContrastMode();

    // Initial Navigation
    this.initAppEmbedded();
  },

  methods: {
    initialState() {
        return {
          dialog: true,
          dialogues: {
          drawer: false,
          }
        };
    },

    initAppContrastMode() {
      this.$store.dispatch(
        'UI/setThemeDarkMode',
        this.strToBool($cookies.get(Vue.prototype.$cookie_names.dark_mode))
      );
    },

    initAppEmbedded() {
      this.$store.dispatch('UI/setAppEmbedded');
    },

    toggleDialog(dialog) {
      this.dialogues[dialog] = !this.dialogues[dialog];
    },
  }
}
</script>


<style>
/*#app {
	font-family: 'Avenir', Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
	margin-top: 60px;
}*/

/* 
————————————————————————————————————
/ ——— All classes go to /scss/classes.scss
————————————————————————————————————
*/ 

</style>