import MenuPredefinedDates from '@/components/MenuPredefinedDates/MenuPredefinedDates.vue';

const moment = require('moment');

export default {
  name: 'DatePickerRange',

  props: {
    value: { type: Array, required: false, default: () => [] },
    label: { type: String, required: false, default: '' },
    dark: { type: Boolean, required: false, default: false },
    outlined: { type: Boolean, required: false, default: true },
    solo: { type: Boolean, required: false, default: false },
    dense: { type: Boolean, required: false, default: false },
    navigate: { type: Boolean, required: false, default: false },
    showParamsUrl: { type: Boolean, required: false, default: true },
    updateStoreUI: { type: Boolean, required: false, default: true },
  },

  data() {
    return this.initialState();
  },

  components: {
    MenuPredefinedDates
  },

  computed: {

    dateRangeText() {
      let datesFormatted = [];

      for (let key in this.value) {
        datesFormatted.push( this.$moment(this.value[key]).format('L') );
      }
      // remove Invalid date if exists
      if (datesFormatted.includes('Invalid date')) {
        let indexPosition = datesFormatted.indexOf('Invalid date');
        datesFormatted.splice(indexPosition, 1)
      }
      return datesFormatted.join(' - ');
    },

    // Synthetic v-model
    computedValue: {
      get: function () {
        return this.finalValue;
      },
      set: function (newValue) {
        if (newValue.length == 1) {
          this.finalValue = newValue;
        } else {
          this.finalValue = this.handleDateInput(newValue);
          if (this.showParamsUrl) {
            let query = {
              dateBegin: this.finalValue[0],
              dateEnd: this.finalValue[1],
            }
            if(this.$route.query.dateBegin !== query.dateBegin || this.$route.query.dateEnd !== query.dateEnd){
              this.$router.replace({ query }).catch(()=>{});
            }
          }

          this.updateStore(this.finalValue);
        }
      }
    },

    themeDarkModeColor() {
      if (!this.dark) {
        return this.themeDarkMode ? 'white-border' : 'dark-border';
      }
    }
  },

  watch: {
    // Emit change to populate the parent v-model
    computedValue: function () {
      // Emit change only if start and end date are picked
      if (this.computedValue.length == 2) {
        this.emitChange();

        const query = {
          dateBegin: this.computedValue[0],
          dateEnd: this.computedValue[1],
        };

        if(!this.$route.query && this.showParamsUrl){
          this.$router.replace({ query }).catch(()=>{});
        }
        // Close date-picking menu
        this.menuShowing = false;
      }
    },

    menuShowing: function () {
      if (this.menuShowing == false) {
        // when user selects only 1 data and then closes the menu
        if (this.computedValue.length == 1) {
          // set default date
          this.computedValue = this.finalValue.concat(this.finalValue);
        }
      }
    },
	},

  created() {
    // Save data passed to this component via v-model
    if (this._.isEmpty(this.value)) {
      // Populate with default values
      this.populateDefault();
    }
    if(Object.keys(this.$route.query).length === 0 && this.navigate == true && this.showParamsUrl){
      const dateBegin = moment().startOf('month').format('YYYY-MM-DD');
      const dateEnd = moment().endOf('month').format('YYYY-MM-DD');
      const query = {
        dateBegin,
        dateEnd,
    };
    this.$router.replace({ query }).catch(()=>{});
    } else {
      this.computedValue =  this.populateDefault();
    }
  
    const query = this.$route.query;
    if (query.dateBegin && query.dateEnd && this.navigate == true && this.showParamsUrl) {
      let dateBegin = moment(query.dateBegin);
      let dateEnd = moment(query.dateEnd);

      if (!dateBegin.isValid() && !dateEnd.isValid()) {
        const queryParameters = {
          dateBegin: dateBegin.format('YYYY-MM-DD'),
          dateEnd: dateEnd.format('YYYY-MM-DD'),
        };
  
        const currentQuery = this.$route.query;
        if (
          currentQuery.dateBegin !== queryParameters.dateBegin ||
          currentQuery.dateEnd !== queryParameters.dateEnd
        ) {
          this.$router.replace({ query: queryParameters });
        }
      }
      this.computedValue = [dateBegin.format('YYYY-MM-DD'), dateEnd.format('YYYY-MM-DD')];
    }
  },

  methods: {
    initialState() {
      return {
        menuShowing: false,
        date: this.$moment().format('YYYY-MM-DD'),
        finalValue: [],
      }
    },

    setPredefinedDate(dates) {
      this.computedValue = dates;
    },

    changeYear(action = 'add') {
      let currentYear = moment(this.computedValue, 'YYYY-MM-DD').year();
      let newYear = action === 'add' ? currentYear + 1 : currentYear - 1;
      this.computedValue = moment(this.computedValue, 'YYYY-MM-DD').year(newYear).format('YYYY-MM-DD');
    },
    
    previousEvent() {
      let currentDate = moment(this.computedValue[0], 'YYYY-MM-DD');
    
      let previousMonth = currentDate.clone().subtract(1, 'month').startOf('month');
      let endOfPreviousMonth = previousMonth.clone().endOf('month');
    
      this.computedValue = [previousMonth.format('YYYY-MM-DD'), endOfPreviousMonth.format('YYYY-MM-DD')];
    
      const query = {
        dateBegin: previousMonth.format('YYYY-MM-DD'),
        dateEnd: endOfPreviousMonth.format('YYYY-MM-DD'),
      };
      if (this.showParamsUrl) {
        this.$router.replace({ query }).catch(()=>{});
      }
    },
    
    nextEvent() {
      let currentDate = moment(this.computedValue[1], 'YYYY-MM-DD');
      let nextMonth = currentDate.clone().add(1, 'month').endOf('month');
      let previousMonth = currentDate.clone().add(1, 'month').startOf('month');
      
      if (nextMonth.month() === 0) {
        this.changeYear('add');
        this.computedValue = [
          previousMonth.clone().subtract(1, 'year').endOf('year').format('YYYY-MM-DD'),
          nextMonth.format('YYYY-MM-DD')
        ]
      }
      this.computedValue = [
        previousMonth, nextMonth.format('YYYY-MM-DD')
      ]

      const query = {
        dateBegin: previousMonth.format('YYYY-MM-DD'),
        dateEnd: nextMonth.format('YYYY-MM-DD'),
      };
      if (this.showParamsUrl) {
        this.$router.replace({ query }).catch(()=>{});
      }
    },
    
    populateDefault() {
      if(this.$route.query && this.$route.query.dateBegin && this.$route.query.dateEnd){
        this.computedValue = [this.$route.query.dateBegin, this.$route.query.dateEnd];
      }else{
        this.computedValue = [
          this.$moment().subtract(1, 'month').format('YYYY-MM-DD'),
          this.$moment().format('YYYY-MM-DD'),
        ];
      }
      return this.computedValue
    },

    handleDateInput(dates) {
      let output = [];
      if (dates.length == 2) {

        let dateA = this.$moment(dates[0])
        let dateB = this.$moment(dates[1])

        dateA.isBefore(dateB)
          ? output.push(dateA.format('YYYY-MM-DD'), dateB.format('YYYY-MM-DD'))
          : output.push(dateB.format('YYYY-MM-DD'), dateA.format('YYYY-MM-DD'))
      }
      return output;
    },

    emitChange() {
      this.$emit('input', this.computedValue);
      this.$emit('change', this.computedValue);
    },

    updateStore(value) {
      if (this.updateStoreUI) {
        this.$store.commit('UI/setHeroDatePickerValue', value);
      }
    }
  }
}