import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth';
import DialogEditUserTeams from '@/components/Rail/Dialog/DialogEditUserTeams/DialogEditUserTeams.vue';
import Alert from '@/components/Alerts/Alert/Alert.vue';

export default {
  name: 'UserTeams',

  props: {
    title: { type: String, required: false, default: 'Equipas' },
  },

  mixins: [
    AuthMixin,
  ],

  components: {
    DialogEditUserTeams,
    Alert,
  },

  data() {
    return this.initialState();
  },

  computed: {
    ...mapGetters({
      userTeamsLoading: 'users/userTeamsLoading',
      userTeams: 'users/userTeams',
    }),
  },

  methods: {
    initialState() {
      return {
        dialogues: {
          editUserTeams: false
        }
      }
    },

    editUserTeam() {
      this.dialogues.editUserTeams = true;
    }
  }
}
