var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{staticClass:"pa-0"},[_c('v-row',[_c('v-col',[_c('v-card',[_c('v-card-title',[_vm._v(" Modelos "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-icon',{staticClass:"pl-2 pb-1"},[_vm._v(" mdi-information-outline ")])],1)]}}])},[_c('span',[_vm._v(" Modelos que avaliam torneios ")])]),_c('v-spacer'),_c('v-text-field',{staticClass:"table-search-field d-none d-md-flex pr-5",attrs:{"append-icon":"mdi-magnify","label":"Pesquisar","single-line":"","hide-details":"","outlined":"","dense":""}}),_c('v-btn',{staticClass:"ml-3",attrs:{"color":"primary secondary--text","elevation":"1"},on:{"click":function($event){return _vm.openCreateDialog()}}},[_c('v-icon',[_vm._v("mdi-plus")]),_c('span',{staticClass:"mx-2"},[_vm._v(" Adicionar Modelo ")])],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.models,"items-per-page":10,"footer-props":_vm.footerProps,"loading":_vm.loading,"loading-text":"A carregar..."},scopedSlots:_vm._u([{key:"item.active",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{attrs:{"inset":"","disabled":""},model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}})]}},{key:"item.ready",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.ready ? 'success' : 'error'}},[_vm._v(" "+_vm._s(_vm.getBooleanIcon(item.ready))+" ")])]}},{key:"item.averageScore",fn:function(ref){
var item = ref.item;
return [(item.averageScore)?_c('v-chip',{attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(item.averageScore)+" ")]):_c('span',[_vm._v("-")])]}},{key:"item.distributionChart",fn:function(ref){
var item = ref.item;
return [_c('ColumnChart',{staticStyle:{"max-width":"350px"},attrs:{"data":Object.values(item.distributionChart),"labels":Object.keys(item.distributionChart),"options":_vm.chartOptions,"loading":_vm.loading,"height":'70',"showCard":false}})]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":'/scheduling/models/' + item.id + '/'}},[_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v(" mdi-eye ")])],1)],1)]}}],null,true)},[_c('span',[_vm._v("Ver")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.openEditModel(item.id)}}},on),[_c('v-icon',[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])])]}}])})],1)],1)],1)],1),_c('DialogEditModels',{attrs:{"open":_vm.dialogs.createEditModel,"action":_vm.dialogAction,"editModelId":_vm.editModelId},on:{"close":function($event){return _vm.toggleDialog('createEditModel')},"addModel":_vm.addModel,"editModel":_vm.editModel}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }