import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth.vue';
import RailMixin from '@/mixins/Rail.vue'
import EmptyStateNoData from '@/components/EmptyStateNoData';

export default {
  name: 'TableVideoAccess',
  mixins: [AuthMixin, RailMixin],

  components: {
    EmptyStateNoData,
  },

  props: {
    title: {
      type: String,
      required: false,
      default: 'Acesso aos vídeos',
    }
  },

  data() {
    return this.initializeState()
  },

  computed: {
    ...mapGetters({
    }),
  },
  
  methods: {
    
    initializeState() {
      return {
        tabs: {
          current: 0,
        },
        
        headers: [
          { text: 'Data', value: 'date' },
          { text: 'Video', value: 'video' },
        ],
        items: [
          {
            date: '11/05/2021',
            video: '351 - RuiNF - Analise de maos marcadas 11/09/2019',
          },
          {
            date: '21/04/2021',
            video: '562 - Iniciação ao ICM - Parte 2',
          },
          {
            date: '08/03/2021',
            video: '561 - Iniciação ao ICM - Parte 1',
          },
          {
            date: '24/01/2021',
            video: '538 - Aula- ICM Multimesas - Parte 1',
          },
        ],
        search: null,
        itemsPerPage: 10,
      };
    },
    
    getTableHeaders() {
      return this.headers;
    },
    
    setActiveItem() {
      console.warn('Todo: setActiveItem()');
    },
  },
};
