export default {
    data() {
      return {
        tabs: [
          { index: 0, label: "Subida Rápida", icon: "mdi-transfer-up" },
          { index: 1, label: "Subida Normal", icon: "mdi-upload" },
          { index: 2, label: "Descida", icon: "mdi-download" },
          { index: 3, label: "Recuperação", icon: "mdi-redo-variant" },
        ],
        totalSplitsAmountErrors: [],
        minAverageSessionsErrors: [],
        calcDaysAverageSessionsErrors: [],
        totalSplitsAmountFastErrors: [],
        totalSplitsErrors: [],
        calcDaysforAmountAndSplitErrors: [],
        numAverageBuyInsErrors: [],
        daysToCalcBuyInErrors: [],
        numDaysOffErrors: [],
        numOfDescentsConsecutiveAllowedErrors: [],
        numSessionsToUpAfterDownOffErrors: [],

        rules: {
          required: [(value) => !!value || "Campo obrigatório."],
        },

        currentTab: 0,
      };
    },

    props: {
      formValues: { type: Object, required: true, default: {} },
      action: {type: String, required: false, default: ''},
    },

    watch: {
      formValues: {
        handler(newValue) {
          if (Object.keys(newValue).length === 0) {
            this.clearErrors();
          }
        },
        deep: true,
        immediate: true
      }
    },

    computed: {
      hasErrors() { 
          return this.totalSplitsAmountErrors.length > 0 || 
          this.minAverageSessionsErrors.length > 0 || 
          this.calcDaysAverageSessionsErrors.length > 0 ||
          this.totalSplitsAmountFastErrors.length > 0 ||
          this.totalSplitsErrors.length > 0 ||
          this.calcDaysforAmountAndSplitErrors.length > 0 ||
          this.numAverageBuyInsErrors.length > 0 ||
          this.daysToCalcBuyInErrors.length > 0 ||
          this.numDaysOffErrors.length > 0 ||
          this.numOfDescentsConsecutiveAllowedErrors.length > 0 ||
          this.numSessionsToUpAfterDownOffErrors.length > 0
      }
    },

   

    methods: {
      getButtonColor(index) {
        return this.currentTab === index ? "#FFC400" : "#D3D3D3";
      },

      clearErrors(){
        this.totalSplitsAmountErrors = [];
        this.minAverageSessionsErrors = [];
        this.calcDaysAverageSessionsErrors = [];
        this.totalSplitsAmountFastErrors = [];
        this.totalSplitsErrors = [];
        this.calcDaysforAmountAndSplitErrors = [];
        this.numAverageBuyInsErrors = [];
        this.daysToCalcBuyInErrors = [];
        this.numDaysOffErrors = [];
        this.numOfDescentsConsecutiveAllowedErrors = [];
        this.numSessionsToUpAfterDownOffErrors = [];
      },

      getCurrentTab(){
        return this.tabs.find(tab => tab.index === this.currentTab).label;
      },

      validateField(value, errorVar) {
        this[errorVar] = [];
        if (!value) {
          this[errorVar].push('Campo obrigatório.');
        }
      },

      resetValidation() {
          this.rules.required = [(value) => !!value || "Campo obrigatório."];
      },
  
      changeTab(index) {
        this.resetValidation();
        this.currentTab = index;
      },
    },
  };