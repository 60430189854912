import Vue from 'vue';
import { mapGetters } from 'vuex';
import DataMixin from '@/mixins/Data';

export default {
    name: 'TransferTypeDropdown',

    mixins: [
        DataMixin,
    ],

    props: {
        value: {
            type: [Object,Array],
            required: false,
            default: () => []
        },
        rules: {
            type: Array,
            required: false,
            default: () => [
                (v) => !!v || ''
            ]
        },
    },

    data() {
        return this.initialState();
    },

    created() {
        this.initialize();
        this.emitChange();
    },

    mounted() {
        // Clear error triggered naturally when element is loaded
        this.$refs.formTransferTypeDropdown.resetValidation();
    },

    computed: {
        ...mapGetters({}),
    
        // Synthetic v-model
        computedValue: {
            get: function () {
                return this.transferType;
            },
            set: function (newValue) {
                this.transferType = newValue;
                this.emitChange();
            }
        },

        computedRules: function () {
            return this.rules;
        },
    },

    watch: {
        value: function(newValue) {
            this.computedValue = newValue;
        },
    },

    methods: {
        initialState() {
            return {
                transferType: null,
                data: [
                    { id: Vue.prototype.$transfer_types.PNPW, source: 'Sala', target: 'Carteira' },
                    { id: Vue.prototype.$transfer_types.PWPN, source: 'Carteira', target: 'Sala' },
                    { id: Vue.prototype.$transfer_types.P_M, source: 'Jogador', target: 'Gestão' },
                    { id: Vue.prototype.$transfer_types.P_O, source: 'Jogador', target: 'Fora' },
                    { id: Vue.prototype.$transfer_types.P_P, source: 'Jogador', target: 'Jogador' },
                    { id: Vue.prototype.$transfer_types.M_P, source: 'Gestão', target: 'Jogador '},
                    { id: Vue.prototype.$transfer_types.M_O, source: 'Gestão', target: 'Fora' },
                    { id: Vue.prototype.$transfer_types.O_M, source: 'Fora', target: 'Gestão' },
                    { id: Vue.prototype.$transfer_types.M_M, source: 'Gestão', target: 'Gestão' },
                    { id: Vue.prototype.$transfer_types.UNKNOWN, source: 'Desconhecido', target: ''},
                ],
            }
        },

        async initialize() {
            // Initial value passed through prop
            this.computedValue = this.value;
        },

        reset() {
            Object.assign(this.$data, this.initialState());
            this.$refs.formTransferTypeDropdown.reset();
        },

        emitChange() {
            this.$emit('input', this.computedValue);
            this.$emit('change', this.computedValue);
        },
    }

}