import Vue from 'vue';
import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth.vue';
import RailMixin from '@/mixins/Rail.vue';
import DataMixin from '@/mixins/Data.vue';
import EmptyStateNoData from '@/components/EmptyStateNoData';
import DialogDownloadCsv from '@/components/Rail/Dialog/DialogDownloadCsv/DialogDownloadCsv.vue';
import DialogAddEditSwapGroup from '@/components/Rail/Dialog/DialogAddEditSwapGroup/DialogAddEditSwapGroup.vue';
import SwapGroupsDropdown from '@/components/Rail/SwapGroupsDropdown/SwapGroupsDropdown.vue';
import DialogConfirmation from '@/components/Rail/Dialog/DialogConfirmation/DialogConfirmation.vue';

export default {
  name: 'TableSwapGroup',

  components: {
    EmptyStateNoData,
    DialogDownloadCsv,
    DialogAddEditSwapGroup,
    SwapGroupsDropdown,
    DialogConfirmation,
  },

  mixins: [
    AuthMixin,
    RailMixin,
    DataMixin,
  ],

  data() {
    return this.initialState();
  },

  created() {
    this.fetchData();
  },

  computed: {
    ...mapGetters({
      selectedSwapGroup: 'railSwaps/selectedSwapGroup',
      swapGroups: 'railSwaps/swapGroups',
      swapGroupsTotal: 'railSwaps/swapGroupsTotal',
      swapGroupsLoading: 'railSwaps/swapGroupsLoading',
      users: 'users/usersAllActive',
      userTeams: 'auth/userTeams',
    }),

    optionsComputed: {
      get: function () {
        return this.options;
      },
      set: function (newValue) {
        if (!this._.isEqual(this.options, newValue)) {
          this.options = newValue;
        }
      }
    },

    dataTableHeadersComputed() {
      let output = [
        { text: '', value: 'selected', sortable: false },
        { text: 'Data', value: 'date', sortable: false },
        { text: 'Nome', value: 'name', sortable: true },
      ]

      if (this.userIsRailManager) {
        output.push(
          { text: 'Jogadores', value: 'players', sortable: false }
        );
      }

      output.push(
        { text: 'Ativo', value: 'active', sortable: true },
        { text: 'Ação', value: 'action', sortable: false }
      );

      return output;
    },

  },

  watch: {
    swapGroup: function () {
      this.fetchData();
    },
    // watches this.options object
    // triggers everytime this.options has changed
    options: {
      handler() {
        this.fetchData();
      },
      deep: true,
    },
  },

  methods: {
    initialState() {
      return {
        tableTitle: 'Swap Groups',
        swapGroup: null,
        dataTable: {
          search: null,
          footerProps: {
            'items-per-page-options': this.$itemsPerPageOptions,
          },
        },
        options: {
          page: 1,
          itemsPerPage: 10,
          sortBy: [],
          sortDesc: [],
          groupBy: [],
          groupDesc: [],
          mustSort: true,
          multiSort: true
        },
        dialogAction: null,
        dialogs: {
          swapGroup: false,
          showAlertUserAccess: false,
        }
      };
    },

    /**
     * Opens/Closes dialog
     * @param {String} Dialog's name 
     */
    toggleDialog(name, action = null) {
      this.dialogAction = action;
      this.dialogs[name] = !this.dialogs[name];
    },

    setActiveItem(item) {
      if (item) {
        this.$store.commit(
          'railSwaps/setSelectedSwapGroup',
          item
        );
      }
    },

    // trigered by v-text-field @input="search()"
    search() {
      setTimeout(() => {
        this.fetchData();
      }, 1000);
    },

    async fetchData() {
      let payload = {
        params: {
          page: this.options.page,
          itemsPerPage: this.options.itemsPerPage,
        }
      }

      // Add sortBy parameter to payload
      if (!this._.isEmpty(this.options.sortBy)) {
        this.options.sortBy.forEach((element, index) => {
          payload.params['sortBy[' + index + ']'] = element;
        })
      }

      // Add sortDesc parameter to payload.params
      if (!this._.isEmpty(this.options.sortDesc)) {
        this.options.sortDesc.forEach((element, index) => {
          payload.params['sort[' + index + ']'] = element == false ? 'ASC' : 'DESC';;
        })
      }
      // Add swapGroup parameter to payload.params
      if (this.swapGroup && this.swapGroup.id) {
        payload.params.swapGroup = this.swapGroup.id;
      }

      this.$store.dispatch('railSwaps/getSwapGroups', payload);
    },

    /**
     * Redirect to /swaps/pool/:id page view
     * @param {Object} item 
     */
    viewSwapGroup(item) {
      // routing
      return Vue.prototype.$tab_slider.tabs.swaps + 'groups/' + item.id;
    },

    handleUsername(id) {
      let output = null;
      if (!this._.isEmpty(this.users)) {
        output = this.users.find(element => element.id == id);

        if (!this._.isUndefined(output)) {
          output = output.name;
        }
      }
      return output;
    },

    getTeamDivision() {
      let output = '';
      output = this.userTeams.find(team => team.type == Vue.prototype.$teams_types.helper);
      if (!this._.isUndefined(output)) {
        output = output.name;
      }
      return output;
    },

    /**
     * Used to enable/disable action table buttons
     * @param {Array} users 
     * @returns Boolean value
     */
    handleAccess(users) {
      let usersMapped = [];
      if (!this._.isEmpty(users)) {
        users.forEach(id => {
          usersMapped.push(this.handleUsername(id));
        })
      }
      // if true it means rail manager doesn't have access 
      return usersMapped.includes(undefined) && this.userHasRole('rail_manager');
    }
  }
};
