import Vue from 'vue';
import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth.vue';
import UserMixin from '@/mixins/User.vue';
import RailMixin from '@/mixins/Rail.vue';
import TableGamesPlayed from '@/components/Tables/TableGamesPlayed/TableGamesPlayed.vue';
import UserCard from '@/components/UserCard/UserCard.vue';
import SessionLiveMetadataView from '@/components/SessionLiveMetadataView/SessionLiveMetadataView.vue';

export default {
	name: 'DialogViewSession',

	props: {
		isOpen: { type: Boolean, required: true, default: false },
    },

	mixins: [
		AuthMixin,
		UserMixin,
		RailMixin,
	],

	components: {
		TableGamesPlayed,
		UserCard,
		SessionLiveMetadataView,
	},

	data() {
		return this.initialState();
	},

    computed: {
		...mapGetters({
			selectedSession: 'railSessions/selectedSession',
			selectedNickname: 'nicknames/selectedNickname',
			userTeams: 'users/userTeams',
			userTeamsLoading: 'users/userTeamsLoading',
		}),

		registeredInPriorDate() {
			if (this.selectedSession) {
				let dateCreated = this.$moment(this.selectedSession.createdAt.date).format('YYYY-MM-DD');
				let dateSession = this.$moment(this.selectedSession.date.date).format('YYYY-MM-DD');
				
				return this.$moment(dateCreated).diff(this.$moment(dateSession), 'Days') >= 1;
			}

			return false;
		},

		pnlIsNull() {
			return this.selectedSession.pnl.EUR.value === 0;
		},

		usernameFromSelectedNickname: function () {
			if (this.selectedNickname) {
			  return this.selectedNickname.user.name;
			}
		},

		userId: function () {
			let data = this.selectedSession
					? this.selectedSession
					: this.selectedNickname;

			if (data && data.user && Object.prototype.hasOwnProperty.call(data, 'user') && Object.prototype.hasOwnProperty.call(data.user, 'id')) {
				return data.user.id;
			}
			return null;
		},

		/**
		 * Same as the computed property in DialogAddEditSession but adapted to
		 * use another property
		 * 
		 * @returns {String} mtt|cash|spins|null Division code or NULL if unknown
		 */
		userDivision: function () {
			let userTeamIds = [];

			if (this.userTeams) {
				userTeamIds = _.map(this.userTeams, 'id');
			}
			if (userTeamIds.includes(Vue.prototype.$team_ids.division_mtt)) {
				return 'mtt';
			} else if (userTeamIds.includes(Vue.prototype.$team_ids.division_cash)) {
				return 'cash';
			} else if (userTeamIds.includes(Vue.prototype.$team_ids.division_spins)) {
				return 'spins';
			} else {
				return null;
			}
		},

		/**
		 * Duplicate of DialogAddEditSession::eventsPlayedLabel computed property
		 * @returns {String} label
		 */
		eventsPlayedLabel: function () {
			let output = 'Nº de eventos';

			if (['mtt', 'spins'].includes(this.userDivision)) {
				output = 'Nº de torneios:';
			} else if (['cash'].includes(this.userDivision)) {
				output = 'Nº de mãos:';
			}

			return output;
		},

	},

	watch: {
		userId: function () {
			// prevent mulltiple API Requests
			if (!this.loadingFetchUserTeams && this.isOpen) {
				this.fetchUserTeams();
			}
		},

		isOpen: function (newVal, oldVal) {
			if(newVal){
				this.loadHeaders();
				this.tabs.current = 0;
			}
		},

		selectedSession: function (newVal, oldVal) {
			if (newVal) {
				this.loadFilters();
				this.fetchUserTeams();
			}
		}
	},

	created(){
		this.loadFilters();
	},

	methods: {
		initialState() {
			return {
				teamCashDivisionID: 11,
				loadingFetchUserTeams: false,
				tabs: {
                    current: 0,
                    headers: [],
                },
				headers: [
					{ text: 'Sala', value: 'nickname.network.name', sortable: false, },
					{ text: 'Balanço', value: 'balance.value', sortable: false, },
					{ text: 'Challenge', value: 'rakebackBalance', sortable: false, },
					{ text: 'Tickets', value: 'rakebackTickets', sortable: false, },
					{ text: 'Stars Coins', value: 'rakebackCoins', sortable: false, },
					{ text: 'Resultado', value: 'pnl.value', sortable: false }
				],
				filters: {},
				emptyStateStyleHeight: 'height: 30vh;',
			}
		},

		loadHeaders(){
			this.tabs.headers = [];
			this.tabs.headers = ['Sessão'];

			if(this.selectedSession && this.selectedSession.isLive) {
				this.tabs.headers = [...this.tabs.headers, 'Live'];
			} else if(this.userDivision === 'mtt'){
				this.tabs.headers = [...this.tabs.headers, 'Jogos' ];
			}
		},

		getTextForAlert(){
			if(this.filters && this.filters.date.dateTimeFinishBegin && this.filters.date.dateTimeFinishEnd){
				return `São mostrados jogos no intervalo entre ${this.$moment.unix(this.filters.date.dateTimeFinishBegin).format('DD/MM/YYYY HH:mm')} e 
				${this.$moment.unix(this.filters.date.dateTimeFinishEnd).format('DD/MM/YYYY HH:mm')}`
			}
		},

		async fetchUserTeams() {
			if (this.userTeamsLoading) {
				return false;
			}
			this.loadingFetchUserTeams = true;
			if (this.userId && this.userIsRailManager) {
				await this.$store.dispatch('users/getUserTeams', this.userId);
			}
			this.loadingFetchUserTeams = false;
		},

		loadFilters(){
			if(this.selectedSession){
				let sessionDate = this.$moment(this.selectedSession.date.date, 'YYYY-MM-DD HH:mm:ss.SSSSSS');
				let dateTimeFinishBegin = sessionDate.clone().hour(8).minute(0).second(0);
				let dateTimeFinishEnd = dateTimeFinishBegin.clone().add(1, 'days');

				dateTimeFinishBegin = dateTimeFinishBegin.unix();
				dateTimeFinishEnd = dateTimeFinishEnd.unix();

				this.filters = {
					users: {
						id: this.userId,
					},
					date: {
						dateTimeFinishBegin,
						dateTimeFinishEnd,
					}
				}
			}
		},

		getStyleTabs() {
			const styleTab = 'overflow: auto;';
			const defaultHeight = ' height: 51vh;';
			const adjustment = this.registeredInPriorDate ? -6 : 0;
		
			if (!this.selectedSession || !this.selectedSession.sessionNicknames) {
				return styleTab + defaultHeight;
			}
		
			let height = 47 + (this.selectedSession.sessionNicknames.length * 5) + adjustment;
			let heightEmptyState = 30 + Number((this.selectedSession.sessionNicknames.length * 6)) + adjustment;
		
			if(height > 65 && heightEmptyState > 55){
				height = 60;
				heightEmptyState = 45;
			}
		
			this.emptyStateStyleHeight = `min-height: ${heightEmptyState}vh;`;
		
			return styleTab + `height: ${height}vh;`;
		},		

		closeDialog() {
			this.$store.commit('railSessions/setSelected', null);
			this.$emit('close-dialog');
		},

		triggerCopyToClipboard() {
            let url = window.location.origin + Vue.prototype.$tab_slider.tabs.sessions + this.selectedSession.id;
            this.copyToClipboard(url);

            // Show snackbar
            this.$store.dispatch('UI/showSnackbar', {
              message: "🔗 Link da sessão copiado com sucesso.",
              color: 'success'
			});
        },
	},
};
