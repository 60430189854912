import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth.vue';
import RailMixin from '@/mixins/Rail.vue';
import DataMixin from '@/mixins/Data.vue';
import EmptyStateNoData from '@/components/EmptyStateNoData';
import DialogEditGroup from '@/components/Dialog/DialogEditGroup/DialogEditGroup.vue';
import DialogRemoveGroup from '@/components/Dialog/DialogRemoveGroup/DialogRemoveGroup.vue';
import DialogAddPlayerToGroup from '@/components/Dialog/DialogAddPlayerToGroup/DialogAddPlayerToGroup.vue';

export default {
  name: 'TableGroups',

  mixins: [
    AuthMixin,
    RailMixin,
    DataMixin,
  ],

  components: {
    EmptyStateNoData,
    DialogEditGroup,
    DialogRemoveGroup,
    DialogAddPlayerToGroup,
  },

  data() {
    return this.initializeState();
  },

  computed: {
    ...mapGetters({
      groups: 'groups/groups',
      groupsTotal: 'groups/groupsTotal',
      groupMembersTotal: 'users/usersFromGroupTotal',
      loading: 'groups/loading',
      error: 'groups/error',
      humanError: 'groups/humanError',
      teamFilter: 'teams/teamFilter',
    }),

    optionsComputed: {
      get: function () {
        return this.options;
      },
      set: function (newValue) {
          if (!this._.isEqual(this.options, newValue)) {
              this.options = newValue;
          }
      }
    }
  },

  watch: {
    // watches this.options object
    // triggers everytime this.options has changed
    options: {
      handler() {
        this.fetchData();
      },
      deep: true,
      immediate: true,
    },
    teamFilter: function () {
      this.fetchData();
    },
  },

  methods: {
    initializeState() {
      return {
        dataTable: {
          headers: [
            { text: 'Nível', value: 'name' },
            { text: 'Nº de jogadores', value: 'countPlayers' },
            { text: 'Ação', value: 'action', sortable: false, width: "150px" },
          ],
          footerProps: {
            'items-per-page-options': this.$itemsPerPageOptions,
          },
          search: null,
        },
        options: {
          page: 1,
          itemsPerPage: 10,
          sortBy: [],
          sortDesc: [],
          groupBy: [],
          groupDesc: [],
          mustSort: true,
          multiSort: false
        },
        dialogues: {
          addPlayer: false,
          removeGroup: false,
          editGroup: false,
        },
      };
    },

    // trigered by v-text-field @input="search()"
    search() {
      setTimeout(() => {
          this.fetchData()
        }, 1000);
    },

    async fetchData() {
      // Base payload
      let payload = {
        params: {
          page: this.options.page,
          itemsPerPage: this.options.itemsPerPage,
        }
      };

      // Add name parameter to payload.params
      if (!this._.isEmpty(this.dataTable.search)) {
        payload.params.name = this.dataTable.search;
      }

      // if team exists and isManagement, Add team parameter to payload.params
      if (this.teamFilter && this.userHasRailAccessDivisionDropdown) {
        payload.params.team = this.teamFilter;
      }

      // Add sortDesc parameter to payload.params
      Object.assign(payload.params, this.sortDescHandler(this.options.sortDesc));

      // Add sortBy parameter to payload
      this.addParameterToPayload(payload, 'sortBy[]', this.options.sortBy);
    
      // API Call
      await this.$store.dispatch('groups/get', payload);
    },

    dialogAddPlayerToGroup() {
      this.dialogues.addPlayer = true;
    },

    dialogRemoveGroup() {
      this.dialogues.removeGroup = true;
    },

    isValidImagePath(imagePath) {
      try {
        require('@/assets/groups/' + imagePath);
        return true;
      } catch (error) {
        return false;
      }
    },

    setActiveItem(item) {
      if (item) {
        this.$store.commit(
          'groups/setSelectedGroup',
          item
        );
      }
    },

    dialogEditGroup() {
      this.dialogues.editGroup = true;
    },

    async dialogViewGroups(item) {
      this.$router.push({
        path: `/groups/${item.id}`
      });
    },
  },
};
