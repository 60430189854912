import Vue from 'vue';
import { mapGetters } from 'vuex';

export default {
    props: {
      selectedGroup: Object,
    },

    data(){
        return {
          teamName: '',
          teamImage: '',
          tabs: {
            current: 0,
            headers: ['Info', 'Subida Rápida', 'Subida Normal', 'Descida', 'Recuperação'],
          },
        }
    },

    computed: {
      ...mapGetters({
          teams: 'teams/teams',
          teamsLoading: 'teams/loading'
      }),
  },

  watch:{
    selectedGroup: function(newVal){
      this.getTeamInfo();
    }
  },

  mounted() {
    this.getTeamInfo();
  },

  methods:{
    hasCustomGoals(){
      if(this.selectedGroup && this.selectedGroup.contractNumEvents != null || this.selectedGroup && this.selectedGroup.contractNumSessions != null || this.selectedGroup && this.selectedGroup.oblNumEvents != null || this.selectedGroup && this.selectedGroup.oblNumSessions != null){
        return true;
      }
      return false;
    },

    getTeamInfo(){
      if(this.teams){
        let item = this.teams.find((item) => item.id === this.selectedGroup.team);
        if(!item) return;
        this.teamName = item.name;
        this.teamImage = item.image;
      }
    },

    checkImageExists(image) {
      try {
        require('@/assets/teams/' + image);
        return image;
      } catch (error) {
         return 'noteam.png';
      }
    },

    getNameImageBadge(imageBadge){
      let badge = Vue.prototype.$group_badges.find(b => b.image === imageBadge);
      return badge ? badge.name : 'N/A';
    }
  }

  };