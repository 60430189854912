import Vue from 'vue';
import { mapGetters } from 'vuex'
import Hero from '@/components/Hero/Hero.vue';
import AlertNoData from '@/components/AlertNoData';
import DataMixin from '@/mixins/Data'
import User from '@/mixins/User.vue';
import ScheduleTable from '@/components/Schedules/ScheduleTableV2/ScheduleTable.vue';

export default {
  name: 'SchedulesUser',

  components: {
    Hero,
    AlertNoData,
    ScheduleTable,
  },

  mixins: [
    DataMixin,
    User,
  ],

  data() {
    return {
      dataLoadingFinished: false,
      data: [],
      btnColor: 'success',
      selectedNetwork: null,
      search: null,
    }
  },

  computed: {
    ...mapGetters({
      themeDarkMode: 'UI/themeDarkMode',
      heroDatePickerValue: 'UI/heroDatePickerValue',
      networks: 'networks/networks',
      schedules: 'schedules/schedulesMine',
      loading: 'schedules/loading',
      schedulesTotal: 'schedules/schedulesMineTotal',
      selectedSchedule: 'schedules/selectedSchedule',
      userTeamsInitialData: 'auth/userTeamsInitialData',
    }),

    schedulesComputed() {
      return this.data;
    },
  },

  watch: {
    schedules: function () {
      this.data = this.schedules;
    },

    selectedNetwork: function () {
      if (this.selectedNetwork) {
        this.selectSchedule(this.selectedNetwork);
      }
    }

  },

  created() {
    const initialUserTeams = this.userTeamsInitialData.find(team => team.id_teams == Vue.prototype.$team_ids.division_mtt);
    if(!initialUserTeams){
      this.$router.push({ path: '/' });
    }
    this.initialize();
  },

  methods: {
    async initialize() {
      await this.getSchedules();
      this.getGroups();
      this.data = this.schedules;
      if (!this._.isEmpty(this.schedules) && this.schedules[0]) {
        this.$store.commit('schedules/setSelectedSchedule', this.schedules[0]);
        this.selectedNetwork = this.selectedSchedule;
      }
    },

    selectSchedule(item) {
      if (Array.isArray(item)) {
        this.$store.commit('schedules/setSelectedSchedule', item[0]);
        this.$store.commit('schedules/setLoadingSelectedSchedule', false);
      }
    },

    async getSchedules() {
      await this.$store.dispatch('schedules/getMine');
    },

    goToDiscord() {

      const discordInviteLink = Vue.prototype.$links.discord_schedule_duvidas;

      // Redirect the user to the Discord channel
      window.open(discordInviteLink, '_blank');
    },

    getGroups() {
      if (this._.isEmpty(this.groups)) {

        this.$store.dispatch('groups/get');
      }
    },

    getBtnColor(name) {
      this.btnColor = name == 'next'
      ? 'error'
      : 'success';
    }
  }
}