import CardRequestDepositUser from './user/CardRequestDeposit.vue'
import CardRequestDepositAdmin from './admin/CardRequestDeposit.vue'
import AuthMixin from '@/mixins/Auth.vue'

export default {
  name: 'CardRequestDeposit',

  components: {
    CardRequestDepositUser,
    CardRequestDepositAdmin,
  },

  mixins: [
    AuthMixin
  ],

  computed: {
    componentToShow() {
      let pageToShow = this.userIsRailManager
                       ? 'CardRequestDepositAdmin'
                       : 'CardRequestDepositUser';
                       
      return pageToShow;
    }
  },
  methods: {

		/** This CardRequestDeposit is focus on routing/component to show based on user role
		 *  When Bankroll Increment view (current action)  by clicking submit button it triggers this submit() present in this file
		 *  This submit() triggers the submit() in child component with ref formBankrollIncrementChild
		 */
		submit() {
			this.$refs.formBankrollIncrementChild.submit()
		}
	}

}