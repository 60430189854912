import { mapGetters } from 'vuex';
import ButtonCalendarAddToGoogle from '@/components/Calendar/ButtonCalendarAddtoGoogle/ButtonCalendarAddtoGoogle.vue';

export default {
    name: 'DialogRsvpConfirmation',

	components: {
		ButtonCalendarAddToGoogle,
	},

    props: {
        isOpen: {
            type: Boolean,
            required: true,
            default: false,
        },
    },

    data() {
        return this.initialState();
    },

    computed: {
        ...mapGetters({
			selectedEvent: 'eventsCalendar/selectedEvent',
        }),
	},

    methods: {
        init() {
        },

        initialState() {
            return {
            };
        },

        closeDialog() {
            this.$emit('close-dialog');
        },

        // TODO: Duplicated
		getSelectedEventUrl() {
			return `/calendar/event/${this.selectedEvent.id}`;
		},
    }
}