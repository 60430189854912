/**
 * @ref https://www.npmjs.com/package/vue-moment
 */

import Vue from 'vue';
import VueMoment from 'vue-moment';
const moment = require('moment');
require('moment/locale/pt');
Vue.use(
    VueMoment,
    { moment }
);

//require('moment/locale/pt');
//moment.locale('moment/locale/pt');