import { mapGetters } from 'vuex';
import Hero from '@/components/Hero/Hero.vue';
import AuthMixin from '@/mixins/Auth';
import TableTransfers from '@/components/Rail/TableTransfers/TableTransfers.vue';
import TableSessions from '@/components/Rail/TableSessions/TableSessions.vue';
import TableGamesPlayed from '@/components/Tables/TableGamesPlayed/TableGamesPlayed.vue';
import LineChartHistoricBalance from '@/components/Charts/LineChartHistoricBalance/LineChartHistoricBalance.vue';
import LineChartCumulativePNL from '@/components/Charts/LineChartCumulativePNL/LineChartCumulativePNL.vue';
import CardStatistics from '@/components/Rail/Statistics/CardStatistics/CardStatistics.vue';
import Snackbar from "@/components/Snackbar";

export default {
    name: 'Nickname-Single-Admin',

    mixins: [
        AuthMixin,
    ],

    components: {
        Hero,
        TableTransfers,
        TableSessions,
        TableGamesPlayed,
        LineChartHistoricBalance,
        LineChartCumulativePNL,
        CardStatistics,
        Snackbar,
    },

    data() {
        return this.initialState();
    },

    async created() {
        await this.fetchData();
        await this.fetchStatistics();
    },

    computed: {
        ...mapGetters({
            heroDatePickerValue: 'UI/heroDatePickerValue',
            selectedNickname: 'nicknames/selectedNickname',
            hasLoadedNickname: 'nicknames/hasLoadedNickname',
            nicknameSessions: 'railSessions/nicknameSessions',
            nicknameSessionsError: 'railSessions/nicknameSessionsError',
            nicknameSessionsHumanError: 'railSessions/nicknameSessionsHumanError',
            hasLoadedNicknameSessionsTotal: 'railSessions/loadingNicknameSessions',
            nicknameSessionsTotal: 'railSessions/totalNicknameSessions',

            nicknameSessionsGraph: 'nicknames/nicknameSessionsGraph',
            hasLoadedNicknameSessionsGraph: 'nicknames/hasLoadedNicknameSessionsGraph',
        }),

        cardInfo() {
            return [
                {
                    name: 'Balanço',
                    value: this.selectedNickname && this.selectedNickname.balance ? this.selectedNickname.balance.value : 0,
                    precision: 2,
                    icon: 'mdi-bank',
                    showCurrency: true,
                    showCurrencyIcon: this.selectedNickname && this.selectedNickname.network && this.selectedNickname.network.currency.symbol,
                    showAltCurrency: false,
                    loading: !this.hasLoadedNickname,
                    show: true,
                },
                {
                    name: 'Número de sessões',
                    value: this.nicknameSessionsTotal ? this.nicknameSessionsTotal : 0,
                    precision: 0,
                    icon: 'mdi-cards-playing-outline',
                    showCurrency: false,
                    showAltCurrency: false,
                    loading: this.hasLoadedNicknameSessionsTotal,
                    show: true, //!this.isNicknameAnWallet,
                },
            ];
        },

        /**
         * validates wheter tabs are visible or not based on selectedNickname
         * @returns {Boolean} value
         */
        isNicknameAnWallet() {
            if (this.selectedNickname) {
                return this.selectedNickname && this.selectedNickname.network && this.selectedNickname.network.isWallet;
            }
        },
    },

    watch: {
        heroDatePickerValue: function () {
            this.fetchStatistics();
        },
    },

    methods: {
        initialState() {
            return {
                dates: null,
                tab: {
                    current: 0,
                    items: [
                        { tab: 'Rail', disabled: false },
                        { tab: 'Jogos', disabled: false },
                    ]
                },
            };
        },

        fetchData(id) {
            let payload = {
                id: this.$route.params.id,
                params: {}
            };

            if (id) {
                payload.id = id;
            }

            // Get balance and balance available
            this.$store.dispatch('nicknames/getNickname', payload);
        },

        fetchStatistics() {
            let payload = {
                id: this.$route.params.id,
                params: {}
            };

            if (!this._.isEmpty(this.heroDatePickerValue)) {
                payload.params.dateBegin = this.heroDatePickerValue[0];
                payload.params.dateEnd = this.heroDatePickerValue[1];
            }

            // Get nickname sessions
            this.$store.dispatch('railSessions/getNicknameSessions', payload);

            // Get sessions graph
            if (!this._.isEmpty(payload.params)) {
                this.$store.dispatch('nicknames/getNicknameSessionsGraph', payload);
            }
        },
    },
}