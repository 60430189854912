import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth.vue';
import UserMixin from '@/mixins/User';
import RowTransfer from '@/components/Rail/RowTransfer/RowTransfer.vue';

export default {
    name: 'CardStepperResume',

    components: {
        RowTransfer
    },

    props: {
        nicknames: { type: Array, required: false, default: null },
        wallets: { type: Array, required: false, default: [] },
        observation: { type: String, required: false, default: null }
    },

    mixins: [
        AuthMixin,
        UserMixin,
    ],

    computed: {
        ...mapGetters({
            userDisplayName: 'auth/userDisplayName',
        })
    },

    data() {
        return this.initializeState();
    },

    methods: {
        initializeState() {
            return {
                player: null,
                manager: null,
                isTransferPending:  true,
            }
        },

        /**
         * Show target nickname based on network currency code
         */
        getTargetNicknameByNetworkCurrency(currency) {
            let output = this._.find(this.wallets, ['code', currency]);
            return output.nickname;
        },
    
        getTransfers() {
            let transfers = [];

            this.nicknames.forEach(element => {

                // Set target by currency code
                let target = this.getTargetNicknameByNetworkCurrency(element.currency.code);

                // Push objects to transfers array
                transfers.push({
                    source: element.wallet,
                    target: target,
                    amount: element.balance,
                    isPending: this.isTransferPending
                    
                })
            });

            return transfers;
        }
    }
}
