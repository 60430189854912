import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth.vue';

export default {
    name: 'ButtonCalendarJoinMeeting',

	mixins: [
		AuthMixin,
	],

    data() {
        return this.initialState();
    },

    computed: {
        ...mapGetters({
            userTrackerId: 'auth/userTrackerId',
            selectedEvent: 'eventsCalendar/selectedEvent',
        }),
    },

    methods: {
        initialState() {
            return {};
        },
    }
}