<template>
  <v-dialog
    max-width="350"
    :value="dialogOpen"
    @input="closeDialog()"
    @click:outside="closeDialog()"
  >
    <v-card>
      <v-card-title class="headline">
        Apagar registo de saída?
      </v-card-title>

      <v-card-text>
        Esta ação é irreversível, todos os dados irão ser apagados do sistema. Note-se que os acessos anteriormente detidos pelo jogador em questão terão de ser restabelecidos de forma manual.
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn
          text
          @click="closeDialog()"
        >
          Cancelar
        </v-btn>
        <v-btn
          color="error"
          text
          @click="deleteDeparture()"
        >
          Apagar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: 'DeparturesDialogDelete',

    mixins: [],

    props: {
        dialogOpen: { type: Boolean, required: true, default: false },
        action: { type: String, required: false, default: null }
    },

    data() {
        return {
        }
    },

    computed: {
        ...mapGetters({
            selectedDeparture: 'departures/selectedDeparture',
            reasonsDeparture: 'departures/reasonsDeparture',
            users: 'users_v1/users'
        }),
    },

    created() {
        //this.initialize();
    },

    methods: {
        deleteDeparture() {
            this.$store.dispatch('departures/deleteDeparture');
            this.$emit('confirm-delete');
        },

        closeDialog() {
            this.$emit('close-dialog');
        }
    }
}
</script>

<style scoped>
</style>