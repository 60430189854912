import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth.vue';
import TableSingleSwapTournament from '@/components/Rail/TableSingleSwapTournament/TableSingleSwapTournament.vue';
import DialogAddEditSwapTournament from '@/components/Rail/Dialog/DialogAddEditSwapTournament/DialogAddEditSwapTournament.vue';
import DialogConfirmation from '@/components/Rail/Dialog/DialogConfirmation/DialogConfirmation.vue';
import DialogSwapClose from '@/components/Rail/Dialog/DialogSwapClose/DialogSwapClose.vue';

export default {
    name: 'TableSwapTournaments',

    components: {
        TableSingleSwapTournament,
        DialogAddEditSwapTournament,
        DialogConfirmation,
        DialogSwapClose,
    },

    mixins: [
        AuthMixin,
      ],

    computed: {
        ...mapGetters({
            swapPools: 'railSwaps/swapPools',
            swapPoolsTotal: 'railSwaps/swapPoolsTotal',
            swapPoolsLoading: 'railSwaps/swapPoolsLoading',
            selectedSwapGroup: 'railSwaps/selectedSwapGroup',
            selectedSwapPool: 'railSwaps/selectedSwapPool',
            error: 'railSwaps/error',
            humanError: 'railSwaps/humanError',
            heroDatePickerValue: 'UI/heroDatePickerValue',
        }),
        
        headers: function () {
            let output =  [
                { text: 'Data', value: 'date', sortable: true },
                { text: 'Nome do torneio', value: 'game', sortable: true },
                { text: 'Pago', value: 'paid', sortable: true },
            ]

            if (this.userIsRailManager) {
                output.push({ text: 'Acção', value: 'action', sortable: false });
            }

            return output;
        },

        showButtonPaymentAll: function () {
            let output = false;
            let poolsToPay = this.swapPools ? this.swapPools.map(e => e.paid) : [];

            output = poolsToPay.includes(false);

            return output;
        }
    },

    watch: {
        // watches this.options object
        // triggers everytime this.options has changed
        options: {
            handler() {
                this.fetchData();
            },
            deep: true,
        },
        heroDatePickerValue: {
            handler() {
                this.fetchData();
            },
            deep: true,
        }
    },

    data() {
        return this.initialState();
    },

    methods: {
        initialState() {
            return {
                form: {
                    deleting: false,
                },
                options: {
                    page: 1,
                    itemsPerPage: 10,
                    sortBy: [],
                    sortDesc: [],
                    groupBy: [],
                    groupDesc: [],
                    mustSort: true,
                    multiSort: true
                },
                dialogs: {
                    swapTournament: false,
                    swapDelete: false,
                    swapClose: false,
                },
                action: null,
                type: '',
            };
        },

        toggleDialog(name, action = 'create', item = null) {
            this.setActiveItem(item);
            this.action = action;
            this.dialogs[name] = !this.dialogs[name];
        },

        setActiveItem(item) {
            if (item) {
                this.$store.commit('railSwaps/setSelectedSwapPool', item);
            }
        },

        setExpandItem(expandItem) {
            if (expandItem.value) {
                this.setActiveItem(expandItem.item);
            } else {
                this.$store.commit('railSwaps/setSelectedSwapPool', null);
            }
        },

        fetchData() {
            let id = this.selectedSwapGroup && this.selectedSwapGroup.id
                ? this.selectedSwapGroup.id
                : this.$route.params.id;

            let payload = {
                params: {
                    swapGroup: id,
                    page: this.options.page,
                    itemsPerPage: this.options.itemsPerPage,
                }
            }


            // Add sortBy parameter to payload
            if (!this._.isEmpty(this.options.sortBy)) {
                this.options.sortBy.forEach((element, index) => {
                payload.params['sortBy[' + index + ']'] = element;
                })
            }

            // Add sortDesc parameter to payload.params
            if (!this._.isEmpty(this.options.sortDesc)) {
                this.options.sortDesc.forEach((element, index) => {
                payload.params['sort[' + index + ']'] = element == false ? 'ASC' : 'DESC';;
                })
            }

            if (!this._.isEmpty(this.heroDatePickerValue)) {
                payload.params.dateBegin = this.heroDatePickerValue[0];
                payload.params.dateEnd = this.heroDatePickerValue[1];
            }
            
            this.$store.dispatch('railSwaps/getSwapPools', payload);
        },

        async deleteSwapPool() {
            let result;

			// Disable delete btn
            this.toggleFormDeleting();

            // Generate payload
            let payload = this.selectedSwapPool && this.selectedSwapPool.id ? this.selectedSwapPool.id : null;

			// Dispatch to store
            if (!this._.isNull(payload)) {
                result = await this.$store.dispatch('railSwaps/deleteSwapPool', payload);
            }

			// Re-enable delete btn
			this.toggleFormDeleting();

            // On success
			if (result === true) {

                // Refresh Swap Pools
                this.fetchData();

                // Refresh Statistics
                this.fetchStatistics();

                this.dialogs.swapDelete = false
                // Show success snackbar
				this.$store.dispatch('UI/showSnackbar', {
                    message: 'Swap pool apagado com sucesso.',
                    color: 'success'
                });
            }
        },

        toggleFormDeleting() {
            this.form.deleting = !this.form.deleting;
        },

        fetchStatistics() {
            let payload = {
                id: this.selectedSwapGroup && this.selectedSwapGroup.id ? this.selectedSwapGroup.id : null,
            }
            // Dispatch to store
            if (!this._.isNull(payload.id)) {
                return this.$store.dispatch('railSwaps/getSwapGroupStatistics', payload);
            }
        },

        /**
         * Opens DialogClose
         * Dispatches API Call to retrieve Swap close request
         * @param {Object} e 
         */
        openDialogSwapClose(e) {
            if (e) {
                this.setActiveItem(e);
                this.$store.dispatch('railSwaps/getSwapPoolFinalize', e);
                this.type = 'pool';
            } else {
                let payload = {
                    id: this.selectedSwapGroup.id
                }
                this.type = 'group';
                this.$store.dispatch('railSwaps/getSwapGroupFinalize', payload);
            }
            // opens Dialog
            this.toggleDialog('swapClose');
        },

    },
}