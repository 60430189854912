import { mapGetters } from 'vuex';
import Hero from '@/components/Hero/Hero.vue';
import DataMixin from '@/mixins/Data';
import AuthMixin from "@/mixins/Auth";
import TableGroups from '@/components/Tables/TableGroups/TableGroups.vue';
import DialogCreateGroup from '@/components/Dialog/DialogCreateGroup/DialogCreateGroup.vue';
import Snackbar from "@/components/Snackbar";

export default {
    name: 'Groups',

    components: {
        Hero,
        TableGroups,
        DialogCreateGroup,
        Snackbar,
    },

    mixins: [
        DataMixin,
        AuthMixin,
    ],

    computed: {
        ...mapGetters({
            groups: 'groups/groups',
        })
    },

    data() {
        return this.initialState();
    },
    
    async created() {
        await this.allowOnlyRoles(['rail_admin', 'rail_manager', 'tracker_admin', 'tracker_manager', 'super_coach']);
    },

    methods: {
        initialState() {
            return {
                dialogues: {
                    create: false,
                }
            };
        },
        dialogCreateGroup() {
            this.dialogues.create = true;
        }
    },
}