/**
 * @ref https://www.npmjs.com/package/vue-lodash
 */

import Vue from 'vue';
import VueLodash from 'vue-lodash';
import lodash from 'lodash';
Vue.use(
    VueLodash,
    { lodash: lodash }
);