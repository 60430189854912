import Vue from 'vue';
import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth';
import RailMixin from '@/mixins/Rail';
import DataMixin from '@/mixins/Data';
import EmptyStateNoData from '@/components/EmptyStateNoData';

export default {
	name: 'DialogViewLevelChangeEvent',

	props: {
		isOpen: { type: Boolean, required: true, default: false },
    },
    
    components: {
        EmptyStateNoData,
    },

    mixins: [
        AuthMixin,
        RailMixin,
        DataMixin,
    ],
	
	data() {
		return this.initialState();
	},

	computed: {
		...mapGetters({
			selectedGroupChange: 'groups/selectedGroupChange',
            usersAll: 'users/usersAllActive',
            themeDarkMode: 'UI/themeDarkMode',
		}),
	},

	methods: {
		initialState() {
			return {
				
			};
		},

		closeDialog() {
			this.$emit("close-dialog");
		},

		handleStatus(status) {
            let output = {
                name: '',
                color: '',
                icon: '',
                textColor: ''
            }

            if (status) {
                output = this._.find(Vue.prototype.$GroupChangeStatus, { key: status });
            }

            return output;
        },

        handleDirection(direction) {
            let output = {
                name: '',
                icon: '',
                color: ''
            }

            if (direction) {
                output = this._.find(Vue.prototype.$GroupChangeDirection, { key: direction })
            }

            return output;
		},
		
		handleUserImgCutout(id) {
            let output = null;

            if (!this._.isEmpty(this.usersAll)) {

                output = this.usersAll.find(element => element.id == id);

                if (!this._.isUndefined(output)) {
                    output = output.imgCutout;
                }

            }
            return output;
        },

        handleReason(reason) {
            let output = {
                name: ''
            }
            if (reason) {
                output = this._.find(Vue.prototype.$GroupsChangeReason, { key: reason });
            }

            return output;
        },

        handleStats(item) {
            let output = {
                name: '',
                color: '',
                icon: ''
            }
            if (item && item.details.stats_ok) {
                output = {
                    name: 'Ok',
                    color: 'success',
                    icon: 'mdi-check-circle-outline',
                }
            } else {
                output = {
                    name: 'Não ok',
                    color: 'error',
                    icon: 'mdi-close-circle-outline',
                }
            }
            return output;
        },

        handleDaysOffColor(daysOff, maxDaysOff) {
            if (daysOff > maxDaysOff) {
                return 'error';
            } else if (daysOff == maxDaysOff) {
                return 'warning';
            } else {
                return 'success';
            }
        },

        triggerCopyToClipboard() {
            let url = window.location.origin + '/levelchange/' + this.selectedGroupChange.id;
            this.copyToClipboard(url);

            // Show snackbar
            this.$store.dispatch('UI/showSnackbar', {
              message: "🔗 Link da alteração de nível copiado com sucesso.",
              color: 'success'
			});
        },

        getCurrencyInputWidth(value) {
            let output = '50px';
            if (value) {
                output = parseFloat(value).toFixed(3);

                // turn output into String
                output = String(output);

                output = (output.length + 1.2) * 8 + 'px';
            }
            return output;
        },
	},
};