
import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth.vue';
import RailMixin from '@/mixins/Rail.vue';
import DataMixin from '@/mixins/Data.vue';
import EmptyStateNoData from '@/components/EmptyStateNoData';

export default {
  name: 'TableStatistics',

  mixins: [
    AuthMixin,
    RailMixin,
    DataMixin
  ],

  components: {
    EmptyStateNoData,
  },

  data() {
    return this.initializeState();
  },

  created() {
    this.fetchData();
  },

  computed: {
    ...mapGetters({
      statistics: 'railStatistics/data',
      statisticsLoading: 'railStatistics/loading',
      themeDarkMode: 'UI/themeDarkMode',
      heroDatePickerValue: 'UI/heroDatePickerValue',
    }),

    tableData: {
      get: function () {
          return this.items;
      },
      set: function (newValue) {
          this.items = Object.values(newValue);
      }
    },
  },

  watch: {
    heroDatePickerValue: function () {
        this.fetchData();
    },

    statistics: function (newValue) {
        this.tableData = newValue;
    },
},

  methods: {
    initializeState() {
      return {
        items: [],
        headers: [
          { text: 'Nome', value: 'typeVerbal' },
          { text: 'Stats € EUR', value: 'value.EUR.value' },
          { text: 'Stats $ USD', value: 'value.USD.value' },
        ],
      };
    },
    fetchData() {
      // Formulate payload
      let payload = null;
      if (! this._.isEmpty(this.heroDatePickerValue)) {
          payload = {
              dateBegin: this.heroDatePickerValue[0],
              dateEnd: this.heroDatePickerValue[1],
          };
      }
      
      // Get data
      this.$store.dispatch('railStatistics/get', payload);
  },
  },
};
