import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth';
import RailMixin from '@/mixins/Rail.vue';
import UserDropdown from '@/components/Rail/UserDropdown/UserDropdown.vue';
import NicknameDropdown from '@/components/Rail/NicknameDropdown/NicknameDropdown.vue';
import AlertError from '@/components/Alerts/AlertError/AlertError.vue';
import CardStepperNotes from '@/components/CardStepper/CardStepperNotes/CardStepperNotes.vue';

export default {
	name: 'DialogSwapClose',

	props: {
        isOpen: { type: Boolean, required: true, default: false },
        type: { type: String, required: true, default: 'pool' },
    },
    
    components: {
        UserDropdown,
        NicknameDropdown,
        AlertError,
        CardStepperNotes,
	},

    mixins: [
        AuthMixin,
        RailMixin,
    ],
	
	data() {
		return this.initialState();
	},

	computed: {
		...mapGetters({
            selectedSwapPoolClose: 'railSwaps/selectedSwapPoolClose',
            selectedSwapPoolCloseLoading: 'railSwaps/selectedSwapPoolCloseLoading',
            selectedSwapPool: 'railSwaps/selectedSwapPool',
            selectedSwapGroup: 'railSwaps/selectedSwapGroup',
            selectedSwapGroupClose: 'railSwaps/selectedSwapGroupClose',
            selectedSwapGroupCloseLoading: 'railSwaps/selectedSwapGroupCloseLoading',
            themeDarkMode: 'UI/themeDarkMode',
            users: 'users/usersAllActive',
            error: 'railSwaps/error',
            humanError: 'railSwaps/humanError',
        }),
        usersPayComputed: {
            get: function () {
               return this.usersPay;
            },
            set: function (newValue) {
                this.usersPay = newValue;
            }
        },
    },
    
    watch: {
        isOpen: {
            handler() {
                if (this.isOpen) {
                    this.populate();
                }
            },
        },
        selectedSwapPoolClose: function () {
            this.populate(this.selectedSwapPoolClose);
        },
        selectedSwapGroupClose: function () {
            this.populate(this.selectedSwapGroupClose);
        },
    },

	methods: {
		initialState() {
            return {
                form: {
                    observation: null,
                    gyazoLink: null,
                    fileName: null,
                },
                submitting: false,
                showSkrillAlert: false,
                arrayBooleanValues: [],
                usersPay: [],
                usersReceive: [],
                transfersPreview: [],
                messageAlert: 'Ao finalizar este swap pool, serão criadas operações do tipo Swap. Alguns jogadores terão de enviar dinheiro para a gestão.',
                dialogTitle: 'Finalizar Swap pool',
                datatable: {
                    headers: [
                        { text: 'Nome', value: 'sourceUser' },
                        { text: 'Nickname', value: 'sourceNickname'},
                        { text: 'Montante', value: 'amount' },
                    ],
                    headersPreview: [
                        { text: 'De', value: 'sourceUser' },
                        { text: 'Para', value: 'targetUser' },
                        { text: 'Montante', value: 'amount' },
                    ]
                },
			};
        },
        
        populate(item) {
            this.dialogTitle = this.type == 'pool'
                ? 'Finalizar Swap pool'
                : 'Finalizar Swap group';
            
            this.messageAlert = this.type == 'pool'
                ? 'Ao finalizar este swap pool, serão criadas operações do tipo Swap. Alguns jogadores terão de enviar dinheiro para a gestão.'
                : 'Ao finalizar este swap group,serão finalizados todos os swap pools que estiverem pendentes neste grupo. Serão criadas operações do tipo Swap. Alguns jogadores terão de enviar dinheiro para a gestão.';
            
            // populates usersPayComputed[] property
            if (item && !this._.isEmpty(item.usersPay)) {
                this.usersPayComputed = item.usersPay.map(element => {
                    return {
                        sourceUser: { id: element.sourceUser },
                        sourceNickname: element.sourceNickname,
                        targetUser: { id: element.targetUser },
                        targetNickname: element.targetNickname,
                        targetNetwork: element.targetNetwork,
                        targetName: element.targetName,
                        amount: element.amount,
                        observation: element.observation
                    }
                });
            }

            // populates usersReceive[]
            if (item && !this._.isEmpty(item.usersReceive)) {
                this.usersReceive = item.usersReceive.map(element => {
                    return {
                        sourceUser: { id: element.sourceUser },
                        sourceNickname: element.sourceNickname,
                        targetUser: { id: element.targetUser },
                        targetNickname: element.targetNickname,
                        targetNetwork: element.targetNetwork,
                        targetName: element.targetName,
                        amount: element.amount,
                        observation: element.observation
                    }
                })
            }
            // concats usersPayComputed + usersReceive
            this.transfersPreview = this.usersPayComputed.concat(this.usersReceive);
        },

        /**
         * 
         * @param {Number|String} id 
         * @returns {String} name
         */
        handleUsername(id) {
            let output = '';
            if (!this._.isEmpty(this.users)) { 
                let user = this.users.find(element => element.id == id);
                if (user.name) {
                    output = user.name;
                }
            }
            return output;
        },

        async submit() {
            // way of validate if NicknameDropdown input is fullfilled
			if (this.$refs && this.$refs.NicknameDropdownComponent && !this.$refs.NicknameDropdownComponent.$refs.formNicknameDropdown.validate()) {
				return false;
            }

			// Toggle submit button
            this.toggleSubmitting();
            
            let item = this.type == 'pool'
            ? this.selectedSwapPool
            : this.selectedSwapGroup;

            const payload = {
                id: item.id,
            };

            payload.params = new FormData();

            // Add 'observation' field if necessary
            if (this.form.observation) {
                payload.params.append('observation[description]', this.form.observation);
            } else {
                payload.params.append('observation[description]', 'Operação criada automaticamente através das Swap pools');
            }

            // Add 'screenshot' field if necessary
            if (!this._.isEmpty(this.form.fileName)) {
                payload.params.append('screenshot', this.form.fileName[0]);
            }

            // Add 'observation[url]' field if necessary
            if (this.form.gyazoLink) {
                payload.params.append('observation[url]', this.form.gyazoLink);
            }

            let actionToDispatch = this.type == 'pool'
                ? 'railSwaps/setSwapPoolFinalize'
                : 'railSwaps/setSwapGroupFinalize';

			let result = await this.$store.dispatch(actionToDispatch, payload);

			// // Re-enable button
			this.toggleSubmitting();

			// On success
			if (result === true) {
				// Show success snackbar
				this.$store.dispatch('UI/showSnackbar', {
					message: 'Swap finalizado com sucesso.',
					color: 'success'
				});

				// refresh TableSwapTournaments, statistics
				let payloadRefresh = {
					id: this.selectedSwapGroup.id,
					params: {
						swapGroup: this.selectedSwapGroup.id,
					}
				}

				this.$store.dispatch('railSwaps/getSwapPools', payloadRefresh);
				this.$store.dispatch('railSwaps/getSwapGroupStatistics', payloadRefresh);
				
				// closes dialog
				this.closeDialog();
			}
        },

		/**
		 * Used to disable Submit button
		 */
		toggleSubmitting() {
			this.submitting = !this.submitting;
		},

		closeDialog() {
			this.$emit("close-dialog");
			//this.resetForm();
		},

		resetForm() {
			Object.assign(this.$data, this.initialState());
			this.$refs.formSwapClose.reset();
        },

        // retrieve observation fields from CardStepperNotes component
        getInfoFromCardStepperNotes(payload) {
            if (payload) {
                this.form.gyazoLink = payload.gyazoLink;
                this.form.fileName = payload.filename;
            }
        },

        /**
         * triggered by NicknameDropdown 
         * @param {Boolean} hasSkrill 
         */
        handleSkrillAlert(hasSkrill) {
            this.arrayBooleanValues.push(hasSkrill);

            // if arrayBooleanValues includes false then it means someone hasn't Skrill USD
            this.showSkrillAlert = this.arrayBooleanValues.includes(false);
        }
	},
};