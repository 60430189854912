var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',[_vm._v(" Estatisticas "),_c('v-spacer')],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.tableData,"server-items-length":_vm.statistics.length,"items-per-page":10,"loading":_vm.statisticsLoading,"loading-text":"A carregar...","must-sort":""},scopedSlots:_vm._u([{key:"item.typeVerbal",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(_vm.$statistics_name[item.typeVerbal].name)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$statistics_name[item.typeVerbal].description))])])]}},{key:"item.value.EUR.value",fn:function(ref){
var item = ref.item;
return [_c('currency-input',{staticClass:"text-start",style:({color: _vm.valueColorFinal()}),attrs:{"value":item.value && item.value.EUR ? item.value.EUR.value : item.value,"currency":null,"allow-negative":true,"disabled":""}})]}},{key:"item.value.USD.value",fn:function(ref){
var item = ref.item;
return [_c('currency-input',{staticClass:"text-start",style:({color: _vm.valueColorFinal()}),attrs:{"value":item.value && item.value.USD ? item.value.USD.value : item.value,"currency":null,"allow-negative":true,"disabled":""}})]}},{key:"no-data",fn:function(){return [(!_vm.statisticsLoading)?_c('EmptyStateNoData',{attrs:{"icon":"mdi-check-circle-outline","title":"Não existem estatisticas para este jogador","class-color":"success white--text","class-icon":"white"}}):_vm._e()]},proxy:true},{key:"no-results",fn:function(){return [_c('EmptyStateNoData',{attrs:{"icon":"mdi-magnify","title":"Não foram encontrados resultados","message":"Ajuste os termos da sua pesquisa para encontrar mais resultados"}})]},proxy:true}])},[_c('template',{slot:"progress"},[_c('v-progress-linear',{attrs:{"color":"accent","indeterminate":""}})],1)],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }