import { mapGetters } from 'vuex';

export default {
    name: 'NetworkSharkscopeDropdown',

    computed: {
        ...mapGetters({
            networks: 'schedulingNetworksSharkscope/data',
            loading: 'schedulingNetworksSharkscope/loading',
            error: 'schedulingNetworksSharkscope/error',
            networksSharkscope: 'schedulingNetworksSharkscope/networksSharkscope',
        })
    },

    props: {
        value: {
            type: Object,
            required: false,
            default: () => {}
        },
        label: {
            type: String,
            required: false,
            default: 'Sala'
        },
        status: {
            type: String,
            required: false,
            default: ''
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        solo: {
            type: Boolean,
            required: false,
            default: false
        },
        clearable: {
            type: Boolean,
            required: false,
            default: false
        },
        outlined: {
            type: Boolean,
            required: false,
            default: true
        },
        hideDetails: {
            type: Boolean,
            required: false,
            default: false
        },
        hint: {
            type: String,
            required: false,
            default: ''
        },
        autoReload: {
            type: Boolean,
            required: false,
            default: true
        },
        loadV2: {
            type: Boolean,
            required: false,
            default: false
        },
    },

    created() {
        this.getNetworks();
    },  

    data() {
        return this.initialState();
    },

    methods: {
        initialState() {
            return {
                selectedNetwork: null,
                search: null,
            }
        },

        emitChange() {
            this.$emit('input', this.selectedNetwork);
            this.$emit('change', this.selectedNetwork);
        },

        async getNetworks() {
            if(this.loadV2){
                await this.$store.dispatch('schedulingNetworksSharkscope/getNetworksSharkscopeV2');
            }else{
                await this.$store.dispatch('schedulingNetworksSharkscope/get');
            }
        },
    },
}