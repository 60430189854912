import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import StoreMixin from '@/mixins/Store.vue';

Vue.use(Vuex);

const state = {
    games: [],
    selectedGame: null,
    loading: true,
    error: null,
    humanError: null,
    totalGames: null,
};

const getters = {
    games: (state) => state.games,
    selectedGame: (state) => state.selectedGame,
    loading: (state) => state.loading,
    error: (state) => state.error,
    humanError: (state) => state.humanError,
    totalGames: (state) => state.totalGames,
};

const mutations = {
    set(state, payload) {
        state.games = payload;
    },
    setLoading(state, payload) {
        state.loading = payload;
    },
    setSelectedGame(state, payload) {
        state.selectedGame = payload;
    },
    setError(state, payload) {
        state.error = payload;
    },
    setHumanError(state, payload) {
        state.humanError = payload;
    },
    setTotalGames(state, payload) {
        state.totalGames = payload;
    },
};

const actions = {
    async get({ dispatch, commit }, payload) {
        // clear state
        dispatch('clearError');
        commit('set', []);
        commit('setLoading', true);

        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/games/';

        // Configure URL parameters
        let parameters = payload && payload.params
            ? payload.params
            : StoreMixin.methods.getParametersDefault();
        
        // Build URL with parameters
        request_url = StoreMixin.methods.generateQueryParamsUrl(request_url, parameters);

        // Execute request & return
        let output = false;

        return axios.get(request_url)
        .then((response) => {
            output = response.data.success;

            if (output) {
                commit('setTotalGames', response.data.total);
                commit('set', response.data.data);
            } else {
                commit('setError', response.data.message);
                commit('setHumanError', response.data.human_message);
            }
        })
        .catch(function (error) {
            commit('setError', error.response ? error.response.data.message : error);
            commit('setHumanError', error.response ? error.response.data.human_message : error);
            output = false;
        })
        .then(function () {
            commit('setLoading', false);
            return output;
        });
    },

    async getGameById({ dispatch, commit }, payload){
         // clear state
         dispatch('clearError');
         commit('setSelectedGame', null);
         commit('setLoading', true);
 
         // Configure request
         let request_url = Vue.prototype.$url_api + 'v2/games/' + payload.id;
 
         // Execute request & return
         let output = false;
 
         return axios.get(request_url)
         .then(function (response) {
             output = response.data.success;
             if (output) {
                 commit('setSelectedGame', response.data.data);
             } else {
                 commit('setError', response.data.message);
                 commit('setHumanError', response.data.human_message);
                 commit('setErrorStatus', response.status);
             }
         })
         .catch(function (error) {
             commit('setError', error.response ? error.response.data.message : error);
             commit('setHumanError', error.response ? error.response.data.human_message : error);
             output = false;
         })
         .then(function () {
             commit('setLoading', false);
             return output;
         });
    },

    clearError({ commit }) {
        commit('setError', null);
        commit('setHumanError', null);
    },

    // Reset store to its initial state
    reset({ commit }) {
        commit('set', []);
        commit('setTotalGames', null);
        commit('setLoading', true);
        commit('setSelected', null);
        commit('setError', null);
        commit('setHumanError', null);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
