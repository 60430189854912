import Vue from 'vue';
import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth.vue';
import DataMixin from '@/mixins/Data.vue';
import RailMixin from '@/mixins/Rail.vue';
import EmptyStateNoData from '@/components/EmptyStateNoData';
import DialogStatusNickname from '@/components/Dialog/DialogStatusNickname/DialogStatusNickname.vue';
import DialogEditNickname from '@/components/Dialog/DialogEditNickname/DialogEditNickname.vue';
import DialogCreateNicknameAlias from '@/components/Dialog/DialogCreateNicknameAlias/DialogCreateNicknameAlias.vue';
import DialogConfirmation from '@/components/Rail/Dialog/DialogConfirmation/DialogConfirmation.vue';
import InputFinancial from '@/components/InputFinancial/InputFinancial.vue';
import UserCard from '@/components/UserCard/UserCard.vue';

export default {
  name: 'TableNicknames',

  props: {
    // values: "nickname" and "network"
    type: { type: String, required: false, default: 'nickname' },
    reload: { type: Boolean, required: false, default: false },
    network: { type: [String, Number], required: false, default: null },
    showAlias: { type: Boolean, required: false, default: true },
  },

  mixins: [
    AuthMixin,
    DataMixin,
    RailMixin,
  ],

  components: {
    EmptyStateNoData,
    DialogStatusNickname,
    DialogEditNickname,
    InputFinancial,
    DialogConfirmation,
    DialogCreateNicknameAlias,
    UserCard,
  },

  data() {
    return this.initialState();
  },

  computed: {
    ...mapGetters({
      nicknames: 'nicknames/nicknames',
      nicknamesOut: 'nicknames/nicknamesOut',
      nicknamesInactive: 'nicknames/nicknamesInactive',
      nicknamesTotal: 'nicknames/nicknamesTotal',
      nicknamesOutTotal: 'nicknames/nicknamesOutTotal',
      nicknamesInactiveTotal: 'nicknames/nicknamesInactiveTotal',
      hasLoaded: 'nicknames/hasLoaded',
      hasLoadedNicknamesOut: 'nicknames/hasLoadedNicknamesOut',
      hasLoadedNicknamesInactive: 'nicknames/hasLoadedNicknamesInactive',
      themeDarkMode: 'UI/themeDarkMode',
      userTrackerId: 'auth/userTrackerId',
      teamFilter: 'teams/teamFilter',
      selectedNickname: 'nicknames/selectedNickname',
      error: 'nicknames/error',
      humanError: 'nicknames/humanError',
    }),

    filterNicknamesByCurrentTab: function () {
      let output;
      switch (this.tabs.current) {
        case 0:
          output = this.nicknames;
          break;
        case 1:
          output = this.nicknamesOut;
          break;
        case 2:
          output = this.nicknamesInactive;
          break;
        default:
          output = this.nicknames;
          break;
      }
      return output;
    },

    filterHasLoadedNicknamesByCurrentTab: function () {
      let output;
      switch (this.tabs.current) {
        case 0:
          output = ! this.hasLoaded;
          break;
        case 1:
          output = ! this.hasLoadedNicknamesOut;
          break;
        case 2:
          output = ! this.hasLoadedNicknamesInactive;
          break;
        default:
          output = ! this.hasLoaded;
          break;
      }
      return output;
    },

    filterNicknamesTotalByCurrentTab: function () {
      let output;
      switch (this.tabs.current) {
        case 0:
          output = this.nicknamesTotal;
          break;
        case 1:
          output = this.nicknamesOutTotal;
          break;
        case 2:
          output = this.nicknamesInactiveTotal;
          break;
        default:
          output = this.nicknamesTotal;
          break;
      }
      return output;
    },

    // Handle table Headers based  in prop type and if it's an Admin
    tableHeaders() {
      if (this.type == 'nickname') {
        return this.userIsManager
          ? this.dataTable.headers.admin
          : this.dataTable.headers.user;
      } else {
        return this.dataTable.headers.network;
      }
    },

    // handle tabs based on prop type
    tableTabs() {
      return this.type == 'nickname'
        ? this.tabs.headers
        : null;
    },

    showTabs() {
      return this.userIsManager && this.type == 'nickname';
    },

    optionsComputed: {
      get: function () {
        return this.options;
      },
      set: function (newValue) {
          if (!this._.isEqual(this.options, newValue)) {
              this.options = newValue;
          }
      }
    },

    dialogConfirmationPhrase() {
      return this.userIsManager
              ? 'Para desativar este nickname tem de primeiro transferir o saldo para um outro nickname, que poderá ser uma wallet ou um outro nickname na mesma sala. Para transferir o saldo aceda a Rail > Movimentos > Registo livre.'
              : 'Para desativar este nickname tem de primeiro transferir o saldo para um outro nickname, que poderá ser uma wallet ou um outro nickname na mesma sala. Para transferir o saldo aceda a Rail > Transferência > Transferência entre contas.';
    },

    currencyInputColor() {
      return this.themeDarkMode ? 'white' : 'black';
    },
  },

  watch: {
    // watches this.options object
    // triggers everytime this.options has changed
    options: {
      handler() {
        this.fetchData();
      },
      deep: true,
    },
    // watches this.tabs object
    // resets this.options object everytime tab has changed
    tabs: {
      handler() {
        this.options = {
          page: 1,
          itemsPerPage: 10,
          sortBy: [],
          sortDesc: [],
          groupBy: [],
          groupDesc: [],
          mustSort: false,
          multiSort: false
        }
      },
      deep: true,
    },
    // watches this.reload prop type
    // triggers fetchData()
    reload: {
      handler() {
        if (this.reload) {
          this.fetchData();
        }
      },
      immediate: true,
    },
    teamFilter: function () {
      this.fetchData();
    },

    showAlias: function () {
      this.fetchData();
    }
  },

  created() {
    return this.initialize();
  },

  methods: {
    initialState() {
      return {
        tabs: {
          current: 0,
          headers: ['Todos', 'OPT-OUT', 'Inativos'],
        },
        dataTable: {
          headers: {
            user: [
              { text: 'Network', value: 'network' },
              { text: 'Nickname', value: 'name' },
              { text: 'Estado', value: 'flag' },
              { text: 'Saldo', value: 'balanceEUR' },
              { text: 'Ação', value: 'action', sortable: false },
            ],
            admin: [
              { text: 'Network', value: 'network',},
              { text: 'Nickname', value: 'name' },
              { text: 'Estado', value: 'flag' },
              { text: 'Saldo', value: 'balanceEUR' },
              { text: 'Jogador', value: 'user' },
              { text: 'Equipas', value: 'teams', sortable: false },
              { text: 'Ação', value: 'action', sortable: false },
            ],
            network: [
              { text: 'Nickname', value: 'name' },
              { text: 'Jogador', value: 'user' },
              { text: 'Equipas', value: 'teams', sortable: false },
            ],
          },
          search: null,
          footerProps: {
            'items-per-page-options': this.$itemsPerPageOptions,
          }
        },
        options: {
          page: 1,
          itemsPerPage: 10,
          sortBy: [],
          sortDesc: [],
          groupBy: [],
          groupDesc: [],
          mustSort: true,
          multiSort: false
        },
        dialogues: {
          edit: false,
          status: false,
          confirmation: false,
          confirmationRemoveAlias: false,
          create: false,
        },
        action: 'deactivate',
        removeAliasLoading: false,
      };
    },

    async initialize() {
      await this.fetchData();
    },

    /**
     * Populates status based on current tab
     * @returns status value and triggers fetchData()
     */
    filter() {
      let status;
        switch (this.tabs.current) {
          case 0:
            // All
            status = [
              { value: Vue.prototype.$nickname_status.active.value , name: 'active' },
              { value: Vue.prototype.$nickname_status.flagged.value, name: 'flagged' },
            ]

            if (this.type == 'nickname') {
              status.push(
                { value: Vue.prototype.$nickname_status.deassociated.value, name: 'deassociated' },
                { value: Vue.prototype.$nickname_status.inactive.value, name: 'inactive' }
              )
            }
            break;
          case 1:
            // deassociated
            status = [
              { value: Vue.prototype.$nickname_status.deassociated.value, name: 'deassociated' },
            ]
            break;
          case 2:
            // inactive
            status = [
              { value: Vue.prototype.$nickname_status.inactive.value, name: 'inactive' },
            ]
            break;
          default:
            status = []
            break;
      }
      return status;
    },

    // trigered by v-text-field @input="search()"
    search() {
      let lastValue = this.dataTable.search;
            
      setTimeout(() => {
          if (lastValue == this.dataTable.search) {
              // Manually trigger API call if page = 1
              if (this.options.page == 1) {
                  this.fetchData();
              }
              // This triggers API call if page != 1 due to watcher
              else {
                  this.options.page = 1;
              }
          }
      }, 1500);
    },

    async fetchData() {
      // Base payload
      let payload = {
        params: {
          page: this.options.page,
          itemsPerPage: this.options.itemsPerPage,
          tab: this.tabs.current
        }
      };

      if (this.showAlias == false) {
        payload.params.aliasOf = null;
      }

      // get status[] based on current tab
      let statusFilter = this.filter();

      if (!this._.isEmpty(statusFilter)) {
        statusFilter.forEach((e, index) => {
          payload.params['status[' + index + ']'] = e.value;
        }) 
      }

      // add prop type network if not null
      if (!this._.isNull(this.network)) {
        payload.params.network = this.network;
      }

      // if team exists and isManagement, Add team parameter to payload.params
      if (this.teamFilter && this.userHasRailAccessDivisionDropdown) {
        payload.params.team = this.teamFilter;
      }

      // add id parameter to payload
      if (this.type == 'network') {
        payload.id = this.$route.params.id;
        payload.params['userStatus[0]'] = 2; // retrieve only active users
      }

      // Add name parameter to payload.params
      if (!this._.isEmpty(this.dataTable.search)) {
        payload.params.search = this.dataTable.search;
      }

      // Add sortDesc parameter to payload.params
      Object.assign(payload.params, this.sortDescHandler(this.options.sortDesc));

      // Add sortBy parameter to payload
      this.addParameterToPayload(payload, 'sortBy[]', this.options.sortBy);


      // API Call
      
      // if is not admin, add user Id in order to retrieve all nicknames (wallets included)
      if (!this.userIsManager && this.type == 'nickname') {
        payload.params.user = this.userTrackerId;
      }
      return await this.$store.dispatch('nicknames/getAll', payload);
    },

    /**
     * Set selected nickname
     * @param {Object} item 
     */
    setActiveItem(item) {
      this.$store.commit('nicknames/setSelectedNickname', item);
    },

    dialogStatusNickname(item, action) {
      this.action = action;

      if (action == 'activate' || item.balance.value == 0) {
        this.dialogues.status = true;
      } else {
        this.dialogues.confirmation = true;
      }
    },

    dialogEditNickname() {
      this.dialogues.edit = true;
    },

    openDialogCreateNickname(item) {
      this.setActiveItem(item);
      this.dialogues.create = true;
    },

    dialogRemoveAlias(){
      this.dialogues.confirmationRemoveAlias = true;
    },

    toggleSubmittingAlias(){
      this.removeAliasLoading = !this.removeAliasLoading;
    },

    async removeAlias(){
      this.toggleSubmittingAlias();
      let payload = {
        id: this.selectedNickname.id,
        body:{
          aliasOf: null,
        }
      }

      let result = await this.$store.dispatch('nicknames/update', payload);
      this.toggleSubmittingAlias();

      if(result) {
        // User feedback
        this.$store.dispatch('UI/showSnackbar', {
            message: 'Alias removido com sucesso',
            color: 'success',
        });

      this.dialogues.confirmationRemoveAlias = false;
      this.fetchData();
      }
    },

    imageCutout(item) {
      let placeholder = require('@/assets/images/players/player-example.png');
      return item && item.imgCutout ?  item.imgCutout : placeholder;
    },
  },
};
