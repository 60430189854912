import { mapGetters } from 'vuex';
import RailMixin from '@/mixins/Rail.vue';
import DataMixin from '@/mixins/Data.vue';
import AuthMixin from '@/mixins/Auth.vue';
import InputFinancial from '@/components/InputFinancial/InputFinancial.vue';
import NicknameDropdown from '@/components/Rail/NicknameDropdown/NicknameDropdown.vue';

export default {
    name: 'NetworkSessionsSpinsPicker',

    mixins: [
        RailMixin,
        DataMixin,
        AuthMixin,
    ],

    components: {
        InputFinancial,
        NicknameDropdown,
    },

    props: {
        action: { type: String, required: false, default: 'create' },
        isDeleteDisabled: { type: Boolean, required: false, default: false },
        userId: { type: [String, Number], required: false, default: null },
        excludedNetworks: { type: Array, required: false, default: () => [] },
        index: {
            type: Number,
            required: true,
            default: 0
        },
        selectedSessionNickname: {
            type: Object,
            required: true,
            default() {
                return  {
                    nickname: null,
                    balance: null,
                    rakebackBalance: 0,
                    rakebackTickets: 0,
                    rakebackCoins: 0,
                    metadata: [
                        {
                            stake: null,
                            gameType: 1,
                            numberEvents: null,
                            chipNetWon: 0,
                            chipEv: 0,
                        }
                    ],
                }
            }
        },
    },

    data() {
        return this.initialState();
    },

    mounted() {
        // Clear error in <input-financial>, triggered naturally when element is loaded
        if (this.$refs.inputFinancial) {
            this.$refs.inputFinancial.resetValidation();
        }
        if (this.$refs.numberEvents) {
            this.$refs.numberEvents.resetValidation();
        }

        if (this.action == 'update') {
            this.populateOnEdit();
        }
    },

    computed: {
        ...mapGetters({
            themeDarkMode: 'UI/themeDarkMode',
            users: 'users/usersAllActive',
            userTrackerId: 'auth/userTrackerId',
            selectedSession: 'railSessions/selectedSession',
            userRolesLoading: 'auth/userRolesLoading',
        }),

        spinsListComputed: {
            get() {
                return this.selectedSessionNickname.metadata;
            },
            set(newVal) {
                this.form.spinsList = newVal;
            },
        },

        sessionNicknamesListHeaders: function () {
            let output = [
                { text: 'Sala/Nickname', value: 'nickname', sortable: false, width: '18.6%'},
                { text: 'Saldo Final', value: 'balance', sortable: false, width: '18.6%' },
                { text: 'Rakeback - Challenge', value: 'rakebackBalance', sortable: false, width: '18.6%' },
                { text: 'Rakeback - Tickets', value: 'rakebackTickets', sortable: false, width: '18.6%' },
                { text: 'Rakeback - Stars Coins', value: 'rakebackCoins', sortable: false, width: '18.6%' },
                { text: '', value: 'action', sortable: false, width: '6.6%', align: 'center' }
            ];

            return output;
        },

        spinsListHeaders: function () {
            let output = [
                { text: 'Stake', value: 'stake', sortable: false, width: '18.6%' },
                { text: 'Tipo de jogo', value: 'gameType', sortable: false, width: '18.6%' },
                { text: 'N.º de Spins', value: 'numberEvents', sortable: false, width: '18.6%' },
                { text: 'Chips Net won', value: 'chipNetWon', sortable: false, width: '18.6%' },
                { text: 'Chip EV', value: 'chipEv', sortable: false, width: '18.6%'  },
                { text: '', value: 'action', sortable: false, width: '6.6%', align: 'center' },
            ];

            return output;
        },

        // @returns {Object} props passed to NicknameDropdown
        nicknameSourceComputed() {
            return {
                ref: "NicknameDropdownSource",
                type:"PLAYER",
                scope: this.userIsRailManager ? 'ALL' : 'MINE',
                user: this.userIsRailManager ? this.userId : null,
                currencies: [],
                readonly: this.userIsRailManager && ! this.userId,
                'always-reload': !this.userIsRailManager,
                excludeZeroBalance: ! this.userIsRailManager,
                label: "Sala/Nickname",
                dense: true,
                outlined: true,
                flat: true,
                solo: false,
                hideDetails: true,
            }
        },

        /**
         * uses excludedNetworks[]
         * @returns {Array} filled with network IDS
         */
        handleExcludedNetworks() {
            let ownNicknameNetworkId = this.selectedSessionNickname.nickname && this.selectedSessionNickname.nickname.network
                ? this.selectedSessionNickname.nickname.network.id
                : null;
            return this.excludedNetworks.filter(id => id != ownNicknameNetworkId);
        }
    },

    watch: {
        userRolesLoading: function () {
            this.setupRules();
        }   
    },

	created() {
        this.setupRules();
	},

    methods: {
        initialState() {
            return {
                gameTypes: [
                    { value: 1, name: 'Normal' },
                    { value: 2, name: 'Flash' },
                ],
                stakeTypes: [
                    { value: 0.25, name: '0,25' },
                    { value: 0.50, name: '0,50' },
                    { value: 1, name: '1' },
                    { value: 2, name: '2' },
                    { value: 3, name: '3' },
                    { value: 5, name: '5' },
                    { value: 10, name: '10' },
                    { value: 20, name: '20' },
                    { value: 25, name: '25' },
                    { value: 50, name: '50' },
                    { value: 100, name: '100' },
                    { value: 200, name: '200' },
                    { value: 250, name: '250' },
                    { value: 500, name: '500' },
                    { value: 1000, name: '1000' },
                ],
                checkbox: false,
                form: {
                    spinsList: [
                        {
                            stake: null,
                            gameType: 1,
                            numberEvents: 0,
                            chipNetWon: 0,
                            chipEv: 0,
                        }
                    ],
                },
                dataTable: {
                    itemsPerPage: -1,
                },
                rules: {
                    buyin: [
                        (v) => !!v || 'Por favor introduza o buyin',
                        (v) => ! Number.isNaN(v) || 'Insira um valor numérico',
                    ],
                    nickname: [
                        (v) => !!v || 'Selecione uma sala'
                    ],
                    balance: [
                        (v) => !!v || 'Por favor introduza um balanço',
                    ],
                    stake: [
                        (v) => !!v || 'Por favor introduza um stake',
                    ],
                    numberEvents: [
                        (v) => !!v || 'Por favor introduza um número de eventos',
                    ],
                    chipNetWon: [
                        (v) => !!v || 'Por favor introduza um número de fichas',
                    ],
                    chipEv: [
                        (v) => !!v || 'Por favor introduza o valor do chipEV',
                    ],
                    rakebackBalance: [],
                    rakebackTickets: [],
                    rakebackCoins: [],
                },
                allowNegativeInputChipNetWon: false,
                allowNegativeInputRakeback: false,
            }
        },

        /**
         * Populate Table (Stake spins) when action is 'update'
         */
        populateOnEdit() { 
            this.spinsListComputed = this.selectedSessionNickname.metadata;
        },

        /**
         * emits an event to remove this selected Session nickname
         */
        removeSelectedSessionNickname(index) {
            this.$emit('removeSelectedSessionNickname', index);
        },

        /**
         * Disables delete spin btn based on spinsListComputed.length
         * @returns {Boolean}
         */
        handleDisableDeleteSpinsBtn() {
            return this.spinsListComputed.length == 1;
        },

        /**
         * Retrieve index position from spinsListComputed and remove it from the array
         * @param {Object} item 
         */
        removeSpinEntry(item) {
            let indexPosition = this.spinsListComputed.indexOf(item);

            // removes item (indexPosition) from spinsListComputed
            this.spinsListComputed.splice(indexPosition, 1);

            this.$emit('updateSelectedSessionNickname', this.index);
        },

        /**
         * Add new entry to spinsListComputed
         */
        addNewSpinEntry() {
            this.spinsListComputed.push({
                stake: null,
                gameType: 1,
                numberEvents: 0,
                chipNetWon: 0,
                chipEv: 0,
            })
            
            this.$emit('updateSelectedSessionNickname', this.index);
        },

        // get icon based on selected Nickname
        getCurrencyIcon() {
            return this.selectedSessionNickname.nickname && this.selectedSessionNickname.nickname.network
                ? this.selectedSessionNickname.nickname.network.currency.symbol
                : '';
        },

        /**
         * Adds extra rules for non-manager users
         * @return {void}
         */
        setupRules() {
            // Add mandatory positive
            this.allowNegativeInputChipNetWon = this.userIsRailManager;
            this.allowNegativeInputRakeback = this.userIsRailManager;

            if (! this.userIsRailManager) {
                this.rules.numberEvents.push(
                    (v) => !!v && parseFloat(v) > 0 || 'Introduza um número positivo',
                );

                this.rules.rakebackBalance.push(
                    (v) => !!v || 'Por favor introduza um montante de rakeback',
                );

                this.rules.rakebackTickets.push(
                    (v) => !!v || 'Por favor introduza um montante de rakeback',
                );

                this.rules.rakebackCoins.push(
                    (v) => !!v || 'Por favor introduza um montante de rakeback',
                );
            }
        },
    }
}
