import Vue from 'vue';
import Vuex from 'vuex';
import StoreMixin from '@/mixins/Store.vue';
import DataMixin from '@/mixins/Data.vue';
import axios from 'axios';

Vue.use(Vuex);
Vue.use(StoreMixin);
Vue.use(DataMixin);

const state = {
    stats: [],
    statsBatch: [],
    statsByUserId: [],
    statsTotal: null,
    selectedStat: null,
    error: null,
    humanError: null,
    loading: true,
    statsByUserIdLoading: false,
    selectedStatisticLoading: false,
    statsBatchLoading: false,
    lastPayload: {},
};

const getters = {
    stats: state => state.stats,
    statsBatch: state => state.statsBatch,
    statsByUserId: state => state.statsByUserId,
    statsTotal: state => state.statsTotal,
    selectedStat: state => state.selectedStat,
    error: state => state.error,
    humanError: state => state.humanError,
    loading: state => state.loading,
    selectedStatisticLoading: state => state.selectedStatisticLoading,
    statsByUserIdLoading: state => state.statsByUserIdLoading,
    statsBatchLoading: state => state.statsBatchLoading,
};

const mutations = {
    setStats: (state, payload) => { state.stats = payload; },
    setStatsBatch: (state, payload) => { state.statsBatch = payload; },
    setStatsBatchLoading: (state, payload) => { state.statsBatchLoading = payload },
    setStatsByUserId: (state, payload) => { state.statsByUserId = payload; },
    setStatsTotal: (state, payload) => { state.statsTotal = payload; },
    setError: (state, payload) => { state.error = payload; },
    setSelectedStat: (state, payload) => { state.selectedStat = payload; },
    setErrorStatus: (state, payload) => { state.errorStatus = payload },
    setHumanError: (state, payload) => { state.humanError = payload; },
    setSelectedStatLoading: (state, payload) => { state.selectedStatsLoading = payload },
    setStatsByUserIdLoading: (state, payload) => { state.statsByUserIdLoading = payload },
    setLoading: (state, payload) => { state.loading = payload },
    addStat: (state, payload) => {
        if (! state.stats || ! state.stats.length)
        state.stats = [];
    
        state.stats.unshift(payload);
    },
    updateStat: (state, payload) => {
        const index = state.stats.findIndex(d => d.id == payload.id);

        const updatedItems = [
            ...state.stats.slice(0, index),
            payload,
            ...state.stats.slice(index + 1)
        ];

        state.stats = updatedItems;
    },
    setLastPayload: (state, payload) => { state.lastPayload = payload; },
}

const actions = {
    clearError({ commit }) {
        commit('setError', null);
        commit('setHumanError', null);
        commit('setErrorStatus', null);
    },

    async getStats({ commit, dispatch, state }, payload) {

        // validate payload objects before API Call
        let areObjectsEqual = DataMixin.methods.areObjectsEqual(state.lastPayload, payload.params);

        if (areObjectsEqual) return false;
        // save last payload
        commit('setLastPayload', payload.params);

        // clear states
        commit('setLoading', true);
        commit('setStats', []);
        
        let request_url = Vue.prototype.$url_api + 'v2/stats/';

        let parameters = payload && payload.params
            ? payload.params
            : StoreMixin.methods.getParametersDefault();

        request_url = StoreMixin.methods.generateQueryParamsUrl(request_url, parameters);

        let output = false;

        return axios.get(request_url)
        .then(function (response) {
            output = response.data.success;
            if (output) {
                commit('setStats', response.data.data);
                commit('setStatsTotal', response.data.total);
            } else {
                commit('setError', response.data.message);
                commit('setHumanError', response.data.human_message);
            }
        })
        .catch(function (error) {
            if(error.response && error.response.status !== 404){
                commit('setError', error.response ? error.response.data.message : error);
                commit('setHumanError', error.response ? error.response.data.human_message : error);
            }
            output = false;
        })
        .then(function () {
            commit('setLoading', false);
            return output;
        });
    },

    async getStatsByUserId ({ commit, dispatch }, payload) {
        
        commit('setStatsByUserIdLoading', true)
        let request_url = Vue.prototype.$url_api + 'v2/stats/';

        let parameters = payload && payload.params
            ? payload.params
            : StoreMixin.methods.getParametersDefault();

        request_url = StoreMixin.methods.generateQueryParamsUrl(request_url, parameters);

        let output = false;

        return axios.get(request_url)
        .then(function (response) {
            output = response.data.success;
            if (output) {
                commit('setStatsByUserId', response.data.data);
            } else {
                commit('setError', response.data.message);
                commit('setHumanError', response.data.human_message);
            }
        })
        .catch(function (error) {
            commit('setError', error.response ? error.response.data.message : error);
            commit('setHumanError', error.response ? error.response.data.human_message : error);
            output = false;
        })
        .then(function () {
            commit('setStatsByUserIdLoading', false);
            return output;
        });
    },

    async create({ commit, dispatch }, payload) {
        dispatch('clearError');

        let data = JSON.stringify(payload.body);

        const request_url = Vue.prototype.$url_api + 'v2/stats/';
        let config = {
            method: 'POST',
            url: request_url,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        }
        let output = false;

        return axios(config)
        .then(function (response) {
            output = response.data.success;
            if (output) {
                commit('addStat', response.data.data);
                commit('setSelectedStat', response.data.data);
            } else {
                commit('setError', response.data.message);
                commit('setHumanError', response.data.human_message);
            }
        })
        .catch(function (error) {
            commit('setError', error.response ? error.response.data.message : error);
            commit('setHumanError', error.response ? error.response.data.human_message : error);
            output = false;
        })
        .then(function () {
            return output;
        });
    },

    async createBatchImport({ commit, dispatch }, payload) {
        dispatch('clearError');
        commit('setStatsBatchLoading', true);

        const request_url = Vue.prototype.$url_api + 'v2/stats/batch_import/';

        var config = {
            method: 'POST',
            url: request_url,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            data: payload
        }
        let output = false;

        return axios(config)
        .then(function (response) {
            output = response.data.success;
            if (output) {
                commit('setStatsBatch', response.data.data);
            } else {
                commit('setError', response.data.message);
                commit('setHumanError', response.data.human_message);
            }
        })
        .catch(function (error) {
            commit('setError', error.response ? error.response.data.message : error);
            commit('setHumanError', error.response ? error.response.data.human_message : error);
            output = false;
        })
        .then(function () {
            commit('setStatsBatchLoading', false);
            return output;
        });
    },

    async update({ commit, dispatch }, payload) {
        // clear state
        dispatch('clearError');

        // Handle payload data
        let data = JSON.stringify(payload.body);

        // Configure request
        const request_url = Vue.prototype.$url_api + 'v2/stats/' + payload.id;
        let config = {
            method: 'PUT',
            url: request_url,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        }
        // Execute request & return
        let output = false;

        return axios(config)
        .then(function (response) {
            output = response.data.success;

            if (output) {
                commit('updateStat', response.data.data);
                commit('setSelectedStat', response.data.data);
            } else {
                commit('setError', response.data.message);
                commit('setHumanError', response.data.human_message);
            }
        })
        .catch(function (error) {
            commit('setError', error.response ? error.response.data.message : error);
            commit('setHumanError', error.response ? error.response.data.human_message : error);
            output = false;
        })
        .then(function () {
            return output;
        });
    },

    async remove({ commit, dispatch }, payload) {
        dispatch('clearError');

        const request_url = Vue.prototype.$url_api + 'v2/stats/' + payload.id;
        let config = {
            method: 'DELETE',
            url: request_url,
            headers: {
                'Content-Type': 'application/json'
            },
        }
        let output = false;
        return axios(config)
        .then(function (response) {
            // 204 response status represents success
            output = response.status == 204;
            if (output) {
                // set last payload to default state in order to force refresh when triggering getStats()
                commit('setLastPayload', {});
            } else {
                commit('setError', response.data.message);
                commit('setHumanError', response.data.human_message);
            }
        })
        .catch(function (error) {
            commit('setError', error.response ? error.response.data.message : error);
            commit('setHumanError', error.response ? error.response.data.human_message : error);
            output = false;
        })
        .then(function () {
            return output;
        });
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
