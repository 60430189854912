export default {
    name: 'GroupImageDropdown',

    props: {
        value: { type: [Object,String], required: false, default: () => { } },
        hideDetails: { type: Boolean, required: false, default: false },
        outlined: { type: Boolean, required: false, default: false },
        solo: { type: Boolean, required: false, default: false },
        hint: { type: Boolean, required: false, default: false },
        multiple: { type: Boolean, required: false, default: false },
        disabled: { type: Boolean, required: false, default: false },
    },

    computed: {
        computedValue: {
            get: function () {
               return this.groupImage;
            },
            set: function (newValue) {
                this.groupImage = newValue;
                this.emitChange();
            }
        },
    },

    watch: {
        value: {
            handler() {
                this.computedValue = this.value;
            }   
        }
    },

    data() {
        return this.initialState();
    },

    created() {
        this.computedValue = this.value;
    },

    methods: {
        initialState() {
            return {
                groupImage: null,
            }
        },
        
        emitChange() {
            this.$emit('input', this.computedValue);
            this.$emit('change', this.computedValue);
        },

        reset() {
            this.groupImage = null;
            if (this.$refs.formGroupImageDropdown) {
                this.$refs.formGroupImageDropdown.reset();
            }
         },
    }
}