import Vue from 'vue';
import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth.vue';
import RailMixin from '@/mixins/Rail.vue';
import DatePickerNavigator from '@/components/DatePickerNavigator/DatePickerNavigator.vue';
import DialogViewSession from '@/components/Rail/DialogViewSession/DialogViewSession.vue';

export default {
  name: 'HeatmapYearly',

  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    labels: {
      type: Array,
      default: () => ['Jan', 'Feb', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
    },
  },

  components: {
    DatePickerNavigator,
    DialogViewSession,
  },

  mixins: [
    AuthMixin,
    RailMixin,
  ],
  
  data() {
    return this.initialState();
  },

  computed: {
    ...mapGetters({
      selectedUser: 'users/selectedUser',
      userTeams: 'auth/userTeams',
      userStatisticsHeatmap: 'users/userStatisticsHeatmap',
      userStatisticsHeatmapLoading: 'users/userStatisticsHeatmapLoading',
      themeDarkMode: 'UI/themeDarkMode',
    }),

    series() {
      if (this.userStatisticsHeatmapLoading || this._.isNull(this.userStatisticsHeatmap)) {
        return [];
      }
      let data = [
        { name: 'Sab', data: this.userStatisticsHeatmap.Saturday.entries },
        { name: 'Sex', data: this.userStatisticsHeatmap.Friday.entries },
        { name: 'Qui', data: this.userStatisticsHeatmap.Thursday.entries },
        { name: 'Qua', data: this.userStatisticsHeatmap.Wednesday.entries },
        { name: 'Ter', data: this.userStatisticsHeatmap.Tuesday.entries },
        { name: 'Seg', data: this.userStatisticsHeatmap.Monday.entries },
        { name: 'Dom', data: this.userStatisticsHeatmap.Sunday.entries },
      ]

      return data;
    },


    userTeamIds: function () {
      let teamsMap = [];

      if (this.userIsPlayer && this.userTeams) {
        teamsMap = this.userTeams.map(e => e.id);
      }

      if (this.userIsRailManager && this.selectedUser) {
        teamsMap = this.selectedUser.teams.map(e => e.id);
      }

      return teamsMap;
    },

    colorRangesByTeam: function () {
      let teamsMap = this.userTeamIds;

      if (teamsMap.includes(Vue.prototype.$team_division_cash_id)) {
        return this.colorTeamCash;
      } else if (teamsMap.includes(Vue.prototype.$team_division_mtt_id)) {
        return this.colorTeamMtt;
      } else {
        return [];
      }
    },

    colorNoValueDefault: function () {
      return this.themeDarkMode ? '#6E6E6E' : '#f6f7f8'
    },

    colorTeamMtt: function () {
      return [
        {
          from: 0,
          to: 0,
          color: this.colorNoValueDefault,
          foreColor: undefined,
          name: undefined,
        },
        {
          from: 1,
          to: 10,
          color: '#D3E7D4',
          foreColor: undefined,
          name: undefined,
        },
        {
          from: 11,
          to: 20,
          color: '#B1D9B3',
          foreColor: undefined,
          name: undefined,
        },
        {
          from: 21,
          to: 30,
          color: '#90CB92',
          foreColor: undefined,
          name: undefined,
        },
        {
          from: 31,
          to: 40,
          color: '#6EBD71',
          foreColor: undefined,
          name: undefined,
        },
        {
          from: 41,
          to: 50,
          color: '#6EBD71',
          foreColor: undefined,
          name: undefined,
        },
        {
          from: 51,
          to: 2500,
          color: '#4CAF50',
          foreColor: undefined,
          name: undefined,
        },
      ]
    },

    colorTeamCash: function () {
      return [
        {
        from: 0,
        to: 0,
        color: this.colorNoValueDefault,
        foreColor: undefined,
        name: undefined,
        },
        {
          from: 1,
          to: 1000,
          color: '#D3E7D4',
          foreColor: undefined,
          name: undefined,
        },
        {
          from: 1001,
          to: 2000,
          color: '#B1D9B3',
          foreColor: undefined,
          name: undefined,
        },
        {
          from: 2001,
          to: 3000,
          color: '#90CB92',
          foreColor: undefined,
          name: undefined,
        },
        {
          from: 3001,
          to: 4000,
          color: '#6EBD71',
          foreColor: undefined,
          name: undefined,
        },
        {
          from: 4001,
          to: 5000,
          color: '#4CAF50',
          foreColor: undefined,
          name: undefined,
        },
        {
          from: 5001,
          to: 15000,
          color: '#4CAF50',
          foreColor: undefined,
          name: undefined,
        },
      ]
    },


    options() {
      return {
        chart: {
          type: 'heatmap',
          toolbar: {
            show: false
          },
          foreColor: '#f6f7f8',
          background: this.themeDarkMode ? 'dark' : 'light',
          events: {
            dataPointSelection: (event, chartContext, config) => {
              let details = chartContext.series.w.config.series[config.seriesIndex].data[config.dataPointIndex].details;
              
              if (details) {
                this.fetchSessionByID(details.id);
              }
            }
          }
        },
        // @ref https://apexcharts.com/docs/options/legend/
        legend: {
          show: true,
          fontSize: '12px',
          fontFamily: 'Inter, sans-serif',
          position: 'bottom',
          horizontalAlign: 'left',
          inverseOrder: false,
          formatter: function (item) {
            let output = '?';
            
            // Calculate the first num of the interval
            let minNumber = item.split(' - ')[0];

            // So the labels start at 0 instead of 1 (e.g. 10, 20, 30 instead of 11, 21, 31)
            minNumber = minNumber == 1
                        ? minNumber
                        : minNumber-1;

            if (item == '0 - 0') {
              output = '0';
            } else if (item == '51 - 2500') {
              output = '50+ torneios'
            } else if (item == '5001 - 15000') {
              output = '5000+ mãos'
            } else {
              output = minNumber + '+';
            }

            return output;
          },
          markers: {
            width: 15,
            height: 15,
            strokeWidth: 0,
            strokeColor: '#fff',
            fillColors: undefined,
            radius: 0,
            customHTML: undefined,
            onClick: undefined,
            offsetX: 0,
            offsetY: 0
          },
          itemMargin: {
            horizontal: 5,
            vertical: 5
          },
        },
        plotOptions: {
          heatmap: {
            radius: 0,
            enableShades: false,
            colorScale: {
              ranges: this.colorRangesByTeam,
            }
          },
        },
        theme: {
          mode: this.themeDarkMode ? 'dark' : 'light',
        },
        grid: {
          show: true,
          xaxis: {
            lines: {
                show: true
            }
          },   
          yaxis: {
              lines: {
                  show: false
              }
          },
        },
        colors: [this.colorNoValueDefault],
        dataLabels: {
          enabled: false,
        },
        tooltip: {
          enabled: true,
          enabledOnSeries: true,
          shared: true,
          intersect: false,
          x: {
            show: true,
            formatter: (series, item) => {
              let output = 'Sem dados'
              if (item.w && item.w.config) {
                output = 'Data: ' + this.$moment(item.w.config.series[item.seriesIndex].data[item.dataPointIndex].x).format('DD-MM-YYYY');
              }
              return output;
            },
          },
          y: {
            show: true,
            formatter: (series, item) => {
              if (item.w && item.w.config.series[item.seriesIndex].data[item.dataPointIndex].details) {
                const pnl = item.w.config.series[item.seriesIndex].data[item.dataPointIndex].details.pnl.value
                const eventsPlayed = item.w.config.series[item.seriesIndex].data[item.dataPointIndex].details.eventsPlayed
                const minutesPlayed = item.w.config.series[item.seriesIndex].data[item.dataPointIndex].details.minutesPlayed
                const pnlText = pnl + ' €';
                const color = pnl > 0 ? '#4CAF50' : '#FF0000';

                return '<div class="d-flex flex-column" style="width: 100% !important">' +
                  '<div class="d-flex align-center" style="width: 150px !important; justify-content: space-between !important;">' +
                  '<span>Lucro:</span>' +
                  '<span style="color: ' + color + ';" > ' + pnlText + '</span>' +
                  '</div>' +
                  '<div class="d-flex align-center" style="width: 150px !important; justify-content: space-between !important;">' +
                  '<span>Nº de ' + (this.userTeamIds.includes(Vue.prototype.$team_division_mtt_id) ? 'torneios' : 'mãos') +':</span>' +
                  '<span> ' + eventsPlayed + '</span>' +
                  '</div>' +
                  '<div class="d-flex align-center" style="width: 150px !important; justify-content: space-between !important;">' +
                  '<span>Minutos jogados:</span>' +
                  '<span> ' + minutesPlayed + '</span>' +
                  '</div>' +
                  '</div > ';
              }
              return 'Sem sessão registada'
            },
            title: {
              formatter: () => undefined,
            },
          },
        },
        xaxis: {
          type: 'category',
          overwriteCategories: this.labels,
          tickAmount: 13,
          tickPlacement: 'between',
          labels: {
            rotate: 0,
            style: {
              colors: this.themeDarkMode ? '#FFFFFF' : '#000000',
            },
          },
          tooltip: {
            enabled: false,
          }
        },
        yaxis: {
          labels: {
            style: {
              colors: this.themeDarkMode ? '#FFFFFF' : '#000000',
            }
          }
        },
        states: {
          normal: {
            filter: {
              type: 'none',
              value: 0,
            }
          },
          hover: {
            filter: {
              type: 'none',
              value: 0.15,
            }
          },
          active: {
            allowMultipleDataPointsSelection: false,
            filter: {
              type: 'darker',
              value: 0.35,
            }
          },
        }
      }
    },

    computedCalendarDates: {
      get: function () {
        return this.calendarDatesFinal;
      },
      set: function (newValue) {
        this.calendarDatesFinal = newValue;
      }
    },
  },

  watch: {
    calendarDatesFinal: function () {
      this.fetchData();
    },
    themeDarkMode: function () {
      if (this.$refs.HeatmapChart) {
        this.$refs.HeatmapChart.refresh();
      }
    },
  },

  created() {
    this.computedCalendarDates = [this.$moment().format('YYYY-MM-DD'), this.$moment().format('YYYY-MM-DD')];
  },

  methods: {
    initialState() {
      return {
        calendarDatesFinal: [],
        dialogues: {
          viewSession: false,
        },
        sessionId: null,
      };
    },

    toggleDialog(dialog) {
      this.dialogues[dialog] = !this.dialogues[dialog];
    },

    async fetchSessionByID(id) {
      let result = false;

      if (id) {
        result = await this.$store.dispatch('railSessions/getSessionById', id);
      }
      if (result == true) {
        this.toggleDialog('viewSession');
      }
    },

    fetchData() {
      let payload = {
        id: this.$route.params.id,
        body: {
          year: this.$moment(this.calendarDatesFinal[0]).format('YYYY')
        }
      }
      this.$store.dispatch('users/getUserStatisticsHeatmap', payload);
    },
  },
};