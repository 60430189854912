import { mapGetters } from "vuex";

export default {
    name: "dialog-feedback",
    data() {
        return this.initialState();
    },
    created() {
        this.fetchData();
    },
    props: {
        dialogOpen: { type: Boolean, required: true, default: false },
    },
    computed: {
        ...mapGetters({
            feedback: "videoRequests/feedback",
        }),
    },
    methods: {
        async fetchData() {
            await this.$store.commit("videoRequests/feedbackStatus");
        },
        initialState() {
            return {};
        },
        closeDialog() {
            this.$emit("close-dialog");
        },
    },
};
