import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth';
import RowTransfer from '@/components/Rail/RowTransfer/RowTransfer.vue';

export default {
    name: 'DialogSplitHelperInfo',

    components: {
        RowTransfer,
    },

    props: {
        isOpen: {
            type: Boolean,
            required: true,
            default: false
        },
    },

    mixins: [
        AuthMixin,
    ],

    data() {
        return this.initialState();
    },

    computed: {
        ...mapGetters({

        }),

        examples: function () {
            switch (this.tabs.current) {
                case 0:
                    return this.edgeCaseGGPoker();
                case 1:
                    return this.edgeCaseSkrill();
                default:
                    break;
            }
        },

    },

    watch: {
        isOpen: function () {
            if (this.isOpen) {

            }
        }
    },

    methods: {
        initialState() {
            return {
                tabs: {
                    list: [
                        { name: 'Exemplo GGPoker', disabled: false },
                        { name: 'Exemplo Skrill', disabled: false },
                    ],
                    current: 0
                }
            };
        },
        closeDialog() {
            this.$emit("close-dialog");
        },

        exampleNickname(name, networkName, image) {
            return {
                "id": 20,
                "name": name,
                "description": null,
                "network": {
                    "id": 17,
                    "name": networkName,
                    "identifier": "gg",
                    "currency": {
                        "id": 3,
                        "code": "USD",
                        "symbol": "$",
                        "rate": 1.15799
                    },
                    "isWallet": false,
                    "imageURL": image,
                    "nameLegacyRail": "",
                    "metadata_required": null,
                    "active": true
                },
                "isManagement": false,
            }
        },

        edgeCaseGGPoker() {
            let output = [
                {
                    source: this.exampleNickname('', 'Jogador', 'gg_usd.png'),
                    target: this.exampleNickname('', 'Gestão', 'gg_usd.png'),
                    amount: {value: 1000} 
                },
                {
                    source: this.exampleNickname('', 'Gestão', 'gg_usd.png'),
                    target: this.exampleNickname('', 'Jogador', 'gg_usd.png'),
                    amount: {value: 500} 
                },
                {
                    source: this.exampleNickname('', 'Jogador', 'gg_usd.png'),
                    target: this.exampleNickname('', 'In & Out Wallet', 'withdraw_lg.png'),
                    amount: {value: 500} 
                }
            ]

            return output;
        },

        edgeCaseSkrill() {
            let output = [
                {
                    source: this.exampleNickname('', 'Jogador', 'skrill_usd.png'),
                    target: this.exampleNickname('', 'Gestão', 'skrill_usd.png'),
                    amount: {value: 1000} 
                },
                {
                    source: this.exampleNickname('', 'Gestão', 'skrill_usd.png'),
                    target: this.exampleNickname('', 'Jogador', 'skrill_usd.png'),
                    amount: {value: 500} 
                },
                {
                    source: this.exampleNickname('', 'Jogador', 'skrill_usd.png'),
                    target: this.exampleNickname('', 'In & Out Wallet', 'withdraw_lg.png'),
                    amount: {value: 500} 
                }
            ]

            return output;
        }
    },
};