<template>
  <component
    :is="componentToShow"
  />
</template>

<script>
import AuthMixin from '@/mixins/Auth';
import SchedulesAdmin from '@/views/Schedules/Admin/Schedules-Admin.vue';
import SchedulesUser from '@/views/SchedulesV2/User/Schedules-User.vue';
import Loading from '@/views/Loading.vue';

export default {
  name: 'Home',

  components: {
    SchedulesAdmin,
    SchedulesUser,
    Loading,
  },

  mixins: [
    AuthMixin,
  ],

  computed: {
    componentToShow() {
      let component = null;
      if (this.userIsManager || this.userHasRole(['super_coach', 'coach', 'scheduling_manager'])) {
        component = 'SchedulesAdmin';
      } else {
        component = 'SchedulesUser';
      }

      return component;
    }
  },
  
  watch: {
    userIsPlayer: {
      handler: function() {
        this.initialize();
      }
    }
  },
  
  async created() {
    await this.initialize();
  },

  methods: {
    async initialize() {
      if(this.userIsPlayer) {
        if (! this.userIsManager && ! this.userHasRole(['super_coach', 'coach', 'scheduling_manager'])) {
          this.$router.replace({ path: '/schedules/me' });
        }
      }
    },
  },

}
</script>

<style scoped>
</style>