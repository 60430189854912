export default {
    name: 'PercentageDropdown',

    props: {
        value: { type: [Object, String, Number], required: false, default: () => { } },
        label: { type: String, required: false, default: 'Percentagem' },
        hideDetails: { type: Boolean, required: false, default: false },
        outlined: { type: Boolean, required: false, default: false },
        solo: { type: Boolean, required: false, default: false },
        dense: { type: Boolean, required: false, default: false },
        hint: { type: Boolean, required: false, default: false },
        multiple: { type: Boolean, required: false, default: false },
        disabled: { type: Boolean, required: false, default: false },
        clearable: { type: Boolean, required: false, default: false },
    },

    computed: {
        computedValue: {
            get: function () {
               return this.percentage;
            },
            set: function (newValue) {
                this.percentage = newValue;
                this.emitChange();
            }
        },
    },

    watch: {
        value: {
            handler() {
                this.computedValue = this.value;
            }   
        }
    },

    data() {
        return this.initialState();
    },

    created() {
        this.computedValue = this.value;
    },

    methods: {
        initialState() {
            return {
                percentage: null,
                percentageList: [
                    { value: '0.0500', name: '5 %' },
                    { value: '0.1000', name: '10 %' },
                    { value: '0.1500', name: '15 %' },
                    { value: '0.2000', name: '20 %' },
                    { value: '0.2500', name: '25 %' },
                    { value: '0.4000', name: '40 %' },
                    { value: '0.5000', name: '50 %' },
                    { value: '0.6000', name: '60 %' },
                    { value: '0.7000', name: '70 %' },
                    { value: '0.8000', name: '80 %' },
                ]
            }
        },
        
        emitChange() {
            this.$emit('input', this.computedValue);
            this.$emit('change', this.computedValue);
        },

        reset() {
            this.percentage = null;
            if (this.$refs.formPercentageDropdown) {
                this.$refs.formPercentageDropdown.reset();
            }
         },
    }
}