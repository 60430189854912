export default {
    name: 'DashboardMenu',
    
    components: {
    },
    
    mixins: [
    ],
    
    props: {
        title: { type: String, required: false, default: 'title', },
        caption: { type: String, required: false, default: '', },
        icon: { type: String, required: false, default: 'mdi-home', },
        name_link1: { type: String, required: false, default: '', },
        name_link2: { type: String, required: false, default: '', },
        name_link3: { type: String, required: false, default: '', },
        name_link4: { type: String, required: false, default: '', },
        name_link5: { type: String, required: false, default: '', },
        name_link6: { type: String, required: false, default: '', },
        name_link7: { type: String, required: false, default: '', },
        name_link8: { type: String, required: false, default: '', },
        link1: { type: String, required: false, default: '', },
        link2: { type: String, required: false, default: '', },
        link3: { type: String, required: false, default: '', },
        link4: { type: String, required: false, default: '', },
        link5: { type: String, required: false, default: '', },
        link6: { type: String, required: false, default: '', },
        link7: { type: String, required: false, default: '', },
        link8: { type: String, required: false, default: '', },
        isNew: { type: Boolean, required: false, default: false },
    },
    
    data() {
      return this.initialState();
    },
  
    methods: {
      initialState() {
          return {
              data: null
          }
      },
    }
  };