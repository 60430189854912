import moment from 'moment';
import { mapGetters } from 'vuex';
import DataMixin from '@/mixins/Data.vue';
import DialogDelete from '@/components/Departures/DialogDelete.vue';
import DialogFinalize from '@/components/Departures/DialogFinalize.vue';
import InputNetworkAmount from '@/components/Departures/InputNetworkAmount.vue';

export default {
    name: 'DeparturesDialogEdit',

    props: {
        dialogOpen: { type: Boolean, required: true, default: false }
    },

    components: {
        DialogDelete,
        DialogFinalize,
        InputNetworkAmount
    },

    mixins: [
        DataMixin,
    ],

    computed: {
        ...mapGetters({
            selectedDeparture: 'departures/selectedDeparture',
            reasonsDeparture: 'departures/reasonsDeparture',
            users: 'users_v1/users',
            networks: 'networks/networks',
        }),

        selectedProcessStatusId() {
            if (this.processStatusOptions !== null)
                return this.processStatusOptions.find(e => e.name == this.selectedProcessStatus).id;
            
            return null;
        },

        selectedUserId() {
            if (this.selectedUser !== null)
                return this.users.find(e => e.display_name_users == this.selectedUser).id_users;

            return null;
        },

        selectedReasonsDepartureId() {
            if (this.selectedReasonsDeparture !== null)
                return this.reasonsDeparture.find(e => e.name_dr == this.selectedReasonsDeparture).id_dr;
            else
                return null;
        },

        rateUsdEur() {            
            let rate = 1;

            if (this.networks.length) {
                let usdCurrency = this.networks.find(e => e.currency.code == 'USD');

                if (usdCurrency)
                    rate = usdCurrency.currency.rate;
            }

            return parseFloat(rate);
        },
        
        textConversionEurUsd() {
            if (! this.rateUsdEur)
                return 'Convertido à taxa de 1 EUR = 1 USD';
            else
                return 'Convertido à taxa de 1 EUR = ' + this.rateUsdEur + ' USD';
        },

        sendBankrollReturnRequestStyle() {
            let output = {};

            if (this.returnRequest == false) {
                output.icon =  'mdi-email-send';
                output.text = 'Enviar pedido de devolução';
                output.color = 'primary secondary--text';
            } else {
                output.icon = 'mdi-check';
                output.text = 'Pedido de devolução enviado';
                output.color = 'success';
            }

            return output;
        },
        
        textBankrollReturned() {
            if (this.bankrollReturned) return 'Sim (' + this.bankrollReturnedTimestamp.toLocaleString() + ')'
            else return 'Não'
        },

        textMakeupReturned() {
            if (this.makeupReturned) return 'Sim (' + this.makeupReturnedTimestamp.toLocaleString() + ')'
            else return 'Não'
        },
    },

    data() {
        return this.initialState();
    },

    created() {
        this.initialize();
    },

    methods: {
        initialize() {
            this.fetchData();
        },

        async fetchData() {
            if (this._.isEmpty(this.users)) {
                this.$store.dispatch('users_v1/getUsers');
            }

            if (this._.isEmpty(this.networks)) {
                this.$store.dispatch('networks/get');
            }

            if (this._.isEmpty(this.reasonsDeparture)) {
                await this.$store.dispatch('departures/getReasonsDeparture');
            }
        },

        initialState() {
            return {
                formStage: 1,
                saving: false,

                dialogues: {
                    delete: false,
                    finalize: false
                },

                rules: {
                    fieldUserRules: [v => !!v || 'Por favor introduza um jogador'],
                    fieldReasonsDepartureRules: [v => !!v || 'Por favor selecione um motivo'],
                    fieldBankrollReturnRequestSendDatetimeRules: [v => !!v || 'Por favor selecione uma data e hora'],
                    fieldProcessStatus: [v => !!v || 'Por favor selecione um estado'],
                    fieldMakeup: [v => !!v || 'Por favor introduza o valor do makeup'],
                },

                formValidStage1: false,
                formValidStage2: false,

                /* Stage 1 */
                processStatusOptions: [
                    { id: 1, name: 'A decorrer' },
                    { id: 2, name: 'Finalizado' }
                ],
                selectedProcessStatus: 'A decorrer',
                selectedUser: null,
                selectedReasonsDeparture: null,
                observations: null,

                /* Stage 2 */
                makeupUSD: null,
                bankrollReturned: false,
                makeupReturned: false,
                bankrollReturnedTimestamp: false,
                makeupReturnedTimestamp: false,
                PairsNetworkAmount: [],
                notificationSum: {
                    USD: 0,
                    EUR: 0,
                    total: 0
                },
                bankrollSum: {
                    USD: 0,
                    EUR: 0,
                    total: 0
                },
                notificationText: null,
                returnRequest: false,
                returnRequestTimestamp: null,

                /* Stage 3 */
                accessRemoval: {
                    website: null,
                    rail: null,
                    rail_resumo: null,
                    sharkscope: null,
                    discord: null,
                    kick_discord: null,
                    kick_discord_videos: null,
                    kick_discord_mds: null,
                    folha_nicks: null
                },
            }
        },

        parseBankrollObject() {
            if (this.selectedDeparture && this.selectedDeparture.bankroll_departures) {
                if (this.isJsonString(this.selectedDeparture.bankroll_departures) && this.selectedDeparture.bankroll_departures) {
                    return JSON.parse(this.selectedDeparture.bankroll_departures);
                } else {
                    return [];
                }
            }
        },

        /* Based on view/departures - need to call populateFields */
        populateFields() {
            /* Phase 1 */
            this.selectedProcessStatus = this.processStatusOptions.find(e => e.id == this.selectedDeparture.status_departures).name;
            this.selectedUser = this.selectedDeparture.user.display_name_users;
            this.selectedReasonsDeparture = this.reasonsDeparture.find(e => e.id_dr == this.selectedDeparture.reason_departures).name_dr;
            this.observations = this.selectedDeparture.observations_departures;
            
            /* Phase 2 */
            this.makeupUSD = this.selectedDeparture.makeup_departures;

            let bankrollObj = this.parseBankrollObject();
            if ( bankrollObj )
                this.PairsNetworkAmount = bankrollObj;

            // Timestamps
            if (this.selectedDeparture.bankroll_returned_departures) {
                this.bankrollReturned = true;
                this.bankrollReturnedTimestamp = this.selectedDeparture.bankroll_returned_departures;
            }
            if (this.selectedDeparture.makeup_returned_departures) {
                this.makeupReturned = true;
                this.makeupReturnedTimestamp = this.selectedDeparture.makeup_returned_departures;
            }

            // Fill user notification screen
            this.recalculateCurrencySum();
            this.notificationText = this.selectedDeparture.notification_text_departures;
            if (this.selectedDeparture.notification_send_departures) {
                this.returnRequest = true;
                this.returnRequestTimestamp = this.selectedDeparture.notification_send_departures;
            }

            /* Phase 3 */
            this.accessRemoval.website = this.selectedDeparture.access_website_departures;
            this.accessRemoval.rail = this.selectedDeparture.access_rail_departures;
            this.accessRemoval.rail_resumo = this.selectedDeparture.access_rail_resumo_departures;
            this.accessRemoval.sharkscope = this.selectedDeparture.access_sharkscope_departures;
            this.accessRemoval.discord = this.selectedDeparture.access_discord_permissions_departures;
            this.accessRemoval.kick_discord = this.selectedDeparture.access_discord_departures;
            this.accessRemoval.kick_discord_videos = this.selectedDeparture.access_discord_videos_departures;
            this.accessRemoval.kick_discord_mds = this.selectedDeparture.access_discord_mds_departures;
            this.accessRemoval.folha_nicks = this.selectedDeparture.access_folha_nicks_departures;
        },

        markAccessRemoval(type) {
            if (! type) return;
            
            // Mark timestamp
            if ( typeof this.accessRemoval[type] !== 'undefined' )
                this.accessRemoval[type] = new Date();  //this.accessRemoval[type] = new Date().toLocaleString()
        },

        markBankrollReturned() {
            if (this.bankrollReturned)
                this.bankrollReturnedTimestamp = new Date();
            else
                this.bankrollReturnedTimestamp = null;
        },

        markMakeupReturned() {
            if (this.makeupReturned)
                this.makeupReturnedTimestamp = new Date();
            else
                this.makeupReturnedTimestamp = null;
        },

        async saveDepartures(confirmedFinalization = false, stealth = false) {
            // Validate form
            if ( ! this.$refs.formStage1.validate() ) {
                this.formStage = 1;
                return false;
            }

            // Case in which user is finalizing through the Save button, instead of the Finalize button.
            // Happens when the field 'status' on stage 1 is set to finalize.
            // We still want to show the finalization confirmation modal.
            if ( ! confirmedFinalization ) {
                if (this.selectedProcessStatusId && this.processStatusOptions.length) {
                    if (this.selectedProcessStatusId == this.processStatusOptions[this.processStatusOptions.length - 1].id) {
                        this.dialogues.finalize = true;
                        return false;
                    }
                }
            }

            // Disable saving
            this.saving = true;

            // Formulate request parameters
            let params = this.formulateParameters();

            // Dispatch Update or Create actions
            if (this.selectedDeparture) {
                // Dispatch 'updateDeparture'
                await this.$store.dispatch('departures/updateDeparture', params);
            } else {
                // Dispatch 'createDeparture'
                await this.$store.dispatch('departures/createDeparture', params);
            }

            // UI Feedback
            if ( ! stealth ) {
                // Show snackbar and close dialog
                this.$store.dispatch('UI/showSnackbar', {
                    message: 'Registo de saída ' + (confirmedFinalization ? 'finalizado' : 'guardado') + ' com sucesso.',
                    color: 'success'
                });

                this.closeDialog();
            }

            // Re-enable saving
            this.saving = false;
        },

        formulateParameters() {
            // Formulate parameters
            let params = {
                status:                     this.selectedProcessStatusId,
                user:                       this.selectedUserId,
                reason:                     this.selectedReasonsDepartureId,
                observations:               this.observations,
                bankroll_eur:               this.bankrollSum.EUR,
                bankroll_usd:               this.bankrollSum.USD,
                bankroll_total:             this.bankrollSum.total,
                bankroll_returned:          this.bankrollReturnedTimestamp ? moment(this.bankrollReturnedTimestamp).format('YYYY-MM-DD HH:mm:ss') : null,
                makeup:                     this.makeupUSD,
                makeup_returned:            this.makeupReturnedTimestamp ? moment(this.makeupReturnedTimestamp).format('YYYY-MM-DD HH:mm:ss') : null,
                notification_eur:           this.notificationSum.EUR,
                notification_usd:           this.notificationSum.USD,
                notification_total:         this.notificationSum.total,
                notification_text:          this.notificationText,
                notification_send:          this.returnRequest ? moment(this.returnRequestTimestamp).format('YYYY-MM-DD HH:mm:ss') : null,
                access_website:             this.accessRemoval.website ? moment(this.accessRemoval.website).format('YYYY-MM-DD HH:mm:ss') : null,
                access_rail:                this.accessRemoval.rail ? moment(this.accessRemoval.rail).format('YYYY-MM-DD HH:mm:ss') : null,
                access_rail_resumo:         this.accessRemoval.rail_resumo ? moment(this.accessRemoval.rail_resumo).format('YYYY-MM-DD HH:mm:ss') : null,
                access_sharkscope:          this.accessRemoval.sharkscope ? moment(this.accessRemoval.sharkscope).format('YYYY-MM-DD HH:mm:ss') : null,
                access_discord:             this.accessRemoval.discord ? moment(this.accessRemoval.discord).format('YYYY-MM-DD HH:mm:ss') : null,
                access_discord_permissions: this.accessRemoval.kick_discord ? moment(this.accessRemoval.kick_discord).format('YYYY-MM-DD HH:mm:ss') : null,
                access_discord_videos:      this.accessRemoval.kick_discord_videos ? moment(this.accessRemoval.kick_discord_videos).format('YYYY-MM-DD HH:mm:ss') : null,
                access_discord_mds:         this.accessRemoval.kick_discord_mds ? moment(this.accessRemoval.kick_discord_mds).format('YYYY-MM-DD HH:mm:ss') : null,
                access_folha_nicks:         this.accessRemoval.folha_nicks ? moment(this.accessRemoval.folha_nicks).format('YYYY-MM-DD HH:mm:ss') : null
            };

            // Convert parameters to URLSearchParams
            let parametersFinal = this.formulateRequestParameters(params);

            for (let index in this.PairsNetworkAmount) {
                for (const[key,value] of Object.entries(this.PairsNetworkAmount[index])) {
                    let fabricatedKey = 'bankroll['+index+']['+key+']';
                    parametersFinal.append(fabricatedKey, value);
                }
            }

            return parametersFinal;
        },

        finalizeCallback() {
            // Close confirmation dialog
            this.closeChildDialog('finalize');

            // Change current status to finalized
            this.selectedProcessStatus = this.processStatusOptions
                                         [this.processStatusOptions.length - 1]
                                         .name;

            // Save or update - this function also handles UI (snackbars/dialogues)
            this.saveDepartures(true);  // 'true' flag must be passed to function so it doesnt loop
        },

        deleteCallback() {
            // Close all dialogues - view and delete
            this.closeDialog();

            // Show success snackbar
            this.$store.dispatch('UI/showSnackbar', {
                message: 'Registo de saída apagado com sucesso.',
                color: 'success'
            });
        },

        async sendBankrollReturnRequest() {
            // Validate there is a departure selected and that the form's stage1 is valid
            if (
                (! this.selectedDeparture && ! this.$refs.formStage1.validate()) ||
                ! this.$refs.formStage1.validate()
               )
            {
                // Show warning snackbar
                this.$store.dispatch('UI/showSnackbar', {
                    message: 'Por favor preencha a informação assinalada para possibilitar o envio da notificação.',
                    color: 'warning'
                });

                // Direct user to stage 1
                this.formStage = 1;
                return;
            }

            // Validate stage 2 is valid
            if ( ! this.$refs.formStage2.validate() ) {
                // Show warning snackbar
                this.$store.dispatch('UI/showSnackbar', {
                    message: 'Por favor preencha a informação assinalada para possibilitar o envio da notificação.',
                    color: 'warning'
                });

                return;
            }

            this.returnRequest = true;
            this.returnRequestTimestamp = new Date();

            // Save departure (necessary because 'departures/sendNotificationValueReturnDepartures' needs ID)
            await this.saveDepartures(false, true);

            // Formulate request parameters
            //let params = this.formulateParameters();

            // Send notification
            let params = {};
            await this.$store.dispatch('departures/sendNotificationValueReturnDepartures', params);

            // Show success snackbar
            this.$store.dispatch('UI/showSnackbar', {
                message: 'Notificação enviada com sucesso.',
                color: 'success'
            });
        },

        addPairNetworkAmount() {
            this.PairsNetworkAmount.push({
                network: null,
                amount: null,
                currency: null
            });
        },

        updatePairsNetworkAmount(index, event) {
            // Update pair with new info
            this.PairsNetworkAmount[index] = event;

            // Recalculate contact form values
            this.recalculateCurrencySum();
        },

        removePairsNetworkAmount(index) {
            // Remove pair
            if (this.PairsNetworkAmount[index])
                this.$delete(this.PairsNetworkAmount, index); //this.PairsNetworkAmount.splice(index, 1);
        
            // Recalculate
            this.recalculateCurrencySum();
        },

        recalculateCurrencySum() {
            this.calculateCurrencySum('USD');
            this.calculateCurrencySum('EUR');
            this.calculateTotal();
        },

        calculateCurrencySum(currencyToSum) {
            if ( ! this.PairsNetworkAmount )
                return 0;
            
            let inputs = this.PairsNetworkAmount.filter(e => e.currency == currencyToSum);
            let sum = 0;

            // Sum the amount of all networks
            if (inputs) {
                for (let key in inputs) {
                    if ( ! isNaN(inputs[key].amount) )
                        sum += parseFloat(inputs[key].amount);
                }
            }

            // Set bankroll currency sum
            this.bankrollSum[currencyToSum] = sum;

            // Sum the makeup
            if (currencyToSum == 'USD') {
                if (this.makeupUSD)
                    sum += parseFloat(this.makeupUSD);
            }

            // Set notification currency sum (bankroll + makeup)
            this.notificationSum[currencyToSum] = sum;
        },

        calculateTotal() {
            // Bankroll total
            if ( isNaN(this.bankrollSum.USD) || isNaN(this.bankrollSum.EUR) ) {
                this.bankrollSum.total = 0;
            } else {
                this.bankrollSum.total = (parseFloat(this.bankrollSum.USD) * parseFloat(this.rateUsdEur)) + parseFloat(this.bankrollSum.EUR);
            }

            // Notification total
            if ( isNaN(this.notificationSum.USD) || isNaN(this.notificationSum.EUR) ) {
                this.notificationSum.total = 0;
            } else {
                this.notificationSum.total = (parseFloat(this.notificationSum.USD) * parseFloat(this.rateUsdEur)) + parseFloat(this.notificationSum.EUR);
            }            
        },

        closeDialog() {
            // Return all fields to initial state
            Object.assign(this.$data, this.initialState());

            // Reset forms
            this.$refs.formStage1.reset();

            // Close child dialogues
            this.closeChildDialog('edit');
            this.closeChildDialog('delete');

            // Close self
            this.$emit('close-dialog');
        },

        closeChildDialog(dialog) {
            this.dialogues[dialog] = false;
        },
    }
}