import VCurrencyField from './VCurrencyField/VCurrencyField.vue';

export default {
	name: 'InputFinancial',

	components: {
        VCurrencyField
    },

	props: {
		/**
       	 * Used to pass the 'v-bind' props to the <v-currency-field> component.
    	 */
		vbind: {
			type: Object,
			default: function () {
				return {
					label: 'Montante',
					outlined: true,
				}
			}
		},
		value: {
			type: Number,
			required: false,
			default: 0,
		},
		autoDecimalMode: {
			type: Boolean,
			required: false,
			default: false,
		},
		allowNegative: {
			type: Boolean,
			required: false,
			default: false,
		},
		precision: {
			type: Number,
			required: false,
			default: 2,
		},
		locale: {
			type: String,
			required: false,
			default: 'pt-PT',
		},
		currency: {
			type: String,
			required: false,
			default: null,
		},
	},

	data() {
		return this.initialState();
	},

	computed: {
        computedValue: {
            get: function() {
                return this.valueFinal;
            },
            set: function(newValue) {
                this.valueFinal = newValue;
                return newValue;
            },
		},

		options: function () {
			return {
				locale: this.locale,
				precision: this.precision,
				autoDecimalMode: this.autoDecimalMode,
				allowNegative: this.allowNegative,
				currency: this.currency,
				distractionFree: {
					hideNegligibleDecimalDigits: true,
					hideCurrencySymbol: false,
					hideGroupingSymbol: false
				},
			}
		},
		
    },

    watch: {
		value (value) {
			this.setValue(value);
		}
	},

    mounted () {
		this.setValue(this.value);
	},

	methods: {
		initialState() {
			return {
				valueFinal: 0,
			};
		},

		// https://vue-currency-input-v1.netlify.app/api/#setvalue
		setValue (value) {
			this.valueFinal = value;
			this.$ci.setValue(this.$refs.input, value);
		},

		emitChange() {
			this.$emit('input', this.valueFinal);
		},

		reset() {
			this.$refs.input.reset();
			this.setValue(null);
		},

		resetValidation() {
			this.$refs.input.resetValidation();
		},
	},
};