import AuthMixin from '@/mixins/Auth';
import Hero from '@/components/Hero/Hero.vue';
import CardMainActions from '@/components/Rail/CardMainActions/CardMainActions.vue';
import TabSlider from '@/components/TabSlider/TabSlider.vue';

export default {
    name: 'Rail-Admin',

    mixins: [
        AuthMixin,
    ],

    components: {
        Hero,
        CardMainActions,
        TabSlider,
    },

    async created() {
        await this.allowOnlyRoles(['rail_admin', 'rail_manager']);
    },
}