var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex"},[_c('v-form',{ref:"formTransferTypeDropdown"},[_c('v-select',{attrs:{"items":_vm.data,"item-value":"id","label":"Tipos de transferência","clearable":"","hide-details":"","return-object":"","multiple":"","solo":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"ripple":false,"input-value":active}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('div',{staticClass:"d-flex align-center"},[_c('span',[_vm._v(" "+_vm._s(item.source)+" "),_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(item.source != 'Desconhecido' ),expression:"item.source != 'Desconhecido' "}]},[_vm._v("mdi-arrow-right")]),_vm._v(" "+_vm._s(item.target)+" ")],1)])])],1)],1)]}}],null,true)},'v-list-item',attrs,false),on))]}},{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('div',[(index === 0)?_c('v-chip',[_c('span',[_vm._v(" "+_vm._s(item.source)+" "),_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(item.source != 'Desconhecido' ),expression:"item.source != 'Desconhecido' "}]},[_vm._v("mdi-arrow-right")]),_vm._v(" "+_vm._s(item.target)+" ")],1)]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" ( +"+_vm._s(_vm.value.length - 1)+" ) ")]):_vm._e()],1)]}}]),model:{value:(_vm.computedValue),callback:function ($$v) {_vm.computedValue=$$v},expression:"computedValue"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }