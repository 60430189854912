import Vue from 'vue';
export default {
    name: 'GroupImageBadgeDropdown',

    props: {
        value: { type: [Object,String], required: false, default: () => { } },
        hideDetails: { type: Boolean, required: false, default: false },
        outlined: { type: Boolean, required: false, default: false },
        solo: { type: Boolean, required: false, default: false },
        hint: { type: Boolean, required: false, default: false },
        multiple: { type: Boolean, required: false, default: false },
        disabled: { type: Boolean, required: false, default: false },
    },

    computed: {
        computedValue: {
            get: function () {
               return this.getImageBadgePng(this.groupImageBadge);
            },
            set: function (newValue) {
                this.groupImageBadge = this.getImageBadgePng(newValue)
                this.emitChange();
            }
        },
    },

    watch: {
        value: {
            handler() {
                this.computedValue = this.value;
            }   
        }
    },

    data() {
        return this.initialState();
    },

    created() {
        this.computedValue = this.isPng(`${this.value}`) ? this.value : this.getImageBadgePng(this.value);
    },

    methods: {
        initialState() {
            return {
                groupImageBadge: null,
            }
        },
        
        emitChange() {
            this.$emit('input', this.computedValue);
            this.$emit('change', this.computedValue);
        },

        reset() {
            this.groupImageBadge = null;
            if (this.$refs.formGroupImageBadgeDropdown) {
                this.$refs.formGroupImageBadgeDropdown.reset();
            }
         },

         getImageBadgePng(item){
            const badges = Vue.prototype.$group_badges;

            if (typeof item === 'string' && item.endsWith('.png')) {
                const badge = badges.find(b => b.image === item);

                if (badge) {
                    return badge.image;
                }
            }

            if (typeof item === 'string') {
              const badge = badges.find(b => b.name === item);

              if (badge) {
                return badge.image;
              }
            }

            return 'default.png';
        },

        isPng(value) {
            return value.includes('.png');
        },
    }
}