import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth';

import NicknamesSingleAdmin from '@/views/Nicknames-Single/Admin/Nicknames-Single-Admin.vue';
import NicknamesSingleUser from '@/views/Nicknames-Single/User/Nicknames-Single-User.vue';
import Loading from '@/views/Loading.vue';

export default {
    name: 'Nicknames-Single',
  
    mixins: [
      AuthMixin,
    ],

    components: {
      NicknamesSingleAdmin, 
      NicknamesSingleUser,
      Loading,
    },

    computed: {
      ...mapGetters({
        userType: 'auth/userType',
        userRoles: 'auth/userRoles',
        userRolesLoading: 'auth/userRolesLoading',
      }),
  
      componentToShow() {
        let component = 'Loading';
  
        if (! this.userRolesLoading) {
          component = this.userIsManager
                      ? 'NicknamesSingleAdmin'
                      : 'NicknamesSingleUser';
        }

        return component;
      },
    }
}