var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',[_c('v-form',{ref:"formSessionNicknames"},[_c('v-data-table',{attrs:{"id":"sessionNicknamesList","headers":_vm.sessionNicknamesListHeaders,"items":[_vm.selectedSessionNickname],"items-per-page":_vm.dataTable.itemsPerPage,"height":"100%","fixed-header":"","dense":"","hide-default-header":"","hide-default-footer":"","caption":"","calculate-widths":"","headers-length":6},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var headers = ref.headers;
return [_c('tr',[_c('td',{style:('width:' + headers[0].width)},[_c('v-select',{staticClass:"d-none",model:{value:(item.nickname),callback:function ($$v) {_vm.$set(item, "nickname", $$v)},expression:"item.nickname"}}),_c('NicknameDropdown',_vm._b({staticClass:"my-3",attrs:{"only-nicknames":"","excluded-networks":_vm.handleExcludedNetworks,"rules":_vm.rules.nickname},on:{"change":function($event){return _vm.$emit('updateSelectedSessionNickname', _vm.index)}},model:{value:(item.nickname),callback:function ($$v) {_vm.$set(item, "nickname", $$v)},expression:"item.nickname"}},'NicknameDropdown',_vm.nicknameSourceComputed,false))],1),_c('td',{style:('width:' + headers[1].width)},[_c('input-financial',{ref:"inputFinancial",style:({ color: _vm.valueColorFinal() }),attrs:{"allow-negative":"","precision":_vm.handleDecimalNumbers(item.nickname),"vbind":{
                label:'Saldo Final',
                outlined: true,
                disabled: false,
                solo: false,
                dense: true,
                flat: false,
                backgroundColor: 'transparent',
                hideDetails: true,
                readonly: _vm.action == 'view',
                rules: _vm.rules.balance,
              }},on:{"input":function($event){return _vm.$emit('updateSelectedSessionNickname', _vm.index)}},model:{value:(item.balance),callback:function ($$v) {_vm.$set(item, "balance", _vm._n($$v))},expression:"item.balance"}})],1),_c('td',{style:('width:' + headers[2].width)},[_c('input-financial',{ref:"rakebackBalance",style:({ color: _vm.valueColorFinal() }),attrs:{"precision":2,"allow-negative":_vm.allowNegativeInputRakeback,"vbind":{
                label:'Rakeback - Challenge',
                outlined: true,
                disabled: false,
                solo: false,
                dense: true,
                flat: false,
                backgroundColor: 'transparent',
                hideDetails: true,
                readonly: _vm.action == 'view',
                rules: _vm.rules.rakebackBalance,
              }},on:{"input":function($event){return _vm.$emit('updateSelectedSessionNickname', _vm.index)}},model:{value:(item.rakebackBalance),callback:function ($$v) {_vm.$set(item, "rakebackBalance", _vm._n($$v))},expression:"item.rakebackBalance"}})],1),_c('td',{style:('width:' + headers[3].width)},[_c('input-financial',{ref:"rakebackTickets",style:({ color: _vm.valueColorFinal() }),attrs:{"precision":2,"allow-negative":_vm.allowNegativeInputRakeback,"vbind":{
                label:'Rakeback - Tickets',
                outlined: true,
                disabled: false,
                solo: false,
                dense: true,
                flat: false,
                backgroundColor: 'transparent',
                hideDetails: true,
                readonly: _vm.action == 'view',
                rules: _vm.rules.rakebackTickets,
              }},on:{"input":function($event){return _vm.$emit('updateSelectedSessionNickname', _vm.index)}},model:{value:(item.rakebackTickets),callback:function ($$v) {_vm.$set(item, "rakebackTickets", _vm._n($$v))},expression:"item.rakebackTickets"}})],1),_c('td',{style:('width:' + headers[4].width)},[_c('input-financial',{ref:"rakebackCoins",style:({ color: _vm.valueColorFinal() }),attrs:{"precision":0,"allow-negative":_vm.allowNegativeInputRakeback,"vbind":{
                label:'Rakeback - Coins',
                outlined: true,
                disabled: false,
                solo: false,
                dense: true,
                flat: false,
                backgroundColor: 'transparent',
                hideDetails: true,
                readonly: _vm.action == 'view',
                rules: _vm.rules.rakebackCoins,
              }},on:{"input":function($event){return _vm.$emit('updateSelectedSessionNickname', _vm.index)}},model:{value:(item.rakebackCoins),callback:function ($$v) {_vm.$set(item, "rakebackCoins", _vm._n($$v))},expression:"item.rakebackCoins"}})],1),_c('td',{staticClass:"text-center",style:('width:' + headers[5].width)},[_c('v-btn',{attrs:{"icon":"","disabled":_vm.isDeleteDisabled},on:{"click":function($event){return _vm.removeSelectedSessionNickname(_vm.index)}}},[_c('v-icon',[_vm._v("mdi-delete-outline")])],1)],1)])]}}])})],1),_c('v-form',{ref:"formGameSpins"},[_c('v-data-table',{style:({'max-height': '230px', 'overflow':'auto'}),attrs:{"id":"spinsList","headers":_vm.spinsListHeaders,"items":_vm.spinsListComputed,"items-per-page":_vm.dataTable.itemsPerPage,"height":"100%","fixed-header":"","dense":"","hide-default-footer":"","caption":"","calculate-widths":"","headers-length":6},scopedSlots:_vm._u([{key:"item.stake",fn:function(ref){
              var item = ref.item;
return [_c('v-select',{attrs:{"items":_vm.stakeTypes,"rules":_vm.rules.stake,"item-value":"value","item-text":"name","label":"","required":"","outlined":"","hide-details":"","dense":""},on:{"change":function($event){return _vm.$emit('updateSelectedSessionNickname', _vm.index)}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
              var item = ref.item;
return [_c('span',{staticClass:"d-flex justify-end",staticStyle:{"width":"100%"}},[_vm._v(" "+_vm._s(_vm.getCurrencyIcon(item))+" "+_vm._s(item.name))])]}},{key:"selection",fn:function(ref){
              var item = ref.item;
return [_c('span',{staticClass:"d-flex justify-end",staticStyle:{"width":"100%"}},[_vm._v(_vm._s(_vm.getCurrencyIcon(item))+" "+_vm._s(item.name))])]}}],null,true),model:{value:(item.stake),callback:function ($$v) {_vm.$set(item, "stake", $$v)},expression:"item.stake"}})]}},{key:"item.gameType",fn:function(ref){
              var item = ref.item;
return [_c('v-btn-toggle',{staticClass:"d-flex flex-row my-3",attrs:{"mandatory":"","color":"success accent-3"},on:{"change":function($event){return _vm.$emit('updateSelectedSessionNickname', _vm.index)}},model:{value:(item.gameType),callback:function ($$v) {_vm.$set(item, "gameType", $$v)},expression:"item.gameType"}},[_vm._l((_vm.gameTypes),function(itemType){return [_c('v-btn',{key:itemType.value,staticClass:"flex-grow-1",attrs:{"value":itemType.value}},[_vm._v(" "+_vm._s(itemType.name)+" "),_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(item.gameType == itemType.value),expression:"item.gameType == itemType.value"}],staticClass:"ml-2",attrs:{"color":"success"}},[_vm._v(" mdi-check ")])],1)]})],2)]}},{key:"item.numberEvents",fn:function(ref){
              var item = ref.item;
return [_c('input-financial',{ref:"numberEvents",style:({ color: _vm.valueColorFinal() }),attrs:{"precision":0,"allow-negative":_vm.allowNegativeInputChipNetWon,"vbind":{
            label:'',
            outlined: true,
            disabled: false,
            solo: false,
            dense: true,
            flat: false,
            backgroundColor: 'transparent',
            hideDetails: true,
            readonly: _vm.action == 'view',
            rules: _vm.rules.numberEvents,
          }},on:{"input":function($event){return _vm.$emit('updateSelectedSessionNickname', _vm.index)}},model:{value:(item.numberEvents),callback:function ($$v) {_vm.$set(item, "numberEvents", _vm._n($$v))},expression:"item.numberEvents"}})]}},{key:"item.chipNetWon",fn:function(ref){
          var item = ref.item;
return [_c('input-financial',{ref:"chipNetWon",style:({ color: _vm.valueColorFinal() }),attrs:{"precision":0,"allow-negative":"","vbind":{
            label:'',
            outlined: true,
            disabled: false,
            solo: false,
            dense: true,
            flat: false,
            backgroundColor: 'transparent',
            hideDetails: true,
            readonly: _vm.action == 'view',
            rules: _vm.rules.chipNetWon,
          }},on:{"input":function($event){return _vm.$emit('updateSelectedSessionNickname', _vm.index)}},model:{value:(item.chipNetWon),callback:function ($$v) {_vm.$set(item, "chipNetWon", _vm._n($$v))},expression:"item.chipNetWon"}})]}},{key:"item.chipEv",fn:function(ref){
          var item = ref.item;
return [_c('input-financial',{ref:"chipEv",style:({ color: _vm.valueColorFinal() }),attrs:{"precision":0,"allow-negative":"","vbind":{
            label:'',
            outlined: true,
            disabled: false,
            solo: false,
            dense: true,
            flat: false,
            backgroundColor: 'transparent',
            hideDetails: true,
            readonly: _vm.action == 'view',
            rules: _vm.rules.chipEv,
          }},on:{"input":function($event){return _vm.$emit('updateSelectedSessionNickname', _vm.index)}},model:{value:(item.chipEv),callback:function ($$v) {_vm.$set(item, "chipEv", _vm._n($$v))},expression:"item.chipEv"}})]}},{key:"item.action",fn:function(ref){
          var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","disabled":_vm.handleDisableDeleteSpinsBtn()},on:{"click":function($event){return _vm.removeSpinEntry(item)}}},[_c('v-icon',[_vm._v("mdi-delete-outline")])],1)]}}])})],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"block":"","raised":"","text":"","tile":""},on:{"click":function($event){return _vm.addNewSpinEntry()}}},on),[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-plus-circle-outline ")]),_c('span',[_vm._v("Adicionar novo stake")])],1)]}}])},[_c('span',[_vm._v("Carrega aqui se jogaste mais do que 1 tipo de Stake")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }