var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.subtitle)?_c('div',{staticClass:"d-flex align-center text-left justify-space-between"},[_c(_vm.componentToShow,{tag:"component",attrs:{"to":_vm.id ? ("/" + _vm.redirectTo + "/" + (+ _vm.id)) : ("/" + _vm.redirectTo + "/" + (+ _vm.networkId) + "/")}},[_c('div',{staticClass:"d-flex align-center"},[(_vm.showIcon)?_c('img',{staticClass:"mr-2",attrs:{"src":_vm.networkImage}}):_vm._e(),(!_vm.vChipCases)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"d-flex align-center justify-space-between"},'span',attrs,false),on),[_c('div',{staticClass:"d-flex flex-column"},[_c('span',[_vm._v(" "+_vm._s(_vm.networkName)+" ")]),_c('span',{staticClass:"caption text--secondary custom-name"},[_vm._v(" "+_vm._s(_vm.name ? _vm.name : _vm.networkName)+" ")])])])]}}],null,false,1399187369)},[_c('span',[_vm._v(_vm._s(_vm.name ? _vm.name : _vm.networkName))])]):(_vm.vChipCases !== null)?_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-flex flex-column"},[_c('span',[_vm._v(" "+_vm._s(_vm.networkName)+" ")]),_c('span',{staticClass:"caption text--secondary custom-name"},[_vm._v(" "+_vm._s(_vm.name ? _vm.name : _vm.networkName)+" ")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({style:({'backgroundColor': _vm.vChipCases.color, 'borderRadius': '5px', 'width': '30px'}),attrs:{"icon":"","tile":"","x-small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":"","color":_vm.vChipCases.iconColor}},[_vm._v(" "+_vm._s(_vm.vChipCases.icon)+" ")])],1)]}}],null,false,659801936)},[_c('span',[_vm._v(_vm._s(_vm.vChipCases.name))])])],1):_c('span',[_vm._v(" "+_vm._s(_vm.name ? _vm.name : _vm.networkName))])],1)]),(_vm.networkId != _vm.$rail_external_wallet_id && _vm.copyToClipboard && _vm.copyToClipboard)?_c('ButtonCopyToClipboard',{attrs:{"name":_vm.name ? _vm.name : _vm.networkName,"network":_vm.networkId,"description":_vm.description,"is-management":_vm.isManagement}}):_vm._e()],1):_c('div',{staticClass:"d-flex align-center text-left justify-space-between"},[_c(_vm.componentToShow,{tag:"component",attrs:{"to":_vm.id ? ("/" + _vm.redirectTo + "/" + (+ _vm.id)) : ("/" + _vm.redirectTo + "/" + (+ _vm.networkId) + "/")}},[_c('div',{staticClass:"d-flex align-center"},[_c('div',[_c('v-img',{staticClass:"mr-2 my-auto",attrs:{"src":_vm.networkImage}})],1),(_vm.vChipCases == null)?_c('span',[_vm._v(" "+_vm._s(_vm.name ? _vm.name : _vm.networkName))]):_c('div',{staticClass:"d-flex align-center justify-space-between"},[_c('div',{staticClass:"d-flex custom-name"},[_c('span',[_vm._v(" "+_vm._s(_vm.networkName)+" ")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({style:({'backgroundColor': _vm.vChipCases.color, 'borderRadius': '5px', 'width': '30px'}),attrs:{"icon":"","tile":"","x-small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-small":"","color":_vm.vChipCases.iconColor}},[_vm._v(" "+_vm._s(_vm.vChipCases.icon)+" ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.vChipCases.name))])])],1)])]),(_vm.networkId != _vm.$rail_external_wallet_id && _vm.copyToClipboard && _vm.copyToClipboard)?_c('ButtonCopyToClipboard',{attrs:{"name":_vm.name ? _vm.name : _vm.networkName,"network":_vm.networkId,"description":_vm.description,"is-management":_vm.isManagement}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }