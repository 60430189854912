import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';

Vue.use(Vuex);

const state = {
    departures: [],
    selectedDeparture: null,
    reasonsDeparture: []
};

const getters = {
    contracts: state => state.departures
};

const mutations = {
    setContracts: (state, payload) => { state.departures = payload; }
};

const actions = {
    async getContracts({ commit }) {
        await axios
        .get(Vue.prototype.$url_api + 'v1/contracts.php')
        .then(result => {
            commit('setContracts', result.data.data);
        })
        .catch(err => {
            //console.log(err);
        });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};