import Vue from 'vue';
import { mapGetters } from 'vuex';
import DataMixin from '@/mixins/Data.vue';
import AuthMixin from '@/mixins/Auth.vue';
import UserMixin from '@/mixins/User.vue';
import RailMixin from '@/mixins/Rail.vue';

export default {
	name: 'DialogViewSessionSpins',

	mixins: [
		DataMixin,
		AuthMixin,
		UserMixin,
		RailMixin,
	],

	props: {
		isOpen: { type: Boolean, required: true, default: false },
	},

	computed: {
		...mapGetters({
			selectedSession: 'railSessions/selectedSession',
			themeDarkMode: 'UI/themeDarkMode',
		}),

		tableDataComputed: {
			get: function () {
			  return this.table.data;
			},
			set: function (newValue) {
				this.table.data = newValue;
			}
		},

	},

	watch: {
		// When form is reopened, reset it and fill date
		isOpen: function (newVal, oldVal) {
			if (newVal === true) {
				this.onOpen();
			}
		},
	},

	data() {
		return this.initialState();
	},

	async created() {

	},
	
	methods: {
		initialState() {
			return {
				table: {
					headers: [
						{ text: 'Nickname', value: 'nickname', groupable: true, },
						{ text: 'Saldo', value: 'balance', groupable: false,  },
						{ text: 'Rakeback - Challenge', value: 'rakebackBalance', groupable: false,  },
						{ text: 'Rakeback - Tickets', value: 'rakebackTickets', groupable: false,  },
						{ text: 'Rakeback - Stars Coins', value: 'rakebackCoins', groupable: false,  },
					],
					metadata: {
						headers: [
							{ text: '', value: 'action', sortable: false, width: '2.9%', align: 'center' },
							{ text: 'Stake', value: 'stake', sortable: false, width: '17.5%' },
							{ text: 'Tipo de jogo', value: 'gameType', sortable: false, width: '19.2%' },
							{ text: 'N.º de Spins', value: 'numberEvents', sortable: true, width: '20.2%'  },
							{ text: 'Chips Net won', value: 'chipNetWon', sortable: true, width: '18.8%' },
							{ text: 'Chip EV', value: 'chipEv', sortable: false,   },
							{ text: '', value: 'action', sortable: false,  align: 'center' },
						],
					},
					data: [],
				},
				form: {
					submitting: false,
					selectedSessionLoading: false,
				}
			}
		},

		async onOpen() {
			await this.getSession();
			this.populateFields();
		},

		populateFields() {
			if (this.selectedSession && ! this._.isEmpty(this.selectedSession.sessionNicknames)) {
				this.tableDataComputed = this.selectedSession.sessionNicknames;	
			}
		},

		async getSession() {
			if (this.selectedSession)
			await this.$store.dispatch('railSessions/getSessionById', this.selectedSession.id);
		},

		closeDialog() {
			this.$store.commit('railSessions/setSelected', null);
			this.$emit('close-dialog');
		},

		pnlIsNull() {
			return this.selectedSession.pnl.EUR.value === 0;
		},

		triggerCopyToClipboard() {
            let url = window.location.origin + '/tracker' + Vue.prototype.$tab_slider.tabs.sessions + this.selectedSession.id;
            this.copyToClipboard(url);

            // Show snackbar
            this.$store.dispatch('UI/showSnackbar', {
              message: "🔗 Link da sessão copiado com sucesso.",
              color: 'success'
			});
		},
		
		handleRakebackCoins(item) {
			let output = 0;
			if (item) {
				let coins = '( ' + item + ' coins )'
				output = (item / 100).toFixed(2) + ' ' + coins;
			}

			return output;
		},

	},
};
