import { mapGetters } from "vuex";
import CardBankrollReductionStage1 from "@/components/Rail/CardBankrollReduction/Stage1/CardBankrollReduction.vue";
import CardBankrollReductionStage2 from "@/components/Rail/CardBankrollReduction/Stage2/CardBankrollReduction.vue";
import AuthMixin from "@/mixins/Auth.vue";

export default {
	name: "CardBankrollReduction",

	components: {
		CardBankrollReductionStage1,
		CardBankrollReductionStage2,
	},

	mixins: [
		AuthMixin
		],

	computed: {
		...mapGetters({
			selectedRequest: "railRequests/selectedRequest",
		}),
		
		componentToShow() {
			let pendingUserSide = this.selectedRequest && this.selectedRequest.phase == 1 && this.selectedRequest.status == 3;
			let pendingAdminSide = this.selectedRequest && this.selectedRequest.phase == 2;

			let pageToShow =
			pendingUserSide || pendingAdminSide
					? "CardBankrollReductionStage2"
					: "CardBankrollReductionStage1";

			return pageToShow;
		},
	},
	
	methods: {
		/** This CardBankrollReduction.vue is focus on routing/component to show based on user role
		 *  When Bankroll Reduction view (current action)  by clicking submit button it triggers this submit() present in this file
		 *  This submit() triggers the submit() in child component with ref formBankrollReductionChild
		 */
		submit() {
			this.$refs.formBankrollReductionRouting.submit();
		},
	},
};