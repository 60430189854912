import Vue from 'vue';
import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth';
import Hero from '@/components/Hero/Hero.vue';
import Snackbar from '@/components/Snackbar';
import TableNetworks from '@/components/Tables/TableNetworks/TableNetworks.vue';
import DialogCreateNetwork from '@/components/Dialog/DialogCreateNetwork/DialogCreateNetwork.vue';
import TableAudits from '@/components/Tables/TableAudits/TableAudits.vue';
import DragAndDropInputFile from '@/components/DragAndDropInputFile/DragAndDropInputFile.vue';
import EmptyStateNoData from '@/components/EmptyStateNoData';
import LoadingSpinner from '@/components/LoadingSpinner/LoadingSpinner.vue';
import DatePicker from '@/components/DatePicker/DatePicker.vue';
import UserDropdown from '@/components/Rail/UserDropdown/UserDropdown.vue';
import AlertError from '@/components/Alerts/AlertError/AlertError.vue';
import NetworkDropdown from '@/components/Rail/NetworkDropdown/NetworkDropdown.vue';

export default {
    name: 'Audit-Admin',

    components: {
        Hero,
        Snackbar,
        TableNetworks,
        DialogCreateNetwork,
        TableAudits,
        DragAndDropInputFile,
        EmptyStateNoData,
        LoadingSpinner,
        DatePicker,
        UserDropdown,
        AlertError,
        NetworkDropdown
    },

    computed: {
		...mapGetters({
			humanError: 'audits/humanError',
            error: 'audits/error',
            uploadError: 'audits/uploadError',
            uploadHumanError: 'audits/uploadHumanError', 
			auditEligibilityLoading: 'audits/auditEligibilityLoading',
            auditEligibility: 'audits/auditEligibility',
            selectedAudit: 'audits/selectedAudit',
            userTrackerId: 'auth/userTrackerId',
            heroDatePickerValue: 'UI/heroDatePickerValue',
		}),

		/**
         * form.filename passed through prop to DragAndDropInputFile
         */
		computedFilename: {
			get: function () {
				return this.form.filename;
			},
			set: function (newValue) {
                this.form.filename = newValue;
			}
		},
    },
    
    watch: {
        'form.auditType': function () {
            if (this.form.user && this.form.auditType) {
                this.checkEligibility(this.form.auditType.id, this.form.user.id);
                this.clearUploadErrors();
            }
        },
        'form.user': function () {
            if (this.form.user && this.form.auditType) {
                this.checkEligibility(this.form.auditType.id, this.form.user.id);
                this.clearUploadErrors();
            }
        },
        'form.filename': function () {
            this.clearUploadErrors();
        },
    },

    mixins: [
        AuthMixin,
    ],

    data() {
        return this.initialState();
    },

    methods: {
        initialState() {
            return {
                createAuditBtn: false,
                submitting: false,
                form: {
					filename: [],
					auditType: null,
                    user: null,
                    status: null,
                    date: this.$moment().format('YYYY-MM-DD')
                },
                statusTypes: [
                    { value: Vue.prototype.$audits.status.REQUESTED, text: 'Pedido submetido' },
                    { value: Vue.prototype.$audits.status.PENDING_REVIEW, text: 'Revisão pendente' },
                    { value: Vue.prototype.$audits.status.APPROVED, text: 'Aprovado' },
                    { value: Vue.prototype.$audits.status.REJECTED, text: 'Rejeitado' },
                ],
                rules: {
                    user: [(v) => !!v || 'Por favor introduza um jogador'],
                    auditType: [(v) => !!v || 'Por favor introduza um tipo de ficheiro'],
                    status: [(v) => !!v || 'Por favor introduza um estado']
                }
            }
        },

        checkEligibility(networkID, userID) {
            let payload = {
                params: {
                    network: networkID,
                    user_target: userID
                }
            }
            this.$store.dispatch('audits/checkEligibility', payload);
        },

        async uploadAudit() {

            if (!this.$refs.formUploadAudit.validate()) {
                this.$refs.UserDropdown.$refs.formUserDropdown.validate();
                this.$refs.NetworkDropdown.$refs.formNetworkDropdown.validate();
                return false;
            }

            if (this._.isEmpty(this.form.filename)) {
                this.$store.commit('audits/setUploadHumanError', 'Seleciona um ficheiro para poderes submeter o audit');
                this.$store.commit('audits/setUploadError', true);
                return false;
            }

            this.clearUploadErrors();
            
            this.submitting = true;

            let payload = new FormData();

            payload.append('network', this.form.auditType.id);
            payload.append('file', this.form.filename[0]);
            payload.append('user', this.form.user.id);
            payload.append('date', this.$moment().format('YYYY-MM-DD'));
            payload.append('status', this.form.status);

            let result = await this.$store.dispatch('audits/uploadAudit', payload);

            this.submitting = false;
            
            if (result === true) {
                this.closeUploadAudit();
                this.$store.dispatch('UI/showSnackbar', {
                    message: 'Audit importado com sucesso!',
                    color: 'success'
                  });

                this.$router.push({ path:'/tools/pokercraft/' + this.selectedAudit.id} ).catch(() => { });
            }
        },

        getDataFromDragAndDropInputFile(payload) {
            this.computedFilename = payload;
        },

        clearInputFields() {
            this.computedFilename = [];
            this.form.auditType = null;
            this.form.status = null;
            this.form.user = null;
        },

        clearUploadErrors() {
            this.$store.commit('audits/setUploadError', null);
            this.$store.commit('audits/setUploadHumanError', null);
        },

        closeUploadAudit() {
            this.createAuditBtn = false;
            this.clearInputFields();
        },

        handleEventFromTableAudits() {
            this.createAuditBtn = true;
            this.$refs.formUploadAudit.resetValidation()
            this.$refs.UserDropdown.$refs.formUserDropdown.resetValidation();
            this.$refs.NetworkDropdown.$refs.formNetworkDropdown.resetValidation();
        }
    }
}
