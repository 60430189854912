import Vue from 'vue';
import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth.vue';
import DataMixin from '@/mixins/Data.vue';
import UserMixin from '@/mixins/User.vue';
import RailMixin from '@/mixins/Rail.vue';
import EmptyStateNoData from '@/components/EmptyStateNoData';
import DialogViewLevelChangeEvent from '@/components/Rail/Dialog/DialogViewLevelChangeEvent/DialogViewLevelChangeEvent.vue';
import DialogConfirmation from '@/components/Rail/Dialog/DialogConfirmation/DialogConfirmation.vue';
import DialogAddLevelChange from '@/components/Dialog/DialogAddLevelChange/DialogAddLevelChange.vue';
import Snackbar from "@/components/Snackbar";

export default {
    name: 'TableLevelChange',

    components: {
        EmptyStateNoData,
        DialogViewLevelChangeEvent,
        DialogConfirmation,
        DialogAddLevelChange,
        Snackbar
    },

    mixins: [
        AuthMixin,
        DataMixin,
        UserMixin,
        RailMixin,
    ],

    props: {
        title: {
            type: String,
            required: false,
            default: 'Subidas/Descidas',
        },
        showTabs: {
            type: Boolean,
            required: false,
            default: true
        },
        userId: { type: [Number, String], required: false, default: null }, // Player single
        statusFilter:{ type: Object, required: false, default: () => {} },
        groupFilter:{ type: Array, required: false, default: () => [] },
    },

    computed: {
        ...mapGetters({
            groupsChanges: 'groups/groupsChanges',
            pendingGroupsChanges: 'groups/pendingGroupsChanges',
            finalizedGroupsChanges: 'groups/finalizedGroupsChanges',

            groupsChangesTotal: 'groups/groupsChangesTotal',
            pendingGroupsChangesTotal: 'groups/pendingGroupsChangesTotal',
            finalizedGroupsChangesTotal: 'groups/finalizedGroupsChangesTotal',
            
            groupsChangesLoading: 'groups/groupsChangesLoading',
            pendingGroupsChangesLoading: 'groups/pendingGroupsChangesLoading',
            finalizedGroupsChangesLoading: 'groups/finalizedGroupsChangesLoading',

            selectedGroupChange: 'groups/selectedGroupChange',
            themeDarkMode: 'UI/themeDarkMode',
            heroDatePickerValue: 'UI/heroDatePickerValue',
            error: 'groups/error',
            humanError: 'groups/humanError',
            usersAll: 'users/usersAllActive',
            teamFilter: 'teams/teamFilter',
            selectedUser: 'users/selectedUser',
        }),

        optionsComputed: {
            get: function () {
                return this.dataTable.options;
            },
            set: function (newValue) {
                if (!this._.isEqual(this.dataTable.options, newValue)) {
                    this.dataTable.options = newValue;
                }
            }
        },

        tableHeadersComputed: function () {

            let groupPreviousName = 'Nível anterior';
            let groupNextName = 'Novo nível';

            let headers = {
                date: { text: 'Data', value: 'date', sortable: false },
                player: { text: 'Jogador', value: 'player', sortable: false },
                group: { text: groupPreviousName, value: 'group', sortable: false },
                direction: { text: 'Sentido', value: 'direction', sortable: false },
                groupNew: { text: groupNextName, value: 'groupNew', sortable: false },
                reason: { text: 'Motivo', value: 'reason', sortable: false },
                status: { text: 'Estado', value: 'status', sortable: false },
                action: { text: 'Ação', value: 'action', sortable: false },
            }

            if (this.$route.name == 'Evolução do Jogador') {
                return [
                    headers.date,
                    headers.group,
                    headers.direction,
                    headers.groupNew,
                    headers.reason,
                    headers.status,
                    headers.action
                ]
            }

            if(this.userIsPlayer) {
                return [
                    headers.date,
                    headers.group,
                    headers.direction,
                    headers.groupNew,
                    headers.reason,
                    headers.action
                ]
            }

            // default header
            return [
                headers.date,
                headers.player,
                headers.group,
                headers.direction,
                headers.groupNew,
                headers.reason,
                headers.status,
                headers.action
            ]
        },

        // Manages data shown in TableLevelChange tabs
        tableDataComputed: function () {
            let output;

            if (!this.showTabs) {
                return this.groupsChanges;
            }

            // Filter data based on current tab
            switch (this.tabs.current) {
                case 0:
                    output = this.pendingGroupsChanges;
                    break;
                case 1:
                    output = this.finalizedGroupsChanges;
                    break;
                case 2:
                    output = this.groupsChanges;
                    break;
                default:
                    output = this.groupsChanges;
                    break;
            };

            return output;
        },

        // Manages data shown in TableLevelChange tabs
        tableDataTotalComputed: function () {
            let output;

            if (!this.showTabs) {
                return this.groupsChangesTotal;
            }

            // Filter data based on current tab
            switch (this.tabs.current) {
                case 0:
                    output = this.pendingGroupsChangesTotal;
                    break;
                case 1:
                    output = this.finalizedGroupsChangesTotal;
                    break;
                case 2:
                    output = this.groupsChangesTotal;
                    break;
                default:
                    output = this.groupsChangesTotal;
                    break;
            };

            return output;
        },

        tableDataLoadingComputed: function () {
            let output;

            if (!this.showTabs) {
                return this.groupsChangesLoading;
            }

            // Filter data based on current tab
            switch (this.tabs.current) {
                case 0:
                    output = this.pendingGroupsChangesLoading;
                    break;
                case 1:
                    output = this.finalizedGroupsChangesLoading;
                    break;
                case 2:
                    output = this.groupsChangesLoading;
                    break;
                default:
                    output = this.groupsChangesLoading;
                    break;
            };

            return output;
        },

        emptyStateMessage: function () {
            let output = 'Não existem registos de subidas ou descidas.';

            if (!this.showTabs) {
                return output;
            }

            switch (this.tabs.current) {
                case 0:
                    output = 'Não existem items com estado pendente';
                    break;
                case 1:
                    output = 'Não existem items respondidos';
                    break;
                case 2:
                    output = 'Não existe histórico de subidas / descidas';
                    break;
                default:
                    output = 'Não existem registos de subidas ou descidas.';
                    break;
            }
            return output;
        },
    },

    watch: {
        heroDatePickerValue: function () {
            this.fetchData();
        },

        // provided by LevelChange view (params to be used in this component)
        groupFilter: {
            handler() {
                this.fetchData();
            },
            deep: true,
        },

        statusFilter: {
            handler() {
                this.fetchData();
            },
            deep: true,
        },

        // options from v-data-table
        optionsComputed: {
            handler() {
                this.fetchData();
            },
            deep: true,
        },

        // used in Player Single view, retrieve only items from userId
        userId: function () {
            this.fetchData();
        },

        'tabs.current': function () {
            this.fetchData();
        }
    },

    data() {
        return this.initialState();
    },

    created() {

        // handle Table tab
        this.tabs.current == this.userIsRailManager && this.showTabs
            ? 0
            : 2;
        
        // if current route name is 'Alteração de nível', triggers openDialogViewLevelChange()
        if (this.$router.currentRoute.name == 'Alteração de nível') {
            // fetches single group change if URL has an id
            this.openDialogViewLevelChange({id: this.$route.params.id})
        }
        
        // fetch data to populate TableLevelChange
        this.fetchData();
    },

    methods: {
        initialState() {
            return {
                fetchLoading: false,
                fetchLoadingSingle: false,
                dialogues: {
                    viewLevelChange: false,
                    confirm: false,
                    createLevelChange: false,
                    submitting: false,
                    confirmation: {
                        title: '',
                        message: '',
                        btn: {
                            name: '',
                            color: '',
                        },
                        action: null,
                    }
                },
                dataTable: {
                    search: null,
                    page: 1,
                    itemsPerPage: 10,
                    options: {
                        page: 1,
                        itemsPerPage: 10,
                        sortBy: ['date'],
                        sortDesc: [false],
                        groupBy: [],
                        groupDesc: [],
                        mustSort: false,
                        multiSort: false
                    },
                    data: [],
                    footerProps: {
                        'items-per-page-options': this.$itemsPerPageOptions,
                    }
                },
                tabs: {
                    current: 0,
                    items: [
                        { name: 'Pendentes', disabled: false },
                        { name: 'Respondidos', disabled: false },
                        { name: 'Todos', disabled: false }
                    ],
                },
            }
        },

        // trigered by v-text-field @input="search()"
        search() {
            setTimeout(() => {
                this.fetchData()
            }, 1000);
        },

        // item active to be used in DialogView
        setActiveItem(item) {
            if (item) {
                this.$store.commit(
                    'groups/setSelectedGroupChange',
                    item
                );
            }
        },

        toggleDialog(dialog) {
            this.dialogues[dialog] = !this.dialogues[dialog];
        },

        async fetchData() {

            if (this.fetchLoading) {
                return false;
            }

            // loading is on
            this.fetchLoading = true;

            // Base payload
            let payload = {
                params: {
                    page: this.dataTable.options.page,
                    itemsPerPage: this.dataTable.options.itemsPerPage,
                }
            };

            // Add user id param
            if (this.userId) {
                payload.params.user = this.userId;
            }

            // Add date params
            if (!this._.isEmpty(this.heroDatePickerValue)) {
                payload.params.dateBegin = this.heroDatePickerValue[0];
                payload.params.dateEnd = this.heroDatePickerValue[1];
            }

            // if team exists and isManagement, Add team parameter to payload.params
            if (this.teamFilter && this.userHasRailAccessDivisionDropdown) {
                payload.params.team = this.teamFilter;
            }

            // Add name parameter to payload.params
            if (!this._.isEmpty(this.dataTable.search)) {
                payload.params.name = this.dataTable.search;
            }

            // add filter by group ID if is not empty
            if (this.groupFilter) {
                this.groupFilter.forEach((element, index) => {
                    return payload.params['group[' + index + ']'] = element;
                })
            }

            // handle tab status parameter
            if (this.tabs.current == 0 && this.showTabs) {
                payload.params['status[' + 0 + ']'] = 1;
            } else if (this.tabs.current == 1 && this.showTabs) {
                payload.params['status[' + 0 + ']'] = 2;
                payload.params['status[' + 1 + ']'] = 3;
            } else {
                delete payload.params.status;
            }
            
            if (!this._.isEmpty(this.statusFilter)) {
                // set tab to "TODOS"
                this.tabs.current = 2;
                payload.params.status = this.statusFilter.value;
            }

            // Add sortBy parameter to payload
            if (!this._.isEmpty(this.dataTable.options.sortBy)) {
                this.dataTable.options.sortBy.forEach((element, index) => {
                    payload.params['sortBy[' + index + ']'] = element;
                })
            }

            // Add sortDesc parameter to payload.params
            if (!this._.isEmpty(this.dataTable.options.sortDesc)) {
                this.dataTable.options.sortDesc.forEach((element, index) => {
                    payload.params['sort[' + index + ']'] = element == false ? 'ASC' : 'DESC';
                })
            }

            await this.$store.dispatch('groups/getGroupsChanges', payload);

            // loading is off
            this.fetchLoading = false;
        },

        handleUserImgCutout(id) {
            let output = null;

            if (!this._.isEmpty(this.usersAll)) {

                output = this.usersAll.find(element => element.id == id);

                if (!this._.isUndefined(output)) {
                    output = output.imgCutout;
                }

            }
            return output;
        },

        handleDirection(direction) {
            let output = {
                name: '',
                icon: '',
                color: ''
            }

            if (direction) {
                output = this._.find(Vue.prototype.$GroupChangeDirection, { key: direction });
            }

            return output;
        },

        handleStatus(status) {
            let output = {
                name: '',
                color: '',
                icon: '',
                textColor: ''
            }

            if (status) {
                output = this._.find(Vue.prototype.$GroupChangeStatus, { key: status });
            }

            return output;
        },

        handleReason(reason) {
            let output = {
                name: '',
                subtitle: '',
            }
            if (reason) {
                output = this._.find(Vue.prototype.$GroupsChangeReason, { key: reason });
            }

            return output;
        },

        changeTabEmptyState() {
            if (this.tabs.current == 0) {
                this.tabs.current = 1;
            }
        },

        openDialogConfirmation(item, action) {

            this.$store.dispatch('groups/clearError');

            let direction;
            let wordAction = action == 'approveSingleGroupChange'
                ? 'aceitar'
                : 'rejeitar'

            if (item) {
                this.setActiveItem(item); 
                direction = Vue.prototype.$GroupChangeDirection.find(e => e.key == item.direction);
                switch (item.status) {
                    case Vue.prototype.$GroupChangeStatus[0].key:
                        let buttonName = wordAction == 'aceitar' ? 'Confirmar' : 'Rejeitar';
                        let buttonColor = wordAction == 'rejeitar' ? 'error' : 'success';
                        this.dialogues.confirmation = {
                            title: buttonName + ' ' + direction.name.toLowerCase(),
                            message: 'Tem a certeza que quer '+ wordAction + ' a ' + direction.name.toLowerCase() + ' de nível do ' + item.user_target.displayName + ' ?',
                            btn: {
                                name: buttonName,
                                color: buttonColor
                            },
                            action: action
                        }
                        break;
                    case Vue.prototype.$GroupChangeStatus[1].key:
                    case Vue.prototype.$GroupChangeStatus[2].key:
                        this.dialogues.confirmation = {
                            title: 'Reverter alteração',
                            message: 'Tem a certeza que quer reverter a ' + direction.name.toLowerCase() + ' de nível do ' + item.user_target.displayName + ' ?',
                            btn: {
                                name: 'Reverter',
                                color: 'error'
                            },
                            action: action
                        }
                        break;
                    default:
                        break;
                }
            }

            this.toggleDialog('confirm');
        },

        async submitAction(action) {
            if (action) {
                this.dialogues.submitting = true;
                let payload = {
                    id: this.selectedGroupChange.id,
                }
                let result = await this.$store.dispatch('groups/' + action, payload);

                this.dialogues.submitting = false;
                if (result == true) {

                    // refresh TableLevelChange
                    this.fetchData();

                    if (this.$route.name == 'Evolução do Jogador') {
                        //if approve/reject/restore in player single view, it's necessary to refresh all user info
                        this.$store.dispatch('users/getUser', this.$route.params.id);
                        this.$store.commit('groups/setLastPayloadGroupChangesByUserId', {});
                    }

                    this.toggleDialog('confirm');
                    let words = [
                        { text: 'aprovada', action: 'approveSingleGroupChange' },
                        { text: 'rejeitada', action: 'rejectSingleGroupChange' },
                        { text: 'restaurada', action: 'restoreSingleGroupChange' },
                        
                    ]
                    let snackbarWord = words.find(e => e.action == action).text;

                    // Show snackbar
                    this.$store.dispatch('UI/showSnackbar', {
                        message: 'Progressão de nível ' + snackbarWord + ' com sucesso!',
                        color: 'success'
                    });
                }
            }
        },

        async openDialogViewLevelChange(item) {
            this.fetchLoadingSingle = true;
            await this.$store.dispatch('groups/getSingleGroupChange', item);
            this.fetchLoadingSingle = false;

            if (this.$route.name == 'Progressão de nível' || this.$route.name == 'Alteração de nível' ) {
                // routing
                this.routing(item.id);
            }
            
            this.toggleDialog('viewLevelChange');
        },

        closeDialogViewLevelChange() {
            this.toggleDialog('viewLevelChange');
            this.routing();
        },

        routing(id=null) {
            let routeObject = { path: '/levelchange/' };

            // add id if exists
            if (id) {
              routeObject.path = routeObject.path + id;
            } else {
                routeObject.path = routeObject.path + '?dateBegin=' + this.heroDatePickerValue[0] + '&dateEnd=' + this.heroDatePickerValue[1];
            }
            
            // routing updates url without triggering vue-router
            history.replaceState({}, null, routeObject.path);
        }
    }
}