<template>
  <component :is="componentToShow" />
</template>

<script>
import { mapGetters } from 'vuex';
import AnomaliesUser from './Anomalies-User.vue'
import AnomaliesAdmin from './Anomalies-Admin.vue'
import Loading from '@/views/Loading.vue'
import AuthMixin from '@/mixins/Auth'

export default {
  name: 'Anomalies',

  components: {
    AnomaliesUser,
    AnomaliesAdmin,
    Loading,
  },

  mixins: [
    AuthMixin,
  ],

  computed: {
		...mapGetters({
			userIsManager: 'auth/userIsManager',
			userRoles: 'auth/userRoles',
		}),

    componentToShow() {
        let component = null;
  
        if (this.userHasRole(['rail_admin', 'rail_manager', 'tracker_admin', 'tracker_manager', 'super_coach'])) {
          component = 'AnomaliesAdmin';
        } else {
          component = 'AnomaliesUser';
        }
  
        return component;
    }
  }
}
</script>

<style scoped>
</style>