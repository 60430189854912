import { mapGetters } from 'vuex';
import TableEntries from '@/components/Scheduling/Tables/TableEntries/TableEntries.vue';

export default {
    name: 'TableGames',

    components: {
        TableEntries
    },

    computed: {
        ...mapGetters({
            games: 'schedulingGames/data',
            totalData: 'schedulingGames/total',
            loading: 'schedulingGames/loading',
            error: 'schedulingGames/error',
        })
    },

    props: {
        groupId: { type: Number, required: false, default: null },
        filters: { type: Object, required: false, default: () => { } },
    },

    created() {
        this.fetchData();
    },

    data() {
        return this.initialState();
    },

    methods: {
        initialState() {
            return {
                headers: [
                    { text: 'Ação', value: 'action', sortable: false },
                    { text: 'Nome', value: 'name', sortable: true },
                    { text: 'Sala', value: 'sharkscopeNetwork.name', sortable: true },
                    { text: 'Buyin', value: 'full_buyin', sortable: true },
                    { text: 'Garantido', value: 'guarantee', sortable: true},
                    { text: 'Flags', value: 'flags', sortable: true },
                    { text: 'Data', value: 'full_date', sortable: true },
                    { text: 'Duração', value: 'duration', sortable: true }
                ],
                footerProps: {
                    'items-per-page-options': this.$itemsPerPageOptions,
                },
                options: {
                    page: 1,
                    itemsPerPage: 10,
                    sortBy: [],
                    sortDesc: [],
                    groupBy: [],
                    groupDesc: [],
                    mustSort: true,
                    multiSort: true
                },
            };
        },

        fetchData() {
            if (this.groupId) {
                this.getGamesForGroup(this.groupId);
            } else {
                this.getGames();
            }
        },

        constructPayload(groupId = null) {
            let payload = {
                params: {
                    page: this.options.page,
                    itemsPerPage: this.options.itemsPerPage,
                },
            }

            if (groupId) {
                payload.id = groupId;
            }

            // add network filter
            if (this.filters && this.filters.network) {
                payload.params.networks = this.filters.network.id;
            }

            // add flags filter
            if (this.filters && this.filters.flags) {
                payload.params.flags = this.filters.flags.join(',');
            }

            // add dateRange filter
            if (this.filters && this.filters.dateRange) {
                payload.params.dateRange = this.$moment(this.filters.dateRange[0]).unix() + ',' + this.$moment(this.filters.dateRange[1]).unix();
            }

            // add buyin filter
            if (this.filters && this.filters.buyinRange) {
                payload.params.buyin = this.filters.buyinRange.join(',');
            }

            // add player filter
            if (this.filters && this.filters.user) {
                payload.params.users = this.filters.user.id;
            }

            return payload;
        },

        async getGames() {
            let payload = this.constructPayload();
            await this.$store.dispatch('schedulingGames/get', payload);
        },

        async getGamesForGroup(groupId) {
            let payload = this.constructPayload(groupId);
            await this.$store.dispatch('schedulingGames/getFromGroup', payload);
        },
    },
    watch: {
        options: {
            handler() {
                this.fetchData();
            },
            deep: true,
        },
        filters: {
            handler() {
                // resets options object
                this.options = {
                    page: 1,
                    itemsPerPage: 10,
                    mustSort: false,
                    multiSort: false
                }
            },
            deep: true,
        },
    }
}