import Vue from 'vue';
import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth';
import ButtonCopyToClipboard from '@/components/Rail/ButtonCopyToClipboard/ButtonCopyToClipboard.vue';

export default {
  name: 'NicknameCard',

  props: {
    showIcon: {type: Boolean, required: false, default: true},
    copyToClipboard: {type: Boolean, required: false, default: true},
    subtitle: {type: Boolean, required: false, default: true}, // If you need to display a subtitle, it must be the network name
    networkName: {type: String, required: true, default: ''},
    imageURL: {type: String, required: true, default: ''},
    networkId: {type: Number, required: false, default: null},
    name: {type: String, required: false, default: ''},
    id: {type: Number, required: false, default: null},
    description: {type: String, required: false, default: ''},
    isManagement: {type: Boolean, required: false, default: false},
    redirectTo: {type: String, required: false, default: 'nicknames'},
    verifyExternalTransfer: {type: Object, required: false, default: null},
  },

  mixins: [
    AuthMixin,
  ],

  components: {
    ButtonCopyToClipboard,
  },

  data() {
    return this.initialState();
  },

  computed: {
    ...mapGetters({}),
  
    networkImage() {
      return require('@/assets/networks/' + this.imageURL);
    },

    vChipCases(){
      if(!this.verifyExternalTransfer) return null;
      let isExternal = this.verifyExternalTransfer && this.verifyExternalTransfer.isExternal;
      let isManagement = this.verifyExternalTransfer && this.verifyExternalTransfer.isManagement;

      if(isManagement){
        return {
          name: 'Gestão',
          color: '#FFCB0A',
          icon: 'mdi-cards-club',
          textColor: 'black',
          iconColor: 'black',
        }
      }
      else if(isExternal){
        return {
          name: 'Externo',
          color: '#EC4235',
          icon: 'mdi-arrow-right',
          textColor: 'white',
          iconColor: 'white',
        }
      }else{
        return null;
      }
    },

    componentToShow(){
      if((this.id == 0 || this.id == null) && (this.networkId == 0 || this.networkId == null)){
        return 'div';
      }
      return 'router-link';
    }
  },
  

  methods: {
    initialState() {
      return {}
    },
  }
}
