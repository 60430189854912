import { mapGetters } from 'vuex';
import Hero from '@/components/Hero/Hero.vue';
import LoadingSpinner from '@/components/LoadingSpinner/LoadingSpinner.vue';
import AlertError from '@/components/Alerts/AlertError/AlertError.vue';
import TableEntries from '@/components/Scheduling/Tables/TableEntries/TableEntries.vue';

export default {
    name: 'Games-Single',

    components: {
        Hero,
        LoadingSpinner,
        AlertError,
        TableEntries
    },

    computed: {
        ...mapGetters({
            game: 'schedulingGames/data',
            loading: 'schedulingGames/loading',
            error: 'schedulingGames/error',
        }),

        sharkscopeUrl() {
            return "https://pt.sharkscope.com/#Find-Tournament//networks/" + this.game.sharkscopeNetwork.name + "/tournaments/" + this.game.idSharkscope
        }
    },

    data() {
        return this.initialState();
    },

    created() {
        this.init();
    },

    methods: {
        initialState() {
            return {}
        },

        init() {
            this.getSchedulingGame(this.$route.params.id);
        },

        getBooleanIcon(atr) {
            return (atr) ? "mdi-check" : "mdi-close";
        },

        async getSchedulingGame(id) {
            await this.$store.dispatch('schedulingGames/getById', id);
        },
    }
}