import { mapGetters } from 'vuex';
import ColumnChart from '@/components/Charts/ColumnChart/ColumnChart.vue';
import DialogEditModels from '@/components/Scheduling/Dialogs/DialogEditModels/DialogEditModels.vue';

export default {
    name: 'TableModels',

    components: {
        ColumnChart,
        DialogEditModels
    },

    data() {
        return this.initialState();
    },

    computed: {
        ...mapGetters({
            schedulingModels: 'schedulingModels/data',
            loading: 'schedulingModels/loading',
            error: 'schedulingModels/error',
        })
    },

    created() {
        this.getSchedulingModels();
    },

    methods: {
        initialState() {
            return {
                search: null,
                dialogs: {
                    createEditModel: false
                },
                dialogAction: 'create',
                editModelId: undefined,
                headers: [
                    { text: 'Ativo', value: 'active', sortable: false },
                    { text: 'Atualizado', value: 'ready', sortable: false },
                    { text: 'Pontuação média', value: 'averageScore', sortable: false },
                    { text: 'Nome', value: 'name', sortable: false },
                    { text: 'Descrição', value: 'description', sortable: false },
                    { text: 'Distribuição', value: 'distributionChart', sortable: false },
                    { text: 'Ação', value: 'action', sortable: false },
                ],
                footerProps: {
                    'items-per-page-options': this.$itemsPerPageOptions,
                },
                models: [],
                chartOptions: {
                    chart: {
                        type: 'bar',
                        zoom: {
                            enabled: false
                        },
                        toolbar: {
                            show: false
                        },
                        foreColor: '#f6f7f8',
                        background: this.themeDarkMode ? 'dark' : 'light',
                    },
                    plotOptions: {
                        bar: {
                            borderRadius: 5,
                            columnWidth: '60%',
                        }
                    },
                    colors: ['#FFC900'],
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        curve: 'straight'
                    },
                    xaxis: {
                        type: 'week',
                        labels: {
                            show: false,
                            style: {
                                colors: this.themeDarkMode ? '#FFFFFF' : '#000000',
                            },
                        }
                    },
                    yaxis: {
                        opposite: true,
                        labels: {
                            show: false,
                            style: {
                                colors: this.themeDarkMode ? '#FFFFFF' : '#000000',
                            },
                        }
                    },
                    grid: {
                        xaxis: {
                            lines: {
                                show: false
                            }
                        },
                        yaxis: {
                            lines: {
                                show: false
                            }
                        }
                    },
                    legend: {
                        horizontalAlign: 'left'
                    },
                    theme: {
                        mode: this.themeDarkMode ? 'dark' : 'light',
                    }
                }
            };
        },

        getBooleanIcon(atr) {
            return (atr) ? "mdi-check" : "mdi-close";
        },

        toggleDialog(name) {
            this.dialogs[name] = !this.dialogs[name];
        },

        async openCreateDialog() {
            this.dialogAction = 'create';
            await this.$store.dispatch('schedulingModels/clearError');
            this.toggleDialog('createEditModel');
        },

        openEditModel(id) {
            this.dialogAction = 'edit';
            this.editModelId = id;
            this.toggleDialog('createEditModel');
        },

        // Prepares the model to have the correct format to be added/replaced on the list of models and shown on the UI
        prepareModel(model) {
            if (model) {
                if (model.exclusionFactors.length > 0) {
                    model.exclusionFactors.forEach(factor => {
                        factor.factor1 = factor.factor1.id;
                        factor.comparisonOperator = factor.comparisonOperator.id;
                        factor.value1 = factor.value1;
                        factor.value2 = factor.value2 ? factor.value2 : undefined;
                    })
                }

                if (model.calculationFactors.length > 0) {
                    model.calculationFactors.forEach(factor => {
                        factor.factor1 = factor.factor1.id;
                        factor.factor2 = (factor.factor2) ? factor.factor2.id : undefined;
                        factor.weight = factor.weight;
                        factor.relationship = factor.relationship.id;
                    })
                }
            }
            return model;
        },

        addModel(model) {
            if(model){
                model = this.prepareModel(model);
                this.models.push(model);
            }  
        },

        editModel(model) {
            model = this.prepareModel(model);
            let index = this.models.map(model => model.id).indexOf(model.id);
            this.models.splice(index, 1, model);
        },

        async getSchedulingModels() {
            await this.$store.dispatch('schedulingModels/get');
            this.models = this.schedulingModels;
        },
    }
}