import Vue from 'vue';
import { mapGetters } from 'vuex';
import RailMixin from '@/mixins/Rail.vue';
import DataMixin from '@/mixins/Data.vue';
import AuthMixin from '@/mixins/Auth.vue';
import UserDropdown from '@/components/Rail/UserDropdown/UserDropdown.vue';
import InputFinancial from '@/components/InputFinancial/InputFinancial.vue';
import PercentageDropdown from '@/components/PercentageDropdown/PercentageDropdown.vue';
import Alert from '@/components/Alerts/Alert/Alert.vue';

export default {
    name: 'TableSingleSwapTournament',

    mixins: [
        RailMixin,
        DataMixin,
        AuthMixin,
    ],

    components: {
        UserDropdown,
        InputFinancial,
        PercentageDropdown,
        Alert,
    },

    props: {
        list: { type: Array, required: false, default: () => [] },
        maxHeight: { type: String, required: false, default: '600px' },
        clean: { type: Boolean, required: false, default: false },
        action: { type: String, required: false, default: 'create' },
    },

    data() {
        return this.initialState();
    },

    computed: {
        ...mapGetters({
            themeDarkMode: 'UI/themeDarkMode',
            currencies: 'currencies/currencies',
            selectedSwapGroup: 'railSwaps/selectedSwapGroup',
            selectedSwapPool: 'railSwaps/selectedSwapPool',
            users: 'users/usersAllActive',
        }),

        poolListComputed: {
            get() {
                return this.form.poolList;
            },
            set(newVal) {
                this.form.poolList = newVal;
            },
        },

        usersHavePaidComputed: {
            get() {
                return this.showPaymentAlert.usersHavePaid;
            },
            set(newVal) {
                this.showPaymentAlert.usersHavePaid = newVal;
            },
        },

        dataTableHeaders: function () {
            let output = [
                { text: 'Nome', value: 'user', sortable: false, width: "3%"},
                { text: 'Jogou', value: 'played', sortable: true, width: "1%", align: 'center' },
                { text: 'Buy in', value: 'amount', sortable: true, width: this.action == 'view' ? "1%" : "5%" },
                { text: 'Gyazo', value: 'prizeScreenshot', sortable: false, width: this.action == 'view' ? "1%" : "10%"},
                { text: 'Prémio', value: 'prize', sortable: true, width: this.action == 'view' ? "1%" : "5%" },
            ];

            if (this.action == 'view') {
                output = output.concat([
                    { text: 'Percentagem', value: 'deal', sortable: true, width: this.action == 'view' ? "1%" : "5%" },
                    { text: 'Transferência', value: 'transferAmount', sortable: true, width: "1%" },
                    { text: 'PnL', value: 'pnl.value', sortable: true, width: "1%" },
                    { text: 'Overall Share', value: 'overallShare', sortable: true, width: "1%" },
                    { text: 'Own Share', value: 'ownShare', sortable: true, width: "1%" },
                ])
            }

            return output;
        },
    },

     watch: {
        'form.poolList': {
            handler() {
                this.emitChange();
            },
            deep: true,
        },

        selectedSwapGroup: {
            handler() {
                // populate
                if (this.action == 'create') {
                    this.populateTable();
                }
            },
            immediate: true,
            deep: true
        },
        selectedSwapPool: {
            handler() {
                // populate
                if (!this._.isNull(this.selectedSwapPool)) {
                    this.populateTable();
                }
            },
            immediate: true,
            deep: true
        },
        
         action: function () {
            this.populateTable();
         },
    },

    methods: {
        initialState() {
            return {
                checkbox: false,
                regexp: /^(\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gim,
                showPaymentAlert: {
                    state: true,
                    usersHavePaid: [],
                },
                form: {
                    poolList: [],
                },
                dataTable: {
                    itemsPerPage: -1,
                },
                rules: {
                    prizeScreenshot: [
                        (v) => !v ? true : this.regexp.test(v) || 'Formato errado. ex: www.gyazo.com/j1b23k'
                    ],
                    buyin: [
                        (v) => !!v || 'Por favor introduza o buyin',
                        (v) => ! Number.isNaN(v) || 'Insira um valor numérico',
                    ],
                },
            }
        },

        emitChange() {
            this.$emit('change', this.generateOutput());
            this.$emit('input', this.generateOutput());
        },

        generateOutput() {
            let output = [];

            if (!this._.isEmpty(this.poolListComputed)) {
                output = this.poolListComputed.map(element => {
                    if (element && element.user && element.user.id) {
                        let deal = element.deal && element.deal.value ? element.deal.value : element.deal
                        return {
                            id: element.id,
                            user: element.user.id,
                            played: element.played,
                            amount: element.amount,
                            deal: deal ? deal.toString() : null,
                            prizeScreenshot: element.prizeScreenshot,
                            prize: element.prize,
                        }
                    }
                });
            }

            let populated = output.length == this.poolListComputed.length;

            if (populated) {
                return output;
            }
        },

        getTooltipPlayMessage(item) {
            let adminPhrase = item.played
                ? 'Este jogador jogou neste torneio'
                : 'Este jogador não jogou neste torneio';
            let userPhrase = item.played
                ? 'Jogaste neste torneio'
                : 'Não jogaste neste torneio';
            
            return this.userIsRailManager
                ? adminPhrase
                : userPhrase;
        },

        // clears pool list
        clearTable() {
            this.poolListComputed = [];
        },

        populateTable() {
            if (this.action == 'create') {
                this.populateOnCreate();
            } else if (this.action == 'edit') {
                this.checkbox = this.selectedSwapPool.paid;
                this.populateOnEdit();
            } else {
                this.populateOnView();
            }
        },

        handleShareBasedOnSwapGroup() {
            let output = ''
            if (this.selectedSwapGroup) {
                output = Vue.prototype.$deal_share_percentages.find(e => this.selectedSwapGroup.size == e.usersNumber);
                
                if (!this._.isUndefined(output)) {
                    output =  { value: output.share };
                }
            }
            return output;
        },

        populateOnCreate() {
            // clear state
            let table = [];
            Object.assign(this.poolListComputed, table);

            if (!this._.isNull(this.selectedSwapGroup) && !this._.isEmpty(this.selectedSwapGroup.users)) {
                this.selectedSwapGroup.users.forEach(element => {
                    table.push({
                        user: { id: element },
                        played: false,
                        amount: 0,
                        deal: this.handleShareBasedOnSwapGroup(),
                        prizeScreenshot: null,
                        prize: 0,
                    })  
                })
            }

            Object.assign(this.poolListComputed, table);
            this.poolListComputed = table;
        },

        populateOnEdit() {
            // clear state
            let table = [];
            Object.assign(this.poolListComputed, table);
            
            if (this.selectedSwapPool && !this._.isEmpty(this.selectedSwapPool.entries)) {
                this.selectedSwapPool.entries.map(element => {
                    table.push({
                        id: element.id,
                        user: { id: element.userID },
                        played: element.played,
                        amount: element.amount.value,
                        deal: { value: element.share },
                        prizeScreenshot: element.prizeScreenshot,
                        prize: element.prize.value,
                        transferAmount: element.transferAmount
                    })
                })
            }
            Object.assign(this.poolListComputed, table);
            this.poolListComputed = table;
        },

        populateOnView() {
            // clear state
            let table = [];
            Object.assign(this.poolListComputed, table);
            
            if (this.selectedSwapPool && !this._.isEmpty(this.selectedSwapPool.entries)) {
                this.selectedSwapPool.entries.map(element => {
                    table.push({
                        id: element.id,
                        user: { id: element.userID },
                        played: element.played,
                        amount: element.amount.value,
                        deal: { value: element.share },
                        prizeScreenshot: element.prizeScreenshot,
                        prize: element.prize.value,
                        paid: element.paid,
                        pnl: element.pnl,
                        overallShare: element.overallShare,
                        ownShare: element.ownShare,
                        transferAmount: element.transferAmount
                    })
                })
            }

            Object.assign(this.poolListComputed, table);
            this.poolListComputed = table;
        },

        handleUsername(id) {
            let output = null;
            if (!this._.isEmpty(this.users)) { 
                output = this.users.find(element => element.id == id);
                if (!this._.isUndefined(output)) {
                    output = output.name;
                }
            }
            return output;
        },

        stringToPercentage(value) {
           return (parseFloat(value) * 100).toFixed(2);
        },

        openDialogViewAttachment(image) {
            if (image) {
                return window.open(image, '_blank');
            }
        },

        getTooltipTransferMessage(amount) {
            let output = '';

            if (amount > 0) {
                output = this.userIsRailManager
                    ? 'Montante a receber da pool'
                    : 'Montante a receber da gestão'
            } else {
                output = this.userIsRailManager
                    ? 'Montante a enviar para a pool'
                    : 'Montante a enviar para a gestão'
            }
            return output;
        },
    }
}
