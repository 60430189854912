import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth';
import RailMixin from '@/mixins/Rail.vue';
import DialogEdit from '@/components/Players/DialogEdit.vue';
import DialogAdditionalUserInformation from '@/components/Dialog/DialogAdditionalUserInformation/DialogAdditionalUserInformation.vue';


export default {
  name: 'UserInformation',

  props: {
    title: { type: String, required: false, default: '' },
  },
  
  components: {
    DialogEdit,
    DialogAdditionalUserInformation,
  },
  
  mixins: [
    RailMixin,
    AuthMixin,
  ],

  computed: {
    ...mapGetters({
      selectedUser: 'users/selectedUser',
      userLoading: 'users/userLoading',
      userMeta: 'users/userMeta',
    }),

    userInfo() {
      return [
        {
          label: 'Nome Completo',
          field: this.name,
          icon: 'mdi-account',
          tooltip: false
        },
        {
          label: 'Data de Nascimento',
          field: this.birth,
          icon: 'mdi-calendar',
          tooltip: false
        },
        {
          label: 'Idade',
          field: this.age,
          icon: 'mdi-cake-variant',
          tooltip: false
        },
        {
          label: 'Email',
          field: this.email,
          icon: 'mdi-email',
          tooltip: true,
          tooltipName: 'email'
        },
        {
          label: 'Nacionalidade',
          field: this.nationality,
          icon: 'mdi-earth',
          tooltip: false
        },
        {
          label: 'Telemóvel',
          field: this.phone,
          icon: 'mdi-cellphone',
          tooltip: true,
          tooltipName: 'Telemóvel'
        }
      ]
    },

    socialNetworks() {
      return [
        {
          id: 1,
          field: this.facebook,
          icon: 'mdi-facebook',
          tooltip: 'Facebook'
        },
        {
          id: 2,
          field: this.instagram,
          icon: 'mdi-instagram',
          tooltip: 'Instagram'
        },
        {
          id: 3,
          field: this.twitter,
          icon: 'mdi-twitter',
          tooltip: 'Twitter'
        },
        {
          id: 4,
          field: this.youtube,
          icon: 'mdi-youtube',
          tooltip: 'Youtube'
        },
        {
          id: 5,
          field: this.twitch,
          icon: 'mdi-twitch',
          tooltip: 'Twitch'
        },
        {
          id: 6,
          field: this.skype,
          icon: 'mdi-skype',
          tooltip: 'Skype'
        },
        {
          id: 7,
          field: this.discord,
          icon: 'mdi-message-text',
          tooltip: 'Discord'
        },
      ]
    }
  },

  created() {
      this.populateFields();
  },

  data() {
    return this.initialState();
  },

  methods: {
    initialState() {
      return {
        name: null,
        age: null,
        birth: null,
        email: null,
        nationality: null,
        phone: null,
        discord: null,
        skype: null,
        facebook: null,
        instagram: null,
        twitter: null,
        youtube: null,
        twitch: null,
        dialogues: {
          edit: false,
          userInformation: false,
        },
        skeletonLoading: 8
      }
    },

    editUserInfo() {
      this.dialogues.edit = true;
    },

    populateFields() {
      if (this.selectedUser) {
        this.name = this.selectedUser.name ? this.selectedUser.name : null;
        this.birth = this.selectedUser.birthdate ? this.$moment(this.selectedUser.birthdate.date).format('DD-MM-YYYY') : null;
        this.age = this.selectedUser.age ? this.selectedUser.age : null;
        this.email = this.selectedUser.email ? this.selectedUser.email : null;
        this.nationality = this.userMeta && this.userMeta.nationality ? this.userMeta.nationality.name : null;
        this.phone = this.selectedUser.phone ? this.selectedUser.phone : null;
        this.discord = this.validateSocialNetworkFields('discord');
        this.skype = this.validateSocialNetworkFields('skype');
        this.facebook = this.validateSocialNetworkFields('facebook');
        this.instagram = this.validateSocialNetworkFields('instagram');
        this.twitter = this.validateSocialNetworkFields('twitter');
        this.youtube = this.validateSocialNetworkFields('youtube');
        this.twitch = this.validateSocialNetworkFields('twitch');
      }
    },

    validateSocialNetworkFields(field) {
      return this._.has(this.selectedUser.socialNetworks, field)
             ? this.selectedUser.socialNetworks[field]
             : null;
    },

    triggerCopyToClipboard(content) {
      this.copyToClipboard(content);

      // Show snackbar
      this.$store.dispatch('UI/showSnackbar', {
        message: "Nome '" + content + "' copiado com sucesso.",
        color: 'success'
      });
    },

    updateUserInfo() {
      this.$store.dispatch('users/getUser', this.selectedUser.id);
    }
  }
}
