var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('DialogConfirmation',{attrs:{"title":"Remover inscrição","body":'Tens a certeza que queres remover o jogador do evento ' + _vm.selectedEvent.title + '?',"width":450,"button-name":"Remover","button-color":"error","is-open":_vm.dialogConfirmation.open,"submitting":_vm.dialogConfirmation.submitting,"error":_vm.eventsError,"human-error":_vm.eventsHumanError},on:{"submit":function($event){return _vm.undoRsvp()},"close-dialog":function($event){_vm.dialogConfirmation.open = false}}}),_c('v-card',{staticClass:"overflow-x-hidden"},[_c('v-sheet',{staticClass:"d-flex pa-4 pb-2 justify-space-between align-center"},[_c('span',{staticClass:"subtitle-1"},[_vm._v(" Organizador ")])]),(_vm.selectedEvent.organizer)?_c('v-list-item',{staticClass:"px-2 mt-2",attrs:{"dense":""}},[_c('router-link',{attrs:{"to":_vm.getUserProfileLink(_vm.selectedEvent.organizer.id_users)}},[_c('v-list-item-avatar',{staticClass:"mx-2",attrs:{"color":"accent","size":"32"}},[(_vm.selectedEvent.organizer.img_cutout_users)?_c('v-img',{staticClass:"mt-1",attrs:{"position":"","src":_vm.selectedEvent.organizer.img_cutout_users}}):_c('span',{staticClass:"font-weight-semibold"},[_vm._v(" "+_vm._s(_vm.selectedEvent.organizer.name_users[0])+" ")])],1)],1),_c('v-list-item-content',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item-title',_vm._g(_vm._b({staticClass:"subtitle-2"},'v-list-item-title',attrs,false),on),[_c('router-link',{attrs:{"to":_vm.getUserProfileLink(_vm.selectedEvent.organizer.id_users)}},[_vm._v(" "+_vm._s(_vm.joinNameWithNickname(_vm.selectedEvent.organizer.name_users, _vm.selectedEvent.organizer.nickname_users))+" ")])],1)]}}],null,false,3392358874)},[_c('span',[_vm._v(_vm._s(_vm.joinNameWithNickname(_vm.selectedEvent.organizer.name_users, _vm.selectedEvent.organizer.nickname_users)))])]),(_vm.canManageCalendarEvent())?_c('v-list-item-subtitle',{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm.selectedEvent.organizer.email_users)+" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"x-small":""},on:{"click":function($event){return _vm.triggerCopyToClipboard(_vm.selectedEvent.organizer.email_users)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-content-copy ")])]}}],null,false,2112262990)},[_c('span',[_vm._v("Copiar email")])])],1):_vm._e()],1)],1):_vm._e(),_c('v-divider',{staticClass:"my-2"}),_c('v-sheet',{staticClass:"d-flex px-4 py-2 justify-space-between align-center"},[_c('span',{staticClass:"subtitle-1"},[_vm._v(" Participantes ")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[(_vm.userIsCalendarManager)?_c('v-btn',{attrs:{"disabled":! _vm.addParticipantsEnabled,"icon":"","small":""},on:{"click":_vm.toggleAddAttendeesDiv}},[_c('v-icon',[_vm._v(_vm._s(_vm.showAddAttendeesDiv ? 'mdi-close' : 'mdi-account-plus'))])],1):_vm._e()],1)]}}])},[_c('div',[(_vm.addParticipantsEnabled)?_c('span',[_vm._v("Adicionar participante")]):_c('span',[_vm._v("Este evento já atingiu a lotação")])])])],1),(_vm.showAddAttendeesDiv)?_c('div',{staticClass:"px-4",attrs:{"transition":"scroll-y-transition"}},[_c('v-autocomplete',{attrs:{"label":"Selecione participantes","items":_vm.users,"item-text":"name","item-value":"id","outlined":"","hide-details":"","dense":"","multiple":"","small-chips":"","clearable":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var index = ref.index;
return [(index === 0)?_c('v-chip',[_c('span',{staticClass:"caption"},[_vm._v(_vm._s(_vm.selectedAttendeesText))])]):_vm._e()]}}],null,false,1428993996),model:{value:(_vm.selectedNewAttendees),callback:function ($$v) {_vm.selectedNewAttendees=$$v},expression:"selectedNewAttendees"}}),_c('v-btn',{staticClass:"mt-2",attrs:{"text":"","block":"","color":"success","disabled":! _vm.selectedNewAttendees.length},on:{"click":function($event){return _vm.rsvpAttendees()}}},[_c('span',[_vm._v(" "+_vm._s(_vm.selectedNewAttendees.length === 1 ? 'Adicionar participante' : 'Adicionar participantes')+" ")])])],1):_vm._e(),(_vm.selectedEvent.category.can_see_participant_list || _vm.canManageCalendarEvent())?_c('div',[(!_vm._.isEmpty(_vm.selectedEvent.rsvp_list))?_c('v-list',_vm._l((_vm.selectedEvent.rsvp_list),function(item){return _c('v-list-item',{key:item.user.id_users,staticClass:"px-2",attrs:{"dense":""}},[_c('router-link',{attrs:{"to":_vm.getUserProfileLink(item.user.id_users)}},[_c('v-list-item-avatar',{staticClass:"mx-2",attrs:{"color":"accent","size":"32"}},[(item.user.img_cutout_users)?_c('v-img',{staticClass:"mt-1",attrs:{"position":"","src":item.user.img_cutout_users}}):_c('span',{staticClass:"font-weight-semibold"},[_vm._v(" "+_vm._s(item.user.name_users[0])+" ")])],1)],1),_c('v-list-item-content',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item-title',_vm._g(_vm._b({staticClass:"subtitle-2"},'v-list-item-title',attrs,false),on),[_c('router-link',{attrs:{"to":_vm.getUserProfileLink(item.user.id_users)}},[_vm._v(" "+_vm._s(_vm.joinNameWithNickname(item.user.name_users, item.user.nickname_users))+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.joinNameWithNickname(item.user.name_users, item.user.nickname_users)))])]),(_vm.canManageCalendarEvent())?_c('v-list-item-subtitle',{staticClass:"caption"},[_vm._v(" "+_vm._s(item.user.email_users)+" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"x-small":""},on:{"click":function($event){return _vm.triggerCopyToClipboard(item.user.email_users)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-content-copy ")])]}}],null,true)},[_c('span',[_vm._v("Copiar email")])])],1):_vm._e()],1),(_vm.canManageCalendarEvent())?_c('v-list-item-action',{staticClass:"ma-0"},[_c('v-menu',{attrs:{"bottom":"","left":"","max-width":"260","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',[_c('v-list-item-content',{staticClass:"body-2"},[_c('v-list-item-subtitle',{staticClass:"font-weight-semibold"},[_vm._v("Inscrito por:")]),_c('v-list-item-subtitle',{staticClass:"mb-2"},[_vm._v(_vm._s(item.rsvpCreatedBy.name_users))]),_c('v-list-item-subtitle',{staticClass:"font-weight-semibold"},[_vm._v("Data de inscrição:")]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.$moment(item.date).format('llll')))])],1)],1),_c('v-divider',{staticClass:"my-2"}),_c('v-list-item',{staticClass:"pr-0",attrs:{"link":""}},[_c('v-checkbox',{staticClass:"mt-0 pt-0 d-flex w-100",attrs:{"color":"success","label":"Compareceu","height":"48","hide-details":""},on:{"click":function($event){return _vm.toggleAttended(item.id)}},model:{value:(item.attended),callback:function ($$v) {_vm.$set(item, "attended", $$v)},expression:"item.attended"}})],1),_c('v-list-item',{on:{"click":function($event){return _vm.removeAttendee(item.user.id_users)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-account-remove ")]),_vm._v(" Remover participante ")],1)],1)],1)],1):_vm._e()],1)}),1):_c('v-alert',{staticClass:"mt-2 mb-4 mx-4 subtitle-2",attrs:{"color":"warning","outlined":"","text":""}},[_vm._v(" Ainda ninguém se inscreveu neste evento. ")])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }