import { mapGetters } from 'vuex';
import moment from 'moment';

export default {
    name: 'ButtonCalendarAddtoGoogle',

    computed: {
        ...mapGetters({
			selectedEvent: 'eventsCalendar/selectedEvent',
        }),
	},

	created() {
	},

	mounted() {
        this.generateUrl()
	},

    data() {
		return this.initialState();
	},

    methods: {
		initialState() {
			return {
                datetime_start: '',
                datetime_end: '',
                url: ""
            }
		},
        generateUrl() {
            this.formatDates();
            let url = `https://calendar.google.com/calendar/render?action=TEMPLATE&dates=${this.datetime_start}/${this.datetime_end}&details=${this.selectedEvent.description_short}&text=${this.selectedEvent.title}`;
            this.url = encodeURI(url);
        },
        formatDates() {
            if (this.selectedEvent.is_all_day) {
                // We send format without hours
                this.datetime_start = moment(Number(this.selectedEvent.datetime_start)).format('YYYYMMDD');
                this.datetime_end = moment(Number(this.selectedEvent.datetime_end)).format('YYYYMMDD');
            } else {
                this.datetime_start = moment(Number(this.selectedEvent.datetime_start)).format('YYYYMMDDTHHmmZ');
                this.datetime_end = moment(Number(this.selectedEvent.datetime_end)).format('YYYYMMDDTHHmmZ');
            }
            

        },
	},

}

/* 

.format('YYYYMMDD');
format('YYYYMMDDTHmmssZ');


*/