import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth.vue';
import DataMixin from '@/mixins/Data.vue';
import AlertNote from '@/components/Alerts/AlertNote/AlertNote.vue';
import MenuUserNotes from '@/components/Menus/MenuUserNotes/MenuUserNotes.vue';
import EmptyStateNoData from '@/components/EmptyStateNoData';

export default {
    name: 'UserNotes',

    props: {
        title: { type: String, required: false, default: '' },
        singleUserPage: { type: Boolean, required: false, default: false },
        elevation: { type: [String, Number], required: false, default: 0 },
        notes: { type: Array, required: false, default: () => [] },
        stateToPopulate: { type: String, required: false, default: 'userNotes' },
    },

    components: {
        AlertNote,
        MenuUserNotes,
        EmptyStateNoData,
    },
    
    mixins: [
        DataMixin,
        AuthMixin,
    ],

    computed: {
        ...mapGetters({
            userNotesLoading: 'users/userNotesLoading',
            selectedUserNote: 'users/selectedUserNote',
            selectedUser: 'users/selectedUser',
        }),

        userNotesInfo: function () {
            if (! this._.isEmpty(this.notes)) {
                return this.notes.filter(note => note.sticky == false);
            }
        },

        userStickyNotesInfo: function () {
            if (! this._.isEmpty(this.notes)) {
                return this.notes.filter(note => note.sticky == true);
            }
        },

        isSubmitDisable: function () {
            let output = true;
            if (this.form.content) {
                let handleInput = this.form.content.trim();
    
                output = handleInput.length == 0;
            }
            return output;
        },
    },

    data() {
        return this.initialState();
    },

    async created() {
        return await this.fetchData();
    },

    methods: {
        initialState() {
            return {
                skeletonLoading: 3,
                submitting: false,
                form: {
                    content: '',
                    sticky: false
                },
            }
        },

        async fetchData() {
            if (this.$route.params && this.singleUserPage) {
                let payload = {
                    id: this.$route.params.id,
                    populateUserNotes: this.stateToPopulate == 'userNotes',
                }
                await this.$store.dispatch('users/getUserNotes', payload);
            }
        },

        setActiveItem(item) {
            if (item) {
              this.$store.commit(
                'users/setSelectedUserNote',
                item
              );
            }
        },

        // switches between true or false
        toggleSticky() {
            this.form.sticky = !this.form.sticky;
        },

        /**
        * Used to disable Submit button
        */
        toggleSubmitting() {
            this.submitting = !this.submitting;
        },
                 
        async submitNote() {

            // Toggle submit button
            this.toggleSubmitting();

            // Formulate payload
            let payload = {
                userID: this.selectedUser.id,
                stateToPopulate: this.stateToPopulate,
                notes: {
                    content: this.form.content,
                    sticky: this.form.sticky
                }
            }

            let result = await this.$store.dispatch('users/createUserNotes', payload);

            // // Re-enable button
            this.toggleSubmitting();

            // On success
            if (result === true) {
                // Show success snackbar
                this.$store.dispatch('UI/showSnackbar', {
                    message: 'Nota do jogador criada com sucesso.',
                    color: 'success'
                });

                this.reset();
            }
        },

        reset() {
            Object.assign(this.$data, this.initialState());
            this.$store.dispatch('users/clearErrors');
        }
    }
}