import axios from 'axios';
import Vue from 'vue';
import Vuex from 'vuex';
import StoreMixin from '@/mixins/Store.vue';
import DataMixin from '@/mixins/Data.vue';

Vue.use(Vuex);
Vue.use(StoreMixin);
Vue.use(DataMixin);

const state = {
  reports: [],
  reportsPending: [],
  reportsFinalized: [],
  exportedReports: [],
  reportTypes: [],
  loading: true,
  reportsPendingLoading: true,
  reportsFinalizedLoading: true,
  selectedReportLoading: true,
  selectedReport: null,
  error: null,
  humanError: null,
  totalReports: null,
  reportsPendingTotal: null,
  reportsFinalizedTotal: null,
  lastPayloadReports: {}
};

const getters = {
  reports: (state) => state.reports,
  reportsPending: (state) => state.reportsPending,
  reportsFinalized: (state) => state.reportsFinalized,
  exportedReports: (state) => state.exportedReports,
  reportTypes: (state) => state.reportTypes,
  selectedReport: (state) => state.selectedReport,
  selectedReportLoading: (state) => state.selectedReportLoading,
  error: (state) => state.error,
  loading: (state) => state.loading,
  reportsPendingLoading: (state) => state.reportsPendingLoading,
  reportsFinalizedLoading: (state) => state.reportsFinalizedLoading,
  humanError: (state) => state.humanError,
  totalReports: (state) => state.totalReports,
  reportsPendingTotal: (state) => state.reportsPendingTotal,
  reportsFinalizedTotal: (state) => state.reportsFinalizedTotal,
};

const mutations = {
  set(state, payload) {
    state.reports = payload;
  },

  setReportsPending(state, payload) {
    state.reportsPending = payload;
  },

  setReportsFinalized(state, payload) {
    state.reportsFinalized = payload;
  },

  setReportsExported(state, payload) {
    state.exportedReports = payload;
  },

  setLoading(state, payload) {
    state.loading = payload;
  },

  setReportsPendingLoading(state, payload) {
    state.reportsPendingLoading = payload;
  },

  setReportsFinalizedLoading(state, payload) {
    state.reportsFinalizedLoading = payload;
  },

  setSelectedReport(state, payload) {
    state.selectedReport = payload;
  },

  setSelectedReportLoading(state, payload) {
    state.selectedReportLoading = payload;
  },

  setReportTypes(state, payload) {
    state.reportTypes = payload;
  },

  add(state, payload) {
    if (!state.reports || !Array.isArray(state.reports))
      state.reports = [];
    state.reports.unshift(payload);
  },

  update: (state, payload) => {
    const report = state.reports.filter(
      i => i.id == payload.id
    )[0];

    const index = state.reports.findIndex(
      i => i.id == payload.id
    );

    const updatedItems = [
      ...state.reports.slice(0, index),
      report,
      ...state.reports.slice(index + 1)
    ];
    state.reports = updatedItems;
  },

  setError(state, payload) {
    state.error = payload;
  },

  setHumanError(state, payload) {
    state.humanError = payload;
  },
  
  setTotalReports(state, payload) {
    state.totalReports = payload;
  },
  
  setReportsPendingTotal(state, payload) {
    state.reportsPendingTotal = payload;
  },

  setReportsFinalizedTotal(state, payload) {
    state.reportsFinalizedTotal = payload;
  },

  setLastPayloadReports(state, payload) {
    state.lastPayloadReports = payload;
  },
}

const actions = {
  async create({ commit, dispatch }, payload) {
    dispatch('clearError');

    // Handle payload data
    var data = JSON.stringify(payload);

    // Configure request
    const request_url = Vue.prototype.$url_api + 'v2/rails/reports';
    var config = {
      method: 'POST',
      url: request_url,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    }

    // Execute request & return
    let output = false;

    return axios(config)
      .then(function (response) {
        output = response.data.success;
        if (output) {
          commit('add', response.data.data);
        } else {
          commit('setError', response.data.message);
          commit('setHumanError', response.data.human_message);
        }
      })
      .catch(function (error) {
          commit('setError', error.response.data.message);
          commit('setHumanError', error.response ? error.response.data.human_message : error);
        output = false;
      })
      .then(function () {
        return output;
      });
  },

  async update({ commit, dispatch }, payload) {
    dispatch('clearError');

    // Handle payload data
    var data = JSON.stringify(payload.report);

    // Configure request
    const request_url = Vue.prototype.$url_api + 'v2/rails/reports/' + payload.id;
    var config = {
      method: 'PUT',
      url: request_url,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    // Execute request & return
    let output = false;

    return axios(config)
      .then(function (response) {
        output = response.data.success;
        if (output) {
          commit('update', response.data.data);
        } else {
          commit('setError', response.data.message);
          commit('setHumanError', response.data.human_message);
        }
      })
      .catch(function (error) {
          commit('setError', error.response.data.message);
          commit('setHumanError', error.response ? error.response.data.human_message : error);
        output = false;
      })
      .then(function () {
        return output;
      });
  },

  async finalize({ commit, dispatch }, payload) {
    commit('set', []);
    dispatch('clearError');

    // Handle payload data
    var data = JSON.stringify(payload.report);

    // Configure request
    const request_url = Vue.prototype.$url_api + 'v2/rails/reports/' + payload.id + '/finalize';
    var config = {
      method: 'PUT',
      url: request_url,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    // Execute request & return
    let output = false;

    return axios(config)
      .then(function (response) {
        output = response.data.success;
        if (output) {
          commit('setSelectedReport', response.data.data);
        } else {
          commit('setError', response.data.message);
          commit('setHumanError', response.data.human_message);
        }
      })
      .catch(function (error) {
          commit('setError', error.response.data.message);
          commit('setHumanError', error.response ? error.response.data.human_message : error);
        output = false;
      })
      .then(function () {
        return output;
      });
  },

  async get({ commit, dispatch, state }, payload) {

    // Configure URL parameters
    let parameters = payload && payload.params
      ? payload.params
      : StoreMixin.methods.getParametersDefault();
    
    // validate payload objects before API Call
    let areObjectsEqual = DataMixin.methods.areObjectsEqual(state.lastPayloadReports, parameters);

    if (areObjectsEqual) return false;
    // save last payload
    commit('setLastPayloadReports', parameters);


    // declare variables
    let setItems;
    let setTotalItems;
    let setLoadingItems;
    let handleMutation = StoreMixin.methods.handleReportMutations();

    if (payload && payload.params) {
      handleMutation = StoreMixin.methods.handleReportMutations(payload.params['reportStatus[]']);
    }

    // mutations that will be used to populate states based on status parameter
    setItems = handleMutation.reports;
    setTotalItems = handleMutation.total;
    setLoadingItems = handleMutation.loading;
    
    // clear states
    dispatch('clearError');
    commit(setItems, []);
    commit(setTotalItems, 0);
    commit(setLoadingItems, true);
    
    // Configure request
    let request_url = Vue.prototype.$url_api + 'v2/rails/reports/';
    
      // if status is empty , delete it. (TableReport, 1st tab)
      if (parameters['reportStatus[]'] === '') {
        delete parameters['reportStatus[]'];
      // if status is 1 it means that is on going and we want to retrieve 'ongoing'(status 1) and 'seen' (status 2) reports
      } else if (parameters['reportStatus[]'] === 1) {
        parameters['reportStatus[1]'] = 2;
      }
    
    // Formulate URL with query string with generateQueryParamsUrl mixin function
    request_url = StoreMixin.methods.generateQueryParamsUrl(request_url, parameters);

    // Execute  request & return
    let output = false;

    return axios.get(request_url)
    .then(function (response) {
        output = response.data.success;
      if (output) {
        if (payload.export) {
          commit('setReportsExported', response.data.data);
          }
          commit(setItems, response.data.data);
          commit(setTotalItems, response.data.total);
        }
    })
      .catch(function (error) {
        commit(setItems, []);
        commit(setTotalItems, 0);
        commit('setError', error.response ? error.response.data.message : error);
        commit('setHumanError', error.response ? error.response.data.human_message : error);
        output = false;
    })
    .then(function () {
        commit(setLoadingItems, false);
        return output;
    });
  },

  async getReportById({ commit, dispatch }, payload) {

    // clear states
    dispatch('clearError');
    commit('setSelectedReport', null);
    commit('setSelectedReportLoading', true);
    
    // Configure request
    let request_url = Vue.prototype.$url_api + 'v2/rails/reports/' + payload;

    // Execute  request & return
    let output = false;

    return axios.get(request_url)
    .then(function (response) {
        output = response.data.success;
      if (output) {
        commit('setSelectedReport', response.data.data);
      }
    })
      .catch(function (error) {
        commit('setSelectedReport', null);
        commit('setError', error.response ? error.response.data.message : error);
        commit('setHumanError', error.response ? error.response.data.human_message : error);
        output = false;
    })
    .then(function () {
        commit('setSelectedReportLoading', false);
        return output;
    });
  },

  async getReportTypes({ commit, dispatch }) {
    // Clear errors
    dispatch('clearError');
    
    // Configure request
    const request_url = Vue.prototype.$url_api + 'v2/rails/reports/types';

    // Execute  request & return
    let output = false;

    return axios.get(request_url)
    .then(function (response) {
        output = response.data.success;

        if (output) {
            commit('setReportTypes', response.data.data);
        } else {
          commit('setError', response.data.message);
          commit('setHumanError', response.data.human_message);
        }
    })
    .catch(function (error) {
        commit('setError', error.response ? error.response.data.message : error);
        commit('setHumanError', error.response ? error.response.data.human_message : error);
        output = false;
    })
    .then(function () {
        commit('setLoading', false);
        return output;
    });
  },

  /**
  * Reset to initial state
  */
  reset({commit}) {
    commit('set', []);
    commit('setReportsPending', []);
    commit('setReportsFinalized', []);
    commit('setReportsExported', []);
    commit('setReportTypes', []);
    commit('setLoading', true);
    commit('setSelectedReportLoading', true);
    commit('setReportsPendingLoading', true);
    commit('setReportsFinalizedLoading', true);
    commit('setSelectedReport', null);
    commit('setTotalReports', null);
    commit('setReportsPendingTotal', null);
    commit('setReportsFinalizedTotal', null);
  },

  clearError({ commit }) {
    commit('setError', null);
    commit('setHumanError', null);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
