import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import StoreMixin from '@/mixins/Store.vue';

Vue.use(Vuex);
Vue.use(StoreMixin);

const state = {
    currencies: null,
    currenciesBase: [],
    currenciesTotal: null,
    error: null,
    humanError: null,
    loading: true,
    currenciesBaseLoading: null,
};

const getters = {
    currencies: state => state.currencies,
    currenciesTotal: state => state.currenciesTotal,
    currenciesBase: state => state.currenciesBase,
    error: state => state.error,
    humanError: state => state.humanError,
    loading: state => state.loading,
    currenciesBaseLoading: state => state.currenciesBaseLoading,

};

const mutations = {
    setCurrencies: (state, payload) => { state.currencies = payload; },
    setCurrenciesTotal: (state, payload) => { state.currenciesTotal = payload; },
    setError: (state, payload) => { state.error = payload; },
    setHumanError: (state, payload) => { state.humanError = payload; },
    setLoading: (state, payload) => { state.loading = payload },
    setCurrenciesBase: (state, payload) => { state.currenciesBase = payload },
    setCurrenciesBaseLoading: (state, payload) => { state.currenciesBaseLoading = payload },
};

const actions = {
    async get({ commit, dispatch }, payload) {
        dispatch('reset');

        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/currencies/';

        // Configure URL Parameters
        let parameters = payload && payload.params
            ? payload.params
            : StoreMixin.methods.getParametersDefault();
        
        // Build URL with parameters
        request_url = StoreMixin.methods.generateQueryParamsUrl(request_url, parameters);

        // Execute request & return
        let output = false;

        return axios.get(request_url)
        .then(function (response) {
            output = response.data.success;

            if (output) {
                commit('setCurrencies', response.data.data);
                commit('setCurrenciesTotal', response.data.total);
            } else {
                commit('setError', response.data.message);
                commit('setHumanError', response.data.human_message);
            }
        })
        .catch(function (error) {
            commit('setError', error.response ? error.response.data.message : error);
            commit('setHumanError', error.response ? error.response.data.human_message : error);
            output = false;
        })
        .then(function () {
            commit('setLoading', false);
            return output;
        });
    },

    async getCurrenciesBase({ commit, dispatch }, payload) {
        dispatch('reset');

        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/currencies/base';

        // Configure URL Parameters
        let parameters = payload && payload.params
            ? payload.params
            : StoreMixin.methods.getParametersDefault();
        
        // Build URL with parameters
        request_url = StoreMixin.methods.generateQueryParamsUrl(request_url, parameters);

        // Execute request & return
        let output = false;

        return axios.get(request_url)
        .then(function (response) {
            output = response.data.success;

            if (output) {
                commit('setCurrenciesBase', response.data.data.results);
            } else {
                commit('setError', response.data.message);
                commit('setHumanError', response.data.human_message);
            }
        })
        .catch(function (error) {
            commit('setError', error.response ? error.response.data.message : error);
            commit('setHumanError', error.response ? error.response.data.human_message : error);
            output = false;
        })
        .then(function () {
            commit('setCurrenciesBaseLoading', false);
            return output;
        });
    },

    /**
     * Reset to initial state
     */
        reset({commit}) {
        commit('setCurrencies', []);
        commit('setCurrenciesTotal', null);
        commit('setCurrenciesBase', []);
        commit('setCurrenciesBaseLoading', true);
        commit('setError', null);
        commit('setHumanError', null);
        commit('setLoading', true);
    },

    clearError({ commit }) {
        commit('setError', null);
        commit('setHumanError', null);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};