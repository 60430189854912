import AuthMixin from '@/mixins/Auth';
import Hero from "@/components/Hero/Hero.vue";
import DialogRequestAdmin from "@/components/VideoRequests/dialogues/DialogRequestAdmin/DialogRequestAdmin.vue";
import TableVideoRequestsAdmin from "@/components/VideoRequests/tables/TableVideoRequestsAdmin/TableVideoRequestsAdmin.vue";
import Snackbar from "@/components/Snackbar";

export default {
    name: "VideoRequests",

    mixins: [
        AuthMixin
    ],

    components: {
        Hero,
        DialogRequestAdmin,
        TableVideoRequestsAdmin,
        Snackbar,
    },
    data() {
        return this.initialState();
    },
    async created() {
        await this.allowOnlyRoles(['rail_admin', 'tracker_admin', 'tracker_manager', 'team_cash_manager', 'video_manager']);
    },
    methods: {
        initialState() {
            return {
                dialogues: {
                    request: false,
                },
            };
        },
        requestAccess() {
            // Open dialog
            this.openDialog("request");
        },
        openDialog(d) {
            this.dialogues[d] = true;
        },
    },
};
