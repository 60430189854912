import Vue from 'vue';
import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth';
import RailMixin from '@/mixins/Rail';

export default {
	name: 'DialogReport',

	props: {
		isOpen: { type: Boolean, required: true, default: false },
	},

	mixins: [
		AuthMixin,
		RailMixin,
	],
	
	data() {
		return this.initialState();
	},

	async created() {
		if (this._.isEmpty(this.reportTypes)) {
			await this.$store.dispatch('railReports/getReportTypes');
		}
	},

	computed: {
		...mapGetters({
			selectedRequest: 'railRequests/selectedRequest',
			error: 'railReports/error',
			reportTypes: 'railReports/reportTypes',
			teamFilter: 'teams/teamFilter',
		}),
	},

	methods: {
		initialState() {
			return {
				submitting: false,
				form: {
					type: null,
					observation: null,
				},
				rules: {
					type: [(v) => !!v || 'Por favor selecione um motivo',],
					observation: [
						v => !!v || 'Por favor preencha o campo de Observação',
					  ],
				},
			};
		},

		async submit() {
			if (!this.$refs.formReportProblem.validate()) {
				return false;
			}

			// Toggle submit button
			this.toggleSubmitting();

			const payload = {
				type: this.form.type.id,
				observation: this.form.observation,
				operation: this.selectedRequest.id,
				status: Vue.prototype.$rail_reports.status.ongoing
			};

			let result = await this.$store.dispatch('railReports/create', payload);

			// // Re-enable button
			this.toggleSubmitting();

			// On success
			if (result === true) {
				// Show success snackbar
				this.$store.dispatch('UI/showSnackbar', {
					message: 'Report criado com sucesso.',
					color: 'success'
				});

				let payloadRefresh = {
					params: {}
				  }
		  
				  // if team exists and isManagement, Add team parameter to payload.params
				  if (this.teamFilter && this.userHasRailAccessDivisionDropdown) {
					payloadRefresh.params.team = this.teamFilter;
				  }

				this.updateRequestsTransfersAndBalances(payloadRefresh);
				
				/** Current Tab is Vue.prototype.$tab_slider.tabs.action
				 *  When report is submitted, change to Reports Tab
				 */
				this.$store.commit('TabSlider/setSelectedTab', Vue.prototype.$tab_slider.tabs.reports);
				this.$router.push({ path: Vue.prototype.$tab_slider.tabs.reports }).catch(() => { });
				
				// closes dialog
				this.closeDialog();
			}
		},

		/**
		 * Used to disable Submit button
		 */
		toggleSubmitting() {
			this.submitting = !this.submitting;
		},

		closeDialog() {
			this.$emit("close-dialog");
			this.resetForm();
		},

		resetForm() {
			Object.assign(this.$data, this.initialState());
			this.$refs.formReportProblem.reset();
			this.$store.dispatch('railReports/clearError');
		}
	},
};