import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';

Vue.use(Vuex);

const state = {
    accounts: [],
    selectedAccount: null,
    loading: true
};

const getters = {
    accounts: state => state.accounts,
    selectedAccount: state => state.selectedAccount,
    loading: (state) => state.loading,
};

const mutations = {
    setAccounts: (state, payload) => { state.accounts = payload; },

    setSelectedAccount: (state, payload) => { state.selectedAccount = payload; },

    addAccount: (state, payload) => {
        if (! state.accounts || ! state.accounts.length)
            state.accounts = [];
        
        state.accounts.unshift(payload);
    },

    updateAccount: (state, payload) => {
        const index = state.accounts.findIndex(d => d.id_nicknames == payload.id_nicknames);

        const updatedItems = [
            ...state.accounts.slice(0, index),
            payload,
            ...state.accounts.slice(index + 1)
        ];

        state.accounts = updatedItems;
    },

    deleteAccount: (state, payload) => {
        const index = state.nicknames.findIndex(d => d.id_nicknames == payload.id_nicknames);
        state.accounts.splice(index, 1);
    },

    setLoading(state, payload) {
        state.loading = payload;
    },
};

const actions = {
    async get({ commit }, payload) {
        const requestUrl = Vue.prototype.$url_api + 'v2/nicknames/';

        await axios
        .get(requestUrl)
        .then(result => {
            commit('setAccounts', result.data.data);
            commit('setLoading', false);
            return true;
        })
        .catch(err => {
            console.log(err);
            return false;
        });
    },

    select({commit}, payload) {
        commit('setSelectedAccount', payload);
    },

    clear({commit}) {
        commit('setAccounts', []);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};