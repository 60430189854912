import Vue from 'vue';
import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth.vue';
import RailMixin from '@/mixins/Rail.vue';
import DataMixin from '@/mixins/Data.vue';
import EmptyStateNoData from '@/components/EmptyStateNoData';
import DialogViewTransfer from '@/components/Rail/DialogViewTransfer/DialogViewTransfer.vue';
import DialogEditTransfer from '@/components/Rail/Dialog/DialogEditTransfer/DialogEditTransfer.vue';
import DialogConfirmation from '@/components/Rail/Dialog/DialogConfirmation/DialogConfirmation.vue';
import DialogDownloadCsv from '@/components/Rail/Dialog/DialogDownloadCsv/DialogDownloadCsv.vue';

export default {
  name: 'TableExpenses',

  props: {
    expenseTypes: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  components: {
    EmptyStateNoData,
    DialogViewTransfer,
    DialogEditTransfer,
    DialogConfirmation,
    DialogDownloadCsv,
  },

  mixins: [
    AuthMixin,
    RailMixin,
    DataMixin,
  ],

  data() {
    return this.initialState();
  },

  async created() {
    await this.initialize();
  },

  computed: {
    ...mapGetters({
      expenses: 'railExpenses/expenses',
      selectedTransfer: 'railTransfers/selectedTransfer',
      selectedExpense: 'railExpenses/selectedExpense',
      loading: 'railExpenses/loading',
      dialogDeleteError: 'railRequests/error',
      totalExpenses: 'railExpenses/totalExpenses',
      heroDatePickerValue: 'UI/heroDatePickerValue',
      themeDarkMode: 'UI/themeDarkMode',
      teamFilter: 'teams/teamFilter',
    }),

    optionsComputed: {
      get: function () {
        return this.options;
      },
      set: function (newValue) {
        if (!this._.isEqual(this.options, newValue)) {
          this.options = newValue;
        }
      }
    },
  },

  watch: {
    // watches this.options object
    // triggers everytime this.options has changed
    options: {
      handler() {
        this.fetchData();
      },
      deep: true,
    },

    heroDatePickerValue: function () {
      // resets options object
      this.options = {
        page: 1,
        itemsPerPage: 10,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        mustSort: true,
        multiSort: false
      }
    },
    expenseTypes: function () {
      this.fetchData();
    },
    
    teamFilter: function () {
      this.fetchData();
    },
  },

  methods: {
    initialState() {
      return {
        tableTitle: 'Despesas',
        dataTable: {
          headers: [
            { text: 'Data', value: 'date' },
            { text: 'Conta', value: 'network', sortable: false },
            { text: 'Tipo de despesa', value: 'expenseType' },
            { text: 'Montante', value: 'amount', sortable: false },
            { text: 'Ação', value: 'action', sortable: false },
          ],
          search: null,
          footerProps: {
            'items-per-page-options': this.$itemsPerPageOptions,
          },
        },
        options: {
          page: 1,
          itemsPerPage: 10,
          sortBy: [],
          sortDesc: [],
          groupBy: [],
          groupDesc: [],
          mustSort: true,
          multiSort: false
        },
        dialogues: {
          view: false,
          edit: false,
          download: {
            state: false,
            message: 'Exporta os dados da tabela para formato Excel.',
          },
        },
        dialogAction: 'create',
      };
    },

    openDialogDownloadCsv() {
      this.dialogues.download.state = true;
    },

    initialize() {
      if (this._.isEmpty(this.expenses)) {
        this.fetchData();
      }

      // fetches single expense based  on route params id and opens Dialog
      if (this.$route.params.id) {
        this.fetchSingleExpense();
      }
    },

    async fetchData() {

      // Base payload
      let payload = {
        export: false,
        params: {
          page: this.options.page,
          itemsPerPage: this.options.itemsPerPage,
        }
      };

      // if team exists and isManagement, Add team parameter to payload.params
      if (this.teamFilter && this.userHasRailAccessDivisionDropdown) {
        payload.params.team = this.teamFilter;
      }

      // Add date params
      if (!this._.isEmpty(this.heroDatePickerValue)) {
        payload.params.dateBegin = this.heroDatePickerValue[0];
        payload.params.dateEnd = this.heroDatePickerValue[1];
      }

      // add filter by expense type
      if (!this._.isEmpty(this.expenseTypes)) {
        this.expenseTypes.forEach((element, index) => {
          return payload.params['expenseType[' + index + ']'] = element;
        })
      }

      // Add sortDesc parameter to payload.params
      Object.assign(payload.params, this.sortDescHandler(this.options.sortDesc));

      // Add sortBy parameter to payload
      this.addParameterToPayload(payload, 'sortBy[]', this.options.sortBy);

      // API Call
      await this.$store.dispatch('railExpenses/getExpenses', payload);
    },

    async fetchSingleExpense() {
      await this.$store.dispatch('railTransfers/getTransferById', this.$route.params.id);
      if (this.selectedTransfer) {
        // opens dialog
        this.dialogues.view = true;
        // routing
        this.routing(item.id);
      }
    },

    setActiveItem(item) {
      if (item)
        this.$store.commit('railExpenses/setSelectedExpense', item);
    },

    removeExpense(item) {
      this.$store.dispatch('railExpenses/delete', item);
    },

    viewItem(item) {
      item = this.standardizeDTO(item);
      this.$store.commit('railTransfers/setSelected', item.transfers[0]);

      // opens dialog
      this.dialogues.view = true;

      // routing
      this.routing(item.id);
    },

    editItem(item) {
      if (item) {
        item = this.standardizeDTO(item);

        // Set selected transfer
        this.$store.commit('railTransfers/setSelected', item.transfers[0]);

        // routing
        this.routing(item.id);

        // Open dialog
        this.dialogues.edit = true;
      }
    },

    closeDialogViewExpense() {
      this.dialogues.view = false;
      // routing
      this.routing();
    },

    closeDialogEditExpense() {
      this.dialogues.edit = false;
      // routing
      this.routing();
    },

    /**
     * Handle route based on prop type and if id exists.
     * @param {String} id 
     */
    routing(id = null) {
      let routeObject = { path: '/rail/expenses/' };

      // add id if exists
      if (id) {
        routeObject.path = routeObject.path + id;
      }

      // routing
      this.$router.push(routeObject).catch(() => { });
    },

    /**
     * Standardize ExpenseDTO as TransferDTO so they are usable in DialogViewTransfer / DialogEditTransfer
     * 
     * The ExpenseDTO retrieves the RailOperation data on the top level, instead of on the
     * 'railOperation' key, as is the case in TransferDTO. We move this data, which is required
     * for DialogEditTransfer to open.
     * 
     * @param {Object} item v-datatable item
     * @returns {Object} Object standardized to the TransferDTO standards
     */
    standardizeDTO(item) {
      let selectedItem = this._.cloneDeep(item);
      selectedItem.transfers[0].railOperation = this._.clone(selectedItem);
      return selectedItem;
    },

    createExpense() {
      this.$store.dispatch(
        'TabSlider/setCurrentCard',
        Vue.prototype.$rail_ops.operations.withdraw
      );

      this.$store.commit('railExpenses/setIsExpense', true);

      // clear states
      this.$store.dispatch('railRequests/clearError');
      this.$store.commit('railRequests/setSelectedRequest', null);
      this.$store.commit('railTransfers/setSelected', null);
      this.$store.commit('users/setSelectedUser', null);

      // routing
      this.$store.commit('TabSlider/setSelectedTab', Vue.prototype.$tab_slider.tabs.action);
      this.$router.push({ path: Vue.prototype.$tab_slider.tabs.action }).catch(() => { });
    },

    /**
     * Handles date
     * @param {Object} item 
     * @returns String 
     */
    getExpenseDate(item) {
      let output = '';
      if (item && item.date) {
        output = this.$moment(item.date.date).format('LL');
      }
      return output;
    },

    /**
     * Checks if item.transfers isn't empty
     * @param {Object} item 
     * @returns Boolean value
     */
    isTransfersEmpty(item) {
      return !this._.isEmpty(item.transfers)
    },
  }
};
