import Vue from 'vue';
import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth.vue';
import RailMixin from '@/mixins/Rail.vue';
import DataMixin from '@/mixins/Data.vue';
import DialogNoteDetails from '@/components/Dialog/DialogNoteDetails/DialogNoteDetails.vue';
import DialogAddEditNotes from '@/components/Dialog/DialogAddEditNotes/DialogAddEditNotes.vue';
import EmptyStateNoData from '@/components/EmptyStateNoData';
import PlayerLiveDropdown from '@/components/PlayerLiveDropdown/PlayerLiveDropdown.vue';
import DialogConfirmation from '@/components/Rail/Dialog/DialogConfirmation/DialogConfirmation.vue';

export default {
    components: {
        DialogNoteDetails,
        DialogAddEditNotes,
        EmptyStateNoData,
        PlayerLiveDropdown,
        DialogConfirmation,
    },

    mixins: [
      AuthMixin,
      RailMixin,
      DataMixin,
    ],

    props: {
      hideToggleFilter: {
        type: Boolean,
        required: false,
        default: false,
      },
      hidePlayerFilter: {
        type: Boolean,
        required: false,
        default: false,
      },
      hideCreateButton: {
        type: Boolean,
        required: false,
        default: true,
      },
      filterNotesByUserId: {
        type: String,
        required: false,
        default: null,
      },
      shouldBeTable: {
        type: Boolean,
        required: false,
        default: true,
      }
    },

    computed: {
      ...mapGetters({
        notes: 'notes/notes',
        userTrackerId: 'auth/userTrackerId',
        notesTotal: 'notes/notesTotal',
        loading: 'notes/loading',
        selectedNote: 'notes/selectedNote',
        selectedNoteLoading: 'notes/selectedNoteLoading',
        selectedPlayer: 'livePlayers/selectedPlayer',
        selectedPlayerNotes: 'livePlayers/selectedPlayerNotes',
        selectedPlayerNotesLoading: 'livePlayers/selectedPlayerNotesLoading',
        userNotesLoading: 'notes/userNotesLoading',
        userNotes: 'notes/userNotes',
        error: 'notes/error',
        humanError: 'notes/humanError',
      }),

      filteredNotes: {
        get() {
          let notesToFilter = this.filterByMyNotes === 'myNotes' ? this.userNotes : this.notes;

          if(this.dataTable.search && this.filterByMyNotes === 'allNotes' && !this.selectedPlayer) {
            return notesToFilter;
          }

          if(this.filterNotesByUserId){
            notesToFilter = notesToFilter.filter(note => note.player.id === this.filterNotesByUserId);
          }

          if (this.filterByPlayer && this.selectedPlayer) {
            notesToFilter = this.selectedPlayerNotes;

            if (this.filterByMyNotes === 'myNotes') {
              notesToFilter = this.selectedPlayerNotes.filter(note => note.created_by.id === this.userTrackerId);
            }
          }
      
          if (this.dataTable.search) {
            const searchLowercased = this.dataTable.search.toLowerCase();
            return notesToFilter.filter(note =>
              note.title.toLowerCase().includes(searchLowercased) ||
              note.text.toLowerCase().includes(searchLowercased)
            );
          }
          return notesToFilter;
        },
      },

      numberOfPages() {
        return Math.ceil(this.filteredNotes.length / this.options.itemsPerPage)
      },

      
      getHeightForEmptyState(){
        const breakpointValues = {
          md: '64vh',
          xl: '69vh',
          lg: '66vh',
          xs: '45vh',
          sm: '62vh',
        };

        return breakpointValues[this.$vuetify.breakpoint.name] || '100%';
      },

      skeletonCardsToShow() {
        const breakpointValues = {
          sm: 6,
          md: 6,
          xl: 12,
          xs: 3,
        };
        
        return breakpointValues[this.$vuetify.breakpoint.name] || 9;
      }
    },

    created(){
      this.$root.$on('update-player-notes', () => {
        if (!this.selectedLivePlayer && !this.filterByMyNotes === 'myNotes') {
          this.$store.commit('notes/setLoading', true);
        } else if (!this.selectedLivePlayer && this.filterByMyNotes === 'myNotes') {
          this.$store.commit('notes/setUserNotesLoading', true);
        }
        setTimeout(this.fetchData, 1300) 
      });

      // if it is not table, it its filtered by player
      this.filterByPlayer = !this.shouldBeTable;
      this.$store.commit('livePlayers/setSelectedPlayerNotesLoading', false);

      this.fetchData();
    },

    watch: {
      selectedPlayer() {
        this.filterData();
      },

      selectedLivePlayer() {
        if (this.selectedLivePlayer && this.selectedLivePlayer.id) {
          this.filterByPlayer = true;
          this.$store.commit('livePlayers/setSelectedPlayer', this.selectedLivePlayer);
          this.$store.commit('livePlayers/setSelectedPlayerNotesLoading', true);
          let payload = {
            params: {
              id: this.selectedLivePlayer.id,
            }
          }
          setTimeout(() => {
            this.$store.dispatch('livePlayers/fetchSelectedPlayerNotes', payload);
          }, 500);
        } else {
          this.filterByPlayer = false;
          this.$store.commit('livePlayers/setSelectedPlayerNotesLoading', false);
          this.$store.commit('livePlayers/setSelectedPlayer', false);
        }
      },

      filteredNotes: function (notes) {
        this.showPreviousAndNextButton();
      },
      
      filterByMyNotes(newVal) {
        this.filterByPlayer = false;
        this.selectedLivePlayer = null;
        this.fetchData();
      },
    },

    data() {
      return {
        selectedLivePlayer: null,
        filterByMyNotes: 'allNotes',
        filterByPlayer: false,
        noteDetails: {},
        dialogues: {
          viewNote: false,
          addNote: false,
          editNote: false,
          deleteNote: false,
        },
        idToDelete: null,
        submitDelete: false,
        dataTable:{
          search: '',
        },
        toggleItems: [
          { label: 'Todas', value: 'allNotes', color: 'success' },
          { label: 'Minhas', value: 'myNotes', color: 'success' },
        ],
        showNextButton: true,
        showPreviousButton: false,
        itemsPerPageArray: [4, 8, 12],
        paginationNotes: {
          page: 1,
          itemsPerPage: 9,
        },
        paginationUserNotes: {
          page: 1,
          itemsPerPage: 9,
        },
        paginationSelectedPlayerNotes: {
          page: 1,
          itemsPerPage: 9,
        },
        typingTimeout: null,
      };
    },

    mounted() {
      this.$root.$on('reset-pagination-table-notes', this.resetPaginationTableNotes);
      this.$root.$on('reload-table-notes', this.fetchData);
    },
    beforeDestroy() {
      this.$root.$off('reset-pagination-table-notes', this.resetPaginationTableNotes);
      this.$root.$off('reload-table-notes', this.fetchData);
    },

    methods: {
      filterData() {
        this.filterByPlayer = !!this.selectedPlayer;
      },

      resetPaginationTableNotes(){
        this.paginationNotes = {
          page: 1,
          itemsPerPage: 9,
        }
        this.paginationUserNotes = {
          page: 1,
          itemsPerPage: 9,
        }
        this.paginationSelectedPlayerNotes = {
          page: 1,
          itemsPerPage: 9,
        }
      },

      showPreviousAndNextButton(){
        const dataset = this.filterByMyNotes == 'myNotes' ? 'userNotes' : this.filterByPlayer ? 'selectedPlayerNotes' : 'notes';
        switch (dataset) {
          case 'notes':
            this.showNextButton = this.filteredNotes.length === this.paginationNotes.itemsPerPage;
            this.showPreviousButton = this.paginationNotes.page > 1;
            break;
            case 'userNotes':
              this.showNextButton = this.filteredNotes.length === this.paginationUserNotes.itemsPerPage;
              this.showPreviousButton = this.paginationUserNotes.page > 1;
              break;
              case 'selectedPlayerNotes':
            this.showNextButton = this.filteredNotes.length === this.paginationSelectedPlayerNotes.itemsPerPage;
            this.showPreviousButton = this.paginationSelectedPlayerNotes.page > 1;
            break;
          }
      },

      searchNotes() {
        // Limpa o temporizador anterior para evitar múltiplas execuções
        clearTimeout(this.typingTimeout);
        // Define um novo temporizador que chama 'searchPlayer' após 1 segundo de inatividade
        this.typingTimeout = setTimeout(() => {
          if(this.filterByMyNotes == 'allNotes' && !this.selectedPlayer){
            this.fetchData();
          }
        }, 1000);
      },

      getItemsPerPage(){
        const dataset = this.filterByMyNotes == 'myNotes' ? 'userNotes' : this.filterByPlayer ? 'selectedPlayerNotes' : 'notes';
        switch (dataset) {
          case 'notes':
            return this.paginationNotes.itemsPerPage;
          case 'userNotes':
            return this.paginationUserNotes.itemsPerPage;
          case 'selectedPlayerNotes':
            return this.paginationSelectedPlayerNotes.itemsPerPage;
          default:
            break;
        }
      },

      getPaginationItems(variable){
        const dataset = this.filterByMyNotes == 'myNotes' ? 'userNotes' : this.filterByPlayer ? 'selectedPlayerNotes' : 'notes';
        switch (variable) {
          case 'itemsPerPage':
            switch (dataset) {
              case 'notes':
                return this.paginationNotes.itemsPerPage;
              case 'userNotes':
                return this.paginationUserNotes.itemsPerPage;
              case 'selectedPlayerNotes':
                return this.paginationSelectedPlayerNotes.itemsPerPage;
              default:
                break;
            }
            return 9;
          
          case 'page':
            switch (dataset) {
              case 'notes':
                return this.paginationNotes.page;
              case 'userNotes':
                return this.paginationUserNotes.page;
              case 'selectedPlayerNotes':
                return this.paginationSelectedPlayerNotes.page;
              default:
                break;
            }
            return 1;
          default:
            break;
        }
      },

      openNoteDetails(note) {
        this.setActiveItem(note);
        this.noteDetails = note;
        this.dialogues.viewNote = true;
      },

      openDialogAddEditNotes(dialog, item) {
        this.setActiveItem(item);
        this.toggleDialog(dialog);
      },

      openDialogConfirmation(item) {
        this.dialogues.deleteNote = true;
        this.idToDelete = item.id;
      },

      closeDialogRemoveConfirmation(){
        this.dialogues.deleteNote = false;
      },

      setActiveItem(item) {
        if (item) {
          this.$store.commit(
            'notes/setSelectedNote',
            item
          );
        }
      },

      checkIsOpen(){
        return this.dialogues.addNote || this.dialogues.editNote;
      },

      checkAction(){
        return this.dialogues.addNote ? 'create' : 'update';
      },

      toggleDialog(dialog) {
        this.dialogues[dialog] = ! this.dialogues[dialog];
      },

      closeDialogNote() {
        this.$store.commit('notes/setSelectedNote', null);
        if(this.dialogues.addNote){
          this.toggleDialog('addNote');
        }else if(this.dialogues.editNote){
          this.toggleDialog('editNote');
        }
      },

      async deleteNote(){
        this.submitDelete = true;

        let payload = {
            id: this.idToDelete,
        }

        let result = await this.$store.dispatch('notes/delete', payload);
  
        if (result === true) {
            this.$store.dispatch('UI/showSnackbar', {
                message: 'Nota apagada com sucesso.',
                color: 'success',
            });

          this.submitDelete = false;
          setTimeout(async () => {
            await this.fetchData();
          }, 1000);
          this.dialogues.deleteNote = false;
        }
    },

      async fetchData() {
        let payload = {};

        const dataset = this.filterByMyNotes == 'myNotes' ? 'userNotes' : this.filterByPlayer ? 'selectedPlayerNotes' : 'notes';

        switch (dataset) {
          case 'notes':
            payload = {
              params: {
                per_page: this.paginationNotes.itemsPerPage,
                offset: (this.paginationNotes.page - 1) * this.paginationNotes.itemsPerPage,
              },
            };
            if(this.dataTable.search){
              payload.params.search = this.dataTable.search;
            }
             // API Call
            await this.$store.dispatch('notes/get', payload);
            break;
          case 'userNotes':
            payload = {
              params: {
                per_page: this.paginationUserNotes.itemsPerPage,
                offset: (this.paginationUserNotes.page - 1) * this.paginationUserNotes.itemsPerPage,
              },
            };
            // API call only user notes
            await this.$store.dispatch('notes/getUserNotes', payload);
            break;
          case 'selectedPlayerNotes':
            payload = {
              params: {
                per_page: this.paginationSelectedPlayerNotes.itemsPerPage,
                offset: (this.paginationSelectedPlayerNotes.page - 1) * this.paginationSelectedPlayerNotes.itemsPerPage,
              },
            };
            payload.params.id = this.selectedPlayer.id;
            this.$store.dispatch('livePlayers/fetchSelectedPlayerNotes', payload);
            this.$store.commit('notes/setLoading', false);
            break;
          }
      },

      userIsNoteOwner(note){
        return note.created_by.id === this.userTrackerId;
      },

      nextPage() {
        const dataset = this.filterByMyNotes == 'myNotes' ? 'userNotes' : this.filterByPlayer ? 'selectedPlayerNotes' : 'notes';
        switch (dataset) {
          case 'notes':
            if (this.filteredNotes.length === this.paginationNotes.itemsPerPage) this.paginationNotes.page += 1;
            break;
          case 'userNotes':
            if (this.filteredNotes.length === this.paginationUserNotes.itemsPerPage) this.paginationUserNotes.page += 1;
            break;
          case 'selectedPlayerNotes':
            if (this.filteredNotes.length === this.paginationSelectedPlayerNotes.itemsPerPage) this.paginationSelectedPlayerNotes.page += 1;
            break;
        }
        this.fetchData();
      },

      formerPage() {
        const dataset = this.filterByMyNotes == 'myNotes' ? 'userNotes' : this.filterByPlayer ? 'selectedPlayerNotes' : 'notes';
        switch (dataset) {
          case 'notes':
            if (this.paginationNotes.page > 1) this.paginationNotes.page -= 1;
            break;
          case 'userNotes':
            if (this.paginationUserNotes.page > 1) this.paginationUserNotes.page -= 1;
            break;
          case 'selectedPlayerNotes':
            if (this.paginationSelectedPlayerNotes.page > 1) this.paginationSelectedPlayerNotes.page -= 1;
            break;
        }
        this.fetchData();
      },

      updateItemsPerPage(number) {
        const dataset = this.filterByMyNotes == 'myNotes' ? 'userNotes' : this.filterByPlayer ? 'selectedPlayerNotes' : 'notes';
        switch (dataset) {
          case 'notes':
            this.paginationNotes.itemsPerPage = number;
            this.paginationNotes.page = 1;
            break;
          case 'userNotes':
            this.paginationUserNotes.itemsPerPage = number;
            this.paginationUserNotes.page = 1;
            break;
          case 'selectedPlayerNotes':
            this.paginationSelectedPlayerNotes.itemsPerPage = number;
            this.paginationSelectedPlayerNotes.page = 1;
            break;
        }
        this.fetchData();
      },
    }
  };
