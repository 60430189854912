import Vue from 'vue';
import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth.vue';
import RailMixin from '@/mixins/Rail.vue';
import DataMixin from '@/mixins/Data.vue';
import UserMixin from '@/mixins/User.vue';
import EmptyStateNoData from '@/components/EmptyStateNoData';
import DialogViewSession from '@/components/Rail/DialogViewSession/DialogViewSession.vue';
import DialogAddEditSession from '@/components/Rail/Dialog/DialogAddEditSession/DialogAddEditSession.vue';
import DialogDownloadCsv from '@/components/Rail/Dialog/DialogDownloadCsv/DialogDownloadCsv.vue';
import DialogAddEditSessionSpins from '@/components/Rail/Dialog/DialogAddEditSessionSpins/DialogAddEditSessionSpins.vue';
import DialogViewSessionSpins from '@/components/Rail/Dialog/DialogViewSessionSpins/DialogViewSessionSpins.vue';

export default {
  /**
   * User view - Display data table rails sessions for a specific user
   * Admin view - Display data table rails sessions of every user
   */
  name: 'TableSessions',

  props: {
    nicknameId: {
      type: String,
      required: false,
      default: null,
    },
    userId: {
      type: String,
      required: false,
      default: null,
    },
    filters: {
      type: Object,
      required: false,
      default: () => { }
    },
    showEdit: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  components: {
    EmptyStateNoData,
    DialogViewSession,
    DialogAddEditSession,
    DialogDownloadCsv,
    DialogAddEditSessionSpins,
    DialogViewSessionSpins,
  },

  mixins: [
    AuthMixin,
    RailMixin,
    DataMixin,
    UserMixin,
  ],

  data() {
    return this.initialState();
  },
 
  computed: {
    ...mapGetters({
      sessions: 'railSessions/sessions',
      selectedSession: 'railSessions/selectedSession',
      loading: 'railSessions/loading',
      totalSessions: 'railSessions/totalSessions',
      selectedNickname: 'nicknames/selectedNickname',
      nicknameSessions: 'railSessions/nicknameSessions',
      nicknameSessionsTotal: 'railSessions/totalNicknameSessions',
      loadingNicknameSessions: 'railSessions/loadingNicknameSessions',
      themeDarkMode: 'UI/themeDarkMode',
      heroDatePickerValue: 'UI/heroDatePickerValue',
      selectedTab: 'TabSlider/selectedTab',
      requests: 'railRequests/requests',
      teamFilter: 'teams/teamFilter',
      userTeams: 'users/userTeams',
      authUserTeams: 'auth/userTeams',
      userTrackerId: 'auth/userTrackerId',
      selectedUser: 'users/selectedUser',
    }),

    tableHeaders: function () {
      let authUserTeamsMapId = !this._.isEmpty(this.authUserTeams) ? this.authUserTeams.map(e => e.id) : [];
      let isRailManagerAndTeamSpins = (this.userHasRole('rail_manager') && authUserTeamsMapId.includes(Vue.prototype.$team_division_spins_id)) || (this.userIsRailAdmin && (this.teamFilter == Vue.prototype.$team_division_spins_id));
      let isRailManagerAndTeamCash = (this.userHasRole('rail_manager') && authUserTeamsMapId.includes(Vue.prototype.$team_division_cash_id)) || (this.userIsRailAdmin && (this.teamFilter == Vue.prototype.$team_division_cash_id));
      let isRailManagerAndTeamMtt = (this.userHasRole('rail_manager') && authUserTeamsMapId.includes(Vue.prototype.$team_division_mtt_id)) || (this.userIsRailAdmin && (this.teamFilter == Vue.prototype.$team_division_mtt_id));

      // if user is rail admin
      if (this.userIsRailManager) {
        // handle Player Single View
        if (this.userId && this.selectedUser) {
          let selectedUserTeamsMapId = this.selectedUser.teams.map(e => e.id);
          return selectedUserTeamsMapId.includes(Vue.prototype.$team_division_spins_id)
            ? this.dataTable.headersUserSpins
            : this.dataTable.headersUser;
        }

        // handle Nickname Single View
        if (this.nicknameId && this.selectedNickname) {
          let selectedUserTeamsMapId = this.selectedNickname.teams.map(e => e.id);
          return selectedUserTeamsMapId.includes(Vue.prototype.$team_division_spins_id)
            ? this.dataTable.headersUserSpins
            : this.dataTable.headersUser;
        }

        // if user belongs to management and is from Spins Division or has teamFilter 
        if (isRailManagerAndTeamSpins) {
          return this.dataTable.headersAdminSpins;
        }
        
        // if user belongs to management and is from Cash/Mtt Division or has teamFilter 
        if (isRailManagerAndTeamCash || isRailManagerAndTeamMtt) {
          return this.dataTable.headersAdmin;
        } 

        return this.dataTable.headersAdmin;
      }

      // if user is player
      if (this.userIsPlayer) {
        if (this.userIsDivision('spins')) {
          return this.dataTable.headersUserSpins;
        } else {
          return this.dataTable.headersUser;
        }
      }
      
    },
    
    tableData: function () {
      return this.nicknameId
        ? this.nicknameSessions
        : this.sessions;
    },

    tableLoading: function () {
      return this.nicknameId
        ? this.loadingNicknameSessions
        : this.loading;
    },

    tableDataTotal: function () {
      return this.nicknameId
        ? this.nicknameSessionsTotal
        : this.totalSessions;
    },

    usernameFromSelectedNickname: function () {
      if (this.selectedNickname) {
        return this.selectedNickname.user.name;
      }
    },

    textColor: function () {
      let output = null;

      if (this.themeDarkMode) {
        output = 'white';
      }

      return output;
    },

    optionsComputed: {
      get: function () {
        return this.options;
      },
      set: function (newValue) {
        if (!this._.isEqual(this.options, newValue)) {
          this.options = newValue;
        }
      }
    },

    // Passed through props to DialogAddEditSession component
    reloadSingleUserComputed: function () {
      return !this._.isNull(this.userId) ? true : false;
    }
  },

  watch: {
    heroDatePickerValue: function () {
      if (!this._.isEmpty(this.heroDatePickerValue)) {
        // reset options.page 
        this.goToFirstPage();
        
        if (!this.fetchLoading) {
          this.fetchData();
        }

        // only user
        if (! this.userIsRailManager) {
          this.fetchRequests();
        }
      }
    },

    // watches this.options object
    // triggers everytime this.options has changed
    options: {
      handler() {
        if (!this.fetchLoading) {
          this.fetchData();
        }
      },
      deep: true,
    },

    userId: {
      handler() {
        if (!this.fetchLoading) {
          this.fetchData();
        }
      },
      immediate: true,
    },

    nicknameId: function () {
      if (!this._.isNull(this.nicknameId)) {
        if (!this.fetchLoading) {
          this.fetchData();
        }
      }
    },

    // watches this.filters prop
    // triggers fetchData() by "resetting" options object
    filters: {
      handler() {
        // resets options object
        this.options = {
          page: 1,
          itemsPerPage: 10,
          sortBy: [],
          sortDesc: [],
          groupBy: [],
          groupDesc: [],
          mustSort: false,
          multiSort: true
        }
      },
      deep: true,
    },

    teamFilter: function () {
      if (!this.fetchLoading) {
        this.fetchData();
      }
    },
  },

	async created() {
		this.initialize();
	},

  methods: {
    initialState() {
      return {
        tableTitle: 'Sessões',
        sessionNicknames: null,
        fetchLoading: false,
        dataTable: {
          headersUser: [
            { text: 'Data', value: 'date' },
            { text: 'Salas', value: 'networks', sortable: false },
            { text: 'Resultado', value: 'pnlEUR' },
            { text: 'Ação', value: 'action', sortable: false },
          ],
          headersUserSpins: [
            { text: 'Data', value: 'date' },
            { text: 'Salas', value: 'networks', sortable: false },
            { text: 'N.º Spins', value: 'eventsPlayed', sortable: false, width: '110px' },
            { text: 'N.º fichas', value: 'chipNetWon', sortable: true, width: '110px' },
            { text: 'Chip EV', value: 'chipEV', sortable: true, width: '110px' },
            { text: 'Resultado', value: 'pnlEUR' },
            { text: 'Ação', value: 'action', sortable: false },
          ],
          headersAdmin: [
            { text: 'Data', value: 'date' },
            { text: 'Salas', value: 'networks', sortable: false },
            { text: 'Jogador', value: 'user' },
            { text: 'Resultado', value: 'pnlEUR' },
            { text: 'Ação', value: 'action', sortable: false },
          ],
          headersAdminSpins: [
            { text: 'Data', value: 'date' },
            { text: 'Salas', value: 'networks', sortable: false },
            { text: 'Jogador', value: 'user' },
            { text: 'N.º Spins', value: 'eventsPlayed', sortable: false, width: '110px', align: 'center' },
            { text: 'N.º fichas', value: 'chipNetWon', sortable: true, width: '110px', align: 'center' },
            { text: 'Chip EV', value: 'chipEV', sortable: true, width: '110px', align: 'center' },
            { text: 'Resultado', value: 'pnlEUR' },
            { text: 'Ação', value: 'action', sortable: false },
          ],
          search: null,
          footerProps: {
            'items-per-page-options': this.$itemsPerPageOptions,
          },
        },
        options: {
          page: 1,
          itemsPerPage: 10,
          sortBy: [],
          sortDesc: [],
          groupBy: [],
          groupDesc: [],
          mustSort: true,
          multiSort: true
        },
        dialogues: {
          viewSession: false,
          addEditSession: false,
          viewSessionSpins: false,
          addEditSessionSpins: false,
          download: {
            state: false,
            message: 'Exporta os dados da tabela para formato Excel.',
        },
        },
        dialogAction: 'create',
      };
    },

    initialize() {
      // only user
      if (! this.userIsRailManager) {
        this.fetchRequests();
      }
    },

    openDialogDownloadCsv() {
      this.dialogues.download.state = true;
    },

    // trigered by v-text-field @input="search()"
    search() {
      setTimeout(() => {
        this.fetchData();
      }, 1000);
    },

    async fetchData() {
      this.fetchLoading = true;

      // Base payload
      let payload = {
        export: false,
        params: {
          page: this.options.page,
          itemsPerPage: this.options.itemsPerPage,
        }
      };

      // if team exists and isManagement, Add team parameter to payload.params
      if (this.teamFilter && this.userHasRailAccessDivisionDropdown) {
        payload.params.team = this.teamFilter;
      }

      // Add date params
      if (!this._.isEmpty(this.heroDatePickerValue)) {
        payload.params.dateBegin = this.heroDatePickerValue[0];
        payload.params.dateEnd = this.heroDatePickerValue[1];
      }

      if (this.filters && this.filters.user && this.filters.user.id) {
        payload.params.user = this.filters.user.id;
      }

      if (this.filters && this.filters.group) {
        this.filters.group.forEach((element, index) => {
          payload.params['group[' + index + ']'] = element;
        })
      }

      // Add sortBy parameter to payload
      if (!this._.isEmpty(this.options.sortBy)) {
        this.options.sortBy.forEach((element, index) => {
          payload.params['sortBy[' + index + ']'] = element;
        })
      }

      // Add sortDesc parameter to payload.params
      if (!this._.isEmpty(this.options.sortDesc)) {
        this.options.sortDesc.forEach((element, index) => {
          payload.params['sort[' + index + ']'] = element == false ? 'ASC' : 'DESC';;
        })
      }

      // Add name parameter to payload.params
      if (!this._.isEmpty(this.dataTable.search)) {
        payload.params.name = this.dataTable.search;
      }
      
      // API Call
      if (this.nicknameId) {
        // add nickname id
        payload.id = this.nicknameId;
        // get nickname sessions
        this.$store.dispatch('railSessions/getNicknameSessions', payload);

      } else {
        // add user parameter to payload
        if (this.userId) {
          payload.params.user = this.userId;
        }

        // get sessions
        await this.$store.dispatch('railSessions/get', payload);
      }

      this.fetchLoading = false;
    },

    setActiveItem(item) {
      if (item)
        this.$store.commit('railSessions/setSelected', item);
    },

    removeSession(item) {
      this.$store.dispatch('railSessions/delete', item);
    },

    getNetworkImageURL(sessionNickname) {
      return require('@/assets/networks/' + sessionNickname.nickname.network.imageURL);
    },

    /**
     * If nicknameId exists it retrieves PnL from single nickname
     * otherwise  retrieves the overall pnl from a single session
     * @param {Object} item 
     * @returns {Number} pnl value
     */
    handlePnlToDisplay(item) {
      let pnl = 0;
      if (!this._.isNull(this.nicknameId)) {
        pnl = item.sessionNicknames[0].pnl.value;
      } else {
        pnl = item.pnl.EUR.value;
      }
      return pnl;
    },

    isEnabledEditSession(item) {
      let output = false;

      // hide edit btn in nickname single view
      if (!this.showEdit) {
        output = false;
        return output;
      }

      if (this.userIsRailManager) {
        output = true;
      } else {
        // user can only edit if editable is true and selectedSession is equal to last request (uc session id)
        if (item && item.editable) {
          if (! this._.isEmpty(this.requests)) {          
            output = item.id == this.requests[0].id;
          }
        }
      }

      return output;
    },

    fetchRequests() {
      let payload = {
        params: {
          itemsPerPage: 10,
          page: 1,
          'sortBy[0]': "id",
          'sort[0]': "DESC",
          'type[0]': 6,
          'type[1]': 3,
          'type[2]': 7,
          'type[3]': 9,
          'type[4]': 2,
          'type[5]': 1,
          'type[6]': 5,
          'type[7]': 8,
          'type[8]': 4,
        }
      }
      this.$store.dispatch('railRequests/getRequests', payload);
    },

    goToFirstPage() {
      this.$set(this.options, 'page', 1);
    },

    /**
     * As manager, Checks if userId exists and retrieve userTeams
     * opens dialog based on user teams division
     * @param {String} action ex: 'create', 'update', 'view'
     * @param {Object|null} selectedItem 
     */
    async handleDialogToAddEdit(action = 'create', selectedItem = null) {
      
      // get userId from selected Session
      let userId = selectedItem && selectedItem.user ? selectedItem.user.id : null;

      // if user is rail manager
      if (this.userIsRailManager) {
        // if has userId from selected Session
        if (userId) {
          await this.$store.dispatch('users/getUserTeams', userId);
        } else {
          // else it retrieves his own teams in order to know which Dialog it must open
          await this.$store.dispatch('users/getUserTeams', this.userTrackerId);
        }
      }

      //  if is player
      if (this.userIsPlayer) {
        // retrieve user teams if empty
        if (this._.isEmpty(this.authUserTeams)) {
            await this.$store.dispatch('auth/getUser', this.userTrackerId);
        }
      }

      // if is player, uses teams from auth, else from users store
      let teams = this.userIsPlayer
        ? this.authUserTeams
        : this.userTeams;

      // map userTeams by name and verifies if user belongs to Spins Division
      let userTeamsMap = teams.map(e => e.name);
      let isSpinDivision = userTeamsMap.includes("Spins Division");

      // if user belongs to Spins Division, open Dialog Spins
      return isSpinDivision
        ? this.openDialogAddEditSession(action, selectedItem, 'addEditSessionSpins')
        : this.openDialogAddEditSession(action, selectedItem, 'addEditSession');

    },

    /**
     * opens dialog based on dialog parameter dialog (Ex: 'addEditSession' and 'addEditSessionSpins')
     * if trying to open/close in route name 'Sessões' or 'Sessão' will trigger routing()
     * @param {String} action ex: 'create', 'update', 'view'
     * @param {Object|null} selectedItem 
     * @param {String} dialog 
     */
    openDialogAddEditSession(action = 'create', selectedItem = null, dialog) {

      if (action == 'update' && selectedItem && (this.$route.name == 'Sessões' || this.$route.name == 'Sessão' )) {
        // routing
        this.routing(selectedItem.id)
      }

      // Set active item in store
      if (selectedItem) {
        this.setActiveItem(selectedItem);
      }

      // Set the dialog action to the passed action
      this.dialogAction = action;
      this.dialogues[dialog] = true;
    },

    /**
     * Closes dialog based on dialog parameter dialog (Ex: 'addEditSession' and 'addEditSessionSpins')
     * if trying to open/close in route name 'Sessões' or 'Sessão' will trigger routing()
     * @param {String} dialog 
     */
    closeDialogAddEditSession(dialog) {
      this.dialogues[dialog] = false;
      
      if (this.$route.name == 'Sessões' || this.$route.name == 'Sessão') {
        // routing
        this.routing();
      }
    },

    /**
     * As manager, Checks if userId exists and retrieve userTeams
     * opens dialog based on user teams division
     * @param {Object|null} selectedItem 
     */
    async handleDialogToView(selectedItem) {

      // get userId from selected Session
      let userId = selectedItem && selectedItem.user ? selectedItem.user.id : null;

      // if rail manager and has userId
      if (this.userIsRailManager) {
        // retrieve user teams
        if (userId) {
          await this.$store.dispatch('users/getUserTeams', userId);
        }
      }

      //  if is player
      if (this.userIsPlayer) {
        // retrieve user teams if empty
        if (this._.isEmpty(this.authUserTeams)) {
            await this.$store.dispatch('auth/getUser', this.userTrackerId);
        }
      }

      // if is player, uses teams from auth, else from users store
      let teams = this.userIsPlayer ? this.authUserTeams : this.userTeams;

      // map userTeams by name and verifies if user belongs to Spins Division
      let userTeamsMap = teams.map(e => e.name);
      let isSpinDivision = userTeamsMap.includes("Spins Division");
      let dialogToOpen = isSpinDivision ? 'viewSessionSpins' : 'viewSession';

      return this.openDialogViewSession(selectedItem, dialogToOpen);
    },

    /**
     * opens dialog based on dialog parameter dialog (Ex: 'viewSession' and 'viewSessionSpins')
     * @param {Object} item 
     * @param {String} dialog 
     */
    openDialogViewSession(item, dialog) {
      this.setActiveItem(item);
      this.dialogues[dialog] = true;

      if (this.$route.name == 'Sessões' || this.$route.name == 'Sessão' ) {
        // routing
        this.routing(item.id);
      }
    },
    
    /**
     * Closes dialog based on dialog parameter dialog (Ex: 'viewSession' and 'viewSessionSpins')
     * if trying to open/close in route name 'Sessões' or 'Sessão' will trigger routing()
     * @param {String} dialog 
     */   
    closeDialogViewSession(dialog) {
      this.dialogues[dialog] = false;

      if (this.$route.name == 'Sessões' || this.$route.name == 'Sessão') {
        // routing
        this.routing();
      }
    },

    /**
     * Handle route based on prop type and if id exists.
     * @param {String} id 
     */
    async routing(id = null) {
      let routeObject = { path: '/rail/sessions/' };

      // add id if exists
      if (id) {
        routeObject.path = routeObject.path + id;
      }
      
      // routing updates url without triggering vue-router
      history.replaceState({}, null, routeObject.path);

      this.$store.commit('TabSlider/setSelectedTab', Vue.prototype.$tab_slider.tabs.sessions);
      
    },
    
    imageCutout(item) {
      let placeholder = require('@/assets/images/players/player-example.png');
      return item && item.imgCutout ?  item.imgCutout : placeholder;
    },
  }
};
