import { mapGetters } from 'vuex';
import EmptyStateNoData from '@/components/EmptyStateNoData';
import LoadingSpinner from '@/components/LoadingSpinner/LoadingSpinner.vue';

export default {
  name: 'ChartStatsOkPctPerLevel',

  components: {
    EmptyStateNoData,
    LoadingSpinner,
  },

  props: {
    barHorizontal: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    ...mapGetters({
      themeDarkMode: 'UI/themeDarkMode',
      heroDatePickerValue: 'UI/heroDatePickerValue',
      statsStatistics: 'railStatistics/statsStatistics',
      loadingStatsStatistics: 'railStatistics/loadingStatsStatistics',
    }),

    options: function () {
      return {
        chart: {
          type: 'bar',
          stacked: false,
          fontFamily: 'Inter, sans-serif',
          toolbar: {
            show: false,
            tools: {
              download: false,
            },
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            distributed: false,
            borderRadius: this.getBorderRadius(),
            barHeight: '20%',
            dataLabels: {
              position: 'top',
              textAnchor: 'middle',
            },
            colors: {
              ranges: [],
            },
            columnWidth: '25%',
          },
        },
        colors: ['#4CAF50'],
        grid: {
          show: true,
          borderColor: '#90A4AE',
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          type: 'category',
          categories: this.getCategories(),
          labels: {
            show: true,
            style: {
              colors: '#000000',
            },
          },
        },
        yaxis: {
          opposite: false,
          labels: {
            show: true,
            style: {
              colors: '#000000',
            },
          },
        },
        legend: {
          show: false,
        },
        tooltip: {
          enabled: false, // Desativar o tooltip
        },
      };
    },

    series: function () {
      if (this.statsStatistics && this.statsStatistics.GROUP_STATS_OK) {
        const data = this.statsStatistics.GROUP_STATS_OK.series;
        return [
          {
            data,
          },
        ];
      }
      return [
        {
          data: [],
        },
      ];
    },
  },

  data() {
    return this.initialState();
  },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
  },

  beforeDestroy() { 
    window.removeEventListener('resize', this.onResize); 
  },

  methods: {
    initialState() {
      return {
        windowWidth: window.innerWidth
      };
    },

    onResize() {
        this.windowWidth = window.innerWidth
        this.getBorderRadius()
    },

    getCategories() {
      return this.statsStatistics.GROUP_STATS_OK.labels;
    },

    getBorderRadius(){
        if(this.windowWidth <= 600){
            return 7;
        }
        else if(this.windowWidth <= 960){
            return 10;
        }
        else if(this.windowWidth <= 1280){
            return 13;
        }
        else if(this.windowWidth <= 1920){
            return 20;
        }
        return 25;
    }
  },
};