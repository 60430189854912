import Vue from 'vue';
import { mapGetters } from 'vuex';
import Hero from '@/components/Hero/Hero.vue';
import LoadingSpinner from '@/components/LoadingSpinner/LoadingSpinner.vue';
import AlertError from '@/components/Alerts/AlertError/AlertError.vue';
import AuthMixin from '@/mixins/Auth';
import DataMixin from '@/mixins/Data';
import TableGamesPlayed from '@/components/Tables/TableGamesPlayed/TableGamesPlayed.vue';
import CardStatistics from '@/components/Rail/Statistics/CardStatistics/CardStatistics.vue';

export default {
    name: 'Games-Single',

    components: {
        Hero,
        LoadingSpinner,
        AlertError,
        TableGamesPlayed,
        CardStatistics,
    },

    mixins: [
        AuthMixin,
        DataMixin,
    ],

    computed: {
        ...mapGetters({
            game: 'games/games',
            loading: 'games/loading',
            error: 'games/error',
            selectedGame: 'games/selectedGame',
            loading: 'games/loading',
            heroDatePickerValue: 'UI/heroDatePickerValue',
        }),

        cardInfo() {
            return [
                {
                    name: 'Nº de jogos',
                    value: 0,
                    precision: 0,
                    icon: 'mdi-cards-playing-outline',
                    showCurrency: false,
                    showCurrencyIcon: 'mdi-currency-usd',
                    showAltCurrency: false,
                    show: true,
                },
                {
                    name: 'Nº de entradas',
                    value: 0,
                    icon: 'mdi-account',
                    showCurrency: false,
                    precision: 0,
                    showCurrencyIcon: 'mdi-currency-usd',
                    showAltCurrency: false,
                    show: true,
                    showIconTooltip: true,
                    iconTooltipMessage: 'Contabilizado rebuys e reentries',
                },
                {
                    name: 'Lucro',
                    value: 0,
                    precision: 2,
                    icon: 'mdi-cash',
                    showCurrency: true,
                    showAltCurrency: false,
                    show: true,
                },
                {
                    name: 'ROI',
                    value: 0,
                    precision: 2,
                    icon: 'mdi-cash',
                    showCurrency: true,
                    showAltCurrency: false,
                    show: true,
                },
            ];
        },

        sharkscopeUrl() {
            return "https://pt.sharkscope.com/#Find-Tournament//networks/" + this.selectedGame.networkSharkscope.name + "/tournaments/" + this.selectedGame.idSharkscope
        },

        currencyInputColor() {
            return this.$vuetify.theme.dark ? '#ffffffb3 ' : '';
        },
    },

    data() {
        return this.initialState();
    },

    created() {
        this.init();
    },

    methods: {
        initialState() {
            return {}
        },

        async init() {
            this.getSchedulingGame(this.$route.params.id);
        },

        getBooleanIcon(atr) {
            return (atr) ? "mdi-check" : "mdi-close";
        },

        async getSchedulingGame(id) {
            let payload = {
                id: id,
            }
            await this.$store.dispatch('games/getGameById', payload);
        },

        /**
        * @param {Object} item 
        * @returns {Array} description and name flags
        */
        mapFlagToLabel(flag, mode=null) {
            const flagLabels = Vue.prototype.$metadata;
            if(flag == null || flag == '') return;
            if(flagLabels[flag] === undefined) return flag;
            if(!mode) {
            if (flagLabels[flag]['@description']) return flagLabels[flag]['@description'];
            if (flagLabels[flag]['@name']) return flagLabels[flag]['@name'];
            };
            if(mode == 'game')  return flagLabels[flag]['@name'];
            if(mode == 'id') return flagLabels[flag]['@id'];
        },
    
        convertSecondsToTime(seconds) {
            // Calculate hours
            let hours = Math.floor(seconds / 3600);
            
            // Calculate remaining seconds after calculating hours
            seconds %= 3600;
            
            // Calculate minutes
            let minutes = Math.floor(seconds / 60);
            
            return hours + 'h ' + minutes + 'm ';
        },

        /**
         * @param {Number|String} value 
         * @returns {String} with width value
         */
        getCurrencyInputWidth(value) {
            let output = '50px';
            if (value) {
                output = parseFloat(value).toFixed(3);

                // turn output into String
                output = String(output);

                output = (output.length + 1) * 7 + 'px';
            }
            return output;
        },
    }
}