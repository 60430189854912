<script>
import { mapState, mapGetters } from 'vuex';
import storeAuth from '@/store/auth';
import storeCalendarEvents from '@/store/calendar/events';

export default {
	computed: {
		...mapState([
			'userAuthenticated',
			'userType',
			'userTrackerId',
			'userRoles',
			'userRolesLoading',
		]),

		...mapGetters({
            userRolesLoading: 'auth/userRolesLoading',
        }),

		userIsAdmin: function() {
			return ! this._.isEmpty(this._.intersection(
				storeAuth.state.userRoles,
				['administrator']
			));
		},
		
		userIsRailAdmin: function() {
			return ! this._.isEmpty(this._.intersection(
				storeAuth.state.userRoles,
				['rail_admin']
			));
		},

		userIsPlayer: function() {
			return ! this._.isEmpty(this._.intersection(
				storeAuth.state.userRoles,
				['player']
			));
		},

		userIsManager: function() {
			return ! this._.isEmpty(this._.intersection(
				storeAuth.state.userRoles,
				['rail_admin', 'rail_manager', 'tracker_admin', 'tracker_manager']
			));
		},

		userIsRailManager: function() {
			return ! this._.isEmpty(this._.intersection(
				storeAuth.state.userRoles,
				['rail_admin', 'rail_manager']
			));
		},

		userIsApplicationsManager: function() {
			return ! this._.isEmpty(this._.intersection(
				storeAuth.state.userRoles,
				['applications_manager', 'team_cash_manager']
			));
		},

		userIsCalendarManager: function() {
			return ! this._.isEmpty(this._.intersection(
				storeAuth.state.userRoles,
				['calendar_admin']
			));
		},

		userHasRailAccessDivisionDropdown: function() {
			return ! this._.isEmpty(this._.intersection(
				storeAuth.state.userRoles,
				['rail_admin', 'rail_manager', 'tracker_admin', 'tracker_manager']
			));
		},
	},

    methods: {
		isAuthenticated () {
			return storeAuth.state.userAuthenticated;
		},

		/**
		 * only used for auth user logged in
		 * Returns true if user has ANY of the specified roles
		 */
		userHasRole(roles, userRoles=storeAuth.state.userRoles) {

			// Transform input to array if not that type
			if ( ! Array.isArray(roles) )
				roles = [roles];

			return ! this._.isEmpty(
					this._.intersection(
						userRoles,
						roles
					)
			);

			/*
			// Return true if user has any of specified roles
			for (const [key, value] of Object.entries(roles)) {
				if (storeAuth.state.userRoles.find(r => r == value) !== undefined)
					return true;
			}

			// If script gets here, user does not have any of specified roles
			return false;
			*/
		},

		/* Redirects user if he is authenticated */
		unauthenticatedOnly () {
			if ( this.isAuthenticated() )
				this.$router.push({ path: '/' });
		},

		/* Redirects user if he is not authenticated */
		authenticatedOnly () {
			if ( ! this.isAuthenticated() )
				this.$router.push({ path: '/login/' });
		},

		/* Redirects user if he is not admin */
		adminOnly () {
			if ( ! this.userIsAdmin )
				this.$router.push({ path: '/' });
		},

		async allowOnlyRoles(allowedRoles = []) {
			// Transform input to array if not that type
			if ( ! Array.isArray(allowedRoles) ) {
				allowedRoles = [allowedRoles];
			}

			// If user roles arent set, fetch them
			if (this._.isEmpty(storeAuth.state.userRoles)
				&& ! storeAuth.state.userRolesLoading
				&& ! this.userRolesLoading
			) {
				await this.$store.dispatch('auth/getUserRoles');
			}

			// If we get here and roles still arent loaded, call this function recursively every 100ms
			if (this.userRolesLoading) {
				return await setTimeout(async () => {
					return await this.allowOnlyRoles(allowedRoles);
				}, 100);
			}

			// Check if user has any of the allowed roles, otherwise redirect him to the homepage
			if ( this._.isEmpty( this._.intersection(storeAuth.state.userRoles, allowedRoles) ) ) {          
				this.$router.push({ path: '/', });
			}
		},

		canViewCalendarEventPrivateInfo() {
			let conditions = [
				// If user is calendar admin
				this.userIsCalendarManager,
				// If user is enrolled in the event
				storeCalendarEvents.state.selectedEvent.user_is_enrolled,
				this.isUserCalendarEventOrganizer(),
			];

			// If any of the conditions are true, the user is able to view the events private info
			return conditions.includes(true);
		},

		canManageCalendarEvent() {
			let conditions = [
				// If user is calendar admin
				this.userIsCalendarManager,
				// If user is the event organizer
				storeAuth.state.userTrackerId == storeCalendarEvents.state.selectedEvent.ref_organizer,
			];

			// If any of the conditions are true, the user is able to edit the event
			return conditions.includes(true);
		},

		isUserCalendarEventOrganizer() {
			if ( _.isNull(storeCalendarEvents.state.selectedEvent.organizer) ) {
				return false;
			}

			return storeAuth.state.userTrackerId == storeCalendarEvents.state.selectedEvent.organizer.id_users; 
		},
	},
}
</script>