import Vue from 'vue';
import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth.vue';
import DataMixin from '@/mixins/Data.vue';
import UserMixin from '@/mixins/User.vue';
import DialogEditUserLevel from '@/components/Rail/Dialog/DialogEditUserLevel/DialogEditUserLevel.vue';

export default {
    name: 'UserGroupProgress',

    components: {
        DialogEditUserLevel,
      },

    computed: {
        ...mapGetters({
            selectedUser: 'users/selectedUser',
            userTrackerId: 'auth/userTrackerId',
            userProgressLevelLoading: 'users/userProgressLevelLoading',
            userProgressLevel: 'users/userProgressLevel',
            userGroup: 'auth/userGroup',
        }),

        userProgressLevelComputed: function () {
            let output = 0;
            if (this.userProgressLevel) {
                output = this.userProgressLevel * 100;
            }
            return output;
        },

        userProgressGroupName: function () {
            let output = null;
            
            // if is player
            if (!this.userIsManager) {
                let userGroup = this.getUserGroupNumeric();

                if (this.userIsDivision('cash')) {
                    userGroup = userGroup.split('NL');
                    output = "NL " + userGroup;
                }
                if (this.userIsDivision('mtt')) {
                    userGroup = userGroup.split('Nível');
                    output = "Nível " + userGroup;
                }
                if (this.userIsDivision('spins')) {
                    userGroup = userGroup.split('Nível');
                    output = "Nível " + userGroup;
                }
            }

            // if is admin 
            if (this.userIsManager) {
                let userGroup = this.getUserGroupNumericAsAdmin(this.selectedUser);
                let userTeamIds = [];

                if (this.selectedUser && this.selectedUser.teams) {
                    userTeamIds = this._.map(this.selectedUser.teams, 'id');
                }
    
                if (userTeamIds.includes(Vue.prototype.$team_ids.division_mtt)) {
                    output = "Nível " + userGroup;
                } else if (userTeamIds.includes(Vue.prototype.$team_ids.division_cash)) {
                    output = "NL " + userGroup;
                } else if (userTeamIds.includes(Vue.prototype.$team_ids.division_spins)) {
                    output = "Nível " + userGroup;
                } else {
                    return null;
                }

            }

            return output;
        },

        tooltipMessageAlert: function () {
            let output;

            let selectedUserGroup = this.selectedUser && this.selectedUser.group
                ? this.selectedUser.group.name
                : null;
            
            let name = this.userIsManager
                ? selectedUserGroup
                : this.userGroup['name'];
            
            output = this.userIsManager
                ? 'O Jogador está no grupo ' + name
                : 'Estás no grupo ' + name;
            
            return output;
        },


        tooltipMessageAlertNoData: function () {
            let output = this.userIsManager
                ? 'Certifica-te que o jogador está na equipa e/ou grupo correto.'
                : 'Certifica-te que estás na equipa e/ou grupo correto. Em caso de dúvida contacte a Gestão.';
            
            return output;
        },

    },

    mixins: [
        DataMixin,
        AuthMixin,
        UserMixin,
    ],

    created() {
        this.fetchData();
    },

    data() {
        return this.initialState();
    },

    methods: {
        initialState() {
            return {
                direction: null,
                dialogues: {
                    editUserLevel: false
                }
            };
        },

        editUserLevel(direction) {
            this.direction = direction;
            this.dialogues.editUserLevel = true;
        },

        async fetchData() {
            if (!this.userIsManager) {
                await this.$store.dispatch('users/getUserLevelProgress', this.userTrackerId);
            } else if (this.userIsManager && this.selectedUser) {
                await this.$store.dispatch('users/getUserLevelProgress', this.selectedUser.id);
            }
        },

        async reloadUser() {
            let userId = this.userIsManager ? this.selectedUser.id : this.userTrackerId;
            await this.$store.dispatch('users/getUser', userId);
            if (!this._.isNull(this.selectedUser) && !this.userProgressLevelLoading) {
                this.fetchData();
            }
        }
        
    },
}