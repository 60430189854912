
import Vue from 'vue';
import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth';
import RailMixin from '@/mixins/Rail';
import AlertError from '@/components/Alerts/AlertError/AlertError.vue';

export default {
	name: 'DialogLevelChangeUserMode',

	components: {
		AlertError,
	},

    computed: {
        ...mapGetters({
            groupChangeRoutineMode: 'users/groupChangeRoutineMode',
            error: 'users/error',
            humanError: 'users/humanError',
        }),
        toggleItemsWithLabels() {
            const screenWidth = window.innerWidth;
            const isMobile = screenWidth < 600;
            
            return this.toggleItems.map(item => ({
              label: isMobile ? item.label.replace('modo ', '') : item.label,
              value: item.value,
              color: item.color,
            }));
          },
    },

    mixins: [
		AuthMixin,
		RailMixin,
    ],

	props: {
        isOpen: {
            type: Boolean,
            required: true,
            default: false
        },
        data: {
            type: Object,
            required: true,
            default: {}
        },
    },

    data() {
        return this.initialState(); 
    },
    
    created(){
        this.populateForm();
    },

    watch:{
        data(newVal){
            this.populateForm();
        },
        isOpen(newVal){
            if(newVal){
                this.clearErrors();
                this.populateForm();
            }
        },
        mode() {
            if (this.error) {
                this.clearErrors();
            }
        }
    },

    methods:{
        closeDialog() {
            this.resetForm();
            this.$emit("close-dialog");
        },

        clearErrors() {
			this.$store.dispatch('users/clearErrors');
		},

        resetForm() {
            Object.assign(this.$data, this.initialState());
            this.$refs.formLevelChangeUserMode.reset();
        },

        initialState(){
            return{
                mode: 0,
                submitting: false,
                form: {
                    pct_profit_sessions: 0,
                    pct_profit_swaps: 0,
                    num_days: 0,
                },
                toggleItems: [
                    { label: 'modo normal', value: 2, color: 'success' },
                    { label: 'modo swaps', value: 3, color: 'success' },
                    { label: 'modo manual', value: 1, color: 'success' },
                ],
                rules: {
                    pct_profit_sessions: [v => !!v && v > 0 || 'Por favor, insira um número maior que 0.'],
                    pct_profit_swaps: [v => !!v && v > 0 || 'Por favor, insira um número maior que 0.'],
                    num_days: [v =>  Number(v) > 0 || 'Por favor, insira um número inteiro maior que 0.'],
                }
            }
        },

        getInfoAlert(mode){
            switch (mode) {
                case 2:
                    return 'O modo normal utiliza a rotina do sistema para identificar possíveis alterações de nível';
            
                case 3:
                    return 'O modo swaps requer informações sobre as porcentagens de lucro das Sessões e Swaps, além do número de dias para contabilizar a alteração de nível';

                case 1:
                    return 'Quando o modo manual está ativado, a rotina do sistema é desativada e a alteração de nível precisa ser feita manualmente. Para realizar alterações posteriores, será necessário fazer um novo registo';
            }
        },

        toggleSubmitting(){
            this.submitting = !this.submitting;
        },

        populateForm(){
            if(this.data && this.data.noData == true){
                this.mode = 1; // modo manual
                this.form.pct_profit_sessions = 0;
                this.form.pct_profit_swaps = 0;
                this.form.num_days = 0;
            }
            else if(Object.keys(this.data).length > 0){
                this.mode = this.data.values.mode;
                if(this.mode == Vue.prototype.$LevelChangeUserMode[2].key){
                    this.form.pct_profit_sessions = Number(this.data.values.pct_profit_sessions) * 100;
                    this.form.pct_profit_swaps = Number(this.data.values.pct_profit_swaps) * 100;
                    this.form.num_days = this.data.values.num_days;
                }else{
                    this.form.pct_profit_sessions = 0;
                    this.form.pct_profit_swaps = 0;
                    this.form.num_days = 0;
                }
            }
        },

        async submitForm() {
            if (!this.$refs.formLevelChangeUserMode.validate()) {
              return false;
            }
            this.toggleSubmitting();
            let { pct_profit_sessions, pct_profit_swaps, num_days } = this.form;

            let payload = {
                mode: this.mode,
                id: Number(this.$route.params.id),
            }
            
            if(this.mode == 3){
                payload.pct_profit_sessions = Number(pct_profit_sessions) / 100;
                payload.pct_profit_swaps = Number(pct_profit_swaps) / 100;
                payload.num_days = Number(num_days);
            }

            let result = await this.$store.dispatch('users/updateUserChangeRoutineMode', payload);

             this.toggleSubmitting();

            if (result === true) {
                this.$store.dispatch('UI/showSnackbar', {
                  message: 'Mudança de nível alterada com sucesso.',
                  color: 'success'
                });
        
                let params = {
                    id: this.$route.params.id,
                }
        
                await this.$store.dispatch('users/getUserChangeRoutineMode', params);
                this.$emit('form-submitted', payload);
                this.closeDialog();
            }
        },
    }
}