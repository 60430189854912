import { mapGetters } from 'vuex';
import Hero from '@/components/Hero/Hero.vue';
import LoadingSpinner from '@/components/LoadingSpinner/LoadingSpinner.vue';
import TableGames from '@/components/Scheduling/Tables/TableGames/TableGames.vue';

export default {
    name: 'GameGroups-Games',

    components: {
        Hero,
        LoadingSpinner,
        TableGames
    },

    data() {
        return this.initialState();
    },

    computed: {
        ...mapGetters({
            gameGroup: 'schedulingGameGroups/data',
            loading: 'schedulingGameGroups/loading',
        })
    },

    created() {
        this.init();
    },

    methods: {
        initialState() {
            return {
                groupId: null,
                groupName: null
            }
        },

        init() {
            if(this.$route.params.id) {
                this.groupId = parseInt(this.$route.params.id);
                this.getSchedulingGameGroup();
            }
        },

        async getSchedulingGameGroup() {
            await this.$store.dispatch('schedulingGameGroups/getById', this.groupId);
            this.groupName = this.gameGroup.name;
        },
    }
}