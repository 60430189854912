import { mapGetters } from 'vuex';
import EmptyStateNoData from '@/components/EmptyStateNoData';

export default {
    name: 'PieChartExpenses',

    components: {
        EmptyStateNoData,
    },

	props: {
		title: {
			type: String,
			required: false,
			default: '',
		},
		subtitle: {
			type: String,
			required: false,
			default: '',
		},
		data: {
			type: Array,
			required: false,
			default: () => [10, 20, 50, 40, 100],
		},
	},

    computed: {
        ...mapGetters({
            graph: 'railExpenses/graph',
            loading: 'railExpenses/loadingGraph',
            heroDatePickerValue: 'UI/heroDatePickerValue',
            themeDarkMode: 'UI/themeDarkMode',
        }),

        series: function () {
            return this.graph.series;
        },
        
        options: function () {
            return {
                chart: {
                    type: 'pie',
                    background: this.themeDarkMode ? 'dark' : 'light',
                },
                labels: this.graph.labels,
                dataLabels: {
                    formatter(val, opts) {
                        const name = opts.w.globals.labels[opts.seriesIndex]
                        return [name, val.toFixed(1) + '%']
                    },
                },
                legend: {
                    position: 'bottom',
                    labels: {
                        colors: this.themeDarkMode ? '#FFFFFF' : '#000000',
                        useSeriesColors: false
                    },
                },
                tooltip: {
                    theme: true,
                },
                colors: this.loading ? [] : this.graph.colors,
                theme: {
                    mode: this.themeDarkMode ? 'dark' : 'light',
                }
            };
        
            /**
            theme: {
                monochrome: {
                    enabled: true
                }
            },
            */
        },
    },

    watch: {
        heroDatePickerValue: function () {
            this.fetchData();
        },
    },

    data() {
        return this.initialState();
    },

    created() {
        this.initialize();
    },

    methods: {
        initialState() {
            return {};
        },

        initialize() {
            if (this._.isEmpty(this.graph)) {
                this.fetchData();
            }
        },

        async fetchData() {
            let payload = {
                params: {}
            }

            if (!this._.isEmpty(this.heroDatePickerValue)) {
                payload.params.dateBegin = this.heroDatePickerValue[0];
                payload.params.dateEnd = this.heroDatePickerValue[1];
            }

            await this.$store.dispatch('railExpenses/getGraph');
        },
    },
}