import Hero from '@/components/Hero/Hero.vue';
import TableModels from '@/components/Scheduling/Tables/TableModels/TableModels.vue';

export default {
    name: 'ModelsAll',

    components: {
        Hero,
        TableModels,
    }
}