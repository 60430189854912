import AuthMixin from '@/mixins/Auth';
import Hero from '@/components/Hero/Hero.vue';
import Snackbar from '@/components/Snackbar';
import TableNetworks from '@/components/Tables/TableNetworks/TableNetworks.vue';
import DialogCreateNetwork from '@/components/Dialog/DialogCreateNetwork/DialogCreateNetwork.vue';

export default {
    name: 'Networks',

    components: {
        Hero,
        Snackbar,
        TableNetworks,
        DialogCreateNetwork,
    },

    mixins: [
        AuthMixin,
    ],

    data() {
        return this.initialState();
    },

    async created() {
        await this.allowOnlyRoles(['rail_admin', 'rail_manager', 'tracker_admin', 'tracker_manager', 'super_coach']);
    },

    methods: {
        initialState() {
            return {
                dialogues: {
                    createNetwork: false,
                }
            }
        },

        openDialogCreateNetwork() {
            this.dialogues.createNetwork = true;
        },

        async refreshTableNetworks() {
            let payload = {
                params: {
                page: 1,
                itemsPerPage: 10,
                }
            };

            // API Call
            await this.$store.dispatch('networks/get', payload);
        }
    }
}