import Vue from 'vue';
import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth';
import TabSlider from '@/components/TabSlider/TabSlider.vue';
import CardReport from '@/components/Rail/CardReport/CardReport.vue';

export default {
    name: 'Report',

    mixins: [
        AuthMixin,
    ],

    components: {
        TabSlider,
        CardReport,
    },

    computed: {
        ...mapGetters({
            selectedReport: 'railReports/selectedReport',
            selectedReportLoading: 'railReports/selectedReportLoading',
        }),
    },

    async created() {
        this.fetchData();
    },

    methods: {
        /**
         * Validates if routeId is different from reportId
         * If yes, it triggers an API Call to retrieve the operation.
         * Else it redirect user to /rail/operations/
         */
        async fetchData() {
            let routeId = this.$route.params.id;
            let reportId = this.selectedReport ? this.selectedReport.id : null;

            // only runs if route name is "Report"
            if (this.$route.name == 'Report') {
                if (routeId != reportId) {
                    let result = await this.$store.dispatch('railReports/getReportById', routeId);
    
                    // if operation doesn't exist, redirect to /rail/reports/
                    if (!result) {
                        this.$router.push({ path: Vue.prototype.$tab_slider.tabs.reports });
                    }
                }
            }
        },
    },
}