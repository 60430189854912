import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

const state = {
    selectedBalance: null,
    selectedTargetBalance: null,
    cardBalance: null,
    tableBalancePage: 1,
    cardBalanceProfitEUR: null,
    cardBalanceProfitUSD: null,
};

const getters = {
    selectedBalance: (state) => state.selectedBalance,
    selectedTargetBalance: (state) => state.selectedTargetBalance,
    cardBalance: (state) => state.cardBalance,
    tableBalancePage: (state) => state.tableBalancePage,
    cardBalanceProfitEUR: (state) => state.cardBalanceProfitEUR,
    cardBalanceProfitUSD: (state) => state.cardBalanceProfitUSD,
};

const mutations = {
    setSelectedBalance(state, payload) {
        state.selectedBalance = payload;
    },
    setSelectedTargetBalance(state, payload) {
        state.selectedTargetBalance = payload;
    },
    setCardBalance(state, payload) {
        state.cardBalance = payload;
    },
    setTableBalancePage(state, payload) {
        state.tableBalancePage = payload;
    },
    setCardBalanceProfitEUR(state, payload) {
        state.cardBalanceProfitEUR = payload;
    },
    setCardBalanceProfitUSD(state, payload) {
        state.cardBalanceProfitUSD = payload;
    },
};

const actions = {};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
