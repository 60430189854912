import Vue from 'vue';
import Vuex from 'vuex';
import Vuetify from '@/plugins/vuetify'

import Data from '@/mixins/Data';

Vue.use(Vuex);

const state = {
  themeDarkMode: false,
  sidebarStatus: false,
  snackbarStatus: false,
  snackbarMsg: '',
  snackbarColor: '',
  snackbarMultiline: false,
  snackbarTimeout: 5000,
  cardStepperStep: 1,
  heroDatePickerValue: [],
  isAppEmbedded: false,
};

const getters = {
  themeDarkMode: state => state.themeDarkMode,
  sidebarStatus: state => state.sidebarStatus,
  snackbarStatus: state => state.snackbarStatus,
  snackbarMsg: state => state.snackbarMsg,
  snackbarColor: state => state.snackbarColor,
  snackbarMultiline: state => state.snackbarMultiline,
  snackbarTimeout: state => state.snackbarTimeout,
  cardStepperStep: state => state.cardStepperStep,
  heroDatePickerValue: state => state.heroDatePickerValue,
  isAppEmbedded: state => state.isAppEmbedded,
};

const mutations = {
  setThemeDarkMode: (state, payload) => {
    state.themeDarkMode = payload;
  },

  setSidebarStatus: (state, payload) => {
    state.sidebarStatus = payload;
  },

  setSnackbarStatus: (state, payload) => {
    state.snackbarStatus = payload;

    // Auto hide after time elapsed
    setTimeout(() => {
      state.snackbarStatus = false;
    }, state.snackbarTimeout);
  },

  setSnackbarMsg: (state, payload) => {
    state.snackbarMsg = payload;
  },

  setSnackbarColor: (state, payload) => {
    state.snackbarColor = payload;
  },

  setSnackbarMultiline: (state, payload) => {
    state.snackbarMultiline = payload;
  },

  setCardStepperStep: (state, payload) => {
    state.cardStepperStep = payload;
  },

  setHeroDatePickerValue: (state, payload) => {
    state.heroDatePickerValue = payload;
  },
  
  setAppEmbedded: (state, payload) => {
    state.isAppEmbedded = payload;
  },
};

const actions = {
  setThemeDarkMode({ commit }, value) {
    commit('setThemeDarkMode', value);    
    Vuetify.framework.theme.dark = value;
    $cookies.set(Vue.prototype.$cookie_names.dark_mode, value);
  },

  toggleThemeDarkMode({ commit }) {
    commit('setThemeDarkMode', ! state.themeDarkMode);    
    Vuetify.framework.theme.dark = state.themeDarkMode;
    $cookies.set(Vue.prototype.$cookie_names.dark_mode, state.themeDarkMode);
  },

  toggleSidebar({ commit }) {
    commit('setSidebarStatus', ! state.sidebarStatus);
  },

  showSnackbar({ commit }, payload) {
    // Defaults
    if ( payload.message == '' ) return;
    if ( ! payload.hasOwnProperty('snackbarMultiline') ) payload.snackbarMultiline = false;

    // Commit
    commit('setSnackbarMsg', payload.message);
    commit('setSnackbarColor', payload.color);
    commit('setSnackbarMultiline', payload.snackbarMultiline);
    commit('setSnackbarStatus', true);
  },

  setAppEmbedded({ commit }) {
    let originUrl = window.location.origin;
    let output = originUrl == Vue.prototype.$polarize_site;
    commit('setAppEmbedded', output);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};