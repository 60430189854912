var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('DialogViewTransfer',{attrs:{"title":_vm.titleViewTransfer,"type":_vm.typeViewTransfer,"dialog-open":_vm.dialogues.viewTransfer},on:{"close-dialog":function (e) { return _vm.closeDialogViewTransfer(e); }}}),_c('DialogDownloadCsv',{ref:"DialogDownloadCsv",attrs:{"type":_vm.getDownloadType,"is-open":_vm.dialogues.download.state,"message":_vm.dialogues.download.message},on:{"close-dialog":function($event){_vm.dialogues.download.state = false}}}),_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.title)+" "),(_vm.userIsRailManager && _vm.type == 'TRANSFERS')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-icon',{staticClass:"pl-2 pb-1"},[_vm._v("mdi-information-outline")])],1)]}}],null,false,1326258114)},[_c('span',[_vm._v(_vm._s(_vm.informationString))])]):_vm._e(),_c('v-spacer'),(_vm.userIsRailManager && _vm.type != 'MOVEMENTS')?_c('div',{staticClass:"userdropdown-width"},[_c('UserDropdown',{attrs:{"rules":[],"outlined":"","clearable":"","dense":"","label":"Pesquisar jogador","hide-details":""},model:{value:(_vm.user),callback:function ($$v) {_vm.user=$$v},expression:"user"}})],1):_vm._e(),(_vm.userIsRailManager)?_c('v-tooltip',{staticClass:"d-flex ml-2",attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.openDialogDownloadCsv()}}},[_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-download ")])],1)]}}],null,false,3973998341)},[_c('span',[_vm._v("Exportar tabela")])]):_vm._e()],1),_c('v-text-field',{staticClass:"mx-4 mb-3 d-none d-md-none",attrs:{"append-icon":"mdi-magnify","label":"Pesquisar","single-line":"","hide-details":""},on:{"input":function($event){return _vm.search()}},model:{value:(_vm.dataTable.search),callback:function ($$v) {_vm.$set(_vm.dataTable, "search", $$v)},expression:"dataTable.search"}}),(_vm.showTabs)?_c('v-tabs',{attrs:{"show-arrows":"","color":"accent"},model:{value:(_vm.tabs.current),callback:function ($$v) {_vm.$set(_vm.tabs, "current", $$v)},expression:"tabs.current"}},_vm._l((_vm.tableTabs),function(tab){return _c('v-tab',{key:tab,staticClass:"text--primary"},[_vm._v(" "+_vm._s(tab)+" ")])}),1):_vm._e(),_c('v-tabs-items',{model:{value:(_vm.tabs.current),callback:function ($$v) {_vm.$set(_vm.tabs, "current", $$v)},expression:"tabs.current"}},[_c('v-data-table',{attrs:{"headers":_vm.tableHeaders,"items":_vm.tableData,"search":_vm.dataTable.search,"server-items-length":_vm.tableDataTotal,"options":_vm.options,"footer-props":_vm.dataTable.footerProps,"loading":_vm.tableDataLoading,"loading-text":"A carregar...","must-sort":""},on:{"click:row":_vm.setActiveItem,"update:options":function (e) { return _vm.optionsComputed = e; }},scopedSlots:_vm._u([{key:"item.type_parties",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"color":item.isPending ? 'error' : 'success'}},on),[_vm._v(" "+_vm._s(_vm.getStatusIcon(item))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getStatusMessage(item)))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2"},on),[_vm._v(" "+_vm._s(_vm.getTypePartyIcon(item))+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.getTypePartyName(item))+" ")])])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(_vm.$moment(item.date.date).format('DD/MM/YYYY'))+" ")])]}}],null,true)},[_c('span',[_vm._v("Criada em: "+_vm._s(_vm.$moment(item.date.date).format('LLL')))]),_vm._v(" "),_c('br'),_c('span',[_vm._v("Última atualização: "+_vm._s(item.updatedAt ? _vm.$moment(item.updatedAt.date).format('LLL') : '----'))]),_vm._v(" "),_c('br')])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [(item.railOperation)?_c('router-link',{staticClass:"text-decoration-none cursor-pointer",attrs:{"to":'/rail/operations/' + item.railOperation.id}},[_c('v-chip',{staticClass:"w-100",attrs:{"color":_vm.getRailOperationColor(item.railOperation && item.railOperation.type),"label":""},on:{"click":function($event){return _vm.goToRailOperation(item)}}},[_vm._v(" "+_vm._s(_vm.getRailOperationName(item.railOperation && item.railOperation.type))+" "+_vm._s(_vm.getMakeUpReason(item.railOperation))+" ")])],1):_vm._e()]}},{key:"item.source",fn:function(ref){
var item = ref.item;
return [(item.source)?_c('div',{staticClass:"d-flex align-center"},[_c('NicknameCard',{attrs:{"image-u-r-l":item.source.network.imageURL,"network-name":_vm.getSourceName(item),"copy-to-clipboard":false,"network-id":_vm.getSourceId(item),"redirect-to":'players',"name":item.source.network.name,"verify-external-transfer":item.source}})],1):_vm._e()]}},{key:"item.target",fn:function(ref){
var item = ref.item;
return [(item.target)?_c('div',{staticClass:"d-flex align-center"},[_c('NicknameCard',{attrs:{"image-u-r-l":item.target.network.imageURL,"network-name":_vm.getTargetName(item),"copy-to-clipboard":false,"network-id":_vm.getTargetId(item),"redirect-to":'players',"name":item.target.network.name,"verify-external-transfer":item.target}})],1):_vm._e()]}},{key:"item.amountEUR",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('v-chip',[_c('div',{staticStyle:{"display":"flex","width":"100%","min-width":"127px","justify-content":"space-between"}},[(_vm.getAmountVariationIcon(item))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-1 align-content-center",attrs:{"small":"","color":_vm.getAmountVariationColor(item)}},on),[_vm._v(" "+_vm._s(_vm.getAmountVariationIcon(item))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getAmountVariationMessage(item)))])]):_vm._e(),_c('span',{staticClass:"mr-2"},[_vm._v(_vm._s(_vm.getCurrencySymbol(item)))]),_c('currency-input',{staticClass:"text-end",style:({ color: _vm.valueColorFinal(), width: '90px' }),attrs:{"value":item.amount ? item.amount.value : 0,"currency":null,"precision":2,"disabled":""}})],1)])],1)]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getRailOperationStatusColor(item.railOperation.status)}},[_vm._v(" "+_vm._s(_vm.getRailOperationStatusName(item.railOperation.status))+" ")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",on:{"click":function($event){return _vm.viewItem(item)}}},on),[_vm._v(" mdi-eye-outline ")])]}}],null,true)},[_c('span',[_vm._v("Ver")])])]}},{key:"no-data",fn:function(){return [(_vm._.isEmpty(_vm.tableData))?_c('EmptyStateNoData',{attrs:{"icon":"mdi-alert-circle-outline","title":_vm.emptyStateTitle,"message":_vm.emptyStateMessage}}):_vm._e()]},proxy:true},{key:"no-results",fn:function(){return [_c('EmptyStateNoData',{attrs:{"icon":"mdi-magnify","title":"Não foram encontrados resultados","message":"Ajuste a sua pesquisa para encontrar mais resultados"}})]},proxy:true}])},[_c('template',{slot:"progress"},[_c('v-progress-linear',{attrs:{"color":"accent","indeterminate":""}})],1)],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }