import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth.vue';
import AlertError from '@/components/Alerts/AlertError/AlertError.vue';

export default {
	name: 'DialogEditUserTeams',

	components: {
		AlertError,
	},

	props: {
		isOpen: { type: Boolean, required: true, default: false },
	},

	mixins: [
		AuthMixin,
	],
	
	data() {
		return this.initialState();
	},

	computed: {
		...mapGetters({
			userTeams: 'users/userTeams',
			selectedUser: 'users/selectedUser',
			humanError: 'users/humanError',
			error: 'users/error',
			teams: 'teams/teams',
		}),
	},

	watch: {
		isOpen: function () {
			// Clear user errors
			this.clearErrors();

			// when open, populate form.teams with user teams.
			if (this.isOpen && this.userTeams) {
				this.userTeams.forEach(element => {
					if ( ! this.form.teams.includes(element.id)) {
						this.form.teams.push(element.id);
					}
				});
			}
		},
	},

	async created() {
		// when created, populate v-select element with teams
		let payload = {
			params: {
				page: 1,
				itemsPerPage: -1
			}
		}
		await this.$store.dispatch('teams/get', payload);
	},

	methods: {
		initialState() {
			return {
				submitting: false,
				form: {
					teams: [],
				},
			};
		},

		async submit() {
			if (!this.$refs.formEditUserTeams.validate()) {
				return false;
			}

			// Toggle submit button
			this.toggleSubmitting();

			const payload = {
				id: this.selectedUser.id,
				teams: this.form.teams,
			};

			let result = await this.$store.dispatch('users/updateUserTeams', payload);

			// // Re-enable button
			this.toggleSubmitting();

			// On success
			if (result === true) {
				// Show success snackbar
				this.$store.dispatch('UI/showSnackbar', {
					message: 'Equipas do jogador atualizadas com sucesso.',
					color: 'success'
				});

				// refresh stats and user teams
				this.getUserStatistics();
				this.getUserTeams();
				
				// closes dialog
				this.closeDialog();
			}
		},

		/**
		 * Used to disable Submit button
		 */
		toggleSubmitting() {
			this.submitting = !this.submitting;
		},

		closeDialog() {
			this.$emit("close-dialog");
			this.reset();
			this.clearErrors();
		},

		reset() {
			this.form.teams = [];
		},
		
		clearErrors() {
			this.$store.dispatch('users/clearErrors');
		},

		getUserStatistics() {
            let payload = {
                id: this.selectedUser.id,
            }
            if (payload.id) {
				// reset last payload in order to refresh user statistics
				this.$store.commit('users/setLastPayloadUserStatistics', {});

				// api call to get user statistics
                this.$store.dispatch('users/getUserStatistics', payload);
            }
		},
		
        async getUserTeams() {
            await this.$store.dispatch('users/getUserTeams', this.selectedUser.id);
        },
	},
};