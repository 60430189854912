<template>
  <v-sheet
    class="pa-6 text-center"
    :color="classColor"
  >
    <v-sheet
      class="mx-auto rounded-circle d-flex justify-center ma-3"
      height="64"
      width="64"
      color="rgb(0, 0, 0, 0.08)"
    >
      <v-icon
        large
        :color="classIcon"
      >
        {{ icon }}
      </v-icon>
    </v-sheet>
    <h1 class="mb-2 title">
      {{ title }}
    </h1>
    <p class="body-2">
      {{ message }}
      <slot name="messageInput" />
    </p>
    <v-btn
      v-if="showAction && userIsPlayer || showAdminAction"
      :depressed="depressed"
      :outlined="outlined"
      class="ma-2"
      @click="execActionButton()"
    >
      {{ actionText }}
    </v-btn>
  </v-sheet>
</template>

<script>
import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth.vue';

export default {
    name: 'EmptyStateNoData',


    mixins: [
      AuthMixin,
    ],

    props: {
        icon: { type: String, required: false, default: 'a' },
        title: { type: String, required: false, default: 'a' },
        message: { type: String, required: false, default: '' },
        classColor: { type: String, required: false, default: '' },
        classIcon: { type: String, required: false, default: '' },
        showAction: { type: Boolean, required: false, default: false },
        showAdminAction: { type: Boolean, required: false, default: false },
        actionText: { type: String, required: false, default: 'Clica aqui' },
        actionAddSession: { type: Boolean, required: false, default: false },
        actionAddNicknames: { type: Boolean, required: false, default: false },
        reloadSingleUser: { type: Boolean, required: false, default: false },
        depressed: { type: Boolean, required: false, default: true },
        outlined: { type: Boolean, required: false, default: false },
    },

    data() {
      return {};
    },

    computed: {
      ...mapGetters({
        userTeams: 'users/userTeams',
      }),
    },

    methods: {
      execActionButton() {
        if (this.actionAddSession) {
          this.$emit('openDialogAddEditSession');
        } else if (this.actionAddNicknames) {
          this.$emit('openDialogCreateNickname');
        } else {
          this.$emit('action');
        }
      },
    }
}
</script>
