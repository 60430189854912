import { mapGetters } from 'vuex';
import Hero from '@/components/Hero/Hero.vue';
import LoadingSpinner from '@/components/LoadingSpinner/LoadingSpinner.vue';
import DialogCreateEditFactors from '@/components/Scheduling/Dialogs/DialogCreateEditFactors/DialogCreateEditFactors.vue';
import AuthMixin from '@/mixins/Auth.vue';

export default {
    name: 'Factors-Single',

    components: {
        Hero,
        LoadingSpinner,
        DialogCreateEditFactors,
    },

    mixins: [
      AuthMixin
    ],

    data() {
        return this.initialState();
    },

    computed: {
        ...mapGetters({
            factor: 'schedulingFactors/factor',
            loading: 'schedulingFactors/loading',
            error: 'schedulingFactors/error'
        }),
        cardWidth() {
            switch(this.$vuetify.breakpoint.name) {
                case 'xs': return '100%'
                default: return '50%'
            }
        }
    },

    created() {
        this.getFactor(this.$route.params.id);
    },

    methods: {
        initialState() {
            return {
                dialogs: {
                    createEditFactor: false,
                },
                factorToEdit: undefined,
            };
        },

        getBooleanIcon(atr) {
            return (atr) ? "mdi-check" : "mdi-close";
        },

        toggleDialog(name) {
            this.dialogs[name] = !this.dialogs[name];
        },

        openEditDialog(factor) {
            this.factorToEdit = this._.clone(factor);
            this.toggleDialog('createEditFactor');
        },

        updateFactor() {
            this.toggleDialog('createEditFactor');
        },

        async getFactor(id) {
            await this.$store.dispatch('schedulingFactors/getById', id);
        },
    }
}