export default {
  name: 'DashboardBtn',
  
  components: {
  },
  
  mixins: [
  ],
  
  props: {
    title: { type: String, required: false, default: 'title', },
    caption: { type: String, required: false, default: '', },
    icon: { type: String, required: false, default: 'mdi-home', },
    link: { type: String, required: false, default: '', },
    isNew: { type: Boolean, required: false, default: false },
  },
  
  data() {
    return this.initialState();
  },

  methods: {
    initialState() {
        return {
            data: null
        }
    },
  }
};