import Vue from 'vue';
import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth.vue';
import UserMixin from '@/mixins/User.vue';
import DataMixin from '@/mixins/Data.vue';
import TableSessions from '@/components/Rail/TableSessions/TableSessions.vue';
import TableRequests from '@/components/Rail/TableRequests/TableRequests.vue';
import UserGroupProgress from '@/components/UserGroupProgress/UserGroupProgress.vue';
import DashboardMenu from '@/components/Menus/DashboardMenu/DashboardMenu.vue';
import DashboardBtn from '@/components/Menus/DashboardMenu/DashboardBtn.vue';
import CardPlayerGoals from '@/components/Rail/CardPlayerGoals/CardPlayerGoals.vue';
import PlayerGroupBadge from '@/components/PlayerGroupBadge/PlayerGroupBadge.vue';
import CalendarWidget from '@/components/CalendarWidget/CalendarWidget.vue';
import DialogAlertLevelChange from '@/components/Dialog/DialogAlertLevelChange/DialogAlertLevelChange.vue';
import LevelProgress from '../../../components/LevelProgress/LevelProgress.vue';

export default {
    name: 'DashboardUser',

    components: {
        TableSessions,
        TableRequests,
        UserGroupProgress,
        DashboardMenu,
        DashboardBtn,
        CardPlayerGoals,
        PlayerGroupBadge,
        CalendarWidget,
        DialogAlertLevelChange,
        LevelProgress,
    },

    mixins: [
        AuthMixin,
        UserMixin,
        DataMixin,
    ],

    data() {
        return this.initialState();
    },

    async created() {
        await this.fetchData();
        await this.checkLevelChange();
    },

    computed: {
        ...mapGetters({
            userDisplayName: 'auth/userDisplayName',
            userImgCutout: 'auth/userImgCutout',
            userTeams: 'auth/userTeams',
            userGroup: 'auth/userGroup',
            statistics: 'railStatistics/data',
            statisticsLoading: 'railStatistics/loading',
            userTrackerId: 'auth/userTrackerId',
            heroDatePickerValue: 'UI/heroDatePickerValue',
            userStatistics: 'users/userStatistics',
            userStatisticsLoading: 'users/userStatisticsLoading',
            selectedUser: 'users/selectedUser',
            teams: 'teams/teams',
            groupChangeRoutineMode: 'users/groupChangeRoutineMode',
        }),

        imageCutout: function () {
            let placeholder = require('@/assets/images/players/player-example.png');
            return this.userImgCutout ?  this.userImgCutout : placeholder;
        },

        cardPlayerSessions: function () {
            let output = {
                percentageValue: this.userStatistics && this.userStatistics.MONTHLY_SESSIONS_PCT ? this.userStatistics.MONTHLY_SESSIONS_PCT.percentageValue : 0,
                total: this.userStatistics && this.userStatistics.MONTHLY_SESSIONS_PCT ? this.userStatistics.MONTHLY_SESSIONS_PCT.total : 0,
                value: this.userStatistics && this.userStatistics.MONTHLY_SESSIONS_PCT ? this.userStatistics.MONTHLY_SESSIONS_PCT.value : 0,
                monthProgress: this.userStatistics && this.userStatistics.MONTH_PROGRESS ? this.userStatistics.MONTH_PROGRESS.percentageValue : 0,
                customGoal: this.userStatistics && this.userStatistics.MONTHLY_SESSIONS_PCT ? this.userStatistics.MONTHLY_SESSIONS_PCT.customGoal : false,
                pastTeamGoal: this.userStatistics && this.userStatistics.MONTHLY_SESSIONS_PCT ? this.userStatistics.MONTHLY_SESSIONS_PCT.pastTeamGoal : false,
                pastTeamGoalMessage: this.userStatistics && this.userStatistics.MONTHLY_SESSIONS_PCT ? this.getTeamGoalMessageByTeamID(this.userStatistics.MONTHLY_SESSIONS_PCT.teamConsidered) : ''
            }
            return output;
        },

        cardPlayerEvents: function () {
            let output = {
                percentageValue: this.userStatistics && this.userStatistics.MONTHLY_EVENTS_PCT ? this.userStatistics.MONTHLY_EVENTS_PCT.percentageValue : 0,
                total: this.userStatistics && this.userStatistics.MONTHLY_EVENTS_PCT ? this.userStatistics.MONTHLY_EVENTS_PCT.total : 0,
                value: this.userStatistics && this.userStatistics.MONTHLY_EVENTS_PCT ? this.userStatistics.MONTHLY_EVENTS_PCT.value : 0,
                monthProgress: this.userStatistics && this.userStatistics.MONTH_PROGRESS ? this.userStatistics.MONTH_PROGRESS.percentageValue : 0,
                customGoal: this.userStatistics && this.userStatistics.MONTHLY_EVENTS_PCT ? this.userStatistics.MONTHLY_EVENTS_PCT.customGoal : false,
                pastTeamGoal: this.userStatistics && this.userStatistics.MONTHLY_EVENTS_PCT ? this.userStatistics.MONTHLY_EVENTS_PCT.pastTeamGoal : false,
                pastTeamGoalMessage: this.userStatistics && this.userStatistics.MONTHLY_EVENTS_PCT ? this.getTeamGoalMessageByTeamID(this.userStatistics.MONTHLY_EVENTS_PCT.teamConsidered) : ''
            }
            return output;
        },

        cardPlayerEventText: function () {
            let output = {
                title: 'N.º de Eventos',
                subtitle: 'N.º de eventos registados neste mês'
            }

            if (!this._.isEmpty(this.userTeams)) {
                let userTeamsMapID = this.userTeams.map(e => e.id)
                if (userTeamsMapID.includes(Vue.prototype.$team_division_cash_id)) {
                    output = {
                        title: 'N.º de mãos',
                        subtitle: 'N.º de mãos jogadas este mês'
                    } 
                } else {
                    output = {
                        title: 'N.º de torneios',
                        subtitle: 'N.º de torneios jogados este mês'
                    } 
                }
            }

            return output;
        },
    },

    methods: {
        initialState() {
            return {
                data: null,
                userGroupLevel: $cookies.get(Vue.prototype.$cookie_names.group_level) || null,
                showLevelProgress: null,
                shouldRenderPlayerGroupBadge: false,
                dialogAlertLevelChange: {
                    open: false,
                    levelUp: false,
                    data: {},
                    title: '',
                }
            }
        },

        checkLevelChange() {
            this.dialogAlertLevelChange.open = false;

            // If not logging-in
            if (! this._.isNull(this.userGroupLevel)) {
                // If user changed level
                if (this.userGroupLevel != this.selectedUser.group.level) {
                    this.dialogAlertLevelChange.open = true;

                    // Configure level up / down
                    this.dialogAlertLevelChange.levelUp = parseFloat(this.selectedUser.group.level) > parseFloat(this.userGroupLevel);

                    this.dialogAlertLevelChange.title = this.dialogAlertLevelChange.levelUp
                                                        ? 'Subiste de nível 🎉'
                                                        : 'Desceste de nível 😪';
                }
            }
        },

        async checkPayloadChangeRoutine() {
            let output = false;

            let payloadChangeRoutine = {
                id: this.userTrackerId,
            }

            await this.$store.dispatch('users/getUserChangeRoutineMode', payloadChangeRoutine);

            if(this.groupChangeRoutineMode && this.groupChangeRoutineMode.mode){
                output = this.groupChangeRoutineMode.mode != Vue.prototype.$LevelChangeUserMode[0].key;
            }

            return output;
        },

        checkUserTeams() {
            let userTeamIds = this.selectedUser && !this._.isEmpty(this.selectedUser.teams)
                ? this.selectedUser.teams.map(e => e.id)
                : []
            
            return userTeamIds.includes(Vue.prototype.$team_division_mtt_id);
        },

        async fetchData() {
            let payloadTeams = {
                params: {
                    page: 1,
                    itemsPerPage: -1
                }
            }
            
            await this.$store.dispatch('teams/get', payloadTeams);
            await this.$store.dispatch('users/getUser', this.userTrackerId);
            
            this.dialogAlertLevelChange.data = this.selectedUser;

            if(this.checkUserTeams()){
                this.showLevelProgress = await this.checkPayloadChangeRoutine();
            }else{
                this.showLevelProgress = false;
            }

            let payload = {
                id: this.userTrackerId,
                body: {}
            }

            if (this.userTrackerId) {
                await this.$store.dispatch('users/getUserStatistics', payload);
            }

            return true;
        },

        getTeamGoalMessageByTeamID(id) {
            if (this._.isNull(id) || this._.isUndefined(id)) {
                return '';
            }

            let findTeam = this.teams.find(e => e.id == id);
            let team = !this._.isUndefined(findTeam) ? findTeam.name : 'atual';

            return 'A utilizar objetivos da equipa ' + team;
        },

        getUserChangeRoutine() {
            let payload = {
                id: this.userTrackerId
            }
            this.$store.dispatch('users/getUserChangeRoutineMode', payload);
        },
    }
}
