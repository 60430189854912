import { mapGetters } from 'vuex';
import AlertError from '@/components/Alerts/AlertError/AlertError.vue';

export default {
    name: 'DrawerCreateEditCategory',

    components: {
        AlertError,
    },

    props: {
        isOpen: {
            type: Boolean, 
            required: true,
            default: false,
        },
        action: {
            type: String,
            required: true,
            default: 'create',
        }
    },

    data() {
        return this.initialState();
    },

    created() {},

    computed: {
        ...mapGetters({
			selectedCategory: 'eventsCategoriesCalendar/selected',
            error: 'eventsCategoriesCalendar/error',
            humanError: 'eventsCategoriesCalendar/humanError',
        }),
        drawerState: {
            get: function () {
                return this.isOpen;
            },
            set: function (value) {
                if(!value)
                    this.closeDrawer()
            }
        },

        changeStyle() {
            return {
              backgroundColor: this.category.color,
              cursor: 'pointer',
              height: '25px',
              width: '25px',
              borderRadius: '100%',
              transition: 'border-radius 200ms ease-in-out'
            }
        }

    },

    watch: {
        isOpen: function () {
            this.setupSelectedCategory();
        }   
    },

    methods: {
        initialState() {
            return {
                submitting: false,
                category: {
                    color: '#546E7A',
                    can_see_participant_list: true,
                    can_unregister: true,
                    default_duration: null,
                    default_capacity: null,
                    warning_show: false,
                    warning_text: null,
                },
                panelsActive: 0,
                colorPickerMenu: false,
                rules: {
                    title: [ v => !! v || 'Por favor preencha o título da categoria.'],
                    color: [ v => !! v || 'Por favor preencha a cor da categoria.'],
                    default_duration: [
                        v => _.isInteger(Number(v)) || 'Por favor introduza um número válido. Exemplo: 60',
                        v => !v ? true : v > 0 || 'Por favor introduza um número superior a 0.',
                        v => v < 1440 || 'Por favor introduza um valor inferior a 1 dia.'
                    ],
                    default_capacity: [ v => _.isInteger(Number(v)) || 'Por favor introduza um número válido.'],
                    asana_satisfaction_form: [ v => !v ? true : /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/.test(v) || 'Por favor preencha o formulário com um link válido.'],
                    asana_attendance_form: [v => !v ? true : /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/.test(v) || 'Por favor preencha o formulário com um link válido.'],
                }
            };
        },

        closeDrawer() {
            if(this.$refs.formCategory)
                this.$refs.formCategory.resetValidation();

            this.$emit('close-drawer');
            this.resetForm();
        },

        async submit() {
            // Validate form
            if (!this.$refs.formCategory.validate()) {
				return false;
			}

            // toggle state of submit btn 
            this.toggleSubmitting();

            // Define store action
            const storeAction = this.action === 'edit'
                                ? 'eventsCategoriesCalendar/update'
                                : 'eventsCategoriesCalendar/create';

            let payload = {
                body: {
                    title: this.category.title, 
                    description: this.category.description,
                    color: this.category.color,
                    default_capacity: this.category.default_capacity,
                    default_duration: this.category.default_duration,
                    can_see_participant_list: this.category.can_see_participant_list,
                    can_unregister: this.category.can_unregister,
                    asana_satisfaction_form: this.category.asana_satisfaction_form,
                    asana_attendance_form: this.category.asana_attendance_form,
                    warning_show: this.category.warning_show,
                    warning_text: this.category.warning_text,
                }
            }

            // Send 'categoryID' if this is an edit
            if (this.action === 'edit') {
                payload.body.id = this.selectedCategory.id;
            }

            let res = await this.$store.dispatch(storeAction, payload)

            // toggle state of submit btn 
            this.toggleSubmitting();

            if(res) {
                // select all categories
                this.$emit('select-all-categories')
                // fetch events
                this.$emit('fetch-events');
                // Close drawer
                this.closeDrawer();

                let message = this.action == 'edit'
                                ? 'Categoria editada com sucesso.'
                                : 'Categoria criada com sucesso.';

                // User feedback
                this.$store.dispatch('UI/showSnackbar', {
                    message: message,
                    color: 'success',
                });
           }
        },

        /**
         * Setup inputs with selected Category
         */
        setupSelectedCategory()
        {
            if (this.action === 'edit') {
                this.category = this.selectedCategory;
            } else {
                this.resetForm();
            }
        },

        resetForm() {
            Object.assign(this.$data, this.initialState())
            // this.$refs.formCategory.resetValidation()

            // clear errors
            this.$store.dispatch('eventsCategoriesCalendar/clearError');
        },

        /**
         * Toggle loading state of submit button
         * 
         * @returns {void}
         */
        toggleSubmitting() {
            this.submitting = ! this.submitting;    
        }
},
    
}
