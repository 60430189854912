export default {
    name: 'DialogDelete',

    props: {
        isOpen: { type: Boolean, required: true, default: false },
        editedItem: { type: Object, required: false, default: null },
    },

    methods: {
        deleteItem() {
            // Prepare request
            let requestParameters = new URLSearchParams();
            let id = this.editedItem.id_rulesets;
            let requestUrl = this.$url_api + 'v1/rulesets.php?id=' + id;
            
            // Ruleset configuration parameters
            requestParameters.append('ruleset', id);
      
            // API call
            axios({
              method: 'DELETE',
              url: requestUrl
            })
            .then((response) => {
              //console.log(response);
            })
            .catch(error => console.log(error))
            .then(() => {
                this.$emit('force-update');
            });

            // UI
            this.close();
        },

        close() {      
            this.$emit('close-dialog');
        },
    },
}