var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('DialogAddLevelChange',{attrs:{"is-open":_vm.dialogues.editUserLevel,"player-single":_vm.selectedUser,"direction":_vm.direction},on:{"close-dialog":function($event){_vm.dialogues.editUserLevel = false}}}),_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-img',_vm._g(_vm._b({staticClass:"my-1 mx-4",attrs:{"max-width":"92","src":require('@/assets/groups_badges/' + _vm.badgePathComputed),"value":_vm.userProgressLevelComputed,"loading":_vm.userProgressLevelLoading}},'v-img',attrs,false),on)),(_vm.userIsManager)?_c('div',{staticClass:"d-flex align-center justify-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","x-small":"","disabled":_vm._.isNull(_vm.selectedUser.group.previous)},on:{"click":function($event){return _vm.editUserLevel('DOWN')}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-minus ")])],1)]}}],null,true)},[_c('span',[_vm._v("Descer")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","x-small":"","disabled":_vm._.isNull(_vm.selectedUser.group.next)},on:{"click":function($event){return _vm.editUserLevel('UP')}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-plus ")])],1)]}}],null,true)},[_c('span',[_vm._v("Subir")])])],1):_vm._e()]}}])},[(! _vm._.isNull( _vm.userIsRailManager ? _vm.getUserGroupNumericAsAdmin(_vm.selectedUser) : _vm.getUserGroupNumeric() ))?_c('span',[_vm._v(" "+_vm._s(_vm.tooltipMessageAlert)+" ")]):_c('span',[_vm._v(_vm._s(_vm.tooltipMessageAlertNoData))])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }