import Vue from 'vue';
import { mapGetters } from 'vuex';
import UserMixin from '@/mixins/User.vue';
import AuthMixin from '@/mixins/Auth.vue';
import DataMixin from '@/mixins/Data.vue';
import Hero from '@/components/Hero/Hero.vue';
import TableLiveTournaments from '@/components/Tables/TableLiveTournaments/TableLiveTournaments.vue';

export default {
    name: 'LiveEvents-Single',

    components: {
        Hero,
        TableLiveTournaments,
    },

    mixins: [
        UserMixin,
        AuthMixin,
        DataMixin,
    ],

    async created() {
        // Load necessary page data
        
        if (this.$route.params.id && this._.isNull(this.selectedEvent)) {
            await this.fetchData();
            if(!this._.isNull(this.events)){
                let event = this.events.find(event => event.id === this.$route.params.id);
                this.$store.commit('liveEvents/setSelectedEvent', event);
            }
        }
    },

    computed: {
        ...mapGetters({
            events: 'liveEvents/events',
            selectedEvent: 'liveEvents/selectedEvent',
            error: 'liveEvents/error',
            humanError: 'liveEvents/humanError',
        }),
    },

    methods: {
        async fetchData() {
         await this.$store.dispatch('liveEvents/fetchEvents')
        },
    }
}