import { mapGetters } from 'vuex';
import BarChartAvgBuyIn from '@/components/Charts/BarChartAvgBuyIn/BarChartAvgBuyIn.vue';

export default {
    name: 'CardAvgBuyIn',

    props: {
    },

    components: {
        BarChartAvgBuyIn,
    },

    data() {
        return this.initialState();
    },

    computed: {
        ...mapGetters({
            dataAverageBuyIn: 'railStatistics/dataAverageBuyIn',
            loadingDataAverageBuyIn: 'railStatistics/loadingDataAverageBuyIn',
        }),

        titleTooltip: function () {
            return 'Dados calculados via SharkScope';
        },

        title: function () {
            return 'Buy-in médio';
        },

        subtitle: function () {
            return 'Compara o teu buy-in médio com o do teu nível';
        },

        abi: function () {
            let abiDiffPct = this.dataAverageBuyIn && this.dataAverageBuyIn.ABI_PLAYER_VS_GROUP ? this.dataAverageBuyIn.ABI_PLAYER_VS_GROUP.data.diffPct : 0;
            let output = {
                isAbove: abiDiffPct > 0,
                difference: parseInt(abiDiffPct * 100)
            }
            return output;
        },

        avgBuyInMessage: function () {
            let word = this.abi.isAbove ? 'acima' : 'abaixo';
            let output = Math.abs(this.abi.difference) + '% ' + word + ' do buy-in médio do nível';
            return output;
        },

        avgBuyInIcon: function () {
            let output = this.abi.isAbove
                ? 'mdi-arrow-up'
                : 'mdi-arrow-down';
            return output;
        },

        avgBuyInMessageColor: function () {
            let output = this.abi.isAbove
                ? 'green'
                : 'red';
            return output;
        },

        abiPlayer: function () {
            return this.dataAverageBuyIn.ABI_PLAYER ? this.dataAverageBuyIn.ABI_PLAYER.value.USD.value : 0;
        },
    },

    watch: {

    },

    methods: {
        initialState() {
            return {

            };
        },
    }
}   
