var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticClass:"pa-4",attrs:{"height":"600"}},[(_vm.hasEvents)?_c('div',{staticClass:"overflow-y-auto mt-2 fill-height",staticStyle:{"max-height":"550px"}},_vm._l((_vm.eventsByDay),function(day,date){return _c('v-simple-table',{key:date,scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',[_c('div',{staticClass:"d-flex justify-space-between"},[_c('span',[_vm._v(" "+_vm._s(_vm.dayTitleString(date))+" ")]),_c('span',[_vm._v(" "+_vm._s(_vm.weekdayString(date))+" ")])])])])]),_c('tbody',_vm._l((day),function(event){return _c('tr',{key:event.id},[_c('td',[_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"mr-2",staticStyle:{"width":"120px"}},[_vm._v(" "+_vm._s(_vm.eventHoursString(event))+" ")]),_c('div',{staticClass:"cursor-pointer"},[_c('router-link',{attrs:{"to":_vm.getEventUrl(event)}},[_c('v-badge',{staticClass:"mr-6",attrs:{"color":event.color,"dot":""}}),_c('span',[_vm._v(" "+_vm._s(event.name)+" ")])],1)],1)]),_c('div',{staticClass:"d-none d-lg-flex"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('router-link',{attrs:{"to":_vm.getEventUrl(event)}},[_c('v-btn',_vm._g(_vm._b({attrs:{"x-small":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-eye")])],1)],1)]}}],null,true)},[_c('span',[_vm._v("Ver evento")])]),(_vm.userIsCalendarManager)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"x-small":"","icon":""},on:{"click":function($event){return _vm.editEvent(event)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar evento")])]):_vm._e(),(_vm.userIsCalendarManager)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"x-small":"","icon":""},on:{"click":function($event){return _vm.deleteEvent(event)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Remover evento")])]):_vm._e()],1)])])])}),0)]},proxy:true}],null,true)})}),1):_c('div',{staticClass:"d-flex justify-center align-center fill-height"},[_c('span',{staticClass:"overline"},[_vm._v(" Não existem eventos planeados para o mês selecionado. ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }