import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

const state = {
    data: null,
    loading: true,
    error: null,
};

const getters = {
    data: state => state.data,
    loading: state => state.loading,
    error: state => state.error,
    selectedModel: (state) => state.selectedModel,
};

const mutations = {
    setData: (state, payload) => { state.data = payload; },
    setLoading: (state, payload) => { state.loading = payload; },
    setError: (state, payload) => { state.error = payload; },
    setSelectedModel: (state, payload) => { state.selectedModel = payload; },
};

const actions = {
    async get({ commit, dispatch }) {
        dispatch('reset');
        let output = false;
        let request_url = Vue.prototype.$scheduling_api + 'models/';

        return axios.get(request_url)
            .then(function (response) {
                output = true;
                commit('setData', response.data.data);
            })
            .catch(function (error) {
                commit('setError', error.response ? error.response.data.error.message : error);
                output = false;
            })
            .then(function () {
                commit('setLoading', false);
                return output;
            });
    },

    async getById({ commit }, payload) {
        if(payload.updateLoading) {
            commit('setLoading', true);
        }

        let output = false;
        let request_url = Vue.prototype.$scheduling_api + 'models/' + payload.id;

        return axios.get(request_url)
            .then(function (response) {
                output = true;
                commit('setData', response.data.data);
            })
            .catch(function (error) {
                commit('setError', error.response ? error.response.data.error.message : error);
                output = false;
            })
            .then(function () {
                if(payload.updateLoading) {
                    commit('setLoading', false);
                }
                return output;
            });
    },

    async create({ commit, dispatch }, payload) {
        dispatch('reset');
        let output = false;
        let request_url = Vue.prototype.$scheduling_api + 'models/';
        let requestConfig = {
            method: 'POST',
            url: request_url,
            headers: {
                'Content-Type': 'application/json'
            },
            data: payload
        }

        return axios(requestConfig)
            .then(function (response) {
                output = true;
                commit('setData', response.data.data);
                commit('setSelectedModel', response.data.data);
            })
            .catch(function (error) {
                commit('setError', error.response ? error.response.data.error.message : error);
                output = false;
            })
            .then(function () {
                commit('setLoading', false);
                return output;
            });
    },

    async update({ commit, dispatch }, {id, payload}) {
        dispatch('reset');
        let output = false;
        let request_url = Vue.prototype.$scheduling_api + 'models/' + id;

        var requestConfig = {
            method: 'PUT',
            url: request_url,
            headers: {
                'Content-Type': 'application/json'
            },
            data: payload
        }

        return axios(requestConfig)
            .then(function (response) {
                output = true;
                commit('setData', response.data.data);
            })
            .catch(function (error) {
                commit('setError', error.response ? error.response.data.error.message : error);
                output = false;
            })
            .then(function () {
                commit('setLoading', false);
                return output;
            });
    },

    async delete({ commit, dispatch }) {
        dispatch('reset');
        let output = false;
        let request_url = Vue.prototype.$scheduling_api + 'models/' + id;

        var requestConfig = {
            method: 'DELETE',
            url: request_url,
            headers: {
                'Content-Type': 'application/json'
            }
        }

        return axios(requestConfig)
            .then(function (response) {
                // 204 response status represents success
                output = response.status == 204;
                if (!output) {
                    commit('setError', response.data.message);
                }
            })
            .catch(function (error) {
                commit('setError', error.response ? error.response.data.error.message : error);
                output = false;
            })
            .then(function () {
                commit('setLoading', false);
                return output;
            });
    },

    async evaluateGameGroups({commit}, id) {
        //commit('setLoading', true);
        let output = false;
        let request_url = Vue.prototype.$scheduling_api + 'models/' + id + '/gamegroups/recalc';
        var requestConfig = {
            method: 'PATCH',
            url: request_url,
            headers: {
                'Content-Type': 'application/json'
            }
        }

        return axios(requestConfig)
        .then(function (response) {
            output = true;
            commit('setData', response.data.data);
        })
        .catch(function (error) {
            commit('setError', error.response ? error.response.data.error.message : error);
            output = false;
        })
        .then(function () {
            commit('setLoading', false);
            return output;
        });
    },

    clearError({ commit }) {
        commit('setError', null);
    },

    // Reset store to its initial state
    reset({ commit }) {
        commit('setData', null);
        commit('setLoading', true);
        commit('setSelectedModel', null);
        commit('setError', null);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};