import Vue from 'vue';
import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth.vue';
import RailMixin from '@/mixins/Rail.vue';
import CardStepper from '@/components/CardStepper/CardStepper.vue';
import CardStepperNotes from '@/components/CardStepper/CardStepperNotes/CardStepperNotes.vue';
import NicknameDropdown from '@/components/Rail/NicknameDropdown/NicknameDropdown.vue';
import RowTransfer from '@/components/Rail/RowTransfer/RowTransfer.vue';
import InputFinancial from '@/components/InputFinancial/InputFinancial.vue';
import AlertError from '@/components/Alerts/AlertError/AlertError.vue';
import DatePicker from '@/components/DatePicker/DatePicker.vue';

export default {
  name: 'CardTransferToSelf',

  components: {
    CardStepper,
    CardStepperNotes,
    NicknameDropdown,
    RowTransfer,
    InputFinancial,
    AlertError,
    DatePicker,
  },

  mixins: [
    AuthMixin,
    RailMixin,
  ],

  computed: {
    ...mapGetters({
      error: 'railTransfers/error',
      nicknamesAllMine: 'nicknames/nicknamesAllMine',
      humanError: 'railTransfers/humanError',
      teamFilter: 'teams/teamFilter',
      selectedTransfer: 'railTransfers/selectedTransfer',
    }),

    selectedCurrency() {
      let output = null;

      if (this.form.sourceAccount) {
        output = this.form.sourceAccount.network.currency;
      }

      return output;
    },

    selectedNetwork() {
      let output = [];

      if (this.form.sourceAccount) {
        let isWallet = this.form.sourceAccount.network.isWallet;
        let nicknames = this.nicknamesAllMine.filter(e => !e.network.isWallet);
        if (!isWallet) {
          nicknames.forEach(element => output.push(element.network.id));
        }
        return output;
      }
    },
  },

  data() {
    return this.initialState();
  },

  methods: {
    initialState() {
      return {
        messageDateAlert: 'Data definida para o dia anterior. Altera-a se tencionas registar a transferência no dia de hoje.',
        form: {
          date: this.$moment().format('YYYY-MM-DD'),
          amount: {
            value: null,
          },
          sourceAccount: null,
          targetAccount: null,
          observation: null,
          gyazoLink: null,
          fileName: [],
          isTransferPending: true
        },
        formValidations: [
          false,
          true,
        ],
        rules: {
          date: [
            (v) => !!v || 'Por favor introduza uma data',
            (v) => (!!v && (v <= this.$moment().format('YYYY-MM-DD'))) || 'Não podes inserir datas futuras',
          ],
          amount: [
            (v) => !!v || "Por favor introduza o montante",
            (v) => !Number.isNaN(v) || 'Insira um valor numérico',
          ],
          nicknameDropdown: [(v) => !!v || 'Por favor introduza uma carteira',],
        },
        cardTitles: [
          'Registar transferência',
          'Finalizar'
        ]
      }
    },

    /**
     * Validates a specific form step.
     * Assumes form steps are named 'formStep_x' where x is a Int 0 - maxStep-1.
     * 
     * @param {Int} step Identifier of the form step to validate.
     * @returns {Void}
     */
    validateStep(step) {
      const formStepRef = 'formStep_' + (step);
      if (this.$refs[formStepRef]) {

        // Reset validation for step 1 to enforce lazy-validation
        this.resetValidationFormStep1();

        // Update formValidation[step] in a way the DOM will be reactive
        Vue.set(
          this.formValidations,
          step - 1,
          this.$refs[formStepRef].validate()
        );
      }
      // clean errors if exists
      if (this.error) {
        this.$store.dispatch('railTransfers/clearError');
      }
    },

    resetValidationFormStep1() {
      if (this.$refs.formStep_1) {
        this.$refs.formAmountRef.resetValidation();
      }
    },

    // retrieve fields from CardStepperNotes component
    getInfoFromCardStepperNotes(payload) {
      if (payload) {
        this.form.gyazoLink = payload.gyazoLink;
        this.form.fileName = payload.filename;
      }
    },

    getTransfers() {
      let transfers = []

      transfers.push({
        source: this.form.sourceAccount,
        target: this.form.targetAccount,
        amount: this.form.amount,
        isPending: this.form.isTransferPending
      })

      return transfers
    },

    setBalance() {
      this.$store.commit(
        'railOverview/setSelectedBalance',
        this.form.sourceAccount
      );

      this.$store.commit(
        'railOverview/setCardBalance',
        this.form.amount.value
      );
    },


    setSelectedNickname(pos) {
      if (pos == 0) {
        this.$store.commit(
          'railOverview/setSelectedBalance',
          this.form.sourceAccount
        );
      } else {
        this.$store.commit(
          'railOverview/setSelectedTargetBalance',
          this.form.targetAccount
        );
      }
    },

    async submit() {
      // Validate form
      if (!this.$refs.formTransferToSelf.validate()) {
        return false;
      }

      // Toggle submit button
      this.$refs.CardStepper.toggleSubmitting();

      /**
       * Formulate payload formData
       */

      let payload = new FormData();
      payload.append('date', this.form.date);
      payload.append('source', this.form.sourceAccount.id);
      payload.append('target', this.form.targetAccount.id);
      payload.append('amount', this.form.amount.value);

      // Add 'observation' field if necessary
      if (this.form.observation) {
        payload.append('observation[description]', this.form.observation);
      }

      if (!this._.isEmpty(this.form.fileName)) {
        payload.append('screenshot', this.form.fileName[0]);
      }

      if (this.form.gyazoLink) {
        payload.append('observation[url]', this.form.gyazoLink);
      }

      // Dispatch to store
      let result = await this.$store.dispatch('railTransfers/create', payload);

      // Re-enable btn
      if (this.$refs.CardStepper) {
        this.$refs.CardStepper.toggleSubmitting();
      }

      // On success
      if (result === true) {

        let payloadRefresh = {
          params: {}
        }

        // if team exists and isManagement, Add team parameter to payload.params
        if (this.teamFilter && this.userHasRailAccessDivisionDropdown) {
          payloadRefresh.params.team = this.teamFilter;
        }

        let id = this.selectedTransfer.id;
        let url = Vue.prototype.$tab_slider.tabs.requests + id;

        // Refresh Requests Transfers and Balances
        this.updateRequestsTransfersAndBalances(payloadRefresh);

        // Show success snackbar
        this.$store.dispatch('UI/showSnackbar', {
          message: 'Transferência registada com sucesso.',
          color: 'success'
        });

        // change hasFinished state in order to change component
        this.$store.dispatch('TabSlider/setHasFinished', true);

        // change to operation single view
        this.$router.push({ path: url });

        this.resetForm();
      }
    },

    resetForm() {
      // clean selected Balance
      this.$store.commit(
        'railOverview/setSelectedBalance',
        null
      );
      this.$store.commit(
        'railOverview/setSelectedTargetBalance',
        null
      );

      // Refresh rail data
      this.updateRequestsTransfersAndBalances();

      Object.assign(this.$data, this.initialState());
      //this.$refs.formTransferToSelf.reset();
    },
  }
}