export default {
    name: 'TableEntries',

    props: {
        currency: { type: String, required: true, default: "$" },
        entries: { type: Array, required: true }
    },

    created() {
    },

    data() {
        return this.initialState();
    },

    methods: {
        initialState() {
            return {
                headers: [
                    { text: 'Jogador', value: 'nickname.userName', sortable: true },
                    { text: 'Nickname', value: 'nickname.nickname', sortable: true },
                    { text: 'Posição', value: 'position', sortable: true, align: 'center' },
                    { text: 'Reentradas', value: 'multiEntries', sortable: true, align: 'center' },
                    { text: 'Rebuys', value: 'rebuys', sortable: true, align: 'center' },
                    { text: 'Investimento', value: 'investment', sortable: true },
                    { text: 'PnL', value: 'pnl', sortable: true },
                    { text: 'ROI', value: 'roi', sortable: true }
                ]
            };
        },
    }
}