import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth.vue';
import RailMixin from '@/mixins/Rail.vue';

export default {
    name: 'SessionLiveMetadata',

    components: {

    },

    props: {
        action: {
			type: String,
			required: true,
			default: 'create',
			validator: function (value) {
				const allowedActions = ['create', 'update']
				return allowedActions.includes(value);
			},
		},
    },

    data() {
        return this.initialState();
    },

    mixins: [
        AuthMixin,
        RailMixin,
    ],

    computed: {
        ...mapGetters({
            liveEvents: 'railSessions/liveEvents',
            selectedLiveEvent: 'railSessions/selectedLiveEvent',
            selectedSession: 'railSessions/selectedSession',
            loadingSelectedLiveEvent: 'railSessions/loadingSelectedLiveEvent'
        }),
    },

    watch: {
        metadata: {
            handler() {
                this.emitChange();
            },
            deep: true
        },
        selectedSession() {
            this.populateMetadata();
        }
    },

    created() {
        if (this.action == 'update') {
            if (this.selectedSession && this.selectedSession.metaLive) {
                this.populateMetadata();

                let liveID = this.metadata[0] ? this.metadata[0].live : null;
                if (liveID) {
                    this.fetchEvents(liveID);
                }
            }
        } else {
            this.metadata = [
                {
                    live: null,
                    event: null,
                    buyin: null,
                    day: null,
                    prize: 0,
                    num_players_begin_1: null,
                    num_players_begin_2: null,
                    num_players_end_1: null,
                    num_players_end_2: null,
                    num_players_total: null,
                    difficulty: null,
                    passed_next_day: false,
                    hours_played: null,
                }
            ]
        }
        this.emitChange();
    },

    methods: {
        initialState() {
            return {
                metadata: [
                    {
                        live: null,
                        event: null,
                        buyin: null,
                        day: null,
                        prize: 0,
                        num_players_begin_1: null,
                        num_players_begin_2: null,
                        num_players_end_1: null,
                        num_players_end_2: null,
                        num_players_total: null,
                        difficulty: null,
                        passed_next_day: false,
                        hours_played: null,
                    }
                ],
                form: {
                    tournament_difficulties: [
                        { value: 1, name: 'Fácil' },
                        { value: 2, name: 'Médio' },
                        { value: 3, name: 'Difícil' }
                    ],
                },
                rules: {
                    live: [(v) => !!v || 'Selecione um evento'],
                    event: [(v) => !!v || 'Selecione um torneio'],
                    buyin: [(v) => !!v || 'Selecione um buyin'],
                    day: [(v) => !!v || 'Selecione um dia'],
                    prize: [],
                    num_players_begin_1: [(v) => !!v || 'Introduza um valor numérico'],
                    num_players_begin_2: [(v) => !!v || 'Introduza um valor numérico'],
                    num_players_end_1: [(v) => !!v || 'Introduza um valor numérico'],
                    num_players_end_2: [(v) => !!v || 'Introduza um valor numérico'],
                    num_players_total: [(v) => !!v || 'Introduza um valor numérico'],
                    difficulty: [(v) => !!v || 'Selecione uma dificuldade'],
                    hours_played: [(v) => !!v || 'Introduza um número de horas jogadas'],
                },
                showError: false,
            }
        },

        // add an empty entry to metadata
        add() {
            this.metadata.push({
                live: null,
                event: null,
                buyin: null,
                day: null,
                prize: 0,
                num_players_begin_1: null,
                num_players_begin_2: null,
                num_players_end_1: null,
                num_players_end_2: null,
                num_players_total: null,
                difficulty: null,
                passed_next_day: false,
                hours_played: null,
            });

            // Scroll user down
            setTimeout(() => {
                document.getElementById('divList').scrollTop = document.getElementById('divList').scrollHeight;
            }, 1);
        },

        /**
         * Removes entry from metadata based on item id and index position
         * @param {Object} item 
         * @param {Number} index 
         */
        remove(item, index) {
            let indexPosition;

            indexPosition = this.metadata.findIndex(e => e == index);

            // remove entry from metadata
            this.metadata.splice(indexPosition, 1);

        },

        reset() {
            Object.assign(this.$data, this.initialState());
            this.$refs.form.reset();
        },

        emitChange() {
            this.$emit('change', this.generateOutput());
            this.$emit('input', this.generateOutput());
        },

        /**
         * @returns {Array} 
         */
        generateMetadata() {
            let output = [];

            if (!this._.isEmpty(this.metadata)) {
                output = this.metadata.map(element => {
                    // add validation if necessary
                    if (element && element.live && element.event && element.buyin) {
                        return {
                            live: element.live,
                            event: element.event && element.event.id ? element.event.id : element.event,
                            buyin: element.buyin,
                            day: element.day,
                            prize: element.prize,
                            num_players_begin_1: element.num_players_begin_1,
                            num_players_begin_2: element.num_players_begin_2,
                            num_players_end_1: element.num_players_end_1,
                            num_players_end_2: element.num_players_end_2,
                            num_players_total: element.num_players_total,
                            difficulty: element.difficulty,
                            passed_next_day: element.passed_next_day,
                            hours_played: element.hours_played,
                        };
                    }
                })
            }

            // removes all undefined values
            output = output.filter(element => element != undefined);
            return output;
        },

        generateOutput() {
            return this.generateMetadata();
        },

        toggleShowError() {
            this.showError = !this.showError;
        },

        setShowError(v) {
            this.showError = v;
        },

        prePopulateFields(item) {
            if (item) {
                this.metadata = this.metadata.map(e => {
                    if (e.event.id == item.id) {
                        return {
                            live: e.live,
                            event: e.event,
                            buyin: item.buyin,
                            day: e.day,
                            prize: e.prize,
                            num_players_begin_1: e.num_players_begin_1,
                            num_players_begin_2: e.num_players_begin_2,
                            num_players_end_1: e.num_players_end_1,
                            num_players_end_2: e.num_players_end_2,
                            num_players_total: e.num_players_total,
                            difficulty: e.difficulty,
                            passed_next_day: e.passed_next_day,
                            hours_played: e.hours_played,
                        }
                    } else {
                        return e;
                    }
                })
            }
        },

        fetchEvents(id) {
            this.$store.dispatch('railSessions/getSingleLiveEvent', { id: id });
        },

        populateMetadata() {
            if (this.selectedSession && this.selectedSession.metaLive) {
                let sessionMetaLive = this._.cloneDeep(this.selectedSession);
                this.metadata = sessionMetaLive.metaLive.map(e => {
                    e.live = e.live[0] ? e.live[0].id : e.live;
                    e.event = e.event[0] ? e.event[0].id : e.event;
                    return e;
                })
            }
        }
    }
}
