export default {
    name: 'Alert',

    props: {
        label: {
            type: String,
            required: false,
            default: 'Alert info',
        },
        type: {
            type: String,
            required: false,
            default: 'warning',
        },
        icon: {
            type: String,
            required: false,
            default: 'mdi-alert-outline',
        },
        color: {
            type: String,
            required: false,
            default: 'warning',
        }
    },

}   
