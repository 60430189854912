var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"form"},[(_vm.selectedSwapPool && _vm.selectedSwapPool.paid && _vm.action == 'edit')?_c('Alert',{staticClass:"mt-3",attrs:{"label":"Este Swap Pool encontra-se finalizado.","color":"blue"}}):_vm._e(),_c('v-data-table',{style:({'max-height': _vm.maxHeight, 'overflow':'auto'}),attrs:{"id":"TableSingleSwapTournament","headers":_vm.dataTableHeaders,"items":_vm.poolListComputed,"items-per-page":_vm.dataTable.itemsPerPage,"height":"100%","fixed-header":"","dense":"","hide-default-footer":"","caption":"","calculate-widths":""},scopedSlots:_vm._u([{key:"item.user",fn:function(ref){
var item = ref.item;
return [(_vm.action == 'view')?_c('span',[(_vm.handleUsername(item.user.id))?_c('span',[_vm._v(" "+_vm._s(_vm.handleUsername(item.user.id))+" ")]):_c('span',{staticClass:"align-center d-flex"},[_c('v-chip',{staticClass:"mx-1 cursor-pointer white--text",attrs:{"small":"","color":"red"}},[_vm._v(" Jogador desconhecido ")])],1)]):_c('span',{staticClass:"d-flex w-100"},[_c('UserDropdown',{attrs:{"disabled":"","hide-details":"","dense":""},model:{value:(item.user),callback:function ($$v) {_vm.$set(item, "user", $$v)},expression:"item.user"}})],1)]}},{key:"item.played",fn:function(ref){
var item = ref.item;
return [(_vm.action == 'view')?_c('span',[(item.played)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" mdi-check ")]):_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-close ")])],1):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"text-center d-flex justify-center"},on),[_c('v-checkbox',{staticClass:"d-flex mb-0 align-center",model:{value:(item.played),callback:function ($$v) {_vm.$set(item, "played", $$v)},expression:"item.played"}})],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getTooltipPlayMessage(item))+" ")])])]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [(_vm.action == 'view')?_c('span',[_c('v-chip',[_c('span',{staticClass:"mr-2"},[_vm._v("$")]),_c('currency-input',{staticClass:"text-end",style:({ color: _vm.valueColorFinal(), width: '90px' }),attrs:{"value":item.amount,"currency":null,"precision":2,"allow-negative":"","disabled":""}})],1)],1):_c('div',{staticClass:"d-flex align-center"},[_c('input-financial',{ref:"inputFinancial",style:({ color: _vm.valueColorFinal() }),attrs:{"allow-negative":"","vbind":{
            label:'Buyin',
            outlined: true,
            disabled: !item.played,
            solo: false,
            dense: true,
            flat: false,
            backgroundColor: 'transparent',
            hideDetails: true,
            readonly: _vm.action == 'view',
            rules: _vm.rules.buyin,
          }},model:{value:(item.amount),callback:function ($$v) {_vm.$set(item, "amount", _vm._n($$v))},expression:"item.amount"}})],1)]}},{key:"item.deal",fn:function(ref){
          var item = ref.item;
return [(_vm.action == 'view')?_c('span',[_vm._v(" "+_vm._s(_vm.stringToPercentage(item.deal.value))+" % ")]):_c('div',[_c('v-select',{staticClass:"d-none",attrs:{"disabled":!item.played,"rules":[]},model:{value:(item.deal),callback:function ($$v) {_vm.$set(item, "deal", $$v)},expression:"item.deal"}}),_c('PercentageDropdown',{ref:"PercentageDropdown",attrs:{"disabled":!item.played,"outlined":"","dense":"","hide-details":""},model:{value:(item.deal),callback:function ($$v) {_vm.$set(item, "deal", $$v)},expression:"item.deal"}})],1)]}},{key:"item.prizeScreenshot",fn:function(ref){
          var item = ref.item;
return [(_vm.action == 'view')?_c('span',[_c('v-btn',{attrs:{"disabled":_vm._.isNull(item.prizeScreenshot),"elevation":"0","icon":""},on:{"click":function($event){return _vm.openDialogViewAttachment(item.prizeScreenshot)}}},[(item.prizeScreenshot)?_c('v-icon',[_vm._v(" mdi-eye ")]):_c('v-icon',[_vm._v(" mdi-eye-off ")])],1)],1):_c('v-text-field',{attrs:{"disabled":!item.played,"rules":_vm.rules.prizeScreenshot,"label":"Gyazo","type":"url","placeholder":"ex: www.gyazo.com/j1b23k","outlined":"","dense":"","hide-details":""},model:{value:(item.prizeScreenshot),callback:function ($$v) {_vm.$set(item, "prizeScreenshot", $$v)},expression:"item.prizeScreenshot"}})]}},{key:"item.transferAmount",fn:function(ref){
          var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-chip',_vm._g({staticClass:"my-2",attrs:{"color":item.transferAmount.value > 0 ?'success' : 'error'}},on),[_c('span',{staticClass:"mr-2"},[_vm._v("$")]),_c('currency-input',{staticClass:"text-end",style:({ color: 'white', width: '70px' }),attrs:{"value":item.transferAmount.value,"currency":null,"precision":2,"disabled":""}})],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getTooltipTransferMessage(item.transferAmount.value))+" ")])]),_c('span')]}},{key:"item.prize",fn:function(ref){
          var item = ref.item;
return [(_vm.action == 'view')?_c('span',{staticClass:"w-100"},[_c('v-chip',[_c('span',{staticClass:"mr-2"},[_vm._v("$")]),_c('currency-input',{staticClass:"text-end",style:({ color: _vm.valueColorFinal(), width: '90px' }),attrs:{"value":item.prize,"currency":null,"precision":2,"allow-negative":"","disabled":""}})],1)],1):_c('div',{staticClass:"d-flex align-center"},[_c('input-financial',{ref:"inputFinancial",style:({ color: _vm.valueColorFinal(), width: '60px' }),attrs:{"allow-negative":"","vbind":{
            label:'Prémio',
            outlined: true,
            disabled: !item.played,
            solo: false,
            dense: true,
            flat: false,
            backgroundColor: 'transparent',
            hideDetails: true,
            readonly: _vm.action == 'view',
            rules: [],
          }},model:{value:(item.prize),callback:function ($$v) {_vm.$set(item, "prize", _vm._n($$v))},expression:"item.prize"}})],1)]}},{key:"item.pnl.value",fn:function(ref){
          var item = ref.item;
return [_c('span',{staticClass:"w-100",attrs:{"color":"success"}},[_c('v-chip',{staticClass:"my-2",attrs:{"color":item.pnl.value > 0 ? 'success' : 'error'}},[_c('span',{staticClass:"mr-2"},[_vm._v("$")]),_c('currency-input',{staticClass:"text-end",style:({ color: 'white', width: '90px' }),attrs:{"value":item.pnl.value,"currency":null,"precision":2,"allow-negative":"","disabled":""}})],1)],1)]}},{key:"item.overallShare",fn:function(ref){
          var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.stringToPercentage(item.overallShare))+" %")])]}},{key:"item.ownShare",fn:function(ref){
          var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.stringToPercentage(item.ownShare))+" %")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }