import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import StoreMixin from '@/mixins/Store.vue';

Vue.use(Vuex);
Vue.use(StoreMixin);

const state = {
    swaps: [],
    swapGroups: [],
    swapGroupsDropdown: [],
    swapGroupStatistics: [],
    swapPools: [],
    swapsPending: [],
    swapsFinalized: [],
    swapsRejected: [],
    selectedSwap: null,
    selectedSwapGroup: null,
    selectedSwapPool: null,
    selectedSwapPoolClose: null,
    selectedSwapGroupClose: null,
    loading: false,
    swapsPendingLoading: false,
    swapsFinalizedLoading: false,
    swapsRejectedLoading: false,
    swapGroupsLoading: false,
    swapPoolsLoading: false,
    swapGroupStatisticsLoading: false,
    swapGroupsDropdownLoading: false,
    selectedSwapPoolCloseLoading: false,
    selectedSwapGroupCloseLoading: false,
    error: null,
    humanError: null,
    errorGroupUsers: null,
    humanErrorGroupUsers: null,
    swapsTotal: null,
    swapsPendingTotal: null,
    swapsFinalizedTotal: null,
    swapsRejectedTotal: null,
    swapGroupsTotal: null,
    swapPoolsTotal: null,
};

const getters = {
    swaps: (state) => state.swaps,
    swapGroups: (state) => state.swapGroups,
    swapGroupsDropdown: (state) => state.swapGroupsDropdown,
    swapGroupStatistics: (state) => state.swapGroupStatistics,
    swapPools: (state) => state.swapPools,
    swapsPending: (state) => state.swapsPending,
    swapsFinalized: (state) => state.swapsFinalized,
    swapsRejected: (state) => state.swapsRejected,
    selectedSwap: (state) => state.selectedSwap,
    selectedSwapGroup: (state) => state.selectedSwapGroup,
    selectedSwapPool: (state) => state.selectedSwapPool,
    selectedSwapPoolClose: (state) => state.selectedSwapPoolClose,
    selectedSwapGroupClose: (state) => state.selectedSwapGroupClose,
    swapsTotal: (state) => state.swapsTotal,
    swapsPendingTotal: (state) => state.swapsPendingTotal,
    swapsFinalizedTotal: (state) => state.swapsFinalizedTotal,
    swapsRejectedTotal: (state) => state.swapsRejectedTotal,
    swapGroupsTotal: (state) => state.swapGroupsTotal,
    swapPoolsTotal: (state) => state.swapPoolsTotal,
    loading: (state) => state.loading,
    swapsPendingLoading: (state) => state.swapsPendingLoading,
    swapsFinalizedLoading: (state) => state.swapsFinalizedLoading,
    swapsRejectedLoading: (state) => state.swapsRejectedLoading,
    swapGroupsLoading: (state) => state.swapGroupsLoading,
    swapPoolsLoading: (state) => state.swapPoolsLoading,
    swapGroupStatisticsLoading: (state) => state.swapGroupStatisticsLoading,
    swapGroupsDropdownLoading: (state) => state.swapGroupsDropdownLoading,
    selectedSwapPoolCloseLoading: (state) => state.selectedSwapPoolCloseLoading,
    selectedSwapGroupCloseLoading: (state) => state.selectedSwapGroupCloseLoading,
    error: (state) => state.error,
    humanError: (state) => state.humanError,
    errorGroupUsers: (state) => state.errorGroupUsers,
    humanErrorGroupUsers: (state) => state.humanErrorGroupUsers,
};

const mutations = {
    set(state, payload) {
        state.swaps = payload;
    },
    setSwapGroups(state, payload) {
        state.swapGroups = payload;
    },
    setSwapGroupsDropdown(state, payload) {
        state.swapGroupsDropdown = payload;
    },
    setSwapGroupStatistics(state, payload) {
        state.swapGroupStatistics = payload;
    },
    setSwapPools(state, payload) {
        state.swapPools = payload;
    },
    setPendingSwaps(state, payload) {
        state.swapsPending = payload;
    },
    setFinalizedSwaps(state, payload) {
        state.swapsFinalized = payload;
    },
    setRejectedSwaps(state, payload) {
        state.swapsRejected = payload;
    },
    setSelectedSwap(state, payload) {
        state.selectedSwap = payload;
    },
    setSelectedSwapGroup(state, payload) {
        state.selectedSwapGroup = payload;
    },
    setSelectedSwapPool(state, payload) {
        state.selectedSwapPool = payload;
    },

    setSelectedSwapPoolClose(state, payload) {
        state.selectedSwapPoolClose = payload;
    },

    setSelectedSwapGroupClose(state, payload) {
        state.selectedSwapGroupClose = payload;
    },

    addSwapPool(state, payload) {
        if (!state.swapPools || !Array.isArray(state.swapPools))
            state.swapPools = [];
        state.swapPools.unshift(payload);
    },

    // Loadings
    setLoading(state, payload) {
        state.loading = payload;
    },
    setPendingSwapsLoading(state, payload) {
        state.swapsPendingLoading = payload;
    },
    setFinalizedSwapsLoading(state, payload) {
        state.swapsFinalizedLoading = payload;
    },
    setRejectedSwapsLoading(state, payload) {
        state.swapsRejectedLoading = payload;
    },
    setSwapGroupsLoading(state, payload) {
        state.swapGroupsLoading = payload;
    },
    setSwapPoolsLoading(state, payload) {
        state.swapPoolsLoading = payload;
    },
    setSwapGroupStatisticsLoading(state, payload) {
        state.swapGroupStatisticsLoading = payload;
    },
    setSwapGroupsDropdownLoading(state, payload) {
        state.swapGroupsDropdownLoading = payload;
    },
    setSelectedSwapPoolCloseLoading(state, payload) {
        state.selectedSwapPoolCloseLoading = payload;
    },
    setSelectedSwapGroupCloseLoading(state, payload) {
        state.selectedSwapGroupCloseLoading = payload;
    },
    // Totals
    setSwapsTotal(state, payload) {
        state.swapsTotal = payload;
    },
    setPendingSwapsTotal(state, payload) {
        state.swapsPendingTotal = payload;
    },
    setFinalizedSwapsTotal(state, payload) {
        state.swapsFinalizedTotal = payload;
    },
    setRejectedSwapsTotal(state, payload) {
        state.swapsRejectedTotal = payload;
    },
    setSwapGroupsTotal(state, payload) {
        state.swapGroupsTotal = payload;
    },
    setSwapPoolsTotal(state, payload) {
        state.swapPoolsTotal = payload;
    },

    // Errors
    setError(state, payload) {
        state.error = payload;
    },
    setHumanError(state, payload) {
        state.humanError = payload;
    },
    setErrorGroupUsers(state, payload) {
        state.errorGroupUsers = payload;
    },
    setHumanErrorGroupUsers(state, payload) {
        state.humanErrorGroupUsers = payload;
    },
};

const actions = {
    async get({ commit }, payload) {
        // declare variables
        let setItems;
        let setTotalItems;
        let setLoadingItems;
        let handleMutation = StoreMixin.methods.handleRailSwapsMutations();
        let transferParams = "&transferType[0]=" + Vue.prototype.$transfer_types.P_P + "&transferType[1]=" + Vue.prototype.$transfer_types.P_M + "&transferType[2]=" + Vue.prototype.$transfer_types.M_P

        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/rails/transfers/?type=' + Vue.prototype.$rail_ops.operations.swap + transferParams;

        // if status is empty , delete it. (TableTransfers, 1st tab)
        if (payload.params.status === '') delete payload.params.status;

        // Formulate URL with query string with generateQueryParamsUrl mixin function
        if (payload && payload.params) {
            handleMutation = StoreMixin.methods.handleRailSwapsMutations(payload.params.status);
            request_url = StoreMixin.methods.generateQueryParamsUrl(request_url, payload.params);
        }

        // mutations that will be used to populate states based on status parameter
        setItems = handleMutation.swaps;
        setTotalItems = handleMutation.total;
        setLoadingItems = handleMutation.loading;

        // clear states
        commit('setError', null);
        commit(setItems, []);
        commit(setTotalItems, 0);
        commit(setLoadingItems, true);

        // Execute request & return
        let output = false;

        return axios.get(request_url)
            .then((response) => {
                output = response.data.success;

                if (output) {
                    commit(setTotalItems, response.data.total);
                    commit(setItems, response.data.data);
                }
            })
            .catch(function (error) {
                commit('setError', error.response ? error.response.data.message : error);
                commit('setHumanError', error.response ? error.response.data.human_message : error);
                output = false;
            })
            .then(function () {
                commit(setLoadingItems, false);
                return output;
            });
    },

    async getSwapById({ commit, dispatch }, payload) {
        // clear state
        commit('setSelectedSwap', null);

        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/rails/transfers/' + payload;

        // Execute request & return
        let output = false;

        return axios.get(request_url)
            .then((response) => {
                output = response.data.success;
                if (output) {
                    commit('setSelectedSwap', response.data.data);
                } else {
                    commit('setError', response.data.message);
                    commit('setHumanError', response.data.human_message);
                }
            })
            .catch(function (error) {
                commit('setError', error.response ? error.response.data.message : error);
                commit('setHumanError', error.response ? error.response.data.human_message : error);
                output = false;
            })
            .then(function () {
                return output;
            });
    },

    async getSwapGroups({ commit, dispatch }, payload) {

        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/rails/swaps/groups';

        // Configure URL parameters
        if (payload && payload.params) {
            request_url = StoreMixin.methods.generateQueryParamsUrl(request_url, payload.params);
        }

        // clear states
        commit('setError', null);
        commit('setSwapGroups', []);
        commit('setSwapGroupsTotal', 0);
        commit('setSwapGroupsLoading', true);

        // Execute request & return
        let output = false;

        return axios.get(request_url)
            .then((response) => {
                output = response.data.success;
                if (output) {
                    commit('setSwapGroupsTotal', response.data.total);
                    commit('setSwapGroups', response.data.data);
                }
            })
            .catch(function (error) {
                commit('setError', error.response ? error.response.data.message : error);
                commit('setHumanError', error.response ? error.response.data.human_message : error);
                output = false;
            })
            .then(function () {
                commit('setSwapGroupsLoading', false);
                return output;
            });
    },

    async getSwapGroupsDropdown({ commit, dispatch }, payload) {

        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/rails/swaps/groups';

        // Configure URL parameters
        if (payload && payload.params) {
            request_url = StoreMixin.methods.generateQueryParamsUrl(request_url, payload.params);
        }

        // clear states
        commit('setError', null);
        commit('setSwapGroupsDropdown', []);
        commit('setSwapGroupsDropdownLoading', true);

        // Execute request & return
        let output = false;

        return axios.get(request_url)
            .then((response) => {
                output = response.data.success;
                if (output) {
                    commit('setSwapGroupsDropdown', response.data.data);
                }
            })
            .catch(function (error) {
                commit('setError', error.response ? error.response.data.message : error);
                commit('setHumanError', error.response ? error.response.data.human_message : error);
                output = false;
            })
            .then(function () {
                commit('setSwapGroupsDropdownLoading', false);
                return output;
            });
    },

    async getSwapGroupStatistics({ commit, dispatch }, payload) {

        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/rails/swaps/groups/' + payload.id + '/stats';

        // Configure URL parameters
        if (payload && payload.params) {
            request_url = StoreMixin.methods.generateQueryParamsUrl(request_url, payload.params);
        }

        // clear states
        commit('setError', null);
        commit('setSwapGroupStatisticsLoading', true);
        commit('setSwapGroupStatistics', []);

        // Execute request & return
        let output = false;

        return axios.get(request_url)
            .then((response) => {
                output = response.data.success;
                if (output) {
                    commit('setSwapGroupStatistics', response.data.data);
                }
            })
            .catch(function (error) {
                commit('setError', error.response ? error.response.data.message : error);
                commit('setHumanError', error.response ? error.response.data.human_message : error);
                output = false;
            })
            .then(function () {
                commit('setSwapGroupStatisticsLoading', false);
                return output;
            });
    },

    async getSwapGroupById({ commit, dispatch }, payload) {

        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/rails/swaps/groups/?swapGroup=' + payload;

        // clear states
        commit('setError', null);
        commit('setSelectedSwapGroup', null);

        // Execute request & return
        let output = false;

        return axios.get(request_url)
            .then((response) => {
                output = response.data.success;

                if (output) {
                    commit('setSelectedSwapGroup', response.data.data[0]);
                }
            })
            .catch(function (error) {
                commit('setError', error.response ? error.response.data.message : error);
                commit('setHumanError', error.response ? error.response.data.human_message : error);
                output = false;
            })
            .then(function () {
                commit('setSwapGroupsLoading', false);
                return output;
            });
    },

    async getSwapPoolFinalize({ commit, dispatch }, payload) {

        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/rails/swaps/pools/'+ payload.id +'/finalize/';

        // clear states
        commit('setError', null);
        commit('setSelectedSwapPoolClose', null);
        commit('setSelectedSwapPoolCloseLoading', true);

        // Execute request & return
        let output = false;

        return axios.get(request_url)
            .then((response) => {
                output = response.data.success;
                if (output) {
                    commit('setSelectedSwapPoolClose', response.data.data);
                }
            })
            .catch(function (error) {
                commit('setError', error.response ? error.response.data.message : error);
                commit('setHumanError', error.response ? error.response.data.human_message : error);
                output = false;
            })
            .then(function () {
                commit('setSelectedSwapPoolCloseLoading', false);
                return output;
            });
    },

    async getSwapGroupFinalize({ commit, dispatch }, payload) {

        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/rails/swaps/groups/'+ payload.id +'/finalize/';

        // clear states
        commit('setError', null);
        commit('setSelectedSwapGroupClose', null);
        commit('setSelectedSwapGroupCloseLoading', true);

        // Execute request & return
        let output = false;

        return axios.get(request_url)
            .then((response) => {
                output = response.data.success;
                if (output) {
                    commit('setSelectedSwapGroupClose', response.data.data);
                }
            })
            .catch(function (error) {
                commit('setError', error.response ? error.response.data.message : error);
                commit('setHumanError', error.response ? error.response.data.human_message : error);
                output = false;
            })
            .then(function () {
                commit('setSelectedSwapGroupCloseLoading', false);
                return output;
            });
    },

    async setSwapPoolFinalize({ commit, dispatch }, payload) {
        dispatch('clearError');

        // Configure request
        const request_url = Vue.prototype.$url_api + 'v2/rails/swaps/pools/'+ payload.id +'/finalize/';

        var requestConfig = {
            method: 'POST',
            url: request_url,
            headers: {
                'Content-Type': 'application/json'
            },
            data: payload.params
        }

        // Execute request & return
        let output = false;

        return axios(requestConfig)
            .then(function (response) {
                output = response.data.success;

                if (!output) {
                    commit('setError', response.data.message);
                    commit('setHumanError', response.data.human_message);
                }
            })
            .catch(error => {
                commit('setError', error.response ? error.response.data.message : error);
                commit('setHumanError', error.response ? error.response.data.human_message : error);
                output = false;
            })
            .then(function () {
                return output;
            });
    },

    async setSwapGroupFinalize({ commit, dispatch }, payload) {
        dispatch('clearError');

        // Configure request
        const request_url = Vue.prototype.$url_api + 'v2/rails/swaps/groups/'+ payload.id +'/finalize/';

        var requestConfig = {
            method: 'POST',
            url: request_url,
            headers: {
                'Content-Type': 'application/json'
            },
            data: payload.params
        }

        // Execute request & return
        let output = false;

        return axios(requestConfig)
            .then(function (response) {
                output = response.data.success;

                if (!output) {
                    commit('setError', response.data.message);
                    commit('setHumanError', response.data.human_message);
                }
            })
            .catch(error => {
                commit('setError', error.response ? error.response.data.message : error);
                commit('setHumanError', error.response ? error.response.data.human_message : error);
                output = false;
            })
            .then(function () {
                return output;
            });
    },

    async createSwapGroup({ commit, dispatch }, payload) {
        dispatch('clearError');

        // Configure request
        const request_url = Vue.prototype.$url_api + 'v2/rails/swaps/groups/';

        var requestConfig = {
            method: 'POST',
            url: request_url,
            headers: {
                'Content-Type': 'application/json'
            },
            data: payload.params
        }

        // Execute request & return
        let output = false;

        return axios(requestConfig)
            .then(function (response) {
                output = response.data.success;

                if (output) {
                    commit('setSelectedSwapGroup', response.data.data);
                    let payloadUpdateUsers = {
                        id: response.data.data.id,
                        params: payload.params.players
                    }
                    dispatch('updateUsersFromSwapGroup', payloadUpdateUsers)
                } else {
                    commit('setError', response.data.message);
                    commit('setHumanError', response.data.human_message);
                }
            })
            .catch(error => {
                commit('setError', error.response ? error.response.data.message : error);
                commit('setHumanError', error.response ? error.response.data.human_message : error);
                output = false;
            })
            .then(function () {
                return output;
            });
    },

    async updateSwapGroup({ commit, dispatch }, payload) {
        dispatch('clearError');

        // Configure request
        const request_url = Vue.prototype.$url_api + 'v2/rails/swaps/groups/' + payload.id;

        var requestConfig = {
            method: 'PUT',
            url: request_url,
            headers: {
                'Content-Type': 'application/json'
            },
            data: {
                name: payload.params.name,
                active: payload.params.active
            }
        }

        // Execute request & return
        let output = false;

        return axios(requestConfig)
            .then(function (response) {
                output = response.data.success;

                if (output) {
                    commit('setSelectedSwapGroup', response.data.data);
                    let payloadUpdateUsers = {
                        id: response.data.data.id,
                        params: payload.params.players
                    }
                    dispatch('updateUsersFromSwapGroup', payloadUpdateUsers)
                } else {
                    commit('setError', response.data.message);
                    commit('setHumanError', response.data.human_message);
                }
            })
            .catch(error => {
                commit('setError', error.response ? error.response.data.message : error);
                commit('setHumanError', error.response ? error.response.data.human_message : error);
                output = false;
            })
            .then(function () {
                return output;
            });
    },

    async updateUsersFromSwapGroup({ commit, dispatch }, payload) {
        // clear state
        dispatch('clearGroupUserErrors');

        // Configure request
        const request_url = Vue.prototype.$url_api + 'v2/rails/swaps/groups/' + payload.id + '/users/';

        var requestConfig = {
            method: 'PUT',
            url: request_url,
            headers: {
                'Content-Type': 'application/json'
            },
            data: payload.params
        }

        // Execute request & return
        let output = false;

        return axios(requestConfig)
            .then(function (response) {
                output = response.data.success;
                if (output) {
                    dispatch('getSwapGroups');
                    dispatch('getSwapGroupById', payload.id)
                } else {
                    commit('setErrorGroupUsers', response.data.message);
                    commit('setHumanErrorGroupUsers', response.data.human_message);
                }
            })
            .catch(error => {
                commit('setErrorGroupUsers', error.response ? error.response.data.message : error);
                commit('setHumanErrorGroupUsers', error.response ? error.response.data.human_message : error);
                output = false;
            })
            .then(function () {
                return output;
            });
    },

    async getSwapPools({ commit, dispatch }, payload) {
        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/rails/swaps/pools';

        // Configure URL parameters
        if (payload && payload.params) {
            request_url = StoreMixin.methods.generateQueryParamsUrl(request_url, payload.params);
        }

        // clear states
        commit('setError', null);
        commit('setSwapPools', []);
        commit('setSwapPoolsTotal', 0);
        commit('setSwapPoolsLoading', true);

        // Execute request & return
        let output = false;

        return axios.get(request_url)
            .then((response) => {
                output = response.data.success;
                if (output) {
                    commit('setSwapPoolsTotal', response.data.total);
                    commit('setSwapPools', response.data.data);
                }
            })
            .catch(function (error) {
                commit('setError', error.response ? error.response.data.message : error);
                commit('setHumanError', error.response ? error.response.data.human_message : error);
                output = false;
            })
            .then(function () {
                commit('setSwapPoolsLoading', false);
                return output;
            });
    },

    async createSwapPool({ commit, dispatch }, payload) {
        dispatch('clearError');

        // Configure request
        const request_url = Vue.prototype.$url_api + 'v2/rails/swaps/pools/';

        var requestConfig = {
            method: 'POST',
            url: request_url,
            headers: {
                'Content-Type': 'application/json'
            },
            data: payload.params
        }

        // Execute request & return
        let output = false;

        return axios(requestConfig)
            .then(function (response) {
                output = response.data.success;

                if (output) {
                    commit('addSwapPool', response.data.data);
                    commit('setSelectedSwapPool', response.data.data);
                } else {
                    commit('setError', response.data.message);
                    commit('setHumanError', response.data.human_message);
                }
            })
            .catch(error => {
                commit('setError', error.response ? error.response.data.message : error);
                commit('setHumanError', error.response ? error.response.data.human_message : error);
                output = false;
            })
            .then(function () {
                return output;
            });
    },

    async updateSwapPool({ commit, dispatch }, payload) {
        dispatch('clearError');

        // Configure request
        const request_url = Vue.prototype.$url_api + 'v2/rails/swaps/pools/' + payload.id;

        // Handle payload data
        var data = payload.params;

        var requestConfig = {
            method: 'PUT',
            url: request_url,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        }

        // Execute request & return
        let output = false;

        return axios(requestConfig)
            .then(function (response) {
                output = response.data.success;

                if (output) {
                    commit('setSelectedSwapPool', response.data.data);
                } else {
                    commit('setError', response.data.message);
                    commit('setHumanError', response.data.human_message);
                }
            })
            .catch(error => {
                commit('setError', error.response ? error.response.data.message : error);
                commit('setHumanError', error.response ? error.response.data.human_message : error);
                output = false;
            })
            .then(function () {
                return output;
            });
    },

    async deleteSwapPool({ commit, dispatch }, id) {
        dispatch('clearError');

        // Configure request
        const request_url = Vue.prototype.$url_api + 'v2/rails/swaps/pools/' + id;

        let config = {
            method: 'DELETE',
            url: request_url,
            headers: {
                'Content-Type': 'application/json'
            },
        }

        // Execute request & return
        let output = false;

        return axios(config)
            .then(function (response) {
                // 204 response status represents success
                output = response.status == 204;
                if (!output) {
                    commit('setError', response.data.message);
                    commit('setHumanError', response.data.human_message);
                }
            })
            .catch(function (error) {
                commit('setError', error.response ? error.response.data.message : error);
                commit('setHumanError', error.response ? error.response.data.human_message : error);
                output = false;
            })
            .then(function () {
                return output;
            });
    },

    reset({ commit }) {
        commit('set', []);
        commit('setSwapGroups', []);
        commit('setPendingSwaps', []);
        commit('setFinalizedSwaps', []);
        commit('setRejectedSwaps', []);
        commit('setSwapsTotal', null);
        commit('setPendingSwapsTotal', null);
        commit('setFinalizedSwapsTotal', null);
        commit('setRejectedSwapsTotal', null);
        commit('setSwapGroupsTotal', null);
        commit('setLoading', true);
        commit('setPendingSwapsLoading', true);
        commit('setFinalizedSwapsLoading', true);
        commit('setRejectedSwapsLoading', true);
        commit('setSwapGroupsLoading', true);
        commit('setError', null);
        commit('setHumanError', null);
        commit('setErrorGroupUsers', null);
        commit('setHumanErrorGroupUsers', null);
    },

    clearError({ commit }) {
        commit('setError', null);
        commit('setHumanError', null);
    },
    
    clearGroupUserErrors({ commit }) {
        commit('setErrorGroupUsers', null);
        commit('setHumanErrorGroupUsers', null);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};