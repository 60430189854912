import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth'
import DialogConfirmation from '@/components/Rail/Dialog/DialogConfirmation/DialogConfirmation.vue';
import DialogRsvpConfirmation from '@/components/Calendar/DialogRsvpConfirmation/DialogRsvpConfirmation.vue';

export default {
    name: 'ButtonCalendarRSVP',

    mixins: [
        AuthMixin,
    ],

	components: {
		DialogConfirmation,
		DialogRsvpConfirmation,
	},

    computed: {
        ...mapGetters({
			userTrackerId: 'auth/userTrackerId',
			selectedEvent: 'eventsCalendar/selectedEvent',
			eventsError: 'eventsCalendar/error',
			eventsHumanError: 'eventsCalendar/humanError',
			eventsCategories: 'eventsCategoriesCalendar/data',
        }),

		userCanUnRsvp() {
			return this.selectedEvent.user_is_enrolled && this.selectedEvent.category.can_unregister;
		},
	},

	created() {
	},

    data() {
		return this.initialState();
	},

    methods: {
		initialState() {
			return {
				hoverStatus: false,
				dialogConfirmation: {
					open: false,
					submitting: false,
					buttonColor: '',
					buttonName: '',
					body: '',
				},
				dialogRsvpConfirmation: {
					open: false,
				}
			};
		},

		submit() {
			// Organizers cannot RSVP
			if (this.isUserCalendarEventOrganizer()) {
				return false;
			}

			// setup props of dialog confirmation 
			this.setupDialogConfirmation();

			if (this.selectedEvent.user_is_enrolled) {
				if (this.userCanUnRsvp) {
					// Open DialogConfirmation "Are you sure you want to undo-rsvp?"
					this.dialogConfirmation.open = true;	
				}	
			} else if (!this.selectedEvent.user_is_enrolled && this.selectedEvent.category.warning_show) {
				// Open DialogConfirmation with warning text if exists 
				this.dialogConfirmation.open = true;	
			} else {
				this.toggleRsvp();
			}
		},

		async toggleRsvp() {
			// send to store (same action for rsvp/undo-rsvp)
			let result = await this.submitToStore();

			// show snackbar error if was not possible submit to store
			if (!result) {
				let errorMessage = this.selectedEvent.user_is_enrolled ? 'desinscrever'
					: 'inscrever'
				// show snackbar with error
				await this.$store.dispatch('UI/showSnackbar', {
					message: `Não foi possível ${errorMessage} no evento. Por favor contacte o suporte.`,
					color: 'error'
				})
				// fetch events
				await this.$store.dispatch('eventsCalendar/get');
			}

			// Open dialog "RSVP Successful if user was not enrolled"
			if (this.selectedEvent.user_is_enrolled && result)
				this.dialogRsvpConfirmation.open = true;

			// Close DialogConfirmation 
			this.dialogConfirmation.open = false;
		},

		setupDialogConfirmation() {
			if (this.selectedEvent) {
				if (this.selectedEvent.user_is_enrolled) {
					this.dialogConfirmation.buttonColor = 'error';
					this.dialogConfirmation.buttonName = 'Remover';
					this.dialogConfirmation.title = 'Remover Inscrição'
					this.dialogConfirmation.body = `Tens a certeza que queres remover a tua inscrição do evento ${this.selectedEvent.title} ?`
				} else {
					this.dialogConfirmation.buttonColor = 'success';
					this.dialogConfirmation.buttonName = 'Confirmar';
					this.dialogConfirmation.title = 'Confirmar Inscrição'
					this.dialogConfirmation.body = this.selectedEvent.category.warning_text;
				}
			}
		},

		/**
		 * Submit to store the rsvp Enroll
		 * @returns boolean
		 */
		async submitToStore() {
			let payload = {
				eventID: this.selectedEvent.id,
				user: this.userTrackerId,
			};

			return await this.$store.dispatch('eventsCalendar/rsvpEnroll', payload);
		},

		getButtonText() {
			let output = null;

			if (this.hoverStatus===true && this.userCanUnRsvp) {
				output = 'Desinscrever-me'
			} else {
				if (this.selectedEvent.user_is_enrolled) {
					output = 'Inscrito'
				} else if (this.isUserCalendarEventOrganizer()) {
					output = 'Organizador'
				} else {
					output = 'Inscrever-me';
				}
			}

			return output;
		},

		getButtonColor() {
			if (this.hoverStatus===true && this.userCanUnRsvp && ! this.isUserCalendarEventOrganizer()) {
				return 'red'
			} else {
				return this.selectedEvent.user_is_enrolled || this.isUserCalendarEventOrganizer()
					? 'success secondary--text'
					: 'accent';
			}
		},
	},
}