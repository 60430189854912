import { mapGetters } from 'vuex';
import Hero from '@/components/Hero/Hero.vue';
import AuthMixin from '@/mixins/Auth';
import DataMixin from '@/mixins/Data';
import Snackbar from "@/components/Snackbar";
import TableNicknames from '@/components/Tables/TableNicknames/TableNicknames.vue';
import DialogCreateNickname from '@/components/Dialog/DialogCreateNickname/DialogCreateNickname.vue';

export default {
  name: 'Nicknames-User',

  components: {
    Hero,
    Snackbar,
    TableNicknames,
    DialogCreateNickname,
  },

  mixins: [
    AuthMixin,
    DataMixin,
  ],

  computed: {
    ...mapGetters({
      selectedNickname: 'nicknames/selectedNickname',
      networks: 'networks/networks',
    })
  },

  data() {
    return this.initialState();
  },

  created() {
    this.fetchData();
  },

  methods: {
    initialState() {
      return {
        dialogues: {
          create: false,
        },
        reload: false,
      }
    },

    dialogCreateNickname() {
      this.dialogues.create = true;
    },

    triggerTableNicknamesReload() {
      this.reload = false;
      setTimeout(() => {
          this.reload = true;
      }, 100);
    },

    fetchData() {
      // Pre load networks
      this.$store.dispatch('networks/get');
    },
  }
}