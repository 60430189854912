<template>
  <component :is="componentToShow" />
</template>

<script>
import OverviewUser from './user/Overview.vue';
import OverviewAdmin from './admin/Overview.vue';
import AuthMixin from '@/mixins/Auth.vue';
import { mapGetters } from 'vuex';
import Loading from '@/views/Loading.vue';

export default {
  name: 'Overview',

  components: {
    OverviewUser,
    OverviewAdmin,
    Loading,
    },

  mixins: [
    AuthMixin
    ],

  computed: {
      ...mapGetters({
        userRolesLoading: 'auth/userRolesLoading',
        userRoles: 'auth/userRoles'
      }),

    componentToShow() {
        if (this._.isEmpty(this.userRoles)) {
          return 'Loading';
        }

        return  this.userHasRole(['rail_admin', 'rail_manager'])
                ? 'OverviewAdmin'
                : 'OverviewUser';
    }
  }
}
</script>

<style scoped>
</style>