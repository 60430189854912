import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth';

export default {
  name: 'UserCard',

  props: {
    showIcon: {type: Boolean, required: false, default: true},
    id: {type: Number, required: false, default: null},
    imgCutout: {type: String, required: false, default: null},
    displayName: {type: String, required: false, default: null},
    alignCenter: {type: Boolean, required: false, default: false},
  },

  mixins: [
    AuthMixin,
  ],

  data() {
    return this.initialState();
  },

  computed: {
    ...mapGetters({
    }),
  },

  methods: {
    initialState() {
      return {
        
      }
    },

    imageCutout(imgCutout) {
      let placeholder = require('@/assets/images/players/player-example.png');
      return imgCutout ?  imgCutout : placeholder;
    },
  }
}
