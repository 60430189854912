import Vue from 'vue'
import { mapGetters } from "vuex";
import VideoReportsEvaluate from "../VideoReports-Evaluate/VideoReports-Evaluate.vue";
import AuthMixin from '@/mixins/Auth';

export default {
    name: "video-reports-preview",
    mixins: [
        AuthMixin
    ],
    components: {
        "video-reports-evaluate": VideoReportsEvaluate,
    },
    props: {
        dialogOpen: { type: Boolean, required: true, default: false },
    },
    data() {
        return this.initialState();
    },
    computed: {
        ...mapGetters({
            selectedReport: "videoReports/selectedVideoReport",
            selectedVideo: "videoRequests/selectedVideo",
            users: "users_v1/users"
        }),
        report_file_url: function () {
            if (this.dialogData && this.dialogData.video_reports)
                return Vue.prototype.$video_reports.base_url + this.dialogData.video_reports.file_video_reports;
            else
                return null;
        },
    },
    async created() {
        await this.initialize();
    },
    methods: {
        async initialize() {
            await this.$store.dispatch('users_v1/getUsers');
        },
        initialState() {
            return {
                userHasEditPermission: this.userHasRole(['tracker_admin', 'tracker_manager', 'video_reports_reviewer_team_mtt', 'video_reports_reviewer_team_cash']),
                dialogues: {
                    evaluate: false,
                },
                dialogData: null
            };
        },
        /**
         * Assures the 'selectedReport' variable is always filled for standardization purposes.
         * Admins have access to both selectedVideo and selectedReport object, but users only
         * have access to the selectedVideo object.
         */
        populate(item) {
            if ( item.hasOwnProperty('video_reports') ) {
                // Obj is of type video_requests' (user-view)
                this.dialogData = item;
            } else if ( item.hasOwnProperty('video_requests') ) {
                // Obj if os type 'video_reports' (admin-view)
                this.dialogData = Object.assign({}, item.video_requests);
                this.dialogData.video_reports = Object.assign({}, item);
                delete this.dialogData.video_reports.video_requests;
            }
        },
        openDialog(d) {
            this.dialogues[d] = true;
        },
        closeDialog(d) {
            this.dialogues[d] = false;
        },
        closeDialogPreview() {
            // Close dialog
            this.$emit('closeDialog');
            // Return all fields to initial state
            Object.assign(this.$data, this.initialState());
        },
        closeBothDialogs() {
            this.closeDialog('evaluate');
            this.$emit('closeDialog');
        },
        getUserDisplayNameById(id) {
            if (this.users) {
                let user = this.users.filter((e) => e.id_users == id);
                return user[0]['display_name_users'];
            }
        },
        /**
         * Retrieves color for the delivery status v-chip.
         * 
         * @param   {Object}    data Video request object with a 'video_reports' key.
         * @returns {String}    Returns 'gray', 'green' or 'red'.
         */
        getChipColor(data) {
            let output = 'gray';

            if (data.video_reports) {
                if (data.video_reports.status_video_reports  == Vue.prototype.$video_reports.status.approved)
                    output = 'green';
                else
                    output = 'red';
            }

            return output;
        },
        /**
         * Retrieves text for the delivery status v-chip.
         * 
         * @param {Object} data Video request object with a 'video_reports' key.
         * @returns {String}    Returns 'Desconhecido', 'Avaliado' or 'Por avaliar'.
         */
        getChipText(data) {
            let output = 'Desconhecido';

            if (data.video_reports) {
                if (data.video_reports.status_video_reports  == Vue.prototype.$video_reports.status.approved)
                    output = 'Avaliado';
                else
                    output = 'Por avaliar';
            }

            return output;
        }
    },
};
