import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth.vue';
import RailMixin from '@/mixins/Rail.vue';
import AlertError from '@/components/Alerts/AlertError/AlertError.vue';
import DatePicker from '@/components/DatePicker/DatePicker.vue';
import InputFinancial from '@/components/InputFinancial/InputFinancial.vue';

export default {
	name: 'DialogEditUserContract',

	components: {
		AlertError,
		DatePicker,
		InputFinancial,
	},

	props: {
		isOpen: { type: Boolean, required: true, default: false },
	},

	mixins: [
		AuthMixin,
		RailMixin,
	],

	data() {
		return this.initialState();
	},

	computed: {
		...mapGetters({
			selectedUser: 'users/selectedUser',
			error: 'users/error',
			humanError: 'users/humanError',
			themeDarkMode: 'UI/themeDarkMode',
		}),

	},

	watch: {
		// whenever user has changed, triggers populateFields()
		selectedUser: function () {
			if (this.selectedUser && this.selectedUser.contractSignDate && this.selectedUser.contractEndDate) {
				this.populate();
			}
		},
		isOpen: function () {
			this.clearErrors();
			this.clearUserInputs();
			this.resetInputFinancialValidation();
			this.populate();
		},

	},

	created() {
		this.clearUserInputs();
		this.populate();
	},

	methods: {
		initialState() {
			return {
				showContractObligations: false,
				submitting: false,
				menu: false,
				form: {
					renewalDuration: null,
					beginDate: null,
					endDate: null,
					contractNumSessions: null,
					contractNumEvents: null,
					oblNumEvents: null,
					oblNumSessions: null
				},
				renewalOptions: [
					{ title: '1 mês', numDays: 30 },
					{ title: '3 meses', numDays: 90 },
					{ title: '6 meses', numDays: 180 },
					{ title: '1 ano', numDays: 365 },
					{ title: '1 ano e meio', numDays: 548 },
				],
				rules: {
					date: [
						(v) => !!v || 'Por favor introduza uma data',
					],
				},
			};
		},

		populate() {
			this.form.beginDate = this.selectedUser.contractSignDate ? this.$moment(this.selectedUser.contractSignDate.date).format('YYYY-MM-DD') : null;
			this.form.endDate = this.selectedUser.contractEndDate ? this.$moment(this.selectedUser.contractEndDate.date).format('YYYY-MM-DD') : null;
			this.form.contractNumSessions = this.selectedUser.contractNumSessions;
			this.form.contractNumEvents = this.selectedUser.contractNumEvents;
			this.form.oblNumEvents = this.selectedUser.oblNumEvents;
			this.form.oblNumSessions = this.selectedUser.oblNumSessions;

			if (this.form.contractNumSessions || this.form.contractNumEvents || this.form.oblNumEvents || this.form.oblNumSessions) {
				this.showContractObligations = true;
			} else {
				this.showContractObligations = false;
			}
		},

		updateRenewalDates() {
			this.form.beginDate = this.$moment().format('YYYY-MM-DD');
			this.form.endDate = this.$moment(this.form.beginDate).add(this.form.renewalDuration, 'days').format('YYYY-MM-DD');
		},

		async submit() {
			if (!this.$refs.formEditUserContract.validate()) {
				return false;
			}

			// Toggle submit button
			this.toggleSubmitting();

			const payload = {
				id: this.selectedUser.id,
				contractSignDate: this.form.beginDate,
				contractEndDate: this.form.endDate,
				contractNumSessions: this.showContractObligations ? this.form.contractNumSessions : null,
				contractNumEvents: this.showContractObligations ? this.form.contractNumEvents : null,
				oblNumEvents: this.showContractObligations ? this.form.oblNumEvents : null,
				oblNumSessions: this.showContractObligations ? this.form.oblNumSessions : null,
			};
			
			let result = await this.$store.dispatch('users/updateUser', payload);

			// // Re-enable button
			this.toggleSubmitting();

			// On success
			if (result === true) {
				// Show success snackbar
				this.$store.dispatch('UI/showSnackbar', {
					message: 'Contrato do jogador atualizado com sucesso.',
					color: 'success'
				});

				// Refresh obligation statistics
				let payloadObligationStats = {
					id: this.selectedUser.id,
				}
				
				// reset last payload in order to refresh user statistics
				this.$store.commit('users/setLastPayloadUserStatistics', {});

				// API Call refresh user statistics
				this.$store.dispatch('users/getUserStatistics', payloadObligationStats);
				
				// closes dialog
				this.closeDialog();
			}
		},

		/**
		 * Used to disable Submit button
		 */
		toggleSubmitting() {
			this.submitting = !this.submitting;
		},

		closeDialog() {
			this.clearErrors();
			this.$emit('close-dialog');
		},

		clearErrors() {
			this.$store.dispatch('users/clearErrors');
		},

		/**
		 * used in input-financial input element in order to do proper validations
		 * @param {Number} field 
		 * @returns {Array} with boolean values
		 */
		handleObligationsRules(field) {
			return [
				() => !!field || 'Introduza um número válido',
				() => _.isInteger(Number(field)) || 'Por favor introduza um número válido.',
				() => (!!field && parseFloat(String(field).replace(/,./g, '.')) > 0) || 'O número tem de ser positivo',
			]
		},

		resetInputFinancialValidation() {
			// need timeout to render element and then validate if exists to reset validation
			setTimeout(() => {
				if (this.$refs && this.$refs.contractNumSessions) {
					this.$refs.contractNumSessions.resetValidation();
				}

				if (this.$refs && this.$refs.contractNumEvents) {
					this.$refs.contractNumEvents.resetValidation();
				}

				if (this.$refs && this.$refs.oblNumEvents) {
					this.$refs.oblNumEvents.resetValidation();
				}

				if (this.$refs && this.$refs.oblNumSessions) {
					this.$refs.oblNumSessions.resetValidation();
				}

			}, 50);
		},

		clearUserInputs() {
			this.form.contractNumSessions = null;
			this.form.contractNumEvents = null;
			this.form.oblNumEvents = null;
			this.form.oblNumSessions = null;
			this.showContractObligations = false;
		}
	},
};