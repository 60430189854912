var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"formCreateNetwork",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit($event)}}},[_c('v-dialog',{attrs:{"value":_vm.isOpen,"width":"600"},on:{"input":function($event){return _vm.closeDialog()}}},[_c('v-card',{staticClass:"overflow-x-hidden"},[_c('v-card-title',[_vm._v(" Adicionar Sala "),_c('v-spacer'),_c('v-btn',{staticClass:"mr-n2",attrs:{"icon":""},on:{"click":function($event){return _vm.closeDialog()}}},[_c('v-icon',[_vm._v(" mdi-close ")])],1)],1),_c('AlertError',{staticClass:"px-6",attrs:{"error":_vm.error,"human-error":_vm.humanError}}),_c('v-container',{staticClass:"px-6"},[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-space-between",attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.nicknameType,"label":"Tipo de sala","item-value":"value","item-text":"name","return-object":"","outlined":""},model:{value:(_vm.form.isWallet),callback:function ($$v) {_vm.$set(_vm.form, "isWallet", $$v)},expression:"form.isWallet"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"rules":_vm.rules.name,"label":"Nome","outlined":""},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),(_vm.form.isWallet && !_vm.form.isWallet.value)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"rules":_vm.rules.identifier,"label":"Identificador","outlined":""},model:{value:(_vm.form.identifier),callback:function ($$v) {_vm.$set(_vm.form, "identifier", $$v)},expression:"form.identifier"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.currenciesBase,"rules":_vm.rules.currency,"item-value":"id","item-text":"name","label":"Tipo de moeda","outlined":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.symbol)+" "+_vm._s(item.name))])]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.symbol)+" "+_vm._s(item.name))])]}}]),model:{value:(_vm.form.currency),callback:function ($$v) {_vm.$set(_vm.form, "currency", $$v)},expression:"form.currency"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"items":_vm.images,"rules":_vm.rules.image,"item-value":"pathShort","label":"Imagem da Sala","outlined":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-sheet',{staticClass:"d-flex px-3 my-auto",attrs:{"rounded":""}},[_c('v-img',{staticClass:"my-auto",attrs:{"src":require('@/assets/networks/' + item),"height":"24","width":"24"}})],1),_c('span',[_vm._v(_vm._s(item))])]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-sheet',{staticClass:"d-flex px-3 my-auto",attrs:{"rounded":""}},[_c('v-img',{staticClass:"my-auto",attrs:{"src":require('@/assets/networks/' + item),"height":"24","width":"24"}})],1),_c('span',[_vm._v(_vm._s(item))])]}}]),model:{value:(_vm.form.image),callback:function ($$v) {_vm.$set(_vm.form, "image", $$v)},expression:"form.image"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('MetadataDropdown',{model:{value:(_vm.form.metadata),callback:function ($$v) {_vm.$set(_vm.form, "metadata", $$v)},expression:"form.metadata"}})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.closeDialog()}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"text":"","disabled":_vm.submitting,"color":"success"},on:{"click":function($event){return _vm.submit()}}},[(_vm.submitting)?_c('span',[_c('v-icon',[_vm._v("mdi-loading mdi-spin")])],1):_c('span',[_vm._v(" Adicionar ")])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }