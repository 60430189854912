import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth.vue';
import RailMixin from '@/mixins/Rail.vue';
import Loading from '@/views/Loading.vue';
import Hero from "@/components/Hero/Hero.vue";
import TableLiveListTournaments from '@/components/Tables/TableLiveListTournaments/TableLiveListTournaments.vue';

export default {
    name: 'LiveTournaments',

    mixins: [
      AuthMixin,
      RailMixin,
    ],

    components: {
        Loading,
        Hero,
        TableLiveListTournaments,
    },

    computed: {
      ...mapGetters({
        }),
    },

    created() {
      this.initialize();
    },

    data() {
      return this.initialState();
    },

    methods: {
      initialize() {
        return;
      },

      initialState() {
        return {
        };
      },
    }
}