import AuthMixin from '@/mixins/Auth.vue';

export default {
    name: "NavBarMobile",

    props: {
        playersBtnMenu: { type: Array, required: false, default: [] },
        trackerBtnMenu: { type: Array, required: false, default: [] },
        gamesBtnMenu: { type: Array, required: false, default: [] },
        videosBtnMenu: { type: Array, required: false, default: [] },
        statisticsBtnMenu: { type: Array, required: false, default: [] },
        scheduleBtnMenu: { type: Array, required: false, default: () => [] },
    },

    components: {},

    mixins: [
        AuthMixin
    ],

    data() {
        return this.initialState();
    },

    methods: {
        initialState() {
            return {
                menu: false,
                sheet: false,
                sheetFab: false,
            }
        },
    }
};