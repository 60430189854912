import { mapGetters } from 'vuex';
import RailMixin from '@/mixins/Rail.vue';
import AuthMixin from '@/mixins/Auth.vue';

export default {
    name: 'CardPlayerGoals',

    props: {
        valueTotal: { type: Number, required: false, default: 0 },
        valueCurrent: { type: [Number, String], required: false, default: 0 },
        monthProgress: { type: [Number, String], required: false, default: 0 },
        showMonthProgress: { type: Boolean, required: false, default: true },
        valuePercentage: { type: [Number,String], required: false, default: 0.4 },
        title: { type: String, required: false, default: 'N.º de sessões' },
        subtitle: { type: String, required: false, default: 'N.º de sessões registadas este mês' },
        showSubtitle: { type: Boolean, required: false, default: true },
        showAvatar: { type: Boolean, required: false, default: true },
        icon: { type: String, required: false, default: 'mdi-cards-outline' },
        iconColor: { type: String, required: false, default: 'white' },
        striped: { type: Boolean, required: false, default: false },
        color: { type: String, required: false, default: 'yellow yellow darken-1' },
        colorAvatar: { type: String, required: false, default: 'accent' },
        height: { type: Number, required: false, default: 10 },
        animation: { type: Boolean, required: false, default: false },
        rounded: { type: Boolean, required: false, default: false },
        cardFlat: { type: Boolean, required: false, default: false },
        cardTile: { type: Boolean, required: false, default: true },
        showTooltip: { type: Boolean, required: false, default: false },
        tooltipText: { type: String, required: false, default: 'N.º de sessões registadas este mês' },
        loading: { type: Boolean, required: false, default: false },
        customGoal: { type: Boolean, required: false, default: false },
        pastTeamGoal: { type: Boolean, required: false, default: false },
        customGoalMessage: { type: String, required: false, default: '' },
        pastTeamGoalMessage: { type: String, required: false, default: '' },
        pastPercentage: { type: Number, required: false, default: 0 },
        pct_change: { type: Number, required: false, default: 0 }
    },

    components: {

    },

    mixins: [
        RailMixin,
        AuthMixin,
    ],

    // necessary to be able to remove margins from v-slider component
    mounted() {
        if (this.$refs && this.$refs.slider) {
            this.$nextTick(() => {
              const sliderElem = this.$refs.slider.$el.querySelector('.v-slider--horizontal');
              if (sliderElem) {
                sliderElem.style.margin = 0;
              }
            });
        }
        if(this.$props.animation == true){
            this.startAnimation();
        }
      },

    computed: {
        ...mapGetters({

        }),

        valuePercentageComputed: function () {
            let output = this.valuePercentage;
            return parseInt(output * 100);
        },

        monthProgressComputed: function () {
            let output = this.monthProgress;
            return output * 100;
        },

        getMonthProgressColor: function () {
            if (this.valuePercentage >= this.monthProgress) {
                return 'green';
            } else {
                return 'red';
            }
        }
    },

    data() {
        return this.initialState();
    },

    methods: {
        initialState() {
            return { 
                actualPercent: (this.pastPercentage * 100),
            }
        },

        startAnimation(){
            setTimeout(()=>{
                let percentUpdated = this.pct_change * 100;
                this.actualPercent = percentUpdated
            }, 1500)
        },

        getTooltipMessage() {
            let output = '';
            let isDoingGood = this.valuePercentage >= this.monthProgress;

            output = isDoingGood
                ? 'Acima do ritmo necessário'
                : 'Abaixo do ritmo necessário';

            return output;
        }

    }
}