import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import StoreMixin from '@/mixins/Store.vue';
import DataMixin from '@/mixins/Data.vue';
Vue.use(Vuex);
Vue.use(StoreMixin);
Vue.use(DataMixin);
const state = {
    sessions: [],
    liveEvents: [],
    exportedSessions: [],
    nicknameSessions: [],
    selectedSession: null,
    selectedLiveEvent:[],
    lastSession: null,
    loading: false,
    loadingLiveEvents: false,
    loadingSelectedLiveEvent: false,
    loadingNicknameSessions: false,
    error: null,
    humanError: null,
    nicknameSessionsError: null,
    nicknameSessionsHumanError: null,
    totalSessions: null,
    totalNicknameSessions: null,
    currentPayloadLastSessionUserId: null,
    gamification: {},
    lastPayloadSession: {}
};

const getters = {
    sessions: (state) => state.sessions,
    liveEvents: (state) => state.liveEvents,
    nicknameSessions: (state) => state.nicknameSessions,
    exportedSessions: (state) => state.exportedSessions,
    selectedSession: (state) => state.selectedSession,
    selectedLiveEvent: (state) => state.selectedLiveEvent,
    lastSession: (state) => state.lastSession,
    loading: (state) => state.loading,
    loadingLiveEvents: (state) => state.loadingLiveEvents,
    loadingSelectedLiveEvent: (state) => state.loadingSelectedLiveEvent,
    loadingNicknameSessions: (state) => state.loadingNicknameSessions,
    error: (state) => state.error,
    humanError: (state) => state.humanError,
    nicknameSessionsError: (state) => state.nicknameSessionsError,
    nicknameSessionsHumanError: (state) => state.nicknameSessionsHumanError,
    totalSessions: (state) => state.totalSessions,
    totalNicknameSessions: (state) => state.totalNicknameSessions,
    gamification: (state) => state.gamification,
};

const mutations = {
    set(state, payload) {
        state.sessions = payload;
    },
    setLiveEvents(state, payload) {
        state.liveEvents = payload;
    },
    setExportedSessions(state, payload) {
        state.exportedSessions = payload;
    },
    add(state, payload) {
        if (!state.sessions || !Array.isArray(state.sessions))
            state.sessions = [];
        state.sessions.unshift(payload);
    },
    setSelected(state, payload) {
        state.selectedSession = payload;
    },
    setSelectedLiveEvent(state, payload) {
        state.selectedLiveEvent = payload;
    },
    setLastSession(state, payload) {
        state.lastSession = payload;
    },
    setLoading(state, payload) {
        state.loading = payload;
    },
    setLoadingLiveEvents(state, payload) {
        state.loadingLiveEvents = payload;
    },
    setLoadingSelectedLiveEvent(state, payload) {
        state.loadingSelectedLiveEvent = payload;
    },
    setError(state, payload) {
        state.error = payload;
    },
    setHumanError(state, payload) {
        state.humanError = payload;
    },
    update(state, payload) {
        const session = state.sessions.filter(
            i => i.id == payload.id
        )[0];

        const index = state.sessions.findIndex(
            i => i.id == payload.id
        );

        const updatedItems = [
            ...state.sessions.slice(0, index),
            session,
            ...state.sessions.slice(index + 1)
        ];

        state.sessions = updatedItems;
    },
    delete(state, payload) {
        const index = state.sessions.findIndex(
            i => i.id == payload
        );

        state.sessions.splice(index, 1);
    },

    setTotalSessions(state, payload) {
        state.totalSessions = payload;
    },

    setNicknameSessions(state, payload) {
        state.nicknameSessions = payload;
    },

    setNicknameSessionsTotal: (state, payload) => {
        state.totalNicknameSessions = payload;
    },
    setLoadingNicknameSessions(state, payload) {
        state.loadingNicknameSessions = payload;
    },
    setNicknameSessionsError(state, payload) {
        state.nicknameSessionsError = payload;
    },
    setNicknameSessionsHumanError(state, payload) {
        state.nicknameSessionsHumanError = payload;
    },
    setCurrentPayloadLastSessionUserId(state, payload) {
        state.currentPayloadLastSessionUserId = payload;
    },
    setGamification(state, payload) {
        state.gamification = payload;
    },
    setLastPayloadSession(state, payload) {
        state.lastPayloadSession = payload;
    },
};

const actions = {
    async create({ commit, dispatch }, payload) {
        dispatch('clearError');

        // validate payload objects before API Call
        let areObjectsEqual = DataMixin.methods.areObjectsEqual(state.lastPayloadSession, payload);

        // save last payload
        commit('setLastPayloadSession', payload);

        if (areObjectsEqual) {
            commit('setError', 'Acabaste de registar sessão exatamente com estes dados.Por favor aguarda e/ou tenta mais tarde.');
            commit('setHumanError', 'Acabaste de registar sessão exatamente com estes dados.Por favor aguarda e/ou tenta mais tarde.');   
            return false;
        }

        // Handle payload data
        let data = JSON.stringify(payload);

        // Configure request
        const request_url = Vue.prototype.$url_api + 'v2/rails/sessions';
        let config = {
            method: 'POST',
            url: request_url,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data,
            timeout: 15000 // Timeout in milliseconds (15 seconds)
        }

        // Execute request & return
        let output = false;
        let outputGamification = false;

        return axios(config)
            .then(function (response) {
                output = response.data.success;
                outputGamification = response.data.data.gamification
                if (outputGamification) {
                    commit('setGamification', {
                        events: outputGamification.values ? outputGamification.values.events : null,
                        sessions: outputGamification.values ? outputGamification.values.sessions : null,
                        show_dialog_feedback: outputGamification.show,
                        show_animation: outputGamification.show_animation,
                        message: outputGamification.message
                  });
                }
                if (!output) {
                    commit('setError', response.data.message);
                    commit('setHumanError', response.data.human_message);
                }
            })
            .catch(function (error) {
                if (error.code === 'ECONNABORTED') {
                    commit('setError', 'Estamos a experienciar problemas de conectividade, por favor volta a tentar mais tarde');
                    commit('setHumanError', 'Estamos a experienciar problemas de conectividade, por favor volta a tentar mais tarde');
                } else {
                    commit('setError', error.response ? error.response.data.message : error);
                    commit('setHumanError', error.response ? error.response.data.human_message : error);
                }
                output = false;
            })
            .then(function () {
                // clear payload session
                commit('setLastPayloadSession', null);
                return output;
            });
    },

    async update({ commit, dispatch }, payload) {
        dispatch('clearError');

        const sessionID = payload.id;
        delete payload.id;

        // Handle payload data
        let data = JSON.stringify(payload);

        // Configure request_url
        const request_url = Vue.prototype.$url_api + 'v2/rails/sessions/' + sessionID;

        // Configure request
        let config = {
            method: 'PUT',
            url: request_url,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data,
            timeout: 15000 // Timeout in milliseconds (15 seconds)
        };

        // Execute request & return
        let output = false;
        let outputGamification = false;

        return axios(config)
            .then(function (response) {
                output = response.data.success;
                outputGamification = response.data.data.gamification
                if (outputGamification) {
                    commit('setGamification',{
                        events: outputGamification.values.events,
                        sessions: outputGamification.values.sessions,
                        show_dialog_feedback: outputGamification.show,
                        show_animation: outputGamification.show_animation,
                        message: outputGamification.message
                    });
                }
                if (!output) {
                    commit('setError', response.data.message);
                    commit('setHumanError', response.data.human_message);
                }
            })
            .catch(function (error) {
                if (error.code === 'ECONNABORTED') {
                    commit('setError', 'Estamos a experienciar problemas de conectividade, por favor volta a tentar mais tarde');
                    commit('setHumanError', 'Estamos a experienciar problemas de conectividade, por favor volta a tentar mais tarde');
                } else {
                    commit('setError', error.response ? error.response.data.message : error);
                    commit('setHumanError', error.response ? error.response.data.human_message : error);
                }
                output = false;
            })
            .then(function () {
                // clear payload session
                commit('setLastPayloadSession', {});
                return output;
            });
    },

    async get({ commit }, payload) {
        commit('set', []);
        commit('setSelected', null);
        commit('setLoading', true);
        commit('setError', null);

        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/rails/sessions/';

        // Formulate URL with query string with generateQueryParamsUrl mixin function
        if (payload && payload.params) {
            request_url = StoreMixin.methods.generateQueryParamsUrl(request_url, payload.params);
        }

        // Execute request & return
        let output = false;

        return axios.get(request_url)
            .then(function (response) {
                output = response.data.success;

                if (output) {
                    commit('setTotalSessions', response.data.total);
                    commit('set', response.data.data);
                } else {
                    commit('setError', response.data.message);
                    commit('setHumanError', response.data.human_message);
                }
            })
            .catch(function (error) {
                commit('setError', error.response ? error.response.data.message : error);
                commit('setHumanError', error.response ? error.response.data.human_message : error);
                output = false;
            })
            .then(function () {
                commit('setLoading', false);
                return output;
            });
    },

    async getLastSessionByUserId({ commit }, userID) {

        if (state.currentPayloadLastSessionUserId == userID) {
            return true;
        }

        // clear states
        commit('setLastSession', null);
        commit('setLoading', true);
        commit('setError', null);

        // populates current payload user id
        commit('setCurrentPayloadLastSessionUserId', userID);

        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/rails/sessions/?user=' + userID;

        // Execute request & return
        let output = false;

        return axios.get(request_url)
            .then(function (response) {
                output = response.data.success;

                if (output) {
                    if (!_.isEmpty(response.data.data)) {
                        commit('setLastSession', response.data.data[0]);
                        commit('setSelected', response.data.data[0]);
                    }
                } else {
                    commit('setError', response.data.message);
                    commit('setHumanError', response.data.human_message);
                }
                return output;
            })
            .catch(function (error) {
                commit('setError', error.response ? error.response.data.message : error);
                commit('setHumanError', error.response ? error.response.data.human_message : error);
                output = false;
            })
            .finally(function () {
                commit('setLoading', false);
                return output;
            });
    },

    async getSessionById({ commit }, payload) {
        commit('setSelected', null);
        commit('setError', null);

        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/rails/sessions/' + payload;

        // Execute request & return
        let output = false;

        return axios.get(request_url)
            .then(function (response) {
                output = response.data.success;

                if (output) {
                    commit('setSelected', response.data.data);
                } else {
                    commit('setError', response.data.message);
                    commit('setHumanError', response.data.human_message);
                }
            })
            .catch(function (error) {
                commit('setError', error.response ? error.response.data.message : error);
                commit('setHumanError', error.response ? error.response.data.human_message : error);
                output = false;
            })
            .then(function () {
                return output;
            });
    },

    async getLiveEvents({ commit }, payload) {
        commit('setLiveEvents', []);
        commit('setLoadingLiveEvents', true);
        commit('setError', null);

        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/live/';

        // Formulate URL with query string with generateQueryParamsUrl mixin function
        if (payload && payload.params) {
            request_url = StoreMixin.methods.generateQueryParamsUrl(request_url, payload.params);
        }

        // Execute request & return
        let output = false;

        return axios.get(request_url)
            .then(function (response) {
                output = response.data.success;

                if (output) {
                    commit('setLiveEvents', response.data.data);
                } else {
                    commit('setError', response.data.message);
                    commit('setHumanError', response.data.human_message);
                }
            })
            .catch(function (error) {
                // commit('setError', error.response ? error.response.data.message : error);
                // commit('setHumanError', error.response ? error.response.data.human_message : error);
                output = false;
            })
            .then(function () {
                commit('setLoadingLiveEvents', false);
                return output;
            });
    },

    async getSingleLiveEvent({ commit }, payload) {
        commit('setLoadingSelectedLiveEvent', true);
        commit('setError', null);

        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/live/' + payload.id;

        // Formulate URL with query string with generateQueryParamsUrl mixin function
        if (payload && payload.params) {
            request_url = StoreMixin.methods.generateQueryParamsUrl(request_url, payload.params);
        }

        // Execute request & return
        let output = false;

        return axios.get(request_url)
            .then(function (response) {
                output = response.data.success;

                if (output) {
                    commit('setSelectedLiveEvent', response.data.data);
                } else {
                    commit('setError', response.data.message);
                    commit('setHumanError', response.data.human_message);
                }
            })
            .catch(function (error) {
                // commit('setError', error.response ? error.response.data.message : error);
                // commit('setHumanError', error.response ? error.response.data.human_message : error);
                output = false;
            })
            .then(function () {
                commit('setLoadingSelectedLiveEvent', false);
                return output;
            });
    },

    async export({ commit }, payload) {
        commit('setExportedSessions', []);
        commit('setError', null);

        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/rails/sessions/';

        // Formulate URL with query string with generateQueryParamsUrl mixin function
        if (payload && payload.params) {
            request_url = StoreMixin.methods.generateQueryParamsUrl(request_url, payload.params);
        }

        // Execute request & return
        let output = false;

        return axios.get(request_url)
            .then(function (response) {
                output = response.data.success;

                if (output) {
                    commit('setExportedSessions', response.data.data);
                } else {
                    commit('setError', response.data.message);
                    commit('setHumanError', response.data.human_message);
                }
            })
            .catch(function (error) {
                commit('setError', error.response ? error.response.data.message : error);
                commit('setHumanError', error.response ? error.response.data.human_message : error);
                output = false;
            })
            .then(function () {
                commit('setLoading', false);
                return output;
            });
    },

    async delete({ commit, dispatch }, payload) {
        dispatch('clearError');

        // Configure request
        const request_url = Vue.prototype.$url_api + 'v2/rails/sessions/' + payload.id;
        let config = {
            method: 'DELETE',
            url: request_url,
            headers: {},
        }

        // Execute request & return
        let output = false;

        return axios(config)
            .then(function (response) {
                output = response.data.success;

                if (!output) {
                    commit('setError', response.data.message);
                    commit('setHumanError', response.data.human_message);
                }
            })
            .catch(function (error) {
                commit('setError', error.response ? error.response.data.message : error);
                commit('setHumanError', error.response ? error.response.data.human_message : error);
                output = false;
            })
            .then(function () {
                return output;
            });
    },

    /**
     * Get nickname sessions
     */
    async getNicknameSessions({ commit, dispatch }, payload) {
        // Reset store to initial state
        commit('setNicknameSessions', []);
        commit('setNicknameSessionsTotal', null);
        commit('setLoadingNicknameSessions', true);
        const nicknameID = payload.id;

        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/rails/sessions/?nickname=' + nicknameID;

        // Formulate URL with query string with generateQueryParamsUrl mixin function
        if (payload && payload.params) {
            request_url = StoreMixin.methods.generateQueryParamsUrl(request_url, payload.params);
        }

        // Execute request & return
        let output = false;

        return axios.get(request_url)
            .then(function (response) {
                output = response.data.success;

                if (output) {
                    commit('setNicknameSessions', response.data.data);
                    commit('setNicknameSessionsTotal', response.data.total);
                } else {
                    commit('setNicknameSessionsError', response.data.message);
                    commit('setNicknameSessionsHumanError', response.data.human_message);
                }
            })
            .catch(function (error) {
                commit('setNicknameSessionsError', error.response ? error.response.data.message : error);
                commit('setNicknameSessionsHumanError', error.response ? error.response.data.human_message : error);
                output = false;
            })
            .then(function () {
                commit('setLoadingNicknameSessions', false);
                return output;
            });
    },

    // Reset this store to its initial state
    reset({ commit }) {
        commit('set', []);
        commit('setSelected', null);
        commit('setLastSession', null);
        commit('setLoading', true);
        commit('setError', null);
        commit('setHumanError', null);
    },

    clearError({ commit }) {
        commit('setError', null);
        commit('setHumanError', null);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
