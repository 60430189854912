import { mapGetters } from 'vuex';
import moment from 'moment';
import EventPermissionPicker from '@/components/Calendar/EventPermissionPicker/EventPermissionPicker.vue';
import Snackbar from "@/components/Snackbar";
import AlertError from '@/components/Alerts/AlertError/AlertError.vue';
import RailMixin from '@/mixins/Rail.vue';

export default {
    name: 'DrawerCreateEditEvent',
    
    mixins: [
        RailMixin,
    ],

    components: {
        EventPermissionPicker,
        Snackbar,
        AlertError,
    },

    props: {
        isOpen: {
            type: Boolean,
            required: true,
            default: false
        },
        action: {
            type: String, 
            required: false,
            default: 'create',
        },
        startDate: {
            type: String,
            required: false,
            default: null,
        },
        startTime: {
            type: String,
            required: false,
            default: null,
        }
    },

    data() {
        return this.initialState();
    },

    created() {
        this.computedValue = this.isOpen;
    },

    computed: {
        ...mapGetters({
            userTrackerId: 'auth/userTrackerId',
            events: 'eventsCalendar/data',
            selectedEvent: 'eventsCalendar/selectedEvent',
            eventCategories: 'eventsCategoriesCalendar/data',
            selectedCategory: 'eventsCategoriesCalendar/selected',
            error: 'eventsCalendar/error',
            humanError: 'eventsCalendar/humanError',
            users: 'users/usersAllActive',
            groups: 'groups/groups',
            teams: 'teams/teams',
        }),

        // Synthetic v-model
        computedValue: {
            get: function () {
                return this.drawerState;
            },
            set: function (newValue) {
                this.drawerState = newValue;

                if (newValue === false) {
                    this.$emit('close-drawer');
                }
            },
        },

        eventEpochTimestamps() {
            let output = {
                start: null,
                end: null,
            };

            if (this.dates.date_start && this.dates.time_start && this.dates.date_end && this.dates.time_end) {
                // Format Y-m-d T HH:MM:SS
                let start = this.dates.date_start + 'T' + this.dates.time_start;
                let end = this.dates.date_end + 'T' + this.dates.time_end;

                // Convert to epoch
                output.start = moment(start).valueOf();
                output.end = moment(end).valueOf();
            }

            return output;
        },

        currencyIcon() {
            let output = 'mdi-help';

            const currencyItemSelected = this.currenciesItems.find(e => e.value == this.event.cost_currency);
            if (currencyItemSelected) {
                const costCurrencyText = currencyItemSelected.text;
                if (this.event.cost_currency) {
                    output = 'mdi-currency-' + _.lowerCase(costCurrencyText);
                }
            }

            return output;
        },

        // Create or edit
        actionVerb() {
            return this.action == 'create'
                   ? 'Criar'
                   : 'Editar';
        },

        selectedEventPermissions() {
            let selectedPermissions = this.action == 'create' ? [] : this.selectedEvent.permissions
            return selectedPermissions;
        },
    },

    watch: {
        isOpen: function (newValue) {
            this.computedValue = newValue;

            // Setup form fields
            this.onOpen();
        },
    },

    methods: {
        initialState() {
            return {
                drawerState: false,
                submitting: false,
                event: {
                    title: null,
                    ref_organizer: null,
                    organizer: null,
                    ref_category: null,
                    description_public: null,
                    capacity: null,
                    datetime_start: null,
                    datetime_end: null,
                    is_all_day: false,
                    is_featured: false,
                    is_paid: false,
                    cost_currency: 2,
                    cost_price: null,
                    link_private: null,
                    link_public: null,
                    image: null,
                    venue_address: null,
                    venue_map_link: null,
                    venue_website: null,
                    venue_contact_email: null,
                    venue_contact_phone: null,
                },
                dates: {
                    date_start: null,
                    date_end: null,
                    time_start: null,
                    time_end: null,
                },
                eventImageRules: [
                    value => !value || value.size < 2000000 || 'Image size should be less than 2 MB!',
                ],
                permissions: {
                    types: [
                        { value: 'users', text: 'Jogadores' }, 
                        { value: 'groups', text: 'Níveis' }, 
                        { value: 'teams', text: 'Equipas' },
                    ],
                },
                booleanItems: [
                    { value: false, text: 'Não' },
                    { value: true, text: 'Sim' },
                ],
                currenciesItems: [
                    { value: 2, text: 'EUR' },
                    { value: 3, text: 'USD' },
                    { value: 4, text: 'GBP' },
                    { value: 7, text: 'BTC' },
                ],
                panelsActive: 0,
                menu: {
                    date_start: false,
                    date_end: false,
                    time_start: false,
                    time_end: false,
                },
                rules: {
                    title: [ v => !! v || 'Por favor preencha o título.'],
                    category: [ v => !! v || 'Por favor preencha a categoria.'],
                    capacity: [ v => _.isInteger(Number(v)) || 'Por favor introduza um número válido.'],
                    date_start: [ v => !! v || 'Por favor preencha a data.'],
                    time_start: [ v => !! v || 'Por favor preencha a hora de início.'],
                    time_end: [ v => !! v || 'Por favor preencha a hora de fim.'],
                    link_private: [ v => !v ? true : /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/.test(v) || 'Por favor preencha o link com um url válido.'],
                    link_public: [ v => !v ? true : /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/.test(v) || 'Por favor preencha o link com um url válido.'],
                },
            };
        },

        onOpen() {
            this.fetchData();

            if (this.action === 'create') {
                this.resetToInitialState();
                this.setupVModels();
                this.resetForm();
                this.$refs.EventPermissionPicker.reset()
            }

            // Setup form fields if editting an event
            if (this.action === 'edit') {
                this.setupSelectedEvent();
            }

            // Force setup of permissions in child component
            if (this.$refs.EventPermissionPicker && this.action === 'edit') {
                this.$refs.EventPermissionPicker.reset();
                this.$refs.EventPermissionPicker.setupPermissionsPassedByProp();
            }

            // Reset form validation
            this.resetForm(); 
        },

        fetchData() {
            if (_.isEmpty(this.eventCategories)) {
                this.$store.dispatch('eventsCategoriesCalendar/get');
            }

            if (_.isEmpty(this.users)) {
                let payload = this.formulateUsersAllUrlParameters();
                this.$store.dispatch('users/getUsersAllActive', payload);
            }
        },

        async submit() {
            // Validate form
            if ( ! this.$refs.FormEvent.validate() ) {
				return false;
			}

            // toggle state of submit btn  
            this.toggleSubmitting();

            // Define store action
            const storeAction = this.action == 'edit'
                                ? 'eventsCalendar/updateEvent'
                                : 'eventsCalendar/createEvent';

            // TODO: function to make the short description
            let payload = {
                body: {
                    ref_organizer: this.event.ref_organizer,
                    ref_category: this.event.ref_category,
                    title: this.event.title,
                    description_short: this.event.description_long,
                    description_public: this.event.description_public,
                    description_private: this.event.description_private,
                    datetime_start: this.eventEpochTimestamps.start,
                    datetime_end: this.eventEpochTimestamps.end,
                    timezone: 'GMT-TODO',
                    is_all_day: this.event.is_all_day,
                    is_featured: false,
                    is_paid: this.event.is_paid,
                    cost_currency: this.event.is_paid ? this.event.cost_currency : null,
                    cost_price: this.event.is_paid ? this.event.cost_price : null,
                    capacity: this.event.capacity,
                    link_private: this.event.link_private,
                    link_public: this.event.link_public,
                    image: null,
                    venue_address: this.event.venue_address,
                    venue_website: this.event.venue_website,
                    venue_map_link: this.event.venue_map_link,
                    venue_contact_email: this.event.venue_contact_email,
                    venue_contact_phone: this.event.venue_contact_phone,
                    permissions: this.$refs.EventPermissionPicker ? this.$refs.EventPermissionPicker.output : null,
                }
            };

            // Send 'eventID' if this is an edit
            if (this.action == 'edit') {
                payload.body.eventID = this.selectedEvent.id;
            }

            // Dispatch to store
            let res = await this.$store.dispatch(storeAction, payload);

            // toggle state of submit btn  
            this.toggleSubmitting();

            if(res) {
                 // Close drawer
                this.closeDrawer();
                
                // Get events
                this.$emit('get-events');

                // update selected event if we are editing 
                if (this.action == 'edit') {
                    this.$emit('set-selected-event');
                    this.updateSelectedEvent();
                }

                // User feedback
                this.$store.dispatch('UI/showSnackbar', {
                    message: 'Evento submetido com sucesso',
                    color: 'success',
                });
            }
        },

        closeDrawer() {
            this.drawerState = false;
            this.clearSelectedCategory();
        }, 

        /**
         * Fill the v-models with the selected event data
         */
        async setupSelectedEvent() {
            this.event = _.cloneDeep(this.selectedEvent);
            // Convert date/hour to input format
            this.setupTimestamps();
        },

        // Update selected event after edit
        async updateSelectedEvent() {
            // Refresh all events
            await this.$store.dispatch('eventsCalendar/get');
            // Update current selected event (so user sees update on current view)
            this.$store.commit('eventsCalendar/setSelectedEvent', this.events.find(e => e.id == this.selectedEvent.id));
        },

        setupTimestamps() {
            this.dates.date_start = moment(Number(this.selectedEvent.datetime_start)).format('YYYY-MM-DD');
            this.dates.date_end = moment(Number(this.selectedEvent.datetime_end)).format('YYYY-MM-DD');
            this.dates.time_start = moment(Number(this.selectedEvent.datetime_start)).format('HH:mm');
            this.dates.time_end = moment(Number(this.selectedEvent.datetime_end)).format('HH:mm');
        },

        /**
         * Reset form 
         */
        resetForm() {
            this.$refs.FormEvent.resetValidation();

            //Object.assign(this.$data, this.initialState());
            //this.event = this.initialState().event;

            // Clear errors
            this.$store.commit('eventsCalendar/setError', null); 
        },

        /**
         * Auto-populates capacity when category is selected
         * @param {Integer} value ID of category selected
         * @returns {Void}
         */
        onSelectCategory(value) {
            let selectedCategory = this.eventCategories.find(e => e.id == value);

            if (selectedCategory) {
                // Save selected category in store
                this.$store.commit('eventsCategoriesCalendar/setSelected', selectedCategory);

                // Populate capacity
                this.event.capacity = selectedCategory.default_capacity;
            }
        },

        /**
         * Auto-populates DateEnd when DateStart is selected
         * @param {Integer} value DateStart selected
         * @returns {Void}
         */
        onSelectDateStart(value) {
            // Close date picker
            this.menu.date_start = false;

            // Set date_end to the same value (most usual scenario)
            this.dates.date_end = value;
        },

        /**
         * Auto-populates TimeEnd when TimeStart is selected, based on the
         * selected category's default duration, if possible
         * @param {Int} value
         * @returns {Void}
         */
        onSelectTimeStart(value) {
            // Save time_start value
            this.$refs.startHourMenu.save(this.dates.time_start);
        
            // If category is selected, we prefill using the category default duration
            // Otherwise, we add 1 hour by default
            const durationToAdd = ! _.isEmpty(this.selectedCategory)
                                  ? this.selectedCategory.default_duration
                                  : 60;

            // Prefill time_end if was not defined before
            if(! this.dates.time_end) {
                this.dates.time_end = moment('1970-01-01T' + this.dates.time_start)
                .add(durationToAdd, 'minutes')
                .format('HH:mm');
            }
        },

        /**
         * Auto-populates DateEnd when IsAllDay is selected
         * @param {Boolean} value IsAllDay value selected
         * @returns {Void}
         */
        onSelectIsAllDay(value) {
            if (value === true) {
                this.dates.date_end = this.dates.date_start;
            }
        },

        /**
         * Call mutation from eventsCategoriesCalendar to clear selected Category
         * @returns {Void}
         */
        clearSelectedCategory() {
            this.$store.commit('eventsCategoriesCalendar/setSelected', null);
        },

        reset() {
            Object.assign(this.$data, this.initialState());
        },

        /**
         * return all v-models as to their initial state
         * 
         * @returns {Void}
         */
        resetToInitialState() {
            Object.assign(this.$data.event, this.initialState().event);
            Object.assign(this.$data.dates, this.initialState().dates);
            this.panelsActive = 0;
        },

        /**
         * Setup v-models if the prop is defined
         * 
         * @returns {Void}
         */
        setupVModels() {
            if(this.startDate) {
                this.dates.date_start = this.startDate;
                this.dates.date_end = this.startDate;
            }

            if(this.startTime) {
                this.dates.time_start = this.startTime;
                this.onSelectTimeStart(this.dates.time_start);
            }
        },

        /**
         * Toggle loading state of submit button
         * 
         * @returns {void}
         */
        toggleSubmitting() {
            this.submitting = ! this.submitting;    
        }
    },
}