import Vue from 'vue';

export default {
    name: 'GameFlagsCombobox',

    props: {
        value: { 
            type: [Object, Array], 
            required: false, 
            default: () => { } },
        label: {
            type: String,
            required: false,
            default: 'Opções'
        },
        status: {
            type: String,
            required: false,
            default: ''
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        solo: {
            type: Boolean,
            required: false,
            default: false
        },
        clearable: {
            type: Boolean,
            required: false,
            default: false
        },
        outlined: {
            type: Boolean,
            required: false,
            default: true
        },
        hideDetails: {
            type: Boolean,
            required: false,
            default: false
        },
        hint: {
            type: String,
            required: false,
            default: ''
        },
        autoReload: {
            type: Boolean,
            required: false,
            default: true
        },
        multiple: {
            type: Boolean,
            required: false,
            default: true,
        },
    },

    created() {
    },

    data() {
        return this.initialState();
    },

    methods: {
        initialState() {
            return {
                flags: Vue.prototype.scheduling_configs.gameFlags,
                selectedFlags: null,
            }
        },

        emitChange() {
            this.$emit('input', this.selectedFlags);
            this.$emit('change', this.selectedFlags);
        },
    }
}