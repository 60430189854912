import { mapGetters } from 'vuex';
import AlertError from '@/components/Alerts/AlertError/AlertError.vue';

export default {
    name: 'DialogCreateEditFactors',

    components: {
        AlertError
    },

    computed: {
        ...mapGetters({
            data: 'schedulingFactors/factor',
            loading: 'schedulingFactors/loading',
            error: 'schedulingFactors/error'
        }),
        title: function () {
            let word = this.action == 'create'
                ? 'Criar'
                : 'Editar';

            let output = word + ' fator';
            return output;
        },
    },

    props: {
        open: {
            type: Boolean,
            required: false,
            default: false
        },
        action: {
            type: String,
            required: false,
            default: "create"
        },
        factorToEdit: {
            type: Object,
            required: false
        }
    },

    data() {
        return this.initialState();
    },

    created() {
        this.init()
    },

    methods: {
        initialState() {
            return {
                factor: {},
                rules: {
                    name: [
                        v => !!v || 'Por favor introduza um nome para o fator',
                        v => (v && v.length <= 45) || 'O nome do fator não pode ter mais de 45 caracteres',
                    ],
                    description: [
                        v => (v && v.length <= 150) || 'A descrição do fator não pode ter mais de 150 caracteres',
                    ],
                    code: [
                        v => (v && v.length <= 45) || 'O código do fator não pode ter mais de 45 caracteres',
                    ],
                    DTOproperty: [
                        v => {
                            if (v) return v.length <= 45 || 'A propriedade do DTO do fator não pode ter mais de 45 caracteres';
                            else return true;
                        },
                    ],
                    column: [
                        v => {
                            if (v) return v.length <= 75 || 'O campo coluna não pode ter mais de 75 caracteres';
                            else return true;
                        },
                    ],
                }
            }
        },

        async init() {
        },

        closeDialog() {
            this.$emit('close');
        },

        async save() {
            this.$store.dispatch('schedulingFactors/clearError');
            this.validateFactor();
            if (this.action == 'edit') {
                await this.$store.dispatch('schedulingFactors/update', this.factor);
                if (!this.error) {
                    this.$emit('updateFactor', this.data);
                }
            } else if (this.action == 'create') {
                await this.$store.dispatch('schedulingFactors/create', this.factor);
                if (!this.error) {
                    this.$emit('addFactor', this.data);
                }
            }
        },

        validateFactor() {
            if (!this.factor.exclusion) {
                this.factor.exclusion = false;
            }
            if (!this.factor.calculation) {
                this.factor.calculation = false;
            }
        },

        resetForm() {
            if(this.$refs.form) {
                this.$refs.form.reset();
                this.$refs.form.resetValidation();
            }

            Object.assign(this.$data, this.initialState());
            this.$store.dispatch('schedulingFactors/clearError');
        },
    },

    watch: {
        open(open) {
            if (open) {
                if (this.action == "create") {
                    this.resetForm();
                }
            }
        },

        factorToEdit(factorToEdit) {
            this.factor = factorToEdit;
        }
    }
}