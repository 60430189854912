import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth.vue';
import RailMixin from '@/mixins/Rail.vue';

export default {
    name: "CardLiveEvent",
    props: {
        event: {
            type: Object,
            required: true
        }
    },

    mixins: [
        AuthMixin,
        RailMixin,
    ],

    computed: {
        ...mapGetters({
            countries: 'countries/countries',
        }),
    },

    data() {
        return this.initialState();
    },
      
    methods: {
        initialState() {
            return {}
        },
        
        goToEventDetails(event) {
            this.$store.commit('liveEvents/setSelectedEvent', event);
            this.$router.push(`/live/events/${event.id}`);
        },

        editEvent(event) {
            this.$emit('edit-event', event);
        },

        deleteEvent(event) {
            // Lógica para apagar evento
            this.$emit('delete-event', event);
        },

        getCodeFromCountry(name) {
            let output = 'unknown.svg';

            if (!this._.isEmpty(this.countries) && name) {
                let countryObject = this.countries.find(e => e.name == name);
                if (countryObject) {
                    output = countryObject.code + '.svg'
                }
            }

            return output;
        }
    }
}