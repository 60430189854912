import Hero from '@/components/Hero/Hero.vue';
import DialogRequestUser from '@/components/VideoRequests/dialogues/DialogRequestUser/DialogRequestUser.vue';
import TableVideoRequestsUser from '@/components/VideoRequests/tables/TableVideoRequestsUser/TableVideoRequestsUser.vue';
import DialogFeedback from '@/components/VideoRequests/dialogues/DialogFeedback/DialogFeedback.vue';

export default {
    name: 'VideoRequests',
    
    components: {
        Hero,
        DialogRequestUser,
        TableVideoRequestsUser,
        DialogFeedback,
    },
    data() {
        return this.initialState();
    },
    methods: {
        initialState() {
            return {
                dialogues: {
                    request: false,
                    feedback: false,
                },
            };
        },
        requestAccess() {
            // Open dialog
            this.openDialog('request');
        },
        openDialogFeedback() {
            this.openDialog('feedback');
        },
        openDialog(d) {
            this.dialogues[d] = true;
        },
    },
};
