import { mapGetters } from "vuex";
import RailMixin from '@/mixins/Rail.vue';

export default {
    name: 'CalendarFilter',

    mixins: [
        RailMixin,
    ],

    components: {},

    computed: {
        ...mapGetters({
            groups: 'groups/groups',
            users: 'users/usersAllActive',
        }),

        output() {
            let out = {};

            // add group filter
            if ( ! _.isEmpty(this.filters.group) ) {
                out.permission_group = this.filters.group;
            }

            // add coach filter
            if ( ! _.isEmpty(this.filters.coach) ) {
                out.coach = this.filters.coach;
            }

            // add participant filter
            if ( ! _.isEmpty(this.filters.attendee) ) {
                out.attendee = this.filters.attendee;
            }

            // add attended filter
            if ( ! _.isUndefined(this.filters.attended) ) {
                out.attended = this.filters.attended;
            }

            return out;
        },
    },

    created() {
        this.fetchData();
    },

    data() {
        return this.initialState();
    },

    methods: {
        initialState() {
            return {
                filters: {
                    group: null,
                    coach: null,
                    attendee: [],
                    attended: undefined,
                },
                itemsAttendance: [
                    { value: 1, text: 'Compareceu' },
                    { value: 0, text: 'Não compareceu' }
                ],
            }
        },

        fetchData() {
            this.$store.dispatch('groups/get');
            
            if (this._.isEmpty(this.users)) {
                let payload = this.formulateUsersAllUrlParameters();
                this.$store.dispatch('users/getUsersAllActive', payload);
            }
        },

        onAttendeeInput() {
            // Clear 'attended' if 'attendee' is null
            if ( _.isEmpty(this.filters.attendee) ) {
                this.filters.attended = this.initialState().filters.attended;
            }

            this.emitChanges();
        },

        async onInput() {
            output = await this.constructPayload();
            this.emitChanges();
        },

        emitChanges() {
            this.$emit('change');
        },
    }
}