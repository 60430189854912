import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth';
import DataMixin from '@/mixins/Data';
import AlertNoData from '@/components/AlertNoData';
import Snackbar from '@/components/Snackbar';
import Hero from '@/components/Hero/Hero.vue';

export default {
    name: 'Contracts',

    components: {
        Hero,
        AlertNoData,
        Snackbar,
    },

    mixins: [
        AuthMixin,
        DataMixin,
    ],

    computed: {
        ...mapGetters({
            contracts: 'contracts/contracts'
        })
    },

    data() {
        return {
            dataLoadingFinished: false,

            tableHeaders: [
                { text: 'Jogador', value: 'name_users' },
                { text: 'Data de termo', value: 'contractEndDate_users' },
                { text: 'Estado', value: 'status', sortable: false },
            ],

            tables: {
                valid: {
                    search: null,
                    itemsPerPage: 10,
                    footerProps: {
                        'items-per-page-options': this.$itemsPerPageOptions,
                    },
                },
                expired: {
                    search: null,
                    itemsPerPage: 10,
                    footerProps: {
                        'items-per-page-options': this.$itemsPerPageOptions,
                    },
                },
                expiring_soon: {
                    search: null,
                    itemsPerPage: 10,
                    footerProps: {
                        'items-per-page-options': this.$itemsPerPageOptions,
                    },
                },
            },

            tabs: {
                current: null,
                headers: ['Validos', 'Incompletos']
            }
        };
    },

    async created() {
        await this.allowOnlyRoles(['rail_admin', 'rail_manager', 'tracker_admin', 'tracker_manager']);
        this.initialize();
    },

    methods: {
        async initialize() {
            await this.$store.dispatch('contracts/getContracts');
            this.dataLoadingFinished = true;
        },

        filterContracts() {
            switch ( this.tabs.current ) {
                case 0:
                    return this.contracts.valid;
                case 1:
                    return this.contracts.incomplete;
                default:
                    return this.contracts.valid;
            }
        },

        getExpiryColor(item) {
            if (item.days_to_expiry <= 30) return 'orange'
            else if (item.days_to_expiry <= 90) return 'blue'
            else return 'green'
        }
    }
};