import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth.vue';
import DataMixin from '@/mixins/Data.vue';
import DialogEditUserContract from '@/components/Rail/Dialog/DialogEditUserContract/DialogEditUserContract.vue';
import Alert from '@/components/Alerts/Alert/Alert.vue';

export default {
  name: 'UserContract',

  props: {
    title: { type: String, required: false, default: '' },
    flat: { type: Boolean, required: false, default: false },
    tile: { type: Boolean, required: false, default: false },
  },

  components: {
    DialogEditUserContract,
    Alert,
  },

  computed: {
    ...mapGetters({
      selectedUser: 'users/selectedUser',
      userLoading: 'users/userLoading',
      userStatistics: 'users/userStatistics'
    }),

    hasContract: function () {
      let hasContractSignDates = this.selectedUser && this._.isEmpty(this.selectedUser.contractSignDate) && this._.isEmpty(this.selectedUser.contractEndDate);
      return hasContractSignDates;
    },

    // uses mixin dateDiff() to get number of days between two dates
    daysToEndContract: function () {
      if (this.selectedUser && this.selectedUser.contractSignDate && this.selectedUser.contractEndDate) {
        return this.dateDiff(this.selectedUser.contractEndDate.date, this.$moment());
      } else {
        return '0';
      }
    },

    progressBarContractValues: function () {
      let output = {
        value: 0,
        totalDays: 0,
        progressDay: 0,
      }

      if (this.selectedUser && this.selectedUser.contractSignDate && this.selectedUser.contractEndDate) {

        output.totalDays = this.userStatistics && this.userStatistics.CONTRACT_DURATION_PCT ? this.userStatistics.CONTRACT_DURATION_PCT.total : 0
        output.progressDay = (this.userStatistics && this.userStatistics.CONTRACT_DURATION_PCT ? this.userStatistics.CONTRACT_DURATION_PCT.percentageValue : 0 ) * 100
      }

      return output;
    },
  },

  mixins: [
    DataMixin,
    AuthMixin,
  ],

  data() {
    return this.initialState();
  },

  methods: {
    initialState() {
      return {
        dialogues: {
          editUserContract: false,
        }
      }
    },

    editUserContract() {
      this.dialogues.editUserContract = true;
    }
  }
}
