import Vue from 'vue';
import RailMixin from '@/mixins/Rail.vue';
import AuthMixin from '@/mixins/Auth.vue';

export default {
    name: 'ButtonCopyToClipboard',

    mixins: [
        RailMixin,
        AuthMixin,
    ],

    props: {
        name: { type: String, required: false, default: null },
        network: { type: Number, required: false, default: null },
        description: { type: String, required: false, default: null },
        isManagement: { type: Boolean, required: false, default: false }
    },

    data() {
        return this.initialState();
    },

    computed: {
        /**
         * Expand networks are networks where both the 'Name' and 'Email' might be necessary
         * @returns {Bol}
         */
        isExpandNetwork: function () {
            let expandNetworks = [
                Vue.prototype.$rail_GGPoker_network_id,
                Vue.prototype.$rail_YaPoker_network_id,
            ]
            return this._.includes(expandNetworks, this.network);
        },

        isExternal: function() {
            return this.name.toLowerCase().includes('external');
        },

        isCriptoNetwork: function () {
            let criptoNetworks = [Vue.prototype.$rail_Coinbase_network_id];
            return this._.includes(criptoNetworks, this.network);
        },
    },

    methods: {
        initialState() {
            return {
                warnUser: '⚠️ Entra em contacto com a gestão para obter o endereço',
                warnAdmin: '⚠️ Endereço criado na hora',
                listItems: [
                    {
                        propertyToCopy: 'name',
                        text: this.name ? '✅ Nickname' : '❌ Nickname',
                        class: this.name ? 'cursor-pointer' : 'text--secondary'
                    },
                    {
                        propertyToCopy: 'description',
                        text: this.description ? '✅ Email' : '❌ Email',
                        class: this.description ? 'cursor-pointer' : 'block-pointer text--secondary'
                    }
                ]
            };
        },

        triggerCopyToClipboard(property) {
            // Bail if property to copy doesnt exist
            if (! this[property] || (this.isCriptoNetwork && this.isManagement)) {
                return false;
            }

            this.$emit('action');

            // Trigger copy to clipboard
            this.copyToClipboard(this[property]);
      
            // Show snackbar
            const titleCopiedProperty = property == 'name'
                                        ? 'Nickname'
                                        : 'Email';

            this.$store.dispatch('UI/showSnackbar', {
              message: titleCopiedProperty + " copiado com sucesso.",
              color: 'success'
            });
        }
    }

}