import { mapGetters } from 'vuex';
import DialogConfirmation from '@/components/Rail/Dialog/DialogConfirmation/DialogConfirmation.vue';
import DialogAddEditTournamentLive from '@/components/Dialog/DialogAddEditTournamentLive/DialogAddEditTournamentLive.vue';
import DialogAddEditTeam from '@/components/Dialog/DialogAddEditTeam/DialogAddEditTeam.vue';
import AuthMixin from '@/mixins/Auth.vue';
import RailMixin from '@/mixins/Rail.vue';
import DataMixin from '@/mixins/Data.vue';
import EmptyStateNoData from '@/components/EmptyStateNoData';

export default {
    name: 'TableLiveListTournaments',
    
    components: {
      DialogConfirmation,
      EmptyStateNoData,
      DialogAddEditTeam,
      DialogAddEditTournamentLive,
    }, 

    data() {
      return this.initialState();
    },

    mixins: [
      AuthMixin,
      RailMixin,
      DataMixin,
    ],

    computed: {
      ...mapGetters({
        tournaments: 'liveTournaments/tournaments',
        selectedTournament: 'liveTournaments/selectedTournament',
        tournamentsLoading: 'liveTournaments/tournamentsLoading',
        error: 'liveTournaments/error',
        humanError: 'liveTournaments/humanError',
      }),

      optionsComputed: {
        get: function () {
          return this.options;
        },
        set: function (newValue) {
            if (!this._.isEqual(this.options, newValue)) {
                this.options = newValue;
            }
        }
      }
    },

    props: {
      filters: { type: Object, required: false, default: () => { }},
      actions:{
        type: Function,
        required: false,
        default: ()=> {}
      }
    },
    
    watch: {
      options: {
        handler() {
          this.fetchData();
        },
        deep: true,
        immediate: true,
      },
    },

    methods: {
      initialState() {
        return {
          dialogues: {
            viewTournament: false,
            removeTournament: false,
            editTournament: false
          },
          idToDelete: null,
          headers: [
            { text: 'Nome', value: 'name', sortable: false },
            { text: 'Evento', value: 'event.name', sortable: false },
            { text: 'Data inicial', value: 'starts_at', sortable: false },
            { text: 'Inscrições até', value: 'late_registration_at', sortable: false },
            { text: 'Buy-in', value: 'buyin', sortable: false },
            { text: 'Prize Pool', value: 'prize_pool', sortable: false },
            { text: 'Stack Inicial', value: 'starting_stack', sortable: false },
            { text: 'Participantes', value: 'participants', sortable: false },
            { text: 'Ação', value: 'action', sortable: false },
          ],
          footerProps: {
            'items-per-page-options': this.$itemsPerPageOptions,
          },
          search: null,
          options: {
            page: 1,
            itemsPerPage: 10,
            sortBy: [],
            sortDesc: [],
            groupBy: [],
            groupDesc: [],
            mustSort: true,
            multiSort: true
          },
        }
      },

      async fetchData() {
        // Base payload
        let payload = {
          params: {
            page: this.options.page,
            itemsPerPage: this.options.itemsPerPage,
          }
        };
        Object.assign(payload.params, this.sortDescHandler(this.options.sortDesc));
        this.addParameterToPayload(payload, 'sortBy[]', this.options.sortBy);
        // API Call
        await this.$store.dispatch('liveTournaments/fetchTournaments', payload);
      },

      getCurrencyInputWidth(value) {
        let output = '50px';
        if (value) {
            output = parseFloat(value).toFixed(3);

            // turn output into String
            output = String(output);

            output = (output.length + 1.2) * 8 + 'px';
        }
        return output;
    },

      async deleteTournament(){
        let payload = {
          id:this.idToDelete,
        }
        let result = await this.$store.dispatch('liveTournaments/deleteTournament', payload);

        this.dialogues.removeTournament = false

        if (result === true) {
          // Show success snackbar
          this.$store.dispatch('UI/showSnackbar', {
              message: 'Torneio removido com sucesso.',
              color: 'success'
          });

          this.$store.dispatch('liveTournaments/fetchTournaments', payload);
          this.closeDialog();
      }
      },
      
      constructPayload() {
        let payload = {
          params: {
            page: this.options.page,
            itemsPerPage: this.options.itemsPerPage,
          },
        }
        return payload;
      },

      async dialogViewTournament(item) {
        this.setActiveItem(item);
        this.$router.push({
          path: `/live/tournaments/${item.id}`
        });
      },
      
      openDialogRemoveConfirmation(id) {
        this.dialogues.removeTournament = true;
        this.idToDelete = id
      },

      closeDialogRemoveConfirmation() {
        this.dialogues.removeTournament = false;
      },

      toggleDialog(dialog) {
        this.dialogues[dialog] = ! this.dialogues[dialog];
      },

      setActiveItem(item) {
        if (item) {
          this.$store.commit(
            'liveTournaments/setSelectedTournament',
            item
          );
        }
      },

      openDialogAddEditTournament(dialog, item) {
        this.setActiveItem(item);
        this.toggleDialog(dialog);
      },

      closeDialogEditTournament() {
        this.$store.commit('liveTournaments/setSelectedTournament', null);
        this.toggleDialog('editTournament');
      }

    },

  }