import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth.vue';

export default {
    name: 'ButtonCalendarGotoForm',

	mixins: [
		AuthMixin,
	],

    created() {
        this.init();
    },

    mounted() {},

    data() {
        return this.initialState();
    },

    computed: {
        ...mapGetters({
            selectedEvent: 'eventsCalendar/selectedEvent',
        }),
    },

    methods: {
        init() {},
        initialState() {
            return {}
        },
        openDialog() {
            this.$emit('open-dialog')
        },
    }
}