import { mapGetters } from 'vuex';

export default {
    name: 'SchedulingAdminHome',

    components: {
    },

    computed: {
        ...mapGetters({
          schedulingModels: 'schedulingModels/data',
          schedulingGameGroups:  'schedulingGameGroups/data',
          schedulingGames:  'schedulingGames/data',
          schedulingFactors:  'schedulingFactors/factors'
        }),
    },

    data() {
        return this.initialState();
    },

    methods: {
        initialState() {
            return {
                results: 'Empty'
            };
        },

        init() {
            // Deny access if user doesn't have the necessary role
            if (!this.userHasRole(['beta_tester_scheduling'])) {
                this.$router.push({ path: '/' });
            }
        },

        async getSchedulingModels() {
            await this.$store.dispatch('schedulingModels/get');
            this.results = this.schedulingModels;
        },

        async getSchedulingModel(id) {
            await this.$store.dispatch('schedulingModels/getById', id);
            this.results = this.schedulingModels;
        },

        async getSchedulingGameGroupsFromModel(modelId) {
            await this.$store.dispatch('schedulingGameGroups/getForModel', modelId);
            this.results = this.schedulingGameGroups;
        },
        
        async getSchedulingGameGroupFromModel(modelId, gameGroupId) {
            await this.$store.dispatch('schedulingGameGroups/getOneForModel', { modelId, gameGroupId });
            this.results = this.schedulingGameGroups;
        },

        async getSchedulingFactors() {
            await this.$store.dispatch('schedulingFactors/get');
            this.results = this.schedulingFactors;
        },

        async getSchedulingFactor(id) {
            await this.$store.dispatch('schedulingFactors/getById', id);
            this.results = this.schedulingFactors;
        },

        async getSchedulingGames() {
            await this.$store.dispatch('schedulingGames/get');
            this.results = this.schedulingGames;
        },

        async getSchedulingGame(id) {
            await this.$store.dispatch('schedulingGames/getById', id);
            this.results = this.schedulingGames;
        },

        async getSchedulingGamesFromGroup(id) {
            await this.$store.dispatch('schedulingGames/getFromGroup', id);
            this.results = this.schedulingGames;
        },

        async getSchedulingGameGroups() {
            await this.$store.dispatch('schedulingGameGroups/get');
            this.results = this.schedulingGameGroups;
        },

        async getSchedulingGameGroup(id) {
            await this.$store.dispatch('schedulingGameGroups/getById', id);
            this.results = this.schedulingGameGroups;
        },
    }
}