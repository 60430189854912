import { mapGetters } from 'vuex';

export default {
    name: 'SessionLiveMetadataView',
    props: {
      metaLive: {
        type: Array,
        required: true,
      }
    },

    data() {
      return {
        headers: [
            { text: 'Evento', value: 'live.name', sortable: false, },
            { text: 'Torneio', value: 'event.name', sortable: false, },
            { text: 'Dia', value: 'day', sortable: false, },
            { text: 'Buy-in', value: 'buyin', sortable: false, },
            { text: 'Prêmio', value: 'prize', sortable: false, },
            { text: '', value: 'data-table-expand' },
          ],
      };
    },

  computed: {
    ...mapGetters({
      selectedSession: 'railSessions/selectedSession',
    }),
    
      metaLiveData(){
        return this.metaLive;
      }
    },

    methods: {
        formatDate(date){
            return this.$moment(date).format('YYYY-MM-DD HH:mm');
        },

        getMaxWidthColumnName() {
            if (this.$vuetify.breakpoint.lgOnly || this.$vuetify.breakpoint.lgAndUp) {
              return '500px';
            }  else if (this.$vuetify.breakpoint.md) {
              return '180px';
            } else if (this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs) {
              return '80px';
            } else{
              return '150px';
            }
          },

        getNumPlayersBegin(item){
            let keys = Object.keys(item);
            let numPlayersBeginKeys = keys.filter(key => key.startsWith("num_players_begin"));
            let numPlayersBeginValues = numPlayersBeginKeys.map(key => item[key]);
            return numPlayersBeginValues.join(" / ");
        },

        getCurrency(item){
          let currency = item.event[0] && item.event[0].buyin_currency;
          switch(currency){
              case "EUR":
                  return "€";
              case "USD":
                  return "$";
              default:
                  return '';
          }
        },

        getCurrencyInputWidth(value) {
          let output = '50px';
          if (value) {
              output = parseFloat(value).toFixed(3);

              // turn output into String
              output = String(output);

              output = (output.length + 1) * 7 + 'px';
          }
          return output;
        },

        getNumPlayersEnd(item){
            let keys = Object.keys(item);
            let numPlayersEndKeys = keys.filter(key => key.startsWith("num_players_end"));
            let numPlayersEndValues = numPlayersEndKeys.map(key => item[key]);
            return numPlayersEndValues.join(" / ");
        },

        getDifficultyName(item){
            switch(item.difficulty){
                case 1:
                    return "1 - Fácil";
                case 2:
                    return "2 - Médio";
                case 3:
                    return "3 - Difícil";
                default:
                    return "Sem dados";
            }
      },
        
      getEventName(item) {
        if (item && (typeof item.event == 'object')) {
          return item.event[0].name;
        } else {
          return item.event;
        }
      },

      getLiveName(item) {
        if (item && (typeof item.live == 'object')) {
          return item.live[0].name;
        } else {
          return item.live;
        }
      }
    },
}