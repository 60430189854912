import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth';
import RailMixin from '@/mixins/Rail';
import AlertError from '@/components/Alerts/AlertError/AlertError.vue';

export default {
    name: 'DialogCreateNicknameAlias',

    props: {
        isOpen: {
            type: Boolean,
            required: true,
            default: false
        },
    },

    components: {
        AlertError,
    },

    mixins: [
        AuthMixin,
        RailMixin,
    ],

    data() {
        return this.initialState();
    },

    computed: {
        ...mapGetters({
            error: 'nicknames/error',
            humanError: 'nicknames/humanError',
            userTrackerId: 'auth/userTrackerId',
            users: 'users/usersAllActive',
            selectedUser: 'users/selectedUser',
            nicknamesByUserID: 'nicknames/nicknamesByUserID',
            nicknamesAllMine: 'nicknames/nicknamesAllMine',
            networks: 'networks/networks',
            selectedNickname: 'nicknames/selectedNickname',
        }),

        hintAlias: function () {
            let output;
            if (this.selectedNickname) {
                output = 'Este alias vai ficar associado ao nickname ' + this.selectedNickname.name;
            }
            if (this.form.name && this.selectedNickname) {
                output = this.form.name + ' vai ser um alias do nickname ' + this.selectedNickname.name;
            }

            return output;
        },

    },

    watch: {
        isOpen: function () {
            if (this.$refs && this.$refs.inputAliasNickname) {
                this.$refs.inputAliasNickname.resetValidation();
            }
        },
    },
    
    methods: {
        initialState() {
            return {
                submitting: false,
                form: {
                    name: null,
                },
                rules: {
                    name: [
                        v => !!v || 'Por favor preencha o campo nickname alias',
                    ],
                },
            };
        },

        async submit() {
            if (!this.$refs.formCreateNicknameAlias.validate()) {
                return false;
            }

            // Toggle submit button
            this.toggleSubmitting();

            let payload = {
                body: {
                }
            }

            // pre populated fields by selectedNickname
            if (this.selectedNickname) {
                if (this.selectedNickname.user) {
                    payload.body.user = this.selectedNickname.user.id;
                }
    
                if (this.selectedNickname.network) {
                    payload.body.network = this.selectedNickname.network.id;
                }
    
                if (this.selectedNickname.id) {
                    payload.body.aliasChildOf = this.selectedNickname.id;
                }
            }

            // if has name
            if (!this._.isNull(this.form.name)) {
                payload.body.name = this.form.name;
            }

            let result = await this.$store.dispatch('nicknames/create', payload);

            // // Re-enable button
            this.toggleSubmitting();

            // On success
            if (result === true) {
                // Show success snackbar
                this.$store.dispatch('UI/showSnackbar', {
                    message: 'Nickname alias criado com sucesso.',
                    color: 'success'
                });
                
                if (this.userIsManager) {

                    // only run if this.selectedNickname.user.id == userTrackerId
                    if (this.selectedNickname.user.id == this.userTrackerId) {
                        // clear current payload user tracker id in order to be able to refresh nicknames again
                        this.$store.commit('nicknames/setCurrentPayloadUserTrackerId', null);
                        this.getNicknamesByUserTrackerID();
                    }

                    // update TableBalances
                    let payload = {
                        user: this.selectedNickname.user.id,
                        aliasParameter: '&aliasChildOf=null'
                    }
                    // clear current payload user id in order to be able to refresh nicknames again
                    this.$store.commit('nicknames/setCurrentPayloadUserId', null);
                    // reload nicknames by user id
                    this.$store.dispatch('nicknames/getAllPlayer', payload);
                } else {
                    // set promise to null in order to be able to reload nicknames all mine
                    this.$store.commit('nicknames/setPromiseAllMine', null);

                    // reload nicknames all mine
                    this.$store.dispatch('nicknames/getAllMine');
                }

                // if update is sucessfully completed , it emits reload event to update tableNicknames
                this.$emit('reload');
                
                // closes dialog
                this.closeDialog();
            }
        },

        /**
         * Used to disable Submit button
         */
        toggleSubmitting() {
            this.submitting = !this.submitting;
        },

        closeDialog() {
            this.$emit("close-dialog");
            this.form.name = null;
        },

    },
};
