export default {
    name: "ButtonAction",
    props: {
        icon: {
            type: String,
            required: false,
            default: ''
        },
        text: {
            type: String,
            required: false,
            default: 'Default text'
        },
        color: {
            type: String,
            required: false,
            default: '#FFC900'
        },
        textColor: {
            type: String,
            required: false,
            default: 'black'
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        large: {
            type: Boolean,
            required: false,
            default: true
        },
        small: {
            type: Boolean,
            required: false,
            default: false
        },
        block: {
            type: Boolean,
            required: false,
            default: true
        },
    },
    methods: {
        onClick() {
            this.$emit("click");
        }
    }
}