/**
 * Proprietary Polarize Poker plugin
 * 
 * Previously used the 'v-clipboard' plugin
 * @ref https://www.npmjs.com/package/v-clipboard
 * 
 * Replaced due to a problem where content couldn't be copied in a dialog context
 * Used a function a user posted as an answer
 * @ref https://github.com/euvl/v-clipboard/issues/18#issuecomment-733642508
 * 
 * Created the plugin following this guide
 * @ref https://chafikgharbi.com/vuejs-global-function/
 */

import Vue from 'vue';

const ClipboardPlugin = {
    install(Vue, options) {
      Vue.prototype.clipboard = (str) => {
        const el = document.createElement('textarea');
        el.addEventListener('focusin', e => e.stopPropagation());
        el.value = str;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
      }
    },
}

Vue.use(ClipboardPlugin);