export default {
  computed: {
    items () {
      return Array.from({ length: this.length }, (k, v) => v + 1)
    },
    length () {
      return 70
    },
  },
  props: {
    title: {
      type: String,
      required: false,
      default: 'Histórico',
    }
},
}