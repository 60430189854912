import AuthMixin from '@/mixins/Auth';
import TableVideoReports from '@/components/VideoReports/tables/TableVideoReports.vue';
import Hero from "@/components/Hero/Hero.vue";
import Snackbar from "@/components/Snackbar";

export default {
    name: 'VideoReports',

    components:{
      TableVideoReports,
      Hero,
      Snackbar,
    },
    
    mixins: [
        AuthMixin,
    ],

    async created() {
        await this.allowOnlyRoles([
            'rail_admin',
            'rail_manager',
            'tracker_admin',
            'tracker_manager',
            'team_cash_manager',
            'video_reports_reviewer_team_mtt',
            'video_reports_reviewer_team_cash',
        ]);
    }
}