import Vue from 'vue';
import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth.vue';
import RailMixin from '@/mixins/Rail.vue';
import DataMixin from '@/mixins/Data.vue';
import EmptyStateNoData from '@/components/EmptyStateNoData';
import ValueChip from '@/components/ValueChip';
import UserCard from '@/components/UserCard/UserCard.vue';

export default {
  name: 'TableGamesPlayed',

  props: {
    // values: "nickname", "network", "audit", "gameEntries", "games"
    type: { type: String, required: false, default: 'nickname' },
    elevation: { type: String, required: false, default: '2' },
    filters: { type: Object, required: false, default: () => {} },
    returnOwnData: { type: Boolean, required: false, default: false },
    singleGame: { type: Number, required: false, default: null },
    title: { type: String, required: false, default: 'Jogos' },
    isGameSingleView: { type: Boolean, required: false, default: false },
    hideColumnLevel: { type: Boolean, required: false, default: false },
    hideColumnPlayer: { type: Boolean, required: false, default: false },
    hideColumnData: { type: Boolean, required: false, default: false },
    hideColumnTournament: { type: Boolean, required: false, default: false },
    hideColumnFlag: { type: Boolean, required: false, default: false },
    useInDialogSession: { type: Boolean, required: false, default: false },
    hideHeaderAndFooter: { type: Boolean, required: false, default: false },
    emptyStateMessage: { type: String, required: false, default: 'Ajuste os termos da sua pesquisa para encontrar mais resultados' },
    emptyStateIcon: { type: String, required: false, default: 'mdi-check-circle-outline' },
    emptyStateClassColor: { type: String, required: false, default: 'success white--text' },
    emptyStateStyle: { type: String, required: false, default: 'min-height: 500px' },
    emptyStateClassIcon: { type: String, required: false, default: 'white' },
  },

  mixins: [
    AuthMixin,
    RailMixin,
    DataMixin,
  ],

  components: {
    EmptyStateNoData,
    ValueChip,
    UserCard,
  },

  data() {
    return this.initializeState();
  },

  async created() {
    if (!this.fetchLoading) {
      return await this.fetchData();
    }
  },

  computed: {
    ...mapGetters({
      nicknameGameEntries: 'nicknames/nicknameGameEntries',
      nicknameGameEntriesTotal: 'nicknames/nicknameGameEntriesTotal',
      hasLoadedNicknameGameEntries: 'nicknames/hasLoadedNicknameGameEntries',
      networkGameEntries: 'games/games',
      games: 'games/games',
      gameEntries: 'gameEntries/gameEntries',
      loadingGameEntries: 'gameEntries/loadingGameEntries',
      totalGameEntries: 'gameEntries/totalGameEntries',
      totalGames: 'games/totalGames',
      networkGameEntriesTotal: 'games/totalGames',
      networkGameEntriesLoading: 'games/loading',
      auditGames: 'audits/auditGames',
      auditGamesTotal: 'audits/auditGamesTotal',
      auditGamesLoading: 'audits/auditGamesLoading',
      heroDatePickerValue: 'UI/heroDatePickerValue',
      teamFilter: 'teams/teamFilter',
      themeDarkMode: 'UI/themeDarkMode',
      selectedAudit: 'audits/selectedAudit',
      uploadError: 'audits/uploadError',
      uploadHumanError: 'audits/uploadHumanError',
    }),


    computedHeaders() {
      let gamesHeaders = this.dataTable.games.headers;
      let defaultHeaders = this.dataTable.default.headers;
      let networkHeaders = this.dataTable.network.headers;
      let gameEntriesHeaders = this.dataTable.gameEntries.headers;

      // remove column "Jogador" when prop is true
      if (this.hideColumnPlayer) {
        gameEntriesHeaders = gameEntriesHeaders.filter(e => e.text != 'Jogador');
      }

      if (this.hideColumnLevel) {
        gameEntriesHeaders = gameEntriesHeaders.filter(e => e.text != 'Nível');
      }

      if (this.hideColumnData) {
        gameEntriesHeaders = gameEntriesHeaders.filter(e => e.text != 'Data');
      }
    
      if (this.hideColumnTournament) {
        gameEntriesHeaders = gameEntriesHeaders.filter(e => e.text != 'Torneio');
      }

      if (this.hideColumnFlag) {
        gameEntriesHeaders = gameEntriesHeaders.filter(e => e.text != 'Flags');
      }

      switch (this.type) {
        case 'games':
          gamesHeaders[0].width = this.games.length > 0 ? '5%' : '';
          return gamesHeaders;
        case 'gameEntries':
          gameEntriesHeaders[0].width = this.gameEntries.length > 0 ? '5%' : '';
          return gameEntriesHeaders;
        case 'nickname':
          return this.dataTable.default.headers;
        case 'network':
          networkHeaders[0].width = this.networkGameEntries.length > 0 ? '5%' : '';
          return networkHeaders;
        case 'audit':
          defaultHeaders[0].width = this.auditGames.length > 0 ? '5%' : '';
          return defaultHeaders;
        default:
          break;
      }
    },
  
    tableHeaders() {
      switch (this.type) {
        case 'nickname':
          return this.dataTable.default.headers;
        case 'games':
        case 'gameEntries':
        case 'network':
        case 'audit':
          return this.computedHeaders;
      }
    },

    tableData() {
      switch (this.type) {
        case 'gameEntries':
          return this.gameEntries;
        case 'games':
          return this.games;
        case 'nickname':
          return this.nicknameGameEntries;
        case 'network':
          return this.networkGameEntries;
        case 'audit':
          return this.auditGames;
      }
    },

    tableLoading() {
      switch (this.type) {
        case 'gameEntries':
          return this.loadingGameEntries;
        case 'games':
          return this.networkGameEntriesLoading;
        case 'nickname':
          return !this.hasLoadedNicknameGameEntries;
        case 'network':
          return this.networkGameEntriesLoading;
        case 'audit':
          return this.auditGamesLoading;
      }
    },

    tableTotal() {
      switch (this.type) {
        case 'gameEntries':
          return this.totalGameEntries;
        case 'games':
          return this.totalGames;
        case 'nickname':
          return this.nicknameGameEntriesTotal;
        case 'network':
          return this.networkGameEntriesTotal;
        case 'audit':
          return this.auditGamesTotal;
      }
    },

    optionsComputed: {
      get: function () {
        return this.options;
      },
      set: function (newValue) {
          if (!this._.isEqual(this.options, newValue)) {
              this.options = newValue;
          }
      }
    },
  },

  watch: {
    // watches this.options object
    // triggers everytime this.options has changed
    options: {
      handler() {
        if (!this.fetchLoading) {
          this.fetchData();
        }
      },
      deep: true,
    },
    
    heroDatePickerValue: function () {
      if (!this.fetchLoading) {
        this.fetchData();
      }
    },

    teamFilter: function () {
      if (!this.fetchLoading) {
        this.fetchData();
      }
    },
  },
  
  methods: {
    initializeState() {
      return {
        fetchLoading: false,
        tableLoadingSynthetic: false,
        dataTable: {
          default: {
            headers: [
              { text: 'Data', value: 'dateStart'},
              { text: 'Torneio', value: 'name', sortable: false},
              { text: 'Flags', value: 'game.flags', sortable: false, width: '80px'},
              { text: 'Buy-in', value: 'investment', sortable: true, width: '90px'},
              { text: 'Posição', value: 'position' },
              { text: 'Lucro', value: 'pnl', sortable: true },
              { text: 'Ação', value: 'action', sortable: false, width: '72px' }
            ],
          },
          gameEntries: {
            headers: [
              { text: 'Data', value: 'dateStart'},
              { text: 'Torneio', value: 'name', sortable: false},
              { text: 'Jogador', value: 'nickname.user', sortable: true},
              { text: 'Nickname', value: 'nickname.name', sortable: true},
              { text: 'Nível', value: 'group', sortable: false },
              { text: 'Flags', value: 'game.flags', sortable: false, width: '80px'},
              { text: 'Buy-in', value: 'investment', sortable: true, width: '90px'},
              { text: 'Posição', value: 'position' },
              { text: 'Prémio', value: 'prize', sortable: true },
              { text: 'Lucro', value: 'pnl', sortable: true },
            ],
          },
          games:{
            headers: [
              { text: 'Data', value: 'dateStart'},
              { text: 'Torneio', value: 'name', sortable: false},
              { text: 'Salas', value: 'networkSharkscope.name', sortable: true},
              { text: 'Flags', value: 'flags', sortable: false, width: '80px'},
              { text: 'Buy-in', value: 'buyin', sortable: true, width: '90px'},
              { text: 'Entradas', value: 'totalEntrants', sortable: false },
              { text: 'Ação', value: 'action', sortable: false, width: '72px'}
            ],
          },
          network: {
            headers: [
              { text: 'Data', value: 'dateStart'},
              { text: 'Torneio', value: 'name'},
              { text: 'Flags', value: 'flags', sortable: false, width: '80px'},
              { text: 'Buy-in', value: 'buyin', sortable: true, width: '88px'},
              { text: 'Entradas', value: 'totalEntrants', sortable: false },
              { text: 'Ação', value: 'action', sortable: false, width: '72px'},
            ],
          },
          search: null,
          footerProps: {
            'items-per-page-options': this.$itemsPerPageOptions,
        },
        },
        options: {
          page: 1,
          itemsPerPage: 10,
          sortBy: [],
          sortDesc: [],
          groupBy: [],
          groupDesc: [],
          mustSort: true,
          multiSort: false
        },
      };
    },

    /**
     * Helper function - API returns different objects 
     * @param {Object} item 
     * @returns {Object} 
     */
    getItemObject(item) {
      switch (this.type) {
        case 'nickname':
        case 'audit':
        case 'gameEntries':
          return item.game;
        case 'games':
        case 'network':
          return item;
      }
    },

    /**
     * @param {Object} item 
     * @returns {String} Tournament name
     */
    getTournamentName(item) {
      if (item) {
        let output = this.getItemObject(item);
        return output.name;
      };
    },

    getTitleForEmptyState() {
      if(this.useInDialogSession) return '';
      if(this.type == 'audit'){
        return 'Não foi possível extrair jogos do audit submetido'
      } else {
        return 'Não existem jogos registados'
      }
    },

    formatNumber(value, symbol) { return value.toFixed(0) + symbol; },

    /**
     * @param {Object} item 
     * @returns String Date
     */
    getDate(item, mode = null) {
      let output = this.getItemObject(item);
      if (item && !mode) {
        return this.$moment(output.dateFinish.date).format('DD/MM/YYYY');
      }else if(mode == 'all'){
        return this.$moment(output.dateFinish.date).format('DD/MM/YYYY hh:mm');
      }
    },
    
    /**
    * @param {Object} item 
    * @returns {String} flags
    */
    getFlags(item) {
      let output = this.getItemObject(item);
      if (output.flags == null || output.flags == '') return [null];
      let newOutput = output.flags.split(',').map(flag => flag.trim());
      if(this.type == 'games' || this.type == 'network'){
        newOutput.push(item.structure + item.game);
      }else{
        newOutput.push(item.game.structure + item.game.game);
      }
      if(newOutput.length > 2){
        newOutput = newOutput.slice(0, 2);
        newOutput.push("...");
      }
      return newOutput;
    },

  /**
    * @param {Object} item 
    * @returns {String} last flags
  */

    getRemainingFlags(item) {
      let output = this.getItemObject(item);
      if (output.flags == null || output.flags == '') return '';
      let newOutput = output.flags.split(',').map(flag => flag.trim());
      if(newOutput.length > 2){
        return newOutput.slice(2).join(', ');
      }
      return '';
    },

    /**
    * @param {Object} item 
    * @returns {Array} description and name flags
    */
    mapFlagToLabel(flag, mode=null) {
      const flagLabels = Vue.prototype.$metadata;
      if(flag == null || flag == '') return;
      if(flagLabels[flag] === undefined) return flag;
      if(!mode) {
        if (flagLabels[flag]['@description']) return flagLabels[flag]['@description'];
        if (flagLabels[flag]['@name']) return flagLabels[flag]['@name'];
      };
      if(mode == 'game')  return flagLabels[flag]['@name'];
      if(mode == 'id') return flagLabels[flag]['@id'];
    },

    /**
     * @param {Object} item 
     * @returns {Number} buyin value
     */
    getBuyIn(item) {
      if (item) {
        let output = this.getItemObject(item);
        return output.buyin.value;
      };
    },

    /**
     * @param {Object} item 
     * @returns {String} return large numbers formatted with suffix (ex: 350K)
     */
     formatNumberWithSuffix (num) {
      if (num < 1000) {
        return num.toString ();
      } else if (num >= 1000 && num < 1000000) {
        return (Math.round (num / 100) / 10).toString () + "K";
      } else {
        return (Math.round (num / 10000) / 100).toString () + "M";
      }
    },

     /**
     * @param {Object} item 
     * @returns {Number} buyin value
     */
     getGuarantee(item, mode = null) {
      if (item) {
        let output = this.getItemObject(item);
        if(output.guarantee == null) return null;
        if(!mode) return this.formatNumberWithSuffix(output.guarantee);
        if(mode == 'all') {
          let formatter = new Intl.NumberFormat(); 
          return formatter.format(output.guarantee); 
        };
      };
    },

    /**
     * @param {Object} item 
     * @returns {Number} rake value
     */
    getRake(item) {
      if (item) {
        let output = this.getItemObject(item);
        return output.rake.value;
      };
    },

    /**
     * @param {Object} item 
     * @returns {Number} stake value
     */
    getStake(item) {
      if (item) {
        let output = this.getItemObject(item);
        return output.stake.value;
      };
    },

    /**
     * @param {Object} item 
     * @returns {String} symbol value
     */
    getSymbol(item) {
      if (item) {
        let output = this.getItemObject(item);
        return output.currency.symbol;
      };
    },

    // trigered by v-text-field @input="search()"
    search() {
      setTimeout(() => {
          this.fetchData()
      }, 1000);
    },

    async fetchData() {

      this.fetchLoading = true;

      // Base payload
      let payload = {
        params: {
          page: this.options.page,
          itemsPerPage: this.options.itemsPerPage,
        }
      };

      // set call to store based on prop type
      let actionToDispatch = '';

      switch (this.type) {
        case 'gameEntries':
          actionToDispatch = 'gameEntries/getGameEntries';
          break;
        case 'games':
          actionToDispatch = 'games/get';
          break;
        case 'nickname':
          actionToDispatch = 'nicknames/getNicknameGameEntries';
          payload.id = this.$route.params.id;
          break;
        case 'audit':
          actionToDispatch = 'audits/getGamesFromSingleAudit';
          payload.id = this.$route.params.id;
          break;
        case 'network':
          actionToDispatch = 'games/get';
          payload.params.network = this.$route.params.id;
          break;
        default:
          break;
      }

      // Add name parameter to payload.params
      if (!this._.isEmpty(this.dataTable.search)) {
        switch(this.type) {
          case 'gameEntries':
              payload.params.gameName = this.dataTable.search;
              break;
          case 'games':
              payload.params.search = this.dataTable.search;
              break;
          default:
              payload.params.name = this.dataTable.search;
        }      
      }
      
      if(this.type == 'games' || this.type == 'gameEntries'){
        if(this.returnOwnData){
          if(this.$route.params.id){
            payload.params.userID = Number(this.$route.params.id);
          }
        }
        // Add userId to payload.params
        if(this.filters && this.filters.users){
          payload.params.userID = this.filters.users.id;
        }

        // Add nicknameID to payload.params
        if(this.filters && this.filters.nicknames){
          payload.params.nicknameID = this.filters.nicknames.id;
        }

        // Add groupID to payload.params
        if(this.filters && this.filters.groups){
          payload.params.groupID = this.filters.groups.id;
        }

        // Add networkSharkscope to payload.params
        if(this.filters && this.filters.networks){
          payload.params.networkSharkscope = this.filters.networks.id;
        }

        // if user is player and no userID is set, add userTrackerId to payload.params
        if(this.userIsPlayer && !payload.params.userID){
          payload.params.userID = this.userTrackerId;
        }
      }

      // if team exists and isManagement, Add team parameter to payload.params
      if (this.teamFilter && this.userHasRailAccessDivisionDropdown) {
        payload.params.team = this.teamFilter;
      }

      // Add sortDesc parameter to payload.params
      Object.assign(payload.params, this.sortDescHandler(this.options.sortDesc));

      // Add sortBy parameter to payload.params
      this.addParameterToPayload(payload, 'sortBy[]', this.options.sortBy);

      // add date values to payload params
      if (!this._.isEmpty(this.heroDatePickerValue) && !this.useInDialogSession) {
        payload.params.dateFinishBegin = this.heroDatePickerValue[0];
        payload.params.dateFinishEnd = this.heroDatePickerValue[1];
      } else {
        if(this.type !== 'gameEntries' && !this.singleGame && !this.useInDialogSession){
          payload.params.dateFinishBegin = this.$moment().subtract(1, 'month').format('YYYY-MM-DD');
          payload.params.dateFinishEnd = this.$moment().format('YYYY-MM-DD');
        }
      }

      if(this.useInDialogSession){
        if(this.filters && this.filters.date && this.filters.date.dateTimeFinishBegin && this.filters.date.dateTimeFinishEnd){
          payload.params.dateTimeFinishBegin = this.filters.date.dateTimeFinishBegin;
          payload.params.dateTimeFinishEnd = this.filters.date.dateTimeFinishEnd;
        }
      }
       // if singleGame is set, add id to payload.params to load entries of a single game
      if(this.type == 'gameEntries'){
        if(this.singleGame){
          payload.params.id = Number(this.singleGame);
        }
      }

      payload.params = Object.fromEntries(
        Object.entries(payload.params).filter(
            ([key, value]) => value !== null && value !== undefined
        )
      );

      // extract sortBy from payload.params and process it
      payload.params['sortBy[]'] = this.processSortBy(payload.params['sortBy[]']);
      // API Call
      await this.$store.dispatch(actionToDispatch, payload);
      this.fetchLoading = false;
    }, 

    processSortBy(sortByList) {
      if (!sortByList) return [];
      return sortByList.map(item => {
        if (item === 'nickname.user') return 'user';
        if (item.includes('networkSharkscope')) return 'network';
        if (item.includes('.')) return item.split('.')[0];
        return item;
      });
    },
    
    generateLink(item) {
      const itemId = this.type == 'games' || this.type == 'network' ? item.id : item.game.id;
      const queryParams = this.$route.query;

      const params = new URLSearchParams(queryParams);

      let link = `/games/${itemId}`;
      if (params.toString() !== '') {
        link += `?${params.toString()}`;
      }

      return link;
    },
  
    getMaxWidthColumnName() {
      if (this.$vuetify.breakpoint.lgOnly) {
        return '380px';
      } else if (this.$vuetify.breakpoint.lgAndUp) {
        if (this.type == 'games' || this.type == 'network' || this.type == 'audit') {
          return '100%';
        } else {
          return '380px';
        }
      } else if (this.$vuetify.breakpoint.md) {
        return '180px';
      } else if (this.$vuetify.breakpoint.sm) {
        return '140px';
      } else if (this.$vuetify.breakpoint.xs) {
        return '100px';
      }
    },

    imageCutout(item) {
      let placeholder = require('@/assets/images/players/player-example.png');
      return item && item.imgCutout ?  item.imgCutout : placeholder;
    },

    generateValidationNetworkGGPoker(item) {
      if (this.type == 'games' || this.type == 'network') {
        return item.networkSharkscopeID != Vue.prototype.$network_Sharkscope_GGPoker_id;
      } else {
        return item.game.networkSharkscopeID != Vue.prototype.$network_Sharkscope_GGPoker_id;
      }
    }
  },
};