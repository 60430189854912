import Hero from '@/components/Hero/Hero.vue';
import Snackbar from "@/components/Snackbar";
import DataMixin from '@/mixins/Data';
import AlertNoData from '@/components/AlertNoData';
import TableNicknames from '@/components/Tables/TableNicknames/TableNicknames.vue';
import DialogCreateNickname from '@/components/Dialog/DialogCreateNickname/DialogCreateNickname.vue';

export default {
    name: 'Nicknames-Admin',

    components: {
        Hero,
        AlertNoData,
        Snackbar,
        TableNicknames,
        DialogCreateNickname,
    },

    mixins: [
        DataMixin,
    ],

    data() {
        return this.initialState();
    },

    created() {
        this.fetchData();
    },
    
    methods: {
        initialState() {
            return {
                dialogues: {
                    create: false,
                },
                reload: false,
            }
        },

        dialogCreateNickname() {
            this.dialogues.create = true;
        },

        triggerTableNicknamesReload() {
            this.reload = false;
            setTimeout(() => {
                this.reload = true;
            }, 100);
        },

        fetchData() {
            // Pre load networks
            this.$store.dispatch('networks/get');
        },
    },
}