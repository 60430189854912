//import Vue from 'vue';
import DataMixin from '../../mixins/Data';
import { mapGetters } from "vuex";
//import moment from 'moment';

export default {
    name: 'DialogCandidateContact',

    props: {
        dialogOpen: { type: Boolean, required: true, default: false }
    },

    components: {
    },

    mixins: [DataMixin],

    computed: {
        ...mapGetters({
            selectedApplication: 'applications/selectedApplication'
        }),
    },

    data() {
        return {
            tabs: {
                current: 0,
                headers: [
                    'Contacto',
                    'Formulários'
                ]
            },
        }
    },

    created() {
        //this.initialize();
    },

    methods: {
        async initialize() {
        },

        close() {
            this.$emit('closeDialog');
        },

        /**
         * If an application is in "Fase 1", stage = 0.
         * current application stage = this.selectedApplication.stage_applications - 1
         */
        copyLink(stage) {
            let dataSnackbar = { message: 'Link copied to clipboard.', color: 'dark' };

            // Form application link
            let applicationForm =  this.$form_application[this.selectedApplication.code_applications_categories][stage];
            if (typeof applicationForm !== 'undefined' && applicationForm != '') {
                // Copy link to clipboard if it was well formed
                applicationForm += '?id=' + this.selectedApplication.id_applications
                                   + '&key=' + this.selectedApplication.access_key_applications;

                this.clipboard(applicationForm);
            } else {
                // Link is undefined, show error message
                dataSnackbar.message = 'Error copying link to clipboard.';
                dataSnackbar.color = 'error';
            }

            // Show snackbar
            this.$store.dispatch('UI/showSnackbar', {
                message: dataSnackbar.message,
                color: dataSnackbar.color
            });
        }
    }
}