import Vue from 'vue';
import DialogAddEditSession from '@/components/Rail/Dialog/DialogAddEditSession/DialogAddEditSession.vue';
import ButtonAction from '@/components/Rail/ButtonAction/ButtonAction.vue';
import AuthMixin from '@/mixins/Auth.vue';
import RailMixin from '@/mixins/Rail.vue';

export default {
    name: 'ButtonCreateOperation',

    components: {
        DialogAddEditSession,
        ButtonAction,
    },

    mixins: [
        AuthMixin,
        RailMixin,
    ],

    computed: {
        listItems() {
            if (this.userHasRole('rail_admin') || this.hasRailManagerOwnNicknames()) {
                const mergeItems = [...this.items, ...this.railAdminItems];
                return mergeItems;
            } else {
                return this.items; 
            }
        }
    },

    data() {
        return this.initializeState();
    },

    methods: {
        initializeState() {
            return {
                items: [
                    { title: 'Split', card: Vue.prototype.$rail_ops.operations.split  },
                    { title: 'Redução de Banca', card: Vue.prototype.$rail_ops.operations.bankroll_reduction },
                    { title: 'Incremento de Banca', card: Vue.prototype.$rail_ops.operations.any },
                    { title: 'Swap', card: Vue.prototype.$rail_ops.operations.swap },
                    { title: 'Alterar Makeup', card: Vue.prototype.$rail_ops.operations.makeup },
                  ],
                railAdminItems: [
                    { title: 'Depósito', card: Vue.prototype.$rail_ops.operations.deposit },
                    { title: 'Levantamento', card: Vue.prototype.$rail_ops.operations.withdraw },
                ]
            }
        },

        async setCurrentCard(cardName) {
            // set current card
            this.$store.commit('TabSlider/setCard', cardName);
            // force CardStepper to display 1 step
            this.$store.commit('UI/setCardStepperStep', 1);

            // clear states
            this.$store.commit('TabSlider/setHasFinished', false);
            this.$store.dispatch('railRequests/clearError');
            this.$store.commit('railRequests/setSelectedRequest', null);
            this.$store.commit('railTransfers/setSelected', null);
            this.$store.commit('users/setSelectedUser', null);

            // routing
            await this.$router.push({ path: Vue.prototype.$tab_slider.tabs.action }).catch(() => { });
            this.$store.commit('TabSlider/setSelectedTab', Vue.prototype.$tab_slider.tabs.action);
        },
    }
}
