var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.annotations != '')?_c('v-alert',{attrs:{"icon":"mdi-information","color":"blue","border":"left","colored-border":"","prominent":"","elevation":"2"}},[_vm._v(" "+_vm._s(_vm.annotations)+" ")]):_vm._e(),_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Game type")]),_c('th',{staticClass:"text-left"},[_vm._v("Maximum buy-in")])])]),_c('tbody',[(!isNaN(_vm.regular))?_c('tr',[_c('td',[_vm._v("Regular")]),_c('td',[_vm._v(_vm._s(_vm.regular))])]):_vm._e(),(!isNaN(_vm.regular_pko))?_c('tr',[_c('td',[_vm._v("Regular PKO")]),_c('td',[_vm._v(_vm._s(_vm.regular_pko))])]):_vm._e(),(!isNaN(_vm.turbo))?_c('tr',[_c('td',[_vm._v("Turbo")]),_c('td',[_vm._v(_vm._s(_vm.turbo))])]):_vm._e(),(!isNaN(_vm.turbo_pko))?_c('tr',[_c('td',[_vm._v("Turbo PKO")]),_c('td',[_vm._v(_vm._s(_vm.turbo_pko))])]):_vm._e(),(!isNaN(_vm.hyper))?_c('tr',[_c('td',[_vm._v("Hyper Turbo")]),_c('td',[_vm._v(_vm._s(_vm.hyper))])]):_vm._e(),(!isNaN(_vm.sng))?_c('tr',[_c('td',[_vm._v("Sit & Go")]),_c('td',[_vm._v(_vm._s(_vm.sng))])]):_vm._e(),(!isNaN(_vm.bubble_rush))?_c('tr',[_c('td',[_vm._v("Bubble Rush")]),_c('td',[_vm._v(_vm._s(_vm.bubble_rush))])]):_vm._e(),(!isNaN(_vm.splash))?_c('tr',[_c('td',[_vm._v("Splash")]),_c('td',[_vm._v(_vm._s(_vm.splash))])]):_vm._e(),(!isNaN(_vm.fast))?_c('tr',[_c('td',[_vm._v("Fast (PartyPoker)")]),_c('td',[_vm._v(_vm._s(_vm.fast))])]):_vm._e(),(!isNaN(_vm.early_bird))?_c('tr',[_c('td',[_vm._v("Early Bird (Microgaming)")]),_c('td',[_vm._v(_vm._s(_vm.early_bird))])]):_vm._e(),(!isNaN(_vm.blue))?_c('tr',[_c('td',[_vm._v("Blue (GGPoker)")]),_c('td',[_vm._v(_vm._s(_vm.blue))])]):_vm._e(),(!isNaN(_vm.red))?_c('tr',[_c('td',[_vm._v("Red (GGPoker)")]),_c('td',[_vm._v(_vm._s(_vm.red))])]):_vm._e(),(
						typeof _vm.exception_specials_array !== 'undefined' &&
						_vm.exception_specials_array != ''
					)?_c('tr',[_c('td',[_vm._v("Special Tournaments")]),_c('td',[_c('ul',_vm._l((_vm.exception_specials_array),function(item,i){return _c('li',{key:i},[_vm._v(" "+_vm._s(item)+" ")])}),0)])]):_vm._e(),(
						typeof _vm.exception_weekend_array !== 'undefined' &&
						_vm.exception_weekend_array != ''
					)?_c('tr',[_c('td',[_vm._v("Friday / Saturday")]),_c('td',[_c('ul',_vm._l((_vm.exception_weekend_array),function(item,i){return _c('li',{key:i},[_vm._v(" "+_vm._s(item)+" ")])}),0)])]):_vm._e(),(
						typeof _vm.exception_sunday_array !== 'undefined' &&
						_vm.exception_sunday_array != ''
					)?_c('tr',[_c('td',[_vm._v("Sunday")]),_c('td',[_c('ul',_vm._l((_vm.exception_sunday_array),function(item,i){return _c('li',{key:i},[_vm._v(" "+_vm._s(item)+" ")])}),0)])]):_vm._e()])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }