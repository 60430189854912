import { mapGetters } from 'vuex';

export default {
    name: 'SwapGroupsDropdown',

    props: {
        value: {
            type: [Object,Number,String],
            required: false,
            default: () => {}
        },
        rules: {
            type: Array,
            required: false,
            default: () => []
        },
        label: {
            type: String,
            required: false,
            default: 'Swap Groups'
        },
        dense: {
            type: Boolean,
            required: false,
            default: false
        },
        outlined: {
            type: Boolean,
            required: false,
            default: false
        },
        clearable: {
            type: Boolean,
            required: false,
            default: true
        },
        hideDetails: {
            type: Boolean,
            required: false,
            default: true
        },
        solo: {
            type: Boolean,
            required: false,
            default: true
        },
    },

    data() {
        return this.initialState();
    },

    created() {
        this.initialize();
        this.emitChange();
    },

    mounted() {
        // Clear error triggered naturally when element is loaded
        this.$refs.formSwapGroupsDropdown.resetValidation();
    },
    
    computed: {
        ...mapGetters({
            swapGroupsDropdown: 'railSwaps/swapGroupsDropdown',
            swapGroupsDropdownLoading: 'railSwaps/swapGroupsDropdownLoading',
        }),

        // Synthetic v-model
        computedValue: {
            get: function () {
                return this.group;
            },
            set: function (newValue) {
                this.group = newValue;
                this.emitChange();
            }
        },

        // return prop rules
        computedRules: function () {
            return this.rules;
        },
    },

    watch: {
        value: function(newValue) {
            this.computedValue = newValue;
        },
    },

    methods: {
        initialState() {
            return {
                group: null,
            }
        },

        initialize() {
            // Initial value passed through prop
            this.computedValue = this.value;
            this.fetchData();
        },

        async fetchData() {
            // get swap groups list
            if (this._.isEmpty(this.swapGroupsDropdown)) {
                let payload = {
                    params: {
                        page: 1,
                        itemsPerPage: -1
                    }
                }
                await this.$store.dispatch('railSwaps/getSwapGroupsDropdown', payload);
            }
        },

        reset() {
            Object.assign(this.$data, this.initialState());
            this.$refs.formSwapGroupsDropdown.reset();
        },

        emitChange() {
            this.$emit('input', this.computedValue);
            this.$emit('change', this.computedValue);
        },
    }
}