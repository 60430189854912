import Vue from 'vue';
import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth.vue';
import DataMixin from '@/mixins/Data.vue';
import UserMixin from '@/mixins/User.vue';
import RailMixin from '@/mixins/Rail.vue';
import EmptyStateNoData from '@/components/EmptyStateNoData';
import DialogCreateNickname from '@/components/Dialog/DialogCreateNickname/DialogCreateNickname.vue';
import DialogViewSession from '@/components/Rail/DialogViewSession/DialogViewSession.vue';
import DialogViewSessionSpins from '@/components/Rail/Dialog/DialogViewSessionSpins/DialogViewSessionSpins.vue';
import ButtonCopyToClipboard from '@/components/Rail/ButtonCopyToClipboard/ButtonCopyToClipboard.vue';
import NicknameCard from '@/components/NicknameCard/NicknameCard.vue';

export default {
    name: 'TableBalance',

    components: {
        EmptyStateNoData,
        DialogCreateNickname,
        DialogViewSession,
        DialogViewSessionSpins,
        ButtonCopyToClipboard,
        NicknameCard,
    },

    mixins: [
        AuthMixin,
        DataMixin,
        UserMixin,
        RailMixin,
    ],

    props: {
        title: {
            type: String,
            required: false,
            default: 'Balanços',
        },
        showButtons: {
            type: Boolean,
            required: false,
            default: false,
        },
        showTabs: {
            type: Boolean,
            required: false,
            default: false
        },
        singlePageView: {
            type: Boolean,
            required: false,
            default: false
        },
        user: {
            type: Object,
            required: false,
            default: () => { }
        },
        maxHeight: {
            type: String,
            required: false,
            default: null
        },
        minHeight: {
            type: String,
            required: false,
            default: null
        },
        adminStats: {
            type: Boolean,
            required: false,
            default: true
        },
        showTitle: {
            type: Boolean,
            required: false,
            default: true
        },
        vSheetClass: {
            type: Boolean,
            required: false,
            default: true
        },
        showTableTopInfo: {
            type: Boolean,
            required: false,
            default: true
        },
        showBalances: {
            type: Boolean,
            required: false,
            default: true
        }
    },

    computed: {
        ...mapGetters({
            hasLoadedMine: 'nicknames/hasLoadedNicknamesAllMine',
            hasLoadedNicknamesByUserID: 'nicknames/hasLoadedNicknamesByUserID',
            selectedTab: 'TabSlider/selectedTab',
            selectedBalance: 'railOverview/selectedBalance',
            selectedTargetBalance: 'railOverview/selectedTargetBalance',
            cardBalance: 'railOverview/cardBalance',
            card: 'TabSlider/card',
            nicknamesAllMine: 'nicknames/nicknamesAllMine',
            nicknamesByUserID: 'nicknames/nicknamesByUserID',
            statisticsByUserId: 'railStatistics/dataByUserId',
            statisticsAdmin: 'railStatistics/dataAdmin',
            statisticsUser: 'railStatistics/data',
            statisticsLoadingUserById: 'railStatistics/loadingDataByUserId',
            statisticsLoadingAdmin: 'railStatistics/loadingDataAdmin',
            statisticsLoading: 'railStatistics/loading',
            heroDatePickerValue: 'UI/heroDatePickerValue',
            selectedUser: 'users/selectedUser',
            selectedRequest: "railRequests/selectedRequest",
            themeDarkMode: 'UI/themeDarkMode',
            userDisplayName: 'auth/userDisplayName',
            userGroup: 'auth/userGroup',
            userTeams: 'users/userTeams',
            userTeamsLoading: 'users/userTeamsLoading',
            lastSession: 'railSessions/lastSession',
            userTrackerId: 'auth/userTrackerId',
            selectedUserProfitShare: 'users/selectedUserProfitShare',
            cardBalanceProfitEUR: 'railOverview/cardBalanceProfitEUR',
            cardBalanceProfitUSD: 'railOverview/cardBalanceProfitUSD',
            currencies: 'currencies/currencies',
            userRoles: 'auth/userRoles',
            nicknamesByUserTrackerID: 'nicknames/nicknamesByUserTrackerID',
            teamFilter: 'teams/teamFilter',
            authUserProfitShare: 'auth/userProfitShare',
        }),

        heightComputed: function () {
            if (this.maxHeight) {
                return this.maxHeight;
            }

            return this.tabs.current == 0 ? '603px' : '522px';
        },

        isUserManagement: function () {
            let output = false;
            if (this.user && this.user.roles) {
                output = this.userHasRole(['rail_admin', 'rail_manager'], this.user.roles);
            }
            return output;
        },

        /**
         * @returns {Array} with Objects that populate Table Headers
         */
        tableHeaders: function () {
            // base Headers
            let output = [
                this.dataTable.headers.selected,
                this.dataTable.headers.network,
            ];

            // add balance column
            output.push(this.dataTable.headers.balance);

            if (this.showButtons && (this.userIsRailAdmin || this.hasRailManagerOwnNicknames())) {
                output.push(this.dataTable.headers.buttons);
            }
            return output;
        },

        /**
         * @returns {Array} with Objects (can be empty too) that populate tabs
         */
        tableTabs: {
            get: function () {
                return this.userIsRailManager && this.showTabs
                    ? this.tabs.items
                    : [];
            },
            set: function (newVal) {
                if (this.userIsRailManager && this.showTabs) {
                    this.tabs.items = [
                        { name: 'Gestão', disabled: false },
                        { name: 'Jogador', disabled: newVal }
                    ]
                }
            }
        },

        /**
         * @returns {String} with user display name
         */
        tableUsernameTitle: function () {
            let name;
            let userDisplayName;

            if (this.userIsRailManager) {
                userDisplayName = this.user
                    ? this.user.displayName
                    : 'Por definir';

            } else {
                userDisplayName = this.userDisplayName;
            }

            switch (this.tabs.current) {
                case 0:
                    name = this.userIsRailManager
                        ? 'Gestão'
                        : userDisplayName;
                    break;
                case 1:
                    name = !this.isUserManagement
                        ? userDisplayName
                        : ''
                    break;
                default:
                    break;
            }

            return name;
        },

        tableUserGroup: function () {
            let output;
            let userLevel;

            if (this.userIsRailManager) {
                userLevel = this.user && this.user.group
                    ? this.user.group.name
                    : '';
            } else {
                userLevel = this.userGroup ? this.userGroup.name : '';
            }

            switch (this.tabs.current) {
                case 0:
                    output = this.userIsRailManager
                        ? ''
                        : userLevel;
                    break;
                case 1:
                    output = !this.isUserManagement
                        ? userLevel
                        : ''
                    break;
                default:
                    break;
            }

            return output;
        },

        /**
         * @returns {Array} based on current tab
         */
        tableData: function () {
            switch (this.tabs.current) {
                case 0:
                    return this.nicknamesAllMine;
                case 1:
                    return this.nicknamesByUserID;
                default:
                    break;
            }
        },

        /**
         * @returns {Boolean} loading value based on current tab
         */
        hasLoadedTableData: function () {
            switch (this.tabs.current) {
                case 0:
                    return this.hasLoadedMine;
                case 1:
                    return this.hasLoadedNicknamesByUserID;
                default:
                    break;
            }
        },

        /**
         * Message to show when Table has no data
         * @returns {String} or sets current tab to 0
         */
        emptyStateTableData: function () {
            switch (this.tabs.current) {
                case 0:
                    return 'Clica no botão abaixo para registar os teus nicknames e carteiras';
                case 1:
                    return this._.isEmpty(this.nicknamesByUserID) && this.user
                        ? 'O jogador selecionado não tem nicknames registados'
                        : this.tabs.current = 0;
                default:
                    break;
            }
        },

        balanceTotal: function () {
            let totalAdmin = this.statisticsAdmin.BANKROLL_MANAGEMENT ? this.statisticsAdmin.BANKROLL_MANAGEMENT.value : 0;
            let totalUser = this.statisticsUser.BANKROLL_USER ? this.statisticsUser.BANKROLL_USER.value : 0;
            let totalUserById = this.statisticsByUserId.BANKROLL_USER ? this.statisticsByUserId.BANKROLL_USER.value : 0;

            switch (this.tabs.current) {
                case 0:
                    return this.userIsRailManager
                        ? totalAdmin
                        : totalUser;
                case 1:
                    return totalUserById;
                default:
                    break;
            }
        },

        balanceInitial: function () {
            let totalUser = this.statisticsUser.BANKROLL_INITIAL_USER ? this.statisticsUser.BANKROLL_INITIAL_USER.value : 0;
            let totalUserById = this.statisticsByUserId.BANKROLL_INITIAL_USER ? this.statisticsByUserId.BANKROLL_INITIAL_USER.value : 0;

            switch (this.tabs.current) {
                case 0:
                    return totalUser;
                case 1:
                    return totalUserById;
                default:
                    break;
            }
        },

        balanceUserPnl: function () {
            let output = {
                USD: 0,
                EUR: 0,
            }

            let pnl = this.userIsRailManager
                ? this.statisticsByUserId
                : this.statisticsUser


            output.USD = pnl.PROFIT ? pnl.PROFIT.value.USD.value : 0;
            output.EUR = pnl.PROFIT ? pnl.PROFIT.value.EUR.value : 0;

            if (this.cardBalanceProfitUSD) {
                output.USD = output.USD - this.cardBalanceProfitUSD;
                // convert usd value to eur value
                output.EUR = output.EUR - (this.cardBalanceProfitUSD * this.currencies.USDEUR.rate).toFixed(2);
            }

            if (this.cardBalanceProfitEUR) {
                output.EUR = output.EUR - this.cardBalanceProfitEUR;
                // convert eur value to usd value
                output.USD = output.USD - (this.cardBalanceProfitEUR * this.currencies.EURUSD.rate).toFixed(2);
            }
            return output;
        },

        cardColorBackground: function () {
            if (this.themeDarkMode) {
                return 'theme--dark';
            } else {
                return 'theme--light';
            }
        },

        cardSheetClass: function () {
            if (this.vSheetClass) {
                return 'v-sheet';
            }
        },

        isCashPlayer: function () {
            let output = false;

            if (this.userTeams) {
                output = this._.find(this.userTeams, { id: Vue.prototype.$team_division_cash_id });
            }
            return output;
        },

        isSpinsPlayer: function () {
            let output = false;

            if (this.userTeams) {
                output = this._.find(this.userTeams, { id: Vue.prototype.$team_division_spins_id });
            }
            return output;
        },

        userProfitShare: function () {
            let output;
            let userShareAdminView = this.user && this.user.profitShare ? this.user.profitShare : this.selectedUserProfitShare;
            let userSharePlayerView = this.authUserProfitShare;
            let share = this.userIsRailManager ? userShareAdminView : userSharePlayerView;

            if (share) {
                output = this._.round(this.convertDecimalToPercentage(share), 2).toFixed(2) + ' %';
            }

            return output;
        },
    },

    watch: {
        heroDatePickerValue: function () {
            if (!this.loadingOwnStatistics) {
                this.fetchStatistics();
            }
            if (this.userIsRailManager) {
                this.fetchStatisticsByUserId();
            }
        },

        // used when showing tabs 
        showTabs: {
            handler() {
                
                this.tableTabs = this.showTabs;

                // if it's an admin and is viewing  single page
                if (this.userIsRailManager && this.singlePageView) {
                    return this.tabs.current = 1;
                }

                // if is showing tabs and then changes for a view without tabs, set current tab to 0.
                if (!this.showTabs || (this.user && !this.user.id)) {
                    return this.tabs.current = 0;
                }
            },
            immediate: true
        },

        user: function () {
            if (!this.loadingFetchPlayerNicknames) {
                this.fetchPlayerNicknames();
            }

            if (!this.loadingFetchUserTeams) {
                this.fetchUserTeams();
            }

            let userExists = !this._.isEmpty(this.user) && !this._.isUndefined(this.user) && !this._.isNull(this.user);

            if (this.userIsRailManager && userExists) {
                this.fetchStatisticsByUserId();
            }

            if (userExists && !this.isUserManagement) {
                this.fetchLastDateSession(this.user ? this.user.id : null);
            }
            // enables/disables tab "Jogador"
            this.tableTabs = userExists && !this.isUserManagement ? false : true;

        },
    },

    data() {
        return this.initialState();
    },

    async created() {
        await this.fetchData();
    },

    methods: {
        initialState() {
            return {
                showBalanceInitial: false,
                loadingFetchPlayerNicknames: false,
                loadingFetchUserTeams: false,
                loadingOwnStatistics: false,
                arrowColor: null,
                futureBalance: null,
                dialogues: {
                    createNickname: false,
                    viewSession: false,
                    viewSessionSpins: false,
                },
                dataTable: {
                    headers: {
                        selected: { text: '', value: 'selected', sortable: false },
                        network: { text: 'Sala/Carteira', value: 'network.name' },
                        balance: { text: 'Balanço Atual', value: 'balance.EUR.value' },
                        buttons: { text: 'Ação', value: 'action', sortable: false },
                    },
                    search: null,
                    page: 1,
                    itemsPerPage: -1,
                    options: {},
                    data: [],
                },
                tabs: {
                    current: 0,
                    items: [
                        { name: 'Gestão', disabled: false },
                        { name: 'Jogador', disabled: true }
                    ],
                },
            }
        },

        setActiveItem(item) {
            if (item && this.card != Vue.prototype.$rail_ops.operations.transfer_to_self) {
                this.$store.commit(
                    'railOverview/setSelectedBalance',
                    item
                );
            }
        },

        /**
         *  Returns a balance preview to the selectedBalance based on cardBalance.
         */
        balanceUpAndDown(item) {
            let selectBalanceId = this.selectedBalance ? this.selectedBalance.id : '';
            let selectTargetBalanceId = this.selectedTargetBalance ? this.selectedTargetBalance.id : '';
            let itemId = item ? item.id : '';
            let isSelected = selectBalanceId == itemId || selectTargetBalanceId == itemId;

            if (isSelected) {
                switch (this.card) {
                    case Vue.prototype.$rail_ops.operations.deposit:
                        if (this.cardBalance != null) {
                            return {
                                futureBalance: this.cardBalance + item.balance.value,
                                arrowColor: 'success',
                                arrowIcon: 'mdi-arrow-up'
                            }
                        } else {
                            return null;
                        }
                    case Vue.prototype.$rail_ops.operations.withdraw:
                        if (this.cardBalance != null) {
                            return {
                                futureBalance: this.cardBalance - item.balance.value,
                                arrowColor: 'error',
                                arrowIcon: 'mdi-arrow-down'
                            };
                        } else {
                            return null;
                        }
                    case Vue.prototype.$rail_ops.operations.transfer_to_self:
                        if (this.cardBalance != null) {
                            if (selectBalanceId == itemId) {
                                return {
                                    futureBalance: this.cardBalance - item.balance.value,
                                    arrowColor: 'error',
                                    arrowIcon: 'mdi-arrow-down'
                                };
                            } else if (selectTargetBalanceId == itemId) {
                                return {
                                    futureBalance: this.cardBalance + item.balance.value,
                                    arrowColor: 'success',
                                    arrowIcon: 'mdi-arrow-up'
                                }
                            } else {
                                return null;
                            }
                        } else {
                            return null;
                        }
                    default:
                        break;
                }
            }
        },

        /**
         * set pnl color
         * @param {Number} item 
         * @returns {String}
         */
        userPnlColor(item) {
            if (item == 0) {
                return this.valueColorFinal;
            } else if (item > 0) {
                return '#4CAF50';
            } else {
                return '#FF5252';
            }
        },

        /**
         * Route to action tab
         * @param {Integer} cardName 
         * @param {Object} item
         * @returns {type} set of mutations variables
         */
        setCurrentCard(cardName, item) {
            // set item
            this.setActiveItem(item);
            // set card
            this.$store.commit('TabSlider/setCard', cardName);
            // routing
            this.$store.commit('TabSlider/setSelectedTab', Vue.prototype.$tab_slider.tabs.action);
            this.$router.push({ path: Vue.prototype.$tab_slider.tabs.action });

            // clear states
            this.$store.commit('TabSlider/setHasFinished', false);
            this.$store.commit('railRequests/setSelectedRequest', null);
            this.$store.commit('railTransfers/setSelected', null);
            this.$store.commit('railOverview/setCardBalanceProfitUSD', null);
            this.$store.commit('railOverview/setCardBalanceProfitEUR', null);
            this.$store.dispatch('railRequests/clearError');
            this.$store.commit('railExpenses/setIsExpense', false);
        },

        /**
         * num of hands/tournaments played since the start of the player's contract
         * @returns {String,Number,null}
         */
        eventsPlayedDuringContract() {
            if (this.userIsRailManager) {
                return this.statisticsByUserId.EVENTS_CONTRACT_COUNT
                    ? this.statisticsByUserId.EVENTS_CONTRACT_COUNT.value
                    : null;
            } else {
                return this.statisticsUser.EVENTS_CONTRACT_COUNT
                    ? this.statisticsUser.EVENTS_CONTRACT_COUNT.value
                    : null;
            }
        },

        /**
         * num of hands/tournaments played between an interval data
         * @returns {String,Number,null}
         */
        eventsPlayedDuringDaterange() {
            if (this.userIsRailManager) {
                return this.statisticsByUserId.EVENTS_COUNT
                    ? this.statisticsByUserId.EVENTS_COUNT.value
                    : null;
            } else {
                return this.statisticsUser.EVENTS_COUNT
                    ? this.statisticsUser.EVENTS_COUNT.value
                    : null;
            }
        },

        /**
         * @param {Number|String} value 
         * @returns {String} with width value
         */
        getCurrencyInputTableBalanceWidth(value) {
            let output = '50px';
            if (value) {
                output = parseFloat(value).toFixed(3);

                // turn output into String
                output = String(output);

                output = (output.length + 1) * 8 + 'px';
            }
            return output;
        },

        async openDialogViewSession() {
            await this.$store.commit('railSessions/setSelected', this.lastSession);

            if (this.lastSession) {
                this.handleDialogViewSession();
            }
        },

        handleDialogViewSession() {
            if (this.lastSession) {
                // map userTeams by name and verifies if user belongs to Spins Division
                let userTeams = this.lastSession.user
                    ? this.lastSession.user.teams.map(e => e.name)
                    : [];

                let isSpinDivision = userTeams.includes("Spins Division");

                // if user belongs to Spins Division, open Dialog Spins
                return this.toggleDialog(isSpinDivision ? 'viewSessionSpins' : 'viewSession');
            }
        },

        toggleDialog(dialog) {
            this.dialogues[dialog] = !this.dialogues[dialog];
        },

        /**
         *  used by fetchStatistics() and fetchStatisticsByUserId()
         * @returns {Object}
         */
        formulatePayloadStatistics() {
            let payload = {
                params: {}
            }

            // if team exists and isManagement, Add team parameter to payload.params
            if (this.teamFilter && this.userHasRailAccessDivisionDropdown) {
                payload.params.team = this.teamFilter;
            }

            if (!this._.isEmpty(this.heroDatePickerValue)) {
                payload.params.dateBegin = this.heroDatePickerValue[0];
                payload.params.dateEnd = this.heroDatePickerValue[1];
            }
            return payload;
        },

        /**
         * fetch nicknames (own, management and by user ID)
         * fetch statistics (own, management and by user ID)
         * fetch user teams
         * fetch last date session
         */
        async fetchData() {
            // fetch own nicknames
            if (this._.isEmpty(this.nicknamesAllMine)) {
                await this.$store.dispatch('nicknames/getAllMine');
            }

            if (!this.loadingOwnStatistics) {
                this.fetchStatistics();
            };

            if (this.userIsPlayer) {
                if (this.selectedUser && (this.selectedUser.id != this.userTrackerId)) {
                    // get user info and profitShare
                    this.$store.dispatch('users/getUser', this.userTrackerId);
                }
                // get user teams
                if (this._.isEmpty(this.userTeams)) {
                    this.fetchUserTeams();
                }
            }

            // fetch Player Nicknames if user id exists
            if (!this.loadingFetchPlayerNicknames) {
                this.fetchPlayerNicknames();
            }

            let userExists = !this._.isEmpty(this.user) && !this._.isUndefined(this.user) && !this._.isNull(this.user);

            if (this.userIsRailManager && userExists) {
                this.fetchStatisticsByUserId();
            }

            if (userExists && !this.isUserManagement) {
                this.fetchLastDateSession(this.user ? this.user.id : null);
            }

            // enables/disables tab "Jogador"
            this.tableTabs = userExists && !this.isUserManagement ? false : true;
        },

        /**
         * Retrieve nicknames by user id
         */
        async fetchPlayerNicknames() {

            this.loadingFetchPlayerNicknames = true;

            // if admin, get nicknames from user id
            if (this.userIsRailManager) {
                // if user id exists and doesn't belong to management OR is in SinglePageView
                if (this.user && this.user.id && (!this.isUserManagement || this.singlePageView)) {
                    setTimeout(() => {
                        // changes tab to display player nicknames
                        this.tabs.current = 1;

                        let payload = {
                            user: this.user.id,
                            aliasParameter: '&aliasChildOf=null'
                        }

                        // retrieve nicknames 
                        this.$store.dispatch('nicknames/getAllPlayer', payload);
                    }, 500);
                } else {
                    this.tabs.current = 0;
                }
            } else {
                if (this._.isUndefined(this.user) || this._.isNull(this.user)) {
                    this.$store.commit('nicknames/setHasLoadedNicknamesByUserID', true);
                    this.$store.commit('nicknames/setNicknamesByUserID', []);
                }
            }

            this.loadingFetchPlayerNicknames = false;
        },

        /**
         * Retrieve own stats
         */
        async fetchStatistics() {
            this.loadingOwnStatistics = true;
            let payload = this.formulatePayloadStatistics();

            if (!this._.isEmpty(payload.params.dateBegin) && !this._.isEmpty(payload.params.dateEnd)) {
                if (this.userIsRailManager && this.adminStats) {

                    if (this.statisticsLoadingAdmin) {
                        return false;
                    }

                    // get admin stats
                    await this.$store.dispatch('railStatistics/getAdmin', payload.params);
                } else if (!this.userIsRailManager) {
                    await this.$store.dispatch('railStatistics/get', payload.params);
                }
            }

            this.loadingOwnStatistics = false;
        },

        /**
         * Retrieve stats by user id
         */
        fetchStatisticsByUserId() {
            let id = this.user ? this.user.id : null;
            let payload = this.formulatePayloadStatistics();

            // get user by id stats
            if (id && !this.isUserManagement) {
                payload.params.user = String(id);
                this.$store.dispatch('railStatistics/getByUserId', payload);
            }
        },

        /**
         * fetch user teams by id
         */
        async fetchUserTeams() {

            if (this.userTeamsLoading) {
                return false;
            }

            this.loadingFetchUserTeams = true;

            let id = this.user ? this.user.id : null;

            // is user logged in is player, uses userTrackerId
            if (this.userIsPlayer) {
                id = this.userTrackerId;
            }
            // if has id makes API Call to retrieve user teams
            if (id) {
                await this.$store.dispatch('users/getUserTeams', id);
            }

            this.loadingFetchUserTeams = false;
        },

        /**
         * Api Call to retrieve last session object
         * @param {Integer|String} id  
         */
        fetchLastDateSession(id) {
            if (this.userIsRailManager && id) {
                this.$store.dispatch('railSessions/getLastSessionByUserId', id);
            }
        },

        /**
         * Only runs if user is rail manager || rail admin
         * @param {Integer|String} id 
         * returns {Boolean} value
         */
        handleDisableButton(id) {
            let nicknamesByUserTrackerIDMap = !this._.isEmpty(this.nicknamesByUserTrackerID) 
                ? this.nicknamesByUserTrackerID.map(e => e.id)
                : [];
            
            // only runs if user is manager
            if (this.userHasRole('rail_manager')) {
                // if includes it means that userRailManager has own nicknames and can make a deposit/withdraw
                return !nicknamesByUserTrackerIDMap.includes(id);
            }

            return false;
        },
        
    }
}