import EmptyStateNoData from '@/components/EmptyStateNoData';
import { VChip } from 'vuetify/lib';

export default{
    name: 'CalendarEvent',
    props: {
		events: {
			type: Array,
			required: true,
			default: [],
		},
        loading:{
            type: Boolean,
            required: false,
            default: false,
        }
	},

    components:{
        EmptyStateNoData,
        VChip
    },

    data(){
        return this.initialize();
    },
    
    watch:{
        events(){
            this.formatEvents(this.$props.events)
        },
        hasEvents(){
            this.formatEvents(this.$props.events)
        }
    },

    methods:{
        initialize(){
            return{
                eventsFormated: [],
                hasEvents: false,
            }
        },

        formatEvents(events){
            if(events.length>0){
                this.hasEvents = true
                this.eventsFormated = events.map(({ title, timeRange, formattedStartDate, id }) => ({ title, timeRange, formattedStartDate, id }));
                
            }else{
                this.hasEvents = false
            }
        }

    },

    created(){
        this.formatEvents(this.$props.events)
    }
    
}