
import Vue from 'vue';
import { mapGetters } from 'vuex';
import TableTransfers from '@/components/Rail/TableTransfers/TableTransfers.vue'
import CardStatistics from '@/components/Rail/Statistics/CardStatistics/CardStatistics.vue'
import TransferTypeDropdown from '@/components/Rail/TransferTypeDropdown/TransferTypeDropdown.vue';
import OperationDropdown from '@/components/Rail/OperationDropdown/OperationDropdown.vue';
import DatePickerRange from '@/components/DatePickerRange/DatePickerRange.vue';

export default {
    name: 'Transfers',
    components: {
        TableTransfers,
        CardStatistics,
        TransferTypeDropdown,
        DatePickerRange,
        OperationDropdown,
    },
    data() {
       return this.initialState()
    },

    computed: {
        ...mapGetters({
            heroDatePickerValue: 'UI/heroDatePickerValue',
        }),

        excludedOperations: function () {
            return [
                Vue.prototype.$rail_ops.operations.swap,
                Vue.prototype.$rail_ops.operations.deposit,
                Vue.prototype.$rail_ops.operations.withdraw,
            ];
        },

        computedCalendarDates: {
			get() {
				return this.date;
			},
			set(newValue) {
                this.date = newValue;
				this.$store.commit('UI/setHeroDatePickerValue', newValue);
			}
        },

        // computed property passed to TableTransfers
        formFilters: {
            get() {
                return this.form;
            },
            set(newVal) {
                this.form = newVal;
            }
        },
    },

    methods: {
        initialState() {
            return {
                date: [],
                form: {
                    user: null,
                    operation: [],
                    transferType: [],
                }
            };
        },
    }
}