import { mapGetters } from 'vuex';
import DialogConfirmation from '@/components/Rail/Dialog/DialogConfirmation/DialogConfirmation.vue';
import DialogAddEditTeam from '@/components/Dialog/DialogAddEditTeam/DialogAddEditTeam.vue';
import AuthMixin from '@/mixins/Auth.vue';
import RailMixin from '@/mixins/Rail.vue';
import DataMixin from '@/mixins/Data.vue';
import EmptyStateNoData from '@/components/EmptyStateNoData';

export default {
    name: 'TableTeams',
    
    components: {
      DialogConfirmation,
      EmptyStateNoData,
      DialogAddEditTeam,
    }, 

    data() {
      return this.initialState();
    },

    mixins: [
      AuthMixin,
      RailMixin,
      DataMixin,
    ],

    computed: {
      ...mapGetters({
        teams: 'teams/teams',
        teamsTotal: 'teams/teamsTotal',
        selectedTeam: 'teams/selectedTeam',
        loading: 'teams/loading',
        error: 'teams/error',
        humanError: 'teams/humanError'
      }),

      optionsComputed: {
        get: function () {
          return this.options;
        },
        set: function (newValue) {
            if (!this._.isEqual(this.options, newValue)) {
                this.options = newValue;
            }
        }
      }
    },

    props: {
      filters: { type: Object, required: false, default: () => { }},
      actions:{
        type: Function,
        required: false,
        default: ()=> {}
      }
    },
    
    created() {
      this.init();
    },
    
    watch: {
      options: {
        handler() {
          this.fetchData();
        },
        deep: true,
        immediate: true,
      },
    },

    methods: {
      initialState() {
        return {
          dialogues: {
            viewTeam: false,
            removeTeam: false,
            editTeam: false
          },
          idToDelete: null,
          headers: [
            { text: 'Nome', value: 'name', sortable: true },
            { text: 'Duração do Contrato', value: 'contractDuration', sortable: true },
            { text: 'Profit Share', value: 'profitShare', sortable: true },
            { text: 'Ação', value: 'action', sortable: false },
          ],
          footerProps: {
            'items-per-page-options': this.$itemsPerPageOptions,
          },
          search: null,
          options: {
            page: 1,
            itemsPerPage: 10,
            sortBy: [],
            sortDesc: [],
            groupBy: [],
            groupDesc: [],
            mustSort: true,
            multiSort: true
          },
        }
        
      },

      showDaysInMonths(item){
        if(Math.round(item/30) === 1){
          return `${(Math.round(item/30))} mês`
        }else if(Math.round(item/30) > 1){
          return `${(Math.round(item/30))} meses`
        }else{
          return 'Por definir'
        }
      },

      checkImageExists(image) {
        try {
          require('@/assets/teams/' + image);
          return image;
        } catch (error) {
          return 'noteam.png';
        }
      },

      async fetchData() {
        // Base payload
        let payload = {
          params: {
            page: this.options.page,
            itemsPerPage: this.options.itemsPerPage,
          }
        };
        Object.assign(payload.params, this.sortDescHandler(this.options.sortDesc));
        this.addParameterToPayload(payload, 'sortBy[]', this.options.sortBy);
        // API Call
        await this.$store.dispatch('teams/get', payload);
      },

      async deleteTeam(){
        let payload = {
          id:this.idToDelete,
        }
        let result = await this.$store.dispatch('teams/remove', payload);

        this.dialogues.removeTeam = false

        if (result === true) {
          // Show success snackbar
          this.$store.dispatch('UI/showSnackbar', {
              message: 'Time removido com sucesso.',
              color: 'success'
          });

          this.$store.dispatch('teams/get', payload);
          this.closeDialog();
      }
      },
      
      constructPayload() {
        let payload = {
          params: {
            page: this.options.page,
            itemsPerPage: this.options.itemsPerPage,
          },
        }
        return payload;
      },
      
      async getSchedulingAllTeams() {
        let payload = this.constructPayload();
        await this.$store.dispatch('teams/get', payload);
      },

      async dialogViewTeam(item) {
        this.$router.push({
          path: `/teams/${item.id}`
        });
      },
      
      openDialogRemoveConfirmation(id) {
        this.dialogues.removeTeam = true;
        this.idToDelete = id
      },

      closeDialogRemoveConfirmation() {
        this.dialogues.removeTeam = false;
      },
      
      init() {
        this.getSchedulingAllTeams();
      },

      toggleDialog(dialog) {
        this.dialogues[dialog] = ! this.dialogues[dialog];
      },

      setActiveItem(item) {
        if (item) {
          this.$store.commit(
            'teams/setSelectedTeam',
            item
          );
        }
      },

      openDialogAddEditTeam(dialog, item) {
        this.setActiveItem(item);
        this.toggleDialog(dialog);
      },

      closeDialogEditTeam() {
        this.$store.commit('teams/setSelectedTeam', null);
        this.toggleDialog('editTeam');
      }

    },

  }