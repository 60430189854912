import { mapGetters } from 'vuex';
import DialogConfirmation from '@/components/Rail/Dialog/DialogConfirmation/DialogConfirmation.vue';
import DatePickerRange from '@/components/DatePickerRange/DatePickerRange.vue';
import LoadingSpinner from '@/components/LoadingSpinner/LoadingSpinner.vue';
import Snackbar from "@/components/Snackbar";
import AlertError from '@/components/Alerts/AlertError/AlertError.vue';

export default {
    name: 'RailDocumentsDropdown',

    components: {
        DialogConfirmation,
        DatePickerRange,
        LoadingSpinner,
        Snackbar,
        AlertError,
	},

    computed: {
        ...mapGetters({
            documents: 'documents/documents',
            loading: 'documents/loading',
            heroDatePickerValue: 'UI/heroDatePickerValue',
            singleDocument: 'documents/singleDocument',
            singleDocumentLoading: 'documents/singleDocumentLoading',
            error: 'documents/error',
            humanError: 'documents/humanError',
        }),

        computedValue: {
            get: function () {
               return this.document;
            },
            set: function (newValue) {
                this.document = newValue;
            }
        },

        computedCalendarDates: {
			get() {
				return this.dates;
			},
			set(newValue) {
                this.dates = newValue;
			}
        },
    },

    data() {
        return this.initialState();
    },

    created() {
        this.fetchData();
    },

    methods: {
        initialState() {
            return {
                document: null,
                dialog: false,
                title: '',
                message: '',
                submitting: false,
                showMessageFeedback: false,
                showError: false,
                showRake: false,
                simplify: false,
                dates: [],
                year: this.$moment().format('YYYY'),
                years: ['2021','2022','2023','2024'],
                data: [
                    { value: 1, name: 'Rail Contabilidade'},
                    { value: 2, name: 'Credit Transfer'},
                    { value: 3, name: 'Staking Statement'},
                ],
            }
        },

        // used in DialogConfirmation (for feedback message)
        getDocumentName(value) {
            return this.data.find(e => e.value == value).name;
        },

        emitChange(item) {
            if (item) {
                this.dialog = true;
                this.title = "Download do ficheiro '" + this.getDocumentName(item) + "'";

                this.handleShowSubmitButton(item);

                if (this.documents && this.documents.CREDIT_TRANSFER == item) {
                    this.getSingleDocument();
                }
            }
        },

        async fetchData() {
            await this.$store.dispatch('documents/getDocumentTypes');
        },

        clearErrors() {
			this.$store.dispatch('documents/clearError');
		},

        async getSingleDocument() {
            // UI
            this.submitting = true;

            // API req
            let payload = this.formulatePayload();

            payload.params = {
                ...payload.params,
                showRake: this.showRake ? 1 : 0,
                simplify: this.simplify ? 1 : 0,
            }
            
            let result = await this.$store.dispatch('documents/getSingleDocument', payload);
            if (result === true) {
                this.clearErrors();
                // Show "download should have started" msg
                this.showMessageFeedback = true;

                // Show snackbar
                this.$store.dispatch('UI/showSnackbar', {
                    message: "Download terminado com sucesso",
                    color: 'success'
                });
                
                window.open(this.singleDocument, '_blank');
            } else {
                this.showError = true;
            }

            // UI
            this.submitting = false;
        },

        formulatePayload() {
            let payload = {
                params: {
                    user: this.$route.params.id,
                    type: this.document
                },
            }

            // Add date params
            if (this.documents && this.documents.ACCOUNTING_CSV == this.computedValue && !this._.isEmpty(this.computedCalendarDates)) {
                payload.params.dateBegin = this.computedCalendarDates[0];
                payload.params.dateEnd = this.computedCalendarDates[1];
            }

            if (this.documents && this.documents.STAKING_STATEMENT == this.computedValue) {
                payload.params.year = this.year;   
            }

            return payload;
        },

        closeDialog() {
            this.dialog = false;
            this.computedValue = null;
            this.showMessageFeedback = false;
            this.showError = false;
        },

        /**
         * Handles submit button based on item passed through params 
         * @returns {Bool}
         */
        handleShowSubmitButton(item) {
            let output = false;

            if (!this._.isEmpty(this.documents)) {
                switch (item) {
                    case this.documents.ACCOUNTING_CSV:
                        output = true;
                        break;
                    case this.documents.CREDIT_TRANSFER:
                        output = false;
                        break;
                    case this.documents.STAKING_STATEMENT:
                        output = true;
                        break;
                    default:
                        output = false;
                        break;
                }
            }

            if (this.showMessageFeedback) {
                output = false;
            }

            return output;
        }
    }
}