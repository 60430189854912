import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth.vue';
import RailMixin from '@/mixins/Rail.vue';
import DataMixin from '@/mixins/Data.vue';
import EmptyStateNoData from '@/components/EmptyStateNoData';
import DialogAddPlayerToGroup from '@/components/Dialog/DialogAddPlayerToGroup/DialogAddPlayerToGroup.vue';
import DialogRemovePlayerFromGroup from '@/components/Dialog/DialogRemovePlayerFromGroup/DialogRemovePlayerFromGroup.vue';
import UserCard from '@/components/UserCard/UserCard.vue';

export default {
    name: 'TableGroupMembers',

    mixins: [
        AuthMixin,
        RailMixin,
        DataMixin,
    ],

    components: {
        EmptyStateNoData,
        DialogAddPlayerToGroup,
        DialogRemovePlayerFromGroup,
        UserCard,
    },

    created() {
        return this.initialize();
    },

    data() {
        return this.initialState();
    },

    computed: {
        ...mapGetters({
            groupMembers: 'users/usersFromGroup',
            groupMembersLoading: 'users/usersFromGroupLoading',
            groupMembersTotal: 'users/usersFromGroupTotal',
        }),
        
        optionsComputed: {
            get: function () {
              return this.options;
            },
            set: function (newValue) {
                if (!this._.isEqual(this.options, newValue)) {
                    this.options = newValue;
                }
            }
        },
    },

    watch: {
        // watches this.options object
        // triggers everytime this.options has changed
        options: {
            handler() {
                this.fetchData();
            },
            deep: true,
        },
    },

    methods: {
        initialState() {
            return {
                dataTable: {
                    headers: [
                        { text: 'Nome', value: 'name' },
                        { text: 'Ação', value: 'action', sortable: false },
                    ],
                    search: null,
                },
                options: {
                    page: 1,
                    itemsPerPage: 10,
                    sortBy: [],
                    sortDesc: [],
                    groupBy: [],
                    groupDesc: [],
                    mustSort: true,
                    multiSort: false
                },
                dialogues: {
                    addPlayer: false,
                    remove: false,
                },
            };
        },

        initialize() {
            // only run when initializing component
            this.$store.dispatch('groups/get');
            this.fetchData();
        },

        async fetchData() {
            // Base payload
            let payload = {
                params: {
                    group: this.$route.params.id,
                    page: this.options.page,
                    itemsPerPage: this.options.itemsPerPage,
                }
            };

            // Add sortDesc parameter to payload.params
            Object.assign(payload.params, this.sortDescHandler(this.options.sortDesc));

            // Add sortBy parameter to payload
            this.addParameterToPayload(payload, 'sortBy[]', this.options.sortBy);

            // API Call
            await this.$store.dispatch('users/getUsersFromGroup', payload);
        },

        setActiveItem(item) {
            if (item) {
                this.$store.commit(
                    'groups/setSelectedGroupMember',
                    item
                );
              }
          },

        dialogAddPlayerToGroup() {
            this.dialogues.addPlayer = true;
        },

        dialogRemovePlayerFromGroup() {
            this.dialogues.remove = true;
        },

        handleUserImgCutout(item) {
            let output = require('@/assets/images/players/player-example.png');
            
            if (item.imgCutout) {
                output = item.imgCutout;
            }

            return output;
          },
    },
};
