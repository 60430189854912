import Vue from 'vue';
import { mapGetters } from 'vuex';
import RailMixin from '@/mixins/Rail.vue';
import DataMixin from '@/mixins/Data.vue';
import AuthMixin from '@/mixins/Auth.vue';
import NicknameDropdown from '@/components/Rail/NicknameDropdown/NicknameDropdown.vue';
import UserDropdown from '@/components/Rail/UserDropdown/UserDropdown.vue';
import InputFinancial from '@/components/InputFinancial/InputFinancial.vue';

export default {
    name: 'NetworkTransferPicker',

    components: {
        NicknameDropdown,
        UserDropdown,
        InputFinancial,
    },

    props: {
        list: { type: Array, required: false, default: () => [] },
        userId: { type: Number, required: false, default: null },
        targetUserId: { type: Number, required: false, default: null },
        isUserManagement: { type: Boolean, required: false, default: false },
        isTargetUserManagement: { type: Boolean, required: false, default: false },
        maxHeight: { type: String, required: false, default: '600px' },
        finalized: { type: Boolean, required: false, default: false },
        action: {
			type: String,
			required: false,
			default: 'create',
			validator: function(value) {
				const allowedActions = ['create', 'readOnly']
				return allowedActions.includes(value);
			},
        },
        operationType: { type: [Number, String], required: false, default: '' },
        singleTransfer: { type: Boolean, required: false, default: false },
    },

    data() {
        return this.initialState();
    },

    mixins: [
        RailMixin,
        DataMixin,
        AuthMixin,
    ],

    computed: {
        ...mapGetters({
            themeDarkMode: 'UI/themeDarkMode',
            currencies: 'currencies/currencies'
        }),

        nicknameSourceComputed: function () {
            return {
                ref: "NicknameDropdownSource",
                type: this.isUserManagement ? 'ANY' : 'PLAYER',
                scope: this.isUserManagement ? 'MANAGEMENT' : 'ALL',
                user: this.userId,
                readonly: this.action != 'create',
                placeholder: "Origem",
                label: "Sala Origem",
                'always-reload':  false,
                'background-color': "transparent",
                'full-width': true,
                'show-external-wallet': this.operationType == Vue.prototype.$rail_ops.operations.split,
                'concatAllNicknames': this.operationType == Vue.prototype.$rail_ops.operations.split,
                dense: true,
                outlined: false,
                flat: true,
                solo: true,
            }
        },

        nicknameTargetComputed: function () {
            return {
                ref: "NicknameDropdownTarget",
                type: this.isTargetUserManagement ? 'ANY' : 'PLAYER',
                scope: this.isTargetUserManagement ? 'MANAGEMENT' : 'ALL',
                user: this.targetUserId,
                readonly: this.action != 'create',
                placeholder: "Destino",
                label: "Sala Destino",
                'always-reload': false,
                'background-color': "transparent",
                'full-width': true,
                'show-external-wallet': this.operationType == Vue.prototype.$rail_ops.operations.split,
                'concatAllNicknames': this.operationType == Vue.prototype.$rail_ops.operations.split || this.operationType == Vue.prototype.$rail_ops.operations.bankroll_reduction,
                dense: true,
                outlined: false,
                flat: true,
                solo: true,
            }
        },

        transfersListComputed: {
            get() {
                return this.form.transfersList;
            },
            set(newVal) {
                this.form.transfersList = newVal;
            },
        },

        dataTableHeaders: function () {
            let output = [
                { text: 'De', value: 'source', sortable: false },
                { text: 'Para', value: 'target', sortable: false },
                { text: 'Montante', value: 'amount', sortable: false, width: '20%' },
                { text: 'Taxa', value: 'rate', sortable: false, width: '1%' },
                { text: '', value: 'delete', sortable: false, width: '1%'},
            ];
            return output;
        },
    },

     watch: {
        'form.transfersList': {
            handler() {
                this.emitChange();
            },
            deep: true,
        },
    },

    created() {
        // Set loading input state
        this.setLoadingInput();
        // populates transfersListComputed with prop list
        this.transfersListComputed = this.list;
        // add new empty entry to form.transfersList
        if (this._.isEmpty(this.list)) {
            this.add();
        }
    },

    methods: {
        initialState() {
            return {
                loadingInput: true,
                form: {
                    transfersList: []
                },
                dataTable: {
                    itemsPerPage: -1,
                },
                rules: {
                    nickname: [(v) => !!v || 'Selecione uma sala'],
                    balance: [
                        (v) => !!v || 'Introduza um montante',
                        (v) => !Number.isNaN(v) || 'Introduza um valor numérico',
                    ]
                },
            }
        },

        // add an empty entry to transfersList
        add() {
            let output = this.form.transfersList;
            let newEntry = {
                source: null,
                target: null,
                amount: 0,
                rate: null,
            }

            output.push(newEntry);

            this.transfersListComputed = output;

            // Scroll user down
            setTimeout(() => {
                document.getElementById('divNicknameList').scrollTop = document.getElementById('divNicknameList').scrollHeight;
            }, 1);
        },

        /**
         * Removes entry from transfersList based on item and index position
         * @param {Object} item 
         */
        remove(item) {
            let indexPosition;
            // when NicknameDropdown is fullfilled it findIndex by item
            indexPosition = this.form.transfersList.findIndex(e => e == item);

            // remove entry from transfersList
            this.form.transfersList.splice(indexPosition, 1);
        },

        emitChange() {
            this.$emit('change', this.generateOutput());
            this.$emit('input', this.generateOutput());
        },

        generateOutput() {
            return this.transfersListComputed;
        },

        /**
         * @param {Object} item 
         * @returns {String} with an icon to be used in Amount column
         */
        getCurrency(item) {
            let output = '';
            if (item && item.source) {
                output = item.source.network.currency.symbol;
            }
            return output;
        },
        
        // clears transfersList, add new empty entry
        clearTable() {
            this.transfersListComputed = [];
            this.add();
        },

        /**
         * @param {Object} item from table
         * @returns {Number} rate value
         */
        setRate(item) {
            let output = null
            if (item && item.source && item.target) {

                let sourceIsExternal = item.source.id == Vue.prototype.$rail_external_wallet_id;
                let targetIsExternal = item.target.id == Vue.prototype.$rail_external_wallet_id;
                let exchangePairName = item.source.network.currency.code + item.target.network.currency.code;

                if (sourceIsExternal || targetIsExternal) {
                    item.rate = null;
                } else {
                    item.rate = this.currencies[exchangePairName] ? this.currencies[exchangePairName].rate : null;
                }
            }

            output = item.rate;
            return output;
        },

        /**
         * It's triggered on created()
         * Set a loading input state in order to props (from NicknameDropdown) be fully loaded
         * @returns {Boolean} loadingInput
         */
        setLoadingInput() {
            this.loadingInput = true;
            setTimeout(() => {
                this.loadingInput = false;
            }, 1750);
            return this.loadingInput;
        }
    }
}
