<template>
  <div class="page">
    <h1>Classes</h1>
  </div>
</template>

<script>
export default {
  name: 'Classes'
}
</script>

<style scoped>
</style>