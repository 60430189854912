import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth';
import UserDropdown from '@/components/Rail/UserDropdown/UserDropdown.vue';
import AlertError from '@/components/Alerts/AlertError/AlertError.vue';

export default {
	name: 'DialogAddEditSwapGroup',

	props: {
		isOpen: { type: Boolean, required: true, default: false },
		action: { type: String, required: false, default: 'create'}
	},

	components: {
		UserDropdown,
		AlertError,
    },

    mixins: [
		AuthMixin,
	],
	
	data() {
		return this.initialState();
	},

	computed: {
		...mapGetters({
			themeDarkMode: 'UI/themeDarkMode',
			selectedSwapGroup: 'railSwaps/selectedSwapGroup',
			error: 'railSwaps/error',
			humanError: 'railSwaps/humanError',
			errorGroupUsers:'railSwaps/errorGroupUsers',
			humanErrorGroupUsers: 'railSwaps/humanErrorGroupUsers',
		}),

		showAlertUsersLimit: function () {
			let output = false;

			// if players length isn't between 2 and 7, it shows alert
			if (!this._.isNull(this.form.players)) {
				if (this.form.players.length < 2 || this.form.players.length > 7) {
					output = true;
				}
			}
			return output;
		},
	},

	watch: {
		isOpen: function () {
			if (this.isOpen) {

				// clear input errors
				this.$store.dispatch('railSwaps/clearError');
				this.$store.dispatch('railSwaps/clearGroupUserErrors');
				
				if (this.$refs && this.$refs.UserDropdownRef) {
					this.$refs.UserDropdownRef.reset();				
				}
				
				if (this.action == 'create') {
					// clear states
					this.resetForm();
				}
				if (this.action == 'edit') {
					this.populateFields();
				}
			}
		},
		selectedSwapGroup: function () {
			if (this.action == 'edit') {
				this.populateFields();
			}
		}
	},

	methods: {
		initialState() {
			return {
				submitting: false,
				form: {
                    name: null,
					players: null,
					active: true,
				},
				rules: {
					name: [],
					players: [],
				}
			};
		},

		populateFields() {
			if (this.selectedSwapGroup) {
				this.form.name = this.selectedSwapGroup.name
				this.form.players = !this._.isEmpty(this.selectedSwapGroup.users) ? this.selectedSwapGroup.users.map(element => { return { id: element } }) : [];
				this.form.active = this.selectedSwapGroup.active;
			}
		},

		generatePlayersPayload() {
			let output = [];
			if (!this._.isEmpty(this.form.players)) {
				output = this.form.players.map(element => element.id);
			} 
			return output;
		},

		async submit() {
			if (!this.$refs.formAddEditSwapPool.validate()) {
				return false;
			}

			// Toggle submit button
			this.toggleSubmitting();

            // construct payload
			let payload = {
				params: {
					name: this.form.name,
					active: this.form.active,
					players: this.generatePlayersPayload()
				}
			}

			if (this.selectedSwapGroup) {
				payload.id = this.selectedSwapGroup.id;
			}

			let actionToDispatch = this.action == 'edit'
				? 'railSwaps/updateSwapGroup'
				: 'railSwaps/createSwapGroup';

            // API call
			let createUpdateResult = await this.$store.dispatch(actionToDispatch, payload);

			// // Re-enable button
			this.toggleSubmitting();

			// On success
			if (createUpdateResult === true) {
				let word = this.action == 'create'
					? 'criado'
					: 'editado';
				
				// Show success snackbar
				this.$store.dispatch('UI/showSnackbar', {
					message: 'Swap group ' + word + ' com sucesso',
					color: 'success'
				});

				let payloadRefresh = {
					id: this.selectedSwapGroup.id,
					params: {
                        page: 1,
                        itemsPerPage: -1
                    }
				}

                this.$store.dispatch('railSwaps/getSwapGroupsDropdown', payloadRefresh);

				if (this.action == 'edit') {
					this.$store.dispatch('railSwaps/getSwapGroupStatistics', payloadRefresh);
				}
				// closes dialog
				this.closeDialog();
			}
		},

		/**
		 * Used to disable Submit button
		 */
		toggleSubmitting() {
			this.submitting = !this.submitting;
		},

		closeDialog() {
			this.$emit("close-dialog");
			// this.resetForm();
		},

		resetForm() {
			Object.assign(this.$data, this.initialState());
			this.$refs.formAddEditSwapPool.reset();
		}
	},
};