import Vue from 'vue';
import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth';
import Hero from '@/components/Hero/Hero.vue';
import Loading from '@/views/Loading.vue';
import Snackbar from '@/components/Snackbar';
import TableGamesPlayed from '@/components/Tables/TableGamesPlayed/TableGamesPlayed.vue';
import NetworkSharkscopeDropdown from '@/components/Scheduling/Menus/NetworkSharkscopeDropdown/NetworkSharkscopeDropdown.vue';
import NetworkDropdown from '@/components/Rail/NetworkDropdown/NetworkDropdown.vue';

export default {
  name: 'Games',

  mixins: [
    AuthMixin,
  ],

  components: {
    Hero,
    TableGamesPlayed,
    Loading,
    Snackbar,
    NetworkDropdown,
    NetworkSharkscopeDropdown,
  },

  computed: {
    ...mapGetters({
      userType: 'auth/userType',
      userRoles: 'auth/userRoles',
      themeDarkMode: 'UI/themeDarkMode',

    }),

    formFilters: {
      get() {
          return this.filters;
      },
      set(newVal) {
          this.filters = newVal;
      }
    },

    valueColorFinal: function () {
	    let output = 'black';
	  
		if (this.themeDarkMode) {
			output = 'white';
		}
	  
		return output;
	},
  },

  async created() {
    await this.allowOnlyRoles(['rail_admin', 'rail_manager', 'tracker_admin', 'tracker_manager']);
  },

  watch: {
    formFilters: {
      handler(newVal) {
        this.$refs.tableGamesPlayedFilter.fetchData();
      },
      deep: true
    }
  },

  beforeDestroy() {
  },

  data() {
    return this.initialState();
  },

  methods: {
    initialState() {
      return {
        filters:{
          networks: null,
        }
      }
    },
  }
}