<template>
  <v-form
    ref="formSplitRouting"
    lazy-loading
    class="fill-height"
  >
    <component
      :is="componentToShow"
      ref="formSplitChild"
    />
  </v-form>
</template>

<script>
import CardSplitUser from "@/components/Rail/CardSplit/Stage1/user/CardSplit.vue";
import CardSplitAdmin from "@/components/Rail/CardSplit/Stage1/admin/CardSplit.vue";
import AuthMixin from "@/mixins/Auth.vue";

export default {
	name: "CardSplit",

	components: {
		CardSplitUser,
		CardSplitAdmin
		},

	mixins: [
		AuthMixin,
		],
	
	computed: {
		componentToShow() {
			let pageToShow = this.userIsRailManager
				? "CardSplitAdmin"
				: "CardSplitUser";
				
			return pageToShow;
		},
		
	},
	methods: {

		submit() {
			this.$refs.formSplitChild.submit()
		}
	}
};
</script>
