import { mapGetters } from 'vuex';
import DialogConfirmation from '@/components/Rail/Dialog/DialogConfirmation/DialogConfirmation.vue';
import DialogEditUserNotes from '@/components/Dialog/DialogEditUserNotes/DialogEditUserNotes.vue';

export default {
    name: 'MenuUserNotes',

    props: {
		stateToPopulate: { type: String, required: true, default: 'userNotes' }, // VALID VALUES ['userNotes', 'userTargetNotes]
    },

    components: {
        DialogConfirmation,
        DialogEditUserNotes,
    },

    computed: {
        ...mapGetters({
            selectedUserNote: 'users/selectedUserNote',
            selectedUser: 'users/selectedUser',
            error: 'users/error',
            humanError: 'users/humanError'
        }),
    },

    data() {
        return this.initialState();
    },

    methods: {
        initialState() {
            return {
                submittingDelete: false,
                dialogues: {
                    edit: false,
                    delete: false,
                }
            }
        },

        // emits change to parent component
        setActiveItem() {
            this.$emit('set-active');
        },

        // opens DialogEditUserNotes
        openDialogEditUserNotes() {
            this.dialogues.edit = true;
        },

        /**
         * close DialogEditUserNotes
         * send dispatch to users store to clear errors
         */
        closeDialogEditUserNotes() {
            this.dialogues.edit = false;
            this.$store.dispatch('users/clearErrors');
        },

        // opens DialogConfirmation
        openDialogDeleteUserNotes() {
            this.dialogues.delete = true;
        },

        /**
         * close DialogConfirmation
         * send dispatch to users store to clear errors
         */
        closeDialogDeleteUserNotes() {
            this.dialogues.delete = false;
            this.$store.dispatch('users/clearErrors');
        },

        /**
         * Formulate payload
         * send dispatch to users store to delete user note
         * if successfully deleted, shows snackbar and triggers  closeDialogDeleteUserNotes()
         */
        async deleteUserNote() {
            // Set DialogConfirmation to submitting state
            this.submittingDelete = true;

            // Formulate payload
            let payload = {
                stateToPopulate: this.stateToPopulate,
                userID: this.selectedUser.id,
                noteID: this.selectedUserNote.id,
            }

            // Dispatch to store
            let result = await this.$store.dispatch('users/deleteUserNotes', payload);

            // re-enables submit button;
            this.submittingDelete = false;

            if (result === true) {
                // Show success snackbar
                this.$store.dispatch('UI/showSnackbar', {
                    message: 'Nota apagada com sucesso.',
                    color: 'success'
                    });

                // closes dialog
                this.closeDialogDeleteUserNotes();
            }
        },
    }
}