import AuthMixin from '@/mixins/Auth'
import Loading from '@/views/Loading.vue'
import CalendarHome from './Home/Home.vue'
import CalendarMatrix from '@/components/Calendar/CalendarMatrix/CalendarMatrix.vue';
import Hero from "@/components/Hero/Hero.vue";
import Snackbar from "@/components/Snackbar";

export default {
    name: 'Calendar',

    components: {
        Loading,
        CalendarHome,
        CalendarMatrix,
        Hero,
        Snackbar,
    },

    mixins: [
        AuthMixin,
    ],

    created() {
        this.init();
    },

    methods: {
        init() {
        }
    }
}