import Vue from 'vue';
import AuthMixin from '@/mixins/Auth.vue';
import UserMixin from '@/mixins/User.vue';

export default {
    data() {
      return {
        showDialog: false,
      };
    },

    mixins: [
      AuthMixin,
      UserMixin,
    ],

    methods: {
      closeDialog() {
        this.$emit('close-dialog');
      },
    },
    
    props:{
      dialogData: { type: Object, required: true, default: {}},
    },

    async created() {
    },
  };