import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth';
import AlertError from '@/components/Alerts/AlertError/AlertError.vue';
import MetadataDropdown from '@/components/Rail/MetadataDropdown/MetadataDropdown.vue';


export default {
    name: 'DialogCreateNetwork',

    components: {
        AlertError,
        MetadataDropdown,
    },

    props: {
        isOpen: {
            type: Boolean,
            required: true,
            default: false
        },
    },

    mixins: [
        AuthMixin,
    ],

    data() {
        return this.initialState();
    },

    created() {
        this.$store.dispatch('networks/getImages')

        if (this._.isEmpty(this.currenciesBase)) {
            this.$store.dispatch('currencies/getCurrenciesBase');
        }
    },

    computed: {
        ...mapGetters({
            error: 'networks/error',
            humanError: 'networks/humanError',
            networks: 'networks/networks',
            images: 'networks/images',
            currenciesBase: 'currencies/currenciesBase'
        }), 
    },
    
    methods: {
        initialState() {
            return {
                submitting: false,
                nicknameType: [
                    { value: 0, name: 'Sala' },
                    { value: 1, name: 'Carteira' }
                ],
                form: {
                    name: null,
                    identifier: null,
                    currency: null,
                    image: null,
                    isWallet: false,
                    metadata: [],
                },
                rules: {
                    name: [ 
                        v => !!v || 'Por favor introduza um nome para a sala',
                    ],
                    identifier: [ 
                        v => !!v || 'Por favor introduza um identificador para a sala',
                    ],
                    currency: [ 
                        v => !!v || 'Por favor introduza um tipo de moeda para a sala',
                    ],
                    image: [ 
                        v => !!v || 'Por favor introduza uma imagem para a sala',
                    ],
                },
            };
        },

        async submit() {
            if (!this.$refs.formCreateNetwork.validate()) {
                return false;
            }

            // Toggle submit button
            this.toggleSubmitting();

            let payload = {
                name: this.form.name,
                identifier: this.form.identifier,
                currencyID: this.form.currency,
                image: this.form.image,
                isWallet: this.form.isWallet.value
            }

            if (this.form.isWallet.value) {
                payload.identifier = '';
            }

            if (!this._.isEmpty(this.form.metadata)) {
                let metadataMapped = this.form.metadata.map(e => e.name);

                payload.metadataRequired = metadataMapped.join(',');
            }

            let result = await this.$store.dispatch('networks/create', payload);

            // // Re-enable button
            this.toggleSubmitting();

            // On success
            if (result === true) {
                // Show success snackbar
                this.$store.dispatch('UI/showSnackbar', {
                    message: 'Network adicionada com sucesso.',
                    color: 'success'
                });

                // set promiseNetworks to NULL in order to be possible to make new API Request
                this.$store.commit('networks/setPromiseNetworks', null);

                this.$emit('refresh');

                // closes dialog
                this.closeDialog();
            }
        },

        /**
         * Used to disable Submit button
         */
        toggleSubmitting() {
            this.submitting = !this.submitting;
        },

        closeDialog() {
            this.$emit('close-dialog');
            this.resetForm();
        },

        resetForm() {
            Object.assign(this.$data, this.initialState());
            this.$refs.formCreateNetwork.reset();
            this.$store.dispatch('networks/clearError');
        }
    },
};
