import Vue from 'vue';
import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth';
import RailMixin from '@/mixins/Rail.vue';
import EmptyStateNoData from '@/components/EmptyStateNoData';
import TableSwapTournaments from '@/components/Rail/TableSwapTournaments/TableSwapTournaments.vue';
import TableSwapGroupMembers from '@/components/Rail/TableSwapGroupMembers/TableSwapGroupMembers.vue';
import DialogAddEditSwapGroup from '@/components/Rail/Dialog/DialogAddEditSwapGroup/DialogAddEditSwapGroup.vue';
import CardStatistics from '@/components/Rail/Statistics/CardStatistics/CardStatistics.vue';

export default {
    name: 'SwapPool',

    mixins: [
        AuthMixin,
        RailMixin,
    ],

    components: {
        TableSwapTournaments,
        DialogAddEditSwapGroup,
        EmptyStateNoData,
        TableSwapGroupMembers,
        CardStatistics,
    },

    computed: {
        ...mapGetters({ 
            selectedSwapGroup: 'railSwaps/selectedSwapGroup',
            swapGroupStatistics: 'railSwaps/swapGroupStatistics',
            swapGroupStatisticsLoading: 'railSwaps/swapGroupStatisticsLoading',
            users: 'users/usersAllActive',
            heroDatePickerValue: 'UI/heroDatePickerValue',
        }),

        cardInfo() {
            if (this.userIsRailManager) {
                return [
                    {
                        name: 'Montante investido',
                        value: this.swapGroupStatistics['global'] ? this.swapGroupStatistics['global'].investedAmount.value : 0,
                        precision: 2,
                        icon: 'mdi-bank',
                        currencyIcon: 'mdi-currency-usd',
                        showCurrency: true,
                        showAltCurrency: false,
                        loading: this.swapGroupStatisticsLoading
                    },
                    {
                        name: 'Montante ganho',
                        value: this.swapGroupStatistics['global'] ? this.swapGroupStatistics['global'].earnedAmount.value : 0,
                        precision: 2,
                        icon: 'mdi-bank',
                        currencyIcon: 'mdi-currency-usd',
                        showCurrency: true,
                        showAltCurrency: false,
                        loading: this.swapGroupStatisticsLoading,
                    },
                    {
                        name: 'Lucro',
                        value: this.swapGroupStatistics['global'] ? this.swapGroupStatistics['global'].pnlAmount.value : 0,
                        precision: 2,
                        icon: 'mdi-bank',
                        currencyIcon: 'mdi-currency-usd',
                        showCurrency: true,
                        showAltCurrency: false,
                        loading: this.swapGroupStatisticsLoading,
                    },
                ];
            } else {
                return [];
            }
        },

        playersToReceive: function () {
            let output = [];
            if (!this.swapGroupStatisticsLoading && this.swapGroupStatistics) {
                output = this.swapGroupStatistics.toReceive;
            }
            return output;
        },

        playersToSend: function () {
            let output = [];
            if (!this.swapGroupStatisticsLoading && this.swapGroupStatistics) {
                output = this.swapGroupStatistics.toSend;
            }
            return output;
        },
    },

    watch: {
        heroDatePickerValue: function () {
            this.fetchStatistics();
        },

        '$route.params.id': function() {
            this.fetchSwapGroup();
            this.fetchStatistics();
        }
    },

    async created() {
        await this.fetchSwapGroup();
        await this.fetchStatistics();

        if (this._.isEmpty(this.users)) {
            this.getUsers();
        }

    },

    data() {
        return this.initializeState();
    },

    methods: {
        initializeState() {
            return {
                dialogues: {
                    editPool: false,
                }
            }
        },

        async fetchStatistics() {
            let payload = {
                id: this.$route.params.id,
                params: {}
            }

            if (! this._.isEmpty(this.heroDatePickerValue)) {
                payload.params = {
                    dateBegin: this.heroDatePickerValue[0],
                    dateEnd: this.heroDatePickerValue[1],
                };
            }

            await this.$store.dispatch('railSwaps/getSwapGroupStatistics', payload);
        },

        async fetchSwapGroup() {
            await this.$store.dispatch('railSwaps/getSwapGroupById', this.$route.params.id);
        },

        /**
         * Opens Dialog and allows to edit swap pool
         */
        openEditSwapPool() {
            this.dialogues.editPool = true;
        },
        
        /**
         * Closes Dialog
         */
        closeEditSwapPool() {
            this.dialogues.editPool = false;
        },

        /**
         * Redirects user to Swaps View
         */
        cancel() {
            this.$store.commit('TabSlider/setSelectedTab', Vue.prototype.$tab_slider.tabs.swaps);
            this.$router.push({ path: Vue.prototype.$tab_slider.tabs.swaps }).catch(() => { });
        },

        handleUsername(id) {
            let output = null;

            if (!this._.isEmpty(this.users)) { 
                output = this.users.find(element => element.id == id);
                if (!this._.isUndefined(output)) {
                    output = output.name;
                }
            }
            return output;
        },

        getUsers() {
            let payload = this.formulateUsersAllUrlParameters();
            this.$store.dispatch('users/getUsersAllActive', payload);
        }
    },
}