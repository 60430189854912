import Vue from 'vue';
import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth';
import Checkout from '@/components/Rail/Checkout/Checkout.vue';
import TableBalance from '@/components/Rail/TableBalance/TableBalance.vue';
import CardSplit from '@/components/Rail/CardSplit/CardSplit.vue';
import CardMovement from '@/components/Rail/CardMovement/CardMovement.vue';
import CardRequestDeposit from '@/components/Rail/CardRequestDeposit/CardRequestDeposit.vue';
import CardReport from '@/components/Rail/CardReport/CardReport.vue';
import CardBankrollReduction from '@/components/Rail/CardBankrollReduction/CardBankrollReduction.vue'
import CardTransferToSelf from '@/components/Rail/CardTransferToSelf/CardTransferToSelf.vue';
import CardTransferSwap from '@/components/Rail/CardTransferSwap/CardTransferSwap.vue';
import CardTransferToPlayer from '@/components/Rail/CardTransferToPlayer/CardTransferToPlayer.vue';
import CardRegisterAnyOperation from '@/components/Rail/CardRegisterAnyOperation/CardRegisterAnyOperation.vue';
import CardRegisterRake from '@/components/Rail/CardRegisterRake/CardRegisterRake.vue';
import ButtonContainer from '@/components/Rail/ButtonContainer/ButtonContainer.vue';
import LoadingSpinner from '@/components/LoadingSpinner/LoadingSpinner.vue';

export default {
  name: 'Operação',

  mixins: [
    AuthMixin,
  ],

  components: {
    Checkout,
    TableBalance,
    CardSplit,
    CardMovement,
    CardRequestDeposit,
    CardBankrollReduction,
    CardTransferToSelf,
    CardTransferSwap,
    CardTransferToPlayer,
    CardRegisterAnyOperation,
    CardRegisterRake,
    ButtonContainer,
    LoadingSpinner
  },

  computed: {
    ...mapGetters({
      card: 'TabSlider/card',
      hasFinished: 'TabSlider/hasFinished',
      previousTab: 'TabSlider/previousTab',
      selectedRequest: 'railRequests/selectedRequest',
      selectedTransfer: 'railTransfers/selectedTransfer',
      selectedUser: 'users/selectedUser',
      userTrackerId: 'auth/userTrackerId',
    }),

    // Props that the chosen component needs to work
    currentProps: function () {
      if (this.card) {
        switch (this.card) {
          case Vue.prototype.$rail_ops.operations.split:
            return {
              ref: 'formRailSplit'
            };
          case Vue.prototype.$rail_ops.operations.deposit:
          case Vue.prototype.$rail_ops.operations.withdraw:
            return {
              ref: 'formRailMovement'
            };
          case Vue.prototype.$rail_ops.operations.bankroll_increment:
            return {
              ref: 'formBankrollIncrement'
            };
          case Vue.prototype.$rail_ops.operations.bankroll_reduction:
            return {
              ref: 'formBankrollReduction'
            };
          case Vue.prototype.$rail_ops.operations.transfer_to_self:
            return {
              ref: 'formTransferToSelf'
            };
          case Vue.prototype.$rail_ops.operations.swap:
            return {
              ref: 'formRailTransferSwap'
            };
          case Vue.prototype.$rail_ops.operations.transfer_to_player:
            return {
              ref: "formTransferToPlayer"
            };
          case Vue.prototype.$rail_ops.operations.reports:
            return {
              ref: "formReportRequest"
            };
          case Vue.prototype.$rail_ops.operations.any:
            return {
              ref: 'formRegisterAnyOperation'
            };
          case Vue.prototype.$rail_ops.operations.makeup:
            return {
              ref: 'formRegisterRake'
            }
          default:
            break;
        }
      }
    },

    // Computed Property used in Table Balances
    showTabs: function () {
      if (this.card) {
        switch (this.card) {
          case Vue.prototype.$rail_ops.operations.split:
            return true;
          case Vue.prototype.$rail_ops.operations.deposit:
          case Vue.prototype.$rail_ops.operations.withdraw:
            return false;
          case Vue.prototype.$rail_ops.operations.bankroll_increment:
          case Vue.prototype.$rail_ops.operations.bankroll_reduction:
            return true;
          case Vue.prototype.$rail_ops.operations.transfer_to_self:
            return false;
          case Vue.prototype.$rail_ops.operations.swap:
            return this.userIsRailManager ? true : false;
          case Vue.prototype.$rail_ops.operations.transfer_to_player:
            return true;
          case Vue.prototype.$rail_ops.operations.any:
            return true;
          case Vue.prototype.$rail_ops.operations.makeup:
            return true;
          default:
            break;
        }
      }
    },

    // Computed Property used in Table Balances
    user: function () {
      if (this.selectedRequest && this.selectedRequest.user) {
        return this.selectedRequest.user;
      };
      if (this.selectedRequest && this.selectedRequest.targetUser) {
        return this.selectedRequest.targetUser;
      };
      if (this.selectedUser) {
        return this.selectedUser;
      };
    },

    // Computed Property used in Table Balances
    tableBalanceMaxHeight: function () {
      let output = '811px';

      if (this.card != Vue.prototype.$rail_ops.operations.deposit || this.card != Vue.prototype.$rail_ops.operations.deposit) {
        output = '605px';
      }
      return output;
    },

    // isOver = rail operation or hasFinished state is over
    isRailOperationOver: function () {
      let isOver = this.hasFinished || (this.selectedRequest && !this.selectedRequest.ongoing) || (this.selectedTransfer && !this.selectedTransfer.ongoing);
      return isOver;
    },

    // isFlagged = rail operation reported, flag == 1
    isRailOperationFlagged: function () {
      let isFlagged = this.selectedRequest && this.selectedRequest.flag;
      return isFlagged;
    },

    // createdByManagement = rail operation created by management
    isCreatedByManagement: function () {
      let createdByManagement = this.selectedRequest && this.selectedRequest.createdByManagement;
      return createdByManagement;
    },
  },

  watch: {
    // works like created(), triggers when view is rendered
    card: {
      handler() {
        this.populateViewDescription();
      },
      immediate: true,
    },
    
    // runs everytime selectedRequest has changed in order to update current Card
    selectedRequest: function () {
      if (this.selectedRequest) {
        // set card based on selectedRequest operation type
        this.$store.commit('TabSlider/setCard', this.selectedRequest.type);
      }
    },
  },

  data() {
    return this.initialState();
  },

  async created() {
    this.fetchData();
  },

  methods: {
    initialState() {
      return {
        TableBalanceDisplay: 'd-block',
        cardColumnValue: 7,
        title: null,
        description: null,
        loading: true,
      }
    },

    /**
     * Validates if routeId is different from operationId
     * If yes, it triggers an API Call to retrieve the operation.
     * Else it redirect user to /rail/operations/
     */
    async fetchData() {
      let routeId = this.$route.params.id;
      let operationId = this.selectedRequest ? this.selectedRequest.id : null;
      // shows LoadingSpinner component
      this.loading = true;

      // only runs if route name is "Operação #"
      if (this.$route.name == 'Operação #') {
        if (routeId != operationId) {
          let result = await this.$store.dispatch('railRequests/getRequestById', routeId);

          // EDGE CASE: When entering a single operation but is a session, redirects to the correct view
          if (this.selectedRequest && (this.selectedRequest.type == Vue.prototype.$rail_ops.operations.sessions)) {
            this.$router.push({ path: Vue.prototype.$tab_slider.tabs.sessions + routeId });
            this.loading = false;
            return 0;
          }

          // if operation doesn't exist, redirect to /rail/operations/
          if (!result) {
            this.$router.push({ path: Vue.prototype.$tab_slider.tabs.requests });
          }
        }
      }
      // hides LoadingSpinner component
      this.loading = false;
    },

    /** Verifies if this.card from store exists 
     *  Validates which fase and status the current operation is
     *  Sets a class TableBalanceDisplay (d-block or d-none)
     *  Sets a value for cardColumnValue (7 or 12)
     */
    cardToShow() {
      if (this.card) {

        /**
         * F1S1 = Fase 1 Status 1
         * F1S3 = Fase 1 Status 3
         * F2S1 = Fase 2 Status 1
         * F2S2 = Fase 2 Status 2
         * F2S3 = Fase 2 Status 3
         * F3S3 = Fase 3 Status 3
         */
        let F1S1 = this.phaseAndStatusValidator(1, 1);
        let F1S3 = this.phaseAndStatusValidator(1, 3);
        let F2S1 = this.phaseAndStatusValidator(2, 1);
        let F2S2 = this.phaseAndStatusValidator(2, 2);
        let F2S3 = this.phaseAndStatusValidator(2, 3);
        let F3S3 = this.phaseAndStatusValidator(3, 3);

        switch (this.card) {
          case Vue.prototype.$rail_ops.operations.split:
            /**
            * VALIDATIONS:
            * if rail operation hasFinished state true or operation is over
            * OR isRailManager and F2S2 and createdByManagement are true
            * OR isRailManager and F2S3 and are true
            * OR isUser and F1S1 or F3S3 are true
            */
            if ((this.isRailOperationOver) || ((this.userIsRailManager && this.selectedRequest && (this.isCreatedByManagement && F2S2) || this.userIsRailManager && F2S3))
              || (!this.userIsRailManager && (F1S1 || F3S3))) {

              // hide tableBalances
              this.TableBalanceDisplay = 'd-none';
              // Checkout component ocupies 12 columns
              this.cardColumnValue = 12;
              return Checkout;
            }
            else {
              // show tableBalances
              this.TableBalanceDisplay = 'd-block';
              // CardSplit component ocupies 7 columns
              this.cardColumnValue = 7;
              return CardSplit;
            }

          case Vue.prototype.$rail_ops.operations.deposit:
          case Vue.prototype.$rail_ops.operations.withdraw:
            /**
             * VALIDATIONS:
             * if rail operation hasFinished state true or operation is over
             */
            if (this.isRailOperationOver) {
              // hide tableBalances
              this.TableBalanceDisplay = 'd-none';
              // Checkout component ocupies 12 columns
              this.cardColumnValue = 12;
              return Checkout;
            } else {
              // show tableBalances
              this.TableBalanceDisplay = 'd-block';
              // CardMovement component ocupies 7 columns
              this.cardColumnValue = 7;
              return CardMovement;
            }

          case Vue.prototype.$rail_ops.operations.bankroll_increment:
            /**
             * VALIDATIONS:
             * if it's user and operation is phase 1
             * if rail operation hasFinished state true or operation is over
             */
            if (this.isRailOperationOver || !this.userIsRailManager && this.selectedRequest && this.selectedRequest.phase == 1) {

              // hide tableBalances
              this.TableBalanceDisplay = 'd-none';
              // Checkout component ocupies 12 columns
              this.cardColumnValue = 12;
              return Checkout;

            } else {

              // show tableBalances
              this.TableBalanceDisplay = 'd-block';
              // CardRequestDeposit component ocupies 7 columns
              this.cardColumnValue = 7;
              return CardRequestDeposit;
            }

          case Vue.prototype.$rail_ops.operations.bankroll_reduction:
            /**
            * VALIDATIONS:
            * if rail operation hasFinished state true or operation is over
            * OR isRailManager and createdByManagement and F1S3 are true
            * OR iUser and F2S1 OR F2S3 are true
            */
            if (this.isRailOperationOver || (this.userIsRailManager &&  F1S3)
              || (!this.userIsRailManager && (F2S1 || F2S3))) {

              // hide tableBalances
              this.TableBalanceDisplay = 'd-none';
              // Checkout component ocupies 12 columns
              this.cardColumnValue = 12;
              return Checkout;
            } else {
              // show tableBalances
              this.TableBalanceDisplay = 'd-block';
              // CardBankrollReduction component ocupies 7 columns
              this.cardColumnValue = 7;
              return CardBankrollReduction;
            }

          case Vue.prototype.$rail_ops.operations.transfer_to_self:
            /**
             * VALIDATIONS:
             * if rail operation hasFinished state true or operation is over
             */
            if (this.isRailOperationOver) {
              // hide tableBalances
              this.TableBalanceDisplay = 'd-none';
              // Checkout component ocupies 12 columns
              this.cardColumnValue = 12;
              return Checkout;
            } else {
              // show tableBalances
              this.TableBalanceDisplay = 'd-block';
              // CardTransferToSelf component ocupies 7 columns
              this.cardColumnValue = 7;
              return CardTransferToSelf;
            }

          case Vue.prototype.$rail_ops.operations.swap:
            /**
             * VALIDATIONS:
             * if rail operation hasFinished state true or operation is over
             */
            if (this.isRailOperationOver || this.selectedRequest && this.userTrackerId == this.selectedRequest.user.id) {
              // hide tableBalances
              this.TableBalanceDisplay = 'd-none';
              // Checkout component ocupies 12 columns
              this.cardColumnValue = 12;
              return Checkout;
            } else {
              // show tableBalances
              this.TableBalanceDisplay = 'd-block';
              // CardTransferToSelf component ocupies 7 columns
              this.cardColumnValue = 7;
              return CardTransferSwap;
            }

          case Vue.prototype.$rail_ops.operations.transfer_to_player:
            /**
            * VALIDATIONS:
            * if rail operation hasFinished state true or operation is over
            */
            if (this.isRailOperationOver) {
              // hide tableBalances
              this.TableBalanceDisplay = 'd-none';
              // Checkout component ocupies 12 columns
              this.cardColumnValue = 12;
              return Checkout;
            } else {
              // show tableBalances
              this.TableBalanceDisplay = 'd-block';
              // CardTransferToPlayer component ocupies 7 columns
              this.cardColumnValue = 7;
              return CardTransferToPlayer;
            }

          case Vue.prototype.$rail_ops.operations.reports:
            // hide tableBalances
            this.TableBalanceDisplay = 'd-none';
            // Checkout component ocupies 12 columns
            this.cardColumnValue = 12;
            return CardReport;

          case Vue.prototype.$rail_ops.operations.any:
            /**
            * VALIDATIONS:
            * if rail operation hasFinished state true or operation is over
            */
            if (this.isRailOperationOver) {
              // hide tableBalances
              this.TableBalanceDisplay = 'd-none';
              // Checkout component ocupies 12 columns
              this.cardColumnValue = 12;
              return Checkout;
            } else {
              // show tableBalances
              this.TableBalanceDisplay = 'd-block';
              // CardRegisterAnyOperation component ocupies 7 columns
              this.cardColumnValue = 7;
              return CardRegisterAnyOperation;
            }

          case Vue.prototype.$rail_ops.operations.makeup: {
            /*
            * VALIDATIONS:
            * if rail operation hasFinished state true or operation is over
            */
            if (this.isRailOperationOver) {
              // hide tableBalances
              this.TableBalanceDisplay = 'd-none';
              // Checkout component ocupies 12 columns
              this.cardColumnValue = 12;
              return Checkout;
            } else {
              // show tableBalances
              this.TableBalanceDisplay = 'd-block';
              // CardRegisterAnyOperation component ocupies 7 columns
              this.cardColumnValue = 7;
              return CardRegisterRake;
            }
          }
          default:
            break;
        }
      }
    },

    /**
     * Validates if this.selectedRequest.phase is equal to phase
     * Validates if this.selectedRequest.status is equal to status
     * @param {*Number} phase 
     * @param {*Number} status 
     */
    phaseAndStatusValidator(phase, status) {
      let isValid = this.selectedRequest && (this.selectedRequest.phase == phase && this.selectedRequest.status == status);
      return isValid
    },

    /**
     *  Populate this.title and this.description based on selected card
     */
    populateViewDescription() {
      if (this.card) {
        switch (this.card) {
          case Vue.prototype.$rail_ops.operations.split:
            this.title = 'Pedido de Split';
            this.description = this.userIsRailManager
              ? 'Enviar um pedido de split a um jogador.'
              : 'Enviar um pedido de split à gestão.';
            break;
          case Vue.prototype.$rail_ops.operations.deposit:
            this.title = 'Depósito';
            this.description = 'Inserir capital nas contas da Polarize.';
            break;
          case Vue.prototype.$rail_ops.operations.withdraw:
            this.title = 'Levantamento';
            this.description = 'Remover capital das contas da Polarize.';
            break;
          case Vue.prototype.$rail_ops.operations.bankroll_increment:
            if (this.userIsRailManager) {
              this.title = 'Validar pedido de depósito';
              this.description = 'Dar seguimento a um pedido de depósito de um jogador.';
            } else {
              this.title = 'Pedido de Depósito';
              this.description = 'Enviar um pedido de depósito à gestão.';
            }
            break;
          case Vue.prototype.$rail_ops.operations.bankroll_reduction:
            this.title = 'Pedido Redução de Banca';
            this.description = 'Enviar um pedido de redução de banca à gestão.';
            break;
          case Vue.prototype.$rail_ops.operations.transfer_to_self:
            this.title = 'Transferência entre contas';
            this.description = 'Transferir capital entre as minhas contas.';
            break;
          case Vue.prototype.$rail_ops.operations.swap:
            this.title = 'Transferência Swap';
            this.description = 'Pagar um swap a outro jogador.';
            break;
          case Vue.prototype.$rail_ops.operations.transfer_to_player:
            this.title = 'Transferência para jogador';
            this.description = 'Transferir capital para a conta de um jogador.';
            break;
          case Vue.prototype.$rail_ops.operations.reports:
            this.title = 'Problema reportado';
            this.description = 'Dar seguimento a um relato de problema no Rail.';
            break;
          case Vue.prototype.$rail_ops.operations.any:
            this.title = 'Registar Operação';
            this.description = 'Registar operação no Rail.';
            break;
          case Vue.prototype.$rail_ops.operations.makeup:
            this.title = this.userIsRailManager ? 'Alterar Makeup' : 'Registar Rake';
            this.description = 'Preenche o formulário para terminar o processo';
          default:
            break;
        }
      }
    },
  }
}