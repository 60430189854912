import Vue from 'vue';
import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth.vue';
import RailMixin from '@/mixins/Rail.vue';
import DataMixin from '@/mixins/Data.vue';
import EmptyStateNoData from '@/components/EmptyStateNoData';
import DatePicker from '@/components/DatePicker/DatePicker.vue';
import DialogDownloadCsv from '@/components/Rail/Dialog/DialogDownloadCsv/DialogDownloadCsv.vue';
import ButtonCreateOperation from '@/components/Rail/ButtonCreateOperation/ButtonCreateOperation.vue';
import UserCard from '@/components/UserCard/UserCard.vue';

export default {
  name: 'DataTableRailsRequests',
  
  components: {
    EmptyStateNoData,
    DialogDownloadCsv,
    DatePicker,
    ButtonCreateOperation,
    UserCard,
  },
  
  mixins: [
    AuthMixin,
    RailMixin,
    DataMixin,
  ],
  
  props: {
    title: { type: String, required: false, default: 'Operações', },
    borderColor: { type: String, required: false, default: 'primary' },
    showButtonSeeAll: { type: Boolean, required: false, default: false },
    showTabs: { type: Boolean, required: false, default: true },
    showCustomLoading: { type: Boolean, required: false, default: false },
    filters: { type: Object, required: false, default: () => { } },
    userId: { type: [Number, String], required: false, default: null },
    dateIndependent: { type: Boolean, required: false, default: false },
  },
  
  data() {
    return this.initialState();
  },
  
  created() {
    // retrieve reports in order to be able to redirect user to correct view if operation is flagged
    this.getReports();
  },

  computed: {
    ...mapGetters({
      requests: 'railRequests/requests',
      ongoingRequests: 'railRequests/ongoingRequests',
      finalizedRequests: 'railRequests/finalizedRequests',
      hasLoaded: 'railRequests/hasLoaded',
      hasLoadedOngoingRequests: 'railRequests/hasLoadedOngoingRequests',
      hasLoadedFinalizedRequests: 'railRequests/hasLoadedFinalizedRequests',
      totalOngoingRequests: 'railRequests/totalOngoingRequests',
      totalFinalizedRequests: 'railRequests/totalFinalizedRequests',
      totalRequests: 'railRequests/totalRequests',
      selectedRequest: 'railRequests/selectedRequest',
      selectedReport: 'railReports/selectedReport',
      reports: 'railReports/reports',
      userTrackerId: 'auth/userTrackerId',
      heroDatePickerValue: 'UI/heroDatePickerValue',
      themeDarkMode: 'UI/themeDarkMode',
      selectedTab: 'TabSlider/selectedTab',
      teamFilter: 'teams/teamFilter',
      groups: 'groups/groups',
    }),
    
    tableHeaders: function () {
      if (this.userIsRailManager)
        return this.headers.admin;
      else
        return this.headers.user;
    },

    // shows total Items based on current tab
    totalData: function () {
      let output;

      // Filter data based on current tab
      switch (this.tabs.current) {
        case 0:
          output = this.userIsRailManager
            ? this.ongoingRequests.filter(e => this.isPendingOnSide('admin', e)).length
            : this.ongoingRequests.filter(e => this.isPendingOnSide('user', e)).length;
        break;
        case 1:
          output = this.totalOngoingRequests;
          break;
        case 2:
          output = this.totalFinalizedRequests;
          break;
        default:
          output = this.totalRequests;
          break;
      };
      
      return output;
    },
    
    // Manages data shown in TableRequests tabs
    filteredData: function () {
      let output;

      // Filter data based on current tab
      switch (this.tabs.current) {
        case 0:
          output = this.userIsRailManager
            ? this.ongoingRequests.filter(e => this.isPendingOnSide('admin', e))
            : this.ongoingRequests.filter(e => this.isPendingOnSide('user', e));
          break;
        case 1:
          output = this.ongoingRequests;
          break;
        case 2:
          output = this.finalizedRequests;
          break;
        default:
          output = this.requests;
          break;
      };
      
      return output;
    },

    hasLoadedData: function () {
      let output;

      // Filter data based on current tab
      switch (this.tabs.current) {
        case 0:
        case 1:
          output = this.hasLoadedOngoingRequests;
          break;
        case 2:
          output = this.hasLoadedFinalizedRequests;
          break;
        default:
          output = this.hasLoaded;
          break;
      };
      
      return output;
    },

    informationString: function() {
      let output = '';

      switch (this.tabs.current) {
        case 0:
          output = 'Está a ver operações Pendentes - significando que é necessária ação da tua parte para lhes dar seguimento. Este separador não é afetado pelo filtro de datas.';
          break;
        case 1:
          output = 'Está a ver operações A Decorrer. Neste momento, não precisas de fazer nada para lhes dar seguimento.';
          break;
        case 2:
          output = 'Está a ver operações com estado Finalizado.';
          break;
        case 3:
          output = 'Está a ver operações com qualquer estado.';
          break;
        default:
          output = '';
          break;
      };

      return output;
    },

    emptyStateMessage: function() {
      let output = null;
        
      switch (this.tabs.current) {
        case 0:
          output = this.userIsRailManager
          ? 'Não existem operações pendentes do lado da Gestão'
          : 'Não tens operações pendentes';
          break;
        case 1:
          output = 'Não existem operações a decorrer';
          break;
        case 2:
          output = 'Não existem operações finalizadas';
          break;
        case 3:
          output = 'Não existem operações';
          break;
        default:
          break;
      }
      
      return output;
    },

    optionsComputed: {
      get: function () {
        return this.options;
     },
      set: function (newValue) {
        if (!this._.isEqual(this.options, newValue)) {
          this.options = newValue;
        }
     }
    },

    sortByComputed: function () {
      return this.tabs.current == 0 || this.tabs.current == 1
                    ? ['date', 'user', 'targetUser']
                    : [];
    },

    sortDescComputed: function () {
      return this.tabs.current == 0 || this.tabs.current == 1
                    ? [false, false, false]
                    : [];
    },

    // used in Submit() in order to know how many items will retrieve
    // if current tab is 0 (PENDENTES) it will get them all, else retrieve only 10
    itemsPerPageComputed: function () {
      let output;
      if (this.tabs.current == 0) {
        output = -1;
      } else {
        output = this.options.itemsPerPage;
      }
      return output;
    },
    
    operationSplitPhase1: function () {
      return Vue.prototype.table_requests_phases[Vue.prototype.$rail_ops.operations.split][0]['value'];
    }
  },

  watch: {
    // when component is created and retrieves heroDatePickerValues, it resets options object which triggers fetchData()
    heroDatePickerValue: function () {
        // resets options object
        this.options = {
          page: 1,
          itemsPerPage: this.tabs.current == 0 ? -1 : 10,
          sortBy: this.sortByComputed,
          sortDesc: this.sortDescComputed,
          groupBy: [],
          groupDesc: [],
          mustSort: false,
          multiSort: false
        }
    },

    teamFilter: function () {
      // resets options object
      this.options = {
        page: 1,
        itemsPerPage: this.tabs.current == 0 ? -1 : 10,
        sortBy: this.sortByComputed,
        sortDesc: this.sortDescComputed,
        groupBy: [],
        groupDesc: [],
        mustSort: false,
        multiSort: false
      }
  },

    // watches this.options object
    // triggers everytime this.options has changed
    options: {
      handler() {
        if (!this.fetchLoading) {
          this.fetchData();
        }
      },
      deep: true,
    },

    // watches this.tabs object
    // resets this.options object everytime tab has changed
    tabs: {
      handler() {
        // can switch between tabs and make multiple requests
        this.fetchLoading = false;

        // if userId is null, emits an event named change-tabs to be used in Requests View that then triggers options object through prop filters
        if (this._.isNull(this.userId)) {
          this.$emit('change-tabs', this.tabs.current);
        } else {
          // resets options object
          this.options = {
            page: 1,
            itemsPerPage: this.tabs.current == 0 ? -1 : 10,
            sortBy: this.sortByComputed,
            sortDesc: this.sortDescComputed,
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false
          };
        }
      },
      deep: true,
    },

    userId: {
      handler() {
        if (!this.fetchLoading) {
          this.fetchData();
        }
      },
      immediate: true,
    },

    // watches this.filters prop
    // triggers fetchData()
    filters: {
      handler() {
        // resets options object
        this.options = {
          page: 1,
          itemsPerPage: this.tabs.current == 0 ? -1 : 10,
          sortBy: this.sortByComputed,
          sortDesc: this.sortDescComputed,
          groupBy: [],
          groupDesc: [],
          mustSort: false,
          multiSort: false
        }
      },
      deep: true,
    },

    // watches select tab slider, when selected tab is equal to 0 or 1 (Overview - Visão Geral || Requests - Operações), it triggers fetchData
    selectedTab: {
      handler() {
        if (this.selectedTab == 0 || this.selectedTab == 1) {
          this.fetchData();
        }
      }
    },
  },
  
  methods: {
    initialState() {
      return {
        submitting: false,
        fetchLoading: false,
        date: null,
        tabs: {
          current: 0,
          items: ['Pendentes', 'A decorrer', 'Finalizados', 'Todos'],
        },
        headers: {
          admin: [
            { text: '', value: 'selected', sortable: false },
            { text: 'Data', value: 'date', width: '80px' },
            { text: 'Tipo de pedido', value: 'type', width: '100px' },
            { text: 'Jogador', value: 'user', sortable: true },
            { text: 'Grupo', value: 'group', sortable: false },
            { text: 'Equipa', value: 'team', sortable: false },
            { text: 'Sala (Nickname)', value: 'source', sortable: false, width: '130px' },
            { text: 'Carteira destino', value: 'destination', sortable: false, width: '130px' },
            { text: 'Montante', value: 'value', sortable: false },
            { text: '', value: 'profit_split', sortable: false },
            { text: 'Estado', value: 'status', sortable: false },
            { text: 'Ação', value: 'action', sortable: false },
          ],
          user: [
            { text: '', value: 'selected', sortable: false },
            { text: 'Data', value: 'date' },
            { text: 'Tipo de pedido', value: 'type', width: '200px' },
            { text: 'Sala (Nickname)', value: 'source', sortable: false },
            { text: 'Carteira destino', value: 'destination', sortable: false },
            { text: 'Valor', value: 'value', sortable: false },
            { text: 'Estado', value: 'status', sortable: false, width: '120px' },
            { text: 'Ação', value: 'action', sortable: false },
          ],
        },
        options: {
          page: 1,
          itemsPerPage: -1,
          sortBy: ['date', 'user', 'targetUser'],
          sortDesc: [false, false, false], 
          groupBy: [],
          groupDesc: [],
          mustSort: false,
          multiSort: false
        },
        dataTable: {
          search: null,
          footerProps: {
            'items-per-page-options': this.$itemsPerPageOptions
          }
        },
        showNoRequestsNotice: false,
        // Positions according to Vue.prototype.$rail_status.<STATUS>
        operationStatusColor: [
          '',
          'orange',
          'orange',
          'orange',
          'red',
          'green',
        ],
        dialogues: {
          download: {
              state: false,
              message: 'Exporta os dados da tabela para formato Excel.',
          },
      },
      }
    },

    openDialogDownloadCsv() {
      this.dialogues.download.state = true;
    },

    // trigered by v-text-field @input="search()"
    search() {
      setTimeout(() => {
          this.fetchData()
        }, 1000);
    },
    
    async fetchData() {

      this.fetchLoading = true;

      // Base payload
      let payload = {
        export: false,
        params: {
          page: this.options.page,
          itemsPerPage: this.itemsPerPageComputed,
        }
      };

      // Add user id param
      if (this.userId) {
        payload.params.sourceUser = this.userId;
        payload.params.targetUser = this.userId;
      }
 
      // if dateIndependent is true, it means payload doesn't use date params
      if (!this.dateIndependent && this.tabs.current != 0 && this.tabs.current != 1) {
        // Add date params
        if (!this._.isEmpty(this.heroDatePickerValue)) {
          payload.params.dateBegin = this.heroDatePickerValue[0];
          payload.params.dateEnd = this.heroDatePickerValue[1];
        }
      }

      // if team exists and isManagement, Add team parameter to payload.params
      if (this.teamFilter && this.userHasRailAccessDivisionDropdown) {
        payload.params.team = this.teamFilter;
      }

      // Add ongoing parameter if date independent is false
      payload.params.ongoing = this.getTabOnGoingStatus();
      
      // Add name parameter to payload.params
      if (!this._.isEmpty(this.dataTable.search)) {
        payload.params.name = this.dataTable.search;
      }

      // add filter by user
      if (this.filters && !this._.isNull(this.filters.user) && !this._.isUndefined(this.filters.user) && !this._.isEmpty(this.filters.user)) {
        payload.params.sourceUser = this.filters.user.id;
        payload.params.targetUser = this.filters.user.id;
      }

      // add filter by operation type if is not empty
      if (this.filters && this.filters.operation) {
        this.filters.operation.forEach((element, index) => {
          return payload.params['type[' + index + ']'] = element.value;
        })
      }

      // add filter createdByManagement 
      if (this.filters && this.filters.createdByManagement) {
        payload.params.createdByManagement = this.filters.createdByManagement;
      }

      if (this.filters && this.filters.status) {

        this.filters.status.forEach((element, index) => {
          return payload.params['status[' + index + ']'] = element.value;
        })

        // removes status from payload params if doesn't exists
        if (this._.isEmpty(payload.params.status)) {
          delete payload.params.status;
        }
      }

      if (this.filters && this.filters.rakeType) {
        payload.params['reason'] = this.filters.rakeType;
      }

      // Add sortBy parameter to payload
      if (!this._.isEmpty(this.options.sortBy)) {
        this.options.sortBy.forEach((element, index) => {
          payload.params['sortBy[' + index + ']'] = element;
        })
      }

      // Add sortDesc parameter to payload.params
      if (!this._.isEmpty(this.options.sortDesc)) {
        this.options.sortDesc.forEach((element, index) => {
          payload.params['sort[' + index + ']'] = element == false ? 'ASC' : 'DESC';;
        })
      }

      const actionToDispatch = this.tabs.current == 0
        ? 'railRequests/getOngoingRequests'
        : 'railRequests/getRequests';
      
      await this.$store.dispatch(actionToDispatch, payload);

      this.fetchLoading = false;
    },
    
    setActiveItem(item) {
      if (item) {
        this.$store.commit(
          'railRequests/setSelectedRequest',
          item
        );
      }
    },
            
    /**
     * Assess whether or not an item is pending on a side (admin / user)
     * @param {Object} item Table Row
     * @returns {Boolean} Whether or not request is pending on the admin side
     */
    isPendingOnSide(side, item) {
      let output = false;
      let phaseStatusString = item.phase + ',' + item.status;
      
      // If type-usecase pair doesnt exist, it's not pending on this side
      if (Vue.prototype.$table_requests_pending[side][item.type]) {
        output = Vue.prototype.$table_requests_pending[side][item.type].includes(phaseStatusString);
      }

      // Specific case UC 'SWAP' - Only pending on this side if I am the target user
      if (item.type == Vue.prototype.$rail_ops.operations.swap && item.targetUser.id != this.userTrackerId) {
        output = false;
      }
      
      return output;
    },
      
    /**
    * Status() that returns an array based on UC populated with phases
    * @param {*} item 
    */
    getAdminOperationPhases(item) {
      return Vue.prototype.table_requests_phases[item.type];
    },
      
    /**
    * Retrieve rail operation status and based on rail operation type we return status name
    * @param {*} item 
    */
    getUserOperationPhase(item) {
      let out = '';

      if (item && !this._.isUndefined(item.type)) {
        out = Vue.prototype.table_requests_phases[item.type].filter(e => e.value == item.phase)[0].name;
      }
        
      // Case rejected
      if (item.status == 4) {
        out = 'Rejeitado';
      }
      return out;
    },
      
    /**
    * Retrieve rail operation status and based on rail operation type then return statusColor
    * @param {*} item 
    */
    getOperationStatusColor(item) {
      let color = this.operationStatusColor[item.status];
      return color + ' white--text';
    },

    getOperationRequests(item) {
      let output = [];
      if (item && item.requests) {

        // retrieving only ID's from target wallets avoiding duplicates
        let walletsID = [...new Set(item.requests.map(element => element.wallet.id))];

        // for each id stored in walletsID, it finds the first wallet request with same ID and then populates output
        walletsID.forEach(id => {
          item.requests.find(request => {
            if (request.wallet.id == id) {
              return output.push(request);
            }
          })
        })
      }
      return output;
    },

    /**
     * @param {Object} item 
     * @returns {String} value
     */
    getAmount(item) {
      let output;

      if (Vue.prototype.$rail_status.finalized == item.status) {
        output = this.getAmountSentTooltip(item);
      } else {
        output = this.getAmountRequestedTooltip(item);
      }

      return output;
    },

    /**
     * 
     * @param {Object} item single operation
     * @returns 
     */
    handlePrecision(item) {
      let keyToUse = this.getOpTransfersRequestsKey(item);
      let secondKeyToUse = keyToUse == 'transfers' ? 'source' : 'nickname';
      
      // Gen arr with id's of currencies involved in op
      let currenciesInOp = item[keyToUse].map(e => e[secondKeyToUse].network.currency.id);
    
      // Confront currenciesInOp with configCurrencies
      let currenciesInConfig = Vue.prototype.$currencies.filter(e => currenciesInOp.includes(e.id));
      
      // Find currenciesInConfig with least precision
      let currInConfigPrecisions = this._.map(currenciesInConfig, 'precision');
      let output = this._.min(currInConfigPrecisions);
      
      if (! output) {
        output = Vue.prototype.$currency_default_precision;
      }
      
      return output;
    },

    getAmountRequestedTooltip(item) {
      let output;

      let fieldToBeSummed = this.opHasSingleCurrency(item) ? 'value' : 'EUR.value';
      output = this.getAmountRequest(item, fieldToBeSummed);
      return output;
    },

    getAmountSentTooltip(item) {
      let output;

      let fieldToBeSummed = this.opHasSingleCurrency(item) ? 'value' : 'EUR.value';
      output = this.getAmountSent(item, fieldToBeSummed);
      return output;
    },

    showTooltipAmountRequested(item) {
      let output = false;

      if (item) {
        switch (item.type) {
          case Vue.prototype.$rail_ops.operations.swap:
          case Vue.prototype.$rail_ops.operations.makeup:
          case Vue.prototype.$rail_ops.operations.deposit:
          case Vue.prototype.$rail_ops.operations.withdraw:
          case Vue.prototype.$rail_ops.operations.transfer_to_self:
            output = false;
            break;
          default:
            output = true;
            break;
        }
      }
      return output;
    },
          
    // when showButtonSeeAll prop is true, it shows a button, when clicked it changes tabs to Requests views
    seeAllRequests() {
      this.$router.push({ path: Vue.prototype.$tab_slider.tabs.requests }).catch(() => { });
    },

    /**
     * Number that represents an UC - see Vue.prototype.$rail_ops.operations
     * @param {Number} cardName 
     */
    createOperation(cardName) {
      // set current card
      this.$store.dispatch('TabSlider/setCurrentCard', cardName);
      
      // clear states
      this.$store.dispatch('railRequests/clearError');
      this.$store.commit('railRequests/setSelectedRequest', null);
      this.$store.commit('railTransfers/setSelected', null);
      this.$store.commit('users/setSelectedUser', null);

      // clear balance profit from UC - SPLIT
      this.$store.commit('railOverview/setCardBalanceProfitUSD', null);
      this.$store.commit('railOverview/setCardBalanceProfitEUR', null);

      // routing
      this.$store.commit('TabSlider/setSelectedTab', Vue.prototype.$tab_slider.tabs.action);
      this.$router.push({ path: Vue.prototype.$tab_slider.tabs.action }).catch(() => { });
    },
 
    triggerCopyToClipboard(content) {

      let contentToBeCopied = '';

      if (content)
        contentToBeCopied = content.target.network.id == Vue.prototype.$rail_GGPoker_network_id
          ? content.target.description
          : content.target.name;
      
      this.copyToClipboard(contentToBeCopied);
      
      // Show snackbar
      this.$store.dispatch('UI/showSnackbar', {
        message: "Nome da conta '" + contentToBeCopied + "' copiado com sucesso.",
        color: 'success'
      });
    },

    /**
     * Trigger everytime current tab has changed, set ongoing status in order to make API request
     * @returns String
     */
    getTabOnGoingStatus() {
      let ongoing;
      switch (this.tabs.current) {
        case 0:
        case 1:
          ongoing = '1';
          break;
        case 2:
          ongoing = '0';
          break;
        default:
          ongoing = null;
          break;
      }
      return ongoing;
    },

    /**
     * @param {Object} item 
     * @returns {String}
     */
    goToRailOperationIcon(item) {
      let output = 'mdi-launch';
      if (this.selectedRequest) {
        if (item.id == this.selectedRequest.id) {
          output = this.submitting ? 'mdi-loading mdi-spin' : output;
        }
      }
      return output;
    },

    /**
     * @param {Object} item 
     * @returns {String} value
     */
    getProfitShare(item) {
      let profitCalculation = this.userIsRailManager
        ? (1 - item.user.profitShare)
        : item.user.profitShare;
      
      return this.profitAmount(profitCalculation, this.getAmount(item));
    },

    async editDateRailOperation(item) {
      let payload = {
        id: item.id,
        body: {
          date: this.date
        }
      }

      // Dispatch to store
      let result = await this.$store.dispatch('railRequests/editDateRailOperation', payload);

      // On success
      if (result === true) {
        let operations = this.tabs.current == 0
          ? this.ongoingRequests
          : this.filteredData
        
        // update selected Request in TableRequests 
        Vue.set(operations, operations.findIndex(e => e.id == item.id), this.selectedRequest);
          
        // Show success snackbar
        this.$store.dispatch('UI/showSnackbar', {
            message: 'Data da Operação editada com sucesso',
            color: 'success'
        });
      } else {
        // Show success snackbar
        this.$store.dispatch('UI/showSnackbar', {
          message: 'Ocorreu um erro, por favor tente mais tarde',
          color: 'error'
        });
      }
    },

    openDateRailOperation(item) {
      this.setActiveItem(item);
      this.date = this.$moment(item.date.date).format('YYYY-MM-DD');
    },

    /**
     * @param {Array} item 
     * @returns {String} value
     */
    getUserTeam(item) {
      let output = 'Sem equipa';
      if (!this._.isEmpty(item)) {
        output = item.find(e => e.type == Vue.prototype.$teams_types.real);

        output = typeof output == 'object'
          ? output.name
          : 'Sem equipa';
      }
      return output;
    },

    /**
     * Clears some states before going to operation page
     * @param {Object} item 
     */
    async goToRailOperation(item) {
      // set ative item (green dot that appears 1st column)
      this.setActiveItem(item);
      
      // clear balance profit from UC - SPLIT
      this.$store.commit('railOverview/setCardBalanceProfitUSD', null);
      this.$store.commit('railOverview/setCardBalanceProfitEUR', null);

      // set hasFinished state to false
      this.$store.commit('TabSlider/setHasFinished', false);

      // set card based on selectedRequest type
      await this.$store.commit('TabSlider/setCard', item.type);

      this.$store.commit('TabSlider/setSelectedTab', null); 

      // force CardStepper to display 1 step
      await this.$store.commit('UI/setCardStepperStep', 1);
    },

    /**
     * Checks if operation is flagged, find report Id and redirect user to report single view
     * @param {Object} item 
     * @returns {String}
     */
    goToReport(item) {
      let url = Vue.prototype.$tab_slider.tabs.reports;
      if (item.flag) {
        let findReport = this.reports.find(element => element && element.railOperation && (element.railOperation.id == item.id));

        if (!this._.isUndefined(findReport)) {
          let reportId = findReport.id;
          url = url + reportId;
        }
        return url;
      }
    },

    // retrieve reports
    getReports() {
      let payload = {
        params: {
          page: 1,
          itemsPerPage : -1
        }
      }
      this.$store.dispatch('railReports/get', payload);
    },

    handleTransfers(item, type) {
      // item.type == makeup && item.reason.name == "Pagamento de makeup";
      let value =  item.type == Vue.prototype.$rail_ops.operations.makeup && item.reason.name == "Pagamento de Makeup"
          ? 'isNotInternalTransfer'
          : null;

      return type == 'source'
        ? this.getSourceNetworkTransfers(item, value)
        : this.getTargetWalletTransfers(item, value);
    }
  },
};