import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth.vue';
import DialogNoteDetails from '@/components/Dialog/DialogNoteDetails/DialogNoteDetails.vue';

export default {
    name: 'SearchLive',

    components: {
        DialogNoteDetails,
    },

    mixins: [
        AuthMixin,
    ],

    props: {
        rules: {
            type: Array,
            required: false,
            default: () => []
        },
        returnObject: {
            type: Boolean,
            required: false,
            default: false,
        },
        solo: {
            type: Boolean,
            required: false,
            default: true,
        },
        outlined: {
            type: Boolean,
            required: false,
            default: false,
        },
        hideDetails: {
            type: Boolean,
            required: false,
            default: true,
        },
        excludeGroups: {
            type: Array,
            required: false,
            default: () => []
        },
        dense: {
            type: Boolean,
            required: false,
            default: false,
        },
        clearable: {
            type: Boolean,
            required: false,
            default: false,
        },
        showLabel: {
            type: Boolean,
            required: false,
            default: true,
        },
    },

    data() {
        return this.initialState();
    },

    created() {
        if (this._.isEmpty(this.countries)) {
            this.$store.dispatch('countries/get');
        }
    },

    mounted() {
        // Clear error triggered naturally when element is loaded
        if (this.$refs.searchLiveDropdown) {
            this.$refs.searchLiveDropdown.resetValidation();
        }
    },

    computed: {
        ...mapGetters({
            searchItems: 'livePlayers/search',
            searchLoading: 'livePlayers/searchLoading',
            countries: 'countries/countries',
        }),

        // Synthetic v-model
        computedItems: {
            get: function () {
                return this.list;
            },
            set: function (newValue) {
                this.list = newValue;
            }
        },
    },

    watch: {
        search: function (newValue) {
            this.searchInput();
        },
        searchItems: function (newValue) {
            this.computedItems = this.transformApiResponse(newValue);
            this.reopenAutocompleteMenu();
        },
    },

    methods: {
        initialState() {
            return {
                list: [],
                search: '',
                select: null,
                options: {
                    page: 1,
                    itemsPerPage: -1,
                },
                typingTimeout: null,
                isMenuActive: false,
                dialogues: {
                    viewNote: false,
                },
            };
        },

        async fetchData() {
            
            if (this.search == '' || this._.isNull(this.search)|| this.search && this.search.length < 1) {
                return false;
            }

            // Base payload
            let payload = { q: this.search };

            // get list
            await this.$store.dispatch('livePlayers/search', payload);
        },

        searchInput() {
            // Limpa o temporizador anterior para evitar múltiplas execuções
            clearTimeout(this.typingTimeout);
            // Define um novo temporizador que chama 'fetchData' após 1 segundo de inatividade
            this.typingTimeout = setTimeout(() => {
                this.fetchData();
            }, 1000);
        },

        async openDialogViewNote(item) {
            if (item.group !== 'notes') return;
            // clear selected note
            this.$store.commit('notes/setSelectedNote', item.item);
            this.dialogues.viewNote = true;
            await this.$nextTick();
        },
    
        closeDialogViewNote() {
            this.$store.commit('notes/setSelectedNote', null);
            this.dialogues.viewNote = false;
        },

        transformApiResponse(response) {
                const items = [];
    
                // Players
                if (response && response.players && response.players.length > 0) {
                    items.push({ header: 'Jogadores' });
                    response.players.forEach(player => {
                        items.push({
                            name: player.name,
                            country: player.country,
                            group: 'players',
                            avatar: player.picture,
                            id: player.id,
                        });
                    });
                    items.push({ divider: true });
                }
    
                // Teams
                if (response && response.teams && response.teams.length > 0) {
                    items.push({ header: 'Equipas' });
                    response.teams.forEach(team => {
                        items.push({
                            name: team.name,
                            group: 'teams',
                            avatar: team.picture,
                            id: team.id,
                        });
                    });
                    items.push({ divider: true });
                }
    
                // Notes
                if (response && response.notes && response.notes.length > 0) {
                    items.push({ header: 'Notas' });
                    response.notes.forEach(note => {
                        items.push({
                            name: note.text,
                            group: 'notes',
                            avatar: note.player.picture,
                            id: note.id,
                            item: note,
                        });
                    });
                    items.push({ divider: true });
                }
    
                // Tourneys
                if (response && response.tourneys && response.tourneys.length > 0) {
                    items.push({ header: 'Torneios' });
                    response.tourneys.forEach(tourney => {
                        items.push({
                            name: tourney.name,
                            group: 'tourneys',
                            avatar: '', // Adicione o avatar se disponível
                            id: tourney.id,
                        });
                    });
                    items.push({ divider: true });
                }
    
                // Forçar reatividade criando um novo array
                this.list = items;
                return this.list;
            
        },

        updateSearchValue(event) {
            // Atualiza o valor de search manualmente para manter o valor digitado
            this.search = event.target.value;
        },

        reopenAutocompleteMenu() {
            // Fecha e reabre o menu para garantir que os itens sejam mostrados
            if (this.$refs.autocomplete) {
                this.$refs.autocomplete.isMenuActive = false; // Fecha o menu
                this.$nextTick(() => {
                    this.$refs.autocomplete.isMenuActive = true; // Reabre o menu
                });
            }
        },

        handlePlayerName(user) {
            let output = '';
        
            if (user && user.name) {
                // Divide o nome em palavras
                const names = user.name.split(' ');
                
                // Pega a primeira letra dos dois primeiros nomes
                if (names.length > 1) {
                    output = names[0][0] + names[1][0];
                } else if (names.length === 1) {
                    output = names[0][0];
                }
            }
        
            return output.toUpperCase(); // Converte para maiúsculas
        },

        getCodeFromCountry(name) {
            let output = 'unknown.svg';

            if (!this._.isEmpty(this.countries) && name) {
                let countryObject = this.countries.find(e => e.name == name);
                if (countryObject) {
                    output = countryObject.code + '.svg'
                }
            }

            return output;
        }
    },
};
