import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth';
import DataMixin from '@/mixins/Data';
import Snackbar from "@/components/Snackbar";
import Hero from "@/components/Hero/Hero.vue";

export default {
  name: 'AnomaliesUser',

  components:{
    Hero,
    Snackbar,
  },

  mixins: [
    AuthMixin,
    DataMixin,
  ],

  data() {
    return {
      dialogEdit: false,
      dialogDelete: false,
      editedIndex: -1,
      editedItem: {
        id_analysis: null,
        id_games: null,
        id_nicknames: null,
        id_users: null,
        id_groups: null,
        name_users: null,
        name_nicknames: null,
        name_groups: null,
        date_analysis: null,
        date: null,
        buyin_games: null,
        currency_games: null,
        name_games: null,
        name_networks: null,
        message_analysis: null,
        flag_analysis: null,
        id_rulesets: null,
      },
      defaultItem: {
        id_analysis: null,
        id_games: null,
        id_nicknames: null,
        id_users: null,
        id_groups: null,
        name_users: null,
        name_nicknames: null,
        name_groups: null,
        date_analysis: null,
        date: null,
        buyin_games: null,
        currency_games: null,
        name_games: null,
        name_networks: null,
        message_analysis: null,
        flag_analysis: null,
        id_rulesets: null,
      },

      dataFetchComplete: false,
      data: [],

      dataTable: {
        search: '',
        headers: [
          { text: 'Data', value: 'timestamp_analysis'},
          { text: 'Sala', value: 'name_networks', sortable: false },
          { text: 'Nickname', value: 'name_nicknames', sortable: false },
          { text: 'Torneio', value: 'name_games', sortable: false },
          { text: 'Buy-in', value: 'buyin_games' },
          { text: 'Mensagem', value: 'message_analysis', sortable: false },
          { text: 'Status', value: 'flag_analysis', sortable: false },
          { text: 'Ação', value: 'action', sortable: false },
        ],
        footerOptions: {
          'items-per-page-options': this.$itemsPerPageOptions,
        },
      },

      menus: {
        date: {
          open: false,
          value: null   // Set to current date by 'routeParameterHandler()'
        }
      },
    }
  },

  computed: {
    ...mapGetters({
      userAuthenticated: 'auth/userAuthenticated',
      userType: 'auth/userType',
      userId: 'auth/userId',
      userDisplayName: 'auth/userDisplayName',
      anomalies: 'anomalies/anomalies',
      loading: 'anomalies/loading',
    })
  },

  created() {
    this.initialize()
  },

  methods: {
    initialize() {
      this.routeParameterHandler();
      this.fetchData();
    },

    /**
     * Invoked on 'created()', autofills query parameters.
     * Invokes 'dateHandler()'.
     */
    routeParameterHandler() {
      if ( this.$route.params.date && this.validateDate(this.$route.params.date) ) {
        // Parameter 'date' set
        this.menus.date.value = this.$route.params.date;
      } else if (this.$route.params.iteration) {
        // Parameter 'iteration' set
        this.iteration = this.$route.params.iteration;
      } else {
        // No parameters set, define to current date
        this.menus.date.value = this.generateHtmlFormatDateString();
        this.dateHandler(this.menus.date.value); // Fetches data, updates URL
      }
    },

    /**
     * Invoked when date input changes, and by 'routeParameterHandler()'.
     * Invokes 'fetchData()'.
     */
    dateHandler(val = false) {
      if (val) {
        if (this.$refs.menuDate) {
          this.$refs.menuDate.save(val);
        }

        this.$router.push({ path: '/anomalies/date/' + this.menus.date.value + '/' });
        this.fetchData();
      }
    },

    /**
     * Accessing the store, fetching data.
     */
    async fetchData() {
      // Clear any previous data
      this.$store.dispatch('anomalies/clear');
      for (let key in this.filteredData)
        this.filteredData[key] = {};

      // Generate the fetch payload (date / iteration)
      let fetchPayload = {};
      if (this.$route.params.date) {
        fetchPayload = { date: this.menus.date.value };
      } else if (this.$route.params.iteration) {
        fetchPayload = { iteration: this.$route.params.iteration };
      }

      //let fetchPayload = null;

      // Get data
      let fetch = await this.$store.dispatch(
        'anomalies/get',
        fetchPayload
      );

      // Update iteration
      if (this.anomalies && this.anomalies.length)
        this.iteration = this.anomalies[0].iteration_analysis;
      
      // Update date if query is set through 'iteration' parameter
      if (this.menus.date.value === null && this.iteration && this.anomalies.length)
        this.menus.date.value = this.$moment(this.anomalies[0]['timestamp_analysis']).format('YYYY-MM-DD');

      // Return status
      return fetch;
    },

    flag(item) {
      this.$store.dispatch('anomalies/flag', item);

      // Show snackbar
      this.$store.dispatch('UI/showSnackbar', {
        message: 'Flag modificada com sucesso.',
        color: 'green'
      });
    },

    // Deprecated ?
    assignEditItem(item)
    {
      if (item != null) {
        this.editedIndex = this.data.indexOf(item);
        this.editedItem = Object.assign({}, item);
      } else {
        this.editedItem = this.defaultItem;
      }
    },

    // Deprecated ?
    close() {
      if (this.dialogEdit) this.dialogEdit = false;
      if (this.dialogDelete) this.dialogDelete = false;

      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      }, 300)
    }
  }
}