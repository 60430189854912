var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('DialogEditUserLevel',{attrs:{"is-open":_vm.dialogues.editUserLevel,"user":_vm.selectedUser,"direction":_vm.direction},on:{"close-dialog":function($event){_vm.dialogues.editUserLevel = false},"reload":function($event){return _vm.reloadUser()}}}),(! _vm._.isNull( _vm.userIsRailManager ? _vm.getUserGroupNumericAsAdmin(_vm.selectedUser) : _vm.getUserGroupNumeric() ))?_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-progress-circular',_vm._g(_vm._b({staticClass:"d-flex flex-column",attrs:{"rotate":-90,"size":100,"width":8,"value":_vm.userProgressLevelComputed,"loading":_vm.userProgressLevelLoading,"color":"success"}},'v-progress-circular',attrs,false),on),[_c('div',{staticClass:"d-flex flex-column align-center text-center"},[_c('span',{staticClass:"body-2 my-2"},[_vm._v(" "+_vm._s(_vm.userProgressGroupName)+" ")]),_c('div',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.userIsManager)?_c('v-btn',_vm._g({staticClass:"mt-n1",attrs:{"icon":"","x-small":"","disabled":_vm._.isNull(_vm.selectedUser.group.previous)},on:{"click":function($event){return _vm.editUserLevel('DOWN')}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-minus ")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Descer")])]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.userIsManager)?_c('v-btn',_vm._g({staticClass:"mt-n1",attrs:{"icon":"","x-small":"","disabled":_vm._.isNull(_vm.selectedUser.group.next)},on:{"click":function($event){return _vm.editUserLevel('UP')}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-plus ")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Subir")])])],1)])])]}}],null,false,1109294073)},[_c('span',[_vm._v(_vm._s(_vm.tooltipMessageAlert))])])],1):_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-progress-circular',_vm._g(_vm._b({attrs:{"rotate":-90,"size":100,"width":6,"value":0,"color":"error"}},'v-progress-circular',attrs,false),on),[_c('div',{staticClass:"d-flex flex-column align-center text-center"},[_c('span',{staticClass:"body-2 my-2"},[_vm._v(" Sem progresso ")]),_c('div',[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.userIsManager)?_c('v-btn',_vm._g({staticClass:"mt-n1",attrs:{"icon":"","x-small":"","disabled":_vm._.isNull(_vm.selectedUser.group.previous)},on:{"click":function($event){return _vm.editUserLevel('DOWN')}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-minus ")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Descer nível")])]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.userIsManager)?_c('v-btn',_vm._g({staticClass:"mt-n1",attrs:{"icon":"","x-small":"","disabled":_vm._.isNull(_vm.selectedUser.group.next)},on:{"click":function($event){return _vm.editUserLevel('UP')}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-plus ")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Subir nível")])])],1)])])]}}])},[_c('span',[_vm._v(_vm._s(_vm.tooltipMessageAlertNoData))])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }