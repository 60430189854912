import { reduce } from 'lodash';
import { mapGetters } from 'vuex';

export default {
	name: 'ProgressBar',

	components: {},

	mixins: [],

	computed: {
        ...mapGetters({
			selectedEvent: 'eventsCalendar/selectedEvent',
        }),

        progressLinearColor() {
            let percentage = this.getProgressbarValue();
			let output = 'green';
            
            if (percentage <= 50 || _.isNull(this.selectedEvent.capacity))
                output = 'green';
            
            if (percentage <= 75)
                output = 'yellow';

            if (percentage < 100)
                output = 'orange';

            if (percentage == 100)
                output = 'red';
            
            return output;
        },

		capacityPhrase() {
			const slotsSingularPlural = this.selectedEvent.rsvp_count == 1
										? 'lugar'
										: 'lugares';

			return _.isNull(this.selectedEvent.capacity)
				   ? `${this.selectedEvent.rsvp_count} ${slotsSingularPlural} ocupados`
				   : `${this.selectedEvent.rsvp_count} / ${this.selectedEvent.capacity} ${slotsSingularPlural} ocupado`;
		},
    },

	created() {},

	data() {
		return this.initialState();
	},
	
	methods: {
		initialState() {
			return {}
		},

        getProgressbarValue() {
			let output = 0;

			if ( ! this._.isEmpty(Object.keys(this.selectedEvent)) && this.selectedEvent.rsvp_count > 0) {
				output =  this.selectedEvent.rsvp_count / this.selectedEvent.capacity * 100;
			}

			return output;
		},

	}
}