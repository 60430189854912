import { mapGetters } from 'vuex';
import RailMixin from '@/mixins/Rail.vue';
import DataMixin from '@/mixins/Data.vue';
import NicknameDropdown from '@/components/Rail/NicknameDropdown/NicknameDropdown.vue';
import InputFinancial from '@/components/InputFinancial/InputFinancial.vue';
import ButtonCopyToClipboard from '@/components/Rail/ButtonCopyToClipboard/ButtonCopyToClipboard.vue';

export default {
    name: 'SelectWalletExpansionPanel',

    components: {
        NicknameDropdown,
        InputFinancial,
        ButtonCopyToClipboard,
    },

    props: {
        value: { type: Object, required: false, default: () => { } },
        currencies: { type: Array, required: false, default: () => [] },
        deletePanel: { type: Boolean, required: false, default: false },
        disabled: { type: Boolean, required: false, default: false },
    },

    mixins: [
        RailMixin,
        DataMixin,
    ],

    created() {
        this.initialize();
        this.emitChange();
    },

    computed: {
        ...mapGetters({
            selectedRequest: 'railRequests/selectedRequest'
        }),

        // if every field is completed we change color to green
        isFieldCompleted: function () {
            if (this.form.amount && this.form.source && this.form.target) {
                return 'green';
            }
        },

        // Synthetic v-model
        computedValue: {
            get: function () {
                return this.form;
            },
            set: function (newValue) {
                // Populate form with newValue
                this.form = newValue;
                this.$emit('input', newValue);
                this.emitChange();
            }
        },
    },

    watch: {
        value: function (newValue) {
            this.computedValue = newValue;
        },

        computedValue: {
            handler: function (newValue) {
                // Update internal properties - 'currency'
                if (newValue.target) {
                    this.currency.symbol = newValue.target.network.currency.symbol;
                    this.currency.code = newValue.target.network.currency.code;
                }
            },
            deep: true
        },
    },

    data() {
        return this.initialState();
    },

    methods: {
        initialState() {
            return {
                form: {
                    currency: null,
                    amount: null,
                    source: null,
                    target: null,
                },
                rules: {
                    amount: [
                        (v) => !!v || 'Por favor introduza o montante',
                        (v) => ! Number.isNaN(v) || 'Insira um valor numérico',
                    ],
                },
                currency: {
                    symbol: null,
                    code: null,
                }
            }
        },

        getCurrencyIcon() {
            if (this.computedValue.target) {
                switch (this.computedValue.target.network.currency.code) {
                    case 'USD':
                        return 'mdi-currency-usd';
                    case 'EUR':
                        return 'mdi-currency-eur';
                    case 'BTC':
                        return 'mdi-currency-btc';
                    default:
                        break;
                }
            }
        },

        initialize() {
            // Initial value passed through prop
            this.computedValue = this.value;
        },

        handleDeletePanel() {
            this.$emit('eventPanel', this.computedValue);
        },

        emitChange() {
            this.$emit('change', this.computedValue);
            this.$emit('input', this.computedValue);
        },
    }
}