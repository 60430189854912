<template>
  <component :is="componentToShow" />
</template>

<script>
import TransfersUser from './user/Transfers.vue';
import TransfersAdmin from './admin/Transfers.vue';
import AuthMixin from '@/mixins/Auth.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'Transfers',

  components: {
    TransfersUser,
    TransfersAdmin
  },

  mixins: [
    AuthMixin
  ],

  computed: {
    ...mapGetters({
      userRolesLoading: 'auth/userRolesLoading',
    }),

    componentToShow() {
      if (this.userRolesLoading) {
        return false;
      }
      
      let pageToShow = this.userIsRailManager
                       ? 'TransfersAdmin'
                       : 'TransfersUser';
                       
      return pageToShow;
    }
  }
}
</script>

<style scoped>
</style>