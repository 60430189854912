import Vue from 'vue';
import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth.vue';
import RailMixin from '@/mixins/Rail.vue';
import DialogReport from '@/components/Rail/Dialog/DialogReport/DialogReport.vue';
import DialogConfirmation from '@/components/Rail/Dialog/DialogConfirmation/DialogConfirmation.vue';
import CardStepperNotes from '@/components/CardStepper/CardStepperNotes/CardStepperNotes.vue';

export default {
    name: 'ButtonContainer',

    props: {
        showSubmitReportBtn: {
            type: Boolean,
            required: false,
            default: false,
        },
        showReportBtn: {
            type: Boolean,
            required: false,
            default: false,
        },
        showDeleteBtn: {
            type: Boolean,
            required: false,
            default: false,
        },
        showShareBtn: {
            type: Boolean,
            required: false,
            default: false,
        },
        showEditBtn: {
            type: Boolean,
            required: false,
            default: false,
        },
    },

    components: {
        DialogReport,
        DialogConfirmation,
        CardStepperNotes,
    },

    mixins: [
        AuthMixin,
        RailMixin,
    ],

    data() {
        return this.initializeState();
    },

    computed: {
        ...mapGetters({
            card: 'TabSlider/card',
            hasFinished: 'TabSlider/hasFinished',
            previousTab: 'TabSlider/previousTab',
            selectedRequest: 'railRequests/selectedRequest',
            selectedReport: 'railReports/selectedReport',
            selectedTransfer: 'railTransfers/selectedTransfer',
            selectedUser: 'users/selectedUser',
            userTrackerId: 'auth/userTrackerId',
            error: 'railRequests/error',
            humanError: 'railRequests/humanError',
            teamFilter: 'teams/teamFilter',
            heroDatePickerValue: 'UI/heroDatePickerValue',
        }),

        isRailOperationOver: function () {
            // isOver = rail operation or hasFinished state is over
            let isOver = this.hasFinished || (this.selectedRequest && !this.selectedRequest.ongoing) || (this.selectedTransfer && !this.selectedTransfer.ongoing);
            return isOver;
        },

        isRailOperationFlagged: function () {
            // isFlagged = rail operation reported, flag == 1
            let isFlagged = this.selectedRequest && this.selectedRequest.flag;
            return isFlagged;
        },

        isCreatedByManagement: function () {
            // createdByManagement = rail operation created by management
            let createdByManagement = this.selectedRequest && this.selectedRequest.createdByManagement;
            return createdByManagement;
        },

        cancelButton: function () {
            return true;
        },

        shareButton: function () {
            return this.showShareBtn;
        },

        deleteButton: function () {
            return this.handleDeleteButton();
        },

        editButton: function () {
            return this.handleEditButton();
        },

        reportButton: function () {
            return this.handleReportButton();
        },

        submitButton: function () {
            return this.handleSubmitButton();
        },

        rejectButton: function () {
            return this.handleRejectButton();
        },
    },

    methods: {
        initializeState() {
            return {
                dialogues: {
                    report: false,
                    reject: {
                        state: false,
                        title: 'Tem a certeza que pretende rejeitar esta operação?',
                        buttonName: 'Rejeitar',
                        buttonColor: "error"
                    },
                    delete: {
                        state: false,
                        title: 'Tem a certeza que pretende apagar esta operação?',
                        body: 'Os saldos das salas/carteiras serão revertidos.',
                        buttonName: 'Apagar',
                        buttonColor: "error"
                    },
                },
                submittingReject: false,
                submittingDelete: false,
                submittingConfirmResolve: false,
                form: {
                    observation: null,
                    fileName: [],
                    gyazoLink: null
                }
            }
        },

        /**
         * Validates if this.selectedRequest.phase is equal to phase
         * Validates if this.selectedRequest.status is equal to status
         * @param {*Number} phase 
         * @param {*Number} status 
         */
        phaseAndStatusValidator(phase, status) {
            let isValid = this.selectedRequest && (this.selectedRequest.phase == phase && this.selectedRequest.status == status);
            return isValid;
        },

        /** Reset current card
         *  When resetCard() is trigered, we retrieve the ref from current card and access resetForm()
         */
        resetCard() {
            if (this.card) {
                switch (this.card) {
                    case Vue.prototype.$rail_ops.operations.split:
                        return this.$refs.formRailSplit.resetForm();
                    case Vue.prototype.$rail_ops.operations.deposit:
                    case Vue.prototype.$rail_ops.operations.withdraw:
                        return this.$refs.formRailMovement.resetForm();
                    case Vue.prototype.$rail_ops.operations.transfer_to_self:
                        return this.$refs.formTransferToSelf.resetForm();
                    case Vue.prototype.$rail_ops.operations.swap:
                    case Vue.prototype.$rail_ops.operations.transfer_to_player:
                        return this.$refs.formTransferToPlayer.resetForm();
                    case Vue.prototype.$rail_ops.operations.bankroll_reduction:
                        return this.$refs.formBankrollReduction.resetForm();
                    case Vue.prototype.$rail_ops.operations.bankroll_increment:
                        return this.$refs.formBankrollIncrement.resetForm();
                    default:
                        break;
                }
            }
        },

        // Cancels current action
        cancelCard() {

            this.$router.go(-1);

            //disabled tab
            this.$store.commit('TabSlider/setDisableTab', true);

            // set setSelectedTransfer to NULL
            this.$store.commit('railTransfers/setSelected', null);

            // clear balance profit from UC - SPLIT
            this.$store.commit('railOverview/setCardBalanceProfitUSD', null);
            this.$store.commit('railOverview/setCardBalanceProfitEUR', null);
        },

        /**
         * Show Process, goes to respective tab view based on card type
         */
        goToTabView() {
            if (this.card) {
                if (this.isRailOperationFlagged) {

                    /** 
                     *  When isRailOperationFlagged is true, change to Reports Tab
                     */
                    return this.$store.commit('TabSlider/setSelectedTab', Vue.prototype.$tab_slider.tabs.reports);
                } else {
                    switch (this.card) {
                        case Vue.prototype.$rail_ops.operations.split:
                        case Vue.prototype.$rail_ops.operations.bankroll_reduction:
                        case Vue.prototype.$rail_ops.operations.bankroll_increment:
                        case Vue.prototype.$rail_ops.operations.any:
                        case Vue.prototype.$rail_ops.operations.makeup:
                            this.$store.commit('TabSlider/setSelectedTab', Vue.prototype.$tab_slider.tabs.requests);
                            this.$store.commit('TabSlider/setCard', null);
                            break;
                        case Vue.prototype.$rail_ops.operations.deposit:
                        case Vue.prototype.$rail_ops.operations.withdraw:
                        case Vue.prototype.$rail_ops.operations.transfer_to_self:
                        case Vue.prototype.$rail_ops.operations.swap:
                        case Vue.prototype.$rail_ops.operations.transfer_to_player:
                            this.$store.commit('TabSlider/setSelectedTab', Vue.prototype.$tab_slider.tabs.transfers);
                            this.$store.commit('TabSlider/setCard', null);
                            break;
                        default:
                            break;
                    }
                }
            }
        },

        openDialogReport() {
            this.dialogues.report = true;
        },

        // opens dialog DialogConfirmation with reject state
        openDialogConfirmationReject() {
            this.dialogues.reject.state = true;
        },

        // submits rejection
        async dialogConfirmReject() {

            let result;

            // Set DialogConfirmation to submitting state
            this.submittingReject = true;
            
            if (this.selectedRequest) {
                // Base payload
                let payload = {
                    id: this.selectedRequest.id,
                }

                // add body FormData
                payload.body = new FormData();

                // Add 'observation' field if necessary
                if (this.form.observation) {
                    payload.body.append('observation[description]', this.form.observation);
                }

                // Add 'screenshot' field if necessary
                if (!this._.isEmpty(this.form.fileName)) {
                    payload.body.append('screenshot', this.form.fileName[0]);
                }

                // Add 'observation[url]' field if necessary
                if (this.form.gyazoLink) {
                    payload.body.append('observation[url]', this.form.gyazoLink);
                }

                // Dispatch to store
                result = await this.$store.dispatch('railRequests/rejectRequest', payload);
            }

            // re-enables submit button;
            this.submittingReject = false;

            // On success
            if (result === true) {

                let payloadRefresh = {
                    params: {}
                  }
          
                  // if team exists and isManagement, Add team parameter to payload.params
                  if (this.teamFilter && this.userHasRailAccessDivisionDropdown) {
                    payloadRefresh.params.team = this.teamFilter;
                  }

                // Refresh Requests Transfers and Balances
                this.updateRequestsTransfersAndBalances(payloadRefresh);

                this.$store.dispatch('railSwaps/get', payloadRefresh);

                // Show success snackbar
                this.$store.dispatch('UI/showSnackbar', {
                    message: 'Operação rejeitada com sucesso.',
                    color: 'success'
                });
                // closes dialog
                this.closeDialogConfirmation();
                this.routing();
            }
        },

        // closes dialog DialogConfirmation with reject state
        closeDialogConfirmation() {
            this.dialogues.reject.state = false;
            this.clearError();
        },

        // opens dialog confirmation with delete state
        openDialogConfirmationDelete() {
            this.dialogues.delete.state = true;
        },

        // submits Delete
        async dialogConfirmDelete() {
            // Set DialogConfirmation to submitting state
            this.submittingDelete = true;
            let result = false;
            
            // Dispatch to store
            if (this.selectedRequest) {
                result = await this.$store.dispatch('railRequests/deleteRequest', this.selectedRequest.id);
            }

            // re-enables submit button;
            this.submittingDelete = false;

            // On success
            if (result === true) {
                let payloadRefresh = {
                    params: {}
                }
          
                // if team exists and isManagement, Add team parameter to payload.params
                if (this.teamFilter && this.userHasRailAccessDivisionDropdown) {
                    payloadRefresh.params.team = this.teamFilter;
                }

                // Refresh Requests Transfers and Balances
                this.updateRequestsTransfersAndBalances(payloadRefresh);

                // Show success snackbar
                this.$store.dispatch('UI/showSnackbar', {
                    message: 'Operação apagada com sucesso.',
                    color: 'success'
                });
                // closes dialog
                this.closeDialogConfirmationDelete();

                // go to previous page
                if (window.history && window.history.length > 2) {
                    window.history.go(-1);
                } else {
                    this.routing();
                }
            }
        },

        closeDialogConfirmationDelete() {
            this.dialogues.delete.state = false;
            this.clearError();
        },

        editOperation() {
            // set current card
            this.$store.dispatch('TabSlider/setCurrentCard', Vue.prototype.$rail_ops.operations.any);

            // clear states
            this.$store.dispatch('railRequests/clearError');

            // routing
            this.$store.commit('TabSlider/setSelectedTab', Vue.prototype.$tab_slider.tabs.action);
            this.$router.push({ path: Vue.prototype.$tab_slider.tabs.action }).catch(() => { });
        },
    
        async confirmResolveReport() {

            // Set submitting state
            this.submittingConfirmResolve = true;

            let payload = {
                id: this.selectedReport.id,
                report: {
                    type: this.selectedReport.type.id,
                    status: Vue.prototype.$rail_reports.status.resolved,
                    operation: this.selectedReport.railOperation.id
                }
            }

            let result = await this.$store.dispatch('railReports/finalize', payload);

            // Set submitting state
            this.submittingConfirmResolve = false;

            // On success
            if (result === true) {
                
                let payloadRefresh = {
                    params: {}
                }

                if (!this._.isEmpty(this.heroDatePickerValue)) {
                    payloadRefresh.params.dateBegin = this.heroDatePickerValue[0];
                    payloadRefresh.params.dateEnd = this.heroDatePickerValue[1];
                }
          
                // if team exists and isManagement, Add team parameter to payload.params
                if (this.teamFilter && this.userHasRailAccessDivisionDropdown) {
                    payloadRefresh.params.team = this.teamFilter;
                }

                // Refresh Requests Transfers and Balances
                this.updateRequestsTransfersAndBalances(payloadRefresh);

                // refresh reports
                this.$store.dispatch('railReports/get', payloadRefresh);
                this.$router.push({ path: Vue.prototype.$tab_slider.tabs.reports }).catch(() => { });
                // feedback on successs
                this.$store.dispatch('UI/showSnackbar', {
                    message: 'Report resolvido com sucesso.',
                    color: 'success'
                });
            }
        },

        clearError() {
            this.$store.dispatch('railRequests/clearError');
        },

        routing() {
            this.$store.commit('TabSlider/setSelectedTab', Vue.prototype.$tab_slider.tabs.requests);
            this.$router.push({ path: Vue.prototype.$tab_slider.tabs.requests }).catch(() => { });
        },

        // set every button to initial state
        resetButtonState() {
            Object.assign(this.$data.buttons, this.initializeState().buttons);
            if (this.$refs.buttonContainer) {
                this.$refs.buttonContainer.reset();
            }
        },

        triggerCopyToClipboard() {
            let snackBarMessage = this.selectedRequest
                ? "🔗 Link da operação copiado com sucesso."
                : "🔗 Link da operação não encontrado.";
            let snackBarColor = this.selectedRequest
                ? 'success'
                : 'error';
            
            if (this.selectedRequest) {
                let url = window.location.origin + Vue.prototype.$tab_slider.tabs.requests + this.selectedRequest.id
                this.copyToClipboard(url);
            }
            // Show snackbar
            this.$store.dispatch('UI/showSnackbar', {
                message: snackBarMessage,
                color: snackBarColor
            });
        },

        getInfoFromCardStepperNotes(payload) {
            if (payload) {
              this.form.gyazoLink = payload.gyazoLink;
              this.form.fileName = payload.filename;
            }
        },

        /**
         * handles edit button, if can or cannot edit an operation
         * @returns {Boolean} value
         */
        handleEditButton() {
            let output = false;
            let isOperationAlreadyRejected = this.selectedRequest && (this.selectedRequest.status == Vue.prototype.$rail_status.rejected);
            let isOperationTypeSplitStage1 = this.selectedRequest && (this.selectedRequest.phase == 1 && this.selectedRequest.status == Vue.prototype.$rail_status.pending) && this.selectedRequest.type == Vue.prototype.$rail_ops.operations.split;
            
            // if operation is already rejected there's no way to edit it
            // if is OP SPLIT stage 1
            // If showEditBtn prop is false
            if (isOperationAlreadyRejected || isOperationTypeSplitStage1 || this.showEditBtn == false) {
                return output; 
            }

            if (this.userIsRailManager && !this._.isNull(this.selectedRequest)) {
                switch (this.card) {
                    case Vue.prototype.$rail_ops.operations.deposit:
                    case Vue.prototype.$rail_ops.operations.withdraw:
                    case Vue.prototype.$rail_ops.operations.session: 
                    case Vue.prototype.$rail_ops.operations.makeup:
                    case Vue.prototype.$rail_ops.operations.reports:
                    case Vue.prototype.$rail_ops.operations.any:
                        output = false;
                    break;
                    default:
                        output = true;
                        break;
                }
            }

            return output;
        },

        /**
         * If showDeleteBtn is true and user is rail manager
         * @returns Boolean value
         */
        handleDeleteButton() {
            let output = false;

            if (this.showDeleteBtn && this.userIsRailManager) {
                output = true;
            }
 
            return output;
        },

        /**
         * If showReportBtn is true and has card, validates if report btn can be shown
         * @returns Boolean value
         */
        handleReportButton() {
            let output = false;

            let F1S1 = this.phaseAndStatusValidator(1, 1);
            let F1S3 = this.phaseAndStatusValidator(1, 3);
            let F2S1 = this.phaseAndStatusValidator(2, 1);
            let F2S2 = this.phaseAndStatusValidator(2, 2);
            let F2S3 = this.phaseAndStatusValidator(2, 3);
            let F3S3 = this.phaseAndStatusValidator(3, 3);

            if (this.showReportBtn && this.card) {
                switch(this.card) {
                    case Vue.prototype.$rail_ops.operations.split:
                        if (((this.userIsRailManager && this.selectedRequest && (this.isCreatedByManagement && F2S2) || this.userIsRailManager && F2S3)) || (!this.userIsRailManager && (F1S1 || F3S3))) {
                            output = true;
                        } else {
                            output = this.selectedRequest ? true : false;
                        }
                        break;
                    case Vue.prototype.$rail_ops.operations.bankroll_reduction:
                        if ((this.userIsRailManager && this.isCreatedByManagement && F1S3) || (!this.userIsRailManager && (F2S1 || F2S3))) {
                            output = true;
                        } else {
                            output = this.selectedRequest ? true : false;
                        }
                        break;
                    case Vue.prototype.$rail_ops.operations.bankroll_increment:
                        if (!this.userIsRailManager && this.selectedRequest && this.selectedRequest.phase == 1) {
                            output = true;
                        } else {
                            output = this.selectedRequest ? true : false;
                        }
                        break;
                    case Vue.prototype.$rail_ops.operations.deposit:
                    case Vue.prototype.$rail_ops.operations.withdraw:
                    case Vue.prototype.$rail_ops.operations.transfer_to_self:
                    case Vue.prototype.$rail_ops.operations.transfer_to_player:
                        output = this.selectedRequest ? true : false;
                        break;
                    case Vue.prototype.$rail_ops.operations.swap:
                        if (this.isRailOperationOver || this.isRailOperationFlagged) {
                            output = false
                        } else if (F1S1 && this.selectedRequest && this.selectedRequest.user.id != this.userTrackerId) {
                            output = this.selectedRequest ? true : false;
                        } else {
                            output = this.selectedRequest ? true : false;
                        }
                        break;
                    case Vue.prototype.$rail_ops.operations.reports:
                        output = false;
                        break;
                    case Vue.prototype.$rail_ops.operations.any:
                    case Vue.prototype.$rail_ops.operations.makeup:
                        output = this.selectedRequest ? true : false;
                        break;
                }
            }

            return output;
        },

        /**
         * Validates if reject btn can be shown based on bunch of validations
         * @returns Boolean value
         */
        handleRejectButton() {
            let output = false;

            let F1S1 = this.phaseAndStatusValidator(1, 1);
            let F2S1 = this.phaseAndStatusValidator(2, 1);

            if (this.isRailOperationOver || this.isRailOperationFlagged) { 
                return output;
            }

            if (this.card) {
                switch(this.card) {
                    case Vue.prototype.$rail_ops.operations.split:
                        // if isRailManager && !createdByManagement && F1S1 are true
                        if (this.userIsRailManager && this.selectedRequest && !this.isCreatedByManagement && F1S1) {
                            output = true;
                        }
                        break;
                    case Vue.prototype.$rail_ops.operations.bankroll_reduction:
                        // If isRailManager && !createdByManagement && F2S1 are true 
                        if (this.userIsRailManager && this.selectedRequest && !this.isCreatedByManagement && F2S1) {
                            output = true;
                        }
                        break;
                    case Vue.prototype.$rail_ops.operations.bankroll_increment:
                        // If isRailManager && !createdByManagement && F1S1 are true
                        if (this.userIsRailManager && this.selectedRequest && !this.isCreatedByManagement && F1S1) {
                            output = true;
                        }
                        break;
                    case Vue.prototype.$rail_ops.operations.deposit:
                    case Vue.prototype.$rail_ops.operations.withdraw:
                    case Vue.prototype.$rail_ops.operations.transfer_to_self:
                    case Vue.prototype.$rail_ops.operations.transfer_to_player:
                        output = false;
                        break;
                    case Vue.prototype.$rail_ops.operations.swap:
                        if (F1S1 && this.selectedRequest && this.selectedRequest.user.id != this.userTrackerId) {
                            output = true;
                        } else {
                            output = false;
                        }
                        break;
                    case Vue.prototype.$rail_ops.operations.reports:
                    case Vue.prototype.$rail_ops.operations.any:
                    case Vue.prototype.$rail_ops.operations.makeup:
                        output = false;
                        break;
                }
            }


            return output;
        },

        /**
         * Validates if admin can submit based on bunch of validations
         * @returns Boolean value
         */
        handleSubmitButton() {
            let output = false;
            if (this.showSubmitReportBtn && this.userIsRailManager && (this.selectedReport.status != Vue.prototype.$rail_reports.status.resolved)) {
                output = true;
            }

            return output;
        },
    }
}
