import { mapGetters } from 'vuex'
import AuthMixin from '@/mixins/Auth.vue';

export default {
    name: 'FormCalendarEvent',
    
    components: {},

	computed: {
        ...mapGetters({
			selectedEvent: 'eventsCalendar/selectedEvent',
            selectedCategory: 'eventsCategoriesCalendar/data',
        }),
    },

	created() {
		this.init();
	},

	mounted() {
	},

	data() {
		return this.initialState();
	},
	
	methods: {
		initialState() {
			return {
				formUrl: '',
			}
		},
        init() {
            this.fetchData();
            
        },
        async fetchData() {
            // get category
            await this.$store.dispatch('eventsCategoriesCalendar/getById', this.selectedEvent.ref_category);
            this.setForm();
        },
        setForm() {
            if(AuthMixin.methods.userIsCalendarManager) {
                this.formUrl = `${this.selectedCategory.asana_attendance_form}&embed=true`;
            } else {
                this.formUrl = `${this.selectedCategory.asana_satisfaction_form}&embed=true`;
            }
        },
	}

}