import { mapGetters } from 'vuex'
import AuthMixin from '@/mixins/Auth'
import Loading from '@/views/Loading.vue'
import DialogEventForm from '@/components/Calendar/DialogEventForm/DialogEventForm.vue'
import CardCalendarEventInfo from '@/components/Calendar/CardCalendarEventInfo/CardCalendarEventInfo.vue'
import ButtonCalendarGotoForm from '@/components/Calendar/ButtonCalendarGotoForm/ButtonCalendarGotoForm.vue'
import ButtonCalendarJoinMeeting from '@/components/Calendar/ButtonCalendarJoinMeeting/ButtonCalendarJoinMeeting.vue'
import ButtonCalendarAddtoGoogle from '@/components/Calendar/ButtonCalendarAddtoGoogle/ButtonCalendarAddtoGoogle.vue'
import ButtonCalendarRSVP from '@/components/Calendar/ButtonCalendarRSVP/ButtonCalendarRSVP.vue'
import ListCalendarEventParticipants from '@/components/Calendar/ListCalendarEventParticipants/ListCalendarEventParticipants.vue'
import Snackbar from "@/components/Snackbar";

export default {
    name: 'CalendarSingle',

    components: {
        Loading,
        CardCalendarEventInfo,
        ListCalendarEventParticipants,
        ButtonCalendarGotoForm,
        ButtonCalendarJoinMeeting,
        ButtonCalendarAddtoGoogle,
        ButtonCalendarRSVP,
        DialogEventForm,
        Snackbar,
    },

    computed: {
        ...mapGetters({
            selectedEvent: 'eventsCalendar/selectedEvent',
            loading: 'eventsCalendar/loading',
        }),

        eventNotFound() {
            return ! this.loading && _.isEmpty(Object.keys(this.selectedEvent));
        },
    },

    created() {
        this.init();
    },

    mixins: [
        AuthMixin,
    ],

    data() {
        return this.initialState();
    },

    methods: {
        init() {
            // set event to-do change by router id
            this.$store.dispatch('eventsCalendar/getById', this.$route.params.id);
        },

        initialState() {
            return {
                dialogs: {
                    eventForm: false,
                },
                breadcrumbsItems: [
                    {text: 'Início', disabled: false, href: '/'},
                    {text: 'Calendário', disabled: false, href: '/calendar'},
                    {text: 'Nome Evento', disabled: true, href: '/'},
                ]
            }   
        },

        openDialog() {
            this.dialogs.eventForm = true;
        },
        
        closeDialog() {
            this.dialogs.eventForm = false;
        },
    }
}