import Vue from 'vue';
import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth';
import RailMixin from '@/mixins/Rail';
import CardStepper from '@/components/CardStepper/CardStepper.vue';
import CardStepperNotes from '@/components/CardStepper/CardStepperNotes/CardStepperNotes.vue';
import NicknameDropdown from '@/components/Rail/NicknameDropdown/NicknameDropdown.vue';
import AlertError from '@/components/Alerts/AlertError/AlertError.vue';
import DatePicker from '@/components/DatePicker/DatePicker.vue';
import UserDropdown from '@/components/Rail/UserDropdown/UserDropdown.vue';
import InputFinancial from '@/components/InputFinancial/InputFinancial.vue';
import DialogUserNotes from '@/components/Rail/Dialog/DialogUserNotes/DialogUserNotes.vue';
import AlertUserNote from '@/components/Rail/AlertUserNote/AlertUserNote.vue';
import DialogCreateNickname from '@/components/Dialog/DialogCreateNickname/DialogCreateNickname.vue';

export default {
  name: 'CardRegisterRake',
  
  components: {
    CardStepper,
    CardStepperNotes,
    NicknameDropdown,
    AlertError,
    DatePicker,
    UserDropdown,
    InputFinancial,
    AlertUserNote,
    DialogUserNotes,
    DialogCreateNickname,
  },

  mixins: [
    AuthMixin,
    RailMixin,
  ],

  computed: {
    ...mapGetters({
      error: 'railRequests/error',
      humanError: 'railRequests/humanError',
      themeDarkMode: 'UI/themeDarkMode',
      userTrackerId: 'auth/userTrackerId',
      nicknamesByUserID: 'nicknames/nicknamesByUserID',
      nicknamesAllMine: 'nicknames/nicknamesAllMine',
      hasLoadedNicknamesAllMine: 'nicknames/hasLoadedNicknamesAllMine',
      hasLoadedNicknamesByUserID: 'nicknames/hasLoadedNicknamesByUserID',
      selectedUser: 'users/selectedUser',
      dataByUserId: 'railStatistics/dataByUserId',
      userNotes: 'users/userNotes',
      teamFilter: 'teams/teamFilter',
      selectedRequest: 'railRequests/selectedRequest',
    }),

    tableHeaders: function () {
      return this.userIsRailManager && this.form.autoReduce
        ? this.form.tableHeaders.admin
        : this.form.tableHeaders.user;
    },

    tableData: function () {
      let output = [];
      let autoReduce = this.userIsRailManager ? this.form.autoReduce : false;
      
      if (this.form.rakeType == this.form.rakeTypes[0].value) {
        autoReduce = false;
      }

      let userTransfer = {'target': Vue.prototype.$external_wallet, 'amount': this.form.amount,'autoReduce': autoReduce };

      if (this.userIsRailManager) {
        if (autoReduce == false) {
          output.push(userTransfer);
        } else {
          if(this.form.reason && this.form.reason.text == 'Pagamento Makeup'){
            output.push(
              { 'source': Vue.prototype.$external_wallet, 'target': Vue.prototype.$external_wallet, 'amount': this.form.amount, 'autoReduce': false },
            );
          } else {
            output.push(
              { 'source': Vue.prototype.$external_wallet, 'target': Vue.prototype.$external_wallet, 'amount': this.form.amount, 'autoReduce': false },
              { 'source': Vue.prototype.$external_wallet, 'target': Vue.prototype.$external_wallet, 'amount': this.form.amount, 'autoReduce': this.form.autoReduce },
            );
          }
        }
      } else {
        output.push(userTransfer);
      }
      return output;
    },

    // Removes Rake Nicknames and is used in NicknameDropdown component
    excludeRakeNicknames: function () {
      let output = [];
      let arrayMap;
      if (this.userIsRailManager && !this._.isEmpty(this.nicknamesByUserID)) {
        arrayMap = this.nicknamesByUserID.map(e => e.network.id);
      } else if (!this.userIsRailManager && !this._.isEmpty(this.nicknamesAllMine)) {
        arrayMap = this.nicknamesAllMine.map(e => e.network.id);
      }
      if (!this._.isEmpty(arrayMap)) {
        output = this._.pull(arrayMap, Vue.prototype.$rail_RakeEUR_network_id, Vue.prototype.$rail_RakeUSD_network_id);
      }
      return output;
    },

    cardTitlesComputed: function () {
      let title = this.userIsRailManager ? 'Alterar Makeup' : 'Registar Rake';

      this.cardTitles = [
        title,
        'Resumo'
      ]

      return this.cardTitles;
    }
  },

  watch: {
    // makes API call everytime form.user has changed - used to populate TableBalances
    'form.user': async function () {
      if(this.form.reason && this.form.reason.text == 'Pagamento Makeup' && this.form.target){
        this.validateAmount(this.form.target);
      }

      if (this.userIsRailManager && !this._.isEmpty(this.form.user) && !this._.isNull(this.form.user) && !this._.isUndefined(this.form.user)) {
        this.$store.dispatch('users/getUser', this.form.user.id);

        let payload = {
          user: this.form.user.id,
          aliasParameter: '&aliasChildOf=null'
        }
        
        await this.$store.dispatch('nicknames/getAllPlayer', payload);
        this.fetchUserNotes(this.form.user.id);
        this.hasRakeNicknames();
        // clear currency
        this.form.currency = null;
      } else {
        this.$store.commit('users/setUserNotes', []);
        this.$store.commit('users/setSelectedUser', null);
      }
    },

    'form.reason': function (newVal) {
      if(newVal && newVal.text == 'Pagamento Makeup' && this.form.target && this.form.user){
        this.validateAmount(this.form.target);
      }
    },

    
    'form.target': function (){
      if(this.form.reason && this.form.reason.text == 'Pagamento Makeup'  && this.form.target && this.form.user){
        this.validateAmount(this.form.target);
      }
    },

    // resets rakeType input
    'form.direction': function () {
      this.form.rakeType = null;
    },
    'form.currency': function (currency) {
      if(currency && currency.code){
        this.hasRakeNicknameByCurrency(currency.code)
      }
    }
  },

  mounted() {
    // Clear error in <input-financial>, triggered naturally when element is loaded
    if (this.$refs.InputFinancialRef) {
      this.$refs.InputFinancialRef.resetValidation();
    }
  },

  data() {
    return this.initialState();
  },

  created() {
    if (!this.userIsRailManager) {
      // pre populate fields when user view
      this.form.reason =  { reasonDirection: Vue.prototype.$direction_rail_operations.decrease, text: 'Rake' };
      this.form.direction = Vue.prototype.$direction_rail_operations.decrease;
    }
  },

  methods: {
    getUserNetworksId() {
      let userNetworksIds;
      // if is admin and nicknamesByUserId isn't empty
      if (this.userIsRailManager && !this._.isEmpty(this.nicknamesByUserID)) {
        userNetworksIds = this.nicknamesByUserID.map(e => e.network.id);
      }
      // if is user and nicknamesAllMine isn't empty
      if (!this.userIsRailManager && !this._.isEmpty(this.nicknamesAllMine)) {
        userNetworksIds = this.nicknamesAllMine.map(e => e.network.id);
      }

      return userNetworksIds;
    },

  validateAmount(target){
      if(target && target.network && target.network.currency){
      const currency = target.network.currency;
      if(currency.code == 'USD' || currency.code == 'EUR'){
         this.form.amount = this.dataByUserId.PROFIT.value[currency.code].value;
        }else{
          this.form.amount = null;
        }
      }
    },

    hasRakeNicknames() {
      let userNetworksIds = this.getUserNetworksId();

      // If user doesn't have any rake network we show the general error
      if (userNetworksIds && !userNetworksIds.includes(this.$rail_RakeUSD_network_id) && !userNetworksIds.includes(this.$rail_RakeEUR_network_id)) {
        this.alertRakeNicknames.show = true;
        this.alertRakeNicknames.message = 'O jogador selecionado não tem nicknames do tipo Rake. ';
      } else {
        this.alertRakeNicknames.show = false;
      }
    },
    hasRakeNicknameByCurrency(currency) {
      let userNetworksIds = this.getUserNetworksId();
      this.alertRakeNicknames.show = false;
      let message = this.userIsRailManager
                    ? `O jogador selecionado não tem nicknames do tipo Rake ${currency}. `
                    : `Não tens nicknames do tipo Rake ${currency}. `


      switch (currency) {
        case 'EUR':
            if(userNetworksIds && !userNetworksIds.includes(this.$rail_RakeEUR_network_id)) {
              this.alertRakeNicknames.show = true;
              this.alertRakeNicknames.message = message
            } else {
              this.alertRakeNicknames.show = false;
            }
          break;
        case 'USD': 
          if(userNetworksIds && !userNetworksIds.includes(this.$rail_RakeUSD_network_id)) {
            this.alertRakeNicknames.show = true;
            this.alertRakeNicknames.message = message
          } else {
            this.alertRakeNicknames.show = false;
          }
          break;
        default:
          break;
      }

    },
    /**
     * Validates a specific form step.
     * Assumes form steps are named 'formStep_x' where x is a Int 0 - maxStep-1.
     * 
     * @param {Int} step Identifier of the form step to validate.
     * @returns {Void}
     */
    validateStep(step) {
      const formStepRef = 'formStep_' + (step);
      if (this.$refs[formStepRef]) {
        // Update formValidation[step] in a way the DOM will be reactive
        Vue.set(
          this.formValidations,
          step - 1,
          this.$refs[formStepRef].validate()
        );
        if (this.$refs && this.$refs.InputFinancialRef && this._.isNull(this.form.amount)) {
          this.$refs.InputFinancialRef.resetValidation();
        }

        if (this.$refs) {
          if (this.$refs.formReasonRef) {
            this.$refs.formReasonRef.resetValidation();
          }
          if (this.$refs.formRakeTypeRef) {
            this.$refs.formRakeTypeRef.resetValidation();
          }
          if (this.$refs.formCurrencyRef) {
            this.$refs.formCurrencyRef.resetValidation();
          }
        }

        // clean errors if exists
        if (this.error) {
          this.$store.dispatch('railRequests/clearError');
        }
      }
    },

    initialState() {
      return {
        cardTitles: [
          'Alterar Makeup ',
          'Resumo'
        ],
        formValidations: [
          false,
          true, 
        ],
        messageDateAlert: 'Data definida para o dia anterior. Altera-a se pretendes registar Rake já no dia de hoje.',
        dialogues: {
          userNotes: false,
          createNickname: false,
        },
        form: {
          date: this.$moment().format('YYYY-MM-DD'),
          direction: null,
          directionTypes: [
            { value: Vue.prototype.$direction_rail_operations.increase, text: 'Aumentar' },
            { value: Vue.prototype.$direction_rail_operations.decrease, text: 'Diminuir' }
          ],
          reason: null,
          reasonTypes: [
            { reasonDirection: Vue.prototype.$direction_rail_operations.increase, text: 'Aula' },
            { reasonDirection: Vue.prototype.$direction_rail_operations.increase, text: 'Anomalia' },
            { reasonDirection: Vue.prototype.$direction_rail_operations.increase, text: 'Compra Software' },
            { reasonDirection: Vue.prototype.$direction_rail_operations.increase, text: 'Split Adiantado' },
            { reasonDirection: Vue.prototype.$direction_rail_operations.decrease, text: 'Pagamento Makeup' },
            { reasonDirection: Vue.prototype.$direction_rail_operations.decrease, text: 'Rake' },
          ],
          user: null,
          source: null,
          target: null,
          amount: null,
          autoReduce: true,
          observation: null,
          gyazoLink: null,
          currency: null,
          currencies: [
            { code: 'EUR', name: 'Euro', symbol: '€' },
            { code: 'USD', name: 'Dollar', symbol: '$' },
          ],
          rakeType: null,
          rakeTypes: [
            { value: 1, text: 'Registar em nome do jogador' },
            { value: 2, text: 'Gestão paga a jogador' }
          ],
          fileName: [],
          tableHeaders: {
            admin: [
              { text: 'Emissor', value: 'source', sortable: false },
              { text: 'Destinatário', value: 'target', sortable: false },
              { text: 'Montante', value: 'amount', sortable: false },
              { text: '', value: 'autoReduce', sortable: false },
            ],
            user: [
              { text: 'Para', value: 'target', sortable: false },
              { text: 'Montante', value: 'amount', sortable: false },
              { text: '', value: 'autoReduce', sortable: false },
            ],
          },
        },
        rules: {
          user: [
            v => !!v || 'Por favor escolha um jogador'
          ],
          nicknameDropdown: [
            (v) => !!v || 'Por favor introduza uma sala'
          ],
          directionDropdown: [
            (v) => !!v || 'Por favor introduza uma direção'
          ],
          reasonDropdown: [
            (v) => !!v || 'Por favor introduza uma razão'
          ],
          rakeTypeDropdown: [
            (v) => !!v || 'Por favor introduza uma tipologia'
          ],
          amount: [
            (v) => !!v || 'Por favor introduza o montante',
            (v) => !Number.isNaN(v) || 'Insira um valor numérico',
          ],
          currency: [
            v => !!v || 'Por favor escolha uma moeda'
          ],
        },
        alertRakeNicknames: {
          show: false,
          message: ''
        },
      }
    },

    /**
     * Retrieve fields from CardStepperNotes component
     */
    getInfoFromCardStepperNotes(payload) {
      this.form.gyazoLink = payload.gyazoLink;
      this.form.fileName = payload.filename;
    },

    getSymbol(){
      if(this.form.reason && this.form.reason.text == 'Pagamento Makeup'){
        return this.form.target && this.form.target.network && this.form.target.network.currency ? this.form.target.network.currency.symbol : '';
      }
      return this.form.currency ? this.form.currency.symbol : '';
    },

    isTransferAutoReducedColorButton(item) {
      return item.autoReduce
          ? 'warning'
          : 'success';
    },

    isTransferAutoReducedIcon(item) {       
      return item.autoReduce
          ? 'mdi-arrow-left'
          : 'mdi-arrow-right';
    },

    isTransferAutoReducedIconTooltip(item) {
      let amountString = this.userIsRailManager
        ? 'Montante enviado'
        : 'Montante Recebido';
      
        return item.autoReduce
            ? 'Montante auto reduzido'
            : amountString;
    },


    async submit() {
      // Validate
      if (!this.$refs.formRegisterRake.validate()) {
        return false;
      }

      // Toggle submit button
      this.$refs.CardStepper.toggleSubmitting();

      // define userId based on role
      let userId = this.userIsRailManager 
        ? this.form.user.id
        : this.userTrackerId

      /**
       * Formulate payload
       */
      let payload = new FormData();
      payload.append('date', this.form.date);
      payload.append('user', userId);
      payload.append('amount', this.form.amount);
      payload.append('reason', this.form.reason.text);
      payload.append('direction', this.form.direction);
      if(this.form.currency && this.form.currency.code){
        payload.append('currency', this.form.currency.code);
      }
      payload.append('source', this.form.source ? this.form.source.id : null);
      payload.append('target', this.form.target ? this.form.target.id : null);

      // Gestão paga a jogador
      if (this.form.rakeType == this.form.rakeTypes[1].value) {
        payload.append('autoReduce', this.form.autoReduce);
      } else {
        payload.append('autoReduce', false);
      }

      // Add 'observation' field if necessary
      if (this.form.observation) {
        payload.append('observation[description]', this.form.observation);
      }

      // Add 'screenshot' field if necessary
      if (!this._.isEmpty(this.form.fileName)) {
        payload.append('screenshot', this.form.fileName[0]);
      }

      // Add 'observation[url]' field if necessary
      if (this.form.gyazoLink) {
        payload.append('observation[url]', this.form.gyazoLink);
      }

      // Dispatch to store
      let result = await this.$store.dispatch('railRequests/rakeCreate', payload);

			// Re-enable btn
      if (this.$refs.CardStepper) {
        this.$refs.CardStepper.toggleSubmitting();
        }

      // On success
      if (result === true) {

        let payloadRefresh = {
          params: {}
        }

        // if team exists and isManagement, Add team parameter to payload.params
        if (this.teamFilter && this.userHasRailAccessDivisionDropdown) {
          payloadRefresh.params.team = this.teamFilter;
        }

        // Refresh Requests Transfers and Balances
        this.updateRequestsTransfersAndBalances(payloadRefresh);

        if (this.userIsRailManager) {
          this.updateStatisticsByUserId(this.form.user.id);
        }
        
        // Show success snackbar
				this.$store.dispatch('UI/showSnackbar', {
            message: 'Registo de rake criado com sucesso.',
            color: 'success'
        });

        let id = this.selectedRequest.id;
        let url = Vue.prototype.$tab_slider.tabs.requests + id;

        // Change hasFinished state in order to change component
        this.$store.dispatch('TabSlider/setHasFinished', true);

        // change to operation single view
        this.$router.push({ path: url });
      }
    },

    resetForm() {
      Object.assign(this.$data, this.initialState());
      this.$refs.formRegisterRake.reset();
    },

    /**
     * Pre select direction field input based on form.reason
     * param {Object} from form.reason select input
     */
    setDirection(e) {
      if (e) {
        switch (e.reasonDirection) {
          case Vue.prototype.$direction_rail_operations.increase:
            this.form.direction = Vue.prototype.$direction_rail_operations.increase;
            break;
          case Vue.prototype.$direction_rail_operations.decrease:
            this.form.direction = Vue.prototype.$direction_rail_operations.decrease;
            break;
          default:
            break;
        }
      }
    },

    setCurrency(e) {
      let output = null;
      if (e) {
        switch (e) {
          case 'USD':
            output = 'mdi-currency-usd';
            break;
          case 'EUR':
            output = 'mdi-currency-eur';
            break;
          default:
            break;
        }
      }
      return output;
    },

    openDialogUserNotes() {
      this.dialogues.userNotes = true;
    },

    dialogCreateNickname() {
      this.dialogues.createNickname = true;
    },

    fetchUserNotes(id) {
      if (id) {
        let payload = {
          id: id,
          populateUserNotes: true,
        }
        this.$store.dispatch('users/getUserNotes', payload);
      }
    }
  }
}