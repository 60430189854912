import Vue from "vue";
import { mapGetters } from "vuex";

export default {
    name: "dialog-reject",
    data() {
        return this.initialState();
    },
    props: {
        dialogOpen: { type: Boolean, required: true, default: false },
        usernameId: {
            type: Number,
            required: false,
            default: null,
        },
        nameVideo: {
            type: String,
            required: false,
            default: null,
        },
    },
    computed: {
        ...mapGetters({
            selectedVideo: "videoRequests/selectedVideo",
            users: "users_v1/users",
        }),
    },
    methods: {
        initialState() {
            return {
                name_video: this.nameVideo,
                userId: this.usernameId,
                expiry: 0,
            };
        },
        reject() {
            // Build payload
            let payload = {
                id: this.selectedVideo.id_video_requests,
                status: Vue.prototype.$video_requests.status['rejected']
            };
            // Build result
            let result = this.$store.dispatch(
                "videoRequests/updateRequestVideo",
                payload
            );

            this.$store.dispatch("UI/showSnackbar", {
                message: "O pedido de video foi marcado como rejeitado",
                color: "success",
            });

            // Close dialog and return
            this.closeDialog();
            return result;
        },
        closeDialog() {
            this.$emit("closeDialog");
        },
        getUserDisplayNameById(id) {
            if (this.users) {
                let user = this.users.filter((e) => e.id_users == id);
                return user[0]["display_name_users"];
            }
        },
        populateFields() {
            this.name_video = this.selectedVideo.wp_post_title_video_requests;
            this.userId = this.selectedVideo.ref_users_video_requests;
        },
    },
};
