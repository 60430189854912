var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-select',{attrs:{"items":_vm.expenseTypesComputed,"loading":_vm.loadingExpenseTypes,"disabled":_vm.loadingExpenseTypes,"item-text":"name","item-value":"id","label":_vm.label,"multiple":_vm.multiple,"hide-details":_vm.hideDetails,"outlined":_vm.outlined,"clearable":_vm.clearable,"dense":_vm.dense},on:{"input":function($event){return _vm.emitChange()},"click:clear":function($event){return _vm.clearInputField()}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var attrs = ref.attrs;
var on = ref.on;
return [(_vm.multiple)?_c('div',{staticClass:"d-flex align-center"},[_c('v-simple-checkbox',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"value":attrs.inputValue,"label":"","ripple":false}},'v-simple-checkbox',attrs,false),on)),_c('v-icon',{staticClass:"mr-1",attrs:{"color":item.color}},[_vm._v(" mdi-checkbox-blank-circle ")]),_c('span',[_vm._v(" "+_vm._s(item.name)+" ")])],1):_c('div',[_c('v-icon',{staticClass:"mr-1",attrs:{"color":item.color}},[_vm._v(" mdi-checkbox-blank-circle ")]),_c('span',[_vm._v(" "+_vm._s(item.name)+" ")])],1)]}},{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(_vm.multiple)?_c('div',[(index === 0)?_c('v-chip',{attrs:{"small":""}},[_c('span',[_c('v-icon',{staticClass:"mr-1",attrs:{"color":item.color}},[_vm._v(" mdi-checkbox-blank-circle ")]),_c('span',[_vm._v(" "+_vm._s(item.name)+" ")])],1)]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" ( +"+_vm._s(_vm.value.length - 1)+" ) ")]):_vm._e()],1):_c('div',[_c('v-icon',{staticClass:"mr-1",attrs:{"color":item.color}},[_vm._v(" mdi-checkbox-blank-circle ")]),_c('span',[_vm._v(" "+_vm._s(item.name)+" ")])],1)]}}]),model:{value:(_vm.computedValue),callback:function ($$v) {_vm.computedValue=$$v},expression:"computedValue"}})}
var staticRenderFns = []

export { render, staticRenderFns }