import Vue from 'vue';
import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth';
import Hero from '@/components/Hero/Hero.vue';
import Snackbar from '@/components/Snackbar';
import TableNetworks from '@/components/Tables/TableNetworks/TableNetworks.vue';
import DialogCreateNetwork from '@/components/Dialog/DialogCreateNetwork/DialogCreateNetwork.vue';
import TableAudits from '@/components/Tables/TableAudits/TableAudits.vue';
import DragAndDropInputFile from '@/components/DragAndDropInputFile/DragAndDropInputFile.vue';
import EmptyStateNoData from '@/components/EmptyStateNoData';
import LoadingSpinner from '@/components/LoadingSpinner/LoadingSpinner.vue';
import AlertError from '@/components/Alerts/AlertError/AlertError.vue';
import NetworkDropdown from '@/components/Rail/NetworkDropdown/NetworkDropdown.vue';

export default {
    name: 'Audit-User',

    components: {
        Hero,
        Snackbar,
        TableNetworks,
        DialogCreateNetwork,
        TableAudits,
        DragAndDropInputFile,
        EmptyStateNoData,
        LoadingSpinner,
        AlertError,
        NetworkDropdown,
    },

    computed: {
		...mapGetters({
			humanError: 'audits/humanError',
            error: 'audits/error',
            uploadError: 'audits/uploadError',
            uploadHumanError: 'audits/uploadHumanError', 
			auditEligibilityLoading: 'audits/auditEligibilityLoading',
            auditEligibility: 'audits/auditEligibility',
            selectedAudit: 'audits/selectedAudit',
            userTrackerId: 'auth/userTrackerId',
            heroDatePickerValue: 'UI/heroDatePickerValue',
		}),

		/**
         * form.filename passed through prop to DragAndDropInputFile
         */
		computedFilename: {
			get: function () {
				return this.form.filename;
			},
			set: function (newValue) {
				this.form.filename = newValue;
			}
		},
    },
    
    watch: {
        'form.auditType': function () {
            if (this.form.auditType) {
                this.checkEligibility(this.form.auditType.id);
            }
        },
        'form.filename': function () {
            this.clearUploadErrors();
        },
    },

    mixins: [
        AuthMixin,
    ],

    data() {
        return this.initialState();
    },

    async created() {
        this.checkEligibility(this.form.auditType.id);
    },

    methods: {
        initialState() {
            return {
                submitting: false,
                form: {
					filename: [],
                    auditType: { id: Vue.prototype.$rail_GGPoker_network_id },
                    user: null,
                    status: Vue.prototype.$audits.status.REQUESTED,
                },
            }
        },

        checkEligibility(networkID) {
            let payload = {
                params: {
                    network: networkID
                }
            }
            if (this.userIsPlayer) {
                this.$store.dispatch('audits/checkEligibility', payload);
            }
        },

        async uploadAudit() {

            this.clearUploadErrors();

            this.submitting = true;

            let payload = new FormData();

            payload.append('network', this.form.auditType.id);
            payload.append('file', this.form.filename[0]);
            payload.append('user', this.userTrackerId);
            payload.append('date', this.$moment().format('YYYY-MM-DD'));

            let result = await this.$store.dispatch('audits/uploadAudit', payload);

            this.submitting = false;

            if (result === true) {
                this.clearInputFields();
                this.$store.dispatch('UI/showSnackbar', {
                    message: 'Audit importado com sucesso!',
                    color: 'success'
                  });

                this.$router.push({ path:'/tools/pokercraft/' + this.selectedAudit.id} ).catch(() => { });
            }
        },

        getDataFromDragAndDropInputFile(payload) {
            this.computedFilename = payload;
        },

        clearInputFields() {
            this.computedFilename = [];
            this.form.auditType = null;
        },

        clearUploadErrors() {
            this.$store.commit('audits/setUploadError', null);
            this.$store.commit('audits/setUploadHumanError', null);
        }
    }
}
