import Vue from 'vue';
import { mapGetters } from 'vuex';
import CardStepper from '@/components/CardStepper/CardStepper.vue';
import CardStepperNotes from '@/components/CardStepper/CardStepperNotes/CardStepperNotes.vue';
import CardStepperResume from '@/components/CardStepper/CardStepperResume/CardStepperResume.vue';
import RowTransfer from '@/components/Rail/RowTransfer/RowTransfer.vue';
import AlertError from '@/components/Alerts/AlertError/AlertError.vue';
import DialogViewAttachment from '@/components/Rail/Dialog/DialogViewAttachment/DialogViewAttachment.vue';
import RailMixin from '@/mixins/Rail.vue';
import DataMixin from '@/mixins/Data.vue';

export default {
  name: 'Split',

  components: {
    CardStepper,
    CardStepperNotes,
    CardStepperResume,
    RowTransfer,
    AlertError,
    DialogViewAttachment,
  },

  mixins: [
    RailMixin,
    DataMixin,
  ],

  computed: {
    ...mapGetters({
      selectedRequest: 'railRequests/selectedRequest',
      error: 'railRequests/error',
      humanError: 'railRequests/humanError',
      userTrackerId: 'auth/userTrackerId',
      selectedUser: 'users/selectedUser',
    }),

    /**
     * @returns {Number|String} profit share value
     */
    profitShareStr: function () {
      let output = 0;
      if (this.selectedUser) {
        output = this.getProfitShare(this.selectedUser.profitShare);
      }
      return output;
    },

    selectedRequestObservation: function () {
      return this.getObservationByPhase(this.selectedRequest.observations, Vue.prototype.table_requests_phases[Vue.prototype.$rail_ops.operations.split][1]['value']);
    }
  },

  watch: {
    selectedRequest: function () {
      this.resetComponentState();
      // populate fields
      this.populateComponentState();
    },
  },

  created() {
    // API Call to get user profit share
    this.fetchData();
    // populate fields
    this.populateComponentState();
  },

  data() {
    return this.initialState();
  },

  methods: {
    initialState() {
      return {
        arrayPWMW: [],
        form: {
          transfers: {
            playerNetworkPlayerWallet: [],
            playerWalletManagementWallet: [],
            playerWalletOutsideWallet: []
          },
          checkbox: {
            playerNetworkPlayerWallet: false,
            playerWalletManagementWallet: false,
            playerWalletOutsideWallet: false,
          },
          observation: null,
          gyazoLink: null,
          fileName: [],
          isTransferPending: true,
        },
        cardSteps: 4,
        transfersConfirmationStepPWMW: 2,
        transfersConfirmationStepPWOW: 3,
        resumeStep: 4,
        cardTitles: [
          'Realizar processo de Split',
          'Transferências para a Gestão',
          'Transferências para fora',
          'Confirmação'
        ],
        formValidations: [
          false,
          false,
          false,
          true,
        ],
        rules: {
          checkbox: [
            (v) => !!v || '',
          ],
          cardStepperNotes: [
            (v) => !!v || 'Por favor preencha o campo'
          ],
        },
        dialogues: {
          viewAttachment: false,
        },
        observation: {
          url: null,
          type: null,
        },
      }
    },

    /**
     *  set bunch of props used in CardStepper component
     *  if hasTransfersOutside , the CardStepper will have 5 steps, else it has 4.
     *  hasTransfersPNPW is used to know if 1st step needs to be validated
     */
    setCardStepperProps() { 
      // request has transfers Player Network to Player Wallet
      let hasTransfersPNPW = !this._.isEmpty(this.form.transfers.playerNetworkPlayerWallet);

      let hasTransfersOutside = !this._.isEmpty(this.form.transfers.playerWalletOutsideWallet);

      this.cardSteps = hasTransfersOutside ? 4 : 3;
      
      this.transfersConfirmationStepPWOW = hasTransfersOutside ? 3 : null;
      this.resumeStep = hasTransfersOutside ? 4 : 3;
      
      if (hasTransfersOutside) {
        this.cardTitles = [
          'Realizar processo de Split',
          'Transferências para a Gestão',
          'Transferências para fora',
          'Confirmação'
        ];
        this.formValidations = [
          !hasTransfersPNPW,
          false,
          false,
          true,
        ];
      } else {
        this.cardTitles = [
          'Realizar processo de Split',
          'Transferências para a Gestão',
          'Confirmação'
        ];
        this.formValidations = [
          !hasTransfersPNPW,
          false,
          true,
        ];
      }
    },

    /**
     * Validates a specific form step.
     * Assumes form steps are named 'formStep_x' where x is a Int 0 - maxStep-1.
     * 
     * @param {Int} step Identifier of the form step to validate.
     * @returns {Void}
     */
    validateStep(step) {
      const formStepRef = 'formStep_' + (step);

      if (this.$refs[formStepRef]) {
        // Reset validations of each checkbox after enter view
        if (this.$refs.formStep_1 || this.$refs.formStep_2 ||  this.$refs.formStep_3) {
          if (this.$refs.checkbox) {
            this.$refs.checkbox.resetValidation();
          }
        }

        // Update formValidation[step] in a way the DOM will be reactive
        Vue.set(
          this.formValidations,
          step - 1,
          this.$refs[formStepRef].validate()
        );

        // Clear store errors if exists (when user submits and goes back to change something)
        if (this.error) {
          this.$store.dispatch('railRequests/clearError');
        }
      }
    },

    /**
     * Populate each transfers array field with filter transfer data
     */
    populateTransferFields() {
      this.form.transfers.playerNetworkPlayerWallet = this.selectedRequestFilterType(Vue.prototype.$transfer_types.PNPW);
      this.form.transfers.playerWalletManagementWallet = this.selectedRequestFilterType(Vue.prototype.$transfer_types.P_M);
      this.form.transfers.playerWalletOutsideWallet = this.selectedRequestFilterType(Vue.prototype.$transfer_types.P_O);
    },

    // Call API to retrieve profitShare from user
    fetchData() {
      if (this.userTrackerId) {
          this.$store.dispatch('users/getUser', this.userTrackerId);
      }
    },

    populateComponentState() {
      // populate transfer fields
      this.populateTransferFields();
      // set CardStepper props
      this.setCardStepperProps();
    },

    resetComponentState() {
      // force CardStepper to display 1 step
      this.$store.commit('UI/setCardStepperStep', 1);
      // reset data component state
      Object.assign(this.$data, this.initialState());
    },

    /**
     * Filter transfers based on type. 
     * @param {*Integer} type 
     * @returns an array filtered by transfer type 
     */
    selectedRequestFilterType(type) {
      if (this.selectedRequest) {
        let filter = this.selectedRequest.transfers.filter(element => element.type == type);

        // adds checkbox property to each element in order to validate transfers
        filter.forEach(element => element.isPending = this.form.isTransferPending);

        return filter;
      }
    },

    /**
     * retrieve fields from CardStepperNotes component 
     * @param {Object} payload 
     */
    getInfoFromCardStepperNotes(payload) {
      if (payload) {
        this.form.gyazoLink = payload.gyazoLink;
        this.form.fileName = payload.filename;
      }
    },

    async submit() {
      // Validate form
      if (!this.$refs.formSplitChild.validate()) {
        return false;
      }

      // Toggle submit button
      this.$refs.CardStepper.toggleSubmitting();

      /**
      * Preparing payload
      */

      // Base payload
      let payload = {
        body: {}
      }

      payload.body = new FormData();

      // Add 'id' field if necessary
      if (this.selectedRequest && this.selectedRequest.id) {
        payload.id = this.selectedRequest.id;
      }
      
      // Add 'observation' field if necessary
      if (this.form.observation) {
        payload.body.append('observation[description]', this.form.observation);
      }

      // Add 'screenshot' field if necessary
      if (!this._.isEmpty(this.form.fileName)) {
        payload.body.append('screenshot', this.form.fileName[0]);
      }

      // Add 'observation[url]' field if necessary
      if (this.form.gyazoLink) {
        payload.body.append('observation[url]', this.form.gyazoLink);
      }

      /**
       * Dispatch to store
       */

      // Dispatch store action
      let result = await this.$store.dispatch('railRequests/splitUpdate', payload);

      // Re-enable btn
      if (this.$refs.CardStepper) {
        this.$refs.CardStepper.toggleSubmitting();
      }

      // On success
      if (result === true) {
        // Refresh Requests Transfers and Balances
        this.updateRequestsTransfersAndBalances();

        // change hasFinished state in order to change component
        this.$store.dispatch('TabSlider/setHasFinished', true);
        
        // Show success snackbar
        this.$store.dispatch('UI/showSnackbar', {
          message: 'Pedido de Split atualizado com sucesso.',
          color: 'success'
        });
      }
    },

    /**
    * Populates observation.url and observation.name
    * @param {Object} observation 
    * @returns {Boolean} open dialog or opens new page;
    */
    openDialogViewAttachment(observation) {
      if (observation && observation.url) {

        // validate observation type
        let isTypeLink = observation.type == 1;

        // populate fields
        this.observation.url = observation.url;
        this.observation.type = observation.type;

        return isTypeLink
          ? window.open(observation.url, '_blank')
          : this.dialogues.viewAttachment = true;
      }
    }
  }
}