<template>
  <v-snackbar
    v-model="status"
    :color="color"
    :multi-line="multiLine"
    :timeout="timeout"
    bottom
    right
    dark
  >
    <span>
      {{ message }}
    </span>
    <template #action="{ attrs }">
      <v-btn
        dark
        text
        v-bind="attrs"
        @click="close()"
      >
        Fechar
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      status: "UI/snackbarStatus",
      message: "UI/snackbarMsg",
      color: "UI/snackbarColor",
      timeout: "UI/snackbarTimeout",
      multiLine: "UI/snackbarMultiline"
    })
  },

  methods: {
    close() {
      this.$store.commit('UI/setSnackbarStatus', false);
    }
  }
};
</script>

<style>
</style>
