export default {
    name: 'CoursesCompleted',
  
    props: {
      title: {
        type: String,
        required: false,
        default: 'Atividade',
      }
    },
  
    data: () => ({
      items: [
        { id: '1', title: 'Estratégia em PKO', date: '28-12-2021 12:34', icon: 'mdi-school-outline' },
        { id: '2', title: 'Pre-Flop ICM', date: '28-12-2021 12:34', icon: 'mdi-school-outline' },
        { id: '3', title: 'Pre-Flop ChipEV', date: '28-12-2021 12:34', icon: 'mdi-school-outline' },
        { id: '4', title: 'Post-Flop ChipEV', date: '28-12-2021 12:34', icon: 'mdi-school-outline' },
        { id: '5', title: 'HU', date: '28-12-2021 12:34', icon: 'mdi-school-outline' },
        { id: '6', title: 'Privado: Introdução | Polarize MTT', date: '28-12-2021 12:34', icon: 'mdi-school-outline' },
        { id: '7', title: 'Estratégia em PKO', date: '28-12-2021 12:34', icon: 'mdi-school-outline' },
        { id: '8', title: 'Pre-Flop ICM', date: '28-12-2021 12:34', icon: 'mdi-school-outline' },
        { id: '9', title: 'Pre-Flop ChipEV', date: '28-12-2021 12:34', icon: 'mdi-school-outline' },
        { id: '10', title: 'Post-Flop ChipEV', date: '28-12-2021 12:34', icon: 'mdi-school-outline' },
        { id: '11', title: 'HU', date: '28-12-2021 12:34', icon: 'mdi-school-outline' },
        { id: '12', title: 'Privado: Introdução | Polarize MTT', date: '28-12-2021 12:34', icon: 'mdi-school-outline' },
      ],
    }),
}
  