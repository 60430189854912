var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"fill-height"},[_c('DialogViewTransfer',{attrs:{"title":"Despesa","dialog-open":_vm.dialogues.view},on:{"close-dialog":function($event){return _vm.closeDialogViewExpense()}}}),(_vm.dialogues.edit)?_c('DialogEditTransfer',{attrs:{"is-open":_vm.dialogues.edit},on:{"close-dialog":function($event){return _vm.closeDialogEditExpense()},"submitted":function($event){return _vm.fetchData()}}}):_vm._e(),_c('DialogDownloadCsv',{ref:"DialogDownloadCsv",attrs:{"type":"EXPENSE","is-open":_vm.dialogues.download.state,"message":_vm.dialogues.download.message},on:{"close-dialog":function($event){_vm.dialogues.download.state = false}}}),_c('v-card-title',[_c('span',{staticClass:"mb-3 mb-md-0"},[_vm._v(" "+_vm._s(_vm.tableTitle)+" ")]),_c('v-spacer'),_c('div',{staticClass:"d-flex align-center"},[(_vm.userIsRailManager)?_c('v-tooltip',{staticClass:"d-flex ml-2",attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"icon":"","fab":"","small":""},on:{"click":function($event){return _vm.openDialogDownloadCsv()}}},[_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-download ")])],1)]}}],null,false,4073823871)},[_c('span',[_vm._v("Exportar tabela")])]):_vm._e(),(_vm.userIsRailManager)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-3",attrs:{"color":"secondary primary--text","elevation":"1","fab":"","small":""},on:{"click":function($event){return _vm.createExpense()}}},on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}],null,false,111354642)},[_c('span',[_vm._v("Adicionar despesa")])]):_vm._e()],1)],1),_c('v-text-field',{staticClass:"mx-4 mb-3 d-flex d-md-none",attrs:{"append-icon":"mdi-magnify","label":"Pesquisar","single-line":"","hide-details":""},model:{value:(_vm.dataTable.search),callback:function ($$v) {_vm.$set(_vm.dataTable, "search", $$v)},expression:"dataTable.search"}}),_c('v-data-table',{staticClass:"table-footer-bottom",attrs:{"id":"tableExpenses","headers":_vm.dataTable.headers,"items":_vm.expenses,"search":_vm.dataTable.search,"server-items-length":_vm.totalExpenses,"options":_vm.options,"footer-props":_vm.dataTable.footerProps,"loading":_vm.loading,"loading-text":"A carregar...","must-sort":"","no-data-text":"Não foram encontrados registos de despesas."},on:{"update:options":function (e) { return _vm.optionsComputed = e; }},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getExpenseDate(item))+" ")]}},{key:"item.network",fn:function(ref){
var item = ref.item;
return [(_vm.isTransfersEmpty(item))?_c('td',{staticClass:"text-truncate",staticStyle:{"max-width":"10vw"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('img',_vm._g({staticClass:"mr-2",attrs:{"src":require('@/assets/networks/' + item.transfers[0].source.network.imageURL)}},on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.transfers[0].source.network.name))])])],1):_vm._e()]}},{key:"item.expenseType",fn:function(ref){
var item = ref.item;
return [(item && item.expenseType)?_c('span',[_c('v-icon',{attrs:{"color":item.expenseType.color}},[_vm._v(" mdi-checkbox-blank-circle ")]),_vm._v(" "+_vm._s(item.expenseType.name)+" ")],1):_vm._e()]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [(_vm.isTransfersEmpty(item))?_c('span',[(item.transfers[0].source.network.currency.id == _vm.$currency_ids.EUR)?_c('span',[_c('v-chip',[_c('span',{staticClass:"mr-2"},[_vm._v(" € ")]),_c('currency-input',{staticClass:"text-end",style:({ color: _vm.valueColorFinal(), width: '90px' }),attrs:{"value":item.transfers[0].amount.EUR.value,"currency":null,"precision":2,"allow-negative":"","disabled":""}})],1)],1):_c('span',[_c('v-chip',[_c('span',{staticClass:"mr-2"},[_vm._v(" $ ")]),_c('currency-input',{staticClass:"text-end",style:({ color: _vm.valueColorFinal(), width: '90px' }),attrs:{"value":item.transfers[0].amount.USD.value,"currency":null,"precision":2,"allow-negative":"","disabled":""}})],1)],1)]):_vm._e()]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.observations.length ? item.observations[0].description : '')+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.viewItem(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-eye ")])],1)]}}],null,true)},[_c('span',[_vm._v("Ver")])]),(_vm.userIsRailManager)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.editItem(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])]):_vm._e()]}},{key:"no-data",fn:function(){return [_c('div',{staticClass:"fill-height empty-state-custom-class"},[(!_vm.loading)?_c('EmptyStateNoData',{attrs:{"icon":"mdi-check-circle-outline","title":"Não existem despesas","message":"Ajuste os filtros para encontrar mais resultados","class-color":"success white--text py-14","class-icon":"white"}}):_vm._e()],1)]},proxy:true},{key:"loading",fn:function(){return [_c('v-skeleton-loader',{attrs:{"type":"table-tbody"}})]},proxy:true}])},[_c('template',{slot:"progress"},[_c('v-progress-linear',{attrs:{"color":"accent","indeterminate":""}})],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }