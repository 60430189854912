import Vue from 'vue';
import { mapGetters } from 'vuex';
import DataMixin from '@/mixins/Data';
import DialogApproval from '@/components/VideoRequests/dialogues/DialogApproval/DialogApproval.vue';
import DialogReject from '@/components/VideoRequests/dialogues/DialogReject/DialogReject.vue';
import DialogRemoveAccess from '@/components/VideoRequests/dialogues/DialogRemoveAccess/DialogRemoveAccess.vue';
import AlertNoData from '@/components/AlertNoData';
import EmptyStateNoData from '@/components/EmptyStateNoData';
import VideoRequestsMixin from '@/mixins/VideoRequests.vue';

export default {
    name: 'table-video-requests-admin',
    components: {
        'dialog-approval': DialogApproval,
        'dialog-reject': DialogReject,
        'dialog-remove-access': DialogRemoveAccess,
        AlertNoData,
        EmptyStateNoData,
    },
    mixins: [
        DataMixin,
        VideoRequestsMixin
    ],
    computed: {
        ...mapGetters({
            users: 'users_v1/users',
            requestedVideos: 'videoRequests/requestedVideos',
            hasLoaded: 'videoRequests/hasLoaded',
        }),
    },
    data() {
        return this.initialState();
    },
    async created() {
        await this.initialize();
    },
    methods: {
        reloadData() {
            const payload = {
                status: this.tabs.current
            };
            this.$store.dispatch('videoRequests/getRequestVideos', payload);
        },

        async initialize() {
            await this.$store.dispatch('users_v1/getUsers');
            await this.$store.dispatch('videoRequests/getRequestVideos');
        },
        initialState() {
            return {
                dataLoadingFinished: false,
                max_num_videos: 4,
                dataTable: {
                    headers: [
                        { text: '', value: 'info' },
                        { text: 'Estado', value: 'state' },
                        { text: 'Data', value: 'createdAt_video_requests' },
                        { text: 'Jogador', value: 'display_name_users' },
                        { text: 'Video', value: 'wp_post_title_video_requests' },
                        { text: 'Expiração', value: 'expiry_video_requests' },
                        { text: 'Condições de acesso', value: 'access', sortable: false },
                        { text: 'Ações', value: 'action', sortable: false },
                    ],
                    search: '',
                    itemsPerPage: 10,
                    footerProps: {
                        'items-per-page-options': this.$itemsPerPageOptions,
                    }
                },
                tabs: {
                    current: 0,
                    headers: ['Pendentes', 'Aprovados', 'Rejeitados', 'Expirados', 'Concluídos', 'Todos'],
                },
                dialogues: {
                    approval: false,
                    reject: false,
                    remove_access: false
                },
            };
        },
        getUserDisplayNameById(id) {
            let display_name = null;

            if (this.users) {
                const user = this.users.filter((e) => e.id_users == id);
                display_name = user[0]['display_name_users'];
            }

            return display_name;
        },

        filter() {
            if (!this.requestedVideos)
                return [];

            /**
             * Transform requestedVideos items object and add 
             * 'display_name_users' sttribute to each video request item
             */
            this.requestedVideos.map(item => {
                if (item.ref_users_video_requests) {
                    item.display_name_users = this.getUserDisplayNameById(item.ref_users_video_requests);
                }
                return item;
            });

            switch (this.tabs.current) {
                case 0:
                    return this.requestedVideos.filter(
                        (e) => e.status_video_requests == Vue.prototype.$video_requests.status.pending
                    );
                case 1:
                    return this.requestedVideos.filter(
                        (e) => e.status_video_requests == Vue.prototype.$video_requests.status.approved
                    );
                case 2:
                    return this.requestedVideos.filter(
                        (e) => e.status_video_requests == Vue.prototype.$video_requests.status.rejected
                    );
                case 3:
                    return this.requestedVideos.filter(
                        (e) => e.status_video_requests == Vue.prototype.$video_requests.status.expired
                    );
                case 4:
                    return this.requestedVideos.filter(
                        (e) => e.status_video_requests == Vue.prototype.$video_requests.status.complete
                    );
                default:
                    return this.requestedVideos;
            }
        },
        removeAccess(item) {
            // Set selected video on store
            if (item) {
                this.$store.commit('videoRequests/setSelectedVideo', item);

                // Populate dialog
                this.$refs.dialogRemoveAccess.populateFields();

                // Open dialog
                this.openDialog('remove_access');
            }
        },
        rejectAccess(item) {
            // Set selected video on store
            if (item) {
                this.$store.commit('videoRequests/setSelectedVideo', item);

                // Populate dialog
                this.$refs.dialogReject.populateFields();

                // Open dialog
                this.openDialog('reject');
            }
        },
        approveAccess(item) {
            // Set selected video on store
            if (item) {
                this.$store.commit('videoRequests/setSelectedVideo', item);

                // Populate dialog
                this.$refs.dialogApproval.populateFields();

                // Open dialog
                this.openDialog('approval');
            }
        },
        openDialog(d) {
            this.dialogues[d] = true;
        },

        /**
         * Values for this.tabs.current must be [0,1].
         *
         * @param {String} prop Information we want.
         * @return {String} Information to fill prop-
         */
        getEmptyState(prop) {
            // Each arr position represents a tab
            const info = [
                // Tab 'Pendente'
                {
                    icon: 'mdi-check-bold',
                    title: 'Todos os pedidos de vídeo foram respondidos',
                    classColor: 'success white--text',
                    classIcon: 'white',
                },
                // Tab 'Aprovado'
                {
                    icon: 'mdi-video-outline',
                    title: 'Não foram encontrados pedidos em estado Aprovado',
                },
                // Tab 'Rejeitado'
                {
                    icon: 'mdi-video-outline',
                    title: 'Não foram encontrados pedidos em estado Rejeitado',
                },
                // Tab 'Expirado'
                {
                    icon: 'mdi-video-outline',
                    title: 'Não foram encontrados pedidos em estado Expirado',
                },
                // Tab 'Concluido'
                {
                    icon: 'mdi-video-outline',
                    title: 'Não foram encontrados pedidos em estado Concluído',
                },
            ];
            return info[this.tabs.current][prop];
        },

        getIconAccessColumn(parameter_status) {
            let output = '';
            switch (parameter_status) {
                case true:
                    output = '@/assets/icons/check.png';
                    break;
                case false:
                    output = '@/assets/icons/cross.png';
                    break;
                default:
                    break;
            }

            //output = require(output);
            return output;
        }
    },
};
