import DataMixin from '../../mixins/Data';
import { mapGetters } from "vuex";

export default {
    name: 'DialogApplicationsVerified',

    props: {
        dialogOpen: { type: Boolean, required: true, default: false },
        action: { type: String, required: false, default: null }
    },

    components: {
    },

    mixins: [DataMixin],

    computed: {
        ...mapGetters({
            selectedApplication: 'applications/selectedApplication',
            groups: 'groups_v1/groups',
            teams: 'teams_v1/teams',
            rejectionReasons: 'applications/rejectionReasons',
            users: 'users_v1/users'
        }),

        selectedGroupId() {
            if (this.selectedGroup !== null)
                return this.groups.find(e => e.name_groups == this.selectedGroup).id_groups;
            else
                return null;
        },

        selectedTeamId() {
            if (this.selectedTeam !== null)
                return this.teams.find(e => e.name_teams == this.selectedTeam).id_teams;
            else
                return null;
        },

        selectedRejectionReasonId() {
            if (this.selectedRejectionReason !== null)
                return this.rejectionReasons.find(e => e.name_arr == this.selectedRejectionReason).id_arr;
            else
                return null;
        },

        selectedCoachId() {
            if (this.selectedCoach !== null)
                return this.users.find(e => e.display_name_users == this.selectedCoach).id_users;
            else
                return null;
        }
    },

    data() {
        return {
            stepperStage: 1,
            evaluationOptions: this.range(1, 5),
            evaluation: null,
            notes: null,
            selectedGroup: null,
            selectedTeam: null,
            selectedRejectionReason: null,
            selectedCoach: null,
            formValidStep1: false,
            formValidStep2_stage_2: false,
            formValidStep2_stage_3: false,
            fieldEvaluationRules: [
                v => !!v || 'Por favor introduza uma avaliação'
            ],
            fieldGroupRules: [
                v => !!v || 'Por favor selecione um grupo'
            ],
            fieldTeamRules: [
                v => !!v || 'Por favor selecione uma equipa'
            ],
            fieldRejectionReasonRules: [
                v => !!v || 'Por favor selecione uma razão'
            ],
            fieldCoachRules: [
                v => !!v || 'Por favor selecione um coach'
            ],
        }
    },

    created() {
        this.initialize();
    },

    methods: {
        async initialize() {
            await this.$store.dispatch('groups_v1/getGroups');
            await this.$store.dispatch('teams_v1/getTeams');
            await this.$store.dispatch('applications/getRejectionReasons');
            await this.$store.dispatch('users_v1/getUsers');
        },

        approveStep1() {
            // Validate form
            if ( ! this.$refs.formStep1.validate() )
                return false;

            if ( this.selectedApplication.stage_applications == 1 ) {
                // If stage 1, finish at step 1
                this.$emit('approve', this.evaluation, this.notes, this.selectedGroupId, this.selectedTeamId);

                // UI
                this.stepperStage++;
                this.closeDialog();
            } else {
                // If in stage 2/3, need to go to step 2
                this.stepperStage++;
            }
        },

        approveStep2(stage) {
            // Validate form
            if ( ! this.$refs['formStep2Approve_stage_' + stage].validate() )
                return false;

            this.$emit('approve', this.evaluation, this.notes, this.selectedGroupId, this.selectedTeamId, this.selectedCoachId);

            // UI
            this.stepperStage++;
            this.closeDialog();
        },

        rejectStep1() {
            // Validate form
            if ( ! this.$refs.formStep1.validate() ) {
                return false;
            } else {
                this.stepperStage++;
            }
        },

        rejectStep2() {
            // Validate form
            if ( ! this.$refs.formStep2Reject.validate() )
                return false;
            
            this.$emit('reject', this.evaluation, this.notes, this.selectedRejectionReasonId);

            // UI
            this.stepperStage++;
            this.closeDialog();
        },

        closeDialog() {
            this.$emit('closeDialog');

            // Reset data
            this.stepperStage = 1;
            this.evaluation = null;
            this.notes = null;
            this.selectedGroup = null;
            this.selectedTeam = null;
            this.selectedRejectionReason = null;
            this.formValidStep1 = false;
            this.formValidStep2_stage_2 = false;
            this.formValidStep2_stage_3 = false;

            // Reset form validations
            if (this.$refs.formStep1) this.$refs.formStep1.reset();
            if (this.$refs.formStep2Approve_stage_2) this.$refs.formStep2Approve_stage_2.reset();
            if (this.$refs.formStep2Approve_stage_3) this.$refs.formStep2Approve_stage_3.reset();
        },

        getNextButtonText() {
            if ( this.selectedApplication != null ) {
                if (this.selectedApplication.stage_applications < 3) {
                    if (this.action == 'approve') return 'Aprovar';
                    else return 'Rejeitar';
                } else {
                    return 'Continuar';
                }
            }
        }
    }
}