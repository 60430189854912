import { mapGetters } from 'vuex'
import AuthMixin from '@/mixins/Auth'
import DataMixin from '@/mixins/Data.vue';
import moment from 'moment'
import DialogConfirmation from '@/components/Rail/Dialog/DialogConfirmation/DialogConfirmation.vue';
import DrawerCreateEditEvent from '@/components/Calendar/DrawerCreateEditEvent/DrawerCreateEditEvent.vue';
export default {
    name: 'CardCalendarEventInfo',

    mixins: [
        AuthMixin,
        DataMixin,
    ],

    components: {
        DialogConfirmation,
        DrawerCreateEditEvent,
    },

    computed: {
        ...mapGetters({
            events: 'eventsCalendar/data',
            selectedEvent: 'eventsCalendar/selectedEvent',
            eventsError: 'eventsCalendar/error',
            eventsHumanError: 'eventsCalendar/humanError',
            userTrackerId: 'auth/userTrackerId',
        }),

		eventDurationString() {
			let duration = null;
			let output = null;

			// Case hours
            duration = moment(Number(this.selectedEvent.datetime_end))
                           .diff(moment(Number(this.selectedEvent.datetime_start)), 'hours');

			output = duration + ' hora';
			// Pluralize
			if (duration > 1) {
				output += 's';
			}

			// Case minutes
			if (duration == 0) {
				duration = moment(Number(this.selectedEvent.datetime_end))
                           .diff(moment(Number(this.selectedEvent.datetime_start)), 'minutes');

				output = duration + ' minutos';
			}
            
			// Output
            return output;
        },

        /**
         * Verify if exists any info about location
         * 
         * @returns {boolean}
         */
        hasLocationInfo() {
            if(this.selectedEvent.venue_address || this.selectedEvent.venue_map_link || this.selectedEvent.venue_website || this.selectedEvent.venue_contact_email || this.selectedEvent.venue_contact_phone)
                return true
            
            return false;
        },

        /**
         * Verify if current event is live 
         * @returns {boolean} 
         */
        isLive() {
            return moment().isBetween(
                this.epochToDateTime(this.selectedEvent.datetime_start),
                this.epochToDateTime(this.selectedEvent.datetime_end)
            );
        },

        /**
         * Verify if event is coming soon (1 hour)
         * @returns {boolean} 
         */
        isComingSoon() {
            const oneHourBeforeStart = moment(this.selectedEvent.datetime_start).subtract(1, 'hours');
            return moment().isAfter(oneHourBeforeStart)
                   && moment().isBefore(this.selectedEvent.datetime_start);
        },
    },
    
    created () {
        this.init();
    },

    data() {
        return this.initialState();
    },

    methods: {
        initialState() {
            return {
                date: {
                    day: null,
                    month: null,
                    numericStartDate: null,
                    numericEndDate: null,
                    calendarStartDate: null,
                    calendarEndDate: null,
                },
                dialogs: {
					confirmation: {
						open: false,
						submitting: false,
					},
                    drawerEditEvent: false,
				},
            }
        },

        init() {
            this.formatDates();
        },

        formatDates() {
            // Set month
            this.date.month = _.capitalize(
                moment(Number(this.selectedEvent.datetime_start)).format('MMMM')
            );

            // Set Day 
            this.date.day = moment(Number(this.selectedEvent.datetime_start)).format('DD');

            // Set start and end numeric dates (e.g. 10/09/2022 09:00)
            this.date.numericStartDate = moment(Number(this.selectedEvent.datetime_start)).format('L HH:mm');
            this.date.numericEndDate = moment(Number(this.selectedEvent.datetime_end)).format('L HH:mm');

            // set Start and End calendar dates (e.g. 10/09/2022 10:00)
            this.date.calendarStartDate = moment(Number(this.selectedEvent.datetime_start)).calendar();
            this.date.calendarEndDate = moment(Number(this.selectedEvent.datetime_end)).calendar();   
        },

        promptDeleteEvent(event) {
			this.dialogs.confirmation.open = true;
		},

        async deleteEvent(id) {
			// Close the dialog
			this.dialogs.confirmation.open = false;

            // Go to calendar main view
            this.$router.push({ path:'/calendar/'} ).catch(() => { });

            // Delete
			await this.$store.dispatch('eventsCalendar/deleteEvent', id);
            
            // Refresh events
            this.refreshEvents();
		},

        async refreshEvents() {
            // Refresh all events
            await this.$store.dispatch('eventsCalendar/get');
        },
    }
}