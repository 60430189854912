var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('DialogCreateEditFactors',{attrs:{"open":_vm.dialogs.createEditFactor,"action":_vm.dialogAction,"factor-to-edit":_vm.factorToEdit},on:{"close":function($event){return _vm.toggleDialog('createEditFactor')},"addFactor":_vm.addFactor,"updateFactor":_vm.updateFactor}}),_c('DialogConfirmation',{ref:"DialogConfirmation",attrs:{"is-open":_vm.dialogs.delete,"title":"Apagar fator","body":"Tem a certeza que pretende apagar este fator?","width":376,"button-name":"Apagar","button-color":"error","submitting":_vm.submittingDelete,"error":_vm.error,"human-error":_vm.error},on:{"close-dialog":function($event){return _vm.closeDialogDeleteFactor()},"submit":function($event){return _vm.deleteFactor()}}}),_c('v-container',{staticClass:"pa-0"},[_c('v-row',[_c('v-col',[_c('v-card',[_c('v-card-title',[_vm._v(" Fatores "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-icon',{staticClass:"pl-2 pb-1"},[_vm._v(" mdi-information-outline ")])],1)]}}])},[_c('span',[_vm._v(" Fatores de exclusão/cálculo disponíveis para scheduling ")])]),_c('v-spacer'),_c('v-text-field',{staticClass:"table-search-field d-none d-md-flex pr-5",attrs:{"append-icon":"mdi-magnify","label":"Pesquisar","single-line":"","hide-details":"","outlined":"","dense":""}}),(_vm.userHasRole('it_developer'))?_c('v-btn',{staticClass:"ml-3",attrs:{"color":"primary secondary--text","elevation":"1"},on:{"click":function($event){return _vm.openCreateDialog()}}},[_c('v-icon',[_vm._v("mdi-plus")]),_c('span',{staticClass:"mx-2"},[_vm._v(" Adicionar Fator ")])],1):_vm._e()],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.factors,"items-per-page":25,"loading":_vm.loading,"hide-default-footer":true,"loading-text":"A carregar..."},scopedSlots:_vm._u([{key:"item.calculation",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.calculation ? 'success' : 'error'}},[_vm._v(" "+_vm._s(_vm.getBooleanIcon(item.calculation))+" ")])]}},{key:"item.exclusion",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.exclusion ? 'success' : 'error'}},[_vm._v(" "+_vm._s(_vm.getBooleanIcon(item.exclusion))+" ")])]}},{key:"item.code",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.code)+" ")]}},{key:"item.DTOproperty",fn:function(ref){
var item = ref.item;
return [(item.DTOproperty)?_c('v-card',{staticClass:"pa-0 ma-2",attrs:{"flat":"","dark":""}},[_c('p',{staticClass:"mb-0 pa-2 caption"},[_c('pre',[_vm._v(_vm._s(item.DTOproperty))])])]):_vm._e()]}},{key:"item.column",fn:function(ref){
var item = ref.item;
return [(item.column)?_c('v-card',{staticClass:"pa-0 ma-2",attrs:{"flat":"","dark":""}},[_c('p',{staticClass:"mb-0 pa-2 caption"},[_c('pre',[_vm._v(_vm._s(item.column))])])]):_vm._e()]}},{key:"item.values",fn:function(ref){
var item = ref.item;
return [(item.values)?_c('v-card',{staticClass:"pa-0 ma-2",attrs:{"flat":"","dark":""}},[_c('p',{staticClass:"reduceWidthParagraph mb-0 pa-1 caption"},[_c('pre',[_vm._v(_vm._s(JSON.stringify(item.values, undefined, 2)))])])]):_vm._e()]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',{class:_vm.userHasRole('it_developer') ? 'enlargeAction' : ''},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":'/scheduling/factors/' + item.id + '/'}},[_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v(" mdi-eye ")])],1)],1)]}}],null,true)},[_c('span',[_vm._v("Ver")])]),(_vm.userHasRole('it_developer'))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.openEditDialog(item)}}},on),[_c('v-icon',[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])]):_vm._e(),(_vm.userHasRole('it_developer'))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.openDialogDeleteFactor(item.id)}}},on),[_c('v-icon',[_vm._v(" mdi-delete-forever ")])],1)]}}],null,true)},[_c('span',[_vm._v("Apagar")])]):_vm._e()],1)]}},{key:"no-data",fn:function(){return [(!_vm.loading)?_c('EmptyStateNoData',{attrs:{"icon":"mdi-check-circle-outline","title":"Não existem fatores","class-color":"success white--text","class-icon":"white"}}):_vm._e()]},proxy:true}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }