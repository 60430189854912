var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('DialogAddEditTournamentLive',{attrs:{"is-open":_vm.dialogues.editTournament,"action":"update"},on:{"close-dialog":function($event){return _vm.closeDialogEditTournament()}}}),_c('div',[_c('DialogConfirmation',{ref:"DialogConfirmation",attrs:{"is-open":_vm.dialogues.removeTournament,"title":"Apagar Time","body":"Tem a certeza que quer apagar este torneio?","width":376,"button-name":"Apagar","button-color":"error","error":_vm.error,"human-error":_vm.humanError},on:{"close-dialog":function($event){return _vm.closeDialogRemoveConfirmation()},"submit":function($event){return _vm.deleteTournament()}}})],1),_c('v-card-title',[_vm._v("Lista de torneios live")]),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.tournaments,"search":_vm.search,"footer-props":_vm.footerProps,"loading":_vm.tournamentsLoading,"loading-text":"A carregar...","must-sort":"","hide-default-footer":""},on:{"update:options":function (e) { return _vm.optionsComputed = e; },"click:row":_vm.setActiveItem},scopedSlots:_vm._u([{key:"item.event.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"max-width":"145px"}},[_c('span',{staticStyle:{"display":"inline-block","width":"100%","overflow":"hidden","text-overflow":"ellipsis","white-space":"nowrap"}},[_vm._v(" "+_vm._s(item.event.name)+" ")])])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"max-width":"100px"}},[_c('button',{staticStyle:{"width":"100%","text-align":"left","overflow":"hidden","text-overflow":"ellipsis","white-space":"nowrap"},on:{"click":function($event){return _vm.dialogViewTournament(item)}}},[_vm._v(" "+_vm._s(item.name)+" ")])])]}},{key:"item.starts_at",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(_vm.$moment(item.starts_at).format('DD/MM/YYYY HH:ss')))])]}},{key:"item.late_registration_at",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(_vm.$moment(item.late_registration_at).format('DD/MM/YYYY HH:ss')))])]}},{key:"item.buyin",fn:function(ref){
var item = ref.item;
return [_c('currency-input',{staticClass:"text-start",style:({ color: _vm.valueColorFinal(), width: '90px' }),attrs:{"value":item.buyin,"currency":null,"precision":2,"allow-negative":"","disabled":""}})]}},{key:"item.prize_pool",fn:function(ref){
var item = ref.item;
return [_c('currency-input',{staticClass:"text-start",style:({ color: _vm.valueColorFinal(), width: '90px' }),attrs:{"value":item.prize_pool,"currency":null,"precision":2,"allow-negative":"","disabled":""}})]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mx-2",attrs:{"dense":""},on:{"click":function($event){return _vm.dialogViewTournament(item)}}},on),[_vm._v(" mdi-launch ")])]}}],null,true)},[_c('span',[_vm._v("Ver torneio")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"grey--text",attrs:{"dense":"","disabled":""},on:{"click":function($event){return _vm.openDialogAddEditTournament('editTournament', item)}}},on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v("Editar torneio")])])],1)]}},{key:"loading",fn:function(){return [_c('v-skeleton-loader',{attrs:{"type":"table-tbody"}})]},proxy:true}])},[_c('template',{slot:"progress"},[_c('v-progress-linear',{attrs:{"color":"accent","indeterminate":""}})],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }