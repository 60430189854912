var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('DialogAddEditSwapTournament',{attrs:{"is-open":_vm.dialogs.swapTournament,"action":_vm.action},on:{"close-dialog":function($event){return _vm.toggleDialog('swapTournament')}}}),_c('DialogSwapClose',{attrs:{"is-open":_vm.dialogs.swapClose,"type":_vm.type},on:{"close-dialog":function($event){return _vm.toggleDialog('swapClose')}}}),_c('DialogConfirmation',{attrs:{"title":"Apagar Swap Pool","body":"Tem a certeza que quer apagar este Swap pool?","button-name":"Apagar","button-color":"error","is-open":_vm.dialogs.swapDelete,"submitting":_vm.form.deleting,"error":_vm.error,"human-error":_vm.humanError},on:{"submit":_vm.deleteSwapPool,"close-dialog":function($event){_vm.dialogs.swapDelete = false}}}),_c('v-card-title',[_vm._v(" Swaps do grupo "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-icon',{staticClass:"pl-2 pb-1"},[_vm._v(" mdi-information-outline ")])],1)]}}])},[_c('span',[_vm._v("Lista de torneios jogados por este grupo de Swaps ")])]),_c('v-spacer'),(_vm.userIsRailManager && _vm.showButtonPaymentAll)?_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.openDialogSwapClose()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-bank-check ")]),_vm._v(" Pagar todos os swaps ")],1):_vm._e(),(_vm.userIsRailManager)?_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.toggleDialog('swapTournament', 'create')}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-plus-circle-outline ")]),_vm._v(" Adicionar Swap ")],1):_vm._e()],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.swapPools,"server-items-length":_vm.swapPoolsTotal,"loading":_vm.swapPoolsLoading,"options":_vm.options,"loading-text":"A carregar...","single-expand":true,"show-expand":""},on:{"update:options":function($event){_vm.options=$event},"item-expanded":function (e) { return _vm.setExpandItem(e); }},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$moment.unix(item.date).format('DD-MM-YYYY')))])]}},{key:"item.game",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.game ? item.game.name : 'Registado como sessão')+" ")]}},{key:"item.paid",fn:function(ref){
var item = ref.item;
return [(item.paid)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" mdi-check ")]):_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-close ")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(!item.paid)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.openDialogSwapClose(item)}}},on),[_c('v-icon',[_vm._v(" mdi-cash-check ")])],1)]}}],null,true)},[_c('span',[_vm._v("Realizar todas as transferências")])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":item.paid,"icon":""},on:{"click":function($event){return _vm.toggleDialog('swapTournament', 'edit', item)}}},on),[_c('v-icon',[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":item.paid,"icon":""},on:{"click":function($event){return _vm.toggleDialog('swapDelete', '', item)}}},on),[_c('v-icon',[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v("Apagar")])])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"px-0",attrs:{"colspan":headers.length}},[_c('TableSingleSwapTournament',{attrs:{"action":"view"}})],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }