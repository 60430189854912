var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0"},[_c('DialogDownloadCsv',{ref:"DialogDownloadCsv",attrs:{"type":"REQUEST","is-open":_vm.dialogues.download.state,"message":_vm.dialogues.download.message},on:{"close-dialog":function($event){_vm.dialogues.download.state = false}}}),_c('v-row',[_c('v-col',[(! _vm.showCustomLoading || (_vm.showCustomLoading && _vm.hasLoadedData && _vm.filteredData.length))?_c('div',[_c('v-card',{style:('border-top: 4px solid ' + _vm.borderColor + ' !important')},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.title)+" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-icon',{staticClass:"pl-2 pb-1"},[_vm._v("mdi-information-outline")])],1)]}}],null,false,1326258114)},[_c('span',[_vm._v(_vm._s(_vm.informationString))])]),_c('v-spacer'),(_vm.showButtonSeeAll)?_c('div',[_c('v-btn',{staticClass:"ml-4 table-see-all-button",attrs:{"outlined":"","text":""},on:{"click":function($event){return _vm.seeAllRequests()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-format-list-bulleted ")]),_vm._v(" Ver todos ")],1)],1):_vm._e(),(_vm.userIsRailManager)?_c('v-tooltip',{staticClass:"d-flex ml-2",attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"icon":""},on:{"click":function($event){return _vm.openDialogDownloadCsv()}}},[_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-download ")])],1)]}}],null,false,3901849285)},[_c('span',[_vm._v("Exportar tabela")])]):_vm._e(),(_vm.showTabs && _vm.userIsRailManager)?_c('ButtonCreateOperation'):_vm._e()],1),_c('v-text-field',{staticClass:"mx-4 mb-3 d-flex d-md-none",attrs:{"append-icon":"mdi-magnify","label":"Pesquisar","single-line":"","hide-details":""},on:{"input":function($event){return _vm.search()}},model:{value:(_vm.dataTable.search),callback:function ($$v) {_vm.$set(_vm.dataTable, "search", $$v)},expression:"dataTable.search"}}),(_vm.showTabs)?_c('div',[_c('v-tabs',{attrs:{"show-arrows":"","color":"accent"},model:{value:(_vm.tabs.current),callback:function ($$v) {_vm.$set(_vm.tabs, "current", $$v)},expression:"tabs.current"}},_vm._l((_vm.tabs.items),function(tab){return _c('v-tab',{key:tab,staticClass:"text--primary"},[_c('span',[_vm._v(_vm._s(tab))])])}),1)],1):_vm._e(),_c('v-tabs-items',{model:{value:(_vm.tabs.current),callback:function ($$v) {_vm.$set(_vm.tabs, "current", $$v)},expression:"tabs.current"}},[_c('v-data-table',{attrs:{"headers":_vm.tableHeaders,"items":_vm.filteredData,"search":_vm.dataTable.search,"server-items-length":_vm.totalData,"options":_vm.options,"loading":!_vm.hasLoadedData,"footer-props":_vm.dataTable.footerProps,"multi-sort":"","loading-text":"A carregar...","no-data-text":"Não foram encontrados resultados"},on:{"click:row":_vm.setActiveItem,"update:options":function (e) { return _vm.optionsComputed = e; }},scopedSlots:_vm._u([{key:"item.selected",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[(
                          _vm.selectedRequest &&
                            item.id == _vm.selectedRequest.id
                        )?_c('v-icon',{staticClass:"circle"}):_vm._e()],1)]}}],null,true)},[_c('span',[_vm._v("Pedido Selecionado")])])]}},{key:"item.date",fn:function(ref){
                        var item = ref.item;
return [(_vm.showTabs)?_c('span',[(_vm.userIsRailManager)?_c('v-edit-dialog',{attrs:{"return-value":item.date.date,"large":"","persistent":"","cancel-text":"Cancelar","save-text":"Guardar"},on:{"update:returnValue":function($event){return _vm.$set(item.date, "date", $event)},"update:return-value":function($event){return _vm.$set(item.date, "date", $event)},"save":function($event){return _vm.editDateRailOperation(item)},"open":function($event){return _vm.openDateRailOperation(item)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('div',{staticClass:"mt-4 text-h6"},[_vm._v(" Editar Data ")]),_c('DatePicker',{staticClass:"mt-5 px-3",attrs:{"value":_vm.date},on:{"change":function (e) { return _vm.date = e; }}})]},proxy:true}],null,true)},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(_vm.$moment(item.date.date).format('D MMM YYYY'))+" ")])]}}],null,true)},[_c('span',[_vm._v("Criada em: "+_vm._s(_vm.$moment(item.createdAt.date).format('LLL')))]),_vm._v(" "),_c('br'),_c('span',[_vm._v("Última atualização: "+_vm._s(item.updatedAt ? _vm.$moment(item.updatedAt.date).format('LLL') : '----'))]),_vm._v(" "),_c('br')])],1):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(_vm.$moment(item.date.date).format('LL'))+" ")])]}}],null,true)},[_c('span',[_vm._v("Criada em "+_vm._s(_vm.$moment(item.createdAt.date).format('LLL')))]),_vm._v(" "),_c('br'),_c('span',[_vm._v("Última atualização em "+_vm._s(_vm.$moment(item.updatedAt.date).format('LLL')))]),_vm._v(" "),_c('br')])],1):_c('span',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(_vm.$moment(item.createdAt.date).fromNow())+" ")])]}}],null,true)},[_c('span',[_vm._v("Criada em "+_vm._s(_vm.$moment(item.createdAt.date).format('LLL')))]),_vm._v(" "),_c('br'),_c('span',[_vm._v("Última atualização em "+_vm._s(_vm.$moment(item.updatedAt.date).format('LLL')))]),_vm._v(" "),_c('br')])],1)]}},{key:"item.type",fn:function(ref){
                        var item = ref.item;
return [_c('v-chip',{staticClass:"w-100",attrs:{"color":_vm.getRailOperationColor(item.type),"label":""}},[_vm._v(" "+_vm._s(_vm.getRailOperationName(item.type))+" "+_vm._s(_vm.getMakeUpReason(item))+" ")])]}},{key:"item.user",fn:function(ref){
                        var item = ref.item;
return [_c('UserCard',{attrs:{"id":_vm.getUserIdFromRailOperation(item),"display-name":_vm.getUsernameFromRailOperation(item),"img-cutout":item.imgCutout}})]}},{key:"item.group",fn:function(ref){
                        var item = ref.item;
return [(item.user && item.playerGroup && item.playerGroup.id)?_c('span',[_c('router-link',{attrs:{"to":'/groups/' + item.playerGroup.id}},[_c('v-chip',{staticClass:"w-100 cursor-pointer",attrs:{"label":""}},[_vm._v(" "+_vm._s(item.playerGroup.name)+" ")])],1)],1):(item.targetUser && item.playerGroup && item.playerGroup.id)?_c('span',[_c('router-link',{attrs:{"to":'/groups/' + item.playerGroup.id}},[_c('v-chip',{staticClass:"w-100 cursor-pointer",attrs:{"label":""}},[_vm._v(" "+_vm._s(item.playerGroup.name)+" ")])],1)],1):_vm._e()]}},{key:"item.team",fn:function(ref){
                        var item = ref.item;
return [(item.user && item.user.teams)?_c('span',[_c('v-chip',{staticClass:"w-100 cursor-pointer",attrs:{"label":""}},[_vm._v(" "+_vm._s(_vm.getUserTeam(item.user.teams))+" ")])],1):(item.targetUser && item.targetUser.teams)?_c('span',[_c('v-chip',{staticClass:"w-100 cursor-pointer",attrs:{"label":""}},[_vm._v(" "+_vm._s(_vm.getUserTeam(item.targetUser.teams))+" ")])],1):_vm._e()]}},{key:"item.source",fn:function(ref){
                        var item = ref.item;
return [((item.requests && item.requests.length >= 1))?_c('div',[_c('div',{staticClass:"d-block text-truncate",class:{'ellipsis': item.requests.length >= 3},style:(_vm.$vuetify.breakpoint.xlOnly ? '' : 'max-width: 150px;')},_vm._l((item.requests),function(data){return _c('v-tooltip',{key:data.id,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('img',_vm._g({staticClass:"mr-2 pt-1",attrs:{"src":require('@/assets/networks/' + (data.nickname ? data.nickname.network.imageURL : data.source.network.imageURL))}},on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(data.nickname ? data.nickname.network.name : data.source.network.name)+" ")])])}),1)]):_c('div',[_c('div',{staticClass:"d-block text-truncate",style:(_vm.$vuetify.breakpoint.xlOnly ? '' : 'max-width: 150px;')},_vm._l((_vm.handleTransfers(item, 'source')),function(data){return _c('v-tooltip',{key:data.id,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('img',_vm._g({staticClass:"mr-2 pt-1",attrs:{"src":require('@/assets/networks/' + data.source.network.imageURL)}},on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(data.source.network.name)+" ")])])}),1)])]}},{key:"item.destination",fn:function(ref){
                        var item = ref.item;
return [((item.requests && item.requests.length >= 1) && (_vm.$rail_status.finalized != item.status))?_c('div',[_c('div',{staticClass:"d-block text-truncate",style:(_vm.$vuetify.breakpoint.xlOnly ? '' : 'max-width: 150px;')},_vm._l((_vm.getOperationRequests(item)),function(data){return _c('v-tooltip',{key:data.id,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('button',{on:{"click":function($event){data.target && data.target.network.id != _vm.$rail_external_wallet_id ? _vm.triggerCopyToClipboard(data) : null}}},[_c('img',_vm._g({staticClass:"mr-2 pt-1",attrs:{"src":require('@/assets/networks/' + (data.target ? data.target.network.imageURL : data.wallet.network.imageURL))}},on))])]}}],null,true)},[_c('div',{staticClass:"d-flex align-center"},[(data.target && data.target.id != _vm.$rail_external_wallet_id)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"white"}},[_vm._v(" mdi-content-copy ")]):_vm._e(),_c('span',[_vm._v(_vm._s(data.target ? data.target.network.name : data.wallet.network.name))])],1)])}),1)]):_c('div',[_c('div',{staticClass:"d-block text-truncate",style:(_vm.$vuetify.breakpoint.xlOnly ? '' : 'max-width: 150px;')},_vm._l((_vm.handleTransfers(item, 'target')),function(data){return _c('v-tooltip',{key:data.id,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('button',{on:{"click":function($event){data.target && data.target.network.id != _vm.$rail_external_wallet_id ? _vm.triggerCopyToClipboard(data) : null}}},[_c('img',_vm._g({staticClass:"mr-2 pt-1",attrs:{"src":require('@/assets/networks/' + data.target.network.imageURL)}},on))])]}}],null,true)},[_c('div',{staticClass:"d-flex"},[(data.target && data.target.id != _vm.$rail_external_wallet_id)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"white"}},[_vm._v(" mdi-content-copy ")]):_vm._e(),_c('span',[_vm._v(_vm._s(data.target.network.name)+" ")])],1)])}),1)])]}},{key:"item.value",fn:function(ref){
                        var item = ref.item;
return [((typeof _vm.getAmount(item)) == 'number')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({},'v-chip',attrs,false),on),[_c('span',{staticClass:"mr-2"},[_vm._v(_vm._s(_vm.getRailOperationCurrency(item))+" ")]),_c('currency-input',{staticClass:"text-end",style:({color: _vm.valueColorFinal(), width: '90px' }),attrs:{"value":_vm.getAmount(item),"currency":null,"precision":_vm.handlePrecision(item),"disabled":""}})],1)]}}],null,true)},[_c('div',{staticClass:"d-flex flex-column"},[(_vm.showTooltipAmountRequested(item))?_c('span',[_vm._v("Montante pedido: "+_vm._s(_vm.getRailOperationCurrency(item))+" "+_vm._s(_vm.getAmountRequestedTooltip(item))+" ")]):_vm._e(),_c('span',[_vm._v("Montante final: "+_vm._s(_vm.getRailOperationCurrency(item))+" "+_vm._s(_vm.getAmountSentTooltip(item))+" ")])])]):_c('v-chip',{staticClass:"d-flex justify-center",style:({width: '131px' })},[_c('span',[_vm._v(" "+_vm._s(_vm.getAmount(item))+" ")])])]}},{key:"item.profit_split",fn:function(ref){
                        var item = ref.item;
return [(item.type == _vm.$rail_ops.operations.split && item.phase != _vm.operationSplitPhase1)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"w-100",attrs:{"color":"success","small":""}},'v-chip',attrs,false),on),[_c('span',{staticClass:"mr-1"},[_vm._v("+")]),_c('span',{staticClass:"mr-1"},[_vm._v(_vm._s(_vm.getRailOperationCurrency(item)))]),_c('currency-input',{staticClass:"text-start",style:({color: 'white', width: _vm.getCurrencyInputWidth(_vm.getProfitShare(item), true) }),attrs:{"value":parseFloat(_vm.getProfitShare(item)),"currency":null,"precision":2,"disabled":""}})],1)]}}],null,true)},[_c('span',[_vm._v("Lucro do split")])]):_vm._e()]}},{key:"item.status",fn:function(ref){
                        var item = ref.item;
return [(_vm.userIsRailManager)?_c('div',{staticClass:"d-inline-flex"},[(item.status == 4)?_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('img',{attrs:{"src":require("@/assets/icons/cross.png")}})])]}}],null,true)},[_c('span',[_vm._v("Pedido rejeitado")])])],1):(item.flag)?_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('img',{attrs:{"src":require("@/assets/icons/cross.png")}})])]}}],null,true)},[_c('span',[_vm._v("Operação reportada")])])],1):_vm._l((_vm.getAdminOperationPhases(item)),function(stat){return _c('div',{key:stat.value,staticClass:"d-inline-flex"},[(stat.value <= item.phase)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('img',{attrs:{"src":require("@/assets/icons/check.png")}})])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(stat.name)+" ")])]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-avatar',{staticClass:"d-flex",attrs:{"color":"secondary darken-1","size":"16"}})],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(stat.name)+" ")])])],1)})],2):_c('div',{staticClass:"d-inline-flex w-100"},[(!item.flag)?_c('v-chip',{staticClass:"w-100",attrs:{"color":_vm.getOperationStatusColor(item),"label":""}},[_vm._v(" "+_vm._s(_vm.getUserOperationPhase(item))+" ")]):_c('v-chip',{staticClass:"w-100",attrs:{"color":"error","label":""}},[_vm._v(" Reportado ")])],1)]}},{key:"item.action",fn:function(ref){
                        var item = ref.item;
return [(item.flag)?_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":_vm.goToReport(item)}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"disabled":_vm.submitting}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.goToRailOperationIcon(item))+" ")])]}}],null,true)},[_c('span',[_vm._v("Abrir página dos reports")])])],1):_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":_vm.$tab_slider.tabs.requests + item.id}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"disabled":_vm.submitting},on:{"click":function($event){return _vm.goToRailOperation(item)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.goToRailOperationIcon(item))+" ")])]}}],null,true)},[_c('span',[_vm._v("Abrir operação")])])],1)]}},{key:"no-data",fn:function(){return [(_vm.hasLoadedData)?_c('EmptyStateNoData',{attrs:{"icon":"mdi-check-bold","title":_vm.emptyStateMessage,"message":"Ajuste os filtros para encontrar mais resultados","class-color":"green white--text","class-icon":"white"}}):_vm._e()]},proxy:true},{key:"no-results",fn:function(){return [_c('EmptyStateNoData',{attrs:{"icon":"mdi-magnify","title":"Não foram encontrados resultados","message":"Ajuste a sua pesquisa para encontrar mais resultados"}})]},proxy:true}],null,false,4211140412)},[_c('template',{slot:"progress"},[_c('v-progress-linear',{attrs:{"color":"accent","indeterminate":""}})],1)],2)],1)],1)],1):_vm._e(),(_vm.showCustomLoading && ! _vm.hasLoadedData)?_c('div',[_c('v-progress-linear',{attrs:{"indeterminate":"","color":_vm.borderColor}}),_c('v-row',[_c('v-col',{staticClass:"text-center subtitle-1"},[_c('v-list-item-subtitle',[_vm._v("A verificar se existem pedidos pendentes..")])],1)],1)],1):_vm._e()])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }