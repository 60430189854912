import Vue from 'vue';
import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth.vue';
import RailMixin from '@/mixins/Rail.vue';
import TableRequests from '@/components/Rail/TableRequests/TableRequests.vue';
import UserDropdown from '@/components/Rail/UserDropdown/UserDropdown.vue';
import OperationDropdown from '@/components/Rail/OperationDropdown/OperationDropdown.vue';
import NetworkDropdown from '@/components/Rail/NetworkDropdown/NetworkDropdown.vue';
import DatePickerRange from '@/components/DatePickerRange/DatePickerRange.vue';
import OperationStatusDropdown from '@/components/Rail/OperationStatusDropdown/OperationStatusDropdown.vue';
import CardStatistics from '@/components/Rail/Statistics/CardStatistics/CardStatistics.vue';

export default {
    name: 'Operations',

    components: {
        TableRequests,
        UserDropdown,
        OperationDropdown,
        NetworkDropdown,
        DatePickerRange,
        OperationStatusDropdown,
        CardStatistics,
    },

    mixins: [
        AuthMixin,
        RailMixin,
    ],

    computed: {
        ...mapGetters({
            heroDatePickerValue: 'UI/heroDatePickerValue',
            statisticsAdmin: 'railStatistics/dataAdmin',
            statisticsLoading: 'railStatistics/loadingDataAdmin',
            teamFilter: 'teams/teamFilter',
        }),

        formOperationComputed() {
            if (!this._.isEmpty(this.form.operation)) {
                return this.form.operation.map(e => e.value);
            }
            return this.form.operation;
        },

        bankrollReductionStatistic() {
            return this.statisticsAdmin['BANKROLL_REDUCTION'] ? this.statisticsAdmin['BANKROLL_REDUCTION'].value.EUR.value : 0;
        },

        bankrollIncrementStatistic() {
            return this.statisticsAdmin['BANKROLL_INCREMENT'] ? this.statisticsAdmin['BANKROLL_INCREMENT'].value.EUR.value : 0;
        },

        splitStatistic() {
            return this.statisticsAdmin['SPLIT_MANAGEMENT'] ? this.statisticsAdmin['SPLIT_MANAGEMENT'].value.EUR.value : 0;
        },

        cardInfo() { 
            return [
                {
                    name: 'Montante recebido',
                    value: this.bankrollReductionStatistic + this.splitStatistic,
                    icon: 'mdi-cash-plus',
                    showCurrency: true,
                    showAltCurrency: false,
                },
                {
                    name: 'Montante enviado',
                    value: this.bankrollIncrementStatistic,
                    icon: 'mdi-cash-minus',
                    showCurrency: true,
                    showAltCurrency: false,
                },
                {
                    name: 'Montante',
                    value: (this.bankrollReductionStatistic + this.splitStatistic) - this.bankrollIncrementStatistic,
                    icon: 'mdi-bank',
                    showCurrency: true,
                    showAltCurrency: false,
                },
            ];
        },

        // computed property passed to TableRequests
        formFilters: {
            get() {
                return this.form;
            },
            set(newVal) {
                this.form = newVal;
            }
        },

        computedCalendarDates: {
			get() {
				return this.dates;
			},
			set(newValue) {
                this.dates = newValue;
				this.$store.commit('UI/setHeroDatePickerValue', newValue);
			}
        },

        computedTab: {
            get() {
                return this.tab;
            },
            set(newValue) {
                this.tab = newValue;
            }
        },

        computedStatus: {
            get() {
                return this.form.status;
            },
            set(newValue) {
                this.form.status = newValue;
            }
        }
    },

    watch: {
        /**
         * runs everytime some nested object has changed
         * sets formFilters computed property that will be passed as prop to TableRequests
         */
        form: {
            handler(newVal) {
                this.formFilters = newVal;
            },
            deep: true,
        },

        /**
         * Whenever heroDatePickerValue has changed, we set new values to computedCalendarDates computed property
         */
        heroDatePickerValue: function () {
            this.computedCalendarDates = this.heroDatePickerValue;
        },

        teamFilter: function () {
            this.fetchStatistics();
        }
    },

    created() {
		// Save data passed to this component via mapGetters
        this.computedCalendarDates = this.heroDatePickerValue;
	},

    data() {
        return this.initializeState();
    },

    methods: {
        initializeState() {
            return {
                dates: [],
                tab: null,
                operationCreateType: [
                    { value: false, name: 'Todas as operações' },
                    { value: true, name: 'Operações criadas pela gestão' },
                ],
                rakeTypes: [
                    { reasonDirection: Vue.prototype.$direction_rail_operations.increase, text: 'Aula' },
                    { reasonDirection: Vue.prototype.$direction_rail_operations.increase, text: 'Anomalia' },
                    { reasonDirection: Vue.prototype.$direction_rail_operations.increase, text: 'Compra Software' },
                    { reasonDirection: Vue.prototype.$direction_rail_operations.increase, text: 'Split Adiantado' },
                    { reasonDirection: Vue.prototype.$direction_rail_operations.decrease, text: 'Rake' },
                  ],
                form: {
                    user: null,
                    network: null,
                    operation: [
                        { value: Vue.prototype.$rail_ops.operations.deposit, name: 'Depósito' },
                        { value: Vue.prototype.$rail_ops.operations.bankroll_increment, name: 'Incremento de Banca' },
                        { value: Vue.prototype.$rail_ops.operations.withdraw, name: 'Levantamento' },
                        { value: Vue.prototype.$rail_ops.operations.makeup, name: 'Makeup' },
                        { value: Vue.prototype.$rail_ops.operations.bankroll_reduction, name: 'Redução de Banca' },
                        { value: Vue.prototype.$rail_ops.operations.split , name: 'Split' },
                        { value: Vue.prototype.$rail_ops.operations.swap, name: 'Swap' }
                    ],
                    status: [],
                    createdByManagement: false,
                    rakeType: null,
                }
            }
        },

        /**
         * retrieve tab from TableRequests and set computedTab
         * @param {String|Number} item 
         */
        selectTab(item) {
            this.computedTab = item;
        },

        async fetchStatistics() {
            let payload = null;
            if (! this._.isEmpty(this.heroDatePickerValue)) {
                payload = {
                    dateBegin: this.heroDatePickerValue[0],
                    dateEnd: this.heroDatePickerValue[1],
                };
            }

            // if team exists and isManagement, Add team parameter to payload.params
            if (this.teamFilter) {
                payload.team = this.teamFilter;
            }

            if (!this._.isEmpty(payload)) {
                await this.$store.dispatch('railStatistics/getAdmin', payload);
            }
        }
    }
}
