import Vue from 'vue';
import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth';
import NetworkDropdown from '@/components/Rail/NetworkDropdown/NetworkDropdown.vue';
import AlertError from '@/components/Alerts/AlertError/AlertError.vue';
import NicknameDropdown from '@/components/Rail/NicknameDropdown/NicknameDropdown.vue';

export default {
    name: 'DialogEditNickname',

    props: {
        isOpen: {
            type: Boolean,
            required: true,
            default: false
        },
    },

    components: {
        NetworkDropdown,
        AlertError,
        NicknameDropdown,
    },

    mixins: [
        AuthMixin,
    ],

    data() {
        return this.initialState();
    },

    created() {
        this.fetchData();
    },

    computed: {
        ...mapGetters({
            error: 'nicknames/error',
            humanError: 'nicknames/humanError',
            selectedNickname: 'nicknames/selectedNickname',
            userTrackerId: 'auth/userTrackerId',
            nicknamesByUserID: 'nicknames/nicknamesByUserID',
            nicknamesAllMine: 'nicknames/nicknamesAllMine',
            userTeams: 'auth/userTeams',
        }),

        handleMetadataFields: function () {
             // clear states
            this.form.metaDataArray = [];
  
            // populates form.metaDataArray with string's
            if (this.form.network && this.form.network.metadata_required) {
                this.form.metaDataArray = this.form.network.metadata_required;
            }

            return this.form.metaDataArray;
        },

        // @returns {Object} props passed to NicknameDropdown
        nicknameDropdownProps: function () {
            return {
                ref: "NicknameDropdown",
                type: this.userIsRailManager ? 'PLAYER' : 'ANY',
                scope: this.userIsRailManager ? 'ALL' : 'MINE',
                user: this.userIsRailManager && this.selectedNickname.user ? this.selectedNickname.user.id : null,
                'always-reload': !this.userIsRailManager,
                excludeZeroBalance: false,
                label: "Nickname",
            }
        },

        authUserTeamsComputed: function () {
            let userTeamsMapped = [];
            if (this.userIsRailAdmin) {
                userTeamsMapped = [
                    Vue.prototype.$team_division_mtt_id,
                    Vue.prototype.$team_division_cash_id,
                    Vue.prototype.$team_division_spins_id,
                ]
            } else {
                userTeamsMapped = this.userTeams.map(e => e.id);
            }
            return userTeamsMapped;
        }
    },
    
    watch: {
        selectedNickname: function () {
            this.fetchData();
        },

        'form.management': function (val) {
            if(!val){
                this.submitManagementClick = false;
            }
        },

        isOpen: function () {
            if (this.isOpen) {
                // clear input errors
                this.$store.dispatch('nicknames/clearError');
                if (this.$refs && this.$refs.UserDropdownRef && this.$refs.NetworkDropdownRef) {
                    this.$refs.UserDropdownRef.reset();
                    this.$refs.NetworkDropdownRef.resetNetwork();
                }
                this.fetchData();
          }
        }
    },

    methods: {
        initialState() {
            return {
                submitting: false,
                submitManagementClick: false,
                managementTeams: [
                    'mtt', 'cash', 'spins'
                ],
                form: {
                    metaDataArray: [],
                    network: null,
                    name: null,
                    aliasOf: null,
                    description: null,
                    management: false,
                    managementTeam: [],
                    metadata: {
                        email: null,
                        address_btc: null,
                        address_usdt: null,
                    },
                    email: null,
                    accountName: null,
                    accountNumber: null,
                    address_btc: null,
                    address_usdt: null,
                },
                options: {
                    page: 1,
                    itemsPerPage: 10,
                },
                rules: {
                    name: [
                        v => !!v || 'Por favor preencha o campo nickname',
                    ],
                    nicknameDropdown: [
                        (v) => !!v || 'Por favor introduza uma sala'
                    ],
                    email: [
                        (v) => !!v && /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(v) || 'Por favor introduza um email válido'
                    ],
                    accountNumber: [
                        (v) => !!v && /(^[0-9]*$)/.test(v) || 'Por favor introduza um número válido'
                    ],
                    accountName: [
                        (v) => !!v && /^[a-zA-Z\s]*$/.test(v) || 'Por favor introduza um nome válido'
                    ],
                    address: [
                        v => !!v || 'Por favor preencha o campo de endereço da carteira',
                    ],
                    management: [
                        (v) => (!this.submitManagementClick || (v && this.form.managementTeam.length > 0)) || 'Selecione pelo menos uma opção para a equipa de gestão.',
                    ],
                },
            };
        },

        fetchData() {
            // clean these fields everytime fetchData is triggered()
            this.form.management = false;
            this.form.managementTeam = [];
            this.form.network = null;
            this.form.metaDataArray = [];
            this.form.metadata = {
                email: null,
                address_btc: null,
                address_usdt: null,
            };

            // populate fields
            if (this.selectedNickname) {
                this.form.name = this.selectedNickname.name;
                this.form.network = this.selectedNickname.network;
                this.form.description = this.selectedNickname.description;
                this.form.management = this.selectedNickname.isManagement;

                if(this.form.management){
                    this.submitManagementClick = true;
                }
                
                if (this.selectedNickname.isManagement) {
                    this.form.managementTeam = this.selectedNickname.managementTeams;
                } else {
                    this.form.managementTeam = [];
                }

                this.form.aliasOf = { id: this.selectedNickname.aliasOf };

                // handle metadata values
                if (this.selectedNickname.network && this.selectedNickname.network.metadata_required) {
                    if (this.selectedNickname.network.metadata_required.includes('email')) {
                        this.form.metadata.email = this.selectedNickname.metadata ? this.selectedNickname.metadata.email : null;
                    }
                    if (this.selectedNickname.network.metadata_required.includes('addr_btc_btc')) {
                        this.form.metadata.address_btc = this.selectedNickname.metadata ? this.selectedNickname.metadata.addr_btc_btc : null;
                    }
                    if (this.selectedNickname.network.metadata_required.includes('addr_usdt_erc20')) {
                        this.form.metadata.address_usdt = this.selectedNickname.metadata ? this.selectedNickname.metadata.addr_usdt_erc20 : null;
                    }
                    // eco payz edge case
                    if (this.selectedNickname.network.metadata_required.includes('name') && this.selectedNickname.network.metadata_required.includes('number')) {
                        let nameSplited = this.selectedNickname.name.split('-');

                        let handleNumberSplited = typeof (nameSplited[0]) == 'string'
                            ? nameSplited[0].slice(0, -1)
                            : null;
                    
                        this.form.accountNumber = handleNumberSplited;

                        let handleNameSplited = typeof (nameSplited[1]) == 'string'
                            ? nameSplited[1].slice(1)
                            : null;
                        
                        this.form.accountName = handleNameSplited;
                        
                    }
                }
            }
        },

        async submit() {
            this.submitManagementClick = this.form.management;

            if (!this.$refs.formEditNickname.validate()) {
                return false;
            }

            // Toggle submit button
            this.toggleSubmitting();

            let payload = {
                id: this.selectedNickname.id,
                body: {
                    user: this.selectedNickname.user.id,
                    network: this.form.network.id,
                    metadata: {}
                }
            }

            if (this.form.aliasOf) {
                payload.body.aliasOf = this.form.aliasOf.id;
            }

            if (this.form.name != this.selectedNickname.name) {
                payload.body.replace = this.selectedNickname.id;
            }

            // if has management
            if (!this._.isNull(this.form.management)) {
                payload.body.management = this.form.management;
            }

            // if managementTeam isn't empty it means it has teams
            if (this.form.management && !this._.isEmpty(this.form.managementTeam)) {
                payload.body.managementTeam = this.form.managementTeam.join();
            } else {
                delete payload.body.managementTeam;
                payload.body.management = false;
            }

            // if has description
            if (!this._.isNull(this.form.description)) {
                payload.body.description = this.form.description;
            }

            // if has name
            if (!this._.isNull(this.form.name)) {
                payload.body.name = this.form.name;
            }

            // if has accountName && accountNumber
            if (!this._.isNull(this.form.accountName) && !this._.isNull(this.form.accountNumber)) {
                payload.body.name = this.form.accountNumber + ' - ' + this.form.accountName;
            }

            // if has email
            if (!this._.isNull(this.form.metadata.email) && this.selectedNickname.network.metadata_required.includes('email')) {
                payload.body.metadata.email = this.form.metadata.email;
            }

            // if has address_btc
            if (!this._.isNull(this.form.metadata.address_btc) && this.selectedNickname.network.metadata_required.includes('addr_btc_btc')) {
                payload.body.metadata.addr_btc_btc = this.form.metadata.address_btc;
            }

            // if has address_usdt
            if (!this._.isNull(this.form.metadata.address_usdt) && this.selectedNickname.network.metadata_required.includes('addr_usdt_erc20')) {
                payload.body.metadata.addr_usdt_erc20 = this.form.metadata.address_usdt;
            }

            if (this._.isEmpty(payload.body.metadata)) {
                delete payload.body.metadata;
            }

            let result = await this.$store.dispatch('nicknames/update', payload);

            // // Re-enable button
            this.toggleSubmitting();

            // On success
            if (result === true) {
                // Show success snackbar
                this.$store.dispatch('UI/showSnackbar', {
                    message: 'Nickname atualizado com sucesso.',
                    color: 'success'
                });

                // if update is sucessfully completed , it emits reload event to update tableNicknames
                this.$emit('reload');

                // closes dialog
                this.closeDialog();
            }
        },

        /**
         * Used to disable Submit button
         */
        toggleSubmitting() {
            this.submitting = !this.submitting;
        },

        closeDialog() {
            this.$emit("close-dialog");
            this.resetForm();
        },

        resetForm() {
            Object.assign(this.$data, this.initialState());
            this.$refs.formEditNickname.reset();
            this.$store.dispatch('nicknames/clearError');
        },

        handleExcludedNetworks() {
            let output = [];
            let nicknames = this.userIsManager
                ? this.nicknamesByUserID
                : this.nicknamesAllMine;
            if (this.selectedNickname) {
                output = nicknames.filter(e => e.network.id != this.selectedNickname.network.id);
            }

            if (!this._.isEmpty(output)) {
                output = output.map(e => e.network.id);
            }

            return output;
        },
    },
};