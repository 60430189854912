import AuthMixin from '@/mixins/Auth';
import DataMixin from '@/mixins/Data';
import Hero from '@/components/Hero/Hero.vue';
import UserDropdown from '@/components/Rail/UserDropdown/UserDropdown.vue';
import GroupDropdown from '@/components/Rail/GroupDropdown/GroupDropdown.vue';
import TableLevelChange from '@/components/Rail/TableLevelChange/TableLevelChange.vue';
import Loading from '@/views/Loading.vue';

export default {
    name: 'Groups-Single',

    components: {
        Hero,
        UserDropdown,
        GroupDropdown,
        Loading,
        TableLevelChange,
    },

    mixins: [
        AuthMixin,
        DataMixin,
    ],

    data() {
        return this.initialState();
    },

    created() {
        this.initialize();
    },

    methods: {
        initialState() {
            return {
                isLoading: true,
                form: {
                    user: null,
                    group: null,
                    status: null,
                    statusTypes: [
                        { value: 1, text: 'Pendentes' },
                        { value: 2, text: 'Aprovados' },
                        { value: 3, text: 'Rejeitados' },
                    ],
                }
            };
        },

        async validateAccessPermission() {
            return await this.allowOnlyRoles(['coach', 'tracker_manager', 'tracker_admin', 'rail_manager', 'rail_admin']);
        },

        async initialize() {
            this.isLoading = true;
            await this.validateAccessPermission();
            this.isLoading = false;
        },

        /**
         * retrieve tab from TableRequests and set computedTab
         * @param {String|Number} item 
         */
        selectTab(item) {
            // reset status input filter
            this.form.status = null;

            // set tab 
            this.computedTab = item;
        }
    },
}