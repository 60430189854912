import { mapGetters } from 'vuex';

export default {
  name: 'CardStatisticsVertical',

  props: {
    name: { type: String, required: true, default: 'Estatística' },
    value: { type: Number, required: true, default: 0 },
    icon: { type: String, required: true, default: 'mdi-checkbox-blank-outline' },
    borderColor: { type: String, required: false, default: 'primary' },
    valueColor: { type: String, required: false, default: '' },
    showCurrency: { type: Boolean, required: false, default: false },
    loading: { type: Boolean, required: false, default: true },
    viewMoreDetailsLink: { type: String, required: false, default: '' },
    currencyIcon: { type: String, required: false, default: 'mdi-help' },
    precision: { type: Number, required: false, default: 2 },
    showSecondValue: { type: Boolean, required: false, default: false },
    secondValue: { type: Number, required: false, default: 0 },
    secondValueCurrencyIcon: { type: String, required: false, default: 'mdi-help' },
    showColor: { type: Boolean, required: false, default: true },
    showCurrencyInputValue: { type: Boolean, required: false, default: true },
    showIconTooltip: { type: Boolean, required: false, default: false },
    iconTooltipMessage: { type: String, required: false, default: '' },
  },

  computed: {
    ...mapGetters({
      themeDarkMode: 'UI/themeDarkMode',
    }),

    valueColorFinal() {
      let output = this.valueColor;

      if (!this.valueColor && this.themeDarkMode) {
        output = 'white';
      }

      if (this.showColor && this.value < 0) {
        output = 'red';
      } else if (this.showColor && this.value > 0) {
        output = '#4CAF50';
      }

      return output;
    },  
  },
  
  methods: {
    /**
    * @param {Number|String} value 
    * @returns {String} with width value
    */
    getCurrencyInputWidthStats(value) {
      let output = '50px';
      if (value) {
          output = parseFloat(value).toFixed(3);

          // turn output into String
          output = String(output);

          output = (output.length + 1) * 10 + 'px';
      }
      return output;
    },
  }
};