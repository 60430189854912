import { mapGetters } from 'vuex';
import UserCard from '@/components/UserCard/UserCard.vue';

export default {
  name: 'DialogUploadResume',
  
  props: {
    isOpen: {
      type: Boolean,
      required: true,
      default: false,
    },
  },

  components: {
    UserCard
  },

  computed: {
    ...mapGetters({
      usersAllActive: 'users/usersAllActive',
      statsBatchLoading: 'stats/statsBatchLoading',
      statsBatch: 'stats/statsBatch',
    }),
  },

  data() {
    return {
        title: 'Resumo da importação de Stats',
        headers: [
          { text: 'Nome', value: 'user' },
          { text: 'Stats', value: 'value', width: '82px' },
          { text: 'Avaliação', value: 'status', width: '106px' },
        ],
    };
  },

  methods: {
    closeDialog() {
      this.$emit('close-dialog');
    },

    imageCutout(item) {
      let placeholder = require('@/assets/images/players/player-example.png');
      return item && item.user_target.imgCutout ?  item.user_target.imgCutout : placeholder;
  },
  },
};