import Vue from 'vue';
import { inject } from 'vue';
import { mapGetters } from 'vuex';
import EmptyStateNoData from '@/components/EmptyStateNoData';

export default {
    props: {
        playersUp: {
            type: Array,
            required: true,
            default: () => []
        },
        playersDown: {
            type: Array,
            required: true,
            default: () => []
        },
        direction: {
            type: String,
            required: false,
            default: null,
        },
        index: {
            type: Number,
            required: false,
            default: null,
        },
        maxHeight: {
            type: String,
            required: false,
            default: null,
        }
    },

    components: {
        EmptyStateNoData,
    },

    computed: {
        ...mapGetters({
            countries: 'countries/countries',
            livePlayers: 'livePlayers/livePlayers',
            livePlayersLoading: 'livePlayers/livePlayersLoading',
            error: 'livePlayers/error',
            humanError: 'livePlayers/humanError',
            pokerTablePlayers: 'liveGame/pokerTablePlayers',
            pokerTablePlayersLoading: 'liveGame/pokerTablePlayersLoading',
            selectedPokerTablePlayer: 'liveGame/selectedPokerTablePlayer',
        }),

        skeletonLoadingComputed() {
            // return this.$vuetify.breakpoint.mdAndUp
            //     ? 7
            //     : 5;
            return 7;
        },

        filteredLivePlayers: {
            get() {
                let playersToFilter = this.players && this.players.length > 0 ? this.players : this.livePlayers;
                if (this.dataTable.search) {
                    return this.livePlayers
                }
        
                if (playersToFilter === null || playersToFilter.length === 0 && this.getAddedPlayers().length === 0) {
                    return this.livePlayers;
                }
        
                return playersToFilter;
            },
            set(value) {
                this.players = value;
            }
        }
    },

    data(){
        return this.initialState();
    },

    inject: ['clearDirection', 'updatePlayersUp', 'updatePlayersDown', 'getIsTableFull', 'getAddedPlayers', 'isPlayerInTable'],

    async created(){
        await this.fetchData();
        this.$root.$on('remove-player-from-list', this.removePlayerFromList);
        this.$root.$on('clear-players', this.clearPlayers);
    },

    mounted() {
        const addedPlayers = this.playersUp.concat(this.playersDown);

        this.filteredLivePlayers = this.livePlayers.filter((livePlayer) => {
            return !addedPlayers.some((addedPlayer) => addedPlayer.id === livePlayer.id);
        });
    },

    methods: {
        initialState() {
            return {
                submitting: false,
                typingTimeout: null,
                dataTable: {
                    search: null,
                },
                players: [],
            }
        },

        getCardHeight() {
            if(this.maxHeight){
                return 'max-height: ' + this.maxHeight;
            }
            if(this.$vuetify.breakpoint.smAndDown){
                return 'height: calc(100vh - 420px);';
            }
            return 'height: calc(100vh - 500px);';
            },

        setActiveItem(item) {
            if (item) {
              this.$store.commit(
                'livePlayers/setSelectedPlayer',
                item
              );
            }
        },

        removePlayerFromList(player) {
            this.filteredLivePlayers = this.filteredLivePlayers.filter((livePlayer) => {
                return livePlayer.id !== player.id;
            });
        },

        addPlayerToTablePoker(player) {
            if (this.getIsTableFull()) {
                return;
            }
            
            this.setActiveItem(player);
            this.$root.$emit('remove-player-from-list', player);

            if (!this.direction) {
                this.addPlayerToAvailablePosition(player);
            } else {
                this.addPlayerToDirectionPosition(player);
            }
        
            this.clearDirection();
        },
        
        addPlayerToAvailablePosition(player) {
            let added = this.addPlayerToPosition(player, this.playersUp, 'U');
        
            if (!added) {
                this.addPlayerToPosition(player, this.playersDown, 'D');
            }
        },
        
        addPlayerToPosition(player, positionArray, directionPrefix) {
            for (let i = 0; i < positionArray.length; i++) {
                if (Object.keys(positionArray[i]).length === 0) {
                    this.$set(positionArray, i, player);
                    this.addPlayer(player, `${directionPrefix}${i}`);
                    return true;
                }
            }
            return false;
        },
        
        async addPlayerToDirectionPosition(player) {
            switch (this.direction) {
                case 'up':
                    this.$set(this.playersUp, this.index, player);
                    await this.addPlayer(player, `U${this.index}`);
                    break;
                case 'down':
                    this.$set(this.playersDown, this.index, player);
                    await this.addPlayer(player, `D${this.index}`);
                    break;
                default:
                    break;
            }
            this.closeDialog();
        },

        async addPlayer(player, position) {
            let payload = {};
            if(!this.pokerTablePlayers){
                await this.$store.dispatch('liveGame/addPlayerToPokerTable', {
                    players: [{...player, table_position: position}]
                });
                setTimeout(async () => {
                    await this.$store.dispatch('liveGame/getPokerTable');
                }, 1000);
                return;
            }
            const oldPlayers = this.pokerTablePlayers.players || [];
            payload = {
                id: this.pokerTablePlayers.id,
                players:[...oldPlayers, {...player, table_position: position}],
                owned_by: this.pokerTablePlayers.owned_by,
            }

            return this.$store.dispatch('liveGame/updatePokerTable', payload);
        },

        closeDialog() {
            this.$emit('close-dialog')
        },

        clearPlayers(player) {
            if (player) {
                this.updatePlayersUp(this.playersUp.filter((p) => p.id !== player.id));
                this.updatePlayersDown(this.playersDown.filter((p) => p.id !== player.id));

                const addedPlayers = this.playersUp.concat(this.playersDown);

                this.filteredLivePlayers = this.livePlayers.filter((livePlayer) => {
                    return !addedPlayers.some((addedPlayer) => addedPlayer.id === livePlayer.id);
                });

                return;
            }

            this.filteredLivePlayers = this.livePlayers;
        },

        searchPlayer() {
            // Limpa o temporizador anterior para evitar múltiplas execuções
            clearTimeout(this.typingTimeout);
            // Define um novo temporizador que chama 'searchPlayer' após 1 segundo de inatividade
            this.typingTimeout = setTimeout(() => {
                    this.fetchData()
                }, 1000);
        },

        async fetchData() {
            let payload = {};

            if (this.dataTable.search) {
                payload.search = encodeURIComponent(this.dataTable.search);
            }
            
            await this.$store.dispatch('livePlayers/fetchLivePlayers', payload);
        },

        getCodeFromCountry(name) {
            let output = 'unknown.svg';

            if (!this._.isEmpty(this.countries) && name) {
                let countryObject = this.countries.find(e => e.name == name);
                if (countryObject) {
                    output = countryObject.code + '.svg'
                }
            }

            return output;
        },

        handlePlayerName(user) {
            let output = '';
        
            if (user && user.name) {
                // Divide o nome em palavras
                const names = user.name.split(' ');
                
                // Pega a primeira letra dos dois primeiros nomes
                if (names.length > 1) {
                    output = names[0][0] + names[1][0];
                } else if (names.length === 1) {
                    output = names[0][0];
                }
            }
        
            return output.toUpperCase(); // Converte para maiúsculas
        },
    }
}