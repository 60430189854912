import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth';
import User from '@/mixins/User.vue';

export default {
	name: 'Sidebar',

	components: {},

	mixins: [
    AuthMixin,
    User,
  ],

	computed: {
		...mapGetters({
			userAuthenticated: 'auth/userAuthenticated',
			userType: 'auth/userType',
			userRoles: 'auth/userRoles',
		}),

		sidebarStatus: {
			get() {
				return this.$store.state.UI.sidebarStatus;
			},
			set(value) {
				this.$store.commit('UI/setSidebarStatus', value);
			},
		},

    getRecruitmentsRoutes(){
      const routes = [
        { name: 'Candidaturas', icon: 'mdi-account-plus-outline', link: '/applications/', show: this.userHasRole(['rail_admin', 'tracker_admin', 'applications_manager', 'team_cash_manager']), },
        { name: 'Contratos', icon: 'mdi-file-outline', link: '/contracts/', show: this.userHasRole(['tracker_admin', 'super_coach']), },
        { name: 'Saídas', icon: 'mdi-account-minus-outline', link: '/departures/', show: this.userHasRole(['rail_admin', 'tracker_admin', 'super_coach']), },
      ];
      return routes.filter(item => item.show);
    },

    getTeamsRoutes(){
      const routes = [
        { name: 'Jogadores', icon: 'mdi-account-multiple-outline', link: '/players/', show: this.userHasRole(['rail_admin', 'rail_manager', 'tracker_admin', 'tracker_manager', 'super_coach']), },
        { name: 'Nicknames', icon: 'mdi-badge-account-outline', link: '/nicknames/', show: !this.userIsPlayer, },
        { name: 'Níveis', icon: 'mdi-folder-account-outline', link: '/groups/', show: this.userHasRole(['rail_admin', 'rail_manager', 'tracker_admin', 'tracker_manager', 'super_coach']), },
        { name: 'Progressão', icon: 'mdi-swap-vertical-circle-outline', link: '/levelchange/', show: this.userHasRole(['tracker_manager', 'tracker_admin', 'rail_manager', 'rail_admin']), },
        { name: 'Equipas', icon: 'mdi-account-supervisor-circle-outline', link: '/teams/', show: this.userHasRole(['rail_admin', 'rail_manager', 'tracker_admin', 'tracker_manager', 'super_coach']), },
        { name: 'Stats', icon: 'mdi-chart-line', link: '/stats/', show: this.userHasRole(['tracker_manager', 'tracker_admin', 'rail_manager', 'rail_admin']), },
        { name: 'Contratos', icon: 'mdi-scale-balance', link: '/contracts/', show: this.userHasRole(['rail_admin', 'rail_manager', 'tracker_admin', 'tracker_manager']), },
      ];
      return routes.filter(item => item.show);
    },

    getGamesRoutes(){
      const routes = [
      { name: 'Jogos', icon: 'mdi-cards-outline', link: '/games/', show: this.userHasRole(['rail_admin', 'rail_manager', 'tracker_admin', 'tracker_manager']), },
      { name: 'Entradas', icon: 'mdi-format-list-checkbox', link: '/gameEntries/', show: !this.userIsPlayer, },
      { name: 'Anomalias', icon: 'mdi-alarm-light-outline', link: '/anomalies/', show: this.userIsManager, },
      ];
      return routes.filter(item => item.show);
    },

    getToolsRoutes(){
      const routes = [
      { name: 'Importar ficheiros', icon: 'mdi-import', link: '/tools/pokercraft/', show: !this.userIsPlayer, },
      ];
      return routes.filter(item => item.show);
    },
    
    getVideosRoutes(){
      const routes = [
      { name: 'Video Requests', icon: 'mdi-video-check-outline', link: '/videos/requests/', show: this.userHasRole(['rail_admin', 'tracker_admin', 'team_cash_manager', 'video_manager']), },
      { name: 'Video Reports', icon: 'mdi-text-box-outline', link: '/videos/reports/', show: this.userHasRole(['rail_admin', 'tracker_admin', 'team_cash_manager', 'video_reports_reviewer_team_mtt', 'video_reports_reviewer_team_cash']), },
      ];
      return routes.filter(item => item.show);
    },

    getSettingsRoutes(){
      const routes = [
      { name: 'Salas', icon: 'mdi-format-list-checkbox', link: '/networks/', show: this.userHasRole(['rail_admin', 'rail_manager', 'tracker_admin', 'tracker_manager', 'super_coach']), },
      ];
      return routes.filter(item => item.show);
    },

    getPlayerDefaultRoutes(){
      const routes = [
      { name: 'Rail', icon: 'mdi-bank-outline', link: '/rail/', show: !this.userIsManager, },
      { name: 'Schedules', icon: 'mdi-cards-variant', link: '/schedules/me', show: this.getSchedulesPermissions() },
      { name: 'Jogos', icon: 'mdi-cards-outline', link: '/gameEntries/', show: !this.userIsManager, },
      { name: 'Nicknames', icon: 'mdi-badge-account-outline', link: '/nicknames/', show: !this.userIsManager, },
      { name: 'Vídeos', icon: 'mdi-video-outline', link: '/videos/requests/', show: !this.userIsManager, },
      { name: 'Anomalias', icon: 'mdi-alarm-light-outline', link: '/anomalies/', show: !this.userIsManager, },
      ];
      return routes.filter(item => item.show);
    },

    canUserViewManagementTabSidebar: function () {
			return this.userHasRole([
        'tracker_admin',
        'tracker_manager',
        'rail_admin',
        'rail_manager',
				'team_cash_manager',
				'applications_manager',
        'super_coach',
        'coach',
				'video_reports_reviewer_team_mtt',
				'video_reports_reviewer_team_cash',
        "scheduling_manager",
			]);
    },
    
    isLivePlayer: function () {
      let output = false;
      if (this.userHasRole(['live-player-access']) || this.userHasRole(['rail_admin', 'tracker_admin', 'rail_manager', 'tracker_manager'])) {
        output = true;
      }
      return output;
    },
	},

  methods: {
    getSchedulesPermissions() {
      if (this.userHasRole(['player'])) {
          return this.userIsDivision('mtt');
      }
      else if (this.userHasRole(['scheduling_manager'])) {
          return true;
      }
      return false;
    },
  }
};