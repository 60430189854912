import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth';
import UserNotes from '@/components/UserNotes/UserNotes.vue';

export default {
	name: 'DialogUserNotes',

	props: {
		isOpen: { type: Boolean, required: true, default: false },
		notes: { type: Array, required: true, default: () => [] },
		stateToPopulate: { type: String, required: true, default: 'userNotes' },
    },
    
    components: {
        UserNotes,
    },

    mixins: [
        AuthMixin
    ],
	
	data() {
		return this.initialState();
	},

	computed: {
		...mapGetters({
			userNotes: 'users/userNotes',
			userTargetNotes: 'users/userTargetNotes'
		}),

		notesComputed: function () {
			return this.stateToPopulate == 'userNotes'
				? this.userNotes
				: this.userTargetNotes;
		}
	},

	methods: {
		initialState() {
			return {
				
			};
		},

		closeDialog() {
			this.$emit("close-dialog");
		},
	},
};