import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth.vue';

export default {
    name: 'CardStatistics',

    mixins: [
      AuthMixin,
    ],

    props: {
      name: { type: String, required: true, default: 'Estatística' },
      value: { type: Number, required: true, default: 0 },
      valuePrecision: { type: Number, required: false, default: 2 },
      icon: { type: String, required: true, default: 'mdi-checkbox-blank-outline' },
      borderColor: { type: String, required: false, default: 'primary' },
      valueColor: { type: String, required: false, default: null },
      showCurrency: { type: Boolean, required: false, default: false },
      showAltCurrency: { type: Boolean, required: false, default: false },
      percentage: { type: String, required: false, default: null },
      loading: { type: Boolean, required: false, default: true },
      viewMoreDetailsLink: { type: String, required: false, default: '' },
      currencyIcon: { type: String, required: false, default: 'mdi-help' },
      currencyInputWidth: { type: String, required: false, default: '175px' },
      showIconTooltip: { type: Boolean, required: false, default: false },
      iconTooltipMessage: { type: String, required: false, default: 'Os montantes demonstrados são referentes aos swaps que já foram pagos.' },
      showCurrencyInputValue: { type: Boolean, required: false, default: true },
      intValue: { type: Boolean, required: false, default: false },
      iconTooltipMessage: { type: String, required: false, default: 'Mensagem tooltip default' },
    },

    computed: {
      ...mapGetters({
        themeDarkMode: 'UI/themeDarkMode',
      }),

      percentageColor() {
        if (this.percentage > 0)
          return 'green';
        else if (this.percentage < 0)
          return 'red';
        else
          return 'gray'
      },

      percentageIcon() {
        if (this.percentage > 0)
          return 'mdi-arrow-top-right';
        else if (this.percentage < 0)
          return 'mdi-arrow-bottom-right';
        else
          return 'mdi-circle-small'
      },

      valueColorFinal() {
        let output = this.valueColor;

        if (! this.valueColor && this.themeDarkMode) {
          output = 'white';
        }

        return output;
      },
    }
  };