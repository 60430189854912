import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth.vue';
import DataMixin from '@/mixins/Data.vue';
import moment from 'moment';
import CalendarEvent from '../CalendarEvent/CalendarEvent.vue';

export default {
	data() {
		return {
			navigate: new Date(),
			selectedDate: new Date(),
			eventsOfDay: [],
			nowValue: moment().format('YYYY-MM-DD'),
			loading: true,
			weekdays: [0, 1, 2, 3, 4, 5, 6],
			filterByMyEvents: 'myEvents',
			filteredEvents: {},
			toggleItems: [
				{ label: 'Meus', value: 'myEvents', color: 'success' },
				{ label: 'Todos', value: 'allEvents', color: 'success' },
			],
			attrs:[
				{
					// Boolean
					dot: true,
					dates: [
					  new Date(2023, 0, 1),
					 
					],
				  },
			]
		};
	},
	
	created() {
		this.init()
	},
	
	mixins: [
		AuthMixin,
		DataMixin,
	],
	
	components: {
		CalendarEvent
	},
	
	computed: {
		...mapGetters({
			events: 'eventsCalendar/data',
			userTrackerId: 'auth/userTrackerId',
		}),
		
		currentMonth() {
			if(typeof this.navigate == null){
				this.navigate = this.selectedDate
			}
			const date = moment(this.navigate);
			const formattedDate = date.format('MMMM YYYY');
			return formattedDate;
		}
	},
	
	watch: {
		navigate(val) {
			this.nowValue = moment(this.navigate).format('YYYY-MM-DD');
		},
		
		filterByMyEvents: {
			immediate: true,
			async handler(newVal) {
				this.filteredEvents = this.filterEvents()
				this.selectedDay(this.selectedDate)
			}
		},
	},
	
	methods: {
		async fetchEvents(payload = null) {
			this.loading = true
			await this.$store.dispatch('eventsCalendar/get', payload);
			this.filteredEvents = this.filterEvents()
			this.eventsOfDay = this.filterByDate(new Date())
			this.loading = false
		},

		checkEventsOnDate(date){
			let result = false
			const actualDate = moment(date)

			let myEvents = null;
			if(this.filterByMyEvents == 'allEvents'){
				myEvents = this.filteredEvents
			}else{
				myEvents = this.events.filter(e =>(! _.isNull(e.organizer) && e.organizer.id_users == this.userTrackerId));
			}

			if(myEvents.length>0){
				result = myEvents.find((event)=>{
					const [eventDate] = this.convertTimestampToDate([event])
					const eventMomentDate = moment(eventDate.startEventOfDay)
					return eventMomentDate.isSame(actualDate)
				})
			}
			return result
		},

		hasEvents(date) {
			let hasEventsOnDate =  this.checkEventsOnDate(date)
			return (
				hasEventsOnDate
			);
		  },

		filterEvents() {
			let output = null;
			if (this.filterByMyEvents == 'allEvents') {
				output = this.events;
			} else {
				output = this.events.filter(e => e.user_is_enrolled == true || (! _.isNull(e.organizer) && e.organizer.id_users == this.userTrackerId));
			}
			return output
		},
		
		customWeekdayFormat(day) {
			const weekdays = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
			return weekdays[day.weekday];
		},
		
		prevMonth() {
			this.navigate = new Date(this.navigate)
			if (!this.navigate) {
				return;
			}
			
			this.navigate = new Date(
				this.navigate.getFullYear(),
				this.navigate.getMonth() - 1,
				this.navigate.getDate()
			);
		},
			
		async init() {
			await this.fetchEvents();
		},
			
		nextMonth() {
			this.navigate = new Date(this.navigate)
			if (!this.navigate) {
				return;
			}
			
			this.navigate = new Date(
				this.navigate.getFullYear(),
				this.navigate.getMonth() + 1,
				this.navigate.getDate()
			);
		},
			
		selectedDay(date) {
			this.selectedDate = date;
			this.eventsOfDay = this.filterByDate(this.selectedDate)
		},
		
		convertDateToTimestamp(date){
			const startOfDay = moment(date, 'YYYY-MM-DD').startOf('day').valueOf();
			const endOfDay = moment(date, 'YYYY-MM-DD').endOf('day').valueOf();
			
			return [startOfDay, endOfDay];
		},
		
		convertTimestampToDate(evt){
			let filteredEvents = []
			evt.map((item)=>{
				let startEvent = moment(parseInt(item.datetime_start)).format('HH:mm');
				let endEvent = moment(parseInt(item.datetime_end)).format('HH:mm'); 
				item.startEventOfDay =  moment(parseInt(item.datetime_start)).format('YYYY-MM-DD')
				item.timeRange = `${startEvent} - ${endEvent}`;
				item.formattedStartDate = moment(parseInt(item.datetime_start)).format('dddd, D [de] MMMM');
				filteredEvents.push(item) 
			})

			return filteredEvents
		},
		
		filterByDate(data) {
			const rangeDateInTimestamp = this.convertDateToTimestamp(data.date || data);
			const [startOfDay, endOfDay] = rangeDateInTimestamp;
			
			if(this.filteredEvents.length>0) {
				const filteredEvts = this.filteredEvents.filter(item => {
					const datetimeStart = parseInt(item.datetime_start);
					return datetimeStart >= startOfDay && datetimeStart <= endOfDay;
				});
				if(filteredEvts){
					return this.convertTimestampToDate(filteredEvts);
				}
				return []
			}
			return []
		}
	}
};

