var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.entries,"items-per-page":25},scopedSlots:_vm._u([{key:"item.nickname.nickname",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"text-decoration-none",attrs:{"target":"_blank","to":'/nicknames/' + item.nickname.id + '/'}},[_vm._v(" "+_vm._s(item.nickname.nickname)+" ")])]}},{key:"item.nickname.userName",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"text-decoration-none",attrs:{"target":"_blank","to":'/players/' + item.nickname.userId + '/'}},[_vm._v(" "+_vm._s(item.nickname.userName)+" ")])]}},{key:"item.pnl",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":item.pnl > 0 ? 'success' : 'error'}},[_vm._v(" "+_vm._s(item.pnl)+" "+_vm._s(_vm.currency)+" ")])]}},{key:"item.roi",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":item.roi > 0 ? 'success' : 'error'}},[_vm._v(" "+_vm._s(item.roi)+" % ")])]}},{key:"item.multiEntries",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.multiEntries || 0)+" ")]}},{key:"item.rebuys",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.rebuys || 0)+" ")]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }