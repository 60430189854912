import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';

Vue.use(Vuex);

const state = {
    users: [],
    selectedUser: null,
    usersStatuses: null,
    hasLoaded: null,
};

const getters = {
    users: state => state.users,
    selectedUser: state => state.selectedUser,
    usersStatuses: state => state.usersStatuses,
    hasLoaded: (state) => state.hasLoaded
};

const mutations = {
    setUsers: (state, payload) => { state.users = payload; },
    setSelectedUser: (state, payload) => { state.selectedUser = payload; },
    setUsersStatuses: (state, payload) => { state.usersStatuses = payload; },
    setHasLoaded(state, payload) {
        state.hasLoaded = payload;
    },
    addUser: (state, payload) => {
        if (! state.users || ! state.users.length)
            state.users = [];
        
        state.users.unshift(payload);
    },

    updateUser: (state, payload) => {
        // For this commented approach to work, await would be necessary, or closeDialog would clear the selectedDeparture
        // const index = state.users.indexOf(state.setSelectedUser);
        // state.users[index] = payload[0];

        const index = state.users.findIndex(d => d.id_users == payload.id_users);

        const updatedItems = [
            ...state.users.slice(0, index),
            payload,
            ...state.users.slice(index + 1)
        ];

        state.users = updatedItems;
    },

    deleteDeparture: (state, payload) => {
        const index = state.users.findIndex(d => d.id_users == payload.id_users);
        state.users.splice(index, 1);
    }
};

const actions = {
    async getUsers({ commit }, payload) {
        commit("setHasLoaded", false);
        
        let requestUrl = Vue.prototype.$url_api + 'v1/users.php';

        await axios
        .get(requestUrl)
        .then(result => {
            commit('setUsers', result.data.data);
        })
        .catch(err => {
            //console.log(err);
        });

        commit("setHasLoaded", true);
    },

    async getUsersStatuses({ commit }, payload) {
        let requestUrl = Vue.prototype.$url_api + 'v1/users_types.php';

        await axios
        .get(requestUrl)
        .then(result => {
            commit('setUsersStatuses', result.data.data);
        })
        .catch(err => { console.log(err); });
    },

    async updateUser({ state, commit }, payload) {
        // Updating needs a selectedUser set
        if ( ! state.selectedUser.id_users )
            return false;

        // Testing
        //state.selectedUser.id_users = 185;

        // Build request
        let requestUrl = Vue.prototype.$url_api + 'v1/users.php?id=' + state.selectedUser.id_users;
        
        let requestParameters = new URLSearchParams();
        for (let key in payload) {
            if (payload[key])
                requestParameters.append(key, payload[key]);
        }

        // Send request
        await axios
        .put(requestUrl, requestParameters)
            .then(result => {
                commit('updateUser', result.data.data[0]);
            }
        )
        .catch(err => {
            //console.log(err);
        });
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
