import { mapGetters } from 'vuex';
import AlertError from '@/components/Alerts/AlertError/AlertError.vue';
import TeamImageDropdown from '@/components/TeamImageDropdown/TeamImageDropdown.vue';


export default {
  name: 'DialogAddEditTeam',
  props: {
    isOpen: {
      type: Boolean,
      required: true,
      default: false,
    },
    team: {
      type: Object,
      required: false,
      default: null,
    },
    checkFields: {
      type: Boolean,
      required: false,
      default: null,
    },

    action: {
			type: String,
			required: true,
			default: 'create',
			validator: function (value) {
				const allowedActions = ['create', 'update']
				return allowedActions.includes(value);
			},
		},
  },

  computed: {
    ...mapGetters({
      teams: 'teams/teams',
      error: 'teams/error',
      humanError: 'teams/humanError',
      selectedTeam: 'teams/selectedTeam',
    }),

    displayProfitShare: function () {
      return (this.form.profitShare * 100).toFixed(0);
    },
  },

  components:{
    AlertError,
    TeamImageDropdown
  },

  data() {
    return {
        title: '',
        submitting: false,
        validForm: false,
        navBar: null,
        fieldsEmpty: false,
        checked: this.checkFields,
      form: {
        name: '',
        type: null,
        contractDuration: null,
        image: null,
        profitShare: null,
        code: null,
        ranking: null,
        numberContract: null,
        numberEvents: null,
        monthlySessions: null,
        monthlyEvents: null,
        groupsGroupID: null,
        buddyBossGroupId: null,
      },
      rules: {
        name: [ 
          v => {
            return !!v || 'Por favor introduza um nome para o grupo'
          }  
        ],
        profitShare:[ v => !this._.isNull(v) && this._.inRange(v, 0, 101) || 'Por favor introduza um número entre 0 e 100' ],
        code: [
          v => {
            return !!v || 'Por favor introduza um código'
          }
        ],
        ranking: [
          v => {
            return v>=0 || 'Por favor introduza um ranking'
          }
        ],
        type: [
          v => {
            return !!v || 'Por favor introduza um tipo'
          }
        ],
        contractDuration: [
          v => {
            return !!v || 'Por favor introduza um valor'
          }
        ],
        numberContract: [
          v => {
            return !!v || 'Por favor introduza um número de sessões do contrato'
          }  
        ],
        numberEvents: [
          v => {
            return !!v || 'Por favor introduza um número de eventos do contrato'
          }  
        ],
        monthlySessions: [
          v => {
            return !!v || 'Por favor introduza um número de sessões mensalmente'
          }  
        ],
        monthlyEvents: [
          v => {
            return !!v || 'Por favor introduza um número de eventos mensalmente'
          }  
        ],
        image: [ 
          v => !!v || 'Por favor introduza um imagem para o grupo',
      ],
    },
      teamTypes: [
        { text: 'Verdadeiro (Real)', value: 1},
        { text: 'De Apoio (Helper)', value: 2},
      ],
      navBarOptions:['Dados Contratuais', 'Dados Técnicos'],
      rankings:[
        {text: 'Division', value: 0},
        {text: 'Academy', value: 1},
        {text: 'Principal', value: 2},
      ],
      contractDurations: [
        { text: '1 mês', value: 30 },
        { text: '3 meses', value: 90 },
        { text: '6 meses', value: 180 },
        { text: '1 ano', value: 360 },
        { text: '1 ano e meio', value: 540 },
      ],

    };
  },

  watch: {
    isOpen() {
      if (this.isOpen) {
        this.$store.dispatch('teams/clearError')
        this.resetForm();
        this.dialogTitle();
        this.isFormValid();
        this.populateForm();
      }
    },

    selectedTeam() {
      if (this.isOpen) {
        this.dialogTitle();
        this.isFormValid();
        this.populateForm();
      }
    }
  },
  methods: {

    toggleSubmitting(){
      this.submitting = !this.submitting;
      
    },

    blockNumbers(event) {
      if (event.keyCode >= 48 && event.keyCode <= 57) {
        event.preventDefault();
      }
      if (event.keyCode >= 96 && event.keyCode <= 105) {
        event.preventDefault();
      }
    },

    isFormValid() {
      if(this.form.name === ''){
        return this.validForm = false
      }
      return this.validForm = true
    },
    
    dialogTitle() {
      this.title = this.action == 'create' ? 'Adicionar equipa' : 'Editar equipa';
    },

    closeDialog(){
      this.$emit('close-dialog');
      this.resetForm();
    },

    populateForm() {
      if (this.selectedTeam && this.action == 'update') {
        this.form.name = this.selectedTeam.name;
        this.form.type = this.selectedTeam.type;
        this.form.contractDuration = this.selectedTeam.contractDuration;
        this.form.profitShare = this.selectedTeam ? this.selectedTeam.profitShare * 100 : null;
        this.form.code = this.selectedTeam.code;
        this.form.ranking = this.selectedTeam.ranking;
        this.form.numberContract = this.selectedTeam.contractNumSessions;
        this.form.numberEvents = this.selectedTeam.contractNumEvents;
        this.form.monthlySessions = this.selectedTeam.oblNumSessions;
        this.form.monthlyEvents = this.selectedTeam.oblNumEvents;
        this.form.groupsGroupID = this.selectedTeam.wp_groups_id_teams;
        this.form.buddyBossGroupId = this.selectedTeam.wp_bpgroups_id_teams;
        this.form.image =  this.selectedTeam.image;
      }
    },

    resetForm() {
      this.form.name = null;
      this.form.type = null;
      this.form.contractDuration = null;
      this.form.profitShare = null;
      this.form.code = null;
      this.form.ranking = null;
      this.form.numberContract = null;
      this.form.numberEvents = null;
      this.form.monthlySessions = null;
      this.form.monthlyEvents = null;
      this.form.groupsGroupID = null;
      this.form.buddyBossGroupId = null;
      this.form.image = null;
      this.$refs.formCreateTeam.reset();
      
      this.$store.dispatch('teams/clearError');
    },

    async submitForm() {
      if (!this.$refs.formCreateTeam.validate()) {
        return false;
      }

    this.toggleSubmitting();

    let { name, type, contractDuration, 
      profitShare, code, ranking, numberContract, 
      numberEvents, monthlySessions, monthlyEvents, groupsGroupID, buddyBossGroupId, image } = this.form;

    profitShare = ((profitShare / 100).toFixed(2)).toString();
    numberContract = Number(numberContract)
    numberEvents = Number(numberEvents)
    monthlySessions = Number(monthlySessions)
    monthlyEvents = Number(monthlyEvents)

    if(buddyBossGroupId === 0) buddyBossGroupId = null;
    if(groupsGroupID === 0) groupsGroupID = null;

    if(buddyBossGroupId !== null){
      buddyBossGroupId = Number(buddyBossGroupId)
    }
    
    if(groupsGroupID !== null){
      groupsGroupID = Number(groupsGroupID)
    }

    let payload = {
      body:{
        name,
        type,
        contractDuration,
        profitShare,
        code,
        ranking,
        image,
        contractSessions: numberContract,
        contractEvents: numberEvents,
        monthlySessions: monthlySessions,
        monthlyEvents: monthlyEvents,
        wpGroupsGroupId: groupsGroupID,
        wpBuddybossGroupId: buddyBossGroupId,
        }
      }
      
      if (this.action == 'update') {
        payload.id = this.selectedTeam.id;
      }

      let actionToDispatch = this.action == 'create'
        ? 'teams/create'
        : 'teams/update';
      
      let messageSnackbar = this.action == 'create'
        ? 'Equipa adicionada com sucesso.'
        : 'Equipa editada com sucesso.'

      let result = await this.$store.dispatch(actionToDispatch, payload);

      this.toggleSubmitting();
      
      if (result === true) {
        this.$store.dispatch('UI/showSnackbar', {
          message: messageSnackbar,
          color: 'success'
        });

        let pageNumber = {
          params: {
            page: 1,
            itemsPerPage : 10,
          }
        }

        await this.$store.dispatch('teams/get', pageNumber);
        this.closeDialog();
      }
    },
  },
};
