import { mapGetters } from 'vuex';

export default {
    name: 'RowEditCalculationFactors',

    data() {
        return this.initialState();
    },

    created() {
        this.init()
    },

    computed: {
        ...mapGetters({
            schedulingFactors: 'schedulingFactors/factors',
        }),
    },

    props: {
        index: {
            type: Number,
            required: true,
            default: 0
        },
        factors: {
            type: Array,
            required: true,
            default() {
                return []
            }
        },
        relationships: {
            type: Array,
            required: true,
            default() {
                return []
            }
        },
        selectedCalculationFactor: {
            type: Object,
            required: true,
            default() {
                return  {
                    factor1: -1,
                    factor2: undefined,
                    weight: 5,
                    relationship: 0
                }
            }
        },
    },

    methods: {
        initialState() {
            return {}
        },

        init() {
        },
         
        removeCalculationFactor(index){
            this.$emit('removeCalculationFactor', index)
        }
    }
}