import Vue from 'vue';
import { mapGetters } from 'vuex';
import CardStepper from '@/components/CardStepper/CardStepper.vue';
import CardStepperNotes from '@/components/CardStepper/CardStepperNotes/CardStepperNotes.vue';
import CardStepperResume from '@/components/CardStepper/CardStepperResume/CardStepperResume.vue';
import RailMixin from '@/mixins/Rail.vue';
import NicknameDropdown from '@/components/Rail/NicknameDropdown/NicknameDropdown.vue';
import RowTransfer from '@/components/Rail/RowTransfer/RowTransfer.vue';
import AlertError from '@/components/Alerts/AlertError/AlertError.vue';
import DialogViewAttachment from '@/components/Rail/Dialog/DialogViewAttachment/DialogViewAttachment.vue';
import DialogConfirmation from '@/components/Rail/Dialog/DialogConfirmation/DialogConfirmation.vue';


export default {
  name: 'BankrollReduction',

  components: {
    CardStepper,
    CardStepperNotes,
    CardStepperResume,
    NicknameDropdown,
    RowTransfer,
    AlertError,
    DialogViewAttachment,
    DialogConfirmation,
  },

  mixins: [
    RailMixin,
  ],

  computed: {
    ...mapGetters({
      selectedRequest: 'railRequests/selectedRequest',
      error: 'railRequests/error',
      humanError: 'railRequests/humanError',
    }),

    selectedRequestObservation: function () {
      return this.getObservationByPhase(this.selectedRequest.observations, Vue.prototype.table_requests_phases[Vue.prototype.$rail_ops.operations.bankroll_reduction][0]['value']);
    },

    hasInternalTransfers: function () {
      // find transfers type PNPW in selectedRequest
      let findInternalTransfers = this.selectedRequest.transfers.find(e => e.type == Vue.prototype.$transfer_types.PNPW);
      // returns false if doesn't find transfers type PNPW, else return true
      return findInternalTransfers == undefined ? false : true;
    },
  },

  watch: {
    selectedRequest: {
      handler() {
        // reset component state
        this.resetComponentState();
      },
      deep: true,
      immediate: true,
    }
  },

  data() {
    return this.initialState();
  },

  methods: {
    initialState() {
      return {
        cardTitles: [
          'Registo de transferência',
          'Resumo'
        ],
        formValidations: [
          false,
          true,
        ],
        checkboxTransferDone: false,
        form: {
          nickname: null,
          balance: null,
          walletSource: null,
          walletTarget: null,
          observation: null,
          gyazoLink: null,
          fileName: [],
          isTransferPending: true,
        },
        rules: {
          balance: [
            (v) => !!v || 'Por favor introduza o montante',
            (v) => !Number.isNaN(v) || 'Insira um valor numérico',
          ],
          checkbox: [
            (v) => !!v || '',
          ],
          dropdown: [
            (v) => !!v || 'Por favor introduza uma Sala/Carteira'
          ],
        },
        dialogues: {
          viewAttachment: false,
          confirmation: false,
        },
        observation: {
          url: null,
          type: null,
        },
      }
    },


    /**
    * Validates a specific form step.
    * Assumes form steps are named 'formStep_x' where x is a Int 0 - maxStep-1.
    * 
    * @param {Int} step Identifier of the form step to validate.
    * @returns {Void}
    */
    validateStep(step) {
      const formStepRef = 'formStep_' + (step);
      if (this.$refs[formStepRef]) {
        // Reset validation after enter step 1
        if (this.$refs.formStep_1) {
          this.$refs.checkboxRef.resetValidation();
        }

        // Update formValidation[step] in a way the DOM will be reactive
        Vue.set(
          this.formValidations,
          step - 1,
          this.$refs[formStepRef].validate()
        );

        // Clean errors if exists
        if (this.error) {
          this.$store.dispatch('railRequests/clearError');
        }
      }
    },

    /**
    * @returns {Array} passed as prop to RowTransfers Component
    */
    getTransfers() {
      let transfers = [];

      // checks if transfers isn't emtpy
      if (!this._.isEmpty(this.selectedRequest.transfers)) {
        this.selectedRequest.transfers.forEach(e => {
          let transferObject = {
            source: e.source,
            target: e.target,
            amount: e.amount
          }

          // retrieve internal transfers from Player
          if (e.type == Vue.prototype.$transfer_types.PNPW) {
            transfers.push(transferObject);
          }

          // retrieve transfers type Player to Management
          if (e.type == Vue.prototype.$transfer_types.P_M) {
            transfers.push(transferObject);
          }
        })
      }
      return transfers;
    },

    // retrieve fields from CardStepperNotes component
    getInfoFromCardStepperNotes(payload) {
      if (payload) {
        this.form.gyazoLink = payload.gyazoLink;
        this.form.fileName = payload.filename;
      }
    },

    async submit() {
      // Validate form
      if (!this.$refs.formBankrollReductionChild.validate()) {
        return false;
      }

      // Toggle submit button
      this.$refs.CardStepper.toggleSubmitting();

      // Base payload
      let payload = {
        id: this.selectedRequest.id,
      }

      // add body FormData
      payload.body = new FormData();

      // Add 'observation' field if necessary
      if (this.form.observation) {
        payload.body.append('observation[description]', this.form.observation);
      }

      // Add 'screenshot' field if necessary
      if (!this._.isEmpty(this.form.fileName)) {
        payload.body.append('screenshot', this.form.fileName[0]);
      }

      // Add 'observation[url]' field if necessary
      if (this.form.gyazoLink) {
        payload.body.append('observation[url]', this.form.gyazoLink);
      }

      // Dispatch to store
      let result = await this.$store.dispatch('railRequests/bankrollReductionUpdate', payload);

      // Re-enable btn
      if (this.$refs.CardStepper) {
        this.$refs.CardStepper.toggleSubmitting();
      }

      // On success
      if (result === true) {
        // Refresh Requests Transfers and Balances
        this.updateRequestsTransfersAndBalances();

        // Show success snackbar
        this.$store.dispatch('UI/showSnackbar', {
          message: 'Pedido de redução de banca atualizado com sucesso.',
          color: 'success'
        });

        // change hasFinished state in order to change component
        this.$store.dispatch('TabSlider/setHasFinished', true);
      }
    },

    /**
    * Populates observation.url and observation.name
    * @param {Object} observation 
    * @returns {Boolean} open dialog or opens new page;
    */
    openDialogViewAttachment(observation) {
      if (observation && observation.url) {

        // validate observation type
        let isTypeLink = observation.type == 1;

        // populate fields
        this.observation.url = observation.url;
        this.observation.type = observation.type;

        return isTypeLink
          ? window.open(observation.url, '_blank')
          : this.dialogues.viewAttachment = true;
      }
    },

    checkTransferToManagement() {
      if (this.checkboxTransferDone) {
        this.dialogues.confirmation = true;
      }
    },

    dialogConfirmationConfirm() {
      this.checkboxTransferDone = true;
      this.dialogues.confirmation = false;
    },

    dialogConfirmationCancel() {
      this.checkboxTransferDone = false;
      this.dialogues.confirmation = false;
    },

    resetComponentState() {
      // force CardStepper to display 1 step
      this.$store.commit('UI/setCardStepperStep', 1);
      // reset data component state
      Object.assign(this.$data, this.initialState());
    },
  }
}