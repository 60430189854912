export default {
	name: 'DialogViewAttachment',

    props: {
        isOpen: { type: Boolean, required: true, default: false },
        url: { type: String, required: false, default: 'Link do anexo' },
        type: { type: Number, required: false, default: 1 }
	},
	
	methods: {
		closeDialog() {
			this.$emit('close-dialog');
		},
    },
};