import { mapGetters } from 'vuex';
import UserMixin from '@/mixins/User';
import AuthMixin from '@/mixins/Auth.vue';
import Alert from '@/components/Rail/Alert/Alert.vue';
import Activity from '@/components/Rail/Activity/Activity.vue';
import History from '@/components/Rail/History/History.vue';
import TableRequests from '@/components/Rail/TableRequests/TableRequests.vue';
import TableBalance from '@/components/Rail/TableBalance/TableBalance.vue';
import TableSessions from '@/components/Rail/TableSessions/TableSessions.vue';
import AreaChartCumulativePNL from '@/components/Charts/AreaChartCumulativePNL/AreaChartCumulativePNL.vue';
import CardStatistics from '@/components/Rail/Statistics/CardStatistics/CardStatistics.vue';
import CardStatisticsVertical from '@/components/Rail/Statistics/CardStatisticsVertical/CardStatisticsVertical.vue';
import CardMainStatistics from '@/components/Rail/Statistics/CardMainStatistics/CardMainStatistics.vue';
import UserEventList from '@/components/UserEventList/UserEventList.vue';
import UserGroupProgress from '@/components/UserGroupProgress/UserGroupProgress.vue';
import PlayerGroupBadge from '@/components/PlayerGroupBadge/PlayerGroupBadge.vue';

export default {
    name: 'Overview',

    components: {
        CardStatistics,
        TableRequests,
        TableBalance,
        Alert,
        AreaChartCumulativePNL,
        TableSessions,
        CardStatisticsVertical,
        CardMainStatistics,
        Activity,
        History,
        UserEventList,
        UserGroupProgress,
        PlayerGroupBadge,
    },

    mixins: [
        UserMixin,
        AuthMixin,
    ],

    data() {
        return this.initialState();
    },
    
    created() {
        this.fetchData();
    },

    computed: {
        ...mapGetters({
            userDisplayName: 'auth/userDisplayName',
            userGroup: 'auth/userGroup',
            userAvatar: 'auth/userAvatar',
            statistics: 'railStatistics/data',
            statisticsLoading: 'railStatistics/loading',
            reports: 'railReports/reports',
            heroDatePickerValue: 'UI/heroDatePickerValue',
            selectedUser: 'users/selectedUser',
            userTrackerId: 'auth/userTrackerId',
        }),

        cardInfo() {
            return [
                {
                    name: 'A Enviar',
                    value: this.statistics['IN_DEBT'] ? this.statistics['IN_DEBT'].value.EUR.value : 0,
                    icon: 'mdi-share',
                    borderColor: 'red',
                    valueColor: 'red',
                    showCurrency: true
                },
                {
                    name: 'A Receber',
                    value: this.statistics['DEBIT'] ? this.statistics['DEBIT'].value.EUR.value : 0,
                    icon: 'mdi-reply',
                    borderColor: '#4CAF50',
                    valueColor: '#4CAF50',
                    showCurrency: true
                },
            ];
        },

        cardInfoVertical() {
            return [
                {
                    name: 'Lucro',
                    value: this.statistics['PROFIT'] ? this.statistics['PROFIT'].value.EUR.value : 0,
                    icon: 'mdi-calculator-variant-outline',
                    showCurrency: true
                },
                {
                    name: 'Split',
                    value: this.statistics['SPLIT'] ? this.statistics['SPLIT'].value.EUR.value : 0,
                    icon: 'mdi-call-split',
                    showCurrency: true
                },
                {
                    name: 'Swap',
                    value: this.statistics['SWAP'] ? this.statistics['SWAP'].value.EUR.value : 0,
                    icon: 'mdi-autorenew',
                    showCurrency: true
                },
            ];
        },

        // Computed Property used in Table Balances
        user: function () {
            if (this.selectedUser) {
                return this.selectedUser;
            }
        },
    },

    watch: {
        heroDatePickerValue: function () {
            this.fetchData();
        },
    },

    methods: {
        initialState() {
            return {};
        },

        fetchData() {

            let payload = {}

            if (!this._.isEmpty(this.heroDatePickerValue)) {
                payload.dateBegin = this.heroDatePickerValue[0];
                payload.dateEnd = this.heroDatePickerValue[1];
            } else {
                payload.dateBegin = this.$moment().subtract(1, 'month').format('YYYY-MM-DD');
                payload.dateEnd = this.$moment().format('YYYY-MM-DD');
              }

            // Rail Statistics
            if (!this._.isEmpty(payload) && this.userIsPlayer) {
                this.$store.dispatch('railStatistics/get', payload);
            }

        },
    }
}