var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"d-flex align-center",attrs:{"flat":_vm.cardFlat,"tile":_vm.cardTile,"rounded":true}},[(_vm.loading)?[_c('div',{staticClass:"w-100 d-flex flex-column justify-center"},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"text-center"},[_c('v-progress-circular',{attrs:{"size":"50","color":"accent","indeterminate":""}})],1)],1)],1)]:[_c('v-list-item',{staticClass:"px-0"},[(_vm.showAvatar && _vm.animation == false)?_c('v-list-item-avatar',{attrs:{"size":"72","color":_vm.colorAvatar}},[_c('v-icon',{attrs:{"color":_vm.iconColor,"size":"32"}},[_vm._v(" "+_vm._s(_vm.icon)+" ")])],1):_vm._e(),_c('v-list-item-content',{staticClass:"overflow-visible pa-0"},[_c('div',{staticClass:"d-flex align-center"},[_c('v-list-item-title',{staticClass:"subtitle-2 font-weight-semibold d-flex"},[_vm._v(" "+_vm._s(_vm.title)+" "),(_vm.showTooltip)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}],null,false,1774462679)},[_c('span',[_vm._v(_vm._s(_vm.tooltipText))])]):_vm._e()],1),_c('span',{staticClass:"subtitle-2 grey--text text-no-wrap d-flex align-center"},[_c('span',[_vm._v(" "+_vm._s(_vm.valueCurrent)+" de "+_vm._s(_vm.valueTotal)+" ")]),(_vm.customGoal || _vm.pastTeamGoal)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"dense":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-account-edit ")])]}}],null,false,1773830816)},[(_vm.customGoal)?_c('span',[_vm._v(_vm._s(_vm.userIsPlayer ? 'Tens um objetivo personalizado para esta métrica.' : 'Este jogador tem um objetivo personalizado para esta métrica'))]):(_vm.pastTeamGoal)?_c('span',[_vm._v(_vm._s(_vm.pastTeamGoalMessage))]):_vm._e()]):_vm._e()],1)],1),(_vm.showSubtitle)?_c('v-list-item-subtitle',{staticClass:"caption grey--text text-wrap"},[_vm._v(" "+_vm._s(_vm.subtitle)+" ")]):_vm._e(),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"d-flex flex-column w-100"},[(_vm.animation == false)?_c('v-progress-linear',{staticClass:"mt-1",attrs:{"value":_vm.valuePercentageComputed,"height":_vm.height,"striped":_vm.striped,"rounded":_vm.rounded,"color":_vm.color}}):_vm._e(),(_vm.animation == true)?_c('v-progress-linear',{staticClass:"mt-1",attrs:{"value":_vm.actualPercent,"height":_vm.height,"striped":_vm.striped,"rounded":_vm.rounded,"color":_vm.color}}):_vm._e(),(_vm.showMonthProgress)?_c('v-tooltip',{attrs:{"bottom":"","color":_vm.getMonthProgressColor},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.showMonthProgress)?_c('div',_vm._g(_vm._b({staticClass:"mx-n1"},'div',attrs,false),on),[_c('v-slider',{ref:"slider",attrs:{"value":_vm.monthProgressComputed,"tick-labels":['0%', '25%', '50%', '75%', '100%'],"min":0,"max":100,"step":0,"height":"16","ticks":"always","tick-size":"3","readonly":"","hide-details":"","thumb-size":24,"track-color":"grey","track-fill-color":_vm.getMonthProgressColor,"thumb-color":_vm.getMonthProgressColor,"dense":""}}),_c('span',{staticClass:"d-flex justify-space-between caption w-100 mt-n2"},[_c('span',[_vm._v("0%")]),_c('span',[_vm._v("25%")]),_c('span',[_vm._v("50%")]),_c('span',[_vm._v("75%")]),_c('span',[_vm._v("100%")])])],1):_vm._e()]}}],null,false,2630468905)},[_c('span',[_vm._v(_vm._s(_vm.getTooltipMessage()))])]):_vm._e()],1),(_vm.animation == false)?_c('span',{staticClass:"body-2 font-weight-semibold text-no-wrap ml-2"},[_vm._v(" "+_vm._s(_vm.valuePercentageComputed)+"% ")]):_c('span',{staticClass:"body-2 font-weight-semibold text-no-wrap ml-2"},[_vm._v(" "+_vm._s(parseInt(_vm.actualPercent))+"% ")])])],1)],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }