import { mapGetters } from 'vuex';
import Hero from '@/components/Hero/Hero.vue';
import LoadingSpinner from '@/components/LoadingSpinner/LoadingSpinner.vue';

export default {
    name: 'GameGroups-Single',

    components: {
        Hero,
        LoadingSpinner
    },

    computed: {
        ...mapGetters({
            gameGroup: 'schedulingGameGroups/data',
            loading: 'schedulingGameGroups/loading',
        })
    },

    data() {
        return this.initialState();
    },

    created() {
        //console.log(this.loading);
        this.init();
    },

    methods: {
        initialState() {
            return {
                flags: [],
            }
        },

        init() {
            this.getSchedulingGameGroup(this.$route.params.id);
        },

        getBooleanIcon(atr) {
            return (atr) ? "mdi-check" : "mdi-close";
        },

        async getSchedulingGameGroup(id) {
            await this.$store.dispatch('schedulingGameGroups/getById', id);
            if(this.gameGroup.flags){
                this.flags = this.gameGroup.flags.split(",");
            }
        },
    }
}