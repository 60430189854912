import { mapGetters } from 'vuex';

export default {
    name: 'ExpenseTypeDropdown',

    props: {
        value: { type: [Number, Array], required: false, default: () => { } },
        label: { type: String, required: false, default: 'Categoria de despesa' },
        removeLastLevel: { type: Boolean, required: false, default: false },
        reload: { type: Boolean, required: false, default: true },
        dense: { type: Boolean, required: false, default: false },
        clearable: { type: Boolean, required: false, default: true },
        outlined: { type: Boolean, required: false, default: true },
        hideDetails: { type: Boolean, required: false, default: false },
        multiple: { type: Boolean, required: false, default: false },
    },

    computed: {
        ...mapGetters({
          expenseTypes: 'railExpenses/expenseTypes',
          loadingExpenseTypes: 'railExpenses/loadingExpenseTypes',
        }),

        // Synthetic v-model
        computedValue: {
            get: function () {
                return this.expense;
            },
            set: function (newValue) {
                    this.expense = newValue;
                    this.emitChange();
            }
        },

		// only return expense types level 0 and 1
		expenseTypesComputed: function () {
			let expenseTypesFiltered = this.expenseTypes;
			if (!this._.isEmpty(this.expenseTypes) && this.removeLastLevel) {
                expenseTypesFiltered = this.expenseTypes.filter(e => e.level == 0 || e.level == 1);
			}

			return expenseTypesFiltered;
		}

    },

    data() {
        return this.initialState();
    },

    async created() {
        this.initialize();
    },

    methods: {
        initialState() {
            return {
                expense: null
            };
        },

        initialize() {
            this.computedValue = this.value;
            this.fetchData();
        },

        fetchData() {
            // Get expense types
            if ((this._.isEmpty(this.expenseTypes) || this.removeLastLevel) && this.reload) {
                this.$store.dispatch('railExpenses/getTypes');
            }
        },

        emitChange() {
            this.$emit('input', this.computedValue);
            this.$emit('change', this.computedValue);
        },

        clearInputField() {
            this.computedValue = null;
        }
    },
}