import Vue from 'vue';
import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth.vue';
import RailMixin from '@/mixins/Rail.vue';
import NicknameDropdown from '@/components/Rail/NicknameDropdown/NicknameDropdown.vue';
import RowTransfer from '@/components/Rail/RowTransfer/RowTransfer.vue';
import InputFinancial from '@/components/InputFinancial/InputFinancial.vue';
import AlertError from '@/components/Alerts/AlertError/AlertError.vue';
import DialogConfirmation from '@/components/Rail/Dialog/DialogConfirmation/DialogConfirmation.vue';
import ExpenseTypeDropdown from '@/components/Rail/ExpenseTypeDropdown/ExpenseTypeDropdown.vue';

export default {
    name: 'DialogEditTransfer',
    
    components: {
        NicknameDropdown,
        RowTransfer,
        InputFinancial,
        AlertError,
        DialogConfirmation,
        ExpenseTypeDropdown
    },

	mixins: [
		AuthMixin,
		RailMixin,
    ],

	props: {
        isOpen: { type: Boolean, required: true, default: false },
	},

	data() {
		return this.initialState();
    },

    created() {
        this.populate();
    },

    computed: {
        ...mapGetters({
            users: 'users/usersAllActive',
            selectedTransfer: 'railTransfers/selectedTransfer',
            error: 'railTransfers/error',
            humanError: 'railTransfers/humanError',
            teamFilter: 'teams/teamFilter',
        }),

        sourceIsExternal() {
            return this.selectedTransfer.source.id == Vue.prototype.$rail_external_wallet_id;
        },

        targetIsExternal() {
            return this.selectedTransfer.target.id == Vue.prototype.$rail_external_wallet_id;
        },

        sourceIsManagement() {
            return this.selectedTransfer.source.isManagement;
        },

        targetIsManagement() {
            return this.selectedTransfer.target.isManagement;
        },

        // this.sourceIsManagement ? 'WALLET' : 'ANY'
        nicknameDropdownSourceProps() {
            let railOperationTargetUser = this.selectedTransfer.railOperation.targetUser
                ? this.selectedTransfer.railOperation.targetUser.id
                : null;
            
            let userId = this.selectedTransfer.railOperation.user
                ? this.selectedTransfer.railOperation.user.id
                : railOperationTargetUser
            
            return {
                type: 'ANY',
                scope: this.sourceIsManagement ? 'MANAGEMENT' : 'ALL',
                label: 'Origem',
                selectedNicknameId: this.selectedTransfer.source.id != 0 ? this.selectedTransfer.source.id : null,
                user: this.sourceIsManagement || this.sourceIsExternal
                      ? null
                      : userId,
                readonly: this.sourceIsExternal,
                alwaysReload: true,
            };
        },

        // this.targetIsManagement ? 'WALLET' : 'ANY'
        nicknameDropdownTargetProps() {
            let railOperationUser = this.selectedTransfer.railOperation.user
                ? this.selectedTransfer.railOperation.user.id
                : null;
            
            let userId = this.selectedTransfer.railOperation.targetUser
                ? this.selectedTransfer.railOperation.targetUser.id
                : railOperationUser;
            
            return {
                type: 'ANY',
                scope: this.targetIsManagement ? 'MANAGEMENT' : 'ALL',
                label: 'Destino',
                selectedNicknameId: this.selectedTransfer.target.id != 0 ? this.selectedTransfer.target.id : null,
                user: this.targetIsManagement || this.targetIsExternal
                      ? null
                      : userId,
                readonly: this.targetIsExternal,
            };
        },

        selectedTransferIsDeposit() {
            if (this.selectedTransfer) {
                return this.selectedTransfer.type == Vue.prototype.$transfer_types.O_M;
            }

            return false;
        },

        selectedTransferIsWithdrawal() {
            if (this.selectedTransfer) {
                return this.selectedTransfer.type == Vue.prototype.$transfer_types.M_O;
            }

            return false;
        },

        selectedTransferExpense() {
            let expense = false;
            if (this.selectedTransfer && this.selectedTransfer.railOperation) {
                expense = this.selectedTransfer.railOperation.expenseType;
            }
            return expense;
        },

        // Selected transfer is neither a Deposit nor a Withdrawal
        selectedTransferIsMovement() {
            if (this.selectedTransfer) {
                return this.selectedTransfer.source.id && this.selectedTransfer.target.id;
            }

            return false;
        },

        transferText() {
            if (this.selectedTransferIsDeposit) {
                return 'Depósitar na carteira:'
            } else if (this.selectedTransferIsWithdrawal) {
                return 'Levantar da carteira:'
            } else {
                return 'Transferência entre as carteiras:'
            }
        },
    },

    watch: {
        selectedTransfer: function () {
            this.populate();
        },
    },
	
	methods: {
		initialState() {
			return {
                datepickerMenu: false,
                form: {
                    date: null,
                    user: null,
                    targetUser: null,
                    amount: null,
                    sourceWallet: null,
                    targetWallet: null,
                    expenseType: null,
                    submitting: false,
                    deleting: false,
                },
                dialogues: {
                    delete: false,
                },
                rules: {
                    user: [
                        (v) => !!v || 'Por favor introduza um jogador'
                    ],
                    amount: [
                        (v) => !!v || "Por favor introduza o montante",
                        (v) => ! Number.isNaN(v) || 'Insira um valor numérico',
                    ],
					date: [
						(v) => !!v || 'Introduza uma data',
						(v) => this.dateDiff(this.$moment(), v) >= 0 || 'A data não pode ser futura',
					],
				},
			};
        },

        populate() {
            if (this.selectedTransfer) {
                this.form.amount = this.selectedTransfer.amount.value;
                this.form.sourceWallet = this.selectedTransfer.source;
                this.form.targetWallet = this.selectedTransfer.target;
                this.form.expenseType = this.selectedTransfer.railOperation.expenseType ? this.selectedTransfer.railOperation.expenseType.id : null;
            }
        },

		closeDialog() {
            this.$store.commit('railTransfers/setError', null);
			this.$emit('close-dialog');
        },
        
        async submit() {
            // Validate form
            if (! this.$refs.DialogEditTransfer.validate()) {
                return false;
            }

			// Disable submit btn
			this.toggleFormSubmitting();

            // Generate payload
            // 'body.source' / 'body.target' is '0' when the transfer is related to a Withdrawal or Deposit
            let payload = {
                id: this.selectedTransfer.railOperation.id,
            }

            // add body FormData
            payload.body = new FormData();
            payload.body.append('nickname', this.form.sourceWallet ? this.form.sourceWallet.id : Vue.prototype.$rail_external_wallet_id);
            payload.body.append('target', this.form.targetWallet ? this.form.targetWallet.id : Vue.prototype.$rail_external_wallet_id);
            payload.body.append('amount', this.form.amount);
            payload.body.append('expenseType', this.form.expenseType);

			// Dispatch to store
            let result = await this.$store.dispatch('railRequests/editRailOperation', payload);

			// Re-enable submit btn
			this.toggleFormSubmitting();

            // On success
            if (result === true) {
                
                // Refresh data
                this.updateRequestsTransfersAndBalances();

                // Emit 'submitted' event
                this.$emit('submitted');

                // Close dialog
				this.closeDialog();

                // Show success snackbar
				this.$store.dispatch('UI/showSnackbar', {
                    message: 'Despesa atualizada com sucesso.',
                    color: 'success'
                });
            }
        },

        toggleFormSubmitting() {
			this.form.submitting = ! this.form.submitting;
        },
        
        async deleteTransfer() {
            let result;

			// Disable delete btn
            this.toggleFormDeleting();

            // Generate payload
            let payload = this.selectedTransfer && this.selectedTransfer.railOperation && this.selectedTransfer.railOperation.id;

			// Dispatch to store
            if (payload) {
                result = await this.$store.dispatch('railRequests/deleteRequest', payload);
            }

			// Re-enable delete btn
			this.toggleFormDeleting();

            // On success
            if (result === true) {
                
                let payloadRefresh = {
                    params: {}
                }

                // if team exists and isManagement, Add team parameter to payload.params
                if (this.teamFilter && this.userHasRailAccessDivisionDropdown) {
                    payloadRefresh.params.team = this.teamFilter;
                }

                // Refresh Requests Transfers and Balances
                this.updateRequestsTransfersAndBalances(payloadRefresh);

                // Emit 'submitted' event
                this.$emit('submitted');

                // Close dialog
				this.closeDialog();

                // Show success snackbar
				this.$store.dispatch('UI/showSnackbar', {
                    message: 'Transferência apagada com sucesso.',
                    color: 'success'
                });
            }
        },

        toggleFormDeleting() {
			this.form.deleting = ! this.form.deleting;
        },
	},
};
