export default {
    props: {
      title: String,
      image: String,
      profitShare: Number,
      contractDuration: Number,
      ranking: Number,
      type: Number,
      code: String,
      sessionsContract: Number,
      eventsContract: Number,
      monthlySessions: Number,
      monthlyEvents: Number,
    },

    data(){
        return {
            correspondentValueForImage: null,
            correspondentDuration: null,
            correspondentType: null,
            correspondentRanking: null,
            loadImage: false,
            imageFromProp: this.$props.image
        }
    },

    watch: {
      image(imageFromProp) {
        this.verifyImage(imageFromProp);
      },
      contractDuration(newDuration) {
        this.verifyContractDuration(newDuration);
      },
      type(newType) {
        this.verifyType(newType);
      },
      ranking(newRanking) {
        this.verifyRanking(newRanking);
      },
    },  

    mounted() {
        this.verifyImage(this.$props.image);
        this.verifyContractDuration(this.$props.contractDuration);
        this.verifyType(this.$props.type)
        this.verifyRanking(this.$props.ranking);
    },

    methods:{
        verifyImage(image){
          const teamImages = ['cash.png', 'mtt.png', 'spins.png']
          this.loadImage = true

          if(teamImages.includes(image)){
            this.imageFromProp = image
          }else{
            this.imageFromProp = 'noteam.png'
          }
            switch (image) {
                case 'cash.png':
                 this.correspondentValueForImage = 'Cash'
                  break;
                case 'mtt.png':
                 this.correspondentValueForImage = 'MTT'
                  break;
                case 'spins.png':
                  this.correspondentValueForImage = 'Spin & Go'
                  break;
                default:
                  this.correspondentValueForImage = 'Sem Imagem'
              } 
        },

        verifyContractDuration(value){
            let result = (value/30).toFixed(0)
            switch(Number(result)){
                case 1:
                    this.correspondentDuration = '1 mês'
                break;
                case 3:
                    this.correspondentDuration = '3 meses'
                break;
                case 6:
                    this.correspondentDuration = '6 meses'
                break;
                case 12:
                    this.correspondentDuration = '1 ano'
                break;
                case 18:
                    this.correspondentDuration = '1 ano e meio'
                break;

                default:
                    this.correspondentDuration = `${result} meses`
            }
        },

        verifyType(type){
          switch(type){
            case 1:
              this.correspondentType = 'Verdadeiro (Real)'
            break;
            case 2:
              this.correspondentType = 'De Apoio (Helper)'
            break;
          }
        },

        verifyRanking(ranking){
          switch(ranking){
            case 0:
              this.correspondentRanking = 'Division'
            break;
            case 1:
              this.correspondentRanking = 'Academy'
            break;
            case 2:
              this.correspondentRanking = 'Principal'
          }
        }
    }

  };