import { mapGetters } from 'vuex';
import DataMixin from '@/mixins/Data';

export default {
    name: 'GamesDropdown',

    props: {
        value: {
            type: [Object, Array],
            required: false,
            default: () => { }
        },
        multiple: {
            type: Boolean,
            required: false,
            default: false,
        },
        label: {
            type: String,
            required: false,
            default: 'Torneios'
        },
        status: {
            type: String,
            required: false,
            default: ''
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        solo: {
            type: Boolean,
            required: false,
            default: false
        },
        clearable: {
            type: Boolean,
            required: false,
            default: false
        },
        outlined: {
            type: Boolean,
            required: false,
            default: true
        },
        hideDetails: {
            type: Boolean,
            required: false,
            default: false
        },
        rules: {
            type: Array,
            required: false,
            default: () => []
        },
        hint: {
            type: String,
            required: false,
            default: ''
        },
        autoReload: {
            type: Boolean,
            required: false,
            default: true
        },
        dense: {
            type: Boolean,
            required: false,
            default: false
        },
        dateRange: {
            type: Array,
            required: false,
            default: () => []
        }
    },

    mixins: [
        DataMixin,
    ],

    data() {
        return this.initialState();
    },

    created() {
        this.initialize();
        this.emitChange();
    },

    mounted() {
        // Clear error triggered naturally when element is loaded
        this.$refs.formGamesDropdown.resetValidation();
    },

    computed: {
        ...mapGetters({
            games: 'games/games',
            loading: 'games/loading',
        }),

        // Synthetic v-model
        computedValue: {
            get: function () {
                return this.group;
            },
            set: function (newValue) {
                this.group = newValue;
                this.emitChange();
            }
        },

        // return prop rules
        computedRules: function () {
            return this.rules;
        },
    },

    watch: {
        value: function (newValue) {
            this.computedValue = newValue;
        },
        dateRange: {
            handler() {
                this.fetchData();
            },
            deep: true,
        },
    },

    methods: {
        initialState() {
            return {
                group: null,
                search: null,
                options: {
                    page: 1,
                    itemsPerPage: -1,
                    sortBy: ['name'],
                    sortDesc: [false],
                },
            }
        },

        initialize() {
            // Initial value passed through prop
            this.computedValue = this.value;
            this.fetchData();
        },

        async fetchData() {

            // Base payload
            let payload = {
                params: {
                    page: this.options.page,
                    itemsPerPage: this.options.itemsPerPage,
                }
            };

            if (!this._.isEmpty(this.dateRange)) {
                payload.params.dateBegin = this.dateRange[0];
                payload.params.dateEnd = this.dateRange[1];
            }

            // get users list
            await this.$store.dispatch('games/get', payload);
        },

        reset() {
            Object.assign(this.$data, this.initialState());
            this.$refs.formGamesDropdown.reset();
        },

        emitChange() {
            this.$emit('input', this.computedValue);
            this.$emit('change', this.computedValue);
        },
    }
}