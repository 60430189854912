import { mapGetters } from 'vuex';
import Vue from 'vue';
import moment from 'moment';

import RowEditExclusionFactors from '@/components/Scheduling/Rows/RowEditExclusionFactors/RowEditExclusionFactors.vue';
import RowEditCalculationFactors from '@/components/Scheduling/Rows/RowEditCalculationFactors/RowEditCalculationFactors.vue';
import AlertError from '@/components/Alerts/AlertError/AlertError.vue';

export default {
    name: 'DialogEditModels',

    computed: {
        ...mapGetters({
            schedulingFactors: 'schedulingFactors/factors',
            schedulingModels: 'schedulingModels/data',
            schedulingNetworks: 'schedulingNetworksSharkscope/data',
            loading: 'schedulingModels/loading',
            error: 'schedulingModels/error',
        }),
        title: function () {
            let word = this.action == 'create'
                ? 'Criar'
                : 'Editar';

            let output = word + ' modelo';
            return output;
        },
    },

    components: {
        RowEditExclusionFactors,
        RowEditCalculationFactors,
        AlertError
    },

    props: {
        open: {
            type: Boolean,
            required: false,
            default: false
        },
        action: {
            type: String,
            required: false,
            default: "create"
        },
        editModelId: {
            type: Number,
            required: false
        }
    },

    data() {
        return this.initialState();
    },

    created() {
        this.init()
    },

    methods: {
        initialState() {
            return {
                exclusionFactors: [],
                calculationFactors: [],
                availableFactors: [],
                operators: [
                    {
                        id: 1,
                        tooltip: "between",
                        icon: "mdi-code-brackets"
                    },
                    {
                        id: 5,
                        tooltip: "less than or equal",
                        icon: "mdi-less-than-or-equal"
                    },
                    {
                        id: 4,
                        tooltip: "less than",
                        icon: "mdi-less-than"
                    },
                    {
                        id: 0,
                        tooltip: "equal",
                        icon: "mdi-equal"
                    },
                    {
                        id: 2,
                        tooltip: "greater than",
                        icon: "mdi-greater-than"
                    },
                    {
                        id: 3,
                        tooltip: "greater than or equal",
                        icon: "mdi-greater-than-or-equal"
                    }
                ],
                relationships: [
                    { id: 0, name: "direct", display: "direta" },
                    { id: 1, name: "inverse", display: "inversa" },
                ],
                networks: [],
                enums: [],
                modelName: '',
                modelDescription: undefined,
                selectedExclusionFactors: [],
                selectedCalculationFactors: [
                    { factor1: -1, factor2: undefined, weight: 5, relationship: 0 }
                ],
                rules: {
                    name: [
                        v => !!v || 'Por favor introduza um nome para o modelo',
                        v => (v && v.length <= 45) || 'O nome do modelo não pode ter mais de 45 caracteres',
                    ],
                    description: [
                        v => {
                            if (v) return v.length <= 255 || 'A descrição do modelo não pode ter mais de 255 caracteres';
                            else return true;
                        },
                    ],
                }
            }
        },

        async init() {
            await this.getSchedulingExclusionFactors();
            await this.getSchedulingCalculationFactors();
            await this.getSchedulingNetworks();
        },

        closeDialog() {
            this.$emit('close');
        },

        resetForm() {
            if(this.$refs.formAddEditModel) {
                this.$refs.formAddEditModel.reset();
                this.$refs.formAddEditModel.resetValidation();
            }
            
            let calculationFactors = this.calculationFactors;
            let exclusionFactors = this.exclusionFactors;
            Object.assign(this.$data, this.initialState());
            this.calculationFactors = calculationFactors;
            this.exclusionFactors = exclusionFactors;
        },

        // Add empty exclusion factor do exclusionFactorsArray
        // This makes a new row to define a new facto appear on the UI
        // Only works if previous factor is defined
        addExclusionFactor(index = null) {
            let i = index ? index : this.selectedExclusionFactors.length;
            if (i == 0 || (i != 0 && this.$refs['exclusionFactor'.concat(i - 1)][0].selectedExclusionFactor.factor1 != -1)) {
                // Add empty exclusion Factor (with id -1)
                Vue.set(this.selectedExclusionFactors, i, { factor1: -1, comparisonOperator: 1, value1: null, value2: undefined, type: 0 });
                this.availableFactors[i] = _.clone(this.exclusionFactors);
                // Remove used factor from factors list
                if (i > 0) {
                    let exclusionFactorsIds = this.selectedExclusionFactors.map(f => f.factor1);
                    this.availableFactors[i] = this.availableFactors[i].filter(f => !exclusionFactorsIds.includes(f.id));
                }
            }
        },

        // Add empty calculation factor do calculationFactors Array
        // This makes a new row to define a new factor appear on the UI
        // Only works if previous factor is defined
        addCalculationFactor() {
            let i = this.selectedCalculationFactors.length;
            if (i != 0 && this.$refs['calculationFactor'.concat(i - 1)][0].selectedCalculationFactor.factor1 != -1) {
                // Add empty exclusion Factor (with id -1)
                Vue.set(this.selectedCalculationFactors, i, { factor1: -1, factor2: -1, weight: 5, relationship: 0, type: 1 });
            }
        },

        // Updates the selectedCalculationFactors array in order to:
        // - the interface to be updated when the dialog is opened to edit a model
        // - the array to store the values that come from the rows, to be able to send it to the parent on model creation/update
        // called from each exclusion factor row 
        updateCalculationFactor(index) {
            Vue.set(this.selectedCalculationFactors, index, {
                factor1: this.$refs['calculationFactor'.concat(index)][0].selectedCalculationFactor.factor1,
                factor2: this.$refs['calculationFactor'.concat(index)][0].selectedCalculationFactor.factor2,
                weight: this.$refs['calculationFactor'.concat(index)][0].selectedCalculationFactor.weight,
                relationship: this.$refs['calculationFactor'.concat(index)][0].selectedCalculationFactor.relationship,
                type: 0
            });
        },

        // Updates the selectedExclusionFactors array
        updateExclusionFactor(index) {
            Vue.set(this.selectedExclusionFactors, index, {
                factor1: this.$refs['exclusionFactor'.concat(index)][0].selectedFactor.factor1,
                comparisonOperator: this.$refs['exclusionFactor'.concat(index)][0].selectedFactor.comparisonOperator,
                value1: this.$refs['exclusionFactor'.concat(index)][0].selectedFactor.value1,
                value2: this.$refs['exclusionFactor'.concat(index)][0].selectedFactor.value2,
                type: 1
            });
        },

        removeExclusionFactor(data) {
            let deletedFactor = this.exclusionFactors.find(f => f.id == data.factor1);
            for (let a = 0; a < this.selectedExclusionFactors.length; a++) {
                if (a !== data.index) {
                    this.availableFactors[a].push(deletedFactor);
                }
            }
            this.selectedExclusionFactors.splice(data.index, 1);
        },

        removeCalculationFactor(index) {
            this.selectedCalculationFactors.splice(index, 1);
        },

        // Prepares the UI when the dialog is opened to edit a model
        // by requesting the store for the information and populating the modeled variables 
        async prepareForEdit() {
            let payload = {
                id: this.editModelId,
                updateLoading: true
            }
            await this.$store.dispatch('schedulingModels/getById', payload);
            this.modelName = this.schedulingModels.name;
            this.modelDescription = this.schedulingModels.description;

            this.schedulingModels.exclusionFactors.forEach((factor, index) => {
                this.selectedExclusionFactors[index] = {};
                this.selectedExclusionFactors[index].factor1 = factor.factor1.id;
                this.selectedExclusionFactors[index].comparisonOperator = factor.comparisonOperator.id;
                let values = JSON.parse(factor.factor1.values);
                if (values.type == "boolean") {
                    this.selectedExclusionFactors[index].value1 = factor.value1 == 0 ? false : true;
                } else if (values.type == "dateTime") {
                    this.selectedExclusionFactors[index].timeRange = [
                        moment.unix(factor.value1).format("YYYY-MM-DD"),
                        moment.unix(factor.value2).format("YYYY-MM-DD"),
                    ]
                } else {
                    this.selectedExclusionFactors[index].value1 = factor.value1;
                    this.selectedExclusionFactors[index].value2 = factor.value2 ? factor.value2 : undefined;
                }
                this.availableFactors[index] = _.clone(this.exclusionFactors);
            })

            this.schedulingModels.calculationFactors.forEach((factor, index) => {
                this.selectedCalculationFactors[index] = {};
                this.selectedCalculationFactors[index].factor1 = factor.factor1.id;
                this.selectedCalculationFactors[index].factor2 = (factor.factor2) ? factor.factor2.id : undefined;
                this.selectedCalculationFactors[index].weight = factor.weight;
                this.selectedCalculationFactors[index].relationship = factor.relationship.id;
            })
        },

        // Prepares the modelDTO to have the correct format to send to the API either on model update or create actions
        save() {
            let payload = {
                active: false,
                name: this.modelName,
                description: this.modelDescription,
                exclusionFactors: [],
                calculationFactors: []
            };

            payload.exclusionFactors = this.selectedExclusionFactors.filter(factor => factor.factor1 != -1);
            payload.calculationFactors = this.selectedCalculationFactors.filter(factor => factor.factor1 != -1);

            if (payload.calculationFactors && payload.calculationFactors.length > 0) {
                payload.calculationFactors.forEach(factor => {
                    factor.factor2 = factor.factor2 != -1 ? factor.factor2 : undefined;
                })
            }

            if (this.action == "edit") {
                this.updateModel(payload);
            } else {
                this.createModel(payload);
            }
        },

        // Store accesses
        async createModel(payload) {
            await this.$store.dispatch('schedulingModels/create', payload);
            this.$emit('addModel', this.schedulingModels);
            if (!this.error) {
                this.closeDialog();
            }
        },

        async updateModel(payload) {
            let id = this.editModelId;
            await this.$store.dispatch('schedulingModels/update', { id, payload });
            this.$emit('editModel', this.schedulingModels);
            if (!this.error) {
                this.closeDialog();
            }
        },

        async getSchedulingExclusionFactors() {
            await this.$store.dispatch('schedulingFactors/getExclusionFactors');
            this.exclusionFactors = this.schedulingFactors;
            this.availableFactors[0] = this.schedulingFactors;
        },

        async getSchedulingCalculationFactors() {
            await this.$store.dispatch('schedulingFactors/getCalculationFactors');
            this.calculationFactors = this.schedulingFactors;
        },

        async getSchedulingNetworks() {
            await this.$store.dispatch('schedulingNetworksSharkscope/get');
        },
    },

    watch: {
        open(open) {
            if (open) {
                if (this.action == "edit") {
                    this.resetForm();
                    this.prepareForEdit();
                } else {
                    this.resetForm();
                }
            }
        }
    }
}