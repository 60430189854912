import Vue from 'vue';
import DialogApplicationsVerified from '@/components/Applications/DialogApplicationsVerified.vue';
import DialogCandidateContact from '@/components/Applications/DialogCandidateContact.vue';
import DialogScheduleCalendarEvent from '@/components/Calendar/DialogScheduleCalendarEvent.vue';
import AlertApplicationEvaluation from '@/components/Applications/AlertApplicationEvaluation.vue';
import { mapGetters } from 'vuex';

export default {
    name: 'DialogApplications',

    props: {
        dialogOpen: { type: Boolean, required: true, default: false }
    },

    components: {
        DialogApplicationsVerified,
        DialogScheduleCalendarEvent,
        AlertApplicationEvaluation,
        DialogCandidateContact,
    },

    /*mixins: [DataMixin],*/

    computed: {
        ...mapGetters({
            selectedApplication: 'applications/selectedApplication',
            idApplicationInView: 'applications/idApplicationInView',
            pastApplications: 'applications/pastApplications',
            calendarEvents: 'calendar/events'
        })
    },

    /*
    computed: {
        dataStage2Converted() {
            let fields = {
                'referral': 'Referências/Recomendações',
                'previous-coaching': 'Experiência coaching anterior',
                'reasons-why-backed': 'Porque quer ser bancado',
                'financial-dependence': 'Pessoas a depender de sí financeiramente',
                'monthly-expenses': 'Despesa mensal',
                'money-saved': 'Dinheiro poupado para quantos meses sem contar com o lucro do Poker',
                'description-poker-session': 'Descreve uma sessão de Poker',
                'poker-last-6-months': 'Descreve detalhadamente como foram os últimos 6 meses',
                'average-monthly-volume': 'Volume médio mensal'
            };

            let output = [];
            for (let key in this.application.data_stage_2_applications) {
                for (let key2 in fields) {
                    if ( this.application.data_stage_2_applications[key].hasOwnProperty(key2) ) {
                        output.push({
                            field: fields[key2],
                            value: this.application.data_stage_2_applications[key]
                        });
                    }
                }
            }

            return output;
        }
    },
    */

    data() {
        return {
            panelExpanded: 0,
            dialogApplicationsVerifiedAction: null,
            dialogOpenVerification: false,
            dialogOpenFinalApproval: false,
            dialogOpenScheduleCalendarEvent: false,
            dialogOpenCandidateContact: false,
            chipSelected: 0,
            modality: null
        }
    },

    created() {
        this.initialize();
    },

    methods: {
        initialize() {
            //this.application = this.selectedApplication;
        },

        getApplicationStageStatusColor(application, stage) {
            if (stage < application.stage_applications) return 'green'
            else if (stage == application.stage_applications && application.status_applications == 2) return 'green'
            else if (stage == application.stage_applications && application.status_applications == 1) return 'orange'
            else if (stage == application.stage_applications && application.status_applications == 0) return 'red'
            else return 'gray'
        },
        
        getApplicationStageStatusText(application, stage) {
            if (stage < application.stage_applications) return 'Aprovado'
            else if (stage == application.stage_applications && application.status_applications == 2) return 'Aprovado'
            else if (stage == application.stage_applications && application.status_applications == 1) return 'Pendente'
            else if (stage == application.stage_applications && application.status_applications == 0) return 'Rejeitado'
            else return 'Desconhecido'
        },

        openVerificationDialog(type) {
            this.dialogApplicationsVerifiedAction = type;
            this.dialogOpenVerification = true;
        },

        closeDialog() {
            this.$emit('closeDialog');
            //this.dialogOpen = false;
        },

        toggleDialogScheduleCalendarEvent() {
            this.dialogOpenScheduleCalendarEvent = ! this.dialogOpenScheduleCalendarEvent;
        },

        /* Approve does not imply final approval, but simply progression to the stage that follows. */
        async approveApplication(evaluation, notes, group, team, coach) {
            // API Request
            let payload = {
                evaluation: evaluation,
                notes: notes,
                group: group,
                team: team,
                coach: coach
            };
            await this.$store.dispatch('applications/approve', payload);

            // Refresh applications
            await this.$store.dispatch('applications/getApplications');

            this.closeDialog();
        },

        async rejectApplication(evaluation, notes, reason) {
            // API Request
            let payload = {
                evaluation: evaluation,
                notes: notes,
                reason: reason
            };
            await this.$store.dispatch('applications/reject', payload);

            // Refresh data
            await this.$store.dispatch('applications/getApplications');

            this.closeDialog();
        },

        async selectApplicationFromList(application) {
            this.$store.commit('applications/setSelectedApplication', application);
        },

        backToApplicationInView() {
            this.selectApplicationFromList(this.pastApplications[0]);
            this.chipSelected = 0;
        },

        oncloseDialogCandidateContact() {
            this.dialogOpenScheduleCalendarEvent = false;
            this.$store.dispatch('calendar/getEventsByApplication', this.selectedApplication.id_applications);
        },

        copyToClipboard(input) {
            this.clipboard(input);

            // Show snackbar
            this.$store.dispatch('UI/showSnackbar', {
                message: input + "' copiado com sucesso.",
                color: 'success'
            });
        },

        openEmailClient(input) {
            window.open('mailto:' + input);
        },

        openPhoneClient(input) {
            window.open('tel:' + input, '_self');
        },
    }
}