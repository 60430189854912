import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth.vue';
import RailMixin from '@/mixins/Rail.vue';

export default {
    name: "CardLivePlayer",
    props: {
        player: {
            type: Object,
            required: true
        }
    },

    mixins: [
        AuthMixin,
        RailMixin,
    ],

    computed: {
        ...mapGetters({
            countries: 'countries/countries',
            userTrackerId: 'auth/userTrackerId',
        }),
    },

    data() {
        return this.initialState();
    },
      
    methods: {
        initialState() {
            return {}
        },
        
        goToPlayerDetails(player) {
            this.$store.commit('livePlayers/setSelectedPlayer', player);
            if (player && player.tags) {
                this.$store.commit('livePlayers/setSelectedPlayerTags', player.tags);
            }
            if (player && player.nicknames) {
                this.$store.commit('livePlayers/setSelectedPlayerNicknames', player.nicknames);
            }
            
            this.$router.push(`/live/players/${player.id}`);
        },

        editPlayer(player) {
            this.$emit('edit-player', player);
        },

        deletePlayer(player) {
            // Lógica para apagar o jogador
            this.$emit('delete-player', player);
        },

        handlePlayerName(user) {
            let output = '';
        
            if (user && user.name) {
                // Divide o nome em palavras
                const names = user.name.split(' ');
                
                // Pega a primeira letra dos dois primeiros nomes
                if (names.length > 1) {
                    output = names[0][0] + names[1][0];
                } else if (names.length === 1) {
                    output = names[0][0];
                }
            }
        
            return output.toUpperCase(); // Converte para maiúsculas
        },

        isUserCreatorPlayer() {
            if(this.player && this.player.created_by){
                return this.player.created_by.id === this.userTrackerId;
            }
            return false;
        },

        getCodeFromCountry(name) {
            let output = 'unknown.svg';

            if (!this._.isEmpty(this.countries) && name) {
                let countryObject = this.countries.find(e => e.name == name);
                if (countryObject) {
                    output = countryObject.code + '.svg'
                }
            }

            return output;
        }
    }
}