import Vue from 'vue';
import { mapGetters } from 'vuex';
import CardStepper from '@/components/CardStepper/CardStepper.vue';
import CardStepperNotes from '@/components/CardStepper/CardStepperNotes/CardStepperNotes.vue';
import RowTransfer from '@/components/Rail/RowTransfer/RowTransfer.vue';
import AlertError from '@/components/Alerts/AlertError/AlertError.vue';
import DialogViewAttachment from '@/components/Rail/Dialog/DialogViewAttachment/DialogViewAttachment.vue';
import RailMixin from '@/mixins/Rail.vue';
import DataMixin from '@/mixins/Data.vue';
import AuthMixin from '@/mixins/Auth.vue';
import AlertUserNote from '@/components/Rail/AlertUserNote/AlertUserNote.vue';
import DialogUserNotes from '@/components/Rail/Dialog/DialogUserNotes/DialogUserNotes.vue';

export default {
  name: 'CardSplit',

  components: {
    CardStepper,
    CardStepperNotes,
    RowTransfer,
    AlertError,
    DialogViewAttachment,
    AlertUserNote,
    DialogUserNotes,
  },

  mixins: [
    RailMixin,
    DataMixin,
    AuthMixin,
  ],

  computed: {
    ...mapGetters({
      selectedRequest: 'railRequests/selectedRequest',
      error: 'railRequests/error',
      humanError: 'railRequests/humanError',
      selectedUser: 'users/selectedUser',
      userNotes: 'users/userNotes',
      teamFilter: 'teams/teamFilter',
    }),

    /**
     * @returns {Number|String} profit share value
     */
    profitShareStr: function () {
      let output = 0;
      if (this.selectedUser) {
        output = this.getProfitShare(this.selectedUser.profitShare);
      }
      return output;
    },

    selectedRequestObservation: function () {
      return this.getObservationByPhase(this.selectedRequest.observations, Vue.prototype.table_requests_phases[Vue.prototype.$rail_ops.operations.split][2]['value']);
    },
  },

  watch: {
    selectedRequest: function () {
      this.resetComponentState();
      this.fetchData();
    }
  },

  created() {
    return this.fetchData();
  },

  data() {
    return this.initialState();
  },

  methods: {
    initialState() {
      return {
        checkboxTransferDone: false,
        form: {
          observation: null,
          gyazoLink: null,
          fileName: []
        },
        rules: {
          checkbox: [
            (v) => !!v || '',
          ],
        },
        cardTitles: [
          'Resumo do processo',
          'Confirme que recebeu',
          'Confirmação',
        ],
        formValidations: [
          true,
          false,
          true,
        ],
        dialogues: {
          viewAttachment: false,
          userNotes: false,
        },
        observation: {
          url: null,
          type: null,
        },
      }
    },

    fetchData() {
      // used to calculate profitShare and to populate TableBalances
      if (this.selectedRequest) {
        this.$store.dispatch('users/getUser', this.selectedRequest.user.id);
        let payload = {
          id: this.selectedRequest.user.id,
          populateUserNotes: true,
        }
        // get user notes
        this.$store.dispatch('users/getUserNotes',  payload);
      }
    },

    resetComponentState() {
      // force CardStepper to display 1 step
      this.$store.commit('UI/setCardStepperStep', 1);
      // reset data component state
      Object.assign(this.$data, this.initialState());
    },

    /**
    * Validates a specific form step.
    * Assumes form steps are named 'formStep_x' where x is a Int 0 - maxStep-1.
    * 
    * @param {Int} step Identifier of the form step to validate.
    * @returns {Void}
    */
    validateStep(step) {
      const formStepRef = 'formStep_' + (step);
      if (this.$refs[formStepRef]) {
        // Update formValidation[step] in a way the DOM will be reactive
        Vue.set(
          this.formValidations,
          step - 1,
          this.$refs[formStepRef].validate()
        );
      }

      // Clear store errors if exists
      if (this.error) {
        this.$store.dispatch('railRequests/clearError');
      }
    },

    /**
     * @param {*Integer} type
     * @returns an array filter by transfer type used in RowTransfer component
     */
    selectedRequestFilterType(type) {
      if (this.selectedRequest) {
        let filter = this.selectedRequest.transfers.filter(element => element.type == type);
        return filter;
      }
    },

    // retrieve observation fields from CardStepperNotes component
    getInfoFromCardStepperNotes(payload) {
      if (payload) {
        this.form.gyazoLink = payload.gyazoLink;
        this.form.fileName = payload.filename;
      }
    },

    // retrieve fields populated by admin to create a Bankroll Reduction request !
    async submit() {
      // Validate form
      if (!this.$refs.formSplit.validate()) {
        return false;
      }

      // Toggle submit button
      this.$refs.CardStepper.toggleSubmitting();

      /**
       * Preparing payload
      */

      // Base payload
      let payload = {
        body: {}
      }

      // Add 'id' field if necessary
      if (this.selectedRequest && this.selectedRequest.id) {
        payload.id = this.selectedRequest.id;
      }
      // add body formData
      payload.body = new FormData();

      // Add 'observation' field if necessary
      if (this.form.observation) {
        payload.body.append('observation[description]', this.form.observation);
      }

      // Add 'screenshot' field if necessary
      if (!this._.isEmpty(this.form.fileName)) {
        payload.body.append('screenshot', this.form.fileName[0]);
      }

      // Add 'observation[url]' field if necessary
      if (this.form.gyazoLink) {
        payload.body.append('observation[url]', this.form.gyazoLink);
      }

      // Dispatch to store
      let result = await this.$store.dispatch('railRequests/confirmRailOperation', payload);

      // Re-enable btn
      if (this.$refs.CardStepper) {
        this.$refs.CardStepper.toggleSubmitting();
      }

      // On success
      if (result === true) {

        let payloadRefresh = {
            params: {}
          }
  
        // if team exists and isManagement, Add team parameter to payload.params
        if (this.teamFilter && this.userHasRailAccessDivisionDropdown) {
        payloadRefresh.params.team = this.teamFilter;
        }
        
        // Refresh Requests Transfers and Balances
        this.updateRequestsTransfersAndBalances(payloadRefresh);

        this.updateStatisticsByUserId(this.selectedRequest.user.id);

        // change hasFinished state in order to change component
        this.$store.dispatch('TabSlider/setHasFinished', true);

        // Show success snackbar
        this.$store.dispatch('UI/showSnackbar', {
          message: 'Pedido de split confirmado com sucesso.',
          color: 'success'
        });
      }
    },

    /**
    * Populates observation.url and observation.name
    * @param {Object} observation 
    * @returns {Boolean} open dialog or opens new page;
    */
    openDialogViewAttachment(observation) {
      if (observation && observation.url) {

        // validate observation type
        let isTypeLink = observation.type == 1;

        // populate fields
        this.observation.url = observation.url;
        this.observation.type = observation.type;

        return isTypeLink
          ? window.open(observation.url, '_blank')
          : this.dialogues.viewAttachment = true;
      }
    },

    openDialogUserNotes() {
      this.dialogues.userNotes = true;
    },
  }
}