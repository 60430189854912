var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex"},[_c('v-form',{ref:"formNetworkDropdown"},[_c('v-autocomplete',{attrs:{"items":_vm.dataFiltered,"label":_vm.label,"loading":_vm.isLoading || _vm._.isEmpty(_vm.dataFiltered),"rules":_vm.computedRules,"disabled":_vm.disabled,"solo":_vm.solo,"outlined":_vm.outlined,"item-value":"id","item-text":"name","auto-select-first":"","persistent-hint":"","return-object":"","clearable":_vm.clearable,"hide-details":_vm.hideDetails},on:{"input":function($event){return _vm.emitChange()}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('img',{staticClass:"mr-2",attrs:{"src":require('@/assets/networks/' + item.imageURL)}}),_c('span',[_vm._v(" "+_vm._s(item.name))])]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-nowrap align-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('img',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"src":require('@/assets/networks/' + item.imageURL)}},'img',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(item.name)+" ")])]),_c('span',{staticClass:"selectCustom ml-2"},[_vm._v(_vm._s(item.name)+" ")])],1)]}}]),model:{value:(_vm.computedValue),callback:function ($$v) {_vm.computedValue=$$v},expression:"computedValue"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }