var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('DialogViewSession',{attrs:{"is-open":_vm.dialogues.viewSession},on:{"close-dialog":function($event){return _vm.toggleDialog('viewSession')}}}),_c('DialogViewSessionSpins',{attrs:{"is-open":_vm.dialogues.viewSessionSpins},on:{"close-dialog":function($event){return _vm.toggleDialog('viewSessionSpins')}}}),_c('v-card',[_c('v-card-title',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"align-self-center mb-4 mb-md-0",attrs:{"cols":""}},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('v-spacer'),_c('v-col',{attrs:{"cols":"","md":"","xl":"2"}},[_c('GroupDropdown',{staticClass:"mb-4 mr-md-4 mb-md-0",attrs:{"rules":[],"outlined":"","multiple":"","solo":false,"dense":"","clearable":""},model:{value:(_vm.form.group),callback:function ($$v) {_vm.$set(_vm.form, "group", $$v)},expression:"form.group"}})],1),_c('v-col',{attrs:{"cols":"","md":"4","xl":"3"}},[_c('UserDropdown',{attrs:{"rules":[],"outlined":"","clearable":"","hide-details":"","dense":""},model:{value:(_vm.form.user),callback:function ($$v) {_vm.$set(_vm.form, "user", $$v)},expression:"form.user"}})],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.operationsLegacyList,"server-items-length":_vm.totalOperationsLegacyList,"options":_vm.options,"footer-props":_vm.dataTable.footerProps,"loading":!_vm.hasLoadedOperationsLegacyList,"loading-text":"A carregar...","item-key":"index","must-sort":""},on:{"click:row":_vm.setActiveItem,"update:options":function (e) { return _vm.optionsComputed = e; }},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(_vm.$moment(item.date.date).format('L'))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$moment(item.date.date).format('LL')))])])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"w-100",attrs:{"color":_vm.getRailOperationColor(item.type),"label":""}},[_vm._v(" "+_vm._s(_vm.getRailOperationName(item.type))+" "+_vm._s(_vm.getMakeUpReason(item))+" ")])]}},{key:"item.sourceUser",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":_vm.getPlayerURL(item, 'user')}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-avatar',{staticClass:"mr-2",attrs:{"size":"32px","color":"accent"}},[_c('v-img',{staticClass:"mt-1",attrs:{"position":"","src":_vm.imageCutout(item, 'user')}})],1),_c('span',[_vm._v(_vm._s(_vm.getUserFromRailOperation(item)))])],1)])]}},{key:"item.targetUser",fn:function(ref){
var item = ref.item;
return [(item.type != _vm.$rail_ops.operations.sessions)?_c('router-link',{attrs:{"to":_vm.getPlayerURL(item, 'targetUser')}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-avatar',{staticClass:"mr-2",attrs:{"size":"32px","color":"accent"}},[_c('v-img',{staticClass:"mt-1",attrs:{"position":"","src":_vm.imageCutout(item, 'targetUser')}})],1),_c('span',[_vm._v(" "+_vm._s(_vm.getTargetUserFromRailOperation(item))+" ")])],1)]):_vm._e()]}},{key:"item.source",fn:function(ref){
var item = ref.item;
return [((item.requests && item.requests.length >= 1))?_c('div',[_c('div',{staticClass:"d-block text-truncate",style:(_vm.$vuetify.breakpoint.xlOnly ? '' : 'max-width: 150px;')},_vm._l((item.requests),function(data){return _c('v-tooltip',{key:data.id,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('img',_vm._g({staticClass:"mr-2 pt-1",attrs:{"src":require('@/assets/networks/' + (data.nickname ? data.nickname.network.imageURL : data.source.network.imageURL))}},on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(data.nickname ? data.nickname.network.name : data.source.network.name)+" ")])])}),1)]):_c('div',[_c('div',{staticClass:"d-block text-truncate",style:(_vm.$vuetify.breakpoint.xlOnly ? '' : 'max-width: 150px;')},_vm._l((_vm.getSourceNetworkTransfers(item)),function(data){return _c('v-tooltip',{key:data.id,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('img',_vm._g({staticClass:"mr-2 pt-1",attrs:{"src":require('@/assets/networks/' + data.source.network.imageURL)}},on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(data.source.network.name)+" ")])])}),1)])]}},{key:"item.destination",fn:function(ref){
var item = ref.item;
return [((item.requests && item.requests.length >= 1) && (_vm.$rail_status.finalized != item.status))?_c('div',[_c('div',{staticClass:"d-block text-truncate",style:(_vm.$vuetify.breakpoint.xlOnly ? '' : 'max-width: 150px;')},_vm._l((_vm.getOperationRequests(item)),function(data){return _c('v-tooltip',{key:data.id,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('button',{on:{"click":function($event){data.target && data.target.network.id != _vm.$rail_external_wallet_id ? _vm.triggerCopyToClipboard(data) : null}}},[_c('img',_vm._g({staticClass:"mr-2 pt-1",attrs:{"src":require('@/assets/networks/' + (data.target ? data.target.network.imageURL : data.wallet.network.imageURL))}},on))])]}}],null,true)},[_c('div',{staticClass:"d-flex align-center"},[(data.target && data.target.id != _vm.$rail_external_wallet_id)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"white"}},[_vm._v(" mdi-content-copy ")]):_vm._e(),_c('span',[_vm._v(_vm._s(data.target ? data.target.network.name : data.wallet.network.name))])],1)])}),1)]):_c('div',[_c('div',{staticClass:"d-block text-truncate",style:(_vm.$vuetify.breakpoint.xlOnly ? '' : 'max-width: 150px;')},_vm._l((_vm.getTargetWalletTransfers(item)),function(data){return _c('v-tooltip',{key:data.id,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('button',{on:{"click":function($event){data.target && data.target.network.id != _vm.$rail_external_wallet_id ? _vm.triggerCopyToClipboard(data) : null}}},[_c('img',_vm._g({staticClass:"mr-2 pt-1",attrs:{"src":require('@/assets/networks/' + data.target.network.imageURL)}},on))])]}}],null,true)},[_c('div',{staticClass:"d-flex"},[(data.target && data.target.id != _vm.$rail_external_wallet_id)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"white"}},[_vm._v(" mdi-content-copy ")]):_vm._e(),_c('span',[_vm._v(_vm._s(data.target.network.name)+" ")])],1)])}),1)])]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [(item.amount && (typeof item.amount.value) == 'number')?_c('v-chip',[_c('span',{staticClass:"mr-2"},[_vm._v(" "+_vm._s(_vm.getRailOperationCurrency(item))+" ")]),_c('currency-input',{staticClass:"text-end",style:({color: _vm.valueColorFinal(), width: '90px' }),attrs:{"value":item.amount.value,"currency":null,"precision":2,"disabled":""}})],1):(item.amount)?_c('v-chip',{staticClass:"w-100 d-flex justify-center"},[_c('span',[_vm._v(" "+_vm._s(item.amount.value)+" ")])]):_vm._e()]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"disabled":_vm.submitting},on:{"click":function($event){$event.stopPropagation();return _vm.handleGoToOperation(item)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.goToRailOperationIcon(item))+" ")])]}}],null,true)},[_c('span',[_vm._v("Abrir")])])]}},{key:"no-data",fn:function(){return [(_vm._.isEmpty(_vm.operationsLegacyList))?_c('EmptyStateNoData',{attrs:{"icon":"mdi-alert-circle-outline","title":"Sem resultados","message":"Ajuste os filtros para encontrar mais resultados"}}):_vm._e()]},proxy:true},{key:"no-results",fn:function(){return [_c('EmptyStateNoData',{attrs:{"icon":"mdi-magnify","title":"Não foram encontrados resultados","message":"Ajuste a sua pesquisa para encontrar mais resultados"}})]},proxy:true}])},[_c('template',{slot:"progress"},[_c('v-progress-linear',{attrs:{"color":"accent","indeterminate":""}})],1)],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }