var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.data && _vm.data.source && _vm.data.target)?_c('v-dialog',{attrs:{"value":_vm.dialogOpen,"max-width":"1100"},on:{"input":function($event){return _vm.closeDialog()}}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.title)+" "),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":_vm.submitting},on:{"click":function($event){$event.stopPropagation();return _vm.goToRailOperation(_vm.data)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" "+_vm._s(_vm.submitting ? 'mdi-loading mdi-spin' : 'mdi-launch')+" ")])],1)]}}],null,false,457198101)},[_c('span',[_vm._v("Abrir operação")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.triggerCopyToClipboard()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-share-variant ")])],1)]}}],null,false,3288103835)},[_c('span',[_vm._v("Partilhar transferência")])]),_c('v-btn',{staticClass:"mr-n2",attrs:{"icon":""},on:{"click":function($event){return _vm.closeDialog()}}},[_c('v-icon',[_vm._v(" mdi-close ")])],1)],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":""}},[_c('span',{staticClass:"body-2"},[_vm._v(" Data: ")]),_c('p',{staticClass:"body-1"},[_vm._v(" "+_vm._s(_vm.$moment(_vm.data.updatedAt.date).format( "LL" ))+" ")])]),_c('v-col',{attrs:{"cols":"12","sm":""}},[_c('span',{staticClass:"body-2"},[_vm._v(" Montante enviado: ")]),_c('p',{staticClass:"body-1"},[_vm._v(" "+_vm._s(_vm.data.source.network.currency.symbol)+" "+_vm._s(_vm.data.amount.value)+" ")])])],1),_c('span',{staticClass:"body-2 mb-2"},[_vm._v(" Transferência: ")]),_c('v-row',{staticClass:"pt-2"},[_c('v-col',{attrs:{"cols":"12","lg":""}},[_c('RowTransfer',{attrs:{"items":[{
              source: _vm.data.source,
              target: _vm.data.target,
              amount: _vm.data.amount,
              isPending: _vm.data.isPending
            }]}})],1)],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }