import Hero from '@/components/Hero/Hero.vue';
import TableSessions from '@/components/Rail/TableSessions/TableSessions.vue';
import CardMainActions from '@/components/Rail/CardMainActions/CardMainActions.vue';
import TabSlider from '@/components/TabSlider/TabSlider.vue';

export default {
    name: 'RailUser',

    components: {
        Hero,
        TableSessions,
        CardMainActions,
        TabSlider
    },

    data() {
        return this.initialState();
    },

    methods: {
        initialState() {
            return {
                data: null
            }
        },

    }
}
