export default {
    name: 'TeamImageDropdown',

    props: {
        value: { type: [Object,String], required: false, default: () => { } },
        hideDetails: { type: Boolean, required: false, default: false },
        outlined: { type: Boolean, required: false, default: false },
        solo: { type: Boolean, required: false, default: false },
        hint: { type: Boolean, required: false, default: false },
        multiple: { type: Boolean, required: false, default: false },
        disabled: { type: Boolean, required: false, default: false },
    },

    computed: {
        computedValue: {
            get: function () {
               return this.teamImage;
            },
            set: function (newValue) {
                this.teamImage = newValue;
                this.emitChange();
            }
        },
    },

    watch: {
        value: {
            handler() {
                this.computedValue = this.value;
            }   
        }
    },

    data() {
        return this.initialState();
    },

    created() {
        this.computedValue = this.value;
    },

    methods: {
        initialState() {
            return {
                teamImage: null,
                teams: [
                    { name: 'Cash', image: 'cash.png' },
                    { name: 'MTT', image: 'mtt.png' },
                    { name: 'Spin & Go', image: 'spins.png' },
                    { name: 'Desconhecido', image: 'noteam.png' },
                  ],
            }
        },
        
        emitChange() {
            this.$emit('input', this.computedValue);
            this.$emit('change', this.computedValue);
        },

        reset() {
            this.teamImage = null;
            if (this.$refs.formTeamImageDropdown) {
                this.$refs.formTeamImageDropdown.reset();
            }
         },
    }
}