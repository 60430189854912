import AuthMixin from '@/mixins/Auth';
import Hero from '@/components/Hero/Hero.vue';
import TableNotes from '@/components/Tables/TableNotes/TableNotes.vue';
import DialogAddEditNotes from '@/components/Dialog/DialogAddEditNotes/DialogAddEditNotes.vue';

export default {
  name: 'Notes',

  mixins: [
    AuthMixin,
  ],

  components: {
    Hero,
    TableNotes,
    DialogAddEditNotes,
  },

  data () {
    return {
      dialogues: {
        addNote: false,
        editNote: false,
      },
    }
  },

  // async created () {
  //   await this.allowOnlyRoles(['rail_admin', 'rail_manager', 'tracker_admin', 'tracker_manager', 'super_coach']);
  // },

  methods: {
    fabClick() {
      this.dialogues.addNote = true;
    },

    toggleDialog(dialog) {
      this.dialogues[dialog] = ! this.dialogues[dialog];
    },
    
    closeDialogNote() {
      if(this.dialogues.addNote){
      this.toggleDialog('addNote');
      }else if(this.dialogues.editNote){
          this.toggleDialog('editNote');
      }
    },

    openDialogAddEditNote(dialog, item) {
      //this.setActiveItem(item);
      this.toggleDialog(dialog);
    },

    setActiveItem(item) {
      if (item) {
        this.$store.commit(
          'notes/setSelectedStat',
          item
        );
      }
    },

    checkIsOpen(){
      return this.dialogues.addNote || this.dialogues.editNote;
    },

    checkAction(){
        return this.dialogues.addNote ? 'create' : 'update';
    },

    fetchData() {
    },

    reloadData() {
      this.fetchData();
    },
  }
}