export default {
    name: 'DialogScoredGameGroupDetails',
    props: {
        open: {
            type: Boolean,
            required: false,
            default: false
        },
        gameGroup: {
            type: Object,
            required: true,
            default() {
                return null
            }
        }
    },

    data() {
        return this.initialState();
    },

    methods: {
        initialState() {
            return {
                headersFactors: [
                    { text: 'Fator', value: 'selectedFactor.fullName', sortable: true },
                    { text: 'Peso', value: 'selectedFactor.weight', sortable: true },
                    { text: 'Peso normalizado', value: 'selectedFactor.normalizedWeight', sortable: true },
                    { text: 'Relação', value: 'selectedFactor.relationship.id', sortable: true },
                    { text: 'Pontuação', value: 'normalizedScore', sortable: true },
                ]
            }
        },

        getFactorName(factor) {
            let fullName = factor.factor1.name;
            if (factor.factor2) {
                fullName = fullName.concat(' - ');
                fullName = fullName.concat(factor.factor2.name);
            }
            return fullName;
        },

        getArrowIcon(relationship) {
            if (relationship == 0) {
                return 'mdi-arrow-up';
            } else if (relationship == 1) {
                return 'mdi-arrow-down';
            }
        },

        closeDialog() {
            this.$emit('close');
        },
    },

    watch: {
        gameGroup: {
            handler() {
                if (this.gameGroup) {
                    this.gameGroup.scoredFactors.forEach(f => {
                        f.selectedFactor.fullName = this.getFactorName(f.selectedFactor);
                    })
                }
            },
            deep: true
        }
    },
}
