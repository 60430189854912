var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('DialogAddEditTeam',{attrs:{"is-open":_vm.dialogues.editTeam,"action":"update"},on:{"close-dialog":function($event){return _vm.closeDialogEditTeam()}}}),_c('div',[_c('DialogConfirmation',{ref:"DialogConfirmation",attrs:{"is-open":_vm.dialogues.removeTeam,"title":"Apagar Time","body":"Tem a certeza que quer apagar este time?","width":376,"button-name":"Apagar","button-color":"error","error":_vm.error,"human-error":_vm.humanError},on:{"close-dialog":function($event){return _vm.closeDialogRemoveConfirmation()},"submit":function($event){return _vm.deleteTeam()}}})],1),_c('v-card-title',[_vm._v("Equipas")]),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.teams,"search":_vm.search,"footer-props":_vm.footerProps,"loading":_vm.loading,"server-items-length":_vm.teamsTotal,"loading-text":"A carregar...","must-sort":""},on:{"update:options":function (e) { return _vm.optionsComputed = e; },"click:row":_vm.setActiveItem},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mx-2",attrs:{"dense":""},on:{"click":function($event){return _vm.dialogViewTeam(item)}}},on),[_vm._v(" mdi-launch ")])]}}],null,true)},[_c('span',[_vm._v("Ver Equipa")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"dense":""},on:{"click":function($event){return _vm.openDialogAddEditTeam('editTeam', item)}}},on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v("Editar Equipa")])])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex"}},[(_vm.checkImageExists(item.image))?_c('v-img',{staticClass:"my-auto image",staticStyle:{"margin-right":"5px"},attrs:{"src":require('@/assets/teams/' + _vm.checkImageExists(item.image)),"height":"24","width":"24"}}):_vm._e(),_c('button',{on:{"click":function($event){return _vm.dialogViewTeam(item)}}},[_vm._v(" "+_vm._s(item.name)+" ")])],1)]}},{key:"item.contractDuration",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticStyle:{"min-width":"110px"}},[_vm._v(" "+_vm._s(_vm.showDaysInMonths(item.contractDuration))+" ")])]}},{key:"item.profitShare",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((item.profitShare * 100) + '%')+" ")]}},{key:"loading",fn:function(){return [_c('v-skeleton-loader',{attrs:{"type":"table-tbody"}})]},proxy:true}])},[_c('template',{slot:"progress"},[_c('v-progress-linear',{attrs:{"color":"accent","indeterminate":""}})],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }