import TableExpenses from '@/components/Rail/TableExpenses/TableExpenses.vue';
import PieChartExpenses from '@/components/Charts/PieChartExpenses/PieChartExpenses.vue';
import ExpensesTypes from '@/components/Rail/ExpensesTypes/ExpensesTypes.vue'
import AuthMixin from '@/mixins/Auth.vue';

export default {
    name: 'Expenses',

    components: {
        TableExpenses,
        PieChartExpenses,
        ExpensesTypes,
    },

    mixins: [
        AuthMixin,
    ],

    data() {
       return this.initialState()
    },

    methods: {
        initialState() {
            return {
                types: []
            };
        },

        setExpenseTypes(e) {
            this.types = e;
        }
    }
}