import { mapGetters } from 'vuex';
import Alert from '@/components/Rail/Alert/Alert.vue';
import Activity from '@/components/Rail/Activity/Activity.vue';
import History from '@/components/Rail/History/History.vue';
import CardStatistics from '@/components/Rail/Statistics/CardStatistics/CardStatistics.vue';
import TableRequests from '@/components/Rail/TableRequests/TableRequests.vue';
import TableBalance from '@/components/Rail/TableBalance/TableBalance.vue';
import TableSessions from '@/components/Rail/TableSessions/TableSessions.vue';
import TableRequestsLegacyView from '@/components/Rail/TableRequestsLegacyView/TableRequestsLegacyView.vue';
import AreaChartCumulativePNL from '@/components/Charts/AreaChartCumulativePNL/AreaChartCumulativePNL.vue';
import CardMainStatistics from '@/components/Rail/Statistics/CardMainStatistics/CardMainStatistics.vue'; 
import UserEventList from '@/components/UserEventList/UserEventList.vue';
import ListPlayerPnl from '@/components/Rail/Statistics/ListPlayerPnl/ListPlayerPnl.vue';
import TablePlayerTotalBalance from '@/components/Rail/TablePlayerTotalBalance/TablePlayerTotalBalance.vue';
import UserMixin from '@/mixins/User';

export default {
    name: 'Overview',
    
    components: {
        Alert,
        CardStatistics,
        TableRequests,
        TableBalance,
        TableSessions,
        TableRequestsLegacyView,
        CardMainStatistics,
        Activity,
        History,
        AreaChartCumulativePNL,
        UserEventList,
        ListPlayerPnl,
        TablePlayerTotalBalance,
    },
    
	mixins: [
        UserMixin,
    ],

    data() {
        return this.initialState();
    },
    
    created() {
        this.fetchData();
    },
    
    computed: {
        ...mapGetters({
            statisticsAdmin: 'railStatistics/dataAdmin',
            statisticsLoading: 'railStatistics/loadingDataAdmin',
            reports: 'railReports/reports',
            heroDatePickerValue: 'UI/heroDatePickerValue',
            teamFilter: 'teams/teamFilter',
        }),
        
        cardInfo() { 
            return [
                {
                    name: 'Banca gestão',
                    value: this.statisticsAdmin['BANKROLL_MANAGEMENT'] ? this.statisticsAdmin['BANKROLL_MANAGEMENT'].value.EUR.value : 0,
                    icon: 'mdi-bank',
                    showCurrency: true,
                    showAltCurrency: false,
                },
                {
                    name: 'Banca gestão pendente',
                    value: this.statisticsAdmin['DEBIT'] ? this.statisticsAdmin['DEBIT'].value.EUR.value : 0,
                    icon: 'mdi-bank',
                    showCurrency: true,
                    showAltCurrency: false,
                },
                {
                    name: 'Banca jogadores',
                    value: this.statisticsAdmin['BANKROLL_USER'] ? this.statisticsAdmin['BANKROLL_USER'].value.EUR.value : 0,
                    icon: 'mdi-bank',
                    showCurrency: true,
                    showAltCurrency: false,
                },
                {
                    name: 'Despesas',
                    value: this.statisticsAdmin['EXPENSE'] ? this.statisticsAdmin['EXPENSE'].value.EUR.value : 0,
                    icon: 'mdi-file-document-multiple-outline',
                    showCurrency: true,
                    showAltCurrency: false,
                },
                {
                    name: 'Entradas de capital',
                    value: this.statisticsAdmin['CAPITAL_INFLOW'] ? this.statisticsAdmin['CAPITAL_INFLOW'].value.EUR.value : 0,
                    icon: 'mdi-cash-plus',
                    showCurrency: true,
                    showAltCurrency: false,
                },
                {
                    name: 'Saídas de capital',
                    value: this.statisticsAdmin['CAPITAL_OUTFLOW'] ? this.statisticsAdmin['CAPITAL_OUTFLOW'].value.EUR.value : 0,
                    icon: 'mdi-cash-minus',
                    showCurrency: true,
                    showAltCurrency: false,
                },
                {
                    name: 'Depósitos',
                    value: this.statisticsAdmin['DEPOSIT'] ? this.statisticsAdmin['DEPOSIT'].value.EUR.value : 0,
                    icon: 'mdi-bank-transfer-in',
                    showCurrency: true,
                    showAltCurrency: false,
                },
                {
                    name: 'Levantamentos',
                    value: this.statisticsAdmin['WITHDRAW'] ? this.statisticsAdmin['WITHDRAW'].value.EUR.value : 0,
                    icon: 'mdi-bank-transfer-out',
                    showCurrency: true,
                    showAltCurrency: false,
                },
                {
                    name: 'Lucro médio',
                    value: this.statisticsAdmin['AVERAGE_PNL'] ? this.statisticsAdmin['AVERAGE_PNL'].value.EUR.value : 0,
                    icon: 'mdi-chart-box-outline',
                    showCurrency: true,
                    showAltCurrency: false,
                },
                {
                    name: 'Lucro total',
                    value: this.statisticsAdmin['PROFIT'] ? this.statisticsAdmin['PROFIT'].value.EUR.value : 0,
                    icon: 'mdi-calculator-variant-outline',
                    showCurrency: true,
                    showAltCurrency: false,
                },
                {
                    name: 'Split parte gestão',
                    value: this.statisticsAdmin['SPLIT_MANAGEMENT'] ? this.statisticsAdmin['SPLIT_MANAGEMENT'].value.EUR.value : 0,
                    icon: 'mdi-call-split',
                    showCurrency: true,
                    showAltCurrency: false,
                },
                {
                    name: 'Split total',
                    value: this.statisticsAdmin['SPLIT'] ? this.statisticsAdmin['SPLIT'].value.EUR.value : 0,
                    icon: 'mdi-call-split',
                    showCurrency: true,
                },

            ];
        },
    },
    
    watch: {
        heroDatePickerValue: function () {
            this.fetchStatistics();
        },

        teamFilter: function () {
            this.fetchStatistics();
        }
    },
    
    methods: {
        initialState() {
            return {};
        },
        
        fetchData() {
            // Rail Reports
            if (this._.isEmpty(this.reports)) {
                this.$store.dispatch('railReports/get');
            }
        },
        
        async fetchStatistics() {

            let payload = null;
            if (! this._.isEmpty(this.heroDatePickerValue)) {
                payload = {
                    dateBegin: this.heroDatePickerValue[0],
                    dateEnd: this.heroDatePickerValue[1],
                };
            }

            // if team exists and isManagement, Add team parameter to payload.params
            if (this.teamFilter) {
                payload.team = this.teamFilter;
            }

            if (!this._.isEmpty(payload)) {
                await this.$store.dispatch('railStatistics/getAdmin', payload);
            }
        }
    }
}