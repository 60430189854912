import FormCalendarEvent from '@/components/Calendar/FormCalendarEvent/FormCalendarEvent.vue'

export default {
    name: 'DialogEventForm',

    components: {
        FormCalendarEvent,
    },

    props: {
        isOpen: {
            type: Boolean,
            required: true,
            default: false
        },
    },

    computed: {},

    created() {},

    data() {
        return this.initialState();
    },

    methods: {
        initialState() {
            return {}
        }, 
        closeDialog() {
            this.$emit('close-dialog')
        },

    },

}