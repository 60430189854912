import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth.vue';
import RailMixin from '@/mixins/Rail.vue';
import Loading from '@/views/Loading.vue';
import Hero from "@/components/Hero/Hero.vue";
import DialogAddEditPlayerLive from '@/components/Dialog/DialogAddEditPlayerLive/DialogAddEditPlayerLive.vue';
import TableLivePlayers from '@/components/Tables/TableLivePlayers/TableLivePlayers.vue';

export default {
  name: 'LivePlayers',

  mixins: [
    AuthMixin,
    RailMixin,
  ],

  components: {
    Loading,
    Hero,
    DialogAddEditPlayerLive,
    TableLivePlayers,
  },

  computed: {
    ...mapGetters({
      countries: 'countries/countries',
    }),
  },

  created() {
    this.cleanSelectedStates();
    this.initialize();
  },

  data() {
    return this.initialState();
  },

  methods: {
    initialize() {
      return;
    },

    initialState() {
      return {
        players: [
          { id: 1, name: 'William Garcia', nicknames: ['Nickname1', 'Nickname2'], avatar: 'path/to/avatar1.png' },
          { id: 2, name: 'Julius Caesar Brian Test', nicknames: ['Nickname3', 'Nickname4'], avatar: 'path/to/avatar2.png' },
          { id: 3, name: 'John Walter', nicknames: ['Nickname5', 'Nickname6', 'Nickname7'], avatar: 'path/to/avatar3.png' },
          { id: 4, name: 'Julius Caesar', nicknames: ['Nickname3', 'Nickname4'], avatar: 'path/to/avatar2.png' },
          { id: 5, name: 'John Walter22', nicknames: ['Nickname5','Nickname7', 'Nickname9'], avatar: 'path/to/avatar3.png' },
          { id: 6, name: 'John Walter', nicknames: ['Nickname5', 'Nickname6'], avatar: 'path/to/avatar3.png' },
          { id: 7, name: 'Julius Caesar', nicknames: ['Nickname3', 'Nickname4'], avatar: 'path/to/avatar2.png' },
          { id: 8, name: 'John Walter', nicknames: ['Nickname5', 'Nickname6'], avatar: 'path/to/avatar3.png' },
          { id: 9, name: 'Julius Caesar9', nicknames: ['Nickname3', 'Nickname4'], avatar: 'path/to/avatar2.png' },
        ],
        players3: [
          { id: 1, name: 'William Garcia', nicknames: ['Nickname1', 'Nickname2'], avatar: 'path/to/avatar1.png' },
          { id: 2, name: 'Julius Caesar', nicknames: ['Nickname3', 'Nickname4'], avatar: 'path/to/avatar2.png' },
          { id: 3, name: 'John Walter', nicknames: ['Nickname5', 'Nickname6'], avatar: 'path/to/avatar3.png' },
        ],
        search: '',
      selectedCountry: null,
        dialogues: {
          addEditPlayerLive: false,
          deletePlayerLive: false,
        },
        action: 'create',
        teste123: 1,
        sortBy: null,
      };
    },

    cleanSelectedStates() {
      this.$store.commit('livePlayers/setSelectedPlayerNicknames', []);
      this.$store.commit('livePlayers/setSelectedPlayerTags', []);
      this.$store.commit('livePlayers/setSelectedPlayer', null);
    },

    toggleDialog(dialog) {
      this.dialogues[dialog] = !this.dialogues[dialog];
    },

    addNewPlayer() {
      this.action = 'create';
      this.toggleDialog('addEditPlayerLive')
    },

    editPlayer(id) {
      this.action = 'update';
      this.toggleDialog('addEditPlayerLive');
    },

    openDialogConfirmation() {
      this.toggleDialog('deletePlayerLive')
    },

    deletePlayer(id) {

    },


  }
}