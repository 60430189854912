import Vue from 'vue';
import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth';
import RailMixin from '@/mixins/Rail';
import Hero from '@/components/Hero/Hero.vue';
import Loading from '@/views/Loading.vue';
import Snackbar from '@/components/Snackbar';
import TableGamesPlayed from '@/components/Tables/TableGamesPlayed/TableGamesPlayed.vue';
import DialogConfirmation from '@/components/Rail/Dialog/DialogConfirmation/DialogConfirmation.vue';
import EmptyStateNoData from '@/components/EmptyStateNoData';

export default {
  name: 'AuditSingle',

  mixins: [
    AuthMixin,
    RailMixin,
  ],

  components: {
    Hero,
    TableGamesPlayed,
    Loading,
    Snackbar,
    DialogConfirmation,
    EmptyStateNoData,
  },

  computed: {
    ...mapGetters({
      userType: 'auth/userType',
      userRoles: 'auth/userRoles',
      selectedAudit: 'audits/selectedAudit',
      selectedAuditLoading: 'audits/selectedAuditLoading',
      auditsHumanError: 'audits/humanError',
      auditsError: 'audits/error',
      auditGames: 'audits/auditGames',
      themeDarkMode: 'UI/themeDarkMode',

    }),

    showRestoreBtn() {
      return this.selectedAudit && (this.selectedAudit.status == Vue.prototype.$audits.status.APPROVED || this.selectedAudit.status == Vue.prototype.$audits.status.REJECTED)
    },

  },

  async created() {
    await this.initialize();
  },

  beforeDestroy() {
    // set selectedAudit to null
    this.$store.commit('audits/setSelectedAudit', null);
    // Clean up the interval when the component is destroyed
    if (this.auditInterval) {
      clearInterval(this.auditInterval);
    }
  },

  data() {
    return this.initialState();
  },

  methods: {
    initialState() {
      return {
        dialogues: {
          feedback: false,
        },
        auditInterval: null,
        rejectLoading: false,
        restoreLoading: false,
        approveLoading: false,
        analyzeLoading: false,

      }
    },
    async initialize() {
      if (!this.selectedAuditLoading || this.selectedAudit && (this.selectedAudit.id != this.$route.params.id)) {

        let payload = {
          id: this.$route.params.id,
        }

        // API Call to retrieve single audit info
        let result = await this.$store.dispatch('audits/getSingleAudit', payload);

        if (result == true) {

          if (this.selectedAudit && (this.selectedAudit.status == Vue.prototype.$audits.status.REQUESTED)) {
            this.getAudit();
          }
        }
      }
    },

    getAudit() {
      // get audit every 5 minutes
      this.auditInterval = setInterval(() => {
        if (this.selectedAudit && this.selectedAudit.status != Vue.prototype.$audits.status.REQUESTED) {
          // clear interval when selectedAudit.status is not equal to 1
          clearInterval(this.auditInterval);
        } else {
          let payload = {
            id: this.$route.params.id,
            loading: false,
            params: {}
          }
          // API Call
          this.$store.dispatch('audits/getSingleAudit', payload);
        }
      }, Vue.prototype.$audits.timeInterval);
    },

    async rejectAudit(item) {
      this.rejectLoading = true;
      let result = await this.$store.dispatch('audits/rejectSingleAudit', { id: item.id });
      if (result === true) {
        this.showSnackbar('Audit rejeitado com sucesso!', 'success');
      } else {
        this.showSnackbar(this.auditsHumanError, 'error');
      }
      this.rejectLoading = false;
    },

    async approveAudit(item) {
      this.approveLoading = true;
      let result = await this.$store.dispatch('audits/approveSingleAudit', { id: item.id });
      if (result === true) {
        this.showSnackbar('Audit aprovado com sucesso!', 'success');
      } else {
        this.showSnackbar(this.auditsHumanError, 'error');
      }
      this.approveLoading = false;
    },

    async restoreAudit(item) {
      this.restoreLoading = true;
      let result = await this.$store.dispatch('audits/restoreSingleAudit', { id: item.id });
      if (result === true) {
        this.showSnackbar('Audit restaurado com sucesso!', 'success');
      } else {
        this.showSnackbar('Algo correu mal, por favor contacte  o suporte', 'error');
      }
      this.restoreLoading = false;
    },

    async analyzeAudit(item) {
      this.analyzeLoading = true;
      this.dialogues.feedback = true;
      let result = await this.$store.dispatch('audits/analyzeSingleAudit', { id: item.id });
      if (result === true) {
        this.showSnackbar('O Audit analisado com sucesso!', 'success');
      } else {
        this.showSnackbar('Algo correu mal, por favor contacte  o suporte', 'error');
      }
      this.analyzeLoading = false;
    },

    showSnackbar(message, color) {
      this.$store.dispatch('UI/showSnackbar', {
        message: message,
        color: color
      });
    },

    imageCutout(item) {
      let output = require('@/assets/images/players/player-example.png');
      
      if (item.imgCutout) {
          output = item.imgCutout;
      }

      return output;
    },

    getStatusColor(status) {
      let output = '';
      if (status) {
        switch (status) {
          case Vue.prototype.$audits.status.REQUESTED:
            output = 'accent';
            break;
          case Vue.prototype.$audits.status.PENDING_REVIEW:
            output = 'info';
            break;
          case Vue.prototype.$audits.status.APPROVED:
            output = 'success';
            break;
          case Vue.prototype.$audits.status.REJECTED:
            output = 'error';
            break;
          default:
            output = 'accent';
            break;
        }
      }
      return output;
    },

    getStatusName(status) {
      let output = '';
      if (status) {
        switch (status) {
          case Vue.prototype.$audits.status.REQUESTED:
            output = 'Pedido submetido';
            break;
          case Vue.prototype.$audits.status.PENDING_REVIEW:
            output = 'Revisão pendente';
            break;
          case Vue.prototype.$audits.status.APPROVED:
            output = 'Aprovado';
            break;
          case Vue.prototype.$audits.status.REJECTED:
            output = 'Rejeitado';
            break;
          default:
            output = 'Pendente';
            break;
        }
      }
      return output;
    }, 
  }
}
