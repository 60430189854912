import Vue from 'vue';
import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth.vue';
import RailMixin from '@/mixins/Rail.vue';
import DataMixin from '@/mixins/Data.vue';
import EmptyStateNoData from '@/components/EmptyStateNoData';
import DialogDownloadCsv from '@/components/Rail/Dialog/DialogDownloadCsv/DialogDownloadCsv.vue';

export default {
  name: 'TableReports',

  mixins: [
    AuthMixin,
    RailMixin,
    DataMixin
  ],

  components: {
    EmptyStateNoData,
    DialogDownloadCsv,
  },

  props: {
    filters: {
      type: Object,
      required: false,
      default: () => { }
    },
  },

  created() {
    return this.filterStatus();
  },

  data() {
    return this.initializeState();
  },

  computed: {
    ...mapGetters({
      selectedReport: 'railReports/selectedReport',
      reports: 'railReports/reports',
      reportsPending: 'railReports/reportsPending',
      reportsFinalized: 'railReports/reportsFinalized',
      loading: 'railReports/loading',
      reportsPendingLoading: 'railReports/reportsPendingLoading',
      reportsFinalizedLoading: 'railReports/reportsFinalizedLoading',
      reportsTotal: 'railReports/totalReports',
      reportsPendingTotal: 'railReports/reportsPendingTotal',
      reportsFinalizedTotal: 'railReports/reportsFinalizedTotal',
      teamFilter: 'teams/teamFilter',
      heroDatePickerValue: 'UI/heroDatePickerValue',
    }),

    tableData: function () {
      let output;
      switch (this.tabs.current) {
        case 0:
          output = this.reports;
          break;
        case 1:
          output = this.reportsPending;
          break;
        case 2:
          output = this.reportsFinalized;
          break;
        default:
          output = this.reports;
          break;
      }
      return output;
    },

    tableTotal: function () {
      let output;
      switch (this.tabs.current) {
        case 0:
          output = this.reportsTotal;
          break;
        case 1:
          output = this.reportsPendingTotal;
          break;
        case 2:
          output = this.reportsFinalizedTotal;
          break;
        default:
          output = this.reportsTotal;
          break;
      }
      return output;
    },

    tableLoading: function () {
      let output;
      switch (this.tabs.current) {
        case 0:
          output = this.loading;
          break;
        case 1:
          output = this.reportsPendingLoading;
          break;
        case 2:
          output = this.reportsFinalizedLoading;
          break;
        default:
          output = this.reportsLoading;
          break;
      }
      return output;
    },

    optionsComputed: {
      get: function () {
        return this.options;
      },
      set: function (newValue) {
          if (!this._.isEqual(this.options, newValue)) {
              this.options = newValue;
          }
      }
    }
  },

  watch: {
    // watches this.options object
    // triggers everytime this.options has changed
    options: {
      handler() {
        this.filterStatus();
      },
      deep: true,
    },

    // watches this.tabs object
    // resets this.options object everytime tab has changed
    tabs: {
      handler() {
        this.options = {
          page: 1,
          itemsPerPage: 10,
          sortBy: [],
          sortDesc: [],
          groupBy: [],
          groupDesc: [],
          mustSort: false,
          multiSort: false
        }
      },
      deep: true,
    },

    filters: {
      handler() {
        this.options = {
          page: 1,
          itemsPerPage: 10,
          sortBy: [],
          sortDesc: [],
          groupBy: [],
          groupDesc: [],
          mustSort: false,
          multiSort: false
        }
      },
      deep: true,
    },

    teamFilter: function () {
      this.options = {
        page: 1,
        itemsPerPage: 10,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        mustSort: false,
        multiSort: false
      }
    },

    heroDatePickerValue: function () {
      this.filterStatus();
    }
  },

  methods: {
    initializeState() {
      return {
        tabs: {
          current: 0,
          headers: ['Todos', 'Pendentes', 'Finalizados'],
        },
        headersAdmin: [
          { text: '', value: 'selected', sortable: false },
          { text: 'Data', value: 'date' },
          { text: 'Criado por', value: 'user' },
          { text: 'Motivo', value: 'type' },
          { text: 'Processo/Pedido', value: 'railOperation.type', sortable: false },
          { text: 'Jogador', value: 'railOperation.user.name', sortable: false },
          { text: 'Estado', value: 'status' },
          { text: 'Ação', value: 'action', sortable: false },
        ],
        headersUser: [
          { text: '', value: 'selected', sortable: false },
          { text: 'Data', value: 'date'},
          { text: 'Criado por', value: 'userCreator.name'},
          { text: 'Motivo', value: 'type' },
          { text: 'Processo/Pedido', value: 'railOperation.type', sortable: false },
          { text: 'Estado', value: 'status' },
          { text: 'Ação', value: 'action', sortable: false },
        ],
        search: null,
        dataTable: {
          footerProps: {
            'items-per-page-options': this.$itemsPerPageOptions,
          }
        },
        options: {
          page: 1,
          itemsPerPage: 10,
          sortBy: [],
          sortDesc: [],
          groupBy: [],
          groupDesc: [],
          mustSort: true,
          multiSort: false
        },
        dialogues: {
          download: {
            state: false,
            message: 'Exporta os dados da tabela para formato Excel.',
          },
        },
      };
    },

    async fetchData(status = null) {
      // Base payload
      let payload = {
        export: false,
        params: {
          page: this.options.page,
          itemsPerPage: this.options.itemsPerPage,
        }
      };

      // Add date params
      if (!this._.isEmpty(this.heroDatePickerValue) && this.tabs.current != 1) {
        payload.params.dateBegin = this.heroDatePickerValue[0];
        payload.params.dateEnd = this.heroDatePickerValue[1];
      }

      // if team exists and isManagement, Add team parameter to payload.params
      if (this.teamFilter && this.userHasRailAccessDivisionDropdown) {
        payload.params.team = this.teamFilter;
      }

      // add filter by operation type
      if (this.filters && !this._.isEmpty(this.filters.operation)) {
        this.filters.operation.forEach((element, index) => {
          return payload.params['type[' + index + ']'] = element.value;
        })
      }

      // add filter by user
      if (this.filters && !this._.isNull(this.filters.user) && !this._.isUndefined(this.filters.user) && !this._.isEmpty(this.filters.user)) {
        payload.params.sourceUser = this.filters.user.id;
        payload.params.targetUser = this.filters.user.id;
      }

      // add filter by operation type
      if (this.filters && !this._.isEmpty(this.filters.status)) {
        this.filters.status.forEach((element, index) => {
          return payload.params['reportStatus[' + index + ']'] = element.value;
        })
      } else {
        payload.params['reportStatus[]'] = status;
      }

      // Add sortDesc parameter to payload.params
      Object.assign(payload.params, this.sortDescHandler(this.options.sortDesc));

      // Add sortBy parameter to payload
      this.addParameterToPayload(payload, 'sortBy[]', this.options.sortBy);
    
      // API Call
      await this.$store.dispatch('railReports/get', payload);
    },

    filterStatus() {
      let status;
      switch (this.tabs.current) {
        case 0:
          status = '';
          break;
        case 1:
          status = 1;
          break;
        case 2:
          status = 3;
          break;
        default:
          status = '';
          break;
      }
      return this.fetchData(status);
    },

    openDialogDownloadCsv() {
      this.dialogues.download.state = true;
    },

    getTableHeaders() {
      return this.userIsRailManager
        ? this.headersAdmin
        : this.headersUser;
    },

    getStatusColor(item) {
      if (item) {
        switch (item.status) {
          case Vue.prototype.$rail_reports.status.ongoing:
            return 'warning';
          case Vue.prototype.$rail_reports.status.seen:
            return 'info';
          case Vue.prototype.$rail_reports.status.resolved:
            return 'success';
          default:
            break;
        }
      }
    },

    getStatusTextToolTip(item) {
      if (item) {
        switch (item.status) {
          case Vue.prototype.$rail_reports.status.ongoing:
            return 'Este report encontra-se em estado pendente.';
          case Vue.prototype.$rail_reports.status.seen:
            return 'Este report encontra-se em processo de resolução.';
          case Vue.prototype.$rail_reports.status.resolved:
            return 'Este report encontra-se resolvido.';
          default:
            break;
        }
      }
    },

    setActiveItem(item) {
      if (item) {
          this.$store.commit(
              'railReports/setSelectedReport',
              item
          );
        }
    },
    
    /**
     * sets active item
     * sets CardReport to be shown
     * handle routing 
     * @param {Object} item 
     */
    setCurrentCard(item) {
      this.setActiveItem(item);
      this.$store.commit('TabSlider/setCard', Vue.prototype.$rail_ops.operations.reports);
      // redirect to /rail/reports/:id
      this.$router.push({ path: Vue.prototype.$tab_slider.tabs.reports + item.id }).catch(() => { });
    },
  },
};
