import { mapGetters } from 'vuex';
import DataMixin from '@/mixins/Data';

export default {
    name: 'FormDialogEdit',

    mixins: [
        DataMixin,
    ],

    props: {
        isMain: { type: Boolean, required: false, default: false },
        selectedGroup: { type: Number, required: false, default: null },
        selectedNetwork: { type: Number, required: false, default: null },
        rules: { type: Object, required: true, default: () => { } },
    },

    data() {
        return {
            myRules: null,
            group: null,
            network: null,
        };
    },

    computed: {
        ...mapGetters({
            groups: 'groups/groups',
            groupsLoading: 'groups/loading',
            networks: 'networks/networks',
            networksLoading: 'networks/loading',
        }),
    },

    created() {
        this.initialize();
    },

    updated() {
        this.cloneProps();
    },

    methods: {
        initialize() {
            this.cloneProps();
            this.fetchData();
        },

        /**
         * Clone props so we can modify them
         */
        cloneProps() {
            this.myRules = this.rules;
            this.group = this.selectedGroup;
            this.network = this.selectedNetwork;
        },

        async fetchData() {
            this.$store.dispatch('groups/get');
            this.$store.dispatch('networks/get');
        },

        emitToParent() {
            this.$emit('update-schedule', this.rules);
        },

        emitUpdateEditedItemKey(key, value) {
            this.$emit('update-edited-item-key', key, value);
        },
    },
};