import { mapGetters } from 'vuex';
import DatePickerNavigator from '@/components/DatePickerNavigator/DatePickerNavigator.vue';

export default {
  name: 'HeatmapMonthly',

  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
  },

  components: {
    DatePickerNavigator,
  },

  data() {
    return this.initialState();
  },

  computed: {
    ...mapGetters({
      heroDatePickerValue: 'UI/heroDatePickerValue',
      userStatisticsHeatmapMonthly: 'users/userStatisticsHeatmapMonthly',
      userStatisticsHeatmapMonthlyLoading: 'users/userStatisticsHeatmapMonthlyLoading',
      themeDarkMode: 'UI/themeDarkMode',
    }),

    series() {
      if (this.userStatisticsHeatmapMonthlyLoading || this._.isNull(this.userStatisticsHeatmapMonthly)) {
        return [
          { name: 'Sessões', data: [] },
          { name: 'Eventos', data: [] },
          { name: 'Videos', data: [] },
        ];
      }

      return [
        { name: 'Sessões', data: this.userStatisticsHeatmapMonthly['Sessões'].entries },
        { name: 'Eventos', data: this.userStatisticsHeatmapMonthly['Eventos'].entries },
        { name: 'Videos', data: this.userStatisticsHeatmapMonthly['Vídeos'].entries },
      ];
    },

    options() {
      return {
        chart: {
          type: 'heatmap',
          toolbar: {
            show: false
          },
          foreColor: '#f6f7f8',
          background: this.themeDarkMode ? 'dark' : 'light',
        },
        legend: {
          show: false,
        },
        colors: ['#4CAF50', '#0097A7', '#FFC900'],
        plotOptions: {
          heatmap: {
            colorScale: {
              ranges: [{
                from: 0,
                to: 0,
                color: '#dcdcdc',
                foreColor: undefined,
                name: undefined,
              },
              ],
            }
          },
        },
        theme: {
          mode: this.themeDarkMode ? 'dark' : 'light',
        },
        grid: {
          show: true,
          xaxis: {
            lines: {
                show: true
            }
          },   
          yaxis: {
              lines: {
                  show: false
              }
          },
        },
        dataLabels: {
          enabled: false,
        },
        tooltip: {
          enabled: true,
          enabledOnSeries: true,
          shared: true,
          intersect: false,
          y: {
            show: true,
            formatter: (series, item) => {
              if (item.w) {
                const value = this.$moment(item.w.config.series[item.seriesIndex].data[item.dataPointIndex].x).format('DD-MM-YYYY');
                return value;
              }
            },
          },
        },
        xaxis: {
          type: 'numeric',
          tickPlacement: 'on',
          labels: {
            formatter: (item) => this.$moment(item).format('D'),
            rotate: 0,
            style: {
              colors: this.themeDarkMode ? '#FFFFFF' : '#000000',
            },
          },
          tooltip: {
            enabled: false,
          }
        },
        yaxis: {
          labels: {
            style: {
              colors: this.themeDarkMode ? '#FFFFFF' : '#000000',
            }
          }
        },
        states: {
          normal: {
              filter: {
                  type: 'none',
                  value: 0,
              }
          },
          hover: {
              filter: {
                  type: 'none',
                  value: 0.15,
              }
          },
          active: {
              allowMultipleDataPointsSelection: false,
              filter: {
                  type: 'none',
                  value: 0.35,
              }
          },
      }
      }
    },

    computedCalendarDates: {
      get: function () {
        return this.calendarDatesFinal;
      },
      set: function (newValue) {
        this.calendarDatesFinal = newValue;
      }
    },

  },

  watch: {
    calendarDatesFinal: function () {
      this.fetchData();
    },
  },

  created() {
    this.computedCalendarDates = [this.$moment(), this.$moment()];
  },

  methods: {
    initialState() {
      return {
        calendarDatesFinal: [],
      };
    },

    fetchData() {
      let payload = {
        id: this.$route.params.id,
        body: {
          year: this.$moment(this.calendarDatesFinal[0]).format('YYYY'),
          month: this.$moment(this.calendarDatesFinal[0]).format('MM'),
        }
      }
      this.$store.dispatch('users/getUserStatisticsHeatmapMonthly', payload);
    },
  },
};
