import Vue from 'vue';
import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth';
import DataMixin from '@/mixins/Data';
import AlertError from '@/components/Alerts/AlertError/AlertError.vue';
import GroupImageDropdown from '@/components/Rail/GroupImageDropdown/GroupImageDropdown.vue';
import GroupImageBadgeDropdown from '@/components/Rail/GroupImageBadgeDropdown/GroupImageBadgeDropdown.vue';
import LevelChangeGroup from '@/components/LevelChangeGroup/LevelChangeGroup.vue';

export default {
    name: 'DialogCreateGroup',

    components: {
        AlertError,
        GroupImageDropdown,
        GroupImageBadgeDropdown,
        LevelChangeGroup,
    },

    props: {
        isOpen: {
            type: Boolean,
            required: true,
            default: false
        },
    },

    mixins: [
        AuthMixin,
        DataMixin,
    ],

    data() {
        return this.initialState();
    },

    computed: {
        ...mapGetters({
            error: 'groups/error',
            humanError: 'groups/humanError',
            teams: 'teams/teams',
            loading: 'teams/loading'
        }),
    },

    watch: {
        isOpen: function () {
            this.resetForm();
        },

        showContractObligations: function (newVal, oldVal) {
            if (newVal === false) {
                this.form.contractEvents = null;
                this.form.contractSessions = null;
                this.form.monthlyEvents = null;
                this.form.monthlySessions = null;
            }
        }
    },

    created() {
        this.initialize();
    },
    
    methods: {
        initialState() {
            return {
                submitting: false,
                showContractObligations: false,
                tabs: {
                    current: 0,
                    headers: ['Info', 'Subida/Descida', 'Objectivos'],
                  },
                types: [
                    { label: 'Grupo', name: 'Grupo', value: Vue.prototype.$group_types.group },
                    { label: 'Nível', name: 'Nível', value: Vue.prototype.$group_types.level },
                ],
                form: {
                    name: null,
                    image: null,
                    type:  Vue.prototype.$group_types.level,
                    level: null,
                    team: null,
                    contractSessions: null,
                    contractEvents: null,
                    monthlySessions: null,
                    monthlyEvents: null,
                    imageBadge: null,
                },
                levelChangeGroupForm: {
                    minAverageBuyIns: null,
                    numAverageBuyIns: null,
                    daysToCalcBuyIn: null,
                    totalSplitsAmount: null,
                    totalSplits: null,
                    calcDaysforAmountAndSplit: null,
                    minAverageSessions: null,
                    calcDaysAverageSessions: null,
                    totalSplitsAmountFast: null,
                    numSessionsToUpAfterDownOff: null,
                    numOfDescentsConsecutiveAllowed: null,
                    numDaysOff: null,
                    stats_ok: null,
                },
                rules: {
                    name: [ 
                        v => !!v || 'Por favor introduza um nome para o grupo',
                    ],
                    image: [ 
                        v => !!v || 'Por favor introduza um imagem para o grupo',
                    ],
                    imageBadge: [ 
                        v => !!v || 'Por favor introduza uma imagem para o grupo',
                    ],
                    type: [ 
                        v => !!v || 'Por favor introduza um tipo de grupo',
                    ],
                    team: [ 
                        v => !!v || 'Por favor introduza uma equipa para o grupo',
                    ],
                    level: [
                        (v) => (!!v && v > 0) || 'Introduza um valor positivo',
                        (v) => (!!v && /^\d{0,5}.\d{0,1}$/.test(v)) || 'Introduza um número válido até 5 digitos com 1 casa decimal (Ex: 12345 | 12345.6)',
                    ],
                    contractSessions: [
                        v => {
                          return !!v || 'Por favor introduza um valor'
                        }
                    ],
                    contractEvents: [
                        v => {
                          return !!v || 'Por favor introduza um número de sessões do contrato'
                        }  
                    ],
                    monthlyEvents: [
                        v => {
                          return !!v || 'Por favor introduza um número de eventos do contrato'
                        }  
                    ],
                    monthlySessions: [
                        v => {
                          return !!v || 'Por favor introduza um número de sessões mensalmente'
                        }  
                    ],
                }
            };
        },
        
        checkSubmitDisabled() {
            if (Object.keys(this.levelChangeGroupForm).length < 13) {
                return true;
            }

            let hasAnyChildNull = false

            for(let key in this.levelChangeGroupForm){
                if(!this.levelChangeGroupForm[key]){
                    hasAnyChildNull = true
                }
            }

            return hasAnyChildNull
        },

        initialize() {
            let payload = {
                params: {
                    type: Vue.prototype.$teams_types.helper, // HELPER TEAM
                    page: 1,
                    itemsPerPage: -1
                }
            }

            this.$store.dispatch('teams/get', payload);
        },

        async submit() {
            if (!this.$refs.formCreateGroup.validate()) {
                return false;
            }

            // Toggle submit button
            this.toggleSubmitting();

            let payload = {
                name: this.form.name,
                type: this.form.type,
                image: this.form.image,
                level: this.form.level,
                image_badge: this.form.imageBadge,
                contractNumEvents: this.form.contractEvents ? this.form.contractEvents : null,
                contractNumSessions:  this.form.contractSessions ? this.form.contractSessions : null,
                oblNumEvents:  this.form.monthlyEvents ? this.form.monthlyEvents : null,
                oblNumSessions:  this.form.monthlySessions ? this.form.monthlySessions : null,
               rules_change: {
                   UP_FAST_AVG_SESSIONS: Number(this.levelChangeGroupForm.minAverageSessions),
                   UP_FAST_AVG_SESSIONS_DAYS: Number(this.levelChangeGroupForm.calcDaysAverageSessions),
                   UP_FAST_SPLITS_AMOUNT: Number(this.levelChangeGroupForm.totalSplitsAmountFast),
                   UP_NORMAL_SPLITS_AMOUNT: Number(this.levelChangeGroupForm.totalSplitsAmount),
                   UP_NORMAL_SPLITS_COUNT: Number(this.levelChangeGroupForm.totalSplits),
                   UP_NORMAL_SPLITS_DAYS: Number(this.levelChangeGroupForm.calcDaysforAmountAndSplit),
                   DOWN_ABI_AMOUNT: Number(this.levelChangeGroupForm.numAverageBuyIns),
                   DOWN_ABI_DAYS: Number(this.levelChangeGroupForm.daysToCalcBuyIn),
                   DOWN_DAYS_OFF_AMOUNT: Number(this.levelChangeGroupForm.numDaysOff),
                   DOWN_DAYS_OFF_CONSECUTIVE: Number(this.levelChangeGroupForm.numOfDescentsConsecutiveAllowed),
                   UP_DAYS_OFF_SESSIONS: Number(this.levelChangeGroupForm.numSessionsToUpAfterDownOff),
                   UP_SPLITS_MIN_AMOUNT_ABI: Number(this.levelChangeGroupForm.minAverageBuyIns),
                },
                stats_ok: Number(this.levelChangeGroupForm.stats_ok),
            }

            if (this.form.type == Vue.prototype.$group_types.level) {
                payload.level = this.form.level.toString();
            }

            if(this.form.type == Vue.prototype.$teams_types.helper){
                delete payload.level
            }

            if (!this._.isNull(this.form.team)) {
                payload.team = this.form.team;
            }

            let result = await this.$store.dispatch('groups/create', payload);

            // // Re-enable button
            this.toggleSubmitting();

            // On success
            if (result === true) {
                // Show success snackbar
                this.$store.dispatch('UI/showSnackbar', {
                    message: 'Nível adicionado com sucesso.',
                    color: 'success'
                });

            let pageNumber = {
                params: {
                    page: 1,
                    itemsPerPage : 10,
                    'sortBy[0]': 'id',
                    'sort[0]': 'DESC',
                }
            }
            
            // API Call
            await this.$store.dispatch('groups/get', pageNumber);

            // closes dialog
            this.closeDialog();
            }
        },

        /**
         * Used to disable Submit button
         */
        toggleSubmitting() {
            this.submitting = !this.submitting;
        },

        closeDialog() {
            this.$emit("close-dialog");
            this.resetForm();
        },

        resetForm() {
            this.form.name = null;
            this.form.image = null;
            this.form.imageBadge = null;
            this.form.type = null;
            this.form.contractSessions = null;
            this.form.contractEvents = null;
            this.form.monthlySessions = null;
            this.form.monthlyEvents = null;
            this.tabs.current = 0;
            this.levelChangeGroupForm = {};
            if (this.$refs && this.$refs.GroupImageDropdownRef) {
                this.$refs.GroupImageDropdownRef.reset();
            }
            this.$refs.formCreateGroup.reset();

            // clear errors
            this.$store.dispatch('groups/clearError');
        }
    },
};
