var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('DialogConfirmation',{ref:"DialogConfirmation",attrs:{"is-open":_vm.dialogues.confirm,"title":_vm.dialogues.confirmation.title,"body":_vm.dialogues.confirmation.message,"width":600,"button-name":_vm.dialogues.confirmation.btn.name,"button-color":_vm.dialogues.confirmation.btn.color,"error":_vm.error,"human-error":_vm.humanError,"submitting":_vm.dialogues.submittingDelete},on:{"close-dialog":function($event){return _vm.closeDialogConfirmation()},"submit":function($event){return _vm.deleteAudit()}}}),_c('v-card-title',[_c('div',{staticClass:"d-flex flex-column"},[_c('span',[_vm._v("Ficheiros Pokercraft")])]),_c('v-spacer'),(_vm.userIsRailManager && _vm.$route.name != 'Ficheiros do Jogador')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"accent","depressed":""},on:{"click":function($event){return _vm.$emit('upload-audit')}}},on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-upload ")]),_vm._v(" Importar ")],1)]}}],null,false,2223957105)},[_c('span',[_vm._v("Adicionar audit em nome do jogador")])]):_vm._e()],1),_c('div',[_c('v-text-field',{staticClass:"mx-4 d-flex d-md-none",attrs:{"append-icon":"mdi-magnify","label":"Pesquisar","single-line":"","hide-details":""},on:{"input":function($event){return _vm.search()}},model:{value:(_vm.dataTable.search),callback:function ($$v) {_vm.$set(_vm.dataTable, "search", $$v)},expression:"dataTable.search"}})],1),(_vm.showTabs)?_c('v-tabs',{attrs:{"show-arrows":"","color":"accent"},model:{value:(_vm.tabs.current),callback:function ($$v) {_vm.$set(_vm.tabs, "current", $$v)},expression:"tabs.current"}},_vm._l((_vm.tabs.items),function(tab,index){return _c('v-tab',{key:index,staticClass:"text--primary"},[_vm._v(" "+_vm._s(tab.name)+" ")])}),1):_vm._e(),_c('v-tabs-items',{model:{value:(_vm.tabs.current),callback:function ($$v) {_vm.$set(_vm.tabs, "current", $$v)},expression:"tabs.current"}},[_c('v-data-table',{style:({'min-height': '530px', 'overflow':'auto'}),attrs:{"headers":_vm.tableHeaders,"items":_vm.tableData,"search":_vm.dataTable.search,"server-items-length":_vm.tableTotal,"options":_vm.options,"loading":_vm.tableLoading,"must-sort":""},on:{"update:options":function (e) { return (_vm.optionsComputed = e); },"click:row":_vm.setActiveItem},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({style:({ color: _vm.valueColorFinal()})},'span',attrs,false),on),[_vm._v(_vm._s(_vm.getDate(item)))])]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.getDate(item, 'all')))])])]}},{key:"item.userTarget",fn:function(ref){
var item = ref.item;
return [(_vm.userIsRailManager && item.userTarget )?_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":'/players/' + item.userTarget.id + '/'}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-avatar',{staticClass:"mr-2",attrs:{"size":"32px","color":"accent"}},[(item.userTarget.imgCutout != null)?_c('v-img',{staticClass:"mt-2",attrs:{"position":"","src":_vm.imageCutout(item.userTarget)}}):_c('span',{staticClass:"font-weight-semibold"},[_vm._v(" "+_vm._s(item.userTarget.displayName[0])+" ")])],1),_c('span',[_vm._v(" "+_vm._s(item.userTarget ? item.userTarget.name : 'Por definir')+" ")])],1)]):_c('div',{staticClass:"d-flex align-center"},[_c('v-avatar',{staticClass:"mr-2",attrs:{"size":"32px","color":"accent"}},[(item.userTarget.imgCutout != null)?_c('v-img',{staticClass:"mt-2",attrs:{"position":"","src":_vm.imageCutout(item.userTarget)}}):_c('span',{staticClass:"font-weight-semibold"},[_vm._v(" "+_vm._s(item.userTarget.displayName[0])+" ")])],1),_c('span',[_vm._v(" "+_vm._s(item.userTarget ? item.userTarget.name : 'Por definir')+" ")])],1)]}},{key:"item.network",fn:function(ref){
var item = ref.item;
return [(_vm.userIsRailManager)?_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":'/networks/' +item.network.id}},[_c('div',{staticClass:"d-flex align-center"},[_c('img',{staticClass:"mr-2",attrs:{"src":require('@/assets/networks/' + item.network.imageURL)}}),_c('span',[_vm._v(_vm._s(item.network.name))])])]):_c('div',{staticClass:"d-flex align-center"},[_c('img',{staticClass:"mr-2",attrs:{"src":require('@/assets/networks/' + item.network.imageURL)}}),_c('span',[_vm._v(_vm._s(item.network.name))])])]}},{key:"item.file",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('a',{staticClass:"text-decoration-none",attrs:{"href":item.link,"download":""}},[_c('v-chip',_vm._g(_vm._b({staticStyle:{"cursor":"pointer"},attrs:{"small":""}},'v-chip',attrs,false),on),[_c('span',{staticClass:"custom-label"},[_vm._v(" "+_vm._s(item.file)+" ")]),_c('v-icon',{staticClass:"ml-2",attrs:{"small":""}},[_vm._v(" mdi-download ")])],1)],1)]}}],null,true)},[_c('div',{staticClass:"d-flex flex-column align-center"},[_c('span',[_vm._v(" "+_vm._s(item.file))]),_c('span',[_vm._v("Download audit")])])])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('span',[_c('v-chip',{staticClass:"w-100",class:item.status == _vm.$audits.status.REQUESTED ? 'black--text' : 'white--text',attrs:{"small":"","color":_vm.getStatusColor(item.status)}},[_c('span',[_vm._v(" "+_vm._s(_vm.getStatusName(item.status))+" ")])])],1)]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":'/tools/pokercraft/' + item.id}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"icon":"","small":""}},[(_vm.singleViewAuditLoading && _vm.selectedAudit && (_vm.selectedAudit.id == item.id))?_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-spin mdi-loading ")]):_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-open-in-new ")])],1)]}}],null,true)},[_c('span',[_vm._v("Abrir")])])],1),(_vm.userIsRailManager)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""}},'v-btn',attrs,false),on),[_c('a',{staticClass:"text-decoration-none",attrs:{"href":item.link,"download":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-download")])],1)])]}}],null,true)},[_c('span',[_vm._v("Download Audit")])]):_vm._e(),(_vm.userIsRailManager)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.openDialogConfirmation(item)}}},[(_vm.deleteAuditLoading && _vm.selectedAudit && (_vm.selectedAudit.id == item.id))?_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-spin mdi-loading ")]):_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v("Apagar")])]):_vm._e()]}},{key:"no-data",fn:function(){return [_c('div',{staticClass:"fill-height"},[_c('EmptyStateNoData',{staticClass:"justify-center flex-column d-flex",style:({'min-height': '500px'}),attrs:{"icon":"mdi-check-circle-outline","title":"Não existem audits importados","message":"Ajusta os filtros de data, jogador e/ou estado para encontrar resultados","class-color":"success white--text","class-icon":"white"}})],1)]},proxy:true},{key:"no-results",fn:function(){return [_c('EmptyStateNoData',{attrs:{"icon":"mdi-magnify","title":"Não foram encontrados resultados","message":"Ajuste os termos da sua pesquisa para encontrar mais resultados"}})]},proxy:true},{key:"loading",fn:function(){return [_c('v-skeleton-loader',{attrs:{"type":"table-tbody","height":"250px"}}),_c('v-skeleton-loader',{attrs:{"type":"table-tbody","height":"182px"}})]},proxy:true}])},[_c('template',{slot:"progress"},[_c('v-progress-linear',{attrs:{"color":"accent","indeterminate":""}})],1)],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }