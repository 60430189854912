import axios from 'axios';
import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

const state = {
    data: [],
    loading: true,
    selected: {},
    error: null,
    humanError: null,
};

const getters = {
    data: state => state.data,
    loading: state => state.loading,
    selected: state => state.selected,
    error: state => state.error,
    humanError: state => state.humanError,
};

const mutations = {
    setData: (state, payload) => { state.data = payload; },
    setLoading: (state, payload) => { state.loading = payload; },
    setSelected: (state, payload) => { state.selected = payload; },
    setError: (state, payload) => { state.error = payload; },
    setHumanError: (state, payload) => { state.humanError = payload; },
    add: (state, payload) => {
        if (!state.data || !state.data.length) {
            state.data = [];
        }

        state.data.unshift(payload);
    },
    update: (state, payload) => {
        const index = state.data.findIndex(d => d.id == payload.id);

        const updatedItems = [
            ...state.data.slice(0, index),
            payload,
            ...state.data.slice(index + 1)
        ];

        state.data = updatedItems;
    },
    deleteById: (state, id) => {
        state.data.splice(
            state.data.findIndex(d => d.id == id),
            1
        );
    },
};

const actions = {
    async get({ commit, dispatch}) {
        commit('setLoading', true); 
        let output = false; 
        let request_url = Vue.prototype.$calendar_api + 'event_category/'; 

        return axios.get(request_url)
            .then(function (response) {
                output = true;
                commit('setData', response.data.data);
            })
            .catch(function (error) {
                commit('setError', error.response ? error.response.data.message : error);
                output = false;
            })
            .then(function () {
                commit('setLoading', false);
                return output;
            });
    },

    async getById({ commit, dispatch}, id) {
        commit('setLoading', true);
        let output = false; 
        let request_url = Vue.prototype.$calendar_api + 'event_category/' + id + '/';

        dispatch('prepareAxios'); 

        return axios.get(request_url)
            .then(function (response) {
                output = true;
                commit('setData', response.data.data); 
            })
            .catch(function (error) {
                commit('setError', error.response ? error.response.data.message : error);
                output = false;
            })
            .then(function () {
                commit('setLoading', false);
                return output;
            });
    },

    async create({ commit, dispatch }, payload) {
        dispatch('clearError');

        // Handle payload data
        let data = JSON.stringify(payload.body);

        // Configure request
        let request_url = Vue.prototype.$calendar_api + 'event_category/';
        let config = {
            method: 'POST',
            url: request_url,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        }

        // Execute request & return
        let output = false;

        return axios(config)
        .then(function (response) {
            output = response.data.success;

            if (output) {
                commit('add', response.data.data);
                commit('setSelected', response.data.data);
            } else {
                commit('setError', response.data.message);
                commit('setHumanError', response.data.human_message);
            }
        })
        .catch(function (error) {
            commit('setError', error.response ? error.response.data.message : error);
            commit('setHumanError', error.response ? error.response.data.human_message : error);
            output = false;
        })
        .then(function () {
            return output;
        });
    },

    async update({ commit, dispatch }, payload) {
        dispatch('clearError');

        // Handle payload data
        let data = JSON.stringify(payload.body);

        // Configure request
        const request_url = Vue.prototype.$calendar_api  + 'event_category/' + payload.body.id + '/';
        let config = {
            method: 'PUT',
            url: request_url,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        }

        // Execute request & return
        let output = false;

        return axios(config)
        .then(function (response) {
            output = response.data.success;

            if (output) {
                commit('update', response.data.data);
                commit('setSelected', response.data.data);
            } else {
                commit('setError', response.data.message);
                commit('setHumanError', response.data.human_message);
            }
        })
        .catch(function (error) {
            commit('setError', error.response ? error.response.data.message : error);
            commit('setHumanError', error.response ? error.response.data.human_message : error);
            output = false;
        })
        .then(function () {
            return output;
        });
    },

    async delete({ commit, dispatch }, id) {
        dispatch('clearError');

        // Configure request
        const request_url = Vue.prototype.$calendar_api  + 'event_category/' + id + '/';

        // Execute request & return
        let output = false;

        let config = {
            method: 'DELETE',
            url: request_url, 
            headers: {
                'Content-Type': 'application/json'
            },
        }

        return axios(config)
        .then(function (response) {
            output = response.data.success;
            commit('deleteById', id);
        })
        .catch(function (error) {
            commit('setError', error.response ? error.response.data.message : error);
            commit('setHumanError', error.response ? error.response.data.human_message : error);
            output = false;
        })
        .then(function () {
            commit('setLoading', false);
            return output;
        });
    },

    clearError({ commit }) {
        commit('setError', null);
        commit('setHumanError', null);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};