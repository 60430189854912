import { mapGetters } from 'vuex';
import EmptyStateNoData from '@/components/EmptyStateNoData';

export default {
    name: 'LineChartCumulativePNL',

    components: {
        EmptyStateNoData,
    },

    props: {
        title: {
            type: String,
            required: false,
            default: '',
        },
        subtitle: {
            type: String,
            required: false,
            default: '',
        },
        chartType: {
            type: String,
            required: false,
            default: 'area',
        },
        data: {
            type: Array,
            required: false,
            default: () => [],
        },
        labels: {
            type: Array,
            required: false,
            default: () => [],
        },
        loading: {
            type: Boolean,
            required: false,
            default: false
        },
        labelYaxisMax: {
            type: Number,
            required: false,
            default: undefined,
        },
        labelYaxisMin: {
            type: Number,
            required: false,
            default: undefined,
        },
        enableDataLabels: {
            type: Boolean,
            required: false,
            default: false
        },
        height: {
            type: String,
            required: false,
            default: '350'
        },
        customTickAmount: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    data() {
        return this.initialState();
    },

    computed: {
        ...mapGetters({
            themeDarkMode: 'UI/themeDarkMode',
        }),

        series: function () {
            return [{
                name: this.title,
                data: this.data,
            }];
        },

        options: function () {
            return {
                chart: {
                    type: this.chartType,
                    zoom: {
                        enabled: false
                    },
                    toolbar: {
                        show: false
                    },
                    foreColor: '#f6f7f8',
                    background: this.themeDarkMode ? 'dark' : 'light',
                },
                labels: this.labels,
                colors: ['#FFC900'],
                dataLabels: {
                    enabled: this.enableDataLabels,
                },
                stroke: {
                    curve: 'straight'
                },
                xaxis: {
                    type: 'datetime',
                    labels: {
                        format: 'dd/MM',
                        show: true,
                        style: {
                            colors: this.themeDarkMode ? '#FFFFFF' : '#000000',
                        },
                    }
                },
                yaxis: {
                    opposite: true,
                    labels: {
                        show: true,
                        style: {
                            colors: this.themeDarkMode ? '#FFFFFF' : '#000000',
                        },
                    },
                    max: this.labelYaxisMax,
                    min: this.labelYaxisMin,
                    tickAmount: this.getTickAmount(),
                },
                legend: {
                    horizontalAlign: 'left',
                },
                theme: {
                    mode: this.themeDarkMode ? 'dark' : 'light',
                }
            }
        },
    },

    watch: {
        themeDarkMode: function () {
            if (this.$refs.LineChart) {
                this.$refs.LineChart.refresh();
            }
        },
    },

    methods: {
        initialState() {
            return {}
        },

        getTickAmount() {
            let output = 6;
            if (this.customTickAmount) {
                output = 1;
                let seriesData = this._.uniq(this.data);
    
                output = seriesData.length - 1;
            }
            return output;  
        }
    },
}
