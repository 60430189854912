import { mapGetters } from 'vuex';

export default {
    name: 'CardStepper',

    props: {
        steps: {
            type: Number,
            required: false,
            default: 1,
        },
        titles: {
            type: Array,
            required: true,
            default: [
                { step: 1, title: 'Title' },
            ],
        },
        validations: {
            type: Array,
            required: false,
            default: function() {
                let output = [];

                for (let i = 0; i < this.steps; i++) {
                    output[i] = true;
                }

                return output;
            },
        }
    },

    data() {
        return this.initialState();
    },

    computed: {
        ...mapGetters({
            cardStepperStep: 'UI/cardStepperStep',
        }),
        /**
         * @returns {String}
         */
        currentTitle() {
            return this.titles[this.step - 1];
        },

        /**
         * @returns {Boolean} If true, button is enabled
         */
        currentStepValidation() {
            return this.validations[this.step - 1];
        },

        /**
         * @returns {Boolean}
         */
        isFinalStep() {
            return this.step == this.steps;
        },
    },

    watch: {
        step: function (newValue) {
            this.$store.commit('UI/setCardStepperStep', newValue);
        },
        cardStepperStep: function (newValue) {
            if (newValue != this.step) {
                this.step = newValue;
            }
        }
    },

    methods: {
        initialState() {
            return {
                step: 1,
                submitting: false,
            };
        },

        currentStepAction() {
            if (this.isFinalStep) {
                this.$emit('submit');
            } else {
                this.step++;
            }
        },

        toggleSubmitting() {
            this.submitting = ! this.submitting;
        }
    }
}   
