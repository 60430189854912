import Vue from 'vue';
import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth';
import Hero from '@/components/Hero/Hero.vue';
import NetworkSharkscopeDropdown from '@/components/NetworkSharkscopeDropdown/NetworkSharkscopeDropdown.vue';
import AlertError from '@/components/Alerts/AlertError/AlertError.vue';

export default {
    name: 'HRC',

    mixins: [
      AuthMixin,
    ],

    components: {
      Hero,
      NetworkSharkscopeDropdown,
      AlertError,
    },

    computed: {
      ...mapGetters({
        userType: 'auth/userType',
        userRoles: 'auth/userRoles',
        networksSharkScope: 'networksSharkscope/data',
        prizeStructure: 'toolsHRC/prizeStructure',
        loadingPrizeStructure: 'toolsHRC/loadingPrizeStructure',
        errorPrizeStructure: 'toolsHRC/errorPrizeStructure',
        humanErrorPrizeStructure: 'toolsHRC/humanErrorPrizeStructure',
      }),

      apiPayload() {
        return {
          action: 'prizeStructure',
          network: this.selectedNetworkCode,
          game_id: this.formInputs.sharkscopeTournamentId,
          starting_chips: this.formInputs.startingChips,
        }
      },

      selectedNetworkCode() {
        return this.formInputs.network
               ? this.networksSharkScope.find(e => e.id == this.formInputs.network).code
               : null;
      },
    },

    created() {
      this.initialize();
    },

    data() {
      return this.initialState();
    },

    methods: {
      initialize() {
        this.allowOnlyRoles(['rail_admin', 'rail_manager', 'tracker_admin', 'tracker_manager', 'hrcninja_user']);
      },

      initialState() {
        return {
          formInputs: {
            network: null,
            sharkscopeTournamentId: null,
            startingChips: null,
          },
          formRules: {
            network: [v => !! v || 'Introduza a network do jogo'],
            sharkscopeTournamentId: [v => !! v || 'Introduza o ID SharkScope do torneio'],
            startingChips: [v => !! v || 'Introduza o montante inicial de fichas'],
          },
        }
      },

      async submit() {
        // Validate form
        if ( ! this.$refs.FormHRC.validate() ) {
          return false;
        }

        // Store action
        await this.$store.dispatch('toolsHRC/getPrizeStructure', this.apiPayload);

        // Error case
        if ( !  _.isNull(this.errorPrizeStructure) ) {
          return false;
        }

        // Download file
        const filename = `${this.selectedNetworkCode}-${this.formInputs.sharkscopeTournamentId}.json`;
        this.download_file(filename, this.prizeStructure);
      },

      download_file() {
        this.spawn_download_prompt(this.generate_download_filename(), JSON.stringify(this.prizeStructure), 'application/json');
      },

      /**
       * Generate a file to be downloaded
       * @ref https://stackoverflow.com/a/35251739/1298923
       * 
       * @param {String} name Name of the file
       * @param {String} contents File content
       * @param {String} mime_type Mime type
       */
      spawn_download_prompt(name, contents, mime_type) {
        mime_type = mime_type || 'text/plain';

        var blob = new Blob([contents], {type: mime_type});

        var dlink = document.createElement('a');
        dlink.download = name;
        dlink.href = window.URL.createObjectURL(blob);
        dlink.onclick = function(e) {
            // revokeObjectURL needs a delay to work properly
            var that = this;
            setTimeout(function() {
                window.URL.revokeObjectURL(that.href);
            }, 1500);
        };

        dlink.click();
        dlink.remove();
      },

      generate_download_filename() {
        return `${this.formInputs.network}-${this.formInputs.sharkscopeTournamentId}.json`;
      },

      generate_download_link() {
        return Vue.prototype.$ctms_api + this.generate_download_filename();
      },
    }
}