import Hero from '@/components/Hero/Hero.vue';
import TableTeams from '@/components/Tables/TableTeams/TableTeams.vue'
import DialogAddEditTeam from '@/components/Dialog/DialogAddEditTeam/DialogAddEditTeam.vue';
import { mapGetters } from 'vuex';
import DataMixin from '@/mixins/Data';
import AuthMixin from "@/mixins/Auth";

export default {
    name: 'Teams',
    computed: {
        ...mapGetters({
            teams: 'teams/teams',
        })
    },

    data() {
        return this.initialState();
    },
   
    components: {
        Hero,
        TableTeams,
        DialogAddEditTeam
    },

    mixins: [
        DataMixin,
        AuthMixin,
    ],

    async created() {
        await this.allowOnlyRoles(['rail_admin', 'rail_manager', 'tracker_admin', 'tracker_manager', 'team_cash_manager']);
    },
    
    methods: {
        initialState() {
            return {
                isOpened: false,
                dialogues: {
                    addTeam: false,
                },
                teamForEdit: null,
                teamFields: {
                  name: '',
                  type: 1,
                  contractDuration: 0,
                  profitShare: null,
                  action: null,
                  ranking: null,
                  numberContract: null,
                  numberEvents: null,
                  eventsMonthly: null,
                  sectionsMonthly: null,
                  groupsGroupID: null,
                  buddyBossGroupId: null,
                }
              };
        },

        toggleDialog(dialog) {
            this.dialogues[dialog] = ! this.dialogues[dialog];
        },
        
    },
}