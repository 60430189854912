var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"fill-height"},[_c('v-card-title',[_vm._v(" Lucro por jogador "),_c('v-spacer'),_c('v-text-field',{staticClass:"table-search-field d-none d-md-none",attrs:{"append-icon":"mdi-magnify","label":"Pesquisar","single-line":"","hide-details":"","outlined":"","dense":""},model:{value:(_vm.dataTable.search),callback:function ($$v) {_vm.$set(_vm.dataTable, "search", $$v)},expression:"dataTable.search"}})],1),_c('div',[_c('v-text-field',{staticClass:"mx-4 d-none d-md-none",attrs:{"append-icon":"mdi-magnify","label":"Pesquisar","single-line":"","hide-details":""},model:{value:(_vm.dataTable.search),callback:function ($$v) {_vm.$set(_vm.dataTable, "search", $$v)},expression:"dataTable.search"}})],1),_c('v-data-table',{staticClass:"table-footer-bottom",style:({
      'max-height': _vm.heightComputed,
      'min-height': _vm.minHeight,
      overflow: 'auto',
    }),attrs:{"headers":_vm.headers,"items":_vm.networkPnlUsers,"search":_vm.dataTable.search,"options":_vm.options,"loading":_vm.networkPnlUsersLoading,"server-items-length":_vm.networkPnlUsersTotal,"height":"100%","must-sort":"","fixed-header":""},on:{"update:options":function (e) { return (_vm.optionsComputed = e); }},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
    var item = ref.item;
return [(item)?_c('UserCard',{attrs:{"id":item.id,"display-name":item.displayName,"img-cutout":item.imgCutout,"show-icon":false}}):_vm._e()]}},{key:"item.pnl.value",fn:function(ref){
    var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getChipColor(item.pnl.value)}},[_c('span',{staticClass:"mr-2"},[_vm._v(_vm._s(_vm.currencyToBeDisplayed)+" ")]),_c('currency-input',{staticClass:"text-end",style:({ color: 'white', width: '90px' }),attrs:{"value":item.pnl.value,"allow-negative":"","currency":null,"precision":2,"disabled":""}})],1)]}},{key:"item.teams",fn:function(ref){
    var item = ref.item;
return [(!_vm._.isEmpty(item.teams))?_c('div',{staticClass:"d-flex"},_vm._l((item.teams),function(team){return _c('span',{key:team.id,staticClass:"d-flex"},[_c('v-chip',{staticClass:"mx-2",attrs:{"pill":"","small":""}},[_vm._v(" "+_vm._s(team.name)+" ")])],1)}),0):_c('div',[_vm._v(" Sem Equipa ")])]}},{key:"no-data",fn:function(){return [_c('EmptyStateNoData',{attrs:{"icon":"mdi-check-circle-outline","title":"Não existem jogadores registados"}})]},proxy:true},{key:"no-results",fn:function(){return [_c('EmptyStateNoData',{attrs:{"icon":"mdi-magnify","title":"Não foram encontrados resultados","message":"Ajuste os termos da sua pesquisa para encontrar mais resultados"}})]},proxy:true},{key:"loading",fn:function(){return [_c('v-skeleton-loader',{attrs:{"type":"table-tbody"}})]},proxy:true}])},[_c('template',{slot:"progress"},[_c('v-progress-linear',{attrs:{"color":"accent","indeterminate":""}})],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }