import Vue from 'vue';
import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth.vue';
import LoadingSpinner from '@/components/LoadingSpinner/LoadingSpinner.vue';

export default {

    mixins: [
      AuthMixin,
    ],
    
    data() {
      return this.initialState();
    },

    components: {
      LoadingSpinner
    },

    async created(){
      await this.fetchData();
    },

    computed: {
      ...mapGetters({
        loading: 'groups/loading',
        error: 'groups/error',
        userTrackerId: 'auth/userTrackerId',
        userGroup: 'auth/userGroup',
        selectedGroup: 'groups/selectedGroup',
        groupChangesByUserId: 'groups/groupChangesByUserId',
        groupChangesByUserIdLoading: 'groups/groupChangesByUserIdLoading',
      }),

      getTitle() {
        let output = { key: null, name: 'Sem dados', text: 'Sem dados', subtitle: 'Sem dados' };
        let summaryMode = this._.get(this.groupChangesByUserId, 'summary.mode', null);

        if(summaryMode){
          let reason = this.groupChangesByUserId.summary.mode.reason;
          output = Vue.prototype.$GroupsChangeReason.find((e) => e.key === reason);
        }
        
        return output;
      },

      getDirection() {
        let output = { key: null, name: 'Sem dados', icon: 'mdi-close', color: 'error' };
        let summaryMode = this._.get(this.groupChangesByUserId, 'summary.mode', null);
        
        if (summaryMode) {
          output = Vue.prototype.$GroupChangeDirection.find((e) => e.key === this.groupChangesByUserId.summary.mode.direction);
        }
        return output;
      },

      badgePathComputed() {
        return this.userGroup && this.userGroup.imageBadge
          ? this.userGroup.imageBadge
          : 'default.png';
      }
  },

    methods: {
      initialState(){
        return {
            show: false,
            title: ['Subir', 'Descer', 'Recuperar'],
            progress: null,
            groupImages: [],
            checklist: []
        }
      },

      getProgressBarColor() {
        const direction = this.getDirection && this.getDirection.key ? this.getDirection.key : null;
        const reason = this.getTitle.key;
    
        if (!direction) {
            return 'grey';
        }

        const isRecovery = reason === Vue.prototype.$GroupsChangeReason[5].key || reason === Vue.prototype.$GroupsChangeReason[6].key;
        return direction === Vue.prototype.$GroupChangeDirection[1].key ? (isRecovery ? '#fb8c00' : 'green') : '#ff5252';
      },    

      async fetchData(){
        let payload = {
          id: this.userTrackerId,
        }
        if (this.userIsPlayer) {
          await this.$store.dispatch('groups/getOwnGroupChanges', payload);
        }
        await this.populateFields();
      },

      async populateFields(){
        await this.getImages();
        this.getPercentage();
        this.getChecklist();
      },

      getChecklist() {
        this.checklist = [];
        if (this.groupChangesByUserId && this.groupChangesByUserId.summary) {
          for (let key in this.groupChangesByUserId.summary) {
            if (key != "mode") {
              let item = this.groupChangesByUserId.summary[key];
              let payload = {
                text: item.label,
                checked: item.value,
              }
              this.checklist.push(payload);
            }
          }
        }
        return this.checklist
      },

      async getImages(){
        if(this.groupChangesByUserId && this.userGroup && this.userGroup.imageBadge){

          if(this.groupChangesByUserId.summary.mode.direction == Vue.prototype.$GroupChangeDirection[0].key){
            
            let payload = {
              id: this.userGroup.previous,
            }
            await this.$store.dispatch('groups/getGroup', payload);
            if (this.selectedGroup) {
              this.groupImages.push(this.selectedGroup.imageBadge);
            }
            this.groupImages.push(this.userGroup.imageBadge);

          }else{
            let payload = {
              id: this.userGroup.next,
            }
            await this.$store.dispatch('groups/getGroup', payload);

            this.groupImages.push(this.userGroup.imageBadge);
            if (this.selectedGroup) {
              this.groupImages.push(this.selectedGroup.imageBadge);
            }
          }
        }
      },

      getPercentage() {
        let summaryMode = this._.get(this.groupChangesByUserId, 'summary.mode', null);
        if(summaryMode){
          this.progress = (this.groupChangesByUserId.summary.mode.value && this.groupChangesByUserId.summary.mode.value.pct) 
          ? (this.groupChangesByUserId.summary.mode.value.pct * 100 ).toFixed(2) : 0;
        }
      },

      
      calculateCardHeight() {
        let minHeight = 150;
        let additionalHeight = this.checklist.length * 40;
        return Math.max(minHeight, minHeight + additionalHeight);
      },
    },

  };