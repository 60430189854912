import { isInteger } from "core-js/fn/number";
import Vue from "vue";
import { mapGetters } from "vuex";

export default {
    name: "dialog-approval",
    data() {
        return this.initialState();
    },
    props: {
        dialogOpen: {
            type: Boolean,
            required: true,
            default: false
        },
        hintMessage: {
            type: String,
            required: false,
            default: "Após este nº de dias, a contar do momento atual, o acesso é automaticamente removido.",
        },
        days: {
            type: Array,
            required: false,
            default: () => [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
        },
        usernameId: {
            type: Number,
            required: false,
            default: null,
        },
        nameVideo: {
            type: String,
            required: false,
            default: null,
        },
    },
    computed: {
        ...mapGetters({
            selectedVideo: "videoRequests/selectedVideo",
            users: "users_v1/users",
        }),
    },
    methods: {
        initialState() {
            return {
                name_video: this.nameVideo,
                userId: this.usernameId,
                expiry: 7,
                rules: {
                    fieldDays: [
                        (v) => !!v || "Por favor introduza um número de dias",
                        (v) =>
                            parseInt(v) > 0 ||
                            "Por favor introduza um valor numérico positivo",
                        (v) =>
                            isInteger(v) ||
                            "Por favor introduza um número inteiro",
                    ],
                },
                form_valid: true,
            };
        },

        submit() {
            // Validate form
            if (!this.$refs.form.validate()) return false;

            // Wait 0.5s before proceeding because v-combobox are difficult to validate
            setTimeout(() => {
                if (!this.form_valid) return false;

                // Build payload
                let payload = {
                    id: this.selectedVideo.id_video_requests,
                    status: Vue.prototype.$video_requests.status["approved"],
                    expiry: this.expiry,
                    user: this.selectedVideo.ref_users_video_requests,
                    video: this.selectedVideo.ref_post_video_requests,
                };

                // Build result
                this.$store.dispatch(
                    "videoRequests/updateRequestVideo",
                    payload
                );

                // Close dialog and return
                this.closeDialog();
                this.$store.dispatch("UI/showSnackbar", {
                    message: "O pedido de video foi marcado como aprovado",
                    color: "success",
                });
            }, 500);
        },

        closeDialog() {
            this.$emit("closeDialog");
        },
        getUserDisplayNameById(id) {
            if (this.users) {
                let user = this.users.filter((e) => e.id_users == id);
                return user[0]["display_name_users"];
            }
        },

        populateFields() {
            this.name_video = this.selectedVideo.wp_post_title_video_requests;
            this.userId = this.selectedVideo.ref_users_video_requests;
        },
    },
};
