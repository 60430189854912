import Vue from 'vue';
import { mapGetters } from 'vuex';
import RailMixin from '@/mixins/Rail.vue';
import CardStepper from '@/components/CardStepper/CardStepper.vue';
import CardStepperNotes from '@/components/CardStepper/CardStepperNotes/CardStepperNotes.vue';
import CardStepperResume from '@/components/CardStepper/CardStepperResume/CardStepperResume.vue';
import NicknameDropdown from '@/components/Rail/NicknameDropdown/NicknameDropdown.vue';
import RowTransfer from '@/components/Rail/RowTransfer/RowTransfer.vue';
import InputFinancial from '@/components/InputFinancial/InputFinancial.vue';
import AlertError from '@/components/Alerts/AlertError/AlertError.vue';
import DialogConfirmation from '@/components/Rail/Dialog/DialogConfirmation/DialogConfirmation.vue';

export default {
  name: 'BankrollReduction',

  components: {
    CardStepper,
    CardStepperNotes,
    CardStepperResume,
    NicknameDropdown,
    RowTransfer,
    InputFinancial,
    AlertError,
    DialogConfirmation,
  },

  mixins: [
    RailMixin,
  ],

  computed: {
    ...mapGetters({
      cardStepperStep: 'UI/cardStepperStep',
      error: 'railRequests/error',
      nicknamesAllMine: 'nicknames/nicknamesAllMine',
      wallets: 'railWallets/wallets',
      walletsManagement: 'railWallets/walletsManagement',
      humanError: 'railRequests/humanError',
      selectedRequest: 'railRequests/selectedRequest',
    }),
  },

  watch: {
    'form.walletOrNickname': function () {
      this.setCardStepperProps();
    }
  },

  mounted() {
    // Clear error in <input-financial>, triggered naturally when element is loaded
    this.$refs.formStep_1.reset();
  },

  data() {
    return this.initialState();
  },

  created() {
    this.initialize();
  },

  methods: {
    initialState() {
      return {
        transfersConfirmationStep: 2,
        resumeStep: 3,
        numSteps: 3,
        cardTitles: [
          'Selecionar network',
          'Confirmar transferências',
          'Resumo'
        ],
        formValidations: [
          false,
          false,
          true,
        ],
        checkboxTransferDone: false,
        isFirstWalletANetwork: false,
        form: {
          walletOrNickname: null,
          nickname: null,
          balance: {
            value: null,
          },
          nicknameSource: null,
          walletSource: null,
          walletTarget: null,
          observation: null,
          gyazoLink: null,
          fileName: [],
          isTransferPending: true,
        },
        rules: {
          balance: [
            (v) => !!v || 'Por favor introduza o montante',
            (v) => !Number.isNaN(v) || 'Insira um valor numérico',
          ],
          nicknameDropdown: [
            (v) => !!v || 'Por favor introduza uma Sala/Carteira'
          ],
          checkbox: [
            (v) => !!v || '',
          ],
        },
        dialogues: {
          confirmation: false,
        },
      }
    },

    initialize() {
      this.fetchData();
    },

    /**
    * Set bunch of props used by CardStepper Component
    */
    setCardStepperProps() {
      if (this.form.walletOrNickname && this.form.walletOrNickname.network.id == Vue.prototype.$rail_GGPoker_network_id) {
        this.transfersConfirmationStep = null;
        this.resumeStep = 2;
        this.numSteps = 2;
        this.cardTitles = [
          'Selecionar network',
          'Resumo'
        ];
        this.formValidations = [
          false,
          true,
        ];
      } else {
        this.transfersConfirmationStep = 2;
        this.resumeStep = 3;
        this.numSteps = 3;
        this.cardTitles = [
          'Selecionar network',
          'Confirmar transferências',
          'Resumo'
        ];
        this.formValidations = [
          false,
          false,
          true,
        ];
      }
    },

    /**
     * Fetches the necessary data for all steps
     */
    fetchData() {
      if (this._.isEmpty(this.nicknamesAllMine)) {
        this.$store.dispatch('nicknames/getAllMine');
      }

      if (this._.isEmpty(this.wallets)) {
        this.$store.dispatch('railWallets/getWallets');
      }

      if (this._.isEmpty(this.walletsManagement)) {
        this.$store.dispatch('railWallets/getWalletsFromManagement');
      }
    },

    /**
    * Validates a specific form step.
    * Assumes form steps are named 'formStep_x' where x is a Int 0 - maxStep-1.
    * 
    * @param {Int} step Identifier of the form step to validate.
    * @returns {Void}
    */
    validateStep(step) {
      const formStepRef = 'formStep_' + (step);
            
      // reset financial input validatiion on 1st step
      this.resetFinancialInputValidation();

      // reset checkbox input on 2nd step
      this.resetCheckboxValidation();

      if (this.$refs[formStepRef]) {
        // Update formValidation[step] in a way the DOM will be reactive
        Vue.set(
          this.formValidations,
          step - 1,
          this.$refs[formStepRef].validate()
        );

        // Clean errors if exists
        if (this.error) {
          this.$store.dispatch('railRequests/clearError');
        }
      }
    },

    resetFinancialInputValidation() {
      if (this.$refs.formBalanceRef) {
        this.$refs.formBalanceRef.resetValidation();
      } 
    },

    resetCheckboxValidation() {
      if (this.$refs.formStep_2 && this.$refs.checkboxRef) {
        this.$refs.checkboxRef.resetValidation();
      }
    },

    setActiveItem(item) {
      this.$store.commit(
        'railOverview/setSelectedBalance',
        item
      );
    },

    // retrieve fields from CardStepperNotes component
    getInfoFromCardStepperNotes(payload) {
      if (payload) {
        this.form.gyazoLink = payload.gyazoLink;
        this.form.fileName = payload.filename;
      }
    },

    setFirstWalletType(item) {
      // Set active item on TableBalances
      this.setActiveItem(item);

      if (item) {

        // Set 1 or 2 transfers on Step 2
        this.isFirstWalletANetwork = ! item.network.isWallet;

        if (item.network.isWallet) {
          this.form.nicknameSource = null;
          this.form.walletSource = item;
        } else {
          this.form.nicknameSource = item;
          this.form.walletSource = null;
        }
        
        if (this.$refs.NicknameDropdownTarget) {
          this.$refs.NicknameDropdownTarget.resetNickname();
        }
      }
    },

    /**
     * @returns array passed as prop to RowTransfers Component
     */
     getTransfers() {
       let transfers = [];
       // edge case - networks with backoffice  GGPoker / YaPoker
       if (this.form.walletOrNickname && Vue.prototype.$rail_networks_with_Backoffice.includes(this.form.walletOrNickname.network.id)) {
            // transfer object - (GGPoker to management)
            transfers.push({
              source: this.form.walletOrNickname,
              target: this.form.walletTarget,
              amount: this.form.balance,
              isPending: this.form.isTransferPending,
            });
            return transfers;
       } else {
         // 1st transfer object - (nickname/network to wallet)
          if (this.isFirstWalletANetwork) {
              transfers.push({
                source: this.form.nicknameSource,
                target: this.form.walletSource,
                amount: this.form.balance,
                isPending: this.form.isTransferPending,
              });
         };

         // 2nd transfer object - (source user wallet to admin wallet)
         transfers.push({
           source: this.isFirstWalletANetwork ? this.form.walletSource : this.form.walletOrNickname,
           target: this.form.walletTarget,
           amount: this.form.balance,
           isPending: this.form.isTransferPending,
         });
       }

      return transfers;
    },
     
    /**
     * 
     * @returns an Array[Int] of ids from networks that source user doens't have
     */
    filterNetworks() {
      let difference = [];

      // check for each element present in walletsManagement if it's equal to element present in nicknamesAllMine 
      this.walletsManagement.forEach(el1 => {
        let diff = this.nicknamesAllMine.some(el2 => el2.network.id === el1.network.id);
    
        if (!diff) {
          difference.push(el1.network.id);
        }
      });
      
      return difference;
    },
     
    setSourceDisplayWallet(item) {
      if (item && !this._.isEmpty(this.nicknamesAllMine)) {
        this.form.walletSource = this.nicknamesAllMine.find(element => element.network.id == item.network.id);
        return this.form.walletSource
      }
    },

    // retrieve fields populated by admin to create a Bankroll Reduction request !
    async submit() {
      // Validate form
      if (!this.$refs.formBankrollReductionChild.validate()) {
        return false;
      }

      // Re-enable btn
      if (this.$refs.CardStepper) {
        this.$refs.CardStepper.toggleSubmitting();
      }

      /**
       * Preparing payload
      */

      // Base payload
      let payload = new FormData();

      // transfer - (source user to admin wallet)
      payload.append('transfers[0][source]', this.form.walletOrNickname.id);
      payload.append('transfers[0][target]', this.form.walletTarget.id);
      payload.append('transfers[0][amount]', this.form.balance.value);

      // Add 'observation' field if necessary
      if (this.form.observation) {
        payload.append('observation[description]', this.form.observation);
      }

      // Add 'screenshot' field if necessary
      if (!this._.isEmpty(this.form.fileName)) {
        payload.append('screenshot', this.form.fileName[0]);
      }

      // Add 'observation[url]' field if necessary
      if (this.form.gyazoLink) {
        payload.append('observation[url]', this.form.gyazoLink);
      }

      // Dispatch to store
      let result = await this.$store.dispatch('railRequests/bankrollReductionCreate', payload);

      // Re-enable btn
      if (this.$refs.CardStepper) {
        this.$refs.CardStepper.toggleSubmitting();
      }

      // On success
      if (result === true) {
        // Refresh Requests Transfers and Balances
        this.updateRequestsTransfersAndBalances();
        
        // Show success snackbar
        this.$store.dispatch('UI/showSnackbar', {
          message: 'Pedido de redução de banca criado com sucesso.',
          color: 'success'
        });

        let id = this.selectedRequest.id;
        let url = Vue.prototype.$tab_slider.tabs.requests + id;

        // change hasFinished state in order to change component
        this.$store.dispatch('TabSlider/setHasFinished', true);

        // change to operation single view
        this.$router.push({ path: url });
      }
    },

    checkTransferToManagement() {
      if (this.checkboxTransferDone) {
        this.dialogues.confirmation = true;
      }
    },

    dialogConfirmationConfirm() {
      this.checkboxTransferDone = true;
      this.dialogues.confirmation = false;
    },

    dialogConfirmationCancel() {
      this.checkboxTransferDone = false;
      this.dialogues.confirmation = false;
    },
  }
}