import Vue from 'vue';
import { mapGetters } from 'vuex';
import RailMixin from '@/mixins/Rail.vue';
import DataMixin from '@/mixins/Data.vue';
import AuthMixin from '@/mixins/Auth.vue';
import StoreMixin from '@/mixins/Store.vue';
import EmptyStateNoData from '@/components/EmptyStateNoData';
import RowTransfer from '@/components/Rail/RowTransfer/RowTransfer.vue';
import DialogViewAttachment from '@/components/Rail/Dialog/DialogViewAttachment/DialogViewAttachment.vue';
import UserEventList from '@/components/UserEventList/UserEventList.vue';
import TableNicknamesHistoricBalances from '@/components/Rail//TableNicknamesHistoricBalances/TableNicknamesHistoricBalances.vue';
import NicknameCard from '@/components/NicknameCard/NicknameCard.vue';
import UserCard from '@/components/UserCard/UserCard.vue';

export default {
  name: 'Checkoutpage',

  components: {
    EmptyStateNoData,
    RowTransfer,
    DialogViewAttachment,
    UserEventList,
    TableNicknamesHistoricBalances,
    NicknameCard,
    UserCard,
  },

  mixins: [
    RailMixin,
    DataMixin,
    AuthMixin,
    StoreMixin,
  ],

  computed: {
    ...mapGetters({
      selectedRequest: 'railRequests/selectedRequest',
      selectedTransfer: 'railTransfers/selectedTransfer',
      hasFinished: 'TabSlider/hasFinished',
      reports: 'railReports/reports',
      userTrackerId: 'auth/userTrackerId',
      themeDarkMode: 'UI/themeDarkMode',
    }),
  },

  async created() {
    if (this._.isNull(this.selectedRequest) && !this._.isNull(this.selectedTransfer)) {
      await this.$store.dispatch('railRequests/getRequestById', this.selectedTransfer.id);
    }
  },

  data() {
    return this.initialState();
  },

  methods: {
    initialState() {
      return {
        observation: {
          url: null,
          type: null,
        },
        dialogues: {
          viewAttachment: false
        }
      }
    },

    getEmptyStateMessageByOperationType(selectedRequest) {
      switch (selectedRequest.type) {
        case Vue.prototype.$rail_ops.operations.split:
          return 'Pedido de Split realizado com sucesso';
        case Vue.prototype.$rail_ops.operations.bankroll_reduction:
          return 'Pedido de Redução de Banca realizado com sucesso';
        case Vue.prototype.$rail_ops.operations.bankroll_increment:
          return 'Pedido de Incremento de Banca realizado com sucesso';
        case Vue.prototype.$rail_ops.operations.transfer_to_player:
          return 'Transferência entre Jogadores realizada com sucesso';
        case Vue.prototype.$rail_ops.operations.transfer_to_self:
          return 'Transferência entre contas realizada com sucesso';
        case Vue.prototype.$rail_ops.operations.swap:
          return 'Swap realizado com sucesso';
        case Vue.prototype.$rail_ops.operations.deposit:
          return 'Depósito realizado com sucesso';
        case Vue.prototype.$rail_ops.operations.withdraw:
          return 'Levantamento realizado com sucesso';
        case Vue.prototype.$rail_ops.operations.sessions:
          return 'Sessão registada com sucesso';
        case Vue.prototype.$rail_ops.operations.makeup:
          let operationText = selectedRequest.direction == Vue.prototype.$direction_rail_operations.decrease
            ? 'Rake'
            : 'Makeup'
          if(selectedRequest && selectedRequest.reason && selectedRequest.reason.name == 'Pagamento Makeup') {
            operationText = 'Makeup';
          }
          return `Registo de ${operationText} realizado com sucesso`;
        default:
          break;
      }
    },

    /**
     * @param {Object} item 
     * @returns String source name
     */
    getSourceName(item) {
      let isBankrollIncrement = item.type === Vue.prototype.$rail_ops.operations.bankroll_increment;
      let isMakeup = item.type === Vue.prototype.$rail_ops.operations.makeup;

      if (item && item.user) {
        return item.user.displayName;
      } else if (isBankrollIncrement || isMakeup) {
        return item.targetUser.displayName;
      } else {
        return 'Gestor';
      }
    },

      /**
     * @param {Object} item 
     * @returns String source name
     */
      getSourceNameID(item) {
        let isBankrollIncrement = item.type === Vue.prototype.$rail_ops.operations.bankroll_increment;
        let isMakeup = item.type === Vue.prototype.$rail_ops.operations.makeup;
  
        if (item && item.user) {
          return item.user.id;
        } else if (isBankrollIncrement || isMakeup) {
          return item.targetUser.id;
        } else {
          return null;
        }
      },

    // To show networks/wallets that will receive/send money
    getSourceNicknames() {
      let filter;
      if (this.selectedRequest) {
        switch (this.selectedRequest.type) {
          case Vue.prototype.$rail_ops.operations.bankroll_increment:

            filter = this.selectedRequest.requests.filter(element => element.nickname);

            // when OP is created through UC - TransferToPlayer or Registo Livre
            if (this._.isEmpty(filter)) {
              filter = this.selectedRequest.transfers.map(e => {
                return {
                  source: e.target
                }
              });
            }
            break;
          case Vue.prototype.$rail_ops.operations.swap:

            filter = this.selectedRequest.transfers.map(e => {
              if (e.source.isManagement) {
                return {
                  source: e.target
                }
              } else {
                return {
                  source: e.source
                }
              }
            });
            break;
          case Vue.prototype.$rail_ops.operations.split:

            filter = this.selectedRequest.requests.filter(element => element.nickname);
            break;
          default:

            filter = this.selectedRequest.transfers.filter(element => element.source);
            break;
        }

        if(this.selectedRequest.reason && this.selectedRequest.reason.name && this.selectedRequest.reason.name == 'Pagamento Makeup'){
          filter = filter.filter(e => e.isInternalTransfer == true);
        }
      }
      return filter;
    },

    /**
     * Opens report page
     * @param {Object} report 
     */
    goToReport(report) {
      if (report) {
        // set setSelectedReport
        this.$store.dispatch('railReports/getReportById', report.id);
        // set selected tab
        this.$store.commit('TabSlider/setSelectedTab', Vue.prototype.$tab_slider.tabs.reports);
        // routing
        this.$router.push({ path: Vue.prototype.$tab_slider.tabs.reports + report.id }).catch(() => { });
      }
    },

    /**
    * Retrieve rail operation status and based on rail operation type we return status name
    * @param {*} item 
    */
    getRailOperationPhase(item) {
      let out = '';

      // Case rejected
      if (item.status == Vue.prototype.$rail_status.rejected) {
        out = 'Pedido Rejeitado';
      } else {
        out = Vue.prototype.table_requests_phases[item.type].filter(e => e.value == item.phase)[0].name;
      }
      return out;
    },

    /**
     * <router-link> :to prop expects String, Object. Doesn't accept Null values.
     * @param {*} item 
     * @returns String link
     */
    genPlayerLink(item) {
      return item.user
        ? '/players/' + item.user.id
        : '#';
    },

    /**
     * Populates observation.url and observation.name
     * @param {Object} observation 
     * @returns {Boolean} open dialog or opens new page;
     */
    openDialogViewAttachment(observation) {
      if (observation && observation.url) {
        // Populate DialogViewAttachment props
        this.observation.url = observation.url;
        this.observation.type = observation.type;

        // Validate observation type
        let isTypeLink = observation.type == 1;

        // Execute
        return isTypeLink
          ? window.open(observation.url, '_blank')
          : this.dialogues.viewAttachment = true;
      }
    },

    /**
     * @param {Object} selectedRequest 
     * @returns {String} to be displayed
     */
    getLabelAmountRequest(item) {
      let output = 'Montante:';
      if (item) {
        switch (item.type) {
          case Vue.prototype.$rail_ops.operations.split:
            output = 'Montante total a splitar:'
            break;
          case Vue.prototype.$rail_ops.operations.makeup:
            output = 'Razão:'
            break;
          case Vue.prototype.$rail_ops.operations.bankroll_increment:
            // if created by management
            output = item && item.createdByManagement
              ? ''
              : 'Montante Pedido:';
            break;
          case Vue.prototype.$rail_ops.operations.deposit:
          case Vue.prototype.$rail_ops.operations.withdraw:
          case Vue.prototype.$rail_ops.operations.transfer_to_self:
            output = '';
            break;
          default:
            output = 'Montante Pedido:'
            break;
        }
      }
      return output;
    },

    /**
     * @param {Object} selectedRequest 
     * @returns {String} value to be displayed
     */
    getValueAmountRequest(item) {
      let output;
      // if only 1 currency, getAmountSent will sum values by "value", else by "EUR.value"
      let word = this.opHasSingleCurrency(item)
        ? 'value'
        : 'EUR.value';

      output = this.getAmountRequest(item, word);

      switch (item.type) {
        case Vue.prototype.$rail_ops.operations.deposit:
        case Vue.prototype.$rail_ops.operations.withdraw:
        case Vue.prototype.$rail_ops.operations.transfer_to_self:
          output = '';
          break;
        case Vue.prototype.$rail_ops.operations.bankroll_increment:
          // if created by management
          output = item && item.createdByManagement
            ? ''
            : output;
          break;
        case Vue.prototype.$rail_ops.operations.makeup:
          output = item.reason.name;
          break;
        default:
          output = output;
          break;
      }

      return output;
    },

    /**
     * @param {Object} selectedRequest 
     * @returns {String} to be displayed
     */
    getLabelAmountSent(item) {
      let output = 'Montante:';
      if (item) {
        switch (item.type) {
          case Vue.prototype.$rail_ops.operations.split:
            output = 'Lucro do Split:';
            break;
          case Vue.prototype.$rail_ops.operations.bankroll_reduction:
            output = this.userIsRailManager ? 'Montante a receber:' : 'Montante Enviado:';
            break;
          case Vue.prototype.$rail_ops.operations.bankroll_increment:
            output = this.userIsRailManager ? 'Montante Enviado:' : 'Montante recebido:';
            break;
          case Vue.prototype.$rail_ops.operations.makeup:

            if (item.reason.direction == 'increase') {
              output = this.userIsRailManager ? 'Aumentou makeup:' : 'Montante reduzido:'
            } else {
              output = this.userIsRailManager ? 'Jogador recebeu:' : 'Montante Recebido:'
            }
            break;

          case Vue.prototype.$rail_ops.operations.deposit:
            output = 'Montante Depositado';
            break;
          case Vue.prototype.$rail_ops.operations.withdraw:
            output = 'Montante Levantado:';
            break;
          case Vue.prototype.$rail_ops.operations.transfer_to_self:

            if (item.user && item.targetUser && (item.user.id == item.targetUser.id)) {
              output = 'Montante:';
            } else if (item.targetUser && (item.targetUser.id == this.userTrackerId)) {
              output = 'Montante Recebido:';
            } else if (item.user && (item.user.id == this.userTrackerId)) {
              output = 'Montante Enviado:';
            } else {
              output = 'Montante:';
            }
            break;
          default:
            output = 'Montante Enviado:';
            break;
        }
      }
      return output;
    },

    /**
     * @param {Object} selectedRequest 
     * @returns {String} value to be displayed
     */
    getValueAmountSent(item) {
      let output;
      // if only 1 currency, getAmountSent will sum values by "value", else by "EUR.value"
      let word = this.opHasSingleCurrency(item)
        ? 'value'
        : 'EUR.value';

      output = this.getAmountSent(item, word);

      if(item && item.reason && item.reason.name == 'Pagamento Makeup') {
        output = 0;
      }

      return output;
    },

    /**
     * Get profit amount based on item.transfers
     * @param {Object} item 
     * @returns {String}
     */
    getSplitProfitShareAmount(item) {
      let output;
      let shareValueAmount;
      let currencyID;
      let word = this.opHasSingleCurrency(item)
        ? 'value'
        : 'EUR.value';

      if (item && item.transfers) {
        let totalSplitValue = this.getValueAmountSent(item);
        let transfer_P_O = item.transfers.filter(e => e.type == Vue.prototype.$transfer_types.P_O);

        // if userIsRailManager, returns his share, else returns player share
        shareValueAmount = this.userIsRailManager
          ? Number(totalSplitValue) - this._.sumBy(this._.map(transfer_P_O, 'amount'), word)
          : this._.sumBy(this._.map(transfer_P_O, 'amount'), word);
      }

      if (item.requests && item.requests.length) {
        currencyID = this.opHasSingleCurrency(item)
          ? item.requests[0].nickname.network.currency.id
          : Vue.prototype.$currency_ids.EUR;
      } else if (item.transfers.length) {
        currencyID = this.opHasSingleCurrency(item)
          ? item.transfers[0].source.network.currency.id
          : Vue.prototype.$currency_ids.EUR;
      }
        
      let isCryptoCurrency = this.isCryptoCurrency(currencyID);

      output = `+ ${this.getRailOperationCurrency(this.selectedRequest)} ${shareValueAmount.toFixed(isCryptoCurrency ? 8 : 2)}`;

      return output;
    },

    /**
     * Used in RowTransfers Component
     * If operation type is equal to Makeup then we only display first transfer, else we display every transfer
     * returns {Array}
     */
    handleTransfers() {
      let output = [];

      if (this.selectedRequest.transfers) {
        switch (this.selectedRequest.type) {
          case Vue.prototype.$rail_ops.operations.makeup:
            output = [this.selectedRequest.transfers[0]];
            break;
          default:
            output = this.selectedRequest.transfers
            break;
        }
      }
      if(this.getTextValueAmountRequest(this.selectedRequest) == 'Pagamento Makeup' && this.selectedRequest.transfers.length > 1) {
        output = [this.selectedRequest.transfers[1]]
      }
      return output;
    },

    getManagementAmount() {
      let output;
      let transfersPM;

      if (!this._.isEmpty(this.selectedRequest.transfers)) {
        transfersPM = this.selectedRequest.transfers.filter(e => e.type == Vue.prototype.$transfer_types.P_M);
      }

      // Verify if current operation has one or more currencies
      let hasSingleCurrency = this.opHasSingleCurrency(this.selectedRequest);

      if (!this._.isEmpty(transfersPM)) {
        // if has more than one currency we need to map by EUR value
        output = hasSingleCurrency
          ? transfersPM.map(e => e.amount.value)
          : transfersPM.map(e => e.amount.EUR.value)

        if (!this._.isEmpty(output)) {
          output = output.reduce((a, b) => a + b, 0);
        }

      }

      return output;
    },

    getPlayerAmount() {
      let output;
      let transfersMP;

      if (!this._.isEmpty(this.selectedRequest.transfers)) {
        if (this.selectedRequest.type == Vue.prototype.$rail_ops.operations.split) {
          transfersMP = this.selectedRequest.transfers.filter(e => e.type == Vue.prototype.$transfer_types.P_O);
        } else {
          transfersMP = this.selectedRequest.transfers.filter(e => e.type == Vue.prototype.$transfer_types.M_P);
        }
      }

      // Verify if current operation has one or more currencies
      let hasSingleCurrency = this.opHasSingleCurrency(this.selectedRequest);

      if (!this._.isEmpty(transfersMP)) {
        // if has more than one currency we need to map by EUR value
        output = hasSingleCurrency
          ? transfersMP.map(e => e.amount.value)
          : transfersMP.map(e => e.amount.EUR.value);

        if (!this._.isEmpty(output)) {
          output = output.reduce((a, b) => a + b, 0);
        }
      }

      return output;
    },

    getTextValueAmountRequest(selectedRequest) {
      let noCurrencyLabel = selectedRequest.type == Vue.prototype.$rail_ops.operations.makeup ||
        (selectedRequest.type == Vue.prototype.$rail_ops.operations.bankroll_increment && selectedRequest.createdByManagement) ||
        selectedRequest.type == Vue.prototype.$rail_ops.operations.transfer_to_self ||
        selectedRequest.type == Vue.prototype.$rail_ops.operations.deposit ||
        selectedRequest.type == Vue.prototype.$rail_ops.operations.withdraw;

      if (noCurrencyLabel) {
        return this.getValueAmountRequest(selectedRequest)
      } else {
        return this.getRailOperationCurrency(selectedRequest) + ' ' + this.getValueAmountRequest(selectedRequest)
      }
    },

  }
}