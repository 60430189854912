var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('DialogDownloadCsv',{ref:"DialogDownloadCsv",attrs:{"type":"REPORT","is-open":_vm.dialogues.download.state,"message":_vm.dialogues.download.message},on:{"close-dialog":function($event){_vm.dialogues.download.state = false}}}),_c('v-card',[_c('v-card-title',[_vm._v(" Reports "),_c('v-spacer'),_c('v-text-field',{staticClass:"table-search-field d-none d-md-none",attrs:{"append-icon":"mdi-magnify","label":"Pesquisar","single-line":"","hide-details":"","outlined":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),(_vm.userIsRailManager)?_c('v-tooltip',{staticClass:"d-flex ml-2",attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.openDialogDownloadCsv()}}},[_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-download ")])],1)]}}],null,false,3973998341)},[_c('span',[_vm._v("Exportar tabela")])]):_vm._e()],1),_c('v-text-field',{staticClass:"mx-4 mb-3 d-none d-md-none",attrs:{"append-icon":"mdi-magnify","label":"Pesquisar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('div',[_c('v-tabs',{attrs:{"slot":"extension","show-arrows":"","background-color":"transparent","color":"accent"},on:{"change":function($event){return _vm.filterStatus()}},slot:"extension",model:{value:(_vm.tabs.current),callback:function ($$v) {_vm.$set(_vm.tabs, "current", $$v)},expression:"tabs.current"}},_vm._l((_vm.tabs.headers),function(tab){return _c('v-tab',{key:tab,staticClass:"text--primary"},[_vm._v(" "+_vm._s(tab)+" ")])}),1)],1),_c('v-tabs-items',{model:{value:(_vm.tabs.current),callback:function ($$v) {_vm.$set(_vm.tabs, "current", $$v)},expression:"tabs.current"}},[_c('v-data-table',{attrs:{"headers":_vm.getTableHeaders(),"items":_vm.tableData,"search":_vm.search,"server-items-length":_vm.tableTotal,"options":_vm.options,"loading":_vm.tableLoading,"footer-props":_vm.dataTable.footerProps,"loading-text":"A carregar...","must-sort":""},on:{"click:row":_vm.setActiveItem,"update:options":function (e) { return _vm.optionsComputed = e; }},scopedSlots:_vm._u([{key:"item.selected",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[(_vm.selectedReport && item.id == _vm.selectedReport.id)?_c('v-icon',{staticClass:"circle"}):_vm._e()],1)]}}],null,true)},[_c('span',[_vm._v("Relatório selecionado")])])]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$moment(item.openDate.date).fromNow())+" ")]}},{key:"item.user",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.userCreator.name)+" ")]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.type.name)+" ")]}},{key:"item.railOperation.type",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"text-decoration-none cursor-pointer",attrs:{"to":'/rail/operations/' + item.railOperation.id + '/'}},[_c('v-chip',{staticClass:"w-100",attrs:{"color":_vm.getRailOperationColor(item.railOperation.type),"label":""}},[_vm._v(" "+_vm._s(_vm.getRailOperationName(item.railOperation.type))+" "+_vm._s(_vm.getMakeUpReason(item.railOperation))+" ")])],1)]}},{key:"item.railOperation.user.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.railOperation.user ? item.railOperation.user.name : 'Gestão')+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-inline-flex"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-chip',{attrs:{"color":_vm.getStatusColor(item)}},[_vm._v(_vm._s(_vm.getReportStatus(item)))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getStatusTextToolTip(item)))])])],1)]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",on:{"click":function($event){return _vm.setCurrentCard(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-launch ")])]}}],null,true)},[_c('span',[_vm._v("Abrir")])])]}},{key:"no-data",fn:function(){return [(!_vm.loading)?_c('EmptyStateNoData',{attrs:{"icon":"mdi-check-circle-outline","title":"Não existem reports","class-color":"success white--text","class-icon":"white"}}):_vm._e()]},proxy:true},{key:"no-results",fn:function(){return [_c('EmptyStateNoData',{attrs:{"icon":"mdi-magnify","title":"Não foram encontrados resultados","message":"Ajuste os termos da sua pesquisa para encontrar mais resultados"}})]},proxy:true}])},[_c('template',{slot:"progress"},[_c('v-progress-linear',{attrs:{"color":"accent","indeterminate":""}})],1)],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }