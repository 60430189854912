import { mapGetters } from 'vuex';
import Vue from 'vue';
import DialogLevelChangeUserMode from "../../Dialog/DialogLevelChangeUserMode/DialogLevelChangeUserMode.vue";

export default {
    name: 'AlertLevelChangeMode',

    components: {
        DialogLevelChangeUserMode
    },

    computed: {
        ...mapGetters({
            groupChangeRoutineMode: 'users/groupChangeRoutineMode',
        }),
    },

    data() {
        return this.initialState();
    },

    created() {
		this.fetchData();
	},

    methods: {
        initialState() {
          return {
           modeInfo: {},
           modeName: 'manual',
           loadData: false,
           dialogues: {
                levelChangeUserMode: false,
           }
          }
        },

        getNameMode(newMode = 1){
            let mode = this.modeInfo.values.mode;
            if(newMode !== 0){
                mode = newMode;
            }
            const levelChangeUserMode = Vue.prototype.$LevelChangeUserMode;
            const matchingLevel = levelChangeUserMode.find(level => level.key === mode);
            if(matchingLevel){
                this.modeName = matchingLevel.name;
            }
        },

        async fetchData() {
            let payload = {
                id: this.$route.params.id,
            }
            await this.$store.dispatch('users/getUserChangeRoutineMode', payload);
            
            if(this.groupChangeRoutineMode){
                this.modeInfo = { values: {
                    mode: this.groupChangeRoutineMode.mode,
                }};

                if(this.modeInfo.values.mode == Vue.prototype.$LevelChangeUserMode[2].key){
                    this.modeInfo.values = {
                        ...this.modeInfo.values,   
                        pct_profit_sessions: this.groupChangeRoutineMode.pct_profit_sessions,
                        pct_profit_swaps: this.groupChangeRoutineMode.pct_profit_swaps,
                        num_days: this.groupChangeRoutineMode.num_days,
                    }
                }
                this.getNameMode(this.modeInfo.values.mode);
            }else{
                this.modeInfo = { noData: true }
            }
            this.loadData = true;
		},

        updateMode(payload){
            this.loadData = false;
            this.modeInfo.values = payload;
            this.getNameMode(payload.mode);
            this.loadData = true;
        },

        toggleDialog(dialog) {
            this.dialogues[dialog] = ! this.dialogues[dialog];
        },
    }
}