import Vue from 'vue';
import { mapGetters } from 'vuex';
import CardStepper from '@/components/CardStepper/CardStepper.vue';
import CardStepperNotes from '@/components/CardStepper/CardStepperNotes/CardStepperNotes.vue';
import CardStepperResume from '@/components/CardStepper/CardStepperResume/CardStepperResume.vue';
import NicknameDropdown from '@/components/Rail/NicknameDropdown/NicknameDropdown.vue';
import UserDropdown from '@/components/Rail/UserDropdown/UserDropdown.vue';
import RowTransfer from '@/components/Rail/RowTransfer/RowTransfer.vue';
import InputFinancial from '@/components/InputFinancial/InputFinancial.vue';
import AlertError from '@/components/Alerts/AlertError/AlertError.vue';
import DatePicker from '@/components/DatePicker/DatePicker.vue';
import RailMixin from '@/mixins/Rail.vue';
import AuthMixin from '@/mixins/Auth.vue';
import UserMixin from '@/mixins/User.vue';
import DialogUserNotes from '@/components/Rail/Dialog/DialogUserNotes/DialogUserNotes.vue';
import AlertUserNote from '@/components/Rail/AlertUserNote/AlertUserNote.vue';

export default {
    name: 'CardTransferToPlayer',

    components: {
        CardStepper,
        CardStepperNotes,
        CardStepperResume,
        NicknameDropdown,
        RowTransfer,
        InputFinancial,
        UserDropdown,
        AlertError,
        DatePicker,
        AlertUserNote,
        DialogUserNotes,
    },

    mixins: [
        RailMixin,
        AuthMixin,
        UserMixin,
      ],

    computed: {
        ...mapGetters({
            users: 'users/usersAllActive',
            loading: 'users/loading',
            error: 'railTransfers/error',
            humanError: 'railTransfers/humanError',
            nicknamesError: 'nicknames/error',
            nicknamesHumanError: 'nicknames/humanError',
            walletsAll: 'railWallets/walletsAll',
            nicknamesByUserID: 'nicknames/nicknamesByUserID',
            userTrackerId: 'auth/userTrackerId',
            selectedUser: 'users/selectedUser',
            userNotes: 'users/userNotes',
            userTargetNotes: 'users/userTargetNotes',
            teamFilter: 'teams/teamFilter',
            sourceUserRole: 'users/sourceUserRole',
            targetUserRole: 'users/targetUserRole',
            selectedRequest: 'railRequests/selectedRequest',
            selectedTransfer: 'railTransfers/selectedTransfer'
        }),

        // Validator : Used in NicknameDropdown in form.user field
        isUserManagementComputed: {
			get: function () {
				return this.isUserManagement;
			},
			set: function (newValue) {
				this.isUserManagement = newValue;
			}
        },
        
        // Validator : Used in NicknameDropdown in form.targetUser field
        isTargetUserManagementComputed: {
            get: function () {
                return this.isTargetUserManagement;
            },
            set: function (newValue) {
                this.isTargetUserManagement = newValue;
            }
        },
    },

    watch: {
        /**
        * if user has changed , call getRoleBySelectedUserId() and clean NicknameDropdown sourceWallet
        * @param {Object} newVal (user)
        */
        'form.user': function (newVal) {
            if (newVal) {
                // if user exists, get his roles by user Id
                if (this.form.user.id) {
                    this.getRoleBySelectedUserId(this.form.user.id, 'source');
                    this.fetchUserNotes(this.form.user.id, 'userNotes');
                };
                // clear fields
                this.form.sourceWallet = null;
                this.form.amount.value = null;
            };
        },

        /**
        * if targetUser has changed , call getRoleBySelectedUserId() and clean NicknameDropdown targetWallet
        * @param {Object} newVal (targetUser)
        */
        'form.targetUser': function (newVal) {
            if (newVal) {
                // if targetUser exists, get his roles by target user Id
                if (this.form.targetUser.id) {
                    this.getRoleBySelectedUserId(this.form.targetUser.id, 'target');
                    this.fetchUserNotes(this.form.targetUser.id, 'userTargetNotes');
                };
                // clear fields
                this.form.targetWallet = null;
                this.form.amount.value = null;
            };
        }
    },

    created() {
        // Pre fill user with logged in userId (will always be management)
        this.form.user = {id: parseInt(this.userTrackerId)};
    },

    data() {
        return this.initialState();
    },

    methods: {
        initialState() {
            return {
                stateToPopulate: 'userNotes',
                notes: [],
                isUserManagement: false,
                isTargetUserManagement: false,
                isOperationBankrollIncrement: true,
                form: {
                    date: this.$moment().format('YYYY-MM-DD'),
                    user: null,
                    targetUser: null,
                    amount: {
                        value: null,
                    },
                    sourceWallet: null,
                    targetWallet: null,
                    observation: null,
                    gyazoLink: null,
                    fileName: [],
                    isTransferPending: true,
                },
                rules: {
                    user: [
                        (v) => !!v || 'Por favor introduza um jogador'
                    ],
                    amount: [
                        (v) => !!v || "Por favor introduza o montante",
                        (v) => !Number.isNaN(v) || 'Insira um valor numérico',
                    ],
                    nicknameDropdown: [
                        (v) => !!v || 'Por favor introduza uma Sala/Carteira'
                    ],
                },
                cardTitles: [
                    'Selecionar jogadores',
                    'Registar transferência',
                    'Finalizar'
                ],
                formValidations: [
                    false,
                    false,
                    true
                ],
                dialogues: {
                    userNotes: false,
                },
            }
        },

        /**
        * Call API to retrieve full user roles
        * Validates if user has rail admin/manager role and then populates computed property based on userField parameter
        * @param {Integer} userId 
        * @param {String} userField Expects: ('source', 'target')
        */
        async getRoleBySelectedUserId(userId, userField) {
            let output = false;
            let result = false;
            let payload = {
                id: userId,
                state: userField
            }

            // retrieve userRoles
            result = await this.$store.dispatch('users/getUserRoles', payload);
            
            if (result === true) {
                if (userField == 'source') {
                    this.isUserManagementComputed = this.userHasRole(['rail_manager', 'rail_admin'], this.sourceUserRole);
                    if (!this.isUserManagementComputed) {
                        await this.$store.dispatch('users/getUser', userId);
                    }
                };
                if (userField == 'target') {
                    this.isTargetUserManagementComputed = this.userHasRole(['rail_manager', 'rail_admin'], this.targetUserRole);
                    if (!this.isTargetUserManagementComputed) {
                        await this.$store.dispatch('users/getUser', userId);
                    }
                };
            };
            return output;
        },

        /**
         * nicknamesByUserID - sourceUser
         * walletsAll - targetUser
         * @returns an Array[Int] of ids from networks that target user doens't have
         */
        filterNetworks() {
            // array with network ID's to be excluded from NicknameDropdown
            let difference = [];
            let isWallet = ! this._.isNull(this.form.sourceWallet) ? this.form.sourceWallet.network.isWallet : true;

            if (!this._.isEmpty(this.walletsAll) && !isWallet) {
                this.walletsAll.forEach(element => {
                    let diff = element.network.isWallet;

                    if (!diff) {
                        difference.push(element.network.id);
                    }
                })
            }
            return difference;
        },

        /**
         * Validates a specific form step.
         * Assumes form steps are named 'formStep_x' where x is a Int 0 - maxStep-1.
         * 
         * @param {Int} step Identifier of the form step to validate.
         * @returns {Void}
         */
        validateStep(step) {
            const formStepRef = 'formStep_' + (step);
            if (this.$refs[formStepRef]) {

                // Reset validation for step  2 to enforce lazy-validation
                this.resetValidationFormStep2();

                // Update formValidation[step] in a way the DOM will be reactive
                Vue.set(
                    this.formValidations,
                    step - 1,
                    this.$refs[formStepRef].validate()
                );
            
                // Clean errors if exists
                if (this.error) {
                    this.$store.dispatch('railTransfers/clearError');
                }
            }
        },

        resetValidationFormStep2() {
            if (this.$refs.formStep_2 && this._.isNull(this.form.amount.value)) {
                this.$refs.formAmountRef.resetValidation();
            }
        },

        /**
         * retrieve fields from CardStepperNotes component
         * @param {Object} payload 
         */
        getInfoFromCardStepperNotes(payload) {
            if (payload) {
                this.form.gyazoLink = payload.gyazoLink;
                this.form.fileName = payload.filename;
            }
        },

        // @returns {Array} array[objects] that contains transfer info. Used in RowTransfer Component
        getTransfers() {
            return [
                {
                    source: this.form.sourceWallet,
                    target: this.form.targetWallet,
                    amount: this.form.amount,
                    isPending: this.form.isTransferPending
                }
            ];
        },

        async submit() {
            // Validate form
            if (!this.$refs.formTransferToPlayer.validate()) {
                return false;
            }

            // Toggle submit button
            this.$refs.CardStepper.toggleSubmitting();
            
            let source = this.isOperationBankrollIncrement ? 'transfers[0][source]' : 'source';
            let target = this.isOperationBankrollIncrement ? 'transfers[0][target]' : 'target';
            let amount = this.isOperationBankrollIncrement ? 'transfers[0][amount]' : 'amount';
            let user = this.isOperationBankrollIncrement ? this.form.targetUser.id : this.form.user.id;
            /**
             * Formulate payload formData
             */
            let payload = new FormData();
            payload.append('date', this.form.date);
            payload.append('user', user);
            // transfer
            payload.append(source, this.form.sourceWallet.id);
            payload.append(target, this.form.targetWallet.id);
            payload.append(amount, this.form.amount.value);

            if (!this.isOperationBankrollIncrement) {
                payload.append('targetUser', this.form.targetUser.id);
            }

            // Add 'observation' field if necessary
            if (this.form.observation) {
                payload.append('observation[description]', this.form.observation);
            }

            if (!this._.isEmpty(this.form.fileName)) {
                payload.append('screenshot', this.form.fileName[0]);
            }
        
            if (this.form.gyazoLink) {
                payload.append('observation[url]', this.form.gyazoLink);
            }

            let actionToDispatch = this.isOperationBankrollIncrement
                ? 'railRequests/bankrollIncrementCreate'
                : 'railTransfers/create';

            // Dispatch to store
            let result = await this.$store.dispatch(actionToDispatch, payload);

            // Re-enable btn
            if (this.$refs.CardStepper) {
                this.$refs.CardStepper.toggleSubmitting();
            }

            // On success
            if (result === true) {

                let payloadRefresh = {
                    params: {}
                }

                // if team exists and isManagement, Add team parameter to payload.params
                if (this.teamFilter && this.userHasRailAccessDivisionDropdown) {
                    payloadRefresh.params.team = this.teamFilter;
                }

                // Refresh Requests Transfers and Balances
                this.updateRequestsTransfersAndBalances(payloadRefresh);

                this.updateStatisticsByUserId(user);

                let operationMessage = this.isOperationBankrollIncrement
                    ? 'Incremento de banca registado com sucesso.'
                    : 'Transferência registada com sucesso.'
                
                // Show success snackbar
                this.$store.dispatch('UI/showSnackbar', {
                    message: operationMessage,
                    color: 'success'
                });

                // Change hasFinished state in order to change component
                this.$store.dispatch('TabSlider/setHasFinished', true);

                let requestID = this.selectedRequest ? this.selectedRequest.id : null;
                let transferRequestID = this.selectedTransfer ? this.selectedTransfer.id : null;

                let id = this.isOperationBankrollIncrement
                    ? requestID
                    : transferRequestID;
                
                let url = Vue.prototype.$tab_slider.tabs.requests + id;

                // change to operation single view
                this.$router.push({ path: url });
            }
        },

        resetForm() {
            Object.assign(this.$data, this.initialState());
            this.$refs.formTransferToPlayer.reset();
        },

        /**
         * Sets stateToPopulate based on state parameter
         * Sets notes based on state parameter
         * @param {String} state 'userNotes' || 'userTargetNotes'
         */
        openDialogUserNotes(state = 'userNotes') {
            this.stateToPopulate = state;
            this.notes = state == 'userNotes'
                ? this.userNotes
                : this.userTargetNotes;
            
            this.dialogues.userNotes = true;
        },

        /**
         * Receives two parameters and fetch notes from a player
         * @param {Number|String} id 
         * @param {String} state 
         */
        fetchUserNotes(id, state) {

            let payload = {
                id: id,
                populateUserNotes: state == 'userNotes',
            }
            
            if (id) {
                // get user notes
                this.$store.dispatch('users/getUserNotes',payload);
            }
        }
    }
}