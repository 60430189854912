import { mapGetters } from 'vuex';
import AlertError from '@/components/Alerts/AlertError/AlertError.vue';

export default {
    name: 'DialogEditUserNotes',

    components: {
        AlertError,
    },

    props: {
        isOpen: {
            type: Boolean,
            required: true,
            default: false
        },
        stateToPopulate: { type: String, required: true, default: 'userNotes' }, // VALID VALUES ['userNotes', 'userTargetNotes]
    },

    data() {
        return this.initialState();
    },

    computed: {
        ...mapGetters({
            error: 'users/error',
            humanError: 'users/humanError',
            selectedUserNote: 'users/selectedUserNote',
            selectedUser: 'users/selectedUser'
        }),

        isSubmitDisable: function () {
            let output = true;
            if (this.form.content) {
                let handleInput = this.form.content.trim();
    
                output = handleInput.length == 0;
            }
            return output;
        },
    },

    watch: {
        // when re opened,  populate field
        isOpen: function (newVal, oldVal) {
            if (newVal === true) {
                this.populateFields();
            }
        },
        selectedUserNote: function (newVal, oldVal){
            if (newVal != oldVal) {
                this.populateFields();
            }
        }
    },
    
    methods: {
        initialState() {
            return {
                submitting: false,
                form: {
                    content: '',
                    sticky: null,
                },
                rules: {
                    name: [ 
                        v => !!v || 'Por favor introduza uma nota',
                    ],
                },
            };
        },

        populateFields() {
            if (this.selectedUserNote) {
                this.form.content = this.selectedUserNote.content;
                this.form.sticky = this.selectedUserNote.sticky;
            }
        },

        async submit() {
            if (!this.$refs.formEditUserNotes.validate()) {
                return false;
            }

            // Toggle submit button
            this.toggleSubmitting();

            // Formulate payload
            let payload = {
                userID: this.selectedUser.id,
                noteID: this.selectedUserNote.id,
                stateToPopulate: this.stateToPopulate,
                notes: {
                    content: this.form.content,
                    sticky: this.form.sticky
                }                    
            }

            let result = await this.$store.dispatch('users/updateUserNotes', payload);

            // // Re-enable button
            this.toggleSubmitting();

            // On success
            if (result === true) {
                // Show success snackbar
                this.$store.dispatch('UI/showSnackbar', {
                    message: 'Nota do jogador atualizada com sucesso.',
                    color: 'success'
                });

                // Refresh userNotes based on stateToPopulate prop
                let payloadRefresh = {
                    id: this.selectedUser.id,
                    populateUserNotes: this.stateToPopulate == 'userNotes'
                }
                
                this.$store.dispatch('users/getUserNotes', payloadRefresh);

                // closes dialog
                this.closeDialog();
            }
        },

        /**
         * Used to disable Submit button
         */
        toggleSubmitting() {
            this.submitting = !this.submitting;
        },

        closeDialog() {
            this.$emit("close-dialog");
            this.resetForm();
        },

        resetForm() {
            Object.assign(this.$data, this.initialState());
            this.$store.dispatch('users/clearErrors');
        }
    },
};
