import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth';
import DataMixin from '@/mixins/Data.vue';
import Hero from '@/components/Hero/Hero.vue';
import CardStatistics from '@/components/Rail/Statistics/CardStatistics/CardStatistics.vue';
import LineChartCumulativePNL from '@/components/Charts/LineChartCumulativePNL/LineChartCumulativePNL.vue';
import DonutChartProfitTeam from '@/components/Charts/DonutChartProfitTeam/DonutChartProfitTeam.vue';
import ColumnChart from '@/components/Charts/ColumnChart/ColumnChart.vue';
import TableGamesPlayed from '@/components/Tables/TableGamesPlayed/TableGamesPlayed.vue';
import TableNicknames from '@/components/Tables/TableNicknames/TableNicknames.vue';
import TableProfitPlayer from '@/components/Tables/TableProfitPlayer/TableProfitPlayer.vue';

export default {
    name: 'Networks-Single',

    components: {
        Hero,
        CardStatistics,
        LineChartCumulativePNL,
        ColumnChart,
        TableGamesPlayed,
        TableNicknames,
        TableProfitPlayer,
        DonutChartProfitTeam,
    },

    mixins: [
        AuthMixin,
        DataMixin,
    ],

    data() {
        return this.initialState();
    },

    async created() {
        // if isn't admin, redirect to homepage
        await this.allowOnlyRoles(['rail_admin', 'rail_manager', 'tracker_admin', 'tracker_manager']);

        this.fetchData();
    },

    computed: {
        ...mapGetters({
            heroDatePickerValue: 'UI/heroDatePickerValue',
            selectedNetwork: 'networks/selectedNetwork',
            nicknamesTotal: 'nicknames/nicknamesTotal',
            hasLoaded: 'nicknames/hasLoaded',

            networkGameEntriesTotal: 'games/totalGames',
            networkGameEntriesTotalLoading: 'games/loading',
            networkBalance: 'networks/networkBalance',
            networkBalanceLoading: 'networks/networkBalanceLoading',
            networkPnlGraph: 'networks/networkPnlGraph',
            networkPnlGraphLoading: 'networks/networkPnlGraphLoading',

            networkStatistics: 'networks/networkStatistics',
            networkStatisticsLoading: 'networks/networkStatisticsLoading',

            teamFilter: 'teams/teamFilter',
        }),

        cardInfo() {
            let currency = this.networkBalance && Object.keys(this.networkBalance.currencies).toString();
            return [
                {
                    name: 'Nicknames registados',
                    value: this.nicknamesTotal ? this.nicknamesTotal : 0,
                    precision: 0,
                    icon: 'mdi-account-box-multiple',
                    showCurrency: false,
                    showAltCurrency: false,
                    loading: ! this.hasLoaded
                },
                {
                    name: 'Jogos realizados',
                    value: this.networkGameEntriesTotal ? this.networkGameEntriesTotal : 0,
                    precision: 0,
                    icon: 'mdi-cards-playing-outline',
                    showCurrency: false,
                    showAltCurrency: false,
                    loading: this.networkGameEntriesTotalLoading
                },
                {
                    name: 'Balanço atual',
                    value: this.networkBalance && this.networkBalance.value[currency] ? this.networkBalance.value[currency].value : 0,
                    precision: 2,
                    icon: 'mdi-currency-' + (!this._.isNull(currency) ? currency.toLowerCase() : ''),
                    showCurrency: false,
                    showAltCurrency: false,
                    loading: this.networkBalanceLoading
                },
            ];
        },

        /**
         * It uses Object.assign in order to Vue detect changes and rerender 
         * @returns an Object filled with parameters (series and labels) that will be used in LineChartCumulativePNL component
         */
        networkPnlComputed: {
            get: function () {
                return {
                    series: this.network_pnl.series,
                    labels: this.network_pnl.labels
                }
            },
            set: function (newValue) {
                if (newValue) {
                    this.network_pnl.series = newValue.series;
                    this.network_pnl.labels = newValue.labels;
                }
                // network pnl
                Object.assign(this.network_pnl.series, newValue ? newValue.series : []);
                Object.assign(this.network_pnl.labels, newValue ? newValue.labels : []);
            },
        },

        networkStatisticsComputed() {
            // Games played by weekday
            Object.assign(this.network_statistics.games_played_by_weekday.series, this.networkStatistics ? this.networkStatistics.GAMES_PLAYED_BY_WEEKDAY.series : []);
            Object.assign(this.network_statistics.games_played_by_weekday.labels, this.networkStatistics ? this.networkStatistics.GAMES_PLAYED_BY_WEEKDAY.labels : []);

            // Pnl by weekday
            Object.assign(this.network_statistics.pnl_by_weekday.series, this.networkStatistics ? this.networkStatistics.PNL_BY_WEEKDAY.series : []);
            Object.assign(this.network_statistics.pnl_by_weekday.labels, this.networkStatistics ? this.networkStatistics.PNL_BY_WEEKDAY.labels : []);

            // Pnl by level
            Object.assign(this.network_statistics.pnl_by_level.series, this.networkStatistics ? this.networkStatistics.PNL_BY_LEVEL.series : []);
            Object.assign(this.network_statistics.pnl_by_level.labels, this.networkStatistics ? this.networkStatistics.PNL_BY_LEVEL.labels : []);

            // Pnl by team
            Object.assign(this.network_statistics.pnl_by_team.series, this.networkStatistics ? this.networkStatistics.PNL_BY_TEAM.series : []);
            Object.assign(this.network_statistics.pnl_by_team.labels, this.networkStatistics ? this.networkStatistics.PNL_BY_TEAM.labels : []);

            return {
                games_played_by_weekday: {
                    series: this.network_statistics.games_played_by_weekday.series,
                    labels: this.handleStatisticLabelsByWeekDay(this.network_statistics.games_played_by_weekday.labels),
                },
                pnl_by_weekday: {
                    series: this.network_statistics.pnl_by_weekday.series,
                    labels: this.handleStatisticLabelsByWeekDay(this.network_statistics.pnl_by_weekday.labels),
                },
                pnl_by_level: {
                    series: this.network_statistics.pnl_by_level.series,
                    labels: this.network_statistics.pnl_by_level.labels,
                },
                pnl_by_team: {
                    series: this.network_statistics.pnl_by_team.series,
                    labels: this.network_statistics.pnl_by_team.labels,
                }
            }
        },
    },

    watch: {
        heroDatePickerValue: function () {
            if (!this.fetchLoading) {
                this.fetchStatistics();
            }
        },
        selectedNetwork: function () {
            this.getTableTabs();
        },
        teamFilter: function () {
            if (!this.fetchLoading) {
                this.fetchStatistics();
            }
        },
        networkPnlGraph: {
            handler() {
                this.networkPnlComputed = this.networkPnlGraph;
            },
            deep: true,
            immediate: true,
        }
    },

    methods: {
        initialState() {
            return {
                date: null,
                tab: null,
                fetchLoading: false,
                network_pnl: {
                    series: [],
                    labels: [],
                },
                network_statistics: {
                    games_played_by_weekday: {
                        series: [],
                        labels: [],
                    },
                    pnl_by_weekday: {
                        series: [],
                        labels: [],
                    },
                    pnl_by_level: {
                        series: [],
                        labels: [],
                    },
                    pnl_by_team: {
                        series: [],
                        labels: [],
                    }
                },
                items: ['Geral'],
            };
        },

        fetchData() {
            let payload = {
                id: this.$route.params.id,
            }
            this.$store.dispatch('networks/getNetwork', payload);
        },

        getTableTabs() {
            if (this.selectedNetwork && this.selectedNetwork.isWallet) {
                this.items = ['Geral'];
            } else {
                this.items = ['Geral', 'Stats'];
            }
        },

        async fetchStatistics() {

            this.fetchLoading = true;

            let payload = {
                id: this.$route.params.id,
                params: {}
            };

            if (!this._.isEmpty(this.heroDatePickerValue)) {
                payload.params.dateBegin = this.heroDatePickerValue[0];
                payload.params.dateEnd = this.heroDatePickerValue[1];
            }

            // if team exists and isManagement, Add team parameter to payload.params
            if (this.teamFilter && this.userHasRailAccessDivisionDropdown) {
                payload.params.team = this.teamFilter;
            }

            // refresh Pnl
            this.$store.dispatch('networks/getNetworkPnlGraph', payload);

            // refresh Balance
            this.$store.dispatch('networks/getNetworkBalance', payload);  // necessário Hugo validar se está a usar o parametro team

            // refresh network statistics
            await this.$store.dispatch('networks/getNetworkStatistics', payload); 

            this.fetchLoading = false;
        },
    },
}