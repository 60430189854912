import Vue from 'vue';
import { mapGetters } from 'vuex';
import TableTransfers from '@/components/Rail/TableTransfers/TableTransfers.vue';
import CardStatistics from '@/components/Rail/Statistics/CardStatistics/CardStatistics.vue';
import UserDropdown from '@/components/Rail/UserDropdown/UserDropdown.vue';
import OperationDropdown from '@/components/Rail/OperationDropdown/OperationDropdown.vue';
import TransferTypeDropdown from '@/components/Rail/TransferTypeDropdown/TransferTypeDropdown.vue';
import DatePickerRange from '@/components/DatePickerRange/DatePickerRange.vue';
import AuthMixin from '@/mixins/Auth.vue';

export default {
    name: 'Transfers',

    components: {
        TableTransfers,
        CardStatistics,
        UserDropdown,
        TransferTypeDropdown,
        OperationDropdown,
        DatePickerRange,
    },

    mixins: [
        AuthMixin,
    ],

    data() {
       return this.initialState()
    },

    created() {
        this.fetchData();
    },
    
    computed: {
        ...mapGetters({
            statisticsAdmin: 'railStatistics/dataAdmin',
            statisticsLoading: 'railStatistics/loadingDataAdmin',
            heroDatePickerValue: 'UI/heroDatePickerValue',
            teamFilter: 'teams/teamFilter',
        }),

        cardInfo() { 
            return [
                {
                    name: 'Entradas de capital',
                    value: this.statisticsAdmin['CAPITAL_INFLOW'] ? this.statisticsAdmin['CAPITAL_INFLOW'].value.EUR.value : 0,
                    icon: 'mdi-cash-plus',
                    showCurrency: true,
                    showAltCurrency: false,
                },
                {
                    name: 'Saídas de capital',
                    value: this.statisticsAdmin['CAPITAL_OUTFLOW'] ? this.statisticsAdmin['CAPITAL_OUTFLOW'].value.EUR.value : 0,
                    icon: 'mdi-cash-minus',
                    showCurrency: true,
                    showAltCurrency: false,
                },
                {
                    name: 'Banca Gestão',
                    value: this.statisticsAdmin['BANKROLL_MANAGEMENT'] ? this.statisticsAdmin['BANKROLL_MANAGEMENT'].value.EUR.value : 0,
                    icon: 'mdi-bank',
                    showCurrency: true,
                    showAltCurrency: false,
                },
                {
                    name: 'Banca Jogadores',
                    value: this.statisticsAdmin['BANKROLL_USER'] ? this.statisticsAdmin['BANKROLL_USER'].value.EUR.value : 0,
                    icon: 'mdi-bank',
                    showCurrency: true,
                    showAltCurrency: false,
                },
            ];
        },

        excludedOperations: function () {
            return [
                Vue.prototype.$rail_ops.operations.swap,
            ];
        },

        computedCalendarDates: {
			get() {
				return this.date;
			},
			set(newValue) {
                this.date = newValue;
				this.$store.commit('UI/setHeroDatePickerValue', newValue);
			}
        },

        // computed property passed to TableTransfers
        formFilters: {
            get() {
                return this.form;
            },
            set(newVal) {
                this.form = newVal;
            }
        },
    },

    methods: {
        initialState() {
            return {
                date: [],
                form: {
                    user: null,
                    operation: [],
                    transferType: [],
                }
            };
        },

        async fetchData() {
            await this.fetchStatistics();
        },
    
        async fetchStatistics() {
            let payload = null;
            if (! this._.isEmpty(this.heroDatePickerValue)) {
                payload = {
                    dateBegin: this.heroDatePickerValue[0],
                    dateEnd: this.heroDatePickerValue[1],
                    admin: true,
                };
            }

            if (this.teamFilter) {
                payload.team = this.teamFilter;
            }
            
            await this.$store.dispatch('railStatistics/getAdmin', payload);
        },
    },
}