export default {
	name: 'DialogAccessFeedback',

    props: {
        isOpen: { type: Boolean, required: true, default: false },
		title: { type: String, required: false, default: 'Confirmação' },
		subtitle: { type: String, required: false, default: 'Confirmação' },
        body: { type: String, required: false, default: 'Tem a certeza que quer prosseguir com esta ação?' },
        width: { type: Number, required: false, default: 600 },
        buttonName: { type: String, required: true, default: 'Submeter' },
        buttonColor: { type: String, required: false, default: 'success' },
        submitting: { type: Boolean, required: false, default: false },
		showCancelButton: { type: Boolean, required: false, default: true },
		showSubmitButton: { type: Boolean, required: false, default: true },
		error: { type: String, required: false, default: '' },
		humanError: { type: String, required: false, default: '' },
	},

  	data() {
    	return this.initialState();
	},
	
	methods: {
		initialState() {
			return {};
		},

		submit() {
            this.$emit('submit');
		},

		closeDialog() {
			this.$emit('close-dialog');
		},

		toggleSubmitting() {
            this.submitting = ! this.submitting;
        }
    },
};