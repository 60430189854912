import Vue from 'vue';
import { mapGetters } from 'vuex';
import DataMixin from '@/mixins/Data.vue';
import RailMixin from '@/mixins/Rail.vue';
import NicknameDropdown from '@/components/Rail/NicknameDropdown/NicknameDropdown.vue';
import CardStepper from '@/components/CardStepper/CardStepper.vue';
import CardStepperNotes from '@/components/CardStepper/CardStepperNotes/CardStepperNotes.vue';
import InputFinancial from '@/components/InputFinancial/InputFinancial.vue';
import ExpenseTypeDropdown from '@/components/Rail/ExpenseTypeDropdown/ExpenseTypeDropdown.vue';
import AlertError from '@/components/Alerts/AlertError/AlertError.vue';
import DialogCreateNickname from '@/components/Dialog/DialogCreateNickname/DialogCreateNickname.vue';

export default {
  name: 'CardMovement',

  components: {
    NicknameDropdown,
    CardStepper,
    CardStepperNotes,
    InputFinancial,
    ExpenseTypeDropdown,
    AlertError,
    DialogCreateNickname,
  },

  mixins: [
    DataMixin,
    RailMixin,
  ],

  computed: {
    ...mapGetters({
      selectedBalance: 'railOverview/selectedBalance',
      card: 'TabSlider/card',
      error: 'railRequests/error',
      humanError: 'railRequests/humanError',
      errorNickname: 'nicknames/error',
      humanErrorNickname: 'nicknames/humanError',
      isExpenseStore: 'railExpenses/isExpense',
      expenseTypes: 'railExpenses/expenseTypes',
      heroDatePickerValue: 'UI/heroDatePickerValue',
      teamFilter: 'teams/teamFilter',
      userTrackerId: 'auth/userTrackerId',
      nicknamesExistsItemsAvailableAfterFilter: 'nicknames/existsItemsAvailableAfterFilter',
      hasLoadedNicknamesByUserID: 'nicknames/hasLoadedNicknamesByUserID',
      selectedRequest: 'railRequests/selectedRequest',
    }),

    selectedWallet: {
      get() {
        return this.selectedBalance;
      },

      set(newValue) {
        this.$store.commit(
          'railOverview/setSelectedBalance',
          newValue
        );
      }
    },

    areExpenseTypesAvailable: function() {
      return this.expenseTypes.length > 0;
    },

    excludeNicknamesWithNoBalance: function () {
      let output = false;
      if (this.card == Vue.prototype.$rail_ops.operations.withdraw) {
        output = true;
      }
      return output;
    }
  },

  watch: {
    selectedBalance() {
      // prefill selected wallet
      if (this.selectedBalance) {
        this.form.wallet = this.selectedBalance;
      }
    },
    // update card title based on selected card
    card: function () {
      this.setCardTitle();
      this.setExpenseCheckbox();
    },

    /**
     * Necessary for the API payload construction
     */
    selectedWallet: function () {
      this.form.wallet = this.selectedWallet;
    },

    isExpenseStore: function () {
      // pre selects expense checkbox as true 
      this.setExpenseCheckbox();
    },
  },

  data() {
    return this.initialState();
  },

  created() {
    this.initialize();
  },

  methods: {
    initialState() {
      return {
        form: {
          wallet: null,
          balance: null,
          observation: null,
          gyazoLink: null,
          fileName: [],
        },
        formValidations: [
          false,
        ],
        cardTitles: [],
        rules: {
          wallet: [(v) => !!v || 'Por favor introduza uma carteira',],
          balance: [
            (v) => !!v || 'Por favor introduza o montante',
            (v) => !Number.isNaN(v) || 'Insira um valor numérico',
          ],
        },
        isExpense: false,
        expenseType: null,
        dialogs: {
          createNickname: false,
        }
      }
    },

    initialize() {
      // Define the card title
      this.setCardTitle();

      // Get ExpenseTypes
      this.fetchData();

      // pre selects expense checkbox as true 
      this.setExpenseCheckbox();
    },

    /**
     * Runs on created and everytime card has changed
     * @returns cardTitles array used in CardStepper as prop
     */
    setCardTitle() {
      //clean array
      this.cardTitles = [];

      if (this.isExpenseStore) {
        return this.cardTitles.push('Registar Despesa');
      }
      
      if (this.card == Vue.prototype.$rail_ops.operations.deposit) {
        return this.cardTitles.push('Depósito');
      }
      if (this.card == Vue.prototype.$rail_ops.operations.withdraw) {
        return this.cardTitles.push('Levantamento');
      }
    },

    setExpenseCheckbox() {
      if (this.card == Vue.prototype.$rail_ops.operations.withdraw) {
        this.isExpense = this.isExpenseStore;
      }
    },

    /**
     * Validates a specific form step.
     * Assumes form steps are named 'formStep_x' where x is a Int 0 - maxStep-1.
     * 
     * @param {Int} step Identifier of the form step to validate.
     * @returns {Void}
     */
    validateStep(step) {
      const formStepRef = 'formStep_' + (step);

      if (this.$refs[formStepRef]) {

        this.resetFinancialInputValidation();

        // Update formValidation[step] in a way the DOM will be reactive
        Vue.set(
          this.formValidations,
          step - 1,
          this.$refs[formStepRef].validate()
        );
      }
    },

    resetFinancialInputValidation() {
      this.$refs.formBalanceRef.resetValidation();
    },

    fetchData() {
      // Get ExpenseTypes if Withdrawal
      if (this.card == Vue.prototype.$rail_ops.operations.withdraw) {
        this.$store.dispatch('railExpenses/getTypes');
      }
    },

    setActiveItem(item) {
      this.form.wallet = item;

      if (this.form.wallet) {
        this.$store.commit(
          'railOverview/setSelectedBalance',
          this.form.wallet
        );
      }
    },

    setBalance() {
      this.$store.commit(
        'railOverview/setCardBalance',
        this.form.balance
      );
    },

    async submit() {
      /**
       * Validate
       */
      if (!this.$refs.formRailMovement.validate()) {
        return false;
      }

      // Toggle submit button
      this.$refs.CardStepper.toggleSubmitting();


      /**
        *  Preparing payload
      */
      
      // Base payload
      let payload = {
        body: {}
      }

     // payload.body formData
      payload.body = new FormData();
      payload.body.append('amount', this.form.balance);
      payload.body.append('nickname', this.form.wallet.id);

      // Add 'expenseType' field if it exists
      if (this.isExpense && this.expenseType ) {
        payload.body.append('expenseType', this.expenseType);
      }

      // Add 'observation' field if necessary
      if (this.form.observation) {
        payload.body.append('observation[description]', this.form.observation);
      }

      // Add 'screenshot' field if necessary
      if (!this._.isEmpty(this.form.fileName)) {
        payload.body.append('screenshot', this.form.fileName[0]);
      }

      // Add 'observation[url]' field if necessary
      if (this.form.gyazoLink) {
        payload.body.append('observation[url]', this.form.gyazoLink);
      }

      // If this.card is an $rail_ops.operations.deposit, we do a deposit, else, we do a withdraw
      const actionToDispatch = this.card == Vue.prototype.$rail_ops.operations.deposit
        ? 'railRequests/deposit'
        : 'railRequests/withdraw';

      // Dispatch store action
      let result = await this.$store.dispatch(actionToDispatch, payload);

      // Re-enable btn
      if (this.$refs.CardStepper) {
        this.$refs.CardStepper.toggleSubmitting();
      }

      // On success
      if (result === true) {

        let payload = {
          params: {}
        }

        // if team exists and isManagement, Add team parameter to payload.params
        if (this.teamFilter && this.userHasRailAccessDivisionDropdown) {
          payload.params.team = this.teamFilter;
        }

        // clear current payload user in order to be able to refresh nicknames by userId again
        // Refresh NicknameDropdown in CardMovement view
        this.$store.commit('nicknames/setCurrentPayloadUserId', null);
        
        // Refresh Requests Transfers and Balances
        this.updateRequestsTransfersAndBalances(payload);

        let payloadRefresh = {
          params: {}
        }

        // Add date params
        if (!this._.isEmpty(this.heroDatePickerValue)) {
          payloadRefresh.params.dateBegin = this.heroDatePickerValue[0];
          payloadRefresh.params.dateEnd = this.heroDatePickerValue[1];
        }
        // Refresh expenses
        this.$store.dispatch('railExpenses/getExpenses', payloadRefresh);

        // Show success snackbar
        const snackbarActionWord = this.card == Vue.prototype.$rail_ops.operations.deposit
          ? 'Depósito'
          : 'Levantamento';
        
        let id = this.selectedRequest.id;
        let url = Vue.prototype.$tab_slider.tabs.requests + id;

        this.$store.dispatch('UI/showSnackbar', {
          message: snackbarActionWord + ' finalizado com sucesso.',
          color: 'success'
        });
        // Change hasFinished state in order to change component
        this.$store.commit('TabSlider/setHasFinished', true);

        // Refresh rail data
        this.updateRequestsTransfersAndBalances();

        // change to operation single view
        this.$router.push({ path: url });

        // Reset form
        this.resetForm();
      }
    },

    resetForm() {
      // clean selected Balance
      this.$store.commit(
        'railOverview/setSelectedBalance',
        null
      );

      Object.assign(this.$data, this.initialState());
      //this.$refs.formRailMovement.reset();
    },

    getInfoFromCardStepperNotes(payload) {
      if (payload) {
        this.form.gyazoLink = payload.gyazoLink;
        this.form.fileName = payload.filename;
      }
    },

    toggleDialog(name) {
      this.dialogs[name] = !this.dialogs[name];
    },
  }
}
