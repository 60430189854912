import Vue from 'vue';
import { mapGetters } from 'vuex';
import RailMixin from '@/mixins/Rail.vue';
import AuthMixin from '@/mixins/Auth.vue';
import CardStepper from '@/components/CardStepper/CardStepper.vue';
import CardStepperNotes from '@/components/CardStepper/CardStepperNotes/CardStepperNotes.vue';
import NicknameDropdown from '@/components/Rail/NicknameDropdown/NicknameDropdown.vue';
import RowTransfer from '@/components/Rail/RowTransfer/RowTransfer.vue';
import InputFinancial from '@/components/InputFinancial/InputFinancial.vue';
import UserDropdown from '@/components/Rail/UserDropdown/UserDropdown.vue';
import AlertError from '@/components/Alerts/AlertError/AlertError.vue';
import Alert from '@/components/Alerts/Alert/Alert.vue';
import AlertUserNote from '@/components/Rail/AlertUserNote/AlertUserNote.vue';
import DialogUserNotes from '@/components/Rail/Dialog/DialogUserNotes/DialogUserNotes.vue';

export default {
  name: 'CardBankrollReduction',

  components: {
    CardStepper,
    CardStepperNotes,
    NicknameDropdown,
    RowTransfer,
    InputFinancial,
    UserDropdown,
    AlertError,
    Alert,
    AlertUserNote,
    DialogUserNotes
  },

  mixins: [
    RailMixin,
    AuthMixin,
  ],

  created() {
    return this.fetchData();
  },

  computed: {
    ...mapGetters({
      users: 'users/usersAllActive',
      loading: 'users/loading',
      nicknamesAllMine: 'nicknames/nicknamesAllMine',
      walletsManagement: 'railWallets/walletsManagement',
      walletsAll: 'railWallets/walletsAll',
      error: 'railRequests/error',
      humanError: 'railRequests/humanError',
      selectedRequest: 'railRequests/selectedRequest',
      userNotes: 'users/userNotes',
      teamFilter: 'teams/teamFilter',
      selectedRequest: 'railRequests/selectedRequest',
    }),
  },
  watch: {
    'form.user': function (e) {
      if (e) {
        this.fetchUserWallets(e);
        this.fetchUser(e);
        this.fetchUserNotes(e);
      }
    }
  },

  data() {
    return this.initialState();
  },

  methods: {
    initialState() {
      return {
        adminHand: false,
        isNicknameAWallet: false,
        dialogues: {
          userNotes: false,
        },
        form: {
          wallets: [],
          user: null,
          nickname: null,
          observation: null,
          walletSource: null,
          walletTarget: null,
          balance: {
            value: null,
          },
          gyazoLink: null,
          fileName: [],
          isTransferPending: true,
          walletOrNickname: null,
        },
        rules: {
          user: [
            (v) => !!v || 'Por favor introduza um jogador'
          ],
          nicknameDropdown: [
            (v) => !!v || 'Por favor introduza uma Sala/Carteira'
          ],
          wallet: [
            (v) => !!v || 'Por favor introduza uma carteira'
          ],
          balance: [
            (v) => !!v || 'Por favor introduza o montante',
            (v) => ! Number.isNaN(v) || 'Insira um valor numérico',
          ]
        },
        cardTitles: [
          'Selecionar jogador e network',
          'Resumo'
        ],
        formValidations: [
          false,
          true,
        ],
      }
    },

    async fetchData() {
      // get users list
      if (this._.isEmpty(this.users)) {
        let payload = this.formulateUsersAllUrlParameters();
        this.$store.dispatch('users/getUsersAllActive', payload);
      }
    },

    /**
   * Validates a specific form step.
   * Assumes form steps are named 'formStep_x' where x is a Int 0 - maxStep-1.
   * 
   * @param {Int} step Identifier of the form step to validate.
   * @returns {Void}
   */
    validateStep(step) {
      const formStepRef = 'formStep_' + (step);
      if (this.$refs[formStepRef]) {

      // reset validation form.balance after choosing an walletOrNickname 
      this.resetFinancialInputValidation();

      // Update formValidation[step] in a way the DOM will be reactive
      Vue.set(
        this.formValidations,
        step - 1,
        this.$refs[formStepRef].validate()
      );
      // clean errors if exists
      if (this.error) {
        this.$store.dispatch('railRequests/clearError');
      }
      }
    },

    resetFinancialInputValidation() {
      if (this.$refs.formBalanceRef) {
        this.$refs.formBalanceRef.resetValidation();
      } 
    },

    // retrieve observation fields from CardStepperNotes component
    getInfoFromCardStepperNotes(payload) {
      if (payload) {
        this.form.gyazoLink = payload.gyazoLink;
        this.form.fileName = payload.filename;
      }
    },

    /**
     * Populates isNicknameAWallet based on network.isWallet
     * @param {*Object} item 
     */
    setFirstWalletType(item) {
      if (item) {
        this.isNicknameAWallet = item.network.isWallet;
      }
    },

    /**
     * @param {*Object} user 
     */
     fetchUserWallets(user) {
      if (user) {
        // get wallets from user id
        this.$store.dispatch('railWallets/getWalletsAll', user.id);
      }
    },
     
    /**
     * @param {*Object} user 
     */
    fetchUser(user) {
      if (user) {
        // get user info (populate selectedUser - used in TableBalances)
        this.$store.dispatch('users/getUser', user.id);
       }
    },

    fetchUserNotes(user) {
      if (user) {
        let payload = {
          id: user.id,
          populateUserNotes: true,
        }
        // get user notes
        this.$store.dispatch('users/getUserNotes', payload);
      }
    },

    /**
     * @returns array passed as prop to RowTransfers Component
     */
     getTransfers() {
       let transfers = [];

       let isNicknameNetworkBackoffice = this.form.walletOrNickname && Vue.prototype.$rail_networks_with_Backoffice.includes(this.form.walletOrNickname.network.id);

       this.isNicknameAWallet = this.form.walletOrNickname.network.isWallet;

       // if both inputs have same network ID it means only 1 transfer will be created
       if (this.form.walletOrNickname.network.id == this.form.walletTarget.network.id) {
          transfers.push({
            source: this.form.walletOrNickname,
            target: this.form.walletTarget,
            amount: this.form.balance,
          })

          return transfers;
       }

       if (!this.isNicknameAWallet) {
         this.form.nicknameSource = this.form.walletOrNickname;
       } else {
         this.form.walletSource = this.form.walletOrNickname;
       }

      // 1st transfer object - (nickname/network to wallet)
      if (!this.isNicknameAWallet) {
        // if isn't network backoffice
        if (!isNicknameNetworkBackoffice) {
          transfers.push({
            source: this.form.nicknameSource,
            target: this.form.walletSource,
            amount: this.form.balance,
            isPending: this.form.isTransferPending,
          });
        }
       };
       
      // 2nd transfer object - (source user wallet to admin wallet)
      transfers.push({
        source: isNicknameNetworkBackoffice ? this.form.nicknameSource : this.form.walletSource,
        target: this.form.walletTarget,
        amount: this.form.balance,
        isPending: this.form.isTransferPending,
      });
       
      return transfers;
    },
    
     /**
      * Used in NicknameDropdown, (Only shows wallets and network of same type)
      * @returns {Array} of ids
      */
    filterNetworks() {
      let difference = []

      if (this.form.walletOrNickname && this.form.walletOrNickname.network.id) {
        if (!this._.isEmpty(this.nicknamesAllMine)) {
          this.nicknamesAllMine.forEach(el => {
            let diff = (el.network.id == this.form.walletOrNickname.network.id) || el.network.isWallet;
            if (!diff) {
              difference.push(el.network.id);
            }
          })
        }
      }
      return difference;
    },
    
    /**
     * Used in NicknameDropdown
     * @param {Object} 
     * @returns {Object}
     */
    setSourceDisplayWallet(item) {
      if (item && !this._.isEmpty(this.walletsAll)) {
        this.form.walletSource = this.walletsAll.find(element => element.network.id == item.network.id);
        return this.form.walletSource;
      }
    },

    // retrieve fields populated by admin to create a Bankroll Reduction request !
    async submit() {
      // Validate form
      if (!this.$refs.formBankrollReductionChild.validate()) {
        return false;
      }

      // Toggle submit button
      this.$refs.CardStepper.toggleSubmitting();

      /**
       * Formulate payload
       */

      let payload = new FormData();

      payload.append('user', this.form.user.id);
      // transfer - (source user to admin wallet)
      payload.append('transfers[0][source]', this.form.walletOrNickname.id);
      payload.append('transfers[0][target]', this.form.walletTarget.id);
      payload.append('transfers[0][amount]', this.form.balance.value);

      payload.append('adminHand', this.adminHand ? 1 : 0 );

      // Add 'observation' field if necessary
      if (this.form.observation) {
        payload.append('observation[description]', this.form.observation);
      }

      // Add 'screenshot' field if necessary
      if (!this._.isEmpty(this.form.fileName)) {
        payload.append('screenshot', this.form.fileName[0]);
      }

      // Add 'observation[url]' field if necessary
      if (this.form.gyazoLink) {
        payload.append('observation[url]', this.form.gyazoLink);
      }

      // Dispatch to store
      let result = await this.$store.dispatch('railRequests/bankrollReductionCreate', payload);

      // Re-enable btn
      if (this.$refs.CardStepper) {
        this.$refs.CardStepper.toggleSubmitting();
      }

      // On success
      if (result === true) {

        let payloadRefresh = {
          params: {}
        }

        // if team exists and isManagement, Add team parameter to payload.params
        if (this.teamFilter && this.userHasRailAccessDivisionDropdown) {
          payloadRefresh.params.team = this.teamFilter;
        }

        // Refresh Requests Transfers and Balances
        this.updateRequestsTransfersAndBalances(payloadRefresh);

        this.updateStatisticsByUserId(this.form.user.id);

        // Show success snackbar
        this.$store.dispatch('UI/showSnackbar', {
          message: 'Pedido de redução de banca criado com sucesso.',
          color: 'success'
        });

        let id = this.selectedRequest.id;
        let url = Vue.prototype.$tab_slider.tabs.requests + id;

        // change hasFinished state in order to change component
        this.$store.dispatch('TabSlider/setHasFinished', true);

        // change to operation single view
        this.$router.push({ path: url });
      }
    },

    openDialogUserNotes() {
      this.dialogues.userNotes = true;
    },
  }
}