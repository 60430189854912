import { mapGetters } from 'vuex';
import DataMixin from '@/mixins/Data';
import VideoReportsPreview from '../dialogues/VideoReports-Preview/VideoReports-Preview.vue';
import VideoReportsEvaluate from '../dialogues/VideoReports-Evaluate/VideoReports-Evaluate.vue';
import AlertNoData from '@/components/AlertNoData';
import EmptyStateNoData from '@/components/EmptyStateNoData';

export default {
    name: 'table-video-reports',
    mixins: [DataMixin],
    components: {
        'video-reports-preview': VideoReportsPreview,
        'video-reports-evaluate': VideoReportsEvaluate,
        AlertNoData,
        EmptyStateNoData,
    },
    data() {
        return this.initialState();
    },
    async created() {
        await this.initialize();
    },
    computed: {
        ...mapGetters({
            users: 'users_v1/users',
            videoReports: 'videoReports/videoReports',
            selectedReport: 'videoReports/selectedVideoReport',
            hasLoaded: 'videoReports/hasLoaded',
        }),
        computedHeaders() {
            // the trick here is to hide "evaluation" column when TAB is "Por Avaliar" by adding a class "d-none"
            return this.dataTable.headers.filter((filterHeader) => {
                switch (this.tabs.current) {
                    case 0:
                        if (filterHeader.value == "evaluation") {
                        filterHeader['align'] = ''
                        }
                        return filterHeader
                    case 1:
                        if (filterHeader.value == "evaluation") {
                        filterHeader['align'] = ' d-none'
                        }
                        return filterHeader
                    case 2:
                        if (filterHeader.value == "evaluation") {
                        filterHeader['align'] = ''
                        }
                        return filterHeader
                }
            })
        }
    },
    methods: {
        async initialize() {
            // Clear VideoRequests (Prevents bug in VideoReports-Preview)
            this.$store.commit(
                'videoRequests/setRequestedVideos',
                []
            );
            
            // Fetch VideoReports
            await this.$store.dispatch('videoReports/getVideoReports');
            // Fetch Users
            await this.$store.dispatch('users_v1/getUsers');
        },

        initialState() {
            return {
                dataTable: {
                    headers: [
                        { text: '', value: 'selected', sortable: false },
                        { text: 'Data', value: 'createdAt_video_reports' },
                        { text: '', value: 'name_users', sortable: false},
                        { text: 'Jogador', value: 'display_name_users' },
                        { text: 'Vídeo', value: 'video_requests.wp_post_title_video_requests' },
                        { text: 'Relatório', value: 'report', sortable: false },
                        { text: 'Avaliação', value: 'evaluation', align: ''},
                        { text: 'Ações', value: 'action', sortable: false },
                    ],
                    search: null,
                    itemsPerPage: 10,
                    footerProps: {
                        'items-per-page-options': this.$itemsPerPageOptions,
                    },

                },
                tabs: {
                    current: 0,
                    headers: ['Todos', 'Por avaliar', 'Avaliado'],
                },
                dialogues: {
                    reportPreview: false,
                    reportEvaluate: false,
                },
            };
        },
        setActiveItem(item) {
            if (item) {
                this.$store.commit(
                    'videoReports/setSelectedVideoReports',
                    item
                );
            }
        },
        filter() {
            if (!this.videoReports) return [];

            /**
            * Transform requestedVideos items object and add 
            * 'display_name_users' attribute to each video request item
            */
            this.videoReports.map(item => {
                if (item.video_requests) {
                    item.display_name_users = this.getUserDisplayNameById(item.video_requests.ref_users_video_requests);
                    item.name_users = this.getUsernameById(item.video_requests.ref_users_video_requests);
                }
                return item;
            });

            switch (this.tabs.current) {
                case 0:
                    return this.videoReports;
                case 1:
                    return this.videoReports.filter(
                        (e) => e.status_video_reports == 0
                    );
                default:
                    return this.videoReports.filter(
                        (e) => e.status_video_reports == 1
                    );
            }
        },
        getUserDisplayNameById(id) {
            if (this.users) {
                const user = this.users.filter((e) => e.id_users == id);
                return user[0]['display_name_users'];
            }
            return null;
        },
        getUsernameById(id) {
            if (this.users) {
                const user = this.users.filter((e) => e.id_users == id);
                return user[0]['name_users'];
            }
            return null;
        },

        dialogReportPreview(item) {
            if (item) {
                this.$store.commit(
                    'videoReports/setSelectedVideoReports',
                    item
                );

                this.$refs.dialogReportPreview.populate(item);

                this.openDialog('reportPreview');
            }
        },
        dialogReportEvaluate(item) {
            if (item) {
                this.$store.commit(
                    'videoReports/setSelectedVideoReports',
                    item
                );

                this.openDialog('reportEvaluate');
            }
        },
        openDialog(d) {
            this.dialogues[d] = true;
        },
        closeDialog() {
            this.dialogues.reportPreview = false;
            this.dialogues.reportEvaluate = false;
        },

        /**
         * Values for this.tabs.current must be [0,1].
         * 
         * @param {String} prop Information we want.
         * @return {String} Information to fill prop-
         */
        getEmptyState(prop) {
            // Each arr position represents a tab
            const info = [
                // Tab 'To evaluate'
                {
                    icon: 'mdi-file-check-outline',
                    title: 'Todos os relatórios foram avaliados',
                    classColor: 'success white--text',
                    classIcon: 'white',
                },
                // Tab 'Evaluated'
                {
                    icon: 'mdi-file-remove-outline',
                    title: 'Sem relatórios avaliados',
                },
            ];

            return info[this.tabs.current][prop];
        },

        getEvaluationColor(item) {
            if (item.evaluation_video_reports < 10) return 'red'
            else return 'green'
        }
    },
};
