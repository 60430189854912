import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth';
import DataMixin from '@/mixins/Data';

import VideoRequestsAdmin from './admin/VideoRequests-Admin.vue';
import VideoRequestsUser from './user/VideoRequests-User.vue';

export default {
    name: 'video-requests',

    mixins: [AuthMixin, DataMixin],

    components: {
      VideoRequestsAdmin,
      VideoRequestsUser
    },

    computed: {
      ...mapGetters({
          userType: 'auth/userType',
          userRoles: 'auth/userRoles',
          userRolesLoading: 'auth/userRolesLoading',
        }),
  
      componentToShow() {  
        if (this.userHasRole(['rail_admin', 'rail_manager', 'tracker_admin', 'tracker_manager', 'team_cash_manager', 'video_manager'])) {
          return 'VideoRequestsAdmin';
        } else {
          return 'VideoRequestsUser';
        }

        return component;
      }
    },

    methods: {
    }
}