import { mapGetters } from 'vuex';
import AlertError from '@/components/Alerts/AlertError/AlertError.vue';
import GroupDropdown from '@/components/Rail/GroupDropdown/GroupDropdown.vue';
import DatePicker from '@/components/DatePicker/DatePicker.vue';
import AuthMixin from '@/mixins/Auth.vue';
import RailMixin from '@/mixins/Rail.vue';

export default {
    name: 'PlayersDialogEdit',

    components: {
        AlertError,
        DatePicker,
        GroupDropdown
    },

    mixins: [
        AuthMixin,
        RailMixin,
    ],

    props: {
        dialogOpen: { type: Boolean, required: true, default: false },
    },
    
    data() {
        return this.initialState();
    },

    computed: {
        ...mapGetters({
            selectedUser: 'users/selectedUser',
            users: 'users/users',
            error: 'users/error',
            humanError: 'users/humanError',
            groups: 'groups/groups',
            countries: 'countries/countries',
            qualifications: 'qualifications/qualifications',
            userMeta: 'users/userMeta',
            errorUserMeta: 'users/errorUserMeta',
            humanErrorUserMeta: 'users/humanErrorUserMeta',
        }),

        showUserID() {
            return this.selectedUser ? 'ID ' + this.selectedUser.id : null;
        },

        headers() {
             return ['Perfil', 'Informação Adicional'];
        },
    },

    watch: {
        // whenever user has changed, triggers populateFields()
        selectedUser: function() {
            if (this.selectedUser) {
                this.populateFields();
            }
        },
        // whenever dialogOpen has been opened, triggers populateFields()
        dialogOpen: function() {
            if (this.dialogOpen) {
                this.clearErrors();
                this.initialize();
                this.populateFields();
            }
        },
        error: function () {
            this.tabs.current = 0;
        },
        errorUserMeta: function () {
            this.tabs.current = 0;
        },
    },

    created() {
      this.initialize();
    },

    methods: {
        initialState() {
            return {
                formValidation: false,
                submitting: false,
                tabs: {
                    headers: [ 'Perfil', 'Informação Adicional', 'Redes Sociais'],
                    current: 0
                },
                formFields: {
                    name: null,
                    id: null,
                    wpID: null,
                    nickname: '',
                    status: null,
                    group: null,
                    email: null,
                    phone: null,
                    birthdate: null,
                    nationality: null,
                    qualification: null,
                    coach: null,
                    profitShare: null,
                    discord: null,
                    skype: null,
                    facebook: null,
                    instagram: null,
                    twitter: null,
                    youtube: null,
                    twitch: null,
                    passaport: null,
                    cc: null,
                    address: null,
                    nif: null,
                    cpf: null,
                    postalCode: null,
                    city: null,
                },
                formRules: {
                    name: [ 
                        v => !!v || 'Por favor introduza um nome',
                    ],
                    profitShare: [
                        v => !this._.isNull(v) && this._.inRange(v, 0, 101) || 'Por favor introduza um número entre 0 e 100',
                    ],
                    email: [v => !!v || 'Por favor introduza um email válido',],
                    status: [v => !!v || 'Por favor introduza um estado'],
                    group: [v => !!v || 'Por favor introduza um grupo'],
                    address: [v => !!v || 'Por favor introduza uma morada'],
                    nationality: [ 
                        v => !!v || 'Por favor introduza uma nacionalidade',
                    ],
                    postalCode: [
                        v => {
                          const format1Regex = /^\d{4}-\d{3}$/; // Format 1234-567
                          const format2Regex = /^\d{5}(-\d{3})?$/; // Format 12345 or 12345-000
                
                          if (!v) return 'Por favor introduza um código postal até 9 caracteres'; // Validation for empty input
                
                          if (!format1Regex.test(v) && !format2Regex.test(v)) {
                            return 'Formato inválido ! (Ex: 1234-567 or 12345 or 12345-000)';
                          }
                
                          // Validation passed
                          return true;
                        }
                      ],
                }
            }
        },

        initialize() {
            // only dispatch API Calls if dialog was indeed open
            if (!this.dialogOpen) {
                return false;
            }

            if (this._.isEmpty(this.users)) {
                let payload = this.formulateUsersAllUrlParameters();
                this.$store.dispatch('users/get', payload)
            }
            if (this._.isEmpty(this.groups)) {
                this.$store.dispatch('groups/get');
            }
            if (this._.isEmpty(this.countries)) {
                this.$store.dispatch('countries/get');
            }
            if (this._.isEmpty(this.qualifications)) {
                this.$store.dispatch('qualifications/get');
            }
        },

        populateFields() {
            this.formFields.name = this.selectedUser.name;
            this.formFields.nickname = this.selectedUser.nickname;
            this.formFields.status = this.selectedUser.status;
            this.formFields.group = this.selectedUser.group ? this.selectedUser.group : null;
            this.formFields.coach = this.selectedUser.coach ? this.selectedUser.coach.id : null;
            this.formFields.email = this.selectedUser.email;
            this.formFields.phone = this.selectedUser.phone;
            this.formFields.qualification = this.selectedUser.qualification ? this.selectedUser.qualification.id : null;
            this.formFields.birthdate = this.selectedUser.birthdate ? this.$moment(this.selectedUser.birthdate.date).format('YYYY-MM-DD') : null;
            this.formFields.profitShare = this.selectedUser ? this.selectedUser.profitShare * 100 : null;

            if (this.userMeta) {
                this.formFields.nationality = this.userMeta.nationality ? this.userMeta.nationality.id : null;
                this.formFields.cc = this.userMeta.CC;
                this.formFields.nif = this.userMeta.NIF;
                this.formFields.cpf = this.userMeta.CPF;
                this.formFields.address = this.userMeta.address;
                this.formFields.passport = this.userMeta.passport;
                this.formFields.postalCode = this.userMeta.postalCode;
                this.formFields.city = this.userMeta.city;
            }

            // populate social fields
            this.populateSocialNetworksFields();
        },

        populateSocialNetworksFields() {
          if ( ! this._.isEmpty(this.selectedUser.socialNetworks)) {
            this.formFields.discord = this.selectedUser.socialNetworks.discord,          
            this.formFields.skype = this.selectedUser.socialNetworks.skype,
            this.formFields.facebook =  this.selectedUser.socialNetworks.facebook,
            this.formFields.instagram = this.selectedUser.socialNetworks.instagram,
            this.formFields.twitter =  this.selectedUser.socialNetworks.twitter,
            this.formFields.youtube =  this.selectedUser.socialNetworks.youtube,
            this.formFields.twitch = this.selectedUser.socialNetworks.twitch
          }
        },

        async save() {
            // Form validation
            if (!this.$refs.formEditPlayerTab_1.validate()) {
                this.tabs.current = 0;
                return false;
            }
            if (!this.$refs.formEditPlayerTab_2.validate()) {
                this.tabs.current = 1;
                return false;
            }

            // Disable save btn
            this.toggleSubmitting();

            let payload = {
                id: this.selectedUser.id,
                name: this.formFields.name,
                nickname: this.formFields.nickname,
                status: this.formFields.status,
                email: this.formFields.email,
                socialNetworks: {},
                contractNumSessions: this.selectedUser.contractNumSessions,
                contractNumEvents: this.selectedUser.contractNumEvents,
                oblNumEvents: this.selectedUser.oblNumEvents,
                oblNumSessions: this.selectedUser.oblNumSessions,
            }

            if (this._.isNull(this.formFields.nickname))
                payload.nickname = '';

            payload.socialNetworks.discord = this.formFields.discord ? this.formFields.discord : '';
            payload.socialNetworks.facebook = this.formFields.facebook ? this.formFields.facebook : '';
            payload.socialNetworks.skype = this.formFields.skype ? this.formFields.skype : '';
            payload.socialNetworks.twitter = this.formFields.twitter ? this.formFields.twitter : '';
            payload.socialNetworks.twitch = this.formFields.twitch ? this.formFields.twitch : '';
            payload.socialNetworks.instagram = this.formFields.instagram ? this.formFields.instagram : '';
            payload.socialNetworks.youtube = this.formFields.youtube ? this.formFields.youtube : '';

            if (this.formFields.phone) {
                if (this.formFields.phone.includes('+')) {
                    payload.phone = this.formFields.phone.split(" ").join("");
                } else {
                    payload.phone = '+351' + this.formFields.phone.split(" ").join("");
                }
            }

            if (this.formFields.birthdate)
                payload.birthdate = this.$moment(this.formFields.birthdate).format('YYYY-MM-DD');

            if (this.formFields.coach)
                payload.coach = this.formFields.coach;

            if (this.formFields.group)
                payload.group = this.formFields.group.id;

            if (this.formFields.qualification)
                payload.qualification = this.formFields.qualification;

            // if payload.socialNetworks object is empty, then we delete it
            if (this._.isEmpty(payload.socialNetworks)) {
                delete payload.socialNetworks;
            }

            if (!this._.isNull(this.formFields.profitShare)) {
                // turn profitShare into a decimal value and then convert into a String
                let profitShareString = ((this.formFields.profitShare / 100).toFixed(2)).toString();
                payload.profitShare = profitShareString;
            }

            let payloadMeta = {
                id: this.selectedUser.id,
            }

            payloadMeta.address = this.formFields.address ? this.formFields.address : ' ';
            payloadMeta.cc = this.formFields.cc ? this.formFields.cc : null;
            payloadMeta.passport = this.formFields.passport ? this.formFields.passport : null;
            payloadMeta.nif = this.formFields.nif ? this.formFields.nif : null;
            payloadMeta.cpf = this.formFields.cpf ? this.formFields.cpf : null;
            payloadMeta.postalCode =  this.formFields.postalCode ? this.formFields.postalCode : ' ';
            payloadMeta.city =  this.formFields.city ? this.formFields.city : ' ';
            payloadMeta.nationality = this.formFields.nationality;

            // Dispatch action
            let result = await this.$store.dispatch('users/updateUser', payload);
            let resultMeta;
            if (result === true) {
                resultMeta = await this.$store.dispatch('users/updateUserMeta', payloadMeta);
            }

            // Re-enable btn
            this.toggleSubmitting();

            if (result === true && resultMeta === true) {
                this.closeDialog();

                // Show snackbar and close dialog
                this.$store.dispatch('UI/showSnackbar', {
                    message: 'Jogador atualizado com sucesso.',
                    color: 'success'
                });

                // if update is sucessfully completed , it emits reload event to update tableUser
                this.$emit('reload');
            }
        },

        toggleSubmitting() {
			this.submitting = ! this.submitting;
		},

        closeDialog() {
            this.$emit('close-dialog');
            this.resetDialog();
            this.clearErrors();
        },

        toggleDialog(dialog) {
            this.dialogues[dialog] = ! this.dialogues[dialog];
        },

        resetDialog() {
            // Return all fields to initial state
            Object.assign(this.$data, this.initialState());

            // Reset forms
            this.$refs.formEditPlayer.reset();
        },

        clearErrors() {
			this.$store.dispatch('users/clearErrors');
		}
    }
}