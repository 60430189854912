import AuthMixin from '@/mixins/Auth';
import DataMixin from '@/mixins/Data';
import { mapGetters } from 'vuex';

export default {
    name: 'ApplicationsStatistics',

    components: {  },

    mixins: [AuthMixin, DataMixin],

    computed: {
        ...mapGetters({
            statistics: 'applications/statistics'
        }),
    },

    data () {
      return {
      }
    },

    async created () {
      await this.adminOnly()
      this.initialize()
    },

    methods: {
      async initialize() {
        await this.$store.dispatch('applications/getStatistics');
      },
    }
}