import { mapGetters } from 'vuex';
import Hero from '@/components/Hero/Hero.vue';
import ColumnChart from '@/components/Charts/ColumnChart/ColumnChart.vue';
import LoadingSpinner from '@/components/LoadingSpinner/LoadingSpinner.vue';
import EmptyStateNoData from '@/components/EmptyStateNoData';

export default {
    name: 'ModelsSingle',

    components: {
        Hero,
        ColumnChart,
        LoadingSpinner,
        EmptyStateNoData,
    },

    computed: {
        ...mapGetters({
            model: 'schedulingModels/data',
            loading: 'schedulingModels/loading',
        }),

        currentModel() {
            if(this._.isArray(this.model.calculationFactors) && !this._.isEmpty(this.model.calculationFactors)){
                for(let factor of this.model.calculationFactors) {
                    factor.fullName = this.getFactorName(factor);
                }
            }
            return this.model;
        }
    },

    data() {
        return this.initialState();
    },

    created() {
        this.getSchedulingModel(this.$route.params.id);
    },

    methods: {
        initialState() {
            return {
                headersFactors: [
                    { text: 'Fator', value: 'fullName', sortable: true },
                    { text: 'Peso', value: 'weight', sortable: true },
                    { text: 'Peso Normalizado', value: 'normalizedWeight', sortable: true },
                    { text: 'Relação', value: 'relationship.id', sortable: false },
                ]
            };
        },

        getFactorName(factor) {
            let fullName = factor.factor1.name;
            if(factor.factor2){
                fullName = fullName.concat(' - ');
                fullName = fullName.concat(factor.factor2.name);
            }
            return fullName;
        },

        getArrowIcon(relationship) {
            if (relationship == 0) {
                return 'mdi-arrow-up';
            } else if (relationship == 1) {
                return 'mdi-arrow-down';
            }
        },

        goToGameGroups() {
            this.$router.push({
                path: '/scheduling/models/' + this.model.id + '/gamegroups'
              });
        },

        async getSchedulingModel(id) {
            let payload = {
                id: id, 
                updateLoading : true
            }
            await this.$store.dispatch('schedulingModels/getById', payload);
            if (!this.model) {
                this.$router.replace({ path: '/scheduling/models/' });
            }
        },
    }
}
