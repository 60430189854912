import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth.vue';
import DataMixin from '@/mixins/Data.vue';
import CalendarFilter from '@/components/Calendar/CalendarFilter/CalendarFilter.vue';
import ButtonCalendarSync from '@/components/Calendar/ButtonCalendarSync/ButtonCalendarSync.vue';
import ButtonCalendarRSVP from '@/components/Calendar/ButtonCalendarRSVP/ButtonCalendarRSVP.vue';
import DrawerCreateEditEvent from '@/components/Calendar/DrawerCreateEditEvent/DrawerCreateEditEvent.vue';
import DrawerCreateEditCategory from '@/components/Calendar/DrawerCreateEditCategory/DrawerCreateEditCategory.vue';
import DialogConfirmation from '@/components/Rail/Dialog/DialogConfirmation/DialogConfirmation.vue';
import ProgressBar from '@/components/Calendar/ProgressBar/ProgressBar.vue';
import CalendarList from '@/components/Calendar/CalendarList/CalendarList.vue';
import moment from 'moment';
import _ from 'lodash';

export default {
	name: 'CalendarMatrix',

	components: {
		CalendarFilter,
		ButtonCalendarSync,
		DrawerCreateEditEvent,
		ButtonCalendarRSVP,
		DrawerCreateEditCategory,
		DialogConfirmation,
		ProgressBar,
		CalendarList,
	},

	mixins: [
		AuthMixin,
		DataMixin,
	],

	computed: {
        ...mapGetters({
			events: 'eventsCalendar/data',
			selectedEvent: 'eventsCalendar/selectedEvent',
			loading: 'eventsCalendar/loading',
            eventsCategories: 'eventsCategoriesCalendar/data',
			selectedCategory: 'eventsCategoriesCalendar/selected',
			loadingCategories: 'eventsCategoriesCalendar/loading',
            eventsFormsCalendar: 'eventsFormsCalendar/data',
			eventsError: 'eventsCalendar/error',
            eventsHumanError: 'eventsCalendar/humanError',
			userTrackerId: 'auth/userTrackerId',
        }),

		cardWidth() {
			switch (this.$vuetify.breakpoint.name) {
				case 'lg': return 400
				case 'xl': return 400
			}
		},

		/**
		 * Filters 'eventsHandled' according to 'selectedCategories'
		 * 
		 * @returns {Array}
		 */
		eventsFiltered() {
			let output = [];

			// Show only events from the selected categories
			output = this.eventsHandled.filter(e => this.selectedCategories.includes(e.category));

			// Show only events that the user is enrolled in
			if (this.showMyEventsOnly) {
				output = output.filter(e => e.user_is_enrolled == true || (! _.isNull(e.organizer) && e.organizer.id_users == this.userTrackerId));
			}

			return output;
		},

		collapseCategories() {
			if(this.$vuetify.breakpoint.mdAndDown) {
				this.setupMobile();
			} else {
				this.setupDesktop();
			}
		},

		// TODO: Duplicated in CardCalendarEventInfo
		eventDurationString() {
			let duration = null;
			let output = null;

			// Case hours
            duration = moment(Number(this.selectedEvent.datetime_end))
                           .diff(moment(Number(this.selectedEvent.datetime_start)), 'hours');

			output = duration + ' hora';
			// Pluralize
			if (duration > 1) {
				output += 's';
			}

			// Case minutes
			if (duration == 0) {
				duration = moment(Number(this.selectedEvent.datetime_end))
                           .diff(moment(Number(this.selectedEvent.datetime_start)), 'minutes');

				output = duration + ' minutos';
			}

			// Output
            return output;
        },

		eventCapacityString() {
			if (_.isNull(this.selectedEvent.capacity)) {
				return 'Ilimitada';
			}

			const slotsSingularPlural = this.selectedEvent.capacity == 1
										? ' lugar'
										: ' lugares.'

			return this.selectedEvent.capacity + slotsSingularPlural;
		},

		/**
		 * List is not a valid type for v-calendar, so we need to return a valid type if we want show the list view
		 * @returns {string} type of calendar
		 */
		computedCalendarType() {
			this.setupListView();
			if (this.type == 'list') {
				return 'month'
			}

			return this.type;
		},

		/**
		 * Selected month of v-calendar 
		 * @returns {string} of selected v-calendar month (e.g. 03)
		 */
		selectedMonth() {
			// when this.focus (v-model) of v-calendar is empty we want to return the current month
			if (_.isEmpty(this.focus))
				return moment().format('MM');

			return moment(this.focus).format('MM');
		}
	},

	created() {
		this.init()
	},

	data() {
		return this.initialState();
	},
	
	methods: {
		initialState() {
			return {
				focus: '',
				today: new Date().toISOString().slice(0, 10),
				type: 'month',
				types: ['month', 'week', 'day', '4day'],
				selectedCategories: [],
				eventsHandled: [],
				listView: false,
				menuShowEventOpen: false,
				showMyEventsOnly: false,
				selectedElement: null,
				drawerCreateEditEvent: {
					isOpen: false,
					action: 'create',
					startDate: null,
					startTime: null,
				},
				drawerCreateEditCategory: {
					isOpen: false,
					action: 'create',
				},
				categoriesResponsive: {
					show: true,
					mobileActions: false,
					desktopActions: true,
				},
				dialogs: {
					confirmation: {
						open: false,
						submitting: false,
						title: null,
						body: null,
						type: null,
					},
				},
				dragEvent: null,
				dragStart: null,
				mousedown_start: null,
				mousedown_end: null,
				extendingBottom: false,

			}
		},

		async init() {
			await this.fetchCategories();
			await this.fetchEvents();
			this.setupCategories();
		},

		async fetchEvents(payload = null) {
			await this.$store.dispatch('eventsCalendar/get', payload);
		},

		async fetchCategories() {
			await this.$store.dispatch('eventsCategoriesCalendar/get');
		},

		/**
		 * Resets the calendar focus to the current date
		 * 
		 * @returns {Void}
		 */
		setToday() {
			this.focus = '';
		}, 
		
		setupCategories() {
			if(this.$route.query.category) {
				this.selectedCategories = [Number(this.$route.query.category)];
			} else {
				this.selectAllCategories();
			}
		},

		selectAllCategories() {
			this.selectedCategories = this._.map(this.eventsCategories, 'id');
		},

		clearAllCategories() {
			this.selectedCategories = this.initialState().selectedCategories;
		},

		/**
		 * Show menu with event info 
		 * 
		 * @param {Object} event selected v-calendar event
		 */
		showEventMenu({ nativeEvent, event}) {
				const open = () => {
					this.$store.commit('eventsCalendar/setSelectedEvent', this.events.find(e => e.id == event.id));
					this.selectedElement = nativeEvent.target;
					requestAnimationFrame(() => requestAnimationFrame(() => this.menuShowEventOpen = true));
				}
	
				if (this.menuShowEventOpen) {
					this.menuShowEventOpen = false;
					requestAnimationFrame(() => requestAnimationFrame(() => open()));
				} else {
					open();
				}
		  
				nativeEvent.stopPropagation();
		},
		
		getSelectedEventUrl() {
			return `/calendar/event/${this.selectedEvent.id}`;
		},

		/**
		 * Update selected event after edit
		 * runs after $emit from DrawerCreateEditEvent
		 * 
		 * @returns {void}
		 */
		async updateSelectedEvent() {
			await this.fetchAndHandleEvents();
			this.$store.commit('eventsCalendar/setSelectedEvent', this.events.find(e => e.id == this.selectedEvent.id));
		},

		/**
		 * Update drag event after drop
		 * 
		 * @param {Integer} id of the event 
		 * @param {Date} start datetime_start of dragEvent
		 * @param {Date} end datetime_end of dragEvent
		 * @returns {void}
		 */
		async updateDragEvent(id, start, end) {
			const payload = {
				body: {
					eventID: id,
					datetime_start: this.dateToEpoch(start),
					datetime_end: this.dateToEpoch(end),
				}
			}
			
			await this.$store.dispatch('eventsCalendar/updateEvent', payload);
			this.updateSelectedEvent();
		},

		getEventColor(eventCategory) {
			return eventCategory.color;
		},

		/**
		 * Open DrawerCreateEditEvent & Setup props
		 * 
		 * @param {string} action setup action create|edit of drawer
		 * @returns {Void}
		 */
		openDrawerCreateEditEvent(action) {
			// Just open if we are not dragging some event
			if (!this.dragStart) {
				this.drawerCreateEditEvent.isOpen = true;
				this.drawerCreateEditEvent.action = action;
				
				// close dialog of selected Event 
				this.menuShowEventOpen = false;
			}
		},
		
		closeDrawerCreateEditEvent() {
			Object.assign(this.$data.drawerCreateEditEvent, this.initialState().drawerCreateEditEvent);			
		},
		
		/**
		 * Open Drawer to create or edit category
		 * 
		 * @param { string } action The action that defines if we want create or edit
		 * @returns {Void}
		 */
		openDrawerCreateEditCategory(action, category_id = null) {
			if (action === 'edit') {
				// set selected category
				this.setSelectedCategory(category_id);
			}

			this.drawerCreateEditCategory.action = action;
			this.drawerCreateEditCategory.isOpen = true;
		},
	
		async fetchAndHandleEvents(payload = null) {

			// Convert v-calendar dates to epoch and remove 7 days from first day & add 7 days from last day
			if (payload) {
				if (payload.start && payload.end) {
					payload = _.merge(payload, this.setupDatesToPayload(payload.start.date, payload.end.date));
				}
			}
		
			// Merge the payload built by us with the param sent by <v-calendar> on the 'change' event
			if(this.userIsCalendarManager) {
				payload = _.merge(payload, this.$refs.CalendarFilter.output);
			}

			await this.fetchEvents(payload);

			// Setup the events
			let output = [];

			for (let e of this.events) {
				output.push({
					id: e.id,
					name: e.title,
					category: e.ref_category,
					details: e.description_short, 
					start: this.epochToDateTime(e.datetime_start),
					end: this.epochToDateTime(e.datetime_end),
					color: e.category.color,
					user_is_enrolled: e.user_is_enrolled,
					organizer: e.organizer,
				});
			}

			this.eventsHandled = output;
		},

		promptDelete(type) {
			switch (type) {
				case 'event':
					this.setupDialogConfirmationToEvent()
					break;
				case 'category':
					this.setupDialogConfirmationToCategory()
					break;
				default:
					break;
			}
			this.dialogs.confirmation.open = true;
		},

		confirmationDelete(type) {
			switch (type) {
				case 'event':
					this.deleteEvent(this.selectedEvent.id);
					break;
				case 'category':
					this.deleteCategory(this.selectedCategory.id);
					break;
				default:
					break;
			}
		},

		async deleteEvent(id) {
			// Delete
			await this.$store.dispatch('eventsCalendar/deleteEvent', id);

			// Close the dialog
			this.dialogs.confirmation.open = false;

			// Refresh
			this.fetchAndHandleEvents();
		},

		async deleteCategory(id) {
			// Delete
			await this.$store.dispatch('eventsCategoriesCalendar/delete', id);

			// Close the dialog
			this.dialogs.confirmation.open = false;

			// Refresh
			this.fetchAndHandleEvents();
		},

		setSelectedCategory(id) {
			this.$store.commit('eventsCategoriesCalendar/setSelected', this.eventsCategories.find(category => category.id == id));
		},

		setupMobile() {
			this.categoriesResponsive.show = false;
			this.categoriesResponsive.desktopActions = false;
			this.categoriesResponsive.mobileActions = true;
			this.type = 'week';
		}, 

		setupDesktop() {
			this.categoriesResponsive.show = true;
			this.categoriesResponsive.mobileActions = false;
			this.categoriesResponsive.desktopActions = true;
			this.type = 'month';
		},

		/**
		 * Runs on click:day
		 * 
		 * @see https://vuetifyjs.com/en/api/v-calendar/#events-click:event
		 * @param {object} event day & time object
		 */
		onClickDay(event) {
			if (! this.userIsCalendarManager) 
				return false;

			this.drawerCreateEditEvent.startDate = event.date;

			this.openDrawerCreateEditEvent('create');
		},

		/**
		 * Runs on click:time
		 * 
		 * @see https://vuetifyjs.com/en/api/v-calendar/#events-click:time
		 * @param {object} event day & time object
		 */
		onClickTime(event) {
			if (! this.userIsCalendarManager)
				return false;

			this.drawerCreateEditEvent.startDate = event.date;
			this.drawerCreateEditEvent.startTime = event.time;

			this.openDrawerCreateEditEvent('create');
		},

		/**
		 * Setup dialog confirmation after try delete a category.
		 */
		setupDialogConfirmationToCategory() {
			this.dialogs.confirmation.type = 'category'
			this.dialogs.confirmation.title = 'Apagar Categoria'
			this.dialogs.confirmation.body = 'Tens a certeza que queres apagar a categoria ' + this.selectedCategory.title + '?';
		},

		/**
		 * Setup dialog confirmation after try delete a event.
		 */
		setupDialogConfirmationToEvent() {
			this.dialogs.confirmation.type = 'event'
			this.dialogs.confirmation.title = 'Apagar Evento'
			this.dialogs.confirmation.body = 'Tens a certeza que queres apagar o evento ' + this.selectedEvent.title + '?';
		},

		/**
		 * Show the list view if the selected v-model {type} on v-btn-toggle is list 
		 */
		setupListView() {
			this.type == 'list' ? this.listView = true
								: this.listView = false;
		},

		/**
		 * Subtract 7 days to first day of the month, add 7 days to last day of the month
		 * & convert to timestamp to prepare payload
		 * 
		 * @param {string} start 
		 * @param {string} end 
		 */
		setupDatesToPayload(start, end) {
			if (! start || ! end)
				return null; 

			const startDate = moment(start).subtract(7, 'd').format('YYYY-MM-DD');
			const endDate   = moment(end).add(7, 'd').format('YYYY-MM-DD');

			return { start: this.dateToEpoch(startDate), end: this.dateToEpoch(endDate) }
		},
	
		/**
		 * Runs on mousedown:event 
		 * 
		 * @param {Object} event 
		 */
		startDrag({ event }) {
			// Set selected event 
			this.$store.commit('eventsCalendar/setSelectedEvent', this.events.find(e => e.id == event.id));

			if (this.canManageCalendarEvent()) {
				this.mousedown_start = moment();
				this.dragStart = true;
				this.dragEvent = event;
			}
		},

		/**
		 * Runs onmousemove in the month view
		 * 
		 * @param {Object} day https://vuetifyjs.com/en/api/v-calendar/#events-mousedown:day
		 */
		mouseMoveDay(day) {
			if (this.dragStart != null && this.canManageCalendarEvent() && this.type === 'month') {
				// get dragEvent date without day, just the start and end hour
				let startHour = this.dragEvent.start.slice(-9);
				let endHour   = this.dragEvent.end.slice(-9);
				
				// set new day
				this.dragEvent.start = day.date + startHour;
				this.dragEvent.end   = day.date + endHour;
			}
		},
		
		/**
		 * Runs onmousedown in the week/day view to extend end date of event
		 * set the extendingBottom to true to validate during the onmousemove if we are moving the event or extending bottom
		 * 
		 * @param {Object} event 
		 */
		extendBottom(event) {
			this.extendingBottom = true;
			this.dragStart = true;
			this.dragEvent = event;
			this.dragEvent.end = event.end;
		},

		/**
		 * Runs onmousemove in the week/day view 
		 * 
		 * @param {Native mouse event} tms https://vuetifyjs.com/en/api/v-calendar/#events-mousemove:day
		 */
		mouseMoveTime(tms) {
			if (this.dragStart != null && this.canManageCalendarEvent()) {
				/**
				 * Function to get duration 
				 */
				const startDate = moment(this.dragEvent.start);
				const endDate   = moment(this.dragEvent.end);
				const duration = endDate.diff(startDate, 'minutes');
				let newEndDate = null;

				// set end date
				if (!this.extendingBottom) {
					const newStartDate = this.nativeMouseEventToDatetime(tms);
					this.dragEvent.start = newStartDate;
					newEndDate = moment(newStartDate).add(duration, 'minutes').format('YYYY-MM-DDTHH:mm');
					this.dragEvent.end = newEndDate;
				} else {
					newEndDate = this.nativeMouseEventToDatetime(tms);
					this.dragEvent.end = newEndDate;
				}
			}
		},

		nativeMouseEventToDatetime(tms) {
			return tms.date + 'T' + tms.time;
		},

		endDrag() {
			this.mousedown_end = moment(); 

			/**
			 * As we show the menu onclick:event the startDrag and endDrag functions are called 
			 * because onclick event is fired after onmousedown & onmouseup events
			 * so don't wanna update event if is only a click
			 */
			if (this.durationOnMouseDown() > 200 && this.dragEvent) {
				this.updateDragEvent(this.dragEvent.id, this.dragEvent.start, this.dragEvent.end);
			}
			
			/**
			 * TO-DO
			 * improve this setTimeout ? 
			 * I use this setTimeout because we don't wanna open the drawer create edit event on mouseup 
			 */
			setTimeout(() => {
				this.dragEvent = null;
				this.dragStart = null;
				this.extendingBottom = false;
			}, 100)
		},

		/**
		 * mousedown_start is defined on startDrag function 
		 * mousedown_end is defined on endDrag function
		 *  
		 * @returns difference between end and start in milliseconds
		 */
		durationOnMouseDown() {
			return this.mousedown_end.diff(this.mousedown_start, 'milliseconds');
		}
	}
}