<template>
  <v-chip
    class="ma-2"
    :color="chipColor"
    dark
  >
    {{ chipText }}
  </v-chip>
</template>

<script>
import DataMixin from '@/mixins/Data';

export default {
  name: 'ValueChip',

  mixins: [DataMixin],

  props: {
    currency: { type: String, required: true, default: '' },
    value: { type: Number, required: false, default: 0 },
    colorNeutral: {type: Boolean, required: false, default: false}
  },

  data() {
    return {
      positiveColor: 'green',
      negativeColor: 'red',
      neutralColor: 'gray',
    }
  },

  computed: {
    chipText() {
      let valueToShow = this.value != '' && typeof this.value != 'object'
                        ? this.value
                        : 0;

      return this.currency + ' ' + this.formatNumber(valueToShow);
    },

    chipColor() {
      // If color neutral
      if (this.colorNeutral) {
        return this.neutralColor;
      } else {
        // Else
        if (this.value > 0)
          return this.positiveColor;
        else if (this.value < 0)
          return this.negativeColor;
        else
          return this.neutralColor;
      }
    }
  },
}
</script>

<style scoped></style>