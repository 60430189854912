export default {
  props: {
    items: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      itemsBreadcrumb: [],
      lastBreadcrumb: null,
      isMobile: false,
    };
  },

  mounted() {
    this.checkMobile();
    this.updateLastBreadcrumb();
    window.addEventListener('resize', this.checkMobile);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.checkMobile);
  },

  watch: {
    items: {
      immediate: true,
      handler() {
        this.updateLastBreadcrumb();
      }
    }
  },

  methods: {
    updateLastBreadcrumb() {
      this.itemsBreadcrumb = this.items;
      if(this.itemsBreadcrumb.length > 0){
        this.lastBreadcrumb = this.itemsBreadcrumb[this.itemsBreadcrumb.length - 1].text;
      }
    },

    checkMobile() {
      this.isMobile = window.innerWidth <= 600;
    },

    goBack() {
      this.itemsBreadcrumb = this.itemsBreadcrumb.slice(0, this.itemsBreadcrumb.length - 1);
      const lastItem = this.itemsBreadcrumb[this.itemsBreadcrumb.length - 1];
      this.$router.replace(lastItem.to);
    }
  }
};