import Vue from 'vue';
import { mapGetters } from 'vuex';
import CardStepper from '@/components/CardStepper/CardStepper.vue';
import CardStepperNotes from '@/components/CardStepper/CardStepperNotes/CardStepperNotes.vue';
import RowTransfer from '@/components/Rail/RowTransfer/RowTransfer.vue';
import AlertError from '@/components/Alerts/AlertError/AlertError.vue';
import DialogViewAttachment from '@/components/Rail/Dialog/DialogViewAttachment/DialogViewAttachment.vue';
import RailMixin from '@/mixins/Rail.vue';

export default {
  name: 'CardTransferSwap',

  components: {
    CardStepper,
    CardStepperNotes,
    RowTransfer,
    AlertError,
    DialogViewAttachment,
  },

  mixins: [
    RailMixin,
  ],

  computed: {
    ...mapGetters({
      selectedRequest: 'railRequests/selectedRequest',
      error: 'railRequests/error',
      humanError: 'railRequests/humanError',
      teamFilter: 'teams/teamFilter',
    }),

    selectedRequestObservation: function () {
      return this.getObservationByPhase(this.selectedRequest.observations, Vue.prototype.table_requests_phases[Vue.prototype.$rail_ops.operations.swap][0]['value']);
    }
  },

  watch: {
    selectedRequest: {
      handler() {
        // reset form
        this.resetForm();
        // force CardStepper to display 1 step
        this.$store.commit('UI/setCardStepperStep', 1);
        // populate fields
        this.initialize();
      }
    }
  },

  created() {
    this.initialize();
  },

  data() {
    return this.initialState();
  },

  methods: {
    initialState() {
      return {    
        form: {
          source: null,
          target: null,
          amount: null,
          checkbox: false,
          observation: null,
          gyazoLink: null,
          fileName: []
        },
        rules: {
          checkbox: [
            (v) => !!v || '',
          ],
          cardStepperNotes: [
            (v) => !!v || 'Por favor preencha o campo'
          ], 
        },
        cardTitles: [
          'Resumo inicial ',
          'Confirmação da transferência',
          'Resumo final',
        ],
        formValidations: [
          true,
          false,
          true,
        ],
        dialogues: {
          viewAttachment: false,
        },
        observation: {
          url: null,
          type: null,
        },
      }
    },

    initialize() {
      // get amount
      this.form.amount = this.selectedRequest.transfers[0]['amount'];

      // get last transfer object in order to populate source and target data 
      this.form.source = this.selectedRequest.transfers.slice(-1).pop().source;
      this.form.target = this.selectedRequest.transfers.slice(-1).pop().target;
    },

    /**
   * Validates a specific form step.
   * Assumes form steps are named 'formStep_x' where x is a Int 0 - maxStep-1.
   * 
   * @param {Int} step Identifier of the form step to validate.
   * @returns {Void}
   */
    validateStep(step) {
      const formStepRef = 'formStep_' + (step);
      if (this.$refs[formStepRef]) {
        // Update formValidation[step] in a way the DOM will be reactive
        Vue.set(
          this.formValidations,
          step - 1,
          this.$refs[formStepRef].validate()
        );
      }
    },

    resetCheckboxValidation(e) {
      if (e === false) {
        // reset validation step 2
        this.resetStepValidation(2);
      }
    },

    // retrieve fields from CardStepperNotes component
    getInfoFromCardStepperNotes(payload) {
      if (payload) {
        this.form.gyazoLink = payload.gyazoLink;
        this.form.fileName = payload.filename;
      }
    },

    async submit() {
      // Validate form
      if (!this.$refs.formTransferSwap.validate()) {
        return false;
      }

      // Toggle submit button
      this.$refs.CardStepper.toggleSubmitting();

      /**
       * Preparing payload
      */

      // Base payload
      let payload = {
        body: {}
      }

      // Add 'id' field if necessary
      if (this.selectedRequest && this.selectedRequest.id) {
        payload.id = this.selectedRequest.id;
      }

      payload.body = new FormData();

      // Add 'observation' field if necessary
      if (this.form.observation) {
        payload.body.append('observation[description]', this.form.observation);
      }

      // Add 'screenshot' field if necessary
      if (!this._.isEmpty(this.form.fileName)) {
        payload.body.append('screenshot', this.form.fileName[0]);
      }

      // Add 'observation[url]' field if necessary
      if (this.form.gyazoLink) {
        payload.body.append('observation[url]', this.form.gyazoLink);
      }

      // Dispatch to store
      let result = await this.$store.dispatch('railRequests/confirmRailOperation', payload);

      // Re-enable btn
      if (this.$refs.CardStepper) {
        this.$refs.CardStepper.toggleSubmitting();
      }

      // On success
      if (result === true) {

        let payloadRefresh = {
          params: {}
        }

        // if team exists and isManagement, Add team parameter to payload.params
        if (this.teamFilter && this.userHasRailAccessDivisionDropdown) {
          payloadRefresh.params.team = this.teamFilter;
        }

        // Refresh Requests Transfers and Balances
        this.updateRequestsTransfersAndBalances(payloadRefresh);
        // Refresh swaps
        this.$store.dispatch('railSwaps/get', payloadRefresh);

        // Show success snackbar
        this.$store.dispatch('UI/showSnackbar', {
          message: 'Swap confirmado com sucesso.',
          color: 'success'
        });
      }
    },

    resetForm() {
      Object.assign(this.$data, this.initialState());
      if (this.$refs) {
        this.$refs.formTransferSwap.reset();
      }
    },
    
    resetStepValidation(step) {
        const formStepName = 'formStep_' + step;
    
      if (this.$refs[formStepName]) {
            this.$refs[formStepName].resetValidation();
        }
    },

    getTransfers() {
      let transfers = [];

      this.selectedRequest.transfers.forEach(element => {
        // if transfer type isn't PNPW
        if (element.type != Vue.prototype.$transfer_types.PNPW)
        transfers.push({
          source: element.source,
          target: element.target,
          amount: element.amount,
          isPending: element.isPending
        })
      })
      
      return transfers;
    },

    /**
    * Populates observation.url and observation.name
    * @param {Object} observation 
    * @returns {Boolean} open dialog or opens new page;
    */
    openDialogViewAttachment(observation) {
      if (observation && observation.url) {

        // validate observation type
        let isTypeLink = observation.type == 1;

        // populate fields
        this.observation.url = observation.url;
        this.observation.type = observation.type;

        return isTypeLink
          ? window.open(observation.url, '_blank')
          : this.dialogues.viewAttachment = true;
      }
    }
  }
}
