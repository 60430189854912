import AuthMixin from '@/mixins/Auth';
import ReportsView from '@/components/TabSlider/Tabs/Reports/Reports/Reports.vue';
import ReportView from '@/components/TabSlider/Tabs/Reports/Report/Report.vue';

export default {
  name: 'Reports',

  mixins: [
    AuthMixin,
  ],

  components: {
    ReportsView,
    ReportView,
  },

  computed: {
    componentToShow() {
      return this.$route.params.id
        ? 'ReportView'
        : 'ReportsView';
    },
  },
}