var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[(!_vm.hideHeaderAndFooter)?_c('v-card-title',[_vm._v(" "+_vm._s(_vm.title)+" "),_c('v-spacer'),_vm._t("buttons"),(_vm.type != 'audit' && !_vm.hideHeaderAndFooter)?_c('v-text-field',{class:_vm.type == 'gameEntries' || _vm.type == 'games' ? 'table-search-field d-none d-md-flex' : 'table-search-field d-none d-md-flex',attrs:{"id":"searchInput","label":"Pesquisar","append-icon":"mdi-magnify","hide-details":"","outlined":"","dense":""},on:{"input":function($event){return _vm.search()}},model:{value:(_vm.dataTable.search),callback:function ($$v) {_vm.$set(_vm.dataTable, "search", $$v)},expression:"dataTable.search"}}):_vm._e()],2):_vm._e(),(!_vm.hideHeaderAndFooter)?_c('v-card-subtitle',[_vm._v(" "+_vm._s(_vm.type == 'audit' ? 'Dados importados através do audit' : 'Dados retirados do SharkScope')+" ")]):_vm._e(),(_vm.type != 'audit')?_c('v-text-field',{class:_vm.type == 'gameEntries' || _vm.type == 'games' ? 'ma-4 d-md-none' : 'ma-4 d-md-none',attrs:{"id":"searchInput","label":"Pesquisar","append-icon":"mdi-magnify","hide-details":"","dense":""},on:{"input":function($event){return _vm.search()}},model:{value:(_vm.dataTable.search),callback:function ($$v) {_vm.$set(_vm.dataTable, "search", $$v)},expression:"dataTable.search"}}):_vm._e(),_c('div',[_vm._t("generalInfo")],2),_c('v-data-table',{style:({'overflow':'auto'}),attrs:{"headers":_vm.tableHeaders,"items":_vm.tableData,"search":_vm.dataTable.search,"server-items-length":_vm.tableTotal,"options":_vm.options,"loading":_vm.tableLoading,"hide-default-footer":_vm.hideHeaderAndFooter,"footer-props":_vm.dataTable.footerProps,"must-sort":""},on:{"update:options":function (e) { return _vm.optionsComputed = e; }},scopedSlots:_vm._u([{key:"item.dateStart",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({style:({ color: _vm.valueColorFinal() })},'span',attrs,false),on),[_vm._v(_vm._s(_vm.getDate(item)))])]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.getDate(item, 'all')))])])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-content justify-space-between"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',{staticClass:"custom-width",style:({'max-width': _vm.getMaxWidthColumnName()})},[_c('div',[(_vm.generateValidationNetworkGGPoker(item))?_c('a',{attrs:{"href":_vm.type == 'games' || _vm.type == 'network' ? _vm.generateSharkScopeGameUrl(item.networkSharkscope.code, item.idSharkscope) : _vm.generateSharkScopeGameUrl(item.game.networkSharkscope.code, item.game.idSharkscope),"target":"_blank"}},[_c('v-icon',{attrs:{"dense":""}},[_vm._v("mdi-shark-fin")])],1):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"dense":""}},on),[_vm._v(" mdi-shark-fin ")])]}}],null,true)},[_c('span',[_vm._v("Este jogo foi adicionado através da importação de ficheiros Pokercraft e por isso não pode ser consultado no Sharkscope")])])],1),(item)?_c('a',_vm._g(_vm._b({style:({ 
                  color: _vm.valueColorFinal(), 
                  textOverflow: 'ellipsis', 
                  whiteSpace: 'nowrap', 
                  overflow: 'hidden' 
                }),attrs:{"href":_vm.isGameSingleView ? null : _vm.generateLink(item),"rel":"noopener noreferrer"}},'a',attrs,false),on),[_vm._v(_vm._s(_vm.getTournamentName(item)))]):_vm._e()])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getTournamentName(item)))])]),_c('div',{staticStyle:{"margin-left":"1em"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [(_vm.getGuarantee(item) !== null)?_c('v-chip',_vm._g(_vm._b({staticClass:"d-flex align-center justify-center font-weight-medium",staticStyle:{"min-width":"66px"},attrs:{"color":"accent","text-color":"black","label":"","small":""}},'v-chip',attrs,false),on),[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v(" mdi-cash-check ")]),_c('span',[_vm._v(_vm._s(_vm.getGuarantee(item)))])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Garantido: "+_vm._s(_vm.getSymbol(item))+" "+_vm._s(_vm.getGuarantee(item, 'all'))+" ")])])],1)],1)]}},{key:"item.nickname.user",fn:function(ref){
                var item = ref.item;
return [_c('UserCard',{attrs:{"id":item.nickname.user.id,"img-cutout":item.nickname.user.imgCutout,"display-name":item.nickname.user.displayName}})]}},{key:"item.nickname.name",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[(item.game && item.game.networkSharkscope)?_c('router-link',{attrs:{"to":'/networks/' + item.game.networkSharkscope.id}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('div',{staticClass:"d-flex align-center"},[_c('img',_vm._g({staticClass:"mr-2",attrs:{"src":require('@/assets/networks/' + item.game.networkSharkscope.image)}},on))])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(item.game.networkSharkscope.name)+" ")])])],1):_vm._e(),_c('router-link',{staticClass:"custom-name-class mr-2",attrs:{"to":'/nicknames/' + item.nickname.id + '/'}},[_vm._v(" "+_vm._s(item.nickname.name)+" ")]),(item.description)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"dense":""}},on),[_vm._v(" mdi-message-text ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.nickname.description))])]):_vm._e(),(item.nickname.aliasChildOf)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"dense":""}},on),[_vm._v(" mdi-account-switch ")])]}}],null,true)},[_c('span',[_vm._v("Este nickname é um alias")])]):_vm._e()],1)]}},{key:"item.networkSharkscope.name",fn:function(ref){
                var item = ref.item;
return [_c('router-link',{attrs:{"to":'/networks/' + item.networkSharkscope.id}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('div',{staticClass:"d-flex align-center"},[_c('img',_vm._g({staticClass:"mr-2",attrs:{"src":require('@/assets/networks/' + item.networkSharkscope.image)}},on))])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(item.networkSharkscope.name)+"2 ")])])],1)]}},{key:"item.group",fn:function(ref){
                var item = ref.item;
return [(item && item.group)?_c('span',[_c('router-link',{attrs:{"to":'/groups/' + item.group.id + '/'}},[_c('div',{staticClass:"d-flex align-center"},[_c('div',[_c('v-img',{staticClass:"my-auto",attrs:{"src":require('@/assets/groups/' + item.group.image),"height":"24","width":"24"}})],1),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(item.group.name))])])])],1):_vm._e()]}},{key:"item.buyin",fn:function(ref){
                var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"font-weight-semibold w-full",attrs:{"small":""}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(_vm.getSymbol(item))+" "+_vm._s(_vm.getBuyIn(item))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getSymbol(item))+" "+_vm._s(_vm.getStake(item))+" + "+_vm._s(_vm.getSymbol(item))+" "+_vm._s(_vm.getRake(item))+" ")])])]}},{key:"item.investment",fn:function(ref){
                var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"font-weight-semibold w-full",attrs:{"small":""}},'v-chip',attrs,false),on),[_c('span',{staticClass:"mr-1"},[_vm._v(_vm._s(_vm.getSymbol(item)))]),_c('currency-input',{staticClass:"d-flex",style:({ color: _vm.$vuetify.theme.dark ? 'white' : 'black', width: '65px' }),attrs:{"value":_vm.getBuyIn(item),"currency":null,"precision":2,"allow-negative":"","disabled":""}})],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getSymbol(item))+" "+_vm._s(_vm.getStake(item))+" + "+_vm._s(_vm.getSymbol(item))+" "+_vm._s(_vm.getRake(item))+" ")])])]}},{key:"item.position",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-center text--primary"},[_c('span',{staticClass:"font-weight-semibold"},[_vm._v(_vm._s(item.position))]),_c('span',{staticClass:"mx-1"},[_vm._v("/")]),_c('span',[_vm._v(_vm._s(item.game.totalEntrants))])])]}},{key:"item.prize",fn:function(ref){
                var item = ref.item;
return [_c('v-chip',{staticClass:"font-weight-semibold w-full",attrs:{"small":""}},[_c('span',{staticClass:"mr-1"},[_vm._v(_vm._s(_vm.getSymbol(item)))]),_c('currency-input',{staticClass:"d-flex",style:({ color: _vm.$vuetify.theme.dark ? 'white' : 'black', width: '65px' }),attrs:{"value":item.prize.value,"currency":null,"precision":2,"allow-negative":"","disabled":""}})],1)]}},{key:"item.game.flags",fn:function(ref){
                var item = ref.item;
return [_c('div',[_c('v-row',{staticClass:"game-flags flags-wrap",attrs:{"dense":""}},[_vm._l((_vm.getFlags(item)),function(flag,index){return _c('span',{key:index,class:flag !== null ? '' : 'd-none',attrs:{"bottom":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('div',[_c('v-chip',_vm._g(_vm._b({staticClass:"ma-1 px-2 d-flex align-center justify-center font-weight-semibold",staticStyle:{"width":"52px"},attrs:{"label":"","small":""}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(flag)+" ")])],1)]}}],null,true)},[(flag == '...')?_c('span',[_vm._v(" "+_vm._s((_vm.getRemainingFlags(item) ? _vm.getRemainingFlags(item) + ', ' : '') + _vm.mapFlagToLabel(item.game.structure, 'id'))+_vm._s(_vm.mapFlagToLabel(item.game.game, 'id'))+" ")]):_c('span',[_vm._v(_vm._s(_vm.mapFlagToLabel(flag)))])])],1)}),(_vm.getFlags(item).length < 2)?_c('span',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('div',[_c('v-chip',_vm._g(_vm._b({staticClass:"ma-1 px-2 d-flex align-center justify-center font-weight-semibold",staticStyle:{"width":"52px"},attrs:{"label":"","small":""}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(item.game.structure)+_vm._s(item.game.game)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.mapFlagToLabel(item.game.game, 'game'))+", "+_vm._s(_vm.mapFlagToLabel(item.game.structure, 'game')))])])],1):_vm._e()],2)],1)]}},{key:"item.flags",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_vm._l((_vm.getFlags(item)),function(flag,index){return _c('span',{key:index,class:flag !== null ? '' : 'd-none',attrs:{"bottom":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('div',[_c('v-chip',_vm._g(_vm._b({staticClass:"ma-1 px-2 d-flex align-center justify-center font-weight-semibold",staticStyle:{"width":"52px"},attrs:{"label":"","small":""}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(flag)+" ")])],1)]}}],null,true)},[(flag == '...')?_c('span',[_vm._v(" "+_vm._s((_vm.getRemainingFlags(item) ? _vm.getRemainingFlags(item) + ', ' : '') + _vm.mapFlagToLabel(item.structure, 'id'))+_vm._s(_vm.mapFlagToLabel(item.game, 'id'))+" ")]):_c('span',[_vm._v(_vm._s(_vm.mapFlagToLabel(flag)))])])],1)}),(_vm.getFlags(item).length < 2)?_c('span',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('div',[_c('v-chip',_vm._g(_vm._b({staticClass:"ma-1 px-2 d-flex align-center justify-center font-weight-semibold",staticStyle:{"width":"52px"},attrs:{"label":"","small":""}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(item.structure)+_vm._s(item.game)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.mapFlagToLabel(item.game, 'game'))+", "+_vm._s(_vm.mapFlagToLabel(item.structure, 'game')))])])],1):_vm._e()],2)]}},{key:"item.pnl",fn:function(ref){
                var item = ref.item;
return [_c('div',{style:(_vm.type == 'gameEntries' ? 'display: flex; flex-wrap: nowrap; min-width: 10vw' : 'display: flex; flex-wrap: nowrap;')},[_c('v-chip',{attrs:{"small":"","color":item.pnl.value > 0 ? 'success' : 'error'}},[_c('span',{staticClass:"mr-1"},[_vm._v(_vm._s(_vm.getSymbol(item)))]),_c('currency-input',{staticClass:"text-end",style:({color: 'white', width: '65px' }),attrs:{"value":item.pnl.value,"currency":null,"precision":2,"allow-negative":"","disabled":""}})],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"text--primary ml-2",attrs:{"small":""}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(_vm.formatNumber(item.roi, '%'))+" ")])]}}],null,true)},[_c('span',[_vm._v("ROI: "+_vm._s(_vm.formatNumber(item.roi, '%'))+" ("+_vm._s(_vm.getSymbol(item))+" "+_vm._s(item.investment.value)+") ")])])],1)]}},{key:"item.totalEntrants",fn:function(ref){
                var item = ref.item;
return [_c('span',{staticClass:"text--primary"},[_vm._v(_vm._s(item.totalEntrants)+" ")])]}},{key:"item.action",fn:function(ref){
                var item = ref.item;
return [_c('router-link',{attrs:{"to":_vm.generateLink(item)}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"dense":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-launch ")])]}}],null,true)},[_c('span',[_vm._v("Abrir jogo")])])],1)]}},{key:"no-data",fn:function(){return [_c('div',{staticClass:"fill-height"},[_c('EmptyStateNoData',{staticClass:"justify-center flex-column d-flex",style:(_vm.emptyStateStyle),attrs:{"icon":_vm.emptyStateIcon,"title":_vm.getTitleForEmptyState(),"message":_vm.emptyStateMessage,"class-color":_vm.emptyStateClassColor,"class-icon":_vm.emptyStateClassIcon}})],1)]},proxy:true},{key:"no-results",fn:function(){return [_c('EmptyStateNoData',{attrs:{"icon":"mdi-magnify","title":"Não foram encontrados resultados","message":"Ajuste os termos da sua pesquisa para encontrar mais resultados"}})]},proxy:true},{key:"loading",fn:function(){return [_c('v-skeleton-loader',{attrs:{"type":"table-tbody","height":"250px"}}),_c('v-skeleton-loader',{attrs:{"type":"table-tbody","height":"182px"}})]},proxy:true}])},[_c('template',{slot:"progress"},[_c('v-progress-linear',{attrs:{"color":"accent","indeterminate":""}})],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }