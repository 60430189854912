import { mapGetters } from 'vuex';
import TableStatistics from '@/components/Rail/TableStatistics/TableStatistics.vue';

export default {
    name: 'Statistics',

    components: {
        TableStatistics,
    },

    data() {
        return this.initialState();
    },

    computed: {
        ...mapGetters({
            statistics: 'railStatistics/data',
            statisticsLoading: 'railStatistics/loading',
            heroDatePickerValue: 'UI/heroDatePickerValue',
        }),
    },

    methods: {
        initialState() {
            return {};
        },
    }
}
