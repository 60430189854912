import Vue from 'vue';
import { mapGetters } from 'vuex';
import DataMixin from '@/mixins/Data.vue';
import AuthMixin from '@/mixins/Auth.vue';
import UserMixin from '@/mixins/User.vue';
import RailMixin from '@/mixins/Rail.vue';
import DatePicker from '@/components/DatePicker/DatePicker.vue';
import AlertError from '@/components/Alerts/AlertError/AlertError.vue';
import ButtonAction from '@/components/Rail/ButtonAction/ButtonAction.vue';
import NetworkSessionsSpinsPicker from '@/components/Rail/NetworkSessionsSpinsPicker/NetworkSessionsSpinsPicker.vue';
import UserDropdown from '@/components/Rail/UserDropdown/UserDropdown.vue';
import DialogConfirmation from '@/components/Rail/Dialog/DialogConfirmation/DialogConfirmation.vue';
import InputFinancial from '@/components/InputFinancial/InputFinancial.vue';

export default {
	name: 'DialogAddEditSessionSpins',

	components: {
		DatePicker,
		AlertError,
		ButtonAction,
		NetworkSessionsSpinsPicker,
		UserDropdown,
		DialogConfirmation,
		InputFinancial,
	},

	mixins: [
		DataMixin,
		AuthMixin,
		UserMixin,
		RailMixin,
	],

	props: {
		isOpen: { type: Boolean, required: true, default: false },
		action: {
			type: String,
			required: true,
			default: 'create',
			validator: function (value) {
				const allowedActions = ['create', 'update']
				return allowedActions.includes(value);
			},
		},
		reloadSingleUser: { type: Boolean, required: false, default: false },
	},

	computed: {
		...mapGetters({
			selectedSession: 'railSessions/selectedSession',
			error: 'railSessions/error',
			humanError: 'railSessions/humanError',
			userTrackerId: 'auth/userTrackerId',
			selectedUser: 'users/selectedUser',
			heroDatePickerValue: 'UI/heroDatePickerValue',
			teamFilter: 'teams/teamFilter',
			userTrackerId: 'auth/userTrackerId',
			userRoles: 'auth/userRoles',
			themeDarkMode: 'UI/themeDarkMode',
			existsItemsAvailableAfterFilter: 'nicknames/existsItemsAvailableAfterFilter',
			numberOfItemsAvailableAfterFilter: 'nicknames/numberOfItemsAvailableAfterFilter',
			hasLoadedNicknamesAllMine: 'nicknames/hasLoadedNicknamesAllMine',
		}),

		computedUser: {
            get: function () {
                return this.form.user;
            },
            set: function (newValue) {
                this.form.user = newValue;
                return newValue;
            },
		},

		userId: function () {
            return this.computedUser
                ? this.computedUser.id
                : null;
		},
		
		handleClassesContainer: function () {
			let hasAnyAlert = (this.action == 'create' && this.handleDateAlert()) || (this.showAlertErrorInputField) || !this._.isNull(this.error) || !this.existsItemsAvailableAfterFilter && (this.userIsPlayer && this.hasLoadedNicknamesAllMine);
			let hasDoubleAlerts = (this.handleDateAlert() && this.showAlertErrorInputField) || (this.handleDateAlert() && !this._.isNull(this.error)) || (this.showAlertErrorInputField && !this._.isNull(this.error)) || (!this.existsItemsAvailableAfterFilter && this.showAlertErrorInputField && (this.userIsPlayer && this.hasLoadedNicknamesAllMine))

			let classes = ' overflow-auto overflow-x-hidden fill-height px-6';
			let paddingClass = this.userIsManager ? 'custom-class-padding-manager ' : 'custom-class-padding ';
			let paddingAlertClass = this.userIsManager ? 'custom-class-padding-manager-alert ' : 'custom-class-padding-alert ';
			let paddingDoubleAlertClass = this.userIsManager ? 'custom-class-padding-manager-double-alert ' : 'custom-class-padding-double-alert ';

			if (hasDoubleAlerts) {
				return paddingDoubleAlertClass + classes;
			} else if (hasAnyAlert) {
				return paddingAlertClass + classes;
			} else {
				return paddingClass + classes;
			}
		},

		// maps sessionNicknamesID's , used in NetworkSessionSpinsPicker
		sessionNicknamesNetworkIDS: function () {
			return this.sessionNicknames.map(element => {
				if (element.nickname && element.nickname.network) {
					return element.nickname.network.id;
				}
			})
		}
	},

	watch: {
		// When form is reopened, reset it and fill date
		isOpen: function (newVal, oldVal) {
			if (newVal === true) {
				// Clear errors such as "sessions not found" associated with requests not regarding add/edit session
				this.$store.dispatch('railSessions/clearError');

				// Reset the form
				this.resetForm();
				
				// On open
				this.onOpen();
			}
		},
	},

	data() {
		return this.initialState();
	},

	async created() {
		// if is Player user, make API call to get his own info
		if (this.userIsPlayer && this.isOpen) {
			await this.fetchUser(this.userTrackerId);
		};
	},
	
	methods: {
		initialState() {
			return {
				showAlertErrorInputField: false,
				showAlertDate: false,
				sessionNicknames: [],
				messageDateAlert: 'Data definida para o dia anterior. Altera-a se iniciaste a sessão já no dia de hoje.',
				datePickerMenu: false,
				form: {
					submitting: false,
					deleting: false,
					date: this.getCurrentDate(),
					user: null,
					minutesPlayed: null,
					eventsPlayed: null,
					avgNumTables: null,
					sessionWarmup: null,
					networkPicker: null,
					activities: [],
					activitiesList: [
						{ text: 'Warm Up', value: 1 },
						{ text: 'Meditação', value: 2 },
					],
				},
				dialogues: {
					delete: false,
				},
				rules: {
					user: [
						(v) => !!v || 'Por favor preencha o nome do jogador'
					],
					date: [
						(v) => !!v || 'Introduza uma data',
						(v) => this.dateDiff(this.$moment(), v) >= 0 || 'A data não pode ser futura',
					],
					NetworkPicker: [
						(v) => !v.length || 'Selecione pelo menos uma sala'
					],
					notNull: [
						(v) => !!v || 'Introduza um valor',
					],
				},
			};
		},

		async onOpen() {
			// if user is player then populates computedUser
			if (this.userIsPlayer) {
				this.computedUser = { id: this.userTrackerId };
			}

			// need timeout to render element and then validate if exists to reset validation
			setTimeout(() => {
				if (this.$refs && this.$refs.avgNumTables) {
					this.$refs.avgNumTables.resetValidation();
				}

				if (this.$refs && this.$refs.minutesPlayed) {
					this.$refs.minutesPlayed.resetValidation();
				}
			}, 50);

			if (this.action == 'create') {

				// add an default entry 
				this.addSessionNickname();

				// reset UserDropdown validation
				if (this.$refs && this.$refs.userDropdown) {
					this.$refs.userDropdown.$refs.formUserDropdown.resetValidation();
				}

				// Populate date
				if (this.handleDateAlert()) {
					this.form.date = this.$moment().subtract(1, 'days').format('YYYY-MM-DD');
				} else {
					this.form.date = this.$moment().format('YYYY-MM-DD');
				}
			}

			if (this.action == 'update' && this.selectedSession) {
				// retrieve session again in order to get metadata fields
				await this.$store.dispatch('railSessions/getSessionById', this.selectedSession.id);

				// populate fields after retrieving session by id
				this.populateOnEdit();
			}
		},

		/**
		 * @param {Integer} id 
		 */
		async fetchUser(id) {
			if (id) {
				await this.$store.dispatch('users/getUser', id);
			}
			this.form.user = this.selectedUser;
		},

		/**
		 * Submits form
		 */
		async submit() {
			// sets alert to false
			this.showAlertErrorInputField = false;

			// Validate form
			if (!this.$refs.formRailAddSessionSpins.validate()) {
				// shows alert
				this.showAlertErrorInputField = true;
				return false;
			}

			for (let index = 0; index < this.sessionNicknames.length; index++) {
				if (!this.$refs['sessionNickname' + index][0].$refs.formSessionNicknames.validate()) {
					// shows alert
					this.showAlertErrorInputField = true;
					return false;
				}
				if (!this.$refs['sessionNickname' + index][0].$refs.formGameSpins.validate()) {
					// shows alert
					this.showAlertErrorInputField = true;
					return false;
				}
			}

			// Disable submit btn
			this.toggleFormSubmitting();
			
			let payload = this.formulatePayload();

			// Define store action
			let storeAction = this.action == 'create'
				? 'railSessions/create'
				: 'railSessions/update';

			// Dispatch to store
			let result = await this.$store.dispatch(storeAction, payload);

			// Re-enable btn
			this.toggleFormSubmitting();

			// On success
			if (result === true) {
				if (this.action == 'create') {
					let payloadRefresh = {
						params: {
							itemsPerPage: 10,
							page: 1,
							'sortBy[0]': 'id',
							'sort[0]': 'DESC',
							'type[0]': 6,
							'type[1]': 3,
							'type[2]': 7,
							'type[3]': 9,
							'type[4]': 2,
							'type[5]': 1,
							'type[6]': 5,
							'type[7]': 8,
							'type[8]': 4,
						}
					}

					// if team exists and isManagement, Add team parameter to payload.params
					if (this.teamFilter && this.userHasRailAccessDivisionDropdown()) {
						payloadRefresh.params.team = this.teamFilter;
					}
					
					this.$store.dispatch('railRequests/getRequests', payloadRefresh);
				}

				// Refresh Table Sessions/Nicknames/Statistics
				this.refresh(payload.user);

				// Close dialog
				this.closeDialog();

				// Define message to show
				let snackbarMsg = this.action == 'create'
					? 'Sessão guardada com sucesso.'
					: 'Sessão atualizada com sucesso.';

				// Show success snackbar
				this.$store.dispatch('UI/showSnackbar', {
					message: snackbarMsg,
					color: 'success'
				});
			}
		},

		async deleteSession() {
			let result;

			// Disable delete btn
			this.toggleFormDeleting();

			// Generate payload
			let payload = this.selectedSession && this.selectedSession.id;

			// Dispatch to store
			if (payload) {
				result = await this.$store.dispatch('railRequests/deleteRequest', payload);
			}

			// Re-enable delete btn
			this.toggleFormDeleting();

			// On success
			if (result === true) {

				// Refresh Table Sessions/Nicknames/Statistics
				this.refresh();

				// Emit 'submitted' event
				this.$emit('submitted');

				// Close dialog
				this.closeDialog();

				// Show success snackbar
				this.$store.dispatch('UI/showSnackbar', {
					message: 'Sessão apagada com sucesso.',
					color: 'success'
				});
			}
		},

		/**
		 * populates dialog fields based on selectedSession
		 */
		populateOnEdit() {
			if (this.selectedSession) {			
				this.computedUser = this.selectedSession.user;
				this.form.date = this.$moment(this.selectedSession.date.date).format('YYYY-MM-DD');
				this.form.minutesPlayed = this.selectedSession.minutesPlayed;
				this.form.eventsPlayed = this.selectedSession.eventsPlayed;
				this.form.avgNumTables = this.selectedSession.avgNumTables; 
				this.form.meditation = this.selectedSession.meditation;
				this.form.warmup = this.selectedSession.warmup;
				this.form.activities = [];

				// If warmup is true, then we push it to this.form.activities[] in order to appear in v-combobox element view as selected
				if (this.form.warmup)
					this.form.activities.push(this.form.activitiesList[0].text);
				if (this.form.meditation)
					this.form.activities.push(this.form.activitiesList[1].text);
				
				this.sessionNicknames = [];
					
				this.selectedSession.sessionNicknames.forEach((element, index) => {

					let entry = {}
                    entry.balance = element.balance.value
                    entry.rakebackBalance = element.rakebackBalance
                    entry.rakebackTickets = element.rakebackTickets
					entry.rakebackCoins = element.rakebackCoins
					entry.metadata = element.metadata
					entry.nickname = element.nickname
					
					// updates sessionNicknames[]
					Vue.set(this.sessionNicknames, index, entry);
				})
			}
		},

		/**
		 * @returns {String} formatted date
		 */
		getCurrentDate() {
			return this.$moment().format('YYYY-MM-DD');
		},

		closeDialog() {
			this.$store.commit('railSessions/setSelected', null);
			this.$emit('close-dialog');
			this.resetForm();
		},

		resetForm() {
			// Reset data
			Object.assign(this.$data, this.initialState());

			// Reset validation
			if (this.$refs.formRailAddSessionSpins)
				this.$refs.formRailAddSessionSpins.reset();
		},

		// used in submit()
		toggleFormSubmitting() {
			this.form.submitting = !this.form.submitting;
		},
		// used in deleteSession()
		toggleFormDeleting() {
			this.form.deleting = !this.form.deleting;
		},

		/**
		 * Refreshes Nicknames/Statistics/Sessions after an action (Create/Update/Delete) Session
		 * @param {String|Number} User ID
		 */
		refresh(userID) {
			let payloadRefresh = {
				params: {}
			};
			let dateObject = {}

			// Populates dateObject used in railStatistics endpoint && railSessions/get endpoint
			if (!this._.isEmpty(this.heroDatePickerValue)) {
				dateObject.dateBegin = this.heroDatePickerValue[0];
				dateObject.dateEnd = this.heroDatePickerValue[1];

				payloadRefresh.params.dateBegin = dateObject.dateBegin;
				payloadRefresh.params.dateEnd = dateObject.dateEnd;
			}

			// if team exists and isManagement, Add team parameter to payload.params
			if (this.teamFilter && this.userHasRailAccessDivisionDropdown()) {
				payloadRefresh.params.team = this.teamFilter;
			}

			// if reloadSingleUser (Single Page View) is true
			if (this.reloadSingleUser) {
				// add user ID to payload
				if (this.userIsRailManager) {
					payloadRefresh.params.user = userID ? userID : this.selectedSession.user.id;
				} else {
					payloadRefresh.params.user = this.userTrackerId;
				}
				
				let payloadRailStatistics = {
					params: {
						user: payloadRefresh.params.user,
						dateBegin: payloadRefresh.params.dateBegin,
						dateEnd: payloadRefresh.params.dateEnd,
					}
				};

				// Refresh TableBalances by user ID
				if (this.userIsRailManager) {
					let payloadTableBalance = {
						user: payloadRefresh.params.user,
						aliasParameter: '&aliasChildOf=null'
					  }
					this.$store.dispatch('nicknames/getAllPlayer', payloadTableBalance);
				}

				// Refresh rail statistics (TableBalances stats)
				this.$store.dispatch('railStatistics/getByUserId', payloadRailStatistics);

				// Refresh obligation statistics
				let payloadObligationStats = {
					id: userID
				}

				this.$store.dispatch('users/getUserStatistics', payloadObligationStats);
			} 

			// if user
			if (this.userIsPlayer) {
				// Refresh Rail Statistics
				if (!this._.isEmpty(dateObject)) {
					this.$store.dispatch('railStatistics/get', dateObject);
				}

				// set promiseAllMine to null in order to allow refreshing nicknames all mine again
				this.$store.commit('nicknames/setPromiseAllMine', null);

				// Refresh TableBalance (own nicknames)
				this.$store.dispatch('nicknames/getAllMine');
			}

			// Refresh Sessions
			this.$store.dispatch('railSessions/get', payloadRefresh);
		},

		/**
		 * Removes item based on index position
		 * @param {Number} index 
		 */
		removeEntry(index) {
			this.sessionNicknames.splice(index, 1);
		},

		/**
		 * @returns {Array} filled with metadata
		 */
		handleMetadataPayload() {
			let output = [];
			
			this.sessionNicknames.forEach(element => {
				if (!this._.isEmpty(element.metadata)) {
					element.metadata.map(item => {
						let itemMapped = {
							chipEv: item.chipEv,
							chipNetWon: item.chipNetWon,
							gameType: item.gameType,
							nicknameID: element.nickname ? element.nickname.id : null,
							numberEvents: item.numberEvents,
							stake: item.stake
						}

						return output.push(itemMapped);
					})
				}
			})

			return output;
		},

		/**
		 * @returns {Array} filled with sessionNicknames
		 */
		handleSessionNicknamesPayload() {
			let output = [];

			output = this.sessionNicknames.map(element => {
				return {
					nicknameID: element.nickname ? element.nickname.id : null,
					balance: element.balance,
					rakebackBalance: element.rakebackBalance ? element.rakebackBalance : 0,
					rakebackTickets: element.rakebackTickets ? element.rakebackTickets : 0,
					rakebackCoins: element.rakebackCoins ? element.rakebackCoins : 0,
				}
			} )

			return output;
		},

		/**
		 * sums every numberEvents 
		 * @returns {Number}
		 */
		handleEventsPlayedPayload() {
			let output = 0;
			let balanceMapped = []

			this.sessionNicknames.forEach(element => {
				if (!this._.isEmpty(element.metadata)) {
					element.metadata.map(item => {
						return balanceMapped.push(item.numberEvents);
					})
				}
			})

			output = this._.sum(balanceMapped);

			return output;
		},

		/**
		 * prepares an object to be used as payload
		 * @returns {Object}
		 */
		formulatePayload() {
			let payload = {
				date: this.form.date,
				minutesPlayed: this.form.minutesPlayed,
				avgNumTables: this.form.avgNumTables,
				eventsPlayed: this.handleEventsPlayedPayload(),
				warmup: this.form.activities.includes('Warm Up'),
				meditation: this.form.activities.includes('Meditação'),
				sessionNicknames: this.handleSessionNicknamesPayload(),
				metadata: this.handleMetadataPayload(),
				user: this.userId,
			};

			if (this.action == 'update') {
				payload.id = this.selectedSession.id;
			}

			return payload;
		},

		// returns { Boolean } value to show date Alert
		handleDateAlert() {
			let handleDefaultDate = this.handleDateDefaultWhenEarly(Vue.prototype.defaultWhenEarlyCap.start, Vue.prototype.defaultWhenEarlyCap.end);
			let isDatePast = this.$moment().format('YYYY-MM-DD') > handleDefaultDate;

			// boolean value that shows v-alert
			return isDatePast;
		},

		// disables btn based on sessionNicknames length
		handleDeleteBtn() {
			return this.sessionNicknames.length == 1;
		},

		/**
		 * used in input-financial input element in order to do proper validations
		 * @param {Number} field 
		 * @returns {Array} with boolean values
		 */
		handleMinutesPlayedRules(field) {
			return [
				() => !!field || 'Introduza a duração da sessão',
				() => (!!field && Number(field) <= 2880) || 'O valor tem de ser inferior a 24 horas',
				() => _.isInteger(Number(field)) || 'Por favor introduza um número válido.',
			]
		},
		
		/**
		 * used in input-financial input element in order to do proper validations
		 * @param {Number} field 
		 * @returns {Array} with boolean values
		 */
		handleEventsPlayed(field) {
			return [
				() => !!field || 'Introduza um número de mesas',
				() => _.isInteger(Number(field)) || 'Por favor introduza um número válido.',
				() => (!!field && parseFloat(String(field).replace(/,./g, '.')) > 0) || 'O valor tem de ser positivo',
			]
		},

		/**
		 * updates SessionNicknames through refs
		 * @param {Number} index 
		 */
		updateSelectedSessionNickname(index) {
			if (this.$refs && this.$refs['sessionNickname'.concat(index)] && this.$refs['sessionNickname'.concat(index)][0]) {
				Vue.set(this.sessionNicknames, index, {
					nickname: this.$refs['sessionNickname'.concat(index)][0].selectedSessionNickname.nickname,
					balance: this.$refs['sessionNickname'.concat(index)][0].selectedSessionNickname.balance,
					rakebackBalance: this.$refs['sessionNickname'.concat(index)][0].selectedSessionNickname.rakebackBalance,
					rakebackTickets: this.$refs['sessionNickname'.concat(index)][0].selectedSessionNickname.rakebackTickets,
					rakebackCoins: this.$refs['sessionNickname'.concat(index)][0].selectedSessionNickname.rakebackCoins,
					metadata: this.$refs['sessionNickname'.concat(index)][0].spinsListComputed
				});
			}
		},

		addSessionNickname(index = null) {
            let i = index ? index : this.sessionNicknames.length;
            if (i == 0 || (i != 0)) {
                // Add empty session nickname (with id -1)
				Vue.set(this.sessionNicknames, i,
					{
						nickname: null,
						balance: null,
						rakebackBalance: 0,
						rakebackTickets: 0,
						rakebackCoins: 0,
						metadata: [
							{
								stake: null,
								gameType: 1,
								numberEvents: 0,
								chipNetWon: 0,
								chipEv: 0,
							}
						],
					});
            }
		},
		
		removeSelectedSessionNickname(index) {
			this.sessionNicknames.splice(index, 1)
		},
	},
};