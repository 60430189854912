var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{staticClass:"pa-0"},[(_vm.$vuetify.breakpoint.mdAndDown)?_c('v-expansion-panels',[_c('v-expansion-panel',{attrs:{"id":"expansion-panel-calendar-filter"}},[_c('v-expansion-panel-header',[_c('div',[_c('v-btn',{attrs:{"small":"","icon":""}},[_c('v-icon',[_vm._v("mdi-filter")])],1),_c('span',{staticStyle:{"font-size":"large"}},[_vm._v("Filtro")])],1)]),_c('v-expansion-panel-content',[_c('v-col',{staticClass:"d-flex flex-column flex-lg-row px-0 pb-0"},[_c('v-autocomplete',{staticClass:"mr-lg-2 mb-0 pb-0",attrs:{"label":"Grupo","items":_vm.groups,"item-text":"name","item-value":"id","outlined":"","multiple":"","chips":"","dense":"","clearable":""},on:{"input":function($event){return _vm.emitChanges()}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('v-chip',[_c('span',[_vm._v(_vm._s(item.name))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.filters.group.length - 1)+") ")]):_vm._e()]}}],null,false,1028258205),model:{value:(_vm.filters.group),callback:function ($$v) {_vm.$set(_vm.filters, "group", $$v)},expression:"filters.group"}}),_c('v-autocomplete',{staticClass:"mr-lg-2 ml-lg-2 mb-0",attrs:{"label":"Coach","items":_vm.users,"item-text":"name","item-value":"id","outlined":"","dense":"","multiple":"","chips":"","clearable":""},on:{"input":function($event){return _vm.emitChanges()}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('v-chip',[_c('span',[_vm._v(_vm._s(item.name))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.filters.coach.length - 1)+") ")]):_vm._e()]}}],null,false,1956340260),model:{value:(_vm.filters.coach),callback:function ($$v) {_vm.$set(_vm.filters, "coach", $$v)},expression:"filters.coach"}}),_c('v-autocomplete',{staticClass:"mr-lg-2 ml-lg-2 mb-0",attrs:{"label":"Participantes","items":_vm.users,"item-text":"name","item-value":"id","outlined":"","dense":"","multiple":"","chips":"","clearable":""},on:{"input":function($event){return _vm.onAttendeeInput()}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('v-chip',[_c('span',[_vm._v(_vm._s(item.name))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.filters.attendee.length - 1)+") ")]):_vm._e()]}}],null,false,3720901804),model:{value:(_vm.filters.attendee),callback:function ($$v) {_vm.$set(_vm.filters, "attendee", $$v)},expression:"filters.attendee"}}),_c('v-select',{staticClass:"ml-lg-2 mb-0",attrs:{"label":"Comparência","items":_vm.itemsAttendance,"disabled":_vm.filters.attendee.length === 0,"outlined":"","dense":"","clearable":""},on:{"input":function($event){return _vm.emitChanges()}},model:{value:(_vm.filters.attended),callback:function ($$v) {_vm.$set(_vm.filters, "attended", $$v)},expression:"filters.attended"}})],1)],1)],1)],1):_vm._e(),(_vm.$vuetify.breakpoint.lgAndUp)?_c('v-col',{staticClass:"d-flex flex-column flex-md-row px-0"},[_c('v-autocomplete',{staticClass:"mr-lg-3 mb-0 pb-0",attrs:{"label":"Grupo","items":_vm.groups,"item-text":"name","item-value":"id","solo":"","multiple":"","chips":"","clearable":"","hide-details":""},on:{"input":function($event){return _vm.emitChanges()}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('v-chip',[_c('span',[_vm._v(_vm._s(item.name))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.filters.group.length - 1)+") ")]):_vm._e()]}}],null,false,1028258205),model:{value:(_vm.filters.group),callback:function ($$v) {_vm.$set(_vm.filters, "group", $$v)},expression:"filters.group"}}),_c('v-autocomplete',{staticClass:"mr-lg-3 ml-lg-3 mb-0",attrs:{"label":"Coach","items":_vm.users,"item-text":"name","item-value":"id","solo":"","multiple":"","chips":"","clearable":"","hide-details":""},on:{"input":function($event){return _vm.emitChanges()}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('v-chip',[_c('span',[_vm._v(_vm._s(item.name))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.filters.coach.length - 1)+") ")]):_vm._e()]}}],null,false,1956340260),model:{value:(_vm.filters.coach),callback:function ($$v) {_vm.$set(_vm.filters, "coach", $$v)},expression:"filters.coach"}}),_c('v-autocomplete',{staticClass:"mr-lg-3 ml-lg-3 mb-0",attrs:{"label":"Participantes","items":_vm.users,"item-text":"name","item-value":"id","solo":"","multiple":"","chips":"","clearable":"","hide-details":""},on:{"input":function($event){return _vm.onAttendeeInput()}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('v-chip',[_c('span',[_vm._v(_vm._s(item.name))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.filters.attendee.length - 1)+") ")]):_vm._e()]}}],null,false,3720901804),model:{value:(_vm.filters.attendee),callback:function ($$v) {_vm.$set(_vm.filters, "attendee", $$v)},expression:"filters.attendee"}}),_c('v-select',{staticClass:"ml-lg-3 mb-0",attrs:{"label":"Comparência","items":_vm.itemsAttendance,"disabled":_vm.filters.attendee.length === 0,"solo":"","clearable":"","hide-details":""},on:{"input":function($event){return _vm.emitChanges()}},model:{value:(_vm.filters.attended),callback:function ($$v) {_vm.$set(_vm.filters, "attended", $$v)},expression:"filters.attended"}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }