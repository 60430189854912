import axios from 'axios';
import Hero from '@/components/Hero/Hero.vue';
import AuthMixin from '@/mixins/Auth';
import DataMixin from '@/mixins/Data';
import ScheduleTable from '@/components/Schedules/ScheduleTable/ScheduleTable.vue';
import DialogEdit from '@/components/Schedules/Dialogs/DialogEdit/DialogEdit.vue';
import DialogDelete from '@/components/Schedules/Dialogs/DialogDelete/DialogDelete.vue';

export default {
  name: 'Schedules',

  components: {
    Hero,
    ScheduleTable,
    DialogEdit,
    DialogDelete,
  },

  mixins: [
    AuthMixin,
    DataMixin,
  ],

  data () {
    return {
      dialogs: {
        edit: false,
        delete: false,
      },

      dialogEdit: false,
      dialogDelete: false,

      editedIndex: -1,
      editedItem: {
        id_rulesets: null,
        id_networks: null,
        id_groups: null,
        active_rulesets: null,
        name_groups: null,
        name_networks: null,
        annotations: null,
        rules_rulesets: null,
        rules: {
          regular: 0,
          regular_pko: 0,
          turbo: 0,
          turbo_pko: 0,
          hyper: 0,
          bubble_rush: 0,
          splash: 0,
          sng: 0,
          fast: 0,
          earlybird: 0,
          blue: 0,
          red: 0,
          exception_specials: "",
          exception_weekend: "",
          exception_sunday: "",
          annotations: "",
          series: []
        },
      },
      defaultItem: {
        id_rulesets: null,
        id_networks: null,
        id_groups: null,
        active_rulesets: null,
        rules_rulesets: null,
        annotations: null,
        name_groups: null,
        name_networks: null,
        rules: {
          regular: 0,
          regular_pko: 0,
          turbo: 0,
          turbo_pko: 0,
          hyper: 0,
          bubble_rush: 0,
          splash: 0,
          sng: 0,
          fast: 0,
          earlybird: 0,
          blue: 0,
          red: 0,
          exception_specials: "",
          exception_weekend: "",
          exception_sunday: "",
          annotations: "",
          series: []
        },
      },

      data: null,
      networks: [],
      options_networks: [],
      groups: [],
      options_groups: [],

      loading: true,
      error: false,
      errorMessage: null,

      search: null,
      expanded: [],
      singleExpand: false,
      headers: [
        { text: 'Grupo', align: 'left', value: 'name_groups' },
        { text: 'Sala', value: 'name_networks' },
        { text: 'Última atualização', value: 'updatedAt_rulesets' },
        { text: 'Ação', value: 'action', sortable: false },
        { text: '', value: 'data-table-expand' },
      ],

      tab: null
    }
  },

  async created () {
    await this.allowOnlyRoles([
      'rail_admin',
      'rail_manager',
      'tracker_admin',
      'tracker_manager',
      'super_coach',
      'coach',
      'scheduling_manager',
    ]);

    this.initialize();
  },

  methods: {
    initialize() {
      // Get rulesets
      this.fetchRulesets();
      
      // Get groups
      axios
      .get(this.$url_api + 'v1/groups.php')
      .then((response) => {
        this.groups = response.data;
        this.options_groups = this.arrayColumn(response.data, 'name_groups');
      })

      // Get networks
      axios
      .get(this.$url_api + 'v1/networks.php')
      .then((response) => {
        for (let key in response.data) {
          this.networks = response.data
          this.options_networks = this.arrayColumn(response.data, 'name_networks')
        }
      })
    },

    fetchRulesets() {
      this.data = null;
      this.loading = true;
      this.error = false;
      this.errorMessage = null;

      axios
      .get(this.$url_api + 'v1/rulesets.php')
      .then((response) => {
        // Translate to 'rules_rulesets' array format 'rules'
        if (response.data) {
          for (let key in response.data) {
            response.data[key].rules = JSON.parse(response.data[key].rules_rulesets);
          }
        } else {
          response.data = [];
        }

        this.data = response.data;
      })
      .catch((error) => {
        this.error = true;
        this.errorMessage = error;
      })
      .then(() => {
        this.loading = false;
      });
    },
    
    assignEditItem(item) {
      if (item != null) {
        this.editedIndex = this.data.indexOf(item);
        this.editedItem = Object.assign({}, item);
      } else {
        this.editedItem = this.defaultItem;
      }
    },

    openDialogEditItem(item) {
      // Convert exceptions for display
      item = this.transformItemForDisplay(item);
      
      // Logic
      this.assignEditItem(item);

      // UI
      this.dialogs.edit = true;
    },

    openDialogDeleteItem(item) {
      this.assignEditItem(item);
      this.dialogs.delete = true;
    },

    closeDialogEdit() {
      this.dialogs.edit = false;
      this.resetEditItem();
    },

    closeDialogDelete() {
      this.dialogs.delete = false;
      this.resetEditItem();
    },

    resetEditItem() {
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    /* Turn ';' into '\n' */
    convertExceptionsForDisplay(exceptions) {
      if (exceptions) {
        exceptions = exceptions.split(';');

        // Remove empty lines
        for (let key in exceptions) {
          if (exceptions[key] == '')
            exceptions.splice(key, 1);
        }

        exceptions = exceptions.join('\n');
      }
      
      return exceptions;
    },

    /* Turn ';' into '\n' */
    transformItemForDisplay(item) {
      if (item && item.hasOwnProperty('rules')) {
        item.rules.exception_specials = this.convertExceptionsForDisplay(item.rules.exception_specials);
        item.rules.exception_weekend = this.convertExceptionsForDisplay(item.rules.exception_weekend);
        item.rules.exception_sunday = this.convertExceptionsForDisplay(item.rules.exception_sunday);

        if ( Array.isArray(item.rules.series) && item.rules.series.length ) {
          item.rules.series.forEach((value, index) => {
            item.rules.series[index].exception_specials = this.convertExceptionsForDisplay(value.exception_specials);
            item.rules.series[index].exception_weekend = this.convertExceptionsForDisplay(value.exception_weekend);
            item.rules.series[index].exception_sunday = this.convertExceptionsForDisplay(value.exception_sunday);
          });
        }
      }

      return item;
    },
  }
}