import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import StoreMixin from '@/mixins/Store.vue';

Vue.use(Vuex);
Vue.use(StoreMixin);

const state = {
    nicknames: [],
    nicknamesOut: [],
    nicknamesInactive: [],

    nicknamesAllPlayer: [],
    nicknamesAllMine: [],
    nicknamesByUserID: [],
    nicknamesByUserTrackerID: [],
    nicknameTransfers: [],
    nicknameGameEntries: [],
    nicknameHistoricBalance: [],
    selectedNickname: null,

    nicknamesTotal: 0,
    nicknamesOutTotal: 0,
    nicknamesInactiveTotal: 0,

    nicknameGameEntriesTotal: 0,
    nicknameTransfersTotal: 0,
    nicknameSessionsGraph: null,
    nicknameHistoricBalanceGraph: null,

    hasLoaded: false,
    hasLoadedNicknamesOut: false,
    hasLoadedNicknamesInactive: false,

    hasLoadedNickname: false,
    hasLoadedNicknamesAllPlayer: false,

    hasLoadedNicknamesAllMine: false,

    hasLoadedNicknamesByUserID: false,
    hasLoadedNicknamesByUserTrackerID: false,
    hasLoadedNicknameTransfers: false,
    hasLoadedNicknameGameEntries: false,
    hasLoadedNicknameHistoricBalance: false,
    hasLoadedNicknameSessionsGraph: false,
    hasLoadedNicknameHistoricBalanceGraph: false,
    error: null,
    nicknameTransfersError: null,
    nicknameGameEntriesError: null,
    nicknameHistoricBalanceError: null,
    humanError: null,
    nicknameTransfersHumanError: null,
    nicknameGameEntriesHumanError: null,
    nicknameHistoricBalanceHumanError: null,

    promiseAllMine: null,

    existsItemsAvailableAfterFilter: null,
    numberOfItemsAvailableAfterFilter: null,
    currentPayloadUserId: null,
    currentPayloadUserTrackerId: null,
};

const getters = {
    nicknames: (state) => state.nicknames,
    nicknamesOut: (state) => state.nicknamesOut,
    nicknamesInactive: (state) => state.nicknamesInactive,

    nicknamesAllPlayer: (state) => state.nicknamesAllPlayer,
    nicknamesAllMine: (state) => state.nicknamesAllMine,
    nicknamesByUserID: (state) => state.nicknamesByUserID,
    nicknamesByUserTrackerID: (state) => state.nicknamesByUserTrackerID,
    nicknameGameEntriesTotal: (state) => state.nicknameGameEntriesTotal,
    nicknameTransfers: (state) => state.nicknameTransfers,
    nicknameTransfersTotal: (state) => state.nicknameTransfersTotal,
    nicknameGameEntries: (state) => state.nicknameGameEntries,
    nicknameHistoricBalanceGraph: (state) => state.nicknameHistoricBalanceGraph,
    nicknameHistoricBalance: (state) => state.nicknameHistoricBalance,
    selectedNickname: (state) => state.selectedNickname,

    nicknamesTotal: (state) => state.nicknamesTotal,
    nicknamesOutTotal: (state) => state.nicknamesOutTotal,
    nicknamesInactiveTotal: (state) => state.nicknamesInactiveTotal,

    nicknameSessionsGraph: (state) => state.nicknameSessionsGraph,

    hasLoaded: (state) => state.hasLoaded,
    hasLoadedNicknamesOut: (state) => state.hasLoadedNicknamesOut,
    hasLoadedNicknamesInactive: (state) => state.hasLoadedNicknamesInactive,

    hasLoadedNickname: (state) => state.hasLoadedNickname,
    hasLoadedNicknamesAllPlayer: (state) => state.hasLoadedNicknamesAllPlayer,
    hasLoadedNicknamesAllMine: (state) => state.hasLoadedNicknamesAllMine,
    hasLoadedNicknamesByUserID: (state) => state.hasLoadedNicknamesByUserID,
    hasLoadedNicknamesByUserTrackerID: (state) => state.hasLoadedNicknamesByUserTrackerID,
    hasLoadedNicknameTransfers: (state) => state.hasLoadedNicknameTransfers,
    hasLoadedNicknameGameEntries: (state) => state.hasLoadedNicknameGameEntries,
    hasLoadedNicknameHistoricBalance: (state) => state.hasLoadedNicknameHistoricBalance,
    hasLoadedNicknameSessionsGraph: (state) => state.hasLoadedNicknameSessionsGraph,
    hasLoadedNicknameGameEntriesGraph: (state) => state.hasLoadedNicknameGameEntriesGraph,
    hasLoadedNicknameHistoricBalanceGraph: (state) => state.hasLoadedNicknameHistoricBalanceGraph,
    error: (state) => state.error,
    nicknameTransfersError: (state) => state.nicknameTransfersError,
    nicknameGameEntriesError: (state) => state.nicknameGameEntriesError,
    humanError: (state) => state.humanError,
    nicknameTransfersHumanError: (state) => state.nicknameTransfersHumanError,
    nicknameGameEntriesHumanError: (state) => state.nicknameGameEntriesHumanError,
    promiseAllMine: (state) => state.promiseAllMine,

    existsItemsAvailableAfterFilter: (state) => state.existsItemsAvailableAfterFilter,
    numberOfItemsAvailableAfterFilter: (state) => state.numberOfItemsAvailableAfterFilter,
};

const mutations = {
    setNicknames: (state, payload) => {
        state.nicknames = payload;
    },

    setNicknamesOut: (state, payload) => {
        state.nicknamesOut = payload;
    },

    setNicknamesInactive: (state, payload) => {
        state.nicknamesInactive = payload;
    },
    
    setNicknamesAllPlayer: (state, payload) => {
        state.nicknamesAllPlayer = payload;
    },

    setNicknamesAllMine: (state, payload) => {
        state.nicknamesAllMine = payload;
    },

    setNicknamesByUserID: (state, payload) => {
        state.nicknamesByUserID = payload;
    },

    setNicknamesByUserTrackerID: (state, payload) => {
        state.nicknamesByUserTrackerID = payload;
    },

    setSelectedNickname: (state, payload) => {
        state.selectedNickname = payload;
    },

    setNicknamesTotal: (state, payload) => {
        state.nicknamesTotal = payload;
    },

    setNicknamesOutTotal: (state, payload) => {
        state.nicknamesOutTotal = payload;
    },

    setNicknamesInactiveTotal: (state, payload) => {
        state.nicknamesInactiveTotal = payload;
    },

    setNicknameSessionsGraph: (state, payload) => {
        state.nicknameSessionsGraph = payload;
    },

    setNicknameTransfers: (state, payload) => {
        state.nicknameTransfers = payload;
    },

    setNicknameTransfersTotal: (state, payload) => {
        state.nicknameTransfersTotal = payload;
    },

    setNicknameGameEntries: (state, payload) => {
        state.nicknameGameEntries = payload;
    },

    setNicknameGameEntriesTotal: (state, payload) => {
        state.nicknameGameEntriesTotal = payload;
    },

    setNicknameHistoricBalanceGraph: (state, payload) => {
        state.nicknameHistoricBalanceGraph = payload;
    },

    setNicknameHistoricBalance: (state, payload) => {
        state.nicknameHistoricBalance = payload;
    },

    setError(state, payload) {
        state.error = payload;
    },

    setNicknameTransfersError(state, payload) {
        state.nicknameTransfersError = payload;
    },

    setNicknameGameEntriesError(state, payload) {
        state.nicknameGameEntriesError = payload;
    },

    setNicknameHistoricBalanceError: (state, payload) => {
        state.nicknameHistoricBalanceError = payload;
    },

    setHumanError(state, payload) {
        state.humanError = payload;
    },

    setNicknameTransfersHumanError(state, payload) {
        state.nicknameTransfersHumanError = payload;
    },

    setNicknameGameEntriesHumanError(state, payload) {
        state.nicknameGameEntriesHumanError = payload;
    },

    setNicknameHistoricBalanceHumanError: (state, payload) => {
        state.nicknameHistoricBalanceHumanError = payload;
    },

    addNickname: (state, payload) => {
        if (!state.nicknames || !state.nicknames.length)
            state.nicknames = [];

        state.nicknames.unshift(payload);
    },

    updateNickname: (state, payload) => {
        const index = state[payload.collection].findIndex(d => d.id == payload.nickname.id);

        const updatedItems = [
            ...state[payload.collection].slice(0, index),
            payload.nickname,
            ...state[payload.collection].slice(index + 1)
        ];

        state[payload.collection] = updatedItems;
    },

    deleteNickname: (state, payload) => {
        const index = state.nicknames.findIndex(d => d.id_nicknames == payload.id_nicknames);
        state.nicknames.splice(index, 1);
    },

    deleteNicknameById: (state, id) => {
        state.nicknames.splice(
            state.nicknames.findIndex(d => d.id_nicknames == id),
            1
        );
    },

    setHasLoaded(state, payload) {
        state.hasLoaded = payload;
    },

    setHasLoadedNicknamesOut(state, payload) {
        state.hasLoadedNicknamesOut = payload;
    },

    setHasLoadedNicknamesInactive(state, payload) {
        state.hasLoadedNicknamesInactive = payload;
    },

    setHasLoadedNickname(state, payload) {
        state.hasLoadedNickname = payload;
    },

    setHasLoadedNicknamesAllPlayer(state, payload) {
        state.hasLoadedNicknamesAllPlayer = payload;
    },

    setHasLoadedNicknamesAllMine(state, payload) {
        state.hasLoadedNicknamesAllMine = payload;
    },

    setHasLoadedNicknamesByUserID(state, payload) {
        state.hasLoadedNicknamesByUserID = payload;
    },

    setHasLoadedNicknamesByUserTrackerID(state, payload) {
        state.hasLoadedNicknamesByUserTrackerID = payload;
    },

    setHasLoadedNicknameTransfers(state, payload) {
        state.hasLoadedNicknameTransfers = payload;
    },

    setHasLoadedNicknameGameEntries(state, payload) {
        state.hasLoadedNicknameGameEntries = payload;
    },

    setHasLoadedNicknameHistoricBalance(state, payload) {
        state.hasLoadedNicknameHistoricBalance = payload;
    },

    setHasLoadedNicknameSessionsGraph: (state, payload) => {
        state.hasLoadedNicknameSessionsGraph = payload;
    },

    setHasLoadedNicknameHistoricBalanceGraph: (state, payload) => {
        state.hasLoadedNicknameHistoricBalanceGraph = payload;
    },

    setPromiseAllMine: (state, payload) => {
        state.promiseAllMine = payload;
    },

    setExistsItemsAvailableAfterFilter: (state, payload) => {
        state.existsItemsAvailableAfterFilter = payload;
    },

    setNumberOfItemsAvailableAfterFilter: (state, payload) => {
        state.numberOfItemsAvailableAfterFilter = payload;
    },
    
    setCurrentPayloadUserId: (state, payload) => {
        state.currentPayloadUserId = payload;  
    },

    setCurrentPayloadUserTrackerId: (state, payload) => {
        state.currentPayloadUserTrackerId = payload;  
    }
};

const actions = {
    /**
     * Retrieved data:
     * Types:   'PLAYER'.
     * Scope:   Requesting user or all users if the requesting user is a manager.
     */
    async get({ commit, dispatch }, payload) {
        // declare variables
        let setItems;
        let setTotalItems;
        let setLoadingItems;
        let handleMutation = StoreMixin.methods.handleNicknameMutations();

        // Retrieve Object with handleUserMutations mixin function
        if (payload && payload.params) {
            handleMutation = StoreMixin.methods.handleNicknameMutations(payload.params.status);
        }

        // mutations that will be used to populate states based on status parameter
        setItems = handleMutation.nicknames;
        setTotalItems = handleMutation.total;
        setLoadingItems = handleMutation.loading;

        // clear states
        dispatch('clearError');
        commit(setItems, []);
        commit(setTotalItems, 0);
        commit(setLoadingItems, false);
        
        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/nicknames/';

        // Configure URL parameters
        let parameters = payload && payload.params
            ? payload.params
            : StoreMixin.methods.getParametersDefault();

        // if status is empty , delete it. (TableNicknames, 1st tab)
        if (parameters.status === '') delete parameters.status;

        // Build URL with parameters
        request_url = StoreMixin.methods.generateQueryParamsUrl(request_url, parameters);

        // Execute request & return
        let output = false;

        return axios.get(request_url)
            .then(function (response) {
                output = response.data.success;
                if (output) {
                    commit(setItems, response.data.data);
                    commit(setTotalItems, response.data.total);
                }
            })
            .catch(function (error) {
                commit(setItems, []);
                commit(setTotalItems, 0);
                commit('setError', error.response ? error.response.data.message : error);
                commit('setHumanError', error.response ? error.response.data.human_message : error);
                output = false;
            })
            .then(function () {
                commit(setLoadingItems, true);
                return output;
            });
    },

    /**
     * Used in TableNicknames to retrieve NICKNAMES and WALLETS
     */
    async getAll({ commit, dispatch }, payload) {
        // declare variables
        let setItems;
        let setTotalItems;
        let setLoadingItems;
        let handleMutation = StoreMixin.methods.handleNicknameMutations();

        // Retrieve Object with handleUserMutations mixin function
        if (payload && payload.params) {
            handleMutation = StoreMixin.methods.handleNicknameMutations(payload.params.tab);
        }
        
        // delete param tab no longer needed
        delete payload.params.tab;

        // mutations that will be used to populate states based on status parameter
        setItems = handleMutation.nicknames;
        setTotalItems = handleMutation.total;
        setLoadingItems = handleMutation.loading;

        // clear states
        dispatch('clearError');
        commit(setItems, []);
        commit(setTotalItems, 0);
        commit(setLoadingItems, false);
        
        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/nicknames/';

        // Configure URL parameters
        let parameters = payload && payload.params
            ? payload.params
            : StoreMixin.methods.getParametersDefault();

        // if status is empty , delete it. (TableNicknames, 1st tab)
        if (parameters.status === '') delete parameters.status;

        // Build URL with parameters
        request_url = StoreMixin.methods.generateQueryParamsUrl(request_url, parameters);

        // Execute request & return
        let output = false;

        return axios.get(request_url)
            .then(function (response) {
                output = response.data.success;
                if (output) {
                    commit(setItems, response.data.data);
                    commit(setTotalItems, response.data.total);
                }
            })
            .catch(function (error) {
                commit(setItems, []);
                commit(setTotalItems, 0);
                commit('setError', error.response ? error.response.data.message : error);
                commit('setHumanError', error.response ? error.response.data.human_message : error);
                output = false;
            })
            .then(function () {
                commit(setLoadingItems, true);
                return output;
            });
    },

    /**
     * Retrieved data:
     * Types:   All ('PLAYER' & 'WALLET').
     * Scope:   Specified user or all users if none is specified and the requesting user is a manager.
     */
    async getAllPlayer({ commit, dispatch, state }, payload) {
        
        if (state.currentPayloadUserId == payload.user && payload.reset) {
            return true;
        }

        // clear states
        commit('setNicknamesAllPlayer', []);
        commit('setNicknamesByUserID', []);
        commit('setHasLoadedNicknamesAllPlayer', false);
        commit('setHasLoadedNicknamesByUserID', false);
        dispatch('clearError');

        // populates current payload user id
        commit('setCurrentPayloadUserId', payload.user);

        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/nicknames/active';

        if (payload.user) {
            request_url += '?user=' + payload.user + '&page=1&itemsPerPage=-1' + payload.aliasParameter;
        } else {
            request_url += '?page=1&itemsPerPage=-1';
        }

        // Execute request & return
        let output = false;

        return axios.get(request_url)
            .then(function (response) {
                output = response.data.success;

                if (output) {
                    if (payload.user) {
                        commit('setNicknamesByUserID', response.data.data);
                    } else {
                        commit('setNicknamesAllPlayer', response.data.data);
                    }
                }
                return output;
            })
            .catch(function (error) {
                commit('setNicknamesAllPlayer', []);
                commit('setNicknamesByUserID', []);
                commit('setError', error.response ? error.response.data.message : error);
                commit('setHumanError', error.response ? error.response.data.human_message : error);
                output = false;
            })
            .finally(function () {
                commit('setHasLoadedNicknamesAllPlayer', true);
                commit('setHasLoadedNicknamesByUserID', true);
                return output;
            });
    },


    async getOwnNicknamesByTrackerID({ commit, dispatch, state }, payload) {
 
        if (state.currentPayloadUserTrackerId == payload.user) {
            return true;
        }

        // clear states
        commit('setNicknamesByUserTrackerID', []);
        commit('setHasLoadedNicknamesByUserTrackerID', false);
        dispatch('clearError');

        // populates current payload user id
        commit('setCurrentPayloadUserTrackerId', payload.user);

        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/nicknames/active';

        if (payload.user) {
            request_url += '?user=' + payload.user + '&page=1&itemsPerPage=-1' + payload.aliasParameter;
        } else {
            request_url += '?page=1&itemsPerPage=-1';
        }

        // Execute request & return
        let output = false;

        return axios.get(request_url)
            .then(function (response) {
                output = response.data.success;

                if (output) {
                    if (payload.user) {
                        commit('setNicknamesByUserTrackerID', response.data.data);
                    }
                }
                return output;
            })
            .catch(function (error) {
                commit('setNicknamesByUserTrackerID', []);
                commit('setError', error.response ? error.response.data.message : error);
                commit('setHumanError', error.response ? error.response.data.human_message : error);
                output = false;
            })
            .finally(function () {
                commit('setHasLoadedNicknamesByUserTrackerID', true);
                return output;
            });
    },

    /**
     * Retrieved data:
     * Types:   All ('PLAYER' & 'WALLET').
     * Scope:   Requesting user.
     */
    async getAllMine({ commit, dispatch, state }, payload) {
        // prevent multiple API Calls
        if (state.promiseAllMine) {
            return state.promiseAllMine;
        }
        
        commit('setNicknamesAllMine', []);
        commit('setHasLoadedNicknamesAllMine', false);
        dispatch('clearError');

        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/nicknames/active/mine/?page=1&itemsPerPage=-1&aliasChildOf=null';

        // Execute request & return
        let output = false;

        let request = await axios.get(request_url)
            .then(function (response) {
                output = response.data.success;

                if (output) {
                    commit('setNicknamesAllMine', response.data.data);
                } else {
                    commit('setError', response.data.message);
                    commit('setHumanError', response.data.human_message);
                }
            })
            .catch(function (error) {
                commit('setError', error.response ? error.response.data.message : error);
                commit('setHumanError', error.response ? error.response.data.human_message : error);
                output = false;
            })
            .then(function () {
                commit('setHasLoadedNicknamesAllMine', true);
                return output;
            });
        
        commit('setPromiseAllMine', request);
        return request;
    },

    /**
     * Get Nickname
     */
    async getNickname({ commit, dispatch }, payload) {
        // Reset store to initial state
        commit('setSelectedNickname', null);
        const nicknameID = payload.id;

        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/nicknames/' + nicknameID;

        // Execute request & return
        let output = false;

        return axios.get(request_url)
            .then(function (response) {
                output = response.data.success;

                if (output) {
                    commit('setSelectedNickname', response.data.data);
                } else {
                    commit('setError', response.data.message);
                    commit('setHumanError', response.data.human_message);
                }
            })
            .catch(function (error) {
                commit('setError', error.response ? error.response.data.message : error);
                commit('setHumanError', error.response ? error.response.data.human_message : error);
                output = false;
            })
            .then(function () {
                commit('setHasLoadedNickname', true);
                return output;
            });
    },

    /**
     * readSessionsCumulativePNL
     */
    async getNicknameSessionsGraph({ commit, dispatch }, payload) {
        // Reset store to initial state
        commit('setNicknameSessionsGraph', null);
        commit('setHasLoadedNicknameSessionsGraph', false);

        const nicknameID = payload.id;

        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/nicknames/' + nicknameID + '/sessions/graph/';

        // Formulate URL with query string with generateQueryParamsUrl mixin function
        if (payload && payload.params) {
            request_url = StoreMixin.methods.generateQueryParamsUrl(request_url, payload.params);
        }

        // Execute request & return
        let output = false;

        return axios.get(request_url)
            .then(function (response) {
                output = response.data.success;

                if (output) {
                    commit('setNicknameSessionsGraph', response.data.data);
                } else {
                    commit('setNicknameSessionsError', response.data.message);
                    commit('setNicknameSessionsHumanError', response.data.human_message);
                }
            })
            .catch(function (error) {
                commit('setNicknameSessionsError', error.response ? error.response.data.message : error);
                commit('setNicknameSessionsHumanError', error.response ? error.response.data.human_message : error);
                output = false;
            })
            .then(function () {
                commit('setHasLoadedNicknameSessionsGraph', true);
                return output;
            });
    },

    /**
     * Get nickname transfers
     */
    async getNicknameTransfers({ commit, dispatch }, payload) {
        // Reset store to initial state
        commit('setNicknameTransfers', []);
        commit('setHasLoadedNicknameTransfers', false);

        const nicknameID = payload.id;

        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/nicknames/' + nicknameID + '/movements/';

        // Formulate URL with query string with generateQueryParamsUrl mixin function
        if (payload && payload.params) {
            request_url = StoreMixin.methods.generateQueryParamsUrl(request_url, payload.params);
        }

        // Execute request & return
        let output = false;

        return axios.get(request_url)
            .then(function (response) {
                output = response.data.success;

                if (output) {
                    commit('setNicknameTransfers', response.data.data);
                    commit('setNicknameTransfersTotal', response.data.total);
                } else {
                    commit('setNicknameTransfersError', response.data.message);
                    commit('setNicknameTransfersHumanError', response.data.human_message);
                }
            })
            .catch(function (error) {
                commit('setNicknameTransfers', []);
                commit('setNicknameTransfersError', error.response ? error.response.data.message : error);
                commit('setNicknameTransfersHumanError', error.response ? error.response.data.human_message : error);
                output = false;
            })
            .then(function () {
                commit('setHasLoadedNicknameTransfers', true);
                return output;
            });
    },

    /**
     * Get nickname historic balance
     */
    async getNicknameHistoricBalance({ commit, dispatch }, payload) {
        // Reset store to initial state
        commit('setNicknameHistoricBalance', []);

        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/nicknames/' + payload + '/historic/';

        // Formulate URL with query string with generateQueryParamsUrl mixin function
        if (payload) {
            request_url = StoreMixin.methods.generateQueryParamsUrl(request_url, payload);
        }

        // Execute request & return
        let output = false;

        return axios.get(request_url)
            .then(function (response) {
                output = response.data.success;

                if (output) {
                    commit('setNicknameHistoricBalance', response.data.data);
                } else {
                    commit('setNicknameHistoricBalanceError', response.data.message);
                    commit('setNicknameHistoricBalanceHumanError', response.data.human_message);
                }
            })
            .catch(function (error) {
                commit('setNicknameHistoricBalanceError', error.response ? error.response.data.message : error);
                commit('setNicknameHistoricBalanceHumanError', error.response ? error.response.data.human_message : error);
                output = false;
            })
            .then(function () {
                commit('setHasLoadedNicknameHistoricBalance', true);
                return output;
            });
    },

    /**
     * Get nickname historic balance graph
     */
    async getNicknameHistoricBalanceGraph({ commit, dispatch }, payload) {
        // Reset store to initial state
        commit('setNicknameHistoricBalanceGraph', []);
        commit('setHasLoadedNicknameHistoricBalanceGraph', false)

        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/nicknames/' + payload.id + '/historic/graph';

        // Formulate URL with query string with generateQueryParamsUrl mixin function
        if (payload && payload.params) {
            request_url = StoreMixin.methods.generateQueryParamsUrl(request_url, payload.params);
        }

        // Execute request & return
        let output = false;

        return axios.get(request_url)
            .then(function (response) {
                output = response.data.success;

                if (output) {
                    commit('setNicknameHistoricBalanceGraph', response.data.data);
                } else {
                    commit('setError', response.data.message);
                    commit('setHumanError', response.data.human_message);
                }
            })
            .catch(function (error) {
                commit('setError', error.response ? error.response.data.message : error);
                commit('setHumanError', error.response ? error.response.data.human_message : error);
                output = false;
            })
            .then(function () {
                commit('setHasLoadedNicknameHistoricBalanceGraph', true);
                return output;
            });
    },

    /**
     * Get nickname game entries
     */
    async getNicknameGameEntries({ commit, dispatch }, payload) {
        // Reset store to initial state
        commit('setNicknameGameEntries', []);
        commit('setHasLoadedNicknameGameEntries', false);

        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/nicknames/' + payload.id + '/gameEntries/';

        // Configure URL parameters
        let parameters = payload && payload.params
            ? payload.params
            : StoreMixin.methods.getParametersDefault();
        
        // Build URL with parameters
        request_url = StoreMixin.methods.generateQueryParamsUrl(request_url, parameters);

        // Execute request & return
        let output = false;

        return axios.get(request_url)
            .then(function (response) {
                output = response.data.success;

                if (output) {
                    commit('setNicknameGameEntries', response.data.data);
                    commit('setNicknameGameEntriesTotal', response.data.total);
                } else {
                    commit('setNicknameGameEntriesError', response.data.message);
                    commit('setNicknameGameEntriesHumanError', response.data.human_message);
                }
            })
            .catch(function (error) {
                commit('setNicknameGameEntries', []);
                commit('setNicknameGameEntriesError', error.response ? error.response.data.message : error);
                commit('setNicknameGameEntriesHumanError', error.response ? error.response.data.human_message : error);
                output = false;
            })
            .then(function () {
                commit('setHasLoadedNicknameGameEntries', true);
                return output;
            });
    },


    async create({ commit, dispatch }, payload) {
        dispatch('clearError');

        // Handle payload data
        let data = JSON.stringify(payload.body);

        // Configure request
        const request_url = Vue.prototype.$url_api + 'v2/nicknames/';
        let config = {
            method: 'POST',
            url: request_url,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        }

        // Execute request & return
        let output = false;

        return axios(config)
            .then(function (response) {
                output = response.data.success;

                if (output) {
                    commit('addNickname', response.data.data);
                    commit('setSelectedNickname', response.data.data);
                } else {
                    commit('setError', response.data.message);
                    commit('setHumanError', response.data.human_message);
                }
            })
            .catch(function (error) {
                commit('setError', error.response ? error.response.data.message : error);
                commit('setHumanError', error.response ? error.response.data.human_message : error);
                output = false;
            })
            .then(function () {
                return output;
            });
    },

    async replace({ commit, dispatch }, payload) {
        dispatch('clearError');

        // Handle payload data
        let data = JSON.stringify(payload.body);

        // Configure request
        const request_url = Vue.prototype.$url_api + 'v2/nicknames/replace/';
        let config = {
            method: 'POST',
            url: request_url,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        }

        // Execute request & return
        let output = false;

        return axios(config)
            .then(function (response) {
                output = response.data.success;

                if (output) {
                    commit('addNickname', response.data.data);
                    commit('setSelectedNickname', response.data.data);
                } else {
                    commit('setError', response.data.message);
                    commit('setHumanError', response.data.human_message);
                }
            })
            .catch(function (error) {
                commit('setError', error.response ? error.response.data.message : error);
                commit('setHumanError', error.response ? error.response.data.human_message : error);
                output = false;
            })
            .then(function () {
                return output;
            });
    },

    async update({ commit, dispatch }, payload) {
        dispatch('clearError');

        // Handle payload data
        let data = JSON.stringify(payload.body);

        // Configure request
        const request_url = Vue.prototype.$url_api + 'v2/nicknames/' + payload.id + '/';
        let config = {
            method: 'PUT',
            url: request_url,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        }

        // Execute request & return
        let output = false;

        return axios(config)
            .then(function (response) {
                output = response.data.success;

                if (output) {
                    commit('setSelectedNickname', response.data.data);
                } else {
                    commit('setError', response.data.message);
                    commit('setHumanError', response.data.human_message);
                }
            })
            .catch(function (error) {
                commit('setError', error.response ? error.response.data.message : error);
                commit('setHumanError', error.response ? error.response.data.human_message : error);
                output = false;
            })
            .then(function () {
                return output;
            });
    },

    async activate({ commit, dispatch }, nicknameID) {
        dispatch('clearError');

        // Configure request
        const request_url = Vue.prototype.$url_api + 'v2/nicknames/' + nicknameID + '/activate/';

        // Configure request
        let config = {
            method: 'PATCH',
            url: request_url,
            headers: {
                'Content-Type': 'application/json'
            }
        };

        // Execute request & return
        let output = false;

        return axios(config)
            .then(function (response) {
                output = response.data.success;

                if (output) {
                    let payload = {
                        collection: 'nicknames',
                        nickname: response.data.data
                    }
                    commit('updateNickname', payload);
                    payload.collection = 'nicknamesOut';
                    commit('updateNickname', payload);
                    payload.collection = 'nicknamesInactive';
                    commit('updateNickname', payload);
                    commit('setSelectedNickname', response.data.data);
                } else {
                    commit('setError', response.data.message);
                    commit('setHumanError', response.data.human_message);
                }
            })
            .catch(function (error) {
                commit('setError', error.response ? error.response.data.message : error);
                commit('setHumanError', error.response ? error.response.data.human_message : error);
                output = false;
            })
            .then(function () {
                return output;
            });
    },

    async deactivate({ commit, dispatch }, nicknameID) {
        dispatch('clearError');

        // Configure request
        const request_url = Vue.prototype.$url_api + 'v2/nicknames/' + nicknameID + '/deactivate/';


        // Configure request
        let config = {
            method: 'PATCH',
            url: request_url,
            headers: {
                'Content-Type': 'application/json'
            }
        };

        // Execute request & return
        let output = false;

        return axios(config)
            .then(function (response) {
                output = response.data.success;

                if (output) {
                    let payload = {
                        collection: 'nicknames',
                        nickname: response.data.data
                    }
                    commit('updateNickname', payload);
                    payload.collection = 'nicknamesOut';
                    commit('updateNickname', payload);
                    payload.collection = 'nicknamesInactive';
                    commit('updateNickname', payload);
                    commit('setSelectedNickname', response.data.data);
                } else {
                    commit('setError', response.data.message);
                    commit('setHumanError', response.data.human_message);
                }
            })
            .catch(function (error) {
                commit('setError', error.response ? error.response.data.message : error);
                commit('setHumanError', error.response ? error.response.data.human_message : error);
                output = false;
            })
            .then(function () {
                return output;
            });
    },

    async validate({ commit, dispatch }, payload) {
        dispatch('clearError');

        // Handle payload data
        let data = JSON.stringify(payload);

        // Configure request
        const request_url = Vue.prototype.$url_api + 'v2/nicknames/validate/';
        let config = {
            method: 'PATCH',
            url: request_url,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        }

        // Execute request & return
        let output = false;

        return axios(config)
            .then(function (response) {
                output = response.data.success;

                // Catch error
                if (!output) {
                    commit('setError', response.data.message);
                    commit('setHumanError', response.data.human_message);
                }
            })
            .catch(function (error) {
                commit('setError', error.response ? error.response.data.message : error);
                commit('setHumanError', error.response ? error.response.data.human_message : error);
                output = false;
            })
            .then(function () {
                return output;
            });
    },

    async delete({ commit, dispatch }, nicknameID) {
        dispatch('clearError');

        // Configure request
        const request_url = Vue.prototype.$url_api + 'v2/nicknames/' + nicknameID + '/delete/';

        // Execute request & return
        let output = false;

        return axios.patch(request_url)
            .then(function (response) {
                output = response.data.success;

                if (output) {
                    commit('deleteNicknameById', nicknameID);
                } else {
                    commit('setError', response.data.message);
                    commit('setHumanError', response.data.human_message);
                }
            })
            .catch(function (error) {
                commit('setError', error.response ? error.response.data.message : error);
                commit('setHumanError', error.response ? error.response.data.human_message : error);
                output = false;
            })
            .then(function () {
                return output;
            });
    },

    select({ commit }, payload) {
        commit('setSelectedNickname', payload);
    },

    // Reset store to its initial state
    reset({ commit }) {
        commit('setNicknames', []);
        commit('setNicknamesOut', []);
        commit('setNicknamesInactive', []);
        commit('setNicknamesAllMine', []);
        commit('setNicknamesByUserID', []);
        commit('setNicknamesTotal', 0);
        commit('setNicknamesOutTotal', 0);
        commit('setNicknamesInactiveTotal', 0);
        commit('setNicknameGameEntriesTotal', 0);
        commit('setNicknameTransfersTotal', 0);
        commit('setHasLoaded', false);
        commit('setHasLoadedNicknamesOut', false);
        commit('setHasLoadedNicknamesInactive', false);
        commit('setHasLoadedNicknamesByUserID', false);
        commit('setSelectedNickname', null);
        commit('setNumberOfItemsAvailableAfterFilter', null);
        commit('setError', null);
        commit('setHumanError', null);
        commit('setPromiseAllMine', null);
    },

    clear({ commit }) {
        commit('setNicknames', []);
    },

    clearError({ commit }) {
        commit('setError', null);
        commit('setHumanError', null);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};