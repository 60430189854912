import { mapGetters } from "vuex";

export default {
    name: "dialog-request-admin",
    props: {
        dialogOpen: {
            type: Boolean,
            required: true,
            default: false,
        },
        days: {
            type: Array,
            required: false,
            default: () => [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        },      
    },
    computed: {
        ...mapGetters({
            users: "users_v1/users",
            videos: "videoRequests/videos",
        }),
    },
    created() {
        this.fetchData();
    },
    data() {
        return this.initialState();
    },
    methods: {
        initialState() {
            return {
                dialog: false,
                hasAccess: true,
                user: null,
                post: null,
                expiry: 7,
                rules: {
                    fieldUser: [(v) => !!v || "Por favor introduza um jogador"],
                    fieldPost: [(v) => !!v || "Por favor introduza um video"],
                    fieldDays: [
                        (v) => !!v || "Por favor introduza um número de dias",
                        (v) =>
                            parseInt(v) > 0 ||
                            "Por favor introduza um valor numérico positivo",
                        (v) =>
                            Number.isInteger(v) ||
                            "Por favor introduza um valor numérico",
                    ],
                },
                form_valid: true,
            };
        },

        /**
         * Fetch users and videos from the store
         */
        async fetchData() {
            await this.$store.dispatch("users_v1/getUsers");
            await this.$store.dispatch("videoRequests/getVideos");
        },

        /**
         * Handle Auto approve video request switch state
         * If true update expiry state data with default value (7 days)
         * Else update expiry state data with null
         */
        changeAccess() {
            if (this.hasAccess == false) {
                this.expiry = null;
             } 
             else {
                this.expiry = 7;
            }
            return !this.hasAccess;
        },

        setNumberOfAccessDays(date, expiry) {
            return date.add(expiry, "expiry");
        },

        submit() {
            if (!this.$refs.formRequestVideo.validate()) return false;

            setTimeout(() => {
                if (!this.form_valid) return false;

                let payload = {
                    post: this.post,
                    user: this.user,
                    expiry: this.expiry,
                    status: this.hasAccess == true ? 1 : 0,
                };
                this.$store.dispatch(
                    "videoRequests/createRequestVideo",
                    payload
                ).then(() => {
                    this.closeDialog();
                    return this.$store.dispatch("UI/showSnackbar", {
                        message: "Pedido de video criado com sucesso.",
                        color: "success",
                    });
                })
                    .catch(error => {
                        this.$store.dispatch("UI/showSnackbar", {
                            message: error.message,
                            color: "red",
                        });
                    })
            }, 500);
        },
        
        closeDialog() {
            this.resetDialog();
            this.$emit("closeDialog");
        },

        toggleDialog(dialog) {
            this.dialogues[dialog] = !this.dialogues[dialog];
        },

        /**
         * Reset form validation errros (post and user are required)
         * refresh component state data to initial state
         */
        resetDialog() {
            this.$refs.formRequestVideo.resetValidation();
            Object.assign(this.$data, this.initialState());
         },
    },
};
